import { OrderedMap, Record, Set } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const ROLES = 'roles'

export const Role = Record({
  id: null,
  description: null,
  permissionSetIds: Set(),
  permissionIds: Set(),
})

const transformRole = ({
  permissionSetLinks,
  permissionLinks,
  ...role
}: any) => {
  const permissionSetIds = Set(
    permissionSetLinks.map(({ permissionSetId }: any) => permissionSetId)
  )
  const permissionIds = Set(
    permissionLinks.map(({ permissionId }: any) => permissionId)
  )

  return Role({ ...role, permissionSetIds, permissionIds })
}

const transformRoles = (roles: any) =>
  OrderedMap(roles.map((role: any) => [role.id, transformRole(role)]))

export const fetchRoles = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ROLES',
  operation: () => AspireAPI.get('admin_tools/roles'),
  transform: transformRoles,
  messages: { failed: 'There was an issue fetching roles' },
})

export const createRole = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_ROLE',
  requestParams: ['role'],
  operation: (role: any) => AspireAPI.post('admin_tools/roles', role),
  transform: transformRole,
  messages: {
    succeeded: 'Role successfully created',
    failed: 'There was an issue creating this role',
  },
})

export const saveRole = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_ROLE',
  requestParams: ['id', 'role'],
  operation: (id: any, role: any) =>
    AspireAPI.put(`admin_tools/roles/${id}`, role),
  transform: transformRole,
  messages: {
    succeeded: 'Role successfully saved',
    failed: 'There was an issue saving this role',
  },
})

export const deleteRole = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_ROLE',
  requestParams: ['id'],
  operation: (id: any) => AspireAPI.delete(`admin_tools/roles/${id}`),
  messages: {
    succeeded: 'Role successfully deleted',
    failed: 'There was an issue deleting this role',
  },
})

export default createReducer(ROLES, OrderedMap(), {
  [fetchRoles.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [createRole.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set(payload.id, payload),
  [saveRole.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set(payload.id, payload),
  [deleteRole.SUCCEEDED]: (state: any, { meta }: any) =>
    state.delete(meta.request.payload.id),
})

export const getRoles = pipe(getRoot, get(ROLES))
export const getRoleById = (state: any, roleId: any) =>
  getRoles(state).get(roleId, Role())
