import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
import rootKey from '../key'

const transformErrorResponse = (error: any) => {
  const genericSaveError = 'Could not add outcome'
  return error?.response?.data?.message?.label[0] || genericSaveError
}

const transformEditErrorResponse = (error: any) => {
  const genericSaveError = 'Could not update outcome'
  return error?.response?.data?.message.label[0] || genericSaveError
}

export const addOutcome = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_OUTCOME',
  requestParams: ['outcomeInputs'],
  operation: (outcomeInputs: any) =>
    AspireAPI.post('admin_tools/outcome', {
      ...outcomeInputs,
    }),
  messages: {
    succeeded: 'Outcome added successfully',
    failed: (error: any) => transformErrorResponse(error),
  },
})

export const editOutcome = Request({
  typePrefix: rootKey,
  typeBase: 'EDIT_OUTCOME',
  requestParams: ['outcomeInputs'],
  operation: (outcomeInputs: any) =>
    AspireAPI.post('admin_tools/outcome', {
      ...outcomeInputs,
    }),
  messages: {
    succeeded: 'Outcome updated successfully',
    failed: (error: any) => transformEditErrorResponse(error),
  },
})
