import React from 'react'
import { useSelector } from 'react-redux'
import { getUserId } from '~/data/session'
import { getUserById } from '~/data/users'
import {
  eventTypes,
  getDate,
  getTimeZone,
  newEventCreated,
  newEventOpened,
} from '~/features/calendarWidget'
import { callLogInitialized, isCallLogActive } from '~/features/callLogger'
import { modeSet as createHospitalizationDialogOpened } from '~/features/hospitalizations'
import { createNewTaskDialogOpened } from '~/features/tasks'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Divider, List, MenuItem } from '@material-ui/core'
import { getCurrentAPPId } from '../../data/currentAPPId'
import { locationASelected, locationBSelected } from '../../data/quickLocations'
import { fetchDialogReminder } from '../../data/reminderTaskDialog'
import { snfPatientSelected } from '../../data/snfPatient'
import { snoozeDialogOpened } from '../../data/snoozeDialog'

const REFERRED = 'Referred'
const SCHEDULED = 'Scheduled'

export const VISIT = 'visit'
export const PLACEHOLDER = 'placeholder'
export const LOG_CALL = 'log_call'
export const TASK = 'task'
export const REMINDER = 'reminder'
export const SNF = 'snf'
export const HOSPITALIZATION = 'hospitalization'
export const SNOOZE = 'snooze'

const OptionsMenu = ({
  patient,
  showPatientOptions,
  excludedOptions,
  id,
  label,
  location,
}) => {
  const userId = useSelector(getUserId)
  const currentAPPId = useSelector(getCurrentAPPId)
  const owner = useSelector(state => getUserById(state, currentAPPId))
  const timeZone = useSelector(getTimeZone)
  const date = useSelector(getDate)
  const callLogActive = useSelector(isCallLogActive)
  const selectSNFPatient = useAction(snfPatientSelected)
  const openSnoozeDialog = useAction(snoozeDialogOpened)
  const openNewEvent = useAction(newEventOpened)
  const createNewEvent = useAction(newEventCreated)
  const initializeCallLog = useAction(callLogInitialized)
  const openNewTask = useAction(createNewTaskDialogOpened)
  const fetchDialogReminderRequested = useAction(fetchDialogReminder.requested)
  const openNewHospitalization = useAction(createHospitalizationDialogOpened)
  const selectLocationA = useAction(locationASelected)
  const selectLocationB = useAction(locationBSelected)

  const createEventCallback = eventType => () => {
    const defaultEvent = eventType.defaults
    const ownerId = owner.id
    const ownerName = owner.name

    openNewEvent()
    createNewEvent(
      {
        ...defaultEvent,
        patientId: patient.id,
        ownerId,
        ownerName,
      },
      date,
      timeZone
    )
  }

  const onCreateVisit = createEventCallback(eventTypes.Visit)

  const onCreateVisitPlaceholder = createEventCallback(
    eventTypes.VisitPlaceholder
  )

  const onAddSNFAdmission = () => {
    selectSNFPatient(patient.id, patient.status)
  }

  const onSnoozePatient = () => {
    openSnoozeDialog(patient.id, patient.name)
  }

  const onAddHospitalization = () => {
    openNewHospitalization('add', patient.id)
  }

  const onLogCall = () => {
    initializeCallLog(patient.id, patient.status)
  }

  const onAddTask = () => {
    openNewTask(patient.id, patient.name)
  }

  const onAddReminder = () => {
    fetchDialogReminderRequested(userId, patient.id)
  }

  const onSelectLocationA = () => {
    const { lat, lng } = location
    selectLocationA(id, label, lat, lng)
  }

  const onSelectLocationB = () => {
    const { lat, lng } = location
    selectLocationB(id, label, lat, lng)
  }

  return (
    <List>
      <MenuItem dense onClick={onSelectLocationA}>
        Set Quick Location A
      </MenuItem>

      <MenuItem dense onClick={onSelectLocationB}>
        Set Quick Location B
      </MenuItem>

      <Divider key="divider-2" />

      {showPatientOptions && (
        <React.Fragment>
          {!excludedOptions.includes(VISIT) && (
            <MenuItem key={VISIT} dense onClick={onCreateVisit}>
              Schedule Patient
            </MenuItem>
          )}

          {!excludedOptions.includes(PLACEHOLDER) && (
            <MenuItem
              key={PLACEHOLDER}
              dense
              onClick={onCreateVisitPlaceholder}
            >
              Create Placeholder
            </MenuItem>
          )}

          <Divider key="divider-1" />

          {!excludedOptions.includes(LOG_CALL) && (
            <MenuItem
              key={LOG_CALL}
              disabled={callLogActive}
              dense
              onClick={onLogCall}
            >
              Log Call
            </MenuItem>
          )}

          {!excludedOptions.includes(TASK) && (
            <MenuItem key={TASK} dense onClick={onAddTask}>
              Add Task
            </MenuItem>
          )}

          {!excludedOptions.includes(REMINDER) && (
            <MenuItem key={REMINDER} dense onClick={onAddReminder}>
              Add Reminder
            </MenuItem>
          )}

          {!excludedOptions.includes(SNF) && (
            <MenuItem
              key={SNF}
              dense
              disabled={patient.status === SCHEDULED}
              onClick={onAddSNFAdmission}
            >
              {patient.status === REFERRED
                ? 'Set Substatus to SNF'
                : 'Add SNF Admission'}
            </MenuItem>
          )}

          {!excludedOptions.includes(SNOOZE) && (
            <MenuItem key={SNOOZE} dense onClick={onSnoozePatient}>
              Snooze Patient
            </MenuItem>
          )}

          {!excludedOptions.includes(HOSPITALIZATION) && (
            <MenuItem
              key={HOSPITALIZATION}
              dense
              onClick={onAddHospitalization}
            >
              Add Hospitalization
            </MenuItem>
          )}
        </React.Fragment>
      )}
    </List>
  )
}

OptionsMenu.propTypes = {
  patient: PropTypes.record,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  showPatientOptions: PropTypes.bool,
  excludedOptions: PropTypes.arrayOf(
    PropTypes.oneOf([
      VISIT,
      PLACEHOLDER,
      LOG_CALL,
      TASK,
      REMINDER,
      SNF,
      HOSPITALIZATION,
      SNOOZE,
    ])
  ),
}

OptionsMenu.defaultProps = {
  showPatientOptions: false,
  excludedOptions: [],
}

export default OptionsMenu
