import cx from 'classnames'
import React from 'react'
import { Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  divider: {
    visibility: 'hidden',
  },
  small: {
    margin: spacing(1, 0),
  },
  large: {
    margin: spacing(3, 0),
  },
}))

type Props = {
  dense?: boolean
}

export default function InvisibleDivider({ dense = false }: Props) {
  const classes = useStyles()

  return (
    <Divider
      className={cx(classes.divider, dense ? classes.small : classes.large)}
    />
  )
}
