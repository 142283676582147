import React from 'react'
import { compose } from 'redux'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import ViewRowActions from './ViewRowActions'

const styles = {
  width: {
    width: 500,
  },
}

const RowActions = ({ classes, rowActions: RowActions, ...props }) => {
  return (
    <React.Fragment>
      <div className={classes.width}>
        <ViewRowActions {...props} />
        <RowActions {...props} />
      </div>
    </React.Fragment>
  )
}

RowActions.propTypes = {
  classes: PropTypes.object.isRequired,
  rowActions: PropTypes.elementType,
}

RowActions.defaultProps = {
  rowActions: () => {},
}

export default compose(React.memo, withStyles(styles))(RowActions)
