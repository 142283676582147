import React from 'react'
import { Divider, List, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ImoModifier from './imoModifier'
import { Modifier, ModifierGroup } from '../types/types'

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    root: {
      flexGrow: 1,
      flexWrap: 'nowrap',
      maxWidth: 300,
      textAlign: 'center',
      margin: spacing(1, 2),
      padding: spacing(1, 2),
    },
    list: {
      maxHeight: '150px',
      overflow: 'auto',
      backgroundColor: palette.background.default,
      borderRadius: '4px',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      '& div:last-child > hr': {
        display: 'none',
      },
    },
    title: {
      margin: spacing(1, 0),
      fontWeight: 'bold',
    },
  })
)

type Props = {
  id: string
  modifierGroup: ModifierGroup
}

const ImoModifierGroup = ({ id, modifierGroup }: Props) => {
  const classes = useStyles()
  const { title, type, modifiers } = modifierGroup

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <List className={classes.list}>
        {modifiers.map((modifier: Modifier) =>
          modifier.disabled ? null : (
            <div key={modifier.code}>
              <ImoModifier
                key={modifier.code}
                problemId={id}
                modifierGroupType={type}
                modifier={modifier}
              />
              <Divider />
            </div>
          )
        )}
      </List>
    </div>
  )
}

export default ImoModifierGroup
