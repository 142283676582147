import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'

const key = 'orderTypes'

export const orderPreview = Request({
  typePrefix: key,
  typeBase: 'DOWNLOAD_DOCUMENT',
  requestParams: ['orderId'],
  operation: orderId =>
    AspireAPI.get(`v1/orders/${orderId}/pdf`, {
      responseType: 'blob',
    }),
  messages: {
    failed: 'There was a problem downloading the order preview document',
  },
})
