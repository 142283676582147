import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { ClassplanProducts } from './interfaces'

// KEY
const key = 'classPlanProduct'

export const ClassPlanProduct = Record({
  classplanProductName: null,
  classplanProductId: null,
  countyName: null,
})

// TRANSFORMER
const mapClassplanProductResults = (results: [ClassplanProducts]) =>
  List(
    results?.map(product =>
      ClassPlanProduct({
        classplanProductName: product?.classplanProductName,
        classplanProductId: product?.classplanProductId,
        countyName: product?.countyName,
      })
    )
  )

// REQUEST
export const fetchClassplanProducts = Request({
  typePrefix: key,
  typeBase: 'FETCH_CLASSPLAN_PRODUCTS',
  operation: () => AspireAPI.get('transportation/classplan_products'),
  transform: mapClassplanProductResults,
  messages: { failed: 'Failed to fetch classplan products ' },
})

// REDUCER
export const classplanProduct = createReducer(key, List(), {
  [fetchClassplanProducts.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

// SELECTORS
export const getClassPlanProduct = pipe(getRoot, get(key))
