import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import { DISCARD_CONFIRMED } from '../data/admissionsReconciliation'
import {
  discardOdsAdmissionIdCleared,
  discardSaveFailed,
  discardSaved,
  getDiscardOdsAdmissionId,
} from '../data/discardOdsAdmissionId'
import { discardOdsAdmission, odsAdmissionDeleted } from '../data/odsAdmissions'
import { selectedOdsAdmissionIdCleared } from '../data/selectedOdsAdmissionIds'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(DISCARD_CONFIRMED),
    mergeMap(() => {
      const discardOdsAdmissionId = getDiscardOdsAdmissionId(state$.value)

      return concat(
        from(discardOdsAdmission(discardOdsAdmissionId)).pipe(
          map(discardSaved)
        ),
        of(odsAdmissionDeleted(discardOdsAdmissionId)),
        of(discardOdsAdmissionIdCleared()),
        of(selectedOdsAdmissionIdCleared(discardOdsAdmissionId)),
        catchError(e => {
          telemetry.error(e)
          return of(discardSaveFailed())
        })
      )
    })
  )
