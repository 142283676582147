import { List, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
const OPEN_ASSESSMENTS = 'openAssessments'
export const OpenAssessment = Record({
  patientName: null,
  patientId: null,
  status: null,
  dateOfService: null,
  id: null,
  type: null,
  providerName: null,
})
const transformAssessments = (assessments: any) =>
  List(assessments.map(OpenAssessment))
export const fetchOpenAssessments = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_INCOMPLETE_ASSESSMENTS',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('dashboard/incomplete_assessment_list', {
      params: { userId },
    }),
  transform: transformAssessments,
  messages: {
    failed: 'Unable to load incomplete assessments.',
  },
})
export default createReducer(OPEN_ASSESSMENTS, List(), {
  [fetchOpenAssessments.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
export const getOpenAssessments = pipe(getRoot, get(OPEN_ASSESSMENTS))
export const getOpenAssessmentArray = createSelector(
  getOpenAssessments,
  assessments => (assessments as any).toArray()
)
