import React, { useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/styles'
import { AttachmentTypeSelector } from '../components/AttachmentTypeSelect'
import { AttachmentsFilter } from '../components/AttachmentsFilter'
import { AttachmentsTable } from '../components/AttachmentsTable'
import { newAttTypeSelected, onLoadAttachments } from '../data/attachmentEvents'
import {
  getAttachmentTypes,
  getAttachmentsArray,
} from '../data/fetchAttachments'
import { getAttachmentDocumentById } from '../data/fetchedDocument'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginRight: spacing(3),
    marginTop: 13,
    maxHeight: 36,
    padding: [0, spacing(4)],
  },
  fileSpacer: {
    paddingRight: spacing(1),
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    paddingRight: spacing(3),
  },
}))

const AttachmentWidget = ({
  patientId,
  onLoad,
  newAttTypeSelected,
  dispatch,
  attachmentTypes,
  attachments,
  getAttachmentDocument,
}) => {
  const [filter, setFilter] = useState('')
  const [selectedType, setSelectedType] = useState('NextGen Notes')

  const classes = useStyles()

  useLayoutEffect(() => {
    if (patientId) {
      onLoad(patientId, selectedType)
    }
  }, [])

  const onFilterChange = ({ target: { value } }) => setFilter(value)

  const onTypeSelected = ({ target: { value } }) => {
    updateSelectedType(value)
  }

  const updateSelectedType = value => {
    newAttTypeSelected(value)
    dispatch(onLoadAttachments(patientId, value))
    setSelectedType(value)
  }

  return (
    <div>
      <DevXTitleHeader title="NextGen Attachments" />

      <div className={classes.selectContainer}>
        <AttachmentTypeSelector
          className={classes.spacer}
          selected={selectedType}
          handleChange={onTypeSelected}
          types={attachmentTypes}
        />
        <AttachmentsFilter handleChange={onFilterChange} />
      </div>

      {attachments !== undefined && (
        <AttachmentsTable
          attachmentTypes={attachmentTypes}
          attachments={attachments}
          filter={filter}
          getAttachmentDocument={getAttachmentDocument}
        />
      )}
    </div>
  )
}

AttachmentWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  newAttTypeSelected: PropTypes.func.isRequired,
  attachmentTypes: PropTypes.map,
  attachments: PropTypes.map,
  patientId: PropTypes.string,
  onLoad: PropTypes.func,
  getAttachmentDocument: PropTypes.func,
}

const mapStateToProps = state => ({
  attachments: getAttachmentsArray(state),
  attachmentTypes: getAttachmentTypes(state),
})

const mapDispatchToProps = {
  newAttTypeSelected: newAttTypeSelected,
  onLoad: onLoadAttachments,
  getAttachmentDocument: getAttachmentDocumentById.requested,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentWidget)
