import 'react-big-calendar/lib/css/react-big-calendar.css'

import cx from 'classnames'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import { useSelector } from 'react-redux'
import { getRoute } from '~/data/route'
import { useAction, useMountEffect } from '~/hooks'
import { utcToLocal } from '~/utils/dates'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import {
  calendarInitialized,
  dateSet,
  getDate,
  getTimeZone,
} from '../../data/calendar'
import { TimeZoneNote } from '../../data/common/shared'
import { currentEventSet, getEvents } from '../../data/events'
import { eventTypes } from '../EventTypes'
import { recordTypeToEventType } from '../EventTypes/consts'
import CalendarToolbar from './CalendarToolbar'
import CustomEvent from './CustomEvent'
import DayHeaderText from './DayHeaderText'
import DayWrapperStriped from './DayWrapperStriped'

const FULL_HEIGHT_VIEWS = ['month', 'agenda']

const useStyles = makeStyles(({ palette, spacing }) => {
  const BORDER_RADIUS = 6
  const border = [1, 'solid', palette.action.selected]

  return {
    calendar: {
      fontFamily: 'Roboto, sans-serif',
      height: 'calc(100% - 40px)',

      // Outer border
      '& .rbc-month-view, .rbc-time-view, .rbc-agenda-view': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        border,
        borderRadius: BORDER_RADIUS,
      },

      // Hide background color of header cells
      '& .rbc-month-view, .rbc-time-view': {
        overflow: 'hidden',
      },

      // Month View
      '& .rbc-month-view': {
        '& .rbc-month-header .rbc-header': {
          borderBottom: 'none',
        },

        '& .rbc-month-header .rbc-header:first-child': {
          borderLeft: 'none',
        },

        '& .rbc-off-range-bg': {
          backgroundColor: palette.divider,
        },

        '& .rbc-month-row': {
          borderTop: border,
        },

        '& .rbc-month-row .rbc-row-bg .rbc-day-bg:first-child': {
          borderLeft: 'none',
        },

        '& .rbc-show-more': {
          padding: [4, 6],
        },

        '& .rbc-selected': {
          backgroundColor: palette.common.white,
        },
      },

      // Week/Day View
      '& .rbc-week-view, .rbc-time-view': {
        overflowY: 'scroll',

        '& div.rbc-day-slot.rbc-time-column': {
          borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
        },
        '& div.rbc-day-slot.rbc-time-column:nth-child(2)': {
          borderLeft: border,
        },
        '& .rbc-day-slot': {
          // Time Slots
          '& .rbc-timeslot-group': {
            minHeight: 106,
          },
          '& .rbc-time-slot': {
            borderTop: 'initial',

            '&:nth-of-type(odd)': {
              backgroundColor: palette.action.hover,
            },
          },

          // Events
          '& .rbc-events-container': {
            margin: 0,
          },
          '& .rbc-event': {
            overflow: 'initial',
            border: 'initial',
            backgroundColor: 'initial',
            padding: 0,
          },

          '& .rbc-event-label': {
            display: 'none',
          },
        },

        // Headers
        '& .rbc-time-gutter .rbc-timeslot-group, .rbc-time-header-gutter': {
          backgroundColor: palette.background.default,
        },

        '& .rbc-time-header-cell > .rbc-header': {
          borderLeft: border,
        },

        '& .rbc-time-header-content': {
          borderLeft: 'initial',
        },

        '& .rbc-timeslot-group:not(:nth-last-child(2))': {
          borderBottom: border,
        },

        '& .rbc-timeslot-group:nth-last-child(2)': {
          borderBottom: 'initial',
        },

        '& .rbc-time-column:not(:first-child)': {
          borderLeft: border,
        },

        '& .rbc-time-content': {
          borderTop: border,
        },

        '& .rbc-time-content > * + * > *': {
          borderLeft: 'initial',
        },

        // Side gutter
        '& .rbc-time-gutter': {
          '& .rbc-timeslot-group': {
            minHeight: 106,
          },
          '& .rbc-timeslot-group:not(:last-child)': {
            borderBottom: border,
          },

          '& .rbc-timeslot-group:last-child': {
            borderBottom: 'initial',
          },
        },

        // Current Time
        '& .rbc-current-time-indicator': {
          backgroundColor: palette.secondary.dark,
        },
      },

      // Agenda View
      '& .rbc-agenda-view': {
        flex: '0 1 auto',

        '& .rbc-agenda-empty': {
          padding: spacing(3),
        },

        '& .rbc-agenda-table': {
          border: 'initial',

          '& .rbc-agenda-time-cell': {
            paddingLeft: 10,
          },

          '& thead > tr > th': {
            borderBottom: border,
            paddingTop: 4,
          },

          '& tbody > tr > td + td': {
            borderLeft: border,
          },

          '& tbody > tr + tr': {
            borderTop: border,
          },
        },
        '& .rbc-header': {
          paddingLeft: 10,
        },
      },

      // NON VIEW-SPECIFIC CLASSES

      '& .rbc-day-bg': {
        borderLeft: border,
      },

      // Headers
      '& .rbc-header': {
        backgroundColor: palette.background.default,
        borderBottom: border,
        height: 'fit-content',
        padding: 0,
        '& div': {
          paddingBottom: 4,
          paddingTop: 5,
        },
        '& span': {
          display: 'block',
          paddingBottom: 4,
          paddingTop: 5,
        },
      },

      '& .rbc-header + .rbc-header': {
        borderLeft: border,
      },

      // All Day Cell
      '& .rbc-allday-cell': {
        height: 'initial',
      },

      // Current day
      '& .rbc-today': {
        backgroundColor: 'rgba(25, 118, 210, 0.25)',
      },
    },
    eventPropGetter: {
      backgroundColor: 'initial',
    },
    slotPropGetterGrey: {
      backgroundColor: 'rgba(204, 219, 207, 1) !important',
    },
    slotPropGetterWhite: {
      backgroundColor: 'rgba(212, 229, 215, 1) !important',
    },
    availability: {
      // position: 'relative',
      // bottom: 30,
      // float: 'left',
      // width: '100% !important',
      // zIndex: 0,
    },
    unavailability: {
      // left: '0 !important',
      // width: '100% !important',
      // zIndex: 100,
    },
    'scheduling-guidance': {
      // left: '0 !important',
      // width: '100% !important',
      // zIndex: 100,
    },
    'on-call': {
      // left: '0 !important',
      // width: '100%',
      // zIndex: 1000,
    },
    '@global': {
      '.rbc-overlay': {
        border: '3px solid #e5e5e5',
        borderRadius: 8,
        padding: [10, 10, 7],

        '& .rbc-overlay-header': {
          marginBottom: 7,
          padding: [8, 10],
        },
        '& .rbc-event': {
          padding: [3, 0],
        },
      },
    },
  }
})

const eventOrder = (a, b) => {
  const aLayer = eventTypes[recordTypeToEventType[a.recordType]]
    ? eventTypes[recordTypeToEventType[a.recordType]].layer
    : 0

  const bLayer = eventTypes[recordTypeToEventType[b.recordType]]
    ? eventTypes[recordTypeToEventType[b.recordType]].layer
    : 0

  return aLayer - bLayer
}

const getTime = date => ({ hour: date.hour(), minute: date.minute() })
const timeToHours = ({ hour, minute }) => hour + minute / 60

const localizer = momentLocalizer(moment)

const Calendar = ({
  dayHeader: DayHeaderComponent,
  dayWrapper: DayWrapperComponent,
  defaultView,
  hideAdd,
  max,
  min,
  step,
  timeslots,
  timeZoneNotes,
  viewLocked,
  width,
}) => {
  const classes = useStyles()

  const events = useSelector(getEvents)
  const timeZone = useSelector(getTimeZone)
  const date = useSelector(getDate)
  const currentRoute = useSelector(getRoute)
  const onDateChange = useAction(dateSet)
  const onSelectEvent = useAction(currentEventSet)
  const initializeCalendar = useAction(calendarInitialized)

  const [view, setView] = useState(defaultView)

  const formats = {
    dateFormat: 'D',
    dayHeaderFormat: 'ddd MMM D',
    dayRangeHeaderFormat: ({ start, end }, _culture, localizer) =>
      localizer.format(start, 'MMM D') + ' - ' + localizer.format(end, 'MMM D'),
    agendaHeaderFormat: ({ start, end }, _culture, localizer) =>
      localizer.format(start, 'M/D/YY') +
      ' - ' +
      localizer.format(end, 'M/D/YY'),
  }

  const editEvents = events => {
    const availability = events.filter(
      event =>
        event.type !== 'Availability' ||
        (event.type === 'Availability' && event.allDay)
    )

    return view === 'month' ? events : availability
  }

  const calendarEvents = useMemo(() => {
    const editedEvents = editEvents(events.toArray())
    return editedEvents.sort(eventOrder)
  }, [events, view])

  const calendarMinMax = useMemo(() => {
    const rangedEvents = events.filterNot(event => event.allDay)
    const starts = rangedEvents
      .map(event => utcToLocal(event.start, timeZone))
      .set('default', min)
    const ends = rangedEvents
      .map(event => utcToLocal(event.end, timeZone))
      .set('default', max)
    const minStartTime = starts.map(getTime).minBy(timeToHours)
    const maxEndTime = ends.map(getTime).maxBy(timeToHours)

    return {
      min: moment(minStartTime).startOf('hour').toDate(),
      max: moment(maxEndTime).endOf('hour').toDate(),
    }
  }, [events, timeZone, min, max])

  const DayHeader = props => (
    <DayHeaderComponent {...props} currentDate={date} />
  )

  const DayWrapper = props => <DayWrapperComponent {...props} step={step} />

  const ExpandedEvent = props => <CustomEvent {...props} expanded view={view} />

  const NonExpandedEvent = props => (
    <CustomEvent {...props} expanded={false} view={view} />
  )

  const Toolbar = props => (
    <CalendarToolbar
      {...props}
      currentRoute={currentRoute}
      hideAdd={hideAdd}
      viewLocked={viewLocked}
      timeZoneNotes={timeZoneNotes}
      width={width}
    />
  )

  const components = {
    toolbar: Toolbar,
    event: ExpandedEvent,
    day: { event: ExpandedEvent, header: DayHeader },
    week: { event: ExpandedEvent, header: DayHeader },
    month: { event: NonExpandedEvent },
    dayWrapper: DayWrapper,
  }

  const accessDate = (dateField, dateTimeField) => event =>
    event.allDay
      ? moment(event[dateField]).toDate()
      : utcToLocal(event[dateTimeField], timeZone).toDate()

  const startAccessor = accessDate('startDate', 'start')
  const endAccessor = accessDate('endDate', 'end')

  const eventPropGetter = event => {
    const eventType = event.recordType.replace('_', '-')

    return {
      className: cx(classes.eventPropGetter, classes[eventType]),
    }
  }

  const calendarAvailabilities = useMemo(
    () =>
      events
        .toArray()
        .filter(event => event.recordType === 'availability' && !event.allDay),
    [events]
  )

  const slotPropGetter = date => {
    const [availability] = calendarAvailabilities.filter(availability =>
      moment(date).isSame(availability.startDate, 'day')
    )

    if (availability) {
      const availabilityStart = utcToLocal(availability.start, timeZone)
      const availabilityEnd = utcToLocal(availability.end, timeZone)

      if (
        moment(date).isBetween(
          availabilityStart,
          availabilityEnd,
          'minute',
          '[)'
        ) &&
        (moment(date).minutes() === 0 || moment(date).minutes() === 30)
      ) {
        return {
          className: classes.slotPropGetterGrey,
        }
      } else if (
        moment(date).isBetween(
          availabilityStart,
          availabilityEnd,
          'minute',
          '[)'
        )
      ) {
        return {
          className: classes.slotPropGetterWhite,
        }
      }
    }
  }

  const onSelectSlot = ({ start, end }) => {
    const [availability] = calendarAvailabilities.filter(
      availability =>
        moment(start).isBetween(
          utcToLocal(availability.start, timeZone),
          utcToLocal(availability.end, timeZone),
          'minute',
          '[)'
        ) &&
        moment(end).isBetween(
          utcToLocal(availability.start, timeZone),
          utcToLocal(availability.end, timeZone),
          'minute',
          '(]'
        )
    )

    if (availability) {
      onSelectEvent(availability)
    }
  }

  useMountEffect(() => {
    initializeCalendar()
  })

  return (
    <div className={classes.calendar}>
      <BigCalendar
        style={
          FULL_HEIGHT_VIEWS.includes(view) ? { height: '100vh' } : undefined
        }
        localizer={localizer}
        components={components}
        selectable
        popup
        events={calendarEvents}
        formats={formats}
        date={date.toDate()}
        defaultView={defaultView}
        view={view}
        onView={viewLocked ? () => {} : setView}
        timeslots={timeslots}
        step={step}
        min={calendarMinMax.min}
        max={calendarMinMax.max}
        onNavigate={onDateChange}
        onDateChange={onDateChange}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onSelecting={() => false}
        allDayAccessor={event => event.allDay}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        eventPropGetter={eventPropGetter}
        slotPropGetter={slotPropGetter}
        dayLayoutAlgorithm="no-overlap"
      />
    </div>
  )
}

Calendar.propTypes = {
  dayHeader: PropTypes.elementType,
  dayWrapper: PropTypes.elementType,
  defaultView: PropTypes.string,
  hideAdd: PropTypes.bool,
  max: PropTypes.instanceOf(moment),
  min: PropTypes.instanceOf(moment),
  step: PropTypes.number,
  timeslots: PropTypes.number,
  timeZoneNotes: PropTypes.arrayOf(TimeZoneNote),
  viewLocked: PropTypes.bool,
  width: PropTypes.number,
}

Calendar.defaultProps = {
  dayHeader: DayHeaderText,
  dayWrapper: DayWrapperStriped,
  defaultView: 'week',
  max: moment().startOf('day').hour(19),
  min: moment().startOf('day').hour(6),
  step: 15,
  timeslots: 4,
}

export default Calendar
