import createReducer from '~/utils/createReducer'
import { get, replace, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import TASKS from '../key'
import { tomorrow } from '../utils/date'
import { getRoot } from './common'
import { snoozeTask } from './tasks'

// Key
const NEW_DUE_DATE = 'newDueDate'
const creator = scopedCreator(`${TASKS}/${NEW_DUE_DATE}`)

// Actions
export const newDueDateSelected = creator('NEW_DUE_DATE_SELECTED', [
  'newDueDate',
])

// Reducer
export default createReducer(NEW_DUE_DATE, tomorrow(), {
  [newDueDateSelected]: replace('newDueDate'),
  [snoozeTask.SUCCEEDED]: tomorrow,
})

// Selector
export const getNewDueDate = compose(get(NEW_DUE_DATE), getRoot)
