import { scopedCreator } from '~/utils/data'
import CALLS from '../key'

const creator = scopedCreator(CALLS)

export const logCallStartedActionCreator = creator(
  'STARTED',
  ['patientId', 'type'],
  () => ({
    message: 'Loading New Call Form',
  })
)

const logCallStarted = type => patientId =>
  logCallStartedActionCreator(patientId, type)

// Actions
export const logCMRNCallStarted = logCallStarted('cmrn_call')
export const logInboundCallStarted = logCallStarted('inbound_call')
export const logOnCallStarted = logCallStarted('on_call_assessment')
export const logTriageCallStarted = logCallStarted('triage_call')
