import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import {
  carePlanSet,
  mapAssessmentCarePlan,
  problemsInitialized,
} from '~/features/problems/data'
import { assessmentHasCarePlan } from '~/features/problems/utils'
import { fetchEncounterReview } from '../data/currentEncounterReview'

export default (action$: any) =>
  action$.pipe(
    ofType(fetchEncounterReview.SUCCEEDED),
    pluck('payload'),
    filter(assessmentHasCarePlan),
    mergeMap(encounterReview => {
      const { carePlan, legacy } = mapAssessmentCarePlan(encounterReview)

      return of(problemsInitialized(), carePlanSet(carePlan, legacy))
    })
  )
