import React from 'react'
import JsonForm, { getTaggedValue } from '~/components/JsonForm'
import { saveableObjectFieldWithRequest } from '~/components/JsonForm/fields/SaveableObjectField'
import SyncContactsField from '~/components/JsonForm/fields/SyncContactsField'
import PropTypes from '~/utils/propTypes'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Cancel, Done, Person, Save, Snooze } from '@material-ui/icons'
import { CANCEL, COMPLETE, REASSIGN, SNOOZE } from '../constants'
import {
  logCallFromTask,
  saveNoteFromTask,
  sendNotificationFromTask,
} from '../data/tasks'
import { createRNAssessment } from './fields'

class TaskForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dialogOpened: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    syncContactsPending: PropTypes.bool.isRequired,
    reassignTaskDialogOpened: PropTypes.func.isRequired,
    task: PropTypes.record.isRequired,
    onSyncContacts: PropTypes.func.isRequired,
  }

  SaveableCall = saveableObjectFieldWithRequest(
    logCallFromTask,
    this.props.onSave
  )
  SaveableNote = saveableObjectFieldWithRequest(
    saveNoteFromTask,
    this.props.onSave
  )
  SaveableNotification = saveableObjectFieldWithRequest(
    sendNotificationFromTask,
    this.props.onSave
  )

  onChange = ({ errors, formData }) =>
    this.props.onChange(formData, Boolean(errors.length))

  getFormDataByTag = (_state, tag, opts) =>
    getTaggedValue(this.props.task.form, tag, opts)

  openDialog = event => this.props.dialogOpened(event.currentTarget.value)
  openCompleteDialog = () => this.props.dialogOpened(COMPLETE)

  openReassignTaskDialog = event => {
    const {
      task: { patientId },
      reassignTaskDialogOpened,
    } = this.props

    this.openDialog(event)
    reassignTaskDialogOpened(patientId)
  }

  render() {
    const {
      classes,
      onSave,
      onError,
      readOnly,
      syncContactsPending,
      onSyncContacts,
      task: { form },
    } = this.props

    const required_notification =
      form.context.taskDetails.taskDefinitionKey ===
      'medical_records_mail_request'

    const required_task_note =
      form.context.taskDetails.taskDefinitionKey ===
      'red_folder_document_request'

    const task_notes_present = Boolean(
      form.formData.taskNote?.taskNoteArray[0]?.itemSaved.saved
    )

    const notifications_present = Boolean(
      form.formData.userNotification?.userNotificationArray[0]?.itemSaved.saved
    )

    return syncContactsPending ? (
      <CircularProgress />
    ) : (
      <JsonForm
        formRef={e => {
          this.form = e
        }}
        debounce
        context={form.context}
        formData={form.formData}
        getFormDataByTag={this.getFormDataByTag}
        onSubmit={this.openCompleteDialog}
        onError={onError}
        onChange={this.onChange}
        schema={form.schema}
        uiSchema={{
          ...form.uiSchema,
          'ui:disabled': readOnly,
        }}
        fields={{
          saveableCall: this.SaveableCall,
          saveableNote: this.SaveableNote,
          saveableNotification: this.SaveableNotification,
          createRNAssessment: () => {
            return createRNAssessment(form.context)
          },
          syncContactsButton: fieldProps => {
            return SyncContactsField({ ...fieldProps, onSyncContacts })
          },
        }}
        compact
      >
        {!readOnly && (
          <React.Fragment>
            {!form.errored && required_task_note && !task_notes_present && (
              <Typography color="error" variant="body1">
                This form requires at least one task note.
              </Typography>
            )}
            {!form.errored && required_notification && !notifications_present && (
              <Typography color="error" variant="body1">
                This form requires at least one notification.
              </Typography>
            )}
            {form.errored && (
              <Typography color="error" variant="body1">
                Errors exist on this form.
              </Typography>
            )}
            <Button
              className={classes.button}
              disabled={form.saved}
              onClick={onSave}
              variant="outlined"
            >
              <Save className={classes.icon} />
              Save
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => {
                this.form.submit()
              }}
              disabled={
                form.errored ||
                readOnly ||
                (required_task_note && !task_notes_present) ||
                (required_notification && !notifications_present)
              }
              variant="contained"
            >
              <Done className={classes.icon} />
              Complete
            </Button>
            <Button
              disabled={readOnly}
              onClick={this.openReassignTaskDialog}
              value={REASSIGN}
            >
              <Person className={classes.icon} />
              Reassign
            </Button>
            <Button
              disabled={readOnly}
              onClick={this.openDialog}
              value={SNOOZE}
            >
              <Snooze className={classes.icon} />
              Snooze
            </Button>
            <Button
              className={classes.cancel}
              disabled={readOnly}
              onClick={this.openDialog}
              value={CANCEL}
            >
              <Cancel className={classes.icon} />
              Cancel
            </Button>
          </React.Fragment>
        )}
      </JsonForm>
    )
  }
}

const styles = ({ spacing }) => ({
  button: { marginLeft: spacing(1) },
  icon: { marginRight: spacing(1) },
})

export default withStyles(styles)(TaskForm)
