import { Map, Collection, Record as ImmutableRecord, Iterable } from 'immutable'
import { pipe } from './functionalHelpers'

type Action<Payload = any, Meta = Record<string, any>> = {
  type: string
  payload?: Payload
  error?: boolean
  meta?: Meta
}

// Create an action type.
export const typeCreator = (mainKey: string) => (key: string) =>
  `${mainKey}/${key}` // DEPRECATED
export const type = (key: string, name: string) => `${key}/${name}` // DEPRECATED

/**
 * Create a function to create an object with positionally set argument labels.
 *
 * @param {...string} labels - the labels for the positional arguments
 * @returns {function(...*):Object} a function that creates an object by
 * positionally labeling the arguments
 */
export const positional = (...labels: string[]) => (
  ...values: any[]
): Record<string, unknown> =>
  labels.reduce(
    (payload, label, index) => ({ ...payload, [label]: values[index] }),
    {}
  )

/**
 * A function that creates an action
 * @typedef {function(...*):Action} ActionCreator
 * @param {...*} args - values to be handled by the action
 * @returns {Action} the action with the provided values
 */

/**
 * The set of options for an action creator
 * @typedef {(boolean|function|string[])} creatorParamOption
 */

/**
 * Get an action param handler based on the option provided
 *
 * * a function argument returns itself
 * * an array returns  the positional with it spread as arguments
 * * false returns undefined
 * * default returns an identity function
 *
 * @param {creatorParamOption=} option - the option to use to get the handler
 * @returns {function|undefined} a function to handle a param or undefined if it
 * should be ignored
 */
type Option = boolean | ((...args: any[]) => any) | Array<any> | undefined
const handleOption = (option: Option) => {
  if (option === false) return undefined
  if (typeof option === 'function') return option
  if (Array.isArray(option)) return positional(...option)
  if (option !== undefined && option !== true) {
    console.warn('Unexpected creator option:', option)
  }
  return <T>(v: T) => v
}

type ActionCreator = (...args: any[]) => Action

/**
 * Constructs an action creator
 *
 * @param {string} type - an action type
 * @param {creatorParamOption=} payloadOption - the option for the payload
 * @param {creatorParamOption} [metaOption=false] - the option for the meta
 * @returns {ActionCreator} the constructed action creator
 */
export const actionCreator = (
  type: string,
  payloadOption?: Option,
  metaOption: Option = false
): ActionCreator => {
  const payloadHandler = handleOption(payloadOption)
  const metaHandler = handleOption(metaOption)

  const creatorFunction = (...params: any[]) => {
    const payload = payloadHandler ? payloadHandler(...params) : undefined
    const meta = metaHandler ? metaHandler(...params) : undefined
    const error = params[0] instanceof Error

    return {
      type,
      ...(payload !== undefined && { payload }),
      ...(meta !== undefined && { meta }),
      ...(error && { error }),
    }
  }

  creatorFunction.toString = () => type
  return creatorFunction
}

/**
 * Scopes an action creator to a key
 *
 * @returns {function} a creator that combines its type argument with the key
 */
export const scopedCreator = (key: string): typeof actionCreator => (
  typeName: string,
  ...params: any[]
) => {
  const fullType = `${key}/${typeName}`
  return actionCreator(fullType, ...params)
}

// Create an action creator.
export const creator = (type: string, ...argNames: any[]) =>
  creatorBuilder(type, argNames) // DEPRECATED

export const messageCreator = (
  type: string,
  message: string,
  messageType: any
) => creatorBuilder(type, [], { meta: { message, messageType } })

export const snackbarMessageCreator = (
  key: string,
  typeName: string,
  message: any,
  messageType: any
) => {
  return scopedCreator(key)(typeName, false, () => ({
    message,
    messageType,
  }))
}

export const errorCreator = (type: string, message: string) =>
  creatorBuilder(type, [], { error: true, meta: { message } })

export const apiCreator = (type: string, ...argNames: string[]) =>
  creatorBuilder(type, argNames, { meta: { isApiRequest: true } })

export const apiErrorCreator = (type: string, message: string) =>
  creatorBuilder(type, [], {
    error: true,
    meta: { message, isApiRequest: true },
  })

const creatorArgs = (argNames: string[], args: any[]) =>
  argNames.length
    ? argNames.reduce(
        (acc, name, index) => ({ ...acc, [name]: args[index] }),
        {}
      )
    : null

const creatorBuilder = (
  type: string,
  argNames: string[],
  { meta, error }: { meta?: any; error?: boolean } = {}
) => {
  const creatorFunction = (...args: any[]) => {
    const payload = error ? args[0] : creatorArgs(argNames, args)
    return { type, payload, meta, error }
  }

  creatorFunction.toString = () => type
  return creatorFunction
}

// Extract payload from an action.
export const payload = <P>({ payload }: { payload: P }) => payload

// Extract meta from an action.
export const meta = <M>({ meta }: { meta: M }) => meta

// Get whether an action is in an errored state.
export const isError = ({ error }: { error?: boolean }) => error === true

// Extract the action from a reducer.
export const action = <A>(_state: any, action: A) => action

// Create a reducer that replaces state with a value from an action's payload.
export const replace = <S extends Map<K, V>, K extends number | string, V>(
  key: K
) => (_state: S, { payload: { [key]: newState } }: { payload: Record<K, S> }) =>
  newState

// Create a reducer that merges state with a value from an action's payload.
export const merge = <S extends Map<K, V>, K extends number | string, V>(
  key: K
) => (state: S, { payload: { [key]: newState } }: { payload: Record<K, S> }) =>
  state.merge(newState)

// Create a reducer that sets state with a value from an action's payload.
export const set = <S extends Map<K, V>, K extends number | string, V>(
  getKey: (state: V) => K,
  key: K
) => (state: S, { payload: { [key]: newState } }: { payload: Record<K, V> }) =>
  state.set(getKey(newState), newState)

// Create a selector that gets a part of state using a key.
export const get = <S extends Map<K, V>, K, V>(key: K, notSetValue?: V) => (
  state: S
) => state.get(key, notSetValue)

// Create a selector that gets a nested part of state using an array of keys.
export const getIn = <S extends Map<K, V>, K, V>(
  keys: K[],
  notSetValue?: V
) => (state: S) => state.getIn(keys, notSetValue)

// Convert an array of objects to an Immutable.Map, using a transform function
// and a key for each object.
export const into = <
  K extends number | string,
  V,
  Data extends Iterable<K, V>,
  Container extends Collection.Keyed<K, V>,
  Item extends { [k in K]: V }
>(
  transform: ((d: Data) => Item) | ImmutableRecord.Class,
  key: K,
  container: (args: any) => Collection.Keyed<K, V> = Map
) => (data: Data[]): Container =>
  (container(
    data.map(pipe(transform, (item: Item) => [item[key], item]))
  ) as unknown) as Container

// Convert Base 64 string into Blob object
export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
