import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { FORMS_DEVELOPMENT_FORM } from '../router'
import { getFormsDevelopment } from './common/shared'

const key = 'entity'

const reducer = paramsReducer(
  FORMS_DEVELOPMENT_FORM,
  ({ entity }: any) => entity
)

reducer.key = key

export const getEntity = pipe(getFormsDevelopment, get(key))

export default reducer
