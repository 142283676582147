import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
import { fetchPatientInfo } from '~/features/scheduling/data/patientInfo'
import { fetchAttachmentTypes } from '../data/attachmentTypes'
import { fetchPatientAttachments } from '../data/currentAttachments'
import { ATTACHMENTS_LOAD } from '../data/newAttachment'

export default action$ =>
  action$.pipe(
    ofType(ATTACHMENTS_LOAD),
    pluck('payload', 'patientId'),
    mergeMap(patientId => [
      fetchPatientAttachments.requested(patientId),
      fetchPatientInfo.requested(patientId),
      fetchAttachmentTypes.requested(),
    ])
  )
