import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import {
  editCareTeamPod,
  fetchCareTeamPods,
  saveNewCareTeamPod,
} from '../data/careTeamPods'

export const addCareTeamPodEpic = (action$: any) =>
  action$.pipe(
    ofType(saveNewCareTeamPod.SUCCEEDED),
    map(() => fetchCareTeamPods.requested())
  )

export const editCareTeamPodEpic = (action$: any) =>
  action$.pipe(
    ofType(editCareTeamPod.SUCCEEDED),
    map(() => fetchCareTeamPods.requested())
  )
