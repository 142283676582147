import { Map } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'

// CONSTANTS
export const key = 'currentOrder'

// ACTIONS
export const SET_TAB = type(key, 'SET_TAB')
export const TAB_CHANGED = type(key, 'TAB_CHANGED')
// ACTION CREATORS
export const tabChanged = creator(TAB_CHANGED, 'value')
// REDUCERS
export default createReducer(
  key,
  Map({
    orderId: null,
    isLegacy: false,
  }),
  {
    [TAB_CHANGED]: (state, { payload: { value } }) =>
      state.set('isLegacy', value === 1),
  }
)
