import { eq, get } from 'lodash/fp'
import { filter, mapTo, pluck } from 'rxjs/operators'
import { saveAssessment } from '~/apps/assessment/data/assessments'
import { loggedOut } from '~/data/session'
import { isUnauthenticatedRequestError } from '~/errors/UnauthenticatedRequestError'
import { compose } from '~/utils/functionalHelpers'
import { reject } from '~/utils/operators'

export default action$ =>
  action$.pipe(
    filter(get(['error'])),
    reject(compose(eq(saveAssessment.key), get(['meta', 'request', 'key']))),
    pluck('payload'),
    filter(isUnauthenticatedRequestError),
    mapTo(loggedOut())
  )
