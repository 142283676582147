import { List, Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { fetchConfig } from './config'

const DISPOSITIONS = 'dispositions'

const Dispositions = Record({
  patientDispositions: List(),
  stakeholderDispositions: List(),
})

export default createReducer(DISPOSITIONS, Dispositions(), {
  [fetchConfig.SUCCEEDED]: (
    _state,
    { payload: { patientDispositions, stakeholderDispositions } }
  ) => Dispositions({ patientDispositions, stakeholderDispositions }),
})

const getDispositions = pipe(getRoot, get(DISPOSITIONS))
export const getPatientDispositions = pipe(
  getDispositions,
  get('patientDispositions')
)
export const getStakeholderDispositions = pipe(
  getDispositions,
  get('stakeholderDispositions')
)
