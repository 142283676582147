import { ProgramNoUnionURI } from '@morphic-ts/batteries/lib/program-no-union'
import * as Summoner from '@morphic-ts/batteries/lib/summoner-ESBST'
import { ProgramType } from '@morphic-ts/summoners'

// import { IoTsURI } from '@morphic-ts/io-ts-interpreters/lib/hkt'
// import { FastCheckURI } from '@morphic-ts/fastcheck-interpreters/lib/hkt'
// import { ShowURI } from '@morphic-ts/show-interpreters/lib/hkt'
// import { EqURI } from '@morphic-ts/eq-interpreters/lib/hkt'
// import { JsonSchemaURI } from '@morphic-ts/json-schema-interpreters/lib/hkt'

export * from '@morphic-ts/adt'
export * from '@morphic-ts/summoners'

const { summonFor, AsOpaque, AsUOpaque } = Summoner

export interface AppEnv {} // eslint-disable-line @typescript-eslint/no-empty-interface
// IoTsURI: Configure IoTs instances here...
// FastCheckURI: Configure FastCheck instances here...
// ShowURI: Configure Show instances here...
// EqURI: Configure Eq instances here...
// JsonSchemaURI: Configure JsonSchema instances here...
// }

const { summon, tagged } = summonFor<AppEnv>({})
export type M<E, A> = Summoner.M<AppEnv, E, A>
export type UM<A> = Summoner.UM<AppEnv, A>

export { AsOpaque, AsUOpaque, summon, tagged }

export type Program<E, A> = ProgramType<AppEnv, E, A>[ProgramNoUnionURI]

// NOTE: Aliases for educational purposes and to hide library details.
export const Variant = tagged('type')

export interface Brand<Type> {
  readonly type: Type
}

// Consider replacing this with the Record morph below.
export interface ReduxAction<Tag extends string, Payload = undefined>
  extends Brand<Tag> {
  payload: Payload
}

// Helper for declaring a branded record.
export const Ctor = <Tag extends string, Raw, Payload>(
  k: Tag,
  payload: Program<Raw, Payload>
): M<Brand<string> & { payload: Raw }, Brand<Tag> & { payload: Payload }> =>
  summon<Brand<string> & { payload: Raw }, Brand<Tag> & { payload: Payload }>(
    F =>
      F.interface(
        {
          type: F.stringLiteral(k),
          payload: payload(F),
        },
        k
      )
  )

export const Ctor_ = <Tag extends string>(
  k: Tag
): M<Brand<string>, Brand<Tag>> =>
  summon<Brand<string>, Brand<Tag>>(F =>
    F.interface(
      {
        type: F.stringLiteral(k),
      },
      k
    )
  )

// HELPERS

// NOTE: The type checker seems not to like the lens-y version of this... :man_shrugging:
// something to do with having to bring in the Generics as a continuation?
// const payload = <C, P extends {payload: C}>() =>
//   new Lens(
//     ({ payload }: P) => payload,
//     (payload: C) => (s: P) => ({...s, payload})
//   )
//
// So defining the raw functions seems to be a happier path.
export const payloadGet = <C, P extends { payload: C }>({ payload }: P) =>
  payload
// Uncomment if needed
// const payloadSet = <C, P extends {payload: C}>(payload: C) => ({ payload } as P)

// Terser version if using the above gets too tedious
// NOTE: Feels like this is just a generic payload lens getter? Not sure this should even exist...
// export const reduce = <A, B>(reducer: (_: A) => B) =>
//   ({ payload }: { payload: A }) => reducer(payload)
