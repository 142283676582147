import React from 'react'
import { Field } from 'redux-form/immutable'
import { TextField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { required } from '../../../utils/formValidators'
import FormFieldWrapper from './FormFieldWrapper'

const styles = {
  formSpan: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  halfWidth: {
    width: '48%',
  },
  fullWidth: {
    width: '100%',
  },
}

class PatientInfoFields extends React.PureComponent {
  // NOTE(chris): using PureComponent to reduce rerenders from redux-form
  render() {
    const { classes, pendingStatus } = this.props

    return (
      <FormFieldWrapper title="Prescription Information" isCollapsed={false}>
        {pendingStatus && (
          <React.Fragment>
            <span className={classes.formSpan}>
              <Field
                component={TextField}
                className={classes.halfWidth}
                label="Prescriber"
                name="prescriber"
                disabled
                validate={[required]}
              />
              <Field
                component={TextField}
                className={classes.halfWidth}
                label="Prescribing Location"
                name="prescribingLocation"
                disabled
                validate={[required]}
              />
            </span>
            <Field
              component={TextField}
              className={classes.fullWidth}
              label="Preferred Pharmacy"
              name="pharmacy"
              disabled
              validate={[required]}
            />
          </React.Fragment>
        )}
        <Field
          component={TextField}
          className={classes.fullWidth}
          label="Internal Note - INTERNAL USE ONLY"
          name="internalNote"
        />
      </FormFieldWrapper>
    )
  }
}

PatientInfoFields.propTypes = {
  classes: PropTypes.object.isRequired,
  pendingStatus: PropTypes.bool.isRequired,
}
export default withStyles(styles)(PatientInfoFields)
