import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  tocRisk: {
    color: palette.background.default,
    backgroundColor: ({ value }) => {
      const colors = {
        Bronze: '#CD7F32',
        Copper: '#B87333',
        Gold: '#FFD700',
        Green: palette.severity.success.dynamic,
        Red: palette.severity.error.dynamic,
        Silver: '#C0C0C0',
        Steel: '#43464B',
        Yellow: palette.severity.warning.dynamic,
      }

      return colors[value] ? colors[value] : null
    },
  },
}))

const Alerts = props => {
  const { value } = props
  const classes = useStyles(props)

  return <Chip size="small" className={classes.tocRisk} label={value} />
}

Alerts.propTypes = {
  value: PropTypes.string,
}

export default Alerts
