import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import DisplayDate from '~/components/DisplayDate'
import WithTooltip from '~/components/WithTooltip'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import {
  ADD_LTC,
  createLtcDialogClosed,
  createLtcDialogOpened,
  getDialog,
} from '../data/createLtcDialog'
import { getLTCs, saveLTC } from '../data/ltcs'
import AddLTC from './AddLTC'
import DeleteLTC from './DeleteLTC'

const toArray = createSelector([ltcs => ltcs], ltcs =>
  ltcs.toIndexedSeq().toJS()
)

const columnExtensions = [
  { columnName: 'caseNumber', editingEnabled: false, width: 150 },
  { columnName: 'admissionDate', width: 150 },
  { columnName: 'dischargeDate', width: 175 },
  { columnName: 'createdBy', editingEnabled: false, width: 150 },
  { columnName: 'createdAt', editingEnabled: false, width: 150 },
]

const defaultSorting = [{ columnName: 'admissionDate', direction: 'desc' }]

const columns = [
  {
    name: 'caseNumber',
    title: 'Case Number',
    displayComponent: ({ value }) => value || 'Pending',
  },
  {
    name: 'admissionDate',
    title: 'Admission Date',
    displayComponent: DisplayDate,
    type: 'date',
  },
  {
    name: 'dischargeDate',
    title: 'Discharge Date',
    displayComponent: DisplayDate,
    type: 'date',
  },
  {
    name: 'facilityName',
    title: 'Facility Name',
    displayComponent: WithTooltip,
  },
  {
    name: 'notes',
    title: 'Notes',
  },
  {
    name: 'createdBy',
    title: 'Created By',
  },
  {
    name: 'createdAt',
    title: 'Created At',
    getCellValue: ({ createdAt }) =>
      moment(createdAt).format('MM/DD/YYYY hh:mmA'),
  },
]

const LTCs = ({ patientId, saveLTC, ltcs, onClose, createLtcDialogOpened }) => {
  const [mode, setMode] = useState()
  const [selectedLTC, setSelectedLTC] = useState()

  const onCommitChanges = useCallback(
    ({ changed, deleted }) => {
      if (changed) {
        const [ltcId, changes] = Object.entries(changed)[0]

        saveLTC(Number(ltcId), { ...ltcs.get(ltcId), ...changes })
      }
      if (deleted) {
        setSelectedLTC(ltcs.get(deleted[0]))
        setMode('delete')
      }
    },
    [ltcs, setMode, setSelectedLTC]
  )

  const clearState = useCallback(() => {
    setSelectedLTC()
    setMode()
    onClose()
  }, [setMode, setSelectedLTC, onClose])

  const onClick = useCallback(() => {
    createLtcDialogOpened(ADD_LTC, patientId)
    setMode('add')
  }, [patientId, createLtcDialogOpened])

  const dialog = useSelector(getDialog)

  return (
    <React.Fragment>
      <DevXTitleHeader
        action={onClick}
        buttonText="Add LTC Admission"
        title="LTC Admissions"
      />

      <Paper>
        <DevXGrid
          editable
          hideAdd
          onCommitChanges={onCommitChanges}
          rows={toArray(ltcs)}
          columns={columns}
          columnExtensions={columnExtensions}
          sortable
          defaultSorting={defaultSorting}
          getRowId={({ id }) => id}
        />
      </Paper>

      <AddLTC
        open={dialog.dialogType === ADD_LTC}
        patientId={dialog.patientId}
        onClose={clearState}
      />

      <DeleteLTC
        open={mode === 'delete'}
        row={selectedLTC}
        onClose={clearState}
      />
    </React.Fragment>
  )
}

LTCs.propTypes = {
  onClose: PropTypes.func.isRequired,
  ltcs: PropTypes.map.isRequired,
  patientId: PropTypes.string.isRequired,
  saveLTC: PropTypes.func.isRequired,
  createLtcDialogOpened: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  ltcs: getLTCs(state),
})

const mapDispatchToProps = {
  saveLTC: saveLTC.requested,
  onClose: createLtcDialogClosed,
  createLtcDialogOpened,
}

export default connect(mapStateToProps, mapDispatchToProps)(LTCs)
