import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Button, Divider, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  contactSelected,
  contactSkipped,
  getCallLog,
} from '../../../data/callLog'
import ContactInfo from './ContactInfo'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginRight: spacing(1),
  },
  icon: {
    marginRight: spacing(0.5),
  },
  divider: {
    margin: spacing(1, 0),
    gridColumnStart: 'span 3',
  },
}))

const Contact = ({ contact }) => {
  const classes = useStyles()

  const callLog = useSelector(getCallLog)
  const onSelectContact = useAction(contactSelected)
  const onSkipContact = useAction(contactSkipped)

  const attempts = useMemo(() =>
    callLog.dispositions.filter(
      disposition => disposition.phoneNumber === contact.phoneNumber
    )
  )

  const attempted = !attempts.isEmpty()
  const skipped = callLog.skippedContacts.has(contact.phoneNumber)

  return (
    <React.Fragment>
      <div>
        <ContactInfo
          attempted={attempted}
          skipped={skipped}
          contact={contact}
        />
      </div>

      <div>
        {attempted &&
          attempts.map((attempt, i) => (
            <Typography
              key={`${attempt.dispositionId}-${attempt.subDispositionId}-${i}`}
              variant="body2"
            >
              {attempt.subdisposition
                ? `${attempt.disposition} - ${attempt.subdisposition}`
                : attempt.disposition}
            </Typography>
          ))}
      </div>

      <div>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onSelectContact(contact)
          }}
        >
          <Icon className={classes.icon} fontSize="small">
            phone_enabled
          </Icon>
          {attempted ? 'Attempt Again' : 'Attempt'}
        </Button>

        {!attempted && (
          <Button
            disabled={skipped}
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => {
              onSkipContact(contact.phoneNumber)
            }}
          >
            <Icon className={classes.icon} fontSize="small">
              phone_disabled
            </Icon>
            {skipped ? 'Skipped' : 'Skip'}
          </Button>
        )}
      </div>

      <Divider className={classes.divider} />
    </React.Fragment>
  )
}

Contact.propTypes = {
  contact: PropTypes.record.isRequired,
}

export default Contact
