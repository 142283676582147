import _ from 'lodash/fp'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'

const formatPhone = phone =>
  phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

const makeLabel = (
  { typeLabel: contactTypeLabel, name, preferred },
  { phone, typeLabel: phoneTypeLabel, primary }
) =>
  [
    name,
    preferred && primary ? '(Preferred)' : '',
    contactTypeLabel,
    phoneTypeLabel,
    formatPhone(phone),
  ]
    .filter(Boolean)
    .join(' - ')

const sorter = (a, b) => {
  if (a.preferred) return -1
  if (b.preferred) return 1
  if (a.type == 'patient' && b.type != 'patient') return -1
  if (a.type != 'patient' && b.type == 'patient') return 1
  if (a.name && !b.name) return -1
  if (b.name && !a.name) return 1
  if (a.name && b.name) return a.name.localeCompare(b.name)
  return a.id - b.id
}

export default function PhoneContactField(props) {
  const { formContext, registry, schema, uiSchema } = props

  const contactsTag = _.getOr('patientContacts', ['ui:options', 'contactsTag'])(
    uiSchema
  )

  const contacts = useSelector(state =>
    formContext.getFormDataByTag(state, contactsTag)
  )

  const enumProps = useMemo(
    () =>
      pipe(
        _.filter(c => !c.delete),
        list => [...list].sort(sorter),
        _.flatMap(contact =>
          contact.phones.map(phone => [phone.id, makeLabel(contact, phone)])
        ),
        _.reduce(
          (data, [id, label]) => ({
            ...data,
            enum: [...data.enum, id],
            enumNames: [...data.enumNames, label],
          }),
          { enum: [], enumNames: [] }
        )
      )(contacts),
    contacts
  )

  return (
    <registry.fields.NumberField
      {...props}
      schema={{
        ...schema,
        ...enumProps,
      }}
      uiSchema={{ ...uiSchema, 'ui:widget': 'select' }}
    />
  )
}

PhoneContactField.propTypes = {
  formContext: PropTypes.object.isRequired,
  registry: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
}
