import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { mapTo } from 'rxjs/operators'
import { signEncounterReview } from '../data/currentEncounterReview'
import { PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS } from '../router'

export default (action$: any) =>
  action$.pipe(
    ofType(signEncounterReview.SUCCEEDED),
    mapTo(push(PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS))
  )
