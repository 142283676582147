import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator, getIn } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'

import rootKey from '../key'
import { transformTripStatus } from '../utils'
import { getRoot } from './common/shared'

// KEY
const SEARCH_BY_TRIP_ID = 'searchTripId'
const TRIP_ID = 'tripId'

export const getTripId = getIn([rootKey, TRIP_ID])

const tripIdCreator = scopedCreator(TRIP_ID)
export const clearTripIdField = tripIdCreator('CLEAR_FIRST_NAME')
export const setTripIdField = tripIdCreator('MAP_FIRST_NAME')

const creator = scopedCreator(SEARCH_BY_TRIP_ID)
export const clearTripById = creator('CLEAR_TRIP')

export const TripAddressLine = Record({
  addressLine1: null,
  city: null,
  state: null,
  zip: null,
})

export const TripDetail = Record({
  tripId: null,
  tripType: null,
  appointmentType: null,
  destinationType: null,
  status: null,
  caller: null,
  callerRelation: null,
  appointmentDate: null,
  appointmentDateTime: null,
  appointmentTime: null,
  appointmentMonth: null,
  numberOfEscorts: null,
  vendorName: null,
  vendorStatus: null,
  walkerCane: null,
  stairs: null,
  pickUpTime: null,
  pickUpType: null,
  distance: null,
  createdBy: null,
  updatedBy: null,
  phone: null,
  spokeTo: null,
  createdDateTime: null,
  lastUpdatedDateTime: null,
  pickUpAddress: TripAddressLine,
  pickupAddress1: null,
  pickupCity: null,
  pickupState: null,
  pickupZip: null,
  pickupPhone: null,
  pickupExt: null,
  pickupName: null,
  destinationAddress1: null,
  destinationCity: null,
  destinationState: null,
  destinationZip: null,
  destinationPhone: null,
  destinationExt: null,
  destnName: null,
  isAppointmentTimeEmpty: null,
  patientId: null,
  subscriberId: null,
  eligibilityEffectiveDate: null,
  classplanCounty: null,
  classplanProductId: null,
  firstName: null,
  lastName: null,
  effectiveTermDate: null,
  classplanProductDescription: null,
})

export const transformApptDateTimeDisplay = (trip: any) =>
  trip?.emptyApptTime ? trip?.apptDate.split(' ')[0] : trip?.apptDate

// TRANSFORMER
const transformTrip = (trip: any) => {
  if (trip === 'not_found') return { trip }
  else {
    return TripDetail({
      tripId: trip?.id,
      tripType: trip?.tripType,
      appointmentType: trip?.apptType,
      destinationType: trip?.destinationType,
      status: transformTripStatus(
        trip?.statusId,
        trip?.tripStatus,
        trip?.isCountable
      ),
      caller: trip?.contact,
      patientId: trip?.patientId,
      subscriberId: trip?.subscriberId,
      eligibilityEffectiveDate: trip?.eligibilityEffectiveDate,
      classplanCounty: trip?.classplanCounty,
      classplanProductId: trip?.classplanProductId,
      firstName: trip?.patientInfo?.first_name,
      lastName: trip?.patientInfo?.last_name,
      effectiveTermDate: trip?.patientInfo?.eligibility_term_date,
      classplanProductDescription: trip?.patientInfo?.product_description,
      appointmentDateTime: transformApptDateTimeDisplay(trip),
      appointmentDate: trip?.apptDate ? trip?.apptDate.split(' ')[0] : '',
      callerRelation: trip?.contactRelationType,
      isAppointmentTimeEmpty: trip?.emptyApptTime,
      numberOfEscorts: trip?.numberOfEscorts,
      vendorName: trip?.vendorName,
      walkerCane: trip?.walkerOrCane ? 'Yes' : 'No',
      stairs: trip?.stairs ? 'Yes' : 'No',
      pickUpTime: trip?.pickupTime,
      pickUpType: trip?.pickupType,
      distance: trip?.distance,
      phone: trip?.mobileNumber,
      spokeTo: trip?.spokeTo,
      createdBy: trip?.createdBy,
      updatedBy: trip?.modifiedBy ? trip?.modifiedBy : '',
      pickupName: trip?.pickupName,
      pickupAddress1: trip?.pickupAddress?.pickupAddress1,
      pickupCity: trip?.pickupAddress?.pickupCity,
      pickupState: trip?.pickupAddress?.pickupState,
      pickupZip: trip?.pickupAddress?.pickupZip,
      pickupPhone: trip?.pickupAddress?.pickupPhone,
      pickupExt: trip?.pickupAddress?.pickupExt,
      destnName: trip?.destnName,
      destinationAddress1: trip?.destinationAddress?.destinationAddress1,
      destinationCity: trip?.destinationAddress?.destinationCity,
      destinationState: trip?.destinationAddress?.destinationState,
      destinationZip: trip?.destinationAddress?.destinationZip,
      destinationPhone: trip?.destinationAddress?.destinationPhone,
      destinationExt: trip?.destinationAddress?.destinationExt,
    })
  }
}

// REQUEST
export const searchByTripId = Request({
  typePrefix: rootKey,
  typeBase: 'SEARCH_BY_TRIP_ID',
  requestParams: ['trip_id'],
  operation: (trip_id: string) =>
    AspireAPI.get(`transportation/trip/search_by_trip_id/${trip_id}`),
  transform: transformTrip,
  messages: {
    failed: 'There was an issue fetching the trip',
  },
})

// REDUCER
export const searchTripId = createReducer(SEARCH_BY_TRIP_ID, TripDetail, {
  [searchByTripId.SUCCEEDED]: compose(payload, action),
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearTripById]: () => TripDetail,
})

// SELECTORS
export const getTripById = pipe(getRoot, get(SEARCH_BY_TRIP_ID))

export const mapTripIdToState = createReducer(TRIP_ID, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [setTripIdField]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearTripIdField]: () => '',
})
