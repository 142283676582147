import { List, Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { into, scopedCreator } from '~/utils/data'

const ENCOUNTER_TYPES = 'encounterTypes'

export const requestEncounterTypes = scopedCreator(ENCOUNTER_TYPES)('REQUESTED')

// Record
export const EncounterType = Record({
  type: null,
  label: null,
  programsEnrolled: List(),
  providerRoles: List(),
  requireExisting: null,
})

export const fetchEncounterTypes = Request({
  typePrefix: ENCOUNTER_TYPES,
  typeBase: 'FETCH',
  operation: () => AspireAPI.get('encounters/types'),
  transform: into(EncounterType, 'type'),
  messages: {
    failed: 'There was an issue fetching encounter types',
  },
})

// Reducer
export default createReducer(ENCOUNTER_TYPES, Map(), {
  [fetchEncounterTypes.SUCCEEDED]: (_state, { payload }) => payload,
})

// Selectors
export const getEncounterTypes = state => state.get(ENCOUNTER_TYPES)

export const getEncounterTypesByTypes = types => state =>
  state.get(ENCOUNTER_TYPES).filter(({ type }) => types.includes(type))
