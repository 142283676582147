import { OrderedMap, Record } from 'immutable'
import { ofType } from 'redux-observable'
import { first, map } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getEventsRoot } from './root'

const SERVICE_LOCATIONS = 'serviceLocations'

export const ServiceLocation = Record({
  id: null,
  name: null,
  address: null,
  city: null,
  state: null,
  zip: null,
})

export const fetchServiceLocations = Request({
  typePrefix: SERVICE_LOCATIONS,
  typeBase: 'FETCH_SERVICE_LOCATIONS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/service_locations/${patientId}`),
  transform: into(ServiceLocation, 'name', OrderedMap),
  messages: {
    failed: 'There was an issue fetching service locations',
  },
})

const creator = scopedCreator(SERVICE_LOCATIONS)
export const serviceLocationsRequested = creator('SERVICE_LOCATIONS_REQUESTED')

export default createReducer(SERVICE_LOCATIONS, OrderedMap(), {
  [fetchServiceLocations.SUCCEEDED]: (_state, { payload }) => payload,
})

export const epic = action$ =>
  action$.pipe(
    ofType(serviceLocationsRequested),
    first(),
    map(fetchServiceLocations.requested)
  )

export const getServiceLocations = pipe(getEventsRoot, get(SERVICE_LOCATIONS))
