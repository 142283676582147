import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import {
  REFERRAL_NOTES_REQUESTED,
  fetchReferralNotes,
  referralCaseNotesLoaded,
} from '../data/referralNotes'

const fetchNotes = (caseId: any) =>
  from(fetchReferralNotes(caseId)).pipe(
    map(referralCaseNotesLoaded),
    catchError(showError('Error! Unable to load case data'))
  )

export default (action$: any) =>
  action$.pipe(
    ofType(REFERRAL_NOTES_REQUESTED),
    pluck('caseId'),
    mergeMap(caseId => loading(fetchNotes(caseId)))
  )
