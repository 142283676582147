import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import { addPhysician } from '../../data/physicians'
import PhysicianForm from './PhysicianForm'

const AddPhysician = ({ open, onSubmit, onClose }) => (
  <PhysicianForm
    form="physician.new"
    mode="add"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  />
)

AddPhysician.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default connect(null, { onSubmit: addPhysician.requested })(AddPhysician)
