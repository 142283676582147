import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { callLogCleared, getRoot } from './common/shared'

const WARNINGS = 'warnings'

export const Warning = Record({
  warning: null,
})

const transformWarnings = data =>
  List(data.filter(({ warning }) => warning).map(Warning))

export const fetchWarnings = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_WARNINGS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/patient/${patientId}/warnings`),
  transform: transformWarnings,
  messages: { failed: 'There was an issue fetching patient warnings' },
})

const initState = List()
export default createReducer(WARNINGS, initState, {
  [callLogCleared]: () => initState,
  [fetchWarnings.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getWarnings = pipe(getRoot, get(WARNINGS))
