import { combineReducers } from 'redux-immutable'
import attachment from './data/attachmentEvents'
import attachments from './data/fetchAttachments'
import key from './key'

const reducer = combineReducers({
  [attachment.key]: attachment,
  [attachments.key]: attachments,
})

reducer.key = key

export default reducer
