import React from 'react'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { addNonPhysician } from '../../data/nonPhysicians'
import NonPhysicianForm from './NonPhysicianForm'

export default function AddNonPhysician({ open, onClose }) {
  const onSubmit = useAction(addNonPhysician.requested)

  return (
    <NonPhysicianForm
      form="nonPhysician.new"
      mode="add"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

AddNonPhysician.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
