import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import { getCurrentPatient, getNextYearTripsFlag } from '../data/currentPatient'
import { updateTripStatus } from '../data/tripStatus'
import {
  clearTripAction,
  fetchNextYearPatientTrips,
  fetchPatientTrips,
} from '../data/trips'

export const updateTripStatusSuccess = (action$: any) =>
  action$.pipe(
    ofType(updateTripStatus.SUCCEEDED),
    map(() => clearTripAction())
  )

export const refreshPatientTrips = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updateTripStatus.SUCCEEDED),
    switchTo(state$),
    map((state: any) => {
      const currentPatient = getCurrentPatient(state)
      const nextYearTripsFlag = getNextYearTripsFlag(state)
      const params = [
        currentPatient?.patientId,
        currentPatient?.eligibilityEffectiveDate,
        currentPatient?.classplanProductId,
        currentPatient?.countyName,
      ]
      return nextYearTripsFlag
        ? fetchNextYearPatientTrips.requested(...params)
        : fetchPatientTrips.requested(...params)
    })
  )
