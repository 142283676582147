import { combineEpics } from 'redux-observable'
import { exited } from 'redux-routable'
import { filter, mapTo } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { panelClosed } from '~/data/patientPanel'
import {
  PHYSICIAN_DASHBOARD_CENSUS,
  PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS,
  PHYSICIAN_DASHBOARD_ORDERS_INDEX,
  PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS,
  PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS,
  PHYSICIAN_DASHBOARD_TASKS_BY_USER,
} from '../router'

const tasksEpic = (action$: any) =>
  action$.pipe(
    filter(exited(PHYSICIAN_DASHBOARD_TASKS_BY_USER)),
    mapTo(panelClosed())
  )

const ordersEpic = (action$: any) =>
  action$.pipe(
    filter(exited(PHYSICIAN_DASHBOARD_ORDERS_INDEX)),
    mapTo(panelClosed())
  )

const censusEpic = (action$: any) =>
  action$.pipe(filter(exited(PHYSICIAN_DASHBOARD_CENSUS)), mapTo(panelClosed()))

const encounterReviewsEpic = (action$: any) =>
  action$.pipe(
    filter(exited(PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS)),
    mapTo(panelClosed())
  )

const patientOutlierReviewsEpic = (action$: any) =>
  action$.pipe(
    filter(exited(PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS)),
    mapTo(panelClosed())
  )

const patientOutlierFinalDecisionsEpic = (action$: any) =>
  action$.pipe(
    filter(exited(PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS)),
    mapTo(panelClosed())
  )

export default combineEpics(
  tasksEpic,
  ordersEpic,
  censusEpic,
  encounterReviewsEpic,
  patientOutlierReviewsEpic,
  patientOutlierFinalDecisionsEpic
)
