import React, { useState } from 'react'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { DEFER, DEFERRED, IMPORT } from '../../utils/medListConstants'
import { MedImportForm } from './MedImportForm'

// @ts-expect-error ts-migrate(7016)

const styles = () => ({
  disabled: {
    opacity: 0.5,
  },
})

const PrescribedActions = props => {
  const { row, classes } = props

  const statusFlag = row.importStatus == DEFERRED

  const [isDialogOpened, setIsDialogOpened] = useState(false)

  const [dialogAction, setDialogAction] = useState(IMPORT)

  const onImportClick = action => {
    setDialogAction(action)
    setIsDialogOpened(true)
  }

  return (
    <React.Fragment>
      <>
        <MedImportForm
          isDialogOpened={isDialogOpened}
          setIsDialogOpened={setIsDialogOpened}
          dialogAction={dialogAction}
          medId={row.id}
        ></MedImportForm>

        <Tooltip title="IMPORT">
          <IconButton
            onClick={() => onImportClick(IMPORT)}
            disabled={statusFlag}
            className={statusFlag ? classes.disabled : ''}
          >
            <Icon color="primary">open_in_browser_icon</Icon>
          </IconButton>
        </Tooltip>

        <Tooltip title="DEFER">
          <IconButton
            onClick={() => onImportClick(DEFER)}
            disabled={statusFlag}
            className={statusFlag ? classes.disabled : ''}
          >
            <Icon color="primary">cancel</Icon>
          </IconButton>
        </Tooltip>
      </>
    </React.Fragment>
  )
}

PrescribedActions.propTypes = {
  row: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(PrescribedActions)
