import { combineReducers } from 'redux-immutable'
import addTimelineEntryDialog from './data/timelineEntryDialog'
import key from './key'

const reducer = combineReducers({
  [addTimelineEntryDialog.key]: addTimelineEntryDialog,
})

reducer.key = key

export default reducer
