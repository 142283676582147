import { combineReducers } from 'redux-immutable'
import calls from './data/calls'
import currentCallId from './data/currentCallId'
import form from './data/form'
import CALL from './key'

const reducer = combineReducers({
  [form.key]: form,
  [calls.key]: calls,
  [currentCallId.key]: currentCallId,
})

reducer.key = CALL

export default reducer
