import { List, Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error no export
import Request from '~/utils/Request'
// @ts-expect-error no export
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'

const key = 'patientContacts'
const FORM_CONTACTS = 'formContacts'

export const Contact = Record({
  id: null,
  active: null,
  name: null,
  preferred: null,
  relationship: null,
  type: null,
  preferredEmail: null,
  preferredPhone: null,
  emails: List(),
  phoneNumbers: List(),
  addresses: List(),
})

const transformContacts = (contacts: any) => {
  return List(
    contacts.map((c: any) => {
      const preferredEmail = c.emails?.filter((email: any) => email.primary)
      const preferredPhone = c.phones?.filter((phone: any) => phone.primary)

      return Contact({
        preferredEmail: preferredEmail[0]?.email,
        preferredPhone: preferredPhone[0]?.phone,
        ...c,
      })
    })
  )
}

export const fetchContacts = Request({
  typePrefix: key,
  typeBase: 'FETCH_CONTACTS',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`v1/patient/${patientId}/contacts`),
  transform: transformContacts,
  messages: { failed: 'There was an issue fetching patient contacts' },
})
export const patientContacts = createReducer(key, Map(), {
  [fetchContacts.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const syncFormContacts = Request({
  typePrefix: FORM_CONTACTS,
  typeBase: 'SYNC_CONTACTS',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`v1/sync_contacts/${patientId}`),
  messages: { failed: 'There was an issue syncing patient contacts' },
})
export const formContacts = createReducer(FORM_CONTACTS, List(), {
  [syncFormContacts.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getPatientContacts = pipe(get('assessment'), get(key))

export const getFormContacts = pipe(get('assessment'), get(FORM_CONTACTS))
