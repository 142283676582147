import { combineEpics } from 'redux-observable'
import { validateAppointmentDate } from './epics/checkTripExist'
import { distanceCalc } from './epics/getDistance'
import {
  addBenefitSuccess,
  refreshBenefitDelete,
  updateBenefitSuccess,
  uploadBenefitSuccess,
} from './epics/loadBenefits'
import {
  clearSearch,
  loadPatientDemographics,
} from './epics/loadPatientDemographics'
import { loadTripNotes } from './epics/loadTripNotes'
import {
  recuTripValidated,
  recurTripOverRideValid,
  recurTripSaved,
} from './epics/recurTripValidate'
import {
  refreshMemberBenefitsOnAddingNewBenefit,
  refreshMemberBenefitsOnUpdatingBenefit,
  refreshMemberBenefitsOnUpdatingTripStatus,
} from './epics/refreshMemberBenefitsNotes'
import { refreshPatientInfo } from './epics/refreshPatientInfo'
import { refreshTripLocksOnDelete } from './epics/refreshTripLocks'
import {
  refreshTrips,
  refreshTripsOnRecur,
  refreshTripsOnUpdate,
} from './epics/refreshTrips'
import { fetchPatientInfo, openPanel } from './epics/transPatientPanelOpened'
import {
  newTripSaveFailed,
  tripOverRideValid,
  tripSaved,
  tripUpdateFailed,
  tripUpdateSucceeded,
  tripValidated,
} from './epics/tripValidate'
import { fetchTripsRequest, fetchTripsSuccess } from './epics/tripsLoadstatus'
import {
  refreshPatientTrips,
  updateTripStatusSuccess,
} from './epics/updateTripStatus'

export default combineEpics(
  loadPatientDemographics,
  openPanel,
  fetchTripsRequest,
  fetchTripsSuccess,
  fetchPatientInfo,
  refreshPatientInfo,
  distanceCalc,
  refreshTrips,
  addBenefitSuccess,
  updateBenefitSuccess,
  refreshBenefitDelete,
  refreshTripLocksOnDelete,
  validateAppointmentDate,
  tripValidated,
  tripOverRideValid,
  tripSaved,
  updateTripStatusSuccess,
  refreshPatientTrips,
  refreshTripsOnUpdate,
  uploadBenefitSuccess,
  newTripSaveFailed,
  tripUpdateSucceeded,
  tripUpdateFailed,
  clearSearch,
  loadTripNotes,
  refreshTripsOnRecur,
  refreshMemberBenefitsOnUpdatingTripStatus,
  refreshMemberBenefitsOnAddingNewBenefit,
  refreshMemberBenefitsOnUpdatingBenefit,
  recurTripOverRideValid,
  recuTripValidated,
  recurTripSaved
)
