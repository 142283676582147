import { Map, Record } from 'immutable'
import { countBy } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'
import { getOrderKey } from './common/shared'
import { getOrdersUserId } from './userId'

// RECORD
export const OrderInfo = Record({
  actions: [],
  appName: null,
  cancellationReasonKey: null,
  category: null,
  createdAt: null,
  createdBy: null,
  currentlyAssignedTo: null,
  description: null,
  dueDate: null,
  expectedFulfillmentDate: null,
  expirationDate: null,
  form: null,
  fulfillmentDate: null,
  id: null,
  modifiedAt: null,
  modifiedBy: null,
  note: null,
  patientAddress: null,
  patientContactId: null,
  patientDob: null,
  patientFirstName: null,
  patientFullName: null,
  patientId: null,
  patientLastName: null,
  patientStateCode: null,
  program: null,
  primaryInsurance: null,
  status: null,
  substatus: null,
  subtype: null,
  type: null,
  vendorId: null,
  vendorName: null,
  workflowInstanceId: null,
})

// CONSTANTS
export const key = 'orders'
// ACTIONS
export const ORDERS_FETCH_REQUESTED = type(key, 'ORDERS_FETCH_REQUESTED')
export const ORDERS_FETCH_SUCCEEDED = type(key, 'ORDERS_FETCH_SUCCEEDED')
export const ORDERS_FETCH_FAILED = type(key, 'ORDERS_FETCH_FAILED')
export const ORDERS_STATE_CLEARED = type(key, 'ORDERS_STATE_CLEARED')

export const ORDER_SAVE_REQUESTED = type(key, 'ORDER_SAVE_REQUESTED')
export const ORDER_SAVE_SUCCEEDED = type(key, 'ORDER_SAVE_SUCCEEDED')
export const ORDER_SAVE_FAILED = type(key, 'ORDER_SAVE_FAILED')

// ACTION CREATORS
export const ordersFetchRequested = (userId, patientId) => ({
  type: ORDERS_FETCH_REQUESTED,
  payload: { userId, patientId },
})
export const ordersFetchSucceeded = creator(ORDERS_FETCH_SUCCEEDED, 'orders')
export const ordersFetchFailed = creator(ORDERS_FETCH_FAILED)
export const ordersStateCleared = creator(ORDERS_STATE_CLEARED)

// REDUCERS
export default createReducer(key, Map(), {
  [ORDERS_STATE_CLEARED]: () => Map(),
  [ORDERS_FETCH_SUCCEEDED]: (state, { payload: { orders } }) =>
    state.merge(orders),
})

// SELECTORS

export const getAllOrders = createSelector(
  state => getOrderKey(state).get(key),
  orders => orders
)

export const getAllOrdersAsJS = createSelector(getAllOrders, orders =>
  orders.toIndexedSeq().toJS()
)

export const getAllHospiceOrdersAsJS = createSelector(getAllOrders, orders =>
  orders
    .filter(o => o.get('type') == 'hospice')
    .toIndexedSeq()
    .toJS()
)

export const getCountNewOrders = createSelector(
  getAllOrders,
  orders => orders.filter(o => o.get('status') === 'new').size
)

export const getCurrentlyAssignedOrders = createSelector(
  [getAllOrders, getOrdersUserId],
  (orders, id) =>
    orders.filter(o => o.get('currentlyAssignedTo') === id).toList()
)

export const getAssignedOrderCountsBySubstatus = createSelector(
  [getCurrentlyAssignedOrders],
  assignedOrders => countBy(assignedOrders.toJS(), v => v.substatus || 'new')
)

export const getCurrentlyAssignedOrdersAsJS = createSelector(
  getCurrentlyAssignedOrders,
  orders => orders.toIndexedSeq().toJS()
)

export const getNonAssignedOrders = createSelector(
  [getAllOrders, getOrdersUserId],
  (orders, id) =>
    orders.filter(o => o.get('currentlyAssignedTo') !== id).toList()
)

export const getNonAssignedOrdersAsJS = createSelector(
  getNonAssignedOrders,
  orders => orders.toIndexedSeq().toJS()
)

export const getCountOrdersForPatientContext = createSelector(
  getAllOrders,
  orders => orders.size
)

export const getPatientOrderCountsBySubstatus = createSelector(
  [getAllOrders],
  orders => countBy(orders.toJS(), v => v.substatus || 'new')
)

export const fetchUserOrders = user_id =>
  AspireAPI.get('v1/orders', { params: { user_id } }).then(data =>
    data.reduce((prev, current) => {
      return prev.set(current.id, OrderInfo(current))
    }, Map())
  )

export const fetchUserOrdersForCareTeamPatients = care_team_member_id =>
  AspireAPI.get('v1/orders', { params: { care_team_member_id } }).then(data =>
    data.reduce((prev, current) => {
      return prev.set(current.id, OrderInfo(current))
    }, Map())
  )

export const fetchPatientOrders = patient_id =>
  AspireAPI.get('v1/orders', { params: { patient_id } }).then(data =>
    data.reduce((prev, current) => {
      return prev.set(current.id, OrderInfo(current))
    }, Map())
  )
