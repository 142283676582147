import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { CONTACT_MANAGEMENT_HOSPITAL_RECORD } from '../router'
import { getRoot } from './common/shared'

const key = 'currentHospitalId'

const reducer = paramsReducer(
  CONTACT_MANAGEMENT_HOSPITAL_RECORD,
  ({ hospitalId }: any) => hospitalId
)

reducer.key = key

export const getCurrentHospitalId = pipe(getRoot, get(key))

export default reducer
