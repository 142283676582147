import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import { fetchTriggerLogs, editTriggerLog } from '../data/triggerLog'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(editTriggerLog.SUCCEEDED),
    switchTo(state$),
    map((state: any) => fetchTriggerLogs.requested(state))
  )
