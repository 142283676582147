import { OrderedMap } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  dataCleared,
  getRoot,
  transformPatientOutlierFinalDecisions,
} from './common/shared'
import { completePatientOutlierFinalDecision } from './currentPatientOutlierFinalDecision'

const PATIENT_OUTLIER_FINAL_DECISIONS = 'patientOutlierFinalDecisions'

export const fetchPatientOutlierFinalDecisions = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_OUTLIER_FINAL_DECISIONS',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('patient_outliers/final_decisions', {
      params: {
        userId,
      },
    }),
  transform: transformPatientOutlierFinalDecisions,
  messages: {
    failed:
      'There was an issue getting the patient outlier final decision list',
  },
})

const initState = OrderedMap()

export default createReducer(PATIENT_OUTLIER_FINAL_DECISIONS, initState, {
  [fetchPatientOutlierFinalDecisions.SUCCEEDED]: (
    _state: any,
    { payload }: any
  ) => payload,
  [completePatientOutlierFinalDecision.SUCCEEDED]: (
    state: any,
    { meta: { request } }: any
  ) => state.delete(request.payload.id),
  // @ts-expect-error can we deal with the toString being automatically called?
  [dataCleared]: () => initState,
})

export const getPatientOutlierFinalDecisions = pipe(
  getRoot,
  get(PATIENT_OUTLIER_FINAL_DECISIONS)
)

export const getPatientOutlierFinalDecisionsCount = (state: any) =>
  getPatientOutlierFinalDecisions(state).size
