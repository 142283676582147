import { get as getIn } from 'lodash/fp'
import createReducer from '~/utils/createReducer'
import { action, get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common'
import { getEpisodeOfCareById } from './episodesOfCare'

const CURRENT_EPISODE_OF_CARE_ID = 'currentEpisodeOfCareId'

const creator = scopedCreator(rootKey)
export const episodeOfCareSelected = creator('EPISODE_OF_CARE_SELECTED', ['id'])

export default createReducer(CURRENT_EPISODE_OF_CARE_ID, null, {
  [episodeOfCareSelected]: pipe(action, getIn(['payload', 'id']), Number),
})

export const getCurrentEpisodeOfCare = state =>
  pipe(
    getRoot,
    get(CURRENT_EPISODE_OF_CARE_ID),
    getEpisodeOfCareById(state)
  )(state)
