import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import JsonForm from '~/components/JsonForm'
import {
  OTHER,
  PATIENT,
  STAKEHOLDER,
  VENDOR,
} from '~/features/quickCallLogger/data'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CARE_TEAM } from '../../quickCallLogger/data'
import {
  Form,
  completeCareTeamForm,
  completeForm,
  fetchForm,
  formCancelled,
  formErrored,
  formUpdated,
  getForm,
  isFormPending,
  isFormSubmitting,
} from '../data/form'

const styles = makeStyles(({ spacing, zIndex: { drawer }, palette }) => ({
  button: {
    marginLeft: spacing(1),
  },
  buttonIcon: {
    marginRight: spacing(1),
  },
  callButton: {
    color: palette.common.white,
    marginLeft: spacing(1),
  },
  divider: {
    marginBottom: spacing(1),
    marginTop: spacing(3),
  },
  fab: {
    bottom: spacing(5),
    position: 'fixed',
    right: spacing(5),
    zIndex: drawer + 1,
  },
  formPaper: {
    marginBottom: spacing(2),
    padding: spacing(2),
  },
  gridPaper: {
    padding: spacing(2),
  },
  headerTitle: {
    marginBottom: spacing(2),
  },
  icon: { marginRight: spacing(1) },
  radioGroup: {
    marginLeft: spacing(2),
    flexDirection: 'row',
  },
  panelButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  radioButton: {
    '& + span': {
      marginRight: spacing(1),
    },
  },
}))

const CaremoreLogCommunication = ({
  callLog,
  careTeamFormSubmitRequested,
  dialogClosed,
  form,

  formCompletionRequested,
  formErrored,
  formRequested,
  formUpdated,
  onCancelLogCommunication,
  onChangeCallLog,
  patientId,
  phoneCopyErrorMessage,
  phoneCopySuccessMessage,
  selectedContact,
  selectedExtensivist,
  selectedTeams,
  source,
  submitting,
  type,
}) => {
  useEffect(() => {
    formRequested(patientId, type.toLowerCase())
  }, [])
  const classes = styles()
  const onCancel = () => onCancelLogCommunication()

  const onChange = ({ errors, formData }) =>
    formUpdated(formData, Boolean(errors.length))

  const onError = errors => formErrored(Boolean(errors.length))

  const onSubmit = ({ formData }) => {
    if (type.toLowerCase() === 'care_team') {
      let extensivist
      let careTeam = []
      if (selectedExtensivist) {
        extensivist = selectedExtensivist.id
      }
      if (selectedTeams && selectedTeams.length > 0) {
        careTeam = selectedTeams.map(team => team.user.id)
      }
      careTeamFormSubmitRequested(
        patientId,
        formData,
        source,
        type.toLowerCase(),
        careTeam,
        extensivist
      )
    } else {
      const { name, phone, practice, specialty, phoneId } = selectedContact
      const contextData =
        type.toLowerCase() === 'pcp'
          ? { name, phone, practice, specialty }
          : { phoneId }
      formCompletionRequested(
        patientId,
        formData,
        source,
        type.toLowerCase(),
        contextData
      )
    }

    dialogClosed()
  }

  const copyNumber = () =>
    navigator.clipboard
      .writeText(selectedContact.phone)
      .then(() => phoneCopySuccessMessage())
      .catch(() => phoneCopyErrorMessage())

  const onChangeIntent = event => {
    onChangeCallLog(callLog.set('intent', event.target.value))
  }
  const formattedNumber = useCallback(
    () =>
      selectedContact.phone
        ? selectedContact.phone.replace(/[^0-9]+/g, '')
        : '(234) 234-4567'.replace(/[^0-9]+/g, ''),
    [selectedContact]
  )

  return (
    <React.Fragment>
      {type === OTHER && (
        <div className={classes.radioContainer}>
          <Typography variant="subtitle1">Call Intent:</Typography>
          <RadioGroup
            className={classes.radioGroup}
            value={callLog.intent}
            onChange={onChangeIntent}
          >
            <FormControlLabel
              value={PATIENT}
              control={<Radio className={classes.radioButton} />}
              label="Patient"
            />
            <FormControlLabel
              value={STAKEHOLDER}
              control={<Radio className={classes.radioButton} />}
              label="Stakeholder"
            />
            <FormControlLabel
              value={VENDOR}
              control={<Radio />}
              label="Vendor"
            />
          </RadioGroup>
        </div>
      )}
      {type !== CARE_TEAM && (
        <div>
          <Button color="primary" onClick={copyNumber} variant="contained">
            <Icon className={classes.buttonIcon} fontSize="small">
              content_copy
            </Icon>
            Copy Phone Number
          </Button>
          <Button
            className={classes.callButton}
            color="secondary"
            href={`tel:+1${formattedNumber}`}
            variant="contained"
          >
            <Icon className={classes.buttonIcon} fontSize="small">
              phone
            </Icon>
            Call
          </Button>
        </div>
      )}
      <Divider className={classes.divider} />
      {form ? (
        <Paper className={classes.formPaper}>
          <JsonForm
            context={form.context}
            formData={form.formData}
            onChange={onChange}
            onError={onError}
            onSubmit={onSubmit}
            schema={form.schema}
            uiSchema={{ ...form.uiSchema, 'ui:disabled': submitting }}
          >
            {form.errored && (
              <Typography color="error" variant="body1">
                Errors exist on this form.
              </Typography>
            )}
            <Button
              className={classes.button}
              onClick={onCancel}
              variant="outlined"
            >
              <Icon className={classes.icon}>cancel</Icon> Cancel
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={form.errored || submitting}
              type="submit"
              variant="contained"
            >
              <Icon className={classes.icon}>done</Icon>
              Complete
            </Button>
          </JsonForm>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </React.Fragment>
  )
}

CaremoreLogCommunication.propTypes = {
  callLog: PropTypes.object.isRequired,
  onChangeCallLog: PropTypes.func.isRequired,
  careTeamFormSubmitRequested: PropTypes.func.isRequired,
  dialogClosed: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Form),

  formCompletionRequested: PropTypes.func.isRequired,
  formErrored: PropTypes.func.isRequired,
  formRequested: PropTypes.func.isRequired,
  formUpdated: PropTypes.func.isRequired,
  onCancelLogCommunication: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  phoneCopySuccessMessage: PropTypes.func.isRequired,
  phoneCopyErrorMessage: PropTypes.func.isRequired,
  selectedExtensivist: PropTypes.object,
  selectedContact: PropTypes.object,
  selectedTeams: PropTypes.array,
  source: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
}

export default connect(
  state => ({
    form: getForm(state),
    pending: isFormPending(state),
    submitting: isFormSubmitting(state),
  }),
  {
    formCancelled,
    formErrored,
    formCompletionRequested: completeForm.requested,
    careTeamFormSubmitRequested: completeCareTeamForm.requested,
    formRequested: fetchForm.requested,
    formUpdated,
  }
)(CaremoreLogCommunication)
