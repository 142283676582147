import { isEqual } from 'lodash/fp'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import {
  filter,
  map,
  mergeMap,
  pluck,
  take,
  withLatestFrom,
} from 'rxjs/operators'
import {
  createNewTask,
  createNewTaskSubmitted,
  getCreateNewTaskForm,
} from '../data/createNewTask'
import { dialogClosed } from '../data/dialog'

const createForm = ([formData, state]) => {
  const { patientId, taskDefinitionKey } = getCreateNewTaskForm(state)

  return {
    formData,
    patientId,
    taskDefinitionKey,
  }
}

export default (action$, state$) =>
  action$.pipe(
    ofType(createNewTaskSubmitted),
    pluck('payload', 'formData'),
    withLatestFrom(state$),
    map(createForm),
    mergeMap(({ formData, patientId, taskDefinitionKey }) =>
      merge(
        of(createNewTask.requested(patientId, taskDefinitionKey, formData)),
        action$.pipe(
          ofType(createNewTask.SUCCEEDED, createNewTask.FAILED),
          take(1),
          pluck('type'),
          filter(isEqual(createNewTask.SUCCEEDED)),
          map(dialogClosed)
        )
      )
    )
  )
