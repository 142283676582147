import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Icon, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

interface MethodItemPropTypes {
  isPrimary: PropTypes.bool.isRequired
  children: PropTypes.object.isRequired
}

export const MethodItem = ({ isPrimary, children }: MethodItemPropTypes) => {
  return (
    <ListItem>
      <ListItemIcon>
        <Icon> {isPrimary ? 'star' : ''} </Icon>
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItem>
  )
}
