import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'draftProjectPatients'

export const fetchDraftProjectPatients = Request({
  typePrefix: rootKey,
  typeBase: 'DRAFT_PROJECT_PATIENTS',
  requestParams: ['project_id'],
  operation: projectId =>
    AspireAPI.get(`polygonator/draft_projects/${projectId}/patients`, {
      params: { project_id: projectId },
    }),
  messages: { failed: 'Could not download patient list' },
})

export default createReducer(key, Map(), {
  [fetchDraftProjectPatients.SUCCEEDED]: (_state, { payload }) =>
    normalize(payload, { idKey: 'patient_id' }),
})

export const getDraftProjPatients = pipe(getRoot, get(key))
