import React from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  getPatientContactFormEmails,
  patientContactFormEmailsCreator,
} from '~/apps/patientRecord/data/patientContactForm'
import { getFieldValues } from '~/data/fieldValues'
import { Authorized, actions, objects } from '~/features/authorization'
import { useAction } from '~/hooks'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { patientContactFormUseStyles } from '../utils/utils'

interface PatientEmailFormPropTypes {
  email: PropTypes.object.isRequired
  control: PropTypes.object.isRequired
}

export const PatientEmailForm = ({
  email,
  control,
}: PatientEmailFormPropTypes) => {
  const classes = patientContactFormUseStyles()

  const emails: any = useSelector(getPatientContactFormEmails)
  const emailTypeFields = useSelector(getFieldValues(['contact_email_type']))
  const setEmails = useAction(patientContactFormEmailsCreator)

  const removeEmail = (currentIndex: any, isPrimary: any) => {
    const item = emails.find((e: any) => e.index !== currentIndex && !e.delete)
    const updatedEmails: any[] = emails.map((el: any) => {
      const temp = {
        id: el.id,
        email: el.email,
        type: el.type,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        createdAt: el.createdAt,
        index: el.index,
      }
      if (temp.index === currentIndex) {
        temp.primary = false
        temp.delete = true
      }

      if (isPrimary && item && temp.index === item.index) {
        temp.primary = true
      }
      return temp
    })

    setEmails(updatedEmails)
  }

  const markPrimaryEmail = (currentIndex: any) => {
    const updatedEmails: any[] = emails.map((el: any) => {
      const temp = {
        id: el.id,
        email: el.email,
        type: el.type,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        createdAt: el.createdAt,
        index: el.index,
      }
      temp.index === currentIndex
        ? (temp.primary = true)
        : (temp.primary = false)

      return temp
    })

    setEmails(updatedEmails)
  }

  function handleEmailChange(event: any, currentIndex: any) {
    const updatedEmails: any[] = emails.map((el: any) => {
      const temp = {
        id: el.id,
        email: el.email,
        type: el.type,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        createdAt: el.createdAt,
        index: el.index,
      }

      if (el.index === currentIndex) {
        switch (event.target.name) {
          case 'email':
            temp.email = event.target.value
            break
          case '':
            temp.type = event.target.value.toLowerCase()
            break
          case 'active':
            temp.active = event.target.checked
            break
        }
      }

      return temp
    })

    setEmails(updatedEmails)
  }

  return (
    <React.Fragment key={email?.index || email?.id}>
      <Box display="flex" className={classes.marginBottomCls}>
        <IconButton onClick={() => markPrimaryEmail(email?.index)}>
          <Icon color={email.primary ? 'primary' : 'action'}> star </Icon>
        </IconButton>
        <Box className={classes.textFieldBox}>
          <TextField
            type="email"
            required={true}
            variant="filled"
            fullWidth={true}
            label="Email"
            value={email.email}
            name="email"
            onChange={e => handleEmailChange(e, email?.index)}
          />
        </Box>
        <Box className={classes.textFieldBoxNew}>
          <Controller
            render={({ onChange }) => (
              <TextField
                label="Type"
                onChange={value => {
                  onChange(value)
                  handleEmailChange(value, email?.index)
                }}
                value={email.type}
                variant="filled"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                select
                SelectProps={{ native: true }}
                required={true}
                fullWidth
              >
                <option value="" key="" />
                {emailTypeFields.entrySeq().map(([k, v]: any) => (
                  <option value={v.value} key={k}>
                    {v.label}
                  </option>
                ))}
              </TextField>
            )}
            control={control}
            name="emailType"
            rules={{ required: 'Please fill out this field.' }}
            variant="filled"
            defaultValue={email?.type || ''}
          ></Controller>
        </Box>
        <Tooltip
          title={email.id ? 'Active Contact Method' : 'New Contact Method'}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={email.active}
                disabled={email.id ? false : true}
                onChange={e => handleEmailChange(e, email?.index)}
                name="active"
              />
            }
            label="Active"
          />
        </Tooltip>
        <Authorized object={objects.PATIENT_CONTACTS} action={actions.DELETE}>
          <IconButton onClick={() => removeEmail(email?.index, email.primary)}>
            <Icon> close </Icon>
          </IconButton>
        </Authorized>
      </Box>
    </React.Fragment>
  )
}
