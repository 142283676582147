import React, { useCallback, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { areRequestsPending } from '~/data/pending'
import { getIsCaremoreBrand, getPatientName } from '~/features/patientInfo'
import { compose, pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { downloadMedicationList } from '../../../apps/assessment/data/assessments'
import ActionItem from '../components/ActionItem'
import {
  MedListGrid,
  PrescribedMedListGrid,
  RowActions,
} from '../components/CaseManagerMedList'
import { fetchMdtSingleSignOn } from '../data/mdtSingleSignOn'
import { getPatientId, getPrescriberId } from '../data/patientInformation'
import {
  fetchPatientRx,
  getConfirmedPatientRxArray,
  getPatientRxById,
  getPrescribedPatientRxArray,
  putPatientRx,
  savePatientMedication,
  syncPatientRx,
} from '../data/patientRx'
import { DELETED } from '../utils/medListConstants'

const styles = ({ spacing }) => ({
  marginLeft: {
    marginLeft: spacing(2),
  },
})

const MedListRowActions = ({ row }) => <RowActions medication={row} />

MedListRowActions.propTypes = {
  row: PropTypes.object.isRequired,
}

const merge = props => med => med.merge(props)

const CaseManagerMedList = ({
  areRequestsPending,
  patientRxArray,
  classes,
  disabled,
  fetchPatientMedications,
  getDrugById,
  mdtSingleSignOn,
  mdtSync,
  patientId,
  patientMedications,
  patientprescribedMedications,
  prescriberId,
  refreshable,
  savePatientMedication,
}) => {
  const [selectable, setSelectable] = useState(false)
  const patientName = useSelector(getPatientName)
  const isCaremoreBrand = useSelector(getIsCaremoreBrand)

  const dispatch = useDispatch()

  const toggleSelectable = useCallback(
    () => setSelectable(prevSelectable => !prevSelectable),
    [setSelectable]
  )

  const onDownload = () =>
    dispatch(downloadMedicationList.requested(patientId, patientName))

  const redirectToMDT = useCallback(
    params =>
      mdtSingleSignOn({
        patientId,
        prescriberId,
        ...params,
      }),
    [mdtSingleSignOn, patientId, prescriberId]
  )

  const redirectToMedHistory = useCallback(
    () => redirectToMDT({ page: 'elighx' }),
    [redirectToMDT]
  )

  const redirectToPatientMeds = useCallback(
    () => redirectToMDT({ header: 'no_edit' }),
    [redirectToMDT]
  )

  const syncMedsFromMDToolbox = useCallback(() => mdtSync(patientId), [
    mdtSync,
    patientId,
  ])

  const fetchMedicationsForPatient = useCallback(
    () => fetchPatientMedications(patientId),
    [fetchPatientMedications, patientId]
  )

  const fetchDeletedMedicationsForPatient = useCallback(
    () => fetchPatientMedications(patientId, { status: ['Deleted'] }),
    [fetchPatientMedications, patientId]
  )

  const LoadDeleted = useCallback(
    () => (
      <Button
        classes={{ root: classes.marginLeft }}
        variant="outlined"
        onClick={fetchDeletedMedicationsForPatient}
      >
        Load Deleted Meds
      </Button>
    ),
    [classes, fetchDeletedMedicationsForPatient]
  )

  const deleteSelectedMedications = useCallback(
    selection => () => {
      return selection.map(
        pipe(getDrugById, merge({ status: DELETED }), (...args) =>
          savePatientMedication(...args, undefined, true)
        )
      )
    },
    [getDrugById, savePatientMedication]
  )

  const DeleteSelected = useCallback(
    ({ selection }) => (
      <Button
        classes={{ root: classes.marginLeft }}
        variant="outlined"
        onClick={deleteSelectedMedications(selection)}
      >
        Delete Selected Meds
      </Button>
    ),
    [classes, deleteSelectedMedications]
  )

  const batchAction = useMemo(
    () => (selectable ? DeleteSelected : LoadDeleted),
    [selectable, DeleteSelected, LoadDeleted]
  )

  const GridActionsComponent = useCallback(
    () => (
      <span>
        {isCaremoreBrand && (
          <ActionItem
            toolTip="Medication Letter"
            icon="get_app"
            onClick={onDownload}
          />
        )}
        <ActionItem
          toolTip="Toggle Checkbox"
          icon="check_box"
          onClick={toggleSelectable}
          mdtSFAuth
        />
        <ActionItem
          toolTip="Sync With MDToolbox"
          icon="sync"
          onClick={syncMedsFromMDToolbox}
          mdtSFAuth
          disabled={areRequestsPending}
        />
        <ActionItem
          toolTip="Manage Medications in MDToolbox"
          icon="local_pharmacy"
          onClick={redirectToPatientMeds}
          mdtSFAuth
          disabled={areRequestsPending}
        />
        <ActionItem
          toolTip="View Patient Medication History on MDToolbox"
          icon="assignment_returned"
          onClick={redirectToMedHistory}
          mdtSFAuth
          disabled={areRequestsPending}
        />
      </span>
    ),
    [
      isCaremoreBrand,
      toggleSelectable,
      syncMedsFromMDToolbox,
      areRequestsPending,
      redirectToPatientMeds,
    ]
  )

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <MedListGrid
          additionalGridOptions={{
            selectable: selectable,
            refreshable: refreshable,
            onRefresh: fetchMedicationsForPatient,
            batchActions: batchAction,
            rowActions: MedListRowActions,
            rowActionsCount: 2,
          }}
          disabled={disabled}
          getMedById={getDrugById}
          GridActionsComponent={GridActionsComponent}
          medList={patientRxArray ? patientRxArray : patientMedications}
          savePatientMedication={savePatientMedication}
        />
      </Grid>

      {patientprescribedMedications.length && !disabled ? (
        <Grid item xs={12}>
          <Box mb={4}>
            <Typography variant={'h5'} color={'primary'}>
              Prescribed Medications
            </Typography>
          </Box>
          <PrescribedMedListGrid
            medList={patientprescribedMedications}
            disabled={disabled}
          />
        </Grid>
      ) : null}
    </Grid>
  )
}

CaseManagerMedList.propTypes = {
  areRequestsPending: PropTypes.bool,
  patientRxArray: PropTypes.array,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fetchPatientMedications: PropTypes.func.isRequired,
  getDrugById: PropTypes.func.isRequired,
  mdtSingleSignOn: PropTypes.func.isRequired,
  mdtSync: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  patientMedications: PropTypes.array,
  patientprescribedMedications: PropTypes.array,
  prescriberId: PropTypes.string,
  refreshable: PropTypes.bool,
  savePatientMedication: PropTypes.func.isRequired,
}

CaseManagerMedList.defaultProps = {
  areRequestsPending: false,
  disabled: false,
  patientMedications: [],
  patientprescribedMedications: [],
  refreshable: false,
}

const mapStateToProps = state => ({
  areRequestsPending: areRequestsPending(state, [
    fetchMdtSingleSignOn,
    syncPatientRx,
    putPatientRx,
  ]),
  patientMedications: getConfirmedPatientRxArray(state),
  patientprescribedMedications: getPrescribedPatientRxArray(state),
  patientId: getPatientId(state),
  prescriberId: getPrescriberId(state),
  getDrugById: getPatientRxById(state),
})

const mapDispatchToProps = {
  fetchPatientMedications: fetchPatientRx.requested,
  mdtSingleSignOn: fetchMdtSingleSignOn.requested,
  mdtSync: syncPatientRx.requested,
  savePatientMedication: savePatientMedication,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CaseManagerMedList)
