import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { mapTo } from 'rxjs/operators'
import {
  createPermissionSet,
  deletePermissionSet,
  savePermissionSet,
} from '../data/permissionSets'
import { ADMIN_TOOLS_PERMISSION_SETS } from '../router'

export default (action$: any) =>
  action$.pipe(
    ofType(
      createPermissionSet.SUCCEEDED,
      savePermissionSet.SUCCEEDED,
      deletePermissionSet.SUCCEEDED
    ),
    mapTo(push(ADMIN_TOOLS_PERMISSION_SETS))
  )
