import { combineEpics } from 'redux-observable'
import {
  detailPageEntered,
  dialogOpened,
  episodeOfCareSelected,
  episodesOfCareFetched,
  myPatientsClicked,
  primaryOwnerClosed,
  saveForm,
  toggleClicked,
} from './epics'

export const epic = combineEpics(
  detailPageEntered,
  dialogOpened,
  episodeOfCareSelected,
  episodesOfCareFetched,
  myPatientsClicked,
  primaryOwnerClosed,
  saveForm,
  toggleClicked
)
