import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchHospitals, getHospitalsArr } from '../../data/hospitals'

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'county', title: 'County' },
  { name: 'phone', title: 'Phone' },
  { name: 'erPhone', title: 'ER Phone' },
  { name: 'fax', title: 'Fax' },
  { name: 'street', title: 'Street' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'postalCode', title: 'Postal Code' },
]
const columnExtensions = [
  { columnName: 'name', width: 350 },
  { columnName: 'phone', width: 125 },
  { columnName: 'erPhone', width: 125 },
  { columnName: 'fax', width: 125 },
  { columnName: 'state', width: 150 },
  { columnName: 'postalCode', width: 125 },
]
const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

const HospitalLookup = ({
  includeWrapper,
  rows,
  fetchHospitals,
  onSelect,
  rowActions,
  rowActionsCount,
}) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search Hospitals"
    columns={columns}
    columnExtensions={columnExtensions}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchHospitals}
    onSelect={onSelect}
    rowActions={rowActions}
    rowActionsCount={rowActionsCount}
  />
)

HospitalLookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchHospitals: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
}

export default connect(
  state => ({
    rows: getHospitalsArr(state),
  }),
  { fetchHospitals: fetchHospitals.requested }
)(HospitalLookup)
