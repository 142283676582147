import { format, isAfter, isToday, parseJSON } from 'date-fns'
import { partition } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error no export
import { ASSESSMENT_FORM } from '~/apps/assessment/router'
// @ts-expect-error no export
import ConfirmationButton from '~/components/ConfirmationButton'
import PatientLink from '~/components/PatientLink'
// @ts-expect-error no export
import { OpenLink } from '~/components/links'
import { GridLine, GridSection } from '~/components/text'
// @ts-expect-error no export
import { cacheRequested, isCached } from '~/data/cache'
// @ts-expect-error no export
import { getUserId } from '~/data/session'
import { useAction } from '~/hooks'
// @ts-expect-error no export
import { buildMapLink, buildWazeLink } from '~/utils/mapLinkBuilders'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Divider,
  Hidden,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
// @ts-expect-error no export
import EncounterCheckIn from './EncounterCheckIn'
import PatientContactModal from './PatientContactModal'
import WazeIcon from './WazeIcon'

const CRITICAL = 'Critical'
const IN_PERSON = 'in_person'
const TELEPHONIC = 'telephonic'
const VIRTUAL = 'virtual'
const INITIAL = 'initial'
const FOLLOW_UP = 'follow_up'
const URGENT = 'urgent'

const useStyles = makeStyles(({ spacing, palette }) => ({
  eventCard: {
    width: '100%',
  },
  icon: {
    marginRight: spacing(0.5),
  },
  buttonContainer: {
    margin: spacing(1, 0.5),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacing(2),
  },
  iconButtons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  summary: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  actions: {
    flexWrap: 'wrap',
    marginTop: -spacing(1),
    marginBottom: -spacing(1),
    '&:empty': {
      display: 'none',
    },
  },
  avatar: {
    color: palette.getContrastText(palette.primary.main),
    backgroundColor: palette.primary.main,
  },
  alertsLabel: {
    paddingTop: spacing(0.25),
    minHeight: spacing(3),
  },
  alertsLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  alertsIcon: {
    marginRight: spacing(1),
  },
  alertsContent: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    columnGap: spacing(2),
    alignItems: 'center',
    minHeight: spacing(3),
  },
  divider: {
    gridColumnStart: 'span 2',
  },
  alertCompact: {
    gridColumnStart: 'span 3',
  },
}))

const getEventIcon = (event: any) => {
  switch (event.encounterTypeModality) {
    case IN_PERSON:
      return 'person'
    case TELEPHONIC:
      return 'call'
    case VIRTUAL:
      return 'videocam'
    default:
      return 'event'
  }
}

const getEventTitle = (event: any) => {
  const patientName = event.patient?.name
  let phase = null

  switch (event.encounterTypePhase) {
    case INITIAL:
      phase = 'IV'
      break
    case FOLLOW_UP:
      phase = 'FUV'
      break
    case URGENT:
      phase = 'UV'
      break
  }

  if (patientName && phase) {
    return `${patientName} - (${phase})`
  } else if (patientName) {
    return patientName
  } else {
    return event.title
  }
}

const isValidLocation = (event: any) =>
  event.location &&
  (event.encounterTypeModality === IN_PERSON || !event.encounterTypeModality)

type Props = {
  event: any
  launchMedImports: (...args: any[]) => any
  markEventPrepped: (...args: any[]) => any
  openPanel: (...args: any[]) => any
  startAssessment: (...args: any[]) => any
  startingAssessment: boolean
}

const EventCard = ({
  event,
  openPanel,
  launchMedImports,
  markEventPrepped,
  startAssessment,
  startingAssessment,
}: Props) => {
  const classes = useStyles()

  const userId = useSelector(getUserId)
  const cached = useSelector(state => isCached(state, event.encounterId))
  const onRequestCache = useAction(cacheRequested)

  const onPanelButtonClick = (e: any) => {
    openPanel(event.patient?.patientId)
    e.stopPropagation()
  }

  const contactsPreferredFirst = (contacts: any) => {
    const [first, last] = partition(contacts, 'isPreferred')
    return first.concat(last)
  }

  // Eric - We need to move this cast somewhere else at some point
  const startTime = parseJSON(event.start)

  const patientId = event.patient?.patientId
  const patientName = event.patient?.name
  const patientAddress = event.patient?.location
  const patientContacts = contactsPreferredFirst(event.patient?.contacts)
  const patientAlerts = event.patient?.alerts
  const prepRequired = !event.prepComplete && isAfter(startTime, new Date())
  const isVisit = event.type == 'Visit_Appointment'

  const isClosed = [
    'closed',
    'pending_md_review',
    'assessment_signed',
  ].includes(event.assessmentStatus)

  const allowStartAssessment =
    isVisit && event.assessmentStatus === 'not_started'

  return (
    <Accordion
      className={classes.eventCard}
      defaultExpanded={prepRequired || false}
    >
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <div className={classes.summary}>
          <Avatar aria-label="Event" className={classes.avatar}>
            <Icon>{getEventIcon(event)}</Icon>
          </Avatar>
          <div className={classes.title}>
            <Typography>{format(startTime, 'p MM/dd')}</Typography>
            <Typography>{getEventTitle(event)}</Typography>
          </div>
          <div className={classes.iconButtons}>
            {patientId && (
              <Tooltip title="Open Patient Panel">
                <IconButton onClick={onPanelButtonClick}>
                  <Icon>chrome_reader_mode</Icon>
                </IconButton>
              </Tooltip>
            )}

            {isValidLocation(event) && (
              <>
                <Tooltip title="Open in Google Maps">
                  <IconButton
                    target="_blank"
                    rel="noopener noreferrer"
                    href={buildMapLink(event.location || patientAddress)}
                  >
                    <Icon>directions</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open in Waze">
                  <IconButton
                    target="_blank"
                    rel="noopener noreferrer"
                    href={buildWazeLink(event.location || patientAddress)}
                  >
                    <WazeIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.eventCard}>
          <GridSection>
            <GridLine label="Patient" hideIfBlank disableTypography>
              {patientName && (
                <PatientLink patientId={patientId}>{patientName}</PatientLink>
              )}
            </GridLine>

            <GridLine label="Encounter Type" hideIfBlank disableTypography>
              {event.encounterTypeLabel && (
                <Typography variant="body2" color="primary">
                  {event.encounterTypeLabel}
                </Typography>
              )}
            </GridLine>

            <GridLine label="Checked In At" hideIfBlank>
              {event.encounterCheckInAt &&
                format(event.encounterCheckInAt, 'Pp')}
            </GridLine>

            <GridLine label="Checked Out At" hideIfBlank>
              {event.encounterCheckOutAt &&
                format(event.encounterCheckOutAt, 'Pp')}
            </GridLine>

            <GridLine label="Visit Instruction" hideIfBlank>
              {event.patient?.visitInstructions}
            </GridLine>

            <GridLine label="Visit Notes" hideIfBlank>
              {event.description}
            </GridLine>

            {isValidLocation(event) && (
              <>
                <GridLine label="Location" hideIfBlank>
                  {event.location}
                </GridLine>

                {/* Location Alert */}
                {event.locationExact === false && (
                  <>
                    <div className={classes.alertsLabel}>
                      <div className={classes.alertsLabelContainer}>
                        <Hidden smDown>
                          <Icon
                            className={classes.alertsIcon}
                            fontSize="small"
                            color="secondary"
                          >
                            warning
                          </Icon>
                        </Hidden>

                        <Typography variant="caption" color="secondary">
                          Location Alert
                        </Typography>
                      </div>
                    </div>

                    <Typography variant="body2">
                      This address may be inaccurate
                    </Typography>

                    <Divider className={classes.divider} />
                  </>
                )}
              </>
            )}

            {/* Safety Alerts */}
            {patientAlerts.length > 0 && (
              <>
                <div className={classes.alertsLabel}>
                  <div className={classes.alertsLabelContainer}>
                    <Hidden smDown>
                      <Icon
                        className={classes.alertsIcon}
                        fontSize="small"
                        color="secondary"
                      >
                        warning
                      </Icon>
                    </Hidden>

                    <Typography variant="caption" color="secondary">
                      Safety Alerts
                    </Typography>
                  </div>
                </div>

                <div className={classes.alertsContent}>
                  {patientAlerts.map((alert: any) => (
                    <React.Fragment key={alert.description}>
                      {/* Show all data on larger screens */}
                      <Hidden smDown>
                        <Typography variant="caption">
                          {format(new Date(alert.createdAt), 'P')}
                        </Typography>

                        <Typography
                          variant="caption"
                          color={
                            alert.severity === CRITICAL
                              ? 'secondary'
                              : undefined
                          }
                        >
                          {alert.severity}
                        </Typography>

                        <Typography variant="caption">
                          {alert.description}
                        </Typography>
                      </Hidden>

                      {/* Show only alert on small screens */}
                      <Hidden mdUp>
                        <Typography
                          className={classes.alertCompact}
                          variant="caption"
                          color={
                            alert.severity === CRITICAL
                              ? 'secondary'
                              : undefined
                          }
                          component="li"
                        >
                          {alert.description}
                        </Typography>
                      </Hidden>
                    </React.Fragment>
                  ))}
                </div>

                <Divider className={classes.divider} />
              </>
            )}
          </GridSection>
        </div>
      </AccordionDetails>

      <AccordionActions className={classes.actions}>
        {/* Patient Contacts */}
        {patientContacts && patientContacts.length > 0 && (
          <div className={classes.buttonContainer}>
            <PatientContactModal contacts={patientContacts} />
          </div>
        )}

        {/* Med History */}
        {event.title == 'Initial Visit' && (
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="primary"
              onClick={launchMedImports(patientId)}
            >
              Med History
            </Button>
          </div>
        )}

        {/* Prep Complete */}
        {prepRequired && (
          <div className={classes.buttonContainer}>
            <ConfirmationButton
              actionOnConfirm={() => markEventPrepped(event.id)}
              message="Confirm prep work for this visit is complete?"
              buttonLabel="Prep Complete"
              buttonProps={{
                variant: 'contained',
                color: 'primary',
              }}
            />
          </div>
        )}

        {/* Cache Offline */}
        {event.assessmentStatus !== 'not_started' && (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={cached}
              onClick={() => onRequestCache(event.encounterId, patientId)}
            >
              <Icon className={classes.icon} fontSize="small">
                cached
              </Icon>
              Cache Offline
            </Button>
          </div>
        )}

        {/* Encounter Check In */}
        {event.encounterId &&
          userId === event.ownerId &&
          isToday(new Date(event.start)) && (
            <div className={classes.buttonContainer}>
              <EncounterCheckIn
                encounterId={event.encounterId}
                checkedIn={!!event.encounterCheckInAt}
                checkedOut={!!event.encounterCheckOutAt}
              />
            </div>
          )}

        {/* Start Assessment */}
        {allowStartAssessment && (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={startingAssessment}
              onClick={() => startAssessment(event.id)}
            >
              <Icon className={classes.icon} fontSize="small">
                assignment
              </Icon>
              Start Assessment
            </Button>
          </div>
        )}

        {/* Open Assessment */}
        {event.assessmentStatus !== 'not_started' && (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color={isClosed ? undefined : 'secondary'}
              component={OpenLink}
              route={ASSESSMENT_FORM}
              params={{ assessmentId: event.encounterId }}
            >
              <Icon className={classes.icon} fontSize="small">
                {isClosed ? 'assignment_turned_in' : 'assignment_late'}
              </Icon>
              {isClosed ? 'Assessment Signed' : 'Open Assessment'}
            </Button>
          </div>
        )}
      </AccordionActions>
    </Accordion>
  )
}

export default EventCard
