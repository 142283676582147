import { combineEpics } from 'redux-observable'
import checkInsuranceIsValid from './epics/checkInsuranceIsValid'
import onInsuranceCoverageSavedUpdated from './epics/onInsuranceCoverageSavedUpdated'
import onOpenCoverageCreate from './epics/onOpenCoverageCreate'

export default combineEpics(
  checkInsuranceIsValid,
  onInsuranceCoverageSavedUpdated,
  onOpenCoverageCreate
)
