import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

/* RECORD */
export const CaseManager = Record({
  id: null,
  name: null,
})

/* TYPE */
export const CASE_MANAGERS_STORED = 'CASE_MANAGERS/CASE_MANAGERS_STORED'
export const CASE_MANAGER_QUERY_CHANGED =
  'CASE_MANAGERS/CASE_MANAGER_QUERY_CHANGED'

/* Actions */
export const caseManagersStored = (caseManagers: any) => ({
  type: CASE_MANAGERS_STORED,
  caseManagers,
})
export const caseManagerQueryChanged = (caseManagerName: any) => ({
  type: CASE_MANAGER_QUERY_CHANGED,
  caseManagerName,
})

export const key = 'caseManagers'

/* REDUCER */
export default createReducer(key, Map(), {
  [CASE_MANAGERS_STORED]: (state: any, { caseManagers }: any) =>
    state.merge(caseManagers),
})

/* API */
export const fetchCaseManagersByName = (caseManagerName: any) =>
  AspireAPI.get('v1/case_manager', { params: { caseManagerName } }).then(
    (caseManagers: any) =>
      caseManagers.reduce((inc: any, item: any) => {
        const caseManagerToAdd = CaseManager({ id: item.id, name: item.name })
        return inc.set(item.id, caseManagerToAdd)
      }, Map())
  )

/* SELECTORS */
export const getCaseManagers = (state: any) =>
  getReferralManagement(state).get(key, Map())

export const getCaseManagersByName = (state: any, name: any) =>
  getCaseManagers(state).filter((caseManager: any) =>
    caseManager.get('name').toLowerCase().includes(name.toLowerCase())
  )

export const getAutocompleteCaseManagers = createSelector(
  [getCaseManagers],
  caseManagers => {
    const results = caseManagers.toList().map((caseManager: any) => ({
      label: caseManager.get('name'),
      value: caseManager.get('id'),
    }))
    return results.toJS()
  }
)
