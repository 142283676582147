import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const MERGE_ODS_ADMISSION_IDS_SET =
  'reconciliation/MERGE_ODS_ADMISSION_IDS_SET'
export const MERGE_ODS_ADMISSION_IDS_CLEARED =
  'reconciliation/MERGE_ODS_ADMISSION_IDS_CLEARED'

// ACTION CREATORS
export const mergeOdsAdmissionIdsSet = (odsAdmissionIds: any) => ({
  type: MERGE_ODS_ADMISSION_IDS_SET,
  odsAdmissionIds,
})
export const mergeOdsAdmissionIdsCleared = (odsAdmissionIds: any) => ({
  type: MERGE_ODS_ADMISSION_IDS_CLEARED,
  odsAdmissionIds,
})

// KEY
export const key = 'mergeOdsAdmissionIds'

// REDUCERS
export default createReducer(key, Set(), {
  [MERGE_ODS_ADMISSION_IDS_SET]: (state: any, { odsAdmissionIds }: any) =>
    state.union(odsAdmissionIds),
  [MERGE_ODS_ADMISSION_IDS_CLEARED]: (state: any, { odsAdmissionIds }: any) =>
    state.subtract(odsAdmissionIds),
})

// SELECTORS
export const getAllMergeOdsAdmissionIds = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
