import moment from 'moment'
import { combineEpics, ofType } from 'redux-observable'
import { of, timer } from 'rxjs'
import {
  distinctUntilChanged,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import { calendarUnmounted } from '../data/calendar'
import {
  eventsCleared,
  eventsFetchRequested,
  fetchEvents,
  filtersSet,
} from '../data/events'

const refreshTimer = 3 * 60 * 1000 // 3 minutes

const compare = (a, b) => {
  const same_month =
    a.date && b.date && a.date.format('YYYY-MM') == b.date.format('YYYY-MM')
  const same_user = a.userId == b.userId
  return same_month && same_user
}

const getStartDate = date =>
  moment(date).startOf('month').subtract(1, 'week').format('YYYY-MM-DD')

const getEndDate = date =>
  moment(date).endOf('month').add(1, 'week').format('YYYY-MM-DD')

const eventsFetchRequestedEpic = action$ =>
  action$.pipe(
    ofType(eventsFetchRequested),
    distinctUntilChanged(compare),
    pluck('payload'),
    switchMap(({ userId, date }) =>
      timer(0, refreshTimer).pipe(
        takeUntil(action$.pipe(ofType(calendarUnmounted))),
        mergeMap(() => {
          const startDate = getStartDate(date)
          const endDate = getEndDate(date)

          return of(
            fetchEvents.requested(userId, startDate, endDate),
            filtersSet(userId, startDate, endDate)
          )
        })
      )
    )
  )

const eventsClearedEpic = action$ =>
  action$.pipe(
    ofType(fetchEvents.REQUESTED),
    pluck('payload', 'userId'),
    distinctUntilChanged(),
    mapTo(eventsCleared())
  )

export default combineEpics(eventsFetchRequestedEpic, eventsClearedEpic)
