import { Map, fromJS } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'draftProjects'

export const FETCHED_PROJECTS = type(rootKey, 'FETCHED_PROJECTS')
export const NEW_PROJECT_SAVE_ATTEMPTED = type(
  rootKey,
  'NEW_PROJECT_SAVE_ATTEMPTED'
)
export const NEW_PROJECT_SAVED = type(rootKey, 'NEW_PROJECT_SAVED')
export const PROJECT_DELETE_ATTEMPTED = type(
  rootKey,
  'PROJECT_DELETE_ATTEMPTED'
)
export const PROJECT_DELETED = type(rootKey, 'PROJECT_DELETED')

export const loadProjects = creator(FETCHED_PROJECTS, 'projects')
export const loadNewProject = creator(NEW_PROJECT_SAVED, 'project')
export const attemptToSaveNewProject = creator(
  NEW_PROJECT_SAVE_ATTEMPTED,
  'name'
)
export const attemptToDeleteProject = creator(
  PROJECT_DELETE_ATTEMPTED,
  'projectId'
)
export const projectDeleted = creator(PROJECT_DELETED, 'projectId')

export default createReducer(key, new Map(), {
  [FETCHED_PROJECTS]: (state, { payload: { projects } }) => normalize(projects),
  [NEW_PROJECT_SAVED]: (state, { payload: { project } }) =>
    state.set(project.id, fromJS(project)),
  [PROJECT_DELETED]: (state, { payload: { projectId } }) =>
    state.delete(projectId),
})

export const getDraftProjects = pipe(getRoot, get(key))

export const fetchProjects = () => AspireAPI.get('polygonator/draft_projects')

export const saveNewProject = name =>
  AspireAPI.post('polygonator/draft_projects', { name })

export const deleteProject = projectId =>
  AspireAPI.delete(`polygonator/draft_projects/${projectId}`)
