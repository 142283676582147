import { combineReducers } from 'redux-immutable'
import attachmentTypes from './data/attachmentTypes'
import attachments from './data/currentAttachments'
import attachment from './data/newAttachment'
import patient from './data/patient'
import key from './key'

const reducer = combineReducers({
  [attachment.key]: attachment,
  [attachments.key]: attachments,
  [attachmentTypes.key]: attachmentTypes,
  [patient.key]: patient,
})

reducer.key = key

export default reducer
