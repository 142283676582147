// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import carePlanDisabledReducer from './carePlanDisabled'
import legacyReducer from './legacy'
import lockedReducer from './locked'
import problemsReducer from './problems'
import { CARE_PLAN } from './root'

const carePlanReducer = combineReducers({
  [carePlanDisabledReducer.key]: carePlanDisabledReducer,
  [lockedReducer.key]: lockedReducer,
  [legacyReducer.key]: legacyReducer,
  [problemsReducer.key]: problemsReducer,
})

carePlanReducer.key = CARE_PLAN

export default carePlanReducer

export * from './root'
export * from './carePlanDisabled'
export * from './legacy'
export * from './locked'
export * from './problems'
