import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { orderPreview } from '../data/orderPreview'

export default action$ =>
  action$.pipe(
    ofType(orderPreview.SUCCEEDED),
    tap(({ payload, _meta }) => {
      const url = window.URL.createObjectURL(payload)
      const link = document.createElement('a')

      link.setAttribute('href', url)
      link.setAttribute('target', '_blank')
      link.click()

      // cleanup the url after a minute to avoid memory leaks over time
      setTimeout(() => window.URL.revokeObjectURL(url), 1000 * 60)
    }),
    ignoreElements()
  )
