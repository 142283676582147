import { paramsReducer } from 'redux-routable'
import { getUserId } from '~/data/session'

const createDashUserReducer = (router, key) => {
  const reducer = paramsReducer(router, ({ userId }) => userId || null)

  reducer.key = key

  return reducer
}

const createDashUserSelector = (baseSelector, key) => state =>
  baseSelector(state).get(key) || getUserId(state)

export { createDashUserReducer, createDashUserSelector }
