import { combineReducers } from 'redux-immutable'
import createSnfDialog from './data/createSnfDialog'
import snfs from './data/snfs'
import key from './key'

const reducer = combineReducers({
  [createSnfDialog.key]: createSnfDialog,
  [snfs.key]: snfs,
})

reducer.key = key

export default reducer
