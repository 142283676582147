import { entered } from 'redux-routable'
import { filter, first, map } from 'rxjs/operators'
import * as Form from '~/features/noticeOfPrivacyPractices/data/form'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_NOTICE_OF_PRIVACY_PRACTICES } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_NOTICE_OF_PRIVACY_PRACTICES)),
    first(),
    switchTo(state$),
    map(getPatientId),
    map(Form.fetch)
  )
