import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import { problemsInitialized } from '~/features/problems/data'
import { fetchMockForm } from '../data/form'
import { FORMS_DEVELOPMENT_FORM } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(entered(FORMS_DEVELOPMENT_FORM)),
    pluck('payload', 'params'),
    mergeMap(({ templateKey, entity }) =>
      of(fetchMockForm.requested(templateKey, entity), problemsInitialized())
    )
  )
