import * as types from './constants'

export const activeReferralCaseRequested = (caseId: any) => ({
  type: types.ACTIVE_REFERRAL_CASE_REQUESTED,
  caseId,
})

export const activeReferralCaseStored = (data: any) => ({
  type: types.ACTIVE_REFERRAL_CASE_STORED,
  data,
})

export const archiveCaseCompleted = (caseId: any) => ({
  type: types.ARCHIVE_CASE_COMPLETED,
  caseId,
})

export const archiveCaseRequested = (
  caseId: any,
  status: any,
  substatus: any
) => ({
  type: types.ARCHIVE_CASE_REQUESTED,
  caseId,
  status,
  substatus,
})

export const assignmentRequested = (params: any, caseId: any, form: any) => ({
  type: types.ASSIGNMENT_REQUESTED,
  params,
  caseId,
  form,
})

export const assignmentSucceeded = () => ({ type: types.ASSIGNMENT_SUCCEEDED })

export const assignmentFailed = () => ({ type: types.ASSIGNMENT_FAILED })

export const closeChannelSelectionDialog = () => ({
  type: types.CLOSE_CHANNEL_SELECTION_DIALOG,
})

export const channelQueryChanged = (channelName: any) => ({
  type: types.CHANNEL_QUERY_CHANGED,
  channelName,
})

export const channelSelectionDialogRequested = (channels: any) => ({
  type: types.CHANNEL_SELECTION_DIALOG_REQUESTED,
  channels,
})

export const createNewCaseRequested = (caseId: any, form: any) => ({
  type: types.CREATE_NEW_CASE_REQUESTED,
  caseId,
  form,
})

export const deleteContactCompleted = (index: any) => ({
  type: types.DELETE_CONTACT_COMPLETED,
  index,
})

export const deleteContactRequested = (form: any, index: any) => ({
  type: types.DELETE_CONTACT_REQUESTED,
  form,
  index,
})

export const dupeCheckCompleted = () => ({ type: types.DUPE_CHECK_COMPLETED })

export const resetDupeCheck = () => ({ type: types.RESET_DUPE_CHECK })

export const emrDuplicatesFound = (data: any) => ({
  type: types.EMR_DUPLICATES_FOUND,
  data,
})

export const newCaseCreated = (caseId: any) => ({
  type: types.NEW_CASE_CREATED,
  caseId,
})

export const newReferralFormReset = () => ({
  type: types.NEW_REFERRAL_FORM_RESET,
})

export const newReferralOdsLookupRequested = (params: any) => ({
  type: types.NEW_REFERRAL_ODS_LOOKUP_REQUESTED,
  params,
})

export const newReferralValidationRequested = (params: any) => ({
  type: types.NEW_REFERRAL_VALIDATION_REQUESTED,
  params,
})

export const odsLookupCompleted = () => ({ type: types.ODS_LOOKUP_COMPLETED })

export const odsLookupMatchesFound = (results: any) => ({
  type: types.ODS_LOOKUP_MATCHES_FOUND,
  results,
})

export const physicianQueryChanged = (physicianName: any) => ({
  type: types.PHYSICIAN_QUERY_CHANGED,
  physicianName,
})

export const referralCasesRequested = () => ({
  type: types.REFERRAL_CASES_REQUESTED,
})

export const submitCaseCompleted = (caseId: any) => ({
  type: types.SUBMIT_CASE_COMPLETED,
  caseId,
})

export const submitCaseFailed = () => ({ type: types.SUBMIT_CASE_FAILED })

export const submitCaseRequested = (caseId: any) => ({
  type: types.SUBMIT_CASE_REQUESTED,
  caseId,
})

export const requestReferralCaseStatusUpdate = (
  caseId: any,
  status: any,
  substatus: any
) => ({
  type: types.STATUS_UPDATE_REQUESTED,
  caseId,
  status,
  substatus,
})

export const referralCaseStatusUpdated = ({
  caseId,
  status,
  substatus,
}: any) => ({
  type: types.STATUS_UPDATE_COMPLETED,
  caseId,
  status,
  substatus,
})

export const manualAssignmentRequested = (
  caseId: any,
  appId: any,
  channelId: any,
  careTeamId: any
) => ({
  type: types.MANUAL_ASSIGNMENT_REQUESTED,
  caseId,
  appId,
  channelId,
  careTeamId,
})

export const manualAssignmentComplete = () => ({
  type: types.MANUAL_ASSIGNMENT_COMPLETED,
})

export const patientReferralsStored = (payload: any) => ({
  type: types.PATIENT_EXISTING_REFERRALS_FETCHED,
  payload,
})

export const patientReferralsRequested = (payload: any) => ({
  type: types.PATIENT_EXISTING_REFERRALS_FETCH_REQUESTED,
  payload,
})

export const additionalReferralSearch = () => ({
  type: types.ADDITIONAL_REFERRAL_SEARCH,
})

export const additionalReferralSelected = (patientId: any) => ({
  type: types.ADDITIONAL_REFERRAL_SELECTED,
  payload: patientId,
})

export const additionalReferralSaveRequested = (payload: any) => ({
  type: types.ADDITIONAL_REFERRAL_SAVE_REQUESTED,
  payload,
})

export const additonalReferralSaveCompleted = (payload: any) => ({
  type: types.ADDITIONAL_REFERRAL_SUCESSFULLY_SAVED,
  payload,
})

export const potentialChannelsUpdated = (payload: any) => ({
  type: types.POTENTIAL_CHANNELS_UPDATED,
  payload,
})

export const potentialCareTeamsUpdated = (payload: any) => ({
  type: types.POTENTIAL_CARE_TEAMS_UPDATED,
  payload,
})

export const caseIdChanged = (payload: any) => ({
  type: types.CASE_ID_CHANGED,
  payload,
})

export const requestStartPolling = (payload: any) => ({
  type: types.POLL_FOR_CASE_SYNC_COMPLETION_START,
  payload,
})

export const requestStopPolling = (payload: any) => ({
  type: types.POLL_FOR_CASE_SYNC_COMPLETION_STOP,
  payload,
})
