import { List } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'
import { fetchConfig } from './config'

const PCP = 'pcp'

const creator = scopedCreator(key)
export const pcpCleared = creator('PCP_CLEARED')

export default createReducer(PCP, List(), {
  [fetchConfig.SUCCEEDED]: (_state, { payload: { pcp } }) => pcp,
  [pcpCleared]: () => List(),
})

export const getPrimaryCarePhysician = compose(get(PCP), getRoot)
