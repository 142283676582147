import React from 'react'
import { Field } from 'redux-form/immutable'
import { NumberField, TextField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { required } from '../../../utils/formValidators'
import FormFieldWrapper from './FormFieldWrapper'

const styles = {
  fullWidth: {
    width: '100%',
  },
}

class MedInfo extends React.PureComponent {
  render() {
    const { classes } = this.props

    return (
      <FormFieldWrapper title="Medication Information" nonCollapsible>
        <Field
          component={TextField}
          className={classes.fullWidth}
          label="Drug"
          name="drug"
          validate={[required]}
          disabled
        />
        <Field
          component={NumberField}
          className={classes.fullWidth}
          label="DEA Schedule Code"
          name="schedule"
          disabled={true}
          validate={[required]}
        />
      </FormFieldWrapper>
    )
  }
}

MedInfo.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(MedInfo)
