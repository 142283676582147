import classnames from 'classnames'
import React from 'react'
import { TextField } from '~/components/fields'
import { useInput, useUpdateEffect } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  field: {
    width: '100%',
    marginBottom: spacing(1),
  },
  notes: {
    marginTop: spacing(1),
  },
}))

const DischargeRecommendation = ({ onFormChange }) => {
  const classes = useStyles()

  const [dischargeNotes, setDischargeNotes] = useInput()

  useUpdateEffect(() => {
    const valid = Boolean(dischargeNotes)

    onFormChange({ dischargeNotes, valid })
  }, [dischargeNotes])

  return (
    <React.Fragment>
      {/* Discharge Notes */}
      <TextField
        className={classnames(classes.field, classes.notes)}
        variant="outlined"
        label="Discharge Notes"
        onChange={setDischargeNotes}
        value={dischargeNotes}
        multiline
        rowsMax={6}
        error={!dischargeNotes}
      />
    </React.Fragment>
  )
}

DischargeRecommendation.propTypes = {
  onFormChange: PropTypes.func.isRequired,
}

export default DischargeRecommendation
