import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mapTo, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  logEventPrep,
  logPrepCompleted,
  requestLogEventPrep,
} from '../data/dailyEvents'

const logPrepWork = eventId =>
  loading(
    from(logEventPrep(eventId)).pipe(mapTo(logPrepCompleted(eventId))),
    'Error logging prep work.'
  )

const initialLoad = action$ =>
  action$.pipe(
    ofType(requestLogEventPrep),
    pluck('payload', 'eventId'),
    mergeMap(logPrepWork)
  )

export default initialLoad
