import { entered } from 'redux-routable'
import { filter, first, map } from 'rxjs/operators'
// @ts-expect-error no export
import { getUserId } from '~/data/session'
// @ts-expect-error no export
import { switchTo } from '~/utils/operators'
import { fetchUserSignature } from '../data/userSignature'
import { USER_SETTINGS_USER_SIGNATURE } from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(entered(USER_SETTINGS_USER_SIGNATURE)),
    first(),
    switchTo(state$),
    map(getUserId),
    map(fetchUserSignature.requested)
  )
