import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getAppFinder } from './root'

// KEY
export const key = 'potentialAPPs'

// RECORD
export const PotentialAPP = Record({
  id: null,
  name: null,
  censusPercentage: null,
  appType: null,
})

// TYPE
export const POTENTIAL_APPS_FETCH_FAILED = `${key}/POTENTIAL_APPS_FETCH_FAILED`
export const POTENTIAL_APPS_FETCH_REQUESTED = `${key}/POTENTIAL_APPS_FETCH_REQUESTED`
export const POTENTIAL_APPS_STORED = `${key}/POTENTIAL_APPS_STORED`

// ACTION
export const potentialAPPsFetchFailed = () => ({
  type: POTENTIAL_APPS_FETCH_FAILED,
})

export const potentialAPPsFetchRequested = addressMap => ({
  type: POTENTIAL_APPS_FETCH_REQUESTED,
  payload: addressMap,
})

export const potentialAPPsStored = potentialAPPs => ({
  type: POTENTIAL_APPS_STORED,
  payload: potentialAPPs,
})

// SELECTOR
export const selectPotentialAPPs = pipe(getAppFinder, get(key))

// REDUCER
const createEachPotentialAPP = ({ censusPercentage, id, name, appType }) =>
  PotentialAPP({
    id,
    name,
    censusPercentage,
    appType,
  })

const createListOfPotentialAPPs = (_state, { payload }) =>
  List(payload.map(createEachPotentialAPP))

export default createReducer(key, List(), {
  [POTENTIAL_APPS_FETCH_FAILED]: () => List(),
  [POTENTIAL_APPS_STORED]: createListOfPotentialAPPs,
})

// API
export const fetchPotentialAPPs = address =>
  AspireAPI.get('v1/referral/address_lookup/app', {
    params: {
      address1: address.get('street1'),
      address2: address.get('street2'),
      city: address.get('city'),
      state_code: address.get('state'),
      postal_code: address.get('postalCode'),
    },
  })
