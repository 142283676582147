import { Redirect, Route, Router, Scope } from 'redux-routable'
import key from './key'

export const PES_DASHBOARD_APP_LIST = `${key}.appList`
export const PES_DASHBOARD_PATIENT_LIST = `${key}.patientList`
export const PES_DASHBOARD_REFERRAL_SCHEDULING_APP = `${key}.referralSchedulingAPP`
export const PES_DASHBOARD_REFERRAL_SCHEDULING_PATIENT = `${key}.referralSchedulingPatient`
export const PES_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const PES_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const PES_DASHBOARD_PATIENT_SEARCH = `${key}.patientSearch`
export const PES_DASHBOARD_CALL_LIST = `${key}.callList`
export const PES_DASHBOARD_CALENDAR = `${key}.calendar`
export const PES_DASHBOARD_APP_FINDER = `${key}.appFinder`
export const PES_DASHBOARD_USER_TIMELINE = `${key}.userTimeline`

export const referralSchedulingRouter = Router([
  Route(PES_DASHBOARD_REFERRAL_SCHEDULING_APP, '/:appId'),
  Route(PES_DASHBOARD_REFERRAL_SCHEDULING_PATIENT, '/:appId/:patientId'),
])

export const tasksRouter = Router([
  Route(PES_DASHBOARD_TASKS_BY_USER, '/'),
  Route(PES_DASHBOARD_TASK_DETAIL, '/:taskId'),
])

export default Router([
  Route(PES_DASHBOARD_APP_LIST, '/:userId?/app-list'),
  Route(PES_DASHBOARD_PATIENT_LIST, '/:userId?/patient-list'),
  Scope('/:userId?/referral-scheduling', referralSchedulingRouter),
  Scope('/:userId?/tasks', tasksRouter),
  Route(PES_DASHBOARD_PATIENT_SEARCH, '/:userId?/patient-search'),
  Route(PES_DASHBOARD_CALL_LIST, '/:userId?/call-list'),
  Route(PES_DASHBOARD_CALENDAR, '/:userId?/calendar'),
  Route(PES_DASHBOARD_APP_FINDER, '/:userId?/app-finder'),
  Route(PES_DASHBOARD_USER_TIMELINE, '/:userId?/user-timeline'),
  Redirect(PES_DASHBOARD_APP_LIST),
])
