import { Record } from 'immutable'
import { ofType } from 'redux-observable'
import { mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import hospitalizationsKey from '../key'

// KEY
const key = 'hospitalizationDialog'

// CREATORS
const creator = scopedCreator(key)

export const Dialog = Record({
  mode: null,
  patientId: null,
  selectedHospitalization: null,
})

export const modeSet = creator('MODE_SET', [
  'mode',
  'patientId',
  'selectedHospitalization',
])

// REDUCER
export default createReducer(key, Dialog(), {
  [modeSet]: (state, { payload }) => Dialog(payload),
})

export const getHospitalizationDialog = pipe(get(hospitalizationsKey), get(key))

// Epic
export const epic = action$ =>
  action$.pipe(
    ofType(modeSet),
    mapTo(
      fetchDistinctFieldValues([
        'hospitalization_bed_type',
        'hospitalization_discharged_to',
        'hospitalization_notified_by',
        'hospitalization_reason',
        'yes_no',
      ])
    )
  )
