import React from 'react'
import { connect } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { Stakeholder } from '../../data/common/shared'
import { deleteStakeholder } from '../../data/stakeholders'

class DeleteStakeholder extends React.PureComponent {
  onDelete = () => {
    const { row, deleteStakeholder, onClose } = this.props

    deleteStakeholder(row.id)
    onClose()
  }

  render() {
    const { open, row, onClose } = this.props
    const message = `Are you sure you want to delete ${row.name} as a stakeholder?`

    return (
      <ActionDialog
        open={open}
        title="Delete Stakeholder"
        mode="delete"
        onDelete={this.onDelete}
        onClose={onClose}
      >
        <Typography>{message}</Typography>
      </ActionDialog>
    )
  }
}

DeleteStakeholder.propTypes = {
  open: PropTypes.bool.isRequired,
  row: PropTypes.record,
  deleteStakeholder: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DeleteStakeholder.defaultProps = {
  row: Stakeholder(),
}

export default connect(null, {
  deleteStakeholder: deleteStakeholder.requested,
})(DeleteStakeholder)
