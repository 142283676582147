import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { getIsCaseMgmt } from '~/features/patientInfo'
import PharmacySearch from '../components/PharmacySearch'
import {
  getPatientId,
  getPatientPharmacyId,
  savePatientPreferredPharmacy,
} from '../data/patientInformation'
import {
  fetchPharmacySearch,
  getPharmacySearchResultsArray,
} from '../data/pharmacySearch'

const resetPharmSearch = () => reset('medications.pharmacySearch')

const mapStateToProps = state => ({
  searchResults: getPharmacySearchResultsArray(state),
  patientPreferredPharmacyId: getPatientPharmacyId(state),
  patientId: getPatientId(state),
  isCaseMgmt: getIsCaseMgmt(state),
})

const mapDispatchToProps = {
  reset: resetPharmSearch,
  searchRequested: fetchPharmacySearch.requested,
  selectPatientPreferredPharmacy: savePatientPreferredPharmacy.requested,
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacySearch)
