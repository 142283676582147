import React, { useCallback } from 'react'
import { InfoDialog } from '~/components/dialogs'
import { patientPanelOpened } from '~/data/patientPanel'
import { useAction, useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core'

const RowActions = ({ row }) => {
  const [open, toggleOpenTrue, toggleOpenFalse] = useToggle()

  const openPatientPanel = useAction(patientPanelOpened)

  const notesTooltip = row.notes ? 'View Call Notes' : 'No Call Notes'

  const onOpenPanel = useCallback(() => {
    openPatientPanel(row.patientId)
  }, [row, openPatientPanel])

  return (
    <React.Fragment>
      <Tooltip title="Open Patient Panel" placement="bottom">
        <IconButton onClick={onOpenPanel}>
          <Icon>chrome_reader_mode</Icon>
        </IconButton>
      </Tooltip>

      <Tooltip title={notesTooltip} placement="bottom">
        <span>
          <IconButton disabled={!row.notes} onClick={toggleOpenTrue}>
            <Icon>note</Icon>
          </IconButton>
        </span>
      </Tooltip>

      {open && (
        <InfoDialog
          open
          title="Call Notes"
          onClose={toggleOpenFalse}
          maxWidth="sm"
          fullWidth
        >
          <Typography>{row.notes}</Typography>
        </InfoDialog>
      )}
    </React.Fragment>
  )
}

RowActions.propTypes = {
  row: PropTypes.object.isRequired,
}

export default RowActions
