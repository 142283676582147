import React from 'react'
import PropTypes from '~/utils/propTypes'
import GroupSettingsField from './GroupSettingsField'
import RecurrenceSettingsField from './RecurrenceSettingsField'

const BatchSettingsField = props => {
  const { event } = props
  const batchActive = !event.id || event.batchId

  return batchActive ? (
    <React.Fragment>
      <RecurrenceSettingsField {...props} />
      <GroupSettingsField {...props} />
    </React.Fragment>
  ) : null
}

BatchSettingsField.propTypes = {
  userId: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  event: PropTypes.record.isRequired,
}

export default BatchSettingsField
