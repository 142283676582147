import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import {
  catchError,
  debounceTime,
  mapTo,
  mergeMap,
  pluck,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/session' or its corresp... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/notifications/utils... Remove this comment to see the full error message
import getChannelName from '~/features/notifications/utils/getChannelName'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/alertOnError' or its c... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/channels' or its corre... Remove this comment to see the full error message
import { pushToChannel } from '~/utils/channels'
import {
  MARK_NOTIFICATION_UNREAD_REQUESTED,
  markNotificationUnreadSucceeded,
} from '../data/common/shared'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(MARK_NOTIFICATION_UNREAD_REQUESTED),
    debounceTime(250),
    pluck('payload', 'notificationId'),
    mergeMap(notificationId => {
      const state = state$.value
      const userId = getUserId(state)

      return from(
        pushToChannel(getChannelName(userId), 'mark_notification_unread', {
          notificationId,
        })
      ).pipe(
        mapTo(markNotificationUnreadSucceeded()),
        catchError(showError('Unable to mark notification as unread'))
      )
    })
  )
