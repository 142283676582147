import { Route, Router } from 'redux-routable'
import key from './key'

export const ASSESSMENT_FORM = `${key}.form`
export const ASSESSMENT_LEGACY = `${key}.legacy`

export default Router([
  Route(ASSESSMENT_LEGACY, '/legacy/:legacyAssessmentId'),
  Route(ASSESSMENT_FORM, '/:assessmentId'),
])
