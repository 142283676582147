import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientInfo' or its... Remove this comment to see the full error message
import { fetchPatient } from '~/features/patientInfo'
import { saveDischargeRecommendation } from '../data/discharges'

export default (action$: any) =>
  action$.pipe(
    ofType(saveDischargeRecommendation.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'patientId'),
    map(fetchPatient.requested)
  )
