import { OrderedMap, List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error FIXME
import Request from '~/utils/Request'
// @ts-expect-error FIXME
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'

const key = 'telephonicEncounterTypes'

export const TelephonicEncounterType = Record({
  type: null,
  label: null,
  modality: null,
  defaultVisitDuration: null,
})

const filterTelephonicEncounterTypes = (types: any) =>
  List(
    types
      .filter((type: any) => type.modality === 'telephonic')
      .map((type: any) => TelephonicEncounterType({ ...type }))
  )

export const fetchTelephonicEncounterTypes = Request({
  typePrefix: key,
  typeBase: 'FETCH_TELEPHONIC_ENCOUNTER_TYPES',
  requestParams: ['userId', 'patientId', 'dateOfService'],
  operation: (userId: string, patientId: string, dateOfService: string) =>
    AspireAPI.get('encounters/type', {
      params: {
        user_id: userId,
        patient_id: patientId,
        date_of_service: dateOfService,
        scheduling: true,
      },
    }),
  transform: filterTelephonicEncounterTypes,
  messages: {
    failed: 'There was a problem fetching Telephonic encounter types',
  },
})

export default createReducer(key, OrderedMap(), {
  [fetchTelephonicEncounterTypes.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getTelephonicEncounterTypes = pipe(get('assessment'), get(key))
