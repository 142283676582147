import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { dialogClosed } from '../data/dialog'
import {
  cancelTask,
  completeTask,
  reassignTask,
  snoozeTask,
} from '../data/tasks'

export default action$ =>
  action$.pipe(
    ofType(
      cancelTask.SUCCEEDED,
      completeTask.SUCCEEDED,
      reassignTask.SUCCEEDED,
      snoozeTask.SUCCEEDED
    ),
    map(dialogClosed)
  )
