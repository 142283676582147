import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import { ListItem, ListItemText } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { setModifierSelected, updateModifiersInGroup } from '../data/imo'
import { getImoSelectedProblem } from '../data/utils/selectors'
import { Combination, Modifier } from '../types/types'

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 752,
      backgroundColor: palette.background.default,
      textAlign: 'center',
    },
  })
)

type Props = {
  problemId: string
  modifierGroupType: string
  modifier: Modifier
}

const ImoModifier = ({ problemId, modifierGroupType, modifier }: Props) => {
  const classes = useStyles()
  const [selected, setSelected] = useState(false)
  const { modifierCombinations }: any = useSelector(
    getImoSelectedProblem(problemId)
  )
  const updateEnabledModifiers = useAction(updateModifiersInGroup)
  const updateModifierSelected = useAction(setModifierSelected)

  const { code, title, disabled, combinations } = modifier

  const getSelectedAllowedModifiers = () => {
    const applicableCombinations: string[] = []
    combinations.map((combinationId: string) => {
      applicableCombinations.push(
        ...modifierCombinations.find(
          (combination: Combination) => combination.id === combinationId
        ).allowedModifiers
      )
    })
    return [...new Set(applicableCombinations)]
  }

  const resetAllowedModifiers = (modifierCombinations: Combination[]) => {
    const combinations: string[] = []
    modifierCombinations.map((combination: Combination) =>
      combinations.push(...combination.allowedModifiers)
    )
    return [...new Set(combinations)]
  }

  const handleClick = (
    modifierId: string,
    modifierCombinations: Combination[]
  ) => {
    const allowedModifiers = !selected
      ? getSelectedAllowedModifiers()
      : resetAllowedModifiers(modifierCombinations)

    updateEnabledModifiers({
      allowedModifiers,
      problemId,
      modifierId,
      modifierGroupType,
    })
    updateModifierSelected(problemId)
    setSelected(!selected)
  }

  return (
    <ListItem
      key={code}
      button
      className={classes.root}
      disabled={disabled}
      onClick={() => handleClick(code, modifierCombinations)}
      selected={selected}
    >
      <ListItemText primary={title} />
    </ListItem>
  )
}

export default ImoModifier
