import { connect } from 'react-redux'
import AttachmentsGrid from '../components/AttachmentsGrid'
import {
  downloadAttachment,
  getAttachmentsArray,
} from '../data/currentAttachments'

const mapStateToProps = state => ({
  attachments: getAttachmentsArray(state),
})

const mapDispatchToProps = {
  onAttachmentClick: downloadAttachment.requested,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsGrid)
