import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DevXGrid from '~/components/DevXGrid'
import WithTooltip from '~/components/WithTooltip'
import { useMountEffect, useUpdateEffect } from '~/hooks'
import generateId from '~/utils/generateId'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    marginTop: spacing(1),
    overflowX: 'auto',
  },
}))

const TableField = props => {
  const classes = useStyles()
  const { schema, formData } = props
  const [localFormData, setLocalFormData] = useState([])

  useMountEffect(() => {
    const startingData = formData.map(data => {
      data['id'] = generateId()
      return pruneEmpty(data)
    })
    setLocalFormData(startingData)
  })

  useUpdateEffect(() => {
    formData.splice(0, formData.length)
    localFormData.forEach(row => {
      formData.push(row)
    })
  }, [formData, localFormData])

  const pruneEmpty = obj => {
    for (const key in obj) {
      if (obj[key] === '' || obj[key] === undefined || obj[key] === null)
        delete obj[key]
    }
    return obj
  }

  const columns = Object.values(schema['items']['properties']).map(property => {
    const isRequired =
      schema?.items?.required &&
      Object.values(schema.items.required).find(
        requiredProperty => requiredProperty === property['tag']
      )
    const requiredUITag = isRequired ? '*' : ''
    return {
      name: property['tag'],
      title: `${property['title']} ${requiredUITag}`,
      displayComponent: WithTooltip,
    }
  })
  const rows = localFormData
  const getRowId = row => row.id

  return (
    <Paper className={classes.paper}>
      <DevXGrid
        {...props}
        rows={rows}
        columns={columns}
        selectSingleRow={true}
        selectable={true}
        getRowId={getRowId}
      />
    </Paper>
  )
}

TableField.propTypes = {
  schema: PropTypes.object,
  formData: PropTypes.array,
}

TableField.defaultProps = {
  schema: {},
  formData: {},
}

export default TableField
