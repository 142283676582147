import { changedTo } from 'redux-routable'
import { filter, first, mapTo } from 'rxjs/operators'
import { fetchFormList } from '../data/formList'
import { FORMS_DEVELOPMENT_HOME } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(changedTo(FORMS_DEVELOPMENT_HOME)),
    first(),
    mapTo(fetchFormList.requested())
  )
