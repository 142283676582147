import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { downloadDocument } from '../data/assessments'

export default action$ =>
  action$.pipe(
    ofType(downloadDocument.SUCCEEDED),
    tap(({ payload, meta }) => {
      const url = window.URL.createObjectURL(payload)
      const filename = `encounter_${meta.request.payload.encounterId}.pdf`
      const link = document.createElement('a')

      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.click()

      window.URL.revokeObjectURL(url)
    }),
    ignoreElements()
  )
