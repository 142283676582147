import React from 'react'
import Info from '~/components/text/Info'
import { Physician } from '~/data/physicians'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  container: { marginBottom: spacing(2) },
})

const PhysicianInfo = ({ classes, physician: p }) => (
  <div className={classes.container}>
    <Typography variant="subtitle1">Selected Physician</Typography>
    <Info label="Name">{p.name}</Info>
    <Info label="Practice">{p.practiceName}</Info>
    <Info label="Address">{p.mailingStreet}</Info>
    <Info label="City">{p.mailingCity}</Info>
    <Info label="State">{p.mailingStateCode}</Info>
    <Info label="ZIP">{p.mailingPostalCode}</Info>
    <Info label="Fax">{p.fax}</Info>
    <Info label="Phone">{p.phone}</Info>
    <Info label="Specialty">{p.specialty}</Info>
  </div>
)

PhysicianInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  physician: PropTypes.instanceOf(Physician).isRequired,
}

export default withStyles(styles)(PhysicianInfo)
