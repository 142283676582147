import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PatientContacts } from '~/apps/patientRecord/components/PatientContacts/PatientContactDetails/PatientContacts'
import { PatientContactForm } from '~/apps/patientRecord/components/PatientContacts/PatientContactForm/PatientContactForm'
import { getCurrentContact } from '~/apps/patientRecord/data/patientContactData'
import {
  addContactDialogOpened,
  addContactDialogType,
  getDialogTypeValue,
} from '~/apps/patientRecord/data/patientContactDialog'
import {
  fetchPatientContacts,
  getContacts,
  syncPatientContacts,
} from '~/apps/patientRecord/data/patientContacts'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import InvisibleDivider from '~/components/InvisibleDivider'
import { useAction, usePending } from '~/hooks'
import { Button, CircularProgress, Icon } from '@material-ui/core'
import { getCurrentPatientId } from '../../data/currentPatientId'

export default function Contacts() {
  const patientId = useSelector(getCurrentPatientId)
  const [editingContact, setEditingContact] = React.useState()
  const contacts = useSelector(getContacts)
  const actionType = useSelector(getDialogTypeValue)
  const getPatientContact = useAction(fetchPatientContacts.requested)
  const syncPatientContact = useAction(syncPatientContacts.requested)
  const syncContactsPending = usePending(syncPatientContacts)
  const openAddContactDialog = useAction(addContactDialogOpened)
  const openAddContactAction = useAction(addContactDialogType)
  const contactInfo = useSelector(getCurrentContact)

  const handleDialogOpen = () => {
    openAddContactAction()

    openAddContactDialog()
  }

  const [contactsData, setContactsData] = React.useState(contacts.toArray())

  const syncContacts = () => {
    syncPatientContact(patientId)
  }

  useEffect(() => {
    if (patientId) {
      getPatientContact(patientId)
    }
  }, [patientId])

  useEffect(() => {
    setContactsData(contacts.toArray())
  }, [contacts])

  useEffect(() => {
    setEditingContact(contactInfo)
  }, [contactInfo])

  return (
    <React.Fragment>
      <DevXTitleHeader title="Patient Contacts">
        <Button
          variant="outlined"
          disabled={syncContactsPending}
          onClick={() => syncContacts()}
        >
          <Icon>sync</Icon>
          Sync Contacts
        </Button>
        <Button variant="outlined" onClick={() => handleDialogOpen()}>
          Add Contact
        </Button>
      </DevXTitleHeader>
      {syncContactsPending ? (
        <CircularProgress />
      ) : (
        <PatientContacts
          contacts={contactsData}
          readOnly={false}
          sourceIsAssessment={false}
          onChange={null}
        />
      )}

      <PatientContactForm
        contact={actionType === 'Edit' ? editingContact : null}
        actionType={actionType}
        patientId={patientId}
        sourceIsAssessment={false}
        onChange={null}
      ></PatientContactForm>

      <InvisibleDivider />
    </React.Fragment>
  )
}
