import { ofType } from 'redux-observable'
import { debounceTime, filter, map, pluck } from 'rxjs/operators'
import { searchPhysicians } from '~/data/physicians'
import { physicianSearchUpdated } from '../data/physicianSearchUpdated'

export default action$ =>
  action$.pipe(
    ofType(physicianSearchUpdated),
    debounceTime(500),
    pluck('payload'),
    filter(text => text.length > 1),
    map(searchPhysicians.requested)
  )
