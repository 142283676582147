import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useToggle } from '~/hooks'
import * as clipboard from '~/utils/clipboard'
import { Icon, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  copy: {
    color: 'inherit',
    marginLeft: spacing(0.5),
    textDecoration: 'none',
    lineHeight: 'initial',
  },
  noSelect: {
    userSelect: 'none',
  },
}))

const CopyPhoneNumber = ({ phoneNumber, color }) => {
  const classes = useStyles()

  const [showCopied, toggleShowCopied, untoggleShowCopied] = useToggle()
  const divRef = useRef()

  const onCopy = e => {
    e.preventDefault()

    if (clipboard.copy(phoneNumber, divRef.current)) {
      toggleShowCopied()
    }
  }

  return (
    <div
      className={classes.container}
      ref={e => {
        divRef.current = e
      }}
    >
      <Typography color={color}>{phoneNumber}</Typography>

      <Tooltip
        placement="right"
        title={
          showCopied
            ? 'Copied to clipboard!'
            : 'Automatically copy this phone number to your clipboard'
        }
        TransitionProps={{
          onExited: untoggleShowCopied,
        }}
        disableTouchListener
        disableFocusListener
      >
        <a
          className={classnames(classes.copy, classes.noSelect)}
          href="#"
          onClick={onCopy}
        >
          <Icon fontSize="small">file_copy</Icon>
        </a>
      </Tooltip>
    </div>
  )
}

CopyPhoneNumber.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  color: PropTypes.string,
}

CopyPhoneNumber.defaultProps = {
  color: 'initial',
}

export default CopyPhoneNumber
