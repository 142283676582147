// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import {
  encounterReviewFormDataChanged,
  encounterReviewFormDataChangedByTag,
  fetchEncounterReview,
  saveEncounterReview,
} from './currentEncounterReview'
import {
  fetchPatientOutlierFinalDecision,
  patientOutlierFinalDecisionFormDataChanged,
  savePatientOutlierFinalDecision,
} from './currentPatientOutlierFinalDecision'
import {
  fetchPatientOutlierReview,
  patientOutlierReviewFormDataChanged,
  savePatientOutlierReview,
} from './currentPatientOutlierReview'

const SAVE_INFO = 'saveInfo'
export const CHANGED = 'changed'
export const SAVING = 'saving'
export const SAVED = 'saved'

const checkStatus = (status: any) => (status === 'created' ? CHANGED : SAVED)

export default createReducer(SAVE_INFO, CHANGED, {
  // Encounter Reviews
  [fetchEncounterReview.REQUESTED]: () => SAVED,
  [fetchEncounterReview.SUCCEEDED]: (
    _state: any,
    { payload: { status } }: any
  ) => checkStatus(status),
  // @ts-expect-error can we deal with the toString being automatically called?
  [encounterReviewFormDataChanged]: () => CHANGED,
  // @ts-expect-error can we deal with the toString being automatically called?
  [encounterReviewFormDataChangedByTag]: () => CHANGED,
  [saveEncounterReview.REQUESTED]: () => SAVING,
  [saveEncounterReview.SUCCEEDED]: () => SAVED,
  [saveEncounterReview.FAILED]: () => CHANGED,
  // Patient Outlier Reviews
  [fetchPatientOutlierReview.REQUESTED]: () => SAVED,
  [fetchPatientOutlierReview.SUCCEEDED]: (
    _state: any,
    { payload: { status } }: any
  ) => checkStatus(status),
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientOutlierReviewFormDataChanged]: () => CHANGED,
  [savePatientOutlierReview.REQUESTED]: () => SAVING,
  [savePatientOutlierReview.SUCCEEDED]: () => SAVED,
  [savePatientOutlierReview.FAILED]: () => CHANGED,
  // Patient Outlier Final Decisions
  [fetchPatientOutlierFinalDecision.REQUESTED]: () => SAVED,
  [fetchPatientOutlierFinalDecision.SUCCEEDED]: (
    _state: any,
    { payload: { status } }: any
  ) => checkStatus(status),
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientOutlierFinalDecisionFormDataChanged]: () => CHANGED,
  [savePatientOutlierFinalDecision.REQUESTED]: () => SAVING,
  [savePatientOutlierFinalDecision.SUCCEEDED]: () => SAVED,
  [savePatientOutlierFinalDecision.FAILED]: () => CHANGED,
})

export const getSaveInfo = pipe(getRoot, get(SAVE_INFO))
