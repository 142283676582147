import { ofType } from 'redux-observable'
import { debounceTime, filter, map, pluck } from 'rxjs/operators'
import { searchVendors } from '~/data/vendors'
import { VENDOR_SEARCH_UPDATED } from '../data/textUpdate'

export default action$ =>
  action$.pipe(
    ofType(VENDOR_SEARCH_UPDATED),
    debounceTime(500),
    pluck('payload'),
    filter(({ text }) => text.length > 1),
    // the second argument to searchVendors.requested is 'type' and we don't want to pass index to it from `map`
    map(({ text, type, patientId }) =>
      searchVendors.requested(text, type, patientId)
    )
  )
