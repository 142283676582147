import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import FieldWrapper from '../../FieldWrapper'
import memoizedWrapper from '../../widgets/memoizedWrapper'
import AddressPartField from './AddressPartField'

const uiSchemaBase = {
  state: {
    'ui:widget': 'select',
  },
}

const styles = ({ spacing }) => ({
  root: {
    display: 'grid',
    marginTop: spacing(1),
    gridColumnGap: spacing(1) + 'px',
    gridRowGap: spacing(1) + 'px',
    gridAutoFlow: ({ formContext }) => (formContext.compact ? 'row' : 'column'),
  },
})

const AddressField = props => {
  const { classes, uiSchema, ...restProps } = props
  const uiSchemaNew = { ...uiSchemaBase, ...uiSchema }
  const partProps = { ...restProps, uiSchema: uiSchemaNew }

  return (
    <FieldWrapper schema={props.schema} uiSchema={uiSchema}>
      <div className={classes.root}>
        <AddressPartField
          {...partProps}
          part="street"
          label="Street"
          flex="2"
        />
        <AddressPartField {...partProps} part="city" label="City" flex="2" />
        <AddressPartField {...partProps} part="state" label="State" flex="2" />
        <AddressPartField
          {...partProps}
          part="postalCode"
          label="Postal Code"
          flex="1"
        />
      </div>
    </FieldWrapper>
  )
}

AddressField.propTypes = {
  classes: PropTypes.object.isRequired,
  formContext: PropTypes.object.isRequired,
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
  formData: PropTypes.object,
}

AddressField.defaultProps = {
  schema: {},
  uiSchema: {},
  formData: {},
}

export default compose(memoizedWrapper, withStyles(styles))(AddressField)
