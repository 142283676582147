import { Router, Scope } from 'redux-routable'
import adminToolsRouter from '~/apps/adminTools/router'
import admissionsReconciliationRouter from '~/apps/admissionsReconciliation/router'
import appDashboardRouter from '~/apps/appDashboard/router'
import assessmentRouter from '~/apps/assessment/router'
import caseManagerDashboardRouter from '~/apps/caseManagerDashboard/router'
import clinicalDirectorDashboardRouter from '~/apps/clinicalDirectorDashboard/router'
import coderDashboardRouter from '~/apps/coderDashboard/router'
import communityHWDashboardRouter from '~/apps/communityHWDashboard/router'
import contactManagementRouter from '~/apps/contactManagement/router'
import cssDashboardRouter from '~/apps/cssDashboard/router'
import dashboardRouter from '~/apps/dashboard/router'
import formsDevelopmentRouter from '~/apps/formsDevelopment/router'
import iocDashboardRouter from '~/apps/iocDashboard/router'
import notFoundRouter from '~/apps/notFound/router'
import nurseDashboardRouter from '~/apps/nurseDashboard/router'
import patientRecordRouter from '~/apps/patientRecord/router'
import pesDashboardRouter from '~/apps/pesDashboard/router'
import pharmacistDashboardRouter from '~/apps/pharmacistDashboard/router'
import physicianDashboardRouter from '~/apps/physicianDashboard/router'
import polygonatorRouter from '~/apps/polygonator/router'
import referralManagementRouter from '~/apps/referralManagement/router'
import reportsDashboardRouter from '~/apps/reportsDashboard/router'
import RpmRouter from '~/apps/rpm/router'
import swDashboardRouter from '~/apps/swDashboard/router'
import transportationRouter from '~/apps/transportation/router'
import userSettingsRouter from '~/apps/userSettings/router'

const devRouter = __DEV__
  ? Router([Scope('/forms-development', formsDevelopmentRouter)])
  : Router([])

// Please play nice and alphabetize your routers
export default Router([
  Scope('', dashboardRouter),
  Scope('/admin-tools', adminToolsRouter),
  Scope('/admissions', admissionsReconciliationRouter),
  Scope('/app-dashboard', appDashboardRouter),
  Scope('/assessment', assessmentRouter),
  Scope('/case-manager-dashboard', caseManagerDashboardRouter),
  Scope('/cd-dashboard', clinicalDirectorDashboardRouter),
  Scope('/coder-dashboard', coderDashboardRouter),
  Scope('/community-hw-dashboard', communityHWDashboardRouter),
  Scope('/contact-management', contactManagementRouter),
  Scope('/css-dashboard', cssDashboardRouter),
  Scope('/ioc-dashboard', iocDashboardRouter),
  Scope('/nurse-dashboard', nurseDashboardRouter),
  Scope('/patient-record', patientRecordRouter),
  Scope('/pes-dashboard', pesDashboardRouter),
  Scope('/pharmacist-dashboard', pharmacistDashboardRouter),
  Scope('/physician-dashboard', physicianDashboardRouter),
  Scope('/polygonator', polygonatorRouter),
  Scope('/referral-management', referralManagementRouter),
  Scope('/reports-dashboard', reportsDashboardRouter),
  Scope('/sw-dashboard', swDashboardRouter),
  Scope('/user-settings', userSettingsRouter),
  Scope('/transportation', transportationRouter),
  Scope('/rpm', RpmRouter),
  Scope('', devRouter),
  Scope('', notFoundRouter),
])
