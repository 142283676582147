import { Map } from 'immutable'
import React, { PureComponent } from 'react'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  dialog: { minWidth: 400 },
  cancelReason: { width: '100%' },
})

class ActionDialog extends PureComponent {
  state = { cancelReason: null, note: '' }

  onChange = event => {
    this.setState({ cancelReason: event.target.value })
  }

  onNoteChange = event => {
    this.setState({ note: event.target.value })
  }

  onClose = () => {
    this.setState({ cancelReason: null, note: '' })
    this.props.onClose()
  }

  onConfirm = () => {
    this.props.onConfirm(
      this.props.action,
      this.state.cancelReason,
      this.state.note
    )
    this.onClose()
  }

  render() {
    const {
      action,
      reasons = Map(), // TODO: fix
      open,
      classes,
    } = this.props

    const { cancelReason, note } = this.state
    return (
      <div>
        {action ? (
          <Dialog
            open={open}
            onClose={this.onClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {action.label} Order
            </DialogTitle>
            <DialogContent className={classes.dialog}>
              {action.requireReason && (
                <React.Fragment>
                  <TextField
                    select
                    id="reason"
                    helperText="Enter a cancellation reason"
                    value={this.state.cancelReason || ''}
                    onChange={this.onChange}
                    className={classes.cancelReason}
                  >
                    {reasons.toIndexedSeq().map(r => (
                      <MenuItem key={r.value} value={r.value}>
                        {r.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="multiline-flexible"
                    helperText="Cancel Note/Description"
                    multiline
                    rowsMax="5"
                    value={this.state.note}
                    onChange={this.onNoteChange}
                    fullWidth
                  />
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.onClose}>
                Close
              </Button>
              <Button
                disabled={
                  (action.requireReason && !cancelReason) ||
                  (action.requireNote && !note)
                }
                onClick={this.onConfirm}
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
    )
  }
}

ActionDialog.propTypes = {
  action: PropTypes.object,
  reasons: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.object,
}

export default withStyles(styles)(ActionDialog)
