import React from 'react'
import PropTypes from '~/utils/propTypes'
import RadioGroupBase from '@material-ui/core/RadioGroup'

const RadioGroupField = ({ input, ...props }) => (
  <RadioGroupBase
    value={input.value}
    onChange={(_event, value) => input.onChange(value)}
    {...props}
  />
)

RadioGroupField.propTypes = {
  input: PropTypes.object,
}

export default RadioGroupField
