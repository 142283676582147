// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import { saveDischargeRecommendation } from './discharges'

// KEY
const key = 'recommendationCreationDialog'

export const getDialogOpenValue = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const createDischargeRecDialogOpened = creator(
  'CREATE_DISCHARGE_REC_DIALOG_OPENED'
)
export const createDischargeRecDialogClosed = creator(
  'CREATE_DISCHARGE_REC_DIALOG_CLOSED'
)

// REDUCER
export default createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [createDischargeRecDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [createDischargeRecDialogOpened]: () => true,
  [saveDischargeRecommendation.SUCCEEDED]: () => false,
})
