import { combineReducers } from 'redux-immutable'
import alertDialog from './data/alertDialog'
import patientAlerts from './data/patientAlerts'
import key from './key'

const reducer = combineReducers({
  [alertDialog.key]: alertDialog,
  [patientAlerts.key]: patientAlerts,
})

reducer.key = key

export default reducer
