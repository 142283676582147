import moment from 'moment'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const DATE_FORMAT = 'ddd M/D'

const useStyles = makeStyles({
  header: {
    width: '100%',
  },
})

const DayHeaderButton = ({ date, disabled, currentDate }) => {
  const classes = useStyles()

  const selected = moment(currentDate)
    .startOf('day')
    .isSame(moment(date).startOf('day'))
  const label = moment(date).format(DATE_FORMAT)

  return (
    <Button
      className={classes.header}
      disabled={disabled}
      variant={selected ? 'contained' : undefined}
      color={selected ? 'primary' : undefined}
    >
      {label}
    </Button>
  )
}

DayHeaderButton.propTypes = {
  date: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  currentDate: PropTypes.instanceOf(moment),
}

export default DayHeaderButton
