import { combineReducers } from 'redux-immutable'
import form from './data/form'
import CONSENT_FOR_SERVICES from './key'

const reducer = combineReducers({
  [form.key]: form,
})

reducer.key = CONSENT_FOR_SERVICES

export default reducer
