// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import rpmForm from './data/RpmForm'
import rpmFromList from './data/rpmFromList'
import key from './key'

const reducer = combineReducers({
  [rpmForm.key]: rpmForm,
  [rpmFromList.key]: rpmFromList,
})

reducer.key = key

export default reducer
