import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { SELECTED_APPS_KEY, fetchUserSettings, getRoot } from './common/shared'

const PENDING_SELECTED_APPS = 'pendingSelectedAPPs'

const creator = scopedCreator(rootKey)
export const selectedAPPToggled = creator('SELECTED_APP_TOGGLED', ['id'])

const initState = Set()
export default createReducer(PENDING_SELECTED_APPS, initState, {
  [fetchUserSettings.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload.get(SELECTED_APPS_KEY),
  // @ts-expect-error can we deal with the toString being automatically called?
  [selectedAPPToggled]: (state: any, { payload: { id } }: any) =>
    state.includes(id) ? state.delete(id) : state.add(id),
})

export const getPendingSelectedAPPs = pipe(getRoot, get(PENDING_SELECTED_APPS))
