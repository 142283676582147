import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { formatDate } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const INTERVENTIONS = 'interventions'

const creator = scopedCreator(rootKey)
export const selectedRecord = creator('SELECTED_HC_GOAL_INTERVENTION')

export const Intervention = Record({
  interventionId: null,
  hcGoalId: null,
  goalLabel: null,
  statement: null,
  status: null,
  assignee: null,
  assigneeId: null,
  targetDate: null,
  patientId: null,
  patientName: null,
  patientDOB: null,
  subscriberId: null,
  metalTier: null,
  patientSubStatus: null,
  product: null,
  market: null,
  created: null,
  createdBy: null,
  createdById: null,
  modified: null,
  modifiedBy: null,
  closed: null,
  closedBy: null,
  closureReason: null,
  source: null,
})

const transformInterventions = records => {
  return records.map(record => {
    return Intervention({
      interventionId: record.intervention.id,
      hcGoalId: record.intervention.care_plan_goal.id,
      goalLabel: record.intervention.care_plan_goal.goal.label,
      statement: record.intervention.statement,
      status: record.intervention.status,
      assignee: `${record.assignee} - ${record.assignee_role || ''}`,
      assigneeId: record.intervention.assignee_id,
      targetDate: record.intervention.target_date,
      patientId:
        record.intervention.care_plan_goal.care_plan_health_concern.patient_id,
      patientName:
        record.intervention.care_plan_goal.care_plan_health_concern.patient
          .demographics.name,
      patientDOB: formatDate(
        record.intervention.care_plan_goal.care_plan_health_concern.patient
          .demographics.date_of_birth
      ),
      subscriberId: record.subscriber_id,
      metalTier: record.metal_tier,
      patientSubStatus:
        record.intervention.care_plan_goal.care_plan_health_concern.patient
          .aspire.substatus,
      product: record.product,
      market:
        record.intervention.care_plan_goal.care_plan_health_concern.patient
          .market.name,
      created: formatDate(record.intervention.created_at),
      createdBy: `${record.created_by} - ${
        record.intervention.created_by_role || ''
      }`,
      createdById: record.intervention.created_by_id,
      modified: formatDate(record.intervention.modified_at),
      modifiedBy: `${record.modified_by} - ${
        record.intervention.modified_by_role || ''
      }`,
      closed: formatDate(record.intervention.completed_date),
      closedBy: record.closed_by
        ? `${record.closed_by} - ${record.intervention.closed_by_role || ''}`
        : '',
      closureReason: record.intervention.closure_reason,
      source: record.intervention.source,
    })
  })
}

export const fetchInterventions = Request({
  typePrefix: rootKey,
  typeBase: 'INTERVENTION',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/interventions`),
  transform: transformInterventions,
  messages: { failed: 'There was an issue fetching interventions' },
})

export const interventions = createReducer(INTERVENTIONS, [], {
  [fetchInterventions.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getInterventions = pipe(getRoot, get(INTERVENTIONS))
