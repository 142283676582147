import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const SNOOZE_DIALOG = 'snoozePatient'

const SnoozeDialog = Record({
  open: false,
  patientId: null,
  patientName: null,
})

const creator = scopedCreator(key)
export const snoozeDialogOpened = creator('SNOOZE_DIALOG_OPENED', [
  'patientId',
  'patientName',
])
export const snoozeDialogClosed = creator('SNOOZE_DIALOG_CLOSED', false)

const initState = SnoozeDialog()
export default createReducer(SNOOZE_DIALOG, initState, {
  [snoozeDialogOpened]: (_state, { payload }) =>
    SnoozeDialog({ open: true, ...payload }),
  [snoozeDialogClosed]: () => initState,
})

export const getSnoozeDialog = pipe(getRoot, get(SNOOZE_DIALOG))
