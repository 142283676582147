import { Set } from 'immutable'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMarkets } from '~/data/markets'
import { getUserRoles } from '~/data/userRoles'
import { getUsers } from '~/data/users'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import SelectList, { SelectListPopover } from './SelectList'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 500,
    overflow: 'auto',
    marginTop: spacing(1),
  },
  filterContainer: {
    display: 'flex',
    flexShrink: 0,
    padding: spacing(1),
  },
}))

const UserList = ({
  selectedUserIds,
  disabled,
  separateSelected,
  batchActions,
  onUsersSelected,
}) => {
  const classes = useStyles()

  const users = useSelector(getUsers)
  const userRoles = useSelector(getUserRoles)
  const markets = useSelector(getMarkets)

  const [selectedRoles, setSelectedRoles] = useState(Set())
  const [selectedMarkets, setSelectedMarkets] = useState(Set())

  const onRoleSelected = (roleIds, selected) => {
    setSelectedRoles(roles =>
      selected ? roles.merge(roleIds) : roles.subtract(roleIds)
    )
  }

  const onMarketSelected = (marketIds, selected) => {
    setSelectedMarkets(markets =>
      selected ? markets.merge(marketIds) : markets.subtract(marketIds)
    )
  }

  const filteredUsers = useMemo(
    () =>
      users.filter(({ id, active, roleId, marketId }) => {
        const isSelected = selectedUserIds.includes(id)
        const isInRole =
          selectedRoles.isEmpty() || selectedRoles.includes(roleId)
        const isInMarket =
          selectedMarkets.isEmpty() || selectedMarkets.includes(marketId)

        return disabled
          ? isSelected
          : active && (isSelected || (isInRole && isInMarket))
      }),
    [disabled, selectedUserIds, users, selectedRoles, selectedMarkets]
  )

  return (
    <div className={classes.container}>
      <div className={classes.filterContainer}>
        <SelectListPopover
          disabled={disabled}
          items={userRoles}
          label="Roles"
          selectedItemIds={selectedRoles}
          onItemsSelected={onRoleSelected}
        />

        <SelectListPopover
          disabled={disabled}
          items={markets}
          label="Markets"
          selectedItemIds={selectedMarkets}
          onItemsSelected={onMarketSelected}
        />
      </div>

      <SelectList
        disabled={disabled}
        items={filteredUsers}
        itemsLabel="Users"
        selectedItemIds={selectedUserIds}
        separateSelected={separateSelected}
        batchActions={batchActions}
        onItemsSelected={onUsersSelected}
      />
    </div>
  )
}

UserList.propTypes = {
  disabled: PropTypes.bool,
  selectedUserIds: PropTypes.set,
  separateSelected: PropTypes.bool,
  batchActions: PropTypes.bool,
  onUsersSelected: PropTypes.func.isRequired,
}

export default UserList
