import { combineReducers } from 'redux-immutable'
import createEpisodeOfCareDialog from './data/createEpisodeOfCareDialog'
import currentEpisodeOfCareId from './data/currentEpisodeOfCareId'
import episodesOfCare from './data/episodesOfCare'
import key from './key'

export const reducer = combineReducers({
  [createEpisodeOfCareDialog.key]: createEpisodeOfCareDialog,
  [currentEpisodeOfCareId.key]: currentEpisodeOfCareId,
  [episodesOfCare.key]: episodesOfCare,
})

reducer.key = key
