import React from 'react'
import { Field } from 'redux-form/immutable'
import { Checkbox, TextField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { checkRequired, requiredIfPending } from '../../../utils/formValidators'
import ActionItem from '../../ActionItem'
import FormFieldWrapper from './FormFieldWrapper'

const ALERT_CHECK = 'alertCheck'
const validateAlertCheck = checkRequired(ALERT_CHECK)

const styles = ({ palette: { severity }, spacing }) => ({
  formSpan: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    top: spacing(1),
    right: spacing(2),
    '&:hover': {
      top: spacing(1),
      left: spacing(1) * -2,
    },
  },
  fullWidth: {
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drugAlerts: {
    color: severity.error.static,
  },
})

class DrugAlertFields extends React.PureComponent {
  get displayed() {
    const { drugAlerts, isRequired, isToggled } = this.props
    return Boolean(
      drugAlerts && ((isRequired && !drugAlerts.isEmpty()) || isToggled)
    )
  }

  render() {
    const { drugAlerts, onClick, classes, isRequired } = this.props

    return (
      <FormFieldWrapper
        isCollapsed={!this.displayed}
        title={`Potential Drug / Drug Interactions${
          isRequired ? ' - Required' : ''
        }`}
      >
        {drugAlerts && !drugAlerts.isEmpty() ? (
          <React.Fragment>
            {drugAlerts.map((alert, i) => (
              <span className={classes.title} key={i}>
                <ActionItem
                  toolTip="Get Alert Description"
                  icon="info"
                  onClick={() => onClick(alert)}
                  className={classes.drugAlerts}
                  baseClassName={classes.checkbox}
                />
                <Field
                  component={TextField}
                  className={classes.fullWidth}
                  disabled
                  label={alert.title}
                  name={`alertTitle_${i}`}
                />
              </span>
            ))}
            <span className={classes.formSpan}>
              <Field
                component={Checkbox}
                className={classes.checkbox}
                name={ALERT_CHECK}
                validate={[requiredIfPending]}
              />
              <Field
                component={TextField}
                className={classes.fullWidth}
                label="Alert Override Reason - INTERNAL USE ONLY"
                name="overrideReason"
                validate={[validateAlertCheck]}
              />
            </span>
          </React.Fragment>
        ) : (
          <Field
            component={TextField}
            className={classes.fullWidth}
            label="No Serious Drug / Drug Interaction Detected"
            name="noDrugDrugInteractions"
            disabled
          />
        )}
      </FormFieldWrapper>
    )
  }
}

DrugAlertFields.propTypes = {
  classes: PropTypes.object.isRequired,
  drugAlerts: PropTypes.list,
  isRequired: PropTypes.bool.isRequired,
  isToggled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

export default withStyles(styles)(DrugAlertFields)
