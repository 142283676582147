import PropTypes from 'prop-types'
import React from 'react'
import { Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from './FormControlLabel'
import memoizedWrapper from './memoizedWrapper'
import { getBaseProps, valueIn } from './props'

const emptyEvent = { target: { value: '' } }

const getId = (id, value) => id && `${id}_${value}`

const useStyles = makeStyles(({ spacing }) => ({
  radio: {
    marginTop: spacing(-1),
    marginBottom: spacing(-1),
  },
}))

const RadioWidget = props => {
  const classes = useStyles()

  const { id, options, value, disabled } = props
  const { enumOptions, inline = true } = options
  const selected = valueIn(value, props)
  const groupProps = getBaseProps(props)

  return (
    <RadioGroup {...groupProps} row={inline} name={id} value={selected}>
      {enumOptions.map(({ label, value }) => {
        const radioId = getId(id, value)
        const selectedId = getId(id, selected)

        const onClick = () => {
          if (radioId === selectedId && !disabled) {
            groupProps.onChange(emptyEvent)
          }
        }

        return (
          <FormControlLabel
            key={value}
            value={valueIn(value, props)}
            label={label}
            onClick={onClick}
            control={<Radio className={classes.radio} id={radioId} />}
          />
        )
      })}
    </RadioGroup>
  )
}

RadioWidget.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
}

export default memoizedWrapper(RadioWidget)
