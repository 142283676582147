import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { fetchAssessments } from '~/apps/assessment/data/assessments'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_HISTORY_ASSESSMENTS } from '../router'

export default (action$, state$) => {
  return action$.pipe(
    filter(entered(PATIENT_RECORD_HISTORY_ASSESSMENTS)),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId =>
      of(
        fetchAssessments.requested({
          patient_ids: [patientId],
        }),
        fetchDistinctFieldValues(['encounter_assessment_status'])
      )
    )
  )
}
