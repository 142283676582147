import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { getPatientId } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'
import {
  deferPrescribedMedication,
  fetchPatientRx,
  importPrescribedMedication,
} from '../data/patientRx'

export const importMedication = (action$, state$) =>
  action$.pipe(
    ofType(importPrescribedMedication.SUCCEEDED),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId => of(fetchPatientRx.requested(patientId)))
  )

export const deferMedication = (action$, state$) =>
  action$.pipe(
    ofType(deferPrescribedMedication.SUCCEEDED),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId => of(fetchPatientRx.requested(patientId)))
  )
