export const ACTIVE_REFERRAL_CASE_REQUESTED =
  'REFERRAL_MANAGEMENT/ACTIVE_REFERRAL_CASE_REQUESTED'
export const ACTIVE_REFERRAL_CASE_STORED =
  'REFERRAL_MANAGEMENT/ACTIVE_REFERRAL_CASE_STORED'
export const ARCHIVE_CASE_COMPLETED =
  'REFERRAL_MANAGEMENT/ARCHIVE_CASE_COMPLETED'
export const ARCHIVE_CASE_REQUESTED =
  'REFERRAL_MANAGEMENT/ARCHIVE_CASE_REQUESTED'
export const ASSIGNMENT_REQUESTED = 'REFERRAL_MANAGEMENT/ASSIGNMENT_REQUESTED'
export const ASSIGNMENT_SUCCEEDED = 'REFERRAL_MANAGEMENT/ASSIGNMENT_SUCCEEDED'
export const ASSIGNMENT_FAILED = 'REFERRAL_MANAGEMENT/ASSIGNMENT_FAILED'
export const CLOSE_CHANNEL_SELECTION_DIALOG =
  'REFERRAL_MANAGEMENT/CLOSE_CHANNEL_SELECTION_DIALOG'
export const CHANNEL_QUERY_CHANGED = 'REFERRAL_MANAGEMENT/CHANNEL_QUERY_CHANGED'
export const CHANNEL_SELECTION_DIALOG_REQUESTED =
  'REFERRAL_MANAGEMENT/CHANNEL_SELECTION_DIALOG_REQUESTED'
export const CREATE_NEW_CASE_REQUESTED =
  'REFERRAL_MANAGEMENT/CREATE_NEW_CASE_REQUESTED'
export const DELETE_CONTACT_COMPLETED =
  'REFERRAL_MANAGEMENT/DELETE_CONTACT_COMPLETED'
export const DELETE_CONTACT_REQUESTED =
  'REFERRAL_MANAGEMENT/DELETE_CONTACT_REQUESTED'
export const DUPE_CHECK_COMPLETED = 'REFERRAL_MANAGEMENT/DUPE_CHECK_COMPLETED'
export const RESET_DUPE_CHECK = 'REFERRAL_MANAGEMENT/RESET_DUPE_CHECK'
export const EMR_DUPLICATES_FOUND = 'REFERRAL_MANAGEMENT/EMR_DUPLICATES_FOUND'
export const NEW_CASE_CREATED = 'REFERRAL_MANAGEMENT/NEW_CASE_CREATED'
export const NEW_REFERRAL_FORM_RESET =
  'REFERRAL_MANAGEMENT/NEW_REFERRAL_FORM_RESET'
export const NEW_REFERRAL_ODS_LOOKUP_REQUESTED =
  'REFERRAL_MANAGEMENT/NEW_REFERRAL_ODS_LOOKUP_REQUESTED'
export const NEW_REFERRAL_VALIDATION_REQUESTED =
  'REFERRAL_MANAGEMENT/NEW_REFERRAL_VALIDATION_REQUESTED'
export const ODS_LOOKUP_COMPLETED = 'REFERRAL_MANAGEMENT/ODS_LOOKUP_COMPLETED'
export const ODS_LOOKUP_MATCHES_FOUND =
  'REFERRAL_MANAGEMENT/ODS_LOOKUP_MATCHES_FOUND'
export const PHYSICIAN_QUERY_CHANGED =
  'REFERRAL_MANAGEMENT/PHYSICIAN_QUERY_CHANGED'
export const REFERRAL_CASES_REQUESTED =
  'REFERRAL_MANAGEMENT/REFERRAL_CASES_REQUESTED'
export const SUBMIT_CASE_COMPLETED = 'REFERRAL_MANAGEMENT/SUBMIT_CASE_COMPLETED'
export const SUBMIT_CASE_FAILED = 'REFERRAL_MANAGEMENT/SUBMIT_CASE_FAILED'
export const SUBMIT_CASE_REQUESTED = 'REFERRAL_MANAGEMENT/SUBMIT_CASE_REQUESTED'
export const STATUS_UPDATE_COMPLETED =
  'REFERRAL_MANAGEMENT/STATUS_UPDATE_COMPLETED'
export const STATUS_UPDATE_REQUESTED =
  'REFERRAL_MANAGEMENT/STATUS_UPDATE_REQUESTED'
export const MANUAL_ASSIGNMENT_REQUESTED =
  'REFERRAL_MANAGEMENT/MANUAL_ASSIGNMENT_REQUESTED'
export const MANUAL_ASSIGNMENT_COMPLETED =
  'REFERRAL_MANAGEMENT/MANUAL_ASSGINMENT_COMPLETED'
export const PATIENT_EXISTING_REFERRALS_FETCHED =
  'REFERRAL_MANAGEMENT/PATIENT_EXISTING_REFERRALS_FETCHED'
export const PATIENT_EXISTING_REFERRALS_FETCH_REQUESTED =
  'REFERRAL_MANAGEMENT/PATIENT_EXISTING_REFERRALS_FETCH_REQUESTED'
export const ADDITIONAL_REFERRAL_SEARCH = 'ADDITIONAL_REFERRAL_SEARCH'
export const ADDITIONAL_REFERRAL_SELECTED = 'ADDITIONAL_REFERRAL_SELECTED'
export const ADDITIONAL_REFERRAL_SAVE_REQUESTED =
  'REFERRAL_MANAGEMENT/ADDITIONAL_REFERRAL_SAVE_REQUESTED'
export const ADDITIONAL_REFERRAL_SUCESSFULLY_SAVED =
  'REFERRAL_MANAGEMENT/ADDITIONAL_REFERRAL_SUCESSFULLY_SAVED'
export const POTENTIAL_CHANNELS_UPDATED =
  'REFERRAL_MANAGEMENT/POTENTIAL_CHANNELS_UPDATED'
export const CASE_ID_CHANGED = 'REFERRAL_MANAGEMENT/CASE_ID_CHANGED'

export const POLL_FOR_CASE_SYNC_COMPLETION_START =
  'REFERRAL_MANAGEMENT/POLL_FOR_CASE_SYNC_COMPLETION_START'
export const POLL_FOR_CASE_SYNC_COMPLETION_STOP =
  'REFERRAL_MANAGEMENT/POLL_FOR_CASE_SYNC_COMPLETION_STOP'

export const POTENTIAL_CARE_TEAMS_UPDATED = 'POTENTIAL_CARE_TEAMS_UPDATED'
