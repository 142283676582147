import { formatUSPhone } from './transformers'

export const pharmacyColumns = [
  {
    name: 'name',
    title: 'Name',
    displayWhenCollapsed: true,
    getCellValue: ({ name }) => {
      return name ? name : 'Pharmacy not documented'
    },
  },
  {
    name: 'address1',
    title: 'Address',
    getCellValue: ({ address1 }) => {
      return address1 ? address1 : 'N/A'
    },
  },
  {
    name: 'city',
    title: 'City',
    getCellValue: ({ city }) => {
      return city ? city : 'N/A'
    },
  },
  {
    name: 'state',
    title: 'State',
    getCellValue: ({ state }) => {
      return state ? state : 'N/A'
    },
  },
  {
    name: 'zip',
    title: 'Zip Code',
    getCellValue: ({ zip }) => {
      return zip ? zip : 'N/A'
    },
  },
  {
    name: 'phone',
    title: 'Phone Number',
    getCellValue: ({ phone }) => {
      return phone ? formatUSPhone(phone) : 'N/A'
    },
  },
  {
    name: 'type',
    title: 'Pharmacy Type',
    getCellValue: ({ type }) => {
      return type ? type : 'N/A'
    },
  },
]
