import { combineEpics, ofType } from 'redux-observable'
import { delay, mapTo } from 'rxjs/operators'
import { uploadLocationsToMDT } from '../data/locations'
import { uploadPatients } from '../data/patientUpload'
import { uploadPrescribersToMDT } from '../data/prescribers'
import {
  MESSAGE_SET,
  messageCleared,
  messageSet,
} from '../data/snackbarMessage'

const prescribersSuccessful = (action$: any) =>
  action$.pipe(
    ofType(uploadPrescribersToMDT.SUCCEEDED),
    mapTo(messageSet('Successfully uploaded prescribers'))
  )

const locationsSuccessful = (action$: any) =>
  action$.pipe(
    ofType(uploadLocationsToMDT.SUCCEEDED),
    mapTo(messageSet('Successfully uploaded locations'))
  )

const uploadSuccessful = (action$: any) =>
  action$.pipe(
    ofType(uploadPatients.SUCCEEDED),
    mapTo(messageSet('Successfully uploaded patients'))
  )

const clearMessage = (action$: any) =>
  action$.pipe(ofType(MESSAGE_SET), mapTo(messageCleared()), delay(3500))

export default combineEpics(
  prescribersSuccessful,
  locationsSuccessful,
  uploadSuccessful,
  clearMessage
)
