import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { pipe } from '~/utils/functionalHelpers'
import { switchTo } from '~/utils/operators'
import { getAllDates, getCompressedDates, getSwimlane } from '../data'
import { fetchPatientTimeline } from '../data/patientEvents'
import {
  swimlaneCompressionToggled,
  swimlaneOrderReversed,
  swimlanePageChanged,
} from '../data/swimlane'

const getDateSizeAndSwimlaneRowsPerPage = state => {
  const { compressed, reversed, rowsPerPage } = getSwimlane(state)
  return {
    rowsPerPage,
    reversed,
    size: (compressed ? getCompressedDates(state) : getAllDates(state)).size,
  }
}

const findLastPage = ({ reversed, rowsPerPage, size }) =>
  Math[reversed ? 'min' : 'max'](0, Math.ceil(size / rowsPerPage) - 1)

const goToLastPage = pipe(
  getDateSizeAndSwimlaneRowsPerPage,
  findLastPage,
  swimlanePageChanged
)

export default (action$, state$) =>
  action$.pipe(
    ofType(
      fetchPatientTimeline.SUCCEEDED,
      swimlaneCompressionToggled,
      swimlaneOrderReversed
    ),
    switchTo(state$),
    map(goToLastPage)
  )
