import React from 'react'
import { CSS_DASHBOARD_APP_SCHEDULING_APP } from '~/apps/cssDashboard/router'
import TypographyLink from '~/components/TypographyLink'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  linkText: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  spacer: {
    margin: [0, spacing(1)],
  },
})

const PrimaryText = ({ classes, app: { id: appId, fullName, market } }) => {
  return (
    <div className={classes.container}>
      <TypographyLink
        action="open"
        className={classes.linkText}
        route={CSS_DASHBOARD_APP_SCHEDULING_APP}
        params={{ appId }}
      >
        {fullName}
      </TypographyLink>
      <Typography className={classes.spacer}>•</Typography>
      <Typography>{market}</Typography>
    </div>
  )
}

PrimaryText.propTypes = {
  classes: PropTypes.object.isRequired,
  app: PropTypes.record.isRequired,
}

export default withStyles(styles)(PrimaryText)
