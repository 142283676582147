import React from 'react'
import { connect } from 'react-redux'
import { getUserRole } from '~/data/session'
import { pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { isPermittedRole } from '../utils/mdt'
import FormDisabledContext from './FormDisabledContext'

const ActionItemButton = props => {
  const {
    baseClassName,
    buttonRef,
    hasMdtAccess,
    icon,
    mdtSFAuth,
    onClick,
    toolTip,
    disabled: propDisabled,
    dispatch: ingnoreDispatch,
    ...rest
  } = props

  const authDisabled = mdtSFAuth && !hasMdtAccess

  return (
    <FormDisabledContext.Consumer>
      {formDisabled => (
        <Tooltip title={toolTip}>
          <span>
            <IconButton
              disabled={propDisabled || formDisabled || authDisabled}
              onClick={onClick}
              className={baseClassName}
              buttonRef={buttonRef}
            >
              <Icon {...rest}>{icon}</Icon>
            </IconButton>
          </span>
        </Tooltip>
      )}
    </FormDisabledContext.Consumer>
  )
}

ActionItemButton.propTypes = {
  baseClassName: PropTypes.string,
  buttonRef: PropTypes.func,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  hasMdtAccess: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  mdtSFAuth: PropTypes.bool,
  onClick: PropTypes.func,
  toolTip: PropTypes.string,
}

ActionItemButton.defaultProps = {
  baseClassName: '',
  disabled: false,
  mdtSFAuth: false,
  onClick: () => {},
  toolTip: '',
}

const mapStateToProps = state => ({
  hasMdtAccess: pipe(getUserRole, isPermittedRole)(state),
})

export default connect(mapStateToProps)(ActionItemButton)
