import React from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from '@material-ui/core/TextField'

class FileInput extends React.Component {
  state = {}

  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.file && newProps.file !== prevState.file) {
      return { file: newProps.file }
    } else {
      return null
    }
  }

  onClick = () => this.fileInput.click()

  handleChange = e => {
    const file = e.currentTarget.files[0]
    if (file) {
      this.setState({ file })
      this.props.onChange && this.props.onChange(file)
    }
  }

  render() {
    const { value = {}, placeholder, disabled, accept } = this.props
    const fileName = this.state.file ? this.state.file.name : null
    const displayValue = value.name || fileName || ''

    return (
      <React.Fragment>
        <TextField
          {...this.props}
          placeholder={placeholder || ' Select File'}
          value={displayValue}
          onClick={
            !disabled ? this.onClick : undefined
          } /* Need to register click events on already focused text field in order to choose new document*/
          disabled={disabled}
        />

        <input
          ref={fileInput => (this.fileInput = fileInput)}
          type="file"
          onChange={this.handleChange}
          disabled={disabled}
          accept={accept}
          style={{ display: 'none' }}
        />
      </React.Fragment>
    )
  }
}

FileInput.propTypes = {
  onChange: PropTypes.func, // returns the file if selected
  placeholder: PropTypes.string,
  accept: PropTypes.string, // accepted file types
  disabled: PropTypes.bool,
  value: PropTypes.object,
}

export default FileInput
