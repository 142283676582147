import { get, has, isEmpty } from 'lodash'
import snake from 'snake-case'
import deepEntries from './deepEntries'
import getTaggedValue from './getTaggedValue'
import resolve, { isResolved } from './resolve'
import setIn from './setIn'

const calcCache = new Map()

// Evaluate a calc function (with caching)
const evalCalc = calc => {
  if (!calcCache.has(calc)) {
    calcCache.set(calc, eval(calc))
  }

  return calcCache.get(calc)
}

// Get an override value for the initial flag that is passed to calc functions
const initialOverride = formData => {
  const empty = isEmpty(formData)
  const resolved = isResolved(formData)

  if (empty) {
    return true
  } else if (!resolved) {
    return false
  } else {
    return undefined
  }
}

// Retrieve some data using a relative path
const getByPath = (data, path, [key, ...relativePath] = [], defaultVal) => {
  if (key === undefined) {
    return path.length > 0 ? get(data, path, defaultVal) : data
  } else {
    const newPath =
      key === '..'
        ? path.slice(0, path.length - 1) // Go up a level
        : [...path, key] // Go down a level

    return getByPath(data, newPath, relativePath, defaultVal)
  }
}

// Create the get() function that is passed to calc functions
const createGet = (data, path, tags) => (pathOrTag, defaultVal) =>
  Array.isArray(pathOrTag)
    ? getByPath(data, path, pathOrTag, defaultVal)
    : getTaggedValue({ formData: data, tags }, pathOrTag, { defaultVal })

// Update formData using calc functions from schema
const calcFormData = options => {
  const { formData, schema, context, tags, initial, calculated } = options
  const resolvedFormData = resolve(schema, formData)

  // Iterate deeply through formData, evaluate calc functions, and set values
  const newFormData = deepEntries(schema, resolvedFormData, schema.definitions)
    .filter(({ schema }) => schema && 'calc' in schema)
    .reduce((newFormData, { schema, value, valuePath }) => {
      const key = valuePath.join()

      // Get the initial flag that is passed to the calc function
      const getInitial = () => {
        if (calculated.has(key)) {
          // Is false when calc function has been previously called
          return false
        } else if (initial !== undefined) {
          // Is overriden if formData was empty or not resolved
          return initial
        } else {
          // Is equal to whether this field was previously present in formData
          return !has(formData, valuePath)
        }
      }

      const calc = evalCalc(schema.calc)
      const newValue = calc({
        initial: getInitial(),
        get: createGet(resolvedFormData, valuePath, tags),
        value,
        context,
        snake,
      })

      calculated.add(key)

      // Set value in formData only if changed
      return value !== newValue
        ? setIn(newFormData, valuePath, newValue)
        : newFormData
    }, resolvedFormData)

  // Re-calculate formData only if changed
  return resolvedFormData !== newFormData
    ? calcFormData({ ...options, formData: newFormData })
    : resolvedFormData
}

export default options =>
  calcFormData({
    ...options,
    initial: initialOverride(options.formData),
    calculated: new Set(),
  })
