import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { createNewTaskUpdated } from '~/features/tasks/data/createNewTask'
import { fetchTaskDefinitions } from '../data/taskDefinitions'

export default action$ =>
  action$.pipe(
    ofType(createNewTaskUpdated),
    pluck('payload', 'data', 'patientId'),
    filter(Boolean),
    map(fetchTaskDefinitions.requested)
  )
