import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { addSNF } from './snfs'

const CREATE_SNF_DIALOG = 'createSnfDialog'

const Dialog = Record({
  open: false,
  patientId: null,
})

const creator = scopedCreator(rootKey)
export const createSnfDialogOpened = creator('CREATE_SNF_DIALOG_OPENED', [
  'patientId',
])
export const createSnfDialogClosed = creator('CREATE_SNF_DIALOG_CLOSED')

// REDUCER
export default createReducer(CREATE_SNF_DIALOG, Dialog(), {
  [createSnfDialogOpened]: (_state, { payload: { patientId } }) =>
    Dialog({ open: true, patientId }),
  [createSnfDialogClosed]: () => Dialog(),
  [addSNF.SUCCEEDED]: () => Dialog(),
})

export const getDialog = pipe(getRoot, get(CREATE_SNF_DIALOG))
