import { List, Record } from 'immutable'
import { ofType } from 'redux-observable'
import { first, map } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const CALL_DISPOSITIONS = 'callDispositions'

export const CallDisposition = Record({
  intentId: null,
  dispositionId: null,
  subdispositionId: null,
  dispositionName: null,
  subdispositionName: null,
  inbound: null,
  outbound: null,
  forActive: null,
  forDischarged: null,
  forReferred: null,
  forScheduled: null,
})

const creator = scopedCreator(rootKey)
export const callDispositionsRequested = creator('CALL_DISPOSITIONS_REQUESTED')

const transformCallDispositions = data =>
  List(
    data.map(({ intent: intentId, ...item }) =>
      CallDisposition({ intentId, ...item })
    )
  )

export const fetchCallDispositions = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CALL_DISPOSITIONS',
  operation: () => AspireAPI.get('v1/calldispositions'),
  transform: transformCallDispositions,
  messages: { failed: 'There was an issue fetching call dispositions' },
})

export default createReducer(CALL_DISPOSITIONS, List(), {
  [fetchCallDispositions.SUCCEEDED]: (_state, { payload }) => payload,
})

export const epic = action$ =>
  action$.pipe(
    ofType(callDispositionsRequested),
    first(),
    map(fetchCallDispositions.requested)
  )

export const getCallDispositions = pipe(getRoot, get(CALL_DISPOSITIONS))
