import React from 'react'
import PropTypes from '~/utils/propTypes'
import CUPAuthForm from './CUPAuthForm'

const AuthNumber = props => {
  const { row, value } = props
  return row.authNumber ? (
    <CUPAuthForm authNumber={row.authNumber}>{value}</CUPAuthForm>
  ) : null
}

AuthNumber.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
}

export default AuthNumber
