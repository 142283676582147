import { Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'pickupAddress'

// CREATORS
const creator = scopedCreator(key)
export const selectPickupAddress = creator('SELECT_PICKUP_ADDRESS')
export const resetPickupAddress = creator('RESET_PICKUP_ADDRESS')
export const PickupAddress = Record({
  name: null,
  address: null,
  city: null,
  state: null,
  zipcode: null,
  phone: null,
  locationType: null,
  distance: null,
  phoneExtension: null,
})

// REDUCER
export const pickupAddress = createReducer(key, PickupAddress, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [selectPickupAddress]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetPickupAddress]: () => PickupAddress,
})

export const getPickupAddress = getIn([rootKey, key])
