import { connect } from 'react-redux'
import { compose } from 'redux'
import bindStateToSelectors from '~/utils/bindStateToSelectors'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { VitalsChartBase, vitalsStyles } from '../components/VitalsChart'
import { chartDisplayToggled, chartPageChanged, getChart } from '../data/chart'
import { fetchVitals, getPatientVitalsMap } from '../data/vitals'

const styles = vitalsStyles()

const mapStateToProps = bindStateToSelectors({
  vitals: getPatientVitalsMap,
  chartDisplayToggled: chartDisplayToggled,
  chart: getChart,
})

const mapDispatchToProps = {
  fetchPatientVitals: fetchVitals.requested,
  chartPageChanged: chartPageChanged,
  chartDisplayToggled: chartDisplayToggled,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
  withStyles(styles)
)(VitalsChartBase)
