import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from '~/utils/propTypes'
import {
  Accordion,
  AccordionSummary,
  Icon,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getPatientAlertsList } from '../data/patientAlerts'
import AlertsContent from './AlertsContent'

const styles = ({ palette }) => ({
  alerts: {
    flex: '1',
  },
  root: {
    '&:before': {
      height: 0,
    },
  },
  summaryRoot: {
    backgroundColor: palette.primary.dark,
    padding: [0, 16],
    '& h3': {
      color: palette.common.white,
    },
  },
  summaryContent: {
    margin: [4, 0],
  },
  expanded: {
    margin: 0,
  },
  hidePanelSummaryRoot: {
    backgroundColor: palette.primary.dark,
    display: 'none',
  },
  alertColor: {
    backgroundColor: palette.alertsAccordion.backgroundColor,
    borderBottom: '0 !important',
    '&$expanded': {
      margin: 0,
    },
  },
  noSummaryAlertColor: {
    backgroundColor: palette.severity.error.static,
    borderBottom: '0 !important',
    borderRadius: '0 !important',
  },
  numberCircle: {
    backgroundColor: palette.shades.darker,
    borderRadius: '50%',
    display: 'inline-block',
    fontWeight: 'bold',
    height: 32,
    verticalAlign: 'middle',
    width: 32,
  },
  numbers: {
    color: palette.common.white,
    display: 'inline-block',
    fontWeight: 'bold',
    position: 'relative',
    right: 32,
    textAlign: 'center',
    verticalAlign: 'middle',
    width: 32,
  },
  alertMessage: {
    color: palette.common.white,
    display: 'inline-block',
    position: 'relative',
    right: 24,
    top: 1,
  },
  colorWhite: {
    color: palette.common.white,
  },
})

const Alerts = ({
  alerts,
  classes,
  getAlertCount,
  hidePanelSummary,
  patientPanelAlertsOpen,
}) => {
  useEffect(() => {
    getAlertCount && getAlertCount(alerts.size)
  }, [alerts.size])

  return (
    !alerts.isEmpty() &&
    (hidePanelSummary ? (
      <Accordion
        expanded={patientPanelAlertsOpen || false}
        classes={{ expanded: classes.expanded, root: classes.root }}
        className={classes.noSummaryAlertColor}
        elevation={0}
      >
        <AccordionSummary
          classes={{
            root: classes.hidePanelSummaryRoot,
          }}
        ></AccordionSummary>
        <AlertsContent alerts={alerts} />
      </Accordion>
    ) : (
      <Accordion
        classes={{ expanded: classes.expanded, root: classes.root }}
        className={classes.alertColor}
        elevation={0}
      >
        <AccordionSummary
          classes={{
            content: classes.summaryContent,
            root: classes.summaryRoot,
            expandIcon: classes.colorWhite,
          }}
          className={classes.alertColor}
          expandIcon={<Icon>expand_more</Icon>}
        >
          <Typography variant="subtitle1">
            <div className={classes.numberCircle} />
            <div className={classes.numbers}>{alerts.size}</div>
            <div className={classes.alertMessage}>ALERTS</div>
          </Typography>
        </AccordionSummary>
        <AlertsContent alerts={alerts} />
      </Accordion>
    ))
  )
}

Alerts.propTypes = {
  alerts: PropTypes.list,
  classes: PropTypes.object.isRequired,
  hidePanelSummary: PropTypes.bool,
  patientPanelAlertsOpen: PropTypes.bool,
}

Alerts.defaultProps = {
  getAlertCount: null,
  hidePanelSummary: false,
  patientPanelAlertsOpen: false,
}

export default compose(
  withStyles(styles),
  connect(state => ({
    alerts: getPatientAlertsList(state),
  }))
)(Alerts)
