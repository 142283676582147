import { combineReducers } from 'redux-immutable'
import calendar from './data/calendar'
import eventTypes from './data/eventTypes'
import events from './data/events'
import key from './key'

const reducer = combineReducers({
  [calendar.key]: calendar,
  [events.key]: events,
  [eventTypes.key]: eventTypes,
})

reducer.key = key

export default reducer
