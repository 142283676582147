import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'selectedPrograms'

export const PROGRAM_SELECTED = type(rootKey, 'PROGRAM_SELECTED')

export const setSelectedPrograms = creator(PROGRAM_SELECTED, 'programs')

export default createReducer(key, new Set(), {
  [PROGRAM_SELECTED]: (state, { payload: { programs } }) => new Set(programs),
})

export const getSelectedPrograms = pipe(getRoot, get(key))
