import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import key, { defaultFormsKey, templateKeys } from '../key'

export const FORM_KEY = 'generateDocumentForm'
export const defaultTemplates = Object.values(templateKeys)

export const DocumentForm = Record({
  documentName: null,
  context: {},
  schema: {},
  formData: {},
  uiSchema: {},
  additionalMetaSchemas: null,
  edit: null,
  errors: [],
  errorSchema: {},
  pathSchema: {},
})

export type SchemaT = {
  properties: { [key: string]: any }
  [key: string]: any
}

export type FormT = {
  documentName: string
  formData: { [key: string]: any }
  schema: SchemaT
  uiSchema: SchemaT
  errors: ErrT[]
  context: { [key: string]: any }
  [key: string]: any
}

export type ErrT = {
  error: string
  [key: string]: any
}

export const fetchGenDocForm = Request({
  typePrefix: key,
  typeBase: FORM_KEY,
  requestParams: ['patientId', 'templateName'],
  operation: (patientId: string, templateName: string) =>
    AspireAPI.get(`v1/generate_document/${templateName}/${patientId}`),
  messages: {
    failed: 'Failed to fetch generate document form',
  },
})

const structureFromSchema = (
  formData: { [key: string]: any },
  schema: SchemaT
) => {
  Object.keys(schema.properties).forEach(function (schemaKey) {
    if (!(schemaKey in formData)) {
      formData[schemaKey] = ''
    }
  })

  return formData
}

export const submitGenDocForm = Request({
  typePrefix: 'generateDocumentSubmit',
  typeBase: FORM_KEY,
  requestParams: ['patientId', 'templateName', 'form', 'preview'],
  operation: (
    patientId: string,
    templateName: string,
    { formData, schema, uiSchema, context }: FormT,
    preview: boolean
  ) =>
    AspireAPI.post(`v1/generate_document/${templateName}/${patientId}`, {
      form: {
        formData: structureFromSchema(formData, schema),
        schema,
        uiSchema,
        context,
      },
      preview: preview,
    }),
  messages: {
    failed:
      'Failed to submit generate document form. Letter will be reprocessed tonight, check Attachments tomorrow.',
    succeeded: 'Document generated.',
  },
})

export const previewGenDocForm = Request({
  typePrefix: 'generateDocumentPreview',
  typeBase: FORM_KEY,
  requestParams: ['patientId', 'templateName', 'form', 'preview'],
  operation: (
    patientId: string,
    templateName: string,
    { formData, schema, uiSchema, context }: FormT,
    preview: boolean
  ) =>
    AspireAPI.post(`v1/generate_document/${templateName}/${patientId}`, {
      form: {
        formData: structureFromSchema(formData, schema),
        schema,
        uiSchema,
        context,
      },
      preview: preview,
    }),
  messages: {
    failed: 'Failed to create preview of document form.',
  },
})

export const fetchGenDocFormReducer = createReducer(defaultFormsKey, Map(), {
  [fetchGenDocForm.SUCCEEDED]: (state: any, { payload }: FormT) => {
    const { documentName, ...formData } = payload
    return state.setIn([documentName], formData)
  },
})

export const submitGenDocFormReducer = createReducer('submitted', null, {
  [submitGenDocForm.REQUESTED]: (_state: any, _payload: any) => 'REQUESTED',
  [submitGenDocForm.SUCCEEDED]: (_state: any, _payload: any) => 'SUCCEEDED',
  [submitGenDocForm.FAILED]: (_state: any, _payload: any) => 'FAILED',
})

export const previewGenDocFormReducer = createReducer('previewed', null, {
  [previewGenDocForm.REQUESTED]: (_state: any, _payload: any) => 'REQUESTED',
  [previewGenDocForm.SUCCEEDED]: (_state: any, _payload: any) => 'SUCCEEDED',
  [previewGenDocForm.FAILED]: (_state: any, _payload: any) => 'FAILED',
})
