import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// CONSTANTS
export const FILTER_HEALTHPLAN_ID_SET =
  'filterHealthplanIds/FILTER_HEALTHPLAN_ID_SET'
export const FILTER_HEALTHPLAN_ID_CLEARED =
  'filterHealthplanIds/FILTER_HEALTHPLAN_ID_CLEARED'

// ACTION CREATORS
export const filterHealthplanIdSet = (healthplanId: any) => ({
  type: FILTER_HEALTHPLAN_ID_SET,
  healthplanId,
})
export const filterHealthplanIdCleared = (healthplanId: any) => ({
  type: FILTER_HEALTHPLAN_ID_CLEARED,
  healthplanId,
})

// KEY
export const key = 'filterHealthplanIds'

// REDUCER
export default createReducer(key, Set(), {
  [FILTER_HEALTHPLAN_ID_SET]: (state: any, { healthplanId }: any) =>
    state.add(healthplanId),
  [FILTER_HEALTHPLAN_ID_CLEARED]: (state: any, { healthplanId }: any) =>
    state.delete(healthplanId),
})

// SELECTORS
export const getAllFilterHealthplanIds = (state: any) =>
  getReferralManagement(state).get(key)
