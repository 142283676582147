/**
 * Copies a string of text to the user's clipboard.
 *
 * @param {string} text - the text to copy
 * @param {Node} [parent] - the parent element in which to inject the
 * `textarea` node used for copying. This parameter should usually be omitted,
 * but may be necessary in contexts where an element on the page is preventing
 * focus events at the document root (e.g. Bootstrap modals). In this case,
 * the parent element should be within the offending element.
 * @returns {boolean} whether or not the operation was successful
 */
export const copy = (text, parent = document.body) => {
  const textArea = document.createElement('textarea')

  // Prevent any UI flashing.
  textArea.setAttribute('readonly', '')
  textArea.style.position = 'fixed'
  textArea.style.top = 0
  textArea.style.left = 0
  textArea.style.padding = 0
  textArea.style.fontSize = '12pt'
  textArea.style.background = 'transparent'
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em'
  textArea.style.height = '2em'

  // Append node to DOM so we can execute the copy command.
  textArea.value = text
  parent.appendChild(textArea)
  textArea.select()

  const success = document.execCommand('copy')
  parent.removeChild(textArea)
  return success
}
