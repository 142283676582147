import { Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'destinationAddress'

// CREATORS
const creator = scopedCreator(key)
export const selectDestinationAddress = creator('SELECT_DESTINATION_ADDRESS')
export const resetDestAddress = creator('RESELECT_DEST_ADDRESS')
export const DestinationAddress = Record({
  name: null,
  address: null,
  city: null,
  state: null,
  zipcode: null,
  cellPhone: null,
  phone: null,
  locationType: null,
  distance: null,
  phoneExtension: null,
})

// REDUCER
export const destinationAddress = createReducer(key, DestinationAddress, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [selectDestinationAddress]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetDestAddress]: () => DestinationAddress,
})

export const getDestinationAddress = getIn([rootKey, key])
