import { combineReducers } from 'redux-immutable'
import connectionLostSnackbarOpened from './data/connectionLostSnackbarOpened'
import highPrioritySnackbarOpened from './data/highPrioritySnackbarOpened'
import notifications from './data/notifications'
import key from './key'

const reducer = combineReducers({
  [connectionLostSnackbarOpened.key]: connectionLostSnackbarOpened,
  [highPrioritySnackbarOpened.key]: highPrioritySnackbarOpened,
  [notifications.key]: notifications,
})

reducer.key = key

export default reducer
