import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'redux-routable-react'
import UserSelector from '~/components/UserSelector'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  FormControl,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { bulkReplaceCareTeamUser, getRoles, getTypes } from '../data/careTeams'
import { CARE_TEAM_MANAGEMENT } from '../routes'

const styles = ({ spacing }) => ({
  button: {
    marginBottom: spacing(1),
    marginRight: spacing(1),
  },
  bottomMargin: {
    marginBottom: spacing(4),
  },
  icon: {
    marginRight: spacing(1),
  },
})

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
}

const CareTeamMemberReplacement = ({ classes }) => {
  const [currentUser, setCurrentUser] = useState({})
  const [newUser, setNewUser] = useState({})
  const [role, setRole] = useState('')
  const [type, setType] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const roles = useSelector(getRoles)
  const types = useSelector(getTypes)
  const dispatch = useDispatch()
  const valid = currentUser.id && newUser.id && type && role && !submitted

  const onUserSelected = setter => (id, record) => {
    setSubmitted(false)
    setter({ id, name: record.name })
  }

  const onSubmit = () => {
    setSubmitted(true)
    dispatch(
      bulkReplaceCareTeamUser.requested(type, role, currentUser.id, newUser.id)
    )
  }

  return (
    <React.Fragment>
      <Tooltip title={'Back to Care Team Management'}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={Link}
          route={CARE_TEAM_MANAGEMENT}
        >
          <Icon className={classes.icon}>arrow_back</Icon> Back
        </Button>
      </Tooltip>
      <div className={classes.bottomMargin}>
        <Typography variant="h5"> Care Team Member Replacement</Typography>
        <FormControl fullWidth>
          <InputLabel htmlFor="roleSelector">Care Team Role</InputLabel>
          <Select
            fullWidth
            value={role}
            onChange={e => setRole(e.target.value)}
            input={<Input id="roleSelector" />}
            MenuProps={MenuProps}
          >
            {roles.map(role => (
              <MenuItem key={role.key} value={role.key}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="typeSelector">Care Team Type</InputLabel>
          <Select
            fullWidth
            value={type}
            onChange={e => setType(e.target.value)}
            input={<Input id="typeSelector" />}
            MenuProps={MenuProps}
          >
            {types.map(type => (
              <MenuItem key={type.key} value={type.key}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <UserSelector
          label="Current User"
          onUserSelected={onUserSelected(setCurrentUser)}
        />

        <UserSelector
          label="New User"
          onUserSelected={onUserSelected(setNewUser)}
        />
      </div>
      <Button
        onClick={onSubmit}
        disabled={!valid}
        className={classes.button}
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
      <Typography variant="body1">
        {`This will replace ${currentUser.name || '[Pending Selection]'} with ${
          newUser.name || '[Pending Selection]'
        } on all ${
          types.getIn([type, 'label']) || '[Pending Selection]'
        } care teams on which ${
          currentUser.name || '[Pending Selection]'
        } is the ${roles.getIn([role, 'label']) || '[Pending Selection]'}`}
      </Typography>
    </React.Fragment>
  )
}

CareTeamMemberReplacement.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(CareTeamMemberReplacement)
