import { Map } from 'immutable'
import { SUCCEEDED } from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { scopedCreator } from '~/utils/data'

// Key
const CACHE = 'cache'

// Actions
const creator = scopedCreator(CACHE)

export const cacheFailed = creator(
  'FAILED',
  error => error,
  (_, id) => ({
    message: 'Failed to cache Assessment.',
    payload: { id },
  })
)

export const cacheRequested = creator('REQUESTED', ['id', 'patientId'], () => ({
  message: 'Caching Assessment...',
}))

export const cacheSucceeded = creator('SUCCEEDED', false, () => ({
  message: 'Assessment cached.',
  request: { step: SUCCEEDED },
}))

// Reducer
export default createReducer(CACHE, Map(), {
  [cacheFailed]: (state, action) => state.set(action.meta.payload.id, false),
  [cacheRequested]: (state, action) => state.set(action.payload.id, true),
})

// Selector
export const isCached = (state, id) => state.getIn([CACHE, id], false)
