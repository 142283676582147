import { parseISO } from 'date-fns'
import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

export const TelehealthPatient = Record({
  patientId: null,
  patientName: null,
  riskLevel: null,
  appId: null,
  appName: null,
  city: null,
  state: null,
  lastCallDate: null,
  channel: null,
  healthPlan: null,
  referralSourceType: null,
  referralFacilitator: null,
  nextTargetedVisitDate: null,
  patientStatus: null,
  patientSubstatus: null,
  patientInternalSubstatus: null,
  callCount: null,
})

const TELEHEALTH_PATIENTS = 'telehealthPatients'

const transformTelehealthPatients = (data: any) =>
  data.map(({ lastCallDate, nextTargetedVisitDate, ...patient }: any) =>
    TelehealthPatient({
      lastCallDate: lastCallDate && parseISO(lastCallDate),
      nextTargetedVisitDate:
        nextTargetedVisitDate && parseISO(nextTargetedVisitDate),
      ...patient,
    })
  )

export const fetchTelehealthPatients = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CONTRACTS',
  requestParams: ['userId'],
  operation: (userId: string) => AspireAPI.get(`telehealth/census/${userId}`),
  transform: transformTelehealthPatients,
  messages: { failed: 'There was an issue fetching your telehealth patients' },
})

export default createReducer(TELEHEALTH_PATIENTS, [], {
  [fetchTelehealthPatients.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getTelehealthPatients = pipe(getRoot, get(TELEHEALTH_PATIENTS))
