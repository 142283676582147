import { get, head } from 'lodash'
import React, { useMemo } from 'react'
import useMountEffect from '~/hooks/useMountEffect'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../../widgets/memoizedWrapper'

const Task = props => {
  const { formContext, formData = {}, registry, schema, onChange } = props

  const users = useMemo(
    () =>
      get(formContext.context.tasks, [formData.id, 'users'], []).concat([
        { id: 'other', name: 'Other', role: 'Search for Assignee' },
      ]),
    [formContext, formData]
  )
  const userIds = useMemo(() => users.map(({ id }) => id), [users])
  const userNames = useMemo(
    () => users.map(({ name, role }) => `${name} (${role})`),
    [users]
  )

  useMountEffect(() => {
    if (!formData.assignee) onChange({ ...formData, assignee: head(userIds) })
  })

  return (
    <registry.fields.ObjectField
      {...props}
      schema={{
        ...schema,
        properties: {
          ...schema.properties,
          assignee: {
            ...schema.properties.assignee,
            enum: userIds,
            enumNames: userNames,
          },
        },
      }}
    />
  )
}

Task.propTypes = {
  formContext: PropTypes.shape({
    context: PropTypes.shape({
      tasks: PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          users: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              role: PropTypes.string.isRequired,
            })
          ).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  formData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    assignee: PropTypes.string,
  }),
  registry: PropTypes.shape({
    fields: PropTypes.shape({
      ObjectField: PropTypes.elementType.isRequired,
    }).isRequired,
  }).isRequired,
  schema: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memoizedWrapper(Task)
