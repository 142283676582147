import { List, OrderedMap, Record } from 'immutable'

export const ACTIVE = 'Active'
export const CURRENT = 'Current'
export const DELETED = 'Deleted'
export const DISCONTINUED = 'Discontinued'
export const HISTORICAL = 'Historical'
export const INACTIVE = 'Inactive'
export const ORDERED = 'Ordered'
export const PENDING = 'Pending'
export const RECENTLY_UPDATED = 'Recently Updated'
// For import Status
export const DEFERRED = 'Deferred'
export const CONFIRMED = 'Confirmed'
// For Source
export const MANUALLY_ADDED = 'manually_added'
export const NEXT_GEN = 'next_gen'
export const CLAIMS = 'claims'
// Import Actions
export const IMPORT = 'Import'
export const DEFER = 'Defer'

export const sourceMap = {
  manually_added: 'Manually Added',
  next_gen: 'Next Gen',
  claims: 'Claims',
}

export const importStatusMap = {
  Pending: 'Not Reviewed',
  Confirmed: 'Imported',
  Deferred: 'Deferred',
}

const Status = Record({
  key: null,
  value: null,
  display: null,
  status: null,
})

export const rxStatusMap = OrderedMap({
  Pending: Status({
    key: '0',
    value: PENDING,
    display: 'New Prescription',
    status: PENDING,
  }),
  Ordered: Status({
    key: '1',
    value: ORDERED,
    display: 'New Prescription - Pending',
    status: PENDING,
  }),
  Current: Status({
    key: '2',
    value: CURRENT,
    display: 'Current',
    status: ACTIVE,
  }),
  Historical: Status({
    key: '3',
    value: HISTORICAL,
    display: 'Historical',
    status: INACTIVE,
  }),
  Discontinued: Status({
    key: '4',
    value: DISCONTINUED,
    display: 'Discontinued',
    status: INACTIVE,
  }),
  Deleted: Status({
    key: '5',
    value: DELETED,
    display: 'Deleted',
    status: DELETED,
  }),
})

export const caremoreRxStatusMap = OrderedMap({
  Pending: Status({
    key: '0',
    value: PENDING,
    display: 'New Prescription',
    status: PENDING,
  }),
  Ordered: Status({
    key: '1',
    value: ORDERED,
    display: 'New Prescription - Pending',
    status: PENDING,
  }),
  Current: Status({
    key: '2',
    value: CURRENT,
    display: 'Current',
    status: ACTIVE,
  }),
  Historical: Status({
    key: '3',
    value: HISTORICAL,
    display: 'Historical',
    status: INACTIVE,
  }),
  Discontinued: Status({
    key: '4',
    value: DISCONTINUED,
    display: 'Discontinued',
    status: INACTIVE,
  }),
  Deleted: Status({
    key: '5',
    value: DELETED,
    display: 'Deleted',
    status: DELETED,
  }),
  Active: Status({
    key: '6',
    value: ACTIVE,
    display: 'Active',
    status: ACTIVE,
  }),
  Inactive: Status({
    key: '7',
    value: INACTIVE,
    display: 'Inactive',
    status: INACTIVE,
  }),
  RxClaim: Status({
    key: '8',
    value: ACTIVE,
    display: 'Rx Claim',
    status: ACTIVE,
  }),
})

export const rxStatusList = List([
  RECENTLY_UPDATED,
  PENDING,
  ACTIVE,
  INACTIVE,
  DELETED,
])

const pendingStatuses = rxStatusMap
  .filter(rxStatus => rxStatus.status === PENDING)
  .toList()
  .map(rxStatus => rxStatus.value)

export const isPending = status => pendingStatuses.includes(status)

export const cmMedicationAssessmentArray = [
  'caremore_cm_toc',
  'caremore_complex_nurse_assessment',
  'caremore_complex_nurse_assessment_virtual',
  'caremore_screening_tools',
]
