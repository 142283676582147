import { toString } from 'lodash'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getFormsDevelopment } from './common/shared'

const key = 'formList'

export const fetchFormList = Request({
  typePrefix: key,
  typeBase: 'FETCH_MOCK_FORM',
  operation: () => AspireAPI.get(`forms_development/list_forms`),
  messages: { failed: toString },
})

export default createReducer(key, [], {
  [fetchFormList.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getFormList = pipe(getFormsDevelopment, get(key))
