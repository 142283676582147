import React from 'react'
import { Field } from 'redux-form/immutable'
import {
  NumberField,
  RadioGroupField,
  SelectField,
  TextField,
} from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import { drugForms } from '../../../utils/directionsConstants'
import {
  nullableIfNotPending,
  preventDecimal,
  required,
  requiredIfPending,
  singleDecReg,
  validateDaysSupply,
  validateDispenseNumber,
  validateParmacyNote,
  validateRefill,
} from '../../../utils/formValidators'
import FormFieldWrapper from './FormFieldWrapper'

const styles = ({ spacing }) => ({
  formSpanColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '80%',
    marginRight: spacing(3),
  },
  formSpan: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  halfWidth: {
    width: '48%',
  },
  quaterWidth: {
    width: '22%',
  },
  fullWidth: {
    width: '100%',
  },
  radioGroup: {
    position: 'relative',
    top: spacing(1),
  },
})

const palidateDispenseNoIfPending = nullableIfNotPending(validateDispenseNumber)
const validateRefillIfPending = nullableIfNotPending(validateRefill)

class RxInfoFields extends React.PureComponent {
  onKeyDown = e =>
    !singleDecReg.test(e.target.value + e.key) &&
    Number(e.key) &&
    e.preventDefault()

  render() {
    const { classes, refillsDisabled, isRequired } = this.props

    return (
      <FormFieldWrapper
        title={`Rx Information${isRequired ? ' - Required' : ''}`}
        isCollapsed={!isRequired}
      >
        <span className={classes.formSpan}>
          <span className={classes.formSpanColumn}>
            <span className={classes.formSpan}>
              <Field
                component={NumberField}
                className={classes.quaterWidth}
                label="Days Supply"
                name="daysSupply"
                onKeyDown={preventDecimal}
                validate={[validateDaysSupply]}
              />
              <Field
                component={NumberField}
                className={classes.quaterWidth}
                label="Dispense Quantity"
                name="qty"
                validate={[palidateDispenseNoIfPending]}
                onKeyDown={this.onKeyDown}
              />
              <Field
                component={SelectField}
                className={classes.quaterWidth}
                label="Dispense Unit"
                name="qtyQual"
                native
                validate={[requiredIfPending]}
              >
                <option value="" />
                {drugForms.map(x => (
                  <option key={x.id} value={x.name}>
                    {x.name}
                  </option>
                ))}
              </Field>
              <Field
                component={NumberField}
                className={classes.quaterWidth}
                label="Refills"
                name="refills"
                disabled={refillsDisabled}
                validate={[validateRefillIfPending]}
                onKeyDown={preventDecimal}
              />
            </span>
            <Field
              component={TextField}
              className={classes.fullWidth}
              label="Pharmacy Note"
              name="pharmacyNote"
              validate={[validateParmacyNote]}
            />
          </span>
          <span>
            <Field
              name="substitutionAllowedFlag"
              validate={[required]}
              component={RadioGroupField}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Dispense as Written"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Allow Substitutions"
                checked
              />
            </Field>
          </span>
        </span>
      </FormFieldWrapper>
    )
  }
}

RxInfoFields.propTypes = {
  classes: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  refillsDisabled: PropTypes.bool,
}

RxInfoFields.defaultProps = {
  isRequired: false,
  refillsDisabled: false,
}

export default withStyles(styles)(RxInfoFields)
