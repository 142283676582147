import { Request } from '~/utils/Requestable'
import Aspire from '~/resources/aspire-strict'
import * as Toast from '~/utils/toast'
import * as DE from '@nll/datum/DatumEither'
import { State as ReduxS, Action as ReduxA } from '~/reducerT'
import { JsonFormT } from '~/data/form'
import * as O from 'fp-ts/lib/Option'
import { Action } from './formT'
import * as t from 'io-ts'

// Requests
export const fetch = (patientId: string) =>
  Request<JsonFormT>(
    Aspire.get(
      `v1/patients/${patientId}/contract_forms?type=auth_to_release`,
      JsonFormT.strictType.asDecoder()
    ),
    payload => ReduxA.of.AuthToRelease({ payload }),
    {
      toast: Toast.error(_ =>
        O.some('Failed to load Authorization to Release Medical Record')
      ),
    }
  )

export const submit = (patientId: string) => (form: JsonFormT): ReduxA =>
  Request<number>(
    Aspire.post(`/v1/patients/${patientId}/contracts`, t.number.asDecoder(), {
      data: { ...(form.data as any), type: 'auth_to_release' },
    }),
    payload =>
      ReduxA.of.AuthToRelease({
        payload: DE.isSuccess(payload)
          ? DE.initial
          : DE.map(() => form)(payload),
      }),
    {
      toast: Toast.message(
        () => 'Completed Authorization to Release Medical Record',
        () =>
          O.some('Failed to complete Authorization to Release Medical Record')
      ),
    }
  )

export const init = DE.initial
export const reducer = ({ payload }: Action) =>
  ReduxS.lensFromProp('authToRelease').set(payload)
