import { combineEpics } from 'redux-observable'
import appEntered from './epics/appEntered'
import queueExited from './epics/queueExited'
import codingEncounterSelected from './epics/codingEncounterSelected'
import codingStatusUpdated from './epics/codingStatusUpdated'
import codeAdded from './epics/codeAdded'
import codeDeleted from './epics/codeDeleted'
import fetchPatientInfo from './epics/fetchPatientInfo'
import querySent from './epics/querySent'
import batchListEntered from './epics/batchListEntered'
import batchDetailsEntered from './epics/batchDetailsEntered'
import queryResponseSent from './epics/queryResponseSent'
import batchCreationExited from './epics/batchCreationExited'
import updatePlaceOfServiceSucceeded from './epics/placeOfServiceUpdateSucceeded'

export default combineEpics(
  appEntered,
  batchCreationExited,
  batchDetailsEntered,
  batchListEntered,
  codeAdded,
  codeDeleted,
  codingEncounterSelected,
  codingStatusUpdated,
  fetchPatientInfo,
  queryResponseSent,
  querySent,
  queueExited,
  updatePlaceOfServiceSucceeded
)
