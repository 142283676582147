import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import rootKey from '../key'
import { getRoot } from './common/shared'

export const key = `hraOutreach`

export const fetchHraDuePatients = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_HRA_DUE',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/hra_outreach/hra_due`),
})

export const fetchUtcPatients = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_HRA_UTC',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/hra_outreach/hra_utc`),
})

const initState = {
  hraDue: [],
  hraUtc: [],
}

const set = key => (state, { payload }) => ({ ...state, [key]: payload })

export default createReducer(key, initState, {
  [fetchHraDuePatients.SUCCEEDED]: set('hraDue'),
  [fetchUtcPatients.SUCCEEDED]: set('hraUtc'),
})

const getData = state => getRoot(state).get(key)

export const getHraDuePatients = state => getData(state).hraDue
export const getHraUtcPatients = state => getData(state).hraUtc
