import validate from '~/utils/validate'
import { requiredString } from '~/utils/validation/formValidators'

const validNPINumber = value =>
  value && value.trim().length == '10' ? undefined : 'Invalid NPI Number'

export default values => ({
  firstName: validate(values.get('firstName'), [requiredString]),
  lastName: validate(values.get('lastName'), [requiredString]),
  npiNumber: validate(values.get('npiNumber'), [validNPINumber]),
  practice: validate(values.get('practice'), [requiredString]),
})
