import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import { compose } from '~/utils/functionalHelpers'
import { completeTask, getTaskById, taskSubmitted } from '../data/tasks'

const getTaskData = ({ id, form }) => ({
  id,
  formId: form.id,
  formData: form.formData,
})

export default (action$, state$) =>
  action$.pipe(
    ofType(taskSubmitted),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    map(([id, state]) => compose(getTaskData, getTaskById(id))(state)),
    map(completeTask.requested)
  )
