import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchCaseManager } from '~/features/contacts'
import { CONTACT_MANAGEMENT_CASE_MANAGER_RECORD } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(entered(CONTACT_MANAGEMENT_CASE_MANAGER_RECORD)),
    pluck('payload', 'params', 'caseManagerId'),
    filter(Boolean),
    map(fetchCaseManager.requested)
  )
