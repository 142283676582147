import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getPatientRecord } from './common/shared'

const key = 'historicClaims'

export const Claim = Record({
  diagnosisCode: null,
  globalMemberId: null,
  icdCodeDescription: null,
  id: null,
  patientId: null,
  priority: null,
  problemType: null,
  serviceDateFrom: null,
})

const newClaim = ({ problemType, priority, ...rest }) =>
  Claim({
    ...rest,
    problemType: problemType || 'Uncategorized',
    priority: priority ? 'priority' : 'nonpriority',
  })

const fetchHistoricClaimsApi = patientId =>
  AspireAPI.get(`claims_history/${patientId}/claims`)

export const fetchHistoricClaims = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: fetchHistoricClaimsApi,
  transform: into(newClaim, 'id'),
  messages: { failed: 'There was a problem fetching historic claims data' },
})

export default createReducer(key, Map(), {
  [fetchHistoricClaims.REQUESTED]: () => Map(),
  [fetchHistoricClaims.SUCCEEDED]: (_state, { payload }) => payload,
})

const getPatientClaims = pipe(getPatientRecord, get(key))

export const getPatientClaimsList = createSelector([getPatientClaims], claims =>
  claims
    .toList()
    .sort((a, b) => (a.serviceDateFrom > b.serviceDateFrom ? -1 : 1))
)
