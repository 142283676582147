import { debounce as createDebounce } from 'lodash'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export default (
  actionCreator: (...args: any[]) => any,
  debounce = false,
  debounceTime = 500
) => {
  const dispatch = useDispatch()

  const callback = useCallback((...args) => dispatch(actionCreator(...args)), [
    actionCreator,
    dispatch,
  ])

  const debouncedCallback = useCallback(
    createDebounce(callback, debounceTime),
    [callback, debounceTime]
  )

  return debounce ? debouncedCallback : callback
}
