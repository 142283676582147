import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change } from 'redux-form'
import { InfoDialog } from '~/components/dialogs'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import TextFieldWithAdornment from '../../components/TextFieldWithAdornment'
import { ipasCleared } from '../../data/ipas'
import IPALookup from './IPALookup'

const IPAField = ({ className, form, changeValue, ipasCleared }) => {
  const [open, toggleOpenTrue, toggleOpenFalse] = useToggle()

  const onClose = useCallback(() => {
    toggleOpenFalse()
    ipasCleared()
  }, [ipasCleared])

  const onSelect = useCallback(
    ipa => {
      changeValue(form, 'ipaId', ipa.id)
      changeValue(form, 'ipa', ipa.name)

      onClose()
    },
    [form, changeValue, onClose]
  )

  const onClear = useCallback(() => {
    changeValue(form, 'ipaId', '')
    changeValue(form, 'ipa', '')
  }, [form, changeValue])

  return (
    <React.Fragment>
      <Field
        className={className}
        component={TextFieldWithAdornment}
        name="ipa"
        label="IPA"
        onSearch={toggleOpenTrue}
        onClear={onClear}
        disabled
      />

      <InfoDialog
        open={open}
        title="IPA Lookup"
        onClose={onClose}
        maxWidth="lg"
        fullWidth
      >
        <IPALookup onSelect={onSelect} />
      </InfoDialog>
    </React.Fragment>
  )
}

IPAField.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  ipasCleared: PropTypes.func.isRequired,
}

export default compose(
  connect(null, {
    changeValue: change,
    ipasCleared,
  })
)(IPAField)
