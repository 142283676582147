import { List } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import CommandPalette from '../components/CommandPalette'
import { close, getCommands, getIsOpen, open } from '../data'

export class CommandPaletteContainer extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onGlobalKeydown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onGlobalKeydown)
  }

  isCommandPaletteHotkey(e) {
    return (e.ctrlKey && e.key === 'k') || (e.ctrlKey && e.key === ' ')
  }

  runCommand = command => {
    this.props.onRunCommand(command)
    this.props.onClose()
  }

  toggle = () => {
    if (this.props.isOpen) {
      this.props.onClose()
    } else {
      this.props.onOpen()
    }
  }

  onGlobalKeydown = e => {
    if (this.isCommandPaletteHotkey(e)) {
      this.toggle()
    }
  }

  render() {
    const { isOpen, onClose, commands } = this.props
    if (!isOpen) return null
    return (
      <CommandPalette
        commands={commands.toJS()}
        onRunCommand={this.runCommand}
        onClose={onClose}
      />
    )
  }
}
CommandPaletteContainer.propTypes = {
  isOpen: PropTypes.bool,
  commands: PropTypes.list.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRunCommand: PropTypes.func.isRequired,
}

export default connect(
  state => {
    const isOpen = getIsOpen(state)
    return {
      isOpen,
      commands: isOpen ? getCommands(state) : List(),
    }
  },
  dispatch => ({
    onOpen: () => dispatch(open()),
    onClose: () => dispatch(close()),
    onRunCommand: command => dispatch(command.action()),
  })
)(CommandPaletteContainer)
