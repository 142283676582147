import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { REPORTS_DASHBOARD_REPORT } from '../router'
import { getReportsDashboard } from './common/shared'

const key = 'currentReportId'

const reducer = paramsReducer(REPORTS_DASHBOARD_REPORT, ({ reportId }: any) =>
  Number(reportId)
)

reducer.key = key

export const getCurrentReportId = pipe(getReportsDashboard, get(key))

export default reducer
