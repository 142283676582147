import React from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import InvisibleDivider from '~/components/InvisibleDivider'
import { getFailedFaxes } from '../data/faxes'
import {
  StatisticCard,
  StatisticCardContainer,
} from '~/components/StatisticCards'
import FailedFaxesGrid from './FailedFaxesGrid'

const Faxes = () => {
  const failedFaxes = useSelector(getFailedFaxes)

  const groupedFaxes = failedFaxes.reduce((result: any, currentValue: any) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    const grouped = (result[currentValue.type] =
      result[currentValue.type] || [])

    grouped.push(currentValue)

    return result
  }, {})

  const mapFaxLabels = (key: any) => {
    const faxTypes = {
      discharge_workflow: 'Discharge Fax',
      md_reports: 'MD Report Fax',
      order_workflow: 'Order Fax',
      unspecified: 'Unspecified Fax',
    }

    return faxTypes[key as keyof typeof faxTypes]
  }

  return (
    <div>
      <DevXTitleHeader title="Failed Fax Summary" />
      {!isEmpty(groupedFaxes) && (
        <StatisticCardContainer columns={3} dense>
          {Object.keys(groupedFaxes).map(key => {
            const count = groupedFaxes[key].length
            const plural = count > 1 ? 'es' : ''

            return (
              <StatisticCard
                key={key}
                label={mapFaxLabels(key) + plural}
                value={count}
              />
            )
          })}
        </StatisticCardContainer>
      )}
      <InvisibleDivider />
      <DevXTitleHeader title="Failed Faxes" />
      {failedFaxes && (
        <FailedFaxesGrid
          failedFaxes={failedFaxes}
          mapFaxLabels={mapFaxLabels}
        />
      )}
    </div>
  )
}

export default Faxes
