// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { fetchCarePlan, getCarePlan } from './root'

const CARE_PLAN_DISABLED = 'carePlanDisabled'

export default createReducer(CARE_PLAN_DISABLED, false, {
  [fetchCarePlan.SUCCEEDED]: (
    _state: any,
    { payload: { carePlanDisabled } }: any
  ) => carePlanDisabled,
})

export const getCarePlanDisabled = pipe(getCarePlan, get(CARE_PLAN_DISABLED))
