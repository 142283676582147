// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { change } from 'redux-form/immutable'
import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import { addMessage } from '../data/popupMessages'
import {
  SUBMIT_CASE_REQUESTED,
  referralCaseStatusUpdated,
  requestStartPolling,
  submitCaseCompleted,
  submitCaseFailed,
  submitReferral,
} from '../data/referrals'
const getErrors = (results: any) =>
  results.validationMessages.filter((x: any) => !(x === null)).join(', ')

const submit = (caseId: any) =>
  from(submitReferral(caseId)).pipe(
    mergeMap(data => {
      if (data && (data as any)[0] && (data as any)[0].patient_id) {
        const results = (data as any)[0]
        return of(
          addMessage(
            'Referral case verified: it may take a moment for the patient record to be available.'
          ),
          submitCaseCompleted(caseId),
          referralCaseStatusUpdated({ caseId, substatus: 'sync_incomplete' }),
          change(
            `referral-${caseId}`,
            'aspire.patientPgid',
            results.patient_id
          ),
          requestStartPolling(caseId)
        )
      } else {
        const results = data
        return of(
          submitCaseFailed(),
          addMessage(`Referral case has errors: ${getErrors(results)}`)
        )
      }
    }),
    catchError(showError('Error! Problem Uploading Patient'))
  )
export default (action$: any) =>
  action$.pipe(
    ofType(SUBMIT_CASE_REQUESTED),
    mergeMap(({ caseId }) =>
      concat(
        loading(submit(caseId)),
        of(addMessage('Referral case has been submitted for verification.'))
      )
    )
  )
