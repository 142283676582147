import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const PATIENT_LISTS = 'patientLists'

const PatientListItem = Record({
  id: null,
  list: null,
  patientId: null,
  patientName: null,
  patientLastName: null,
  patientFirstName: null,
  program: null,
  dateOfBirth: null,
  appId: null,
  appName: null,
  market: null,
  channel: null,
  createdDate: null,
  reason: null,
  riskLevel: null,
  nextTargetedVisitDate: null,
  lastVisitDate: null,
  acpDocuments: null,
  lastCallDate: null,
  swNtvd: null,
})

const transformPatientLists = patients => List(patients.map(PatientListItem))

export const fetchPatientLists = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_LISTS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get('dashboard/patient_lists', {
      params: {
        userId,
      },
    }),
  transform: transformPatientLists,
  messages: {
    failed: 'There was an issue fetching your lists',
  },
})

export const addPatientListItem = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_PATIENT_LIST_ITEM',
  requestParams: ['userId', 'patientId', 'list', 'reason'],
  operation: (userId, patientId, list, reason) =>
    AspireAPI.post('dashboard/patient_lists/list_item', {
      userId,
      patientId,
      list,
      reason,
    }),
  transform: PatientListItem,
  messages: {
    succeeded: 'Patient successfully added to list',
    failed: 'There was an issue adding this patient',
  },
})

export const savePatientListItem = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PATIENT_LIST_ITEM',
  requestParams: ['listItemId', 'reason'],
  operation: (listItemId, reason) =>
    AspireAPI.put(`dashboard/patient_lists/list_item/${listItemId}`, {
      reason,
    }),
  messages: {
    succeeded: 'List successfully saved',
    failed: 'There was an issue saving this list',
  },
})

export const deletePatientListItem = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_PATIENT_LIST_ITEM',
  requestParams: ['listItemId'],
  operation: listItemId =>
    AspireAPI.delete(`dashboard/patient_lists/list_item/${listItemId}`),
  messages: {
    succeeded: 'Patient successfully deleted from list',
    failed: 'There was an issue deleting this patient',
  },
})

const initState = List()

export default createReducer(PATIENT_LISTS, initState, {
  [fetchPatientLists.SUCCEEDED]: (_state, { payload }) => payload,
  [addPatientListItem.SUCCEEDED]: (state, { payload }) => state.push(payload),
  [savePatientListItem.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { listItemId, reason },
        },
      },
    }
  ) => {
    const index = state.findIndex(listItem => listItem.id === listItemId)
    const listItem = state.get(index)
    return state.set(index, listItem.set('reason', reason))
  },
  [deletePatientListItem.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { listItemId },
        },
      },
    }
  ) => {
    const index = state.findIndex(listItem => listItem.id === listItemId)
    return state.delete(index)
  },
  [fetchPatientLists.REQUESTED]: () => initState,
})

export const getPatientLists = pipe(getRoot, get(PATIENT_LISTS))
