import { List, OrderedMap } from 'immutable'
import { createSelector } from 'reselect'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { HIGH, Link, NORMAL, Notification, getRoot } from './common/shared'

// KEY
const key = 'notifications'

// CONSTANTS
export const NOTIFICATIONS_FETCHED = type(key, 'NOTIFICATIONS_FETCHED')
export const NOTIFICATIONS_CLEARED = type(key, 'NOTIFICATIONS_CLEARED')
export const NOTIFICATION_MARKED_UNREAD = type(
  key,
  'NOTIFICATION_MARKED_UNREAD'
)
export const NOTIFICATION_ADDED = type(key, 'NOTIFICATION_ADDED')

// ACTION CREATORS
export const notificationsFetched = creator(NOTIFICATIONS_FETCHED, 'data')
export const notificationsCleared = creator(
  NOTIFICATIONS_CLEARED,
  'notificationIds'
)
export const notificationMarkedUnread = creator(
  NOTIFICATION_MARKED_UNREAD,
  'notification'
)
export const notificationAdded = creator(NOTIFICATION_ADDED, 'data')

// REDUCER
export default createReducer(key, OrderedMap(), {
  [NOTIFICATIONS_FETCHED]: (state, { payload: { data } }) => state.merge(data),
  [NOTIFICATIONS_CLEARED]: (state, { payload: { notificationIds } }) =>
    notificationIds.reduce(
      (newState, notificationId) => newState.delete(notificationId),
      state
    ),
  [NOTIFICATION_MARKED_UNREAD]: (state, { payload: { notification } }) =>
    state
      .set(notification.id, notification)
      .sort((a, b) => a.createdAt < b.createdAt),
  [NOTIFICATION_ADDED]: (state, { payload: { data } }) =>
    OrderedMap([[data.id, data]]).merge(state),
})

// SELECTORS
const getSelector = priority =>
  pipe(
    getRoot,
    get(key),
    createSelector(
      notifications => notifications,
      notifications =>
        notifications.filter(notification => notification.priority === priority)
    )
  )
const getHighPriorityNotifications = getSelector(HIGH)
const getNormalNotifications = getSelector(NORMAL)

// Make high priority notifications bubble to top
export const getNotifications = state =>
  OrderedMap(getHighPriorityNotifications(state)).merge(
    getNormalNotifications(state)
  )

// TRANSFORM CHANNEL DATA
export const transformChannelData = ({ notifications }) =>
  notifications.reduce(
    (notificationsAcc, notification) =>
      notificationsAcc.set(
        notification.id,
        mapNotificationToRecord(notification)
      ),
    OrderedMap()
  )

export const mapNotificationToRecord = notification =>
  Notification({
    id: notification.id,
    title: notification.title,
    message: notification.message,
    primaryLink: notification.links && notification.links.primary,
    secondaryLinks:
      notification.links && notification.links.secondary
        ? notification.links.secondary.reduce(
            (linksAcc, link) => linksAcc.push(Link(link)),
            List()
          )
        : List(),
    createdAt: notification.created_at,
    viewedAt: notification.viewed_at,
    priority: notification.priority,
  })
