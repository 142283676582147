// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { change } from 'redux-form/immutable'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  mergeMap,
  pluck,
  first,
  map,
  mapTo,
  switchMap,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import { addMessage } from '../data/popupMessages'
import {
  ASSIGNMENT_REQUESTED,
  assignment,
  assignmentFailed,
  assignmentSucceeded,
  channelSelectionDialogRequested,
  getReferralFormValues,
  potentialCareTeamsUpdated,
  potentialChannelsUpdated,
  referralCaseStatusUpdated,
  saveReferral,
} from '../data/referrals'

const message = 'Error! Problem assigning patient'

const makeFormChanges = (data: any) => {
  const changes = []
  const form = `referral-${data.caseId}`
  const ct = data.assignedCareTeam || {}
  const channel = data.assignedChannel || {}
  ct.ownerName &&
    changes.push(change(form, 'aspire.assignedAPP.name', ct.ownerName))
  ct.ownerId && changes.push(change(form, 'aspire.assignedAPP.id', ct.ownerId))
  ct.teamLabel &&
    changes.push(change(form, 'aspire.assignedCareTeam.label', ct.teamLabel))
  ct.teamId &&
    changes.push(change(form, 'aspire.assignedCareTeam.id', ct.teamId))
  channel.primaryContract &&
    changes.push(
      change(form, 'aspire.primaryChannel.name', channel.primaryContract)
    ) &&
    changes.push(
      change(
        form,
        'healthplan.primaryInsurance.channelName',
        channel.primaryContract
      )
    )
  channel.channelId &&
    changes.push(change(form, 'aspire.primaryChannel.id', channel.channelId))
  return changes
}

const saveReferralRequested = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ASSIGNMENT_REQUESTED),
    map(({ caseId, form }: any) => {
      const state = state$.value
      const values = getReferralFormValues(state, form)

      return saveReferral.requested(caseId, values)
    })
  )

const assignmentRequested = (action$: any) =>
  action$.pipe(
    ofType(ASSIGNMENT_REQUESTED),
    switchMap(({ params }) =>
      action$.pipe(
        ofType(saveReferral.SUCCEEDED),
        first(),
        mapTo(assignment.requested(params))
      )
    )
  )

const succeeded = (action$: any) =>
  action$.pipe(
    ofType(assignment.SUCCEEDED),
    pluck('payload'),
    mergeMap(data => {
      const channelsUpdates =
        (data as any).channels && (data as any).channels.length > 1
          ? potentialChannelsUpdated((data as any).channels)
          : potentialChannelsUpdated([])
      const channelSelectionDialog =
        (data as any).channels && (data as any).channels.length > 1
          ? // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
            [channelSelectionDialogRequested()]
          : []
      const careTeamsUpdates =
        (data as any).careTeams && (data as any).careTeams.length > 0
          ? potentialCareTeamsUpdated((data as any).careTeams)
          : potentialCareTeamsUpdated([])
      if (
        data &&
        ((data as any).assignedCareTeam || (data as any).assignedChannel)
      ) {
        return of(
          assignmentSucceeded(),
          ...makeFormChanges(data),
          addMessage((data as any).feedback.join('.\n ')),
          channelsUpdates,
          ...channelSelectionDialog,
          careTeamsUpdates
        )
      } else {
        return of(
          assignmentFailed(),
          referralCaseStatusUpdated({
            caseId: (data as any).caseId,
            substatus: 'Pending Manual Assignment',
          }),
          addMessage((data as any).feedback.join('.\n ')),
          channelsUpdates
        )
      }
    }),
    catchError((e = { message }) => showError(e.message)(e))
  )

const failed = (action$: any) =>
  action$.pipe(
    ofType(assignment.FAILED),
    mergeMap(data => {
      return of(
        assignmentFailed(),
        referralCaseStatusUpdated({
          caseId: (data as any).caseId,
          substatus: 'Pending Manual Assignment',
        }),
        potentialChannelsUpdated([]),
        potentialCareTeamsUpdated([])
      )
    }),
    catchError((e = { message }) => showError(e.message)(e))
  )
export default combineEpics(
  saveReferralRequested,
  assignmentRequested,
  succeeded,
  failed
)
