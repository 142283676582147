import React from 'react'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ breakpoints, spacing }) => ({
  columns: {
    display: 'flex',
  },
  [breakpoints.up('xs')]: {
    columns: {
      flexDirection: 'column',
      '& div:first-child > div > h6': {
        marginTop: 0,
      },
      '& > *:not(:first-child)': {
        paddingTop: spacing(4),
      },
    },
  },
  [breakpoints.up('sm')]: {
    columns: {
      flexDirection: 'row',
      '& div > div > h6': {
        marginTop: 0,
      },
      '& > *:not(:first-child)': {
        paddingTop: 0,
      },
      '& > *:not(:last-child)': {
        marginRight: spacing(4),
      },
    },
  },
})

const Columns = ({ classes, children }) => (
  <div className={classes.columns}>{children}</div>
)

Columns.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default withStyles(styles)(Columns)
