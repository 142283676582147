import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles, withTheme } from '@material-ui/core/styles'
import memoizedWrapper from '../widgets/memoizedWrapper'

const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: spacing(0.5),
  },
  warning: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
    color: palette.severity.error.dynamicHighContrast,
  },
})

const WarningField = ({ classes, schema, uiSchema }) => {
  const renderContent = (text, index) => (
    <Typography key={index}>{text}</Typography>
  )

  const { 'ui:options': options } = uiSchema
  const { title } = schema
  const { content } = options

  return (
    <div className={classes.root}>
      <div className={classes.warning}>
        {title && (
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        )}
        {content && content.map(renderContent)}
      </div>
    </div>
  )
}

WarningField.propTypes = {
  classes: PropTypes.object.isRequired,
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
}

WarningField.defaultProps = {
  uiSchema: {},
}

export default compose(
  memoizedWrapper,
  withTheme,
  withStyles(styles)
)(WarningField)
