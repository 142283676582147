import {
  AOfMorphADT,
  AType,
  AsOpaque,
  EType,
  Ctor,
  ReduxAction,
  Variant,
  summon,
} from '~/utils/type'
import { Async } from '~/resources/resource-strict'

const UserTimelineEvent_ = summon(F =>
  F.interface(
    {
      linkId: F.string(),
      secondId: F.nullable(F.string()),
      linkType: F.string(),
      category: F.string(),
      activity: F.string(),
      createdAt: F.date(),
      createdById: F.string(),
      createdByName: F.string(),
      note: F.string(),
    },
    'UserTimelineEvent'
  )
)
interface UserTimelineEventRaw extends EType<typeof UserTimelineEvent_> {}
export interface UserTimelineEvent extends AType<typeof UserTimelineEvent_> {}
export const UserTimelineEvent = AsOpaque<
  UserTimelineEventRaw,
  UserTimelineEvent
>()(UserTimelineEvent_)

const IndexState = Async(summon(F => F.array(UserTimelineEvent(F))))

const Index_ = Ctor('Index', IndexState)
interface IndexRaw extends EType<typeof Index_> {}
export interface Index extends AType<typeof Index_> {}
export const Index = AsOpaque<IndexRaw, Index>()(Index_)

// Add another request representing some state you wish to get from the server here...
// export const ShowState = Async(summon(F => F.string()))
// export const Show_ = Ctor('Show', ShowState)
// export interface Show extends AType<typeof Show_> {}
// export interface ShowRaw extends EType<typeof Show_> {}
// export const Show = AsOpaque<ShowRaw, Show>()(Show_)

const State_ = summon(F =>
  F.interface(
    {
      index: IndexState(F),
      // show: ShowState(F)
    },
    'State'
  )
)
interface StateRaw extends EType<typeof State_> {}
export interface State extends AType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)

export const Action = Variant({
  Index,
  // Show
})
export interface Action
  extends ReduxAction<'UserTimelineEvent', AOfMorphADT<typeof Action>> {}
