import React from 'react'
import { PATIENT_RECORD_PATIENT_INFO } from '~/apps/patientRecord/router'
import Tooltip from '~/components/Tooltip'
import { OpenLink } from '~/components/links'
import PropTypes from '~/utils/propTypes'
import {
  Icon,
  IconButton,
  InputAdornment as MuiInputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  iconButton: {
    marginRight: spacing(0.5),
  },
}))

const InputAdornment = ({ editing, event, onClear, onFilter }) => {
  const classes = useStyles()

  return (
    <MuiInputAdornment position="end">
      {editing && event.patientId && (
        <Tooltip title="Clear Patient">
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={onClear}
          >
            <Icon>clear</Icon>
          </IconButton>
        </Tooltip>
      )}

      {event.patientId && (
        <Tooltip title="Open Patient Record">
          <IconButton
            className={classes.iconButton}
            size="small"
            route={PATIENT_RECORD_PATIENT_INFO}
            params={{ patientId: event.patientId }}
            component={OpenLink}
          >
            <Icon>link</Icon>
          </IconButton>
        </Tooltip>
      )}

      {editing && (
        <Tooltip title="Filters">
          <IconButton size="small" onClick={onFilter}>
            <Icon>filter_list</Icon>
          </IconButton>
        </Tooltip>
      )}
    </MuiInputAdornment>
  )
}

InputAdornment.propTypes = {
  editing: PropTypes.bool,
  event: PropTypes.record.isRequired,
  onClear: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default InputAdornment
