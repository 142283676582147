import { combineEpics } from 'redux-observable'
import datesEpic from './dates'
import getSwimlaneState from './getSwimlaneState'
import patientEventsEpic from './patientEvents'
import setSwimlaneState from './setSwimlaneState'
import swimlaneEpic from './swimlane'

export default combineEpics(
  datesEpic,
  patientEventsEpic,
  getSwimlaneState,
  setSwimlaneState,
  swimlaneEpic
)
