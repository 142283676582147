// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const MERGE_DIALOG_PATIENT_ID_SET =
  'reconciliation/MERGE_DIALOG_PATIENT_ID_SET'
export const MERGE_DIALOG_PATIENT_ID_CLEARED =
  'reconciliation/MERGE_DIALOG_PATIENT_ID_CLEARED'

// ACTION CREATORS
export const mergeDialogPatientIdSet = (patientId: any) => ({
  type: MERGE_DIALOG_PATIENT_ID_SET,
  patientId,
})
export const mergeDialogPatientIdCleared = () => ({
  type: MERGE_DIALOG_PATIENT_ID_CLEARED,
})

// KEY
export const key = 'mergeDialogPatientId'

// REDUCERS
export default createReducer(key, null, {
  [MERGE_DIALOG_PATIENT_ID_SET]: (_state: any, { patientId }: any) => patientId,
  [MERGE_DIALOG_PATIENT_ID_CLEARED]: () => null,
})

// SELECTORS
export const getMergeDialogPatientId = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
