import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import DisplayDate from '~/components/DisplayDate'
import WithTooltip from '~/components/WithTooltip'
import ViewRowActions from '~/components/rowActions/ViewRowActions'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import { modeSet } from '../data/hospitalizationDialog'
import { getHospitalizations } from '../data/hospitalizations'
import HospitalizationDetails from './HospitalizationDetails'

const columns = [
  {
    name: 'caseNumber',
    title: 'Case Number',
    getCellValue: ({ caseNumber }) => (caseNumber ? caseNumber : 'Pending'),
  },
  {
    name: 'admissionDate',
    title: 'Admission Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'dischargeDate',
    title: 'Discharge Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'hospitalName',
    title: 'Hospital Name',
    displayComponent: WithTooltip,
  },
  {
    name: 'bedType',
    title: 'Bed Type',
  },
  {
    name: 'reason',
    title: 'Reason',
  },
  {
    name: 'notifiedBy',
    title: 'Notified By',
  },
]

const columnExtensions = [
  { columnName: 'caseNumber', width: 150 },
  { columnName: 'admissionDate', width: 150 },
  { columnName: 'dischargeDate', width: 175 },
]

const defaultSorting = [{ columnName: 'admissionDate', direction: 'desc' }]

const Hospitalizations = ({ hospitalizations, onAdd, onEdit, onDelete }) => {
  const rows = useMemo(() => hospitalizations.toArray(), [hospitalizations])

  const RowActions = useCallback(
    ({ row }) => (
      <ViewRowActions row={row} onEdit={onEdit} onDelete={onDelete} />
    ),
    [onEdit, onDelete]
  )

  return (
    <React.Fragment>
      <DevXTitleHeader
        action={onAdd}
        buttonText="Add Hospitalization"
        title="Hospitalizations"
      />

      <Paper>
        <DevXGrid
          rows={rows}
          columns={columns}
          columnExtensions={columnExtensions}
          sortable
          defaultSorting={defaultSorting}
          detailComponent={HospitalizationDetails}
          rowActions={RowActions}
          rowActionsCount={2}
        />
      </Paper>
    </React.Fragment>
  )
}

Hospitalizations.propTypes = {
  hospitalizations: PropTypes.map.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  hospitalizations: getHospitalizations(state),
})

const mapDispatchToProps = (dispatch, { patientId }) => ({
  onAdd: () => dispatch(modeSet('add', patientId)),
  onEdit: hospitalization =>
    dispatch(modeSet('edit', patientId, hospitalization)),
  onDelete: hospitalization =>
    dispatch(modeSet('delete', patientId, hospitalization)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Hospitalizations)
