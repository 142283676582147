import { combineReducers } from 'redux-immutable'
import actionSuccess from './data/actionSuccess'
import currentOrder from './data/currentOrder'
import dialog from './data/dialog'
import newOrder from './data/newOrder'
import orderDetail from './data/orderDetail'
import orderTypes from './data/orderTypes'
import orders from './data/orders'
import patientId from './data/patientId'
import saveIndicator from './data/saveIndicator'
import selectedOrder from './data/selectedOrder'
import signature from './data/signature'
import userId from './data/userId'
import key from './key'

const reducer = combineReducers({
  [actionSuccess.key]: actionSuccess,
  [currentOrder.key]: currentOrder,
  [dialog.key]: dialog,
  [newOrder.key]: newOrder,
  [orderDetail.key]: orderDetail,
  [orderTypes.key]: orderTypes,
  [orders.key]: orders,
  [patientId.key]: patientId,
  [saveIndicator.key]: saveIndicator,
  [selectedOrder.key]: selectedOrder,
  [selectedOrder.key]: selectedOrder,
  [signature.key]: signature,
  [userId.key]: userId,
})

reducer.key = key

export default reducer
