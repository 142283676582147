import React from 'react'
import { values as medAherenceOptions } from '~/fieldValues/medication_adherence_options'

export const AdherenceOptions = (
  <React.Fragment>
    <option disabled={true} value="" />
    {medAherenceOptions.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </React.Fragment>
)
