import { Goal, Problem } from '../data/common/shared'

type NotesKey = 'problemAssessmentNotes' | 'actionAssessmentNotes'
const reduceAssessmentNotes = (problems: any, key: NotesKey) =>
  problems.reduce(
    (acc: any, problem: Problem) =>
      problem[key] ? { ...acc, [problem.id]: problem[key] } : acc,
    {}
  )

export default (problems: any) => {
  const selectedProblems = problems.filter(({ selected }: Problem) => selected)
  const selectedGoals = selectedProblems.flatMap(({ goals }: Problem) => goals)
  const selectedActions = selectedGoals.flatMap(({ actions }: Goal) => actions)
  const problemNotes = reduceAssessmentNotes(
    selectedProblems,
    'problemAssessmentNotes'
  )
  const actionNotes = reduceAssessmentNotes(
    selectedProblems,
    'actionAssessmentNotes'
  )
  const assessmentNotes = { problemNotes, actionNotes }

  return {
    problems,
    selectedProblems,
    selectedGoals,
    selectedActions,
    assessmentNotes,
  }
}
