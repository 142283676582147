import React from 'react'
import { useSelector } from 'react-redux'
import { getUserById } from '~/data/users'
import { getCurrentAPPId } from '~/features/scheduling/data/currentAPPId'
import { getCurrentPatientId } from '~/features/scheduling/data/currentPatientId'
import { useAction } from '~/hooks'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getDate } from '../../data/calendar/date'
import { getTimeZone } from '../../data/calendar/timeZone'
import { newEventOpened } from '../../data/events/creating'
import { newEventCreated } from '../../data/events/root'
import { eventTypes } from '../EventTypes'

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(1),
  },
}))

const AddVisitEventButton = () => {
  const classes = useStyles()

  const appId = useSelector(getCurrentAPPId)
  const patientId = useSelector(getCurrentPatientId)
  const user = useSelector(state => getUserById(state, appId))
  const date = useSelector(getDate)
  const timeZone = useSelector(getTimeZone)
  const openNewEvent = useAction(newEventOpened)
  const createNewEvent = useAction(newEventCreated)

  const onClick = () => {
    const defaultEvent = eventTypes.Visit.defaults
    const ownerId = user.id
    const ownerName = user.name

    openNewEvent()
    createNewEvent(
      { ...defaultEvent, patientId, ownerId, ownerName },
      date,
      timeZone
    )
  }

  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      <Icon className={classes.icon} fontSize="small">
        today
      </Icon>
      Visit
    </Button>
  )
}

export default AddVisitEventButton
