import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'

/* RECORD */
export const App = Record({
  id: null,
  name: null,
  market: null,
  active: null,
})

/* CONSTANTS */
export const APPS_STORED = 'apps/APPS_STORED'

/* Actions */
export const appsStored = apps => ({ type: APPS_STORED, apps })

/* KEY */
export const key = 'apps'

/* REDUCER */
export default createReducer(key, Map(), {
  [APPS_STORED]: (state, { apps }) => state.merge(apps),
})

const mapData = data => Map(data.map(d => [d.id, App(d)]))

/* API */
export const fetchApps = () => AspireAPI.get('v1/np').then(mapData)

/* SELECTORS */
export const getApps = state => state.get(key)
export const getActiveApps = state =>
  getApps(state).filter(app => app.get('active'))
