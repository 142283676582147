import PropTypes from 'prop-types'
import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette }) => ({
  link: {
    color: palette.contentText.secondaryLabel,
    textDecoration: 'none',
  },
})

const getPhoneHref = phone => 'tel:1' + phone.replace(/\D+/g, '')

const PhoneNumber = ({ classes, value }) =>
  value ? (
    <a className={classes.link} href={getPhoneHref(value)}>
      {value}
    </a>
  ) : null

PhoneNumber.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
}

export default compose(React.memo, withStyles(styles))(PhoneNumber)
