import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'reinstatementDialog'

export const getDialogOpenValue = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const createReinstatementDialogOpened = creator(
  'CREATE_REINSTATEMENT_DIALOG_OPENED'
)
export const createReinstatementDialogClosed = creator(
  'CREATE_REINSTATEMENT_DIALOG_CLOSED'
)

// REQUEST
export const saveReinstatement = Request({
  typePrefix: key,
  typeBase: 'SAVE_REINSTATEMENT',
  requestParams: ['params'],
  operation: ({
    dischargeId,
    reinstatementNotes,
    reinstatementDate,
    reinstatementReason,
    patientId,
  }: any) =>
    AspireAPI.post(`v1/patient/${patientId}/reinstate`, {
      dischargeId,
      reinstatementDate,
      reinstatementNotes,
      reinstatementReason,
    }),
  messages: {
    succeeded: 'New reinstatement successfully saved',
    failed: 'Could not save new reinstatement',
  },
})

// REDUCER
export default createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [createReinstatementDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [createReinstatementDialogOpened]: () => true,
  [saveReinstatement.SUCCEEDED]: () => false,
})
