import createReducer from '~/utils/createReducer'
import { getIn } from '~/utils/data'
import rootKey from '../../key'
import { sendToResearch } from '../sendToResearchDialog'
import {
  completeResearchCase,
  updateResearchCase,
} from '../updateResearchDialog'

// KEYS
const key = 'requestIsProcessing'

export const getRequestProcessingValue = getIn([rootKey, key])

// REDUCER
export const isRequestProcessing = createReducer(key, false, {
  [completeResearchCase.REQUESTED]: () => true,
  [completeResearchCase.SUCCEEDED]: () => false,
  [sendToResearch.REQUESTED]: () => true,
  [sendToResearch.SUCCEEDED]: () => false,
  [updateResearchCase.REQUESTED]: () => true,
  [updateResearchCase.SUCCEEDED]: () => false,
})
