import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { AppointmentType } from './interfaces'

// KEY
const APPOINTMENTTYPE = 'appointmentType'

export const Vendor = Record({
  activeInd: null,
  id: null,
  timeInterval: null,
  type: null,
})

// TRANSFORMER
const mapAppointmentType = (results: [AppointmentType]) =>
  List(
    results?.map(apptType =>
      Vendor({
        activeInd: apptType?.activeInd,
        id: apptType?.id,
        timeInterval: apptType?.timeInterval,
        type: apptType?.type,
      })
    )
  )

// REQUEST
export const appointmentType = Request({
  typePrefix: rootKey,
  typeBase: 'APPOINTMENTTYPES',
  operation: () => AspireAPI.get('transportation/lookup/appointment_type'),
  transform: mapAppointmentType,
  messages: { failed: 'There was an issue fetching Appointment Types' },
})

// REDUCER
export const appointmentTypesList = createReducer(APPOINTMENTTYPE, List(), {
  [appointmentType.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getAppointmentTypes = pipe(getRoot, get(APPOINTMENTTYPE))
