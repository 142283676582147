import { Map, Record } from 'immutable'
import { ROUTE_CHANGED } from 'redux-routable'
import { createSelector } from 'reselect'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { ASSESSMENT_FORM } from '../router'
import { currentAssessmentFetched } from './assessments'
import { SECTION_CHANGED, getAssessment } from './common/shared'

const key = 'sectionsInfo'

export const SectionInfo = Record({
  error: false,
  touched: false,
  complete: false,
  inView: false,
  order: null,
})

export const SECTION_SUBMITTED = type(key, 'SECTION_SUBMITTED')
export const sectionSubmitted = creator(SECTION_SUBMITTED, 'section')

export const SECTION_ERRORED = type(key, 'SECTION_ERRORED')
export const sectionErrored = creator(SECTION_ERRORED, 'section')

export const SECTION_BLURRED = type(key, 'SECTION_BLURRED')
export const sectionBlurred = creator(SECTION_BLURRED, 'section', 'errors')

export const SECTION_ENTERED = type(key, 'SECTION_ENTERED')
export const sectionEntered = creator(SECTION_ENTERED, 'section')

export const SECTION_LEFT = type(key, 'SECTION_LEFT')
export const sectionLeft = creator(SECTION_LEFT, 'section')

export default createReducer(key, Map(), {
  [currentAssessmentFetched]: (state, { payload: { assessment } }) => {
    const order = [...assessment.uiSchema['ui:order'], 'addendums']
    return Map(order.map((section, order) => [section, SectionInfo({ order })]))
  },
  [SECTION_CHANGED]: (state, { payload: { section, info } }) =>
    state
      .setIn([section, 'error'], info.errors.length > 0)
      .setIn([section, 'touched'], true)
      .setIn([section, 'complete'], false),
  [SECTION_SUBMITTED]: (state, { payload: { section } }) =>
    state.setIn([section, 'error'], false).setIn([section, 'complete'], true),
  [SECTION_ERRORED]: (state, { payload: { section } }) =>
    state.setIn([section, 'error'], true),
  [SECTION_BLURRED]: (state, { payload: { section, errors } }) =>
    state.setIn([section, 'error'], errors.length > 0),
  [SECTION_ENTERED]: (state, { payload: { section } }) =>
    state.setIn([section, 'inView'], true),
  [SECTION_LEFT]: (state, { payload: { section } }) =>
    state.setIn([section, 'inView'], false),
  [ROUTE_CHANGED]: (state, { payload }) =>
    payload.route === ASSESSMENT_FORM ? state : Map(),
})

export const getSectionsInfo = pipe(getAssessment, get(key))

export const getSectionInfo = (state, section) =>
  getSectionsInfo(state).get(section)

export const getActiveSection = createSelector(
  [getSectionsInfo],
  sectionsInfo => {
    const result = sectionsInfo
      .filter(({ inView }) => inView)
      .map(({ order }, key) => ({ key, order }))
      .minBy(({ order }) => order)

    return result && result.key
  }
)

export const getError = createSelector([getSectionsInfo], sectionsInfo =>
  sectionsInfo.some(({ error }) => error)
)
