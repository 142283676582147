// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { activeChanged } from './active'
import { searchWordChanged } from './searchWord'
import { getUserQuery } from './userQuery'

const PAGE = 'page'

const creator = scopedCreator(rootKey)
export const pageChanged = creator('PAGE_CHANGED', ['page'])

const initState = 0
export default createReducer(PAGE, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [searchWordChanged]: () => initState,
  // @ts-expect-error can we deal with the toString being automatically called?
  [activeChanged]: () => initState,
  // @ts-expect-error can we deal with the toString being automatically called?
  [pageChanged]: (_state: any, { payload: { page } }: any) => page,
})

export const getPage = pipe(getUserQuery, get(PAGE))
