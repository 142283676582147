import moment from 'moment'
import React from 'react'
import PropTypes from '~/utils/propTypes'

const DATE_FORMAT = 'ddd M/D'

const DayHeaderText = ({ date }) => {
  const label = moment(date).format(DATE_FORMAT)

  return <div>{label}</div>
}

DayHeaderText.propTypes = {
  date: PropTypes.instanceOf(Date),
}

export default DayHeaderText
