import { JsonFormT } from '~/data/form'
import { Async } from '~/resources/resource-strict'
import { Patient } from '~/types/patientT'
import { User } from '~/types/userT'
import {
  AOfMorphADT,
  AsOpaque,
  AType,
  Ctor,
  Ctor_,
  EType,
  ReduxAction,
  summon,
  Variant,
} from '~/utils/type'

const Status_ = summon(F =>
  F.interface(
    {
      id: F.number(), // TODO: would be great to make use of newtype-ts for dumb primitives like this.
      status: F.string(), // EnumT.t <- would be nice to get these back for FV's... :thinking:
      statusLabel: F.string(),
      notes: F.string(),
      insertedById: F.string(), // We should have newtypes for these...
      insertedBy: F.nullable(User(F)),
      insertedAt: F.date(),
      patientId: F.string(),
      patient: F.nullable(Patient(F)),
      formId: F.number(),
      form: F.nullable(JsonFormT(F)),
    },
    'Status'
  )
)
export interface Status extends AType<typeof Status_> {}
export interface StatusRaw extends EType<typeof Status_> {}
export const Status = AsOpaque<StatusRaw, Status>()(Status_)

const Form_ = summon(F =>
  F.interface(
    {
      status: F.string(), // EnumT.t <- would be nice to get these back for FV's... :thinking:
      notes: F.string(),
    },
    'Form'
  )
)
export interface Form extends AType<typeof Form_> {}
export interface FormRaw extends EType<typeof Form_> {}
export const Form = AsOpaque<FormRaw, Form>()(Form_)

const Opened = Ctor_('Opened')
type Opened = typeof Opened

const Closed = Ctor_('Closed')
type Closed = typeof Closed

export const DialogState = Variant({ Opened, Closed })

export const IndexState = Async(summon(F => F.array(Status(F))))
export type IndexState = AOfMorphADT<typeof IndexState>

export const NewState = Async(Status)
export type NewState = AOfMorphADT<typeof NewState>

const New = Ctor('New', NewState)
const Create = Ctor('Create', NewState)
const Fetch = Ctor('Fetch', IndexState)
const Show = Ctor('Show', Async(Status))
const Update = Ctor('Update', Async(Status))
const Comment = Ctor(
  'Comment',
  summon(F =>
    F.interface(
      {
        id: F.number(),
        data: Async(JsonFormT)(F),
      },
      'CommentArgs'
    )
  )
)
export const Dialog = Ctor('Dialog', DialogState)

export const Action = Variant({
  New,
  Update,
  Fetch,
  Create,
  Show,
  Comment,
  Dialog,
})
export type Action = ReduxAction<'Status', AOfMorphADT<typeof Action>>

const State_ = summon(F =>
  F.interface(
    {
      dialog: DialogState(F),
      index: IndexState(F),
      new_: NewState(F),
    },
    'State'
  )
)
export interface State extends AType<typeof State_> {}
export interface StateRaw extends EType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)
