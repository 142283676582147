import React from 'react'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  root: {
    padding: spacing(2),
    '& > *': {
      width: spacing(2),
    },
  },
  options: {
    margin: spacing(3),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: '0',
    height: spacing(8),
    padding: spacing(2),
    zIndex: 1,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  title: {
    flex: '1',
    padding: {
      left: spacing(2),
      right: spacing(2),
    },
  },
})

class SearchOptions extends React.Component {
  render() {
    const {
      classes,
      open,
      onClose,
      anchorEl,
      pharmacySearchOptionsValue,
      onChange,
      resetDefaults,
    } = this.props

    return (
      <Popover anchorEl={anchorEl} open={open} onClose={onClose}>
        <Paper square className={classes.header}>
          <Typography className={classes.title} color="inherit" variant="h6">
            Search Options
          </Typography>
          <Button onClick={resetDefaults} color="inherit">
            Reset Defaults
          </Button>
        </Paper>
        <FormControl className={classes.options} component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={pharmacySearchOptionsValue.activeOnly}
                  onChange={onChange}
                  value="activeOnly"
                />
              }
              label="Active Only"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pharmacySearchOptionsValue.includeRetail}
                  onChange={onChange}
                  value="includeRetail"
                />
              }
              label="Include Retail"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pharmacySearchOptionsValue.includeMailOrder}
                  onChange={onChange}
                  value="includeMailOrder"
                />
              }
              label="Include Mail Order"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pharmacySearchOptionsValue.includeFax}
                  onChange={onChange}
                  value="includeFax"
                />
              }
              label="Include Fax"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pharmacySearchOptionsValue.includeSpecialty}
                  onChange={onChange}
                  value="includeSpecialty"
                />
              }
              label="Include Specialty"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pharmacySearchOptionsValue.includeLongTerm}
                  onChange={onChange}
                  value="includeLongTerm"
                />
              }
              label="Include Long Term"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pharmacySearchOptionsValue.includeTwentyFourHour}
                  onChange={onChange}
                  value="includeTwentyFourHour"
                />
              }
              label="Inclue Twenty Four Hour"
            />
          </FormGroup>
        </FormControl>
      </Popover>
    )
  }
}

SearchOptions.propTypes = {
  anchorEl: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pharmacySearchOptionsValue: PropTypes.object.isRequired,
  resetDefaults: PropTypes.func.isRequired,
}

export default withStyles(styles)(SearchOptions)
