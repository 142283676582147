import { ofType } from 'redux-observable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
import { fetchDrugAlerts } from '../data/alerts'
import { getGenericDrugIdArray } from '../data/patientRx'
import { rxMedicationSet } from '../data/selectedMedication'

export default (action$, state$) =>
  action$.pipe(
    ofType(rxMedicationSet),
    pluck('payload', 'selectedMedication'),
    withLatestFrom(state$),
    map(([selectedMedication, state]) => ({
      drugId: selectedMedication.get('drugId'),
      genericDrugIds: getGenericDrugIdArray(state),
    })),
    filter(params => !params.genericDrugIds.isEmpty()),
    map(fetchDrugAlerts.requested)
  )
