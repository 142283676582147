import {
  AOfMorphADT,
  AsOpaque,
  AType,
  Ctor,
  EType,
  ReduxAction,
  summon,
  Variant,
} from '~/utils/type'
import { Patient, Healthplan } from '~/types/patientT'
import { Async } from '~/resources/resource-strict'
import { PatientStats } from './patientStatsT'
import { Interval } from '~/types/Interval'

const Review_ = summon(F =>
  F.interface(
    {
      id: F.number(),
      patientId: F.string(),
      patient: Patient(F),
      notes: F.string(),
      recommendation: F.string(),
      insertedAt: F.date(),
      insertedById: F.string(),
    },
    'Review'
  )
)
export interface Review extends AType<typeof Review_> {}
export interface ReviewRaw extends EType<typeof Review_> {}
export const Review = AsOpaque<ReviewRaw, Review>()(Review_)

export interface Form {
  notes: string
  setNotes: React.Dispatch<React.SetStateAction<string>>
  recommendation: string
  setRecommendation: React.Dispatch<React.SetStateAction<string>>
}

const Identification_ = summon(F =>
  F.interface(
    {
      patient: Patient(F),
      healthPlan: Healthplan(F),
      timeOnService: Interval(F),
      totalReviews: F.number(),
      timeSinceLastReview: F.nullable(Interval(F)),
    },
    'Identification'
  )
)
export interface Identification extends AType<typeof Identification_> {}
export interface IdentificationRaw extends EType<typeof Identification_> {}
export const Identification = AsOpaque<IdentificationRaw, Identification>()(
  Identification_
)

const Index = Async(summon(F => F.array(Identification(F))))

// TODO: Consider making this a ziplist
//   type index = Async.t(ZipList.t(identification));
//   // as:
//   // {
//   //   index: Init,
//   //   currentPatient: Some(currentPatient)
//   // }
//   // is an invalid but possible state.
const State_ = summon(F =>
  F.interface(
    {
      index: Index(F),
      currentPatient: F.nullable(Patient(F)),
      stats: Async(PatientStats)(F),
    },
    'State'
  )
)
export interface State extends AType<typeof State_> {}
export interface StateRaw extends EType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)

const Patients = Ctor('Patients', Index)
const Highlight = Ctor('Highlight', Patient)
const Stats = Ctor('Stats', Async(PatientStats))

export const Action = Variant({
  Patients,
  Highlight,
  Stats,
})
export type Action = ReduxAction<'Review', AOfMorphADT<typeof Action>>
