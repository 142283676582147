import { cloneDeep, set } from 'lodash'

export default (form, tag, override) => {
  try {
    const { formData, tags, uiSchema, template } = form
    let schema = form.schema
    schema = cloneDeep(schema)
    const { schemaValue, value } = override
    const paths = tags[tag]
    let node = schema
    for (const path of paths.schema) {
      node = node[path]
    }
    Object.assign(node, {
      enum: schemaValue.enum,
      enumNames: schemaValue.enumNames,
    })
    return {
      formData: set(formData, paths.data, value),
      schema: schema,
      uiSchema,
      tags,
      template,
    }
  } catch (e) {
    return form
  }
}
