import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { makePreferredPrescriberLocation } from '../data/prescriberLocations'
import { setPreferredLocation } from '../data/prescribers'

export default (action$: any) =>
  action$.pipe(
    ofType(makePreferredPrescriberLocation.SUCCEEDED),
    pluck('payload'),
    // @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type '{ id: an... Remove this comment to see the full error message
    map(({ id, preferredLocationId }) =>
      setPreferredLocation(id, preferredLocationId)
    )
  )
