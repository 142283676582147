import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PATIENT_RECORD_DEMOGRAPHICS } from '~/apps/patientRecord/router'
import { OpenLink } from '~/components/links'
import {
  useAction,
  useFieldValuesOld,
  useMountEffect,
  useUpdateEffect,
} from '~/hooks'
import { localToUtc, utcToLocal } from '~/utils/dates'
import { DATE_PICKER_DATETIME_FORMAT } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { removeUnderscores, titleCase } from '~/utils/stringManipulation'
import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getTimeZone } from '../../../data/calendar'
import { requiredRescheduleReasonSet } from '../../../data/eventTypes'
import {
  confirmedChanged,
  descriptionChanged,
  encounterTypeChanged,
  fetchPatientInfo,
  getEncounterTypes,
  getServiceLocations,
  patientInfoCleared,
  serviceLocationChanged,
  visitDurationChanged,
  visitStartChanged,
} from '../../../data/events'
import { getPatientInfo } from '../../../data/events/patientInfo'
import { fetchServiceLocations } from '../../../data/events/serviceLocations'
import {
  BaseField,
  CheckboxField,
  CreatedModifiedField,
  DateTimeField,
  DropDownField,
  LocationField,
  NumberField,
  PatientSearchField,
  TextField,
  TextLongField,
} from '../../EventFields'

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  left: {
    display: 'flex',
    marginRight: spacing(1),
    justifyContent: 'space-between',
  },
  selectChips: {
    width: '100%',
  },
  menuIcon: {
    marginRight: spacing(1),
  },
}))

const VisitEventDetail = ({
  event,
  editing,
  creating,
  virtualVisitMissingContactInfo,
}) => {
  const classes = useStyles()
  const encounterStatuses = useFieldValuesOld('encounter_assessment_status')
  const timeZone = useSelector(getTimeZone)
  const encounterTypes = useSelector(getEncounterTypes)
  const changeEncounterType = useAction(encounterTypeChanged)
  const onVisitStartChanged = useAction(visitStartChanged)
  const fetchPatientInfoRequested = useAction(fetchPatientInfo.requested)
  const onConfirmedChange = useAction(confirmedChanged)
  const onDescriptionChange = useAction(descriptionChanged)
  const onVisitDurationChanged = useAction(visitDurationChanged)
  const clearPatientInfo = useAction(patientInfoCleared)
  const setRequireRescheduleReason = useAction(requiredRescheduleReasonSet)
  const encounterStatus = encounterStatuses.get(event.encounterStatus)
  const patientInfo = useSelector(getPatientInfo)
  const fetchServiceLocationsRequested = useAction(
    fetchServiceLocations.requested
  )
  const onServiceLocationChanged = useAction(serviceLocationChanged)
  const serviceLocations = useSelector(getServiceLocations)

  const [initStart] = useState(event.start)

  const duration = event.duration

  const defaultDuration = encounterTypes.getIn([
    event.encounterType,
    'defaultVisitDuration',
  ])

  useMountEffect(
    () => {
      if (event.patientId) {
        fetchPatientInfoRequested(event.patientId, event.ownerId)
        fetchServiceLocationsRequested(event.patientId)
      }
    },
    () => {
      clearPatientInfo()
      setRequireRescheduleReason(false)
    }
  )

  useEffect(() => {
    onDurationChange(duration)
  }, [duration])

  useUpdateEffect(() => {
    if (patientInfo.id != null) {
      fetchServiceLocationsRequested(patientInfo.id)
    }
  }, [patientInfo.id])

  useUpdateEffect(() => {
    if (!creating) {
      const reasonRequired = !utcToLocal(event.start, timeZone).isSame(
        utcToLocal(initStart, timeZone),
        'day'
      )
      setRequireRescheduleReason(reasonRequired)
    }
  }, [creating, event.start, initStart, setRequireRescheduleReason])

  const start = utcToLocal(event.start, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )
  const end = utcToLocal(event.end, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )
  const isEditing = editing && !event.cancelled

  const [startDatetime, setStartDatetime] = useState(start)
  const [endDatetime, setEndDatetime] = useState(end)
  const [startTimeInPast, setStartTimeInPast] = useState(false)

  const onBlurStart = useCallback(
    localStart => {
      if (localStart) {
        const localEnd = moment(localStart).add(event.duration, 'minutes')
        const startNew = localToUtc(localStart, timeZone)
        const endNew = localToUtc(localEnd, timeZone)
        const startDate = moment(localStart).format('YYYY-MM-DD')
        const endDate = moment(localEnd).format('YYYY-MM-DD')

        setStartDatetime(localStart.format(DATE_PICKER_DATETIME_FORMAT))
        setEndDatetime(localEnd.format(DATE_PICKER_DATETIME_FORMAT))
        setStartTimeInPast(startNew <= moment())
        onVisitStartChanged(startNew, startDate, endNew, endDate)
      }
    },
    [event.duration, timeZone, onVisitStartChanged]
  )
  const onDurationChange = useCallback(
    duration => {
      const localEnd = moment(start).add(duration, 'minutes')
      const endNew = localToUtc(localEnd, timeZone)
      const endDate = localEnd.format('YYYY-MM-DD')

      setEndDatetime(localEnd.format(DATE_PICKER_DATETIME_FORMAT))
      onVisitDurationChanged(duration, endNew, endDate)
    },
    [start, timeZone, onVisitDurationChanged]
  )

  const durationDefault = defaultDuration ? (
    <Button
      onClick={() => {
        onDurationChange(defaultDuration)
      }}
    >{`Default: ${defaultDuration}`}</Button>
  ) : (
    'minutes'
  )

  const patientId = patientInfo.id

  const virtualCapableFilter = assessmentTypes => {
    return patientInfo.virtualCapable
      ? assessmentTypes
      : assessmentTypes.filter(types => !types.type.includes('virtual'))
  }
  const assessmentsOptions = useMemo(
    () =>
      virtualCapableFilter(encounterTypes)
        .toList()
        .sortBy(({ label }) => label),
    [encounterTypes]
  )

  useEffect(() => {
    if (assessmentsOptions.size === 1) {
      changeEncounterType(assessmentsOptions.get(0).type)
    }
  }, [assessmentsOptions])
  return (
    <React.Fragment>
      <PatientSearchField event={event} editing={isEditing && creating} />
      {encounterStatus && (
        <TextField label="Assessment Status" value={encounterStatus.label} />
      )}
      <BaseField label="Virtual Capable">
        <div className={classes.row}>
          <div className={classes.left}>
            <Typography variant="subtitle1">
              {patientInfo.virtualCapableLabel
                ? titleCase(removeUnderscores(patientInfo.virtualCapableLabel))
                : 'Not Yet Asked'}
            </Typography>
          </div>
          <div>
            {!patientInfo.virtualCapable && patientId && (
              <Tooltip title="Update Virtual Capability Fields">
                <IconButton
                  size="small"
                  component={OpenLink}
                  route={PATIENT_RECORD_DEMOGRAPHICS}
                  params={{ patientId }}
                >
                  <Icon>link</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </BaseField>
      <TextLongField
        label="Notes"
        value={event.description}
        editing={isEditing}
        onChange={onDescriptionChange}
      />
      {isEditing && creating ? (
        <DropDownField
          editing
          label="Encounter Type"
          error={!event.encounterType}
          value={event.encounterType}
          values={assessmentsOptions}
          getValue={encounterType => encounterType.type}
          getLabel={encounterType => encounterType.label}
          onChange={changeEncounterType}
          allowNone={false}
        />
      ) : (
        <TextField label="Encounter Type" value={event.encounterTypeLabel} />
      )}
      {virtualVisitMissingContactInfo ? (
        <Typography color="error" variant="subtitle1">
          Patient does not have preferred email/texting approved phone number.
          Cannot start virtual visit without email/phone number.
        </Typography>
      ) : null}
      <NumberField
        label="Duration"
        value={event.duration}
        onChange={onDurationChange}
        editing={isEditing}
        after={durationDefault}
      />
      <DateTimeField
        label="Start"
        value={startDatetime}
        onBlur={onBlurStart}
        onChange={(v, _e) => setStartDatetime(v)}
        editing={isEditing}
      />
      {startTimeInPast ? (
        <Typography color="error" variant="subtitle1">
          This assessment is in the past, are you sure you want to proceed?
        </Typography>
      ) : null}
      <DateTimeField label="End" value={endDatetime} editing={false} />
      <CheckboxField
        label="Confirmed"
        value={event.confirmed}
        onChange={onConfirmedChange}
        editing={isEditing}
      />
      {patientInfo.isCaseMgmt && serviceLocations.size == 0 ? (
        <Typography color="error" variant="subtitle1">
          There is no service location in the patient’s home state. Please add
          note.
        </Typography>
      ) : null}
      {patientInfo.isCaseMgmt && serviceLocations.size !== 0 ? (
        <DropDownField
          label="Service Location"
          values={serviceLocations}
          editing={isEditing}
          onChange={onServiceLocationChanged}
          error={!event.serviceLocationId}
          value={event.serviceLocationId}
          getValue={serviceLocation => serviceLocation.id}
          getLabel={serviceLocation => serviceLocation.name}
          allowNone={false}
        />
      ) : (
        <LocationField value={event.location} editing={isEditing} />
      )}
      <CreatedModifiedField event={event} />
    </React.Fragment>
  )
}

VisitEventDetail.propTypes = {
  event: PropTypes.record.isRequired,
  editing: PropTypes.bool,
  creating: PropTypes.bool,
  virtualVisitMissingContactInfo: PropTypes.bool,
}

export default VisitEventDetail
