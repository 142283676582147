import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getAppFinder } from './root'

// KEY
export const key = 'potentialMarkets'

// RECORD
export const PotentialMarket = Record({
  id: null,
  name: null,
})

// TYPE
export const POTENTIAL_MARKETS_FETCH_FAILED = `${key}/POTENTIAL_MARKETS_FETCH_FAILED`
export const POTENTIAL_MARKETS_FETCH_REQUESTED = `${key}/POTENTIAL_MARKETS_FETCH_REQUESTED`
export const POTENTIAL_MARKETS_STORED = `${key}/POTENTIAL_MARKETS_STORED`

// ACTION
export const potentialMarketsFetchFailed = () => ({
  type: POTENTIAL_MARKETS_FETCH_FAILED,
})

export const potentialMarketsFetchRequested = addressMap => ({
  type: POTENTIAL_MARKETS_FETCH_REQUESTED,
  payload: addressMap,
})

export const potentialMarketsStored = potentialMarkets => ({
  type: POTENTIAL_MARKETS_STORED,
  payload: potentialMarkets,
})

// SELECTOR
export const selectPotentialMarkets = pipe(getAppFinder, get(key))

// REDUCER
const createEachPotentialMarket = ({ id, name }) =>
  PotentialMarket({
    id,
    name,
  })

const createListOfPotentialMarkets = (_state, { payload }) =>
  List(payload.map(createEachPotentialMarket))

export default createReducer(key, List(), {
  [POTENTIAL_MARKETS_FETCH_FAILED]: () => List(),
  [POTENTIAL_MARKETS_STORED]: createListOfPotentialMarkets,
})

// API
export const fetchPotentialMarkets = address => {
  return AspireAPI.get('v1/referral/address_lookup/market', {
    params: {
      address1: address.get('street1'),
      address2: address.get('street2'),
      city: address.get('city'),
      state_code: address.get('state'),
      postal_code: address.get('postalCode'),
    },
  })
}
