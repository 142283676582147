import React from 'react'
import { useAction } from '~/hooks'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { deleteContactRequest } from '../../../data/patientContactsContext'
interface DeleteDialogPropTypes {
  contact: PropTypes.object.isRequired
  open: PropTypes.bool.isRequired
  onClose: PropTypes.func.isRequired
}

export const DeleteConfirmationDialog = ({
  contact,
  open,
  onClose,
}: DeleteDialogPropTypes) => {
  const deleteContact = useAction(deleteContactRequest.requested)

  const onConfirm = () => {
    deleteContact(contact.patientId, contact.id)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>Delete Patient Contact</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contact.name
            ? `Delete the contact information for ${contact.name}`
            : `Delete this contact?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
