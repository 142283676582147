import { get, isEqual } from 'lodash/fp'
import { pipe } from '~/utils/functionalHelpers'
// Using `instanceof` does not work for `Error`s in Babel
// https://babeljs.io/docs/en/caveats/#classes

const NAME = 'UnauthenticatedRequestError'

export default class UnauthenticatedRequestError extends Error {
  name = NAME
}

export const isUnauthenticatedRequestError = pipe(get('name'), isEqual(NAME))
