import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const ADMISSIONS_RECONCILIATION_MERGE = `${key}.merge`
export const ADMISSIONS_RECONCILIATION_UNMERGE = `${key}.unmerge`
export const ADMISSIONS_RECONCILIATION_RECYCLE = `${key}.recycle`

export default Router([
  Route(ADMISSIONS_RECONCILIATION_MERGE, '/merge'),
  Route(ADMISSIONS_RECONCILIATION_UNMERGE, '/unmerge'),
  Route(ADMISSIONS_RECONCILIATION_RECYCLE, '/emr'),
  Redirect(ADMISSIONS_RECONCILIATION_MERGE),
])
