import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  fetchProjectPolygons,
  loadProjectPolygons,
} from '../data/projectPolygons'
import { selectedPolygonCleared } from '../data/selectedPolygon'
import { DRAFT_PROJECT_SELECTED } from '../data/selectedProject'
import { setVisibleProjectPolygons } from '../data/visibleProjectPolygons'
import showError from '../utils/showError'

const getNPIds = polys => polys.map(polygon => polygon.np_id)

const projectSelectedEpic = action$ =>
  action$.pipe(
    ofType(DRAFT_PROJECT_SELECTED),
    pluck('payload', 'project'),
    mergeMap(project =>
      loading(
        from(fetchProjectPolygons(project.get('id'))).pipe(
          mergeMap(polys =>
            of(
              loadProjectPolygons(polys),
              setVisibleProjectPolygons(getNPIds(polys)),
              selectedPolygonCleared()
            )
          ),
          catchError(showError('Failed to fetch Project NPs.'))
        )
      )
    )
  )

export default projectSelectedEpic
