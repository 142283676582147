import { Record } from 'immutable'
import { summon, AType, EType, AsOpaque } from '~/utils/type'
// @ts-expect-error ts(7016) FIXME: '~/components/JsonForm/utils/calcFormData' implicitly has an 'any' type... Remove this comment to see the full error message
import calcFormData from '~/components/JsonForm/utils/calcFormData'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import * as t_ from 'io-ts-types'
import { pipe } from 'fp-ts/lib/function'

export const Form = Record({
  schema: {},
  uiSchema: {},
  tags: {},
  data: {},
  context: {},
  errored: false,
})

const JsonFormT_ = summon(F =>
  F.interface(
    {
      schema: F.unknown(),
      uiSchema: F.unknown(),
      tags: F.unknown(),
      data: F.unknown(),
      context: F.unknown(),
      errored: F.boolean({ IoTsURI: () => t_.withFallback(t.boolean, false) }),
    },
    'JsonFormT',
    {
      IoTsURI: (type, _env) =>
        new t.Type(
          'JsonForm',
          type.is,
          (inp, ctx) => {
            return pipe(
              inp,
              type.decode,
              E.map(e => {
                const { data: formData } = e
                return { ...e, data: calcFormData({ ...e, formData }) }
              }),
              e => (E.isLeft(e) ? t.failure(e, ctx) : e)
            )
          },
          type.encode
        ),
    }
  )
)
export interface JsonFormT extends AType<typeof JsonFormT_> {}
export interface JsonFormTRaw extends EType<typeof JsonFormT_> {}
export const JsonFormT = AsOpaque<JsonFormTRaw, JsonFormT>()(JsonFormT_)
