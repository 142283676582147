import { get, pickBy } from 'lodash'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/re... Remove this comment to see the full error message
import OrcAPI from '~/resources/orc'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request, { flattenErrors } from '~/utils/Request'
import { Referral, getReferralCaseFromValues } from './records'
import rootKey from '../../key'

export const filterObj = (params: any) => pickBy(params)

export const assignment = Request({
  typePrefix: 'referral_management_assignment',
  typeBase: 'REFERRAL_ASSIGNMENT',
  requestParams: ['body'],
  operation: (body: any) => AspireAPI.post('v1/assignment', body),
  messages: {
    failed: (error: any) => error.response.data.message || 'Assignment Error',
  },
})

export const manualAssignment = (body: any) =>
  AspireAPI.post('v1/referral/manual_assignment', body)

export const existingReferralCaseLookup = (id: any) =>
  AspireAPI.get(`v1/referral/cases_by_patient_id/${id}`)

export const newReferralDupeCheck = ({ patient, healthplan }: any) =>
  AspireAPI.get('v1/referral/duplicate_check', {
    params: filterObj({
      firstName: patient.firstName,
      lastName: patient.lastName,
      dob: patient.dob,
      accountNumber: get(healthplan, 'primaryInsurance.accountNumber'),
    }),
  })

export const odsLookup = (params: any) => {
  return OrcAPI.get('search/patient', {
    params: filterObj({
      healthPlanId: params.odsId,
      firstName: params.firstName,
      lastName: params.lastName,
      state: params.state,
      dob: params.dob,
      umi: params.umi,
      searchType: params.searchType,
    }),
  })
}

export const changeReferralStatus = (
  caseId: any,
  status: any,
  substatus: any
) => {
  const body = filterObj({ caseId, status, substatus })

  return AspireAPI.post('v1/referral/status', body).then(() => ({
    caseId,
    status,
    substatus,
  }))
}

export const deleteContact = (contactId: any) =>
  AspireAPI.delete(`v1/referral/contact/${contactId}`)

export const saveReferral = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_REFERRAL_CASE',
  requestParams: ['caseId', 'values'],
  operation: (caseId: number, values: any) =>
    AspireAPI.post(
      'v1/referral',
      getReferralCaseFromValues(values).set('caseId', caseId).toJS()
    ),
  messages: {
    failed: (e: any) =>
      flattenErrors(get(e, 'response.data.message')) ||
      'There was a problem saving the referral case',
  },
})

export const saveNewReferral = (values: any) => {
  const body = getReferralCaseFromValues(values).toJS()
  return AspireAPI.post('v1/referral/new', body)
}

export const fetchActiveReferral = (caseId: any) =>
  AspireAPI.get(`v1/referral/${caseId}`).then((res: any) => Referral(res[0]))

export const submitReferral = (caseId: any) =>
  AspireAPI.post('v1/referral/submit', { caseId })

export const saveAdditionalReferral = (values: any, shouldOverwrite = false) =>
  AspireAPI.post('v1/referral/additional', {
    info: getReferralCaseFromValues(values).toJS().info,
    shouldOverwrite,
  })
