import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { CheckAppointmentDate } from './interfaces'

// KEY
const key = 'DuplicateAppointmentDate'

// CREATORS
const creator = scopedCreator(key)

export const resetCheck = creator('RESET_CHECK')

export const TripExist = Record({
  tripExist: null,
})

// TRANSFORMER
const mapCheckTripExist = (result: CheckAppointmentDate) =>
  TripExist({
    tripExist: result.tripExist,
  })

// REQUEST
export const checkTripExist = Request({
  typePrefix: key,
  typeBase: 'CHECKTRIPEXIST',
  requestParams: ['checkTrip'],
  operation: (checkTrip: any) =>
    AspireAPI.post(`transportation/trips/validate/trip_exist`, {
      ...checkTrip,
    }),
  transform: mapCheckTripExist,
  messages: { failed: 'Failed to Check Duplicate Trip Appointment Date' },
})

// REDUCER
export const checkTripExists = createReducer(key, null, {
  [checkTripExist.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetCheck]: () => null,
})

// SELECTORS
export const getTripExists = pipe(getRoot, get(key))
