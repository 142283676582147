import React from 'react'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import ActionItem from '../ActionItem'

const styles = ({ palette: { severity } }) => ({
  drugAlertsLogo: {
    color: severity.error.static,
  },
  noDrugAlertsLogo: {
    color: severity.success.static,
  },
})

const DrugAlertBadge = ({ drugAlerts, onClick, classes }) =>
  drugAlerts &&
  (drugAlerts.isEmpty() ? (
    <ActionItem
      toolTip="No Potential Drug / Drug Interactions Detected"
      onClick={onClick}
      icon="check_circle_outline"
      className={classes.noDrugAlertsLogo}
    />
  ) : (
    <ActionItem
      toolTip="Potential Drug / Drug Interactions"
      onClick={onClick}
      icon="warning"
      className={classes.drugAlertsLogo}
    />
  ))
DrugAlertBadge.propTypes = {
  drugAlerts: PropTypes.list,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DrugAlertBadge)
