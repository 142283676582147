import cx from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getFieldValues } from '~/data/fieldValues'
import { actions, getHasPermission, objects } from '~/features/authorization'
import { useAction, useDialogState } from '~/hooks'
import { utcToLocal } from '~/utils/dates'
import PropTypes from '~/utils/propTypes'
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getEncounterTypes } from '../../data'
import { getClaims, getTimeZone } from '../../data/calendar'
import { getRequiredRescheduleReason } from '../../data/eventTypes'
import {
  cancelVisit,
  createBatchEvent,
  createEvent,
  currentEventCleared,
  deleteBatchEvent,
  deleteEvent,
  editVisit,
  getCurrentEvent,
  getFilters,
  newEventClosed,
  saveBatchEvent,
  saveEvent,
  scheduleVisit,
} from '../../data/events'
import useCurrentDayEventsByType from '../../hooks/useCurrentDayEventsByType'
import { eventTypes } from '../EventTypes'
import EventConfirmDialog from './EventConfirmDialog'

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: 'block',
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flex: 1,
  },
  maxVisitsAlerts: {
    alignSelf: 'center',
    flex: 1,
    padding: [spacing(2), spacing(3)],
    textAlign: 'right',
  },
  message: {
    color: palette.common.white,
    padding: [spacing(1), spacing(1.5)],
    textTransform: 'uppercase',
    borderRadius: 4,
  },
  visits: {
    color: palette.common.white,
    padding: [spacing(1), spacing(1.5)],
    textTransform: 'uppercase',
  },
  noMax: {
    backgroundColor: '#07963e',
    borderRadius: 4,
  },
  counter: {
    backgroundColor: '#07963e',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  remaining: {
    backgroundColor: '#067933',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  oneLeft: {
    animation: '$pulsing 2s infinite',
  },
  alert: {
    backgroundColor: '#b71c1c',
  },
  '@keyframes pulsing': {
    '0%': {
      backgroundColor: '#f44336',
    },
    '50%': {
      backgroundColor: '#b71c1c',
    },
    '100%': {
      backgroundColor: '#f44336',
    },
  },
}))

const defaultPermissions = {
  canCreate: () => false,
  canEdit: () => false,
  canSave: () => false,
  canDelete: () => false,
}

const EventDialog = ({ userId, creating, editing }) => {
  const classes = useStyles()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const [
    renderRescheduleDialog,
    openRescheduleDialog,
    rescheduleDialogProps,
  ] = useDialogState()

  const claims = useSelector(getClaims)
  const event = useSelector(getCurrentEvent)
  const eventType = event.type === 'Other' ? 'Unavailability' : event.type
  const isBatchEvent = event.recurrence !== null
  const timeZone = useSelector(getTimeZone)
  const filters = useSelector(getFilters)
  const canCancelVisit = useSelector(state =>
    getHasPermission(state, objects.APP_DASH_CANCEL_VISIT, actions.DELETE)
  )
  const rescheduleReasonRequired = useSelector(getRequiredRescheduleReason)
  const encounterTypes = useSelector(getEncounterTypes)

  const cancelVisitRequested = useAction(cancelVisit.requested)
  const scheduleVisitRequested = useAction(scheduleVisit.requested)
  const editVisitRequested = useAction(editVisit.requested)
  const closeNewEvent = useAction(newEventClosed)
  const clearCurrentEvent = useAction(currentEventCleared)
  const createEventRequested = useAction(createEvent.requested)
  const createBatchEventRequested = useAction(createBatchEvent.requested)
  const saveEventRequested = useAction(saveEvent.requested)
  const saveBatchEventRequested = useAction(saveBatchEvent.requested)
  const deleteEventRequested = useAction(deleteEvent.requested)
  const deleteBatchEventRequested = useAction(deleteBatchEvent.requested)

  const numberVisitsScheduled = useCurrentDayEventsByType(event, 'visit').length
  const maxVisitWarning = event.maxVisits - numberVisitsScheduled <= 0

  const [currentAvailability] = useCurrentDayEventsByType(event, 'availability')
  const currentUnavailabilities = useCurrentDayEventsByType(
    event,
    'unavailability'
  )

  const currentMaxVisits = currentAvailability
    ? currentAvailability.maxVisits
    : 0
  const currentAvailabilityStart = currentAvailability
    ? currentAvailability.start
    : null
  const currentAvailabilityEnd = currentAvailability
    ? currentAvailability.end
    : null

  const isBetweenMoments = (start, end, refStart, refEnd, timeZone) =>
    utcToLocal(start, timeZone).isBetween(
      utcToLocal(refStart, timeZone),
      utcToLocal(refEnd, timeZone),
      'minute',
      '[)'
    ) &&
    utcToLocal(end, timeZone).isBetween(
      utcToLocal(refStart, timeZone),
      utcToLocal(refEnd, timeZone),
      'minute',
      '(]'
    )

  const conflictsWithMoments = (start, end, refStart, refEnd, timeZone) =>
    utcToLocal(start, timeZone).isBetween(
      utcToLocal(refStart, timeZone),
      utcToLocal(refEnd, timeZone),
      'minute',
      '[)'
    ) ||
    utcToLocal(end, timeZone).isBetween(
      utcToLocal(refStart, timeZone),
      utcToLocal(refEnd, timeZone),
      'minute',
      '(]'
    )

  const validateEvent = ({ end, recordType, start }) => {
    if (isBatchEvent) {
      return null
    } else if (
      recordType === 'scheduling_guidance' &&
      currentAvailabilityStart !== null &&
      currentAvailabilityEnd !== null
    ) {
      return isBetweenMoments(
        start,
        end,
        currentAvailabilityStart,
        currentAvailabilityEnd,
        timeZone
      )
        ? null
        : "This scheduling guidance falls outside of the current day's availability."
    } else {
      const conflicts = currentUnavailabilities.map(u =>
        conflictsWithMoments(start, end, u.start, u.end, timeZone)
      )

      return conflicts.includes(true)
        ? 'This visit conflicts with a current unavailability.'
        : null
    }
  }

  const eventsToValidate = ['scheduling_guidance', 'visit', 'visit_placeholder']
  const invalidEvent = eventsToValidate.includes(event.recordType)
    ? validateEvent(event)
    : null

  const onGoBack = () => {
    clearCurrentEvent()
  }

  const onClose = () => {
    closeNewEvent()
    clearCurrentEvent()
  }

  const onSaveBatch = () => {
    saveBatchEventRequested(event, filters)
    onClose()
  }

  const onSave = () => {
    if (creating) {
      event.recurrence || event.userIds
        ? createBatchEventRequested(event, filters)
        : createEventRequested(event)
    } else {
      saveEventRequested(event)
    }

    onClose()
  }

  const onVisitSave = () => {
    if (rescheduleReasonRequired) {
      openRescheduleDialog()
    } else if (creating) {
      scheduleVisitRequested(event)
      onClose()
    } else {
      editVisitRequested(event)
      onClose()
    }
  }

  const onConfirmSave = () => {
    setConfirmDialogOpen(true)
  }

  const onDelete = () => {
    deleteEventRequested(event.id)
    onClose()
  }

  const onDeleteBatch = () => {
    deleteBatchEventRequested(event.batchId)
    onClose()
  }

  const EventDetailComponent = event
    ? eventTypes[eventType].detailComponent
    : null

  const EventActionsComponent = event
    ? eventTypes[eventType].actionsComponent
    : null

  const subtypeField = event ? eventTypes[eventType].subtypeField : null
  const subtypes = useSelector(getFieldValues(subtypeField))
  const dialogTitle = event ? eventTypes[eventType].title(event, subtypes) : ''

  const isAvailabilityType = event.recordType === 'availability'
  const isVisitType =
    event.recordType === 'visit' || event.recordType === 'visit_placeholder'
  const noMaxVisits = currentMaxVisits <= 0
  const maxVisitCounter = isVisitType ? `${currentMaxVisits} Max Visits` : ''
  const visitsRemaining = !noMaxVisits
    ? currentMaxVisits - numberVisitsScheduled
    : 0
  const noVisitsRemaining = visitsRemaining <= 0

  const permissions = event
    ? eventTypes[eventType].permissions
    : defaultPermissions

  const { canEdit, canDelete, canSave, canCreate } = permissions

  const encounterTypeModality = encounterTypes.get(event.encounterType)
    ?.modality

  const isVirtualVisit =
    (event.encounterTypeModality === 'virtual' ||
      encounterTypeModality === 'virtual') &&
    event.recordType === 'visit'

  const hasTextingApprovedPhoneNumber =
    event.patientPhone && event.textingApproved

  const virtualVisitMissingContactInfo =
    isVirtualVisit && !event.patientEmail && !hasTextingApprovedPhoneNumber

  return (
    <React.Fragment>
      <div className={classes.titleContent}>
        <DialogTitle className={classes.title}>{dialogTitle}</DialogTitle>
        {isAvailabilityType && maxVisitWarning && numberVisitsScheduled !== 0 && (
          <div className={classes.maxVisitsAlerts}>
            <span className={cx(classes.message, classes.alert)}>
              {`Provider already has ${numberVisitsScheduled} visits scheduled`}
            </span>
          </div>
        )}
        {isVisitType && (
          <div className={classes.maxVisitsAlerts}>
            {noMaxVisits ? (
              <span className={cx(classes.visits, classes.noMax)}>
                No Max Visits Set
              </span>
            ) : (
              <React.Fragment>
                {maxVisitCounter && (
                  <span className={cx(classes.visits, classes.counter)}>
                    {maxVisitCounter}
                  </span>
                )}
                {maxVisitCounter && (
                  <span
                    className={cx(classes.visits, classes.remaining, {
                      [classes.oneLeft]: visitsRemaining === 1,
                      [classes.alert]: visitsRemaining <= 0,
                    })}
                  >
                    {`${visitsRemaining} Remaining`}
                  </span>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>

      <DialogContent>
        {EventDetailComponent && (
          <EventDetailComponent
            creating={creating}
            editing={editing && canEdit(event, claims)}
            event={event}
            isPlaceholder={event.recordType === 'visit_placeholder'}
            noMaxVisits={noMaxVisits}
            noVisitsRemaining={noVisitsRemaining}
            numberVisitsScheduled={numberVisitsScheduled}
            userId={userId}
            virtualVisitMissingContactInfo={virtualVisitMissingContactInfo}
            isVirtualVisit={isVirtualVisit}
          />
        )}
      </DialogContent>

      <DialogActions className={classes.actions}>
        {EventActionsComponent && (
          <EventActionsComponent
            cancelVisitRequested={cancelVisitRequested}
            canCreate={canCreate(event, claims)}
            canDelete={canDelete(event, claims)}
            canEdit={canEdit(event, claims)}
            canSave={canSave(event, claims)}
            creating={creating}
            editing={editing}
            editVisitRequested={editVisitRequested}
            event={event}
            invalidEvent={invalidEvent}
            isVisitType={isVisitType}
            newPermissions={{ canCancelVisit: canCancelVisit }}
            noMaxVisits={noMaxVisits}
            noVisitsRemaining={noVisitsRemaining}
            onClose={onClose}
            onConfirmSave={onConfirmSave}
            onDelete={onDelete}
            onDeleteBatch={onDeleteBatch}
            onGoBack={onGoBack}
            onSave={invalidEvent ? onConfirmSave : onSave}
            onSaveBatch={invalidEvent ? onConfirmSave : onSaveBatch}
            onVisitSave={onVisitSave}
            renderRescheduleDialog={renderRescheduleDialog}
            rescheduleDialogProps={rescheduleDialogProps}
            rescheduleReasonRequired={rescheduleReasonRequired}
          />
        )}
      </DialogActions>
      <EventConfirmDialog
        event={event}
        message={invalidEvent}
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onSave={onSave}
        onSaveBatch={onSaveBatch}
        onVisitSave={onVisitSave}
        rescheduleReasonRequired={rescheduleReasonRequired}
      />
    </React.Fragment>
  )
}

EventDialog.propTypes = {
  creating: PropTypes.bool,
  editing: PropTypes.bool,
  userId: PropTypes.string.isRequired,
}

export default EventDialog
