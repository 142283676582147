import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { taskSelected } from '~/features/tasks'
import { CASE_MANAGER_DASHBOARD_TASK_DETAIL } from '../router'

export default action$ =>
  action$.pipe(
    filter(entered(CASE_MANAGER_DASHBOARD_TASK_DETAIL)),
    pluck('payload', 'params', 'taskId'),
    map(taskSelected)
  )
