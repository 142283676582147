import EventActions from '../../Events/EventActions'
import SchedulingGuidanceCalendarEvent from './SchedulingGuidanceCalendarEvent'
import SchedulingGuidanceEventDetail from './SchedulingGuidanceEventDetail'
import defaults from './defaults'
import * as info from './info'
import * as permissions from './permissions'

export default {
  ...info,
  type: defaults.type,
  defaults: defaults,
  permissions: permissions,
  detailComponent: SchedulingGuidanceEventDetail,
  calendarComponent: SchedulingGuidanceCalendarEvent,
  actionsComponent: EventActions,
}
