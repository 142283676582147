import { get } from 'lodash'
import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Chip, Collapse, Icon, Typography } from '@material-ui/core'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import memoizedWrapper from '../widgets/memoizedWrapper'

const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: spacing(0.5),
  },
  prompt: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  fade: {
    height: spacing(2),
    marginTop: -spacing(1) * 2,
    background: `linear-gradient(
      ${fade(palette.background.paper, 0)},
      ${fade(palette.background.paper, 1)})`,
  },
  button: {
    margin: 'auto',
  },
})

class PromptField extends React.Component {
  state = {
    collapsed: get(
      this.props.uiSchema,
      ['ui:options', 'defaultCollapsed'],
      true
    ),
  }

  toggleCollapsed = () =>
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }))

  renderContent = (text, index) =>
    text === '\n' ? <br /> : <Typography key={index}>{text}</Typography>

  render() {
    const { classes, theme, schema, uiSchema } = this.props
    const { collapsed } = this.state
    const { 'ui:options': options } = uiSchema
    const { title } = schema
    const { content } = options
    const collapsible = 'collapsible' in options ? options.collapsible : false
    const expanded = !collapsible || !collapsed
    const collapsedHeight = `${theme.spacing(10)}px`
    const buttonLabel = collapsed ? 'Click to expand' : 'Click to collapse'
    const buttonIcon = collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'

    const prompt = (
      <div className={classes.prompt}>
        {title && (
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        )}
        {content && content.map(this.renderContent)}
      </div>
    )

    if (!collapsible) {
      return prompt
    }

    return (
      <div className={classes.root}>
        <Collapse in={expanded} collapsedHeight={collapsedHeight}>
          {prompt}
        </Collapse>
        {!expanded && <div className={classes.fade} />}
        {collapsible && (
          <Chip
            className={classes.button}
            label={buttonLabel}
            deleteIcon={<Icon>{buttonIcon}</Icon>}
            onClick={this.toggleCollapsed}
            onDelete={this.toggleCollapsed}
          />
        )}
      </div>
    )
  }
}

PromptField.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
}

PromptField.defaultProps = {
  schema: {},
  uiSchema: {},
}

export default compose(
  memoizedWrapper,
  withTheme,
  withStyles(styles)
)(PromptField)
