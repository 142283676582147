import { combineReducers } from 'redux-immutable'
import { isRequestProcessing } from './data/common/requestProcessing'
import researchCases from './data/researchCases'
import sendToResearchDialog from './data/sendToResearchDialog'
import { completeDialog, isInResearch } from './data/updateResearchDialog'
import key from './key'

const reducer = combineReducers({
  [completeDialog.key]: completeDialog,
  [isInResearch.key]: isInResearch,
  [isRequestProcessing.key]: isRequestProcessing,
  [researchCases.key]: researchCases,
  [sendToResearchDialog.key]: sendToResearchDialog,
})

reducer.key = key

export default reducer
