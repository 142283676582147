import { combineEpics, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  ARCHIVED_FILTERS_FETCH_REQUESTED,
  UNARCHIVED_FILTERS_FETCH_REQUESTED,
  fetchFilters,
  filtersStored,
} from '../data/filters'

const fetchAndStoreFilters = (archived: any) =>
  from(fetchFilters(archived)).pipe(
    map(filtersStored),
    catchError(showError('Error! Problem loading filters'))
  )

const loadArchived = (action$: any) =>
  action$.pipe(
    ofType(ARCHIVED_FILTERS_FETCH_REQUESTED),
    mergeMap(() => fetchAndStoreFilters(true))
  )

const loadUnarchived = (action$: any) =>
  action$.pipe(
    ofType(UNARCHIVED_FILTERS_FETCH_REQUESTED),
    mergeMap(() => fetchAndStoreFilters(false))
  )

export default combineEpics(loadArchived, loadUnarchived)
