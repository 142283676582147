import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, get, into, payload } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import TASKS from '../key'
import { getRoot } from './common'

// Key
const TASK_DEFINITION = 'taskDefinition'

// Record
export const TaskDefinition = Record({
  defaultRole: null,
  defaultDueAfterDays: null,
  defaultPriority: null,
  description: null,
  formTemplateKey: null,
  key: null,
  title: null,
})

// Request
export const fetchTaskDefinitions = Request({
  typePrefix: `${TASKS}/${TASK_DEFINITION}`,
  typeBase: 'FETCH_LEGACY_TASK_DEFINITIONS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`task_definitions/patient/${patientId}`),
  transform: into(TaskDefinition, 'key'),
  messages: {
    failed: 'Failed to get task definitions.',
  },
})

// Reducer
const initState = Map()
export default createReducer(TASK_DEFINITION, initState, {
  [fetchTaskDefinitions.REQUESTED]: () => initState,
  [fetchTaskDefinitions.SUCCEEDED]: pipe(action, payload),
})

// Selectors
export const getTaskDefinitions = pipe(getRoot, get(TASK_DEFINITION))

export const getTaskDefinitionsList = createSelector(getTaskDefinitions, ltds =>
  ltds.toList().sortBy(ltd => ltd.title)
)
