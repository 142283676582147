import React from 'react'
import { TextField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, InputAdornment } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  iconButton: {
    padding: spacing(0.5),
  },
})

const TextFieldWithAdornment = props => {
  const { classes, onClear, onSearch, ...otherProps } = props

  return (
    <TextField
      {...otherProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {otherProps.input.value && (
              <IconButton className={classes.iconButton} onClick={onClear}>
                <Icon>clear</Icon>
              </IconButton>
            )}
            <IconButton className={classes.iconButton} onClick={onSearch}>
              <Icon>search</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

TextFieldWithAdornment.propTypes = {
  classes: PropTypes.object.isRequired,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
}

export default withStyles(styles)(TextFieldWithAdornment)
