import React from 'react'
import { Field } from 'redux-form'
import { PhoneNumberField, TextField } from '~/components/formFields'
import { Section } from '~/components/text'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing, breakpoints }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    columnGap: spacing(2),
    rowGap: `${spacing(2)}px`,

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  spanTwo: {
    gridColumnStart: 'span 2',

    [breakpoints.down('sm')]: {
      gridColumnStart: 'auto',
    },
  },
})

const ContactInfo = ({ classes }) => (
  <Section title="Contact Information">
    <div className={classes.container}>
      {/* Phone */}
      <Field component={PhoneNumberField} name="phone" />

      {/* Mobile Phone */}
      <Field
        component={PhoneNumberField}
        name="mobilePhone"
        label="Mobile Phone"
      />

      {/* Fax */}
      <Field component={PhoneNumberField} name="fax" label="Fax Number" />

      {/* Email */}
      <Field
        className={classes.spanTwo}
        component={TextField}
        type="email"
        name="email"
        label="Email"
      />
    </div>
  </Section>
)

ContactInfo.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactInfo)
