import React from 'react'
import PropTypes from '~/utils/propTypes'
import { FormControl, InputLabel, Select } from '@material-ui/core'

const PatientOption = p => {
  const birthdate = p.get('birthdate')
  const id = p.get('id')
  const market = p.get('market')
  const name = p.get('fullName')

  return (
    <option key={id} value={id}>
      {`${name} / ${market}${birthdate ? ' / ' + birthdate : ''}`}
    </option>
  )
}

const PatientSelect = ({ className, onChange, patientId, patients }) =>
  !patients.isEmpty() && (
    <FormControl className={className} required>
      <InputLabel htmlFor="new-task-patient">Patient</InputLabel>
      <Select
        inputProps={{ id: 'new-task-patient', name: 'patient' }}
        native
        onChange={onChange}
        value={patientId || ''}
      >
        <option disabled value="" />
        {patients.map(PatientOption)}
      </Select>
    </FormControl>
  )

PatientSelect.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  patients: PropTypes.listOf(PropTypes.record).isRequired,
}

export default PatientSelect
