import { isEqual } from 'lodash'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import directionSuggestions from '../utils/directionSuggestions'
import { isPending } from './medListConstants'

const pendingStatusSelected = formValues =>
  formValues && isPending(formValues.get('status'))

export const required = value =>
  value == null || value === '' ? 'Required' : undefined

export const checkRequired = requiredCheck => (_, formValues) =>
  pendingStatusSelected(formValues) && !formValues.get(requiredCheck)
    ? 'Checkbox Required'
    : undefined

export const validateDaysSupply = value =>
  !Number.isInteger(value)
    ? undefined
    : 0 <= value && value <= 999
    ? undefined
    : 'Must be 0 - 999'

export const validateRefill = value =>
  0 <= parseInt(value) && parseInt(value) <= 99 ? undefined : 'Must be 0 - 99'

export const validateDispenseNumber = value =>
  value && 0 <= value && value <= 9999999999
    ? undefined
    : 'Must be 0 - 9999999999'

export const validateDirections = value =>
  value && value.length > 140
    ? 'Must be shorter than 140 characters'
    : undefined

export const validateParmacyNote = value =>
  value && value.length > 240
    ? 'Must be shorter than 240 characters'
    : undefined

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const directionSuggestionSelector = createDeepEqualSelector(
  directionSuggestions,
  values => values
)

export const singleDecReg = RegExp(/^[\d]*[.]?[\d]?$/)

export const preventDecimal = e => {
  if (e.key === '.') {
    e.preventDefault()
  }
}

export const nullableIfNotPending = validator => (value, formValues) =>
  pendingStatusSelected(formValues)
    ? validator(value, formValues)
    : !value
    ? undefined
    : validator(value, formValues)

export const requiredIfPending = nullableIfNotPending(required)
