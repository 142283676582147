import * as O from 'fp-ts/lib/Option'
import { Action as ReduxA, State as ReduxS } from '~/reducerT'
import Aspire from '~/resources/aspire-strict'
import { Request } from '~/utils/Requestable'
import {
  getOrElse,
  RequestError,
  ServerErrorMessage,
} from '~/resources/resource-strict'
import * as Toast from '~/utils/toast'
import { Identification, Action as Review, State } from './reviewT'
import { Action as LLOS } from '../LLOST'
import { PatientStats } from './patientStatsT'
import { flow } from 'fp-ts/lib/function'
import { payloadGet } from '~/utils/type'
import * as t from 'io-ts'
import * as DE from '@nll/datum/DatumEither'

export const index = (userId: string) =>
  Request<Identification[]>(
    Aspire.get(
      '/v1/llos/review',
      t.array(Identification.strictType).asDecoder(),
      { params: { userId } }
    ),
    payload =>
      ReduxA.of.LLOS({
        payload: LLOS.of.Review({
          payload: Review.of.Patients({ payload }),
        }),
      }),
    {
      toast: Toast.error(_ => O.some("Couldn't get patient list.")),
    }
  )

export const new_ = (patientId: string) =>
  Request<PatientStats>(
    Aspire.get('/v1/llos/review/new', PatientStats.strictType.asDecoder(), {
      params: { patientId },
    }),
    payload =>
      ReduxA.of.LLOS({
        payload: LLOS.of.Review({
          payload: Review.of.Stats({ payload }),
        }),
      }),
    {
      toast: Toast.error(
        RequestError.matchStrict({
          FailedRequest: () =>
            O.some("Couldn't fetch patient details for review"),
          DecodeError: () => O.none,
          ServerError: flow(
            payloadGet,
            ServerErrorMessage.matchStrict({
              StringError: ({ message }) => message,
              VarseError: ({ message }) => message.join(', '),
              ChangesetError: ({ message }) => {
                console.error(message) // eslint-disable-line no-console
                return Object.entries(message)
                  .map(([k, v]) => `${k}${k ? '.' : ''}${v}`)
                  .join(', ')
              },
            }),
            O.some
          ),
        })
      ),
    }
  )

// const patients: ReducerT.selector(index) = x => x.llos.review.index;

export const submit = (
  userId: string,
  patientId: string,
  notes: string,
  recommendation: string
) =>
  Request(
    Aspire.post(
      '/v1/llos/review',
      t.array(Identification.strictType).asDecoder(),
      {
        patientId,
        notes,
        recommendation,
        userId,
      }
    ),
    payload =>
      ReduxA.of.LLOS({
        payload: LLOS.of.Review({ payload: Review.of.Patients({ payload }) }),
      }),
    {
      reloader: ReduxS.lensFromPath(['llos', 'review', 'index']).get,
      toast: Toast.message(
        _ => 'Successfully submitted LLOS Review.',

        RequestError.matchStrict({
          FailedRequest: () => O.some("Couldn't submit LLOS review"),
          DecodeError: () => O.none,
          ServerError: flow(
            payloadGet,
            ServerErrorMessage.matchStrict({
              StringError: ({ message }) => message,
              VarseError: ({ message }) => message.join(', '),
              ChangesetError: ({ message }) => {
                console.error(message) // eslint-disable-line no-console
                return Object.entries(message)
                  .map(([k, v]) => `${k}${k ? '.' : ''}${v}`)
                  .join(', ')
              },
            }),
            O.some
          ),
        })
      ),
    }
  )

export const init = {
  index: DE.initial,
  currentPatient: O.none,
  stats: DE.initial,
}

export const reducer: (_: Review) => (_: ReduxS) => ReduxS = flow(
  payloadGet,
  Review.matchStrict({
    Patients: ({ payload: index }) =>
      ReduxS.lensFromPath(['llos', 'review']).modify((old: State) =>
        DE.isRepleteRight(index)
          ? State.build({
              ...old,
              index,
              currentPatient: O.none,
            })
          : DE.isPending(index)
          ? State.build({
              ...old,
              stats: DE.toRefresh(old.stats),
            })
          : DE.isSuccess(old.index) && DE.isFailure(index)
          ? State.build({
              ...old,
              stats: DE.isPending(old.stats)
                ? DE.toReplete(old.stats)
                : old.stats,
            })
          : State.build({ ...old, index })
      ),
    Highlight: ({ payload }) =>
      ReduxS.lensFromPath(['llos', 'review', 'currentPatient']).set(
        O.some(payload)
      ),
    Stats: flow(
      payloadGet,
      ReduxS.lensFromPath(['llos', 'review', 'stats']).set
    ),
  })
)

export const numOfReviews = flow(
  ReduxS.lensFromPath(['llos', 'review', 'index']).get,
  getOrElse<Identification[]>([]),
  a => a.length
)
