import { array, either, eq, map, semigroup } from 'fp-ts'
import { Either } from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'

type TRecord = { id: number }
type TTuple<T> = [number, T][]
type TMap<T> = Map<number, T>

export const toMap = <T extends TRecord>() => (
  values: Either<any, T[]>
): Either<any, TMap<T>> => {
  // Convert array of values into tuples
  const toTuples = either.map<T[], TTuple<T>>(
    (p: T[]): TTuple<T> => p.map(result => [result.id, result])
  )

  // Convert tuples into map
  const toMap = either.map<TTuple<T>, TMap<T>>(
    map.fromFoldable(
      eq.eqNumber,
      semigroup.getObjectSemigroup<T>(),
      array.array
    )
  )

  return pipe(values, toTuples, toMap)
}

export function mapInsertAt<T>(tmap: TMap<T>, id: number, value: T): TMap<T> {
  return map.insertAt(eq.eqNumber)(id, value)(tmap)
}
