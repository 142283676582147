import { Map } from 'immutable'
import React, { useMemo } from 'react'
import UserSelector from '~/components/UserSelector'
import PropTypes from '~/utils/propTypes'

const careTeamMembers = user =>
  user.careTeamRoles && user.careTeamRoles.length > 0

const filterMap = {
  careTeamMembers,
}

const UserSearchWidget = ({ onChange, options }) => {
  const userFilter = useMemo(() => filterMap[options.userFilter], [
    options.userFilter,
  ])

  return (
    <UserSelector
      formatSuggestion={({ name }) => name}
      userFilter={userFilter}
      onUserSelected={(_id, user) => onChange(Map(user).filter(Boolean).toJS())}
      label="User Name *"
    />
  )
}

UserSearchWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
}

export default UserSearchWidget
