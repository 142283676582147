import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: '100%',
  },
  spacer: {
    paddingRight: spacing(3),
  },
}))

export const AttachmentTypeSelector = ({
  hideLabel,
  selected,
  types,
  handleChange,
  disabled,
}) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.container, classes.spacer)}>
      <FormControl className={classes.container}>
        {!hideLabel && (
          <InputLabel htmlFor="att-type">Choose Attachment Types</InputLabel>
        )}
        <Select
          disabled={disabled}
          error={!selected}
          input={<Input id="att-type" />}
          onChange={handleChange}
          value={selected}
        >
          {types &&
            types.map(t => (
              <MenuItem key={t.type} value={t.type}>
                {t.displayType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

AttachmentTypeSelector.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  types: PropTypes.array,
  handleChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
}
