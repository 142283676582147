import { ofType } from 'redux-observable'
import { ignoreElements, map, tap } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import { VITALS_CHART, setPermanentItem } from '~/utils/storage'
import { chartDisplayToggled, getChart } from '../data/chart'

export default (action$, state$) =>
  action$.pipe(
    ofType(chartDisplayToggled),
    switchTo(state$),
    map(getChart),
    tap(chart =>
      setPermanentItem(
        VITALS_CHART,
        chart.delete('displayed').delete('page').toJSON()
      )
    ),
    ignoreElements()
  )
