import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { CONTACT_MANAGEMENT_PHYSICIAN_RECORD } from '../router'
import { getRoot } from './common/shared'

const key = 'currentPhysicianId'

const reducer = paramsReducer(
  CONTACT_MANAGEMENT_PHYSICIAN_RECORD,
  ({ physicianId }: any) => physicianId
)

reducer.key = key

export const getCurrentPhysicianId = pipe(getRoot, get(key))

export default reducer
