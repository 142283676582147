import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { first, mergeMap } from 'rxjs/operators'
import { fetchChannelList } from '../data/channelList'
import {
  coverageSelectedForCreation,
  coverageSelectedForEditing,
} from '../data/dialogs'
import { fetchHealthplanSpecificPrograms } from '../data/healthplanSpecificPrograms'
import { fetchHealthplans } from '../data/healthplans'

export default action$ =>
  action$.pipe(
    ofType(coverageSelectedForCreation, coverageSelectedForEditing),
    first(),
    mergeMap(() =>
      of(
        fetchHealthplans.requested(),
        fetchHealthplanSpecificPrograms.requested(),
        fetchChannelList.requested()
      )
    )
  )
