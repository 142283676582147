import React from 'react'
import Line from '~/components/text/Line'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import BooleanDisplayCell from './customControls/BooleanDisplayCell'

const displayComponents = {
  boolean: BooleanDisplayCell,
}

const DefaultDisplayComponent = ({ value }) => value

DefaultDisplayComponent.propTypes = {
  value: PropTypes.any,
}

DefaultDisplayComponent.defaultProps = {
  value: null,
}

class RenderedLine extends React.Component {
  get DisplayComponent() {
    return displayComponents[this.props.type] || DefaultDisplayComponent
  }

  get getCellValue() {
    return this.props.getCellValue || (row => row[this.props.name])
  }

  render() {
    const {
      props: { title, row },
      getCellValue,
      DisplayComponent,
    } = this

    return (
      <Line label={title}>
        <DisplayComponent value={getCellValue(row)} row={row} />
      </Line>
    )
  }
}

RenderedLine.propTypes = {
  getCellValue: PropTypes.func,
  name: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
}

const styles = {
  rowActions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}

class CollapsedComponent extends React.Component {
  state = { detailDisplayed: false }

  renderLine = column => (
    <RenderedLine key={column.name} row={this.props.row} {...column} />
  )

  toggleDetailDisplayed = () =>
    this.setState(({ detailDisplayed }) => ({
      detailDisplayed: !detailDisplayed,
    }))

  get displayDetailComponents() {
    return this.props.DetailComponent && this.state.detailDisplayed
  }

  render() {
    const { classes, columns, RowActions, DetailComponent, row } = this.props

    return (
      <React.Fragment>
        {columns.map(this.renderLine)}
        {this.displayDetailComponents && <DetailComponent row={row} />}
        <div className={classes.rowActions}>
          {RowActions && <RowActions row={row} />}
          {DetailComponent && (
            <IconButton onClick={this.toggleDetailDisplayed}>
              <Icon>open_in_browser</Icon>
            </IconButton>
          )}
        </div>
      </React.Fragment>
    )
  }
}

CollapsedComponent.propTypes = {
  DetailComponent: PropTypes.func,
  RowActions: PropTypes.func,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
}

export default withStyles(styles)(CollapsedComponent)
