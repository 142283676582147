import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import { fetchEncounterQueries } from '../data/codingQueries'
import { fetchEncounterCodes } from '../data/encounterCodes'
import { fetchEncounterInfo } from '../data/encounterInfo'
import { CODER_DASHBOARD_ENCOUNTER } from '../router'

const codingEncounterSelected = (action$: any) =>
  action$.pipe(
    filter(changedTo(CODER_DASHBOARD_ENCOUNTER)),
    pluck('payload', 'params', 'encounterId'),
    mergeMap(id =>
      of(
        fetchEncounterInfo.requested(id),
        fetchEncounterCodes.requested(id),
        fetchEncounterQueries.requested(id)
      )
    )
  )

export default codingEncounterSelected
