import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getOrderKey } from './common/shared'
import {
  ORDER_DETAIL_FORM_SAVE_FAILED,
  ORDER_DETAIL_FORM_SAVE_REQUESTED,
  ORDER_DETAIL_FORM_SAVE_SUCCEEDED,
} from './orderDetail'

export const key = 'orderSaveIndicator'

const UNSAVED_CHANGES_DETECTED = type(key, 'UNSAVED_CHANGES_DETECTED')

export const unsavedChangesDetected = creator(UNSAVED_CHANGES_DETECTED)

export default createReducer(key, false, {
  [UNSAVED_CHANGES_DETECTED]: () => true,
  [ORDER_DETAIL_FORM_SAVE_REQUESTED]: () => true,
  [ORDER_DETAIL_FORM_SAVE_SUCCEEDED]: () => false,
  [ORDER_DETAIL_FORM_SAVE_FAILED]: () => true,
})

export const getUnsavedChanges = pipe(getOrderKey, get(key))
