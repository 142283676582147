import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { fetchCall } from '../data/calls'
import { completeForm } from '../data/form'

export default action$ =>
  action$.pipe(
    ofType(completeForm.SUCCEEDED),
    pluck('payload'),
    map(fetchCall.requested)
  )
