import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import { getMapBounds } from '../data/map'
import { ZIPS_VISIBLE_SET, fetchZips, loadZips } from '../data/zips'
import showError from '../utils/showError'

const zipsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ZIPS_VISIBLE_SET),
    pluck('payload', 'isVisible'),
    mergeMap(isVisible => {
      if (!isVisible) return []

      const bounds = getMapBounds(state$.value)

      return loading(
        from(fetchZips(bounds)).pipe(
          map(loadZips),
          catchError(showError('Failed to fetch zip codes.'))
        )
      )
    })
  )

export default zipsEpic
