export default {
  language: {
    title: 'Authorization to Release Medical Record',
    titleSubtitle: '(This authorization complies with HIPAA)',
    consentSubtitle: 'I understand that:',
    consentList: [
      'By signing this form, I am authorizing the use or disclosure of protected health information as indicated above.',
      'I may refuse to sign this authorization, which will not affect my treatment or payment for health care.',
      'I may revoke this authorization at any time before the information I have requested is released by providing written notice of revocation as specified in the Notice of Privacy Practices. ',
      'If the receiving party is not subject to medical records privacy laws, the information may be re-disclosed by the recipient and may no longer be protected by deferral or state law. Aspire Health shall not be liable for any consequences resulting from re-disclosure.',
      'If the information to be released contains any information about HIV/AIDS an additional HIPAA release of medical information will be requested.',
      'Alcohol of substance abuse, mental health or psychiatry notes may have additional compliance requirements that must be met before the information will be released.',
      'A copy of this signed form will be provided to me.',
      'This authorization expires when Aspire Health is no longer your primary care provider.',
    ],
    sendToTitle: 'Send My Medical Information to:',
    sendToAddress: [
      'Name: Aspire Health',
      'Address: 333 Commerce St. Suite 700',
      'City, State, Zip: Nashville, TN 37201',
      'Fax: 855-611-1917',
    ],
    retain:
      'Retain this form in the patient’s medical record and provide a copy to the patient.',
  },
}
