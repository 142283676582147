import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { ASSESSMENT_LEGACY } from '../router'
import { getAssessment } from './common/shared'

const key = 'currentLegacyAssessmentId'

const reducer = paramsReducer(
  ASSESSMENT_LEGACY,
  ({ legacyAssessmentId }) => legacyAssessmentId
)

reducer.key = key

export const getCurrentLegacyAssessmentId = pipe(getAssessment, get(key))

export default reducer
