import { Redirect, Route, Router, Scope } from 'redux-routable'
import {
  CARE_TEAM_MANAGEMENT,
  CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL,
  CARE_TEAM_MANAGEMENT_MEMBER_REPLACEMENT,
  CARE_TEAM_MANAGEMENT_NEW_CARE_TEAM,
  CARE_TEAM_POD_MANAGER,
  // @ts-expect-error no export
} from '~/features/careTeamManagement'
import key from './key'

export const ADMIN_TOOLS_EDIT_PERMISSION_SET = `${key}.editPermissionSet`
export const ADMIN_TOOLS_EDIT_ROLE = `${key}.editRole`
export const ADMIN_TOOLS_EDIT_USER = `${key}.editUser`
export const ADMIN_TOOLS_NEW_PERMISSION_SET = `${key}.newPermissionSet`
export const ADMIN_TOOLS_NEW_ROLE = `${key}.newRole`
export const ADMIN_TOOLS_NEW_USER = `${key}.newUser`
export const ADMIN_TOOLS_PRESCRIBER_PATIENT_UPLOAD = `${key}.prescriberPatientUpload`
export const ADMIN_TOOLS_PERMISSION_SETS = `${key}.permissionSets`
export const ADMIN_TOOLS_PERMISSIONS = `${key}.permissions`
export const ADMIN_TOOLS_PRESCRIBING_LOCATIONS = `${key}.prescribingLocations`
export const ADMIN_TOOLS_PRESCRIBERS = `${key}.prescribers`
export const ADMIN_TOOLS_ROLES = `${key}.roles`
export const ADMIN_TOOLS_USERS = `${key}.users`
export const ADMIN_TOOLS_CARE_PLAN_MANAGER = `${key}.carePlanManager`
export const ADMIN_TOOLS_HEALTH_CONCERNS = `${key}.healthConcerns`
export const ADMIN_TOOLS_GOALS = `${key}.goals`
export const ADMIN_TOOLS_OUTCOMES = `${key}.outcomes`
export const ADMIN_TOOLS_INTERVENTIONS = `${key}.interventions`
export const ADMIN_TOOLS_ICD10_PROBLEM_TYPES = `${key}.icd10ProblemTypes`
export const ADMIN_TOOLS_TRIGGER_LOG = `${key}.triggerLog`

export const usersRouter = Router([
  Route(ADMIN_TOOLS_USERS, '/'),
  Route(ADMIN_TOOLS_NEW_USER, '/new'),
  Route(ADMIN_TOOLS_EDIT_USER, '/:userId'),
])

export const permissionsRouter = Router([
  Route(ADMIN_TOOLS_ROLES, '/roles'),
  Route(ADMIN_TOOLS_NEW_ROLE, '/roles/new'),
  Route(ADMIN_TOOLS_EDIT_ROLE, '/roles/:roleId'),
  Route(ADMIN_TOOLS_NEW_PERMISSION_SET, '/permission-sets/new'),
  Route(ADMIN_TOOLS_EDIT_PERMISSION_SET, '/permission-sets/:permissionSetId'),
  Route(ADMIN_TOOLS_PERMISSION_SETS, '/permission-sets'),
  Route(ADMIN_TOOLS_PERMISSIONS, '/permissions'),
  Redirect(ADMIN_TOOLS_ROLES),
])

export const prescribersRouter = Router([
  Route(ADMIN_TOOLS_PRESCRIBERS, '/prescribers'),
  Route(ADMIN_TOOLS_PRESCRIBING_LOCATIONS, '/prescribing-locations'),
  Route(ADMIN_TOOLS_PRESCRIBER_PATIENT_UPLOAD, '/prescriber-patient-upload'),
  Redirect(ADMIN_TOOLS_PRESCRIBERS),
])

export const carePlanManagerRouter = Router([
  Route(ADMIN_TOOLS_HEALTH_CONCERNS, '/health-concerns'),
  Route(ADMIN_TOOLS_GOALS, '/goals'),
  Route(ADMIN_TOOLS_OUTCOMES, '/outcomes'),
  Route(ADMIN_TOOLS_INTERVENTIONS, '/interventions'),
  Route(ADMIN_TOOLS_CARE_PLAN_MANAGER, '/care-plan-manager'),
  Redirect(ADMIN_TOOLS_HEALTH_CONCERNS),
])

export default Router([
  Scope('/users', usersRouter),
  Scope('/permissions', permissionsRouter),
  Scope('/prescribing-info', prescribersRouter),
  Scope('/care-plan-manager', carePlanManagerRouter),
  Route(CARE_TEAM_MANAGEMENT, '/care-teams'),
  Route(
    CARE_TEAM_MANAGEMENT_MEMBER_REPLACEMENT,
    '/care-teams/user-replacement'
  ),
  Route(CARE_TEAM_MANAGEMENT_NEW_CARE_TEAM, '/care-teams/new'),
  Route(CARE_TEAM_POD_MANAGER, '/care-teams/pod-management'),
  Route(CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL, '/care-teams/:careTeamId'),
  Route(ADMIN_TOOLS_ICD10_PROBLEM_TYPES, '/icd10-problem-types-administration'),
  Route(ADMIN_TOOLS_TRIGGER_LOG, '/trigger-log'),
  Redirect(ADMIN_TOOLS_USERS),
])
