import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import key from '../key'

const CallListItem = Record({
  id: null,
  createdAt: null,
  callerName: null,
  patientId: null,
  patientName: null,
  appId: null,
  appName: null,
  phoneNumber: null,
  disposition: null,
  subDisposition: null,
  notes: null,
})

const transformCallList = items =>
  List(
    items.map(({ createdAt, ...call }) =>
      CallListItem({
        createdAt: new Date(`${createdAt}Z`),
        ...call,
      })
    )
  )

export const fetchCallList = Request({
  typePrefix: key,
  typeBase: 'FETCH_CALL_LIST',
  requestParams: ['userId', 'fromDate', 'throughDate'],
  operation: (userId, fromDate, throughDate) =>
    AspireAPI.get('v1/calldispositions/call_list', {
      params: {
        userId,
        fromDate,
        throughDate,
      },
    }),
  transform: transformCallList,
  messages: {
    failed: 'There was an issue fetching the call list',
  },
})

export default createReducer(key, List(), {
  [fetchCallList.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getCallList = get(key)
