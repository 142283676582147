import { entered } from 'redux-routable'
import { filter, first, map } from 'rxjs/operators'
import { fetchForm } from '~/features/videoVisitConsent'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_VIDEO_VISIT_CONSENT } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_VIDEO_VISIT_CONSENT)),
    first(),
    switchTo(state$),
    map(getPatientId),
    map(id => fetchForm.requested(id, false))
  )
