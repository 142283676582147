import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const SNF_PATIENT = 'snfPatient'

const SNFPatient = Record({
  id: null,
  status: null,
})

const creator = scopedCreator(key)
export const snfPatientSelected = creator('SNF_PATIENT_SELECTED', [
  'id',
  'status',
])
export const snfPatientCleared = creator('SNF_PATIENT_CLEARED', false)

const initState = null
export default createReducer(SNF_PATIENT, initState, {
  [snfPatientSelected]: (_state, { payload }) => SNFPatient(payload),
  [snfPatientCleared]: () => initState,
})

export const getSNFPatient = pipe(getRoot, get(SNF_PATIENT))
