const checkCreateReducerParams = (key, initState, handlers) => {
  if (typeof key !== 'string' || typeof handlers !== 'object') {
    throw new Error(`
      createReducer was passed incorrect arguments.

      The correct signature is:
        (key: string, initState: any, handlers: object) => reducer: func

      The arguments passed in are:
        ${key}, ${initState}, ${handlers}
    `)
  }
}

const defaultHandler = state => state

/*
  Helper for creating a redux reducer function

  key: string - key of the branch of the state tree handled by the reducer
  initialState: any - initial state of the branch of the state tree handled by
    the reducer
  handlers: {string: (state, action) => state} - mapping of action types to
    action handlers (reducers that handle a specific action)
*/
const createReducer = (key, initState, handlers) => {
  checkCreateReducerParams(key, initState, handlers)

  const reducer = (state = initState, action, ...args) => {
    const handler = handlers[action.type] || defaultHandler
    return handler(state, action, ...args)
  }

  reducer.key = key

  return reducer
}

export default createReducer
