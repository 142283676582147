import { Map, Record } from 'immutable'
import { mapValues } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { into, scopedCreator } from '~/utils/data'

const USERS = 'users'

// Record
export const User = Record({
  active: null,
  aspireRole: null,
  careTeamRoles: null,
  id: null,
  label: null,
  lat: null,
  lng: null,
  marketId: null,
  marketName: null,
  name: null,
  patientProvider: null,
  roleId: null,
  roleName: null,
  timeZone: null,
  value: null,
})

// metadata/prototypes get stripped when retrieved from localforage. This puts them back
const parseLocal = localUsers => Map(mapValues(localUsers, User))

// Actions
const creator = scopedCreator(USERS)
export const usersRequested = creator('USERS_REQUESTED', ['includeInactive'])
export const localUsersLoaded = creator('LOCAL_USERS_LOADED', ['users'])

// Request
const labelUser = user => User({ ...user, label: user.name, value: user.id })

export const fetchUsers = Request({
  typePrefix: USERS,
  typeBase: 'FETCH_USERS',
  requestParams: ['includeInactive'],
  operation: includeInactive =>
    AspireAPI.get('v1/user', {
      params: { inactive: !!includeInactive },
    }),
  transform: into(labelUser, 'id'),
  messages: {
    failed: 'There was an issue fetching users',
  },
})

// Reducer
export default createReducer(USERS, Map(), {
  [fetchUsers.SUCCEEDED]: (_state, { payload }) => payload,
  [localUsersLoaded]: (state, { payload: { users } }) =>
    users ? parseLocal(users) : state,
})

// Selectors
export const getUsers = state => state.get(USERS)

export const getUserById = (state, userId) =>
  getUsers(state).get(userId, User())

export const getUserArray = createSelector(getUsers, users => users.toArray())
