// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import {
  filtersCleared,
  filtersLoadedFromStorage,
  getPatientFilters,
} from './root'
import { filterToggled } from './selected'
import { valueUpdated } from './values'

const STORED = 'stored'

const initState = false
export default createReducer(STORED, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [filtersCleared]: () => initState,
  // @ts-expect-error can we deal with the toString being automatically called?
  [filtersLoadedFromStorage]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [filterToggled]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [valueUpdated]: () => true,
})

export const getStored = pipe(getPatientFilters, get(STORED))
