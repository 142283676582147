import { List, Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import { CarePlanActionStatus } from '~/fieldValues/care_plan_action_status'
import { CarePlanGoalBarrier } from '~/fieldValues/care_plan_goal_barrier'
import { CarePlanGoalStatus } from '~/fieldValues/care_plan_goal_status'
import { ProblemStatus } from '~/fieldValues/problem_status'
import { get, scopedCreator } from '~/utils/data'
import key from '../../key'

// TYPES
type User = {
  id: string
  name: string
  role: string
}

export type CarePlanAction = {
  id?: number | string
  carePlanActionTypeId: number | null
  label: string
  status: CarePlanActionStatus
  assignedToId?: string
  createdAt?: string
  modifiedAt?: string
  modifiedBy?: User
  modifiedByCareTeamRoles?: string[]
  assignedTo?: User
}

export type CarePlanGoal = {
  id?: number | string
  carePlanGoalTypeId: number | null
  label: string
  status: CarePlanGoalStatus
  createdAt?: string
  modifiedAt?: string
  modifiedBy?: User
  modifiedByCareTeamRoles?: string[]
  dueDate: string
  barriers: CarePlanGoalBarrier[]
  barrierOther: string
  actions: CarePlanAction[]
  originalDueDate?: string
  autoPopulated?: boolean
}

export type Problem = {
  id: number | string
  problemCategoryId: number
  category: string
  subcategory: string
  problemTypeId?: number
  problemTypeValid: boolean
  label: string
  icd10Code?: string
  icd10Description?: string
  imoLexicalCode: number
  imoOrder: number
  status: ProblemStatus
  statusLabel: string
  onsetDate?: string
  notes?: string
  overview?: string
  rank?: number
  details: any // Immutable
  carePlanGoals: CarePlanGoal[]
  goals: any // Immutable
  selected: boolean
  problemAssessmentNotes?: string
  actionAssessmentNotes?: string
  // Fields linking to other assessment sections
  conditionId?: number
  isPotentialProblem?: boolean
  // Immutable functions
  set: any
  merge: any
  update: any
}

// RECORDS
export const Problem = Record({
  id: null,
  problemCategoryId: null,
  category: null,
  subcategory: null,
  problemTypeId: null,
  problemTypeValid: true,
  label: null,
  icd10Code: null,
  icd10Description: null,
  imoLexicalCode: null,
  imoOrder: null,
  status: 'active',
  statusLabel: 'Active',
  onsetDate: null,
  notes: null,
  overview: null,
  rank: null,
  details: List(),
  carePlanGoals: [],

  // Assessment fields
  goals: List(),
  selected: false,
  problemAssessmentNotes: null,
  actionAssessmentNotes: null,
  conditionId: null,
  isPotentialProblem: false,
})

export type ProblemDetail = {
  id: number
  problemDetailTypeId?: number
  rank: number
  label: string
  value: string
  originalValue: string
  createdAt: string
  new?: boolean
  modified?: boolean
}

export const ProblemDetail = Record({
  id: null,
  problemDetailTypeId: null,
  rank: null,
  label: null,
  value: null,
  originalValue: null,
  createdAt: null,

  // Flags for checking problem detail state
  new: false,
  modified: false,
})

export const ICD10Code = Record({
  code: null,
  description: null,
})

export type Goal = {
  id: string
  rank: number
  problemId: number | string
  goalTypeId?: number
  label: string
  status: string
  actions: any
  // Immutable functions
  set: any
  update: any
}

export const Goal = Record({
  id: null,
  rank: null,
  problemId: null,
  goalTypeId: null,
  label: null,
  status: null,
  actions: List(),
})

export type Action = {
  id: string
  rank: number
  goalId: string
  actionTypeId?: number
  label: string
  supplementRequired: boolean
  supplement: string
  status: string
  followUp: any
  // Immutable functions
  set: any
}

export const Action = Record({
  id: null,
  rank: null,
  goalId: null,
  actionTypeId: null,
  label: null,
  supplementRequired: false,
  supplement: '',
  status: null,
  followUp: List(),
})

export const FollowUp = Record({
  id: null,
  key: null,
  description: null,
})

// SORTING
export const sortRanked = (a: any, b: any) => a.rank - b.rank
export const sortUnranked = (a: any, b: any) => a.label.localeCompare(b.label)

// ACTIONS
export const problemsInitialized = scopedCreator(key)('PROBLEMS_INITIALIZED')

// SELECTOR CREATORS
const createRankedSelector = () =>
  createSelector(
    [items => items],
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    items => items.filter(({ rank }: any) => rank).sort(sortRanked)
  )

const createUnrankedSelector = () =>
  createSelector(
    [items => items],
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    items => items.filterNot(({ label, rank }: any) => !label || rank)
  )

export const createOrderedSelector = () =>
  createSelector(
    [createRankedSelector(), createUnrankedSelector()],
    (items1, items2) => items1.concat(items2)
  )

// SELECTORS
export const getRoot = get(key)

const mapDetails = (details: any) =>
  details ? List(details.map(ProblemDetail)) : List()

const mapActions = (actions: any) =>
  List(
    actions?.map(({ id, tmpId, followUp, ...action }: any) =>
      Action({
        ...action,
        id: id || tmpId,
        followUp: List(followUp?.map(FollowUp)),
      })
    )
  )

const mapGoals = (goals: any) =>
  List(
    goals?.map(({ id, tmpId, actions, ...goal }: any) =>
      Goal({ ...goal, id: id || tmpId, actions: mapActions(actions) })
    )
  )

export const mapProblem = ({ id, tmpId, details, goals, ...problem }: any) =>
  Problem({
    ...problem,
    id: id || tmpId,
    details: mapDetails(details),
    goals: mapGoals(goals),
    selected: goals?.length > 0,
  })

export const mapProblems = (problems: any = []) =>
  Map(
    problems.map((problem: any) => [
      problem.id || problem.tmpId,
      mapProblem(problem),
    ])
  )
