import React from 'react'
import { useSelector } from 'react-redux'
import {
  RiskLevelDisplay,
  getIsCaremoreBrand,
  getRiskLevel,
} from '~/features/patientInfo'
import PropTypes from '~/utils/propTypes'

const RiskCell = ({ row, value }) => {
  const isCaremore = useSelector(getIsCaremoreBrand)
  return row.groupedBy ? (
    getRiskLevel(isCaremore, value).display
  ) : (
    <RiskLevelDisplay isCaremoreBrand={isCaremore} score={value} />
  )
}

RiskCell.propTypes = {
  row: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default React.memo(RiskCell)
