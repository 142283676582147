import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { get, getIn, scopedCreator } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDateTimeWithTimeZone } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { format, parse } from 'date-fns'

// KEY
const key = 'tripReport'
const fieldsKey = 'tripReportFields'

export const TripReport = Record({
  tripId: null,
  subscriberId: null,
  destination: null,
  appointmentDate: null,
  appointmentTime: null,
  tripStatus: null,
  cancelCount: null,
  countable: null,
  pickupName: null,
  pickupTime: null,
  pickupPhone: null,
  pickupAddress: null,
  pickupCity: null,
  pickupZipcode: null,
  pickupState: null,
  destinationName: null,
  destinationAddress: null,
  tripType: null,
  vendorName: null,
  destinationCity: null,
  destinationZipcode: null,
  destinationState: null,
  classplanProductId: null,
  distance: null,
  createdAt: null,
  modifiedAt: null,
  language: null,
  termDate: null,
})

export const TripReportFields = Record({
  startDate: null,
  endDate: null,
})

// CREATORS
const creator = scopedCreator(fieldsKey)
export const selectedFields = creator('SELECTED_TRIP_REPORT_APPT_DATE')
export const resetFields = creator('RESET_TRIP_REPORT_APPT_DATE')

// TRANSFORMER
const mapTripReport = (results: any) =>
  List(
    results?.map((tripReport: any) =>
      TripReport({
        tripId: tripReport?.tripId,
        subscriberId: tripReport?.subscriberId,
        appointmentDate: tripReport?.appointmentDate,
        appointmentTime: tripReport?.isApptTimeEmpty
          ? ''
          : tripReport?.appointmentTime,
        tripStatus: tripReport?.tripStatus,
        cancelCount: tripReport?.cancelInd ? 1 : 0,
        countable: tripReport?.isCountable ? 1 : 0,
        pickupName: tripReport?.pickupName,
        pickupTime: tripReport?.pickupTime,
        destination: tripReport?.destination,
        pickupPhone: tripReport?.pickupPhone,
        pickupAddress: tripReport?.pickupAddress2
          ? `${tripReport?.pickupAddress1},${tripReport?.pickupAddress2}`
          : tripReport?.pickupAddress1,
        pickupCity: tripReport?.pickupCity,
        pickupZipcode: tripReport?.pickupZipcode,
        pickupState: tripReport?.pickupState,
        destinationName: tripReport?.destinationName,
        destinationAddress: tripReport?.destinationAddress2
          ? `${tripReport?.destinationAddress1},${tripReport?.destinationAddress2}`
          : tripReport?.destinationAddress1,
        tripType: tripReport?.tripType,
        vendorName: tripReport?.vendorName,
        destinationCity: tripReport?.destinationCity,
        destinationZipcode: tripReport?.destinationZipcode,
        destinationState: tripReport?.destinationState,
        classplanProductId: tripReport?.classplanProductId,
        distance: tripReport?.distance,
        language: tripReport?.language,
        termDate: tripReport?.termDate
          ? format(
              parse(tripReport?.termDate, 'yyyy-MM-dd', new Date()),
              'MM/dd/yyyy'
            )
          : '',
        createdAt: formatDateTimeWithTimeZone(tripReport?.createdAt),
        modifiedAt: formatDateTimeWithTimeZone(tripReport?.modifiedAt),
      })
    )
  )

export const fetchTripReport = Request({
  typePrefix: key,
  typeBase: 'GET_TRIP_REPORT',
  requestParams: ['startDate', 'endDate'],
  operation: (startDate: string, endDate: string) =>
    AspireAPI.get(
      `transportation/trip_report?start_date=${startDate}&end_date=${endDate}`
    ),
  transform: mapTripReport,
  messages: {
    failed: 'Fetching Trip details by appoitnment dates failed',
  },
})

// REDUCER
export const tripReport = createReducer(key, List(), {
  [fetchTripReport.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const tripReportFields = createReducer(fieldsKey, TripReportFields, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [selectedFields]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetFields]: () => TripReportFields,
})

// SELECTORS
export const getTripReport = pipe(getRoot, get(key))
export const getTripReportFields = getIn([rootKey, fieldsKey])
