import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { mapTo, mergeMap, pluck, withLatestFrom } from 'rxjs/operators'
import { requestSucceeded } from '~/utils/operators'
import {
  fetchPatient,
  getPatientId,
} from '../../../features/patientInfo/data/patient'
import { signAssessment } from '../data/assessments'
import { getCurrentAssessment } from '../data/common/derived'
import { ASSESSMENT_SIGNED } from '../data/common/shared'
import { getManifestData } from '../data/manifest'

export default (action$, state$) =>
  action$.pipe(
    ofType(ASSESSMENT_SIGNED),
    pluck('payload', 'ensurePhysicianReview'),
    withLatestFrom(state$),
    mergeMap(([ensurePhysicianReview, state]) => {
      const id = getCurrentAssessment(state)
      const formData = getManifestData(state)
      const patientId = getPatientId(state)

      return merge(
        of(signAssessment.requested(id, ensurePhysicianReview, formData)),
        action$.pipe(
          requestSucceeded(signAssessment),
          mapTo(fetchPatient.requested(patientId))
        )
      )
    })
  )
