import { colors } from '@material-ui/core'
import lightTheme from '../../light'
import cloud2 from './big-cloud-2.png'
import cloud1 from './big-cloud.png'
import butterfly from './butterfly.png'
import grass from './grass.png'
import cloud3 from './smaller-clouds.png'
import sun from './sun.png'
import sunflower from './sunflower.png'

export const springStyles = () => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      backgroundColor: colors.lightBlue[100],
      backgroundImage: `url(${sun})`,
      backgroundPosition: '200px -70px',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      overflowX: 'hidden',
      '&::after': {
        content: '""',
        animation: 'animatedCloud3 200s linear infinite',
        backgroundImage: `url(${cloud3})`,
        left: -700,
        backgroundRepeat: 'no-repeat',
        height: '35%',
        position: 'absolute',
        top: 145,
        width: '100%',
        zIndex: -15,
      },
    },
    '#root': {
      height: '100%',
      '& > div': {
        height: '100%',
      },
      '&::before': {
        content: '""',
        animation: 'animatedCloud1 280s linear infinite',
        backgroundImage: `url(${cloud1})`,
        left: -700,
        backgroundRepeat: 'no-repeat',
        height: '46%',
        position: 'absolute',
        top: -30,
        width: '100%',
        zIndex: -5,
      },
      '&::after': {
        content: '""',
        animation: 'animatedCloud2 160s linear 18s infinite',
        backgroundImage: `url(${cloud2})`,
        backgroundRepeat: 'no-repeat',
        height: '60%',
        left: -700,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: -10,
      },
    },
    '.content-root': {
      backgroundImage: `url(${grass})`,
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      minHeight: '100%',
    },
    '.panel-handle': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& span:first-child': {
        visibility: 'hidden',
      },
      '&::after': {
        content: '""',
        backgroundImage: `url(${butterfly})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '85%',
        bottom: -12,
        height: 100,
        position: 'absolute',
        right: -42,
        transform: 'rotate(36deg)',
        width: 100,
      },
    },
    '@keyframes animatedCloud1': {
      from: { transform: 'translate(-1600px, 20px)' },
      to: {
        transform: 'translate(calc(100% + 1600px), 22px)',
      },
    },
    '@keyframes animatedCloud2': {
      from: { transform: 'translate(-700px, 80%)' },
      to: { transform: 'translate(calc(100% + 700px), 78%)' },
    },
    '@keyframes animatedCloud3': {
      from: { transform: 'translate(-1000px, 20%)' },
      to: { transform: 'translate(calc(100% + 1000px), 20%)' },
    },
  },
})

export const springTheme = () => ({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    holiday: 'spring',
    primary: {
      light: colors.lightGreen[400],
      main: colors.lightGreen[600],
      dark: colors.lightGreen[800],
    },
    secondary: {
      main: colors.lightGreen[500],
    },
    drawer: {
      backgroundClosed: {
        backgroundColor: 'rgba(33, 148, 243, 0.5)',
        '& $header': {
          visibility: 'hidden',
        },
      },
      backgroundOpen: {
        backgroundColor: 'rgba(33, 148, 243, 1.0)',
        '& $header': {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          visibility: 'visible',
        },
        '@media (min-width: 1280px)': {
          backgroundColor: 'rgba(33, 148, 243, 0.5)',
        },
      },
    },
    enrolledStatusBadge: {
      backgroundColor: colors.purple[500],
      color: '#fff',
    },
    accordion: {
      color: colors.common.white,
      header: colors.lightGreen[700],
    },
    timelineDetail: {
      backgroundColor: colors.yellow[100],
    },
    panelTabSelected: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    tabSelected: {
      backgroundColor: colors.lightGreen[800],
    },
    patientSearchProgramBadge: {
      backgroundColor: colors.lightGreen[700],
      color: colors.common.white,
    },
    alertsAccordion: {
      backgroundColor: colors.pink[300],
    },
    patientInfoTag: {
      backgroundColor: colors.lightBlue[500],
      '& button:hover': {
        backgroundColor: colors.lightBlue[600],
      },
    },
    toolbarHeaderText: {
      color: colors.common.white,
    },
    contentText: {
      primaryLabel: colors.purple[700],
      secondaryLabel: colors.purple[500],
    },
    notifications: {
      linkText: colors.lightGreen[500],
    },
    calendarHead: {
      backgroundColor: colors.lightGreen[700],
    },
    calendarTitleText: {
      color: colors.lightGreen[700],
    },
    formPageTitle: {
      color: colors.lightGreen[700],
    },
    vitalsToolbarHeader: {
      backgroundColor: colors.lightGreen[700],
    },
    vitalsChipContainer: {
      backgroundColor: colors.lightGreen[300],
    },
    vitalsChipChosen: {
      backgroundColor: colors.lightGreen[700],
      color: colors.common.white,
    },
  },
  overrides: {
    ...lightTheme.overrides,
    MuiAppBar: {
      root: {
        '&.mui-fixed': {
          backgroundColor: 'rgba(33, 148, 243, 0.5)',
          color: colors.common.white,
          transition: 'background-color 1s linear',
          '&.holiday-app-bar': {
            backgroundColor: 'rgba(33, 148, 243, 1.0)',
          },
        },
      },
    },
    MuiBadge: {
      root: {
        '& $badge': {
          backgroundColor: colors.orange[500],
        },
      },
    },
    MuiButton: {
      outlined: {
        borderColor: colors.lightGreen[700],
        color: colors.lightGreen[700],
      },
      outlinedPrimary: {
        backgroundColor: colors.lightGreen[700],
        color: colors.common.white,
      },
      root: {
        '&:hover': {
          backgroundColor: colors.lightGreen[200],
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: 'rgb(255, 249, 196)',
      },
    },
    MuiDialogTitle: {
      root: {
        color: colors.purple[700],
      },
    },
    MuiDrawer: {
      paperAnchorRight: {
        backgroundColor: colors.yellow[100],
      },
    },
    MuiFab: {
      root: {
        '&::after': {
          content: '""',
          backgroundImage: `url(${sunflower})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          bottom: -20,
          height: 100,
          position: 'absolute',
          right: -20,
          width: 100,
          zIndex: -10,
        },
      },
      label: {
        color: colors.common.white,
      },
      primary: {
        backgroundColor: colors.brown[500],
      },
    },
    MuiIconButton: {
      root: {
        '&$colorPrimary': {
          color: colors.lightGreen[600],
        },
      },
    },
    MuiLinearProgress: {
      barColorSecondary: {
        backgroundColor: colors.orange[500],
      },
      colorSecondary: {
        backgroundColor: '#ffd394',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: colors.lightGreen[200],
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'rgba(255, 249, 196, 0.8)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: colors.lightGreen[100],
        '& .material-icons': {
          color: colors.lightGreen[500],
        },
      },
    },
    MuiSvgIcon: {
      root: {
        color: colors.lightGreen[600],
      },
    },
    MuiTablePagination: {
      toolbar: {
        color: colors.common.white,
        '& button': {
          color: colors.common.white,
        },
        '& svg': {
          color: colors.common.white,
        },
      },
    },
  },
})
