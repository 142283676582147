import { apiCreator, type } from '~/utils/data'
import key from '../key'

export const REQUEST_ORDERS_FOR_CARE_TEAM_MEMBER_ID = type(
  key,
  'REQUEST_ORDERS_FOR_CARE_TEAM_MEMBER_ID'
)

export const REQUEST_ORDERS_FOR_USER_ID = type(
  key,
  'REQUEST_ORDERS_FOR_USER_ID'
)

export const REQUEST_ORDERS_FOR_PATIENT_ID = type(
  key,
  'REQUEST_ORDERS_FOR_PATIENT_ID'
)

export const careTeamMemberOrdersFetchRequested = apiCreator(
  REQUEST_ORDERS_FOR_CARE_TEAM_MEMBER_ID,
  'userId'
)

export const ownerOrdersFetchRequested = apiCreator(
  REQUEST_ORDERS_FOR_USER_ID,
  'userId'
)

export const patientOrdersFetchRequested = apiCreator(
  REQUEST_ORDERS_FOR_PATIENT_ID,
  'patientId'
)
