import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getAttachmentsRoot } from './common/shared'

export const Attachment = Record({
  id: null,
  patientId: null,
  object: null,
  bucket: null,
  link: null,
  displayName: null,
  contentType: null,
  createdBy: null,
  createdByName: null,
  createdDate: null,
  attachmentType: null,
  dateOfService: null,
  source: null,
})

const key = 'currentAttachments'
const scoped = scopedCreator(key)

export const saveComplete = scoped('SAVE_COMPLETE')

export const fetchPatientAttachments = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_ATTACHMENTS',
  requestParams: ['patientId'],
  operation: (patientId, cancelToken) =>
    AspireAPI.get('/v1/attachments', {
      params: { patient_id: patientId },
      cancelToken,
    }),
  transform: into(Attachment, 'id'),
  messages: { failed: "There was a problem fetching patient's attachments" },
})

export const downloadAttachment = Request({
  typePrefix: key,
  typeBase: 'DOWNLOAD_ATTACHMENT',
  requestParams: ['attachmentId'],
  operation: id =>
    AspireAPI.get(`/media/legacy_attachments/${id}`, { responseType: 'blob' }),
  messages: { failed: 'There was a problem fetching the attachment' },
})

export const logDownload = Request({
  typePrefix: key,
  typeBase: 'LOG_DOWNLOAD',
  requestParams: ['attachmentId'],
  operation: attachmentId =>
    AspireAPI.post(`/v1/attachments/log_download`, { attachmentId }),
})

export default createReducer(key, Map(), {
  [fetchPatientAttachments.SUCCEEDED]: (state, { payload }) => payload,
  [saveComplete]: (state, { payload }) =>
    state.set(payload.id, Attachment(payload)),
})

export const getAttachments = pipe(getAttachmentsRoot, get(key))

export const getAttachmentsArray = createSelector(
  [getAttachments],
  attachments => attachments.toIndexedSeq().toJS()
)
