import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchPractices, getPracticesArr } from '../../data/practices'

const columns = [
  { name: 'name', title: 'Practice Name' },
  { name: 'phone', title: 'Phone' },
  { name: 'street', title: 'Street' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'postalCode', title: 'Postal Code' },
]
const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

const PracticeLookup = ({
  includeWrapper,
  rows,
  fetchPractices,
  onSelect,
  rowActions,
  rowActionsCount,
}) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search Practices"
    columns={columns}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchPractices}
    onSelect={onSelect}
    rowActions={rowActions}
    rowActionsCount={rowActionsCount}
  />
)

PracticeLookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchPractices: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
}

export default connect(
  state => ({
    rows: getPracticesArr(state),
  }),
  { fetchPractices: fetchPractices.requested }
)(PracticeLookup)
