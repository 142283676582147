import { titleCase } from '~/utils/stringManipulation'
import { NORMAL, URGENT } from '../constants'

export const determinePriority = priority => {
  switch (priority) {
    case 'normal':
      return NORMAL
    case 'urgent':
      return URGENT
    default:
      return titleCase(priority)
  }
}

export const comparePriorities = (a, b) => (a == b ? 0 : a == URGENT ? -1 : 1)
