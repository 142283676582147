import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'errors'

export const ERROR_OCCURRED = type(rootKey, 'ERROR_OCCURRED')
export const ERROR_DISPLAYED = type(rootKey, 'ERROR_DISPLAYED')

export const addError = creator(ERROR_OCCURRED, 'errorMessage')
export const deleteError = creator(ERROR_DISPLAYED, 'errorMessage')

export default createReducer(key, new Set(), {
  [ERROR_OCCURRED]: (state, { payload: { errorMessage } }) =>
    state.add(errorMessage),
  [ERROR_DISPLAYED]: (state, { payload: { errorMessage } }) =>
    state.delete(errorMessage),
})

export const getErrors = pipe(getRoot, get(key))
