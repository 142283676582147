import { parseISO } from 'date-fns'
import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const SNF = 'SNF'

const PATIENT_LIST = 'patientList'

export const PatientListItem = Record({
  address: null,
  appId: null,
  appName: null,
  appTimeZone: null,
  callable: null,
  callStatus: null,
  city: null,
  dischargeRecommendationReason: null,
  hasOpenTOCTasks: null,
  hasReminders: null,
  healthPlan: null,
  hospiceEligible: null,
  hotspotter: null,
  id: null,
  internalSubstatus: null,
  inWatchlist: null,
  lastCallDate: null,
  lastVisitDate: null,
  lastVisitModality: null,
  lat: null,
  lng: null,
  name: null,
  nextScheduledVisitDateTime: null,
  nextTargetedVisitDate: null,
  nextTargetedVisitModalityLabel: null,
  patientContactId: null,
  patientContactInfo: null,
  patientReminderDueDate: null,
  patientReminderNotes: null,
  postalCode: null,
  preferredEmailMissing: false,
  preferredPhoneMissing: false,
  prognosis: null,
  program: null,
  reinstated: null,
  reinstatementType: null,
  score: null,
  state: null,
  status: null,
  subStatus: null,
  virtualCapable: false,
  visitLength: null,
  visitType: null,
})

const transformPatientList = (data: any) =>
  Map(
    data.map(
      ({
        appTimeZone,
        patientId,
        patientName,
        npId,
        lastCallDate,
        lastVisitDate,
        nextScheduledVisitDateTime,
        nextTargetedVisitDate,
        patientReminderDueDate,
        patientReminderDescriptions,
        ...patient
      }: any) => [
        patientId,
        PatientListItem({
          id: patientId,
          name: patientName,
          appId: npId,
          appTimeZone,
          lastCallDate: lastCallDate && parseISO(lastCallDate),
          lastVisitDate: lastVisitDate && parseISO(lastVisitDate),
          nextScheduledVisitDateTime:
            nextScheduledVisitDateTime && parseISO(nextScheduledVisitDateTime),
          nextTargetedVisitDate:
            nextTargetedVisitDate && parseISO(nextTargetedVisitDate),
          patientReminderDueDate:
            patientReminderDueDate && parseISO(patientReminderDueDate),
          patientReminderNotes: patientReminderDescriptions,
          ...patient,
        }),
      ]
    )
  )

export const fetchPatientList = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_LIST',
  requestParams: ['userId', 'appId'],
  operation: (userId: any, appIds: any) =>
    AspireAPI.get(`v1/np/patient_census`, {
      params: {
        user_id: userId,
        np_ids: appIds,
      },
    }),
  transform: transformPatientList,
  messages: {
    failed: 'There was an issue fetching the patient list',
  },
})

const creator = scopedCreator(rootKey)
export const patientSNFSubstatusSet = creator('PATIENT_SNF_SUBSTATUS_SET', [
  'patientId',
])

const initState = Map()
export default createReducer(PATIENT_LIST, initState, {
  [fetchPatientList.REQUESTED]: () => initState,
  [fetchPatientList.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientSNFSubstatusSet]: (state: any, { payload: { patientId } }: any) =>
    state.update(patientId, (patient: any) => patient.set('subStatus', SNF)),
})

export const getPatientList = pipe(getRoot, get(PATIENT_LIST))
export const getPatientById = (state: any, id: any) =>
  getPatientList(state).get(id)
