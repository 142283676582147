import { JsonFormT } from '~/data/form'
import { Async } from '~/resources/resource-strict'
import * as StatusT from './covid19/statusT'
import {
  AOfMorphADT,
  AsOpaque,
  AType,
  Ctor,
  EType,
  ReduxAction,
  summon,
  Variant,
} from '~/utils/type'

const State_ = summon(F =>
  F.interface(
    {
      status: StatusT.State(F),
      vaccine: Async(JsonFormT)(F),
    },
    'State'
  )
)
export interface State extends AType<typeof State_> {}
export interface StateRaw extends EType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)

const Vaccine = Ctor('Vaccine', Async(JsonFormT))
const Status = Ctor('Status', StatusT.Action)

export const Action = Variant({
  Status,
  Vaccine,
})
export type Action = ReduxAction<'Covid19', AOfMorphADT<typeof Action>>
