import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  pluck,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { fetchChannelsByName, storeChannels } from '~/data/channels'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import { CHANNEL_QUERY_CHANGED } from '../data/referrals'

export default (action$: any) =>
  action$.pipe(
    ofType(CHANNEL_QUERY_CHANGED),
    debounceTime(750),
    pluck('channelName'),
    filter((channelName: any) => channelName && channelName.length >= 3),
    mergeMap(channelName =>
      from(fetchChannelsByName(channelName)).pipe(
        map(storeChannels),
        catchError(showError('Problem loading channels'))
      )
    )
  )
