import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const key = 'erFacilities'

export const Facility = Record({
  facilityId: null,
  facilityName: null,
})

export const fetchFacilities = Request({
  typePrefix: key,
  typeBase: 'FETCH_FACILITIES',
  requestParams: ['planId'],
  operation: planId => AspireAPI.get(`er_visits/facilities/${planId}`),
  transform: result => result.map(Facility),
  messages: { failed: 'There was a problem fetching facilities' },
})

export default createReducer(key, [], {
  [fetchFacilities.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getFacilities = pipe(get(rootKey), get(key))
