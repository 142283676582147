import { combineEpics } from 'redux-observable'
import { epic as searchFields } from './data/searchFields'
import appEntered from './epics/appEntered'
import authToReleaseEntered from './epics/authToReleaseEntered'
import callsEntered from './epics/callsEntered'
import carePlanEntered from './epics/carePlanEntered'
import careTeamEntered from './epics/careTeamEntered'
import careTeamUpdated from './epics/careTeamUpdated'
import channelAndInsuranceEntered from './epics/channelAndInsuranceEntered'
import consentForServicesEntered from './epics/consentForServicesEntered'
import demographicsEntered from './epics/demographicsEntered'
import downloadDocument from './epics/downloadDocument'
import episodesOfCareEntered from './epics/episodesOfCareEntered'
import hipaaEntered from './epics/hipaaEntered'
import historyAdmitsEntered from './epics/historyAdmitsEntered'
import historyAssessmentsEntered from './epics/historyAssessmentsEntered'
import historyClaimsEntered from './epics/historyClaimsEntered'
import historyDischargesEntered from './epics/historyDischargesEntered'
import historyOrdersEntered from './epics/historyOrdersEntered'
import medicationsEntered from './epics/medicationsEntered'
import noticeOfPrivacyPracticesEntered from './epics/noticeOfPrivacyPracticesEntered'
import onCallEntered from './epics/onCallEntered'
import outlierReviewEntered from './epics/outlierReviewEntered'
import panelOpened from './epics/panelOpened'
import {
  onDeleteContact,
  savePatientContact,
  setDecisionMaker,
  setPreferred,
  updatePatientContact,
} from './epics/patientContact'
import pharmaciesEntered from './epics/pharmaciesEntered'
import referralInfoEntered from './epics/referralInfoEntered'
import referralInfoRefreshed from './epics/referralInfoRefreshed'
import researchCasesEntered from './epics/researchCasesEntered'
import routeParams from './epics/routeParams'
import stakeholdersEntered from './epics/stakeholdersEntered'
import tasksEntered from './epics/tasksEntered'
import timelineEntered from './epics/timelineEntered'
import videoVisitConsentEntered from './epics/videoVisitConsentEntered'
import vitalsEntered from './epics/vitalsEntered'

export default combineEpics(
  appEntered,
  authToReleaseEntered,
  callsEntered,
  downloadDocument,
  carePlanEntered,
  careTeamEntered,
  careTeamUpdated,
  channelAndInsuranceEntered,
  consentForServicesEntered,
  demographicsEntered,
  episodesOfCareEntered,
  hipaaEntered,
  historyAdmitsEntered,
  historyAssessmentsEntered,
  historyClaimsEntered,
  historyDischargesEntered,
  historyOrdersEntered,
  medicationsEntered,
  noticeOfPrivacyPracticesEntered,
  onCallEntered,
  onDeleteContact,
  outlierReviewEntered,
  panelOpened,
  pharmaciesEntered,
  referralInfoEntered,
  referralInfoRefreshed,
  researchCasesEntered,
  routeParams,
  savePatientContact,
  searchFields,
  setDecisionMaker,
  setPreferred,
  stakeholdersEntered,
  tasksEntered,
  timelineEntered,
  updatePatientContact,
  videoVisitConsentEntered,
  vitalsEntered
)
