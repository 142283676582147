import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const OPEN_PATIENT_ID_TOGGLED = 'reconciliation/OPEN_PATIENT_ID_TOGGLED'

// ACTION CREATORS
export const openPatientIdToggled = (patientId: any) => ({
  type: OPEN_PATIENT_ID_TOGGLED,
  patientId,
})

// KEY
export const key = 'openPatientIds'

const toggleSet = (set: any, id: any) =>
  set.has(id) ? set.delete(id) : set.add(id)

// REDUCERS
export default createReducer(key, Set(), {
  [OPEN_PATIENT_ID_TOGGLED]: (state: any, { patientId }: any) =>
    toggleSet(state, patientId),
})

// SELECTORS
export const getOpenPatientIds = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
export const isPatientIdOpen = (state: any, patientId: any) =>
  getOpenPatientIds(state).has(patientId)
