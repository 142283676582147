import { Map, Record } from 'immutable'
import { combineEpics, ofType } from 'redux-observable'
import { createSelector } from 'reselect'
import { map, mapTo, pluck } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { PATIENT_INFO_CLEARED, fetchPatient } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'patientAlerts'

export const Alert = Record({
  createdByUser: null,
  createdDate: null,
  description: null,
  id: null,
  noCrossCoverageReason: null,
  severity: null,
  sfid: null,
  type: null,
})

export const fetchAlerts = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: (patientId, cancelToken) =>
    AspireAPI.get(`v1/patient/${patientId}/alerts`, { cancelToken }),
  transform: into(Alert, 'id'),
  messages: { failed: 'There was a problem fetching alerts' },
})

const deactivateAlertApi = alertId => AspireAPI.delete(`alerts/${alertId}`)

export const deactivateAlert = Request({
  typePrefix: key,
  typeBase: 'DEACTIVATE',
  requestParams: ['alertId'],
  operation: deactivateAlertApi,
  messages: { failed: 'There was a problem deactivating the alert' },
})

const createAlertApi = (patientId, alert) =>
  AspireAPI.post(`v1/patient/${patientId}/alerts`, {
    description: alert.get('description'),
    no_cross_coverage_reason: alert.get('noCrossCoverageReason'),
    severity: alert.get('severity'),
    type: alert.get('type'),
  })

export const createAlert = Request({
  typePrefix: key,
  typeBase: 'CREATE',
  requestParams: ['patientId', 'alert'],
  operation: createAlertApi,
  transform: Alert,
  messages: { failed: 'There was a problem creating alerts' },
})

const fetchAlertsForPatient = action$ =>
  action$.pipe(
    ofType(fetchPatient.SUCCEEDED),
    pluck('payload', 'id'),
    map(fetchAlerts.requested)
  )

const fetchAlertFieldValues = action$ =>
  action$.pipe(
    ofType(fetchAlerts.REQUESTED),
    mapTo(
      fetchDistinctFieldValues([
        'alert_severity',
        'alert_type',
        'alert_type_no_cross_coverage_reason',
      ])
    )
  )

export const epic = combineEpics(fetchAlertsForPatient, fetchAlertFieldValues)

export default createReducer(key, Map(), {
  [fetchAlerts.REQUESTED]: () => Map(),
  [fetchAlerts.SUCCEEDED]: (_state, { payload }) => payload,
  [deactivateAlert.SUCCEEDED]: (state, { payload }) => state.delete(payload.id),
  [createAlert.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [PATIENT_INFO_CLEARED]: () => Map(),
})

export const getPatientAlerts = pipe(getRoot, get(key))
export const getPatientAlertsList = createSelector([getPatientAlerts], alerts =>
  alerts.toList()
)
