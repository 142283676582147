import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error no export
import { ASSESSMENT_FORM } from '~/apps/assessment/router'
// @ts-expect-error no export
import { switchEncounter } from '~/apps/assessment/data/assessments'

export default (action$: any) =>
  action$.pipe(
    ofType(switchEncounter.SUCCEEDED),
    pluck('payload', 'id'),
    map(id => push(ASSESSMENT_FORM, { assessmentId: id }))
  )
