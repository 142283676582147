import classnames from 'classnames'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { startAssessment } from '~/apps/assessment/data/assessments'
import { getUserId } from '~/data/session'
import { dateSet } from '~/features/calendarWidget'
import { fetchMdtSingleSignOn } from '~/features/medications'
import PropTypes from '~/utils/propTypes'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import DayViewCalendar from '../components/DayViewCalendar'
import Navigator from '../components/Navigator'
import {
  getPastEvents,
  getTodaysEvents,
  getUpcomingEvents,
  isStartingAssessment,
  requestLogEventPrep,
} from '../data/dailyEvents'

const styles = ({ spacing }) => ({
  dashboardContainer: {
    maxWidth: '100vw',
  },
  card: {
    maxWidth: '100vw',
  },
  paddedTop: {
    paddingTop: 2 * spacing(1),
  },
})
class DailySchedule extends PureComponent {
  render() {
    const {
      changeCalendarDate,
      classes,
      dashUserId,
      markEventPrepped,
      mdtSingleSignOn,
      pastEvents,
      openPanel,
      startAssessment,
      startingAssessment,
      todaysEvents,
      upcomingEvents,
    } = this.props

    return (
      <div className={classes.dashboardContainer}>
        <Grid container spacing={2} className={classes.paddedTop}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6">Daily Schedule and Visit Prep</Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12}>
                <Navigator
                  openPanel={openPanel}
                  todaysEvents={todaysEvents}
                  upcomingEvents={upcomingEvents}
                  pastEvents={pastEvents}
                  startingAssessment={startingAssessment}
                  markEventPrepped={markEventPrepped}
                  startAssessment={startAssessment}
                  changeCalendarDate={changeCalendarDate}
                  dashUserId={dashUserId}
                  mdtSingleSignOn={mdtSingleSignOn}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Paper className={classnames(classes.card, classes.tallCard)}>
              <Accordion defaultExpanded className={classes.card}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <Typography variant="h6">Calendar</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DayViewCalendar dashUserId={dashUserId} />
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

DailySchedule.propTypes = {
  changeCalendarDate: PropTypes.func.isRequired,
  classes: PropTypes.object,
  dashUserId: PropTypes.string.isRequired,
  markEventPrepped: PropTypes.func.isRequired,
  mdtSingleSignOn: PropTypes.func.isRequired,
  pastEvents: PropTypes.list,
  openPanel: PropTypes.func,
  startAssessment: PropTypes.func.isRequired,
  startingAssessment: PropTypes.bool.isRequired,
  todaysEvents: PropTypes.list,
  upcomingEvents: PropTypes.list,
}

const mapStateToProps = state => ({
  runningUserId: getUserId(state),
  startingAssessment: isStartingAssessment(state),
  todaysEvents: getTodaysEvents(state),
  upcomingEvents: getUpcomingEvents(state),
  pastEvents: getPastEvents(state),
})

const mapDispatchToProps = {
  changeCalendarDate: dateSet,
  markEventPrepped: requestLogEventPrep,
  mdtSingleSignOn: fetchMdtSingleSignOn.requested,
  startAssessment: startAssessment.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DailySchedule)

// export default compose(withStyles(styles)(DailySchedule)
