import cx from 'classnames'
import { get, nthArg } from 'lodash/fp'
import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { Vendor, getVendorsTransformed, vendorsReset } from '~/data/vendors'
import { getPatientId } from '~/features/patientInfo/data/patient'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import {
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import VendorInfo from '../components/VendorInfo'
import { vendorSearchUpdated } from '../data/textUpdate'

const styles = ({ palette, spacing }) => ({
  container: { marginBottom: spacing(2) },
  formControl: { width: '100%' },
  suggestionsContainerOpen: { marginBotton: spacing(2) },
  suggestionsList: { listStyleType: 'none', margin: 0, padding: 0 },
  suggestionItem: {
    color: palette.secondary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: [spacing(1), spacing(2)],
  },
  networkLabel: {
    border: '1px solid',
    textAlign: 'center',
    width: 120,
  },
})

const getSuggestionValue = ({
  billingAddress,
  billingAddress2,
  billingCity,
  billingPostalCode,
  billingStateCode,
  fax,
  name,
  phone,
}) => {
  const haveAddress =
    billingAddress && billingCity && billingStateCode && billingPostalCode
  const address =
    haveAddress &&
    `${billingAddress}, ${
      billingAddress2 && `${billingAddress2}, `
    }${billingCity}, ${billingStateCode} ${billingPostalCode}`

  return (
    name &&
    `${name}${address && ` • ${address}`}${phone && ` • phone: ${phone}`}${
      fax && ` • fax: ${fax}`
    }`
  )
}

// Autosuggest is too eager when fetching suggestions
const VendorSearch = ({
  classes,
  formatSuggestion,
  isHumanaVbid = false,
  onSelect,
  patientId,
  selectedVendor,
  typeFilters,
  vendors,
  vendorsReset,
  vendorSearchUpdated,
}) => {
  const [search, setSearch] = useState('')

  const inputChanged = ({ value, reason }) =>
    reason === 'input-changed' &&
    vendorSearchUpdated(value, typeFilters, patientId)

  const renderSuggestion = vendor => {
    const isVbid = isHumanaVbid && vendor.isInNetwork

    return (
      <MenuItem
        component="div"
        className={cx({ [classes.suggestionItem]: isVbid })}
        divider
      >
        <Typography noWrap>{formatSuggestion(vendor)}</Typography>
        <Typography className={cx({ [classes.networkLabel]: isVbid })} noWrap>
          {isVbid ? 'IN NETWORK' : ''}
        </Typography>
      </MenuItem>
    )
  }

  return (
    <React.Fragment>
      <Autosuggest
        getSuggestionValue={formatSuggestion}
        focusInputOnSuggestionClick={false}
        id="new-task-vendor-autosuggest"
        inputProps={{
          onChange: compose(setSearch, get('newValue'), nthArg(1)),
          value: search,
        }}
        onSuggestionSelected={(x, y) => {
          y.suggestion && onSelect(y.suggestion.id, y.suggestion)
        }}
        onSuggestionsClearRequested={vendorsReset}
        onSuggestionsFetchRequested={inputChanged}
        renderInputComponent={({ ref, ...inputProps }) => (
          <FormControl className={classes.formControl} required>
            <TextField
              {...inputProps}
              id="new-task-vendor-search"
              inputRef={ref}
              helperText="Type to automatically search"
              label="Vendor"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Clear"
                      onClick={() => {
                        onSelect('', null)
                        setSearch('')
                        vendorsReset()
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
        renderSuggestion={renderSuggestion}
        suggestions={vendors}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
        }}
      />
      {selectedVendor && selectedVendor.id && (
        <VendorInfo isHumanaVbid={isHumanaVbid} vendor={selectedVendor} />
      )}
    </React.Fragment>
  )
}

VendorSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  formatSuggestion: PropTypes.func,
  isHumanaVbid: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  selectedVendor: PropTypes.instanceOf(Vendor),
  vendors: PropTypes.arrayOf(PropTypes.instanceOf(Vendor)).isRequired,
  typeFilters: PropTypes.array,
  vendorsReset: PropTypes.func.isRequired,
  vendorSearchUpdated: PropTypes.func.isRequired,
}

VendorSearch.defaultProps = {
  formatSuggestion: getSuggestionValue,
}

export default compose(
  connect(
    state => ({
      vendors: getVendorsTransformed('toArray')(state),
      patientId: getPatientId(state),
    }),
    dispatch => ({
      vendorsReset: () => dispatch(vendorsReset()),
      vendorSearchUpdated: (text, types, patientId) =>
        dispatch(vendorSearchUpdated(text, types, patientId)),
    })
  ),
  withStyles(styles)
)(VendorSearch)
