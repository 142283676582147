import React from 'react'
import { useSelector } from 'react-redux'
import { Line } from '~/components/text'
import { getPatientCareTeam } from '~/data/careTeams'
import { CircularProgress } from '@material-ui/core'
import { getCurrentPatientId } from '../../data/currentPatientId'

const CareTeam = () => {
  const patientId = useSelector(getCurrentPatientId)
  const careTeam = useSelector(state => getPatientCareTeam(state, patientId))
  const userName = 'userName'

  return careTeam ? (
    <React.Fragment>
      <Line label="Physician">{careTeam.getIn(['md', userName])}</Line>
      <Line label="APP">{careTeam.getIn(['app', userName])}</Line>
      <Line label="CSS">{careTeam.getIn(['pcc_cc', userName])}</Line>
      <Line label="Field SW">{careTeam.getIn(['field_sw', userName])}</Line>
      <Line label="Care Center SW">{careTeam.getIn(['pcc_sw', userName])}</Line>
      <Line label="Care Manager RN">
        {careTeam.getIn(['pcc_rn', userName])}
      </Line>
      <Line label="NSS">{careTeam.getIn(['nss', userName])}</Line>
      <Line label="PES">{careTeam.getIn(['pes', userName])}</Line>
    </React.Fragment>
  ) : (
    <CircularProgress />
  )
}

export default CareTeam
