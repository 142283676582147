import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { taskSelected } from '~/features/tasks'
import { CSS_DASHBOARD_TASK_DETAIL } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(entered(CSS_DASHBOARD_TASK_DETAIL)),
    pluck('payload', 'params', 'taskId'),
    map(taskSelected)
  )
