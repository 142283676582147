// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

const key = 'currentCareplanManagerRecord'

const creator = scopedCreator(key)
export const selectedRecord: any = creator('SELECTED_RECORD')

export const currrentRecord = createReducer(key, null, {
  [selectedRecord]: (_state: any, { payload }: any) => payload,
})

export const getCurrentRecord = getIn([rootKey, key])
