import { combineEpics, ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mapTo, mergeMap } from 'rxjs/operators'
import { fetchHighRiskHuddle } from '~/features/highRiskHuddle/data'
import {
  fetchMDPatientCensus,
  fetchPatientLists,
  // @ts-expect-error no export
} from '~/features/patientCensus'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { censusRefreshRequested } from '../data/common/shared'
import { getCurrentUserId } from '../data/currentUserId'
import { PHYSICIAN_DASHBOARD_CENSUS } from '../router'

const censusEnteredEpic = (action$: any) =>
  action$.pipe(
    filter(entered(PHYSICIAN_DASHBOARD_CENSUS)),
    mapTo(censusRefreshRequested())
  )

const fetchCensusEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(censusRefreshRequested),
    switchTo(state$),
    map(getCurrentUserId),
    mergeMap(userId =>
      of(
        fetchMDPatientCensus.requested(userId),
        fetchPatientLists.requested(userId),
        fetchHighRiskHuddle.requested(userId)
      )
    )
  )

export default combineEpics(censusEnteredEpic, fetchCensusEpic)
