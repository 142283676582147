import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import {
  encounterTypesCleared,
  fetchEncounterTypes,
  getCreating,
  getCurrentEvent,
  getEncounterTypes,
} from '../data/events'

// The keys on currentEvent corresponding to the params of
// fetchEncounterTypes.requested()
const paramKeys = ['ownerId', 'patientId', 'startDate']

export default (action$, state$) =>
  state$.pipe(
    map(getCurrentEvent),
    distinctUntilChanged((a, b) => {
      if (a === null || b === null) {
        return a === b
      } else {
        return a.type === b.type && paramKeys.every(p => a[p] === b[p])
      }
    }),
    filter(event => event !== null),
    filter(() => getCreating(state$.value)),
    map(event => {
      const types = getEncounterTypes(state$.value)
      const params = paramKeys.map(p => event[p])
      if (!params.includes(null)) {
        return fetchEncounterTypes.requested(...params)
      } else if (!types.isEmpty()) {
        return encounterTypesCleared()
      } else {
        return null
      }
    }),
    filter(action => action !== null)
  )
