import { List } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot, transformPatient } from './common/shared'

// KEY
const PATIENT_SEARCH = 'patientSearch'

// ACTIONS
const creator = scopedCreator(rootKey)
export const patientSearchAutoSuggested = creator(
  'PATIENT_SEARCH_AUTOSUGGESTED',
  ['value']
)

// TRANSFORMER
const mapSearchPatients = results => List(results.map(transformPatient))

// REQUEST
export const searchPatients = Request({
  typePrefix: rootKey,
  typeBase: 'SEARCH_PATIENTS',
  requestParams: ['query'],
  operation: query =>
    AspireAPI.get('v1/search', {
      params: {
        query,
        type: 'patient',
      },
    }),
  transform: mapSearchPatients,
  messages: { failed: 'Failed to search for patient.' },
})

// REDUCER
const initState = List()

export default createReducer(PATIENT_SEARCH, initState, {
  [searchPatients.REQUESTED]: () => initState,
  [searchPatients.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
const getPatientSearch = pipe(getRoot, get(PATIENT_SEARCH))

export const getPatientSearchResults = createSelector(
  getPatientSearch,
  patients => patients.take(20)
)
