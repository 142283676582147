import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withGoogle } from '~/components/GeoMap'
import { useInput, useMountEffect } from '~/hooks'
import { TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Location } from '../data/common/shared'
import geocode from '../utils/geocode'
import getAddress from '../utils/getAddress'
import InputAdornment from './InputAdornment'

const useStyles = makeStyles(({ spacing, palette }) => ({
  '@global .pac-container': {
    zIndex: 1500,
  },
  warning: {
    marginTop: spacing(1),
    color: palette.severity.warning.static,
  },
}))

const LocationSearch = ({
  className,
  google,
  disabled,
  error: errorProp,
  errorText: errorTextProp,
  location,
  onChange,
  variant,
  onTextChange = () => {},
}) => {
  const classes = useStyles()

  const inputElement = useRef()
  const locationSearch = useRef()
  const valueRef = useRef()
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [warning, setWarning] = useState(false)
  const [value, setValue] = useInput(getAddress(location))

  const onInputChange = event => {
    setValue(event)
    onTextChange(event)
  }

  const onPlacesChange = useCallback(() => {
    const [place] = locationSearch.current.getPlaces()

    if (place) {
      geocode(google, place.formatted_address, valueRef.current)
        .then(result => {
          setWarning(!result.precise)
          setError(false)
          setErrorText('')

          onChange(
            Location({
              street: result.formatted_street,
              city: result.city,
              state: result.state,
              stateCode: result.state_code,
              postalCode: result.postal_code,
              latitude: result.latitude,
              longitude: result.longitude,
              county: result.county,
              exact: result.precise,
            }).update(location =>
              location.merge({ address: getAddress(location) })
            )
          )
        })
        .catch(() => {
          setWarning(false)
          setError(true)
          setErrorText('Address invalid or not specific enough')
        })
    }
  }, [setError, setErrorText, google, onChange])

  const onClear = useCallback(() => {
    onInputChange('')
    onChange(Location())
  }, [onInputChange, onChange])

  useMountEffect(() => {
    locationSearch.current = new google.maps.places.SearchBox(
      inputElement.current
    )
    locationSearch.current.addListener('places_changed', onPlacesChange)
  })

  useEffect(() => {
    setValue(getAddress(location))
  }, [location, setValue])

  useEffect(() => {
    valueRef.current = value
  }, [value])

  const lat = location.get('latitude', null)
  const lng = location.get('longitude', null)
  const geocoded = Boolean(lat && lng)

  return (
    <div className={className}>
      <TextField
        variant={variant}
        inputRef={e => {
          inputElement.current = e
        }}
        placeholder="Search for a location..."
        error={error || errorProp}
        helperText={errorText || errorTextProp}
        fullWidth
        disabled={disabled}
        value={value}
        onChange={onInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment
              disabled={disabled}
              geocoded={geocoded}
              latitude={lat}
              longitude={lng}
              onClear={onClear}
            />
          ),
        }}
      />

      {warning && (
        <Typography className={classes.warning}>
          Warning: Geocoded coordinates may not be exact for this address.
        </Typography>
      )}
    </div>
  )
}

LocationSearch.propTypes = {
  className: PropTypes.string,
  google: PropTypes.object.isRequired,
  location: PropTypes.instanceOf(Location),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  onTextChange: PropTypes.func,
}

LocationSearch.defaultProps = {
  location: Location(),
}

export default withGoogle(LocationSearch)
