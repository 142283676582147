import React from 'react'
import { SelectField as SelectFieldBase } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import formField from './formField'

class SelectField extends React.Component {
  onBlur = (value, e) => this.props.onBlur(this.props.value, e)

  render() {
    return <SelectFieldBase {...this.props} onBlur={this.onBlur} />
  }
}

SelectField.propTypes = {
  value: PropTypes.any,
  onBlur: PropTypes.func,
}

export default formField(SelectField)
