import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { createStatusMapping, idToStatusName } from '../utils/statusMapping'
import { getRoot } from './common/shared'

const key = 'mmStatuses'

export const mmStatuses = [
  'PCP Field Outreach',
  'APP Geography',
  'Pending Contract',
  'Health Plan Review',
  'APP Constraint',
  'To Mail',
  'Campaign Assignment',
]

// Create an object array where each object has a name and id key
export const mmStatusMappings = createStatusMapping(mmStatuses)
export const getMMStatusName = idToStatusName(mmStatusMappings)

export const MM_STATUS_SELECTED = type(rootKey, 'MM_STATUS_SELECTED')

export const setSelectedMMStatuses = creator(MM_STATUS_SELECTED, 'statuses')

export default createReducer(key, new Set(), {
  [MM_STATUS_SELECTED]: (state, { payload: { statuses } }) => new Set(statuses),
})

export const getSelectedMMStatuses = pipe(getRoot, get(key))

export const getSelectedMMStatusNames = state =>
  getSelectedMMStatuses(state).map(getMMStatusName)
