import React from 'react'
import { useSelector } from 'react-redux'
import { getFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Box, Icon, Typography, makeStyles } from '@material-ui/core'
import { getRelationTypeLabel } from '../utils/utils'
import { PrimaryAddress } from './AddressDetails/PrimaryAddress'
import { PrimaryEmail } from './EmailDetails/PrimaryEmail'
import { PrimaryPhone } from './PhoneDetails/PrimaryPhone'
import { AddressDetails, PhoneDetails, EmailDetails } from '../utils/interfaces'

const useStyles = makeStyles(theme => ({
  textFieldBox: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  boxLeftMargin: { marginLeft: theme.spacing(1) },
  boxBottomMargin: { marginBottom: theme.spacing(1) },
  strikeThrough: { textDecoration: 'lineThrough' },
}))

interface ContactSummaryPropTypes {
  contact: PropTypes.object.isRequired
  expanded: PropTypes.bool.isRequired
}

export const ContactSummary = ({
  contact,
  expanded,
}: ContactSummaryPropTypes) => {
  const classes = useStyles()
  const relationTypeFields = useSelector(getFieldValues(['contact_type']))

  return (
    <React.Fragment key={contact.id}>
      <Box clone={true} className={classes.textFieldBox}>
        <Icon> {contact.preferred ? 'star' : ''} </Icon>
      </Box>
      <Box flex="1" display="flex" flexWrap="wrap">
        <Box clone={true} flex="1" display="flex" alignItems="center">
          <Typography variant="subtitle1">
            <Box
              component={'span'}
              className={contact.active ? '' : classes.strikeThrough}
            >
              {contact.name
                ? contact.name
                : contact.type == 'patient'
                ? '<Patient>'
                : '<No Name>'}
            </Box>

            {contact.scheduling ? (
              <Box clone={true} className={classes.boxLeftMargin}>
                <Icon fontSize="inherit"> event </Icon>
              </Box>
            ) : null}
            {contact.decisionMaker ? (
              <Box clone={true} className={classes.boxLeftMargin}>
                <Icon fontSize="inherit"> gavel </Icon>
              </Box>
            ) : null}
          </Typography>
        </Box>
        <Box clone={true} flex="1">
          <Typography>
            {getRelationTypeLabel(relationTypeFields, contact.type || null)}
          </Typography>
        </Box>
        <Box clone={true} flex="1">
          <Typography>{contact.relationship}</Typography>
        </Box>
        <Box flex="1 0 100%" className={classes.boxBottomMargin}>
          <Typography variant="caption">{contact.notes}</Typography>
        </Box>
        {expanded ? null : (
          <React.Fragment>
            <PrimaryPhone
              primaryPhone={
                contact.phones.filter(
                  (phone: PhoneDetails) => phone?.primary === true
                ).length > 0
                  ? contact.phones.filter(
                      (phone: PhoneDetails) => phone?.primary === true
                    )[0]
                  : null
              }
            ></PrimaryPhone>

            <PrimaryEmail
              primaryEmail={
                contact.emails.filter(
                  (email: EmailDetails) => email?.primary === true
                ).length > 0
                  ? contact.emails.filter(
                      (email: EmailDetails) => email?.primary === true
                    )[0]
                  : null
              }
            ></PrimaryEmail>

            <PrimaryAddress
              primaryAddress={
                contact.addresses.filter(
                  (address: AddressDetails) => address?.primary === true
                ).length > 0
                  ? contact.addresses.filter(
                      (address: AddressDetails) => address?.primary === true
                    )[0]
                  : null
              }
            ></PrimaryAddress>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}
