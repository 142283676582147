import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { VendorDetail } from './interfaces'

// KEY
const VENDORLIST = 'vendorList'

export const Vendor = Record({
  phone: null,
  name: null,
  id: null,
  activeInd: null,
})

// TRANSFORMER
const mapVendorResults = (results: [VendorDetail]) =>
  List(
    results?.map(vendor =>
      Vendor({
        id: vendor?.id,
        name: vendor?.name,
        phone: vendor?.phone,
        activeInd: vendor?.activeInd,
      })
    )
  )

// REQUEST
export const vendorList = Request({
  typePrefix: rootKey,
  typeBase: 'VENDORLIST',
  operation: () => AspireAPI.get('transportation/lookup/vendor_detail'),
  transform: mapVendorResults,
  messages: { failed: 'There was an issue fetching Vendor Details' },
})

// REDUCER
export const vendorDetail = createReducer(VENDORLIST, List(), {
  [vendorList.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getVendorDetails = pipe(getRoot, get(VENDORLIST))
