import { isEmpty } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Research, getCurrentResearchCase } from '~/features/patientInfo'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getRequestProcessingValue } from '../data/common/requestProcessing'
import {
  completeResearchCase,
  getDialogOpenValue,
  updateResearchCase,
  updateResearchDialogClosed,
} from '../data/updateResearchDialog'

const styles = ({ palette, spacing }) => ({
  category: {
    fontWeight: 'bold',
    marginRight: spacing(1),
  },
  dialogActions: {
    margin: 0,
    padding: spacing(1),
  },
  dialogTitle: {
    padding: [spacing(2), spacing(3)],
  },
  lighterText: {
    color: palette.grey[500],
    marginLeft: spacing(1.5),
  },
  textField: {
    marginTop: 20,
    '& div': {
      lineHeight: '1.4em',
      padding: [12, spacing(2)],
    },
  },
})

class UpdateResearchDialog extends React.Component {
  state = {
    notes: this.props.research.description,
    resetCallAttempts: this.props.research.shouldResetCallAttemptCount,
  }

  componentDidUpdate = prevProps =>
    this.props.research.description !== prevProps.research.description &&
    this.setState({ notes: this.props.research.description })

  resetState = () => this.setState({ notes: this.props.research.description })

  closeDialog = () => {
    this.resetState()
    this.props.onClose()
  }

  createNotes = event => this.setState({ notes: event.target.value })

  toggleResetCalls = () =>
    this.setState(prevState => ({
      resetCallAttempts: !prevState.resetCallAttempts,
    }))

  updateResearchInfo = () => {
    const {
      updateResearchCase,
      research: { id },
    } = this.props
    const { notes, resetCallAttempts } = this.state

    updateResearchCase(id, { notes, resetCallAttempts })
  }

  completePatientResearch = () => {
    const {
      patientId,
      completeResearchCase,
      research: { id },
    } = this.props
    const { notes, resetCallAttempts } = this.state

    completeResearchCase(id, { notes, patientId, resetCallAttempts })
  }

  render() {
    const {
      classes,
      open,
      requestIsProcessing,
      research: { dateOfService },
    } = this.props
    const { notes, resetCallAttempts } = this.state
    const requiredFieldsEmpty = isEmpty(notes)

    return (
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="sm"
        onClose={this.closeDialog}
        onExited={this.resetState}
        open={open}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          Update Research Case
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className={classes.category}>Research Start Date: </span>
            {formatDate(dateOfService)}
          </DialogContentText>
          <TextField
            className={classes.textField}
            fullWidth
            multiline
            onChange={this.createNotes}
            placeholder="Enter Case Information (Required)"
            required
            rows="4"
            variant="outlined"
            value={notes}
          />
          {notes.includes('Call Cadence Rule') && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={resetCallAttempts}
                  onChange={this.toggleResetCalls}
                />
              }
              label="Reset Call Attempts When Research Is Completed"
            />
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button color="secondary" onClick={this.closeDialog}>
            Close
          </Button>
          <Button
            color="primary"
            disabled={requiredFieldsEmpty || requestIsProcessing}
            onClick={this.updateResearchInfo}
            variant="contained"
          >
            Update
          </Button>
          <Button
            color="primary"
            disabled={requestIsProcessing}
            onClick={this.completePatientResearch}
            variant="contained"
          >
            Complete Research
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UpdateResearchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  completeResearchCase: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patientId: PropTypes.string.isRequired,
  requestIsProcessing: PropTypes.bool.isRequired,
  research: PropTypes.instanceOf(Research),
  shouldResetCallAttemptCount: PropTypes.bool,
  updateResearchCase: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  open: getDialogOpenValue(state),
  requestIsProcessing: getRequestProcessingValue(state),
  research: getCurrentResearchCase(state),
})

const mapDispatchToProps = {
  completeResearchCase: completeResearchCase.requested,
  onClose: updateResearchDialogClosed,
  updateResearchCase: updateResearchCase.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UpdateResearchDialog)
