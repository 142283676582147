import { getUserId } from '~/data/session'
import { getAllOrders } from '../orders'

export const getOrdersCount = (state, impersonationId) => {
  const userId = getUserId(state)
  const assignedOrders = getAllOrders(state).filter(
    order => order.get('currentlyAssignedTo') === (impersonationId || userId)
  )

  return assignedOrders.size
}
