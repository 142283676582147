import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { CONTACT_MANAGEMENT_CASE_MANAGER_RECORD } from '../router'
import { getRoot } from './common/shared'

const key = 'currentCaseManagerId'

const reducer = paramsReducer(
  CONTACT_MANAGEMENT_CASE_MANAGER_RECORD,
  ({ caseManagerId }: any) => caseManagerId
)

reducer.key = key

export const getCurrentCaseManagerId = pipe(getRoot, get(key))

export default reducer
