import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'patients'

export const FETCHED_PATIENTS = type(rootKey, 'FETCHED_PATIENTS')

export const loadPatients = creator(FETCHED_PATIENTS, 'patients')

export default createReducer(key, new Map(), {
  [FETCHED_PATIENTS]: (state, { payload: { patients } }) =>
    normalize(patients, { accumulator: state }),
})

export const getPatients = pipe(getRoot, get(key))

export const fetchMMPatients = (status, path) =>
  AspireAPI.get('polygonator/polygons/patients/mm', {
    params: { status, path },
  })

export const fetchEMRPatients = (status, path) =>
  AspireAPI.get('polygonator/polygons/patients/emr', {
    params: { status, path },
  })

export const fetchPatientsByFilteredCareTeam = (
  status,
  careTeamIds = [],
  path
) =>
  AspireAPI.get('polygonator/polygons/patients', {
    params: { status, careTeamIds, path },
  })
