import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import Search from '~/components/Search'
import useInput from '~/hooks/useInput'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { search } from '../data/search'
import SearchResults from './SearchResults'

const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
  },
  searchBox: {
    flex: 1,
    marginRight: spacing(1),
  },
  searchType: {
    width: 300,
  },
})

const SelectProps = { native: true }

const SearchBar = ({
  classes,
  autoFocus,
  contrast,
  compact,
  includeResults,
  searchRef,
  search,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchType, setSearchType] = useInput('any')

  const onChangeSearch = useCallback(
    value => {
      setSearchValue(value)
      props.onChangeSearch(value)
    },
    [setSearchValue, props.onChangeSearch]
  )

  const onSearch = useCallback(
    value => {
      search(value, searchType)
      props.onSearch(value, searchType)
    },
    [search, props.onSearch, searchType]
  )

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.searchBox}>
          <Search
            searchRef={searchRef}
            label="Search"
            autoFocus={autoFocus}
            contrast={contrast}
            compact={compact}
            value={searchValue}
            onChangeSearch={onChangeSearch}
            onSubmit={onSearch}
          />
        </div>

        <TextField
          className={classes.searchType}
          select
          SelectProps={SelectProps}
          label="Search Type"
          value={searchType}
          onChange={setSearchType}
        >
          <option value="all">Any </option>
          <option value="patient">Patients </option>
          <option value="visit">Events </option>
          <option value="np">APPs </option>
        </TextField>
      </div>

      {includeResults && <SearchResults />}
    </React.Fragment>
  )
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
  onChangeSearch: PropTypes.func,
  onSearch: PropTypes.func,
  contrast: PropTypes.bool,
  compact: PropTypes.bool,
  includeResults: PropTypes.bool,
  searchRef: PropTypes.func,
  search: PropTypes.func.isRequired,
}

SearchBar.defaultProps = {
  autoFocus: false,
  compact: false,
  contrast: false,
  includeResults: false,
}

SearchBar.defaultProps = {
  onSearch: () => {},
  onChangeSearch: () => {},
}

export default compose(
  connect(null, { search: search.requested }),
  withStyles(styles)
)(SearchBar)
