import 'react-vis/dist/style.css'

import cx from 'classnames'
import { isEmpty, isEqual } from 'lodash'
import React from 'react'
import {
  FlexibleXYPlot,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from 'react-vis'
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend'
import { formatShortDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { ChartState } from '../../data/chart'
import { Vitals } from '../../data/vitals'

const createSobData = (filteredData, chip) => ({
  id: `${filteredData.id}${chip}`,
  x: formatShortDate(filteredData.dateOfService),
  y: sobLegend.reduce(
    (acc, val, i) => (val.title === filteredData[chip] ? i + 1 : acc),
    0
  ),
  color: sobLegend.find(val => val.title === filteredData[chip]).color,
  size: 8,
})

const createGraphData = ({ vitals, chip }) =>
  vitals
    .filter(data => data[chip])
    .map(filteredData =>
      chip === ASSOCIATED_ACTIVITY_SOB
        ? createSobData(filteredData, chip)
        : {
            id: `${filteredData.id}${chip}`,
            x: formatShortDate(filteredData.dateOfService),
            y: filteredData[chip],
          }
    )
    .toArray()

// CONSTANTS FOR CHIPS
const ASSOCIATED_ACTIVITY_SOB = 'associatedActivitySob'
const BMI = 'bmi'
const BLOOD_PRESSURE = 'bloodPressure'
const DIASTOLIC = 'diastolic'
const PPS_SCORE = 'ppsScore'
const PULSE_OXIMETRY_SCORE = 'pulseOximetryScore'
const PULSE = 'pulse'
const SEVERITY_CURRENT_PAIN = 'severityCurrentPain'
const SYSTOLIC = 'systolic'
const WEIGHT = 'weight'
const HEIGHT = 'height'
const MID_ARM_CIRCUMFERENCE = 'midArmCircumference'
const RESPIRATORY_RATE = 'respiratoryRate'

// LEGEND ITEMS
const bloodPressureLegend = [
  { title: 'Systolic', color: '#7fff00' },
  { title: 'Diastolic', color: '#ff9924' },
]

const sobLegend = [
  { title: 'No Response', color: '#b3b3b3' },
  { title: 'No Shortness Of Breath', color: '#7fff00' },
  { title: 'With Marked Exertion', color: '#ffcc00' },
  { title: 'With Mild Exertion', color: '#ff7f00' },
  { title: 'At Rest', color: '#d32f2f' },
]

class VitalsChartBase extends React.Component {
  state = {
    chip: PPS_SCORE,
    datapoint: null,
    selectedMark: null,
    series: 'series1',
  }

  setChip = chip => () => this.setState({ chip, datapoint: null })

  toggleValue = (series, data) => {
    this.setState(prevState => ({
      datapoint: isEqual(data, prevState.datapoint) ? null : data,
      selectedMark: isEqual(data.id, prevState.selectedMark) ? null : data.id,
      series,
    }))
  }

  calculateYDomain = chip => {
    switch (chip) {
      case PPS_SCORE:
        return [0, 100]
      case SEVERITY_CURRENT_PAIN:
        return [0, 10]
      case ASSOCIATED_ACTIVITY_SOB:
        return [1, 5]
      default:
        return null
    }
  }

  createHint = datapoint => (
    <div
      className={this.props.classes.hintContainer}
      style={{
        backgroundColor:
          this.state.series === 'series1' ? '#4d9900' : '#cc6d00',
      }}
    >
      <Typography className={this.props.classes.hintText}>
        <strong>Value:</strong> {datapoint.y}
      </Typography>
      <Typography className={this.props.classes.hintText}>
        <strong>Date:</strong> {datapoint.x}
      </Typography>
      <span
        className={this.props.classes.hintPointer}
        style={{
          borderTopColor:
            this.state.series === 'series1' ? '#4d9900' : '#cc6d00',
        }}
      />
    </div>
  )

  renderChart = ({ chip, vitals }) => {
    const {
      classes,
      theme: { palette },
    } = this.props
    const { datapoint, selectedMark } = this.state

    const blackOrWhite =
      palette.type === 'light' ? palette.common.black : palette.common.white

    const bloodPressureChip = [SYSTOLIC, DIASTOLIC]
    const chips = chip === BLOOD_PRESSURE ? bloodPressureChip : [chip]
    const [vitalsData1, vitalsData2] = chips.map(chip =>
      createGraphData({ vitals, chip })
    )

    const haveData = !isEmpty(vitalsData1)

    return haveData ? (
      <div className={classes.chartContainer}>
        <FlexibleXYPlot
          xType="ordinal"
          color="#000000"
          className={classes.chart}
          yDomain={this.calculateYDomain(chip)}
          height={250}
        >
          <XAxis
            style={{
              ticks: {
                stroke: blackOrWhite,
              },
              text: {
                stroke: 'none',
                fill: blackOrWhite,
                fontWeight: 400,
              },
            }}
          />
          {chip !== ASSOCIATED_ACTIVITY_SOB && (
            <YAxis
              style={{
                ticks: {
                  stroke: blackOrWhite,
                },
                text: {
                  stroke: 'none',
                  fill: blackOrWhite,
                  fontWeight: 400,
                },
              }}
            />
          )}
          <VerticalGridLines style={{ stroke: palette.grey[500] }} />
          <HorizontalGridLines style={{ stroke: palette.grey[500] }} />

          {chip === ASSOCIATED_ACTIVITY_SOB ? (
            <LineMarkSeries
              colorType="literal"
              data={vitalsData1}
              sizeType="literal"
            />
          ) : (
            <LineMarkSeries
              colorType="literal"
              data={vitalsData1}
              getColor={d => (d.id === selectedMark ? '#336600' : '#7fff00')}
              lineStyle={{ stroke: '#7fff00' }}
              markStyle={{ stroke: '#59b300', strokeWidth: '3px' }}
              onValueClick={data => this.toggleValue('series1', data)}
              style={{ cursor: 'pointer' }}
            />
          )}
          {vitalsData2 && (
            <LineMarkSeries
              colorType="literal"
              data={vitalsData2}
              getColor={d => (d.id === selectedMark ? '#995200' : '#ff9924')}
              lineStyle={{ stroke: '#ff9924' }}
              markStyle={{ stroke: '#e67a00', strokeWidth: '3px' }}
              onValueClick={data => this.toggleValue('series2', data)}
              style={{ cursor: 'pointer' }}
            />
          )}
          {datapoint && (
            <Hint
              align={{ horizontal: 'left', vertical: 'top' }}
              value={datapoint}
            >
              {this.createHint(datapoint)}
            </Hint>
          )}
        </FlexibleXYPlot>

        {chip === BLOOD_PRESSURE && (
          <div className={classes.legendContainer}>
            <div className={classes.legendBackground}>
              <DiscreteColorLegend
                orientation="horizontal"
                items={bloodPressureLegend}
                className={cx(classes.chart, classes.legend)}
              />
            </div>
          </div>
        )}
        {chip === ASSOCIATED_ACTIVITY_SOB && (
          <div className={classes.legendContainer}>
            <div className={classes.legendBackground}>
              <DiscreteColorLegend
                orientation="horizontal"
                items={sobLegend}
                className={cx(classes.chart, classes.legend)}
              />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className={classes.noData}>
        <Typography variant="h5">No Data Available</Typography>
      </div>
    )
  }

  render() {
    const { classes, vitals, patientBrand } = this.props
    const { chip } = this.state

    return (
      <CardContent className={classes.vitalsContent}>
        <div className={classes.chartBlock}>
          {this.renderChart({ chip, vitals })}
        </div>
        <div className={classes.chipGroup}>
          <div className={classes.chipContainer}>
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === PPS_SCORE,
              })}
              label="PPS"
              onClick={this.setChip(PPS_SCORE)}
            />
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === WEIGHT,
              })}
              label="Weight"
              onClick={this.setChip(WEIGHT)}
            />
            {patientBrand === 'caremore' && (
              <>
                <Chip
                  className={cx(classes.chip, {
                    [classes.chipChosen]: chip === HEIGHT,
                  })}
                  label="Height"
                  onClick={this.setChip(HEIGHT)}
                />
                <Chip
                  className={cx(classes.chip, {
                    [classes.chipChosen]: chip === RESPIRATORY_RATE,
                  })}
                  label="Respiratory Rate"
                  onClick={this.setChip(RESPIRATORY_RATE)}
                />
              </>
            )}
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === BMI,
              })}
              label="BMI"
              onClick={this.setChip(BMI)}
            />
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === PULSE_OXIMETRY_SCORE,
              })}
              label="Pulse Oximetry"
              onClick={this.setChip(PULSE_OXIMETRY_SCORE)}
            />
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === PULSE,
              })}
              label="Pulse"
              onClick={this.setChip(PULSE)}
            />
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === ASSOCIATED_ACTIVITY_SOB,
              })}
              label="Shortness of Breath"
              onClick={this.setChip(ASSOCIATED_ACTIVITY_SOB)}
            />
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === SEVERITY_CURRENT_PAIN,
              })}
              label="Current Pain"
              onClick={this.setChip(SEVERITY_CURRENT_PAIN)}
            />
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === BLOOD_PRESSURE,
              })}
              label="Blood Pressure"
              onClick={this.setChip(BLOOD_PRESSURE)}
            />
            <Chip
              className={cx(classes.chip, {
                [classes.chipChosen]: chip === MID_ARM_CIRCUMFERENCE,
              })}
              label="Mid-Arm Circumference"
              onClick={this.setChip(MID_ARM_CIRCUMFERENCE)}
            />
          </div>
        </div>
      </CardContent>
    )
  }
}

VitalsChartBase.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object.isRequired,
  vitals: PropTypes.mapOf(PropTypes.instanceOf(Vitals)).isRequired,
  chart: PropTypes.instanceOf(ChartState).isRequired,
  patientBrand: PropTypes.string,
}

export default VitalsChartBase
