import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const ENCOUNTER_CODE_LIST = 'encounterCodeList'
// ACTIONS
export const ENCOUNTER_CODE_LIST_CLEARED = type(
  key,
  'ENCOUNTER_CODE_LIST_CLEARED'
)

// ACTION CREATORS
export const encounterCodeListCleared = creator(ENCOUNTER_CODE_LIST_CLEARED)

export const Code = Record({
  id: null,
  codeType: null,
  code: null,
  description: null,
  createdByUser: null,
  createdAt: null,
  coderAdded: null,
})

export type CodeType = {
  code: string
  codeType: string
  coderAdded: boolean
  createdAt: string
  createdByUser: {
    timeZone: string
    role: string
    name: string
    id: string
    aspireRole: string
    firstName: string
    lastName: string
  }
  description: string
  id: string
}

const transformCodes = (codes: any) => List(codes.map(Code))

export const fetchEncounterCodes = Request({
  typePrefix: key,
  typeBase: 'FETCH_ENCOUNTER_CODES',
  requestParams: ['encounterId'],
  operation: (encounterId: number) =>
    AspireAPI.get(`coding_dashboard/${encounterId}/codes`),
  transform: transformCodes,
  messages: {
    failed: 'There was an issue fetching encounter info.',
  },
})

export const deleteCode = Request({
  typePrefix: key,
  typeBase: 'DELETE_CODE',
  requestParams: ['codeId'],
  operation: (codeId: string) =>
    AspireAPI.delete(`coding_dashboard/code/${codeId}`),
  messages: { failed: 'There was a problem deleting the encounter code' },
})

export const getEncounterCodes = pipe(getRoot, get(ENCOUNTER_CODE_LIST))

export default createReducer(ENCOUNTER_CODE_LIST, List(), {
  // @ts-expect-error fix me
  [encounterCodeListCleared]: () => List(),
  [fetchEncounterCodes.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
