import { combineEpics, ofType } from 'redux-observable'
import { distinctUntilChanged, map, mapTo, pluck } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { fetchOnCallsForPatient } from '~/features/onCall'
import { fetchPatientTimeline } from '../data/patientEvents'
import { patientIdSet } from '../data/patientId'

const setPatientIdEpic = action$ =>
  action$.pipe(
    ofType(fetchPatientTimeline.REQUESTED),
    pluck('payload', 'patientId'),
    distinctUntilChanged(),
    map(patientIdSet)
  )

const fetchConstantsEpic = action$ =>
  action$.pipe(
    ofType(fetchPatientTimeline.REQUESTED),
    mapTo(fetchDistinctFieldValues(['timeline_event_type']))
  )

const fetchCallsEpic = action$ =>
  action$.pipe(
    ofType(fetchPatientTimeline.REQUESTED),
    pluck('payload', 'patientId'),
    map(fetchOnCallsForPatient.requested)
  )

export default combineEpics(
  setPatientIdEpic,
  fetchConstantsEpic,
  fetchCallsEpic
)
