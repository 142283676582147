import { ofType } from 'redux-observable'
import { merge } from 'rxjs'
import { auditTime, filter, map, withLatestFrom } from 'rxjs/operators'
import { isRequestPending } from '~/data/pending'
import { switchTo } from '~/utils/operators'
import {
  assessmentScratchNotesChanged,
  saveAssessment,
} from '../data/assessments'
import { canEdit, getCurrentAssessment } from '../data/common/derived'
import {
  ASSESSMENT_CANCELLED,
  ASSESSMENT_SIGNED,
  FORM_DATA_CHANGED_BY_TAG,
  SECTION_CHANGED,
} from '../data/common/shared'
import { ASSESSMENT_SAVE_BUTTON_CLICKED } from '../data/saveInfo'

const AUTO_SAVE_DELAY = 30000

const changeActions = [
  SECTION_CHANGED,
  FORM_DATA_CHANGED_BY_TAG,
  assessmentScratchNotesChanged,
]
const cancelActions = [
  ASSESSMENT_SIGNED,
  ASSESSMENT_CANCELLED,
  ASSESSMENT_SAVE_BUTTON_CLICKED,
]

export default (action$, state$) => {
  const cancel$ = action$.pipe(ofType(...cancelActions))

  const changeOutsideSave$ = action$.pipe(
    ofType(...changeActions),
    filter(() => !isRequestPending(state$.value, saveAssessment))
  )

  const changeDuringSave$ = action$.pipe(
    ofType(saveAssessment.SUCCEEDED, saveAssessment.FAILED),
    withLatestFrom(
      action$.pipe(ofType(...changeActions, saveAssessment.REQUESTED))
    ),
    map(([, action]) => action),
    filter(({ type }) => changeActions.includes(type))
  )

  return merge(cancel$, changeOutsideSave$, changeDuringSave$).pipe(
    filter(() => canEdit(state$.value)),
    auditTime(AUTO_SAVE_DELAY),
    ofType(...changeActions),
    switchTo(state$),
    map(getCurrentAssessment),
    filter(({ id }) => Boolean(id)),
    map(saveAssessment.requested)
  )
}
