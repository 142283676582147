import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { mapTo } from 'rxjs/operators'
import { completePatientOutlierFinalDecision } from '../data/currentPatientOutlierFinalDecision'
import { PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS } from '../router'

export default (action$: any) =>
  action$.pipe(
    ofType(completePatientOutlierFinalDecision.SUCCEEDED),
    mapTo(push(PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS))
  )
