import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog, InfoDialog } from '~/components/dialogs'
import { getPatientEpisodesOfCare } from '~/features/episodesOfCare/data'
import { getHospitalizations } from '~/features/hospitalizations/data/hospitalizations' // avoid circular dependencies
import { getLTCs } from '~/features/ltcs/data/ltcs' // avoid circular dependencies
import { getPatientInfo } from '~/features/patientInfo'
import { getSNFs } from '~/features/snfs/data/snfs' // avoid circular dependencies
import Typography from '@material-ui/core/Typography'

const HasOpenAdmitsDialog = props => {
  const hospitalizations = useSelector(getHospitalizations)
  const snfs = useSelector(getSNFs)
  const ltcs = useSelector(getLTCs)
  const patientInfo = useSelector(getPatientInfo)
  const episodeOfCares = useSelector(state =>
    getPatientEpisodesOfCare(state, patientInfo.id)
  )

  const hasOpenAdmitBool = useMemo(() => {
    const admitsArray = hospitalizations
      .toArray()
      .concat(snfs.toArray(), ltcs.toArray())

    return admitsArray.some(
      h => h.dischargeDate === null || h.dischargeDate === ''
    )
  }, [hospitalizations, snfs, ltcs])

  const hasOpenEpisodeOfCareBool = useMemo(() => {
    return episodeOfCares.some(
      h => h.status !== 'closed' && h.status !== 'cancelled'
    )
  }, [episodeOfCares])

  if (props.open && props.mode !== 'save') {
    if (
      props.name &&
      props.name == 'createEpisodeOfCare' &&
      hasOpenEpisodeOfCareBool
    ) {
      return (
        <InfoDialog open={props.open} onClose={props.onClose} title="EOC Alert">
          <Typography>
            Patient already has an open EOC. There cannot be two open EOCs at
            the same time.
          </Typography>
        </InfoDialog>
      )
    } else if (hasOpenAdmitBool) {
      return (
        <InfoDialog
          open={props.open}
          onClose={props.onClose}
          title="Admit Alert"
        >
          <Typography>
            This patient already has an open hospitalization, SNF, or LTC
            record. Please either update the existing admit record or close it
            before creating a new record.
          </Typography>
        </InfoDialog>
      )
    } else {
      return <ActionDialog {...props} />
    }
  } else {
    return <ActionDialog {...props} />
  }
}

export default HasOpenAdmitsDialog
