import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { DestinationTypeResults } from './interfaces'

// KEY
const key = 'destinationType'

export const DestinationType = Record({
  type: null,
  id: null,
})

// TRANSFORMER
const mapDestnTypeResults = (results: [DestinationTypeResults]) =>
  List(
    results?.map(destType =>
      DestinationType({
        type: destType?.type,
        id: destType?.id,
      })
    )
  )

// REQUEST
export const fetchDestinationType = Request({
  typePrefix: key,
  typeBase: 'FETCH_DESTINATION_TYPE',
  operation: () => AspireAPI.get('transportation/lookup/destination_type'),
  transform: mapDestnTypeResults,
  messages: { failed: 'Failed to fetch destination type ' },
})

// REDUCER
export const destinationType = createReducer(key, List(), {
  [fetchDestinationType.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

// SELECTORS
export const getDestinationType = pipe(getRoot, get(key))
