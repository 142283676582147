import { Route, Router } from 'redux-routable'
import key from './key'

export const REPORTS_DASHBOARD_HOME = `${key}.home`
export const REPORTS_DASHBOARD_REPORT = `${key}.report`

export default Router([
  Route(REPORTS_DASHBOARD_HOME),
  Route(REPORTS_DASHBOARD_REPORT, '/:reportId'),
])
