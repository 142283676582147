import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import BaseInfo from '../../components/Info'

const Info = props => (
  <React.Fragment>
    <Hidden lgUp>
      <BaseInfo labelPosition="top" {...props} />
    </Hidden>
    <Hidden mdDown>
      <BaseInfo valueFlex="2" {...props} />
    </Hidden>
  </React.Fragment>
)

export default Info
