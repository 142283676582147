import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { withTheme } from '@material-ui/core/styles'
import Marker from '../Marker'
import createIcon from './createIcon'
import createLabel from './createLabel'

const CheckMarker = ({ theme, ...props }) => {
  const { color, scale, label } = props

  const icon = useMemo(
    () => CheckMarker.renderIcon(theme, color, scale, label),
    [theme, color, scale, label]
  )

  return <Marker {...props} icon={icon} />
}

CheckMarker.renderIcon = (theme, color, scale, label) =>
  createIcon({
    scale,
    size: label ? { height: 24, width: 24 } : { height: 16, width: 16 },
    icon: label
      ? `
    <circle stroke="${
      theme.palette.map.markers.stroke
    }" stroke-width="2" cx="12" cy="12" r="10"/>
    <circle fill="${color}" cx="12" cy="12" r="10"/>
    <polyline fill="none" stroke="${
      theme.palette.map.markers.stroke
    }" stroke-width="2" points="7.5,15 12,19.5 22.5,9"/>
    ${createLabel(label, 11, 9)}`
      : `
    <circle stroke="${theme.palette.map.markers.stroke}" stroke-width="2" cx="8" cy="8" r="7"/>
    <circle fill="${color}" cx="8" cy="8" r="7"/>
    <polyline fill="none" stroke="${theme.palette.map.markers.stroke}" stroke-width="2" points="5,10 8,13 15,6"/>`,
  })

CheckMarker.propTypes = {
  theme: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  scale: PropTypes.number,
}

CheckMarker.defaultProps = {
  label: '',
  scale: 1,
}

export default withTheme(CheckMarker)
