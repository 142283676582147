import { Map, Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/re... Remove this comment to see the full error message
import OrcAPI from '~/resources/orc'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// RECORDS
export const Patient = Record({
  birthDate: null,
  contactId: null,
  firstName: null,
  id: null,
  lastName: null,
  name: null,
  phone: null,
  sex: null,
  sfid: null,
  sourceMemberId: null,
  status: null,
  subStatus: null,
})

// CONSTANTS
export const PATIENT_STORED = 'patients/PATIENT_STORED'
export const PATIENTS_STORED = 'patients/PATIENTS_STORED'
export const PATIENT_UPDATED = 'patients/PATIENT_UPDATED'
export const PATIENT_DELETED = 'patients/PATIENT_DELETED'
export const PATIENT_FETCH_FAILED = 'patients/PATIENT_FETCH_FAILED'

// ACTION CREATORS
export const patientStored = (patient: any) => ({
  type: PATIENT_STORED,
  patient,
})
export const patientsStored = (patients: any) => ({
  type: PATIENTS_STORED,
  patients,
})
export const patientUpdated = (patientId: any, patient: any) => ({
  type: PATIENT_UPDATED,
  patientId,
  patient,
})
export const patientDeleted = (patientId: any) => ({
  type: PATIENT_DELETED,
  patientId,
})
export const patientFetchFailed = () => ({ type: PATIENT_FETCH_FAILED })

// KEY
export const key = 'patients'

// REDUCER
export default createReducer(key, Map(), {
  [PATIENT_STORED]: (state: any, { patient }: any) =>
    state.set(patient.id, patient),
  [PATIENTS_STORED]: (state: any, { patients }: any) =>
    state.mergeDeep(patients),
  [PATIENT_UPDATED]: (state: any, { patientId, patient }: any) =>
    state.update(patientId, (existingPatient: any) =>
      existingPatient.mergeDeep(patient)
    ),
  [PATIENT_DELETED]: (state: any, { patientId }: any) =>
    state.delete(patientId),
})

const toRecord = (data: any) =>
  Patient({
    birthDate: data.birthDate,
    contactId: data.contactId,
    firstName: data.firstName,
    id: data.globalMemberId,
    lastName: data.lastName,
    name: `${data.firstName} ${data.lastName}`,
    phone: data.phone,
    sex: data.sex,
    sfid: data.sfid,
    sourceMemberId: data.sourceMemberId,
    status: data.status,
    subStatus: data.subStatus,
  })

const mapData = (data: any) =>
  Map(data.map((d: any) => [d.globalMemberId, toRecord(d)]))

// API
export const fetchMergePatients = () =>
  OrcAPI.get('admissions/merge/demographics').then(mapData)

export const fetchPatient = (contactId: any) =>
  OrcAPI.get(`admissions/demographics/${contactId}`).then(toRecord)

// SELECTORS
export const getAllPatients = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
export const getPatientById = (state: any, id: any) =>
  getAllPatients(state).get(id)
