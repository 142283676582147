import React from 'react'
import { useSelector } from 'react-redux'
import { utcToLocal } from '~/utils/dates'
import PropTypes from '~/utils/propTypes'
import { Icon, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getTimeZone } from '../../data/calendar'
import { eventTypeInfo } from '../EventTypes/consts'

const DATE_FORMAT = 'h:mma'
const CLOSED_STATUSES = ['closed', 'pending_md_review', 'assessment_signed']

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    height: '100%',
  },
  event: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ({ event }) => {
      const colors = eventTypeInfo[event.type].colors
      return colors.background
    },
    color: ({ event }) => eventTypeInfo[event.type].colors.text,
    fontSize: '0.75em',
    border: 'none',
    borderRadius: 2,
    height: '100%',
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 1px 4px',
  },
  header: {
    alignItems: 'center',
    backgroundColor: ({ event }) =>
      event.recordType === 'unavailability'
        ? palette.common.black
        : palette.shades.darker,
    display: 'flex',
    fontWeight: 'bold',
    minHeight: ({ view }) => (view === 'month' ? '2.0em' : '2.5em'),
    padding: [5, 8, 4],
    textTransform: 'uppercase',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  summary: {
    flex: 1,
    lineHeight: 1.4,
    padding: spacing(1),
  },
  travelNotes: {
    margin: [spacing(1), 0],
  },
  spacer: {
    flex: 1,
  },
  icons: {
    display: 'flex',
    '& span': {
      fontSize: '1.05rem',
      marginLeft: 4,
    },
  },
}))

const CalendarEvent = props => {
  const { event, maxVisits = null, title, expanded } = props

  const classes = useStyles(props)

  const timeZone = useSelector(getTimeZone)

  const start = utcToLocal(event.start, timeZone).format(DATE_FORMAT)
  const end = utcToLocal(event.end, timeZone).format(DATE_FORMAT)
  const patientName = event.patientName
  const recordType = event.recordType
  const eventLength = `${start} - ${end}`
  const eventNotes = event.subject
  const tooltipNotes =
    eventNotes && eventNotes.length > 65
      ? `${eventNotes.slice(0, 64)} ...`
      : eventNotes
  const travelNotes = event.travelNotes

  const isGeocoded = Boolean(location.latitude && location.longitude)
  const isRecurring = Boolean(event.recurrence)
  const isGroup = event.userIds && event.userIds.count() > 1
  const isConfirmed = event.confirmed
  const isCancelled = event.cancelled
  const isUrgent = event.urgent
  const isSigned = CLOSED_STATUSES.includes(event.encounterStatus)

  const isSchedulingGuidance =
    recordType === 'scheduling_guidance' && maxVisits
      ? `Max Visits: ${maxVisits}\n`
      : ''
  const isVisit =
    recordType === 'visit' || recordType === 'visit_placeholder'
      ? `${patientName}\n`
      : ''
  const unavailabilityNotes =
    recordType === 'unavailability' && tooltipNotes ? `\n${tooltipNotes}` : ''

  const tooltip = `${title}\n${isVisit}${isSchedulingGuidance}${eventLength}${unavailabilityNotes}`

  const renderEventIcon = (title, icon) => (
    <Tooltip title={title}>
      <Icon fontSize="small">{icon}</Icon>
    </Tooltip>
  )

  return (
    <div className={classes.container}>
      <div className={classes.event} title={tooltip}>
        <div className={classes.header}>
          <div className={classes.title}>{title}</div>
          <div className={classes.spacer} />

          <div className={classes.icons}>
            {isGeocoded && renderEventIcon('Geocoded', 'location_on')}
            {isRecurring && renderEventIcon('Recurring', 'autorenew')}
            {isGroup && renderEventIcon('Group', 'group')}
            {isConfirmed && renderEventIcon('Confirmed', 'event_available')}
            {isCancelled && renderEventIcon('Cancelled', 'event_busy')}
            {isUrgent && renderEventIcon('Urgent', 'priority_high')}
            {isSigned && renderEventIcon('Assessment Signed', 'edit')}
          </div>
        </div>

        {expanded && !event.allDay && (
          <div className={classes.summary}>
            {patientName && <div>{patientName}</div>}
            {eventLength && <div>{eventLength}</div>}
            {maxVisits && <div>Max Visits: {maxVisits}</div>}
            {eventNotes && <div>{eventNotes}</div>}
            {travelNotes && (
              <div className={classes.travelNotes}>
                Travel Notes: {travelNotes}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

CalendarEvent.propTypes = {
  event: PropTypes.record,
  expanded: PropTypes.bool,
  maxVisits: PropTypes.number,
  title: PropTypes.node,
}

export default CalendarEvent
