import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import { UNMERGE_SEARCHED } from '../data/admissionsReconciliation'
import {
  fetchUnmergeOdsAdmissions,
  odsAdmissionFetchFailed,
  odsAdmissionsStored,
} from '../data/odsAdmissions'
import {
  fetchPatient,
  patientFetchFailed,
  patientStored,
} from '../data/patients'
import { unmergeContactIdSet } from '../data/unmergeContactId'
import { unmergeOdsAdmissionIdsSet } from '../data/unmergeOdsAdmissionIds'
const fetchAndStoreUnmergePatient = (contactId: any) =>
  from(fetchPatient(contactId)).pipe(
    map(patientStored),
    catchError(e => {
      telemetry.error(e)
      return of(patientFetchFailed())
    })
  )
const fetchAndStoreUnmergeOdsAdmissions = (contactId: any) =>
  from(fetchUnmergeOdsAdmissions(contactId)).pipe(
    mergeMap(odsAdmissions => {
      const keys = (odsAdmissions as any).keySeq().toSetSeq()
      return [
        unmergeOdsAdmissionIdsSet(keys),
        odsAdmissionsStored(odsAdmissions),
      ]
    }),
    catchError(e => {
      telemetry.error(e)
      return of(odsAdmissionFetchFailed())
    })
  )
export default (action$: any) =>
  action$.pipe(
    ofType(UNMERGE_SEARCHED),
    mergeMap(({ contactId }) =>
      concat(
        of(unmergeContactIdSet(contactId)),
        fetchAndStoreUnmergePatient(contactId),
        fetchAndStoreUnmergeOdsAdmissions(contactId)
      )
    )
  )
