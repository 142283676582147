import { Record } from 'immutable'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { FormT } from './generateDocumentForm'
import key from '../key'

export const GENERATE_DOCUMENT_FORM = 'generateDocument'

const Dialog = Record({
  dialogType: null,
  patientId: null,
  form: null,
})

export type DialogRecord = {
  dialogType: string
  patientId: string
  form: FormT
  defaultForms: { [key: string]: FormT }
  get: (arg: string) => any
  getIn: (arg: string[]) => any
  [key: string]: any
}

export type DialogT = {
  dialogType: string
  patientId: string
  form: FormT
  defaultForms: { [key: string]: FormT }
  [key: string]: any
}

export const creator = scopedCreator(key)

export const generateDocumentOpened: any = creator('GENERATE_DOCUMENT_OPENED', [
  'dialogType',
  'patientId',
])
export const generateDocumentClosed: any = creator('GENERATE_DOCUMENT_CLOSED')

export const generateDocumentReducer = createReducer(key, Dialog(), {
  [generateDocumentOpened]: (_state: any, { payload }: any) => payload,
  [generateDocumentClosed]: () => Dialog(),
})

export const getDialog: any = get(key)
