import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { formatDateTimeWithTimeZone } from '~/utils/format'
import rootKey from '../key'

// KEY
const key = 'tripFiles'

export const getTripFileDetails = getIn([rootKey, key])

// TRANSFORMER
const transformData = (files: any, index: any, recordType: any) => {
  const currValue =
    files[index][`${recordType}`] || files[index][`${recordType}`] === 0
      ? files[index][`${recordType}`]
      : ''
  const prevValue =
    index > 0
      ? files[index - 1][`${recordType}`] ||
        files[index - 1][`${recordType}`] === 0
        ? files[index - 1][`${recordType}`]
        : ''
      : ''

  const flag = index > 0 ? (prevValue === currValue ? false : true) : false
  return {
    flag: flag,
    value: currValue || currValue === 0 ? currValue : ' ',
    field: recordType,
  }
}

export const TripFileDetail = Record({
  passengerType: null,
  receiptDate: null,
  destinationState: null,
  tripType: null,
  fileName: null,
  accountNumber: null,
  lagTime: null,
  fileNumber: null,
  sentCount: null,
  createdDate: null,
  tripId: null,
  numberOfEscorts: null,
  passengerFirstName: null,
  passengerLastName: null,
  pickupDate: null,
  pickupPhoneNumber: null,
  pickupPhoneExtension: null,
  pickupStreetAddress: null,
  pickupCity: null,
  pickupState: null,
  pickupZipcode: null,
  pickupComment: null,
  appointmentDate: null,
  destinationPhoneNumber: null,
  destinationPhoneExtension: null,
  destinationStreetAddress: null,
  destinationCity: null,
  destinationZipcode: null,
  destinationComment: null,
  cancelled: null,
  copay: null,
  dialysis: null,
  generalTripComments: null,
  carrierCode: null,
  createdBy: null,
  requestedBy: null,
  passengerId: null,
  willReturnCall: null,
})

export const transformTripFiles = (files: any) => {
  return Array.isArray(files)
    ? List(
        files.map((file, index) => {
          return TripFileDetail({
            passengerType: transformData(files, index, 'passengerType'),
            receiptDate: file?.receiptDate
              ? formatDateTimeWithTimeZone(file?.receiptDate)
              : '',
            fileName: file?.fileName,
            accountNumber: transformData(files, index, 'accountNumber'),
            fileNumber: file?.fileNumber,
            sentCount: file?.isSent ? '1' : '0',
            createdDate: file?.createdAt
              ? formatDateTimeWithTimeZone(file?.createdAt)
              : '',
            tripId: file?.tripId,
            tripType: transformData(files, index, 'tripType'),
            numberOfEscorts: transformData(files, index, 'numberOfEscorts'),
            passengerFirstName: file?.passengerFirstName,
            passengerLastName: file?.passengerLastName,
            pickupDate: transformData(files, index, 'pickupDate'),
            pickupPhoneNumber: transformData(files, index, 'pickupPhoneNumber'),
            pickupPhoneExtension: transformData(
              files,
              index,
              'pickupPhoneExtension'
            ),
            pickupStreetAddress: transformData(
              files,
              index,
              'pickupStreetAddress'
            ),
            pickupCity: transformData(files, index, 'pickupCity'),
            pickupState: transformData(files, index, 'pickupState'),
            pickupZipcode: transformData(files, index, 'pickupZipcode'),
            pickupComment: transformData(files, index, 'pickupComment'),
            appointmentDate: transformData(files, index, 'appointmentDate'),
            destinationPhoneNumber: transformData(
              files,
              index,
              'destinationPhoneNumber'
            ),
            destinationPhoneExtension: transformData(
              files,
              index,
              'destinationPhoneExtension'
            ),
            destinationStreetAddress: transformData(
              files,
              index,
              'destinationStreetAddress'
            ),
            destinationCity: transformData(files, index, 'destinationCity'),
            destinationState: transformData(files, index, 'destinationState'),
            destinationZipcode: transformData(
              files,
              index,
              'destinationZipcode'
            ),
            destinationComment: '',
            cancelled: transformData(files, index, 'cancelled'),
            copay: file?.copay,
            dialysis: transformData(files, index, 'dialysis'),
            generalTripComments: transformData(
              files,
              index,
              'generalTripComments'
            ),
            carrierCode: transformData(files, index, 'carrierCode'),
            createdBy: transformData(files, index, 'createdBy'),
            lagTime: '',
            requestedBy: transformData(files, index, 'requestedBy'),
            passengerId: file?.passengerId,
            willReturnCall: transformData(files, index, 'willReturnCall'),
          })
        })
      )
    : List()
}

// REQUEST

export const fetchTripFiles = Request({
  typePrefix: key,
  typeBase: 'GET_TRIP_FILES',
  requestParams: ['tripId'],
  operation: (tripId: string) =>
    AspireAPI.get(`transportation/trip_files/${tripId}`),
  transform: transformTripFiles,
  messages: {
    failed: 'Could not get trip files',
  },
})

// REDUCER

export const tripFiles = createReducer(key, List(), {
  [fetchTripFiles.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
