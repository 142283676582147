import { fromJS } from 'immutable'
import React from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { useAction } from '~/hooks'
import { Divider, createStyles, makeStyles } from '@material-ui/core'
import ImoProblem from '../components/imoProblem'
import ImoProblemList from '../components/imoProblemList'
import ImoSearchBar from '../components/imoSearchBar'
import { clearImo } from '../data/imo'
import { getImoProblems } from '../data/utils/selectors'
import { Problem } from '../types/types'

const useStyles = makeStyles(() =>
  createStyles({
    emptyRoot: {
      width: '100%',
      height: 600,
    },
  })
)

interface Props {
  onSubmit: (problem: any) => void
  onClose: () => void
}

const ImoProblemSearch = ({ onSubmit, onClose }: Props) => {
  const classes = useStyles()
  const clearSearch = useAction(clearImo)
  const handleClose = () => {
    onClose()
    clearSearch()
  }

  const problems: Problem[] = useSelector(getImoProblems)
  return (
    <ActionDialog
      open
      title="IMO Search"
      mode="add"
      hideAction={true}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      scroll="paper"
    >
      <ImoSearchBar />
      <Divider />
      <ImoProblemList
        onSubmit={onSubmit}
        problems={problems}
        ImoProblem={ImoProblem}
      />
      {fromJS(problems).size === 0 && (
        <div className={classes.emptyRoot}> </div>
      )}
    </ActionDialog>
  )
}

export default ImoProblemSearch
