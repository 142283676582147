import React from 'react'
import { compose } from 'redux'
import PropTypes from '~/utils/propTypes'
import Card from '@material-ui/core/Card'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { ChartState } from '../data/chart'
import { Vitals } from '../data/vitals'
import {
  VitalsChartBase,
  VitalsTableBase,
  VitalsToolbar,
  vitalsStyles,
} from './VitalsChart'

const styles = vitalsStyles({
  '@media (min-width: 400px)': {
    legend: {
      width: '100%',
    },
  },
  '@media (min-width: 500px)': {
    chipContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    chip: {
      width: '48%',
    },
  },
  '@media (min-width: 750px)': {
    vitalsContent: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
    chartBlock: {
      flex: 4,
      marginRight: 8,
    },
    chipGroup: {
      flex: 1,
      marginRight: '30px',
      maxWidth: 216,
    },
    chip: {
      width: '100%',
    },
  },
})

const VitalsBase = ({
  classes,
  vitals,
  chart,
  chartDisplayToggled,
  chartPageChanged,
  theme,
  patientBrand,
}) => {
  const toolbarActions = { chartDisplayToggled }
  const chartActions = { chartPageChanged }

  return (
    <Card className={classes.card}>
      <div className={classes.header}>
        <VitalsToolbar actions={toolbarActions} chart={chart} vitals={vitals} />
      </div>
      {chart.displayed ? (
        <VitalsChartBase
          vitals={vitals}
          actions={chartActions}
          chart={chart}
          classes={classes}
          theme={theme}
          patientBrand={patientBrand}
        />
      ) : (
        <VitalsTableBase
          vitals={vitals}
          classes={classes}
          patientBrand={patientBrand}
        />
      )}
    </Card>
  )
}

VitalsBase.propTypes = {
  chart: PropTypes.instanceOf(ChartState).isRequired,
  chartDisplayToggled: PropTypes.func.isRequired,
  chartPageChanged: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  vitals: PropTypes.mapOf(PropTypes.instanceOf(Vitals)).isRequired,
  patientBrand: PropTypes.string,
}

export default compose(withTheme, withStyles(styles))(VitalsBase)
