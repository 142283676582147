import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { fetchNextNotificationsRequested } from '../data/common/shared'
import {
  notificationsEmptied,
  offsetSet,
  statusSet,
} from '../data/notificationList'

export default (action$: any) =>
  action$.pipe(
    ofType(statusSet),
    mergeMap(() =>
      of(
        notificationsEmptied(),
        offsetSet(null),
        fetchNextNotificationsRequested()
      )
    )
  )
