import { colors as muiColors } from '@material-ui/core'

export const colors = { text: 'white', background: muiColors.lightBlue['A400'] }

export const label = 'Visit Appointment'

export const layer = 2

export const title = event => {
  const encounterTypeTitle = event.encounterTypeLabel
  const cancelledStr = event.cancelled ? ' (Cancelled)' : ''

  return encounterTypeTitle ? `${encounterTypeTitle} ${cancelledStr}` : label
}

export default { colors, label, layer, title }
