import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import { getPatientId } from '../../data/patientInformation'
import { savePatientMedication } from '../../data/patientRx'
import { rxMedicationSelected } from '../../data/selectedMedication'
import ActionItem from '../ActionItem'

class RowActions extends React.Component {
  state = {
    clicked: false,
  }

  searchByGeneric = () => this.props.searchByGeneric(this.props.medication)
  selectMedication = () => {
    const { id: _id, ...selectedMedication } = this.props.medication
    this.props.selectMedication(selectedMedication)
  }

  quickAddMedication = () => {
    const { id: drugId, ...medication } = this.props.medication
    this.props.quickAddMedication({
      ...medication,
      drugId: drugId,
      status: 'Current',
      patientId: this.props.patientId,
    })
    this.setState({ clicked: true })
  }

  render() {
    return (
      <React.Fragment>
        <ActionItem
          key="addMed"
          toolTip="Add Medication To Patients RX List"
          icon="add_circle_outline"
          onClick={this.selectMedication}
          mdtSFAuth
        />
        <ActionItem
          key="quickAdd"
          toolTip="Quick Add As Current"
          icon={!this.state.clicked ? 'playlist_add' : 'check'}
          onClick={this.quickAddMedication}
          disabled={this.state.clicked}
        />
        <ActionItem
          key="searchByGeneric"
          toolTip="Search By Generic Drug Name"
          icon="search"
          onClick={this.searchByGeneric}
        />
      </React.Fragment>
    )
  }
}

RowActions.propTypes = {
  selectMedication: PropTypes.func,
  searchByGeneric: PropTypes.func.isRequired,
  medication: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  quickAddMedication: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  patientId: getPatientId(state),
})

const mapDispatchToProps = {
  selectMedication: rxMedicationSelected,
  quickAddMedication: savePatientMedication,
}

export default connect(mapStateToProps, mapDispatchToProps)(RowActions)
