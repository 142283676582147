import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { TripNoteDetail } from './interfaces'

// KEY
const TRIPNOTES = 'tripNotesKey'

export const TripNote = Record({
  createdAt: null,
  createdBy: null,
  note: null,
  noteType: null,
})

const creator = scopedCreator(TRIPNOTES)

export const clearTripNotes = creator('CLEAR_TRIP_NOTES')

// TRANSFORMER
const mapTripNotesResults = (results: [TripNoteDetail]) =>
  List(
    results?.map((note: TripNoteDetail) =>
      TripNote({
        createdAt: note?.createdAt,
        createdBy: note?.createdBy,
        note: note?.note,
        noteType: note?.noteType,
      })
    )
  )

// REQUEST
export const fetchTripNotes = Request({
  typePrefix: rootKey,
  typeBase: 'TRIPNOTES',
  requestParams: ['tripId'],
  operation: (tripId: string) =>
    AspireAPI.get(`transportation/trip_notes/${tripId}`),
  transform: mapTripNotesResults,
  messages: { failed: 'There was an issue fetching Trip Notes' },
})

// REDUCER
export const tripNoteDetail = createReducer(TRIPNOTES, List(), {
  [fetchTripNotes.SUCCEEDED]: compose(payload, action),
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearTripNotes]: () => List(),
})

// SELECTORS
export const getTripNotes = pipe(getRoot, get(TRIPNOTES))
