import { ofType } from 'redux-observable'
import { empty, merge, of } from 'rxjs'
import { mergeMap, pluck, withLatestFrom } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getRoute } from '~/data/route'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchDailyEvents } from '~/features/encounterWidget'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchOnCallsForUserId } from '~/features/onCall'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerOrdersFetchRequested } from '~/features/orders/data/loaders'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { userIdChanged as ordersUserIdChanged } from '~/features/orders/data/userId'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchSelectedAPPs } from '~/features/scheduling/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
import { censusRefreshRequested, dataCleared } from '../data/common/shared'
import { userIdSelected } from '../data/currentUserId'
import { fetchEncounterReviews } from '../data/encounterReviews'
import { fetchPatientOutlierFinalDecisions } from '../data/patientOutlierFinalDecisions'
import { fetchPatientOutlierReviews } from '../data/patientOutlierReviews'
import { PHYSICIAN_DASHBOARD_CENSUS } from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(userIdSelected),
    pluck('payload', 'userId'),
    withLatestFrom(state$),
    mergeMap(([userId, state]) => {
      // Prevents a large load when not necessary
      const optionalCensusFetch =
        getRoute(state) == PHYSICIAN_DASHBOARD_CENSUS
          ? of(censusRefreshRequested())
          : empty()

      return merge(
        of(
          dataCleared(),
          ordersUserIdChanged(userId),
          ownerTasksFetchRequested(userId),
          ownerOrdersFetchRequested(userId),
          fetchEncounterReviews.requested(userId),
          fetchEpisodesOfCare.requested({ ownerId: userId }),
          fetchOnCallsForUserId.requested(userId),
          fetchPatientOutlierReviews.requested(userId),
          fetchPatientOutlierFinalDecisions.requested(userId),
          fetchSelectedAPPs.requested(userId)
        ),
        fetchDailyEvents(userId),
        optionalCensusFetch
      )
    })
  )
