import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mapTo, mergeMap, pluck } from 'rxjs/operators'
import { getPatientId } from '~/apps/patientRecord/data/patientId'
import {
  CONTACT_INFO_SAVE_REQUESTED,
  getPatientInfo,
  patientInfoStored,
  saveContactInfo,
} from '../data/patient'
import diffRecord from '../utils/diffRecord'

export default (action$, state$) =>
  action$.pipe(
    ofType(CONTACT_INFO_SAVE_REQUESTED),
    pluck('payload', 'contactInfo'),
    mergeMap(contactInfo => {
      const patientId = getPatientId(state$.value)
      const oldPatientInfo = getPatientInfo(state$.value)
      const changes = diffRecord(oldPatientInfo, contactInfo)

      return from(saveContactInfo(patientId, changes)).pipe(
        mapTo(patientInfoStored(contactInfo))
      )
    })
  )
