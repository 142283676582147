import { exited } from 'redux-routable'
import { filter, mapTo } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { callLogCleared } from '~/features/callLogger'
import { referralSchedulingRouter } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(exited(referralSchedulingRouter)),
    mapTo(callLogCleared())
  )
