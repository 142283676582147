import { AsOpaque, AType, EType, summon } from '~/utils/type'

export const User_ = summon(F =>
  F.interface(
    {
      id: F.string(), // TODO: use newtype-ts
      timeZone: F.string(),
      role: F.nullable(F.string()),
      phone: F.nullable(F.string()),
      name: F.string(),
      market: F.nullable(F.string()),
      email: F.string(),
      aspireRole: F.nullable(F.string()),
      active: F.boolean(),
    },
    'User'
  )
)
// export type User = typeof User
export interface User extends AType<typeof User_> {}
export interface UserRaw extends EType<typeof User_> {}
export const User = AsOpaque<UserRaw, User>()(User_)
