import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const VENDORS = 'vendors'

export const Vendor = Record({
  id: null,
  city: null,
  county: null,
  fax: null,
  name: null,
  notes: null,
  phone: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  state: null,
  street: null,
})

export const fetchVendors = Request({
  typePrefix: key,
  typeBase: 'FETCH_VENDORS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/vendors`, {
      params: { searchWord },
    }),
  transform: into(Vendor, 'id'),
  messages: { failed: 'There was a problem fetching vendors' },
})

export const fetchVendor = Request({
  typePrefix: key,
  typeBase: 'FETCH_VENDOR',
  requestParams: ['vendorId'],
  operation: vendorId => AspireAPI.get(`contacts/vendors/${vendorId}`),
  transform: Vendor,
  messages: { failed: 'There was a problem fetching this vendor' },
})

export const addVendor = Request({
  typePrefix: key,
  typeBase: 'ADD_VENDOR',
  requestParams: ['vendor'],
  operation: vendor => AspireAPI.post('contacts/vendors', { vendor }),
  messages: {
    succeeded: 'Successfully added new vendor',
    failed: 'There was a problem adding this vendor',
  },
})

export const saveVendor = Request({
  typePrefix: key,
  typeBase: 'SAVE_VENDOR',
  requestParams: ['vendor'],
  operation: vendor =>
    AspireAPI.put(`contacts/vendors/${vendor.id}`, { vendor }),
  messages: {
    succeeded: 'Successfully saved vendor',
    failed: 'There was a problem saving this vendor',
  },
})

export const deleteVendor = Request({
  typePrefix: key,
  typeBase: 'DELETE_VENDOR',
  requestParams: ['vendorId'],
  operation: vendorId => AspireAPI.delete(`contacts/vendors/${vendorId}`),
  messages: {
    succeeded: 'Successfully deleted vendor',
    failed: 'There was a problem deleting this vendor',
  },
})

const creator = scopedCreator(key)
export const vendorsCleared = creator('VENDORS_CLEARED')

const initState = Map()
export default createReducer(VENDORS, initState, {
  [fetchVendors.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchVendor.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [saveVendor.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { vendor },
        },
      },
    }
  ) => state.set(vendor.id, vendor),
  [deleteVendor.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { vendorId },
        },
      },
    }
  ) => state.delete(vendorId),
  [vendorsCleared]: () => initState,
})

export const getVendors = pipe(getRoot, get(VENDORS))

export const getVendorsArr = createSelector([getVendors], vendors =>
  vendors.toIndexedSeq().toArray()
)

export const getVendorById = (state, id) => getVendors(state).get(id, Vendor())
