import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const CLINICAL_DIRECTOR_DASHBOARD_HOME = `${key}.home`
export const CLINICAL_DIRECTOR_DASHBOARD_OPEN_ASSESSMENTS = `${key}.openAssessments`
export const CLINICAL_DIRECTOR_DASHBOARD_ON_CALL = `${key}.onCall`
export const CLINICAL_DIRECTOR_DASHBOARD_CALENDAR = `${key}.calendar`
export const CLINICAL_DIRECTOR_DASHBOARD_CENSUS = `${key}.census`
export const CLINICAL_DIRECTOR_DASHBOARD_HIGH_RISK_HUDDLE = `${key}.highRiskHuddle`
export const CLINICAL_DIRECTOR_DASHBOARD_MY_LISTS = `${key}.myLists`
export const CLINICAL_DIRECTOR_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const CLINICAL_DIRECTOR_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const CLINICAL_DIRECTOR_DASHBOARD_PROVIDERS = `${key}.providers`
export const CLINICAL_DIRECTOR_DASHBOARD_MTM = `${key}.mtm`

export const CLINICAL_DIRECTOR_DASHBOARD_TASKS_ROUTES = [
  CLINICAL_DIRECTOR_DASHBOARD_TASKS_BY_USER,
  CLINICAL_DIRECTOR_DASHBOARD_TASK_DETAIL,
]

export default Router([
  Route(CLINICAL_DIRECTOR_DASHBOARD_HOME, '/:cdId?/home'),
  Route(
    CLINICAL_DIRECTOR_DASHBOARD_OPEN_ASSESSMENTS,
    '/:cdId?/home/assessments'
  ),
  Route(CLINICAL_DIRECTOR_DASHBOARD_ON_CALL, '/:cdId?/home/on-call'),
  Route(CLINICAL_DIRECTOR_DASHBOARD_CALENDAR, '/:cdId?/calendar'),
  Route(CLINICAL_DIRECTOR_DASHBOARD_CENSUS, '/:cdId?/census'),
  Route(CLINICAL_DIRECTOR_DASHBOARD_MTM, '/:cdId?/mtm'),
  Route(
    CLINICAL_DIRECTOR_DASHBOARD_HIGH_RISK_HUDDLE,
    '/:cdId?/high-risk-huddle'
  ),
  Route(CLINICAL_DIRECTOR_DASHBOARD_MY_LISTS, '/:cdId?/my-lists'),
  Route(CLINICAL_DIRECTOR_DASHBOARD_TASKS_BY_USER, '/:cdId?/tasks'),
  Route(CLINICAL_DIRECTOR_DASHBOARD_TASK_DETAIL, '/:cdId?/tasks/:taskId'),
  Route(CLINICAL_DIRECTOR_DASHBOARD_PROVIDERS, '/:cdId?/providers'),
  Redirect(CLINICAL_DIRECTOR_DASHBOARD_HOME),
])
