import classnames from 'classnames'
import { parseISO } from 'date-fns'
import { OrderedMap } from 'immutable'
import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  DateField,
  SelectField,
  TextField,
  renderFieldValueOptions,
} from '~/components/fields'
import { actions, objects } from '~/features/authorization'
import { getHasPermission } from '~/features/authorization/data/permissions'
import {
  saveDischarge,
  saveDischargeRecommendation,
} from '~/features/discharges/data'
import {
  useAction,
  useInput,
  useMountEffect,
  usePending,
  useUpdateEffect,
} from '~/hooks'
import { TIER_THREE, inTopFive } from '~/utils/programInfo'
import PropTypes from '~/utils/propTypes'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCallDispositions } from '../../../data/callDispositions'
import { contactCleared, getCallLog, saveCall } from '../../../data/callLog'
import { snoozePatient } from '../../../data/common/shared'
import { ACTIVE, REFERRED } from '../../../data/patientInfo'
import Discharge from './Discharge'
import DischargeRecommendation from './DischargeRecommendation'
import {
  CALLBACK_DISPOSITION_REASONS,
  CALLBACK_SUB_DISPOSITION_REASONS,
  DECEASED,
  DISCHARGE,
  HOSPICE,
  INELIGIBLE_INSURANCE,
  OUTREACH_CYCLE_COMPLETE,
  PATIENT_FAMILY_DECLINED_SAFETY_CONCERNS,
  RECOMMEND_DISCHARGE,
  RESCHEDULED_CANCELLATION_DISPOSITIONS,
  TEMPORARY_GEOGRAPHIC_HOLD,
  TODAY,
  VIRTUAL_CARE,
} from './consts'
import { filterCallOrigin, filterIntent, filterStatus } from './filters'

const shouldCallbackDateRender = (disposition, subDisposition) =>
  CALLBACK_DISPOSITION_REASONS.includes(disposition) ||
  CALLBACK_SUB_DISPOSITION_REASONS.includes(subDisposition)

const shouldDischargeRecommendationRender = disposition =>
  disposition === RECOMMEND_DISCHARGE

const shouldDischargeRender = disposition => disposition === DISCHARGE

const inputProps = {
  min: TODAY,
}

const useStyles = makeStyles(({ spacing }) => ({
  field: {
    width: '100%',
    marginBottom: spacing(1),
  },
  notes: {
    marginTop: spacing(1),
  },
  button: {
    marginRight: spacing(1),
  },
  icon: {
    marginRight: spacing(0.5),
  },
}))

const CallLogForm = ({ programEnrolled }) => {
  const classes = useStyles()

  const callLog = useSelector(getCallLog)
  const callDispositions = useSelector(getCallDispositions)
  const canCreateDischargeRecommendation = useSelector(state =>
    getHasPermission(state, objects.DISCHARGE_RECOMMENDATION, actions.CREATE)
  )
  const onContactCleared = useAction(contactCleared)
  const saveCallRequested = useAction(saveCall.requested)
  const snoozePatientRequested = useAction(snoozePatient.requested)
  const saveDischargeRequested = useAction(saveDischarge.requested)
  const saveDischargeRecommendationRequested = useAction(
    saveDischargeRecommendation.requested
  )

  const [dispositionId, setDispositionId] = useInput()
  const [subDispositionId, setSubDispositionId] = useInput()
  const [dispositionLabel, setDispositionLabel] = useState('')
  const [subDispositionLabel, setSubDispositionLabel] = useState('')
  const [callbackDate, setCallbackDate] = useInput()
  const [dischargeForm, setDischargeForm] = useState({})
  const [callNotes, setCallNotes] = useInput()
  const pending = usePending(saveCall)

  const dispositionRef = useRef()

  const filteredDispositions = useMemo(
    () =>
      callDispositions
        .filter(callDisposition => {
          const intent = filterIntent(callLog, callDisposition)
          const callOrigin = filterCallOrigin(callLog, callDisposition)
          const status = filterStatus(callLog.patientStatus, callDisposition)

          return intent && callOrigin && status
        })
        .reduce(
          (map, callDisposition) =>
            map.has(callDisposition.dispositionId)
              ? map
              : map.set(callDisposition.dispositionId, callDisposition),
          OrderedMap()
        )
        .sort((disp1, disp2) => {
          if (disp1.intentId == disp2.intentId) return 0
          if (disp1.intentId > disp2.intentId) return -1
          if (disp1.intentId < disp2.intentId) return 1
        })
        .map(disposition => ({
          value: disposition.dispositionId,
          label: disposition.dispositionName,
        })),
    [
      callDispositions,
      callLog.intentId,
      callLog.callOrigin,
      callLog.patientStatus,
    ]
  )

  const filteredSubDispositions = useMemo(
    () =>
      callDispositions
        .filter(callDisposition => {
          const intentId = filterIntent(callLog, callDisposition)
          const callOrigin = filterCallOrigin(callLog, callDisposition)
          const status = filterStatus(callLog.patientStatus, callDisposition)
          const selectedDisposition =
            callDisposition.dispositionId === dispositionId
          const patientReferred = callLog.patientStatus === REFERRED
          const rescheduledOrCancellation = RESCHEDULED_CANCELLATION_DISPOSITIONS.includes(
            callDisposition.dispositionName
          )

          return (
            intentId &&
            callOrigin &&
            status &&
            selectedDisposition &&
            !(patientReferred && rescheduledOrCancellation)
          )
        })
        .filter(({ dispositionName, subdispositionName }) => {
          if (
            dispositionName === DISCHARGE ||
            dispositionName === RECOMMEND_DISCHARGE
          ) {
            if (programEnrolled === TIER_THREE) {
              return true
            } else if (inTopFive(programEnrolled)) {
              return [
                DECEASED,
                HOSPICE,
                INELIGIBLE_INSURANCE,
                OUTREACH_CYCLE_COMPLETE,
                PATIENT_FAMILY_DECLINED_SAFETY_CONCERNS,
                TEMPORARY_GEOGRAPHIC_HOLD,
                VIRTUAL_CARE,
              ].includes(subdispositionName)
            } else if (
              callLog.patientStatus === ACTIVE &&
              dispositionName === DISCHARGE
            ) {
              return [DECEASED, HOSPICE, OUTREACH_CYCLE_COMPLETE].includes(
                subdispositionName
              )
            } else {
              return true
            }
          } else {
            return true
          }
        })
        .reduce(
          (map, callDisposition) =>
            map.has(callDisposition.subdispositionId)
              ? map
              : map.set(callDisposition.subdispositionId, callDisposition),
          OrderedMap()
        )
        .sort((callDisposition1, callDisposition2) =>
          callDisposition1.subdispositionName.localeCompare(
            callDisposition2.subdispositionName
          )
        )
        .map(callDisposition => ({
          value: callDisposition.subdispositionId,
          label: callDisposition.subdispositionName,
        })),
    [
      callDispositions,
      callLog.intentId,
      callLog.callOrigin,
      callLog.patientStatus,
      dispositionId,
    ]
  )

  const formValid = useMemo(() => {
    const dispositionValid = !!dispositionId
    const subDispositionValid = !!subDispositionId
    const callbackDateValid =
      !shouldCallbackDateRender(dispositionLabel, subDispositionLabel) ||
      callbackDate
    const dischargeRecommendationValid =
      !shouldDischargeRecommendationRender(dispositionLabel) ||
      dischargeForm.valid
    const dischargeValid =
      !shouldDischargeRender(dispositionLabel) || dischargeForm.valid

    return (
      dispositionValid &&
      subDispositionValid &&
      callbackDateValid &&
      dischargeRecommendationValid &&
      dischargeValid
    )
  }, [
    dispositionId,
    dispositionLabel,
    subDispositionId,
    subDispositionLabel,
    callbackDate,
    dischargeForm,
  ])

  const onDispositionChanged = value => {
    const disposition = value ? filteredDispositions.get(value).label : ''

    setDispositionId(value)
    setDispositionLabel(disposition)
    setSubDispositionId('')
    setSubDispositionLabel('')
    setCallbackDate('')
    setDischargeForm({})
  }

  const onSubDispositionChanged = value => {
    setSubDispositionId(value)
    setSubDispositionLabel(filteredSubDispositions.get(value).label)
    setCallbackDate('')
  }

  const onSubmit = () => {
    const disposition = subDispositionLabel
      ? `${dispositionLabel} - ${subDispositionLabel}`
      : dispositionLabel

    saveCallRequested(
      callLog.patientId,
      callLog,
      dispositionId,
      subDispositionId,
      disposition,
      callNotes
    )

    if (callbackDate) {
      snoozePatientRequested(
        callLog.patientId,
        parseISO(callbackDate).toISOString()
      )
    }

    if (shouldDischargeRecommendationRender(dispositionLabel)) {
      saveDischargeRecommendationRequested(
        dischargeForm.dischargeNotes,
        subDispositionLabel,
        null,
        callLog.patientId,
        TODAY
      )
    }

    if (shouldDischargeRender(dispositionLabel)) {
      saveDischargeRequested({
        dischargeNotes: dischargeForm.dischargeNotes,
        dischargeReason: subDispositionLabel,
        dischargeDate: dischargeForm.dischargeDate,
        dateOfDeath: dischargeForm.dateOfDeath,
        hospiceId: dischargeForm.hospiceId,
        hospiceReason: dischargeForm.hospiceReason,
        addToHealthPlanDoNotCallList: dischargeForm.doNotCall,
        potentialToReengage: dischargeForm.reengagePotential,
        patientId: callLog.patientId,
      })
    }

    // TO DO - discharge & discharge recommendation calls
  }

  useMountEffect(() => {
    dispositionRef.current.focus()
  })

  useUpdateEffect(() => {
    if (
      !subDispositionId &&
      filteredSubDispositions.count() === 1 &&
      filteredSubDispositions.first().label === ''
    ) {
      const subDisposition = filteredSubDispositions.first()

      setSubDispositionId(subDisposition.value)
      setSubDispositionLabel(subDisposition.label)
    }
  }, [
    filteredSubDispositions,
    subDispositionId,
    setSubDispositionId,
    setSubDispositionLabel,
  ])

  // Reset form if call origin changes
  useUpdateEffect(() => {
    onDispositionChanged('')
  }, [callLog.callOrigin])

  const singleSubDisposition =
    filteredSubDispositions.count() === 1 &&
    filteredSubDispositions.first().label === ''

  return (
    <React.Fragment>
      {/* Disposition */}
      <SelectField
        className={classes.field}
        inputRef={e => {
          dispositionRef.current = e
        }}
        label="Disposition"
        native
        value={dispositionId}
        onChange={onDispositionChanged}
        error={!dispositionId}
      >
        <option />
        {renderFieldValueOptions(
          canCreateDischargeRecommendation
            ? filteredDispositions
            : filteredDispositions.filter(
                d => d.label !== 'Recommend Discharge'
              )
        )}
      </SelectField>

      {/* Sub Disposition */}
      {dispositionId && !singleSubDisposition && (
        <SelectField
          className={classes.field}
          label="Sub Disposition"
          value={subDispositionId}
          onChange={onSubDispositionChanged}
          native
          error={!subDispositionId}
        >
          <option />
          {renderFieldValueOptions(filteredSubDispositions)}
        </SelectField>
      )}

      {/* Callback Date */}
      {shouldCallbackDateRender(dispositionLabel, subDispositionLabel) && (
        <DateField
          className={classes.field}
          label="Callback Date"
          onChange={setCallbackDate}
          value={callbackDate}
          inputProps={inputProps}
          error={!callbackDate}
        />
      )}

      {/* Discharge Recommendation */}
      {shouldDischargeRecommendationRender(dispositionLabel) && (
        <DischargeRecommendation onFormChange={setDischargeForm} />
      )}

      {/* Discharge */}
      {shouldDischargeRender(dispositionLabel) && (
        <Discharge
          dischargeReason={subDispositionLabel}
          onFormChange={setDischargeForm}
        />
      )}

      {/* Call Notes */}
      <TextField
        className={classnames(classes.field, classes.notes)}
        variant="outlined"
        label="Call Notes"
        onChange={setCallNotes}
        value={callNotes}
        multiline
        rowsMax={6}
      />

      {/* Actions */}
      <Button
        className={classes.button}
        disabled={!formValid || pending}
        variant="contained"
        color="primary"
        onClick={onSubmit}
      >
        <Icon className={classes.icon} fontSize="small">
          phone
        </Icon>
        Submit
      </Button>

      <Button
        disabled={pending}
        variant="contained"
        color="secondary"
        onClick={onContactCleared}
      >
        <Icon className={classes.icon} fontSize="small">
          call_end
        </Icon>
        Cancel
      </Button>
    </React.Fragment>
  )
}

CallLogForm.propTypes = {
  programEnrolled: PropTypes.string,
}

export default CallLogForm
