import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import mapPropTypes from './mapPropTypes'
import { createListener, createPropsToOptions } from './objectHelpers'
import withGoogle from './withGoogle'
import withMap from './withMap'

const propsToOptions = createPropsToOptions(props => ({
  paths: props.path,
  editable: props.editable,
  draggable: props.draggable,
  geodesic: props.geodesic,
  fillColor: props.fillColor,
  fillOpacity: props.fillOpacity,
  strokeColor: props.strokeColor,
  strokeOpacity: props.strokeOpacity,
  strokePosition: props.strokePosition,
  strokeWeight: props.strokeWeight,
}))

class Polygon extends React.PureComponent {
  constructor(props) {
    super(props)

    const options = propsToOptions(props)
    const polygon = new props.google.maps.Polygon(options)

    polygon.setMap(props.map)
    polygon.addListener('click', createListener(this, 'onClick'))
    polygon.addListener('rightclick', createListener(this, 'onRightClick'))
    polygon.addListener('mouseover', createListener(this, 'onMouseover'))
    polygon.addListener('mouseout', createListener(this, 'onMouseout'))
    polygon.addListener('dragstart', this.startDrag)
    polygon.addListener('dragend', compose(this.endDrag, this.onPathChange))

    this.addPathListeners(polygon.getPath())

    this.state = { polygon, dragging: false }
  }

  componentDidMount() {
    this.updateBounds()
  }

  componentDidUpdate(prevProps) {
    const prevPath = this.state.polygon.getPath()

    if (
      prevProps.path !== this.props.path ||
      prevProps.editable !== this.props.editable ||
      prevProps.draggable !== this.props.draggable ||
      prevProps.geodesic !== this.props.geodesic ||
      prevProps.fillColor !== this.props.fillColor ||
      prevProps.fillOpacity !== this.props.fillOpacity ||
      prevProps.strokeColor !== this.props.strokeColor ||
      prevProps.strokeOpacity !== this.props.strokeOpacity ||
      prevProps.strokePosition !== this.props.strokePosition ||
      prevProps.strokeWeight !== this.props.strokeWeight
    ) {
      this.state.polygon.setOptions(propsToOptions(this.props, prevProps))
    }

    const nextPath = this.state.polygon.getPath()

    if (prevProps.path !== this.props.path) {
      prevProps.google.maps.event.clearInstanceListeners(prevPath)
      this.addPathListeners(nextPath)
      this.updateBounds(this.props)
    }
  }

  componentWillUnmount() {
    this.state.polygon.setMap(null)
    this.updateBounds({ ...this.props, path: null })
    this.props.google.maps.event.clearInstanceListeners(this.state.polygon)
    this.props.google.maps.event.clearInstanceListeners(
      this.state.polygon.getPath()
    )
  }

  startDrag = () => this.setState({ dragging: true })
  endDrag = () => this.setState({ dragging: false })

  addPathListeners = path => {
    const listener = () => {
      !this.state.dragging && this.onPathChange()
    }

    path.addListener('insert_at', listener)
    path.addListener('remove_at', listener)
    path.addListener('set_at', listener)
  }

  updateBounds = ({ google, path, setBounds, deleteBounds } = this.props) => {
    if (path) {
      const polygonBounds = path.reduce(
        (polygonBounds, latLng) => polygonBounds.extend(latLng),
        new google.maps.LatLngBounds()
      )

      setBounds(this.state.polygon, polygonBounds)
    } else {
      deleteBounds(this.state.polygon)
    }
  }

  onPathChange = () => {
    if (this.props.onPathChange !== undefined) {
      const pathLatLng = this.state.polygon.getPath().getArray()
      const pathLatLngLiteral = pathLatLng.map(latLng => latLng.toJSON())

      this.props.onPathChange(pathLatLngLiteral)
    }
  }

  render() {
    return null
  }
}

Polygon.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object,
  setBounds: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  deleteBounds: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  path: PropTypes.arrayOf(mapPropTypes.latLng),
  editable: PropTypes.bool,
  draggable: PropTypes.bool,
  geodesic: PropTypes.bool,
  fillColor: PropTypes.string,
  fillOpacity: PropTypes.number,
  strokeColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  strokePosition: PropTypes.number,
  strokeWeight: PropTypes.number,
  onPathChange: PropTypes.func,
  onClick: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onRightClick: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onMouseover: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onMouseout: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
}

export default compose(withMap, withGoogle)(Polygon)
