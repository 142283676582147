import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { handleMedMessage } from './root'
import { rxMedicationCleared } from './selectedMedication'

/* KEY */
const key = 'drugAlerts'

const DrugAlert = Record({
  type: null,
  title: null,
  severityLevel: null,
  matchId: null,
  description: null,
  category: null,
})

const fetchDrugAlertsApi = params =>
  AspireAPI.get('medications/drug_alerts', {
    params: {
      drug_id: params.drugId,
      generic_drug_ids: params.genericDrugIds.toArray(),
    },
  })

const filterBySeverity = data =>
  List(data.filter(x => x.severityLevel === '3').map(x => new DrugAlert(x)))

export const fetchDrugAlerts = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['params'],
  operation: fetchDrugAlertsApi,
  transform: filterBySeverity,
  messages: {
    failed: handleMedMessage(
      'There was a problem fetching Drug / Drug Interactions'
    ),
  },
})

/* REDUCER */
export default createReducer(key, null, {
  [fetchDrugAlerts.SUCCEEDED]: (_state, { payload }) => payload,
  [rxMedicationCleared]: () => null,
})

/* SELECTORS */
export const getDrugAlerts = pipe(get('medications'), get(key))
