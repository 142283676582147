import { ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import { combineLatest, filter, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
import {
  fetchCDPatientCensus,
  fetchPatientLists,
  // @ts-expect-error could not find a declaration file
} from '~/features/patientCensus'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { getSelectedCDId } from '../data/clinicalDirectorId'
import {
  CLINICAL_DIRECTOR_DASHBOARD_CENSUS,
  CLINICAL_DIRECTOR_DASHBOARD_MY_LISTS,
} from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(
      changedTo([
        CLINICAL_DIRECTOR_DASHBOARD_CENSUS,
        CLINICAL_DIRECTOR_DASHBOARD_MY_LISTS,
      ])
    ),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(getSelectedCDId),
    mergeMap(userId =>
      of(
        fetchCDPatientCensus.requested(userId),
        fetchPatientLists.requested(userId)
      )
    )
  )
