import { hasIn, isEmpty } from 'lodash/fp'
import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { currentAssessmentFetched } from '~/apps/assessment/data/assessments'
import { getTaggedValue } from '~/components/JsonForm'
import { getUserId } from '~/data/session'
import { medAssessmentSet } from '../data/assessment'

export default (action$, state$) =>
  action$.pipe(
    ofType(currentAssessmentFetched),
    pluck('payload', 'assessment'),
    filter(({ providerId }) => providerId === getUserId(state$.value)),
    filter(({ status }) => ['created', 'in_progress'].includes(status)),
    filter(hasIn(['tags', 'medList', 'data'])),
    map(getCurrentAssessmentMedState),
    map(medAssessmentSet)
  )

const getCurrentAssessmentMedState = assessment => {
  const {
    patientRx,
    patient,
    prescriber,
    prescribingLocation,
    preferredPharmacy,
  } = assessment.context
  const formDataRxList = getTaggedValue(assessment, 'medList')
  const formDataUnsavedRxList = getTaggedValue(assessment, 'unsavedMedList')
  const formDataPreferredPharmacy = getTaggedValue(
    assessment,
    'preferredPharmacy'
  )

  const assessmentRxList = !isEmpty(formDataRxList) ? formDataRxList : patientRx
  const assessmentPharmacy = !isEmpty(formDataPreferredPharmacy)
    ? formDataPreferredPharmacy
    : preferredPharmacy

  return {
    patientRx: assessmentRxList || [],
    patientUnsavedRx: formDataUnsavedRxList || [],
    patientInformation: {
      id: patient.id || '',
      prescribingLocation: prescribingLocation || {},
      prescriber: prescriber || {},
      preferredPharmacy: assessmentPharmacy || {},
    },
  }
}
