import { Map } from 'immutable'
import { FieldValue } from '~/data/fieldValues'
import { colors as muiColors } from '@material-ui/core'

export const colors = {
  text: 'white',
  background: muiColors.green['A700'],
}

export const label = 'Scheduling Guidance'

export const subtypeField = 'scheduling_guidance_type'

export const layer = 1

export const title = (
  event,
  subtypes = Map(),
  visitMethods = Map(),
  rideAlongRoles = Map()
) => {
  const addGuidanceDescription = type =>
    type ? (type === 'Flex' ? `${type} Time ` : `${type} Visit `) : null

  const guidanceType =
    addGuidanceDescription(
      subtypes.get(event.guidanceType, FieldValue()).label
    ) || ''
  const visitMethod =
    visitMethods.get(event.visitMethod, FieldValue()).label || ''
  const rideAlongRole =
    rideAlongRoles.get(event.rideAlongRole, FieldValue()).label || ''

  return guidanceType || visitMethod || rideAlongRole
    ? `${visitMethod && visitMethod + ' '}${
        guidanceType && guidanceType + ' '
      }${rideAlongRole && rideAlongRole + ' Ride Along'}`
    : label
}

export default { colors, label, layer, title }
