import { ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { merge, of } from 'rxjs'
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { fetchAppOptions } from '../data/appOptions'
import { getCurrentUserId } from '../data/currentUserId'
import router from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(changedTo(router)),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(getCurrentUserId),
    distinctUntilChanged(),
    mergeMap((userId: string) =>
      merge(of(ownerTasksFetchRequested(userId)), of(fetchAppOptions(userId)))
    )
  )
