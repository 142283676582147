import React, { useCallback } from 'react'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import {
  DELETE_LIST_ITEM,
  EDIT_LIST_ITEM,
  dialogOpened,
} from '../../data/dialog'

const ViewRowActions = ({ row }) => {
  const onDialogOpened = useAction(dialogOpened)
  const onEdit = useCallback(() => {
    onDialogOpened(
      EDIT_LIST_ITEM,
      row.patientId,
      row.patientName,
      row.reason,
      row.id
    )
  }, [row, onDialogOpened])

  const onDelete = useCallback(() => {
    onDialogOpened(
      DELETE_LIST_ITEM,
      row.patientId,
      row.patientName,
      row.reason,
      row.id
    )
  }, [row, onDialogOpened])

  return (
    <React.Fragment>
      <Tooltip title="Edit">
        <IconButton onClick={onEdit}>
          <Icon>edit</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={onDelete}>
          <Icon>delete</Icon>
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

ViewRowActions.propTypes = {
  row: PropTypes.object.isRequired,
}

export default React.memo(ViewRowActions)
