import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import { callDispositionsRequested } from '../data/callDispositions'
import { callIntentsRequested } from '../data/callIntents'
import {
  callLogInitialized,
  fetchCallAttempt,
  fetchCallLogStatus,
} from '../data/callLog'
import { fetchContacts } from '../data/contacts'
import { fetchPatientInfo } from '../data/patientInfo'
import { fetchWarnings } from '../data/warnings'

export default action$ =>
  action$.pipe(
    ofType(callLogInitialized),
    pluck('payload', 'patientId'),
    mergeMap(patientId =>
      of(
        callIntentsRequested(),
        callDispositionsRequested(),
        fetchPatientInfo.requested(patientId),
        fetchCallLogStatus.requested(patientId),
        fetchCallAttempt.requested(patientId),
        fetchContacts.requested(patientId),
        fetchWarnings.requested(patientId)
      )
    )
  )
