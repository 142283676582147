import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const TRANSPORTATION_DASHBOARD_PATIENT_SEARCH = `${key}.patientSearch`
export const TRANSPORTATION_DASHBOARD_BENEFITS = `${key}.benefits`
export const TRANSPORTATION_DASHBOARD_TRIP_LOCKS = `${key}.tripLocks`
export const TRANSPORTATION_DASHBOARD_USER_ACTIVITY = `${key}.userActivity`
export const TRANSPORTATION_DASHBOARD_TRIP_REPORT = `${key}.tripReport`
export const TRANSPORTATION_EXTERNAL_LINK = `${key}.externalLink`
export const TRANSPORTATION_SEARCH_BY_TRIP_ID = `${key}.searchByTripId`

export default Router([
  Route(TRANSPORTATION_DASHBOARD_PATIENT_SEARCH, '/patient-search'),
  Redirect(TRANSPORTATION_DASHBOARD_PATIENT_SEARCH),
  Route(TRANSPORTATION_DASHBOARD_BENEFITS, '/benefits'),
  Route(TRANSPORTATION_DASHBOARD_TRIP_LOCKS, '/trip-locks'),
  Route(TRANSPORTATION_DASHBOARD_USER_ACTIVITY, '/user-activity'),
  Route(TRANSPORTATION_DASHBOARD_TRIP_REPORT, '/trip-report'),
  Route(TRANSPORTATION_EXTERNAL_LINK, '/external-link'),
  Route(TRANSPORTATION_SEARCH_BY_TRIP_ID, '/search-by-trip-id'),
])
