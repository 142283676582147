import cx from 'classnames'
import { get } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import DisplayDateTime from '~/components/DisplayDateTime'
import WithTooltip from '~/components/WithTooltip'
import withWidth from '~/components/withWidth'
import { patientPanelOpened } from '~/data/patientPanel'
import { RiskCell } from '~/features/patientCensus'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { titleCase } from '~/utils/stringManipulation'
import { Paper, Typography } from '@material-ui/core'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Actions from '../containers/Actions'
import TaskDetails from '../containers/TaskDetails'
import { compareDates } from '../utils/date'
import { comparePriorities } from '../utils/priority'
import DueOnDate from './DueOnDate'
import PatientLink from './PatientLink'
import Priority from './Priority'

const styles = ({ spacing, palette }) => ({
  header: {
    '& > div:first-child > div:first-child': {
      backgroundColor: palette.accordion.header,
      color: palette.accordion.color,
    },
  },
  gridContainer: {
    '& table td[class*="TableDetailCell"]': {
      backgroundColor:
        palette.type === 'light' ? palette.grey[200] : palette.grey[700],
    },
  },
  container: ({ width }) =>
    width && {
      maxWidth: width - spacing(5),
    },
})

const getRowId = ({ id }) => id

const buildUserTypography = (id, name) => (
  <Typography userId={id}>{name}</Typography>
)
const buildPatientLink = (id, name) => (
  <PatientLink patientId={id}>{name}</PatientLink>
)

const getRowConfigFor = (property, fn = buildUserTypography) => ({
  displayComponent: ({ row, value }) => {
    const { id } = row[property] || {}
    return id ? fn(id, value) : value
  },
  groupByComponent: ({ value }) => value,
  getCellValue: args => get(args, [property, 'name']) || 'Unknown',
})

class TasksDashboard extends React.PureComponent {
  state = {
    expandedRowIds: [],
  }

  defaultHiddenColumns = (tasksContext, hiddenColumns) => [
    'completedOn',
    'healthplanName',
    'modifiedAt',
    'modifiedByName',
    'requestedByName',
    ...(tasksContext === 'patient'
      ? ['patientName', 'vendor', 'physician']
      : []), // hides patient name when on patient record
    ...hiddenColumns, // hides requested by or owner
  ]

  /*
  NOTE: `displayComponent`s are reused in `DisplayCell`s and `GroupDisplayCell`s for `DevXGrid`.
        However, the `row` prop pass to them are not the same, therefore, certain `displayComponent`s
        here default to `value`, which is always present.
  */
  settings = (tasksContext, hiddenColumns) => ({
    columns: [
      {
        name: 'title',
        title: 'Task',
        displayComponent: WithTooltip,
      },
      {
        name: 'status',
        title: 'Status',
        displayComponent: ({ row: { status, value } }) =>
          titleCase(status || value),
      },
      {
        name: 'dueOn',
        title: 'Due',
        displayComponent: DueOnDate,
      },
      {
        name: 'owner',
        title: 'Owner',
        ...getRowConfigFor('ownerUser'),
        getCellValue: ({ ownerUser = {} }) =>
          `${ownerUser.name}/${ownerUser.role}`,
        displayComponent: WithTooltip,
      },
      {
        name: 'patientName',
        title: 'Patient',
        ...getRowConfigFor('patient', buildPatientLink),
      },
      { name: 'program', title: 'Program' },
      {
        name: 'healthplanLabel',
        title: 'Healthplan',
        getCellValue: row => get(row, ['healthplanLabel']),
        displayComponent: WithTooltip,
      },
      {
        name: 'healthplanName',
        title: 'Requested Insurance',
        getCellValue: row =>
          get(row, ['context', 'healthplan', 'healthplanName']),
        displayComponent: WithTooltip,
      },
      {
        name: 'priority',
        title: 'Priority',
        displayComponent: ({ row: { completedOn, priority, value } }) =>
          priority ? (
            <Priority {...{ completedOn, priority }} />
          ) : (
            titleCase(value)
          ),
      },
      {
        name: 'completedOn',
        title: 'Completed On',
        displayComponent: DisplayDateTime,
      },
      {
        name: 'requestedByName',
        title: 'Requested By',
        ...getRowConfigFor('requestedUser'),
      },
      {
        name: 'modifiedByName',
        title: 'Modified By',
        ...getRowConfigFor('modifiedUser'),
      },
      {
        name: 'modifiedAt',
        title: 'Modified On',
        displayComponent: DisplayDateTime,
      },
      {
        name: 'vendor',
        title: 'Vendor',
        getCellValue: row => get(row, ['context', 'vendor', 'name']),
        groupByComponent: ({ value }) => value || 'Unspecified',
        displayComponent: WithTooltip,
      },
      {
        name: 'physician',
        title: 'Physician',
        getCellValue: row => get(row, ['context', 'physician', 'name']),
        groupByComponent: ({ value }) => value || 'Unspecified',
        displayComponent: WithTooltip,
      },
      {
        name: 'score',
        title: 'Risk Stratification',
        getCellValue: row => get(row, ['patient', 'patientScore']),
        groupByComponent: ({ value }) => value || 'Unspecified',
        displayComponent: RiskCell,
      },
      {
        name: 'market',
        title: 'Market',
        getCellValue: row => get(row, ['patient', 'marketName']),
        groupByComponent: ({ value }) => value || 'Unspecified',
      },
    ],
    columnExtensions: [
      { columnName: 'completedOn', compare: compareDates },
      { columnName: 'program', width: 200 },
      {
        columnName: 'dueOn',
        compare: compareDates,
      },
      { columnName: 'modifiedAt', compare: compareDates },
      { columnName: 'priority', compare: comparePriorities },
    ],
    columnHideable: true,
    defaultHiddenColumnNames: this.defaultHiddenColumns(
      tasksContext,
      hiddenColumns || []
    ),
    defaultSorting:
      tasksContext === 'user'
        ? [
            { columnName: 'priority', direction: 'asc' },
            { columnName: 'dueOn', direction: 'desc' },
          ]
        : [{ columnName: 'dueOn', direction: 'asc' }],
    detailComponent: this.TaskDetails,
    filterable: true,
    groupable: true,
    pageable: true,
    pageSizes: [10, 25],
    rowActions: this.Actions,
    rowActionsCount: 2,
    sortable: true,
    localSettingsKey: 'featureTasksGridSettings',
  })

  TaskDetails = props => (
    <div className={this.props.classes.container}>
      <TaskDetails {...props} tasksContext={this.props.tasksContext} />
    </div>
  )

  Actions = props => <Actions {...props} route={this.props.route} />

  onToggle = ({ id, type, patientId }, expanded, tableRowId) => {
    this.props[expanded ? 'taskCollapsed' : 'taskExpanded'](id, type)
    if (!expanded) {
      this.props.patientPanelOpened(patientId)
      this.setState({ expandedRowIds: [tableRowId] })
    } else {
      this.setState({ expandedRowIds: [] })
    }
  }

  render() {
    const { classes, tasksContext, hiddenColumns } = this.props
    return (
      <Paper>
        <DevXGrid
          className={cx(classes.gridContainer, classes.header)}
          getRowId={getRowId}
          onToggle={this.onToggle}
          rows={this.props.tasks}
          expandedRowIds={this.state.expandedRowIds}
          {...this.settings(tasksContext, hiddenColumns)}
        />
      </Paper>
    )
  }
}

TasksDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  hiddenColumns: PropTypes.array,
  patientPanelOpened: PropTypes.func.isRequired,
  taskCollapsed: PropTypes.func.isRequired,
  taskExpanded: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  route: PropTypes.string.isRequired,
  tasksContext: PropTypes.string.isRequired,
}

TasksDashboard.defaultProps = {
  hiddenColumns: [],
}

export default compose(
  connect(null, { patientPanelOpened }),
  withWidth,
  withTheme,
  withStyles(styles)
)(TasksDashboard)
