import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { patientOutlierPanelOpened } from '~/data/patientPanel'
import { fetchPatientOutlierReview } from '../data/currentPatientOutlierReview'

export default (action$: any) =>
  action$.pipe(
    ofType(fetchPatientOutlierReview.SUCCEEDED),
    pluck('payload'),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ patientId, id }: { patientId:... Remove this comment to see the full error message
    map(({ patientId, id }) => patientOutlierPanelOpened(patientId, id))
  )
