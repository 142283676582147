import { OrderedMap } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  dataCleared,
  getRoot,
  transformEncounterReviews,
} from './common/shared'
import { signEncounterReview } from './currentEncounterReview'

const ENCOUNTER_REVIEWS = 'encounterReviews'

export const fetchEncounterReviews = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ENCOUNTER_REVIEWS',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('encounters/encounter_reviews', {
      params: {
        userId,
      },
    }),
  transform: transformEncounterReviews,
  messages: {
    failed: 'There was an issue getting the encounter review list',
  },
})

const initState = OrderedMap()

export default createReducer(ENCOUNTER_REVIEWS, initState, {
  [fetchEncounterReviews.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [signEncounterReview.SUCCEEDED]: (state: any, { meta: { request } }: any) =>
    state.delete(request.payload.id),
  // @ts-expect-error can we deal with the toString being automatically called?
  [dataCleared]: () => initState,
})

export const getEncounterReviews = pipe(getRoot, get(ENCOUNTER_REVIEWS))

export const getEncounterReviewsCount = (state: any) =>
  getEncounterReviews(state).size
