import { Map, Record } from 'immutable'
import moment from 'moment'
import { fetchOnCallsForPatient } from '~/features/onCall'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { into, scopedCreator } from '~/utils/data'
import { ON_CALL } from '../constants'
import rootKey from '../key'
import { formatDate, formatTimestamp, fromUTC } from '../utils/date'

export const PATIENT_EVENTS = 'patientEvents'

export const PatientEvent = Record({
  category: null,
  createdAt: null,
  createdAtDate: null,
  createdById: null,
  createdByName: null,
  createdByRole: null,
  details: null,
  eventDate: null,
  eventDateFormatted: null,
  id: null,
  notes: [],
  referenceId: null,
})

const transformPatientEvent = event =>
  event.eventDate
    ? PatientEvent({
        ...event,
        createdAt: fromUTC(event.createdAt),
        createdAtDate: formatDate(event.createdAt),
        eventDate: event.interpretAsTimestamp
          ? fromUTC(event.eventDate)
          : moment(event.eventDate),
        eventDateFormatted: event.interpretAsTimestamp
          ? formatTimestamp(event.eventDate)
          : formatDate(event.eventDate),
      })
    : PatientEvent({
        ...event,
        createdAt: fromUTC(event.createdAt),
        createdAtDate: formatDate(event.createdAt),
        eventDate: fromUTC(event.createdAt),
        eventDateFormatted: formatTimestamp(event.createdAt),
      })

export const transform = into(transformPatientEvent, 'id')

export const fetchPatientTimeline = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_TIMELINE',
  requestParams: ['patientId'],
  operation: (patientId, cancelToken) =>
    AspireAPI.get(`v1/patient/${patientId}/new_timeline`, { cancelToken }),
  transform,
  messages: {
    failed: 'There was a problem fetching the patient timeline',
  },
})

const creator = scopedCreator(rootKey)
export const patientTimelineCleared = creator('PATIENT_EVENTS_CLEARED', false)

const convertCallToPatientEvent = call =>
  PatientEvent({
    id: call.id,
    category: ON_CALL,
    createdAt: fromUTC(call.dateTimeOfCall),
    createdAtDate: formatDate(call.dateTimeOfCall),
    createdById: call.user.id,
    createdByName: call.user.name,
    createdByRole: call.user.role,
    details: { ...call.form, status: call.typeOfCall },
    eventDate: fromUTC(call.dateTimeOfCall),
    eventDateFormatted: formatTimestamp(call.dateTimeOfCall),
  })

export const transformCallToPatientEvent = into(convertCallToPatientEvent, 'id')

const initState = Map()
export default createReducer(PATIENT_EVENTS, initState, {
  [patientTimelineCleared]: () => initState,
  [fetchPatientTimeline.REQUESTED]: () => initState,
  [fetchPatientTimeline.SUCCEEDED]: (state, { payload }) =>
    state.set('patientEvents', payload),
  [fetchOnCallsForPatient.SUCCEEDED]: (state, action) =>
    state.set(
      'calls',
      transformCallToPatientEvent(
        // the payload of this action contains legacy onCall and Modern onCalls
        // we want to filter out all of the legacy ones from this dataset
        action.payload.filter(({ form }) => !!form).valueSeq()
      )
    ),
})
