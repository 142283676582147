import EventActions from '../../Events/EventActions'
import OnCallCalendarEvent from './OnCallCalendarEvent'
import OnCallEventDetail from './OnCallEventDetail'
import defaults from './defaults'
import * as info from './info'
import * as permissions from './permissions'

export default {
  ...info,
  type: defaults.type,
  defaults: defaults,
  permissions: permissions,
  detailComponent: OnCallEventDetail,
  calendarComponent: OnCallCalendarEvent,
  actionsComponent: EventActions,
}
