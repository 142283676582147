import { createSelector } from 'reselect'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { getPatientAlerts } from './patientAlerts'

const key = 'alertDialog'
const NEW_ALERT = 'NEW_ALERT'

const CLOSED_ALERT_DIALOG = type(key, 'CLOSED_ALERT_DIALOG')
export const closedAlertDialog = creator(CLOSED_ALERT_DIALOG)

const SET_ALERT_DIALOG_VALUE = type(key, 'SET_ALERT_DIALOG_VALUE')
export const setAlertDialogValue = creator(SET_ALERT_DIALOG_VALUE, 'alertId')
export const addAlertDialogOpened = () => setAlertDialogValue(NEW_ALERT)

export default createReducer(key, null, {
  [CLOSED_ALERT_DIALOG]: (_state, _action) => null,
  [SET_ALERT_DIALOG_VALUE]: (_state, { payload }) => payload.alertId,
})

const getAlertDialogValue = pipe(getRoot, get(key))
export const getIsAlertDialogDisplayed = pipe(getAlertDialogValue, Boolean)

export const getSelectedAlert = createSelector(
  [getAlertDialogValue, getPatientAlerts],
  (alertDialogValue, alerts) => alerts.get(alertDialogValue)
)
