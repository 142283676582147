import { format, isBefore, subHours } from 'date-fns'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUserId } from '~/data/session'
import { Divider, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCallLog } from '../../data/callLog'
import { getPatientInfo } from '../../data/patientInfo'
import { getWarnings } from '../../data/warnings'

const REFERRED = 'Referred'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    marginBottom: spacing(1),
  },
  title: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: spacing(1),
  },
  stale: {
    gridColumnStart: 'span 2',
  },
  divider: {
    margin: spacing(1, 0),
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(1),
  },
}))

const Header = () => {
  const classes = useStyles()

  const userId = useSelector(getUserId)
  const callLog = useSelector(getCallLog)
  const patientInfo = useSelector(getPatientInfo)
  const warnings = useSelector(getWarnings)

  const stale = useMemo(() => {
    if (!callLog.createdAt || !callLog.createdBy) return false

    const twoHoursAgo = subHours(new Date(), 2)
    const oldAttempt = isBefore(callLog.createdAt, twoHoursAgo)
    const differentUsers = userId !== callLog.createdBy

    return oldAttempt || differentUsers
  }, [userId, callLog.createdAt, callLog.createdBy])

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography>Contact Attempt: </Typography>
        <Typography>{patientInfo.name}</Typography>

        <Typography>Status:</Typography>
        {callLog.attemptId ? (
          <Typography color="secondary">In Progress</Typography>
        ) : (
          <Typography>Not Started</Typography>
        )}

        {stale && (
          <Typography className={classes.stale} color="error" variant="h6">
            This contact attempt was left open. Please skip any remaining
            numbers and complete the attempt.
          </Typography>
        )}
      </div>

      <Divider className={classes.divider} />

      {callLog.lastUnsuccessfulVisitDate && (
        <Typography variant="body2" color="error">
          {`Patient had unsuccessful encounter on ${format(
            callLog.lastUnsuccessfulVisitDate,
            'M/d/yyyy'
          )}`}
        </Typography>
      )}

      {patientInfo.status === REFERRED && (
        <Typography variant="body2">
          {callLog.attemptCount} completed call attempts for this patient
        </Typography>
      )}

      {callLog.lastMessageDate && (
        <Typography variant="body2">
          Last voicemail left on {format(callLog.lastMessageDate, 'M/d/yyyy')}
        </Typography>
      )}

      {!warnings.isEmpty() &&
        warnings.map(({ warning }) => (
          <div key={warning} className={classes.warningContainer}>
            <Icon className={classes.icon} color="secondary" fontSize="small">
              warning
            </Icon>
            <Typography variant="body2">{warning}</Typography>
          </div>
        ))}

      {(callLog.lastUnsuccessfulVisitDate ||
        patientInfo.status === REFERRED ||
        callLog.lastMessageDate ||
        !warnings.isEmpty()) && <Divider className={classes.divider} />}
    </div>
  )
}

export default Header
