import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { getPatientId } from '~/apps/patientRecord/data/patientId'
import { fetchPatient } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'
import { fetchReferralCases, updateReferralCase } from '../data/referrals'

export default (action$, state$) =>
  action$.pipe(
    ofType(updateReferralCase.SUCCEEDED),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId =>
      of(
        fetchPatient.requested(patientId),
        fetchReferralCases.requested(patientId)
      )
    )
  )
