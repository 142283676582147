import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'healthPlans'

export const FETCHED_HEALTH_PLANS = type(rootKey, 'FETCHED_HEALTH_PLANS')

export const loadHealthPlans = creator(FETCHED_HEALTH_PLANS, 'healthPlans')

export default createReducer(key, new Map(), {
  [FETCHED_HEALTH_PLANS]: (state, { payload: { healthPlans } }) =>
    normalize(
      healthPlans.map(hp => ({
        id: hp.HealthPlanID,
        name: hp.HealthPlanName,
      }))
    ),
})

export const getHealthPlans = pipe(getRoot, get(key))

export const fetchHealthPlans = () => AspireAPI.get('v1/health_plan/')
