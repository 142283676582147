import { formatDuration } from 'date-fns'
import * as E from 'fp-ts/lib/Either'
import { AsOpaque, AType, EType, summon } from '~/utils/type'

const Interval_ = summon(F =>
  F.interface(
    {
      months: F.number(),
      days: F.number(),
      secs: F.number(),
      microsecs: F.number(),
    },
    'Interval'
  )
)
export interface Interval extends AType<typeof Interval_> {}
export interface IntervalRaw extends EType<typeof Interval_> {}
export const Interval = AsOpaque<IntervalRaw, Interval>()(Interval_)

export const formatInterval = (
  interval: Interval,
  monthsInYear = 12
): E.Either<string, string> => {
  const [years, months] = [
    Math.floor(interval.months / monthsInYear),
    interval.months % monthsInYear,
  ]

  return E.tryCatch(
    () =>
      formatDuration({
        years,
        months,
        days: interval.days,
      }),
    () => 'Unknown Duration'
  )
}
