// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const RECYCLE_CONTACT_ID_SET = 'reconciliation/RECYCLE_CONTACT_ID_SET'
export const RECYCLE_CONTACT_ID_CLEARED =
  'reconciliation/RECYCLE_CONTACT_ID_CLEARED'

// ACTION CREATORS
export const recycleContactIdSet = (contactId: any) => ({
  type: RECYCLE_CONTACT_ID_SET,
  contactId,
})
export const recycleContactIdCleared = () => ({
  type: RECYCLE_CONTACT_ID_CLEARED,
})

// KEY
export const key = 'recycleContactId'

// REDUCERS
export default createReducer(key, null, {
  [RECYCLE_CONTACT_ID_SET]: (_state: any, { contactId }: any) => contactId,
  [RECYCLE_CONTACT_ID_CLEARED]: () => null,
})

// SELECTORS
export const getRecycleContactId = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
