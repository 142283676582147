import React, { PureComponent } from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from '@material-ui/core/TextField'

class AttachmentsFilter extends PureComponent {
  render() {
    return (
      <TextField fullWidth label="Filter" onChange={this.props.handleChange} />
    )
  }
}

AttachmentsFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
}

export default AttachmentsFilter
