import {
  AOfMorphADT,
  AsOpaque,
  AType,
  Ctor,
  EType,
  ReduxAction,
  summon,
  Variant,
} from '~/utils/type'
import {
  State as FinalizationS,
  Action as FinalizationA,
} from './data/finalizationT'
import { State as ReviewS, Action as ReviewA } from './data/reviewT'

const State_ = summon(F =>
  F.interface(
    {
      review: ReviewS(F),
      finalization: FinalizationS(F),
    },
    'State'
  )
)
export interface State extends AType<typeof State_> {}
export interface StateRaw extends EType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)

const Finalization = Ctor('Finalization', FinalizationA)
const Review = Ctor('Review', ReviewA)

export const Action = Variant({
  Review,
  Finalization,
})
export type Action = ReduxAction<'LLOS', AOfMorphADT<typeof Action>>
