import cx from 'classnames'
import React from 'react'
import { Navigate as navigateConstants } from 'react-big-calendar'
import { useAction } from '~/hooks'
import { formatDatepickerDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  ButtonGroup,
  Fab,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { dateSet } from '../../data/calendar'
import { TimeZoneNote } from '../../data/common/shared'
import { newEventOpened } from '../../data/events'
import AddPlaceholderEventButton from './AddPlaceholderEventButton'
import AddVisitEventButton from './AddVisitEventButton'
import TimeZoneSelector from './TimeZoneSelector'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, zIndex: { drawer } }) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      minHeight: 72,
      alignItems: 'center',
      width: '100%',
      overflow: 'auto',
    },
    calendarTitle: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexBasis: '100%',
      justifyContent: 'center',
      order: 1,
      '& button': {
        color: palette.calendarTitleText.color,
      },
    },
    calendarTitleWide: {
      marginTop: spacing(1),
      [breakpoints.up('sm')]: {
        margin: [spacing(1), 0],
      },
    },
    leftActions: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginBottom: spacing(2),
      order: 3,
      '& > *:not(:last-child)': {
        marginRight: spacing(1),
      },
    },
    leftActionsWide: {
      [breakpoints.up('sm')]: {
        flex: 'none',
        order: 2,
      },
    },
    removeButtons: {
      width: '100%',
    },
    rightActions: {
      flex: 1,
      flexBasis: '100%',
      margin: spacing(1),
      order: 2,
      textAlign: 'center',
    },
    rightActionsWide: {
      [breakpoints.up('sm')]: {
        flexBasis: 0,
        margin: [0, 0, spacing(2)],
        textAlign: 'right',
      },
    },
    label: {
      color: palette.calendarTitleText.color,
      margin: [0, spacing(1)],
    },
    view: {
      marginLeft: spacing(1),
    },
    fab: {
      bottom: spacing(5),
      position: 'fixed',
      right: spacing(5),
      zIndex: drawer + 1,
    },
    addEvent: {
      marginLeft: spacing(4),
    },
    iconButton: {
      padding: [5, 0],
      minWidth: 0,
      width: 40,
    },
    startIcon: {
      margin: 0,
    },
    todayButton: {
      zIndex: 10,
    },
    datePickerContainer: {
      position: 'relative',
    },
    datePicker: {
      position: 'absolute',
      background: 'inherit',
      border: 'none',
      left: -72,
      opacity: 0,
      top: 0,
      width: 64,
      '&::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&::-webkit-clear-button': {
        display: 'none',
      },
      '&::-webkit-calendar-picker-indicator': {
        cursor: 'pointer',
        width: 32,
        height: 32,
      },
    },
    '@media (max-width: 800px)': {
      label: {
        fontSize: '2em',
      },
    },
    '@media (min-width: 1090px)': {
      calendarTitleWide: {
        flexBasis: 0,
        margin: 0,
        order: 2,
      },
      label: {
        fontSize: '1.8em',
      },
      leftActionsWide: {
        flex: '1 0',
        justifyContent: 'normal',
        margin: 0,
        order: 1,
      },
      rightActionsWide: {
        flex: 1,
        margin: 0,
        order: 3,
      },
    },
    '@media (min-width: 1150px)': {
      calendarTitleNarrow: {
        marginBottom: spacing(1),
      },
      leftActionsNarrow: {
        flex: 'none',
        order: 2,
      },
      rightActionsNarrow: {
        flexBasis: 0,
        margin: [0, 0, spacing(2)],
        textAlign: 'right',
      },
    },
    '@media (min-width: 1400px)': {
      label: {
        fontSize: '2.125rem',
      },
    },
  })
)

const CalendarToolbar = ({
  currentRoute,
  date,
  hideAdd,
  label,
  onNavigate,
  onView,
  timeZoneNotes,
  view,
  viewLocked,
  views,
  width,
}) => {
  const classes = useStyles()
  const onDateChange = useAction(dateSet)
  const openNewEvent = useAction(newEventOpened)

  const onChange = e => {
    const value = e.target.value

    onDateChange(value)
  }

  const toolbarDate = formatDatepickerDate(date)
  const fullWidth = !viewLocked || width === 12
  const isDashboardHome = currentRoute.slice(-4) === 'home'

  return (
    <div className={classes.container}>
      <div
        className={cx(classes.leftActions, {
          [classes.leftActionsWide]: fullWidth,
          [classes.leftActionsNarrow]: !fullWidth,
          [classes.removeButtons]: isDashboardHome,
        })}
      >
        <TimeZoneSelector timeZoneNotes={timeZoneNotes} />

        <Tooltip title="Jump To Today">
          <Button
            variant="outlined"
            classes={{ startIcon: classes.startIcon }}
            className={cx(classes.iconButton, classes.todayButton)}
            onClick={() => onNavigate(navigateConstants.TODAY)}
            startIcon={<Icon>today</Icon>}
          ></Button>
        </Tooltip>

        <Button
          variant="outlined"
          classes={{ startIcon: classes.startIcon }}
          className={cx(classes.iconButton, classes.dateButton)}
          startIcon={<Icon>date_range</Icon>}
        ></Button>

        <div className={classes.datePickerContainer}>
          <input
            className={classes.datePicker}
            type="date"
            onChange={onChange}
            value={toolbarDate}
          />
        </div>
      </div>

      <div
        className={cx(classes.calendarTitle, {
          [classes.calendarTitleWide]: fullWidth,
          [classes.calendarTitleNarrow]: !fullWidth,
        })}
      >
        <IconButton onClick={() => onNavigate(navigateConstants.PREVIOUS)}>
          <Icon>chevron_left</Icon>
        </IconButton>

        <Typography
          variant={view === 'agenda' ? 'h5' : 'h4'}
          className={classes.label}
        >
          {label}
        </Typography>

        <IconButton onClick={() => onNavigate(navigateConstants.NEXT)}>
          <Icon>chevron_right</Icon>
        </IconButton>
      </div>

      <div
        className={cx(classes.rightActions, {
          [classes.rightActionsWide]: fullWidth,
          [classes.rightActionsNarrow]: !fullWidth,
        })}
      >
        {viewLocked && !isDashboardHome && (
          <div>
            <AddPlaceholderEventButton />
            <AddVisitEventButton />
          </div>
        )}
        {!viewLocked && (
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            {views.map(v => (
              <Button
                key={v}
                className={classes.view}
                onClick={() => onView(v)}
                color={v === view ? 'primary' : 'default'}
              >
                {v}
              </Button>
            ))}
          </ButtonGroup>
        )}

        {!hideAdd && (
          <Tooltip title="Create New Calendar Event">
            <Fab
              aria-label="Create New Calendar Event"
              className={classes.fab}
              color="primary"
              onClick={openNewEvent}
            >
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

CalendarToolbar.propTypes = {
  currentRoute: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  timeZoneNotes: PropTypes.arrayOf(TimeZoneNote),
  hideAdd: PropTypes.bool,
  viewLocked: PropTypes.bool,
  views: PropTypes.array,
  view: PropTypes.string,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  width: PropTypes.number,
}

export default CalendarToolbar
