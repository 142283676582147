import { Map, Record } from 'immutable'
import { get } from 'lodash'
import moment from 'moment'
import { combineEpics, ofType } from 'redux-observable'
import { createSelector } from 'reselect'
import { of } from 'rxjs'
import { filter, map, mergeMap, pluck, withLatestFrom } from 'rxjs/operators'
import { calcFormData } from '~/components/JsonForm'
import { Form } from '~/data/form'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error no export
import Request from '~/utils/Request'
// @ts-expect-error no export
import createReducer from '~/utils/createReducer'
import { get as getKey, into, scopedCreator } from '~/utils/data'

const key = 'highRiskHuddle'

// Types
const Row = Record({
  id: null,
  // hrh grid on user dashboards.
  patientId: null,
  patientName: null,
  // patient's nominations
  nominatedBy: null,
  nominatedById: null,
  program: null,
  nominatedAt: null,
  completedAt: null,
  targetDiscussionDate: null,
  notes: null,
  reason: null,
  activeHospitalization: null,
  nextTargetedVisitDate: null,
  lastVisitDate: null,
  limitedPrognosis: null,
  recentHospitalization: null,
  activeSnf: null,
  recentSnf: null,
  overdue: null,
  status: null,
  substatus: null,
  market: null,
  careTeamLabel: null,
})

const Nomination = Record({
  id: null,
  patientId: null,
  completedAt: null,
  cancelledAt: null,
  nominatedAt: null,
  nominatedById: null,
  nominatedBy: null,
  targetDiscussionDate: null,
  reason: '',
  notes: '',
  form: Form(),

  errors: Map(),
})

// Actions
const creator = scopedCreator(key)

export const closeDialog = creator('CLOSE_DIALOG', false)
export const openDialog = creator('OPEN_DIALOG', false)

export const fetchHighRiskHuddle = Request({
  typePrefix: key,
  typeBase: 'FETCH_HIGH_RISK_HUDDLE',
  requestParams: ['userId'],
  operation: (userId: string) =>
    AspireAPI.get(`v1/user/${userId}/high_risk_huddle`),
  transform: into(Row, 'id'),
  messages: {
    failed: 'There was an issue fetching your lists',
  },
})

export const fetchNominations = Request({
  typePrefix: key,
  typeBase: 'FETCH_NOMINATIONS',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`v1/patients/${patientId}/nominations`),
  transform: into(Row, 'id'),
  messages: {
    failed: "There was an issue fetching the patients' nominations.",
  },
})

export const fetchNomination = Request({
  typePrefix: key,
  typeBase: 'FETCH_NOMINATION',
  requestParams: ['id'],
  operation: (id: number) => AspireAPI.get(`v1/nominations/${id}`),
  transform: (nom: any) => {
    const { form } = nom
    const { data, schema, context, tags } = form

    return Nomination(nom).set(
      'form',
      Form({
        ...form,
        data: calcFormData({ context, schema, tags, formData: data }),
      })
    )
  },
  messages: {
    failed: 'There was an issue fetching your lists',
  },
})

export const completeNomination = Request({
  typePrefix: key,
  typeBase: 'COMPLETE_NOMINATION',
  requestParams: ['params'],
  operation: (id: number) =>
    AspireAPI.patch(`v1/nominations/${id}`, { event: 'completed' }),
  messages: {
    succeeded: 'Nomination Completed',
    failed: 'Request to complete nomination failed.',
  },
})

export const cancelNomination = Request({
  typePrefix: key,
  typeBase: 'CANCEL_NOMINATION',
  requestParams: ['params'],
  operation: (id: number) =>
    AspireAPI.patch(`v1/nominations/${id}`, { event: 'cancelled' }),
  messages: {
    succeeded: 'Nomination Cancelled',
    failed: 'Request to cancel nomination failed.',
  },
})

// epic
const createNominationEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(createNomination),
    pluck('payload', 'patientId'), // patientId
    withLatestFrom(state$),
    mergeMap(([patientId, state]) => {
      const { reason, notes, targetDiscussionDate } = getNomination('new')(
        state
      )
      return of(
        createNominationReq.requested({
          patientId,
          notes,
          reason,
          targetDiscussionDate,
        })
      )
    })
  )
export const createNomination = creator('CREATE_NOMINATION', ['patientId'])
const createNominationReq = Request({
  typePrefix: key,
  typeBase: 'CREATE_NOMINATION_REQ',
  requestParams: ['params'],
  operation: ({ patientId, notes, reason, targetDiscussionDate }: any) =>
    AspireAPI.post(`v1/patients/${patientId}/nominations`, {
      notes,
      reason,
      targetDiscussionDate,
    }),
  messages: {
    succeeded: 'Patient successfully nominated for High Risk Huddle',
    failed: (e: any) =>
      (
        (e.response.data.message || {}).patient || [
          'Failed to nominate patient',
        ]
      ).join(),
  },
})

export const modifyDiscussion = creator('MODIFY_DISCUSSION', [
  'id',
  'data',
  'errored',
])

const updateDiscussionReq = Request({
  typePrefix: key,
  typeBase: 'UPDATE_DISCUSSION',
  requestParams: ['params'],
  operation: ({ id, data }: any) =>
    AspireAPI.patch(`v1/nominations/${id}/discussion`, { data }),
  messages: {
    failed: 'Note not saved',
  },
})
const updateDiscussionEpic = (action$: any) =>
  action$.pipe(
    ofType(modifyDiscussion),
    pluck('payload'),
    filter(({ data }) => {
      const notes = data.discussionItem.discussionItemArray
      const last = notes[notes.length - 1] || {}
      return get(last, 'itemSaved.saved', false)
    }),
    map(updateDiscussionReq.requested)
  )

export const modifyNomination = creator(
  'MODIFY_NOMINATION',
  (id: number, payload: any) => ({
    id: id || 'new',
    ...payload,
  })
)
export const updateNomination = Request({
  typePrefix: key,
  typeBase: 'UPDATE_NOMINATION',
  requestParams: ['params'],
  operation: ({ id, ...details }: any) =>
    AspireAPI.patch(`v1/nominations/${id}`, details),
  messages: {
    succeeded: 'Nomination successfully updated',
    failed: 'Nomination could not be saved',
  },
})

export const epic = combineEpics(createNominationEpic, updateDiscussionEpic)

// Reducer
const initialState = Map({
  dialog: false,
  index: Map(),
  nominations: Map(),
  details: Map({ new: Nomination() }),
})

export default createReducer(key, initialState, {
  // Modal functions
  // @ts-expect-error can we deal with the toString being automatically called?
  [closeDialog]: (state: any) => state.set('dialog', false),
  // @ts-expect-error can we deal with the toString being automatically called?
  [openDialog]: (state: any) => state.set('dialog', true),

  // Create
  [createNominationReq.FAILED]: (state: any, { payload: e }: any) =>
    state.setIn(['details', 'new', 'errors'], e.response.data.message),
  [createNominationReq.REQUESTED]: (state: any) =>
    state.setIn(['details', 'new', 'errors'], Map()),
  [createNominationReq.SUCCEEDED]: (state: any) =>
    state.setIn(['details', 'new'], Nomination()).set('dialog', false),

  // Read: HighRiskHuddle.index
  [fetchHighRiskHuddle.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set('index', payload),

  // Read: Nominations.index
  [fetchNominations.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set('nominations', payload),
  // Read: show
  [fetchNomination.SUCCEEDED]: (state: any, { payload }: any) =>
    state.setIn(['details', payload.id], payload),

  // Update
  // @ts-expect-error can we deal with the toString being automatically called?
  [modifyNomination]: (state: any, { payload }: any) =>
    state.updateIn(['details', payload.id], (nom: any) => nom.merge(payload)),
  // @ts-expect-error can we deal with the toString being automatically called?
  [modifyDiscussion]: (state: any, { payload: { id, data, errored } }: any) =>
    state.updateIn(['details', id, 'form'], (maybeForm: any) => {
      const form = maybeForm || Form()
      const { context, schema, tags } = form
      return form
        .set('errored', errored)
        .set('data', calcFormData({ context, schema, tags, formData: data }))
    }),
})

const getRoot = getKey(key)
// @ts-expect-error Object is unknown
export const getHighRiskHuddle = (state: any) => getRoot(state).get('index')
// @ts-expect-error Object is unknown
export const isDialogOpen = (state: any) => getRoot(state).get('dialog')
// @ts-expect-error Object is unknown
export const getNominations = (state: any) => getRoot(state).get('nominations')
export const getNomination = (id: number | string = 'new') => (state: any) =>
  // @ts-expect-error Object is unknown
  getRoot(state).getIn(['details', id])

// Selectors
export const getCountOverdueNominations = createSelector(
  [getHighRiskHuddle],
  (nominations: any) =>
    nominations.filter((n: any) => moment(n.targetDiscussionDate) <= moment())
      .size
)
