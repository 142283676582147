import { ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ap... Remove this comment to see the full error message
import { fetchAssessments } from '~/apps/assessment/data/assessments'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchSelectedAPPs } from '~/features/scheduling/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { fetchAppOptions } from '../data/appOptions'
import { getCurrentUserId } from '../data/currentUserId'
import router from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(changedTo(router)),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(getCurrentUserId),
    distinctUntilChanged(),
    mergeMap(userId =>
      of(
        fetchSelectedAPPs.requested(userId),
        ownerTasksFetchRequested(userId),
        fetchEpisodesOfCare.requested({ ownerId: userId }),
        fetchAppOptions.requested(),
        fetchAssessments.requested({
          provider_ids: [userId],
          statuses: ['not_started', 'created', 'in_progress'],
        })
      )
    )
  )
