import React from 'react'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { eventTypeChanged, subjectChanged } from '../../../data/events'
import {
  BatchSettingsField,
  CreatedModifiedField,
  LocationField,
  SelectField,
  StartEndField,
  TextField,
} from '../../EventFields'

const IncentiveDayEventDetail = ({ userId, event, editing }) => {
  const onEventTypeChange = useAction(eventTypeChanged)
  const onSubjectChange = useAction(subjectChanged)

  return (
    <React.Fragment>
      <SelectField
        label="Type"
        value={event.eventType}
        field="calendar_event_subtype_incentive_day"
        editing={editing}
        onChange={onEventTypeChange}
      />
      <TextField
        label="Notes"
        value={event.subject}
        editing={editing}
        onChange={onSubjectChange}
      />
      <StartEndField event={event} editing={editing} />
      <LocationField value={event.location} editing={editing} />
      <BatchSettingsField userId={userId} event={event} editing={editing} />
      <CreatedModifiedField event={event} />
    </React.Fragment>
  )
}

IncentiveDayEventDetail.propTypes = {
  userId: PropTypes.string.isRequired,
  event: PropTypes.record.isRequired,
  editing: PropTypes.bool,
}

export default IncentiveDayEventDetail
