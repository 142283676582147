import {
  Status,
  Action,
  State,
  DialogState,
  IndexState,
  NewState,
} from './statusT'
import { Action as Covid19 } from '../covid19T'
import { JsonFormT } from '~/data/form'
import { Action as ReduxA, State as ReduxS } from '~/reducerT'
import Aspire from '~/resources/aspire-strict'
import { Request } from '~/utils/Requestable'
import { Async } from '~/resources/resource-strict'
import * as Toast from '~/utils/toast'
import { constant, flow } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { payloadGet } from '~/utils/type'
import * as A from 'fp-ts/Array'
import { ord, ordDate } from 'fp-ts/Ord'
import * as O from 'fp-ts/Option'
import * as DE from '@nll/datum/DatumEither'

export const init: State = {
  dialog: DialogState.of.Closed({}),
  // NOTE: new & index are equivalents.
  new_: NewState.of.Initial({}),
  index: DE.initial,
}

export const new_ = (
  patientId: string // TODO: AGHGHGHGHGHG newtypes...
) =>
  Request<Status>(
    Aspire.get(
      `v1/patients/${patientId}/covid19_status/new`,
      Status.strictType.asDecoder()
    ),
    payload =>
      ReduxA.of.Covid19({
        payload: Covid19.of.Status({ payload: Action.of.New({ payload }) }),
      })
  )

export const index = (
  patientId: string // TODO: AGHGHGHGHGHG newtypes...
) =>
  Request<Status[]>(
    Aspire.get(
      `v1/patients/${patientId}/covid19_status`,
      t.array(Status.strictType).asDecoder()
    ),
    payload =>
      ReduxA.of.Covid19({
        payload: Covid19.of.Status({ payload: Action.of.Fetch({ payload }) }),
      })
  )

export const create = (patientId: string, status: string, notes: string) =>
  Request<Status>(
    Aspire.post(
      `v1/patients/${patientId}/covid19_status`,
      Status.strictType.asDecoder(),
      { status, notes }
    ),
    payload =>
      ReduxA.of.Covid19({
        payload: Covid19.of.Status({ payload: Action.of.Create({ payload }) }),
      }),
    {
      toast: Toast.message(
        _ => 'COVID-19 status update created.',
        _ => O.some('Could not save COVID-19 status update.')
      ),
    }
  )

export const show = (id: number) =>
  Request<Status>(
    Aspire.get(`v1/covid19_status/${id}`, Status.strictType.asDecoder()),
    payload =>
      ReduxA.of.Covid19({
        payload: Covid19.of.Status({ payload: Action.of.Show({ payload }) }),
      })
  )

export const update = (id: number, notes: string) =>
  Request<Status>(
    Aspire.patch(`v1/covid19_status/${id}`, Status.strictType.asDecoder(), {
      notes,
    }),
    payload =>
      ReduxA.of.Covid19({
        payload: Covid19.of.Status({ payload: Action.of.Update({ payload }) }),
      })
  )

export const updateDiscussion = (id: number) => (form: JsonFormT) =>
  Request<JsonFormT>(
    Aspire.patch(
      `v1/covid19_status/${id}/discussion`,
      JsonFormT.strictType.asDecoder(),
      { form }
    ),
    data =>
      ReduxA.of.Covid19({
        payload: Covid19.of.Status({
          payload: Action.of.Comment({ payload: { id, data } }),
        }),
      })
  )

// Verbose / Explicit alternative to using ReduxS.lensFromPath(['some', 'path']).modify
// const Update = ({ payload }) => old =>
//   DE.isRepleteRight(payload) && DE.isRepleteRight(old.covid19Status.index)
//     ? {
//         ...old,
//         index: pipe(
//           old.covid19Status.index,
//           // TODO: This should REALLY BE a Map but DevXGrid wants a list I guess?
//           DE.map(
//             A.map((o: Status) =>
//               o.id === payload.value.right.id ? payload.value.right : o
//             )
//           )
//         ),
//       }
//     : old,
const Update = ({ payload }: { payload: Async<Status> }) =>
  ReduxS.lensFromPath(['covid19', 'status', 'index']).modify(oldState =>
    DE.isRepleteRight(payload) && DE.isRepleteRight(oldState)
      ? DE.map(
          A.map((o: Status) =>
            o.id === payload.value.right.id ? payload.value.right : o
          )
        )(oldState)
      : oldState
  )

// NOTE: Typescript YUNO Pattern Matching :argfist:
export const reducer: (_: Action) => (_: ReduxS) => ReduxS = flow(
  payloadGet,
  Action.matchStrict({
    Update,
    Show: Update,
    Create: ({ payload: new_ }) =>
      ReduxS.lensFromPath(['covid19', 'status']).modify(oldState =>
        DE.isRepleteRight(new_) && DE.isRepleteRight(oldState.index)
          ? {
              ...oldState,
              index: DE.map(
                A.map((o: Status) =>
                  o.id === new_.value.right.id ? new_.value.right : o
                )
              )(oldState.index),
              dialog: DialogState.of.Closed({}),
            }
          : DE.isRepleteRight(new_)
          ? { ...oldState, new_, dialog: DialogState.of.Closed({}) }
          : { ...oldState, new_ }
      ),

    New: flow(
      payloadGet,
      ReduxS.lensFromPath(['covid19', 'status', 'new_']).set
    ),

    //   // NOTE: maybe consider keeping existing state across pages :shrug:
    Fetch: flow(
      payloadGet,
      ReduxS.lensFromPath(['covid19', 'status', 'index']).set
    ),
    Dialog: flow(
      payloadGet,
      ReduxS.lensFromPath(['covid19', 'status', 'dialog']).set
    ),
    Comment: ({ payload: { id, data } }) =>
      ReduxS.lensFromPath(['covid19', 'status', 'index']).modify(oldState =>
        DE.isRepleteRight(data) && DE.isRepleteRight(oldState)
          ? DE.map(
              A.map((o: Status) =>
                o.id === id ? { ...o, form: O.some(data.value.right) } : o
              )
            )(oldState)
          : oldState
      ),
  })
)

// NOTE: This disables the discussion for a given update record
// if it was not the latest status update.
export const isLatest: (_: Status) => (async: IndexState) => boolean = record =>
  DE.squash(
    constant(false),
    constant(false),
    flow(
      A.sort(ord.contramap(ordDate, Status.lensFromProp('insertedAt').get)),
      A.head,
      O.fold(constant(false), (x: Status) => x.id === record.id)
    )
  )
