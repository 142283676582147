import React, { useMemo } from 'react'
import { renderFieldValueOptions } from '~/components/fields'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { useAction, useFieldValuesOld } from '~/hooks'
import useMountEffect from '~/hooks/useMountEffect'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'

const FieldValueSelector = ({ field, value, onChange, ...props }) => {
  const fieldValues = useFieldValuesOld(field)

  const onFetchDistinctFieldValues = useAction(fetchDistinctFieldValues)

  const SelectProps = useMemo(
    () => ({
      native: true,
      value,
      onChange,
    }),
    [value, onChange]
  )

  useMountEffect(() => {
    onFetchDistinctFieldValues([field])
  })

  return (
    <TextField
      {...props}
      select
      SelectProps={SelectProps}
      margin="dense"
      disabled={fieldValues.isEmpty()}
    >
      <option />
      {renderFieldValueOptions(fieldValues)}
    </TextField>
  )
}

FieldValueSelector.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FieldValueSelector
