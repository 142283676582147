// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getUserQuery } from './userQuery'

const ACTIVE = 'active'

const creator = scopedCreator(rootKey)
export const activeChanged = creator('ACTIVE_CHANGED', ['active'])

export default createReducer(ACTIVE, 'active', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [activeChanged]: (_state: any, { payload: { active } }: any) => active,
})

export const getActive = pipe(getUserQuery, get(ACTIVE))
