import { ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import { combineLatest, filter, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
import {
  fetchAPPPatientCensus,
  fetchPatientLists,
  // @ts-expect-error no export
} from '~/features/patientCensus'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { getSelectedDashApp } from '../data/dashAppId'
import { APP_DASHBOARD_CENSUS, APP_DASHBOARD_MY_LISTS } from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(changedTo([APP_DASHBOARD_CENSUS, APP_DASHBOARD_MY_LISTS])),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(getSelectedDashApp),
    mergeMap(userId =>
      of(
        fetchAPPPatientCensus.requested(userId),
        fetchPatientLists.requested(userId)
      )
    )
  )
