import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

// KEY
const key = 'highPrioritySnackbarOpened'

// CONSTANTS
export const HIGH_PRIORITY_SNACKBAR_OPENED = type(
  key,
  'HIGH_PRIORITY_SNACKBAR_OPENED'
)
export const HIGH_PRIORITY_SNACKBAR_CLOSED = type(
  key,
  'HIGH_PRIORITY_SNACKBAR_CLOSED'
)

// ACTION CREATORS
export const highPrioritySnackbarOpened = creator(HIGH_PRIORITY_SNACKBAR_OPENED)
export const highPrioritySnackbarClosed = creator(HIGH_PRIORITY_SNACKBAR_CLOSED)

// REDUCER
export default createReducer(key, false, {
  [HIGH_PRIORITY_SNACKBAR_OPENED]: () => true,
  [HIGH_PRIORITY_SNACKBAR_CLOSED]: () => false,
})

// SELECTORS
export const getHighPrioritySnackbarOpened = pipe(getRoot, get(key))
