import { Map } from 'immutable'
import { createSelector, createStructuredSelector } from 'reselect'
import { getDate, getEvents, getTimeZone } from '~/features/calendarWidget/data'
import { utcToLocal } from '~/utils/dates'
import { getAllHealthplanAlerts } from '../healthplanAlerts'
import { getLocationA, getLocationB } from '../quickLocations'

export const getContext = createStructuredSelector({
  date: getDate,
})

export const getGeocodedEvents = createSelector(
  [getEvents, getDate, getTimeZone],
  (events, date, timeZone) =>
    events
      .filter(
        event =>
          utcToLocal(event.start, timeZone).isSame(date, 'day') &&
          event.location.latitude &&
          event.location.longitude
      )
      .sort((a, b) => a.start.diff(b.start))
)

export const getEventLabels = createSelector([getGeocodedEvents], events =>
  events.toIndexedSeq().reduce((eventLabels, event, index) => {
    const label = String(index + 1)

    return eventLabels.set(event.id, label).set(event.patientId, label)
  }, Map())
)

const getLabel = (eventLabels, locationA, locationB, healthPlanAlerts, id) => {
  if (locationA.id === id) return 'A'
  else if (locationB.id === id) return 'B'
  else if (eventLabels.has(id)) return eventLabels.get(id)
  else if (healthPlanAlerts.has(id)) return '!'
  else return ''
}

export const createGetLocationLabel = () =>
  createSelector(
    [
      getEventLabels,
      getLocationA,
      getLocationB,
      getAllHealthplanAlerts,
      (_state, id) => id,
    ],
    getLabel
  )

export const createGetLocationLabels = () =>
  createSelector(
    [
      getEventLabels,
      getLocationA,
      getLocationB,
      getAllHealthplanAlerts,
      (_state, patients) => patients,
    ],
    (eventLabels, locationA, locationB, healthPlanAlerts, patients) =>
      patients
        .toList()
        .map(patient =>
          getLabel(
            eventLabels,
            locationA,
            locationB,
            healthPlanAlerts,
            patient.id
          )
        )
        .filter(Boolean)
  )
