import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import rootKey from '../key'

const key = 'patientContactDialog'

const creator = scopedCreator(key)

export const addContactDialogOpened: any = creator('CONTACT_DIALOG_OPENED')
export const addContactDialogClosed: any = creator('CONTACT_DIALOG_CLOSED')

export const editContactDialogType: any = creator('EDIT_CONTACT_DIALOG_TYPE')
export const addContactDialogType: any = creator('ADD_CONTACT_DIALOG_TYPE')

const isArray = (data: any) =>
  Object.prototype.toString.call(data) == '[object Array]'

/**
 * Transform method to handle errors from server side.
 * For handling server error JSONs like {{"phones":["must have 1 primary"]},
 * {"atLeastOne":["must have at least one primary of [:phones, :emails]"]}}
 *
 * @param errorObject
 * @param genericSaveError
 * @returns
 */
const transformObjectError = (errorObject: any, genericSaveError: string) => {
  let errorMessage = ' '
  Object.entries(errorObject).forEach(([key, value]) => {
    errorMessage += isArray(value)
      ? `${key} ${(value as Array<string>).join()}. `
      : typeof value === 'string'
      ? value
      : ''
  })
  return errorMessage.trim() || genericSaveError
}

const transformServerError = (errors: any, genericSaveError: string) => {
  const errorType = typeof errors
  return errorType === 'object'
    ? transformObjectError(errors, genericSaveError)
    : errorType === 'string'
    ? errors
    : genericSaveError
}

export const transformErrorResponse = (error: any, isUpdate: boolean) => {
  const genericSaveError = !isUpdate
    ? 'Could not add contact'
    : 'Could not update contact'
  return error &&
    error.response &&
    error.response.data &&
    error.response.data.message
    ? transformServerError(error.response.data.message, genericSaveError)
    : genericSaveError
}

export const addContact = Request({
  typePrefix: key,
  typeBase: 'ADD_CONTACT',
  requestParams: ['patientId', 'contactInputs'],
  operation: (patientId: any, contactInputs: any) =>
    AspireAPI.post(`/v1/patient/${patientId}/contacts`, {
      ...contactInputs,
    }),
  messages: {
    succeeded: 'Contact added successfully',
    failed: (error: any) => transformErrorResponse(error, false),
  },
})

export const updateContact = Request({
  typePrefix: key,
  typeBase: 'UPDATE_CONTACT',
  requestParams: ['patientId', 'contactId', 'contactInputs'],
  operation: (patientId: string, contactId: number, contactInputs: any) =>
    AspireAPI.put(`v1/patient/${patientId}/contacts/${contactId}`, {
      ...contactInputs,
    }),
  messages: {
    succeeded: 'Contact updated successfully',
    failed: (error: any) => transformErrorResponse(error, true),
  },
})

export const contactDialog = createReducer(key, false, {
  [addContactDialogClosed]: () => false,
  [addContactDialogOpened]: () => true,
  [addContact.SUCCEEDED]: () => false,
  [updateContact.SUCCEEDED]: () => false,
  [addContact.FAILED]: () => false,
  [updateContact.FAILED]: () => false,
})

export const contactDialogType = createReducer('contactDialogType', 'Edit', {
  [editContactDialogType]: () => 'Edit',
  [addContactDialogType]: () => 'Add',
})

export const getDialogOpenValue = getIn([rootKey, key])
export const getDialogTypeValue = getIn([rootKey, 'contactDialogType'])
