import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import DisplayDate from '~/components/DisplayDate'
import WithTooltip from '~/components/WithTooltip'
import { Avatar, Button, Chip } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { getPatientAllergies } from '../../data/allergy'

const useToggle = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue)
  const toggle = useCallback(() => setValue(v => !v), [setValue])

  return [value, toggle]
}
const useStyles = makeStyles(({ spacing }) => ({
  filterContainer: {
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },
  chip: {
    textTransform: 'none',
    marginRight: spacing(1),
    opacity: 0.5,
  },
  selected: {
    opacity: 1,
  },
}))

const FilterChip = ({ count, label, onToggle, shown }) => {
  const classes = useStyles()
  return (
    <Chip
      avatar={<Avatar>{count}</Avatar>}
      component={Button}
      key={label}
      label={label}
      className={classnames(classes.chip, { [classes.selected]: shown })}
      onClick={onToggle}
      size="small"
    />
  )
}
FilterChip.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
}

const filterResolved = include => record =>
  include || record.resolutionStatus === 'Resolved'
const filterUnResolved = include => record =>
  include || record.resolutionStatus === 'UnResolved'

const columns = [
  {
    name: 'description',
    title: 'Allergy Description',
  },
  {
    name: 'onsetDate',
    title: 'Onset date',
    displayComponent: DisplayDate,
  },
  {
    name: 'reactionDescription',
    title: 'Reaction',
    displayComponent: WithTooltip,
  },
  {
    name: 'resolvedDate',
    title: 'Resolution date',
    displayComponent: DisplayDate,
  },
  {
    name: 'resolutionStatus',
    title: 'Resolution status',
  },
]

const defaultSorting = [{ columnName: 'admissionDate', direction: 'desc' }]

const AllergyListGrid = () => {
  const classes = useStyles()
  const [showResolved, toggleShowResolved] = useToggle(true)
  const [showUnResolved, toggleShowUnResolved] = useToggle(true)
  const allergies = useSelector(getPatientAllergies)
  const resolvedCount = useMemo(
    () => allergies.filter(x => x.resolutionStatus === 'Resolved').size,
    [allergies]
  )
  const unresolvedCount = useMemo(
    () => allergies.filter(x => x.resolutionStatus === 'UnResolved').size,
    [allergies]
  )

  const rows = useMemo(
    () =>
      allergies
        .valueSeq()
        .filter(filterResolved(showResolved))
        .filter(filterUnResolved(showUnResolved))
        .toJS(),
    [allergies, showResolved, showUnResolved]
  )

  return (
    <React.Fragment>
      <DevXTitleHeader title="Allergies" />
      <div className={classes.filterContainer}>
        <FilterChip
          label="Resolved"
          count={resolvedCount}
          onToggle={toggleShowUnResolved}
          shown={showUnResolved}
        />
        <FilterChip
          label="UnResolved"
          count={unresolvedCount}
          onToggle={toggleShowResolved}
          shown={showResolved}
        />
      </div>
      <Paper>
        <DevXGrid
          rows={rows}
          columns={columns}
          sortable
          defaultSorting={defaultSorting}
        />
      </Paper>
    </React.Fragment>
  )
}

export default AllergyListGrid
