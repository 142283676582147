import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { formatDate } from '~/utils/format'
import { TextField } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { importStatusMap } from '../../utils/medListConstants'

const lineStyles = ({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: spacing(3),
  },
  label: {
    flex: '1',
  },
  content: {
    flex: '2',
  },
})

const Line = ({ classes, label, value }) => (
  <React.Fragment>
    <div className={classes.root}>
      <Typography className={classes.label} variant="caption">
        {label}
      </Typography>
      <Typography className={classes.content}>
        {!isNil(value) ? value.toString() : '-'}
      </Typography>
    </div>
    <Divider />
  </React.Fragment>
)

Line.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
}

const L = withStyles(lineStyles)(Line)

const styles = {
  medDetail: {
    margin: '1rem',
    padding: '1rem',
    '& td': {
      whiteSpace: 'normal',
    },
  },
}

const MedDetail = ({ classes, row: med }) => {
  const importStatusUpdatedBy = med.importStatusUpdatedByRole
    ? `${med.importStatusUpdatedByName} - ${med.importStatusUpdatedByRole}`
    : med.importStatusUpdatedByName

  return (
    <Paper className={classes.medDetail}>
      <Typography variant="overline" color="primary">
        {med.drug}
      </Typography>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={6}>
          <L label="Compound" value={med.compound} />
          <L label="CSMD Attestation Date" value={med.csmdAttestationDate} />
          <L label="Days Supply" value={med.daysSupply} />
          <L label="Directions" value={med.directions} />
          <L label="Internal Note" value={med.internalNote} />
          <L label="Pharmacy Note" value={med.pharmacyNote} />
          <L
            label="Prescription Date"
            value={formatDate(med.prescriptionDate)}
          />
          <L label="Primary Indication" value={med.primaryIndication} />
          <L
            label="Primary Indication Other"
            value={med.primaryIndicationOther}
          />
          <L label="Quantity" value={med.qty} />
          <L label="Start Date" value={formatDate(med.startDate)} />
          <L label="Stop Date" value={formatDate(med.stopDate)} />
          <L label="Review Status" value={importStatusMap[med.importStatus]} />
          <L label="Review Date" value={formatDate(med.importDate)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <L label="Form" value={med.qtyQual} />
          <L label="Refills" value={med.refills} />
          <L label="Route Detail" value={med.routeDetail} />
          <L label="Schedule" value={med.schedule} />
          <L label="Secondary Indication" value={med.secondaryIndication} />
          <L
            label="Secondary Indication Other"
            value={med.secondaryIndicationOther}
          />
          <L label="Status" value={med.status} />
          <L
            label="Substitution Allowed Flag"
            value={med.substitutionAllowedFlag}
          />
          <L label="Supply" value={med.supply} />
          <L label="Adherence" value={med.adherence} />
          <L label="Updated" value={formatDate(med.modifiedAt)} />
          <L label="Updated By" value={med.modifiedByName} />
          <L label="Reviewed By" value={importStatusUpdatedBy} />
          <TextField
            id="defer-reason-input"
            label="Deferral Reason"
            value={med.deferredReason}
            InputLabelProps={{ shrink: true }}
            type="text"
            InputProps={{
              inputProps: { maxLength: 250 },
              required: true,
              multiline: true,
              rows: '4',
              readOnly: true,
            }}
            variant="outlined"
            style={{ marginTop: '20px', width: '532.7px' }}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

MedDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
}

export default withStyles(styles)(MedDetail)
