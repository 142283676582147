import React from 'react'
import PropTypes from '~/utils/propTypes'
import { FormControl, InputLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    flex: ({ flex }) => flex,
  },
}

class NamePartField extends React.Component {
  onChange = (value, errors) => {
    const { part, formData } = this.props
    const newValue = { ...formData, [part]: value }

    this.props.onChange(newValue, errors)
  }

  render() {
    const {
      classes,
      part,
      label,
      disabled,
      readonly,
      schema,
      uiSchema,
      idSchema,
      errorSchema,
      formData,
      registry,
    } = this.props

    if (!schema.properties[part]) {
      return null
    }

    const isRequired = schema.required
      ? schema.required.find(p => p === part)
      : false

    return (
      <FormControl
        className={classes.root}
        disabled={disabled}
        readOnly={readonly}
        error={errorSchema[part]}
        required={isRequired}
      >
        <InputLabel shrink>{label}</InputLabel>
        <registry.fields.StringField
          {...this.props}
          name={part}
          schema={schema.properties[part]}
          uiSchema={uiSchema[part]}
          idSchema={idSchema[part]}
          formData={formData[part]}
          onChange={this.onChange}
        />
      </FormControl>
    )
  }
}

NamePartField.propTypes = {
  classes: PropTypes.object.isRequired,
  part: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  flex: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  idSchema: PropTypes.object.isRequired,
  errorSchema: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  registry: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(NamePartField)
