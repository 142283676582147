import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchCaseManagers, getCaseManagersArr } from '../../data/caseManagers'

const columns = [
  { name: 'contactId', title: 'Contact Id' },
  { name: 'name', title: 'Name' },
  { name: 'email', title: 'Email' },
  { name: 'phone', title: 'Phone' },
  { name: 'title', title: 'Title' },
  { name: 'healthPlan', title: 'Health Plan Name' },
]
const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

const CaseManagerLookup = ({
  includeWrapper,
  rows,
  fetchCaseManagers,
  onSelect,
  rowActions,
  rowActionsCount,
}) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search Case Managers"
    columns={columns}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchCaseManagers}
    onSelect={onSelect}
    rowActions={rowActions}
    rowActionsCount={rowActionsCount}
  />
)

CaseManagerLookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchCaseManagers: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
}

export default connect(
  state => ({
    rows: getCaseManagersArr(state),
  }),
  { fetchCaseManagers: fetchCaseManagers.requested }
)(CaseManagerLookup)
