import { List, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { LEGACY_TASK, LT, ORDER, TASK } from './constants'

// Key
const ACTIONABLE_ITEMS = 'actionableItems'

// Record
export const ActionableItem = Record({
  assigneeName: null,
  dueDate: null,
  id: null,
  label: null,
  patientId: null,
  status: null,
  type: null,
})

// For later linking
const prefixId = item => {
  switch (item.type) {
    case LEGACY_TASK:
      return { ...item, id: LT + item.id }
    case ORDER:
    case TASK:
      return item
  }
}

// Request
export const fetchActionableItems = Request({
  typePrefix: ACTIONABLE_ITEMS,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`/v1/patients/${patientId}/actionable_items`),
  transform: items => List(items.map(compose(ActionableItem, prefixId))),
  messages: {
    failed: 'Failed to get actionable items',
  },
})

// Reducer
export default createReducer(ACTIONABLE_ITEMS, List(), {
  [fetchActionableItems.SUCCEEDED]: compose(payload, action),
})

// Selectors
const getActionableItems = get(ACTIONABLE_ITEMS)
const isOrder = item => item.type === ORDER

export const getActionableOrders = createSelector(getActionableItems, items =>
  items.filter(isOrder)
)

export const getActionableTasks = createSelector(getActionableItems, items =>
  items.filterNot(isOrder)
)
