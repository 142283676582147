import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import { getUsers } from '~/data/users'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatTimeZone } from '../../../../utils/format'
import { getSelectedAPPs } from '../../data/selectedAPPs'
import APPLink from './APPLink'
import ClearSelectedAPPs from './ClearSelectedAPPs'
import RowActions from './RowActions'
import SaveSelectedAPPs from './SaveSelectedAPPs'

const NURSE_PRACTITIONER = 'Nurse Practitioner'
const PHYSICIAN = 'Physician'

const useStyles = makeStyles(({ spacing }) => ({
  buttons: {
    marginBottom: spacing(1),
  },
  button: {
    marginRight: spacing(1),
  },
}))

const defaultSorting = [{ columnName: 'selected', direction: 'asc' }]
const columnExtensions = [{ columnName: 'selected', width: 150 }]

const APPSchedulingList = ({ userId, action }) => {
  const classes = useStyles()

  const users = useSelector(getUsers)
  const selectedAPPs = useSelector(getSelectedAPPs)

  const columns = useMemo(
    () => [
      {
        name: 'selected',
        title: 'Selected',
        getCellValue: ({ id }) =>
          selectedAPPs.has(id) ? 'Selected' : 'Unselected',
      },
      {
        name: 'name',
        title: 'Name',
        /* eslint-disable react/display-name */
        /* eslint-disable react/prop-types */
        displayComponent: ({ value, row: { id } }) => (
          <APPLink appId={id} name={value} action={action} />
        ),
        /* eslint-enable react/display-name */
        /* eslint-enable react/prop-types */
      },
      {
        name: 'marketName',
        title: 'Market',
      },
      {
        name: 'timeZone',
        title: 'Time Zone',
        getCellValue: ({ timeZone }) => timeZone && formatTimeZone(timeZone),
      },
    ],
    [selectedAPPs]
  )

  const rows = useMemo(
    () =>
      users
        .filter(
          user =>
            user.roleName === NURSE_PRACTITIONER || user.roleName === PHYSICIAN
        )
        .toIndexedSeq()
        .toArray(),
    [users, selectedAPPs]
  )

  return (
    <React.Fragment>
      <div className={classes.buttons}>
        <span className={classes.button}>
          <SaveSelectedAPPs userId={userId} />
        </span>

        <ClearSelectedAPPs />
      </div>

      <Paper>
        <DevXGrid
          rows={rows}
          columns={columns}
          columnExtensions={columnExtensions}
          sortable
          defaultSorting={defaultSorting}
          pageable
          filterable
          defaultPageSize={25}
          rowActions={RowActions}
          rowActionsCount={1}
        />
      </Paper>
    </React.Fragment>
  )
}

APPSchedulingList.propTypes = {
  userId: PropTypes.string.isRequired,
  action: PropTypes.string,
}

APPSchedulingList.defaultProps = {
  action: 'open',
}

export default APPSchedulingList
