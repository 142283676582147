import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
import { scopedCreator } from '~/utils/data'
import { clearTripNotes, fetchTripNotes } from '../data/tripNotes'

const key = 'tripNotes'

export const tripNotesRequested = scopedCreator(key)('TRIP_NOTES_REQUESTED', [
  'tripId',
])

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const loadTripNotes = action$ =>
  action$.pipe(
    ofType(tripNotesRequested),
    pluck('payload', 'tripId'),
    mergeMap((tripId: string) => {
      return [clearTripNotes(), fetchTripNotes.requested(tripId)]
    })
  )
