import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(0.5),
  },
}))

export default function BooleanFilterCell({
  filteringEnabled,
  filter,
  onFilter,
}) {
  const classes = useStyles()
  const hasFilter = filter && filter.value
  const toggle = () => onFilter(hasFilter ? null : { value: true })

  return (
    <Button
      size="small"
      color={hasFilter ? 'primary' : undefined}
      disabled={!filteringEnabled}
      variant="outlined"
      onClick={toggle}
    >
      {hasFilter ? (
        <React.Fragment>
          <Icon className={classes.icon}>check_box</Icon> Only
        </React.Fragment>
      ) : (
        'Filter'
      )}
    </Button>
  )
}

BooleanFilterCell.propTypes = {
  filter: PropTypes.object,
  filteringEnabled: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
}
