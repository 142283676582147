import classNames from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  aspireIcon: {
    lineHeight: '1',
  },
  small: {
    fontSize: 20,
  },
  regular: {
    fontSize: 24,
  },
})

type Props = {
  className?: string
  fontSize?: 'default' | 'small'
  children: string
}

export default function AspireIcon({
  children,
  fontSize = 'default',
  className,
  ...props
}: Props) {
  const classes = useStyles()

  const iconClassName = classNames(
    classes.aspireIcon,
    fontSize === 'default' ? classes.regular : classes.small,
    className,
    'aspire-icon',
    children
  )

  return <i {...props} className={iconClassName} />
}
