import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { fetchPatientDischarges, saveDischarge } from '../data/discharges'

export default (action$: any) =>
  action$.pipe(
    ofType(saveDischarge.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'params', 'patientId'),
    map(fetchPatientDischarges.requested)
  )
