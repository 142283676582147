import { changedTo } from 'redux-routable'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { getCurrentUserId } from '../data/currentUserId'
import router from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(changedTo(router)),
    switchTo(state$),
    map(getCurrentUserId),
    distinctUntilChanged(),
    map(ownerTasksFetchRequested)
  )
