import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import PropTypes from '~/utils/propTypes'
import {
  getCareTeamsWithUsers,
  getRoles,
  getTypes,
  requestFullCareTeamList,
} from '../data/careTeams'

const CareTeamSelectionGrid = ({ onCareTeamSelected, typeFilter }) => {
  const dispatch = useDispatch()
  const allCareTeams = useSelector(getCareTeamsWithUsers)
  const types = useSelector(getTypes)
  const roles = useSelector(getRoles)

  const careTeamOptions = useMemo(() => {
    if (typeFilter) {
      return allCareTeams.filter(ct => ct.type == typeFilter)
    } else {
      return allCareTeams
    }
  }, [allCareTeams, typeFilter])
  useEffect(() => {
    dispatch(requestFullCareTeamList())
  }, [])

  const columns = [
    {
      name: 'label',
      title: 'Label',
    },
    {
      name: 'primary_member',
      title: 'Primary Member',
      getCellValue: ({ primaryRole, members }) => {
        const primaryMember = members.filter(mem => mem.role == primaryRole)[0]
        return primaryMember && primaryMember.user
          ? primaryMember.user.name
          : ''
      },
    },
    {
      name: 'primaryRole',
      title: 'Primary Role',
      getCellValue: row => roles.getIn([row.primaryRole, 'label']),
    },
    {
      name: 'type',
      title: 'Type',
      getCellValue: row => types.getIn([row.type, 'label']),
    },
  ]

  const onSelectRow = row => {
    if (onCareTeamSelected) {
      const primaryMember = row.members.filter(
        mem => mem.role == row.primaryRole
      )[0]
      const primaryMemberId =
        primaryMember && primaryMember.user ? primaryMember.user.id : ''

      const primaryMemberName =
        primaryMember && primaryMember.user ? primaryMember.user.name : ''

      onCareTeamSelected({
        id: row.id,
        label: row.label,
        primaryMemberId,
        primaryMemberName,
      })
    }
  }

  return (
    <React.Fragment>
      <DevXGrid
        columns={columns}
        groupable
        onSelect={onSelectRow}
        pageable
        rows={careTeamOptions}
        searchable
        selectSingleRow
        selectable
        highlightSelectedRows
        sortable
      />
    </React.Fragment>
  )
}

CareTeamSelectionGrid.propTypes = {
  onCareTeamSelected: PropTypes.func,
  typeFilter: PropTypes.string,
}

export default CareTeamSelectionGrid
