import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchStakeholders } from '~/features/stakeholders'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { encounterReviewSelected } from '../data/currentEncounterReviewId'
import { getCurrentEncounterReview } from '../data/encounterReviews'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(encounterReviewSelected),
    switchTo(state$),
    map(getCurrentEncounterReview),
    pluck('patientId'),
    map(fetchStakeholders.requested)
  )
