import React from 'react'
import DevXGrid from '~/components/DevXGrid'
import PatientLink from '~/components/PatientLink'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { LegacyOnCall, OnCall } from '../data/onCalls'
import DateTime from './DateTime'
import HistoricalOnCallForm from './HistoricalOnCallForm'
import LegacyOnCallInfo from './LegacyOnCallInfo'

class OnCallGrid extends React.PureComponent {
  nameColumn = [
    {
      name: 'patient',
      title: 'Patient',
      displayComponent: ({ row: { patient } }) => (
        <PatientLink patientId={patient.id}>{patient.name}</PatientLink>
      ),
    },
  ]

  columns = [
    {
      name: 'completedBy',
      title: 'Completed By',
      displayComponent: ({ row: { user } }) => (
        <Typography userId={user.id}>{user.name}</Typography>
      ),
    },
    {
      name: 'dateTimeOfCall',
      title: 'Call Date/Time',
      displayComponent: ({ row: { dateTimeOfCall } }) => (
        <DateTime dateTime={dateTimeOfCall} />
      ),
    },
    {
      name: 'reasonForCall',
      title: 'Reason',
      wrapText: true,
    },
    {
      name: 'onCallActionTaken',
      title: 'Action Taken',
      wrapText: true,
    },
    {
      name: 'taskNeeded',
      title: 'Task Needed',
      wrapText: true,
    },
    { name: 'numberOfCalls', title: 'Number of Calls' },
    {
      name: 'typeOfCall',
      title: 'Type of Call',
    },
    {
      name: 'completedAt',
      title: 'Completed At',
      displayComponent: ({ row: { completedAt } }) => (
        <DateTime dateTime={completedAt} />
      ),
    },
  ]

  columnExtensions = [
    { columnName: 'dateTimeOfCall', width: 140 },
    { columnName: 'numberOfCalls', width: 140 },
  ]

  settings = {
    defaultSorting: [{ columnName: 'dateTimeOfCall', direction: 'desc' }],
    detailComponent: ({ row }) =>
      row.legacy ? (
        <LegacyOnCallInfo legacyOnCall={row} />
      ) : (
        <HistoricalOnCallForm form={row.form} />
      ),
    pageable: true,
    sortable: true,
  }

  render() {
    const { showPatientName } = this.props

    const columnsToUse = showPatientName
      ? this.nameColumn.concat(this.columns)
      : this.columns

    return (
      <DevXGrid
        onToggle={this.onToggle}
        rows={this.props.onCalls}
        columnExtensions={this.columnExtensions}
        columns={columnsToUse}
        {...this.settings}
      />
    )
  }
}

OnCallGrid.propTypes = {
  onCalls: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(LegacyOnCall),
      PropTypes.instanceOf(OnCall),
    ])
  ).isRequired,
  showPatientName: PropTypes.bool,
}

OnCallGrid.defaultProps = {
  showPatientName: false,
}

export default OnCallGrid
