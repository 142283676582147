import { combineEpics, ofType } from 'redux-observable'
import { concat, from, merge, of } from 'rxjs'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators'
import { downloadCSV } from '~/utils/csv'
import loading from '~/utils/loading'
import {
  DOWNLOAD_FINISHED,
  DOWNLOAD_REQUESTED,
  downloadFinished,
} from '../data/common/shared'
import {
  fetchPolygonPatients,
  getEMRPolygonPatients,
  getODSPolygonPatients,
  loadFetchedPolygonPatients,
  polygonPatientsCleared,
} from '../data/polygonPatients'
import { getSelectedPolygonsArea } from '../data/selectedPolygonsArea'
import showError from '../utils/showError'
import stringifyPath from '../utils/stringifyPath'

const downloadRequestedEpic = (action$, state$) =>
  action$.pipe(
    ofType(DOWNLOAD_REQUESTED),
    map(() => getSelectedPolygonsArea(state$.value)),
    filter(polygons => polygons.size > 0),
    mergeMap(polygons =>
      concat(
        of(polygonPatientsCleared()),
        loading(
          merge(
            ...polygons.toIndexedSeq().map(polygon => {
              const path = stringifyPath(polygon.get('path'))
              const name = polygon.get('name')
              const id = polygon.get('id')

              return from(fetchPolygonPatients(path)).pipe(
                map(pats => loadFetchedPolygonPatients(id, name, pats))
              )
            })
          ).pipe(catchError(showError('Failed to fetch Polygon Patients.')))
        ),
        of(downloadFinished())
      )
    )
  )

const downloadFinishedEpic = (action$, state$) =>
  action$.pipe(
    ofType(DOWNLOAD_FINISHED),
    tap(() => {
      const state = state$.value
      const emrPatients = getEMRPolygonPatients(state)
      const odsPatients = getODSPolygonPatients(state)

      // Browser doesn't like simultaneous file downloads, use timeout to get both
      if (emrPatients.size > 0) downloadCSV(emrPatients, 'emr_patients.csv')
      if (odsPatients.size > 0)
        setTimeout(() => {
          downloadCSV(odsPatients, 'ods_patients.csv')
        }, 500)
    }),
    ignoreElements()
  )

export default combineEpics(downloadRequestedEpic, downloadFinishedEpic)
