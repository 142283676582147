import { List, Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { FollowUp } from '../common/shared'
import { getTypes } from './root'

const CARE_PLAN_TYPES = 'carePlanTypes'

// RECORDS
export const GoalType = Record({
  id: null,
  rank: null,
  label: null,
  actionTypes: List(),
})

export const ActionType = Record({
  id: null,
  rank: null,
  goalTypeId: null,
  labelSelection: null,
  labelPlan: null,
  inputType: null,
  inputLabel: null,
  required: false,
  followUpDefinitions: List(),
})

// TRANSFORMERS
const transformActionTypes = (actionTypes: any) =>
  List(
    actionTypes.map(({ followUp, ...rest }: any) =>
      ActionType({
        ...rest,
        followUpDefinitions: List(followUp ? followUp.map(FollowUp) : null),
      })
    )
  ).sortBy((actionType: any) => actionType.rank)

const transformGoalTypes = (goalTypes: any) =>
  List(
    goalTypes.map(({ actionTypes, ...rest }: any) =>
      GoalType({ ...rest, actionTypes: transformActionTypes(actionTypes) })
    )
  ).sortBy((goalType: any) => goalType.rank)

const transformCarePlanTypes = (problemTypes: any) =>
  Map(
    problemTypes.map(({ id, goalTypes }: any) => [
      id,
      transformGoalTypes(goalTypes),
    ])
  )

// REQUESTS
export const fetchCarePlanTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CARE_PLAN_TYPES',
  requestParams: ['problemTypeIds'],
  operation: (problemTypeIds: any) =>
    AspireAPI.get(`care_plan/assessment_types`, {
      params: { problemTypeIds },
    }),
  transform: transformCarePlanTypes,
  messages: {
    failed: 'There was a problem fetching goals and actions',
  },
})

export default createReducer(CARE_PLAN_TYPES, Map(), {
  [fetchCarePlanTypes.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

const getCarePlanTypes = pipe(getTypes, get(CARE_PLAN_TYPES))

export const getProblemCarePlanTypes = (
  state: any,
  problemTypeId: number | undefined
  // @ts-expect-error Object is unknown
): any => getCarePlanTypes(state).get(problemTypeId, List())
