import { OrderedMap } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  dataCleared,
  getRoot,
  transformPatientOutlierReviews,
} from './common/shared'
import { completePatientOutlierReview } from './currentPatientOutlierReview'

const PATIENT_OUTLIER_REVIEWS = 'patientOutlierReviews'

export const fetchPatientOutlierReviews = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_OUTLIER_REVIEWS',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('patient_outliers/reviews', {
      params: {
        userId,
      },
    }),
  transform: transformPatientOutlierReviews,
  messages: {
    failed: 'There was an issue getting the patient outlier review list',
  },
})

const initState = OrderedMap()

export default createReducer(PATIENT_OUTLIER_REVIEWS, initState, {
  [fetchPatientOutlierReviews.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  [completePatientOutlierReview.SUCCEEDED]: (
    state: any,
    { meta: { request } }: any
  ) => state.delete(request.payload.id),
  // @ts-expect-error can we deal with the toString being automatically called?
  [dataCleared]: () => initState,
})

export const getPatientOutlierReviews = pipe(
  getRoot,
  get(PATIENT_OUTLIER_REVIEWS)
)

export const getPatientOutlierReviewsCount = (state: any) =>
  getPatientOutlierReviews(state).size
