import React from 'react'
import setDisplayName from '~/utils/setDisplayName'
import GeoMapContext from './GeoMapContext'

const withMap = BaseComponent => {
  const ComponentWithMap = props => (
    <GeoMapContext.Consumer>
      {({ map, setBounds, deleteBounds }) => (
        <BaseComponent
          {...props}
          map={map}
          setBounds={setBounds}
          deleteBounds={deleteBounds}
        />
      )}
    </GeoMapContext.Consumer>
  )

  setDisplayName(BaseComponent, ComponentWithMap, 'withMap')

  return ComponentWithMap
}

export default withMap
