import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// RECORDS
export const Status = Record({
  id: null,
  value: null,
  label: null,
})

// CONSTANTS
export const STATUSES_STORED = 'statuses/STATUSES_STORED'

// ACTION CREATORS
export const statusesStored = (statuses: any) => ({
  type: STATUSES_STORED,
  statuses,
})

// KEY
export const key = 'statuses'

// REDUCER
export default createReducer(key, Map(), {
  [STATUSES_STORED]: (state: any, { statuses }: any) =>
    state.mergeDeep(statuses),
})

export const mapData = (data: any) =>
  Map(data.map((d: any) => [d.id, Status(d)]))

// API
export const fetchStatuses = () =>
  AspireAPI.get('v1/referral/statuses').then(mapData)

// SELECTORS
export const getAllStatuses = (state: any) =>
  getReferralManagement(state).get(key)
export const getStatusValueLabelMap = createSelector(
  [getAllStatuses],
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'm' implicitly has an 'any' type.
  statuses => statuses.mapEntries(([, m]) => [m.value, m.label])
)
