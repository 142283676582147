import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

/* RECORD */
export const ReferringEntity = Record({
  id: null,
  name: null,
})

/* TYPE */
export const REFERRING_ENTITYS_STORED =
  'REFERRING_ENTITYS/REFERRING_ENTITYS_STORED'
export const REFERRING_ENTITY_QUERY_CHANGED =
  'REFERRING_ENTITYS/REFERRING_ENTITY_QUERY_CHANGED'

/* Actions */
export const referringEntitiesStored = (referringEntities: any) => ({
  type: REFERRING_ENTITYS_STORED,
  referringEntities,
})
export const referringEntityQueryChanged = (referringEntityName: any) => ({
  type: REFERRING_ENTITY_QUERY_CHANGED,
  referringEntityName,
})

/* SELECTORS */
export const getReferringEntities = (state: any) =>
  getReferralManagement(state).get('referringEntities', Map())

export const getReferringEntitiesByName = (state: any, name: any) =>
  getReferringEntities(state).filter((referringEntity: any) =>
    referringEntity.get('name').toLowerCase().includes(name.toLowerCase())
  )

export const getAutocompleteReferringEntitites = createSelector(
  [getReferringEntities],
  entities => {
    const results = entities.toList().map((entity: any) => ({
      label: entity.get('name'),
      value: entity.get('id'),
    }))
    return results.toJS()
  }
)

export const key = 'referringEntities'

/* REDUCER */
export default createReducer(key, Map(), {
  [REFERRING_ENTITYS_STORED]: (state: any, { referringEntities }: any) =>
    state.merge(referringEntities),
})

/* API */
export const fetchReferringEntitiesByName = (referringEntityName: any) =>
  AspireAPI.get('v1/referring_entity', {
    params: { referringEntityName },
  }).then((referringEntities: any) =>
    referringEntities.reduce((inc: any, item: any) => {
      const referringEntityToAdd = ReferringEntity({
        id: item.id,
        name: item.name,
      })
      return inc.set(item.id, referringEntityToAdd)
    }, Map())
  )
