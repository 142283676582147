import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { addBusinessDays, utcToLocal } from '~/utils/dates'
import {
  HOME_BASED_PALLIATIVE_CARE,
  TIER_ONE,
  TIER_TWO,
  TIER_THREE,
  CAREMORE_AT_HOME,
  CAREMORE_HEALTH_NETWORK,
  HIGH_RISK_COMPLEX_CARE,
  COMMERCIAL_PALLIATIVE_CARE,
  HBPC_HYBRID,
  TELEPHONIC,
  PC_PLUS,
} from '~/utils/programInfo'

const ACTIVE = 'Active'
const SCHEDULED = 'Scheduled'
const REFERRED = 'Referred'
const NEW = 'New'
const IN_PROCESS = 'In Process'
const ON_SERVICE = 'On Service'
const SNF = 'SNF'
const HOSPITAL = 'Hospital'
const REMOTE_PATIENT = 'Remote Patient'
const ALGORITHM = 'Algorithm'
const CRITICAL = 'Critical'
const HIGH = 'High'
const MEDIUM = 'Medium'
const LOW = 'Low'
const UNENGAGED = 'Unengaged'
const VIRTUAL_PATIENT_DISCHARGE_REASONS = [
  'Patient / Family Declined - Safety Concerns',
  'Virtual Care',
]
const PATIENT_ALIGNMENT = [
  'Voluntary Alignment Complete',
  'Pending Alignment',
  'Remove From Outreach',
  'Voluntary Alignment Submitted to CMS',
]

const includes = (a: any, b: any) =>
  (a || '').toLowerCase().includes((b || '').toLowerCase())

export const alwaysTrue = () => true
export const statusActive = (patient: any) => patient.status === ACTIVE
export const statusScheduled = (patient: any) => patient.status === SCHEDULED
export const statusReferred = (patient: any) => patient.status === REFERRED
export const statusUnengaged = (patient: any) => patient.subStatus === UNENGAGED
export const substatusNew = (patient: any) => patient.substatus === NEW
export const substatusInProcess = (patient: any) =>
  patient.substatus === IN_PROCESS
export const substatusOnService = (patient: any) =>
  patient.substatus === ON_SERVICE
export const substatusSNF = (patient: any) => patient.substatus === SNF
export const substatusHospital = (patient: any) =>
  patient.substatus === HOSPITAL
export const internalSubstatusRemotePatient = (patient: any) =>
  patient.internalSubstatus === REMOTE_PATIENT
export const internalSubstatusPatientAlignment = (patient: any) =>
  PATIENT_ALIGNMENT.includes(patient.internalSubstatus)
export const referralTypeAlgorithm = (patient: any) =>
  patient.referralType === ALGORITHM
export const referralTypeDirect = (patient: any) =>
  patient.referralType !== ALGORITHM
export const calledToday = (patient: any) => patient.calledToday
export const hasSnooze = (patient: any) => patient.snoozeDate !== null
export const hasNTVD = (patient: any) => patient.nextTargetedVisitDate !== null
export const hasInternalSubstatus = (patient: any) =>
  patient.internalSubstatus !== null
export const hasNextScheduledVisitDateTime = (patient: any) =>
  patient.nextScheduledVisitDateTime !== null
export const comingDueSnooze = (
  patient: any,
  _value: any,
  { date, timeZone }: any
) => {
  const snoozeDate = utcToLocal(patient.snoozeDate, timeZone)

  return snoozeDate.isSameOrBefore(addBusinessDays(date, 1), 'day')
}

export const virtualPatient = (patient: any) =>
  VIRTUAL_PATIENT_DISCHARGE_REASONS.includes(
    patient.dischargeRecommendationReason
  )

export const comingDueNTVD = (
  patient: any,
  _value: any,
  { date, timeZone }: any
) => {
  const nextTargetedVisitDate = utcToLocal(
    patient.nextTargetedVisitDate,
    timeZone
  )

  return nextTargetedVisitDate.isSameOrBefore(addBusinessDays(date, 5), 'day')
}
export const ntvdFrom = (patient: any, value: any) => {
  const targetedDate = patient.nextTargetedVisitDate
  const parsedDate = parseISO(value)
  return (
    !targetedDate ||
    !value ||
    isSameDay(targetedDate, parsedDate) ||
    isAfter(targetedDate, parsedDate)
  )
}
export const ntvdTo = (patient: any, value: any) => {
  const targetedDate = patient.nextTargetedVisitDate
  const parsedDate = parseISO(value)
  return (
    !targetedDate ||
    !value ||
    isSameDay(targetedDate, parsedDate) ||
    isBefore(targetedDate, parsedDate)
  )
}

export const snoozeDateBeforeTomorrow = (
  patient: any,
  _value: any,
  { date, timeZone }: any
) => {
  const snoozeDate = utcToLocal(patient.snoozeDate, timeZone)

  return snoozeDate.isBefore(addBusinessDays(date, 1), 'day')
}

export const snoozeDateTomorrow = (
  patient: any,
  _value: any,
  { date, timeZone }: any
) => {
  const snoozeDate = utcToLocal(patient.snoozeDate, timeZone)

  return snoozeDate.isSame(addBusinessDays(date, 1), 'day')
}

export const snoozeDateAfterTomorrow = (
  patient: any,
  _value: any,
  { date, timeZone }: any
) => {
  const snoozeDate = utcToLocal(patient.snoozeDate, timeZone)

  return snoozeDate.isAfter(addBusinessDays(date, 1), 'day')
}
export const isGreen = (patient: any) =>
  !isCaremore(patient) && patient.score === '3'
export const isYellow = (patient: any) =>
  (isCaremore(patient) && patient.score === 3) ||
  (!isCaremore(patient) && patient.score === '2')
export const isOrange = (patient: any) =>
  isCaremore(patient) && patient.score === 2
export const isRed = (patient: any) =>
  (isCaremore(patient) && patient.score === 1) ||
  (!isCaremore(patient) && patient.score === '1')
export const noScore = (patient: any) =>
  (isCaremore(patient) && (patient.score === null || patient.score === 0)) ||
  (!isCaremore(patient) && (patient.score === null || patient.score === '0'))
export const matchesCity = (patient: any, value: any) =>
  includes(patient.city, value)
export const matchesState = (patient: any, value: any) =>
  includes(patient.state, value)
export const matchesPostalCode = (patient: any, value: any) =>
  includes(patient.postalCode, value)
export const matchesHealthPlan = (patient: any, value: any) =>
  includes(patient.healthPlan, value)
export const matchesHPSPrograms = (patient: any, value: any) =>
  includes(patient.healthPlanSpecificPrograms, value)
export const isCritical = (patient: any) => patient.priority === CRITICAL
export const hasHighProbability = (patient: any) => patient.priority === HIGH
export const hasMediumProbability = (patient: any) =>
  patient.priority === MEDIUM
export const hasLowProbability = (patient: any) => patient.priority === LOW
export const pcPlus = (patient: any) => patient.programEnrolled === PC_PLUS
export const careMoreHealthNetwork = (patient: any) =>
  patient.programEnrolled === CAREMORE_HEALTH_NETWORK
export const careMoreAtHome = (patient: any) =>
  patient.programEnrolled === CAREMORE_AT_HOME
export const highRiskComplexCare = (patient: any) =>
  patient.programEnrolled === HIGH_RISK_COMPLEX_CARE
export const fivePercentTierThree = (patient: any) =>
  patient.programEnrolled === TIER_THREE
export const fivePercentTierTwo = (patient: any) =>
  patient.programEnrolled === TIER_TWO
export const fivePercentTierOne = (patient: any) =>
  patient.programEnrolled === TIER_ONE
export const telephonic = (patient: any) =>
  patient.programEnrolled === TELEPHONIC
export const hbpcHybrid = (patient: any) =>
  patient.programEnrolled === HBPC_HYBRID
export const homeBasedPalliativeCare = (patient: any) =>
  patient.programEnrolled === HOME_BASED_PALLIATIVE_CARE
export const commercialPalliativeCare = (patient: any) =>
  patient.programEnrolled === COMMERCIAL_PALLIATIVE_CARE
const isCaremore = (patient: any) =>
  [CAREMORE_AT_HOME, CAREMORE_HEALTH_NETWORK, PC_PLUS].includes(
    patient.programEnrolled
  )
