export default {
  language: {
    title: 'Video Visit Consent',
    overviewSubtitle: 'What is telehealth?',
    overview:
      'Telehealth is a way to visit with healthcare providers, such as your doctor or nurse practitioner. You can talk to your provider from any place, including your home.  You do not need to go to a clinic or hospital.',
    usesSubtitle: 'How do I use telehealth?',
    uses:
      'Your provider will determine the best mode of telehealth visit, based on the purpose of the visit and your health and treatment issues presented:',
    usesList: [
      'Sometimes, you may speak to your provider by phone, computer, or tablet',
      'Sometimes, you may use video so you and your provider can see each other',
    ],
    benefitsSubtitle:
      'What are the benefits of visiting with my doctor or nurse practitioner by telehealth?',
    benefitsList: [
      'You dont have to go to a clinic or hospital to see your provider',
      'You wont risk getting sick from other people',
    ],
    risksSubtitle:
      'What are the risks of visiting with my doctor or nurse practitioner by telehealth?',
    risksList: [
      'You and your provider won’t be in the same room, so it may feel different than an office visit',
      'Your provider may make a mistake because they cannot examine you as closely as at an office visit',
      'Your provider may decide you still need an office visit',
      'Technical problems may interrupt or stop your visit before you are done',
    ],
    privacySubtitle: 'Will my telehealth visit be private?',
    privacyList: [
      'We will not record visits with your provider',
      'If people are close to you, they may hear something you did not want them to know. You should be in a private place, so other people cannot hear you.',
      'Your provider will tell you if someone else from their office can hear or see you.',
      'We use telehealth technology that is designed to protect your privacy.',
      'If you use the Internet for telehealth, use a network that is private and secure.',
      'There is a very small chance that someone could use technology to hear or see your telehealth visit.',
    ],
    cancelSubtitle: 'What if I try telehealth and don’t like it?',
    cancelList: [
      'You can stop using telehealth any time, even during a telehealth visit',
      'You can still get an office visit if you no longer want a telehealth visit',
      'If you decide you do not want to use telehealth again: call XXX-XXX-XXXX and say you want to stop; it will be as if you never signed this form',
    ],
    costSubtitle: 'How much does a telehealth visit cost?',
    costList: [
      'What you pay depends on your insurance.',
      'A telehealth visit will not cost any more than an office visit.',
      'If your provider decides you need an office visit in addition to your telehealth visit, you may have to pay for both visits',
    ],
    signingRequiredSubtitle: 'Do I have to sign this document?',
    signingRequired:
      'No, only sign this document if you want or agree to use telehealth',
    signingMeaningSubtitle: 'What does it mean if I sign this document?',
    signingMeaning: 'If you sign this document, you agree that:',
    signingMeaningList: [
      'We talked about the information in this document',
      'We answered all your questions',
      'You want or agree to telehealth visit(s)',
    ],
    signingMeaningCopy: 'If you sign this document, we will give you a copy.',
    signingInstructionsSubtitle:
      'Do not sign this form until you start your first telehealth visit. Your provider will discuss it with you.',
  },
}
