// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'nextTargetedVisitDateDialog'
const creator = scopedCreator(key)

export const openNextTargetedVisitDateDialog = creator('OPENED')
export const closeNextTargetedVisitDateDialog = creator('CLOSED')

export default createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [openNextTargetedVisitDateDialog]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [closeNextTargetedVisitDateDialog]: () => false,
})

export const isNextTargetedVisitDateDialogOpen = pipe(getRoot, get(key))
