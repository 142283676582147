import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  NEW_REFERRAL_VALIDATION_REQUESTED,
  dupeCheckCompleted,
  emrDuplicatesFound,
  newReferralDupeCheck,
} from '../data/referrals'
export default (action$: any) =>
  action$.pipe(
    ofType(NEW_REFERRAL_VALIDATION_REQUESTED),
    pluck('params'),
    mergeMap(params =>
      from(newReferralDupeCheck(params)).pipe(
        map(data =>
          (data as any).length >= 1
            ? emrDuplicatesFound(data)
            : dupeCheckCompleted()
        ),
        catchError(showError('Problem checking for duplicate patients'))
      )
    )
  )
