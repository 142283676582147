import { changedTo } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchBatches } from '../data/batchList'
import { CODER_DASHBOARD_BATCHES } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(changedTo(CODER_DASHBOARD_BATCHES)),
    map(() => fetchBatches.requested())
  )
