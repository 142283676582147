// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'snackbarMessage'

export const MESSAGE_SET = type(key, 'MESSAGE_SET')
export const MESSAGE_CLEARED = type(key, 'MESSAGE_CLEARED')

export const messageSet = creator(MESSAGE_SET, 'message')
export const messageCleared = creator(MESSAGE_CLEARED)

export default createReducer(key, null, {
  [MESSAGE_SET]: (_state: any, { payload: { message } }: any) => message,
  [MESSAGE_CLEARED]: () => null,
})

export const getSnackbarMessage = pipe(getRoot, get(key))
