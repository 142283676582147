import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  ReminderTask,
  createCreateReminder,
  createDeleteReminder,
  createFetchReminder,
  createSaveReminder,
  getRoot,
} from './common/shared'

const REMINDER_TASK_DIALOG = 'reminderTaskDialog'

export const fetchDialogReminder = createFetchReminder('FETCH_DIALOG_REMINDER')
export const createDialogReminder = createCreateReminder(
  'CREATE_DIALOG_REMINDER'
)
export const saveDialogReminder = createSaveReminder('SAVE_DIALOG_REMINDER')
export const deleteDialogReminder = createDeleteReminder(
  'DELETE_DIALOG_REMINDER'
)

const creator = scopedCreator(rootKey)
export const reminderDialogClosed = creator('REMINDER_DIALOG_CLOSED', false)

const initState = ReminderTask()
export default createReducer(REMINDER_TASK_DIALOG, initState, {
  [fetchDialogReminder.SUCCEEDED]: (
    _state,
    {
      meta: {
        request: {
          payload: { patientId },
        },
      },
      payload,
    }
  ) => payload.merge({ open: true, patientId }),
  [reminderDialogClosed]: () => initState,
})

export const getReminderTaskDialog = pipe(getRoot, get(REMINDER_TASK_DIALOG))
