import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, into, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

export const Prescriber = Record({
  id: null,
  firstName: null,
  lastName: null,
  npi: null,
  city: null,
  state: null,
  preferredLocationId: null,
  prescriberLocations: null,
  role: null,
  startDate: null,
  rxPrivileges: false,
  controlledPrivileges: false,
  epcsSetupDate: null,
})

const key = 'prescribers'

export const SET_PREFERRED_LOCATION = type(key, 'SET_PREFERRED_LOCATION')

export const setPreferredLocation = creator(
  SET_PREFERRED_LOCATION,
  'prescriberId',
  'locationId'
)

export const fetchPrescribers = Request({
  typePrefix: key,
  typeBase: 'FETCH_PRESCRIBERS',
  operation: () => AspireAPI.get('medications/prescribers'),
  transform: into(Prescriber, 'id'),
  messages: { failed: 'Could not get prescribers' },
})

export const updatePrescriber = Request({
  typePrefix: key,
  typeBase: 'UPDATE_PRESCRIBER',
  requestParams: ['id', 'changes'],
  operation: (id: any, changes: any) =>
    AspireAPI.patch(`medications/prescribers/${id}`, changes),
  transform: Prescriber,
  messages: { failed: 'Could not update prescriber' },
})

export const uploadPrescribersToMDT = Request({
  typePrefix: key,
  typeBase: 'MDT_UPLOAD_PRESCRIBER',
  requestParams: ['ids'],
  operation: (ids: any) =>
    AspireAPI.post(`medications/mdt_prescribers`, {
      id: ids.length ? ids : null,
    }),
  messages: { failed: 'Could not upload prescribers' },
})

export default createReducer(key, Map(), {
  [fetchPrescribers.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'prescriber' implicitly has an 'an... Remove this comment to see the full error message
  [updatePrescriber.SUCCEEDED]: (state: any, { payload: prescriber }) =>
    state.set(prescriber.id, prescriber),
  [SET_PREFERRED_LOCATION]: (
    state: any,
    { payload: { prescriberId, locationId } }: any
  ) => state.setIn([prescriberId, 'preferredLocationId'], locationId),
})

export const getPrescribers = pipe(getRoot, get(key))
export const getPrescriberById = (state: any, id: any) =>
  getPrescribers(state).get(id)
