import { ofType, Epic } from 'redux-observable'
import { ignoreElements, mergeMap, pluck, tap } from 'rxjs/operators'
import { actionCreator as action } from '~/utils/data'
import { getItem, removeItem, setItem } from '~/utils/storage'

// NOTE(adam): the server should be able to inform the client
export const allowOverride = ['development', 'testing', 'staging'].includes(
  window.env.APP_ENV
)

export const header = 'X-User-Override-Id'

const offlineKey = 'userOverrideId'

export const getId = () => getItem(offlineKey)

export const setId = (userId: string) => setItem(offlineKey, userId)

export const clearId = () => removeItem(offlineKey)

export const setUserId = action('SET_OVERRIDE_USER_ID')

export const overrideUserIdEpic: Epic = action$ =>
  action$.pipe(
    ofType(setUserId),
    pluck('payload'),
    mergeMap(setId),
    tap(() => window.location.reload()), // NOTE(adam): arrow required
    ignoreElements()
  )

export const users = [
  {
    id: '00515000007JFCYAA4',
    name: 'Danielle Johnson',
    role: 'Assigned APP',
    program: 'Palliative',
  },
  {
    id: '00515000004vdfHAAQ',
    name: 'Misty Hartley',
    role: 'Clinical Director',
    program: 'Palliative',
  },
  {
    id: '00515000006oZwMAAU',
    name: 'Toni Wetzel',
    role: 'Field RN',
    program: 'Palliative',
  },
  {
    id: '00515000006VA2UAAW',
    name: 'Jessica Alsup',
    role: 'Field SW',
    program: 'Palliative',
  },
  {
    id: '00515000007TqjyAAC',
    name: 'Angela Swinson',
    role: 'Hospice NSS',
    program: 'Palliative',
  },
  {
    id: '00515000007E2piAAC',
    name: 'Ryan Robertson',
    role: 'Hospice RN',
    program: 'Palliative',
  },
  {
    id: '00515000005sGbdAAE',
    name: 'Kristen Coller',
    role: 'Physician',
    program: 'Palliative',
  },
  {
    id: '00515000005c7weAAA',
    name: 'Connie Velazquez',
    role: 'Market Support Specialist',
    program: 'Palliative',
  },
  {
    id: '0051C000005O66tQAC',
    name: 'Jill Capps',
    role: 'Nurse Support Specialist',
    program: 'Palliative',
  },
  {
    id: '00515000006UssWAAS',
    name: 'Sarah Steinhouse',
    role: 'OC Manager',
    program: 'Palliative',
  },
  {
    id: '00515000007UJfnAAG',
    name: 'Yiandria Blackman',
    role: 'CSS',
    program: 'Palliative',
  },
  {
    id: '00515000005tc9NAAQ',
    name: 'Jessica Jackson',
    role: 'CSS Supervisor',
    program: 'Palliative',
  },
  {
    id: '00515000006VA25AAG',
    name: 'Jazmin Ellis',
    role: 'Operations Coordinator',
    program: 'Palliative',
  },
  {
    id: '00515000006oZwMAAU',
    name: 'Toni Wetzel',
    role: 'Clinical Management RN',
    program: 'Palliative',
  },
  {
    id: '00515000006VA2UAAW',
    name: 'Jessica Alsup',
    role: 'Central Social Worker',
    program: 'Palliative',
  },
  {
    id: '00515000005sNjAAAU',
    name: 'Marc Patchen',
    role: 'Rapid Response PCC',
    program: 'Palliative',
  },
]

export const getUser = () => getId().then(id => users.find(u => u.id === id))
