import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { combineEpics, ofType } from 'redux-observable'
import { filter, map, mapTo, pluck } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/careTeamManagement'... Remove this comment to see the full error message
import { getPatientCareTeamById } from '~/features/careTeamManagement'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientInfo'... Remove this comment to see the full error message
import { fetchPatient, patientInfoUpdated } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or ... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'nextTargetedVisitDate'

const Patient = Record({
  id: null,
  name: null,
})

const User = Record({
  id: null,
  name: null,
  role: null,
})

const CareTeamRole = Record({
  key: null,
  label: null,
})

const NextTargetedVisitDate = Record({
  patientId: null,
  patient: Patient(),
  careTeamRole: CareTeamRole(),
  careTeamRoleKey: null,
  date: null,
  modality: null,
  modalityLabel: null,
  updateReason: null,
  updateReasonOther: null,
  modifiedBy: User(),
  modifiedById: null,
  modifiedAt: null,
})

const createNextTargetedVisitDate = (response: any) =>
  NextTargetedVisitDate({
    ...response,
    patient: Patient({ ...response.patient }),
    careTeamRole: CareTeamRole({ ...response.careTeamRole }),
    modifiedBy: User({ ...response.modifiedBy }),
  })

const fetchNextTargetedVisitApi = (patientId: string) =>
  AspireAPI.get(`v1/patients/${patientId}/next_targeted_visit`)

export const fetchNextTargetedVisitDates = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: fetchNextTargetedVisitApi,
  transform: into(createNextTargetedVisitDate as any, 'careTeamRoleKey'),
  messages: {
    failed: 'There was a problem fetching the next targeted visit date',
  },
})

const updateNextTargetedVisitApi = (patientId: string, params: any) =>
  AspireAPI.post(`v1/patients/${patientId}/next_targeted_visit`, params)

export const updateNextTargetedVisitDate = Request({
  typePrefix: key,
  typeBase: 'UPDATE',
  requestParams: ['patientId', 'params'],
  operation: updateNextTargetedVisitApi,
  transform: createNextTargetedVisitDate,
  messages: {
    failed: 'There was a problem setting the next targeted visit date',
  },
})

const fetchNTVDFieldValues = (action$: any) =>
  action$.pipe(
    ofType(fetchPatient.REQUESTED),
    mapTo(fetchDistinctFieldValues(['ntvd_change', 'modality']))
  )

const updatePatientNTVD = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updateNextTargetedVisitDate.SUCCEEDED),
    pluck('payload'),
    filter((ntvd: any) => {
      const patientCareTeam = getPatientCareTeamById(ntvd.patientId)(
        state$.value
      )

      return ntvd.careTeamRole == patientCareTeam.primaryRole
    }),
    map(({ nextTargetedVisitDate }: any) => ({
      scheduling: { nextTargetedVisitDate },
    })),
    map(patientInfoUpdated)
  )

export default createReducer(key, Map(), {
  [fetchNextTargetedVisitDates.SUCCEEDED]: (state: any, action: any) => {
    const patientId = getIn(action, 'meta.request.payload.patientId')
    return state.set(patientId, action.payload)
  },
  [updateNextTargetedVisitDate.SUCCEEDED]: (state: any, { payload }: any) => {
    return state.setIn([payload.patientId, payload.careTeamRoleKey], payload)
  },
})

export const getPatientNTVD = (patientId: string) =>
  pipe(getRoot, get(key), get(patientId, Map()))

export const epic = combineEpics(fetchNTVDFieldValues, updatePatientNTVD)
