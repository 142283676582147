import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import {
  addCareTeamToPod,
  fetchCareTeamDetailsById,
  getCurrentCareTeamDetailId,
  removeCareTeamToPod,
} from '../data/careTeam'

export const addCareTeamManagement = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addCareTeamToPod.SUCCEEDED),
    switchTo(state$),
    map((state: any) =>
      fetchCareTeamDetailsById.requested(getCurrentCareTeamDetailId(state))
    )
  )

export const removeCareTeamManagement = (action$: any, state$: any) =>
  action$.pipe(
    ofType(removeCareTeamToPod.SUCCEEDED),
    switchTo(state$),
    map((state: any) =>
      fetchCareTeamDetailsById.requested(getCurrentCareTeamDetailId(state))
    )
  )
