import { Set } from 'immutable'
import React, { useState } from 'react'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Switch, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { userIdsChanged } from '../../../data/events'
import UserList from '../../UserList'
import BaseField from '../BaseField'

const pluralize = count => (count == 1 ? '' : 's')

const useStyles = makeStyles({
  spacer: {
    flex: 1,
  },
})

const GroupSettingsField = ({ userId, editing, event }) => {
  const classes = useStyles()

  const changeUserIds = useAction(userIdsChanged)

  const [isSelected, setIsSelected] = useState(
    Boolean(
      event.userIds &&
        (event.userIds.count() > 1 || !event.userIds.includes(userId))
    )
  )

  const userIds = event.userIds || Set()

  const onToggleGroupEvent = () => {
    const payload = isSelected ? null : Set([userId])
    changeUserIds(payload)

    setIsSelected(!isSelected)
  }

  const onUsersSelected = (newUserIds, selected) => {
    const payload = selected
      ? userIds.merge(newUserIds)
      : userIds.subtract(newUserIds)
    changeUserIds(payload)
  }

  const summary = `${userIds.size} user${pluralize(userIds.size)} selected`
  const error = !event.recurrence && userIds.count() <= 1

  return (
    <React.Fragment>
      <BaseField label="Group Event">
        <Switch
          disabled={!editing}
          checked={isSelected}
          onChange={onToggleGroupEvent}
        />

        {isSelected && (
          <React.Fragment>
            <span className={classes.spacer} />
            <Typography variant="body1">{summary}</Typography>
          </React.Fragment>
        )}
      </BaseField>

      {isSelected && (
        <BaseField indent={1} label="Selected Users" error={error}>
          <UserList
            disabled={!editing}
            selectedUserIds={userIds}
            separateSelected={editing}
            batchActions={editing}
            onUsersSelected={onUsersSelected}
          />
        </BaseField>
      )}
    </React.Fragment>
  )
}

GroupSettingsField.propTypes = {
  userId: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  event: PropTypes.record.isRequired,
}

export default GroupSettingsField
