import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { ADMIN_TOOLS_EDIT_ROLE } from '../router'
import { getRoot } from './common/shared'

const CURRENT_ROLE_ID = 'currentRoleId'

const reducer = paramsReducer(
  ADMIN_TOOLS_EDIT_ROLE,
  ({ roleId }: any): number => Number(roleId)
)

reducer.key = CURRENT_ROLE_ID

export const getCurrentRoleId = pipe(getRoot, get(CURRENT_ROLE_ID))

export default reducer
