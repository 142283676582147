import { combineReducers } from 'redux-immutable'
import currentAPPId from './data/currentAPPId'
import currentPatientId from './data/currentPatientId'
import healthplanAlerts from './data/healthplanAlerts'
import patientAlerts from './data/patientAlerts'
import patientInfo from './data/patientInfo'
import pendingSelectedAPPs from './data/pendingSelectedAPPs'
import quickLocations from './data/quickLocations'
import reminderTask from './data/reminderTask'
import reminderTaskDialog from './data/reminderTaskDialog'
import schedulingAlerts from './data/schedulingAlerts'
import selectedAPPs from './data/selectedAPPs'
import snfPatient from './data/snfPatient'
import snoozeDialog from './data/snoozeDialog'
import key from './key'

const reducer = combineReducers({
  [currentAPPId.key]: currentAPPId,
  [currentPatientId.key]: currentPatientId,
  [healthplanAlerts.key]: healthplanAlerts,
  [patientAlerts.key]: patientAlerts,
  [patientInfo.key]: patientInfo,
  [pendingSelectedAPPs.key]: pendingSelectedAPPs,
  [quickLocations.key]: quickLocations,
  [reminderTask.key]: reminderTask,
  [reminderTaskDialog.key]: reminderTaskDialog,
  [schedulingAlerts.key]: schedulingAlerts,
  [selectedAPPs.key]: selectedAPPs,
  [snfPatient.key]: snfPatient,
  [snoozeDialog.key]: snoozeDialog,
})

reducer.key = key

export default reducer
