import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { TripStatus } from './interfaces'
import { transformTripStatus } from '../utils'

// KEY
const TRIPSTATUS = 'tripStatus'

export const TripStatusRec = Record({
  activeInd: null,
  id: null,
  isCountable: null,
  status: null,
  statusCode: null,
})

// TRANSFORMER
const mapTripStatus = (results: [TripStatus]) =>
  List(
    results?.map(tripStatus =>
      TripStatusRec({
        id: tripStatus?.id,
        status: transformTripStatus(
          tripStatus?.id,
          tripStatus?.status,
          tripStatus?.isCountable
        ),
        statusCode: tripStatus?.statusCode,
        activeInd: tripStatus?.activeInd,
        isCountable: tripStatus?.isCountable,
      })
    )
  )

// REQUEST
export const tripStatusList = Request({
  typePrefix: rootKey,
  typeBase: 'TRIPSTATUSLIST',
  requestParams: ['id', 'statusData'],
  operation: () => AspireAPI.get('transportation/lookup/trip_status'),
  transform: mapTripStatus,
  messages: { failed: 'There was an issue fetching Trip Status' },
})

export const updateTripStatus = Request({
  typePrefix: rootKey,
  typeBase: 'UPDATE_TRIP_STATUS',
  requestParams: ['TripStatusData', 'patientId'],
  operation: (TripStatusData: any, patientId: any) =>
    AspireAPI.post(`transportation/trips/update_trip_status/${patientId}`, {
      ...TripStatusData,
    }),
  messages: {
    succeeded: `Trip Status changed successfully`,
    failed: 'There was an issue updating Trip Status',
  },
})

// REDUCER
export const tripStatus = createReducer(TRIPSTATUS, List(), {
  [tripStatusList.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getTripStatus = pipe(getRoot, get(TRIPSTATUS))
