import { Set } from 'immutable'
import { createSelector } from 'reselect'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/re... Remove this comment to see the full error message
import OrcAPI from '~/resources/orc'
import { getAdmissionById, getAllAdmissions } from './admissions'
import { getMergeDialogPatientId } from './mergeDialogPatientId'
import { getAllMergeOdsAdmissionIds } from './mergeOdsAdmissionIds'
import { getAllOdsAdmissions } from './odsAdmissions'
import { Patient, getAllPatients } from './patients'
import { getRecycleContactId } from './recycleContactId'
import { getRecycleDialogAdmissionId } from './recycleDialogAdmissionId'
import { getAllSelectedOdsAdmissionIds } from './selectedOdsAdmissionIds'
import { getUnmergeContactId } from './unmergeContactId'
import { getUnmergeDialogOdsAdmissionId } from './unmergeDialogOdsAdmissionId'
import { getAllUnmergeOdsAdmissionIds } from './unmergeOdsAdmissionIds'

// CONSTANTS
export const DISCARD_CONFIRMED = 'reconciliation/DISCARD_CONFIRMED'
export const MERGE_CLICKED = 'reconciliation/MERGE_CLICKED'
export const MERGE_INITIALIZED = 'reconciliation/MERGE_INITIALIZED'
export const MERGE_SAVED = 'reconciliation/MERGE_SAVED'
export const MERGE_SAVE_FAILED = 'reconciliation/MERGE_SAVE_FAILED'
export const UNMERGE_CONFIRMED = 'reconciliation/UNMERGE_CONFIRMED'
export const UNMERGE_INITIALIZED = 'reconciliation/UNMERGE_INITIALIZED'
export const UNMERGE_SAVED = 'reconciliation/UNMERGE_SAVED'
export const UNMERGE_SAVE_FAILED = 'reconciliation/UNMERGE_SAVE_FAILED'
export const UNMERGE_SEARCHED = 'reconciliation/UNMERGE_SEARCHED'
export const RECYCLE_CONFIRMED = 'reconciliation/RECYCLE_CONFIRMED'
export const RECYCLE_INITIALIZED = 'reconciliation/RECYCLE_INITIALIZED'
export const RECYCLE_SEARCHED = 'reconciliation/RECYCLE_SEARCHED'

// ACTION CREATORS
export const discardConfirmed = () => ({ type: DISCARD_CONFIRMED })
export const mergeClicked = (mergedFields: any, odsAdmissionIds: any) => ({
  type: MERGE_CLICKED,
  mergedFields,
  odsAdmissionIds,
})
export const mergeInitialized = () => ({ type: MERGE_INITIALIZED })
export const mergeSaved = (odsAdmissionIds: any) => ({
  type: MERGE_SAVED,
  odsAdmissionIds,
})
export const mergeSaveFailed = () => ({ type: MERGE_SAVE_FAILED })
export const unmergeConfirmed = () => ({ type: UNMERGE_CONFIRMED })
export const unmergeInitialized = () => ({ type: UNMERGE_INITIALIZED })
export const unmergeSaved = () => ({ type: UNMERGE_SAVED })
export const unmergeSaveFailed = () => ({ type: UNMERGE_SAVE_FAILED })
export const unmergeSearched = (contactId: any) => ({
  type: UNMERGE_SEARCHED,
  contactId,
})
export const recycleConfirmed = (reason: any) => ({
  type: RECYCLE_CONFIRMED,
  reason,
})
export const recycleInitialized = () => ({ type: RECYCLE_INITIALIZED })
export const recycleSearched = (contactId: any) => ({
  type: RECYCLE_SEARCHED,
  contactId,
})

// API
export const submitOdsAdmissionMerge = (
  mergedFields: any,
  odsAdmissionIds: any
) => {
  const otherOdsAdmissionIds = odsAdmissionIds
    .filter((a: any) => a !== mergedFields.get('masterOdsAdmissionId'))
    .toJS()

  return OrcAPI.post('admissions/merge', {
    masterAdmission: {
      masterAdmissionsId: mergedFields.get('masterOdsAdmissionId'),
      admissionDate: mergedFields.get('admissionDate'),
      dischargeDate: mergedFields.get('dischargeDate'),
      admissionTypeId: mergedFields.get('admissionTypeId'),
    },
    admissionIds: otherOdsAdmissionIds.length > 0 ? otherOdsAdmissionIds : null,
  }).then(() => odsAdmissionIds)
}

export const submitOdsAdmissionUnmerge = (odsAdmissionIds: any) =>
  OrcAPI.post('admissions/unmerge', {
    admissionIds: odsAdmissionIds.toJS(),
  }).then(() => odsAdmissionIds)

// SELECTORS
export const getSelectedOdsAdmissionIdsGroupedByMemberId = createSelector(
  [getAllOdsAdmissions, getAllSelectedOdsAdmissionIds],
  (odsAdmissions, selectedOdsAdmissionIds) =>
    odsAdmissions
      .filter((a: any) => selectedOdsAdmissionIds.has(a.id))
      .groupBy((a: any) => a.globalMemberId)
      .map((a: any) => Set(a.keySeq()))
)

export const getOdsAdmissionsToMerge = createSelector(
  [
    getMergeDialogPatientId,
    getSelectedOdsAdmissionIdsGroupedByMemberId,
    getAllOdsAdmissions,
  ],
  (dialogPatientId, selectedOdsAdmissionIdsByMemberId, odsAdmissions) => {
    const selectedOdsAdmissionIds = selectedOdsAdmissionIdsByMemberId.get(
      dialogPatientId,
      Set()
    )
    return odsAdmissions.filter((a: any) =>
      selectedOdsAdmissionIds.includes(a.id)
    )
  }
)

export const getPatientsWithMergeOdsAdmissions = createSelector(
  [getAllPatients, getAllOdsAdmissions, getAllMergeOdsAdmissionIds],
  (patients, odsAdmissions, mergeOdsAdmissionIds) => {
    const patientIds = odsAdmissions
      .filter((a: any) => mergeOdsAdmissionIds.includes(a.id))
      .map((a: any) => a.globalMemberId)
      .toSetSeq()
    return patients.filter((p: any) => patientIds.has(p.id))
  }
)

export const getUnmergePatient = createSelector(
  [getAllPatients, getUnmergeContactId],
  (patients, contactId) => patients.find((p: any) => p.contactId === contactId)
)

export const getUnmergeOdsAdmissionsGroupedByParent = createSelector(
  [getAllOdsAdmissions, getUnmergePatient, getAllUnmergeOdsAdmissionIds],
  (odsAdmissions, patient = Patient(), odsAdmissionIds) =>
    odsAdmissions
      .filter((a: any) => odsAdmissionIds.includes(a.id))
      .filter((a: any) => a.globalMemberId === patient.id)
      .groupBy((a: any) => a.parentId)
)

export const getUnmergeOdsAdmissionIdsForDialog = createSelector(
  [
    getAllOdsAdmissions,
    getAllUnmergeOdsAdmissionIds,
    getUnmergeDialogOdsAdmissionId,
  ],
  (odsAdmissions, unmergeOdsAdmissionIds, unmergeDialogOdsAdmissionId) =>
    odsAdmissions
      .valueSeq()
      .filter((a: any) => unmergeOdsAdmissionIds.includes(a.id))
      .filter((a: any) => a.parentId === unmergeDialogOdsAdmissionId)
      .map((a: any) => a.id)
      .sort((a: any, b: any) => a.id - b.id)
)

export const getMergeOdsAdmissionsGroupedByMemberId = createSelector(
  [getAllOdsAdmissions, getAllMergeOdsAdmissionIds],
  (odsAdmissions, mergeOdsAdmissionIds) =>
    odsAdmissions
      .filter((a: any) => mergeOdsAdmissionIds.includes(a.id))
      .groupBy((a: any) => a.globalMemberId)
)

export const getRecyclePatient = createSelector(
  [getAllPatients, getRecycleContactId],
  (patients, contactId) => patients.find((p: any) => p.contactId === contactId)
)

export const getRecycleAdmissions = createSelector(
  [getAllAdmissions, getRecyclePatient],
  (admissions, patient = Patient()) =>
    admissions.filter((a: any) => a.patientId === patient.sfid)
)

export const getRecycleDialogAdmission = (state: any) => {
  const admissionId = getRecycleDialogAdmissionId(state)
  return getAdmissionById(state, admissionId)
}
