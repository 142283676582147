import { isEmpty } from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Subtitle1 } from '~/components/CustomTypography'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getRequestProcessingValue } from '../data/common/requestProcessing'
import {
  getDialogOpenValue,
  sendToResearch,
  sendToResearchDialogClosed,
} from '../data/sendToResearchDialog'

const styles = ({ palette, spacing }) => ({
  lighterText: {
    color: palette.grey[500],
    marginLeft: spacing(1.5),
  },
  textField: {
    marginTop: 20,
    '& div': {
      lineHeight: '1.4em',
      padding: [12, spacing(2)],
    },
  },
})

class SendToResearchDialog extends React.Component {
  state = { notes: '' }

  resetState = () => this.setState({ notes: '' })

  closeDialog = () => {
    this.resetState()
    this.props.onClose()
  }

  createNotes = event => this.setState({ notes: event.target.value })

  sendPatientToResearch = () => {
    const { patientId, sendToResearch } = this.props
    const { notes } = this.state

    sendToResearch(patientId, notes)
  }

  render() {
    const { classes, open, requestIsProcessing } = this.props
    const requiredFieldsEmpty = isEmpty(this.state.notes)

    return (
      <ActionDialog
        disableAction={requiredFieldsEmpty || requestIsProcessing}
        fullWidth
        maxWidth="sm"
        mode="create"
        onClose={this.closeDialog}
        onCreate={this.sendPatientToResearch}
        onExited={this.resetState}
        open={open}
        title="Create New Research Case"
      >
        <Subtitle1>
          Research Start Date:
          <span className={classes.lighterText}>
            {moment().format('M-DD-YY')}
          </span>
        </Subtitle1>
        <TextField
          className={classes.textField}
          fullWidth
          multiline
          onChange={this.createNotes}
          placeholder="Enter Case Information (Required)"
          required
          rows="4"
          variant="outlined"
        />
      </ActionDialog>
    )
  }
}

SendToResearchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patientId: PropTypes.string.isRequired,
  requestIsProcessing: PropTypes.bool.isRequired,
  sendToResearch: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  open: getDialogOpenValue(state),
  requestIsProcessing: getRequestProcessingValue(state),
})

const mapDispatchToProps = {
  onClose: sendToResearchDialogClosed,
  sendToResearch: sendToResearch.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SendToResearchDialog)
