// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import carePlan from './data/carePlan'
import lastCarePlan from './data/lastCarePlan'
import problemDetailTypeMappings from './data/problemDetailTypeMappings'
import types from './data/types'
import key from './key'

const reducer = combineReducers({
  [carePlan.key]: carePlan,
  [lastCarePlan.key]: lastCarePlan,
  [problemDetailTypeMappings.key]: problemDetailTypeMappings,
  [types.key]: types,
})

reducer.key = key

export default reducer
