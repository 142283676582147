import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const key = 'patientSnfs'

export const SNF = Record({
  id: null,
  sfid: null,
  patientId: null,
  caseNumber: null,
  facilityName: null,
  notes: null,
  admissionDate: null,
  dischargeDate: null,
  createdBy: null,
  createdAt: null,
})

export const CaremoreSNF = Record({
  id: null,
  authKey: null,
  facilityName: null,
  notes: null,
  admissionDate: null,
  admittingDiagnosis: null,
  dischargeDate: null,
  dischargeDisposition: null,
  diagnosisDescription: null,
  outOfNetwork: null,
  admissionSource: null,
  tocRisk: null,
  placeOfServiceCode: null,
  status: null,
  authCode: null,
  authStatus: null,
  authReceivedAt: null,
  showInCensus: null,
  metalTier: null,
  actualDischargeDateEnteredAt: null,
  dischargeSummaryUploadedAt: null,
  historyAndPhysicalUploadedAt: null,
  calculatedDcPlanningFlag: null,
})

export const fetchSNFs = Request({
  typePrefix: key,
  typeBase: 'FETCH_SNFS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get('snfs', { params: { patientId } }),
  transform: into(SNF, 'id'),
  messages: { failed: 'There was a problem fetching SNFs' },
})

export const fetchCaremoreSNFs = Request({
  typePrefix: key,
  typeBase: 'FETCH_SNFS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get('snfs', { params: { patientId } }),
  transform: into(CaremoreSNF, 'id'),
  messages: { failed: 'There was a problem fetching SNFs' },
})

export const addSNF = Request({
  typePrefix: key,
  typeBase: 'ADD_SNF',
  requestParams: ['patientId', 'snf'],
  operation: (patientId, snf) =>
    AspireAPI.post('snfs', { snf }, { params: { patientId } }),
  transform: SNF,
  messages: {
    failed: 'There was a problem adding this SNF',
    succeeded: 'Sucessfully added SNF record',
  },
})

export const saveSNF = Request({
  typePrefix: key,
  typeBase: 'SAVE_SNF',
  requestParams: ['snfId', 'changes'],
  operation: (snfId, changes = {}) =>
    AspireAPI.put(`snfs/${snfId}`, {
      ...changes,
    }),
  messages: { failed: 'There was a problem saving this SNF' },
})

export const deleteSNF = Request({
  typePrefix: key,
  typeBase: 'DELETE_SNF',
  requestParams: ['snfId'],
  operation: snfId => AspireAPI.delete(`snfs/${snfId}`),
  messages: { failed: 'There was a problem deleting this SNF' },
})

export default createReducer(key, Map(), {
  [fetchSNFs.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchCaremoreSNFs.SUCCEEDED]: (_state, { payload }) => payload,
  [addSNF.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, SNF(payload)),
  [saveSNF.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.mergeIn([payload.snfId], payload.changes),
  [deleteSNF.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.delete(payload.snfId),
})

export const getSNFs = pipe(get(rootKey), get(key))
