import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import {
  ReminderTask,
  createCreateReminder,
  createDeleteReminder,
  createFetchReminder,
  createSaveReminder,
  getRoot,
  patientCleared,
} from './common/shared'
import {
  createDialogReminder,
  deleteDialogReminder,
  saveDialogReminder,
} from './reminderTaskDialog'

const REMINDER_TASK = 'reminderTask'

export const fetchReminder = createFetchReminder('FETCH_REMINDER')
export const createReminder = createCreateReminder('CREATE_REMINDER')
export const saveReminder = createSaveReminder('SAVE_REMINDER')
export const deleteReminder = createDeleteReminder('DELETE_REMINDER')

const initState = ReminderTask()
export default createReducer(REMINDER_TASK, initState, {
  [patientCleared]: () => initState,
  [fetchReminder.REQUESTED]: () => initState,
  [fetchReminder.SUCCEEDED]: (
    _state,
    {
      meta: {
        request: {
          payload: { patientId },
        },
      },
      payload,
    }
  ) => payload.merge({ patientId }),
  [createReminder.SUCCEEDED]: (
    _state,
    {
      meta: {
        request: {
          payload: { reminder },
        },
      },
      payload,
    }
  ) => reminder.set('id', payload),
  [saveReminder.SUCCEEDED]: (
    _state,
    {
      meta: {
        request: {
          payload: { reminder },
        },
      },
    }
  ) => reminder,
  [deleteReminder.SUCCEEDED]: ({ patientId }) => ReminderTask({ patientId }),
  // If current patient's reminder is modified via dialog
  [createDialogReminder.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { reminder },
        },
      },
      payload,
    }
  ) =>
    state.patientId === reminder.patientId
      ? reminder.set('id', payload)
      : state,
  [saveDialogReminder.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { reminder },
        },
      },
    }
  ) => (state.id === reminder.id ? reminder : state),
  [deleteDialogReminder.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { id },
        },
      },
    }
  ) => (state.id === id ? ReminderTask({ patientId: state.patientId }) : state),
})

export const getReminderTask = pipe(getRoot, get(REMINDER_TASK))
