import { Record } from 'immutable'
import { creator, get, type } from '~/utils/data'
import key from '../../key'

// RECORDS
export const Notification = Record({
  id: null,
  title: null,
  message: null,
  primaryLink: null,
  secondaryLinks: null,
  createdAt: null,
  viewedAt: null,
  priority: null,
})

export const Link = Record({
  label: null,
  url: null,
})

// CONSTANTS
export const HIGH = 'high'
export const NORMAL = 'normal'
export const CHANNEL_JOINED = type(key, 'CHANNEL_JOINED')
export const CLEAR_NOTIFICATIONS_REQUESTED = type(
  key,
  'CLEAR_NOTIFICATIONS_REQUESTED'
)
export const CLEAR_NOTIFICATIONS_SUCCEEDED = type(
  key,
  'CLEAR_NOTIFICATIONS_SUCCEEDED'
)

// ACTION CREATORS
export const channelJoined = creator(CHANNEL_JOINED)
export const clearNotificationsRequested = creator(
  CLEAR_NOTIFICATIONS_REQUESTED,
  'notificationIds'
)
export const clearNotificationsSucceeded = creator(
  CLEAR_NOTIFICATIONS_SUCCEEDED
)

// SELECTORS
export const getRoot = get(key)
