export default {
  contacts: [
    {
      id: '1',
      active: true,
      preferred: true,
      type: 'Home',
      name: 'Bob Test',
      relationship: undefined,
      phoneNumber: '(555) 123-4567',
      phiApproved: true,
      phiVoicemailApproved: true,
    },
    {
      id: '2',
      active: true,
      preferred: false,
      type: 'Family Member',
      name: 'Bobby Joe',
      relationship: 'Son',
      phoneNumber: '(555) 234-5678',
      phiApproved: true,
      phiVoicemailApproved: false,
    },
    {
      id: '3',
      active: true,
      preferred: false,
      type: 'Home',
      name: 'Linda Johnson',
      relationship: 'Neighbor',
      phoneNumber: '(555) 234-5678',
      phiApproved: false,
      phiVoicemailApproved: false,
    },
  ],
  app: {
    id: '00515000007goTfAAI',
    name: 'Test APP',
  },
  pcp: {
    id: '0031C00002C5NsbQAF',
    name: 'Test PCP',
    practice: 'Test Practice',
  },
  user: {
    id: '00515000006MQF7AAO',
    name: 'Test User',
  },
  market: {
    id: '0011500001eWsxzAAC',
    name: 'Nashville',
    localPhone: '555-555-5555',
    hotlinePhone: '1-800-555-5555',
  },
  patient: {
    age: null,
    appId: '00515000007goTfAAI',
    careTeamId: 'a141C0000034CaGQAU',
    city: 'Nashville',
    contactId: '123456',
    currentMedicationsNotes: 'Eye drops',
    dateOfBirth: '1950-01-01',
    drugAllergiesDescription:
      'Allergic to most common NSAIDs (aspirin, ibuprofen, pseudophedrine)',
    ethnicity: 'Other',
    ethnicityOther: 'Antarctican',
    firstName: 'Bob',
    gender: 'Other',
    hasDrugAllergies: 'yes',
    id: '0011C00001txlWvQAI',
    internalSubstatus: 'Telehealth Active',
    language: 'Other',
    lastName: 'Test',
    locationType: 'Other',
    marketId: '0011500001eWsxzAAC',
    middleName: null,
    pcpId: '0031C00002C5NsbQAF',
    physicianName: 'Test Physician',
    postalCode: '37203',
    preferredPhone: '(555) 555-555',
    primaryContract: 'Test Primary Contract',
    primaryDiagnosis: 'Cancer - Brain',
    primaryDiagnosisOther: null,
    primaryInsuranceNumber: null,
    programEnrolled: null,
    riskLevel: 2,
    salutation: 'Mr.',
    state: 'TN',
    status: 'Referred',
    street: '123 Maple Ln',
    substatus: 'In Process',
    suffix: null,
  },
  patientRx: [
    {
      overrideReason: 'Reviewed',
      qty: 100,
      prescriberId: '00515000006l7YPAAY',
      locationId: 29,
      status: 'Historical',
      prescriptionDate: '2018-08-07',
      pharmacyNote: 'Add a pharmacy Note',
      routeDetail:
        'e-Sent:Aug  7 2018  1:58PM Mail Order Pharmacy 10.6<br />Sent/Verified',
      internalNote: null,
      directions: 'Take 100mg Once a day',
      drugId: 32031,
      secondaryIndication: null,
      rxnorm: '581614',
      substitutionAllowedFlag: true,
      drug: 'Zyvox 100 mg/5 mL oral liquid',
      ndc: '00009513601',
      primaryIndication: 'Anxiety',
      refills: 4,
      csmdAttestationDate: null,
      daysSupply: 100,
      supply: false,
      patientId: '0011C00001og7hNQAQ',
      prevId: null,
      id: 345574,
      primaryIndicationOther: null,
      pharmacyNcpdpId: 9900120,
      qtyQual: 'Milliliter',
      genericDrugId: 'd04534',
      compound: false,
      partnerMedId: 'df18bdec-351e-45d0-a4e9-5b602818ef7a',
      secondaryIndicationOther: null,
      schedule: 0,
    },
    {
      overrideReason: 'Reviewed',
      qty: 0,
      prescriberId: '00515000006l7YPAAY',
      locationId: 29,
      status: 'Current',
      prescriptionDate: '2018-08-07',
      pharmacyNote: null,
      routeDetail: null,
      internalNote: null,
      directions: '1 Box(s) Inhaled 3 times a day (as needed)',
      drugId: 160464,
      secondaryIndication: null,
      rxnorm: '1053651',
      substitutionAllowedFlag: true,
      drug: 'Abstral 100 mcg sublingual tablet',
      ndc: '42358010032',
      primaryIndication: 'Anemia',
      refills: 0,
      csmdAttestationDate: null,
      daysSupply: 0,
      supply: false,
      patientId: '0011C00001og7hNQAQ',
      prevId: null,
      id: 345585,
      primaryIndicationOther: null,
      pharmacyNcpdpId: 9900120,
      qtyQual: 'Box',
      genericDrugId: 'd00233',
      compound: false,
      partnerMedId: 'ef38ed55-5b96-4dad-b6f0-5cccf2e12dda',
      secondaryIndicationOther: null,
      schedule: 2,
    },
    {
      overrideReason: 'Reviewed',
      qty: 135,
      prescriberId: '00515000006l7YPAAY',
      locationId: 29,
      status: 'Discontinued',
      prescriptionDate: '2018-08-07',
      pharmacyNote: 'Pharmacy Note',
      routeDetail:
        'Cancel: e-Sent:Aug  7 2018  4:41PM VA Pharmacy 10.6MU<br />Sent/Verified',
      internalNote: null,
      directions: '1 Tablet(s) Orally 3 times a day',
      drugId: 160464,
      secondaryIndication: 'Other (specify)',
      rxnorm: '1053651',
      substitutionAllowedFlag: false,
      drug: 'Abstral 100 mcg sublingual tablet',
      ndc: '42358010032',
      primaryIndication: 'Other (specify)',
      refills: 0,
      csmdAttestationDate: '2018-08-07',
      daysSupply: 45,
      supply: false,
      patientId: '0011C00001og7hNQAQ',
      prevId: null,
      id: 345587,
      primaryIndicationOther: 'Primary Other',
      pharmacyNcpdpId: 7723703,
      qtyQual: 'Tablet',
      genericDrugId: 'd00233',
      compound: false,
      partnerMedId: '923d4d01-49b2-4aa6-99ed-f0223642141c',
      secondaryIndicationOther: 'Secondary Other',
      schedule: 2,
    },
    {
      overrideReason: 'Reviewed',
      qty: 405,
      prescriberId: '00515000006l7YPAAY',
      locationId: 29,
      status: 'Current',
      prescriptionDate: '2018-08-07',
      pharmacyNote: 'Pharmacy Note',
      routeDetail: null,
      internalNote: null,
      directions: '3 Tablet(s) Orally 3 times a day',
      drugId: 160464,
      secondaryIndication: 'Other (specify)',
      rxnorm: '1053651',
      substitutionAllowedFlag: true,
      drug: 'Abstral 100 mcg sublingual tablet',
      ndc: '42358010032',
      primaryIndication: 'Other (specify)',
      refills: 0,
      csmdAttestationDate: '2018-08-07',
      daysSupply: 45,
      supply: false,
      patientId: '0011C00001og7hNQAQ',
      prevId: 345587,
      id: 345613,
      primaryIndicationOther: 'Primary Other',
      pharmacyNcpdpId: 9900120,
      qtyQual: 'Tablet',
      genericDrugId: 'd00233',
      compound: false,
      partnerMedId: '3d676fbf-1058-41a0-99d8-d6794e1e90b6',
      secondaryIndicationOther: 'Secondary Other',
      schedule: 2,
    },
    {
      overrideReason: 'Reviewed',
      qty: 480,
      prescriberId: '00515000006l7YPAAY',
      locationId: 29,
      status: 'Ordered',
      prescriptionDate: '2018-08-08',
      pharmacyNote: null,
      routeDetail: null,
      internalNote: null,
      directions: '4 Tablet(s) Orally 4 times a day (with food)',
      drugId: 30136,
      secondaryIndication: null,
      rxnorm: '993837',
      substitutionAllowedFlag: true,
      drug: 'Tylenol with Codeine #3 oral tablet',
      ndc: '55289004815',
      primaryIndication: 'Pain - acute and/or chronic',
      refills: 2,
      csmdAttestationDate: null,
      daysSupply: 30,
      supply: false,
      patientId: '0011C00001og7hNQAQ',
      prevId: null,
      id: 345649,
      primaryIndicationOther: null,
      pharmacyNcpdpId: 9900120,
      qtyQual: 'Tablet',
      genericDrugId: 'd03423',
      compound: false,
      partnerMedId: 'b87b2ea5-57d5-4418-a8a3-bba8073d5b6f',
      secondaryIndicationOther: null,
      schedule: 3,
    },
    {
      overrideReason: 'Reviewed',
      qty: 450.5,
      prescriberId: '00515000006l7YPAAY',
      locationId: 29,
      status: 'Current',
      prescriptionDate: '2018-08-08',
      pharmacyNote: 'Sending patient with cupon',
      routeDetail:
        'e-Sent:Aug  8 2018  3:52PM VA Pharmacy 10.6MU<br />Sent/Verified',
      internalNote: null,
      directions: '1/2 Tablet Daily',
      drugId: 41513,
      secondaryIndication: null,
      rxnorm: '1049611',
      substitutionAllowedFlag: true,
      drug: 'oxyCODONE 15 mg oral tablet',
      ndc: '43063021910',
      primaryIndication: 'ALS',
      refills: 0,
      csmdAttestationDate: '2018-08-08',
      daysSupply: 999,
      supply: false,
      patientId: '0011C00001og7hNQAQ',
      prevId: null,
      id: 345669,
      primaryIndicationOther: null,
      pharmacyNcpdpId: 7723703,
      qtyQual: 'Tablet',
      genericDrugId: 'd00329',
      compound: false,
      partnerMedId: '1022237d-b455-42bf-bdf4-e407172f8cbf',
      secondaryIndicationOther: null,
      schedule: 2,
    },
  ],
  prescribingLocation: {
    zip: '77005',
    state: 'TX',
    phone: '844-952-5591',
    name: 'Aspire Health Texas',
    id: '29',
    fax: '855.722.0157',
    city: 'Houston',
    address2: 'Suite 360',
    address1: '2455 Dunstan Road',
  },
  preferredPharmacy: {
    zip: '37217',
    state: 'TN',
    phone: '6158666292',
    ncpdpId: '4447264',
    name: 'Advanced Rx Pharmacy 060- Nashville',
    id: '6813965',
    fax: '6158666293',
    city: 'Nashville',
    address2: null,
    address1: '1410 Donelson Pike, STE B6',
  },
  lastAssessmentAndPlan: {
    problems: [],
  },
  carePlan: {
    problems: [
      {
        subcategory: 'Subcategory',
        statusLabel: 'Active',
        status: 'active',
        problemTypeId: 1,
        onsetDate: null,
        label: 'Chronic Obstructive Pulmonary Disease (COPD)',
        id: 1,
        icd10Codes: null,
        details: null,
        category: 'Category',
      },
      {
        subcategory: 'Subcategory',
        statusLabel: 'Active',
        status: 'active',
        problemTypeId: 2,
        onsetDate: null,
        label: 'Congestive Heart Failure (CHF)',
        id: 2,
        icd10Codes: null,
        details: null,
        category: 'Category',
      },
    ],
    goals: [],
    actions: [],
    assessmentNotes: [],
  },
  careTeam: {
    id: 'a141C0000034CaGQAU',
    socialWorkerName: 'Alisa Jorstad',
  },
  unmappedProblemClaims: [
    {
      serviceDate: '2017-10-19',
      problemTypeId: 19,
      patientId: '0011C00001ptPpyQAE',
      description:
        'Benign lipomatous neoplasm of skin and subcutaneous tissue of trunk',
      count: 1,
      code: 'D171',
      claimId: 3573308,
    },
    {
      serviceDate: '2017-09-08',
      problemTypeId: 49,
      patientId: '0011C00001ptPpyQAE',
      description: 'Unspecified dementia without behavioral disturbance',
      count: 5,
      code: 'F0390',
      claimId: 2173685,
    },
    {
      serviceDate: '2017-07-19',
      problemTypeId: 64,
      patientId: '0011C00001ptPpyQAE',
      description: 'Nicotine dependence, unspecified, uncomplicated',
      count: 4,
      code: 'F17200',
      claimId: 1147133,
    },
    {
      serviceDate: '2017-09-08',
      problemTypeId: 15,
      patientId: '0011C00001ptPpyQAE',
      description: 'Major depressive disorder, single episode, unspecified',
      count: 1,
      code: 'F329',
      claimId: 82606,
    },
    {
      serviceDate: '2018-05-17',
      problemTypeId: 63,
      patientId: '0011C00001ptPpyQAE',
      description: 'Dry eye syndrome of bilateral lacrimal glands',
      count: 1,
      code: 'H04123',
      claimId: 3092359,
    },
    {
      serviceDate: '2018-05-17',
      problemTypeId: 52,
      patientId: '0011C00001ptPpyQAE',
      description: 'Age-related nuclear cataract, bilateral',
      count: 1,
      code: 'H2513',
      claimId: 2197869,
    },
    {
      serviceDate: '2018-05-17',
      problemTypeId: 52,
      patientId: '0011C00001ptPpyQAE',
      description: 'Primary open-angle glaucoma, bilateral, mild stage',
      count: 1,
      code: 'H401131',
      claimId: 1477592,
    },
    {
      serviceDate: '2018-04-05',
      problemTypeId: 1,
      patientId: '0011C00001ptPpyQAE',
      description: 'Chronic obstructive pulmonary disease, unspecified',
      count: 6,
      code: 'J449',
      claimId: 3896685,
    },
    {
      serviceDate: '2017-07-05',
      problemTypeId: 47,
      patientId: '0011C00001ptPpyQAE',
      description:
        'Pathological fracture, other site, initial encounter for fracture',
      count: 1,
      code: 'M8448XA',
      claimId: 2319717,
    },
    {
      serviceDate: '2017-07-19',
      problemTypeId: 47,
      patientId: '0011C00001ptPpyQAE',
      description: 'Difficulty in walking, not elsewhere classified',
      count: 4,
      code: 'R262',
      claimId: 650020,
    },
    {
      serviceDate: '2017-09-08',
      problemTypeId: 31,
      patientId: '0011C00001ptPpyQAE',
      description: 'Impaired fasting glucose',
      count: 1,
      code: 'R7301',
      claimId: 1639013,
    },
    {
      serviceDate: '2018-04-05',
      problemTypeId: 31,
      patientId: '0011C00001ptPpyQAE',
      description: 'Hyperglycemia, unspecified',
      count: 1,
      code: 'R739',
      claimId: 3504784,
    },
    {
      serviceDate: '2017-07-05',
      problemTypeId: 47,
      patientId: '0011C00001ptPpyQAE',
      description:
        'Wedge compression fracture of unspecified lumbar vertebra, initial encounter for closed fracture',
      count: 1,
      code: 'S32000A',
      claimId: 50386,
    },
    {
      serviceDate: '2017-11-14',
      problemTypeId: 47,
      patientId: '0011C00001ptPpyQAE',
      description:
        'Other specified fracture of left pubis, subsequent encounter for fracture with routine healing',
      count: 1,
      code: 'S32592D',
      claimId: 2450783,
    },
    {
      serviceDate: '2017-07-05',
      problemTypeId: 47,
      patientId: '0011C00001ptPpyQAE',
      description:
        'Other specified fracture of unspecified pubis, initial encounter for closed fracture',
      count: 1,
      code: 'S32599A',
      claimId: 2924031,
    },
    {
      serviceDate: '2017-08-01',
      problemTypeId: 47,
      patientId: '0011C00001ptPpyQAE',
      description:
        'Multiple fractures of pelvis with stable disruption of pelvic ring, subsequent encounter for fracture with routine healing',
      count: 1,
      code: 'S32810D',
      claimId: 276213,
    },
    {
      serviceDate: '2017-07-19',
      problemTypeId: 47,
      patientId: '0011C00001ptPpyQAE',
      description:
        'Fracture of unspecified part of neck of right femur, subsequent encounter for closed fracture with routine healing',
      count: 4,
      code: 'S72001D',
      claimId: 2828936,
    },
    {
      serviceDate: '2017-07-05',
      problemTypeId: 11,
      patientId: '0011C00001ptPpyQAE',
      description: 'History of falling',
      count: 1,
      code: 'Z9181',
      claimId: 2527700,
    },
  ],
  previousAssessment: {
    rememberForNextVisit:
      'The patient has a dog named Grover who loves belly rubs and treats.',
    sexOther: 'Queer',
    locationTypeOther: 'Research Base',
    locationName: 'Friendly Penguin Research Center',
    interpretationNeeded: 'no',
    pastSurgicalHistory: ['amputation', 'mastectomy', 'pacemaker', 'other'],
    pastSurgicalHistoryOther: 'Pinky toe surgery',
    amputationLimbAndLevel: 'Left arm, full',
    mastectomySide: 'left',
    otherSurgicalHistoryNotes:
      'Patient has had multiple surgeries on left pinky toe',
    inactiveMedicationsNotes: 'Nose drops',
    languageOther: 'Korean',
    tobaccoUse: 'current',
    councilOnSmokingCessation: 'no',
    alcoholUse: 'past',
    unprescribedDrugUse: 'current',
    unprescribedDrugs: ['heroin', 'other'],
    unprescribedDrugsOther: 'Meth',
    spiritualConcerns: 'yes',
    spiritualConcernsDescription:
      "Patient worries that it's just turtles, all the way down",
    anyEnvironmentalConcerns: 'yes',
    environmentalConcerns: ['patient_fall_risk', 'other'],
    environmentalConcernsOther: 'Too many knives',
    environmentalConcernsDescription:
      "The patient has knives everywhere. It's not just that they're sharp, it's that there are piles of them to trip over.",
    financialConcerns: ['food', 'other'],
    financialConcernsOther: 'They spend all their money on ice sculptures',
    appointmentTroubles: 'yes',
    veteranStatus: 'yes',
    appointmentTroublesDescription: 'Their snowmobile is rusty',
    veteranServices: 'yes',
    fallRiskScreeningFallenInLastYear: 2,
    fallRiskScreeningCaneOrWalker: 2,
    fallRiskScreeningUnsteady: 0,
    fallRiskScreeningHoldingOnToFurniture: 0,
    fallRiskScreeningWorried: 0,
    fallRiskScreeningPushToStandUp: 1,
    fallRiskScreeningTroubleSteppingOnToCurb: 1,
    fallRiskScreeningRushToToilet: 0,
    fallRiskScreeningLostFeelingInFeet: 1,
    fallRiskScreeningLightHeadedOrTiredMedicine: 0,
    fallRiskScreeningSleepOrMoodMedicine: 1,
    fallRiskScreeningDepression: 1,
    fallRiskScreeningFallCount: 3,
    fallRiskScreeningFallenWithInjury: 'yes',
    caregiverCoping: 'no',
    additionalCaregiverSupport: 'yes',
    additionalCaregiverSupportNotes:
      'They would like a parrot specialist to come in and talk to their parrot',
    additionalSocialSituationDocumentation:
      'The patient is regularly harassed by their parrot',
    vision: 'grossly_intact',
    hearing: 'grossly_intact',
    abdomenEquipment: ['g_tube_ostomy', 'colostomy'],
    genitourinaryEquipment: ['foley_catheter', 'suprapubic_catheter'],
    upperExtremityAmputation: ['left'],
    lowerExtremityAmputation: ['left_aka', 'left_bka'],
    skinWarmDry: 'yes',
    skinUlcers: ['pressure_ulcer'],
    height: 72,
    hasDocumentPoa: 'yes',
    hasDocumentSurrogate: 'no',
    hasDocumentLivingWill: 'yes',
    hasDocumentFiveWishes: 'yes',
    hasDocumentMolstPolstPost: 'no',
    hasDocumentDnr: 'no',
    carePreferences: 'state_specific_acp_form_uploaded',
    doNotRehospitalize: true,
    stateSpecificAcpForm:
      'Join Californian-Washingtonian ACP Special Deluxe Form',
    goalsOfCareDocumentation: 'The patient wants to go to the moon',
    troubleSleeping: 'yes',
    shortnessOfBreath: 'yes',
    recentNausea: 'yes',
    recentVomiting: 'yes',
    diarrhea: 'yes',
    bowelIncontinence: 'yes',
    constipation: 'yes',
    stoolFrequency: 'fewer',
    bowelMovementsHardStools: 'yes',
    bowelMovementsIncompleteStools: 'yes',
    bowelMovementsExcessOfStraining: 'yes',
    decreaseInAppetite: 'yes',
    pupilSize: 'constricted',
    pupilReactivity: 'nonreactive',
    conjunctiva: 'drainage',
    eom: 'eom_not_intact',
    head: 'trauma',
    nasalDischarge: 'nasal_discharge',
    sinusPain: 'sinus_pain',
    dentition: 'poor',
    dentures: 'yes',
    thrush: 'thrush',
    murmur: 'yes',
    pulses: 'peripheral_unequal',
    genitourinaryWithinNormalLimits: 'no',
    noFocalNeurologicalFindings: 'no',
    gait: ['foot_drop', 'non_ambulatory'],
    strength: 'decreased',
    shortTermMemory: 'reduced',
    longTermMemory: 'reduced',
    affect: 'depressed',
    psychoticSymptoms: ['auditory_hallucinations', 'visual_hallucinations'],
    speech: 'non_sensical_speech',
    primaryCaregiverContact: '2',
    primaryPatientCareContact: '3',
    primaryDecisionmakerContact: '1',
    livesWith: 'with_family',
    livesWithContact: '1',
    callLivesWithContactIfUnavailable: 'yes',
    otherContacts: '3',
    primaryDiagnosis: 'other',
    primaryDiagnosisOther: 'Some other primary reason for referral.',
    secondaryDiagnosis: 'cancer',
    secondaryDiagnosisOther: 'Some other secondary reason for referral.',
    cancerType: 'melanoma',
    cancerStage: '2',
    cancerGrade: '1',
    cancerAdditionalComplications: 'Here is some text for testing.',
    cancerTreatmentRegime: ['immunotherapy'],
    cancerTreatmentRegimeOtherClinicalTrial: 'Some treatment regime.',
    currentLivingSituation: 'other',
    currentLivingSituationOther:
      'This is a different current living situation.',
    maritalStatus: 'other',
    maritalStatusOther: 'This is a different marital status.',
    familyFriendSupportProvided: 'yes',
    familyFriendSupportAmount: 'an_adequate_amount',
    employment: 'employed',
    occupation: 'Hot dog vendor.',
    receivingServicesInHome: 'yes',
    currentServicesInHome: ['other'],
    currentServicesInHomeOther: 'Peapod Delivery service.',
    programSourceName: 'Some program source name.',
    spiritualReligious: 'yes',
    religiousPreference: 'other',
    religiousPreferenceOther: 'Flying Spaghetti Monster',
    atPeace: 'other',
    atPeaceOther: 'I feel sort of at peace.',
    congregationOrGroupMember: 'yes',
    congregationOrGroupName: 'Church of the Poison Mind',
    wantSpiritualSupport: 'yes',
    mentalHealthHistory: 'yes',
    mentalHealthDiagnoses: ['other'],
    mentalHealthDiagnosesOther: 'Some other diagnoses.',
    mentalHealthCurrentlyBeingTreated: 'yes',
    mentalHealthCurrentTreatments: ['other'],
    mentalHealthCurrentTreatmentsOther: 'Some other treatment.',
    mentalHealthCurrentTreatmentProvider: 'Some provider.',
    psychiatricHospitalizationsHistory: 'yes',
    psychiatricHospitalizationsDescription: 'I have a history of this thing.',
    substanceAbuseHistory: 'yes',
    substancesAbused: ['other'],
    substancesAbusedOther: 'Some other substance.',
    substanceAbuseCurrentlyBeingTreated: 'yes',
    substanceAbuseCurrentTreatmentProvider: 'Someone who is treating me.',
    ppsMobility: '100',
    ppsActivityLevel: '90',
    ppsSelfCare: '50',
    ppsIntake: '20',
    ppsLevelOfConsciousness: '60',
    goalProgressionTeachBack: 'not_started',
    goalProgressionReduceAvoidableHospitalizations: 'good_progress',
    goalProgressionGoalsOfCareDiscussion: 'complete',
    goalProgressionHospiceTransition: 'na',
    hospiceTransitionExplainNotAvailable: 'patient_is_medically_stable',
    advanceDirectiveCompleted: 'yes',
    advanceDirectiveDocumentCopyObtained: 'yes',
    advanceDirectiveWhyNotObtained: ['other'],
    advanceDirectiveWhyNotObtainedOther: 'Has always been too busy.',
    advanceDirectiveFollowupPlan: ['other'],
    advanceDirectiveFollowupPlanOther: 'Try again next time, I guess.',
    codeStatus: 'other',
    codeStatusOther: 'Patent pending.',
    understandingOfConditions: 'fair',
    understandingOfConditionsSupportPlan: ['other'],
    understandingOfConditionsSupportPlanOther: 'Help as best we can.',
    hasPowerOfAttorney: 'yes',
    powerOfAttorney: {
      name: {
        salutation: 'Mr.',
        first: 'Billy',
        middle: 'Bob',
        last: 'Thornton',
        suffix: 'Jr.',
        nickname: 'Slingblade',
      },
      relation: {
        choice: 'other',
        other: 'Drunk uncle',
      },
      phones: [
        {
          type: 'other',
          number: '555-555-5555',
        },
      ],
    },
    socialWorkerPrognosis: 'greater_than_6_mo',
    hospiceEligible: 'yes',
    hospiceTransitionBarriers: ['other'],
    hospiceTransitionBarriersOther:
      'Patient fled to Tahiti with a red-haired barista.',
  },
  defaultNextTargetedVisitDate: '2020-03-05',
  virtualCarePlanning: {
    internetAccessAvailable: ['home_wifi'],
    preferredVideoApplications: ['webex'],
    preferredVideoApplicationsOther: 'Zoom',
    videoDevicesAvailable: ['computer_apple'],
    videoDevicesAvailableOther: 'Blackberry',
  },
  riskAdjustedDiagnosisCodes: [
    {
      id: 1905,
      count: 2,
      status: 'new',
      hccCode: 48,
      captured: false,
      icd10Code: 'D696',
      categoryId: 6880,
      groupNumber: 1,
      hccCoefficient: '0.217',
      hccDescription:
        'Coagulation Defects and OtherSpecified Hematological Disorders',
      hccInstructions: 'Most common: senile purpura, thrombocytopenia',
      icd10Description: 'Thrombocytopenia, unspecified',
      mostRecentServiceDate: '2018-01-17',
    },
    {
      id: 1112,
      count: 36,
      status: 'new',
      hccCode: 8,
      captured: false,
      icd10Code: 'C787',
      categoryId: 5826,
      groupNumber: 2,
      hccCoefficient: '2.579',
      hccDescription: 'Metastatic Cancer and Acute Leukemia',
      hccInstructions:
        'Indicate Primary and Secondary site.  Positive lymph nodes are metastatic.  Indicate ACTIVE tx site, remission, relapse, or history of',
      icd10Description:
        'Secondary malignant neoplasm of liver and intrahepatic bile duct',
      mostRecentServiceDate: '2019-02-12',
    },
    {
      id: 1010,
      count: 2,
      status: 'new',
      hccCode: 8,
      captured: false,
      icd10Code: 'C7800',
      categoryId: 5826,
      groupNumber: 2,
      hccCoefficient: '2.579',
      hccDescription: 'Metastatic Cancer and Acute Leukemia',
      hccInstructions:
        'Indicate Primary and Secondary site.  Positive lymph nodes are metastatic.  Indicate ACTIVE tx site, remission, relapse, or history of',
      icd10Description: 'Secondary malignant neoplasm of unspecified lung',
      mostRecentServiceDate: '2018-01-17',
    },
    {
      id: 3153,
      count: 2,
      status: 'captured_external',
      hccCode: 18,
      captured: true,
      icd10Code: 'E1142',
      categoryId: 92,
      groupNumber: 2,
      hccCoefficient: '0.312',
      hccDescription: 'Diabetes with ChronicComplications',
      hccInstructions:
        'Document DM type, manifestion/complication & how monitored, assessed or if on meds link to dx/diet controlled.',
      icd10Description: 'Type 2 diabetes mellitus with diabetic polyneuropathy',
      mostRecentServiceDate: '2018-11-28',
    },
    {
      id: 4100,
      count: 2,
      status: 'captured_external',
      hccCode: 18,
      captured: true,
      icd10Code: 'E1165',
      categoryId: 92,
      groupNumber: 2,
      hccCoefficient: '0.312',
      hccDescription: 'Diabetes with ChronicComplications',
      hccInstructions:
        'Document DM type, manifestion/complication & how monitored, assessed or if on meds link to dx/diet controlled.',
      icd10Description: 'Type 2 diabetes mellitus with hyperglycemia',
      mostRecentServiceDate: '2019-02-04',
    },
    {
      id: 209,
      count: 23,
      status: 'captured_external',
      hccCode: 11,
      captured: true,
      icd10Code: 'C20',
      categoryId: 6226,
      groupNumber: 2,
      hccCoefficient: '0.296',
      hccDescription: 'Colorectal, Bladder, and OtherCancers',
      hccInstructions:
        'Indicate ACTIVE tx site, remission, relapse, or history of.  Active could be current tx, newly dx, watchful waiting, pt refuse tx/frail for tx, tx holiday.',
      icd10Description: 'Malignant neoplasm of rectum',
      mostRecentServiceDate: '2019-02-04',
    },
    {
      id: 1286,
      count: 66,
      status: 'captured_external',
      hccCode: 12,
      captured: true,
      icd10Code: 'C7A026',
      categoryId: 2104,
      groupNumber: 2,
      hccCoefficient: '0.143',
      hccDescription: 'Breast, Prostate, and Other Cancers and Tumors',
      hccInstructions:
        'Indicate ACTIVE tx site, remission, relapse, or history of.  Active could be current tx, newly dx, watchful waiting, pt refuse tx/frail for tx, tx holiday.  HX OF is not returning/no tx',
      icd10Description: 'Malignant carcinoid tumor of the rectum',
      mostRecentServiceDate: '2019-02-12',
    },
    {
      id: 757,
      count: 30,
      status: 'captured_external',
      hccCode: 12,
      captured: true,
      icd10Code: 'C61',
      categoryId: 2104,
      groupNumber: 2,
      hccCoefficient: '0.143',
      hccDescription: 'Breast, Prostate, and Other Cancers and Tumors',
      hccInstructions:
        'Indicate ACTIVE tx site, remission, relapse, or history of.  Active could be current tx, newly dx, watchful waiting, pt refuse tx/frail for tx, tx holiday.  HX OF is not returning/no tx',
      icd10Description: 'Malignant neoplasm of prostate',
      mostRecentServiceDate: '2019-02-12',
    },
    {
      id: 1364,
      count: 3,
      status: 'captured_external',
      hccCode: 12,
      captured: true,
      icd10Code: 'C801',
      categoryId: 2104,
      groupNumber: 2,
      hccCoefficient: '0.143',
      hccDescription: 'Breast, Prostate, and Other Cancers and Tumors',
      hccInstructions:
        'Indicate ACTIVE tx site, remission, relapse, or history of.  Active could be current tx, newly dx, watchful waiting, pt refuse tx/frail for tx, tx holiday.  HX OF is not returning/no tx',
      icd10Description: 'Malignant (primary) neoplasm, unspecified',
      mostRecentServiceDate: '2019-02-18',
    },
    {
      id: 4724,
      count: 6,
      status: 'captured_external',
      hccCode: 19,
      captured: true,
      icd10Code: 'E119',
      categoryId: 301,
      groupNumber: 2,
      hccCoefficient: '0.102',
      hccDescription: 'Diabetes without Complication',
      hccInstructions:
        'Document DM type, if on meds/diet controlled.  If complications do not report "no complications" code.',
      icd10Description: 'Type 2 diabetes mellitus without complications',
      mostRecentServiceDate: '2019-02-12',
    },
  ],
  requiredRiskAdjustmentCategories: [6880],
}
