// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import { patientLabDetails } from './data/patientLabDetails'
import key from './key'

const reducer = combineReducers({
  [patientLabDetails.key]: patientLabDetails,
})

reducer.key = key

export default reducer
