import { ofType } from 'redux-observable'
import {
  debounceTime,
  filter,
  map,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
import { getTaskById, saveTask, taskFormUpdated } from '../data/tasks'
import { getSaveData, isIncomplete, isUnsaved } from '../utils/saveTask'

const AUTOSAVE_DELAY = 5000

const getTask = ([id, state]) => getTaskById(id)(state)

// Automatically save an incomplete, unsaved Task
export default (action$, state$) =>
  action$.pipe(
    ofType(taskFormUpdated),
    debounceTime(AUTOSAVE_DELAY),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    map(getTask),
    filter(isIncomplete),
    filter(isUnsaved),
    map(getSaveData),
    map(saveTask.requested)
  )
