import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getPatientRecord } from './common/shared'

const key = 'admissionClaims'

const Admission = Record({
  admissionDate: null,
  admissionTypeId: null,
  admissionTypeName: null,
  admitDiagnosisCodeFormatted: null,
  admitDiagnosisCodeUnformatted: null,
  admitDiagnosisId: null,
  bedType: null,
  createdAt: null,
  createdBy: null,
  dischargeDate: null,
  dischargeDiagnosisCodeFormatted: null,
  dischargeDiagnosisCodeUnformatted: null,
  dischargeDiagnosisId: null,
  eventCreatedAt: null,
  eventId: null,
  existsEmr: null,
  facilityName: null,
  facilityNpi: null,
  globalMemberId: null,
  globalProviderId: null,
  healthPlanId: null,
  healthPlanName: null,
  hospitalAuthorizationId: null,
  icd10Code: null,
  icdCount: null,
  icu: null,
  id: null,
  isCoveredByClaim: null,
  isValid: null,
  lengthOfStay: null,
  masterAdmissionsClaimId: null,
  masterAdmissionsInvalidationSourceId: null,
  masterAdmissionsParentId: null,
  needsReview: null,
  odsRecordInsertedBy: null,
  odsRecordInsertedDatetime: null,
  odsRecordUpdatedBy: null,
  odsRecordUpdatedDatetime: null,
  paidAmount: null,
  readmission000030: null,
  readmission031060: null,
  sourceAuthorizationId: null,
  patientId: null,
  claimStatus: null,
})

const formatCurrency = paidAmount =>
  paidAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const createAdmission = admission => {
  const {
    dischargeDiagnosisCodeFormatted,
    healthPlanName,
    icd10Code,
    icdCount,
    icu,
    lengthOfStay,
    paidAmount,
    ...rest
  } = admission

  return new Admission({
    ...rest,
    healthPlanName: healthPlanName || '-',
    icd10Code: icd10Code || 'Unspecified',
    icdCount: icdCount || '-',
    icu: typeof icu === 'boolean' ? icu : '-',
    lengthOfStay: lengthOfStay || '-',
    paidAmount: paidAmount ? formatCurrency(paidAmount) : '-',
  })
}

const fetchAdmissionsApi = patientId =>
  AspireAPI.get(`claims_history/${patientId}/admissions`)

export const fetchAdmissions = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: fetchAdmissionsApi,
  transform: into(createAdmission, 'id'),
  messages: { failed: 'There was a problem fetching patient admissions' },
})

export default createReducer(key, Map(), {
  [fetchAdmissions.REQUESTED]: () => Map(),
  [fetchAdmissions.SUCCEEDED]: (_state, { payload }) => payload,
})

const getPatientAdmissions = pipe(getPatientRecord, get(key))
export const getPatientAdmissionsArray = createSelector(
  [getPatientAdmissions],
  admissions => admissions.toArray()
)
