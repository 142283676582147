import { ofType } from 'redux-observable'
import { ignoreElements, map, tap } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import { PATIENT_TIMELINE_SWIMLANE, setPermanentItem } from '~/utils/storage'
import { getSwimlane } from '../data'
import {
  swimlaneCompressionToggled,
  swimlaneDisplayToggled,
  swimlaneOrderReversed,
  swimlaneRowsPerPageChanged,
  swimlaneTimeframeChanged,
} from '../data/swimlane'

export default (action$, state$) =>
  action$.pipe(
    ofType(
      swimlaneCompressionToggled,
      swimlaneDisplayToggled,
      swimlaneOrderReversed,
      swimlaneRowsPerPageChanged,
      swimlaneTimeframeChanged
    ),
    switchTo(state$),
    map(getSwimlane),
    tap(swimlane =>
      setPermanentItem(
        PATIENT_TIMELINE_SWIMLANE,
        swimlane
          .delete('compressed')
          .delete('displayed')
          .delete('page')
          .toJSON()
      )
    ),
    ignoreElements()
  )
