import React, { useState } from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import {
  AddressDetails,
  EmailDetails,
  PatientContactRecord,
  PhoneDetails,
} from '../utils/interfaces'
import { ContactDetails } from './ContactDetails'
import { ContactEntryButtons } from './ContactEntryButtons'
import { ContactSummary } from './ContactSummary'

const useStyles = makeStyles(() => ({
  contactHeaderContent: { flexDirection: 'column' },
}))

interface PatientContactsPropTypes {
  contacts: PropTypes.list.isRequired
  readOnly: PropTypes.bool
  sourceIsAssessment: PropTypes.bool
  isCaremoreBrand: PropTypes.boolean
  onChange: PropTypes.func
}

export const PatientContacts = ({
  contacts,
  readOnly,
  sourceIsAssessment,
  isCaremoreBrand,
  onChange,
}: PatientContactsPropTypes) => {
  const classes = useStyles()

  const [includeInactive, setIncludeInactive] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [currentKey, setCurrentKey] = useState()

  const sorter = (a: any, b: any) => {
    if (a.preferred) return -1
    if (b.preferred) return 1
    if (a.type == 'patient' && b.type != 'patient') return -1
    if (a.type != 'patient' && b.type == 'patient') return 1
    if (a.name && !b.name) return -1
    if (b.name && !a.name) return 1
    if (a.name && b.name) return a.name.localeCompare(b.name)
    return a.id - b.id
  }

  const keepActive = () => {
    return contacts
      .filter((c: PatientContactRecord) => includeInactive || c.active)
      .map((c: PatientContactRecord) => ({
        id: c?.id,
        patientId: c?.patientId,
        type: c?.type,
        name: c?.name,
        relationship: c?.relationship,
        notes: c?.notes,
        preferred: c?.preferred,
        decisionMaker: c?.decisionMaker,
        delete: c?.delete,
        scheduling: c?.scheduling,
        emailAsked: c?.emailAsked,
        active: c?.active,
        phones: Array.isArray(c.phones)
          ? c.phones.filter((p: PhoneDetails) => includeInactive || p.active)
          : c.phones
              .toArray()
              .filter((p: PhoneDetails) => includeInactive || p.active),
        emails: Array.isArray(c.emails)
          ? c.emails.filter((e: EmailDetails) => includeInactive || e.active)
          : c.emails
              .toArray()
              .filter((e: EmailDetails) => includeInactive || e.active),
        addresses: Array.isArray(c.addresses)
          ? c.addresses.filter(
              (a: AddressDetails) => includeInactive || a.active
            )
          : c.addresses
              .toArray()
              .filter((a: AddressDetails) => includeInactive || a.active),
      }))
  }

  const toggleExpanded = (newValue: boolean, id: any) => {
    setExpanded(newValue)
    setCurrentKey(id)
  }

  const displayContacts = (isCaremoreBrand: boolean) => {
    const filteredContacts = keepActive()

    return filteredContacts
      .filter((c: PatientContactRecord) => !c.delete)
      .sort(sorter)
      .map((contact: PatientContactRecord) => {
        return (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            onChange={(_event, newValue) =>
              toggleExpanded(newValue, contact.id)
            }
            key={contact.id}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <ContactSummary
                contact={contact}
                expanded={expanded && contact.id === currentKey}
              />
            </AccordionSummary>
            <AccordionDetails className={classes.contactHeaderContent}>
              <ContactDetails
                contact={contact}
                isCaremoreBrand={isCaremoreBrand}
              ></ContactDetails>
            </AccordionDetails>
            {readOnly ? null : (
              <ContactEntryButtons
                contact={contact}
                sourceIsAssessment={sourceIsAssessment}
                onChange={onChange}
              />
            )}
          </Accordion>
        )
      })
  }

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <FormControlLabel
          control={
            <Checkbox
              checked={includeInactive}
              onChange={() => setIncludeInactive(!includeInactive)}
            />
          }
          label="Include Inactive"
        />
      </Box>
      <div>
        {contacts.length > 0 ? (
          displayContacts(isCaremoreBrand)
        ) : (
          <Typography align="center">No active contacts</Typography>
        )}
      </div>
    </div>
  )
}
