import { addBusinessDays, format } from 'date-fns/fp'
import { flow } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error no export
import { SelectField, TextField } from '~/components/fields'
// @ts-expect-error no export
import { getUserId } from '~/data/session'
// @ts-expect-error no export
import { getProgramEnrolled } from '~/features/patientInfo'
import { AspireProgramEnrolled } from '~/fieldValues/aspire_program_enrolled'
import { values as nominationReasons } from '~/fieldValues/nomination_reason'
import { useAction } from '~/hooks'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Cancel, Edit, Save } from '@material-ui/icons'
import { getNomination, modifyNomination, updateNomination } from '../data'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    '& > *': {
      marginBottom: spacing(2),
    },
  },
  button: {
    marginRight: spacing(1),
  },
}))

type Props = {
  id?: number
  readOnly?: boolean
}

export default function Nomination({ id, readOnly = false }: Props) {
  const classes = useStyles()

  const userId = useSelector(getUserId)
  const programEnrolled: AspireProgramEnrolled = useSelector(getProgramEnrolled)
  const nomination: any = useSelector(getNomination(id))
  const onModify = useAction(modifyNomination)

  const [disabled, setDisabled] = useState(readOnly)

  const { errors, notes, reason, targetDiscussionDate } = nomination

  const change = (payload: any) => {
    onModify(id, payload)
  }

  const done = !!(nomination.completedAt || nomination.cancelledAt)

  const currentUserIsNominator = nomination.nominatedById === userId

  const showActions = readOnly && currentUserIsNominator && !done

  useEffect(() => {
    if (!reason) {
      change({ reason: nominationReasons[0].value })
    }
  }, [reason, change])

  // Target Discussion Date
  useEffect(() => {
    if (!targetDiscussionDate) {
      let days = 0

      switch (programEnrolled) {
        case 'Five Percent Tier One':
          days = 1
          break
        case 'Five Percent Tier Two':
          days = 3
          break
        case 'Five Percent Tier Three':
          days = 5
          break
      }

      const targetDiscussionDate = flow(
        addBusinessDays(days),
        format('yyyy-MM-dd')
      )(new Date())

      change({ targetDiscussionDate })
    }
  }, [targetDiscussionDate, programEnrolled, change])

  const updateNominationReq = useAction(updateNomination.requested)

  return (
    <div className={classes.container}>
      <SelectField
        error={!!errors.reason}
        fullWidth
        helperText={(errors.reason || []).join(' ')}
        label="Nomination Reason"
        onChange={(reason: string) => {
          change({ reason })
        }}
        value={reason}
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
        required
        native
      >
        {nominationReasons.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </SelectField>

      <TextField
        error={!!errors.notes}
        fullWidth
        helperText={(errors.notes || []).join(' ')}
        multiline
        onChange={(notes: string) => {
          change({ notes })
        }}
        label="Nomination Notes"
        required
        disabled={disabled}
        rows="4"
        variant="outlined"
        value={notes}
      />

      <TextField
        error={!!errors.targetDiscussionDate}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label="Target Discussion Date:"
        onChange={(targetDiscussionDate: string) => {
          change({ targetDiscussionDate })
        }}
        disabled={disabled}
        type="date"
        value={targetDiscussionDate}
        helperText={(errors.targetDiscussionDate || []).join(' ')}
      />

      {showActions &&
        (disabled ? (
          <Button
            onClick={() => setDisabled(false)}
            variant="outlined"
            startIcon={<Edit />}
          >
            Edit Details
          </Button>
        ) : (
          <>
            <Button
              className={classes.button}
              onClick={() => {
                setDisabled(true)
                updateNominationReq({
                  id,
                  userId,
                  targetDiscussionDate,
                  reason,
                  notes,
                })
              }}
              variant="outlined"
              startIcon={<Save />}
            >
              Save
            </Button>

            <Button onClick={() => setDisabled(true)} startIcon={<Cancel />}>
              Cancel
            </Button>
          </>
        ))}
    </div>
  )
}
