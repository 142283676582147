import React from 'react'
import { InfoDialog } from '~/components/dialogs'
import Line from '~/components/text/Line'
import { useToggle } from '~/hooks'
import { Button, Icon, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette, spacing }) => ({
  flexColumnContainer: {
    backgroundColor: palette.shades.dark,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    '&:not(last-child)': {
      marginBottom: spacing(2),
    },
  },
  modalContainer: {
    width: '500px',
    maxWidth: '100vw',
  },
  icon: {
    marginRight: spacing(1),
  },
  phoneButton: {
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
    fontSize: '1rem',
    marginBottom: spacing(2),
    '&:hover': {
      backgroundColor: palette.secondary.dark,
    },
  },
}))

const getPhoneHref = (phone: string) => 'tel:1' + phone.replace(/\D+/g, '')

type Props = {
  contacts: any
}

const PatientContactModal = ({ contacts }: Props) => {
  const classes = useStyles()

  const [open, toggleOpen, toggleClosed] = useToggle()

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={toggleOpen}>
        Contacts
      </Button>

      <InfoDialog open={open} title="Patient Contacts" onClose={toggleClosed}>
        <div className={classes.modalContainer}>
          {contacts.map((c: any) => (
            <div className={classes.flexColumnContainer} key={c.phoneNumber}>
              {c.phoneNumber && (
                <Button
                  className={classes.phoneButton}
                  href={getPhoneHref(c.phoneNumber)}
                >
                  <Icon className={classes.icon}>phone</Icon>
                  {c.phoneNumber}
                </Button>
              )}
              <Line label="Name" labelSpace="2" hideIfBlank>
                {c.name}
              </Line>
              <Line label="Preferred" labelSpace="2">
                {c.isPreferred ? 'Yes' : 'No'}
              </Line>
              <Line label="Type" labelSpace="2" hideIfBlank>
                {c.type}
              </Line>
              <Line label="Relationship" labelSpace="2" hideIfBlank>
                {c.relationship}
              </Line>
              <Line label="HIPAA Authorization" labelSpace="2">
                {c.phiContact ? 'Yes' : 'No'}
              </Line>
              <Line label="PHI Messages" labelSpace="2">
                {c.phiMessageApproved ? 'Authorized' : 'Unauthorized'}
              </Line>
              {c.expired && (
                <Line label="Expired" labelSpace="2">
                  {c.effectiveEndDate}
                </Line>
              )}
              <Line label="Description" labelSpace="2">
                {c.phoneDescription}
              </Line>
            </div>
          ))}
        </div>
      </InfoDialog>
    </React.Fragment>
  )
}

export default PatientContactModal
