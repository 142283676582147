import { List, Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const EVENT_DIRECTIONS = 'eventDirections'

const EventDirections = Record({
  eventId: null,
  distance: null,
  distanceValue: null,
  duration: null,
  durationValue: null,
})

const creator = scopedCreator(rootKey)

export const eventDirectionsSet = creator('EVENT_DIRECTIONS_SET', [
  'directions',
])
export const eventDirectionsCleared = creator('EVENT_DIRECTIONS_CLEARED', false)

const initState = List()
export default createReducer(EVENT_DIRECTIONS, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [eventDirectionsSet]: (_state: any, { payload: { directions } }: any) =>
    List(directions.map(EventDirections)),
  // @ts-expect-error can we deal with the toString being automatically called?
  [eventDirectionsCleared]: () => initState,
})

export const getEventDirections = pipe(getRoot, get(EVENT_DIRECTIONS))
