import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import loading from '~/utils/loading'
import { switchTo } from '~/utils/operators'
import { fetchPolygons, loadPolygons } from '../data/polygons'
import { getPrograms } from '../data/programs'
import { MARKET_SELECTED, getSelectedMarkets } from '../data/selectedMarkets'
import { PROGRAM_SELECTED, getSelectedPrograms } from '../data/selectedPrograms'
import showError from '../utils/showError'

const marketSelectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(MARKET_SELECTED, PROGRAM_SELECTED),
    switchTo(state$),
    filter(state => {
      const selectedMarkets = getSelectedMarkets(state)
      const selectedPrograms = getPrograms(state)

      return !selectedMarkets.isEmpty() && !selectedPrograms.isEmpty()
    }),
    mergeMap(state =>
      loading(
        from(
          fetchPolygons(getSelectedMarkets(state), getSelectedPrograms(state))
        ).pipe(
          map(loadPolygons),
          catchError(showError('Failed to fetch Polygons.'))
        )
      )
    )
  )

export default marketSelectedEpic
