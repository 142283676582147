import { List, Map, Record } from 'immutable'
import {
  Codec,
  GetType,
  array,
  boolean,
  number,
  optional,
  string,
} from 'purify-ts'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { createRequest } from '~/utils/createRequest'
import rootKey from '../../key'
import { CarePlanGoalTypes } from '../carePlanGoalTypes'
import { ICD10Code } from '../common/shared'
import { ProblemCategory } from '../problemCategories'
import { getTypes } from './root'

export const ProblemType = Record({
  id: null,
  valid: true,
  label: null,
  priority: false,
  problemCategoryId: null,
  category: null,
  subcategory: null,
  icd10Codes: List(),
})

const PROBLEM_TYPES = 'problemTypes'

const transformProblemType = ({
  problemCategory,
  icd10Codes,
  ...problemType
}: any) =>
  ProblemType({
    ...problemType,
    category: problemCategory.category,
    subcategory: problemCategory.subcategory,
    icd10Codes: List(
      icd10Codes.map(({ icd10Definition }: any) => ICD10Code(icd10Definition))
    ),
  })

const transformProblemTypes = (problemTypes: any) =>
  Map(
    problemTypes.map((problemType: any) => [
      problemType.id,
      transformProblemType(problemType),
    ])
  )

export const fetchProblemTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PROBLEM_TYPES',
  requestParams: ['problemCategoryId'],
  operation: (problemCategoryId: number) =>
    AspireAPI.get(
      `care_plan/problem_categories/${problemCategoryId}/problem_types`
    ),
  transform: transformProblemTypes,
  messages: { failed: 'There was an issue fetching problem types' },
})

export const fetchSimilarProblemTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_SIMILAR_PROBLEM_TYPES',
  requestParams: ['problemCategoryId'],
  operation: (problemCategoryId: number) =>
    AspireAPI.get(
      `care_plan/problem_categories/${problemCategoryId}/similar_problem_types`
    ),
  transform: transformProblemTypes,
  messages: { failed: 'There was an issue fetching problem types' },
})

export const fetchDiagnosisProblemTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_DIAGNOSIS_PROBLEM_TYPES',
  requestParams: ['icd10Code'],
  operation: (icd10Code: string) =>
    AspireAPI.get(`care_plan/problem_types/diagnoses/${icd10Code}`),
  transform: transformProblemTypes,
  messages: { failed: 'There was an issue fetching problem types' },
})

export const fetchProblemType = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PROBLEM_TYPE',
  requestParams: ['problemTypeId'],
  operation: (problemTypeId: number) =>
    AspireAPI.get(`care_plan/problem_types/${problemTypeId}`),
  transform: transformProblemType,
  messages: { failed: 'There was an issue fetching this problem type' },
})

export default createReducer(PROBLEM_TYPES, Map(), {
  [fetchProblemTypes.SUCCEEDED]: (state: any, { payload }: any) =>
    state.merge(payload),
  [fetchSimilarProblemTypes.SUCCEEDED]: (state: any, { payload }: any) =>
    state.merge(payload),
  [fetchDiagnosisProblemTypes.SUCCEEDED]: (state: any, { payload }: any) =>
    state.merge(payload),
  [fetchProblemType.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set(payload.id, payload),
})

export const getProblemTypes = (state: any): any =>
  getTypes(state).get(PROBLEM_TYPES)

// NEW REQUEST STUFF
const Claim = Codec.interface({
  patientId: string,
  problemTypeId: number,
  code: string,
  description: string,
  serviceDate: string,
  claimId: number,
  count: number,
})
const Claims = array(Claim)

const ProblemTypeCodec = Codec.interface({
  id: number,
  valid: boolean,
  label: string,
  problemCategory: ProblemCategory,
  carePlanGoalTypes: CarePlanGoalTypes,
  claims: optional(Claims),
})
export type ProblemType = GetType<typeof ProblemTypeCodec>

export const ProblemTypes = array(ProblemTypeCodec)
export type ProblemTypes = GetType<typeof ProblemTypes>

export const fetchProblemTypeRequest = createRequest<
  [problemTypeId: number],
  ProblemType
>(
  problemTypeId => AspireAPI.get(`care_plan/problem_types/${problemTypeId}`),
  ProblemTypeCodec
)

export const fetchClaimsProblemTypes = createRequest<
  [assessmentId: number],
  ProblemTypes
>(
  assessmentId =>
    AspireAPI.get(`care_plan/problem_types/claims/${assessmentId}`),
  ProblemTypes
)
