import { ofType } from 'redux-observable'
import {
  combineLatest,
  distinctUntilChanged,
  mergeMap,
  pluck,
} from 'rxjs/operators'
import { fetchUser, getUserId } from '~/data/session'
import {
  fetchPatientPreferredPharmacy,
  fetchPatientPrescriber,
  fetchPatientPrescribingLocation,
  fetchPrescriber,
  patientIdProvided,
} from '../data/patientInformation'
import { fetchPatientRx } from '../data/patientRx'

/* FETCH ALL PATIENT INFORMATION WHEN MED FEATURE RECIEVES PATIENT ID */
/* DEFAULT PRESCRIBER TO USER IF NP OR PHYSICIAN - FALL BACK TO PATIENTS APP */
export default (action$, state$) => {
  const roleProvided$ = action$.pipe(
    ofType(fetchUser.SUCCEEDED),
    pluck('payload', 'role')
  )

  return action$.pipe(
    ofType(patientIdProvided),
    pluck('payload', 'id'),
    distinctUntilChanged(),
    combineLatest(roleProvided$),
    mergeMap(([patientId, userRole]) => {
      const userIsPrescriber = prescribingRoles.includes(userRole)
      const userId = getUserId(state$.value)
      const locationParams = {
        patientId: patientId,
        ...(userIsPrescriber && { prescriberId: userId }),
      }

      return [
        fetchPatientRx.requested(patientId),
        fetchPatientPrescribingLocation.requested(locationParams),
        userIsPrescriber
          ? fetchPrescriber.requested(userId)
          : fetchPatientPrescriber.requested(patientId),
        fetchPatientPreferredPharmacy.requested(patientId),
      ]
    })
  )
}

const prescribingRoles = ['Nurse Practitioner', 'Physician']
