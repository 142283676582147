import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import rootKey from '../key'
import { getRoot } from './common/shared'

export const key = `healthRiskAssessment`

export const fetchDueHealthRiskAssessments = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_DUE_HRA',
  requestParams: ['userId'],
  operation: userId => AspireAPI.get(`/case_manager_dashboard/${userId}/hra`),
})

const initState = {
  dueHealthRiskAssessments: [],
}

const set = key => (state, { payload }) => ({ ...state, [key]: payload })

export default createReducer(key, initState, {
  [fetchDueHealthRiskAssessments.SUCCEEDED]: set('dueHealthRiskAssessments'),
})

const getHealthRiskAssessments = state => getRoot(state).get(key)

export const getDueHealthRiskAssessments = state =>
  getHealthRiskAssessments(state).dueHealthRiskAssessments
