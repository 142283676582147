import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/operators' or its corr... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { getEntity } from '../data/entity'
import { FORM_REFRESHED, fetchMockForm } from '../data/form'
import { getTemplateKey } from '../data/templateKey'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(FORM_REFRESHED),
    switchTo(state$),
    map(state =>
      fetchMockForm.requested(getTemplateKey(state), getEntity(state))
    )
  )
