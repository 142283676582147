import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { episodeOfCareSelected } from '../data/currentEpisodeOfCareId'
import { fetchEpisodeOfCare } from '../data/episodesOfCare'

export default action$ => {
  return action$.pipe(
    ofType(episodeOfCareSelected),
    pluck('payload', 'id'),
    filter(Boolean),
    map(fetchEpisodeOfCare.requested)
  )
}
