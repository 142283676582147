import React from 'react'
import PatientInfo from '../PatientInfo'
import GridItem from './GridItem'
import ChangeAddressButton from './actions/ChangeAddressButton'

const PatientInfoGridItem = () => (
  <GridItem title="Patient Info" actions={[ChangeAddressButton]}>
    <PatientInfo />
  </GridItem>
)

export default PatientInfoGridItem
