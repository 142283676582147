import { defaultEnd, defaultStart } from '../defaults'
import { visitTypes } from './constants'

export default {
  type: 'Visit',
  recordType: 'visit',
  eventType: visitTypes.FOLLOW_UP.value,
  encounterStatus: 'Scheduled',
  confirmed: false,
  cancelled: false,
  urgent: false,
  hasCase: false,
  start: defaultStart,
  end: defaultEnd,
}
