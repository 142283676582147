import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SUBSCRIBER_CHOICES } from '../constants'
import { coverageSelectedForEditing, getCoverageId } from '../data/dialogs'
import { getHealthplanProgramList } from '../data/healthplanSpecificPrograms'
import {
  getCoverageById,
  updateInsuranceCoverage,
} from '../data/insuranceCoverages'

const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(1),
    '& div': {
      marginBottom: spacing(0.5),
    },
  },
  containerChild: {
    marginTop: spacing(1),
    width: '100%',
  },
})

const filteredHealthplanSpecificPrograms = createSelector(
  [h => h, (h, id) => id],
  (programs, id) =>
    programs ? programs.filter(item => item.healthplanId == id) : []
)

const initState = {
  accountId: null,
  effectiveDate: null,
  expirationDate: null,
  groupNumber: null,
  groupName: null,
  healthplanId: null,
  healthplanAccountId: null,
  healthplanName: null,
  healthplanPatientId: null,
  healthplanSpecificPrograms: null,
  id: null,
  insuranceNotes: null,
  mbi: null,
  primaryContract: null,
  priority: null,
  productLineC: null,
  subscriberChoice: null,
  subscriberDob: null,
  subscriberName: null,
  vbid: null,
}

const CoverageEditDialog = ({
  classes,
  coverageId,
  onClose,
  healthplanProgramList,
  updateCoverage,
  coverageDetails,
  stateCode,
}) => {
  const [state, setState] = useState(initState)

  const {
    accountId,
    effectiveDate,
    expirationDate,
    groupNumber,
    groupName,
    healthplanId,
    healthplanAccountId,
    healthplanName,
    healthplanPatientId,
    healthplanSpecificPrograms,
    insuranceNotes,
    loadedId,
    mbi,
    priority,
    productLineC,
    subscriberChoice,
    subscriberDob,
    subscriberName,
    vbid,
  } = state

  const [isHumanaError, setIsHumanaError] = useState(false)

  useEffect(() => {
    if (healthplanName) {
      setIsHumanaError(
        healthplanName === 'Humana' &&
          !/^[0-9]{1,13}$/.test(healthplanPatientId)
      )
    } else {
      setIsHumanaError(false)
    }
  }, [healthplanName, healthplanPatientId])

  const closeDialog = () => {
    setState(initState)
    setIsHumanaError(false)
    onClose()
  }

  const editAccountId = event =>
    setState({ ...state, accountId: event.target.value })
  const editEffectiveDate = event =>
    setState({ ...state, effectiveDate: event.target.value })
  const editExpirationDate = event =>
    setState({ ...state, expirationDate: event.target.value })
  const editGroupNumber = event =>
    setState({ ...state, groupNumber: event.target.value })
  const editGroupName = event =>
    setState({ ...state, groupName: event.target.value })
  const editInsuranceNotes = event =>
    setState({ ...state, insuranceNotes: event.target.value })
  const editPriority = event => {
    if (event.target.value > 0 && event.target.value <= 10)
      setState({ ...state, priority: Math.floor(event.target.value) })
  }
  const editHealthplanSpecificPrograms = event =>
    setState({ ...state, healthplanSpecificPrograms: event.target.value })
  const editSubscriberChoice = event =>
    setState({ ...state, subscriberChoice: event.target.value })
  const editSubscriberDob = event =>
    setState({ ...state, subscriberDob: event.target.value })
  const editsubscriberName = event =>
    setState({ ...state, subscriberName: event.target.value })
  const editHealthplanPatientId = event =>
    setState({ ...state, healthplanPatientId: event.target.value })
  const editVbid = event => setState({ ...state, vbid: event.target.value })
  const editProductLine = event =>
    setState({ ...state, productLineC: event.target.value })

  const coverageDetailsId =
    coverageDetails && coverageDetails.id ? coverageDetails.id : ''

  const editableVbidStates = ['CO', 'FL', 'GA', 'IN', 'KY', 'OH', 'VA', 'WI']

  useEffect(() => {
    if (coverageId && coverageDetails && coverageDetails.id !== loadedId) {
      setState({
        ...state,
        accountId: coverageDetails.accountId,
        effectiveDate: coverageDetails.effectiveDate,
        expirationDate: coverageDetails.expirationDate,
        groupNumber: coverageDetails.groupNumber,
        groupName: coverageDetails.groupName,
        healthplanId:
          coverageDetails.healthplan && coverageDetails.healthplan.id,
        healthplanAccountId: coverageDetails.healthplanAccountId,
        healthplanName: coverageDetails.healthplan,
        healthplanPatientId: coverageDetails.healthplanPatientId,
        healthplanSpecificPrograms: coverageDetails.healthplanSpecificPrograms,
        id: coverageDetails.id,
        insuranceNotes: coverageDetails.insuranceNotes,
        mbi: coverageDetails.mbi,
        patientId: coverageDetails.patientId,
        primaryContract: coverageDetails.primaryContract,
        priority: coverageDetails.priority,
        productLineC: coverageDetails.productLineC,
        subscriberChoice: coverageDetails.subscriberChoice,
        subscriberDob: coverageDetails.subscriberDob,
        subscriberName: coverageDetails.subscriberName,
        vbid: coverageDetails.vbid,
      })
    }
  }, [coverageId, loadedId, coverageDetailsId])

  const filteredHealthplanProgramList = filteredHealthplanSpecificPrograms(
    healthplanProgramList,
    healthplanId
  )

  const helperText = isHumanaError
    ? 'Invalid Humana ID (Gen Key). Enter only numeric values not exceeding 13 digits'
    : ''

  return (
    <ActionDialog
      fullWidth
      maxWidth="sm"
      mode="save"
      onClose={closeDialog}
      onSave={() => updateCoverage(state)}
      disableAction={
        !effectiveDate ||
        !accountId ||
        !subscriberChoice ||
        !priority ||
        isHumanaError ||
        !(healthplanName == 'HAP' || healthplanName == 'HAP Medicaid'
          ? groupNumber
          : true)
      }
      open={!!coverageId}
      title="Update Insurance Coverage"
    >
      {coverageDetails ? (
        <div className={classes.container}>
          <TextField
            label="Healthplan"
            id="healthplan-name"
            InputLabelProps={{ shrink: true }}
            disabled
            value={healthplanName || ''}
          />
          <TextField
            label="Healthplan Account Number"
            id="account-number"
            fullWidth
            onChange={editAccountId}
            value={accountId || ''}
          />
          <TextField
            label="Healthplan Account ID"
            id="healthplan-account-id"
            InputLabelProps={{ shrink: true }}
            disabled
            value={healthplanAccountId || ''}
          />
          <TextField
            label="Healthplan Group Number"
            id="group-number"
            fullWidth
            onChange={editGroupNumber}
            value={groupNumber || ''}
          />
          <TextField
            label="Healthplan Group Name"
            id="group-name"
            fullWidth
            onChange={editGroupName}
            value={groupName || ''}
          />
          <TextField
            label="Alternate Healthplan Patient ID"
            id="alternate-healthplan-patient-id"
            error={isHumanaError}
            fullWidth
            helperText={helperText}
            onChange={editHealthplanPatientId}
            value={healthplanPatientId || ''}
          />
          <TextField
            label="MBI"
            id="mbi"
            InputLabelProps={{ shrink: true }}
            disabled
            value={mbi || ''}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Effective Date"
            onChange={editEffectiveDate}
            type="date"
            value={effectiveDate || ''}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Expiration Date"
            onChange={editExpirationDate}
            type="date"
            value={expirationDate || ''}
          />
          {filteredHealthplanProgramList.length > 0 && (
            <FormControl>
              <InputLabel htmlFor="subscriber-choice">
                Healthplan Specific Program
              </InputLabel>
              <Select
                classes={{ root: classes.selectRoot }}
                className={classes.select}
                input={
                  <Input
                    name="healthplan_specific_program"
                    id="healthplan_specific_program"
                  />
                }
                name="healthplan_specific_program"
                onChange={editHealthplanSpecificPrograms}
                value={healthplanSpecificPrograms || ''}
              >
                <MenuItem key={'none'} value={null}>
                  -- None --
                </MenuItem>
                {filteredHealthplanProgramList.map(({ value }) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            label="Insurance Notes"
            id="insurance-notes"
            fullWidth
            onChange={editInsuranceNotes}
            value={insuranceNotes || ''}
          />
          <TextField
            label="Priority"
            id="priority"
            fullWidth
            type="number"
            onChange={editPriority}
            value={priority || ''}
          />
          <FormControl required>
            <InputLabel htmlFor="subscriber-choice">Subscriber</InputLabel>
            <Select
              classes={{ root: classes.selectRoot }}
              className={classes.select}
              label="Subscriber"
              input={<Input name="subscriber-choice" id="subscriber-choice" />}
              name="subscriber-choice"
              onChange={editSubscriberChoice}
              value={subscriberChoice}
            >
              {SUBSCRIBER_CHOICES.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Subscriber Name"
            id="subscriber-name"
            fullWidth
            onChange={editsubscriberName}
            value={subscriberName || ''}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Subscriber DOB"
            onChange={editSubscriberDob}
            type="date"
            value={subscriberDob || ''}
          />
          {editableVbidStates.includes(stateCode) &&
            healthplanName === 'Humana' && (
              <FormControl fullWidth>
                <InputLabel id="vbid-choice">
                  Value Based Insurance Design
                </InputLabel>
                <Select
                  classes={{ root: classes.selectRoot }}
                  className={classes.select}
                  label="Value Based Insurance Design"
                  input={<Input name="vbid-choice" id="vbid-choice" />}
                  name="vbid-choice"
                  onChange={editVbid}
                  value={vbid}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            )}
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Product Line"
            id="productLineC"
            fullWidth
            onChange={editProductLine}
            value={productLineC || ''}
          />
        </div>
      ) : (
        <Typography>Coverage Loading</Typography>
      )}
    </ActionDialog>
  )
}

CoverageEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  coverageId: PropTypes.string,
  updateCoverage: PropTypes.func.isRequired,
  coverageDetails: PropTypes.object,
  healthplanProgramList: PropTypes.array,
  stateCode: PropTypes.string,
}

const mapStateToProps = state => ({
  coverageId: getCoverageId(state),
  coverageDetails: getCoverageById(state, getCoverageId(state)),
  healthplanProgramList: getHealthplanProgramList(state),
})

const mapDispatchToProps = {
  onClose: () => coverageSelectedForEditing(null),
  updateCoverage: updateInsuranceCoverage.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CoverageEditDialog)
