import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const PATIENT_RECORD_ALLERGIES = `${key}.allergies`
export const PATIENT_RECORD_ATTACHMENTS = `${key}.attachments`
export const PATIENT_RECORD_NEXTGEN_ATTACHMENTS = `${key}.nextGenAttachments`
export const PATIENT_RECORD_AUTH_TO_RELEASE = `${key}.authToRelease`
export const PATIENT_RECORD_CALL = `${key}.call`
export const PATIENT_RECORD_COMMUNICATIONS = `${key}.communications`
export const PATIENT_RECORD_CALL_DETAIL = `${key}.callDetail`
export const PATIENT_RECORD_CARE_PLAN = `${key}.carePlan`
export const PATIENT_RECORD_CARE_PLAN_NEW = `${key}.carePlanNew`
export const PATIENT_RECORD_CARE_TEAM = `${key}.careTeam`
export const PATIENT_RECORD_CHANNEL_INSURANCE = `${key}.channelInsurance`
export const PATIENT_RECORD_CONSENT_FOR_SERVICES = `${key}.consentForServices`
export const PATIENT_RECORD_CONTACT = `${key}.contact`
export const PATIENT_RECORD_HISTORY_AUTHORIZATIONS = `${key}.caremoreAuths`
export const PATIENT_RECORD_DEMOGRAPHICS = `${key}.demographics`
export const PATIENT_RECORD_EPISODES_OF_CARE = `${key}.episodesOfCare`
export const PATIENT_RECORD_EPISODE_OF_CARE_DETAIL = `${key}.episodeOfCareDetail`
export const PATIENT_RECORD_FLU_SHOTS = `${key}.fluShots`
export const PATIENT_RECORD_HRH = `${key}.highRiskHuddle`
export const PATIENT_RECORD_HIPAA_AUTHORIZATION = `${key}.hipaaAuthorization`
export const PATIENT_RECORD_HISTORY_ADMITS = `${key}.historyAdmits`
export const PATIENT_RECORD_HISTORY_ASSESSMENTS = `${key}.historyAssessments`
export const PATIENT_RECORD_HISTORY_CLAIMS = `${key}.historyClaims`
export const PATIENT_RECORD_HISTORY_DISCHARGES = `${key}.historyDischarges`
export const PATIENT_RECORD_HISTORY_COVID19_STATUS = `${key}.historyCovid19Status`
export const PATIENT_RECORD_MEDICATIONS = `${key}.medications`
export const PATIENT_RECORD_NOTICE_OF_PRIVACY_PRACTICES = `${key}.noticeOfPrivacyPractices`
export const PATIENT_RECORD_ON_CALL = `${key}.onCall`
export const PATIENT_RECORD_PATIENT_INFO = `${key}.patientInfo`
export const PATIENT_RECORD_PHARMACIES = `${key}.pharmacies`
export const PATIENT_RECORD_OUTLIER_REVIEWS = `${key}.outlierReview`
export const PATIENT_RECORD_REFERRAL_INFO = `${key}.referralInfo`
export const PATIENT_RECORD_RESEARCH_CASES = `${key}.researchCases`
export const PATIENT_RECORD_SCREENINGS = `${key}.screenings`
export const PATIENT_RECORD_SERVICE_INFO = `${key}.serviceInfo`
export const PATIENT_RECORD_STAKEHOLDERS = `${key}.stakeholders`
export const PATIENT_RECORD_TASKS_BY_PATIENT = `${key}.tasksByPatient`
export const PATIENT_RECORD_TASK_DETAIL = `${key}.taskDetail`
export const PATIENT_RECORD_ORDERS_INDEX = `${key}.ordersIndex`
export const PATIENT_RECORD_ORDER_DETAIL = `${key}.orderDetail`
export const PATIENT_RECORD_TIMELINE = `${key}.timeline`
export const PATIENT_RECORD_VIDEO_VISIT_CONSENT = `${key}.videoVisitConsent`
export const PATIENT_RECORD_HISTORY_LABS = `${key}.labs`
export const PATIENT_RECORD_VITALS = `${key}.vitals`

export const PATIENT_RECORD_ORDERS_ROUTES = [
  PATIENT_RECORD_ORDERS_INDEX,
  PATIENT_RECORD_ORDER_DETAIL,
]

export default Router([
  Route(PATIENT_RECORD_ALLERGIES, '/:patientId/allergies'),
  Route(PATIENT_RECORD_ATTACHMENTS, '/:patientId/attachments'),
  Route(PATIENT_RECORD_NEXTGEN_ATTACHMENTS, '/:patientId/nextGenAttachments'),
  Route(PATIENT_RECORD_AUTH_TO_RELEASE, '/:patientId/auth-to-release'),
  Route(PATIENT_RECORD_CALL, '/:patientId/log-call'),
  Route(PATIENT_RECORD_COMMUNICATIONS, ':/patientId/communications'),
  Route(PATIENT_RECORD_CALL_DETAIL, '/:patientId/call/:callId'),
  Route(PATIENT_RECORD_CARE_PLAN, '/:patientId/care-plan'),
  Route(PATIENT_RECORD_CARE_PLAN_NEW, '/:patientId/care-plan-new'),
  Route(PATIENT_RECORD_CARE_TEAM, '/:patientId/aspire-team'),
  Route(PATIENT_RECORD_CHANNEL_INSURANCE, '/:patientId/channel-insurance'),
  Route(
    PATIENT_RECORD_CONSENT_FOR_SERVICES,
    '/:patientId/consent-for-services'
  ),
  Route(PATIENT_RECORD_CONTACT, '/:patientId/contact'),
  Route(PATIENT_RECORD_DEMOGRAPHICS, '/:patientId/demographics'),
  Route(PATIENT_RECORD_EPISODES_OF_CARE, '/:patientId/episodes-of-care'),
  Route(
    PATIENT_RECORD_EPISODE_OF_CARE_DETAIL,
    '/:patientId/episodes-of-care/:episodeOfCareId'
  ),
  Route(PATIENT_RECORD_FLU_SHOTS, '/:patientId/flu-shots'),
  Route(PATIENT_RECORD_HRH, '/:patientId/high-risk-huddle'),
  Route(PATIENT_RECORD_HIPAA_AUTHORIZATION, '/:patientId/hipaa'),
  Route(PATIENT_RECORD_HISTORY_ADMITS, '/:patientId/history-admits'),
  Route(PATIENT_RECORD_HISTORY_ASSESSMENTS, '/:patientId/history-assessments'),
  Route(PATIENT_RECORD_HISTORY_CLAIMS, '/:patientId/history-claims'),
  Route(PATIENT_RECORD_HISTORY_DISCHARGES, '/:patientId/history-discharges'),
  Route(
    PATIENT_RECORD_HISTORY_COVID19_STATUS,
    '/:patientId/history-covid19-status'
  ),
  Route(
    PATIENT_RECORD_HISTORY_AUTHORIZATIONS,
    '/:patientId/history-authorizations'
  ),
  Route(
    PATIENT_RECORD_NOTICE_OF_PRIVACY_PRACTICES,
    '/:patientId/notice-of-privacy-practices'
  ),
  Route(PATIENT_RECORD_MEDICATIONS, '/:patientId/medications'),
  Route(PATIENT_RECORD_ON_CALL, '/:patientId/on-call'),
  Route(PATIENT_RECORD_PATIENT_INFO, '/:patientId/patient-info'),
  Route(PATIENT_RECORD_PHARMACIES, '/:patientId/pharmacies'),
  Route(PATIENT_RECORD_OUTLIER_REVIEWS, '/:patientId/outlier-reviews'),
  Route(PATIENT_RECORD_REFERRAL_INFO, '/:patientId/referral-info'),
  Route(PATIENT_RECORD_RESEARCH_CASES, '/:patientId/research-cases'),
  Route(PATIENT_RECORD_SCREENINGS, '/:patientId/screenings'),
  Route(PATIENT_RECORD_SERVICE_INFO, '/:patientId/service-info'),
  Route(PATIENT_RECORD_STAKEHOLDERS, '/:patientId/stakeholders'),
  Route(PATIENT_RECORD_TASKS_BY_PATIENT, '/:patientId/tasks'),
  Route(PATIENT_RECORD_TASK_DETAIL, '/:patientId/tasks/:taskId'),
  Route(PATIENT_RECORD_ORDERS_INDEX, '/:patientId?/orders'),
  Route(PATIENT_RECORD_ORDER_DETAIL, '/:patientId?/orders/:orderId'),
  Route(PATIENT_RECORD_TIMELINE, '/:patientId/timeline'),
  Route(PATIENT_RECORD_VIDEO_VISIT_CONSENT, '/:patientId/video-visit-consent'),
  Route(PATIENT_RECORD_HISTORY_LABS, '/:patientId/labs'),
  Route(PATIENT_RECORD_VITALS, '/:patientId/vitals'),
  Redirect(PATIENT_RECORD_PATIENT_INFO, '/:patientId'),
])
