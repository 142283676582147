import { isEqual } from 'lodash'
import { formValueSelector } from 'redux-form/immutable'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import {
  getPatientId,
  getPatientPharmacyId,
  getPharmacyInfo,
  getPrescriberId,
  getPrescriberName,
  getPrescribingLocationId,
  getPrescribingLocationName,
} from '../data/patientInformation'
import { getSelectedMedication } from '../data/selectedMedication'
import { drugForms } from './directionsConstants'
import { isPending } from './medListConstants'

export const formSelector = formValueSelector('medications.rxWriter')

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

const getDirectionsValue = state => formSelector(state, 'directions') || ''
const getDaysSupplyValue = state => formSelector(state, 'daysSupply')

const getCalculatedQtyQualValue = createSelector(
  [getDirectionsValue],
  directionsValue => {
    const lowerCaseDirections = directionsValue.toLowerCase()
    return drugForms
      .map(x => x.name)
      .find(form => lowerCaseDirections.includes(form.toLowerCase()))
  }
)

const calculateDispensedNumber = ({ number, frequency }, daysSupply) =>
  number && frequency && daysSupply ? number * frequency * daysSupply : ''

const getCalculatedDirectionsValues = createSelector(
  [getDirectionsValue],
  directionsValue => {
    const [, numString, , freqString] = directionsValue.split(/(\d+)/) || []
    const number = parseInt(numString)
    const frequency = parseInt(freqString)
    return { number: number || null, frequency: frequency || null }
  }
)

const getCalulatedQtyValue = createSelector(
  [getCalculatedDirectionsValues, getDaysSupplyValue],
  (calculatedDirectionsValues, daysSupplyValue) =>
    calculateDispensedNumber(calculatedDirectionsValues, daysSupplyValue)
)

const getCsmdAttestationDate = createSelector(
  [getSelectedMedication],
  selectedMedication => selectedMedication.get('csmdAttestationDate')
)
const getAdherenceValue = createSelector(
  [getSelectedMedication],
  selectedMedication => selectedMedication.get('adherence')
)

const getOverrideReason = createSelector(
  [getSelectedMedication],
  selectedMedication => selectedMedication.get('overrideReason')
)

const getRefillValue = createSelector(
  [getSelectedMedication],
  selectedMedication => (selectedMedication.get('schedule') === 2 ? '0' : null)
)

const isNewPrescription = createSelector(
  [getSelectedMedication],
  selectedMedication => !selectedMedication.get('isEditing')
)

const getStatusValue = state => formSelector(state, 'status')
const isPrescriptionPending = createSelector([getStatusValue], status =>
  isPending(status)
)

const isPrescriberNeeded = createSelector(
  [isNewPrescription, isPrescriptionPending],
  (isNew, isPending) => isNew || isPending
)

const calculateInitialValues = createSelector(
  [
    getAdherenceValue,
    getCalculatedQtyQualValue,
    getCalulatedQtyValue,
    getRefillValue,
    getOverrideReason,
    getCsmdAttestationDate,
    getPrescriberName,
    getPrescriberId,
    getPrescribingLocationId,
    getPrescribingLocationName,
    getPharmacyInfo,
    getPatientPharmacyId,
    getPatientId,
    isPrescriberNeeded,
  ],
  (
    adherence,
    qtyQual,
    qty,
    refills,
    overrideReason,
    csmdAttestation,
    prescriber,
    prescriberId,
    prescribingLocationId,
    prescribingLocation,
    pharmacyInfo,
    pharmacyId,
    patientId,
    isPrescriberNeeded
  ) => ({
    overrideReason: overrideReason || 'Reviewed',
    csmdAttestationCheck: Boolean(csmdAttestation),
    alertCheck: Boolean(overrideReason),
    substitutionAllowedFlag: '1',
    pharmacy: pharmacyInfo,
    pharmacyNcpdpId: pharmacyId,
    adherence,
    patientId,
    ...(Boolean(qtyQual) && { qtyQual }),
    ...(Boolean(qty) && { qty }),
    ...(Boolean(refills) && { refills }),
    ...(isPrescriberNeeded && {
      locationId: prescribingLocationId,
      prescriber,
      prescriberId,
      prescribingLocation,
    }),
  })
)

export const mapCalculatedInitialValues = createDeepEqualSelector(
  [calculateInitialValues, getSelectedMedication],
  (initialValues, selectedMedication) => selectedMedication.merge(initialValues)
)
