import { AsOpaque, AType, EType, summon } from '~/utils/type'

const AttachmentType_ = summon(F =>
  F.interface(
    {
      name: F.string(),
    },
    'AttachmentType'
  )
)
export interface AttachmentType extends AType<typeof AttachmentType_> {}
export interface AttachmentTypeRaw extends EType<typeof AttachmentType_> {}
export const AttachmentType = AsOpaque<AttachmentTypeRaw, AttachmentType>()(
  AttachmentType_
)

const PatientStats_ = summon(F =>
  F.interface(
    {
      patientId: F.string(),
      firstName: F.string(),
      lastName: F.string(),
      gender: F.string(),
      globalMemberId: F.string(),
      dateOfBirth: F.date(),
      market: F.string(),
      state: F.string(),
      age: F.number(),
      status: F.string(),
      substatus: F.string(),
      primaryInsuranceNumber: F.string(),
      referredDate: F.nullable(F.date()),
      receiptOfReferralDate: F.date(),
      initialActiveDate: F.nullable(F.date()),
      dateReinstated: F.nullable(F.date()),
      primaryDiagnosis: F.string(),
      referralSourceType: F.string(),
      // These are comin' back empty for some reason :shrug:
      referringPhysicianName: F.nullable(F.string()),
      referringEntityName: F.nullable(F.string()),
      primaryCarePhysician: F.nullable(F.string()),
      primaryCarePhysicianPractice: F.nullable(F.string()),
      primaryCarePhysicianIpa: F.nullable(F.string()),
      appName: F.string(),
      clinicalManagerName: F.string(),
      physicianName: F.string(),
      dischargeDate: F.nullable(F.date()),
      dischargeReason: F.nullable(F.string()),
      primaryContract: F.string(),
      healthPlan: F.string(),
      daysOnService: F.number(), // "LLOS (In Days)
      providerPrognosis: F.nullable(F.string()),
      /* hospiceEligible: Aspire.Field.Confirmation.Value */
      hospiceEligible: F.nullable(F.string()),
      ppsScore: F.nullable(F.number()),
      hospitalizationsInLast90: F.number(),
      totalHospitalizations: F.number(),
      erVisitsInLast90: F.number(),
      totalErVisits: F.number(),
      encountersInLast90: F.number(),
      totalEncounters: F.number(),
      swEncountersInLast90: F.number(),
      totalSwEncounters: F.number(),
      afterHourCallsInLast90: F.number(),
      totalAfterHourCalls: F.number(),
      rnTasksInLast90: F.number(),
      swTasksInLast90: F.number(),
      urgentVisitsInLast90: F.number(),
      attachmentTypes: F.array(AttachmentType(F)),
      timeZone: F.nullable(F.string()),
    },
    'PatientStats'
  )
)
export interface PatientStats extends AType<typeof PatientStats_> {}
export interface PatientStatsRaw extends EType<typeof PatientStats_> {}
export const PatientStats = AsOpaque<PatientStatsRaw, PatientStats>()(
  PatientStats_
)
