import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { getHasPermission, havePermissionsLoaded } from '../data/permissions'

type OwnProps = {
  displayMessage?: boolean
  requiresAuthorization: boolean
  object: string
  action: string
  children?: React.ReactNode
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AuthorizedBase.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'AuthorizedBase' implicitly has type 'any' because... Remove this comment to see the full error message
const AuthorizedBase = ({
  displayMessage,
  requiresAuthorization,
  object,
  action,
  children,
}: Props) => {
  const permissionsLoaded = useSelector(havePermissionsLoaded)
  const hasPermission = useSelector(state =>
    getHasPermission(state, object, action)
  )

  // Determine if component should render if user is authorized or unauthorized
  const show = requiresAuthorization ? hasPermission : !hasPermission

  return show ? (
    <React.Fragment>{children}</React.Fragment>
  ) : permissionsLoaded && displayMessage ? (
    <Typography variant="h6">
      You do not have permission to view this page. Please contact an
      administrator.
    </Typography>
  ) : null
}

AuthorizedBase.defaultProps = {
  displayMessage: false,
}

export default AuthorizedBase
