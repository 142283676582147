import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { scopedCreator } from '~/utils/data'
import {
  validateRecurTrips,
  setInvalidRecurTrip,
  setValidRecurTrip,
  generateRecurTrip,
} from '../data/recurPopup'

const key = 'ValidRecurTrip'

export const setRecurTripValid = scopedCreator(key)('SET_RECUR_TRIP_VALID')

export const recurTripOverRideValid = (action$: any) =>
  action$.pipe(
    ofType(setRecurTripValid),
    map(() => setValidRecurTrip())
  )

export const recuTripValidated = (action$: any) =>
  action$.pipe(
    ofType(validateRecurTrips.SUCCEEDED),
    pluck('payload'),
    map((payload: any) => {
      if (
        payload?.distanceInRange === 'Yes' &&
        payload?.benefitConfigured &&
        payload?.patientCovered &&
        payload?.activeTrip
      ) {
        return setValidRecurTrip()
      } else return setInvalidRecurTrip()
    })
  )

export const recurTripSaved = (action$: any) =>
  action$.pipe(
    ofType(generateRecurTrip.SUCCEEDED),
    map(() => setInvalidRecurTrip())
  )
