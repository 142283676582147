import { ofType } from 'redux-observable'
import { entered, exited } from 'redux-routable'
import { merge, of } from 'rxjs'
import { filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { PATIENT_RECORD_TASKS_BY_PATIENT } from '~/apps/patientRecord/router'
import { panelClosed, panelOpened } from '~/data/appLayout'
import { fetchTasks } from '~/features/tasks'
import { switchTo } from '~/utils/operators'
import { togglePanelAvailable } from '../data/panelAvailable'
import { getPatientId } from '../data/patientId'

export default (action$, state$) => {
  const mapActionUntilPageExit = (incoming, toggleStatus) =>
    action$.pipe(
      ofType(incoming),
      map(() => togglePanelAvailable(toggleStatus)),
      takeUntil(action$.pipe(filter(exited(PATIENT_RECORD_TASKS_BY_PATIENT))))
    )

  return action$.pipe(
    filter(entered(PATIENT_RECORD_TASKS_BY_PATIENT)),
    switchTo(state$),
    map(getPatientId),
    switchMap(patientId =>
      merge(
        of(fetchTasks.requested({ patientId })),
        mapActionUntilPageExit(panelOpened, true),
        mapActionUntilPageExit(panelClosed, false)
      )
    )
  )
}
