import moment from 'moment'
import { CANCELLED, COMPLETED } from '../constants'

// Formatted ISO for date picker
const DATE_FORMAT = 'YYYY-MM-DD'

const setDueDate = defaultDueInDays => {
  const dueDate = moment().add(defaultDueInDays, 'd')

  switch (dueDate.isoWeekday()) {
    // Saturday
    case 6:
      return dueDate.add(2, 'd').format(DATE_FORMAT)
    // Sunday
    case 7:
      return dueDate.add(1, 'd').format(DATE_FORMAT)
    default:
      return dueDate.format(DATE_FORMAT)
  }
}

// TODO: could compare dates to today?
export const compareDates = (a, b) =>
  -Math.sign(moment(a).diff(moment(b), 'days'))

export const isPastDue = (status, date) =>
  ![CANCELLED, COMPLETED].includes(status) && moment().isAfter(date)

export const tomorrow = () => setDueDate(1)
