import React, { useState } from 'react'
import { TextField } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

const TextLongField = props => {
  const { value, rows, editing, inputProps } = props
  const [newText, setNewText] = useState(value || '')

  const onBlur = () => {
    props.onChange(newText)
  }

  const onChange = text => {
    setNewText(text)
  }

  return (
    <BaseField {...props}>
      <TextField
        rows={rows}
        rowsMax={rows}
        fullWidth
        multiline
        inputProps={inputProps}
        disabled={!editing}
        onBlur={onBlur}
        onChange={onChange}
        value={newText}
      />
    </BaseField>
  )
}

TextLongField.propTypes = {
  value: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
  inputProps: PropTypes.object,
}

TextLongField.defaultProps = {
  rows: 5,
}

export default TextLongField
