import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchVitals } from '~/features/vitals'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_VITALS } from '../router'

// Can limit number of results to return to graph in case it gets too crowded; currently gets all results
const count = null

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_VITALS)),
    switchTo(state$),
    map(getPatientId),
    map(patientId => fetchVitals.requested(patientId, count))
  )
