import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Tooltip } from '@material-ui/core'

const EditRowActions = ({ disabled, onSave, onCancel }) => (
  <React.Fragment>
    <Tooltip title="Save">
      <IconButton disabled={disabled} onClick={onSave}>
        <Icon>save</Icon>
      </IconButton>
    </Tooltip>
    <Tooltip title="Cancel">
      <IconButton onClick={onCancel}>
        <Icon>cancel</Icon>
      </IconButton>
    </Tooltip>
  </React.Fragment>
)

EditRowActions.propTypes = {
  disabled: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default React.memo(EditRowActions)
