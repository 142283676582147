import { Record } from 'immutable'
import moment from 'moment'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import { get, scopedCreator } from '~/utils/data'
import key from '../../key'

export const ReminderTask = Record({
  open: false,
  patientId: null,
  id: null,
  description: '',
  dueDate: '',
})

export const updateSNFSubstatus = Request({
  typePrefix: key,
  typeBase: 'UPDATE_SNF_SUBSTATUS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.put(`v1/patient/${patientId}/snf`),
  messages: {
    succeeded: 'Successfully updated substatus',
    failed: 'There was a problem setting the substatus',
  },
})

export const snoozePatient = Request({
  typePrefix: key,
  typeBase: 'SNOOZE_PATIENT',
  requestParams: ['patientId', 'snooze'],
  operation: (patientId, snooze) =>
    AspireAPI.put(`v1/patient/${patientId}/snooze`, { snooze }),
  messages: {
    succeeded: 'Successfully snoozed patient',
    failed: 'There was a problem snoozing this patient',
  },
})

export const createFetchReminder = typeBase =>
  Request({
    typePrefix: key,
    typeBase,
    requestParams: ['userId', 'patientId'],
    operation: (userId, patientId, cancelToken) =>
      AspireAPI.get('reminder', {
        params: {
          user_id: userId,
          patient_id: patientId,
        },
        cancelToken,
      }),
    transform: data => ReminderTask(data[0]),
    messages: {
      failed: 'There was an issue fetching your patient reminder',
    },
  })

export const createCreateReminder = typeBase =>
  Request({
    typePrefix: key,
    typeBase,
    requestParams: ['userId', 'reminder'],
    operation: (userId, reminder) =>
      AspireAPI.post('reminder', {
        userId,
        patientId: reminder.patientId,
        description: reminder.description,
        dueDate: reminder.dueDate
          ? moment(reminder.dueDate).format('YYYY-MM-DD')
          : null,
      }),
    transform: data => data.id,
    messages: {
      failed: 'There was an issue creating the reminder',
    },
  })

export const createSaveReminder = typeBase =>
  Request({
    typePrefix: key,
    typeBase,
    requestParams: ['userId', 'reminder'],
    operation: (userId, reminder) =>
      AspireAPI.put(`reminder/${reminder.id}`, {
        userId,
        patientId: reminder.patientId,
        description: reminder.description,
        dueDate: reminder.dueDate
          ? moment(reminder.dueDate).format('YYYY-MM-DD')
          : null,
      }),
    messages: {
      failed: 'There was an issue saving the reminder',
    },
  })

export const createDeleteReminder = typeBase =>
  Request({
    typePrefix: key,
    typeBase,
    requestParams: ['id'],
    operation: id => AspireAPI.delete(`reminder/${id}`),
    messages: {
      failed: 'There was an issue deleting the reminder',
    },
  })

const creator = scopedCreator(key)
export const patientCleared = creator('PATIENT_CLEARED', false)

export const getRoot = get(key)
