import { List, Map, Set, fromJS } from 'immutable'
import OrcAPI from '~/resources/orc'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

/* Constants */
const key = 'odsLayer'

export const ODS_VISIBLE = type(rootKey, 'ODS_VISIBLE')
export const RANGE_CHANGED = type(rootKey, 'RANGE_CHANGED')
export const MEMBERS_FETCHED = type(rootKey, 'MEMBERS_FETCHED')

export const setODSIsVisible = creator(ODS_VISIBLE, 'isVisible')
export const setODSRange = creator(RANGE_CHANGED, 'range')
export const loadMembers = creator(MEMBERS_FETCHED, 'mems')

const initialState = new Map({
  range: new List([98.5, 100]),
  isVisible: false,
  members: new Map(),
  selectedHPs: new Set(),
})

export default createReducer(key, initialState, {
  [ODS_VISIBLE]: (state, { payload: { isVisible } }) =>
    state.set('isVisible', isVisible),
  [RANGE_CHANGED]: (state, { payload: { range } }) =>
    state.set('range', fromJS(range)),
  [MEMBERS_FETCHED]: (state, { payload: { mems } }) =>
    state.update('members', m =>
      normalize(mems, {
        idKey: 'GlobalMemberID',
        accumulator: m,
      })
    ),
})

export const getODSLayer = pipe(getRoot, get(key))
export const getODSLayerIsVisible = pipe(getODSLayer, get('isVisible'))
export const getODSMembers = pipe(getODSLayer, get('members'))
export const getODSRange = pipe(getODSLayer, get('range'))

export const fetchODSPatients = (bounds, range) => {
  const [minScore, maxScore] = range.toJS()
  const params = { polygon: bounds }
  if (minScore) {
    params.min_score = minScore
    params.max_score = maxScore
  }

  return OrcAPI.get('polygonator/ods_polygon_members', { params })
}
