import React from 'react'
import PropTypes from '~/utils/propTypes'
import MaterialUITextField from '@material-ui/core/TextField'

class TextField extends React.Component {
  onChange = e => this.props.onChange(e.target.value, e)

  onBlur = e => this.props.onBlur(e.target.value, e)

  render = () => {
    const { value, onChange, onBlur } = this.props

    return (
      <MaterialUITextField
        {...this.props}
        value={value}
        onChange={onChange && this.onChange}
        onBlur={onBlur && this.onBlur}
      />
    )
  }
}

TextField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default TextField
