// Swimlanes
const ADMISSIONS = 'admissions'
export const CALLS_CLINICAL = 'calls_clinical'
export const CALLS_OTHER = 'calls_other'
export const COMMUNICATIONS = 'communications'
const ENCOUNTERS = 'encounters'
const ON_CALL_EVENTS = 'on_call'
const ORDERS = 'orders'
const NOMINATIONS = 'nominations'
const NOTES = 'notes'
const SERVICE_INFORMATION = 'service_information'
const TASKS = 'tasks'

// Categories
export const AMAZE_TIMELINE = 'amaze_timeline'
export const CALL = 'call'
export const COMMUNICATION = 'communication'
export const DISCHARGE = 'discharge'
export const DISCHARGE_RECOMMENDATION = 'discharge_recommendation'
export const PROGRAM_ENROLLED = 'program_enrolled'
export const ENCOUNTER = 'encounter'
export const ER = 'er'
export const HOSPICE = 'hospice'
export const HOSPITALIZATION = 'hospitalization'
export const ICT_TIMELINE = 'ict_timeline'
export const HIGH_RISK_HUDDLE_TIMELINE = 'high_risk_huddle_timeline'
export const LTC = 'ltc'
export const NOMINATION = 'nomination'
export const MANUAL_TIMELINE = 'manual_timeline'
export const ON_CALL = 'on_call'
export const ORDER = 'order'
export const RESEARCH = 'research'
export const SNF = 'snf'
export const TASK = 'task'
export const CANCELLED = 'cancelled'

// Encounter categories
export const APP_ENCOUNTERS = 'app_encounters'
export const CASE_MANAGER_ENCOUNTERS = 'case_manager_encounters'
export const CHW_ENCOUNTERS = 'chw_encounters'
export const OTHER_ENCOUNTERS = 'other_encounters'
export const PHYSICIAN_ENCOUNTERS = 'physician_encounters'
export const RN_ENCOUNTERS = 'rn_encounters'
export const SW_ENCOUNTERS = 'sw_encounters'

// Swimlane labels and timeline filtering
export const SWIMLANE_LABELS = {
  [ADMISSIONS]: 'Admissions',
  [CALLS_CLINICAL]: 'Calls (Clinical)',
  [CALLS_OTHER]: 'Calls (Other)',
  [COMMUNICATIONS]: 'Communications',
  [ENCOUNTERS]: 'Encounters',
  [ON_CALL_EVENTS]: 'On Call',
  [ORDERS]: 'Orders',
  [NOMINATIONS]: 'High Risk Huddle',
  [NOTES]: 'Notes',
  [SERVICE_INFORMATION]: 'Service Information',
  [TASKS]: 'Tasks',
}

// Swimlane labels from above and the event "category" types they contain
export const CATEGORIES_IN_SWIMLANE = {
  [ADMISSIONS]: [HOSPITALIZATION, LTC, SNF, ER],
  [CALLS_CLINICAL]: [CALLS_CLINICAL], // separated in the component
  [CALLS_OTHER]: [CALLS_OTHER],
  [ENCOUNTERS]: [ENCOUNTER],
  [COMMUNICATIONS]: [COMMUNICATION],
  [ON_CALL_EVENTS]: [ON_CALL],
  [ORDERS]: [HOSPICE, ORDER],
  [NOMINATIONS]: [NOMINATION],
  [NOTES]: [
    MANUAL_TIMELINE,
    AMAZE_TIMELINE,
    ICT_TIMELINE,
    HIGH_RISK_HUDDLE_TIMELINE,
  ],
  [SERVICE_INFORMATION]: [
    DISCHARGE,
    DISCHARGE_RECOMMENDATION,
    PROGRAM_ENROLLED,
    RESEARCH,
  ],
  [TASKS]: [TASK],
}

// Encounter category types
export const ENCOUNTER_TYPE_LABELS = {
  [APP_ENCOUNTERS]: 'APP',
  [CASE_MANAGER_ENCOUNTERS]: 'Case Manager',
  [CHW_ENCOUNTERS]: 'Community Health Worker',
  [OTHER_ENCOUNTERS]: 'Other Encounters',
  [PHYSICIAN_ENCOUNTERS]: 'Physician',
  [RN_ENCOUNTERS]: 'RN',
  [SW_ENCOUNTERS]: 'Social Worker',
}

export const ROLES_IN_ENCOUNTER_TYPES = {
  [APP_ENCOUNTERS]: ['APP', 'Field-APP', 'Field-Team Lead'],
  [CASE_MANAGER_ENCOUNTERS]: ['CareMore-Case-Manager'],
  [CHW_ENCOUNTERS]: ['CHW'],
  [PHYSICIAN_ENCOUNTERS]: ['Field-MD'],
  [RN_ENCOUNTERS]: ['PCC-RN', 'Field-RN'],
  [SW_ENCOUNTERS]: ['Field-SW', 'PCC-SW'],
}

// Visibility Filter option
export const SINCE_LAST_VISIT = 'since_last_visit'

export const categoryToIcon = category => {
  // case fallthrough works like boolean `||`
  switch (category) {
    case ENCOUNTER:
      return 'assignment_ind'
    case ER:
    case HOSPITALIZATION:
    case LTC:
    case SNF:
      return 'local_hospital'
    case ON_CALL:
    case CALL:
    case COMMUNICATION:
      return 'call_end'
    case HOSPICE:
    case ORDER:
      return 'shopping_cart'
    case NOMINATION:
      return 'assignment_late'
    case AMAZE_TIMELINE:
    case MANUAL_TIMELINE:
    case ICT_TIMELINE:
    case HIGH_RISK_HUDDLE_TIMELINE:
      return 'description'
    case DISCHARGE:
    case DISCHARGE_RECOMMENDATION:
    case PROGRAM_ENROLLED:
    case RESEARCH:
      return 'person_add_disabled'
    case TASK:
      return 'playlist_add_check'
    default:
      return 'info'
  }
}
