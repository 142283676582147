import React from 'react'
import { useSelector } from 'react-redux'
import { LogCall, isCallLogActive } from '~/features/callLogger'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { getCurrentPatientId } from '../../data/currentPatientId'
import GridItem from './GridItem'
import AddCallLogButton from './actions/AddCallLogButton'

const CallLoggerGridItem = ({ callIntentsFilter, defaultCallIntent }) => {
  const patientId = useSelector(getCurrentPatientId)
  const callLogActive = useSelector(isCallLogActive)

  return (
    <GridItem title="Log Call" actions={[AddCallLogButton]}>
      {callLogActive ? (
        <LogCall
          intentsFilter={callIntentsFilter}
          defaultIntent={defaultCallIntent}
        />
      ) : patientId ? (
        <Typography variant="subtitle1">
          {`Click on the "Log a Call" button above to get started`}
        </Typography>
      ) : (
        <Typography variant="subtitle1">
          Select a patient before logging a call
        </Typography>
      )}
    </GridItem>
  )
}

CallLoggerGridItem.propTypes = {
  callIntentsFilter: PropTypes.array,
  defaultCallIntent: PropTypes.string,
}

export default CallLoggerGridItem
