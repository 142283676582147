import { includes } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import FieldValueSelector from '~/components/FieldValueSelector'
import { ActionDialog } from '~/components/dialogs'
import CareTeamSelectionGrid from '~/features/careTeamManagement/containers/CareTeamSelectionGrid'
import PropTypes from '~/utils/propTypes'
import {
  AppBar,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  fetchAvailableCareTeams,
  getAssignmentCareTeamsWithUsers,
} from '../data/assignment'
import { changePatientProgramAndCareTeam, getTypes } from '../data/careTeams'
import { getCurrentPatientId } from '../data/currentPatientId'

const useStyles = makeStyles(({ spacing }) => ({
  manualSelectTitle: {
    padding: [spacing(2), spacing(3), 0],
  },
}))

const PatientCareTeamAssignment = ({ programEnrolled }) => {
  const classes = useStyles()
  const [careTeamId, setCareTeamId] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [newProgram, setNewProgram] = useState(programEnrolled)
  const [submitting, setSubmitting] = useState(false)
  const [selectedTab, setSelectedTab] = useState('auto')
  const dispatch = useDispatch()
  const patientId = useSelector(getCurrentPatientId)
  const availableCareTeams = useSelector(getAssignmentCareTeamsWithUsers)
  const types = useSelector(getTypes)

  const careTeamType = useMemo(() => {
    const type = Object.values(types.toJS()).filter(type =>
      includes(type.programs, newProgram || programEnrolled)
    )[0]

    return type?.key
  }, [types, newProgram])

  useEffect(() => {
    if (careTeamType) {
      dispatch(fetchAvailableCareTeams.requested(careTeamType, patientId))
    }
  }, [patientId, careTeamType])

  const onClose = () => {
    setCareTeamId(undefined)
    setOpen(false)
    setSubmitting(false)
  }

  const requestChangeCareTeam = () => {
    setSubmitting(true)
    dispatch(
      changePatientProgramAndCareTeam.requested(
        patientId,
        careTeamId,
        newProgram || programEnrolled
      )
    )
    onClose()
  }

  const onProgramChange = e => {
    setNewProgram(e.target.value)
    setCareTeamId(undefined)
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <DevXTitleHeader
          action={() => setOpen(true)}
          buttonText="Change Program / Care Team"
          title="Program"
        />
        <ActionDialog
          open={open}
          title="Change Patient Care Team"
          mode="confirm"
          onConfirm={requestChangeCareTeam}
          onClose={onClose}
          disableAction={!careTeamId || submitting}
          maxWidth="md"
          fullWidth
        >
          <Paper>
            {programEnrolled && (
              <FieldValueSelector
                field={'aspire_program_enrolled'}
                value={newProgram || programEnrolled}
                onChange={onProgramChange}
              />
            )}
            <AppBar position="static">
              <Tabs
                value={selectedTab}
                onChange={(e, v) => setSelectedTab(v)}
                aria-label="simple tabs example"
              >
                <Tab label="Auto" value="auto" />
                <Tab label="Manual" value="manual" />
              </Tabs>
            </AppBar>
            {selectedTab == 'auto' && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Label</TableCell>
                    <TableCell align="right">States</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {availableCareTeams.map(row => (
                    <TableRow
                      hover
                      key={row.label}
                      selected={row.id == careTeamId}
                      onClick={() => setCareTeamId(row.id)}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">
                        {row.validStateCodes && row.validStateCodes.join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {selectedTab == 'manual' && (
              <Paper>
                <Typography className={classes.manualSelectTitle} variant="h6">
                  Manual Care Team Selection
                </Typography>
                <CareTeamSelectionGrid
                  onCareTeamSelected={({ id }) => setCareTeamId(id)}
                  typeFilter={careTeamType}
                />
              </Paper>
            )}
          </Paper>
        </ActionDialog>
      </React.Fragment>
    </React.Fragment>
  )
}

PatientCareTeamAssignment.propTypes = {
  programEnrolled: PropTypes.string,
}

export default PatientCareTeamAssignment
