import React from 'react'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  section: {
    display: 'flex',
    flex: '1',
    backgroundColor: ({ color }) => color,
  },
})

const DayWrapperColored = ({ children, ...props }) => {
  const classes = useStyles(props)

  return <div className={classes.section}>{children}</div>
}

DayWrapperColored.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
}

export default DayWrapperColored
