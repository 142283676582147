import { Record, Set } from 'immutable'
import moment from 'moment'
import { Location } from '~/features/locationSearch'
import { get, scopedCreator } from '~/utils/data'
import { utcToLocal } from '~/utils/dates'
import { pipe } from '~/utils/functionalHelpers'
import { recordTypeToEventType } from '../../components/EventTypes/consts'
import rootKey from '../../key'
import { getRoot } from '../common/shared'

export const EVENTS = 'events'

export const Recurrence = Record({
  startDate: null,
  endDate: null,
  days: Set(),
  type: 'weekly',
  period: 1,
})

export const Event = Record({
  allDay: null,
  assessmentType: null,
  batchId: null,
  cancelled: null,
  confirmed: null,
  createdAt: null,
  createdBy: null,
  createField: null,
  description: null,
  duration: null,
  encounterStatus: null,
  encounterType: null,
  encounterTypeLabel: null,
  encounterTypeModality: null,
  end: null,
  endDate: null,
  eventType: null,
  guests: null,
  guidanceType: null,
  hasCase: null,
  id: null,
  location: Location(),
  locationPending: false,
  maxVisits: null,
  modifiedAt: null,
  modifiedBy: null,
  ownerId: null,
  ownerName: null,
  patientContactId: null,
  patientContactInfo: null,
  patientCurrentAddress: null,
  patientPhone: null,
  patientEmail: null,
  patientId: null,
  patientName: null,
  recordType: null,
  recurrence: null,
  relatedToMarket: null,
  rideAlongRole: null,
  start: null,
  startDate: null,
  serviceLocationId: null,
  subject: null,
  textingApproved: null,
  travelNotes: null,
  type: null,
  urgent: null,
  userIds: null,
  visitMethod: null,
  visitUnits: null,
  whatId: null,
})

const transformRecurrence = batch =>
  batch && batch.recurrence
    ? Recurrence({ ...batch.recurrence, days: Set(batch.recurrence.days) })
    : null

const transformUserIds = batch =>
  batch && batch.users ? Set(batch.users.map(({ userId }) => userId)) : null

const transformDuration = (start, end) => {
  const diff = moment(end).diff(moment(start))

  return moment.duration(diff).asMinutes()
}

const REMOTE_ENCOUNTER_TYPES = [
  'home_based_social_work_follow_up_virtual',
  'home_based_social_work_initial_virtual',
  'hybrid_social_work_virtual',
  'palliative_follow_up_virtual',
  'palliative_initial_virtual',
  'palliative_follow_up_telephonic',
  'palliative_follow_up_toc_telephonic',
  'palliative_initial_telephonic',
  'palliative_intake_form',
  'palliative_urgent_telephonic',
  'palliative_urgent_virtual',
  'telehealth_psychosocial_initial',
  'telehealth_psychosocial_follow_up',
  'telehealth_psychosocial_virtual_initial',
  'telehealth_psychosocial_virtual_follow_up',
  'top_five_follow_up_telephonic',
  'top_five_follow_up_toc_telephonic',
  'top_five_follow_up_toc_virtual',
  'top_five_follow_up_virtual',
  'top_five_healthy_start_telephonic',
  'top_five_healthy_start_toc_telephonic',
  'top_five_healthy_start_toc_virtual',
  'top_five_healthy_start_virtual',
  'top_five_urgent_telephonic',
  'top_five_urgent_virtual',
]
export const encounterTypeToAssessmentType = type =>
  REMOTE_ENCOUNTER_TYPES.includes(type) ? 'remote' : 'in_person'

export const transformEvent = (
  {
    batch,
    location,
    schedulingGuidance,
    start: eventStart,
    end: eventEnd,
    duration,
    createdAt,
    modifiedAt,
    ...event
  },
  date,
  timeZone
) => {
  let start = eventStart
  let end = eventEnd

  // Instantiate dates from default event
  if (start instanceof Function) start = start({ date, timeZone })
  if (end instanceof Function) end = end({ date, timeZone })

  const newType =
    event.recordType === 'other' ? 'unavailability' : event.recordType

  return Event({
    type: recordTypeToEventType[newType],
    location: Location(location),
    allDay: start === null && end == null,
    start: start && moment.utc(start),
    end: end && moment.utc(end),
    startDate: start && utcToLocal(start, timeZone).format('YYYY-MM-DD'),
    endDate: end && utcToLocal(end, timeZone).format('YYYY-MM-DD'),
    duration: duration || transformDuration(start, end),
    assessmentType: encounterTypeToAssessmentType(event.encounterType),
    createdAt: createdAt && moment.utc(createdAt),
    modifiedAt: modifiedAt && moment.utc(modifiedAt),
    recurrence: transformRecurrence(batch),
    userIds: transformUserIds(batch),
    ...event,
  })
}

const creator = scopedCreator(rootKey)
export const currentEventSet = creator('CURRENT_EVENT_SET', ['event'])
export const currentEventCleared = creator('CURRENT_EVENT_CLEARED')
export const newEventCreated = creator('NEW_EVENT_CREATED', [
  'event',
  'date',
  'timeZone',
])
export const patientCleared = creator('PATIENT_CLEARED')
export const allDayChanged = creator('ALL_DAY_CHANGED', [
  'allDay',
  'defaultStart',
  'defaultEnd',
])
export const assessmentTypeChanged = creator('ASSESSMENT_TYPE_CHANGED', [
  'value',
])
export const recurrenceChanged = creator('RECURRENCE_CHANGED', ['value'])
export const userIdsChanged = creator('USER_IDS_CHANGED', ['value'])
export const confirmedChanged = creator('CONFIRM_CHANGED', ['value'])
export const descriptionChanged = creator('DESCRIPTION_CHANGED', ['value'])
export const encounterTypeChanged = creator('ENCOUNTER_TYPE_CHANGED', ['type'])
export const endChanged = creator('END_CHANGED', ['end', 'endDate'])
export const endDateChanged = creator('END_DATE_CHANGED', ['value'])
export const eventTypeChanged = creator('EVENT_TYPE_CHANGED', ['value'])
export const guestsChanged = creator('GUESTS_CHANGED', ['value'])
export const guidanceTypeChanged = creator('GUIDANCE_TYPE_CHANGED', ['value'])
export const locationChanged = creator('LOCATION_CHANGED', ['value'])
export const serviceLocationChanged = creator('SERVICE_LOCATION_CHANGED', [
  'value',
])
export const locationPendingChanged = creator('LOCATION_PENDING_CHANGED', [
  'value',
])
export const maxVisitsChanged = creator('MAX_VISITS_CHANGED', ['value'])
export const patientIdChanged = creator('PATIENT_ID_CHANGED', ['value'])
export const rideAlongTypeChanged = creator('RIDE_ALONG_TYPE_CHANGED', [
  'value',
])
export const startChanged = creator('START_CHANGED', ['start', 'startDate'])
export const startDateChanged = creator('START_DATE_CHANGED', ['value'])
export const subjectChanged = creator('SUBJECT_CHANGED', ['value'])
export const travelNotesChanged = creator('TRAVEL_NOTES_CHANGED', ['value'])
export const urgentChanged = creator('URGENT_CHANGED', ['value'])
export const visitDurationChanged = creator('VISIT_DURATION_CHANGED', [
  'duration',
  'end',
  'endDate',
])
export const visitMethodChanged = creator('VISIT_METHOD_CHANGED', ['value'])
export const visitStartChanged = creator('VISIT_START_CHANGED', [
  'start',
  'startDate',
  'end',
  'endDate',
])

export const setVisitEnd = (event, newStart, newDuration) => {
  const start = newStart || event.start
  const duration = newDuration || event.duration

  return moment(start).add(duration, 'minutes')
}

export const getEventsRoot = pipe(getRoot, get(EVENTS))
