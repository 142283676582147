import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const MARKETS = 'markets'

export const Market = Record({
  id: null,
  marketName: null,
  leadPhysician: null,
  marketDirector: null,
  phone: null,
  fax: null,
  hotlinePhone: null,
})

export const fetchMarkets = Request({
  typePrefix: key,
  typeBase: 'FETCH_MARKETS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/markets`, {
      params: { searchWord },
    }),
  transform: result => result.map(Market),
  messages: { failed: 'There was a problem fetching markets' },
})

const creator = scopedCreator(key)
export const marketsCleared = creator('MARKETS_CLEARED')

export default createReducer(MARKETS, [], {
  [fetchMarkets.SUCCEEDED]: (_state, { payload }) => payload,
  [marketsCleared]: () => [],
})

export const getMarkets = pipe(getRoot, get(MARKETS))
