import React from 'react'
import BaseArrayField from 'react-jsonschema-form/lib/components/fields/ArrayField'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../widgets/memoizedWrapper'

const ArrayField = ({ uiSchema, ...props }) => {
  let newUISchema = uiSchema
  const hasWidget = 'ui:widget' in uiSchema
  const isItemsEnum = Array.isArray(props.schema.items.enum)
  const isUnique = props.schema.uniqueItems

  if (!hasWidget && isItemsEnum && isUnique) {
    newUISchema = { ...uiSchema, 'ui:widget': 'checkboxes' }
  }

  return <BaseArrayField {...props} uiSchema={newUISchema} />
}

ArrayField.propTypes = {
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
}

ArrayField.defaultProps = {
  schema: {},
  uiSchema: {},
}

export default memoizedWrapper(ArrayField)
