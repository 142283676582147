import { addDays, format } from 'date-fns'
import { mapValues, some } from 'lodash'
import { get } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FieldValue } from '~/types/fieldValueT'
import {
  futureDate35DayMax,
  // @ts-expect-error no export
} from '~/components/JsonForm/customFormats'
import { ActionDialog } from '~/components/dialogs'
import {
  SelectField,
  TextField,
  renderFieldValueOptions,
  // @ts-expect-error no export
} from '~/components/fields'
import Line from '~/components/text/Line'
import { getFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/session' or ... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/users' or ... Remove this comment to see the full error message
import { getUserById } from '~/data/users'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/careTeamManagement' or ... Remove this comment to see the full error message
import { getPatientCareTeamById, getRoles } from '~/features/careTeamManagement'
import {
  RiskLevel,
  getPatientInfo,
  getPatientLastEncounter,
  getProgramEnrolled,
  // @ts-expect-error no export
} from '~/features/patientInfo'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/format' or ... Remove this comment to see the full error message
import { formatDate, formatName } from '~/utils/format'
import {
  CAREMORE_AT_HOME,
  caremoreAtHome,
  TELEPHONIC,
} from '~/utils/programInfo'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/validation/formValidators' or ... Remove this comment to see the full error message
import { futureDate, required } from '~/utils/validation/formValidators'
import { Typography, makeStyles } from '@material-ui/core'
import {
  getPatientNTVD,
  updateNextTargetedVisitDate,
} from '../data/nextTargetedVisitDate'
import {
  closeNextTargetedVisitDateDialog,
  isNextTargetedVisitDateDialogOpen,
} from '../data/nextTargetedVisitDateDialog'
import { programEnrolledToNTVDRole } from '../utils'

function FlexLine(props: any) {
  return <Line {...props} contentFlex={1} />
}

const useStyles = makeStyles(({ spacing }) => ({
  spacer: {
    display: 'block',
    margin: spacing(1, 0),
  },
}))

const INITIAL_FORM_STATE = {
  careTeamRoleKey: null,
  date: null,
  updateReason: null,
  updateReasonOther: null,
}

const validateNTVD = (
  userAspireRole: string,
  careTeamRoles: string[],
  ntvdType: string,
  patientProgram: string
) => (date: string) => {
  if (!limitedNTVDType(ntvdType)) {
    return futureDate(date)
  }

  if (!canSetAPPNTVD(careTeamRoles, userAspireRole)) {
    return 'Must be in an allowed care team role'
  }

  if (
    pastMaxNTVD(date, patientProgram) &&
    !canOverrideNTVDMax(careTeamRoles, patientProgram)
  ) {
    switch (patientProgram) {
      case CAREMORE_AT_HOME:
        return `Must be a future date`
      default:
        return 'Must be a future date within 35 days'
    }
  }
}

const appNTVDAllowedRoles = [
  'app',
  'lead_app',
  'cd',
  'md',
  'pcc_sw',
  'field_sw',
  'nss',
  'hospice_nss',
  'bh_sw',
  'bh_apc',
]

const getNextNTVD = () => format(addDays(new Date(), 35), 'yyyy-MM-dd')

const limitedNTVDType = (type: string) => type == 'app'

const pastMaxNTVD = (date: any, patientProgram: string) => {
  switch (patientProgram) {
    case CAREMORE_AT_HOME:
      return futureDate(date)
    default:
      return !futureDate35DayMax(date)
  }
}
const canSetAPPNTVD = (userRoles: string[], userAspireRole: string): boolean =>
  userAspireRole === 'PCC-RN' ||
  userRoles.some((r: string) => appNTVDAllowedRoles.includes(r))

const canOverrideNTVDMax = (userRoles: string[], patientProgram: string) => {
  if (caremoreAtHome(patientProgram)) {
    return userRoles.some((r: string) =>
      ['cd', 'md', 'app', 'lead_app', 'bh_sw', 'bh_apc'].includes(r)
    )
  }
  return userRoles.some((r: string) => r == 'cd')
}

export default function NextTargetedVisitDate() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const patientProgram: any = useSelector(getProgramEnrolled)
  const userId = useSelector(getUserId)
  const currentUser = useSelector(state => getUserById(state, userId))

  const userCareTeamRoles = currentUser.careTeamRoles || []

  const userAspireRole = currentUser.aspireRole || []

  const [formValues, setFormValues]: any = useState(INITIAL_FORM_STATE)
  const updateFormValue = (field: any) => (value: any) =>
    setFormValues((prevState: any) => ({ ...prevState, [field]: value }))

  const VALIDATION: any = useMemo(
    () => ({
      careTeamRoleKey: [required],
      date: [
        required,
        validateNTVD(
          userAspireRole,
          userCareTeamRoles,
          formValues.careTeamRoleKey,
          patientProgram
        ),
      ],
      updateReason: [required],
      updateReasonOther: [
        (value: any, fieldValues: any) =>
          fieldValues.updateReason == 'other' ? required(value) : undefined,
      ],
    }),
    [userCareTeamRoles, formValues.careTeamRoleKey]
  )

  // NOTE(adam): separate warning to not invalidate form when appropriate
  const pastDaysWarning = pastMaxNTVD(formValues.date, patientProgram)
    ? '⚠️ NTVD is outside recommended number of days'
    : undefined

  const open: boolean = useSelector(isNextTargetedVisitDateDialogOpen)
  const patient: any = useSelector(getPatientInfo)
  const reasonsForChange = useSelector(getFieldValues('ntvd_change'))
  const nextTargetedVisitModalities = useSelector(getFieldValues('modality'))
  const careTeamRoles: any = useSelector(getRoles)
  const patientCareTeam = useSelector(getPatientCareTeamById(patient.id))
  const patientNTVD: any = useSelector(getPatientNTVD(patient.id))
  const lastEncounter: any = useSelector(getPatientLastEncounter)

  const closeDialog = () => {
    setFormValues(INITIAL_FORM_STATE)
    dispatch(closeNextTargetedVisitDateDialog())
  }

  const errors: any = useMemo(
    () =>
      mapValues(VALIDATION, (value, key) =>
        value
          .map((fn: any) => fn(formValues[key], formValues))
          .filter(Boolean)
          .join(', ')
      ),
    [formValues]
  )

  const invalid = useMemo(() => some(errors, Boolean), [errors])

  const save = () => {
    dispatch(updateNextTargetedVisitDate.requested(patient.id, formValues))
    closeDialog()
  }

  const getDate = () =>
    patientProgram !== CAREMORE_AT_HOME
      ? getNextNTVD()
      : patientNTVD.getIn([formValues.careTeamRoleKey, 'date'])

  useEffect(() => {
    const primaryRole = get('primaryRole')(patientCareTeam)
    setFormValues({
      ...INITIAL_FORM_STATE,
      careTeamRoleKey: primaryRole,
      date: getDate(),
      modality: patientNTVD.getIn([primaryRole, 'modality']),
    })
  }, [patient.id, open])

  useEffect(() => {
    setFormValues((prevState: any) => ({
      ...prevState,
      date: getDate(),
      modality: patientNTVD.getIn([formValues.careTeamRoleKey, 'modality']),
    }))
  }, [formValues.careTeamRoleKey])

  const getModalities = () =>
    patient.aspire.programEnrolled === TELEPHONIC
      ? nextTargetedVisitModalities.filter(
          (modality: FieldValue) => modality.value !== 'in_person'
        )
      : nextTargetedVisitModalities

  return (
    open && (
      <ActionDialog
        mode="save"
        open={open}
        title="Update Next Targeted Visit"
        onSave={save}
        onClose={closeDialog}
        maxWidth="sm"
        fullWidth
        disableAction={invalid}
      >
        <Typography color="primary" variant="h6">
          Patient Info
        </Typography>
        <FlexLine label="Name">{formatName(patient.demographics)}</FlexLine>
        <FlexLine label="Program Enrolled">
          {patient.aspire.programEnrolled}
        </FlexLine>
        <FlexLine label="Risk Level">
          <RiskLevel score={patient.aspire.riskLevel} />
        </FlexLine>
        {lastEncounter.encounterId && (
          <span className={classes.spacer}>
            <Typography color="primary" variant="h6">
              Last Visit Details
            </Typography>
            <FlexLine label="Type">{lastEncounter.label}</FlexLine>
            <FlexLine label="Provider">{lastEncounter.providerName}</FlexLine>
            <FlexLine label="Date">
              {formatDate(lastEncounter.dateOfService)}
            </FlexLine>
          </span>
        )}
        <span className={classes.spacer}>
          <Typography color="primary" variant="h6">
            Current NTVD Details
          </Typography>
          {programEnrolledToNTVDRole(patient.aspire.programEnrolled).map(
            role => (
              <FlexLine
                key={role}
                label={
                  careTeamRoles.find((r: any) => r.key == role).label +
                  (role == get('primaryRole')(patientCareTeam)
                    ? ' - Primary Role'
                    : '')
                }
              >
                {formatDate(patientNTVD.getIn([role, 'date'])) || 'Not Set'}
              </FlexLine>
            )
          )}
        </span>
        <SelectField
          fullWidth
          error={!!errors.careTeamRoleKey}
          helperText={errors.careTeamRoleKey}
          label="Care Team Role"
          onChange={updateFormValue('careTeamRoleKey')}
          value={formValues.careTeamRoleKey}
          InputLabelProps={{ shrink: true }}
          required
          native
        >
          <option />
          {programEnrolledToNTVDRole(patient.aspire.programEnrolled).map(
            role => {
              const { label } = careTeamRoles.find(
                ({ key }: any) => key == role
              )
              return (
                <option key={role} value={role}>
                  {label +
                    (role == get('primaryRole')(patientCareTeam)
                      ? ' - Primary Role'
                      : '')}
                </option>
              )
            }
          )}
        </SelectField>
        <TextField
          fullWidth
          error={!!errors.date}
          helperText={errors.date || pastDaysWarning}
          label="Next Targeted Visit Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formValues.date}
          onChange={updateFormValue('date')}
        />
        <SelectField
          fullWidth
          error={!!errors.modality}
          helperText={errors.modality}
          label="Next Targeted Visit Modality"
          onChange={updateFormValue('modality')}
          value={formValues.modality}
          InputLabelProps={{ shrink: true }}
          required
          native
        >
          <option />
          {renderFieldValueOptions(getModalities())}
        </SelectField>
        <SelectField
          fullWidth
          error={!!errors.updateReason}
          helperText={errors.updateReason}
          label="Update Reason"
          onChange={updateFormValue('updateReason')}
          value={formValues.updateReason}
          InputLabelProps={{ shrink: true }}
          required
          native
        >
          <option />
          {renderFieldValueOptions(reasonsForChange, ['risk_strat'])}
        </SelectField>
        {formValues.updateReason == 'other' && (
          <TextField
            fullWidth
            error={!!errors.updateReasonOther}
            helperText={errors.updateReasonOther}
            multiline
            label="Update Reason Other"
            required
            onChange={updateFormValue('updateReasonOther')}
            value={formValues.updateReasonOther}
            InputLabelProps={{ shrink: true }}
          />
        )}
      </ActionDialog>
    )
  )
}
