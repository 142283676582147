import { combineReducers } from 'redux-immutable'
import authorizations from './data/authorizations'
import key from './key'

const reducer = combineReducers({
  [authorizations.key]: authorizations,
})

reducer.key = key

export default reducer
