import { combineReducers } from 'redux-immutable'
import callDispositions from './data/callDispositions'
import callIntents from './data/callIntents'
import callLog from './data/callLog'
import contacts from './data/contacts'
import orders from './data/orders'
import patientInfo from './data/patientInfo'
import warnings from './data/warnings'
import key from './key'

const reducer = combineReducers({
  [callDispositions.key]: callDispositions,
  [callIntents.key]: callIntents,
  [callLog.key]: callLog,
  [contacts.key]: contacts,
  [orders.key]: orders,
  [patientInfo.key]: patientInfo,
  [warnings.key]: warnings,
})

reducer.key = key

export default reducer
