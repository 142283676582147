import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { from, of } from 'rxjs'
import { filter, first, map, mergeMap, mergeMapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { marketsRequested } from '~/data/markets'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { userRolesRequested } from '~/data/userRoles'
import { PES_DASHBOARD_PATIENT_FILTERS, getItem } from '~/utils/storage'
import { filtersLoadedFromStorage } from '../data/patientFilters'
import router from '../router'

const appEnteredEpic = (action$: any) =>
  action$.pipe(
    filter(entered(router)),
    first(),
    mergeMapTo(
      of(
        userRolesRequested(),
        marketsRequested(),
        fetchDistinctFieldValues([
          'texting_approved',
          'video_apt_interest',
          'virtual_capable',
        ])
      )
    )
  )

const savedPatientFiltersEpic = (action$: any) =>
  action$.pipe(
    filter(entered(router)),
    first(),
    mergeMap(() =>
      from(getItem(PES_DASHBOARD_PATIENT_FILTERS)).pipe(
        filter(Boolean),
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ selected, values }: { selecte... Remove this comment to see the full error message
        map(({ selected, values }) =>
          filtersLoadedFromStorage(selected, values)
        )
      )
    )
  )

export default combineEpics(appEnteredEpic, savedPatientFiltersEpic)
