import { Map } from 'immutable'
import createReducer from '~/utils/createReducer'
import { scopedCreator } from '~/utils/data'
import rootKey from '../key'

// Key
export const key = 'visibilityFilter'

// Actions
const creator = scopedCreator(rootKey)

export const visibilityFilterChanged = creator('VISIBILITY_FILTER_CHANGED', [
  'category',
])

export const visibilityFilterReset = creator('VISIBILITY_FILTER_RESET', false)

// Reducer
const setOrDeleteKey = (state, { payload: { category } }) =>
  state.has(category) ? state.delete(category) : state.set(category, true)

export default createReducer(key, Map(), {
  [visibilityFilterChanged]: setOrDeleteKey,
  [visibilityFilterReset]: () => Map(),
})
