// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import currentEncounterReviewId from './data/currentEncounterReviewId'
import currentUserId from './data/currentUserId'
import encounterReviews from './data/encounterReviews'
import key from './key'

const reducer = combineReducers({
  [currentEncounterReviewId.key]: currentEncounterReviewId,
  [currentUserId.key]: currentUserId,
  [encounterReviews.key]: encounterReviews,
})

reducer.key = key

export default reducer
