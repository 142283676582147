import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

// KEY
const key = 'connectionLostSnackbarOpened'

// CONSTANTS
export const CONNECTION_SUCCESSFUL = type(key, 'CONNECTION_SUCCESSFUL')
export const CONNECTION_LOST = type(key, 'CONNECTION_LOST')

// ACTION CREATORS

export const connectionSuccessful = creator(CONNECTION_SUCCESSFUL)
export const connectionLost = creator(CONNECTION_LOST)

// REDUCER
export default createReducer(key, false, {
  [CONNECTION_LOST]: () => true,
  [CONNECTION_SUCCESSFUL]: () => false,
})

// SELECTORS
export const getConnectionLostSnackbarOpened = pipe(getRoot, get(key))
