import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, map, mergeMap, pluck, takeUntil } from 'rxjs/operators'
import { getShouldLoadInactiveUsers } from '~/apps/userSettings/data/userListSettings'
import { appInitialized } from '~/data/root'
import { fetchUsers, getUsers } from '~/data/users'
import { switchTo } from '~/utils/operators'
import {
  fetchCareTeamRoles,
  fetchCareTeamTypes,
  fetchPatientCareTeam,
  getTypes,
} from '../data/careTeams'
import { patientIdSelected } from '../data/currentPatientId'

const loadPatientCareTeam = action$ =>
  action$.pipe(
    ofType(patientIdSelected),
    pluck('payload', 'patientId'),
    mergeMap(patientId => of(fetchPatientCareTeam.requested(patientId)))
  )

const getCareTeamRoles = action$ =>
  action$.pipe(
    ofType(appInitialized),
    map(fetchCareTeamRoles.requested),
    takeUntil(action$.pipe(ofType(fetchCareTeamRoles.SUCCEEDED)))
  )

const getCareTeamTypes = (action$, state$) =>
  action$.pipe(
    ofType(patientIdSelected),
    switchTo(state$),
    map(getTypes),
    filter(types => types.isEmpty()),
    map(fetchCareTeamTypes.requested),
    takeUntil(action$.pipe(ofType(fetchCareTeamTypes.SUCCEEDED)))
  )

const loadUsers = (action$, state$) =>
  action$.pipe(
    ofType(patientIdSelected),
    switchTo(state$),
    map(getUsers),
    filter(users => users.isEmpty()),
    switchTo(state$),
    map(getShouldLoadInactiveUsers),
    map(fetchUsers.requested),
    takeUntil(action$.pipe(ofType(fetchUsers.SUCCEEDED)))
  )

export default combineEpics(
  loadPatientCareTeam,
  getCareTeamRoles,
  getCareTeamTypes,
  loadUsers
)
