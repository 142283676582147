import React from 'react'
import PropTypes from '~/utils/propTypes'
import FormFieldWrapper from './FormFieldWrapper'
import IndicationField from './IndicationField'

class IndicationFields extends React.PureComponent {
  render() {
    const { hasStatus } = this.props
    return (
      <FormFieldWrapper
        isCollapsed={!hasStatus}
        title="Patient Indications - Primary Indication Required"
      >
        <IndicationField name="primaryIndication" isRequired />
        <IndicationField name="secondaryIndication" />
      </FormFieldWrapper>
    )
  }
}

IndicationFields.propTypes = {
  hasStatus: PropTypes.bool.isRequired,
}

export default IndicationFields
