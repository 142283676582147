import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientInfo' or its... Remove this comment to see the full error message
import { fetchPatient } from '~/features/patientInfo'
import { saveReinstatement } from '../data/reinstatementDialog'
import { fetchPatientDischarges } from '../data/discharges'
import { of } from 'rxjs'

export default (action$: any) =>
  action$.pipe(
    ofType(saveReinstatement.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'params', 'patientId'),
    mergeMap((patientId: string) =>
      of(
        fetchPatient.requested(patientId),
        fetchPatientDischarges.requested(patientId)
      )
    )
  )
