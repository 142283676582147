import React from 'react'
import { useSelector } from 'react-redux'
import { Section } from '~/components/text'
import { getPatientEvents } from '../../data'
import PatientEvent from './PatientEvent'

const PatientTimelineMinimal = () => {
  const patientEvents = useSelector(getPatientEvents)

  return (
    <Section title="Patient Timeline">
      {patientEvents.map(patientEvent => (
        <PatientEvent key={patientEvent.id} patientEvent={patientEvent} />
      ))}
    </Section>
  )
}

export default PatientTimelineMinimal
