import React, { useEffect } from 'react'
import useInput from '~/hooks/useInput'
import PropTypes from '~/utils/propTypes'
import {
  FilledInput,
  Icon,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  textBox: {
    width: '100%',
  },
  inputPadding: {
    padding: spacing(0.5),
  },
  adornmentPadding: {
    paddingRight: 0,
  },
  iconButton: {
    padding: spacing(0.25),
  },
}))

export default function TextFieldFilterCell({
  filteringEnabled,
  onFilter,
  value: filterValue,
}) {
  const classes = useStyles()
  const [value, setValue] = useInput(filterValue)
  const clearValue = () => setValue('')

  useEffect(() => {
    if (value === null) return

    const handler = setTimeout(() => onFilter({ value }), 500)
    return () => clearTimeout(handler)
  }, [value])

  return (
    <FilledInput
      disabled={!filteringEnabled}
      className={classes.textBox}
      classes={{
        input: classes.inputPadding,
        adornedEnd: classes.adornmentPadding,
      }}
      margin="dense"
      placeholder="Filter..."
      value={value || ''}
      onChange={setValue}
      endAdornment={
        value ? (
          <InputAdornment position="end">
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={clearValue}
            >
              <Icon>clear</Icon>
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
    />
  )
}

TextFieldFilterCell.propTypes = {
  filteringEnabled: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
  value: PropTypes.string,
}
