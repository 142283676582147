import { get as getIn } from 'lodash'
import { get } from '~/utils/data'
import key from '../key'

export const handleMedMessage = fallback => res => {
  const message = getIn(res, ['response', 'data', 'message'])
  return typeof message === 'string' ? message : fallback
}

export const getMedications = get(key)
