import { format, isAfter } from 'date-fns'
import { format as formatFP } from 'date-fns-tz/fp'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import { getUserId } from '~/data/session'
import { useAction } from '~/hooks'
import useInput from '~/hooks/useInput'
import { localDateAndTimeToUTC } from '~/utils/dates'
import { pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Button, Icon, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchCallList, getCallList } from '../data/callList'
import PatientLink from './PatientLink'
import PhoneLink from './PhoneLink'
import RowActions from './RowActions'

const CURRENT_DATE = format(new Date(), 'yyyy-MM-dd')
const InputLabelProps = { shrink: true }

const columns = [
  {
    name: 'createdAt',
    title: 'Logged At',
    getCellValue: ({ createdAt }) => pipe(formatFP('Pp z'))(createdAt),
  },
  {
    name: 'patientName',
    title: 'Patient Name',
    displayComponent: PatientLink,
  },
  { name: 'appName', title: 'APP Name' },
  {
    name: 'phoneNumber',
    title: 'Phone Number',
    displayComponent: PhoneLink,
  },
  { name: 'disposition', title: 'Disposition' },
  { name: 'subDisposition', title: 'Sub Disposition' },
  { name: 'callerName', title: 'Caller Name' },
]

const defaultSorting = [{ columnName: 'createdAt', direction: 'desc' }]

const useStyles = makeStyles(({ palette, spacing }) => ({
  callsText: {
    margin: [spacing(1), 0],
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: spacing(2),

    '& > *:not(:last-child)': {
      marginRight: spacing(2),
    },
  },
  header: {
    '& > div:first-child > div:first-child': {
      backgroundColor: palette.accordion.header,
      color: palette.accordion.color,
    },
  },
  icon: {
    marginRight: spacing(0.5),
  },
}))

const CallList = props => {
  const classes = useStyles()

  const userId = useSelector(state => props.userId || getUserId(state))
  const callList = useSelector(getCallList)
  const fetchCallListRequested = useAction(fetchCallList.requested)

  const [fromDate, setFromDate] = useInput(CURRENT_DATE)
  const [throughDate, setThroughDate] = useInput(CURRENT_DATE)

  const fromDateTime = localDateAndTimeToUTC(fromDate, ' 00:00:00')
  const throughDateTime = localDateAndTimeToUTC(throughDate, ' 23:59:59')

  const rows = useMemo(() => callList.toArray(), [callList])

  const errored = useMemo(
    () =>
      !fromDate ||
      !throughDate ||
      isAfter(new Date(fromDate), new Date(throughDate)),
    [fromDate, throughDate]
  )

  const onSearch = () => {
    fetchCallListRequested(userId, fromDateTime, throughDateTime)
  }

  const callsText = `${rows.length} Call${rows.length === 1 ? '' : 's'}`

  return (
    <React.Fragment>
      <div className={classes.dateContainer}>
        <TextField
          label="From Date"
          type="date"
          value={fromDate}
          onChange={setFromDate}
          error={errored}
          helperText={errored && 'Invalid Date Range'}
          InputLabelProps={InputLabelProps}
        />

        <TextField
          label="Through Date"
          type="date"
          value={throughDate}
          onChange={setThroughDate}
          error={errored}
          helperText={errored && 'Invalid Date Range'}
          InputLabelProps={InputLabelProps}
        />

        <Button
          disabled={errored}
          color="primary"
          variant="contained"
          onClick={onSearch}
        >
          <Icon className={classes.icon}>search</Icon>
          Search
        </Button>
      </div>

      <Typography className={classes.callsText} variant="h6">
        {callsText}
      </Typography>

      <Paper>
        <DevXGrid
          className={classes.header}
          rows={rows}
          columns={columns}
          sortable
          groupable
          pageable
          defaultSorting={defaultSorting}
          rowActions={RowActions}
          rowActionsCount={2}
        />
      </Paper>
    </React.Fragment>
  )
}

CallList.propTypes = {
  userId: PropTypes.string,
}

export default CallList
