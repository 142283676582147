import React from 'react'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ breakpoints }) => ({
  lights: {
    position: 'absolute',
    top: '54px',
    width: '100%',
    margin: '-15px 0 0 0',
    padding: 0,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& li': {
      display: 'inline-block',
      position: 'relative',
      width: 12,
      height: 28,
      margin: 20,
      background: '#ff0',
      boxShadow: '0 5px 24px 3px #df0',
      borderRadius: '50%',
    },
    '& li:nth-child(2n + 1)': {
      background: 'cyan',
      boxShadow: '0 5px 24px 3px cyan',
    },
    '& li:nth-child(4n + 2)': {
      background: '#f70094',
      boxShadow: '0 5px 24px 3px #f70094',
    },
  },
  [breakpoints.down('xs')]: {
    lights: {
      top: '90px',
    },
  },
})

const HolidayLights = ({ classes }) => (
  <ul className={classes.lights}>
    {new Array(100).fill().map((_a, i) => (
      <li key={i}></li>
    ))}
  </ul>
)

HolidayLights.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HolidayLights)
