import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { REQUEST_ORDERS_FOR_PATIENT_ID } from '../data/loaders'
import {
  fetchPatientOrders,
  ordersFetchFailed,
  ordersFetchSucceeded,
} from '../data/orders'

export default action$ =>
  action$.pipe(
    ofType(REQUEST_ORDERS_FOR_PATIENT_ID),
    pluck('payload', 'patientId'),
    mergeMap(
      patientId =>
        fetchPatientOrders(patientId).then(orders =>
          ordersFetchSucceeded(orders)
        ),
      of(
        fetchDistinctFieldValues([
          'hospice_order_cancellation_reasons',
          'order_cancellation_reasons',
        ])
      ).pipe(catchError(err => of(ordersFetchFailed(err))))
    )
  )
