// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import active from './active'
import page from './page'
import searchWord from './searchWord'
import { key } from './userQuery'

const reducer = combineReducers({
  [active.key]: active,
  [page.key]: page,
  [searchWord.key]: searchWord,
})

reducer.key = key

export default reducer
