import { List, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { get } from '~/utils/data'
import EPISODES_OF_CARE from '../key'

export const User = Record({
  id: null,
  name: null,
  role: null,
  roleLabel: null,
})

export const transformCareTeamMembers = patient =>
  List(
    getIn(patient, ['careTeamNew', 'members'], []).map(
      ({ careTeamRole, user }) =>
        User({
          id: user?.id,
          name: user?.name,
          role: careTeamRole?.key,
          roleLabel: careTeamRole?.label,
        })
    )
  )

export const getRoot = get(EPISODES_OF_CARE)
