import { combineEpics, ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { fetchLegacyAssessment } from '~/apps/assessment/data/legacyAssessments'
import { patientInfoRequested } from '~/features/panelContent/epics/loadPatientInfoForPanel'
import { currentAssessmentFetched } from '../data/assessments'

const fetchPatientInfoForCurrentLegacyAssessment = action$ =>
  action$.pipe(
    ofType(fetchLegacyAssessment.SUCCEEDED),
    filter(({ meta }) => meta.request.payload.current),
    pluck('payload', 'patientId'),
    map(patientInfoRequested)
  )

const fetchPatientInfoForCurrentAssessment = action$ =>
  action$.pipe(
    ofType(currentAssessmentFetched),
    pluck('payload', 'assessment', 'patientId'),
    filter(Boolean),
    map(patientInfoRequested)
  )

export default combineEpics(
  fetchPatientInfoForCurrentAssessment,
  fetchPatientInfoForCurrentLegacyAssessment
)
