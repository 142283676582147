import { format, parse } from 'date-fns'
import { debounce } from 'lodash'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from './TextField'

const inputLabelProps = { shrink: true }

const correctZeroDate = value => {
  if (value && value.length === 10 && value.slice(0, 1) === '0') {
    const year = new Date().getFullYear()
    const monthAndDay = value.substring(value.length - 6, value.length)

    return `${year}${monthAndDay}`
  }

  return value
}

const correctDateFormat = value => {
  if (value && value.indexOf('/') > -1) {
    return format(parse(value, 'm/d/yyyy', new Date()), 'yyyy-mm-dd')
  }

  return value
}

class DateField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dateValue: correctDateFormat(this.props.value),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value) {
      this.setState({ dateValue: correctZeroDate(this.props.value) })
    }
  }

  onBlur = value => {
    this.setState({ dateValue: correctZeroDate(value) })
  }

  onChange = value => {
    this.setState({ dateValue: value })

    if (this.props.onChange) {
      this.debouncedOnChange()
    }
  }

  debouncedOnChange = debounce(() => {
    this.setState(prevState => ({
      dateValue: correctZeroDate(prevState.dateValue),
    }))

    this.props.onChange(this.state.dateValue)
  }, 1000)

  render() {
    const { onChange } = this.props

    return (
      <TextField
        {...this.props}
        type="date"
        InputLabelProps={inputLabelProps}
        onBlur={this.onBlur}
        onChange={onChange && this.onChange}
        value={this.state.dateValue}
      />
    )
  }
}

DateField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
}

export default DateField
