import { isEqual } from 'lodash'
import { combineEpics, ofType } from 'redux-observable'
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators'
// @ts-expect-error no export
import { switchTo } from '~/utils/operators'
import {
  carePlanSet,
  getCarePlanProblems,
  problemSaved,
} from '../data/carePlan'
import { Problem } from '../data/common/shared'
import { fetchCarePlanTypes } from '../data/types'

const carePlanSetEpic = (action$: any) =>
  action$.pipe(
    ofType(carePlanSet),
    pluck('payload', 'problems'),
    map((problems: any) =>
      problems
        .filter((problem: Problem) => problem.selected && problem.problemTypeId)
        .map(({ problemTypeId }: Problem) => problemTypeId)
        .toArray()
    ),
    filter((problemTypeIds: number[]) => problemTypeIds.length > 0),
    map(fetchCarePlanTypes.requested)
  )

const problemSavedEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(problemSaved),
    pluck('payload', 'problem'),
    filter((problem: Problem) => problem.selected && !!problem.problemTypeId),
    switchTo(state$),
    map(getCarePlanProblems),
    map((problems: any) =>
      problems
        .filter((problem: Problem) => problem.selected && problem.problemTypeId)
        .map(({ problemTypeId }: Problem) => problemTypeId)
        .toArray()
    ),
    filter((problemTypeIds: number[]) => problemTypeIds.length > 0),
    distinctUntilChanged(isEqual),
    map(fetchCarePlanTypes.requested)
  )

export default combineEpics(carePlanSetEpic, problemSavedEpic)
