import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import {
  fetchSearchProblems,
  noSuggestionSelected,
  sendSuggestionSelected,
} from '../data/imo'
import { getImoRequestId, getImoSuggestions } from '../data/utils/selectors'

export const sendImoSuggestionSelected = (action$: any, state$: any) =>
  action$.pipe(
    ofType(fetchSearchProblems.REQUESTED),
    pluck('payload'),
    withLatestFrom(state$),
    map(([{ sessionId, searchTerm }, state]: any) => {
      const requestId = getImoRequestId(state)
      const suggestions: string[] = getImoSuggestions(state)
      const rank =
        suggestions && suggestions.length > 0
          ? suggestions?.findIndex(
              suggestion =>
                suggestion.toLowerCase() === searchTerm.toLowerCase()
            )
          : -1

      return rank === -1
        ? noSuggestionSelected(state)
        : sendSuggestionSelected.requested(
            sessionId,
            requestId,
            searchTerm,
            rank
          )
    })
  )
