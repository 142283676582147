import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

export const Location = Record({
  id: null,
  name: null,
  address1: null,
  address2: null,
  city: null,
  current: null,
  state: null,
  zip: null,
  phone: null,
  fax: null,
})

const key = 'locations'

export const fetchLocations = Request({
  typePrefix: key,
  typeBase: 'FETCH_LOCATIONS',
  operation: () => AspireAPI.get('medications/locations'),
  transform: into(Location, 'id'),
  messages: { failed: 'Could not get locations' },
})

export const uploadLocationsToMDT = Request({
  typePrefix: key,
  typeBase: 'MDT_UPLOAD_LOCATIONS',
  operation: () => AspireAPI.post('medications/mdt_locations'),
  messages: { failed: 'Could not upload locations' },
})

export default createReducer(key, Map(), {
  [fetchLocations.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getLocations = pipe(getRoot, get(key))
export const getLocationById = (state: any, id: any) =>
  getLocations(state).get(id)
