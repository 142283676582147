import React from 'react'
import { Divider, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  line: {
    minHeight: spacing(3),
    alignItems: 'center',
  },
  divider: {
    gridColumnStart: 'span 2',
  },
}))

type Props = {
  label: string
  children: React.ReactNode
  hideIfBlank?: boolean
  disableTypography?: boolean
}

const GridLine = ({
  label,
  children,
  hideIfBlank,
  disableTypography,
}: Props) => {
  const classes = useStyles()

  const shouldRender = Boolean(!hideIfBlank || children)

  return shouldRender ? (
    <React.Fragment>
      <div className={classes.line}>
        <Typography variant="caption">{label}</Typography>
      </div>

      <div className={classes.line}>
        {disableTypography ? (
          children
        ) : (
          <Typography variant="body2">{children}</Typography>
        )}
      </div>

      <Divider className={classes.divider} />
    </React.Fragment>
  ) : null
}

export default GridLine
