import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Line from '~/components/text/Line'
import Section from '~/components/text/Section'
import { FieldValue, getFieldValues } from '~/data/fieldValues'
import useMountEffect from '~/hooks/useMountEffect'
import { formatDate, formatDateTime } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import {
  fetchPatientDetails,
  getPatientDetailsByPatientId,
} from '../data/patientDetails'

const styles = ({ spacing }) => ({
  container: {
    padding: spacing(1),
  },
})

const PatientDetails = ({
  classes,
  row,
  patientDetails,
  fetchPatientDetails,
  hospiceEligibleValues,
  providerPrognosisValues,
}) => {
  const noDetails =
    patientDetails &&
    !row.limitedPrognosis &&
    !patientDetails.currentHospitalizationAdmissionDate &&
    !patientDetails.recentHospitalizationAdmissionDate &&
    !patientDetails.currentSnfAdmissionDate &&
    !patientDetails.recentSnfAdmissionDate &&
    !patientDetails.dischargeDate &&
    !patientDetails.dischargeRecommendationCreatedDate &&
    !row.overdue

  const hospiceEligibleLabel =
    (patientDetails &&
      hospiceEligibleValues.get(patientDetails.hospiceEligible)) ||
    {}
  const providerPrognosisLabel =
    (patientDetails &&
      providerPrognosisValues.get(patientDetails.providerPrognosis)) ||
    {}

  useMountEffect(() => {
    if (!patientDetails) fetchPatientDetails(row.patientId)
  })

  return patientDetails ? (
    <div className={classes.container}>
      {/* Limited Prognosis */}
      {row.limitedPrognosis && (
        <Section title="Limited Prognosis">
          <Line label="Provider Prognosis" name="providerPrognosis">
            {providerPrognosisLabel.label}
          </Line>

          <Line label="Hospice Eligible" name="hospiceEligible">
            {hospiceEligibleLabel.label}
          </Line>
        </Section>
      )}

      {/* Current Hospitalization */}
      {patientDetails.currentHospitalizationAdmissionDate && (
        <Section title="Current Hospitalization">
          <Line label="Hospital Name">{patientDetails.hospitalName}</Line>
          <Line label="Admission Date">
            {formatDate(patientDetails.currentHospitalizationAdmissionDate)}
          </Line>
          <Line label="Reason">
            {patientDetails.currentHospitalizationReason}
          </Line>
          <Line label="Additional Info">
            {patientDetails.currentHospitalizationInfo}
          </Line>
        </Section>
      )}

      {/* Recent Hospitalization */}
      {patientDetails.recentHospitalizationAdmissionDate && (
        <Section title="Recent Hospitalization">
          <Line label="Admission Date">
            {formatDate(patientDetails.recentHospitalizationAdmissionDate)}
          </Line>
          <Line label="Discharge Date">
            {formatDate(patientDetails.recentHospitalizationDischargeDate)}
          </Line>
          <Line label="Discharged To">
            {patientDetails.recentHospitalizationDischargedTo}
          </Line>
          <Line label="Reason">
            {patientDetails.recentHospitalizationReason}
          </Line>
          <Line label="Additional Info">
            {patientDetails.recentHospitalizationInfo}
          </Line>
        </Section>
      )}

      {/* Current SNF */}
      {patientDetails.currentSnfAdmissionDate && (
        <Section title="Current SNF">
          <Line label="Admission Date">
            {formatDate(patientDetails.currentSnfAdmissionDate)}
          </Line>
          <Line label="Description">{patientDetails.currentSnfNotes}</Line>
        </Section>
      )}

      {/* Recent SNF */}
      {patientDetails.recentSnfAdmissionDate && (
        <Section title="Recent SNF">
          <Line label="Admission Date">
            {formatDate(patientDetails.recentSnfAdmissionDate)}
          </Line>
          <Line label="Discharge Date">
            {formatDate(patientDetails.recentSnfDischargeDate)}
          </Line>
          <Line label="Description">{patientDetails.recentSnfNotes}</Line>
        </Section>
      )}

      {/* Discharge Summary */}
      {patientDetails.dischargeDate && (
        <Section title="Discharge Summary">
          <Line label="Discharge Date">
            {formatDate(patientDetails.dischargeDate)}
          </Line>
          <Line label="Reason">{patientDetails.dischargeReason}</Line>
          <Line label="Discharged By">{patientDetails.dischargedBy}</Line>
          <Line label="Description">{patientDetails.dischargeNotes}</Line>
        </Section>
      )}

      {/* Recommended For Discharge */}
      {patientDetails.dischargeRecommendationCreatedDate && (
        <Section title="Recommended For Discharge">
          <Line label="Reason">
            {patientDetails.dischargeRecommendationReason}
          </Line>
          <Line label="Date / Time">
            {formatDateTime(patientDetails.dischargeRecommendationCreatedDate)}
          </Line>
          <Line label="Recommended By">
            {patientDetails.dischargeRecommendationCreatedBy}
          </Line>
          <Line label="Notes">
            {patientDetails.dischargeRecommendationNotes}
          </Line>
        </Section>
      )}

      {/* Overdue */}
      {row.overdue && (
        <Section title="Overdue">
          <Line label="Last Visit Date">
            {formatDate(patientDetails.lastVisitDate)}
          </Line>
          <Line label="Primary Next Targeted Visit Date">
            {formatDate(patientDetails.nextTargetedVisitDate)}
          </Line>
          <Line label="Days Overdue">{patientDetails.daysOverdue}</Line>
        </Section>
      )}

      {noDetails && <Typography>No Patient Details</Typography>}
    </div>
  ) : (
    <Typography>Loading...</Typography>
  )
}

PatientDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  patientDetails: PropTypes.record,
  fetchPatientDetails: PropTypes.func.isRequired,
  hospiceEligibleValues: PropTypes.iterableOf(FieldValue),
  providerPrognosisValues: PropTypes.iterableOf(FieldValue),
}

export default compose(
  connect(
    (state, { row }) => ({
      patientDetails: getPatientDetailsByPatientId(state, row.patientId),
      hospiceEligibleValues: getFieldValues('hospice_eligible')(state),
      providerPrognosisValues: getFieldValues('provider_prognosis')(state),
    }),
    { fetchPatientDetails: fetchPatientDetails.requested }
  ),
  withStyles(styles)
)(PatientDetails)
