import { Record } from 'immutable'
import { createSelector } from 'reselect'
import createReducer from '~/utils/createReducer'
import { getAttachmentsRoot } from './common/shared'

/* Record */
const Attachment = Record({
  id: null,
  userId: null,
  patientId: null,
  bucket: null,
  object: null,
  displayName: '',
  ext: null,
  attachmentType: '',
  contentType: null,
  signedUrl: null,
  error: null,
  errorMessages: null,
  successful: null,
})

/* Constants */
const key = 'newAttachment'

const NEW_ATTACHMENT_TYPE_SELECTED = 'attachments/NEW_ATTACHMENT_TYPE_SELECTED'
const FILE_SELECTED = 'attachments/FILE_SELECTED'
const ATTACHMENTS_LOAD = 'attachments/ATTACHMENTS_LOAD'

/* Action Creators */
const newAttTypeSelected = attachmentType => ({
  type: NEW_ATTACHMENT_TYPE_SELECTED,
  attachmentType,
})

const onLoadAttachments = (patientId, docType) => ({
  type: ATTACHMENTS_LOAD,
  payload: { patientId, docType },
})
const fileSelected = opts => ({ type: FILE_SELECTED, ...opts })

/* Reducer */
export default createReducer(key, Attachment(), {
  [NEW_ATTACHMENT_TYPE_SELECTED]: (state, { attachmentType }) =>
    state.set('attachmentType', attachmentType),

  [FILE_SELECTED]: (state, { file }) => state.set('displayName', file.name),
})

/* Selectors */
const getAttachment = createSelector(
  [state => getAttachmentsRoot(state).get(key)],
  file => file
)

export {
  Attachment,
  newAttTypeSelected,
  ATTACHMENTS_LOAD,
  FILE_SELECTED,
  fileSelected,
  getAttachment,
  onLoadAttachments,
}
