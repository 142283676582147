import { List, Map } from 'immutable'
import { push } from 'redux-routable'
import createReducer from '~/utils/createReducer'
import initialCommands from './initialCommands'

export const key = 'commandPalette'

const COMMAND_PALETTE_OPENED = `${key}/OPENED`
const COMMAND_PALETTE_CLOSED = `${key}/CLOSED`
const COMMAND_REGISTERED = `${key}/COMMAND_REGISTERED`
const COMMAND_BATCH_REGISTERED = `${key}/COMMAND_BATCH_REGISTERED`
const COMMAND_DEREGISTERED = `${key}/COMMAND_DEREGISTERED`
const COMMAND_BATCH_DEREGISTERED = `${key}/COMMAND_BATCH_DEREGISTERED`

/** Opens the command palette. */
export const open = () => ({ type: COMMAND_PALETTE_OPENED })

/** Closes the command palette. */
export const close = () => ({ type: COMMAND_PALETTE_CLOSED })

/**
 * Registers a single command so that is available to the command palette.
 *
 * @param {Command} command - the command to register
 * @returns {Action}
 */
export const registerCommand = command => ({
  type: COMMAND_REGISTERED,
  payload: command,
})

/**
 * Registers a batch of commands in bulk. This is more efficient than calling
 * `registerCommand` multiple times for a list of commands.
 *
 * @param {Array<Command>} commands - the list of commands to register
 * @returns {Action}
 */
export const registerCommandBatch = commands => ({
  type: COMMAND_BATCH_REGISTERED,
  payload: commands,
})

/**
 * Deregisters a command, removing it from the command palette options.
 *
 * @param {Command} - the command to remove
 * @returns {Action}
 */
export const deregisterCommand = command => ({
  type: COMMAND_DEREGISTERED,
  payload: command,
})

/**
 * Deregisters a batch of commands in bulk. This is more efficient than calling
 * `deregisterCommand` multiple times for a list of commands.
 *
 * @param {Array<Command>} commands - the list of commands to deregister
 * @returns {Action}
 */
export const deregisterCommandBatch = commands => ({
  type: COMMAND_BATCH_DEREGISTERED,
  payload: commands,
})

const initialState = Map({
  open: false,
  commands: List(initialCommands),
})

export default createReducer(key, initialState, {
  // Command palette visibility
  [COMMAND_PALETTE_OPENED]: state => state.set('open', true),
  [COMMAND_PALETTE_CLOSED]: state => state.set('open', false),

  // Registering commands
  [COMMAND_REGISTERED]: (state, { payload }) =>
    state.update('commands', commands => commands.push(payload)),
  [COMMAND_BATCH_REGISTERED]: (state, { payload }) =>
    state.update('commands', commands => commands.concat(payload)),

  // Deregistering commands
  [COMMAND_DEREGISTERED]: (state, { payload }) =>
    state.update('commands', commands =>
      commands.filter(c => c.label !== payload.label)
    ),
  [COMMAND_BATCH_DEREGISTERED]: (state, { payload }) =>
    state.update('commands', commands =>
      commands.filter(c => !payload.includes(c))
    ),
})

const normalizeCommand = command => {
  if (!command.route) return command
  return {
    label: `Go to ${command.label}`,
    action: () => push(command.route, command.params),
  }
}

export const getCommands = state =>
  state
    .getIn([key, 'commands'])
    .filter(command => {
      if (!command.predicate) return true
      return command.predicate(state)
    })
    .map(normalizeCommand)

export const getIsOpen = state => state.getIn([key, 'open'])
