import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef } from 'react'
import { useInput, useUpdateEffect } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FilterBase from './FilterBase'

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    width: '100%',
  },
  input: {
    padding: spacing(0.25),
  },
}))

const TextFilter = ({ color, type, title, filter, value, onChange }) => {
  const classes = useStyles()

  const [inputValue, setInputValue] = useInput(value)
  useEffect(() => {
    setInputValue(value)
  }, [value])
  const inputRef = useRef()

  const onFocus = useCallback(() => {
    inputRef.current.focus()
  })

  const debouncedOnChange = useCallback(
    debounce(value => {
      onChange(filter.key, value)
    }, 250),
    [filter.key, onChange]
  )

  useUpdateEffect(() => {
    if (value !== inputValue) {
      debouncedOnChange(inputValue)
    }
  }, [debouncedOnChange, inputValue])

  return (
    <FilterBase
      color={color}
      title={title}
      value={inputValue}
      onClick={onFocus}
    >
      <TextField
        className={classes.textField}
        inputRef={e => {
          inputRef.current = e
        }}
        inputProps={{ className: classes.input, type }}
        variant="filled"
        value={inputValue}
        onChange={setInputValue}
      />
    </FilterBase>
  )
}

TextFilter.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

TextFilter.defaultProps = {
  type: 'search',
}

export default TextFilter
