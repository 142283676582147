import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import {
  getEpisodeOfCareById,
  saveEpisodeOfCareForm,
  saveForm,
} from '../data/episodesOfCare'

export default (action$, state$) =>
  action$.pipe(
    ofType(saveForm),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    map(([id, state]) => {
      const { form } = getEpisodeOfCareById(state)(id)
      return saveEpisodeOfCareForm.requested(id, form.formData)
    })
  )
