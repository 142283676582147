import cx from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form/immutable'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import DrugAlertBadge from '../../containers/DrugAlertBadge'
import RxWriter from '../../containers/RxWriter'
import { isPending } from '../../utils/medListConstants'
import Warning from './Warning'

const styles = ({ palette: { severity } }) => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
  submitButton: {
    color: ({ invalid }) => (invalid ? severity.error.static : null),
  },
})

const RxWriterDialog = ({
  areRequestsPending,
  classes,
  closeDialog,
  handleSubmit,
  onSubmit,
  pristine,
  reset,
  rxStatusValue,
  selectedMedication,
  submitting,
}) => {
  const [showDrugAlerts, setShowDrugAlerts] = useState(false)
  const toggleShowDrugAlerts = useCallback(
    () => setShowDrugAlerts(prevShowDrugAlerts => !prevShowDrugAlerts),
    [setShowDrugAlerts]
  )
  const save = useCallback(values => onSubmit(values, false), [onSubmit])
  const saveAndSend = useCallback(values => onSubmit(values, true), [onSubmit])
  const dialogTitle = useMemo(
    () =>
      selectedMedication.get('isEditing')
        ? 'Edit Prescription'
        : 'Prescription Writer',
    [selectedMedication]
  )

  return (
    <Dialog open={!selectedMedication.isEmpty()} maxWidth={false}>
      <DialogTitle>
        <span className={classes.flex}>
          <Typography variant="h4">{dialogTitle}</Typography>
          <DrugAlertBadge onClick={toggleShowDrugAlerts} />
        </span>
        <Warning selectedMedication={selectedMedication} />{' '}
      </DialogTitle>
      <DialogContent>
        <RxWriter
          selectedMedication={selectedMedication}
          showDrugAlerts={showDrugAlerts}
        />
      </DialogContent>
      <DialogActions>
        <span className={cx(classes.flex, classes.fullWidth)}>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <span>
            <Button
              className={classes.submitButton}
              onClick={handleSubmit(save)}
              disabled={areRequestsPending}
            >
              Save
            </Button>
            <Button
              className={classes.submitButton}
              disabled={areRequestsPending || !isPending(rxStatusValue)}
              onClick={handleSubmit(saveAndSend)}
            >
              Save And Go To MDToolbox
            </Button>
            <Button
              disabled={pristine || submitting}
              onClick={reset}
              color="primary"
            >
              Clear
            </Button>
          </span>
        </span>
      </DialogActions>
    </Dialog>
  )
}

RxWriterDialog.propTypes = {
  areRequestsPending: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  rxStatusValue: PropTypes.string,
  selectedMedication: PropTypes.map.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default compose(
  reduxForm({
    form: 'medications.rxWriter',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmitFail: errors => {
      document
        .querySelector(`[role=dialog] [name=${Object.keys(errors)[0]}]`)
        .scrollIntoView({ behavior: 'smooth' })
    },
  }),
  React.memo,
  withStyles(styles)
)(RxWriterDialog)
