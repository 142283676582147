import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'createUnengagedDialog'
const dialogOpenKey = 'createUnengagedDialogOpen'

export const getDialogOpenValue = getIn([rootKey, dialogOpenKey])

// CREATORS
const creator = scopedCreator(key)

export const createUnengagedDialogOpened = creator(
  'CREATE_UNENGAGED_DIALOG_OPENED'
)
export const createUnengagedDialogClosed = creator(
  'CREATE_UNENGAGED_DIALOG_CLOSED'
)

// REQUEST
export const createUnengaged = Request({
  typePrefix: key,
  typeBase: 'CREATE_UNENGAGED',
  requestParams: ['patientId', 'notes', 'reason'],
  operation: (patientId, notes, reason) =>
    AspireAPI.post(`v1/patient/${patientId}/unengaged`, {
      notes,
      reason,
    }),
  messages: {
    succeeded: 'Unengaged record was successfully added for Patient',
    failed: e =>
      Object.values(e.response.data.message || {}).join('') ||
      'Failed to create Unengaged record',
  },
})

// REDUCER
export default createReducer(dialogOpenKey, false, {
  [createUnengagedDialogClosed]: () => false,
  [createUnengagedDialogOpened]: () => true,
  [createUnengaged.SUCCEEDED]: () => false,
})
