import { ofType } from 'redux-observable'
import { mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { stakeholdersInitialized } from '../data/common/shared'

export default action$ =>
  action$.pipe(
    ofType(stakeholdersInitialized),
    mapTo(fetchDistinctFieldValues(['stakeholder_report_type']))
  )
