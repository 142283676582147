import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginBottom: spacing(1),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: spacing(2),
  },
}))

type Props = {
  title?: string
  children: React.ReactNode
}

const GridSection = ({ title, children }: Props) => {
  const classes = useStyles()

  return (
    <>
      {title && (
        <Typography color="primary" className={classes.title} variant="h6">
          {title}
        </Typography>
      )}

      <div className={classes.grid}>{children}</div>
    </>
  )
}

export default GridSection
