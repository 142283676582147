import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { orderDetailFetchRequested } from '../data/orderDetail'
import { ORDER_SELECTED } from '../data/selectedOrder'

export default action$ =>
  action$.pipe(
    ofType(ORDER_SELECTED),
    pluck('payload', 'id'),
    filter(Boolean),
    map(id => orderDetailFetchRequested(id))
  )
