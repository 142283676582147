// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import appGraduation from './data/appGraduation'
import currentEncounterReview from './data/currentEncounterReview'
import currentPatientOutlierFinalDecision from './data/currentPatientOutlierFinalDecision'
import currentPatientOutlierReview from './data/currentPatientOutlierReview'
import currentUserId from './data/currentUserId'
import encounterReviews from './data/encounterReviews'
import patientOutlierFinalDecisions from './data/patientOutlierFinalDecisions'
import patientOutlierPanel from './data/patientOutlierPanel'
import patientOutlierReviews from './data/patientOutlierReviews'
import saveInfo from './data/saveInfo'
import key from './key'

const reducer = combineReducers({
  [appGraduation.key]: appGraduation,
  [currentEncounterReview.key]: currentEncounterReview,
  [currentPatientOutlierFinalDecision.key]: currentPatientOutlierFinalDecision,
  [currentPatientOutlierReview.key]: currentPatientOutlierReview,
  [currentUserId.key]: currentUserId,
  [encounterReviews.key]: encounterReviews,
  [patientOutlierFinalDecisions.key]: patientOutlierFinalDecisions,
  [patientOutlierPanel.key]: patientOutlierPanel,
  [patientOutlierReviews.key]: patientOutlierReviews,
  [saveInfo.key]: saveInfo,
})

reducer.key = key

export default reducer
