import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const DIALOG = 'dialog'
export const ADD_LIST_ITEM = 'addListItem'
export const ADD_LIST_ITEM_FROM_ACTIONS = 'addListItemFromActions'
export const EDIT_LIST_ITEM = 'editListItem'
export const DELETE_LIST_ITEM = 'deleteListItem'

const Dialog = Record({
  dialogType: null,
  patientId: null,
  patientName: null,
  reason: null,
  rowId: null,
})

const creator = scopedCreator(rootKey)
export const dialogOpened = creator('DIALOG_OPENED', [
  'dialogType',
  'patientId',
  'patientName',
  'reason',
  'rowId',
])
export const dialogClosed = creator('DIALOG_CLOSED')

export default createReducer(DIALOG, Dialog(), {
  [dialogOpened]: (_state, { payload }) => Dialog(payload),
  [dialogClosed]: () => Dialog(),
})

export const getDialog = pipe(getRoot, get(DIALOG))
