import React from 'react'
import { connect } from 'react-redux'
import { areRequestsPending } from '~/data/pending'
import bindStateToSelectors from '~/utils/bindStateToSelectors'
import PropTypes from '~/utils/propTypes'
import CancelTaskDialog from '../components/CancelTaskDialog'
import CompleteTaskDialog from '../components/CompleteTaskDialog'
import SnoozeTaskDialog from '../components/SnoozeTaskDialog'
import { CANCEL, COMPLETE, REASSIGN, SNOOZE } from '../constants'
import { DialogState, dialogClosed, getDialogState } from '../data/dialog'
import { getNewDueDate, newDueDateSelected } from '../data/newDueDate'
import {
  getReassignmentUser,
  reassignmentUserDeselected,
  reassignmentUserSelected,
} from '../data/reassignmentUser'
import {
  cancelTask,
  reassignTask,
  snoozeTask,
  taskSubmitted,
} from '../data/tasks'
import ReassignTaskDialog from './ReassignTaskDialog'

class TaskDialog extends React.Component {
  static propTypes = {
    cancelTask: PropTypes.func.isRequired,
    dialogClosed: PropTypes.func.isRequired,
    dialogState: PropTypes.instanceOf(DialogState).isRequired,
    taskReassignRequested: PropTypes.func.isRequired,
    newDueDate: PropTypes.string.isRequired,
    newDueDateSelected: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired,
    reassignTask: PropTypes.func.isRequired,
    reassignmentUserDeselected: PropTypes.func.isRequired,
    reassignmentUser: PropTypes.record.isRequired,
    reassignmentUserSelected: PropTypes.func.isRequired,
    snoozeTaskRequested: PropTypes.func.isRequired,
    snoozeTask: PropTypes.func.isRequired,
    task: PropTypes.record,
    taskCompleteRequested: PropTypes.func.isRequired,
  }

  render() {
    const { dialogClosed, dialogState, pending, task } = this.props

    if (!dialogState.open) {
      return null
    }

    const commonProps = {
      dialogClosed,
      task: task,
      open: dialogState.open,
      pending,
    }

    switch (dialogState.type) {
      case CANCEL:
        return (
          <CancelTaskDialog
            taskUpdateRequested={this.props.cancelTask}
            {...commonProps}
          />
        )
      case COMPLETE:
        return (
          <CompleteTaskDialog
            taskCompleteRequested={this.props.taskCompleteRequested}
            {...commonProps}
          />
        )
      case REASSIGN:
        return (
          <ReassignTaskDialog
            taskReassignRequested={this.props.reassignTask}
            reassignmentUserDeselected={this.props.reassignmentUserDeselected}
            reassignmentUser={this.props.reassignmentUser}
            reassignmentUserSelected={this.props.reassignmentUserSelected}
            {...commonProps}
          />
        )
      case SNOOZE:
        return (
          <SnoozeTaskDialog
            newDueDate={this.props.newDueDate}
            newDueDateSelected={this.props.newDueDateSelected}
            snoozeTaskRequested={this.props.snoozeTask}
            {...commonProps}
          />
        )
      default:
        return null
    }
  }
}

const isPending = state => areRequestsPending(state, [reassignTask, snoozeTask])

export default connect(
  bindStateToSelectors({
    dialogState: getDialogState,
    newDueDate: getNewDueDate,
    pending: isPending,
    reassignmentUser: getReassignmentUser,
  }),
  {
    cancelTask: cancelTask.requested,
    dialogClosed,
    taskReassignRequested: reassignTask.requested,
    newDueDateSelected,
    reassignmentUserDeselected,
    reassignmentUserSelected,
    snoozeTaskRequested: snoozeTask.requested,
    reassignTask: reassignTask.requested,
    snoozeTask: snoozeTask.requested,
    taskCompleteRequested: taskSubmitted,
  }
)(TaskDialog)
