import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { createRpmForm } from './RpmForm'
import { getRPM } from './root'

const RMP_FORM = 'rpmForms'

const creator = scopedCreator(rootKey)
export const rpmFormUpdated = creator('FETCH_RPM_FORM')

export const RpmForms = Record({
  forms: [],
})

export const fetchRpmForm = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_RPM_FORMS',
  requestParams: [],
  operation: () => AspireAPI.get(`patient_record/rpm_forms`),
  messages: {
    failed: 'Failed to Fetch Rpm Forms',
  },
})

export const approveOrRejectForm = Request({
  typePrefix: rootKey,
  typeBase: 'APPROVE_OR_REJECT_FORM',
  requestParams: ['action', 'path', 'approve_reject_rpm_id'],
  operation: (action: any, path: any, approve_reject_rpm_id: any) =>
    AspireAPI.put(
      `patient_record/rpm_forms/approve_or_reject/${action}/${path}/${approve_reject_rpm_id}`
    ),
  messages: {
    failed: 'Failed to approve/reject RPM Form',
  },
})

export const addNote = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_NOTE',
  requestParams: ['note', 'path', 'form_id'],
  operation: (note: any, path: any, approve_reject_rpm_id: any) =>
    AspireAPI.post(
      `patient_record/rpm_forms/approve_or_reject/add_note/${approve_reject_rpm_id}`,
      { note, path }
    ),
  messages: {
    failed: (error: any) => {
      return error.response.data.message.note || 'Failed to Add Note'
    },
  },
})

export const deleteNote = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_NOTE',
  requestParams: ['note_id'],
  operation: (note_id: any) =>
    AspireAPI.delete(
      `patient_record/rpm_forms/approve_or_reject/remove_note/${note_id}`
    ),
  messages: {
    failed: 'Failed to delete Note',
  },
})

export const updateRpm = Request({
  typePrefix: rootKey,
  typeBase: 'UPDATE_RPM',
  requestParams: ['id', 'value'],
  operation: (id: any, value: any) =>
    AspireAPI.put(`patient_record/rpm_forms/${id}/${value}`),
  messages: {
    succeeded: 'Form successfully saved',
    failed: 'There was an issue saving this form',
  },
})

const initState = RpmForms()
export default createReducer(RMP_FORM, initState, {
  [fetchRpmForm.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [createRpmForm.SUCCEEDED]: (state: any, { payload }: any) =>
    state.concat(payload),
  [updateRpm.SUCCEEDED]: (state: any, { payload }: any) =>
    state.map((el: any) => {
      return el.id === payload.id
        ? {
            ...el,
            assignTo: payload.assignTo,
          }
        : el
    }),
  [approveOrRejectForm.SUCCEEDED]: (state: any, { payload }: any) =>
    state.map((el: any) => {
      return el.approveRejectRpm.id === payload.id
        ? {
            ...el,
            status: payload.referralActionPlan.status,
            approveRejectRpm: {
              ...el.approveRejectRpm,
              rpm_status: payload.rpmStatus,
              coordinator_status: payload.coordinatorStatus,
              approved_rpm_by: payload.approvedRpmBy,
              approved_coordinator_by: payload.approvedCoordinatorBy,
              created_at: payload.createdAt,
            },
          }
        : el
    }),
  [addNote.SUCCEEDED]: (state: any, { payload }: any) =>
    state.map((el: any) => {
      return el.approveRejectRpm.id === payload.approveRejectRpmId
        ? {
            ...el,
            approveRejectRpm: {
              ...el.approveRejectRpm,
              notes: [
                {
                  ...payload,
                  created_by: payload.createdBy,
                  created_at: payload.createdAt,
                },
              ].concat(el.approveRejectRpm.notes),
            },
          }
        : el
    }),
  [deleteNote.SUCCEEDED]: (state: any, { payload }: any) =>
    state.map((el: any) =>
      el.approveRejectRpm.id === payload.approveRejectRpmId
        ? {
            ...el,
            approveRejectRpm: {
              ...el.approveRejectRpm,
              notes: el.approveRejectRpm.notes.filter(
                (note: any) => note.id !== payload.id
              ),
            },
          }
        : el
    ),
})

export const getForms = pipe(getRPM, get(RMP_FORM))
