import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { getAttachmentsRoot } from './common/shared'

/* RECORD */
export const Patient = Record({
  id: null,
  name: null,
})

/* CONSTANTS */
export const DEMOGRAPHICS_STORED = '/v1/attachments/DEMOGRAPHICS_STORED'

/* Actions */
export const demographicsStored = name => ({ type: DEMOGRAPHICS_STORED, name })

/* KEY */
export const key = 'patient'

/* REDUCER */
export default createReducer(key, Patient(), {
  [DEMOGRAPHICS_STORED]: (state, { name }) => state.set('name', name),
})

/* API */
export const fetchDemographics = patientId =>
  AspireAPI.get('/v1/patientdemographics', {
    params: { patientIDs: patientId },
  }).then(res => res[0].name)

/* SELECTORS */
export const getPatient = state => getAttachmentsRoot(state).get(key)
