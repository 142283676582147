import {
  differenceInDays,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
} from 'date-fns'

import {
  HOME_BASED_PALLIATIVE_CARE,
  TIER_ONE,
  TIER_TWO,
  TIER_THREE,
  CAREMORE_AT_HOME,
  CAREMORE_HEALTH_NETWORK,
  HIGH_RISK_COMPLEX_CARE,
  COMMERCIAL_PALLIATIVE_CARE,
  HBPC_HYBRID,
  TELEPHONIC,
  PC_PLUS,
} from '~/utils/programInfo'

const ACTIVE = 'Active'
const SCHEDULED = 'Scheduled'
const REFERRED = 'Referred'
const CSS_REINSTATE = 'CSS_Reinstate'
const HOSPITAL = 'Hospital'
const SNF = 'SNF'
const LTC = 'LTC'
const UNENGAGED = 'Unengaged'

export const createDueFilter = (min: any, max: any) => (
  { nextScheduledVisitDateTime, nextTargetedVisitDate }: any,
  _value: any,
  { date }: any
) => {
  // ERIC NOTE - CALENDAR WIDGET DATE SHOULD BE NATIVE JS DATE IN THE FUTURE
  const convertedDate = date.toDate()
  const dueDays = differenceInDays(nextTargetedVisitDate, convertedDate)

  return !nextScheduledVisitDateTime && nextTargetedVisitDate
    ? dueDays >= min && dueDays <= max
    : false
}

export const notDue = (patient: any) =>
  patient.nextScheduledVisitDateTime || !patient.nextTargetedVisitDate
export const overdueMax = createDueFilter(-Infinity, -47)
export const overdueHigh = createDueFilter(-46, -24)
export const overdueMedium = createDueFilter(-23, -9)
export const overdueLow = createDueFilter(-8, -1)
export const dueMedium = createDueFilter(0, 3)
export const dueLow = createDueFilter(0, 3)
export const dueHigh = createDueFilter(4, Infinity)
export const statusActive = (patient: any) => patient.status === ACTIVE
export const statusScheduled = (patient: any) => patient.status === SCHEDULED
export const statusReferred = (patient: any) => patient.status === REFERRED
export const reinstated = (patient: any) =>
  patient.reinstated && patient.reinstatementType === CSS_REINSTATE
export const transitionOfCare = (patient: any) =>
  [HOSPITAL, SNF, LTC].includes(patient.subStatus) || patient.hasOpenTOCTasks
export const scheduledFrom = (patient: any, value: any) => {
  const scheduledDate = patient.nextScheduledVisitDateTime
  const parsedDate = parseISO(value)

  return (
    !scheduledDate ||
    !value ||
    isSameDay(scheduledDate, parsedDate) ||
    isAfter(scheduledDate, parsedDate)
  )
}
export const scheduledTo = (patient: any, value: any) => {
  const scheduledDate = patient.nextScheduledVisitDateTime
  const parsedDate = parseISO(value)

  return (
    !scheduledDate ||
    !value ||
    isSameDay(scheduledDate, parsedDate) ||
    isBefore(scheduledDate, parsedDate)
  )
}
export const ntvdFrom = (patient: any, value: any) => {
  const targetedDate = patient.nextTargetedVisitDate
  const parsedDate = parseISO(value)
  return (
    !targetedDate ||
    !value ||
    isSameDay(targetedDate, parsedDate) ||
    isAfter(targetedDate, parsedDate)
  )
}
export const ntvdTo = (patient: any, value: any) => {
  const targetedDate = patient.nextTargetedVisitDate
  const parsedDate = parseISO(value)
  return (
    !targetedDate ||
    !value ||
    isSameDay(targetedDate, parsedDate) ||
    isBefore(targetedDate, parsedDate)
  )
}
export const isGreen = (patient: any) =>
  !isCaremore(patient) && patient.score === '3'
export const isYellow = (patient: any) =>
  (isCaremore(patient) && patient.score === 3) ||
  (!isCaremore(patient) && patient.score === '2')
export const isOrange = (patient: any) =>
  isCaremore(patient) && patient.score === 2
export const isRed = (patient: any) =>
  (isCaremore(patient) && patient.score === 1) ||
  (!isCaremore(patient) && patient.score === '1')
export const noScore = (patient: any) =>
  (isCaremore(patient) && (patient.score === null || patient.score === 0)) ||
  (!isCaremore(patient) && (patient.score === null || patient.score === '0'))
export const wasCalledToday = (patient: any) =>
  isSameDay(patient.lastCallDate, new Date())
export const isCallable = (patient: any) =>
  patient.callable && patient.subStatus !== UNENGAGED
export const tasksReminders = (patient: any) =>
  patient.callStatus === 'Tasks / Reminders'
export const needsCall = (patient: any) => patient.callStatus === 'Needs Call'
export const needsCallTomorrow = (patient: any) =>
  patient.callStatus === 'Needs Call Tomorrow'
export const notDueForCall = (patient: any) =>
  patient.callStatus === 'Not Due For Call'
export const inHospital = (patient: any) => patient.subStatus === HOSPITAL
export const inSNF = (patient: any) => patient.subStatus === SNF
export const inLTC = (patient: any) => patient.subStatus === LTC
export const hasOpenTOCTasks = (patient: any) => patient.hasOpenTOCTasks
export const rideAlongPriority = (patient: any) =>
  patient.hotspotter ||
  patient.hospiceEligible ||
  patient.inWatchlist ||
  (patient.prognosis || '').toLowerCase().includes('weeks')
export const hasNTVD = (patient: any) => Boolean(patient.nextTargetedVisitDate)
export const alwaysTrue = () => true
export const pcPlus = (patient: any) => patient.program === PC_PLUS
export const careMoreHealthNetwork = (patient: any) =>
  patient.program === CAREMORE_HEALTH_NETWORK
export const careMoreAtHome = (patient: any) =>
  patient.program === CAREMORE_AT_HOME
export const highRiskComplexCare = (patient: any) =>
  patient.program === HIGH_RISK_COMPLEX_CARE
export const fivePercentTierThree = (patient: any) =>
  patient.program === TIER_THREE
export const fivePercentTierTwo = (patient: any) => patient.program === TIER_TWO
export const fivePercentTierOne = (patient: any) => patient.program === TIER_ONE
export const telephonic = (patient: any) => patient.program === TELEPHONIC
export const hbpcHybrid = (patient: any) => patient.program === HBPC_HYBRID
export const homeBasedPalliativeCare = (patient: any) =>
  patient.program === HOME_BASED_PALLIATIVE_CARE
export const commercialPalliativeCare = (patient: any) =>
  patient.program === COMMERCIAL_PALLIATIVE_CARE
const isCaremore = (patient: any) =>
  [CAREMORE_AT_HOME, CAREMORE_HEALTH_NETWORK, PC_PLUS].includes(patient.program)
