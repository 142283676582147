import { isEmpty } from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Subtitle1 } from '~/components/CustomTypography'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import {
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { UNENGAGED_REASONS } from './constants'
import { getRequestProcessingValue } from './data/common/requestProcessing'
import {
  createUnengaged,
  createUnengagedDialogClosed,
  getDialogOpenValue,
} from './data/createUnengagedDialog'

const styles = ({ palette, spacing }) => ({
  lighterText: {
    color: palette.grey[500],
    marginLeft: spacing(1.5),
  },
  textField: {
    marginTop: 20,
    '& div': {
      lineHeight: '1.4em',
      padding: [12, spacing(2)],
    },
  },
})

class CreateUnengagedDialog extends React.Component {
  state = { notes: '', reason: '' }

  resetState = () => this.setState({ notes: '', reason: '' })

  closeDialog = () => {
    this.resetState()
    this.props.onClose()
  }

  createNotes = event => this.setState({ notes: event.target.value })
  pickReason = event => this.setState({ reason: event.target.value })

  createUnengagedRecord = () => {
    const { patientId, createUnengaged } = this.props
    const { notes, reason } = this.state

    createUnengaged(patientId, notes, reason)
  }

  render() {
    const { classes, open, requestIsProcessing } = this.props
    const requiredFieldsEmpty = isEmpty(this.state.notes)

    const { reason } = this.state

    return (
      <ActionDialog
        disableAction={requiredFieldsEmpty || requestIsProcessing}
        fullWidth
        maxWidth="sm"
        mode="create"
        onClose={this.closeDialog}
        onCreate={this.createUnengagedRecord}
        onExited={this.resetState}
        open={open}
        title="Create New Unengaged Record"
      >
        <Subtitle1>
          Unengaged Start Date:
          <span className={classes.lighterText}>
            {moment().format('M-DD-YY')}
          </span>
        </Subtitle1>
        <InputLabel htmlFor="unengaged-reason" shrink>
          Choose an Unengaged Reason
        </InputLabel>
        <Select
          classes={{ root: classes.selectRoot }}
          className={classes.select}
          input={<Input name="reason" id="unengaged-reason" />}
          fullWidth
          name="unengaged-reason"
          onChange={this.pickReason}
          required
          value={reason}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {UNENGAGED_REASONS.map((reason, index) => (
            <MenuItem key={index} value={reason}>
              {reason}
            </MenuItem>
          ))}
        </Select>
        <InputLabel shrink>Notes</InputLabel>
        <TextField
          className={classes.textField}
          fullWidth
          multiline
          onChange={this.createNotes}
          placeholder="Enter Notes (Required)"
          required
          rows="4"
          variant="outlined"
        />
      </ActionDialog>
    )
  }
}

CreateUnengagedDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patientId: PropTypes.string.isRequired,
  requestIsProcessing: PropTypes.bool.isRequired,
  createUnengaged: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  open: getDialogOpenValue(state),
  requestIsProcessing: getRequestProcessingValue(state),
})

const mapDispatchToProps = {
  onClose: createUnengagedDialogClosed,
  createUnengaged: createUnengaged.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CreateUnengagedDialog)
