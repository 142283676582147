import { combineEpics, ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import {
  map,
  mapTo,
  mergeMap,
  pluck,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators'
import { getUserId, getUserRole } from '~/data/session'
import {
  directSSORequested,
  fetchMdtSingleSignOn,
} from '../data/mdtSingleSignOn'
import {
  practiceSelected,
  selectorClosed,
  selectorOpened,
} from '../data/practiceSelection'

const requestSSO = (action$, state$) =>
  action$.pipe(
    ofType(practiceSelected),
    pluck('payload', 'practice'),
    withLatestFrom(state$),
    map(([practice, state]) => {
      const userId = getUserId(state)
      const role = getUserRole(state)
      const menu_bottom = role === 'System Admin' ? 'show' : 'hide_pat_logout'

      return fetchMdtSingleSignOn.requested({
        prescriberId: userId,
        menu: 'show',
        menu_bottom,
        page: null,
        header: 'full',
        state: practice,
      })
    })
  )

const closeSelectorOnSuccess = onSuccess$ =>
  onSuccess$.pipe(mapTo(selectorClosed()))

export const direct = (action$, state$) => {
  const fetchSucceeded$ = action$.pipe(ofType(fetchMdtSingleSignOn.SUCCEEDED))
  const selectorClosed$ = action$.pipe(ofType(selectorClosed))

  return action$.pipe(
    ofType(directSSORequested),
    mergeMap(() =>
      merge(
        of(selectorOpened()),
        requestSSO(action$, state$),
        closeSelectorOnSuccess(fetchSucceeded$)
      ).pipe(takeUntil(selectorClosed$))
    )
  )
}

export default combineEpics(direct)
