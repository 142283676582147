import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field } from 'redux-form/immutable'
import { Checkbox, TextField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { fetchCsmdUrl } from '../../../data/csmdAttestation'
import { getPatientState } from '../../../data/patientInformation'
import { checkRequired, required } from '../../../utils/formValidators'
import FormFieldWrapper from './FormFieldWrapper'

const CSMD_ATTESTATION_CHECK = 'csmdAttestationCheck'
const validateCSMDCheck = checkRequired(CSMD_ATTESTATION_CHECK)

const styles = ({ spacing }) => ({
  formSpan: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    top: spacing(1),
    right: spacing(2),
    '&:hover': {
      top: spacing(1),
      left: spacing(1) * -2,
    },
  },
  fullWidth: {
    width: '100%',
  },
})

class CsmdAttestation extends React.PureComponent {
  state = { hasBeenClicked: false }

  onClick = () => {
    const { hasBeenClicked } = this.state
    const { csmdRequest, patientState } = this.props

    !hasBeenClicked &&
      this.setState({ hasBeenClicked: true }, () => csmdRequest(patientState))
  }

  render() {
    const { isRequired, classes } = this.props
    return (
      isRequired && (
        <FormFieldWrapper
          title="CSMD Attestation - Required"
          isCollapsed={false}
        >
          <span className={classes.formSpan}>
            <Field
              component={Checkbox}
              className={classes.checkbox}
              name={CSMD_ATTESTATION_CHECK}
              validate={[required]}
              onChange={this.onClick}
            />
            <Field
              component={TextField}
              className={classes.fullWidth}
              label="CSMD ATTESTATION - INTERNAL USE ONLY"
              name="csmdAttesttationText"
              validate={[validateCSMDCheck]}
              disabled={true}
            />
          </span>
        </FormFieldWrapper>
      )
    )
  }
}

CsmdAttestation.propTypes = {
  classes: PropTypes.object.isRequired,
  csmdRequest: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  patientState: PropTypes.string.isRequired,
}

CsmdAttestation.defaultProps = {
  isRequired: false,
}

const mapStateToProps = state => ({
  patientState: getPatientState(state),
})

const mapDispatchToProps = {
  csmdRequest: fetchCsmdUrl.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CsmdAttestation)
