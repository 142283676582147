import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const GROUPS = 'groups'

export const Group = Record({
  id: null,
  name: null,
  phone: null,
  fax: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  ceoAdministrator: null,
  nursingLeader: null,
  coo: null,
  networkLeader: null,
  champion: null,
  ipaId: null,
  ipa: null,
  notes: null,
  nextTargetedContactDate: null,
})

const transformGroup = ({ ipa, ...group }) =>
  Group({
    ...group,
    ipaId: getIn(ipa, 'id'),
    ipa: getIn(ipa, 'name'),
  })

export const fetchGroups = Request({
  typePrefix: key,
  typeBase: 'FETCH_GROUPS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/groups`, {
      params: { searchWord },
    }),
  transform: into(Group, 'id'),
  messages: { failed: 'There was a problem fetching groups' },
})

export const fetchGroup = Request({
  typePrefix: key,
  typeBase: 'FETCH_GROUP',
  requestParams: ['groupId'],
  operation: groupId => AspireAPI.get(`contacts/groups/${groupId}`),
  transform: transformGroup,
  messages: { failed: 'There was a problem fetching this group' },
})

export const addGroup = Request({
  typePrefix: key,
  typeBase: 'ADD_GROUP',
  requestParams: ['group'],
  operation: group => AspireAPI.post('contacts/groups', { group }),
  messages: {
    succeeded: 'Successfully added new group',
    failed: 'There was a problem adding this group',
  },
})

export const saveGroup = Request({
  typePrefix: key,
  typeBase: 'SAVE_GROUP',
  requestParams: ['group'],
  operation: group => AspireAPI.put(`contacts/groups/${group.id}`, { group }),
  messages: {
    succeeded: 'Successfully saved group',
    failed: 'There was a problem saving this group',
  },
})

export const deleteGroup = Request({
  typePrefix: key,
  typeBase: 'DELETE_GROUP',
  requestParams: ['groupId'],
  operation: groupId => AspireAPI.delete(`contacts/groups/${groupId}`),
  messages: {
    succeeded: 'Successfully deleted group',
    failed: 'There was a problem deleting this group',
  },
})

const creator = scopedCreator(key)
export const groupsCleared = creator('GROUPS_CLEARED')

const initState = Map()
export default createReducer(GROUPS, initState, {
  [fetchGroups.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchGroup.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [saveGroup.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { group },
        },
      },
    }
  ) => state.set(group.id, group),
  [deleteGroup.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { groupId },
        },
      },
    }
  ) => state.delete(groupId),
  [groupsCleared]: () => initState,
})

export const getGroups = pipe(getRoot, get(GROUPS))

export const getGroupsArr = createSelector([getGroups], groups =>
  groups.toIndexedSeq().toArray()
)

export const getGroupById = (state, id) => getGroups(state).get(id, Group())
