import { Map } from 'immutable'
import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import {
  getPatientPharmacyId,
  preferredPharmacyNeeded,
} from '../data/patientInformation'
import {
  rxMedicationSelected,
  rxMedicationSet,
} from '../data/selectedMedication'

export default (action$, state$) =>
  action$.pipe(
    ofType(rxMedicationSelected),
    pluck('payload', 'selectedMedication'),
    withLatestFrom(state$),
    map(([selectedMedication, state]) => {
      const hasPatientPreferredPharmacy = Boolean(getPatientPharmacyId(state))

      return hasPatientPreferredPharmacy
        ? rxMedicationSet(Map(selectedMedication))
        : preferredPharmacyNeeded()
    })
  )
