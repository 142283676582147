import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Checkbox } from '@material-ui/core'
import {
  getPendingSelectedAPPs,
  selectedAPPToggled,
} from '../../data/pendingSelectedAPPs'

const RowActions = ({ row }) => {
  const pendingSelectedAPPs = useSelector(getPendingSelectedAPPs)
  const toggleSelectedAPP = useAction(selectedAPPToggled)

  const [checked, setChecked] = useState(false)

  const onChange = (_e, checked) => {
    setChecked(checked)
    toggleSelectedAPP(row.id)
  }

  useEffect(() => {
    setChecked(pendingSelectedAPPs.includes(row.id))
  }, [row, pendingSelectedAPPs, setChecked])

  return <Checkbox checked={checked} onChange={onChange} />
}

RowActions.propTypes = {
  row: PropTypes.record.isRequired,
}

export default RowActions
