import { useCallback, useState } from 'react'

export default (initValue = false) => {
  const [value, setValue] = useState(initValue)

  const setValueCallback = useCallback(
    event => {
      setValue(event.target.checked)
    },
    [setValue]
  )

  const values: [boolean, (e: any) => void] = [value, setValueCallback]

  return values
}
