import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import PropTypes from '~/utils/propTypes'
import { Paper, makeStyles } from '@material-ui/core'
import { getPatientInfo } from '../../data/patientInformation'
import {
  addDefaults,
  prescribedIntegratedSortingColumnExtensions,
  prescribedMedListColumns,
} from '../../utils/common'
import { importStatusMap } from '../../utils/medListConstants'
import FilterChipsCM from './FilterChipsCM'
import MedDetail from './MedDetail'
import PrescribedActions from './PrescribedActions'

// @ts-expect-error Migrate import to typescript

// @ts-expect-error Migrate import to typescript

const useStyles = makeStyles(({ spacing }) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: spacing(1),
    alignItems: 'center',
  },
  whiteSpace: {
    '& td': {
      whiteSpace: 'normal',
    },
  },
}))

const PrescribedMedListGrid = ({ medList, initialFilterState, disabled }) => {
  const classes = useStyles()
  const [filters, setFilters] = useState(initialFilterState)
  const patientInfo = useSelector(getPatientInfo)

  const additionalGridOptions = {
    rowActions: ({ ...props }) => prescribedActions({ ...props }),
    rowActionsCount: 2,
  }

  const filteredPatientRxArray = useMemo(
    () =>
      medList
        .filter(x => filters[importStatusMap[x.importStatus]])
        .map(addDefaults),
    [medList, filters]
  )

  return (
    <React.Fragment>
      <span className={classes.flexContainer}>
        <FilterChipsCM
          medList={medList}
          setFilters={setFilters}
          filters={filters}
          isCaseMgmt={patientInfo.isCaseMgmt}
        />
      </span>
      <Paper>
        <DevXGrid
          {...{
            ...additionalGridOptions,
          }}
          className={classes.whiteSpace}
          disabled={disabled}
          rows={filteredPatientRxArray}
          columns={prescribedMedListColumns}
          columnExtensions={prescribedIntegratedSortingColumnExtensions}
          getRowId={row => row.id}
          searchable
          sortable
          groupable
          detailComponent={MedDetail}
          searchPlaceholder="Search Med List..."
          hideColumnsWhenGrouped
          pageable
          defaultPageSize={15}
          pageSizes={[15, 25, 50]}
          collapsible
          columnHideable
          indentColumnWidth={18}
          defaultHiddenColumnNames={['importStatus', 'importDate']}
          localSettingsKey="patientPrescribedMedList"
        />
      </Paper>
    </React.Fragment>
  )
}

const prescribedActions = props => {
  return <PrescribedActions {...props} />
}

PrescribedMedListGrid.propTypes = {
  disabled: PropTypes.bool,
  initialFilterState: PropTypes.object,
  medList: PropTypes.array,
}

PrescribedMedListGrid.defaultProps = {
  disabled: false,
  initialFilterState: {
    'Not Reviewed': true,
    Deferred: false,
  },
  medList: {},
}

export default PrescribedMedListGrid
