// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { emptyFilter, isEmpty } from './filters'
import { getReferralManagement } from './root'

// CONSTANTS
export const ACTIVE_FILTER_SET = 'activeFilter/ACTIVE_FILTER_SET'
export const ACTIVE_FILTER_SET_REQUESTED =
  'activeFilter/ACTIVE_FILTER_SET_REQUESTED'
export const ACTIVE_FILTER_OPTION_ID_TOGGLED =
  'activeFilter/ACTIVE_FILTER_OPTION_ID_TOGGLED'
export const ACTIVE_FILTER_CLEAR_REQUESTED =
  'activeFilter/ACTIVE_FILTER_CLEAR_REQUESTED'
export const ACTIVE_FILTER_CLEARED = 'activeFilter/ACTIVE_FILTER_CLEARED'
export const ACTIVE_FILTER_SAVE_REQUESTED =
  'activeFilter/ACTIVE_FILTER_SAVE_REQUESTED'
export const ACTIVE_FILTER_RESET_REQUESTED =
  'activeFilter/ACTIVE_FILTER_RESET_REQUESTED'
export const ACTIVE_FILTER_RESET = 'activeFilter/ACTIVE_FILTER_RESET'
export const ACTIVE_FILTER_MAKE_DEFAULT_REQUESTED =
  'activeFilter/ACTIVE_FILTER_MAKE_DEFAULT_REQUESTED'

// ACTION CREATORS
export const activeFilterSet = (filter: any) => ({
  type: ACTIVE_FILTER_SET,
  filter,
})
export const activeFilterSetRequested = (filter: any) => ({
  type: ACTIVE_FILTER_SET_REQUESTED,
  filter,
})
export const activeFilterOptionIdToggled = (
  option: any,
  id: any,
  value: any
) => ({
  type: ACTIVE_FILTER_OPTION_ID_TOGGLED,
  option,
  id,
  value,
})
export const activeFilterClearRequested = () => ({
  type: ACTIVE_FILTER_CLEAR_REQUESTED,
})
export const activeFilterCleared = () => ({ type: ACTIVE_FILTER_CLEARED })
export const activeFilterSaveRequested = () => ({
  type: ACTIVE_FILTER_SAVE_REQUESTED,
})
export const activeFilterMakeDefaultRequested = () => ({
  type: ACTIVE_FILTER_MAKE_DEFAULT_REQUESTED,
})
export const activeFilterResetRequested = () => ({
  type: ACTIVE_FILTER_RESET_REQUESTED,
})
export const activeFilterReset = () => ({ type: ACTIVE_FILTER_RESET })

// KEY
export const key = 'activeFilter'

const toggleOption = (set: any, id: any) =>
  set.has(id) ? set.delete(id) : set.add(id)
const toggleToValue = (set: any, id: any, value: any) =>
  value ? set.add(id) : set.delete(id)

// REDUCER
export default createReducer(key, emptyFilter, {
  [ACTIVE_FILTER_SET]: (_state: any, { filter }: any) => filter,
  [ACTIVE_FILTER_CLEARED]: () => emptyFilter,
  [ACTIVE_FILTER_OPTION_ID_TOGGLED]: (state: any, { option, id, value }: any) =>
    value === undefined
      ? state.update(option, (o: any) => toggleOption(o, id))
      : state.update(option, (o: any) => toggleToValue(o, id, value)),
})

// SELECTORS
export const getActiveFilter = (state: any) =>
  getReferralManagement(state).get(key)
export const isActiveFilterEmpty = (state: any) =>
  isEmpty(getActiveFilter(state))
