// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import entity from './data/entity'
import form from './data/form'
import formList from './data/formList'
import templateKey from './data/templateKey'
import key from './key'

const reducer = combineReducers({
  [entity.key]: entity,
  [form.key]: form,
  [formList.key]: formList,
  [templateKey.key]: templateKey,
})

reducer.key = key

export default reducer
