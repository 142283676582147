import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { createSnfDialogOpened } from '~/features/snfs'
import { useAction, useToggle } from '~/hooks'
import { Typography } from '@material-ui/core'
import { updateSNFSubstatus } from '../data/common/shared'
import { getSNFPatient, snfPatientCleared } from '../data/snfPatient'

const REFERRED = 'Referred'

const SNFActions = () => {
  const patient = useSelector(getSNFPatient)
  const onClearSNFPatient = useAction(snfPatientCleared)

  const openSNFDialog = useAction(createSnfDialogOpened)
  const updateSNFSubstatusRequested = useAction(updateSNFSubstatus.requested)

  const [open, toggleOpen, toggleClosed] = useToggle()

  const onUpdateSubstatus = () => {
    updateSNFSubstatusRequested(patient.id)
    toggleClosed()
  }

  const onClose = () => {
    toggleClosed()
    onClearSNFPatient()
  }

  useEffect(() => {
    if (patient) {
      if (patient.status === REFERRED) toggleOpen()
      else openSNFDialog(patient.id)
    }
  }, [patient, toggleOpen, openSNFDialog])

  return patient ? (
    <ActionDialog
      open={open}
      title="Confirm SNF Substatus"
      mode="confirm"
      onConfirm={onUpdateSubstatus}
      onClose={onClose}
    >
      <Typography>{`Are you sure you want to set this referred patient's substatus to SNF?`}</Typography>
    </ActionDialog>
  ) : null
}

export default SNFActions
