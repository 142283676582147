import { Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { formatUSPhone } from '../utils/transformers'
import { medAssessmentSet } from './assessment'
import { getMedications, handleMedMessage } from './root'

const key = 'patientInformation'
const creator = scopedCreator(key)

export const patientIdProvided = creator('PATIENT_ID_PROVIDED', ['id'])
export const preferredPharmacyNeeded = creator(
  'PREFERRED_PHARMACY_NEEDED',
  false,
  () => ({
    message: "Please set patient's preferred pharmacy before adding medication",
    request: {
      step: 'failed',
    },
  })
)

/* PRESCRIBING LOCATION */
const PRESCRIBING_LOCATION = 'prescribingLocation'
const PrescribingLocation = Record({
  address1: null,
  address2: null,
  city: null,
  fax: null,
  id: null,
  name: null,
  phone: null,
  state: null,
  zip: null,
})

const fetchPatientPrescribingLocationApi = ({ patientId, prescriberId }) =>
  AspireAPI.get('medications/locations', {
    params: { patient_id: patientId, prescriber_id: prescriberId },
  })

export const fetchPatientPrescribingLocation = Request({
  typePrefix: key,
  typeBase: 'FETCH_PRESCRIBING_LOCATION',
  requestParams: ['params'],
  operation: fetchPatientPrescribingLocationApi,
  transform: data => ({ [PRESCRIBING_LOCATION]: PrescribingLocation(data[0]) }),
  messages: {
    failed: handleMedMessage(
      "There was a problem fetching prescriber's prescribing location"
    ),
  },
})

/* PRESCRIBER */
const PRESCRIBER = 'prescriber'
const Prescriber = Record({
  active: null,
  address1: null,
  city: null,
  controlledPrivileges: null,
  current: null,
  dea: null,
  email: null,
  epcsSetupDate: null,
  fax: null,
  firstName: null,
  id: null,
  lastName: null,
  middleName: null,
  name: null,
  npi: null,
  phone: null,
  preferredLocationId: null,
  role: null,
  rxPrivileges: null,
  startDate: null,
  state: null,
  stateLicenseNumber: null,
  suffix: null,
  userId: null,
  userName: null,
  zip: null,
})

const fetchPrescriberApi = prescriberId =>
  AspireAPI.get(`medications/prescribers/${prescriberId}`)

export const fetchPrescriber = Request({
  typePrefix: key,
  typeBase: 'FETCH_PRESCRIBER',
  requestParams: ['prescriberId'],
  operation: fetchPrescriberApi,
  transform: data => ({ [PRESCRIBER]: Prescriber(data) }),
  messages: {
    failed: handleMedMessage('There was a problem fetching prescriber'),
  },
})

const fetchPatientPrescriberApi = patient_id =>
  AspireAPI.get('medications/prescribers', {
    params: { patient_id },
  })

export const fetchPatientPrescriber = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_PRESCRIBER',
  requestParams: ['patientId'],
  operation: fetchPatientPrescriberApi,
  transform: data => ({ [PRESCRIBER]: Prescriber(data[0]) }),
  messages: {
    failed: handleMedMessage('There was a problem fetching patient prescriber'),
  },
})

/* PREFERRED PHARMACY */
const PREFERRED_PHARMACY = 'preferredPharmacy'
const PreferredPharmacy = Record({
  address1: null,
  address2: null,
  city: null,
  fax: null,
  id: null,
  name: null,
  ncpdpId: null,
  phone: null,
  state: null,
  zip: null,
  type: null,
})

const fetchPatientPreferredPharmacyApi = patient_id =>
  AspireAPI.get('medications/preferred_pharmacies', {
    params: { patient_id },
  })

export const fetchPatientPreferredPharmacy = Request({
  typePrefix: key,
  typeBase: 'FETCH_PREFERRED_PHARMACY',
  requestParams: ['patientId'],
  operation: fetchPatientPreferredPharmacyApi,
  transform: data => ({ [PREFERRED_PHARMACY]: PreferredPharmacy(data) }),
  messages: {
    failed: handleMedMessage(
      'There was a problem fetching patient preferred pharmacy'
    ),
  },
})

const savePatientPreferredPharmacyApi = preferredPharmacy =>
  AspireAPI.post('medications/preferred_pharmacies/', preferredPharmacy)

export const savePatientPreferredPharmacy = Request({
  typePrefix: key,
  typeBase: 'SAVE_PREFERRED_PHARMACY',
  requestParams: [PREFERRED_PHARMACY],
  operation: savePatientPreferredPharmacyApi,
  messages: {
    failed: handleMedMessage(
      'There was a problem saving patient preferred pharmacy'
    ),
    succeeded: 'Patient Preferred Pharmacy Set',
  },
})

/* DEFUALT STATE */
const PatientInformation = Record({
  id: null,
  prescribingLocation: PrescribingLocation(),
  prescriber: Prescriber(),
  preferredPharmacy: PreferredPharmacy(),
})

const toPatientInformation = patientInformation =>
  PatientInformation({
    id: patientInformation.id,
    prescribingLocation: PrescribingLocation(
      patientInformation.prescribingLocation
    ),
    prescriber: Prescriber(patientInformation.prescriber),
    preferredPharmacy: PreferredPharmacy(patientInformation.preferredPharmacy),
  })

export default createReducer(key, PatientInformation(), {
  [patientIdProvided]: (state, { payload }) => state.set('id', payload.id),
  [fetchPrescriber.SUCCEEDED]: (state, { payload }) => state.merge(payload),
  [fetchPatientPrescriber.SUCCEEDED]: (state, { payload }) =>
    state.merge(payload),
  [fetchPatientPrescribingLocation.SUCCEEDED]: (state, { payload }) =>
    state.merge(payload),
  [fetchPatientPreferredPharmacy.SUCCEEDED]: (state, { payload }) =>
    state.merge(payload),
  [savePatientPreferredPharmacy.SUCCEEDED]: (state, { meta }) =>
    state.merge({
      preferredPharmacy: PreferredPharmacy(
        meta.request.payload.preferredPharmacy
      ),
    }),
  [medAssessmentSet]: (state, { payload }) =>
    toPatientInformation(payload.patientInformation),
})

/* SELECTORS */
export const getPatientInfo = pipe(getMedications, get(key))

export const getPatientId = pipe(getPatientInfo, get('id'))

export const getPatientPrescriber = pipe(getPatientInfo, get(PRESCRIBER))

export const getPrescriberName = pipe(
  getPatientPrescriber,
  p => p.name || `${p.firstName} ${p.lastName}`
)

export const getPrescriberId = pipe(getPatientPrescriber, get('id'))

export const getPatientPrescribingLocation = pipe(
  getPatientInfo,
  get(PRESCRIBING_LOCATION)
)

export const getPrescribingLocationId = pipe(
  getPatientPrescribingLocation,
  get('id')
)

export const getPrescribingLocationName = pipe(
  getPatientPrescribingLocation,
  get('name')
)

export const getPatientState = pipe(getPatientPrescribingLocation, get('state'))

export const getPatientPharmacy = pipe(getPatientInfo, get(PREFERRED_PHARMACY))

export const getPatientPharmacyObject = createSelector(
  [getPatientPharmacy],
  pharmacy => pharmacy.toJS()
)

export const getPatientPharmacyArray = createSelector(
  [getPatientPharmacyObject],
  pharmacy => [pharmacy]
)

export const getPatientPharmacyId = pipe(
  getPatientPharmacy,
  p => p && p.ncpdpId
)

export const getPharmacyInfo = pipe(
  getPatientPharmacy,
  p =>
    p &&
    [p.name, p.address1, p.address2, p.city, p.state, formatUSPhone(p.phone)]
      .filter(Boolean)
      .join(', ')
)
