import { Lazy } from 'fp-ts'
import { KeycloakPromise } from 'keycloak-js'

// NOTE: these types are a guess.
export interface User {
  id: string
  name: string
}
// TODO: This is a terrible interface and is a prime example of why OOP (even with types) is garbage.
// All these void methods tell us nothing about the implementation when in reality these types should be
// rich in describing the functionality.
export interface KeycloakProvider {
  onLogin: (listener: Lazy<any>) => void
  onLogout: (listener: Lazy<any>) => void
  init(): Promise<[boolean, unknown] | void>
  login(): void
  logout(): KeycloakPromise<void, void> | void
  isTokenExpired(minValidaty?: number): boolean | never
  refreshToken(minValidaty?: number): Promise<string | undefined | void>
  user: Promise<User | undefined>
  token: Promise<string | undefined>
  expiresAt: Date | null
}
