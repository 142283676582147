import classnames from 'classnames'
import { isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'redux-routable-react'
import { PATIENT_RECORD_PATIENT_INFO } from '~/apps/patientRecord/router'
import { Line } from '~/components/text'
import { getPatientInfo as getPatientInfoMain } from '~/features/patientInfo'
import {
  COMMERCIAL_PALLIATIVE_CARE,
  HIGH_RISK_COMPLEX_CARE,
} from '~/utils/programInfo'
import { makeStyles } from '@material-ui/core/styles'
import { formatTimeZone } from '../../../../utils/format'
import { getPatientInfo } from '../../data/patientInfo'

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/co... Remove this comment to see the full error message

const N_A = 'N/A'
const NEVER_ACTIVE = 'Never Active'
const NEW_STATUSES = [N_A, NEVER_ACTIVE]
const EMPLOYER_GROUP_NAME_PROGRAMS = [
  COMMERCIAL_PALLIATIVE_CARE,
  HIGH_RISK_COMPLEX_CARE,
]

const useStyles = makeStyles(({ palette }) => ({
  link: {
    color: palette.secondary.main,
  },
  important: {
    color: palette.severity.warning.static,
  },
}))

const Demographics = () => {
  const classes = useStyles()
  const patientInfo = useSelector(getPatientInfo)
  const patientInfoMain = useSelector(getPatientInfoMain)
  const { demographics, information } = patientInfoMain

  const vbidCoverages = patientInfoMain.coverages
    .filter(c => c.vbid !== null)
    .filter(c => c.priority === 1)
    .filter(c => c.vbid.isVbid)

  const isVbidEligible =
    vbidCoverages.size > 0 && patientInfo.insurance === 'Humana'

  return (
    <React.Fragment>
      <Line contentFlex={2} label="Name">
        {patientInfo.name && (
          <Link
            className={classes.link}
            route={PATIENT_RECORD_PATIENT_INFO}
            params={{ patientId: patientInfo.id }}
            action="open"
          >
            {patientInfo.name}
          </Link>
        )}
      </Line>
      <Line contentFlex={2} label="Contact ID">
        {patientInfo.contactID}
      </Line>
      <Line contentFlex={2} label="Status">
        {[
          patientInfo.status,
          patientInfo.subStatus,
          patientInfo.internalSubstatus,
        ]
          .filter(Boolean)
          .join(' / ')}
      </Line>
      {patientInfo.previousStatus !== N_A && (
        <Line contentFlex={2} label="Previous Status">
          {patientInfo.previousStatus}
        </Line>
      )}
      {!NEW_STATUSES.includes(patientInfo.previousStatus) && (
        <React.Fragment>
          <Line contentFlex={2} label="Days Since Last Assessment">
            {patientInfo.daysSinceLastAssessment}
          </Line>
          <Line contentFlex={2} label="Last Assessment Date">
            {patientInfo.lastAssessmentDate}
          </Line>
        </React.Fragment>
      )}
      <Line contentFlex={2} label="Program Enrolled">
        {patientInfo.programEnrolled}
      </Line>
      <Line contentFlex={2} label="Insurance">
        {patientInfo.insurance}
      </Line>
      <Line contentFlex={2} label="Insurance Number">
        {patientInfo.insuranceNumber}
      </Line>
      {isVbidEligible && (
        <Line contentFlex={2} label="VBID">
          Eligible
        </Line>
      )}
      <Line contentFlex={2} label="Copay">
        <span
          className={classnames({ [classes.important]: patientInfo.copay })}
        >
          {patientInfo.copay ? 'Yes' : 'No'}
        </span>
      </Line>
      <Line contentFlex={2} label="DOB">
        {patientInfo.dob}
      </Line>
      <Line contentFlex={2} label="Ethnicity">
        {demographics.ethnicityLabel}
      </Line>
      {!isEmpty(demographics.ethnicityExtra) && (
        <Line contentFlex={2} label="Ethnicity Expansion">
          {demographics.ethnicityExtraLabel}
        </Line>
      )}
      <Line contentFlex={2} label="Race">
        {demographics.raceLabel}
      </Line>
      <Line contentFlex={2} label="Sex">
        {demographics.sexLabel}
      </Line>
      <Line contentFlex={2} label="Gender Identity">
        {demographics.genderIdentityLabel}
      </Line>
      {!isEmpty(demographics.genderIdentityOther) && (
        <Line contentFlex={2} label="Gender Identity Other">
          {demographics.genderIdentityOther}
        </Line>
      )}
      <Line contentFlex={2} label="Spoken Language">
        {demographics.languageLabel}
      </Line>
      <Line contentFlex={2} label="Written Language">
        {information.writtenLanguageLabel}
      </Line>
      <Line contentFlex={2} label="Marital Status">
        {patientInfo.maritalStatus}
      </Line>
      <Line contentFlex={2} label="Address">
        {patientInfo.location.address}
      </Line>
      <Line contentFlex={2} label="Location Type">
        {patientInfo.locationType}
      </Line>
      <Line contentFlex={2} label="Phone">
        {patientInfo.phoneFormatted || 'Missing Preferred Phone'}
      </Line>
      <Line contentFlex={2} label="Email">
        {patientInfo.email || 'Missing Preferred Email'}
      </Line>
      <Line contentFlex={2} label="Referral Type">
        {patientInfo.referralType}
      </Line>
      <Line contentFlex={2} label="Referring Physician">
        {patientInfo.referringPhysician}
      </Line>
      <Line contentFlex={2} label="Referring Entity">
        {patientInfo.referringEntity}
      </Line>
      <Line contentFlex={2} label="Mailing Date">
        {patientInfo.mailingDate}
      </Line>
      <Line contentFlex={2} label="Mailing Type">
        {patientInfo.mailingType}
      </Line>
      <Line contentFlex={2} label="Time Zone">
        {formatTimeZone(demographics.timeZone)}
      </Line>
      <Line contentFlex={2} label="Primary Reason for Referral">
        {patientInfo.primaryDiagnosis}
      </Line>
      <Line contentFlex={2} label="Secondary Reason for Referral">
        {patientInfo.secondaryDiagnosis}
      </Line>
      <Line contentFlex={2} label="Referral Facilitator">
        {patientInfo.referralFacilitator}
      </Line>
      <Line contentFlex={2} label="PCP">
        {patientInfo.primaryCarePhysician}
      </Line>
      <Line contentFlex={2} label="PCP Phone">
        {patientInfo.pcpPhone}
      </Line>
      <Line contentFlex={2} label="Primary Channel">
        {patientInfo.primaryChannel}
      </Line>
      <Line contentFlex={2} label="Care Team APP">
        {patientInfo.appName}
      </Line>
      <Line contentFlex={2} label="Case Manager">
        {patientInfo.caseManagerName}
      </Line>
      <Line contentFlex={2} label="Market">
        {patientInfo.market}
      </Line>
      <Line contentFlex={2} label="Market Phone">
        {patientInfo.mktPhone}
      </Line>
      <Line contentFlex={2} label="Market Hotline Phone">
        {patientInfo.mktHotline}
      </Line>
      {EMPLOYER_GROUP_NAME_PROGRAMS.includes(patientInfo.programEnrolled) && (
        <Line contentFlex={2} label="Employer Group Name">
          {demographics.hpManagementLevel}
        </Line>
      )}
      <Line contentFlex={2} label="Health Plan Care Manager Name">
        {patientInfo.hpCareManagerName}
      </Line>
      <Line contentFlex={2} label="Health Plan Care Manager Email">
        {patientInfo.hpCareManagerEmail}
      </Line>
    </React.Fragment>
  )
}

export default Demographics
