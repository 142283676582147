// @ts-expect-error no export
import Request from '~/utils/Request'
import AspireAPI from '~/resources/aspire'
import { Map, Record } from 'immutable'

/**
 * Fetch the demographics options that will have the shape of fieldValues
 * but are actually stored in database tables
 */

const key = 'fieldValues'

// TODO: Move to a common types file just doing this
// to avoid a circular dependency when importing them from
// fieldValues
export const FieldValue = Record({
  value: null,
  label: null,
  rank: null,
})

const mapFieldValues = (fieldValues: any) =>
  Map(
    fieldValues.map(({ field, values }: any) => [
      field,
      Map(values.map(FieldValue).map((fv: any) => [fv.value, fv])).sortBy(
        (fv: any) => fv.rank
      ),
    ])
  )

/**
 * Request
 */

export const fetchDemographicsOptions = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  operation: () => AspireAPI.get('/v1/patientdemographics/options'),
  transform: mapFieldValues,
  messages: { failed: 'Could not fetch demographics options.' },
})
