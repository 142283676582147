import React from 'react'
import { Link } from 'redux-routable-react'

// These link components should be used when turning a Material UI button
// (`Button`, `IconButton`, etc.) into a link using the `component` prop. This
// is because the `action` prop of `ButtonBase` is already used.

export let PushLink = (props, ref) => (
  <Link {...props} ref={ref} action="push" />
)

PushLink = React.forwardRef(PushLink)

export let ReplaceLink = (props, ref) => (
  <Link {...props} ref={ref} action="replace" />
)

ReplaceLink = React.forwardRef(ReplaceLink)

export let OpenLink = (props, ref) => (
  <Link {...props} ref={ref} action="open" />
)

OpenLink = React.forwardRef(OpenLink)
