import React, { PureComponent } from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const styles = () => {}

class OrderNotes extends PureComponent {
  state = {
    actionNote: '',
  }
  appendNote = actionNote => {
    this.setState({ actionNote: actionNote })
  }
  render() {
    const { notes, onNoteChange } = this.props
    return (
      <TextField
        id="multiline-flexible"
        label="Internal Order Notes"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Notes"
        multiline
        rowsMax="25"
        value={this.state.actionNote ? notes && this.state.actionNote : notes}
        onChange={e => {
          onNoteChange(e.target.value)
        }}
        fullWidth
      />
    )
  }
}
OrderNotes.propTypes = {
  notes: PropTypes.string,
  onNoteChange: PropTypes.func,
}
export default withStyles(styles)(OrderNotes)
