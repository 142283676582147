import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { APP_DASHBOARD_TASK_DETAIL } from '~/apps/appDashboard/router'
import { CASE_MANAGER_DASHBOARD_TASK_DETAIL } from '~/apps/caseManagerDashboard/router'
import { CLINICAL_DIRECTOR_DASHBOARD_TASK_DETAIL } from '~/apps/clinicalDirectorDashboard/router'
import { CSS_DASHBOARD_TASK_DETAIL } from '~/apps/cssDashboard/router'
import { IOC_DASHBOARD_TASK_DETAIL } from '~/apps/iocDashboard/router'
import { NURSE_DASHBOARD_TASK_DETAIL } from '~/apps/nurseDashboard/router'
import { PES_DASHBOARD_TASK_DETAIL } from '~/apps/pesDashboard/router'
import { PHYSICIAN_DASHBOARD_TASK_DETAIL } from '~/apps/physicianDashboard/router'
import { REFERRAL_MANAGEMENT_TASK_DETAIL } from '~/apps/referralManagement/router'
import { SW_DASHBOARD_TASK_DETAIL } from '~/apps/swDashboard/router'
import { patientPanelOpened } from '~/data/patientPanel'
import { getRoute } from '~/data/route'
import { fetchTask } from '../data/tasks'

const TASK_DETAIL_ROUTES = [
  APP_DASHBOARD_TASK_DETAIL,
  CASE_MANAGER_DASHBOARD_TASK_DETAIL,
  CLINICAL_DIRECTOR_DASHBOARD_TASK_DETAIL,
  CSS_DASHBOARD_TASK_DETAIL,
  SW_DASHBOARD_TASK_DETAIL,
  IOC_DASHBOARD_TASK_DETAIL,
  NURSE_DASHBOARD_TASK_DETAIL,
  PES_DASHBOARD_TASK_DETAIL,
  PHYSICIAN_DASHBOARD_TASK_DETAIL,
  REFERRAL_MANAGEMENT_TASK_DETAIL,
]

export default (action$, state$) =>
  action$.pipe(
    ofType(fetchTask.SUCCEEDED),
    filter(() => TASK_DETAIL_ROUTES.includes(getRoute(state$.value))),
    pluck('payload', 'patientId'),
    filter(Boolean),
    map(patientPanelOpened)
  )
