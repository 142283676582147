import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, scopedCreator, type } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDateTimeWithTimeZone, formatMonth } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import { transformTripStatus } from '../utils'
import { getRoot } from './common/shared'
import { CURRENT_YEAR, EDITING, EDITSTATUS, FILES, RECUR } from './constants'
import { TripAddress } from './interfaces'
import { generateRecurTrip } from './recurPopup'

const key = 'patientTrips'
const TRIP_ACTION = 'tripAction'
const PREVIOUS_YEAR_TRIPS = 'previousYearTrips'
const NEXT_YEAR_TRIPS = 'nextYearTrips'
const TRIP_COUNT_BY_YEAR = 'tripCountByYear'
export const PATIENT_TRIPS_CLEARED = type(key, 'PATIENT_TRIPS_CLEARED')
export const patientTripsCleared: any = creator(PATIENT_TRIPS_CLEARED)

const actionCreator = scopedCreator(TRIP_ACTION)
export const recurTripAction = actionCreator('RECUR_TRIP')
export const fileTripAction = actionCreator('TRIP_FILE')
export const editTripAction = actionCreator('EDIT_TRIP')
export const editTripStatusAction = actionCreator('EDIT_TRIP_STATUS')
export const clearTripAction = actionCreator('CLEAR_TRIP')

export const TripAddressLine = Record({
  addressLine1: null,
  city: null,
  state: null,
  zip: null,
})

export const PatientTripDetail = Record({
  tripId: null,
  tripType: null,
  appointmentType: null,
  destinationType: null,
  statusId: null,
  status: null,
  caller: null,
  callerRelation: null,
  appointmentDate: null,
  eligibilityEffectiveDate: null,
  appointmentDateTime: null,
  appointmentTime: null,
  appointmentMonth: null,
  numberOfEscorts: null,
  vendorName: null,
  vendorStatus: null,
  walkerCane: null,
  stairs: null,
  pickUpTime: null,
  pickUpType: null,
  distance: null,
  countable: null,
  ccc: null,
  dys: null,
  lcb: null,
  flu: null,
  oneway: null,
  createdBy: null,
  updatedBy: null,
  phone: null,
  spokeTo: null,
  createdDateTime: null,
  lastUpdatedDateTime: null,
  apptDtTime: null,
  pickUpAddress: TripAddressLine,
  destAddress: TripAddressLine,
  pickupAddress1: null,
  pickupCity: null,
  pickupState: null,
  pickupZip: null,
  pickupPhone: null,
  pickupExt: null,
  pickupName: null,
  destinationAddress1: null,
  destinationCity: null,
  destinationState: null,
  destinationZip: null,
  destinationPhone: null,
  destinationExt: null,
  destnName: null,
  isAppointmentTimeEmpty: null,
})

const displayAddress = (address: TripAddress) => {
  let displayTripAddress = ''

  if (address?.addressLine1) {
    displayTripAddress = `${displayTripAddress}${address?.addressLine1}, `
  }
  if (address?.addressLine2) {
    displayTripAddress = `${displayTripAddress}${address?.addressLine2}, `
  }
  return `${displayTripAddress}${address?.city}, ${address?.state}, ${address?.zip}`
}

export const transformPickupTripAddress = (tripAddress: any) => {
  return TripAddressLine({
    addressLine1: tripAddress?.pickupAddress1,
    city: tripAddress?.pickupCity,
    state: tripAddress?.pickupState,
    zip: tripAddress?.pickupZip,
  })
}
export const transformTripDestAddress = (tripAddress: any) => {
  return TripAddressLine({
    addressLine1: tripAddress?.destinationAddress1,
    city: tripAddress?.destinationCity,
    state: tripAddress?.destinationState,
    zip: tripAddress?.destinationZip,
  })
}

export const transformApptDateTimeDisplay = (trip: any) =>
  trip?.empty_appt_time ? trip?.appt_date.split(' ')[0] : trip?.appt_date

export const transformApptTimeDisplay = (trip: any) =>
  !trip?.empty_appt_time
    ? `${trip?.appt_date.split(' ')[1]} ${trip?.appt_date.split(' ')[2]}`
    : ''
export const transformCountable = (
  limited: any,
  configured_destinations: any,
  unlimited_locations: any,
  trip: any
) => {
  let count = ''
  if (limited.includes('ONEWAY')) {
    unlimited_locations.includes(trip?.pickup_type_code?.toString()) ||
    unlimited_locations.includes(trip?.destination_type_code?.toString()) ||
    (!limited.includes(trip?.pickup_type_code?.toString()) &&
      configured_destinations.includes(trip?.pickup_type_code?.toString())) ||
    (!limited.includes(trip?.destination_type_code?.toString()) &&
      configured_destinations.includes(trip?.destination_type_code?.toString()))
      ? (count = 'No')
      : (count = trip?.is_countable ? 'Yes' : 'No')
  } else if (
    !limited.includes('ONEWAY') &&
    configured_destinations.includes('ONEWAY')
  ) {
    limited.includes(trip?.pickup_type_code?.toString()) ||
    limited.includes(trip?.destination_type_code?.toString())
      ? (count = trip?.is_countable ? 'Yes' : 'No')
      : (count = 'No')
  } else {
    limited.includes(trip?.pickup_type_code?.toString()) ||
    limited.includes(trip?.destination_type_code?.toString())
      ? (count = trip?.is_countable ? 'Yes' : 'No')
      : (count = 'No')
  }
  return count
}

export const transformPatientTrips = (response: any) => {
  const trips = response.trips
  const configured_destinations: [string] = response.configuredDestinations
  const unlimited_locations: [string] = response.unlimitedLocations
  const limited: [string] = response.limited
  return Array.isArray(trips)
    ? List(
        trips.map(trip => {
          return PatientTripDetail({
            tripId: trip?.id,
            tripType: trip?.trip_type,
            appointmentType: trip?.appt_type,
            destinationType: trip?.destination_type,
            statusId: trip?.status_id,
            status: transformTripStatus(
              trip?.status_id,
              trip?.trip_status,
              trip?.is_countable
            ),
            caller: trip?.contact,
            appointmentDateTime: transformApptDateTimeDisplay(trip),
            appointmentDate: trip?.appt_date
              ? trip?.appt_date.split(' ')[0]
              : '',
            appointmentTime: transformApptTimeDisplay(trip),
            appointmentMonth: formatMonth(trip?.appt_date),
            eligibilityEffectiveDate: trip?.eligibility_effective_date,
            callerRelation: trip?.contact_relation_type,
            isAppointmentTimeEmpty: trip?.empty_appt_time,
            createdDateTime: formatDateTimeWithTimeZone(trip?.created_at),
            numberOfEscorts: trip?.nbr_of_escorts,
            vendorName: trip?.vendor_name,
            vendorStatus: trip?.vendor_status,
            walkerCane: trip?.walker_or_cane ? 'Yes' : 'No',
            stairs: trip?.stairs ? 'Yes' : 'No',
            pickUpTime: trip?.pickup_time,
            pickUpType: trip?.pickup_type,
            distance: trip?.distance,
            countable: transformCountable(
              limited,
              configured_destinations,
              unlimited_locations,
              trip
            ),
            ccc:
              (trip?.pickup_code === 'CCC' ||
                trip?.destination_code === 'CCC') &&
              configured_destinations.includes('CCC')
                ? 'Yes'
                : '',
            dys:
              (trip?.pickup_code == 'DYS' || trip?.destination_code == 'DYS') &&
              configured_destinations.includes('DYS')
                ? 'Yes'
                : '',
            lcb:
              (trip?.pickup_code == 'LCB' || trip?.destination_code == 'LCB') &&
              configured_destinations.includes('LCB')
                ? 'Yes'
                : '',
            flu:
              (trip?.pickup_code == 'FLU' || trip?.destination_code == 'FLU') &&
              configured_destinations.includes('FLU')
                ? 'Yes'
                : '',
            oneway:
              unlimited_locations.includes(
                trip?.pickup_type_code?.toString()
              ) ||
              unlimited_locations.includes(
                trip?.destination_type_code?.toString()
              ) ||
              ((trip?.pickup_code == 'DYS' ||
                trip?.destination_code == 'DYS') &&
                configured_destinations.includes('DYS')) ||
              ((trip?.pickup_code === 'CCC' ||
                trip?.destination_code === 'CCC') &&
                configured_destinations.includes('CCC')) ||
              ((trip?.pickup_code == 'LCB' ||
                trip?.destination_code == 'LCB') &&
                configured_destinations.includes('LCB')) ||
              ((trip?.pickup_code == 'FLU' ||
                trip?.destination_code == 'FLU') &&
                configured_destinations.includes('FLU'))
                ? ''
                : 'Yes',

            phone: trip?.mobile_nbr,
            spokeTo: trip?.spoke_to,
            createdBy: trip?.created_by,
            updatedBy: trip?.modified_by ? trip?.modified_by : '',
            lastUpdatedDateTime: formatDateTimeWithTimeZone(trip?.modified_at),
            pickUpAddress: displayAddress(
              //   @ts-expect-error ts-migrate(7016) FIXME: Argument of type 'Map<string, any>' is not assignable to parameter of type 'TripAddress'.
              transformPickupTripAddress(trip?.pickup_address)
            ),
            pickupName: trip?.pickup_name,
            pickupAddress1: trip?.pickup_address?.pickupAddress1,
            pickupCity: trip?.pickup_address?.pickupCity,
            pickupState: trip?.pickup_address?.pickupState,
            pickupZip: trip?.pickup_address?.pickupZip,
            pickupPhone: trip?.pickup_address?.pickupPhone,
            pickupExt: trip?.pickup_address?.pickupExt,
            destnName: trip?.destn_name,
            destinationAddress1: trip?.destination_address?.destinationAddress1,
            destinationCity: trip?.destination_address?.destinationCity,
            destinationState: trip?.destination_address?.destinationState,
            destinationZip: trip?.destination_address?.destinationZip,
            destinationPhone: trip?.destination_address?.destinationPhone,
            destinationExt: trip?.destination_address?.destinationExt,
            destAddress: displayAddress(
              //   @ts-expect-error ts-migrate(7016) FIXME: Argument of type 'Map<string, any>' is not assignable to parameter of type 'TripAddress'.
              transformTripDestAddress(trip?.destination_address)
            ),
          })
        })
      )
    : List()
}

export const fetchPatientTrips = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_TRIPS',
  requestParams: ['id', 'effectiveDate', 'classplanProductId', 'countyName'],
  operation: (
    id: string,
    effectiveDate: string,
    classplanProductId: string,
    countyName: string
  ) =>
    AspireAPI.get(
      `transportation/trips/${id}?effectiveDate=${effectiveDate}&benefitYear=${CURRENT_YEAR}&classplanProductId=${classplanProductId}&countyName=${countyName}`
    ),
  transform: transformPatientTrips,
  messages: {
    failed: 'There was an issue fetching the patient trips',
  },
})

export const fetchPreviousYearPatientTrips = Request({
  typePrefix: key,
  typeBase: 'FETCH_PREVIOUS_YEAR_PATIENT_TRIPS',
  requestParams: ['id', 'classplanproductid', 'productdescription'],
  operation: (
    id: string,
    classplanproductid: string,
    productdescription: string
  ) =>
    AspireAPI.get(
      `transportation/previous_year_trips/${id}?benefitYear=${
        CURRENT_YEAR - 1
      }&classplanproductid=${classplanproductid}&productdescription=${productdescription}`
    ),
  transform: transformPatientTrips,
  messages: {
    failed: 'There was an issue fetching previous year patient trips',
  },
})

export const fetchNextYearPatientTrips = Request({
  typePrefix: key,
  typeBase: 'FETCH_NEXT_YEAR_PATIENT_TRIPS',
  requestParams: ['id', 'effectiveDate', 'classplanProductId', 'countyName'],
  operation: (
    id: string,
    effectiveDate: string,
    classplanProductId: string,
    countyName: string
  ) =>
    AspireAPI.get(
      `transportation/trips/${id}?effectiveDate=${effectiveDate}&benefitYear=${
        CURRENT_YEAR + 1
      }&classplanProductId=${classplanProductId}&countyName=${countyName}`
    ),
  transform: transformPatientTrips,
  messages: {
    failed: 'There was an issue fetching next year patient trips',
  },
})

export const fetchPatientTripCounts = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_TRIPS_COUNT',
  requestParams: ['patientId', 'benefitYear'],
  operation: (patientId: string, benefitYear: string) =>
    AspireAPI.get(
      `transportation/trips/count_by_year/${patientId}?benefitYear=${benefitYear}`
    ),
  messages: {
    failed: 'There was an issue fetching the patient trip count',
  },
})

export const trips = createReducer(key, List(), {
  [fetchPatientTrips.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [patientTripsCleared]: () => List(),
})

export const previousYearTrips = createReducer(PREVIOUS_YEAR_TRIPS, List(), {
  [fetchPreviousYearPatientTrips.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  [patientTripsCleared]: () => List(),
})

export const nextYearTrips = createReducer(NEXT_YEAR_TRIPS, List(), {
  [fetchNextYearPatientTrips.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  [patientTripsCleared]: () => List(),
})

export const patientTripCountByBenefitYear = createReducer(
  TRIP_COUNT_BY_YEAR,
  0,
  {
    [fetchPatientTripCounts.SUCCEEDED]: (_state: any, { payload }: any) =>
      payload,
  }
)

export const tripActionData = {
  dialogState: null,
  tripInfo: null,
}

export const tripAction = createReducer(TRIP_ACTION, tripActionData, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [recurTripAction]: (_state: any, { payload }: any) => {
    return { dialogState: RECUR, tripInfo: payload }
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [fileTripAction]: (_state: any, { payload }: any) => {
    return { dialogState: FILES, tripInfo: payload }
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [editTripAction]: (_state: any, { payload }: any) => {
    return { dialogState: EDITING, tripInfo: payload }
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [editTripStatusAction]: (_state: any, { payload }: any) => {
    return { dialogState: EDITSTATUS, tripInfo: payload }
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearTripAction]: () => tripActionData,
  [generateRecurTrip.success]: () => tripActionData,
})

export const getPatientTrips = pipe(getRoot, get(key))
export const getTripAction = pipe(getRoot, get(TRIP_ACTION))
export const getPreviousYearPatientTrips = pipe(
  getRoot,
  get(PREVIOUS_YEAR_TRIPS)
)
export const getNextYearPatientTrips = pipe(getRoot, get(NEXT_YEAR_TRIPS))
export const getPatientTripCountByBenefitYear = pipe(
  getRoot,
  get(TRIP_COUNT_BY_YEAR)
)
