import { ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import {
  NEW_ORDER,
  NEW_ORDER_DIALOG_OPENED,
  dialogOpened,
} from '../data/dialog'
import { fetchOrderTypes } from '../data/orderTypes'

export default action$ =>
  action$.pipe(
    ofType(NEW_ORDER_DIALOG_OPENED),
    pluck('payload', 'patientId'),
    filter(Boolean),
    mergeMap(() =>
      concat(of(dialogOpened(NEW_ORDER)), of(fetchOrderTypes.requested()))
    )
  )
