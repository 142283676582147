import React from 'react'
import { Divider, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: spacing(3),
  },
  label: {
    flex: ({ labelSpace }: any) => (labelSpace ? labelSpace : '1'),
  },
  content: {
    marginLeft: spacing(1),
    flex: ({ contentFlex }: any) => contentFlex || 5,
  },
}))

type Props = {
  label: string
  labelSpace?: string
  children: React.ReactNode
  contentFlex?: number
  hideIfBlank?: boolean
}

const Line = (props: Props) => {
  const classes = useStyles(props)

  const { label, children, hideIfBlank } = props

  const shouldRender = Boolean(!hideIfBlank || children)

  return shouldRender ? (
    <React.Fragment>
      <div className={classes.root}>
        <Typography className={classes.label} variant="caption">
          {label}
        </Typography>
        <Typography className={classes.content} variant="body2">
          {children}
        </Typography>
      </div>
      <Divider />
    </React.Fragment>
  ) : null
}

export default Line
