import { ROUTE_CHANGED } from 'redux-routable'
import { createSelector } from 'reselect'
import { getRoute } from '~/data/route'
import {
  ORDER_SELECTED as ORDERS_PANEL_OPENED,
  getSelectedOrder,
} from '~/features/orders'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'

const key = 'panel'
const PATIENT = 'patient'
const PATIENT_OUTLIER = 'patientOutlier'
const ORDERS = 'orders'
const PATIENT_TRANSPORTATION = 'patientTransportation'
const CARE_PLAN_MANAGER = 'careplanManager'

const creator = scopedCreator(key)
export const patientPanelOpened = creator('PATIENT_PANEL_OPENED', ['patientId'])
export const toggleDashPanel = creator('PATIENT_PANEL_TOGGLED')
export const patientOutlierPanelOpened = creator(
  'PATIENT_OUTLIER_PANEL_OPENED',
  ['patientId', 'reviewId']
)
export const patientTransportationPanelOpened = creator(
  'PATIENT_TRANSPORTATION_PANEL_OPENED',
  ['id']
)
export const careplanManagerPanelOpened = creator(
  'CARE_PLAN_MANAGER_PANEL_OPENED',
  ['data']
)
export const panelClosed = creator('PANEL_CLOSED', false)

// Reducer
export default createReducer(key, null, {
  [toggleDashPanel]: (_state, { payload }) => payload,
  [patientPanelOpened]: () => PATIENT,
  [panelClosed]: () => null,
  [ROUTE_CHANGED]: () => null,
  [patientOutlierPanelOpened]: () => PATIENT_OUTLIER,
  [careplanManagerPanelOpened]: () => CARE_PLAN_MANAGER,
  [patientTransportationPanelOpened]: () => PATIENT_TRANSPORTATION,
  [ORDERS_PANEL_OPENED]: (state, { payload: { id } }) => (id ? ORDERS : state),
})

// Selector
const isDashPanelAvailable = get(key)
export const panelAvailable = createSelector(
  [getRoute, getSelectedOrder, isDashPanelAvailable],
  (route, orderPanel, dashPanel) => {
    const isOrdersPage = ['ordersIndex', 'orderDetail'].some(endpoint =>
      route.includes(endpoint)
    )

    return (isOrdersPage && !!orderPanel) || (!isOrdersPage && !!dashPanel)
  }
)
