import { Map } from 'immutable'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { rxStatusMap } from '../../utils/medListConstants'
import DrugAlertDialog from './DrugAlertDialog'
import {
  AdherenceField,
  CsmdAttestation,
  DrugAlertFields,
  IndicationFields,
  MedInfoFields,
  PatientInfoFields,
  PrimaryRxFields,
  RxInfoFields,
} from './fields'

const styles = ({ spacing }) => ({
  wrapper: {
    padding: spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
})

class RxWriter extends React.PureComponent {
  state = {
    drugAlertInfo: null,
  }

  clearDrugAlert = () =>
    this.setState({
      drugAlertInfo: null,
    })

  selectDrugAlert = alert =>
    this.setState({
      drugAlertInfo: alert,
    })
  get isSchedule2() {
    return this.props.selectedMedication.get('schedule') == '2'
  }
  get isEditing() {
    return Boolean(this.props.selectedMedication.get('isEditing'))
  }
  get isRefilling() {
    return Boolean(this.props.selectedMedication.get('isRefilling'))
  }
  get isPending() {
    return rxStatusMap.getIn([this.props.rxStatusValue, 'status']) === 'Pending'
  }
  get hasStatus() {
    return Boolean(this.props.rxStatusValue)
  }

  render() {
    const {
      classes,
      drugAlerts,
      showDrugAlerts,
      selectedMedication,
    } = this.props

    const { drugAlertInfo } = this.state

    return (
      <div className={classes.wrapper}>
        <form>
          <MedInfoFields isExistingMed={this.isEditing || this.isRefilling} />
          <PatientInfoFields pendingStatus={this.isPending} />
          <PrimaryRxFields isEditing={this.isEditing} />
          <AdherenceField
            isEditing={this.isEditing}
            selectedMedication={selectedMedication}
          />
          <IndicationFields hasStatus={this.hasStatus} />
          <RxInfoFields
            isRequired={this.isPending}
            refillsDisabled={this.isSchedule2}
          />
          <DrugAlertFields
            isRequired={this.isPending}
            isToggled={showDrugAlerts}
            drugAlerts={drugAlerts}
            onClick={this.selectDrugAlert}
          />
          <CsmdAttestation isRequired={this.isSchedule2 && this.isPending} />
        </form>
        <DrugAlertDialog
          displayedAlert={drugAlertInfo}
          onClick={this.clearDrugAlert}
        />
      </div>
    )
  }
}

RxWriter.propTypes = {
  classes: PropTypes.object.isRequired,
  drugAlerts: PropTypes.list,
  rxStatusValue: PropTypes.string,
  selectedMedication: PropTypes.map,
  showDrugAlerts: PropTypes.bool.isRequired,
}

RxWriter.defaultProps = {
  selectedMedication: Map(),
}

export default withStyles(styles)(RxWriter)
