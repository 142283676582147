import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { downloadMedicationList } from '../data/assessments'

export default action$ =>
  action$.pipe(
    ofType(downloadMedicationList.SUCCEEDED),
    tap(({ meta, payload }) => {
      const url = window.URL.createObjectURL(payload)
      const filename = `medication_list_for_${meta.request.payload.patientName
        .split(' ')
        .join('_')}.pdf`
      const link = document.createElement('a')

      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.click()

      window.URL.revokeObjectURL(url)
    }),
    ignoreElements()
  )
