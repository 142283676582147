import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import DevXGrid from '~/components/DevXGrid'
import { formatAddress } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getSuggestedStakeholders } from '../../data/suggestedStakeholders'

const styles = ({ spacing }) => ({
  limitMessage: {
    textAlign: 'right',
    margin: [spacing(1), 0],
  },
})

const columns = [
  { name: 'name', title: 'Contact Name' },
  { name: 'phone', title: 'Business Phone' },
  {
    name: 'fullAddress',
    title: 'Primary Address',
    getCellValue: formatAddress,
  },
]

const StakeholderGrid = ({ classes, suggestedStakeholders, onSelect }) => (
  <React.Fragment>
    <DevXGrid
      rows={suggestedStakeholders}
      columns={columns}
      selectable
      selectSingleRow
      onSelect={onSelect}
      sortable
      pageable
      defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
    />

    {suggestedStakeholders.length === 100 && (
      <Typography className={classes.limitMessage}>
        Showing first 100 results. Narrow search.
      </Typography>
    )}
  </React.Fragment>
)

StakeholderGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestedStakeholders: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default compose(
  connect(state => ({
    suggestedStakeholders: getSuggestedStakeholders(state),
  })),
  withStyles(styles)
)(StakeholderGrid)
