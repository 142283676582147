import { List } from 'immutable'
import moment from 'moment'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { LOCAL_ASSESSMENT, getItem } from '~/utils/storage'
import {
  Addendum,
  Assessment,
  assessmentFetched,
  currentAssessmentFetched,
  fetchAssessment,
} from '../data/assessments'

export default action$ =>
  action$.pipe(
    ofType(fetchAssessment.SUCCEEDED),
    mergeMap(({ payload, meta }) => {
      const serverAssessment = payload
      const key = `${LOCAL_ASSESSMENT}-${serverAssessment.id}`

      return from(getItem(key)).pipe(
        map(storedAssessment =>
          storedAssessment
            ? Assessment(storedAssessment)
                .set('local', true)
                .update('addendums', a => List(a).map(Addendum))
            : null
        ),
        map(localAssessment => {
          if (localAssessment && localAssessment.modifiedAt) {
            const localModifiedAt = moment(localAssessment.modifiedAt)
            const serverModifiedAt = moment(serverAssessment.modifiedAt)

            return localModifiedAt.isAfter(serverModifiedAt)
              ? localAssessment
              : serverAssessment
          } else {
            return serverAssessment
          }
        }),
        map(assessment =>
          meta.request.payload.current
            ? currentAssessmentFetched(assessment)
            : assessmentFetched(assessment)
        )
      )
    })
  )
