import { combineEpics } from 'redux-observable'
import calendarInitialized from './epics/calendarInitialized'
import currentEventCleared from './epics/currentEventCleared'
import defaultEncounterType from './epics/defaultEncounterType'
import encounterTypes from './epics/encounterTypes'
import eventsFetchRequested from './epics/eventsFetchRequested'
import patientSearchChanged from './epics/patientSearchChanged'

export default combineEpics(
  calendarInitialized,
  currentEventCleared,
  defaultEncounterType,
  encounterTypes,
  eventsFetchRequested,
  patientSearchChanged
)
