import moment from 'moment'
import React from 'react'
import { DATE_PICKER_DATETIME_FORMAT } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import TextField from './TextField'

const inputLabelProps = { shrink: true }

const transformOut = (value, timeZone) => {
  if (!value) {
    return null
  }

  return timeZone ? moment.tz(value).utc() : moment(value).utc().format()
}

class DateTimeField extends React.Component {
  onBlur = value => this.props.onBlur(transformOut(value, this.props.timeZone))

  onChange = value => {
    let newValue

    if (value) {
      const year = value.slice(0, 4)
      const monthDayTime = value.substring(value.length - 12, value.length)
      newValue = `${year}${monthDayTime}`
    } else {
      newValue = moment().format(DATE_PICKER_DATETIME_FORMAT)
    }

    return this.props.onChange(newValue)
  }

  render = () => {
    const { onBlur, onChange } = this.props

    return (
      <TextField
        {...this.props}
        type="datetime-local"
        InputLabelProps={inputLabelProps}
        onBlur={onBlur && this.onBlur}
        onChange={onChange && this.onChange}
      />
    )
  }
}

DateTimeField.propTypes = {
  value: PropTypes.string,
  timeZone: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default DateTimeField
