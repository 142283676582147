import { OrderedMap, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, actionCreator, get, into, payload } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'

// Key
const PHYSICIANS = 'physicians'

// Record
export const Physician = Record({
  fax: null,
  id: null,
  mailingCity: null,
  mailingPostalCode: null,
  mailingStateCode: null,
  mailingStreet: null,
  name: null,
  phone: null,
  practiceName: null,
  specialty: null,
})

// Action
export const physiciansReset = actionCreator(`${PHYSICIANS}/RESET`)

// Request
export const searchPhysicians = Request({
  typePrefix: PHYSICIANS,
  typeBase: 'SEARCH',
  requestParams: ['searchText'],
  operation: searchText =>
    AspireAPI.get('v1/physicians', { params: { searchText } }),
  transform: into(Physician, 'id', OrderedMap),
  messages: {
    failed: 'Could not fetch physicians.',
  },
})

// Reducer
export default createReducer(PHYSICIANS, OrderedMap(), {
  [physiciansReset]: () => OrderedMap(),
  [searchPhysicians.SUCCEEDED]: compose(payload, action),
})

// Selector
export const getPhysicians = get(PHYSICIANS)

// transformer can be any of the `to*` methods on an Immutable OrderedMap
export const getPhysiciansTransformed = transformer =>
  createSelector(getPhysicians, physicians => physicians[transformer]())
