import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
// @ts-expect-error no export
import { getPatientId } from '~/features/patientInfo'
import {
  cancelSearch,
  fetchImoSessionId,
  fetchSuggestedImoProblems,
} from '../data/imo'
import { getImoSearchCriteria } from '../data/utils/selectors'

export const getImoProblemSuggestions = (action$: any, state$: any) =>
  action$.pipe(
    ofType(fetchImoSessionId.SUCCEEDED),
    pluck('payload'),
    withLatestFrom(state$),
    map(([payload, state]: any) => {
      const patientId = getPatientId(state)
      const { searchTerm, filterByAge, filterBySex } = getImoSearchCriteria(
        state
      )

      return searchTerm
        ? fetchSuggestedImoProblems.requested(
            patientId,
            payload.sessionId,
            searchTerm,
            filterBySex,
            filterByAge
          )
        : cancelSearch(state)
    })
  )
