import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchPatientDischarges } from '~/features/discharges/data'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_HISTORY_DISCHARGES } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_HISTORY_DISCHARGES)),
    switchTo(state$),
    map(getPatientId),
    map(fetchPatientDischarges.requested)
  )
