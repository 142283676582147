import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, ignoreElements, tap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import {
  PES_DASHBOARD_PATIENT_FILTERS,
  removeItem,
  setPermanentItem,
} from '~/utils/storage'
import {
  filterToggled,
  filtersCleared,
  getFilterValues,
  getSelectedFilters,
  valueUpdated,
} from '../data/patientFilters'

const saveFiltersEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(valueUpdated, filterToggled),
    debounceTime(500),
    switchTo(state$),
    tap(state => {
      // @ts-expect-error state is unknown
      const values = getFilterValues(state).toJS()
      // @ts-expect-error state is unknown
      const selected = getSelectedFilters(state).toJS()

      setPermanentItem(PES_DASHBOARD_PATIENT_FILTERS, {
        values,
        selected,
      })
    }),
    ignoreElements()
  )

const clearFiltersEpic = (action$: any) =>
  action$.pipe(
    ofType(filtersCleared),
    tap(() => {
      removeItem(PES_DASHBOARD_PATIENT_FILTERS)
    }),
    ignoreElements()
  )

export default combineEpics(saveFiltersEpic, clearFiltersEpic)
