import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, mergeMapTo, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  attemptToDeleteSelectedPolygon,
  deleteSelectedPolygon,
  deletedSelectedPolygon,
  selectedPolygonCleared,
} from '../data/selectedPolygon'
import showError from '../utils/showError'

const draftPolygonDeleted = action$ =>
  action$.pipe(
    ofType(attemptToDeleteSelectedPolygon),
    pluck('payload', 'npId'),
    mergeMap(npId =>
      loading(
        from(deleteSelectedPolygon(npId)).pipe(
          mergeMapTo(
            of(deletedSelectedPolygon(npId), selectedPolygonCleared())
          ),
          catchError(showError('Failed to delete NP.'))
        )
      )
    )
  )

export default draftPolygonDeleted
