import { colors as muiColors } from '@material-ui/core'

export const colors = {
  text: 'white',
  background: muiColors.purple['A700'],
}

export const label = 'Visit Placeholder' // 'APP Visit Placeholder'

export const layer = 2

export const title = event =>
  event.encounterTypeLabel ? event.encounterTypeLabel : label

export default { colors, label, layer, title }
