import { combineEpics } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap, pluck } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { fetchAssessment } from '../data/assessments'
import { fetchLegacyAssessment } from '../data/legacyAssessments'
import { ASSESSMENT_FORM, ASSESSMENT_LEGACY } from '../router'

const loadAssessment = action$ =>
  action$.pipe(
    filter(changedTo(ASSESSMENT_FORM)),
    pluck('payload', 'params', 'assessmentId'),
    mergeMap(id =>
      of(
        fetchAssessment.requested(id, true),
        fetchDistinctFieldValues(['assessment_cancellation_reason'])
      )
    )
  )

const loadLegacyAssessment = action$ =>
  action$.pipe(
    filter(changedTo(ASSESSMENT_LEGACY)),
    pluck('payload', 'params', 'legacyAssessmentId'),
    map(id => fetchLegacyAssessment.requested(id, true))
  )

export default combineEpics(loadAssessment, loadLegacyAssessment)
