import { combineEpics } from 'redux-observable'
import { default as appChanged } from './epics/appChanged'
import { default as patientChanged } from './epics/patientChanged'
import { default as savePatientAddressSucceeded } from './epics/savePatientAddressSucceeded'

export default combineEpics(
  appChanged,
  patientChanged,
  savePatientAddressSucceeded
)
