import { ERROR } from '~/data/messages'
import { get, messageCreator, scopedCreator } from '~/utils/data'
import key from '../../key'

export const SELECTED_APPS_KEY = 'selectedAPPs'
export const APP_KEY = 'referral_scheduling'

const creator = scopedCreator(key)
export const patientSelected = creator('PATIENT_SELECTED', [
  'patientId',
  'appId',
])
export const patientCleared = creator('PATIENT_CLEARED', false)
export const callLogSnackbarOpened = messageCreator(
  `${key}/CALL_LOG_SNACKBAR_OPEN`,
  'A call is currently being logged for this patient',
  ERROR
)

export const getRoot = get(key)
