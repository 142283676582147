import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'

const OUTCOMES = 'outcomes'

export const Outcome = Record({
  id: null,
  label: null,
  status: null,
  goals: null,
  createdBy: null,
  modifiedBy: null,
  createdAt: null,
  modifiedAt: null,
})

const transformOutcomes = (outcomes: any) => {
  return List(
    outcomes.map((outcome: any) => {
      return Outcome({
        id: outcome?.id,
        label: outcome?.label,
        status: outcome?.status ? 'Active' : 'Deactivated',
        goals: outcome?.goals,
        createdBy: outcome?.createdBy,
        modifiedBy: outcome?.modifiedBy,
        createdAt: formatDate(outcome?.createdAt),
        modifiedAt: formatDate(outcome?.modifiedAt),
      })
    })
  )
}

export const fetchOutcomes = Request({
  typePrefix: rootKey,
  typeBase: 'OUTCOME',
  operation: () => AspireAPI.get('admin_tools/outcomes'),
  transform: transformOutcomes,
  messages: { failed: 'There was an issue fetching outcomes' },
})

export default createReducer(OUTCOMES, List(), {
  [fetchOutcomes.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getOutcomes = pipe(getRoot, get(OUTCOMES))
