import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import AutoComplete from '~/components/AutoComplete'
import { Checkbox } from '~/components/fields'
import { getMarkets } from '~/data/markets'
import { isRequestPending } from '~/data/pending'
import { getUserById } from '~/data/users'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import {
  getPatientSearchResults,
  patientSearchChanged,
  patientSearchCleared,
} from '../../../data/eventTypes'
import { fetchPatientInfo, patientCleared } from '../../../data/events'
import BaseField from '../BaseField'
import InputAdornment from './InputAdornment'

const useStyles = makeStyles(({ spacing }) => ({
  field: {
    width: '100%',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(0.5),
  },
}))

const PatientSearchField = props => {
  const { event, editing } = props

  const classes = useStyles()

  const markets = useSelector(getMarkets)
  const owner = useSelector(state => getUserById(state, event.ownerId))
  const patients = useSelector(getPatientSearchResults)
  const pending = useSelector(state =>
    isRequestPending(state, fetchPatientInfo)
  )
  const clearPatient = useAction(patientCleared)
  const clearPatientSearch = useAction(patientSearchCleared)
  const changePatientSearch = useAction(patientSearchChanged)
  const fetchPatientInfoRequested = useAction(fetchPatientInfo.requested)

  const [appFilter, setAPPFilter] = useState(true)
  const [marketFilter, setMarketFilter] = useState(true)
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (event.patientName) setQuery(event.patientName)
  }, [event.patientName, setQuery])

  const filteredPatients = useMemo(
    () =>
      patients
        .toList()
        .map(patient => ({
          label: patientLabel(
            patient.name,
            patient.contactId,
            patient.status,
            patient.market
          ),
          value: patient.id,
        }))
        .toJS(),
    [patients]
  )

  function patientLabel(name, contactId, status, market) {
    const parsed = Number.parseInt(query)

    if (Number.isInteger(parsed)) {
      return `${name} - ID: ${contactId} (${status}, ${market})`
    } else {
      return `${name} (${status}, ${market})`
    }
  }

  const onSelectPatient = ({ suggestion: { value } }) => {
    fetchPatientInfoRequested(value, event.ownerId)
  }

  const onChange = value => {
    setQuery(value)

    if (!owner.id || markets.isEmpty()) return

    const marketName = markets.getIn([owner.marketId, 'name'])
    const appId = appFilter ? owner.id : null
    const market = marketFilter ? marketName : null

    if (value) changePatientSearch(value, appId, market)
  }

  const onBlur = () => {
    clearPatientSearch()

    if (!pending) setQuery(event.patientName || '')
  }

  const onClearPatient = () => {
    clearPatient()
    setQuery('')
  }

  return (
    <BaseField {...props} label="Patient">
      <div className={classes.field}>
        <div className={classes.search}>
          <AutoComplete
            disabled={!editing}
            value={query}
            onChange={onChange}
            onBlur={onBlur}
            availableSuggestions={filteredPatients}
            onSelectValue={onSelectPatient}
            inputAdornment={
              <InputAdornment
                editing={editing}
                event={event}
                onClear={onClearPatient}
                onFilter={() => setOptionsOpen(prevValue => !prevValue)}
              />
            }
          />
        </div>

        {optionsOpen && (
          <div>
            <Checkbox
              label="Filter on current user"
              disabled={!editing}
              onChange={value => setAPPFilter(value)}
              value={appFilter}
            />

            <Checkbox
              label={"Filter on current user's market"}
              disabled={!editing}
              onChange={value => setMarketFilter(value)}
              value={marketFilter}
            />
          </div>
        )}
      </div>
    </BaseField>
  )
}

PatientSearchField.propTypes = {
  event: PropTypes.record.isRequired,
  editing: PropTypes.bool,
}

export default PatientSearchField
