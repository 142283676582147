// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import dischargeCreationDialog from './data/dischargeCreationDialog'
import dischargeUpdateDialog from './data/dischargeUpdateDialog'
import discharges from './data/discharges'
import recommendations from './data/recommendationCreationDialog'
import recommendationUpdate from './data/recommendationUpdateDialog'
import reinstatementDialog from './data/reinstatementDialog'
import validityCheck from './data/validityCheck'
import key from './key'

const reducer = combineReducers({
  [dischargeCreationDialog.key]: dischargeCreationDialog,
  [dischargeUpdateDialog.key]: dischargeUpdateDialog,
  [discharges.key]: discharges,
  [recommendationUpdate.key]: recommendationUpdate,
  [recommendations.key]: recommendations,
  [reinstatementDialog.key]: reinstatementDialog,
  [validityCheck.key]: validityCheck,
})

reducer.key = key

export default reducer
