import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import {
  fetchProblemInformation,
  fetchSecondaryICD10Information,
  fetchSecondaryProblemTypes,
  updateCategoriesStatus,
  updateSecondaryCategoriesStatus,
  updateSecondaryCategory,
  updateSecondaryDiagnosisStatus,
  fetchProblemTypes,
} from '../data/imo'

export const updateProblemCategoriesStatus = (action$: any, _state$: any) =>
  action$.pipe(
    ofType(fetchProblemInformation.SUCCEEDED),
    pluck('payload'),
    map(([payload]: any) => updateCategoriesStatus(payload))
  )
export const updateSecondaryProblemCategoriesStatus = (
  action$: any,
  _state$: any
) =>
  action$.pipe(
    ofType(fetchSecondaryICD10Information.SUCCEEDED),
    pluck('payload'),
    map(([payload]: any) => updateSecondaryCategoriesStatus(payload))
  )
export const updateSecondaryCategories = (action$: any, _state$: any) =>
  action$.pipe(
    ofType(fetchSecondaryICD10Information.SUCCEEDED),
    pluck('payload'),
    map(([payload]: any) => updateSecondaryCategory(payload))
  )

export const updateSecondaryProblemDiagnosisStatus = (
  action$: any,
  _state$: any
) =>
  action$.pipe(
    ofType(fetchSecondaryProblemTypes.SUCCEEDED, fetchProblemTypes.SUCCEEDED),
    pluck('payload'),
    map(([payload]: any) => updateSecondaryDiagnosisStatus(payload))
  )
