import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash/fp'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

export const handleMessage = (fallback: any) => (res: any) => {
  const message = getIn(res, ['response', 'data', 'message'])
  return typeof message === 'string' ? message : fallback
}

export const MTM = Record({
  id: null,
  patientId: null,
  patientName: null,
  status: null,
  days: null,
  nextTargetedVisitDate: null,
  nextScheduledVisitDateTime: null,
  appId: null,
  appName: null,
  lastAttemptDate: null,
  numberOfAttempts: null,
  eligibilityDate: null,
})

export const isAtRisk = (m: any) => m.days >= 45

const key = 'mtm'

export const fetchMTM = Request({
  typePrefix: key,
  typeBase: 'FETCH_MTM',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('mtm', {
      params: { user_id: userId },
    }),
  transform: into(MTM, 'id'),
  messages: { failed: handleMessage('Could not get MTM Records') },
})

export default createReducer(key, Map(), {
  [fetchMTM.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getMTMRecords = pipe(getRoot, get(key))

export const getMTMCounts = pipe(getMTMRecords, (m: any) => ({
  atRisk: m.filter(isAtRisk).size,
  notAtRisk: m.filter((r: any) => !isAtRisk(r)).size,
  total: m.size,
}))
