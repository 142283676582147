import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

export const ACCESS_AUTHORIZATION = 'accessAuthorization'

export const fetchAccessAuthorization = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ACCESS_AUTHORIZATION',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`/v1/patient/${patientId}/verify_access`),
  messages: {
    failed: 'Unable to confirm access.',
  },
})

const initState = false
export default createReducer(ACCESS_AUTHORIZATION, initState, {
  [fetchAccessAuthorization.REQUESTED]: () => initState,
  [fetchAccessAuthorization.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getRoot = get(rootKey)

export const getAccessAuthorization = pipe(getRoot, get(ACCESS_AUTHORIZATION))
