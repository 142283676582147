import { ofType } from 'redux-observable'
import { exited } from 'redux-routable'
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import { isOnline } from '~/data/connectivity'
import { currentAssessmentFetched, saveAssessment } from '../data/assessments'
import { getCurrentAssessment } from '../data/common/derived'
import { SAVED_OFFLINE, getStatus } from '../data/saveInfo'
import { ASSESSMENT_FORM } from '../router'

const isSavedOffline = state => getStatus(state) === SAVED_OFFLINE

export default (action$, state$) =>
  action$.pipe(
    ofType(currentAssessmentFetched),
    switchMap(() =>
      state$.pipe(
        distinctUntilChanged((a, b) => isOnline(a) === isOnline(b)),
        filter(isOnline),
        filter(isSavedOffline),
        map(getCurrentAssessment),
        map(saveAssessment.requested),
        takeUntil(action$.pipe(filter(exited(ASSESSMENT_FORM))))
      )
    )
  )
