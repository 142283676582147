import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getEventsRoot } from './root'

const CREATING = 'creating'

const creator = scopedCreator(rootKey)
export const newEventOpened = creator('NEW_EVENT_OPENED', false)
export const newEventClosed = creator('NEW_EVENT_CLOSED', false)

export default createReducer(CREATING, false, {
  [newEventOpened]: () => true,
  [newEventClosed]: () => false,
})

export const getCreating = pipe(getEventsRoot, get(CREATING))
