import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Button } from '@material-ui/core'
import { getPendingSelectedAPPs } from '../../data/pendingSelectedAPPs'
import { getSelectedAPPs, saveSelectedAPPs } from '../../data/selectedAPPs'

const SaveSelectedAPPs = ({ userId }) => {
  const selectedAPPs = useSelector(getSelectedAPPs)
  const pendingSelectedAPPs = useSelector(getPendingSelectedAPPs)
  const saveSelectedAPPsRequested = useAction(saveSelectedAPPs.requested)

  const disabled = useMemo(
    () =>
      selectedAPPs.count() === pendingSelectedAPPs.count() &&
      pendingSelectedAPPs.every(p_app =>
        selectedAPPs.some(app => app === p_app)
      ),
    [selectedAPPs, pendingSelectedAPPs]
  )

  const onSave = () => {
    saveSelectedAPPsRequested(userId, pendingSelectedAPPs)
  }

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={disabled}
      onClick={onSave}
    >
      Save Selected APPs
    </Button>
  )
}

SaveSelectedAPPs.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default SaveSelectedAPPs
