import { ofType } from 'redux-observable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
import {
  fetchAssessment,
  getAssessmentById,
} from '~/apps/assessment/data/assessments'
import {
  fetchLegacyAssessment,
  getLegacyAssessmentById,
} from '~/apps/assessment/data/legacyAssessments'
import { scopedCreator } from '~/utils/data'
import { reject } from '~/utils/operators'
import key from '../key'

export const requestSelectedEncounter = scopedCreator(key)(
  'REQUEST_SELECTED_ENCOUNTER'
)

const isLegacy = type => type.includes('legacy')

export default (action$, state$) =>
  action$.pipe(
    ofType(requestSelectedEncounter),
    pluck('payload'),
    withLatestFrom(state$),
    reject(([{ type, encounterId }, state]) => {
      if (encounterId) {
        const encounter = isLegacy(type)
          ? getLegacyAssessmentById(state, encounterId)
          : getAssessmentById(state, encounterId)
        return encounter.id
      } else return null
    }),
    map(([{ type, encounterId }, _state]) => {
      if (encounterId)
        return isLegacy(type)
          ? fetchLegacyAssessment.requested(encounterId)
          : fetchAssessment.requested(encounterId)
      else return null
    }),
    filter(x => x)
  )
