import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change, formValueSelector, reduxForm } from 'redux-form'
import { withGoogle } from '~/components/GeoMap'
import { ActionDialog } from '~/components/dialogs'
import { renderFieldValueOptions } from '~/components/fields'
import {
  PhoneNumberField,
  SelectField,
  TextField,
} from '~/components/formFields'
import { Section } from '~/components/text'
import { fetchDistinctFieldValues, getFieldValues } from '~/data/fieldValues'
import { LocationSearch } from '~/features/locationSearch'
import formValuesTransform from '~/utils/formValuesTransform'
import PropTypes from '~/utils/propTypes'
import validate from '~/utils/validate'
import { requiredString } from '~/utils/validation/formValidators'
import { withStyles } from '@material-ui/core/styles'
import {
  getPhysicianFromValues,
  getValuesFromPhysician,
} from '../../utils/selectors'
import { PracticeField } from '../Practices'

const validator = values => ({
  firstName: validate(values.get('firstName'), [requiredString]),
  lastName: validate(values.get('lastName'), [requiredString]),
  fax: validate(values.get('fax'), [requiredString]),
})

const styles = ({ spacing, breakpoints }) => ({
  location: {
    margin: [spacing(2), 0],
  },
  sectionContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    columnGap: spacing(2),
    rowGap: `${spacing(2)}px`,

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  spanTwo: {
    gridColumnStart: 'span 2',

    [breakpoints.down('sm')]: {
      gridColumnStart: 'auto',
    },
  },
  fullWidth: {
    gridColumnStart: 'span 5',

    [breakpoints.down('sm')]: {
      gridColumnStart: 'auto',
    },
  },
})

const NonPhysicianForm = ({
  classes,
  form,
  mode,
  google,
  open,
  pristine,
  reset,
  changeValue,
  address,
  salutations,
  suffixes,
  ...props
}) => {
  const onClose = useCallback(() => {
    props.onClose()
    reset()
  }, [reset, props.onClose])

  const onLocationChange = useCallback(
    location => {
      changeValue(form, 'address', location)
    },
    [form, changeValue]
  )

  const handleSubmit = useCallback(
    contact => {
      const failed = props.handleSubmit(contact)
      if (!failed) onClose()
    },
    [props.handleSubmit, onClose]
  )

  return (
    <ActionDialog
      open={open}
      title="Add Non Physician"
      mode={mode}
      onAdd={handleSubmit}
      onSave={handleSubmit}
      onClose={onClose}
      disableAction={pristine}
      maxWidth="lg"
      fullWidth
    >
      <form>
        <Section title="Stakeholder Information">
          <div className={classes.sectionContainer}>
            <Field
              component={SelectField}
              name="salutation"
              label="Salutation"
              native
            >
              <option />
              {renderFieldValueOptions(salutations)}
            </Field>
            <Field component={TextField} name="firstName" label="First Name" />
            <Field
              component={TextField}
              name="middleName"
              label="Middle Name"
            />
            <Field component={TextField} name="lastName" label="Last Name" />
            <Field component={SelectField} name="suffix" label="Suffix" native>
              <option />
              {renderFieldValueOptions(suffixes)}
            </Field>
            <Field
              className={classes.fullWidth}
              component={TextField}
              name="title"
              label="Title"
            />
          </div>
        </Section>

        <Section title="Contact Information">
          <div className={classes.sectionContainer}>
            <Field component={PhoneNumberField} name="phone" />
            <Field
              component={PhoneNumberField}
              name="mobilePhone"
              label="Mobile Phone"
            />
            <Field component={PhoneNumberField} name="fax" label="Fax Number" />
            <Field
              className={classes.spanTwo}
              component={TextField}
              type="email"
              name="email"
              label="Email"
            />
          </div>
        </Section>

        <Section title="Mailing Information">
          <div className={classes.location}>
            <LocationSearch location={address} onChange={onLocationChange} />
          </div>
        </Section>

        <Section title="Other Information">
          <div className={classes.sectionContainer}>
            <PracticeField className={classes.fullWidth} form={form} />
            <Field
              className={classes.fullWidth}
              component={TextField}
              name="notes"
              label="Notes"
              variant="outlined"
              multiline
              rowsMax={4}
            />
          </div>
        </Section>
      </form>
    </ActionDialog>
  )
}

NonPhysicianForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  google: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  address: PropTypes.record.isRequired,
  salutations: PropTypes.map,
  suffixes: PropTypes.map,
}

export default compose(
  connect(
    (state, { form }) => {
      const selector = formValueSelector(form)
      return {
        address: selector(state, 'address'),
        salutations: getFieldValues('person_salutation')(state),
        suffixes: getFieldValues('person_suffix')(state),
      }
    },
    {
      changeValue: change,
      fetchDistinctFieldValues,
    }
  ),
  formValuesTransform(
    'physician',
    getValuesFromPhysician,
    getPhysicianFromValues
  ),
  reduxForm({ enableReinitialize: true, validate: validator }),
  withStyles(styles),
  withGoogle
)(NonPhysicianForm)
