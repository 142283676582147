import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { transformUser } from './currentUser'

const COPIED_USER = 'copiedUser'

const creator = scopedCreator(rootKey)
export const copiedUserCleared = creator('COPIED_USER_CLEARED', false)

export const fetchCopiedUser = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_COPIED_USER',
  requestParams: ['userId'],
  operation: (userId: any) => AspireAPI.get(`admin_tools/users/${userId}`),
  transform: transformUser,
  messages: { failed: 'There was an issue fetching this user' },
})

const initState = null
export default createReducer(COPIED_USER, initState, {
  [fetchCopiedUser.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [copiedUserCleared]: () => initState,
})

export const getCopiedUser = pipe(getRoot, get(COPIED_USER))
