import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'selectedPolygonArea'

const creator = scopedCreator(rootKey)
export const polygonAreaSelected = creator('POLYGON_AREA_SELECTED', [
  'polygon',
  'polygonId',
])
export const polygonCountsFetched = creator('POLYGON_COUNTS_FETCHED', [
  'polygonId',
  'counts',
])
export const polygonsCleared = creator('POLYGONS_CLEARED', false)

export default createReducer(key, Map(), {
  [polygonAreaSelected]: (state, { payload: { polygon, polygonId } }) =>
    state.set(polygonId, polygon),
  [polygonCountsFetched]: (state, { payload: { counts, polygonId } }) => {
    const newCounts = counts.reduce((map, careTeamCounts) => {
      const { active, scheduled, label, ...referredStauses } = careTeamCounts
      // Reduce referred statuses to get the sum and multiply by 25%
      const estimatedReferred = Math.floor(
        Object.values(referredStauses).reduce((total, val) => total + val, 0) *
          0.25
      )
      const estimated_census = active + scheduled + estimatedReferred
      return map.set(label, { ...careTeamCounts, estimated_census })
    }, Map())
    return state.setIn([polygonId, 'counts'], newCounts)
  },
  [polygonsCleared]: () => Map(),
})

export const getSelectedPolygonsArea = pipe(getRoot, get(key))
export const getSelectedPolygonsAreaCounts = pipe(
  getSelectedPolygonsArea,
  get('counts')
)

export const fetchPolygonCounts = (path, programs = []) =>
  AspireAPI.get('polygonator/polygons/patients/counts', {
    params: { path, programs: programs.join(',') },
  })
