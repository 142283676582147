import React from 'react'
import {
  PATIENT_RECORD_ORDER_DETAIL,
  PATIENT_RECORD_TASK_DETAIL,
} from '~/apps/patientRecord/router'
import { OpenLink } from '~/components/links'
import Info from '~/components/text/Info'
import PropTypes from '~/utils/propTypes'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ORDER } from '../constants'
import { ActionableItem as ActionableItemRecord } from '../data'

const styles = ({ palette, spacing }) => ({
  avatar: {
    backgroundColor: palette.primary.main,
  },
  card: {
    borderTop: [spacing(1), 'solid', palette.background.default],
    borderRight: [spacing(1), 'solid', palette.background.default],
    borderLeft: [spacing(1), 'solid', palette.background.default],
    '&:last-child': {
      borderBottom: [spacing(1), 'solid', palette.background.default],
    },
  },
  cardHeaderRoot: {
    padding: [spacing(2), spacing(2), 0],
  },
})

const ActionableItem = ({ classes, item }) => {
  const isOrder = item.type === ORDER

  return (
    <Card className={classes.card} elevation={0} raised={false} square>
      <CardHeader
        action={
          <Tooltip title={`Go to ${isOrder ? 'Order' : 'Task'}`}>
            <IconButton
              color="secondary"
              component={OpenLink}
              params={{
                patientId: item.patientId,
                [isOrder ? 'orderId' : 'taskId']: item.id,
              }}
              route={
                isOrder
                  ? PATIENT_RECORD_ORDER_DETAIL
                  : PATIENT_RECORD_TASK_DETAIL
              }
            >
              <Icon>open_in_new</Icon>
            </IconButton>
          </Tooltip>
        }
        avatar={
          <Avatar className={classes.avatar}>
            <Icon>{isOrder ? 'shopping_cart' : 'playlist_add_check'}</Icon>
          </Avatar>
        }
        classes={{ root: classes.cardHeaderRoot }}
        subheader={`Due Date: ${item.dueDate}`}
        title={item.label}
      />
      <CardContent>
        <Info label="Status">{item.status}</Info>
        <Info label="Assignee">{item.assigneeName}</Info>
      </CardContent>
    </Card>
  )
}

ActionableItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.instanceOf(ActionableItemRecord).isRequired,
}

export default withStyles(styles)(ActionableItem)
