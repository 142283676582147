import { combineEpics } from 'redux-observable'
import appEntered from './epics/appEntered'
import callListEntered from './epics/callListEntered'
import closePendingStakeholderEncounterReviewSucceeded from './epics/closePendingStakeholderEncounterReviewSucceeded'
import encounterReviewSelected from './epics/encounterReviewSelected'
import taskSelected from './epics/taskSelected'
import userIdRouteChanged from './epics/userIdRouteChanged'

export default combineEpics(
  appEntered,
  callListEntered,
  closePendingStakeholderEncounterReviewSucceeded,
  encounterReviewSelected,
  taskSelected,
  userIdRouteChanged
)
