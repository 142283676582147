import { Redirect, Route, Router, Scope } from 'redux-routable'
import key from './key'

export const CSS_DASHBOARD_APP_SCHEDULING_LIST = `${key}.appSchedulingList`
export const CSS_DASHBOARD_APP_SCHEDULING_APP = `${key}.appSchedulingAPP`
export const CSS_DASHBOARD_APP_SCHEDULING_PATIENT = `${key}.appSchedulingPatient`
export const CSS_DASHBOARD_CROSS_COVERAGE_DASHBOARD = `${key}.crossCoverageDashboard`
export const CSS_DASHBOARD_EPISODES_OF_CARE = `${key}.episodesOfCare`
export const CSS_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const CSS_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const CSS_DASHBOARD_ORDERS_INDEX = `${key}.ordersIndex`
export const CSS_DASHBOARD_ORDER_DETAIL = `${key}.orderDetail`
export const CSS_DASHBOARD_PATIENT_SEARCH = `${key}.patientSearch`
export const CSS_DASHBOARD_CALL_LIST = `${key}.callList`
export const CSS_DASHBOARD_CALENDAR = `${key}.calendar`
export const CSS_DASHBOARD_APP_FINDER = `${key}.appFinder`
export const CSS_DASHBOARD_OPEN_ASSESSMENTS = `${key}.openAssessments`
export const CSS_DASHBOARD_USER_TIMELINE = `${key}.userTimeline`

export const appSchedulingRouter = Router([
  Route(CSS_DASHBOARD_APP_SCHEDULING_APP, '/:appId'),
  Route(CSS_DASHBOARD_APP_SCHEDULING_PATIENT, '/:appId/:patientId'),
])

export const tasksRouter = Router([
  Route(CSS_DASHBOARD_TASKS_BY_USER, '/'),
  Route(CSS_DASHBOARD_TASK_DETAIL, '/:taskId'),
])

export const ordersRouter = Router([
  Route(CSS_DASHBOARD_ORDERS_INDEX, '/'),
  Route(CSS_DASHBOARD_ORDER_DETAIL, '/:orderId'),
])

export default Router([
  Scope('/:userId?/app-scheduling', appSchedulingRouter),
  Scope('/:userId?/orders', ordersRouter),
  Scope('/:userId?/tasks', tasksRouter),
  Route(CSS_DASHBOARD_APP_FINDER, '/:userId?/app-finder'),
  Route(CSS_DASHBOARD_APP_SCHEDULING_LIST, '/:userId?/app-list'),
  Route(CSS_DASHBOARD_CALENDAR, '/:userId?/calendar'),
  Route(CSS_DASHBOARD_CALL_LIST, '/:userId?/call-list'),
  Route(CSS_DASHBOARD_CROSS_COVERAGE_DASHBOARD, '/:userId?/cross-coverage'),
  Route(CSS_DASHBOARD_EPISODES_OF_CARE, '/:userId?/episodes-of-care'),
  Route(CSS_DASHBOARD_OPEN_ASSESSMENTS, '/:userId?/open-assessments'),
  Route(CSS_DASHBOARD_PATIENT_SEARCH, '/:userId?/patient-search'),
  Route(CSS_DASHBOARD_USER_TIMELINE, '/:userId?/user-timeline'),
  Redirect(CSS_DASHBOARD_APP_SCHEDULING_LIST),
])
