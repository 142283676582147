import { List } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { getRoot, typePrefixCreator } from './common/shared'
import { fetchConfig } from './config'

const STAKEHOLDERS = 'stakeholders'

const typePrefix = typePrefixCreator(STAKEHOLDERS)
export const STAKEHOLDERS_CLEARED = type(typePrefix, 'STAKEHOLDERS_CLEARED')
export const stakeholdersCleared = creator(STAKEHOLDERS_CLEARED)

export default createReducer(STAKEHOLDERS, List(), {
  [fetchConfig.SUCCEEDED]: (_state, { payload: { stakeholders } }) =>
    stakeholders,
  [STAKEHOLDERS_CLEARED]: () => List(),
})

export const getStakeholders = compose(get(STAKEHOLDERS), getRoot)
