import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'programs'

export const fetchPrograms = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PROGRAMS',
  operation: () => AspireAPI.get('polygonator/programs'),
  messages: { failed: 'Could not fetch programs' },
})

export default createReducer(key, new Map(), {
  [fetchPrograms.SUCCEEDED]: (_state, { payload }) =>
    normalize(payload, { idKey: 'program_enrolled' }),
})

export const getPrograms = pipe(getRoot, get(key))
