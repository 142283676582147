import { Iterable, Map } from 'immutable'
import { Epic } from 'redux-observable'
import { EMPTY, from, of } from 'rxjs'
import { mergeAll, mergeMap } from 'rxjs/operators'
import { Action, Requestable_, State, ts } from '~/reducerT'
import { replete } from '@nll/datum/Datum'
import { constPending, toRefresh } from '@nll/datum/DatumEither'
import { Async, Resource } from '~/resources/resource-strict'
import { Fn as ToastFn, Msg, Action as Toast } from '~/utils/toast'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export function Request<A>(
  resource: Resource<A>,
  creator: (a: Async<A>) => Action,
  extras?: {
    reloader?: (_: State) => Async<A>
    toast?: ToastFn<A>
  }
): Action {
  return Action.of.Request({
    payload: <R>(run: (prop: Requestable_<A>) => R) =>
      run({ ...extras, creator, resource }),
  })
}

export const epic: Epic<
  Action,
  Action,
  Map<string, Iterable.Indexed<State>>
> = (action$, state$) =>
  action$.pipe(
    mergeMap(
      Action.match(
        {
          Request: ({ payload }) =>
            payload(({ creator, reloader, resource, toast }) =>
              of(
                of(
                  creator(
                    toRefresh(reloader?.(ts(state$.value)) || constPending())
                  )
                ),
                from(resource()).pipe(
                  mergeMap(r =>
                    of([
                      creator(replete(r)),
                      ...pipe(
                        toast,
                        O.fromNullable,
                        O.chain(fn => fn(r)),
                        O.map((x: Msg) =>
                          Action.of.Toast({
                            payload: Toast.of.Push({ payload: x }),
                          })
                        ),
                        O.fold(
                          () => [],
                          x => [x]
                        )
                      ),
                    ]).pipe(mergeAll())
                  )
                )
              ).pipe(mergeAll())
            ),
        },
        () => EMPTY
      )
    )
  )

// TODO: Re-implement cancelations...
// const requestMiddleware = store => next => action => {
//   if (
//     has(action, 'meta.request.options') &&
//     get(action, 'meta.request.step') === REQUESTED
//   ) {
//     const { payload, meta } = action
//     const {
//       requestParams,
//       operation,
//       transform,
//       succeeded,
//       failed,
//       cancelled,
//     } = meta.request.options

//     // Dispatch succeeded/failed actions from operation
//     of(payloadToArgs(payload, requestParams))
//       .pipe(
//         mergeMap(args => from(operation(...args))),
//         map(transform),
//         tap(result => {
//           store.dispatch(succeeded(result, payload))
//         }),
//         catchError(error => {
//           if (axios.isCancel(error)) {
//             telemetry.warn('Request cancelled!', error)
//             store.dispatch(cancelled())
//           } else {
//             store.dispatch(failed(error, payload))
//           }

//           return empty()
//         })
//       )
//       .subscribe()

//     // Strip out request.options meta information
//     const newAction = {
//       ...action,
//       meta: { ...meta, request: { ...meta.request, options: undefined } },
//     }

//     return next(newAction)
//   } else {
//     return next(action)
//   }
// }
