const availabilityWhatId = '0061500000TdhSAAAZ'

import moment from 'moment'
import { localToUtc } from '~/utils/dates'

const DEFAULT_START_HOUR = 8
const DEFAULT_END_HOUR = 17
const defaultStartEnd = hour => ({ timeZone, date }) =>
  localToUtc(
    moment(date).set({ hours: hour, minutes: 0, seconds: 0 }),
    timeZone
  )

export default {
  type: 'Availability',
  recordType: 'availability',
  whatId: availabilityWhatId,
  start: defaultStartEnd(DEFAULT_START_HOUR),
  end: defaultStartEnd(DEFAULT_END_HOUR),
  maxVisits: 5,
}
