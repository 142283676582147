import { Map } from 'immutable'
import { combineEpics, ofType } from 'redux-observable'
import { empty, from } from 'rxjs'
import { catchError, first, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  FILTER_MAKE_DEFAULT_REQUESTED,
  appDataStored,
  fetchAppData,
  saveAppData,
} from '../data/appData'
import {
  DASHBOARD_PAGE_NAVIGATED,
  getCurrentUserAppData,
} from '../data/referralManagement'

const referralManagementAppDataKey = 'referral_management'

const initAppData = Map({
  defaultFilterId: null,
})

const fetchAndStoreReferralAppData = (userId: any) =>
  from(fetchAppData(userId, referralManagementAppDataKey)).pipe(
    map(d => appDataStored(userId, referralManagementAppDataKey, d)),
    catchError(showError('Could not get app settings'))
  )

const saveReferralAppData = (userId: any, appData: any) =>
  from(saveAppData(userId, referralManagementAppDataKey, appData)).pipe(
    map(d => appDataStored(userId, referralManagementAppDataKey, d)),
    catchError(showError('Could not save app settings'))
  )

const initialLoad = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DASHBOARD_PAGE_NAVIGATED),
    first(),
    mergeMap(() => {
      const state = state$.value
      const userId = getUserId(state)

      return fetchAndStoreReferralAppData(userId)
    })
  )

const setFilterAsDefault = (action$: any, state$: any) =>
  action$.pipe(
    ofType(FILTER_MAKE_DEFAULT_REQUESTED),
    mergeMap(({ filter }) => {
      const state = state$.value
      const userId = getUserId(state)
      const appData = getCurrentUserAppData(state, referralManagementAppDataKey)

      if (!appData)
        return saveReferralAppData(
          userId,
          initAppData.set('defaultFilterId', filter.id)
        )

      return filter.id === appData.get('defaultFilterId')
        ? empty()
        : saveReferralAppData(userId, appData.set('defaultFilterId', filter.id))
    })
  )

export default combineEpics(initialLoad, setFilterAsDefault)
