import { combineEpics } from 'redux-observable'
import appInitialized from './epics/appInitialized'
import channelJoined from './epics/channelJoined'
import clearNotificationsRequested from './epics/clearNotificationsRequested'

export default combineEpics(
  appInitialized,
  channelJoined,
  clearNotificationsRequested
)
