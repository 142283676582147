import { List, Map, fromJS } from 'immutable'
import { createSelector } from 'reselect'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getActiveApps } from '~/data/apps'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getAllChannels } from '~/data/channels'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getHealthplans } from '~/data/healthplans'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUsers } from '~/data/users'
import { getAllFilterHealthplanIds } from '../filterHealthplanIds'
import { isFilterUserOnly } from '../filterUserOnly'
import { getPhysicians } from '../physicians'
import { getAllReferralCases } from '../referralCases'
import { getReferralManagement } from '../root'
import { getValuesFromReferralCase } from './records'
export const getReferrals = (state: any) =>
  getReferralManagement(state).get('referrals')
export const isPolling = (state: any) => getReferrals(state).get('polling')
export const getActiveCase = (state: any) =>
  getReferrals(state).getIn(['activeCase'], Map())
export const getSelectedCaseId = (state: any) =>
  getActiveCase(state).get('caseId')
export const getCaseById = (state: any, caseId: any) =>
  getCases(state).get(caseId, Map())
export const getReferralFormSyncErrors = (state: any, formName: any) =>
  fromJS(state.getIn(['form', formName, 'syncErrors'], {}))
export const getReferralFormValues = (state: any, formName: any) =>
  state.getIn(['form', formName, 'values'], Map())
export const getAddressFields = (state: any, formName: any) =>
  getReferralFormValues(state, formName).get('address', Map())
export const getHealthplanFields = (state: any, formName: any) =>
  getReferralFormValues(state, formName).get('healthplan', Map())
export const getPersonInfoFields = (state: any, formName: any) =>
  getReferralFormValues(state, formName).get('person', Map())
export const isDupeCheckComplete = (state: any) =>
  getReferrals(state).getIn(['newReferral', 'duplicateCheckComplete'], false)
export const potentialDuplicates = (state: any) =>
  getReferrals(state).getIn(['newReferral', 'potentialDuplicates'], null)
export const odsMatches = (state: any) =>
  getReferrals(state).getIn(['newReferral', 'odsMatches'], null)
export const newCaseId = (state: any) =>
  getReferrals(state).getIn(['newReferral', 'newCaseId'], null)
export const searchingOds = (state: any) =>
  getReferrals(state).getIn(['newReferral', 'searchingOds'], false)
export const getSecondaryChannel = (state: any, formName: any) =>
  getReferralFormValues(state, formName).getIn(
    ['aspire', 'secondaryChannel', 'id'],
    null
  )
export const getHealthplanFilters = (state: any) =>
  Object.keys(
    getReferrals(state).getIn(['filters', 'healthplans'], Map()).toJS()
  )
export const getPatientSfid = (state: any, form: any) =>
  getReferralFormValues(state, form).getIn(['aspire', 'patientSfid'], null)
export const getPatientPgid = (state: any, form: any) =>
  getReferralFormValues(state, form).getIn(['aspire', 'patientPgid'], null)
export const getSelectedPatientId = (state: any) =>
  getReferrals(state).getIn(['additionalReferral', 'selectedPatientId'])
export const getExistingPatientReferrals = (state: any) =>
  getReferrals(state).getIn(
    ['additionalReferral', 'selectedPatient', 'referrals'],
    List()
  )
export const getSelectedPatientInfoForAdditionalReferral = (state: any) =>
  getReferrals(state).getIn(
    ['additionalReferral', 'selectedPatient', 'patientInfo'],
    Map()
  )
export const getAutomaticAssignmentChannelOptions = (state: any) =>
  getReferrals(state).getIn(['assignment', 'potentialChannels'], List())
export const getCareTeamOptions = (state: any) =>
  getReferrals(state).getIn(['assignment', 'potentialCareTeams'], List())
export const getCareTeamOptionsWithUsers = createSelector(
  [getCareTeamOptions, getUsers],
  (careTeams, users) => {
    const updatedCareTeams = careTeams.map((ct: any) => {
      const primaryMember = ct
        .get('members')
        .filter((x: any) => x.get('role') == ct.get('primaryRole'))
        .first()
      return ct.set(
        'primaryUser',
        (users as any).get(primaryMember.get('userId'))
      )
    })
    return updatedCareTeams
  }
)
export const getCases = createSelector(
  [
    state => getUserId(state),
    state => isFilterUserOnly(state),
    state => getAllFilterHealthplanIds(state),
    state => getAllReferralCases(state),
  ],
  (userId, userOnly, filterHealthplanIds, cases) =>
    cases
      .filter((h: any) =>
        filterHealthplanIds.size
          ? filterHealthplanIds.has(h.healthplanId)
          : true
      )
      .filter((h: any) => (userOnly ? h.createdById === userId : true))
)
export const getManualAssignmentCases = (state: any) =>
  getAllReferralCases(state).filter(
    (c: any) => c.substatus === 'pending_manual_assignment'
  )
export const getAssignmentValues = createSelector(
  [
    (state: any, formName: any) => getAddressFields(state, formName),
    (state: any, formName: any) => getHealthplanFields(state, formName),
    (state: any, formName: any) => getReferralFormValues(state, formName),
    state => getActiveCase(state),
  ],
  (address, healthplan, values, activeCase) => {
    const mailingStreet = address.get('street1', null)
    const mailingCity = address.get('city', null)
    const stateCode = address.get('state', null)
    const postalCode = address.get('postalCode', null)
    const healthplanId = healthplan.get('id', null)
    const programEnrolled = values.getIn(['aspire', 'program'], null)
    if (
      mailingStreet &&
      mailingCity &&
      stateCode &&
      postalCode &&
      healthplanId &&
      programEnrolled
    ) {
      return { caseId: activeCase.get('caseId') }
    }
    return null
  }
)
export const getProgramEnrolled = createSelector(
  [(state: any, formName: any) => getReferralFormValues(state, formName)],
  values => values.getIn(['aspire', 'program'], null)
)
export const getSelectedHealthplan = (state: any, location: any, form?: any) =>
  getReferralFormValues(
    state,
    form || `referral-${getActiveCase(state).get('caseId')}`
  ).getIn(['healthplan', location, 'healthplanId'], 0)

export const getAutocompleteChannels = createSelector(
  [
    getAllChannels,
    (state: any, location: any) => getSelectedHealthplan(state, location),
  ],
  (channels, selectedHealthplan) => {
    const filteredChannels = selectedHealthplan
      ? (channels as any).filter(
          (v: any) => v.healthplan_id == selectedHealthplan
        )
      : channels
    const results = filteredChannels.toList().map((channel: any) => ({
      label: channel.get('name'),
      healthplanName: channel.get('healthplan_name'),
      healthplanId: channel.get('healthplan_id'),
      value: channel.get('id'),
    }))
    return results.toJS()
  }
)
export const getAutocompletePhysicians = createSelector(
  // @ts-expect-error state is unknown
  [state => getPhysicians(state)],
  physicians => {
    const results = physicians.toList().map((physician: any) => ({
      label: `${physician.get('name') || ''} (${physician.get('city') || ''}, ${
        physician.get('state') || ''
      }) ${physician.get('phone', '') || ''} ${physician.get('npi') || ''}`,
      value: physician.get('id'),
    }))
    return results.toJS()
  }
)
export const getAutocompleteApps = createSelector(
  [state => getActiveApps(state)],
  apps => {
    const results = apps.toList().map((app: any) => ({
      label: app.get('name'),
      value: app.get('id'),
    }))
    return results.toJS()
  }
)
export const getCustomHealthplanFieldMappings = createSelector(
  [
    state => getHealthplans(state),
    (state: any, location: any, form: any) =>
      getSelectedHealthplan(state, location, form),
  ],
  (healthplans, selectedPlan) =>
    healthplans.reduce((mappings: any, plan: any) => {
      if (plan.get('id') == selectedPlan) {
        return plan.get('fieldMappings') || mappings
      } else return mappings
    }, Map())
)
export const getHealthplanNames = createSelector(
  [state => getHealthplans(state)],
  healthplans =>
    healthplans
      .toList()
      .map((h: any) => h.name)
      .toJS()
)
export const getFilteredHealthplans = createSelector(
  [state => getAllFilterHealthplanIds(state), state => getHealthplans(state)],
  (filterIds, healthplans) =>
    healthplans.filter((h: any) => filterIds.has(h.id))
)
export const getODSLookupvalues = createSelector(
  [
    (state: any, form: any) => getReferralFormValues(state, form || 'new'),
    state => getHealthplans(state),
  ],
  (data, healthplans) => {
    const EMRid = data.getIn(['healthplan', 'primaryInsurance', 'healthplanId'])
    const EMRname = data.getIn([
      'healthplan',
      'primaryInsurance',
      'healthplanName',
    ])
    const odsId = healthplans
      ? healthplans.reduce(
          (x: any, plan: any) =>
            plan.get('id') === EMRid && plan.get('name') === EMRname
              ? plan.get('odsId')
              : x,
          null
        )
      : null
    return {
      firstName: data.getIn(['patient', 'firstName']),
      lastName: data.getIn(['patient', 'lastName']),
      dob: data.getIn(['patient', 'dob']),
      odsId: odsId,
      state: data.getIn(['address', 'state']),
      umi: data.getIn(['healthplan', 'primaryInsurance', 'accountNumber']),
    }
  }
)
export const getActiveFormInitValues = createSelector(
  [state => getActiveCase(state)],
  data => getValuesFromReferralCase(data)
)
export const getChannelSelectionDialogOpen = (state: any) =>
  getReferrals(state).getIn(['assignment', 'channelSelectionDialogOpen'])
export const getSelectedHealthplanName = (state: any, form?: any) =>
  getReferralFormValues(
    state,
    form || `referral-${getActiveCase(state).get('caseId')}`
  ).getIn(['healthplan', 'primaryInsurance', 'healthplanName'], '')
export const getSelectedHealthplanNameBySection = (
  state: any,
  location: any,
  form?: any
) =>
  getReferralFormValues(
    state,
    form || `referral-${getActiveCase(state).get('caseId')}`
  ).getIn(['healthplan', location, 'healthplanName'], '')
