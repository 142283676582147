import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchCareTeam } from '~/data/careTeams'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_CARE_TEAM } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_CARE_TEAM)),
    switchTo(state$),
    map(getPatientId),
    map(fetchCareTeam.requested)
  )
