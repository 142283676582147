import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientInfo' or its... Remove this comment to see the full error message
import { fetchPatient } from '~/features/patientInfo'
import { updateDischargeRecommendation } from '../data/discharges'

export default (action$: any) =>
  action$.pipe(
    ofType(updateDischargeRecommendation.SUCCEEDED),
    pluck('payload', 'patientId'),
    map(fetchPatient.requested)
  )
