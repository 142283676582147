import reduceReducers from 'reduce-reducers'
import { combineReducers } from 'redux-immutable'
import commonReducer from './common/reducer'
import creating from './creating'
import currentEvent from './currentEvent'
import encounterTypes from './encounterTypes'
import events from './events'
import filters from './filters'
import patientInfo from './patientInfo'
import { EVENTS } from './root'
import serviceLocations from './serviceLocations'

const combinedReducers = combineReducers({
  [creating.key]: creating,
  [currentEvent.key]: currentEvent,
  [events.key]: events,
  [filters.key]: filters,
  [patientInfo.key]: patientInfo,
  [encounterTypes.key]: encounterTypes,
  [serviceLocations.key]: serviceLocations,
})

const reducer = reduceReducers(combinedReducers, commonReducer)

reducer.key = EVENTS

export default reducer

export * from './root'
export * from './creating'
export * from './currentEvent'
export * from './events'
export * from './filters'
export * from './patientInfo'
export * from './encounterTypes'
export * from './serviceLocations'
