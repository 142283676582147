import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn, into } from '~/utils/data'
import REFERRAL_MANAGMENT from '../key'

// Key
const PHYSICIANS = 'physicians'

// Record
export const Physician = Record({
  id: null,
  name: null,
  org: null,
  city: null,
  state: null,
  phone: null,
  npi: null,
})

// Request
export const fetchPhysiciansByName = Request({
  typePrefix: `${REFERRAL_MANAGMENT}/${PHYSICIANS}`,
  typeBase: 'FETCH_PHYSICIANS',
  requestParams: ['physicianName'],
  operation: (physicianName: any) =>
    AspireAPI.get('v1/physician', { params: { physicianName } }),
  transform: into(Physician, 'id'),
  messages: {
    failed: 'Problem loading physicians',
  },
})

// Reducer
export default createReducer(PHYSICIANS, Map(), {
  [fetchPhysiciansByName.SUCCEEDED]: (state: any, { payload }: any) =>
    state.merge(payload),
})

// Selector
export const getPhysicians = getIn([REFERRAL_MANAGMENT, PHYSICIANS])
