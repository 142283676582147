import React from 'react'
import PropTypes from '~/utils/propTypes'

export default Component => {
  const FormField = ({ input, meta, ...props }) => (
    <Component
      {...props}
      {...input}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.touched && meta.invalid}
    />
  )

  FormField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
  }

  return FormField
}
