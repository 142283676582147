import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { withTheme } from '@material-ui/core/styles'
import Marker from '../Marker'
import createIcon from './createIcon'
import createLabel from './createLabel'

const DiamondMarker = ({ theme, ...props }) => {
  const { color, scale, label } = props

  const icon = useMemo(
    () => DiamondMarker.renderIcon(theme, color, scale, label),
    [theme, color, scale, label]
  )

  return <Marker {...props} icon={icon} />
}

DiamondMarker.renderIcon = (theme, color, scale, label) =>
  createIcon({
    scale,
    size: label ? { height: 24, width: 24 } : { height: 18, width: 18 },
    icon: label
      ? `
    <path stroke="${
      theme.palette.map.markers.stroke
    }" stroke-width="2" d="M 2 12 L 12 22 L 22 12 L 12 2 Z"/>
    <path fill="${color}" d="M 2 12 L 12 22 L 22 12 L 12 2 Z"/>
    ${createLabel(label, 12, 12)}`
      : `
    <path stroke="${theme.palette.map.markers.stroke}" stroke-width="2" d="M 2 9 L 9 16 L 16 9 L 9 2 Z"/>
    <path fill="${color}" d="M 2 9 L 9 16 L 16 9 L 9 2 Z"/>`,
  })

DiamondMarker.propTypes = {
  theme: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  scale: PropTypes.number,
}

DiamondMarker.defaultProps = {
  label: '',
  scale: 1,
}

export default withTheme(DiamondMarker)
