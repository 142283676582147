import React from 'react'
// @ts-expect-error no export
import { PATIENT_RECORD_PATIENT_INFO } from '~/apps/patientRecord/router'
import TypographyLink from '~/components/TypographyLink'

type Props = {
  patientId: string
  children: string
}

const PatientLink = ({ children, patientId }: Props) => (
  <TypographyLink
    action="open"
    params={{ patientId }}
    route={PATIENT_RECORD_PATIENT_INFO}
  >
    {children}
  </TypographyLink>
)

export default PatientLink
