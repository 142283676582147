import { differenceInSeconds, subSeconds } from 'date-fns'
import { combineEpics } from 'redux-observable'
import { fromEvent, merge, timer } from 'rxjs'
import { filter, map, mapTo, throttleTime } from 'rxjs/operators'
import {
  activityDetected,
  getUserLastActivity,
  isAuthenticated,
  isUnauthenticated,
  loggedOut,
} from '~/data/session'
import {
  SECONDS_UNTIL_EXPIRATION,
  dialogOpened,
  isDialogOpen,
} from '~/data/sessionExtensionDialog'
import { switchTo } from '~/utils/operators'
import keycloak from '~/utils/security/keycloak'

// Milliseconds
const TEN_SECONDS = 1000 * 10
const ONE_MINUTE = 1000 * 60

// Seconds
const INACTIVITY_TIMEOUT = 60 * 15

const activityEpic = (_action$, state$) =>
  merge(fromEvent(document, 'keyup'), fromEvent(document, 'click')).pipe(
    throttleTime(TEN_SECONDS),
    switchTo(state$),
    filter(state => {
      const authenticated = isAuthenticated(state)
      const unauthenticated = isUnauthenticated(state)

      return authenticated || unauthenticated
    }),
    mapTo(activityDetected())
  )

const authenticatedEpic = (_action$, state$) =>
  timer(0, ONE_MINUTE).pipe(
    switchTo(state$),
    filter(isAuthenticated),
    // tap(state => {
    //   const lastActivity = getUserLastActivity(state)
    //   const diff = differenceInSeconds(keycloak.expiresAt, lastActivity)

    //   // Refresh token if user has been actively using the system
    //   if (diff > SECONDS_UNTIL_EXPIRATION && diff < TEN_MINUTES) {
    //     keycloak
    //       .refreshToken(TEN_MINUTES)
    //       .catch(err => console.error('Error refreshing token', err))
    //   }
    // }),
    filter(state => {
      const dialogOpen = isDialogOpen(state)
      const lastActivity = getUserLastActivity(state)
      const aDiff = differenceInSeconds(
        lastActivity,
        subSeconds(new Date(), INACTIVITY_TIMEOUT)
      )
      const kcDiff = differenceInSeconds(keycloak.expiresAt, new Date())

      // There are scenarios where requests can be made without user input (dashboard refreshes)
      // If last activity is closer to expiration, use that value instead
      const diff = Math.min(aDiff, kcDiff)

      // Continue if session is expired or is about to expire
      return diff <= 0 || (diff < SECONDS_UNTIL_EXPIRATION && !dialogOpen)
    }),
    map(state => (isDialogOpen(state) ? loggedOut() : dialogOpened()))
  )

const unauthenticatedEpic = (_action$, state$) =>
  timer(0, ONE_MINUTE).pipe(
    switchTo(state$),
    filter(isUnauthenticated),
    filter(state => {
      const lastActivity = getUserLastActivity(state)
      const diff = differenceInSeconds(new Date(), lastActivity)

      return diff >= INACTIVITY_TIMEOUT
    }),
    mapTo(loggedOut())
  )

export default combineEpics(
  activityEpic,
  authenticatedEpic,
  unauthenticatedEpic
)
