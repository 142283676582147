import moment from 'moment'
import React, { useEffect } from 'react'
import useInput from '~/hooks/useInput'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import Input from './Input'
import debounce from './debounce'
import memoizedWrapper from './memoizedWrapper'
import { getInputProps } from './props'

/* THIS IS DEPRECATED - USE futureDate FORMAT INSTEAD */

const FutureDateWidget = ({ value, ...props }) => {
  const [date, setDate] = useInput()
  const valid = Boolean(date) && moment(date).isAfter(moment())

  useEffect(() => {
    value && setDate(value)
  }, [value])

  useEffect(() => {
    props.onChange(valid ? date : undefined)
  }, [date])

  return (
    <Input
      {...getInputProps(props)}
      fullWidth
      onChange={setDate}
      value={date}
      type="date"
    />
  )
}

FutureDateWidget.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
}

export default compose(memoizedWrapper, debounce)(FutureDateWidget)
