import { swimlaneCategory } from './event'

// Colors Patient Events based on category
export const colorize = colors => {
  const bg = (color, value) => ({ background: colors[color][value] })
  const c = (color, value) => ({ color: colors[color][value] })

  return {
    // admissions
    hospitalizationColor: bg('cyan', 200),
    hospitalizationIconColor: c('grey', 800),
    snfColor: bg('cyan', 500),
    snfIconColor: c('grey', 800),
    ltcColor: bg('cyan', 700),
    ltcIconColor: c('common', 'white'),
    erColor: bg('cyan', 800),
    erIconColor: c('common', 'white'),

    // clinical calls
    calls_clinicalColor: bg('blue', 200),
    calls_clinicalIconColor: c('grey', 800),

    // other calls
    calls_otherColor: bg('orange', 200),
    calls_otherIconColor: c('grey', 800),

    // communications
    successful_communicationColor: bg('blue', 600),
    successful_communicationIconColor: c('common', 'white'),
    unsuccessful_communicationColor: bg('blue', 200),
    unsuccessful_communicationIconColor: c('grey', 800),

    // encounters
    encounterColor: bg('lime', 'A400'),
    encounterIconColor: c('grey', 800),

    // on call events
    on_callColor: bg('pink', 200),
    on_callIconColor: c('grey', 800),

    // orders
    hospice_transitionColor: bg('purple', 200),
    hospice_transitionIconColor: c('grey', 800),
    orderColor: bg('purple', 400),
    orderIconColor: c('common', 'white'),

    // other
    amaze_timelineColor: bg('yellow', 700),
    manual_timelineColor: bg('yellow', 500),
    manual_timelineIconColor: c('grey', 800),
    ict_timelineColor: bg('yellow', 700),
    high_risk_huddle_timelineColor: bg('yellow', 700),

    // service_information
    dischargeColor: bg('green', 200),
    dischargeIconColor: c('grey', 800),
    discharge_recommendationColor: bg('green', 500),
    discharge_recommendationIconColor: c('grey', 800),
    researchColor: bg('green', 700),
    researchIconColor: c('common', 'white'),
    program_enrolledColor: bg('green', 900),
    program_enrolledIconColor: c('common', 'white'),

    // tasks
    taskColor: bg('pink', 200),
    taskIconColor: c('common', 'white'),

    // nominations
    nominationColor: bg('red', 300),
    nominationIconColor: c('grey', 800),
  }
}

export const setColorCategory = patientEvent =>
  patientEvent.category == 'communication'
    ? patientEvent.details.success + '_' + swimlaneCategory(patientEvent)
    : swimlaneCategory(patientEvent)
