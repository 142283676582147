import { ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import { combineLatest, filter, map, takeUntil } from 'rxjs/operators'
import { fetchAssessment } from '~/apps/assessment/data/assessments'
import { fetchLegacyAssessment } from '~/apps/assessment/data/legacyAssessments'
import { fetchPatient, getPatientLastEncounter } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'

// Eric Note - This is gonna be refactored to not suck as much later on
// Anderson Note - I made it suck a little more by adding the ability to put the page in because this information is also needed on the On Call page
export default page => (action$, state$) =>
  action$.pipe(
    filter(entered(page)),
    combineLatest(action$.pipe(ofType(fetchPatient.SUCCEEDED))),
    switchTo(state$),
    map(getPatientLastEncounter),
    filter(({ encounter_id }) => Boolean(encounter_id)),
    map(({ encounter_id, type }) =>
      type.includes('legacy')
        ? fetchLegacyAssessment.requested(encounter_id)
        : fetchAssessment.requested(encounter_id)
    ),
    takeUntil(
      action$.pipe(
        ofType(fetchAssessment.SUCCEEDED, fetchLegacyAssessment.SUCCEEDED)
      )
    )
  )
