import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import { ADD_LIST_ITEM, dialogOpened } from '../../../data/dialog'

const AddListAction = ({ row, dialogOpened }) => {
  const onClick = useCallback(() => {
    dialogOpened(ADD_LIST_ITEM, row.patientId, row.patientName)
  }, [row, dialogOpened])

  return (
    <Tooltip title="Add Patient To List" placement="bottom">
      <IconButton onClick={onClick}>
        <Icon>playlist_add</Icon>
      </IconButton>
    </Tooltip>
  )
}

AddListAction.propTypes = {
  row: PropTypes.object.isRequired,
  dialogOpened: PropTypes.func.isRequired,
}

export default connect(null, { dialogOpened })(AddListAction)
