import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error FIXME
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'

const key = 'currentVirtualEncounterType'
const creator = scopedCreator(key)
export const virtualEncounterTypeChanged = creator(
  'VIRTUAL_ENCOUNTER_TYPE_CHANGED'
)

export default createReducer(key, null, {
  [virtualEncounterTypeChanged.toString()]: (_state: any, { payload }: any) =>
    payload,
})

export const getCurrentVirtualEncounterType = pipe(get('assessment'), get(key))
