import { get, isEqual } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../widgets/memoizedWrapper'

const SELF_UNKNOWN = {
  enum: ['self', 'unknown'],
  enumNames: ['Self', 'Unknown'],
}

const wrap = (string, left, right) => (string ? left + string + right : string)

const join = (strings, separator) => strings.filter(Boolean).join(separator)

const getEnum = contact => contact.id

const getEnumName = (contact, index) => {
  const preferred = contact.preferred ? '(Preferred)' : null
  const type = join([contact.type, preferred], ' ')
  const relationship = wrap(contact.relationship, '(', ')')
  const name = join([contact.name, relationship], ' ')
  const label = join([type, name, contact.phoneNumber], ' - ')

  return label || `Contact #${index + 1}`
}

class Contact extends React.Component {
  componentDidUpdate() {
    const { formData } = this.props
    const enumValues = this.getEnum()

    if (!enumValues.includes(formData)) {
      this.props.onChange(undefined)
    }
  }

  getValues = (transform, selfUnknownValues) => () => {
    const { contacts, uiSchema } = this.props
    const displaySelfUnknown = uiSchema['ui:options'].displaySelfUnknown
    const contactsValues = contacts.map(transform)
    const extraValues = displaySelfUnknown ? selfUnknownValues : []

    return [...contactsValues, ...extraValues]
  }

  getEnum = this.getValues(getEnum, SELF_UNKNOWN.enum)
  getEnumNames = this.getValues(getEnumName, SELF_UNKNOWN.enumNames)

  render() {
    const { registry, schema, uiSchema } = this.props
    return (
      <registry.fields.StringField
        {...this.props}
        schema={{
          ...schema,
          enum: this.getEnum(),
          enumNames: this.getEnumNames(),
        }}
        uiSchema={{ ...uiSchema, 'ui:widget': 'select' }}
      />
    )
  }
}

Contact.propTypes = {
  contacts: PropTypes.array.isRequired,
  registry: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  formData: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default compose(
  memoizedWrapper,
  connect(() => {
    const contactsSelector = makeContactsSelector()

    return (state, { uiSchema, formContext }) => {
      const contactFields = get(uiSchema, ['ui:options', 'contactFields']) || [
        'existingContacts',
        'newContacts',
      ]

      return {
        contacts: contactsSelector(contactFields, formContext, state),
      }
    }
  })
)(Contact)

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

const baseContactsSelector = createDeepEqualSelector(
  [
    contacts => contacts,
    (_contacts, formContext) => formContext.getFormDataByTag,
    (_contacts, _formContext, state) => state,
  ],
  (contacts, getFormDataByTag, state) =>
    contacts.reduce(
      (acc, elem) => [
        ...acc,
        ...getFormDataByTag(state, elem, { defaultVal: [] }),
      ],
      []
    )
)

const makeContactsSelector = () =>
  createDeepEqualSelector(baseContactsSelector, c => c)
