import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getRoot } from '../common/shared'

export const PATIENT_FILTERS = 'patientFilters'

const creator = scopedCreator(rootKey)
export const filtersLoadedFromStorage = creator('FILTERS_LOADED_FROM_STORAGE', [
  'selected',
  'values',
])
export const filtersCleared = creator('FILTERS_CLEARED', false)

export const getPatientFilters = pipe(getRoot, get(PATIENT_FILTERS))
