const createIcon = ({ scale, size, icon }) => {
  const url = iconToUrl(icon, size)
  const scaledSize = { height: size.height * scale, width: size.width * scale }

  return {
    url,
    scaledSize,
    size: scaledSize,
  }
}

const iconToUrl = (icon, size) =>
  stripWhitespace(
    `data:image/svg+xml,
    <svg
      height="${size.height}" width="${size.width}"
      viewBox="0 0 ${size.height} ${size.width}"
      xmlns="http://www.w3.org/2000/svg">
      ${encodeURIComponent(icon)}
    </svg>`
  )

const stripWhitespace = string =>
  string
    .replace(/\n/g, '') // Remove newlines
    .replace(/\s{2,}/g, ' ') // Shrink spaces

export default createIcon
