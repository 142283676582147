import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { mapCarePlan } from './carePlan/root'
import { getRoot, mapProblems } from './common/shared'

const CarePlan = Record({
  encounterId: null,
  age: null,
  ethnicity: null,
  sex: null,
  signedAt: null,
  providerName: null,
  providerRole: null,
  problems: Map(),
})

const LAST_CARE_PLAN = 'lastCarePlan'

export const transformLegacyLastCarePlan = ({
  overviewProblems,
  problems: selectedProblems,
  goals: selectedGoals,
  actions: selectedActions,
  assessmentNotes,
  provider,
  ...rest
}: any) => {
  const problems = mapCarePlan(
    {
      problems: overviewProblems,
      selectedProblems,
      selectedGoals,
      selectedActions,
      assessmentNotes,
    },
    null
  )

  return CarePlan({
    problems,
    providerName: provider?.name,
    providerRole: provider?.role,
    ...rest,
  })
}

export const transformLastCarePlan = ({
  encounter = {},
  problems = [],
  ...rest
}: any) => {
  return CarePlan({
    encounterId: encounter?.id,
    signedAt: encounter?.signedAt,
    providerName: encounter?.provider?.name,
    providerRole: encounter?.provider?.role,
    problems: mapProblems(problems),
    ...rest,
  })
}

export const fetchLastCarePlan = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_LAST_CARE_PLAN',
  requestParams: ['patientId'],
  operation: (patientId: any) =>
    AspireAPI.get(`care_plan/last_encounter/${patientId}`),
  transform: transformLastCarePlan,
  messages: { failed: 'There was an issue fetching the last care plan' },
})

export default createReducer(LAST_CARE_PLAN, CarePlan(), {
  [fetchLastCarePlan.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getLastCarePlan = pipe(getRoot, get(LAST_CARE_PLAN))
