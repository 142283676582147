import { flow } from 'lodash'
import { Codec } from 'purify-ts'

type Operation<P extends any[]> = (...args: P) => Promise<unknown>

export type Request<P extends any[], T> = (...params: P) => Promise<T | null>

export const createRequest = <P extends any[], T = never>(
  request: Operation<P>,
  decoder?: Codec<T>,
  errorMessage = 'Failed to decode data'
) => (...params: P): Promise<T | null> => {
  return request(...params).then((data: unknown) => {
    return decoder
      ? flow(decoder.decode, value =>
          value.caseOf({
            Left: error => {
              console.error(error)
              throw new TypeError(errorMessage)
            },
            Right: data => data,
          })
        )(data)
      : null
  })
}
