import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import debounce from './debounce'
import memoizedWrapper from './memoizedWrapper'

const AttachmentLinkWidget = props => {
  const { enum: link_name, enumNames: link } = props.schema
  return <a href={link}>{link_name}</a>
}

AttachmentLinkWidget.propTypes = {
  enum: PropTypes.string.isRequired,
  enumNames: PropTypes.string.isRequired,
  schema: PropTypes.object,
}

export default compose(memoizedWrapper, debounce)(AttachmentLinkWidget)
