// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import clinicalDirectorId from './data/clinicalDirectorId'
import mtm from './data/mtm'
import openAssessment from './data/openAssessments'
import providers from './data/providers'
import tabs from './data/tabs'
import key from './key'

const reducer = combineReducers({
  [clinicalDirectorId.key]: clinicalDirectorId,
  [mtm.key]: mtm,
  [openAssessment.key]: openAssessment,
  [providers.key]: providers,
  [tabs.key]: tabs,
})

reducer.key = key

export default reducer
