import last from 'lodash/last'
import React, { useEffect } from 'react'
import { ISelectSearchProps, SelectSearchWidget } from './SelectSearchWidget'

const DECLINE_CODES = ['UNK', 'ASKU']

export const RaceSelectSearchWidget = (props: ISelectSearchProps) => {
  useEffect(() => {
    if (
      props.value &&
      props.value.length > 1 &&
      hasUnkOrAsku(props.value as string[])
    ) {
      const value = last(props.value)
      // We are using this set timeout here because there are occassions where
      // the onChange is called but the UI doesn't re render and reflect the
      // changes. This pushes the onChange execution to the bottom of the
      // stack waiting for whatever react json schema forms is doing to complete
      // before triggering the change.
      setTimeout(() => {
        props.onChange([value as string])
      })
    }
  }, [props?.value?.length])

  const hasUnkOrAsku = (codes: string[]) =>
    DECLINE_CODES.some(code => codes.includes(code))

  return <SelectSearchWidget {...props} />
}
