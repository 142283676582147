import { combineReducers } from 'redux-immutable'
import form from './data/form'
import VIDEO_VISIT_CONSENT from './key'

const reducer = combineReducers({
  [form.key]: form,
})

reducer.key = VIDEO_VISIT_CONSENT

export default reducer
