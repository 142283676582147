import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import { Checkbox, FormControlLabel, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TasksDashboard from '../components/TasksDashboard'
import {
  careTeamMemberTasksFetchRequested,
  getAssignedTasksAsArray,
  getCareTeamMemberPatientTasksAsArray,
  getRequestedTasksAsArray,
  taskCollapsed,
  taskExpanded,
} from '../data/tasks'
import {
  Toggles,
  cancelledToggled,
  completedToggled,
  getToggles,
} from '../data/toggles'
import { getUserId } from '../data/userId'

const useStyles = makeStyles(({ spacing }) => ({
  tabs: {
    marginBottom: spacing(2),
  },
}))

const UserTasksDashboard = ({
  assignedTasks,
  cancelledToggled,
  careTeamMemberTasks,
  careTeamMemberTasksFetchRequested,
  completedToggled,
  requestedTasks,
  toggles,
  userId,
  ...props
}) => {
  const [pageValue, setPageValue] = useState('userTasks')
  const classes = useStyles()

  const handleChangeTab = useCallback((_e, value) => setPageValue(value), [
    setPageValue,
  ])

  useEffect(() => setPageValue('userTasks'), [userId])

  const tasks = useMemo(
    () =>
      pageValue === 'userTasks'
        ? assignedTasks
        : pageValue === 'assignedTasks'
        ? requestedTasks
        : pageValue === 'careTeamMemberTasks'
        ? careTeamMemberTasks
        : null,
    [pageValue, assignedTasks, requestedTasks, careTeamMemberTasks]
  )

  const hiddenColumns = useMemo(
    () =>
      pageValue === 'userTasks'
        ? ['owner']
        : pageValue === 'assignedTasks'
        ? ['requestedByName']
        : pageValue === 'careTeamMemberTasks'
        ? ['priority']
        : null,
    [pageValue]
  )

  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <Checkbox checked={toggles.completed} onChange={completedToggled} />
        }
        label="Include Completed"
      />

      <FormControlLabel
        control={
          <Checkbox checked={toggles.cancelled} onChange={cancelledToggled} />
        }
        label="Include Cancelled"
      />

      <Tabs
        className={classes.tabs}
        value={pageValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
      >
        <Tab value="userTasks" label="Assigned To Me" />
        <Tab value="assignedTasks" label="Requested By Me" />
        <Tab
          value="careTeamMemberTasks"
          label="My Patients' Open Tasks"
          onClick={() => careTeamMemberTasksFetchRequested(userId)}
        />
      </Tabs>
      <TasksDashboard
        tasksContext="user"
        hiddenColumns={hiddenColumns}
        key={pageValue}
        tasks={tasks}
        {...props}
      />
    </React.Fragment>
  )
}

UserTasksDashboard.propTypes = {
  assignedTasks: PropTypes.array.isRequired,
  cancelledToggled: PropTypes.func.isRequired,
  careTeamMemberTasks: PropTypes.array.isRequired,
  careTeamMemberTasksFetchRequested: PropTypes.func.isRequired,
  completedToggled: PropTypes.func.isRequired,
  requestedTasks: PropTypes.array.isRequired,
  toggles: PropTypes.instanceOf(Toggles).isRequired,
  userId: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  assignedTasks: getAssignedTasksAsArray(state),
  careTeamMemberTasks: getCareTeamMemberPatientTasksAsArray(state),
  requestedTasks: getRequestedTasksAsArray(state),
  toggles: getToggles(state),
  userId: getUserId(state),
})

export default connect(mapStateToProps, {
  careTeamMemberTasksFetchRequested,
  taskCollapsed,
  taskExpanded,
  cancelledToggled,
  completedToggled,
})(UserTasksDashboard)
