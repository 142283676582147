import { combineEpics, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, first, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  ACTIVE_FILTER_CLEARED,
  ACTIVE_FILTER_SET,
  getActiveFilter,
} from '../data/activeFilter'
import {
  fetchFilteredReferralCases,
  referralCasesStored,
} from '../data/referralCases'
import {
  ASSIGNMENT_PAGE_NAVIGATED,
  DASHBOARD_PAGE_NAVIGATED,
} from '../data/referralManagement'
import { REFERRAL_CASES_REQUESTED } from '../data/referrals'

const fetchAndStoreFilteredCases = (filter: any) =>
  from(fetchFilteredReferralCases(filter)).pipe(
    map(referralCasesStored),
    catchError(showError('Problem loading filtered cases.'))
  )

const loadCasesFromFilter = (action$: any) =>
  action$.pipe(
    ofType(ACTIVE_FILTER_CLEARED, ACTIVE_FILTER_SET),
    first(),
    pluck('filter'),
    mergeMap(fetchAndStoreFilteredCases)
  )

const initialLoad = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DASHBOARD_PAGE_NAVIGATED),
    first(),
    mergeMap(() => {
      const state = state$.value
      const activeFilter = getActiveFilter(state)

      return activeFilter.id === null
        ? loadCasesFromFilter(action$)
        : fetchAndStoreFilteredCases(activeFilter)
    })
  )

const loadOnRequest = (action$: any, state$: any) =>
  action$.pipe(
    ofType(REFERRAL_CASES_REQUESTED),
    mergeMap(() => {
      const state = state$.value
      const activeFilter = getActiveFilter(state)

      return fetchAndStoreFilteredCases(activeFilter)
    })
  )

const loadAssignmentCases = (action$: any) =>
  action$.pipe(
    ofType(ASSIGNMENT_PAGE_NAVIGATED),
    first(),
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    mergeMap(() => fetchAndStoreFilteredCases())
  )

export default combineEpics(initialLoad, loadOnRequest, loadAssignmentCases)
