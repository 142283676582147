import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const PHARMACIST_DASHBOARD_HOME = `${key}.home`
export const PHARMACIST_DASHBOARD_CALENDAR = `${key}.calendar`
export const PHARMACIST_DASHBOARD_EPISODES_OF_CARE = `${key}.episodesOfCare`
export const PHARMACIST_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const PHARMACIST_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const PHARMACIST_DASHBOARD_PATIENT_LIST = `${key}.patientList`
export const PHARMACIST_DASHBOARD_HIGH_RISK_HUDDLE = `${key}.highRiskHuddle`
export const PHARMACIST_DASHBOARD_MY_LISTS = `${key}.myLists`

export const PHARMACIST_DASHBOARD_TASKS_ROUTES = [
  PHARMACIST_DASHBOARD_TASKS_BY_USER,
  PHARMACIST_DASHBOARD_TASK_DETAIL,
]

export default Router([
  Route(PHARMACIST_DASHBOARD_HOME, '/:userId?/home'),
  Route(PHARMACIST_DASHBOARD_CALENDAR, '/:userId?/calendar'),
  Route(PHARMACIST_DASHBOARD_EPISODES_OF_CARE, '/:userId?/episodes-of-care'),
  Route(PHARMACIST_DASHBOARD_TASKS_BY_USER, '/:userId?/tasks'),
  Route(PHARMACIST_DASHBOARD_TASK_DETAIL, '/:userId?/tasks/:taskId'),
  Route(PHARMACIST_DASHBOARD_PATIENT_LIST, '/:userId?/patient-list'),
  Route(PHARMACIST_DASHBOARD_HIGH_RISK_HUDDLE, '/:userId?/high-risk-huddle'),
  Route(PHARMACIST_DASHBOARD_MY_LISTS, '/:userId?/my-lists'),
  Redirect(PHARMACIST_DASHBOARD_HOME),
])
