export default function parseMapBounds(boundaries) {
  const pageBounds = boundaries.replace(/[()]/g, '').split(',')

  return (
    pageBounds[1] +
    ' ' +
    pageBounds[2] +
    ',' +
    pageBounds[3] +
    ' ' +
    pageBounds[2] +
    ',' +
    pageBounds[3] +
    ' ' +
    pageBounds[0] +
    ',' +
    pageBounds[1] +
    ' ' +
    pageBounds[0] +
    ',' +
    pageBounds[1] +
    ' ' +
    pageBounds[2]
  )
}
