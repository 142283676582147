import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { into } from '~/utils/data'
import { getAttachmentsRoot } from './common/shared'

/* Record */
const AttachmentType = Record({
  id: null,
  name: null,
})

/* Constants */
const key = 'attachmentTypes'
const ATTACHMENT_TYPES_FETCHED = 'S3/ATTACHMENT_TYPES_FETCHED'

/* Action Creators */
const loadAttachmentTypes = types => ({ type: ATTACHMENT_TYPES_FETCHED, types })

export const fetchAttachmentTypes = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  operation: () => AspireAPI.get('/v1/attachments/type'),
  transform: into(AttachmentType, 'id'),
  messages: {
    failed: 'There was a problem fetching attachment types',
  },
})

/* Reducer */
export default createReducer(key, Map(), {
  [fetchAttachmentTypes.SUCCEEDED]: (state, { payload }) => payload,
})

/* Selectors */
const getAttachmentTypes = createSelector(
  [state => getAttachmentsRoot(state).get(key)],
  file => file
)

const getOrderedAttachmentTypes = createSelector(
  [state => getAttachmentsRoot(state).get(key)],
  file =>
    file
      .toIndexedSeq()
      .toJS()
      .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : -1))
)

export {
  AttachmentType,
  ATTACHMENT_TYPES_FETCHED,
  loadAttachmentTypes,
  getAttachmentTypes,
  getOrderedAttachmentTypes,
}
