import { Map } from 'immutable'
import { nth } from 'lodash'
import { ofType } from 'redux-observable'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'

export const key = 'connectivity'

const scoped = scopedCreator(key)
export const connected = scoped(
  'CONNECTED',
  false,
  ({ shouldDisplayMessage = true } = {}) =>
    shouldDisplayMessage && { message: 'Entering online mode' }
)
export const disconnected = scoped('DISCONNECTED', false, () => ({
  message: 'Entering offline mode',
}))

const initialState = Map({
  online: false,
  lastOnline: null,
})

export default createReducer(key, initialState, {
  [connected]: state => state.set('online', true),
  [disconnected]: state =>
    state.set('online', false).set('lastOnline', Date.now()),
})

export const getConnectivity = get(key)
export const isOnline = pipe(getConnectivity, get('online'))
export const isOffline = pipe(isOnline, o => !o)
export const lastOnline = pipe(getConnectivity, get('lastOnline'))
export const neverOnline = pipe(
  getConnectivity,
  conn => !conn.get('online') && !conn.get('lastOnline')
)

export const onConnect = () => action$ => action$.pipe(ofType(connected))
export const onDisconnect = () => action$ => action$.pipe(ofType(disconnected))
export const onReconnect = state$ => action$ =>
  action$.pipe(
    ofType(connected),
    withLatestFrom(state$),
    map(nth(1)),
    filter(lastOnline)
  )
