import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'selectedHealthPlans'

export const HEALTH_PLAN_SELECTED = type(rootKey, 'HEALTH_PLAN_SELECTED')

export const setSelectedHealthPlans = creator(HEALTH_PLAN_SELECTED, 'hpIds')

export default createReducer(key, new Set(), {
  [HEALTH_PLAN_SELECTED]: (state, { payload: { hpIds } }) => new Set(hpIds),
})

export const getSelectedHealthPlans = pipe(getRoot, get(key))
