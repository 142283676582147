import classNames from 'classnames'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Collapse, Icon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing, palette }) => ({
  root: {
    overflow: 'hidden',
    marginTop: spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: [spacing(0.5), spacing(1.5)],
    backgroundColor: palette.divider,
    borderRadius: 2,
    borderBottomLeftRadius: 0,
  },
  clickable: {
    cursor: 'pointer',
  },
  title: {
    flex: 1,
  },
  titleMandatory: {
    flex: 1,
    color: palette.severity.warning.dynamic,
  },
  description: {
    marginBottom: spacing(0.5),
  },
  contentWrapper: {
    borderLeft: [2, 'solid', palette.divider],
  },
  content: {
    paddingLeft: 8,
  },
  bottomContent: {
    display: 'flex',
    padding: spacing(1),
  },
})

class FieldWrapper extends React.Component {
  state = {
    collapsed: get(
      this.props.uiSchema,
      ['ui:options', 'defaultCollapsed'],
      true
    ),
  }

  toggleCollapsed = () =>
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }))

  render() {
    const { classes, children, bottomContent, schema, uiSchema } = this.props
    const { collapsed } = this.state
    const options = uiSchema['ui:options'] || {}
    const title = schema.title || uiSchema['ui:title']
    const description = schema.description || uiSchema['ui:description']
    const displayLabel = 'displayLabel' in options ? options.displayLabel : true
    const collapsible = 'collapsible' in options ? options.collapsible : false
    const mandatorySection = 'mandatory' in options ? options.mandatory : false
    const expanded = !collapsible || !collapsed
    const previousDateScore =
      get(uiSchema, 'previous.ui:options.content', '') ||
      get(uiSchema, 'cover.previous.ui:options.content', '')

    const headerClassName = classNames(classes.header, {
      [classes.clickable]: collapsible,
    })
    const sectionClassName = mandatorySection
      ? classes.titleMandatory
      : classes.title

    if (!displayLabel || !title) {
      return children
    }

    return (
      <div
        data-track-content
        data-content-name="Field Wrapper"
        className={classes.root}
      >
        <div
          className={headerClassName}
          onClick={collapsible ? this.toggleCollapsed : undefined}
        >
          <div className={sectionClassName}>
            {title && (
              <Typography data-content-piece={title} variant="subtitle1">
                {mandatorySection ? title + '*' : title}
                {previousDateScore}
              </Typography>
            )}
            {description && (
              <Typography
                data-content-piece={description}
                className={classes.description}
                variant="caption"
              >
                {description}
              </Typography>
            )}
          </div>
          {collapsible && (
            <Icon data-content-piece="collapse button" color="action">
              {collapsed ? 'expand_more' : 'expand_less'}
            </Icon>
          )}
        </div>
        <div className={classes.contentWrapper}>
          <Collapse classes={{ wrapperInner: classes.content }} in={expanded}>
            {children}
          </Collapse>
          {bottomContent && (
            <Collapse in={expanded}>
              <div className={classes.bottomContent}>{bottomContent}</div>
            </Collapse>
          )}
        </div>
      </div>
    )
  }
}

FieldWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  bottomContent: PropTypes.node,
}

export default withStyles(styles)(FieldWrapper)
