import React, { PureComponent } from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import MarkerContext from './MarkerContext'
import mapPropTypes from './mapPropTypes'
import { createListener, createPropsToOptions } from './objectHelpers'
import withGoogle from './withGoogle'
import withMap from './withMap'

const propsToOptions = createPropsToOptions(props => ({
  position:
    props.position?.lat && props.position?.lng
      ? props.position
      : { lat: 0, lng: 0 },
  icon: props.icon,
  opacity: props.opacity,
  zIndex: props.zIndex,
}))

class Marker extends PureComponent {
  constructor(props) {
    super(props)

    const options = propsToOptions(props)
    const marker = new props.google.maps.Marker(options)

    marker.setMap(props.map)
    marker.addListener('click', createListener(this, 'onClick'))
    marker.addListener('rightclick', createListener(this, 'onRightClick'))
    marker.addListener('mouseover', createListener(this, 'onMouseover'))
    marker.addListener('mouseout', createListener(this, 'onMouseout'))

    this.state = { marker }
  }

  componentDidMount() {
    this.updateBounds()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.position !== this.props.position ||
      prevProps.icon !== this.props.icon ||
      prevProps.opacity !== this.props.opacity
    ) {
      this.state.marker.setOptions(propsToOptions(this.props, prevProps))
    }

    if (prevProps.position !== this.props.position) {
      this.updateBounds(this.props)
    }
  }

  componentWillUnmount() {
    this.state.marker.setMap(null)
    this.updateBounds({ ...this.props, position: null })
    this.props.google.maps.event.clearInstanceListeners(this.state.marker)
  }

  updateBounds = (
    { google, position, setBounds, deleteBounds } = this.props
  ) => {
    if (position?.lat && position?.lng) {
      const markerBounds = new google.maps.LatLngBounds(position, position)
      setBounds(this.state.marker, markerBounds)
    } else {
      deleteBounds(this.state.marker)
    }
  }

  render() {
    const { children } = this.props
    const { marker } = this.state

    return (
      <div>
        {marker ? (
          <MarkerContext.Provider value={{ marker }}>
            {children}
          </MarkerContext.Provider>
        ) : null}
      </div>
    )
  }
}

Marker.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object,
  setBounds: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  deleteBounds: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  children: PropTypes.node,
  position: mapPropTypes.latLng,
  icon: mapPropTypes.icon,
  opacity: PropTypes.number,
  zIndex: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  onClick: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onRightClick: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onMouseover: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onMouseout: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
}

export default compose(withMap, withGoogle)(Marker)
