import { Record } from 'immutable'
import { get } from '~/utils/data'
import key from '../../key'

// RECORDS
export const Stakeholder = Record({
  id: null,
  sfid: null,
  name: null,
  phone: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  title: null,
  practiceName: null,
  physicianNotes: null,
  reportTypes: null,
  createdBy: null,
  createdAt: null,
})

// SELECTORS
export const getPatientRecord = get(key)
