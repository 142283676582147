import { combineEpics } from 'redux-observable'
import census from './epics/census'
import cmIdChanged from './epics/cmIdChanged'
import hraOutreach from './epics/hraOutreach'
import intervention from './epics/intervention'
import loadDataForUser from './epics/loadDataForUser'
import taskSelected from './epics/taskSelected'

export default combineEpics(
  census,
  cmIdChanged,
  taskSelected,
  loadDataForUser,
  intervention,
  hraOutreach
)
