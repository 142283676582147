import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { PATIENT_RECORD_ORDERS_INDEX } from '~/apps/patientRecord/router'
import { patientOrdersFetchRequested } from '~/features/orders/data/loaders'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_ORDERS_INDEX)),
    switchTo(state$),
    map(getPatientId),
    map(patientOrdersFetchRequested)
  )
