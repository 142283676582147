import { OrderedMap, Record } from 'immutable'
// @ts-expect-error fix me
import Request from '~/utils/Request'
import { get, into } from '~/utils/data'
import AspireAPI from '~/resources/aspire'
import key from '../key'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getRoot } from './common/shared'

const CONFIG_OPTIONS = 'configOptions'

export const ConfigOption = Record({
  id: null,
  description: null,
  isSubmitDisabled: null,
})

export type ConfigOptionType = {
  id: number
  description: string
  isSubmitDisabled: boolean
}

export const fetchConfigOptions = Request({
  typePrefix: key,
  typeBase: 'FETCH_CONFIG_OPTIONS',
  operation: () => AspireAPI.get('claims/config'),
  transform: into(ConfigOption, 'description', OrderedMap),
  messages: {
    failed: 'There was an issue fetching Coding Queue Config options',
  },
})

export const getConfigOptions = pipe(getRoot, get(CONFIG_OPTIONS))

export default createReducer(CONFIG_OPTIONS, OrderedMap(), {
  [fetchConfigOptions.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
