import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { fetchPatientTimeline } from '~/features/patientTimeline/data'
import { saveManualTimelineEntry } from '../data/timelineEntryDialog'

export default action$ =>
  action$.pipe(
    ofType(saveManualTimelineEntry.SUCCEEDED),
    pluck('payload'),
    map(fetchPatientTimeline.requested)
  )
