import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import UserSelector from '~/components/UserSelector'
import { getUserId } from '~/data/session'
import { getUserById } from '~/data/users'
import PropTypes from '~/utils/propTypes'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CalendarWidget from './CalendarWidget'

const useStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    color: palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.23)',
    marginLeft: spacing(2),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  calendarHead: {
    alignItems: 'center',
    backgroundColor: palette.calendarHead.backgroundColor,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: spacing(2),
  },
  name: {
    color: palette.common.white,
    flexBasis: '100%',
    margin: [0, 'auto', spacing(1)],
    textAlign: 'center',
  },
  selectorContainer: {
    display: 'flex',
    margin: [0, 'auto', spacing(1)],
  },
  userSelector: {
    minWidth: '200px',
    maxHeight: 36,
    position: 'relative',
    top: -12,
    '& label': {
      color: palette.common.white,
    },
    '& input': {
      color: palette.common.white,
    },
  },
  '@media (min-width: 700px)': {
    name: {
      flex: '1 1 0',
      margin: 0,
      textAlign: 'left',
    },
    selectorContainer: {
      justifyContent: 'flex-end',
      margin: 0,
    },
  },
}))

const CalendarImpersonator = ({ userId, userFilter }) => {
  const classes = useStyles()

  const [selectedUserId, setSelectedUserId] = useState(userId)
  const sessionUser = useSelector(state => getUserId(state))
  const selectedUserInfo = useSelector(state =>
    getUserById(state, selectedUserId)
  )

  const onUserSelected = userId => {
    setSelectedUserId(userId)
  }

  const onUserCleared = () => {
    setSelectedUserId(sessionUser)
  }

  useEffect(() => {
    if (userId) setSelectedUserId(userId)
  }, [userId, setSelectedUserId])

  return (
    <div>
      <div className={classes.calendarHead}>
        <Typography className={classes.name} variant="h6">
          {`${selectedUserInfo.name}'s Calendar` || 'Calendar'}
        </Typography>
        <div className={classes.selectorContainer}>
          <div className={classes.userSelector}>
            <UserSelector
              onUserCleared={onUserCleared}
              onUserSelected={onUserSelected}
              userFilter={userFilter}
            />
          </div>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => onUserSelected(sessionUser)}
          >
            My Calendar
          </Button>
        </div>
      </div>

      <CalendarWidget defaultView="month" editing userId={selectedUserId} />
    </div>
  )
}

CalendarImpersonator.propTypes = {
  userFilter: PropTypes.func,
  userId: PropTypes.string,
}

export default CalendarImpersonator
