import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { flow } from 'fp-ts/lib/function'
import { Iterable } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-form` if it exists o... Remove this comment to see the full error message
import { reducer as form } from 'redux-form/immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers as combineImmutableReducers } from 'redux-immutable'
import adminTools from '~/apps/adminTools/reducer'
import admissionsReconciliation from '~/apps/admissionsReconciliation/reducer'
import appDashboard from '~/apps/appDashboard/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/apps/assessment/reducer' or ... Remove this comment to see the full error message
import assessment from '~/apps/assessment/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/apps/caseManagerDashboard/reducer' o... Remove this comment to see the full error message
import caseManagerDashboard from '~/apps/caseManagerDashboard/reducer'
import cdDashboard from '~/apps/clinicalDirectorDashboard/reducer'
import coderDashboard from '~/apps/coderDashboard/reducer'
import communityHWDashboard from '~/apps/communityHWDashboard/reducer'
import contactManagement from '~/apps/contactManagement/reducer'
import cssDashboard from '~/apps/cssDashboard/reducer'
import formsDevelopment from '~/apps/formsDevelopment/reducer'
import iocDashboard from '~/apps/iocDashboard/reducer'
import nurseDashboard from '~/apps/nurseDashboard/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/apps/patientRecord/reducer' ... Remove this comment to see the full error message
import patientRecord from '~/apps/patientRecord/reducer'
import {
  reducer as APPOption,
  init as appOption,
} from '~/apps/pesDashboard/data/appOptions'
import pesDashboard from '~/apps/pesDashboard/reducer'
import pharmacistDashboard from '~/apps/pharmacistDashboard/reducer'
import physicianDashboard from '~/apps/physicianDashboard/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/apps/polygonator' or its co... Remove this comment to see the full error message
import polygonator from '~/apps/polygonator/reducer'
import referralManagement from '~/apps/referralManagement/reducer'
import reportsDashboard from '~/apps/reportsDashboard/reducer'
import rpm from '~/apps/rpm/reducer'
import swDashboard from '~/apps/swDashboard/reducer'
import transportation from '~/apps/transportation/reducer'
import userSettings from '~/apps/userSettings/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/appLayout' or its corre... Remove this comment to see the full error message
import panelOpen from '~/data/appLayout'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/apps' or its correspond... Remove this comment to see the full error message
import apps from '~/data/apps'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/cache' or its correspon... Remove this comment to see the full error message
import cache from '~/data/cache'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/careTeams' or its corre... Remove this comment to see the full error message
import careTeams from '~/data/careTeams'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/channels' or its corres... Remove this comment to see the full error message
import channels from '~/data/channels'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/connectivity' or its co... Remove this comment to see the full error message
import connectivity from '~/data/connectivity'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/encounterTypes' or its ... Remove this comment to see the full error message
import encounterTypes from '~/data/encounterTypes'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/epicError' or its corre... Remove this comment to see the full error message
import epicError from '~/data/epicError'
import fieldValues from '~/data/fieldValues'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/healthplans' or its cor... Remove this comment to see the full error message
import healthplans from '~/data/healthplans'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/markets' or its corresp... Remove this comment to see the full error message
import markets from '~/data/markets'
import messages from '~/data/messages'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/patientPanel' or its co... Remove this comment to see the full error message
import patientPanel from '~/data/patientPanel'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/pending' or its corresp... Remove this comment to see the full error message
import pending from '~/data/pending'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/physicians' or its corr... Remove this comment to see the full error message
import physicians from '~/data/physicians'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/route' or its correspon... Remove this comment to see the full error message
import route from '~/data/route'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/session' or its corresp... Remove this comment to see the full error message
import session from '~/data/session'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/sessionExtensionDialog'... Remove this comment to see the full error message
import sessionExtensionDialog from '~/data/sessionExtensionDialog'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/settings' or its corres... Remove this comment to see the full error message
import settings from '~/data/settings'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/userRoles' or its corre... Remove this comment to see the full error message
import userRoles from '~/data/userRoles'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/users' or its correspon... Remove this comment to see the full error message
import users from '~/data/users'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/vendors' or its corresp... Remove this comment to see the full error message
import vendors from '~/data/vendors'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/actionableItems' or... Remove this comment to see the full error message
import { reducer as actionableItems } from '~/features/actionableItems'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/addTimelineEntry' o... Remove this comment to see the full error message
import { reducer as addTimelineEntry } from '~/features/addTimelineEntry'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/appFinder/reducer' or its c... Remove this comment to see the full error message
import { default as appFinder } from '~/features/appFinder/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/attachments' or its... Remove this comment to see the full error message
import { reducer as attachments } from '~/features/attachments'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/attachmentsNextGen' or its... Remove this comment to see the full error message
import { reducer as attachmentsNextGen } from '~/features/attachmentsNextGen'
import {
  reducer as AuthToRelease,
  init as authToRelease,
} from '~/features/authToRelease/data/form'
import { reducer as authorization } from '~/features/authorization'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/authorizations' or ... Remove this comment to see the full error message
import { default as authorizations } from '~/features/authorizations/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/calendarWidget' or ... Remove this comment to see the full error message
import { reducer as calendarWidget } from '~/features/calendarWidget'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/callList' or its co... Remove this comment to see the full error message
import { reducer as callList } from '~/features/callList'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/callLogger' or its ... Remove this comment to see the full error message
import { reducer as callLogger } from '~/features/callLogger'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/calls' or its corre... Remove this comment to see the full error message
import { reducer as call } from '~/features/calls'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/carePlan' or its corre... Remove this comment to see the full error message
import { reducer as carePlan } from '~/features/carePlan'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/careTeamManagement'... Remove this comment to see the full error message
import { reducer as careTeamManagement } from '~/features/careTeamManagement'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/caremoreLogCommunication' or ... Remove this comment to see the full error message
import { default as caremoreLogCommunication } from '~/features/caremoreLogCommunication/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/communications'... Remove this comment to see the full error message
import { reducer as commandPalette } from '~/features/commandPalette'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/commandPalette' or ... Remove this comment to see the full error message
import communications from '~/features/communications/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/consentForServices'... Remove this comment to see the full error message
import { reducer as consentForServices } from '~/features/consentForServices'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/contacts' or its co... Remove this comment to see the full error message
import { reducer as contacts } from '~/features/contacts'
import { default as discharges } from '~/features/discharges/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/encounterWidget' or... Remove this comment to see the full error message
import { reducer as encounterWidget } from '~/features/encounterWidget'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/episodesOfCare' or ... Remove this comment to see the full error message
import { reducer as episodesOfCare } from '~/features/episodesOfCare/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/erVisits' or ... Remove this comment to see the full error message
import { default as erVisits } from '~/features/erVisits/reducer'
import { default as fetchFailedFaxes } from '~/features/faxes/reducer'
import { default as generateDocument } from '~/features/generateDocument/reducer'
import { default as highRiskHuddle } from '~/features/highRiskHuddle/reducer'
import { reducer as HIPAA, init as hipaa } from '~/features/hipaa/data/form'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/hospitalizations' o... Remove this comment to see the full error message
import { reducer as hospitalizations } from '~/features/hospitalizations'
import { reducer as imo } from '~/features/imo'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/insuranceManagement... Remove this comment to see the full error message
import { reducer as insuranceManagement } from '~/features/insuranceManagement'
import { reducer as LLOS, init as llos } from '~/features/llos/LLOS'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/ltcs' or its corres... Remove this comment to see the full error message
import { reducer as ltcs } from '~/features/ltcs'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/medications' or its... Remove this comment to see the full error message
import { reducer as medications } from '~/features/medications'
import { reducer as nextTargetedVisitDate } from '~/features/nextTargetedVisitDate'
import {
  reducer as NoticeOfPrivacyPractices,
  init as noticeOfPrivacyPractices,
} from '~/features/noticeOfPrivacyPractices/data/form'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/notifications' or i... Remove this comment to see the full error message
import { reducer as notifications } from '~/features/notifications'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/onCall' or its corr... Remove this comment to see the full error message
import { reducer as onCall } from '~/features/onCall'
import { reducer as openGaps } from '~/features/openGaps'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/orders' or its corr... Remove this comment to see the full error message
import { reducer as orders } from '~/features/orders'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientAlerts' or i... Remove this comment to see the full error message
import { reducer as alerts } from '~/features/patientAlerts'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientCensus' or i... Remove this comment to see the full error message
import { reducer as patientCensus } from '~/features/patientCensus'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientInfo' or its... Remove this comment to see the full error message
import { reducer as patientInfo } from '~/features/patientInfo'
import {
  reducer as Covid19,
  init as covid19,
} from '~/features/patientInfo/data/covid19'
import labDetails from '~/features/patientLabs/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/patientTimeline' or... Remove this comment to see the full error message
import { default as patientTimeline } from '~/features/patientTimeline/data'
import {
  reducer as PlaceOfService,
  init as placeOfService,
} from '~/features/placeOfService/data'
import { default as problems } from '~/features/problems/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/quickCallLogger' or... Remove this comment to see the full error message
import { default as quickCallLogger } from '~/features/quickCallLogger/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/research' or its co... Remove this comment to see the full error message
import { reducer as research } from '~/features/research'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/scheduling' or its ... Remove this comment to see the full error message
import { default as scheduling } from '~/features/scheduling/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/screenings' or its ... Remove this comment to see the full error message
import screenings from '~/features/screenings/reducer'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/search' or its corr... Remove this comment to see the full error message
import { reducer as search } from '~/features/search'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/snfs' or its corres... Remove this comment to see the full error message
import { reducer as snfs } from '~/features/snfs'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/stakeholders' or it... Remove this comment to see the full error message
import { reducer as stakeholders } from '~/features/stakeholders'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/tasks' or its corre... Remove this comment to see the full error message
import { reducer as tasks } from '~/features/tasks'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/unengaged' or its c... Remove this comment to see the full error message
import { reducer as unengaged } from '~/features/unengaged'
import {
  reducer as UserTimelineEvent,
  init as userTimelineEvents,
} from '~/features/userTimeline/data/userTimelineEvents'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/videoVisitConsent'... Remove this comment to see the full error message
import { reducer as videoVisitConsent } from '~/features/videoVisitConsent'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/vitals' or its corr... Remove this comment to see the full error message
import { reducer as vitalsChart } from '~/features/vitals'
import { Action, State } from '~/reducerT'
import { Action as Toast } from '~/utils/toast'

// import Aspire from '~/resources/aspire-strict'
// // Use
// const myIndex: Request = Request<Person>(
//   Aspire.get("/problems", Person.strictType.decode),
//   payload => Action.of.Person({ payload }),
//   // {
//   //   reloader: State.lensFromPath(['problemCategories']).get,
//   //   toast: Toast.success("Stuff and things")
//   // }
// )

const init = State.build({
  appOption,
  userTimelineEvents,
  toasts: [],
  placeOfService,
  covid19,
  llos,
  noticeOfPrivacyPractices,
  authToRelease,
  hipaa,
})
const tsReducer = flow(
  Action.createReducer(init)({
    APPOption,
    UserTimelineEvent,
    PlaceOfService,
    Covid19,
    LLOS,
    NoticeOfPrivacyPractices,
    AuthToRelease,
    HIPAA,
    // compose your child reducers here!
    // Person: (_a) => (s) => s,
    // Place: (_a) => (s) => s,

    // NOTE: These are generic reducers meant for more global functionality.
    Request: _a => s => s,
    Toast: ({ payload }) =>
      Toast.matchStrict({
        Push: ({ payload }) => (state: State) => ({
          ...state,
          toasts: [payload, ...state.toasts],
        }),
        Pop: _ => (state: State) => ({
          ...state,
          toasts: O.getOrElseW(() => [])(A.tail(state.toasts)),
        }),
      })(payload),
  }),
  Iterable
)

// Please play nice and alphabetize your reducers
export default combineImmutableReducers({
  tsReducer: (s: Iterable.Indexed<State> | undefined, a: Action) => {
    return tsReducer(s?.toJS(), a)
  },
  [actionableItems.key]: actionableItems,
  [addTimelineEntry.key]: addTimelineEntry,
  [adminTools.key]: adminTools,
  [admissionsReconciliation.key]: admissionsReconciliation,
  [alerts.key]: alerts,
  [appDashboard.key]: appDashboard,
  [appFinder.key]: appFinder,
  [apps.key]: apps,
  [assessment.key]: assessment,
  [attachments.key]: attachments,
  [attachmentsNextGen.key]: attachmentsNextGen,
  [authorization.key]: authorization,
  [authorizations.key]: authorizations,
  [cache.key]: cache,
  [calendarWidget.key]: calendarWidget,
  [call.key]: call,
  [callList.key]: callList,
  [callLogger.key]: callLogger,
  [caremoreLogCommunication.key]: caremoreLogCommunication,
  [carePlan.key]: carePlan,
  [careTeamManagement.key]: careTeamManagement,
  [careTeams.key]: careTeams,
  [caseManagerDashboard.key]: caseManagerDashboard,
  [channels.key]: channels,
  [cdDashboard.key]: cdDashboard,
  [coderDashboard.key]: coderDashboard,
  [commandPalette.key]: commandPalette,
  [communications.key]: communications,
  [communityHWDashboard.key]: communityHWDashboard,
  [connectivity.key]: connectivity,
  [consentForServices.key]: consentForServices,
  [contactManagement.key]: contactManagement,
  [contacts.key]: contacts,
  [cssDashboard.key]: cssDashboard,
  [discharges.key]: discharges,
  [encounterTypes.key]: encounterTypes,
  [encounterWidget.key]: encounterWidget,
  [epicError.key]: epicError,
  [episodesOfCare.key]: episodesOfCare,
  [erVisits.key]: erVisits,
  [fetchFailedFaxes.key]: fetchFailedFaxes,
  [fieldValues.key]: fieldValues,
  [formsDevelopment.key]: formsDevelopment,
  [generateDocument.key]: generateDocument,
  [healthplans.key]: healthplans,
  [highRiskHuddle.key]: highRiskHuddle,
  [hospitalizations.key]: hospitalizations,
  [insuranceManagement.key]: insuranceManagement,
  [iocDashboard.key]: iocDashboard,
  [imo.key]: imo,
  [ltcs.key]: ltcs,
  [markets.key]: markets,
  [medications.key]: medications,
  [messages.key]: messages,
  [nextTargetedVisitDate.key]: nextTargetedVisitDate,
  [notifications.key]: notifications,
  [nurseDashboard.key]: nurseDashboard,
  [onCall.key]: onCall,
  [openGaps.key]: openGaps,
  [orders.key]: orders,
  [panelOpen.key]: panelOpen,
  [patientCensus.key]: patientCensus,
  [patientInfo.key]: patientInfo,
  [patientPanel.key]: patientPanel,
  [patientRecord.key]: patientRecord,
  [patientTimeline.key]: patientTimeline,
  [pending.key]: pending,
  [pesDashboard.key]: pesDashboard,
  [pharmacistDashboard.key]: pharmacistDashboard,
  [physicianDashboard.key]: physicianDashboard,
  [physicians.key]: physicians,
  [polygonator.key]: polygonator,
  [problems.key]: problems,
  [quickCallLogger.key]: quickCallLogger,
  [referralManagement.key]: referralManagement,
  [reportsDashboard.key]: reportsDashboard,
  [research.key]: research,
  [route.key]: route,
  [rpm.key]: rpm,
  [scheduling.key]: scheduling,
  [screenings.key]: screenings,
  [search.key]: search,
  [session.key]: session,
  [sessionExtensionDialog.key]: sessionExtensionDialog,
  [settings.key]: settings,
  [snfs.key]: snfs,
  [stakeholders.key]: stakeholders,
  [swDashboard.key]: swDashboard,
  [tasks.key]: tasks,
  [transportation.key]: transportation,
  [unengaged.key]: unengaged,
  [userRoles.key]: userRoles,
  [users.key]: users,
  [userSettings.key]: userSettings,
  [vendors.key]: vendors,
  [videoVisitConsent.key]: videoVisitConsent,
  [labDetails.key]: labDetails,
  [vitalsChart.key]: vitalsChart,
  form,
})
