import EventActions from '../../Events/EventActions'
import UnavailabilityCalendarEvent from './UnavailabilityCalendarEvent'
import UnavailabilityEventDetail from './UnavailabilityEventDetail'
import defaults from './defaults'
import * as info from './info'
import * as permissions from './permissions'

export default {
  ...info,
  type: defaults.type,
  defaults: defaults,
  permissions: permissions,
  detailComponent: UnavailabilityEventDetail,
  calendarComponent: UnavailabilityCalendarEvent,
  actionsComponent: EventActions,
}
