import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { Stakeholder, getRoot } from './common/shared'

const STAKEHOLDERS = 'stakeholders'

export const fetchStakeholders = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_STAKEHOLDERS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`stakeholders/patient/${patientId}`),
  transform: into(Stakeholder, 'id'),
  messages: { failed: 'There was a problem getting stakeholders' },
})

export const addStakeholder = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_STAKEHOLDER',
  requestParams: ['patientId', 'contactId', 'reportTypes'],
  operation: (patientId, contactId, reportTypes) =>
    AspireAPI.post(`stakeholders/patient/${patientId}`, {
      contactId,
      reportTypes,
    }),
  transform: Stakeholder,
  messages: { failed: 'There was a problem adding this stakeholder' },
})

export const saveStakeholder = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_STAKEHOLDER',
  requestParams: ['stakeholderId', 'reportTypes'],
  operation: (stakeholderId, reportTypes) =>
    AspireAPI.put(`stakeholders/${stakeholderId}`, {
      reportTypes,
    }),
  messages: { failed: 'There was a problem saving this stakeholder' },
})

export const deleteStakeholder = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_STAKEHOLDER',
  requestParams: ['stakeholderId'],
  operation: stakeholderId =>
    AspireAPI.post(`stakeholders/${stakeholderId}/delete`),
  messages: { failed: 'There was a problem deleting this stakeholder' },
})

const initState = Map()

export default createReducer(STAKEHOLDERS, initState, {
  [fetchStakeholders.REQUESTED]: () => initState,
  [fetchStakeholders.SUCCEEDED]: (_state, { payload }) => payload,
  [addStakeholder.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [saveStakeholder.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.setIn([payload.stakeholderId, 'reportTypes'], payload.reportTypes),
  [deleteStakeholder.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.delete(payload.stakeholderId),
})

export const getStakeholders = pipe(getRoot, get(STAKEHOLDERS))
