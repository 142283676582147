import { combineEpics } from 'redux-observable'
import addProblemDetails from './epics/addProblemDetails'
import assessmentCancelled from './epics/assessmentCancelled'
import assessmentSaveButtonClicked from './epics/assessmentSaveButtonClicked'
import assessmentSelected from './epics/assessmentSelected'
import assessmentSigned from './epics/assessmentSigned'
import assessmentStarted from './epics/assessmentStarted'
import autoSaveAssessment from './epics/autoSaveAssessment'
import blockNavigation from './epics/blockNavigation'
import cacheAssessment from './epics/cacheAssessment'
import displayCheckInWarningSnackbar from './epics/displayCheckInWarningSnackbar'
import downloadDocument from './epics/downloadDocument'
import downloadMedicationList from './epics/downloadMedicationList'
import downloadSlums from './epics/downloadSlums'
import fetchAssessmentSucceeded from './epics/fetchAssessmentSucceeded'
import fetchPatientInfo from './epics/fetchPatientInfo'
import initializeCarePlan from './epics/initializeCarePlan'
import navigateToSwitchedEncounter from './epics/navigateToSwitchedEncounter'
import navigation from './epics/navigation'
import persistLocalAssessment from './epics/persistLocalAssessment'
import problemInvalidated from './epics/problemInvalidated'
import removeLocalAssessment from './epics/removeLocalAssessment'
import saveAfterConnect from './epics/saveAfterConnect'
import { updateCarePlan, updateProblems } from './epics/updateCarePlan'

export default combineEpics(
  addProblemDetails,
  downloadSlums,
  downloadMedicationList,
  assessmentCancelled,
  assessmentSaveButtonClicked,
  assessmentSelected,
  assessmentSigned,
  assessmentStarted,
  autoSaveAssessment,
  blockNavigation,
  cacheAssessment,
  downloadDocument,
  displayCheckInWarningSnackbar,
  fetchAssessmentSucceeded,
  fetchPatientInfo,
  initializeCarePlan,
  navigation,
  persistLocalAssessment,
  problemInvalidated,
  removeLocalAssessment,
  saveAfterConnect,
  updateCarePlan,
  updateProblems,
  navigateToSwitchedEncounter
)
