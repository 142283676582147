import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const IPAS = 'IPAs'

export const IPA = Record({
  id: null,
  name: null,
  phone: null,
  fax: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  ceoAdministrator: null,
  nursingLeader: null,
  coo: null,
  physicianLeader: null,
  champion: null,
  boardOfDirectors: null,
  notes: null,
  nextTargetedContactDate: null,
})

export const fetchIPAs = Request({
  typePrefix: key,
  typeBase: 'FETCH_IPAS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/ipas`, {
      params: { searchWord },
    }),
  transform: into(IPA, 'id'),
  messages: { failed: 'There was a problem fetching IPAs' },
})

export const fetchIPA = Request({
  typePrefix: key,
  typeBase: 'FETCH_IPA',
  requestParams: ['ipaId'],
  operation: ipaId => AspireAPI.get(`contacts/ipas/${ipaId}`),
  transform: IPA,
  messages: { failed: 'There was a problem fetching this IPA' },
})

export const addIPA = Request({
  typePrefix: key,
  typeBase: 'ADD_IPA',
  requestParams: ['ipa'],
  operation: ipa => AspireAPI.post('contacts/ipas', { ipa }),
  messages: {
    succeeded: 'Successfully added new IPA',
    failed: 'There was a problem adding this IPA',
  },
})

export const saveIPA = Request({
  typePrefix: key,
  typeBase: 'SAVE_IPA',
  requestParams: ['ipa'],
  operation: ipa => AspireAPI.put(`contacts/ipas/${ipa.id}`, { ipa }),
  messages: {
    succeeded: 'Successfully saved IPA',
    failed: 'There was a problem saving this IPA',
  },
})

export const deleteIPA = Request({
  typePrefix: key,
  typeBase: 'DELETE_IPA',
  requestParams: ['ipaId'],
  operation: ipaId => AspireAPI.delete(`contacts/ipas/${ipaId}`),
  messages: {
    succeeded: 'Successfully deleted IPA',
    failed: 'There was a problem deleting this IPA',
  },
})

const creator = scopedCreator(key)
export const ipasCleared = creator('IPAS_CLEARED')

const initState = Map()
export default createReducer(IPAS, initState, {
  [fetchIPAs.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchIPA.SUCCEEDED]: (state, { payload }) => state.set(payload.id, payload),
  [saveIPA.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { ipa },
        },
      },
    }
  ) => state.set(ipa.id, ipa),
  [deleteIPA.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { ipaId },
        },
      },
    }
  ) => state.delete(ipaId),
  [ipasCleared]: () => initState,
})

export const getIPAs = pipe(getRoot, get(IPAS))

export const getIPAsArr = createSelector([getIPAs], hospices =>
  hospices.toIndexedSeq().toArray()
)

export const getIPAById = (state, id) => getIPAs(state).get(id, IPA())
