// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import nextTargetedVisitDate from './data/nextTargetedVisitDate'
import nextTargetedVisitDateDialog from './data/nextTargetedVisitDateDialog'
import key from './key'

const reducer = combineReducers({
  [nextTargetedVisitDateDialog.key]: nextTargetedVisitDateDialog,
  [nextTargetedVisitDate.key]: nextTargetedVisitDate,
})

reducer.key = key

export default reducer
