import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const PATIENT_SNOOZE_REASONS = 'patientSnoozeReasons'

const PatientSnoozeReason = Record({
  value: null,
  label: null,
  order: null,
})

const transformPatientSnoozeReasons = (data: any) =>
  List(
    data.map((item: any) => PatientSnoozeReason({ ...item, value: item.code }))
  )

export const fetchPatientSnoozeReasons = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_INCENTIVE_DAY_TYPES',
  operation: () =>
    AspireAPI.get('v1/reason_codes', {
      params: {
        type: 'referral_scheduling_snooze_reason',
      },
    }),
  transform: transformPatientSnoozeReasons,
  messages: {
    failed: 'There was an issue fetching snooze reasons',
  },
})

export default createReducer(PATIENT_SNOOZE_REASONS, List(), {
  [fetchPatientSnoozeReasons.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getPatientSnoozeReasons = pipe(
  getRoot,
  get(PATIENT_SNOOZE_REASONS)
)
