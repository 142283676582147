import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

// CONSTANTS
export const key = 'failedFaxes'

// RECORD
export const Fax = Record({
  createdAt: null,
  faxTrackingId: null,
  id: null,
  internalTrackingId: null,
  type: null,
})

const transformFaxes = (data: any) =>
  data.map((fax: any) =>
    Fax({
      ...fax,
      faxTrackingId: fax.sfaxQueueTrackingId,
      type: fax.application,
    })
  )

// REQUESTS
export const fetchFailedFaxes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_FAILED_FAXES',
  operation: () => AspireAPI.get(`/v1/faxes/failed`),
  transform: transformFaxes,
  messages: {
    failed: 'Failed to fetch faxes',
  },
})

// REDUCER
export default createReducer(key, Map(), {
  [fetchFailedFaxes.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

// SELECTOR
export const getFailedFaxes = pipe(getRoot, get(key))
