import { createSelector } from 'reselect'
import { actions, objects } from '~/features/authorization'
import { getHasPermission } from '~/features/authorization/data/permissions'

const notProduction = __DEV__ || __TEST__

export const getFlags = createSelector(
  [
    state =>
      getHasPermission(state, objects.GENERAL_ORDERS_ACCESS, actions.VIEW),
    state =>
      getHasPermission(state, objects.GENERAL_ORDERS_ACCESS, actions.CREATE),
    state => getHasPermission(state, objects.METRICS, actions.VIEW),
  ],
  (canViewOrders, canCreateOrders, canViewMetrics) => ({
    canViewOrders: canViewOrders,
    canCreateOrders: canCreateOrders,
    canViewMetrics: notProduction || canViewMetrics,
  })
)
