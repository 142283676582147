import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { filter, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import { fetchCarePlanGoals } from '../data/careplanGoals'
import { fetchGoalAssociations } from '../data/goalAssociations'
import { fetchHealthConcerns } from '../data/healthConcern'
import { fetchInterventions } from '../data/intervention'
import { fetchOutcomes } from '../data/outcome'
import { addCareplanGoal, editCareplanGoal } from '../data/careplanGoalsDialog'
import {
  addHealthConcern,
  editHealthConcern,
} from '../data/healthConcernDialog'
import { addIntervention, editIntervention } from '../data/interventionDialog'
import { addOutcome, editOutcome } from '../data/outcomeDialog'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { careplanManagerPanelOpened } from '~/data/patientPanel'
import {
  ADMIN_TOOLS_HEALTH_CONCERNS,
  ADMIN_TOOLS_INTERVENTIONS,
  ADMIN_TOOLS_OUTCOMES,
} from '../router'

export const saveHealthConcern = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addHealthConcern.SUCCEEDED, editHealthConcern.SUCCEEDED),
    switchTo(state$),
    map((state: any) => fetchHealthConcerns.requested(state))
  )

export const panelOpened = (action$: any) =>
  action$.pipe(
    ofType(careplanManagerPanelOpened),
    pluck('payload', 'data'),
    mergeMap((data: any) =>
      of(
        data && data.source == 'careplanGoal'
          ? fetchGoalAssociations.requested(data.id)
          : null
      )
    )
  )

export const fetchInterventionsEpic = (action$: any) =>
  action$.pipe(
    filter(entered(ADMIN_TOOLS_INTERVENTIONS)),
    map(fetchInterventions.requested)
  )

export const fetchHealthConcernsEpic = (action$: any) =>
  action$.pipe(
    filter(entered(ADMIN_TOOLS_HEALTH_CONCERNS)),
    map(fetchHealthConcerns.requested)
  )

export const fetchOutcomesEpic = (action$: any) =>
  action$.pipe(
    filter(entered(ADMIN_TOOLS_OUTCOMES)),
    map(fetchOutcomes.requested)
  )

export const saveIntervention = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addIntervention.SUCCEEDED, editIntervention.SUCCEEDED),
    switchTo(state$),
    map((state: any) => fetchInterventions.requested(state))
  )

export const saveGoal = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addCareplanGoal.SUCCEEDED, editCareplanGoal.SUCCEEDED),
    switchTo(state$),
    map((state: any) => fetchCarePlanGoals.requested(state))
  )

export const saveOutcome = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addOutcome.SUCCEEDED, editOutcome.SUCCEEDED),
    switchTo(state$),
    map((state: any) => fetchOutcomes.requested(state))
  )
