import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, into, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

export const PrescriberLocation = Record({
  id: null,
  prescriberId: null,
  state: null,
  locationId: null,
  licenseNumber: null,
  deaNumber: null,
  rxAuthority: null,
  enabledDate: null,
})

const key = 'prescriberLocations'

export const PRESCRIBER_LOCATIONS_VIEWED = type(
  key,
  'PRESCRIBER_LOCATIONS_VIEWED'
)

export const prescriberLocationsViewed = creator(
  PRESCRIBER_LOCATIONS_VIEWED,
  'prescriberId'
)

export const fetchPrescriberLocations = Request({
  typePrefix: key,
  typeBase: 'FETCH_PRESCRIBER_LOCATIONS',
  requestParams: ['prescriberId'],
  operation: (prescriberId: any) =>
    AspireAPI.get('medications/prescriber_locations', {
      params: { prescriber_id: prescriberId },
    }),
  transform: into(PrescriberLocation, 'id'),
  messages: { failed: 'Could not get prescriber locations' },
})

export const createPrescriberLocation = Request({
  typePrefix: key,
  typeBase: 'CREATE_PRESCRIBER_LOCATION',
  requestParams: ['prescriberLocation'],
  operation: (prescriberLocation: any) =>
    AspireAPI.post(
      'medications/prescriber_locations',
      prescriberLocation.toJS()
    ),
  transform: PrescriberLocation,
  messages: { failed: 'Could not create prescriber location' },
})

export const savePrescriberLocation = Request({
  typePrefix: key,
  typeBase: 'SAVE_PRESCRIBER_LOCATION',
  requestParams: ['prescriberLocation'],
  operation: (prescriberLocation: any) =>
    AspireAPI.put(
      `medications/prescriber_locations/${prescriberLocation.id}`,
      prescriberLocation.toJS()
    ),
  transform: PrescriberLocation,
  messages: { failed: 'Could not save prescriber location' },
})

export const enablePrescriberLocation = Request({
  typePrefix: key,
  typeBase: 'ENABLE_PRESCRIBER_LOCATION',
  requestParams: ['prescriberLocationId'],
  operation: (prescriberLocationId: any) =>
    AspireAPI.post(
      `medications/prescriber_locations/enable/${prescriberLocationId}`
    ),
  transform: PrescriberLocation,
  messages: { failed: 'Could not enable prescriber location' },
})

export const makePreferredPrescriberLocation = Request({
  typePrefix: key,
  typeBase: 'MAKE_PREFERRED_PRESCRIBER_LOCATION',
  requestParams: ['prescriberLocationId'],
  operation: (prescriberLocationId: any) =>
    AspireAPI.post(
      `medications/prescriber_locations/make_preferred/${prescriberLocationId}`
    ),
  messages: { failed: 'Could not make preferred prescriber location' },
})

export default createReducer(key, Map(), {
  [fetchPrescriberLocations.SUCCEEDED]: (
    state: any,
    { payload = Map(), meta }: any
  ) => {
    const prescriberId = meta.request.payload.prescriberId

    // NOTE(adam): if there is a prescriber id, the data was loaded for a single prescriber
    const data = prescriberId
      ? { [prescriberId]: payload }
      : // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
        payload.groupBy(p => p.get('prescriberId'))

    return state.merge(data)
  },
  [createPrescriberLocation.SUCCEEDED]: (state: any, { payload }: any) =>
    state.setIn([payload.prescriberId, payload.id], payload),
  [savePrescriberLocation.SUCCEEDED]: (state: any, { payload }: any) =>
    state.setIn([payload.prescriberId, payload.id], payload),
  [enablePrescriberLocation.SUCCEEDED]: (state: any, { payload }: any) =>
    state.setIn([payload.prescriberId, payload.id], payload),
})

export const getPrescriberLocations = pipe(getRoot, get(key))
export const getPrescriberLocationByPrescriberId = (
  state: any,
  prescriberId: any
) => getPrescriberLocations(state).get(prescriberId)
