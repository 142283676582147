import moment from 'moment'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  flatMap,
  ignoreElements,
  map,
  pluck,
  tap,
} from 'rxjs/operators'
import { cacheRequested } from '~/data/cache'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import { scopedCreator } from '~/utils/data'
import { getItem, getItemWithMetadata, setPermanentItem } from '~/utils/storage'

const key = 'offlineDrugs'

const creator = scopedCreator(key)

export const searchOffline = creator('SEARCH_OFFLINE')
export const searchOfflineSucceeded = creator('SEARCH_OFFLINE_SUCCEEDED')
export const searchOfflineFailed = creator(
  'SEARCH_OFFLINE_FAILED',
  true,
  () => ({ messages: { failed: 'Could not search offline drug list' } })
)

const maxAgeDays = 7

const isExpired = timestamp =>
  !timestamp || moment.duration(moment().diff(timestamp)).days() > maxAgeDays

export const getDrugs = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  operation: () => AspireAPI.get('medications/drugs'),
  messages: {
    failed: 'Could not get drug list for offline',
  },
})

const fetchWhenNeeded = action$ =>
  action$.pipe(
    ofType(cacheRequested),
    flatMap(() => getItemWithMetadata(key)),
    catchError(),
    filter(isExpired),
    map(getDrugs.requested)
  )

const storeDrugs = action$ =>
  action$.pipe(
    ofType(getDrugs.SUCCEEDED),
    pluck('payload'),
    tap(list => setPermanentItem(key, list)),
    ignoreElements()
  )

const searchDrugs = action$ =>
  action$.pipe(
    ofType(searchOffline),
    pluck('payload'),
    flatMap(searchTerm =>
      getItem(key).then(list =>
        list.filter(({ drug }) => drug.match(RegExp(searchTerm, 'i')))
      )
    ),
    map(searchOfflineSucceeded),
    catchError(e => of(searchOfflineFailed(e)))
  )

export const epic = combineEpics(fetchWhenNeeded, storeDrugs, searchDrugs)
