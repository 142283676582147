import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getReportsDashboard } from './common/shared'

const key = 'metabaseSecret'

export const fetchMetabaseSecret = Request({
  typePrefix: key,
  typeBase: 'FETCH_METABASE_SECRET',
  operation: () => AspireAPI.get('/reports_dashboard/metabase_secret_key'),
})

const reducer = createReducer(key, null, {
  [fetchMetabaseSecret.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getMetabaseSecret = pipe(getReportsDashboard, get(key))

export default reducer
