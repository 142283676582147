import createReducer from '~/utils/createReducer'
import { creator, get, replace, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import mainKey from '../key'

const key = 'patientId'

export const PATIENT_ID_CHANGED = type(key, 'PATIENT_ID_CHANGED')

export const patientIdSet = creator(PATIENT_ID_CHANGED, 'patientId')

export default createReducer(key, null, {
  [PATIENT_ID_CHANGED]: replace('patientId'),
})

export const getPatientId = pipe(get(mainKey), get(key))
