import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { renderFieldValueOptions } from '~/components/fields'
import { fetchDistinctFieldValues, getFieldValues } from '~/data/fieldValues'
import { useMountEffect } from '~/hooks'
import useInput from '~/hooks/useInput'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { dialogClosed } from '../../data/dialog'
import { addPatientListItem } from '../../data/patientLists'

const styles = ({ spacing }) => ({
  marginBottom: {
    marginBottom: spacing(3),
  },
})

const AddListDialog = ({
  classes,
  userId,
  excludedListTypes,
  types,
  open,
  patientId,
  patientName,
  dialogClosed,
  addPatientListItem,
  fetchDistinctFieldValues,
}) => {
  useMountEffect(() => {
    fetchDistinctFieldValues(['census_patient_list_type'])
  })

  const [list, setList] = useInput('')
  const [reason, setReason] = useInput('')

  const clearData = useCallback(() => {
    setList('')
    setReason('')
  }, [setList, setReason])

  const onClose = useCallback(() => {
    dialogClosed()
    clearData()
  }, [dialogClosed, clearData])

  const onSave = useCallback(() => {
    addPatientListItem(userId, patientId, list, reason)
    onClose()
  }, [userId, patientId, list, reason, onClose, addPatientListItem])

  return (
    <ActionDialog
      mode="save"
      title={`Add ${patientName} to List`}
      open={open}
      disableAction={list === ''}
      onSave={onSave}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <TextField
        className={classes.marginBottom}
        select
        fullWidth
        label="List Type"
        SelectProps={{ native: true }}
        onChange={setList}
        value={list}
      >
        <option />
        {renderFieldValueOptions(types, excludedListTypes)}
      </TextField>

      <TextField
        fullWidth
        multiline
        rowsMax={3}
        variant="outlined"
        label="Reason"
        onChange={setReason}
        value={reason}
      />
    </ActionDialog>
  )
}

AddListDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.string,
  excludedListTypes: PropTypes.array,
  types: PropTypes.map.isRequired,
  open: PropTypes.bool,
  patientId: PropTypes.string,
  patientName: PropTypes.string,
  dialogClosed: PropTypes.func.isRequired,
  addPatientListItem: PropTypes.func.isRequired,
  fetchDistinctFieldValues: PropTypes.func.isRequired,
}

AddListDialog.defaultProps = {
  excludedListTypes: [],
}

const mapStateToProps = state => ({
  types: getFieldValues('census_patient_list_type')(state),
})

const mapDispatchToProps = {
  dialogClosed,
  addPatientListItem: addPatientListItem.requested,
  fetchDistinctFieldValues,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AddListDialog)
