import 'react-virtualized/styles.css'

import { parse } from 'date-fns'
import React, { useMemo } from 'react'
import { createSelector } from 'reselect'
import DevXGrid from '~/components/DevXGrid'
import { formatFullDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(({ palette }) => ({
  tableContainer: {
    height: '500px',
    'margin-top': '16px',
  },
  header: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  link: {
    color: palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  attachmentType: {
    display: 'flex',
    'align-items': 'center',
  },
}))

export const AttachmentsTable = ({
  attachments,
  getAttachmentDocument,
  filter,
}) => {
  const getAttList = createSelector(
    [obj => obj.filter, obj => obj.attachments],
    (filter, attachments) => {
      return !filter
        ? attachments
        : attachments.filter(a => {
            for (const k in a) {
              if (a[k] && a[k].toLowerCase().includes(filter.toLowerCase())) {
                return true
              }
            }
            return false
          })
    }
  )
  const dataList = getAttList({ filter, attachments })
  const attachmentsGridColumns = useMemo(() => {
    const nameDisplayComponent = props => (
      <AttachmentLink {...props} onClick={getAttachmentDocument} />
    )

    return [
      {
        name: 'displayName',
        title: 'Name',
        displayComponent: nameDisplayComponent,
      },
      {
        name: 'dateOfService',
        title: 'Date Of Service',
        getCellValue: ({ dateOfService }) => formatFullDate(dateOfService),
      },
      {
        name: 'attachmentType',
        title: 'Attachment Type',
      },
    ]
  }, [getAttachmentDocument])

  const pageSizes = [25, 50]

  const sortDate = (a, b) => {
    const aDate = parse(a, 'MM/dd/yyyy', new Date())
    const bDate = parse(b, 'MM/dd/yyyy', new Date())
    return aDate.getTime() - bDate.getTime()
  }

  const columnExtensions = [{ columnName: 'dateOfService', compare: sortDate }]
  const defaultSorting = [{ columnName: 'dateOfService', direction: 'desc' }]
  const classes = useStyles()
  function AttachmentLink({ row, value, onClick }) {
    return (
      <a
        className={classes.link}
        href={row.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => onClick(row.id, row.docCode)}
      >
        {value}
      </a>
    )
  }

  AttachmentLink.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object,
    onClick: PropTypes.func,
  }

  return (
    <div className={classes.tableContainer}>
      <DevXGrid
        sortable
        rows={dataList}
        columns={attachmentsGridColumns}
        columnExtensions={columnExtensions}
        pageable
        pageSizes={pageSizes}
        defaultPageSize={25}
        defaultSorting={defaultSorting}
      />
    </div>
  )
}

AttachmentsTable.propTypes = {
  attachments: PropTypes.map, // eslint-disable-line react/no-unused-prop-types
  getAttachmentDocument: PropTypes.func,
  filter: PropTypes.func,
}
