import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { NEW_TASK } from '../constants'
import TASKS from '../key'
import { getRoot } from './common'

// Key
const DIALOG = 'dialog'

// Types
const creator = scopedCreator(`${TASKS}/${DIALOG}`)

// Actions
export const dialogClosed = creator('DIALOG_CLOSED', false)
export const dialogOpened = creator('DIALOG_OPENED', ['type', 'patientId'])
export const createNewTaskDialogOpened = (...args) =>
  dialogOpened(NEW_TASK, ...args)
export const reassignTaskDialogOpened = creator('REASSIGN_TASK_DIALOG_OPENED', [
  'patientId',
])

// Record
export const DialogState = Record({
  open: false,
  type: null,
  patientId: null,
})

// Reducer
const init = () => DialogState()

export default createReducer(DIALOG, init(), {
  [dialogClosed]: init,
  [dialogOpened]: (_state, { payload }) =>
    DialogState({ open: true, ...payload }),
})

// Selectors
export const getDialogState = compose(get(DIALOG), getRoot)
