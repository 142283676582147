import Keycloak from 'keycloak-js'
import { get } from 'lodash'
import { SESSION_TOKEN, SESSION_USER, getItem, setItem } from '~/utils/storage'
import messages, { createOnMessage, RxCallbacks } from '../messages'
import { KeycloakProvider, User } from './provider'
import { keycloak_config } from 'ahc-config'

let iframe: any, popup: any
let logoutListener = () => {}
let loginListener = () => {}
const keycloak = Keycloak(keycloak_config)
const { onMessage, onFirstMessage }: RxCallbacks = createOnMessage(window)

onFirstMessage(messages.INIT_LOGIN_REQUESTED, () => {
  keycloak.login()
})

onFirstMessage(messages.INIT_SUCCEEDED, ({ source }: any) => {
  iframe = source
  setItem(SESSION_TOKEN, iframe.keycloak.token)
  setItem<User>(SESSION_USER, iframe.user)
})

onMessage(messages.INIT_SUCCEEDED, () => {
  loginListener()
})

onMessage(messages.LOGGED_OUT, () => {
  logoutListener()
})

onMessage(messages.LOGGED_IN, () => {
  setItem(SESSION_TOKEN, iframe.keycloak.token)
  setItem(SESSION_USER, iframe.user)
  popup.close()
  iframe.location.replace('/auth.html')
})

const provider: KeycloakProvider = {
  onLogin: (listener: () => any): void => {
    loginListener = listener
  },
  onLogout: (listener: () => any): void => {
    logoutListener = listener
  },
  init: () =>
    Promise.all([
      keycloak.init({ checkLoginIframe: false }),
      new Promise((resolve, reject) => {
        onFirstMessage(messages.INIT_SUCCEEDED, resolve)
        onFirstMessage(messages.INIT_FAILED, reject)
      }),
    ]),
  login: () => {
    popup = window.open('/auth.html')
  },
  logout: () => keycloak.logout(),
  get user() {
    return iframe
      ? Promise.resolve<User>(iframe.user)
      : getItem<User>(SESSION_USER)
  },
  get token() {
    return iframe
      ? Promise.resolve<string>(iframe.keycloak.token)
      : getItem<string>(SESSION_TOKEN)
  },
  get expiresAt() {
    const exp = get(iframe, 'keycloak.refreshTokenParsed.exp')
    return exp ? new Date(exp * 1000) : null
  },
  isTokenExpired: (minValidity?: number) =>
    Boolean(iframe) && iframe.keycloak.isTokenExpired(minValidity),
  refreshToken: (minValidity?: number): Promise<string> =>
    iframe
      ? iframe.keycloak
          .updateToken(minValidity)
          .then(() => iframe.keycloak.token)
      : Promise.resolve(getItem(SESSION_TOKEN)),
}
export default provider
