import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

// KEY
const key = 'benefitClassPlanProduct'

export const ClassPlanProduct = Record({
  counties: List(),
  classplanProducts: List(),
})

// REQUEST
export const fetchBenefitClassplanProducts = Request({
  typePrefix: key,
  typeBase: 'FETCH_BENEFIT_CLASSPLAN_PRODUCTS',
  requestParams: ['year'],
  operation: (year: number) =>
    AspireAPI.get(`transportation/benefits/classplan_products/${year}`),
  messages: { failed: 'Failed to fetch benefit classplan products ' },
})

// REDUCER
export const benefitClassplanProduct = createReducer(key, List(), {
  [fetchBenefitClassplanProducts.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

// SELECTORS
export const getBenefitClassPlanProduct = pipe(getRoot, get(key))
