import React from 'react'
// @ts-expect-error ts-migrate(2307) FIXME
import { formatDate } from '~/utils/format'
import { GridLine, GridSection, Column, Columns } from '~/components/text'
import { Gap } from '../data/openGaps'

type Props = {
  gap: Gap
  key: number
  hiddenDescription: boolean
  hiddenDate: boolean
}

const OpenGap = ({
  gap: { measure, description, lastUpdatedDate },
  hiddenDescription,
  hiddenDate,
}: Props) => {
  return (
    <Columns>
      <Column>
        <GridSection>
          {!hiddenDate && (
            <GridLine label="Last Updated">
              {formatDate(lastUpdatedDate)}
            </GridLine>
          )}
          <GridLine label="Measure">{measure}</GridLine>
          {!hiddenDescription && (
            <GridLine label="Description">{description}</GridLine>
          )}
        </GridSection>
      </Column>
    </Columns>
  )
}

export default OpenGap
