import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'tripAdd'

export const getTripSaveStatus = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const resetTripForm = creator('TRIP_FORM_INIT')

// REQUEST
export const saveNewTrip = Request({
  typePrefix: key,
  typeBase: 'SAVE_NEW_TRIP',
  requestParams: ['trip', 'id'],

  operation: (trip: any, id: string) =>
    AspireAPI.post(`transportation/trips/${id}`, { ...trip }),
  messages: {
    succeeded: 'Trip Saved Successfully',
    failed: 'Could not save new Trip',
  },
})

// Trip Update
export const updateTrip = Request({
  typePrefix: key,
  typeBase: 'UPDATE_TRIP',
  requestParams: ['trip', 'id'],

  operation: (trip: any, id: string) =>
    AspireAPI.put(`transportation/trips/${id}`, { ...trip }),
  messages: {
    succeeded: 'Trip Updated Successfully',
    failed: 'Could not update The Trip',
  },
})

// REDUCER
export const tripSave = createReducer(key, null, {
  [saveNewTrip.REQUESTED]: () => ({ type: 'NewTrip', operation: 'Requested' }),
  [saveNewTrip.SUCCEEDED]: () => ({ type: 'NewTrip', operation: 'Saved' }),
  [saveNewTrip.FAILED]: () => ({ type: 'NewTrip', operation: 'Failed' }),
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetTripForm]: () => null,
  [updateTrip.REQUESTED]: () => ({ type: 'EditTrip', operation: 'Requested' }),
  [updateTrip.SUCCEEDED]: () => ({ type: 'EditTrip', operation: 'Saved' }),
  [updateTrip.FAILED]: () => ({ type: 'EditTrip', operation: 'Failed' }),
})
