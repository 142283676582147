import React, { useState } from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Collapse,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ActionableItem as ActionableItemRecord } from '../data'
import ActionableItem from './ActionableItem'

const styles = ({ spacing, palette: { text } }) => ({
  icon: {
    color: text.primary,
  },
  noActionableItems: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
})

const ActionableItems = ({ classes, orders, tasks }) => {
  const [ordersExpanded, toggleOrdersExpanded] = useState(true)
  const [tasksExpanded, toggleTasksExpanded] = useState(true)

  if (orders.isEmpty() && tasks.isEmpty()) {
    return (
      <Typography className={classes.noActionableItems} variant="h6">
        Patient has no open Actionable Items at this time
      </Typography>
    )
  }

  return (
    <List disablePadding>
      {!orders.isEmpty() && (
        <React.Fragment>
          <ListItem
            button
            onClick={() => toggleOrdersExpanded(!ordersExpanded)}
          >
            <ListItemIcon>
              <Icon>shopping_cart</Icon>
            </ListItemIcon>
            <ListItemText inset primary="Orders" />
            <Icon className={classes.icon}>
              {ordersExpanded ? 'expand_less' : 'expand_more'}
            </Icon>
          </ListItem>
          <Collapse in={ordersExpanded}>
            {orders.map(o => (
              <ActionableItem key={o.id} item={o} />
            ))}
          </Collapse>
        </React.Fragment>
      )}
      {!tasks.isEmpty() && (
        <React.Fragment>
          <ListItem button onClick={() => toggleTasksExpanded(!tasksExpanded)}>
            <ListItemIcon>
              <Icon>playlist_add_check</Icon>
            </ListItemIcon>
            <ListItemText inset primary="Tasks" />
            <Icon className={classes.icon}>
              {tasksExpanded ? 'expand_less' : 'expand_more'}
            </Icon>
          </ListItem>
          <Collapse in={tasksExpanded}>
            {tasks.map(t => (
              <ActionableItem key={t.id} item={t} />
            ))}
          </Collapse>
        </React.Fragment>
      )}
    </List>
  )
}

ActionableItems.propTypes = {
  classes: PropTypes.object.isRequired,
  orders: PropTypes.listOf(ActionableItemRecord).isRequired,
  tasks: PropTypes.listOf(ActionableItemRecord).isRequired,
}

export default withStyles(styles)(ActionableItems)
