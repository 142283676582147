import { filter, flow, identity, map, sortBy, uniq } from 'lodash/fp'
import { Codec, GetType, array, number, string } from 'purify-ts'
import { RequestStatus } from '~/hooks/useRequest'
import AspireAPI from '~/resources/aspire'
import { createRequest } from '~/utils/createRequest'

export const ProblemCategory = Codec.interface({
  id: number,
  category: string,
  subcategory: string,
})

export const ProblemCategories = array(ProblemCategory)
export type ProblemCategories = GetType<typeof ProblemCategories>

export function mapProblemCategoryKeys(
  req: RequestStatus<ProblemCategories>
): string[] {
  return req.status === 'success'
    ? flow([map(({ category }) => category), uniq, sortBy(identity)])(req.data)
    : []
}

export function mapProblemSubcategories(
  req: RequestStatus<ProblemCategories>,
  selectedCategory: string
): ProblemCategories {
  return req.status === 'success'
    ? flow([
        filter(({ category }) => category === selectedCategory),
        sortBy('subcategory'),
      ])(req.data)
    : []
}

export const fetchProblemCategories = createRequest<[], ProblemCategories>(
  () => AspireAPI.get('care_plan/problem_categories'),
  ProblemCategories
)
