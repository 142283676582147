import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import memoizedWrapper from './widgets/memoizedWrapper'

const renderErrors = errors => errors.join(', ')

const styles = ({ palette, spacing }) => ({
  root: {
    marginTop: spacing(1),
  },
  normalRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogContent: {
    '& > p:not(:last-child)': {
      marginBottom: spacing(1),
    },
  },
  labelWrapper: {
    flex: 1,
    marginRight: spacing(1),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    minHeight: spacing(4),
  },
  labelRequired: {
    color: palette.secondary.main,
  },
  labelDisabled: {
    '&&': {
      color: palette.text.secondary,
    },
  },
  helpIcon: {
    fontSize: '1em',
    marginLeft: spacing(1),
    cursor: 'help',
    flexShrink: 0,
  },
  field: {
    flex: 3,
  },
  topLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '133.33%',
  },
  compactRow: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: spacing(2),
    '& > *:first-child': {
      flex: 1,
    },
    '& a': {
      color: palette.secondary.main,
      textDecoration: 'none',
    },
  },
  infoButton: {
    padding: spacing(0.5),
    marginLeft: spacing(1),
  },
})

class FieldTemplate extends React.Component {
  state = {
    helpOpen: false,
    helpClosing: false,
  }

  openHelp = () => this.setState({ helpOpen: true })
  closeHelp = () => this.setState({ helpClosing: true })
  exitHelp = () => this.setState({ helpOpen: false, helpClosing: false })

  renderDialog = () => {
    const { classes, label, rawDescription, rawHelp } = this.props
    const { helpOpen, helpClosing } = this.state

    return (
      helpOpen && (
        <Dialog
          fullWidth
          data-track-content
          data-content-name={label}
          open={helpOpen && !helpClosing}
          onClose={this.closeHelp}
          onClick={this.closeHelp}
          onExited={this.exitHelp}
        >
          <DialogTitle>{label}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {rawDescription && (
              <React.Fragment>
                <Typography
                  data-content-piece={rawDescription}
                  variant="caption"
                  color="textSecondary"
                >
                  Description
                </Typography>
                <Typography>{rawDescription}</Typography>
              </React.Fragment>
            )}
            {rawHelp && (
              <React.Fragment>
                <Typography
                  data-content-piece={rawHelp}
                  variant="caption"
                  color="textSecondary"
                >
                  Help
                </Typography>
                <Typography>{rawHelp}</Typography>
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
      )
    )
  }

  render() {
    const {
      classes,
      children,
      schema,
      uiSchema,
      rawErrors = [],
      label,
      rawDescription,
      rawHelp,
      required,
      disabled,
      hidden,
      formContext: { compact },
    } = this.props
    const { 'ui:options': options = {} } = uiSchema
    const displayLabel = options.label || this.props.displayLabel

    if (hidden || schema.hidden) {
      return null
    }

    if (schema.type !== 'boolean' && !displayLabel) {
      return children
    }

    const errors = rawErrors.filter(Boolean)
    const showErrors = errors.length > 0
    const showInfoButton = rawDescription || rawHelp
    const displayErrors = showErrors && (
      <FormHelperText error>
        {options.customErrorMessage || renderErrors(errors)}
      </FormHelperText>
    )

    return (
      <FormControl
        fullWidth
        className={classNames(classes.root, { [classes.normalRow]: !compact })}
        required={required}
        error={showErrors}
        disabled={disabled}
      >
        {this.renderDialog()}
        {!compact && (
          <React.Fragment>
            <div className={classes.labelWrapper}>
              <div className={classes.label}>
                <FormLabel
                  classes={{
                    root: classNames({ [classes.labelRequired]: required }),
                    disabled: classes.labelDisabled,
                  }}
                >
                  {label}
                </FormLabel>
                {rawHelp && (
                  <Tooltip placement="top" title={rawHelp}>
                    <Icon className={classes.helpIcon} color="secondary">
                      help
                    </Icon>
                  </Tooltip>
                )}
              </div>
              {rawDescription && (
                <Typography variant="caption">{rawDescription}</Typography>
              )}
            </div>
            <div className={classes.field}>
              {children}
              {displayErrors}
            </div>
          </React.Fragment>
        )}
        {compact && (
          <React.Fragment>
            <InputLabel className={classes.topLabel} shrink>
              {label}
            </InputLabel>
            <div className={classes.compactRow}>
              {children}
              {showInfoButton && (
                <IconButton
                  className={classes.infoButton}
                  onClick={this.openHelp}
                >
                  <Icon color="secondary">info</Icon>
                </IconButton>
              )}
            </div>
            {displayErrors}
          </React.Fragment>
        )}
      </FormControl>
    )
  }
}

FieldTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  rawErrors: PropTypes.array,
  displayLabel: PropTypes.bool.isRequired,
  label: PropTypes.string,
  rawDescription: PropTypes.string,
  rawHelp: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  formContext: PropTypes.object,
}

export default compose(memoizedWrapper, withStyles(styles))(FieldTemplate)
