export default {
  tasks: {
    121: {
      id: 121,
      name: 'Ride Along',
      users: [
        { id: 'jkl', name: 'Flurgle McDurgle', role: 'CSS' },
        { id: 'abc', name: 'Boople Maloople', role: 'APP' },
        { id: 'def', name: 'Hingle McCringleberry', role: 'PCC CC' },
        { id: 'mno', name: "H'erp DaDerp", role: 'PCC RN' },
        { id: 'ghi', name: 'Chumba Wumba', role: 'PCC SW' },
        { id: 'pqr', name: 'Crinkle Tinkle', role: 'PCC OA' },
        { id: 'stu', name: 'Stinky Stinkerson', role: 'NSS' },
      ],
    },
    122: {
      id: 122,
      name: 'Coordinate Care PCP/Specialist',
      users: [
        { id: 'mno', name: "H'erp DaDerp", role: 'PCC RN' },
        { id: 'abc', name: 'Boople Maloople', role: 'APP' },
      ],
    },
    123: {
      id: 123,
      name: 'Health Plan CM Consult',
      users: [{ id: 'stu', name: 'Stinky Stinkerson', role: 'NSS' }],
    },
    124: {
      id: 124,
      name: 'Review Inpatient Discharge Plan',
      users: [{ id: 'mno', name: "H'erp DaDerp", role: 'PCC RN' }],
    },
    125: {
      id: 125,
      name: 'Advocate Hospice Election',
      users: [{ id: 'ghi', name: 'Chumba Wumba', role: 'PCC SW' }],
    },
    126: {
      id: 126,
      name: 'Advance Care Planning',
      users: [{ id: 'def', name: 'Hingle McCringleberry', role: 'PCC CC' }],
    },
    127: {
      id: 127,
      name: 'Assess for Resource Support',
      users: [
        { id: 'def', name: 'Hingle McCringleberry', role: 'PCC CC' },
        { id: 'ghi', name: 'Chumba Wumba', role: 'PCC SW' },
      ],
    },
    128: {
      id: 128,
      name: 'Hospice Discussion',
      users: [
        { id: 'def', name: 'Hingle McCringleberry', role: 'PCC CC' },
        { id: 'pqr', name: 'Crinkle Tinkle', role: 'PCC OA' },
        { id: 'stu', name: 'Stinky Stinkerson', role: 'NSS' },
      ],
    },
    129: {
      id: 129,
      name: 'Upload ACP Documents',
      users: [
        { id: 'def', name: 'Hingle McCringleberry', role: 'PCC CC' },
        { id: 'abc', name: 'Boople Maloople', role: 'APP' },
        { id: 'def', name: 'Hingle McCringleberry', role: 'PCC CC' },
        { id: 'mno', name: "H'erp DaDerp", role: 'PCC RN' },
      ],
    },
  },
}
