import { fromJS } from 'immutable'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  Accordion,
  AccordionSummary,
  Divider,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { getProblemsLoaded } from '../data/utils/selectors'
import { Problem } from '../types/types'

const useStyles = makeStyles(({ typography, palette }) =>
  createStyles({
    root: {
      width: '100%',
    },
    header: {
      backgroundColor: palette.divider,
      borderBottom: '1',
    },
    heading: {
      fontSize: typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: typography.pxToRem(15),
    },
    expanded: {
      margin: 0,
    },
    summaryContent: {
      display: 'grid',
      gridTemplateColumns: '2fr 0.5fr 0.2fr',
      width: '100%',
      marginLeft: '4em',
    },
    noResultsContainer: {
      marginTop: '1em',
    },
  })
)

interface Props {
  onSubmit: (problem: any) => void
  problems: Problem[]
  ImoProblem: React.ElementType
}

const ImoProblemList = ({ onSubmit, problems, ImoProblem }: Props) => {
  const classes = useStyles()
  const problemsLoaded: boolean = useSelector(getProblemsLoaded)

  return problemsLoaded ? (
    <div>
      {problems && fromJS(problems).size > 0 ? (
        <Accordion
          expanded={true}
          classes={{ expanded: classes.expanded, root: classes.root }}
          elevation={1}
        >
          <AccordionSummary className={classes.header}>
            <div className={classes.summaryContent}>
              <Typography className={classes.heading}>Description</Typography>
              <Typography className={classes.secondaryHeading}>
                ICD-10
              </Typography>
            </div>
          </AccordionSummary>
          {problems.map((problem: Problem) => (
            <ImoProblem
              onSubmit={onSubmit}
              key={problem.id}
              problem={problem}
            />
          ))}
          <Divider />
        </Accordion>
      ) : (
        <div className={classes.noResultsContainer}>
          <Typography>No results found</Typography>
        </div>
      )}
    </div>
  ) : null
}

export default ImoProblemList
