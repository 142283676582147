import { distinctUntilChanged, map } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import { patientEventsStateUpdated } from '../data/dates'
import { getPatientEvents, patientEventsScope } from '../data/index'

export default (_action$, state$) =>
  state$.pipe(
    map(patientEventsScope),
    distinctUntilChanged(),
    switchTo(state$),
    map(getPatientEvents),
    map(patientEventsStateUpdated)
  )
