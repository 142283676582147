import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAction, usePending } from '~/hooks'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  completeCallAttempt,
  contactSkipped,
  getCallLog,
} from '../../data/callLog'
import { ACTIVE, getContacts } from '../../data/contacts'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginRight: spacing(1),
  },
}))

const CompleteAttemptButton = () => {
  const classes = useStyles()

  const contacts = useSelector(getContacts)
  const callLog = useSelector(getCallLog)
  const skipContact = useAction(contactSkipped)
  const completeCallAttemptRequested = useAction(completeCallAttempt.requested)

  const pending = usePending(completeCallAttempt)

  const nonSkippedNumbers = useMemo(
    () =>
      contacts
        .filter(({ active, expired }) => active === ACTIVE && !expired)
        .filterNot(
          ({ phoneNumber }) =>
            callLog.skippedContacts.has(phoneNumber) ||
            callLog.dispositions.some(
              disposition => disposition.phoneNumber === phoneNumber
            )
        ),
    [contacts, callLog.dispositions, callLog.skippedContacts]
  )

  const onCompleteAttempt = () => {
    completeCallAttemptRequested(callLog.patientId, callLog)
  }

  const onSkipRemaining = () => {
    nonSkippedNumbers.forEach(contact => {
      skipContact(contact.phoneNumber)
    })
  }

  return !callLog.activeContact ? (
    <React.Fragment>
      <Button
        className={classes.button}
        disabled={
          !nonSkippedNumbers.isEmpty() ||
          callLog.dispositions.isEmpty() ||
          pending
        }
        variant="contained"
        color="primary"
        onClick={onCompleteAttempt}
      >
        Complete Call Attempt
      </Button>

      <Button
        disabled={nonSkippedNumbers.isEmpty()}
        variant="contained"
        color="secondary"
        onClick={onSkipRemaining}
      >
        Skip Remaining
      </Button>
    </React.Fragment>
  ) : null
}

export default CompleteAttemptButton
