import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { constant, flow } from 'fp-ts/lib/function'
import {
  AType,
  summon,
  Variant,
  EType,
  AsOpaque,
  Ctor,
  Ctor_,
  AOfMorphADT,
  ReduxAction,
} from './type'
import { RequestError } from '~/resources/resource-strict'

export const Msg = summon(F => F.either(F.string(), F.string()))
export type Msg = AType<typeof Msg>

export const Type = summon(F => F.option(Msg(F)))
export type Type = AType<typeof Type>

const Push_ = Ctor('Push', Msg)
export interface Push extends AType<typeof Push_> {}
export interface PushRaw extends EType<typeof Push_> {}
export const Push = AsOpaque<PushRaw, Push>()(Push_)

const Pop_ = Ctor_('Pop')
export interface Pop extends AType<typeof Pop_> {}
export interface PopRaw extends EType<typeof Pop_> {}
export const Pop = AsOpaque<PopRaw, Pop>()(Pop_)

export const Action = Variant({ Push, Pop })
export type Action = ReduxAction<'Toast', AOfMorphADT<typeof Action>>

export type Fn<Data> = (_: E.Either<RequestError, Data>) => Type

export const success = <T>(succFn: (_: T) => string): Fn<T> =>
  E.fold(constant(O.none), flow(succFn, E.right, O.some))

export const error = (
  errFn: (_: RequestError) => O.Option<string>
): Fn<unknown> => E.fold(flow(errFn, O.map(E.left)), constant(O.none))

export const message = <T>(
  success: (_: T) => string,
  error: (_: RequestError) => O.Option<string>
): Fn<T> =>
  E.fold(flow(error, O.map(E.left)), data => O.some(E.right(success(data))))
