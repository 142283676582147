import { ofType } from 'redux-observable'
import { debounceTime, map, pluck, tap } from 'rxjs/operators'
import { patientSearchChanged, searchPatients } from '../data/eventTypes'

export default action$ =>
  action$.pipe(
    ofType(patientSearchChanged),
    debounceTime(500),
    tap(() => {
      searchPatients.cancelLatest()
    }),
    pluck('payload'),
    map(({ query, appId, market }) =>
      searchPatients.requested(query, appId, market)
    )
  )
