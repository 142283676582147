import React from 'react'
import BaseStringField from 'react-jsonschema-form/lib/components/fields/StringField'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../widgets/memoizedWrapper'

const StringField = ({ uiSchema, ...props }) => {
  let newUISchema = uiSchema
  const hasWidget = 'ui:widget' in uiSchema
  const isEnum = Array.isArray(props.schema.enum)

  if (!hasWidget && isEnum) {
    newUISchema = { ...uiSchema, 'ui:widget': 'radio' }
  }

  return <BaseStringField {...props} uiSchema={newUISchema} />
}

StringField.propTypes = {
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
}

StringField.defaultProps = {
  schema: {},
  uiSchema: {},
}

export default memoizedWrapper(StringField)
