import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import filters from '../../utils/filterDefinitions'
import {
  filtersCleared,
  filtersLoadedFromStorage,
  getPatientFilters,
} from './root'
const SELECTED = 'selected'
const initState = Set([
  (filters as any).referredDirect.key,
  (filters as any).referredAlgorithm.key,
  (filters as any).referredNew.key,
  (filters as any).referredInProcess.key,
  (filters as any).referredSNFHospital.key,
  (filters as any).activeComingDue.key,
  (filters as any).scheduled.key,
  (filters as any).snoozeDateBeforeTomorrow.key,
  (filters as any).includeGreen.key,
  (filters as any).includeYellow.key,
  (filters as any).includeOrange.key,
  (filters as any).fivePercentTierThree.key,
  (filters as any).fivePercentTierTwo.key,
  (filters as any).fivePercentTierOne.key,
  (filters as any).telephonic.key,
  (filters as any).homeBasedPalliativeCare.key,
  (filters as any).pcPlus.key,
  (filters as any).commercialPalliativeCare.key,
  (filters as any).hbpcHybrid.key,
  (filters as any).highRiskComplexCare.key,
  (filters as any).careMoreHealthNetwork.key,
  (filters as any).careMoreAtHome.key,
  // @ts-expect-error ts-migrate(2551) FIXME: Property 'includeRed' does not exist on type 'Map<... Remove this comment to see the full error message
  filters.includeRed.key,
  (filters as any).noScore.key,
])
const creator = scopedCreator(rootKey)
export const filterToggled = creator('FILTER_TOGGLED', ['key'])
export default createReducer(SELECTED, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [filtersCleared]: () => initState,
  // @ts-expect-error can we deal with the toString being automatically called?
  [filtersLoadedFromStorage]: (_state: any, { payload: { selected } }: any) =>
    Set(selected),
  // @ts-expect-error can we deal with the toString being automatically called?
  [filterToggled]: (state: any, { payload: { key } }: any) =>
    state.has(key) ? state.delete(key) : state.add(key),
})
export const getSelectedFilters = pipe(getPatientFilters, get(SELECTED))
export const getFilterSelected = (state: any, key: any) =>
  // @ts-expect-error Object is unknown
  getSelectedFilters(state).includes(key)
