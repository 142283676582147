import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  getRoot,
  transformAPP,
  transformEvent,
  transformPatient,
} from './common/shared'

const transform = {
  PR: transformPatient,
  NP: transformAPP,
  VI: transformEvent,
}

// KEY
const SEARCH = 'search'

// ACTIONS
const creator = scopedCreator(rootKey)
export const searchAutoSuggested = creator('SEARCH_AUTOSUGGESTED', ['value'])

// TRANSFORMER
const mapSearchResults = results =>
  List(results.map(result => transform[result.docType](result)))

// REQUEST
export const search = Request({
  typePrefix: rootKey,
  typeBase: 'SEARCH',
  requestParams: ['query', 'type'],
  operation: (query, type) =>
    AspireAPI.get('v1/search', {
      params: {
        query,
        type,
      },
    }),
  transform: mapSearchResults,
  messages: { failed: 'Failed to search for entities.' },
})

// REDUCER
export default createReducer(SEARCH, List(), {
  [search.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getSearchResults = pipe(getRoot, get(SEARCH))
