import { combineReducers } from 'redux-immutable'
import careTeams from './data/careTeams'
import draftProjectPatients from './data/draftProjectPatients'
import draftProjects from './data/draftProjects'
import selectedEMRStatusFilter from './data/emrStatusesFilter'
import errors from './data/errors'
import fetched from './data/fetched'
import focusedMarketPolygon from './data/focusedMarketPolygon'
import healthPlans from './data/healthPlans'
import map from './data/map'
import markets from './data/markets'
import mmStatuses from './data/mmStatuses'
import odsLayer from './data/odsLayer'
import patientErrors from './data/patientErrors'
import patientStatuses from './data/patientStatuses'
import selectedPatientStatusFilter from './data/patientStatusesFilter'
import patients from './data/patients'
import polygonPatients from './data/polygonPatients'
import polygons from './data/polygons'
import programs from './data/programs'
import projectPolygons from './data/projectPolygons'
import selectedHealthPlans from './data/selectedHealthPlans'
import selectedMarketPolygons from './data/selectedMarketPolygons'
import selectedMarkets from './data/selectedMarkets'
import selectedPolygon from './data/selectedPolygon'
import selectedPolygons from './data/selectedPolygonsArea'
import selectedPrograms from './data/selectedPrograms'
import selectedProject from './data/selectedProject'
import visibleProjectPolygons from './data/visibleProjectPolygons'
import zips from './data/zips'
import key from './key'

const reducer = combineReducers({
  [draftProjectPatients.key]: draftProjectPatients,
  [draftProjects.key]: draftProjects,
  [patientStatuses.key]: patientStatuses,
  [errors.key]: errors,
  [fetched.key]: fetched,
  [focusedMarketPolygon.key]: focusedMarketPolygon,
  [healthPlans.key]: healthPlans,
  [map.key]: map,
  [markets.key]: markets,
  [mmStatuses.key]: mmStatuses,
  [careTeams.key]: careTeams,
  [odsLayer.key]: odsLayer,
  [patientErrors.key]: patientErrors,
  [patients.key]: patients,
  [polygonPatients.key]: polygonPatients,
  [polygons.key]: polygons,
  [programs.key]: programs,
  [projectPolygons.key]: projectPolygons,
  [selectedEMRStatusFilter.key]: selectedEMRStatusFilter,
  [selectedHealthPlans.key]: selectedHealthPlans,
  [selectedMarketPolygons.key]: selectedMarketPolygons,
  [selectedMarkets.key]: selectedMarkets,
  [selectedPatientStatusFilter.key]: selectedPatientStatusFilter,
  [selectedPolygon.key]: selectedPolygon,
  [selectedPolygons.key]: selectedPolygons,
  [selectedPrograms.key]: selectedPrograms,
  [selectedProject.key]: selectedProject,
  [visibleProjectPolygons.key]: visibleProjectPolygons,
  [zips.key]: zips,
})

reducer.key = key

export default reducer
