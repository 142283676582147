import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'

export const Document = Record({
  id: null,
  fileType: null,
  fileName: null,
  docContent: null,
})

const key = 'fetchedDocument'

export const getAttachmentDocumentById = Request({
  typePrefix: key,
  typeBase: 'FETCH_ATTACHMENT_DOCUMENT',
  requestParams: ['documentId'],
  operation: id => AspireAPI.get(`/v1/attachments/document/${id}`),
  transform: Document,
  messages: { failed: 'There was a problem fetching attachment document' },
})
