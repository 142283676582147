import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import JsonForm from '~/components/JsonForm'
import DateField from '~/components/fields/DateField'
import { getFieldValues } from '~/data/fieldValues'
import { getUserId } from '~/data/session'
import { getPatientInfoProp } from '~/features/patientInfo/data/patient'
import { VendorSearch } from '~/features/vendorSearch'
import PropTypes from '~/utils/propTypes'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import ActionButtons from '../components/ActionButtons'
import OrderHistory from '../components/OrderHistory'
import OrderNotes from '../components/OrderNotes'
import ReasonForSelectionOptions from '../components/ReasonForSelectionOptions'
import {
  applyActionRequested,
  canApplyAction,
  canEditDetail,
  expectedFulfillmentDateChanged,
  formChanged,
  fulfillmentDateChanged,
  getOrderDetail,
  noteChanged,
  outOfNetworkReasonChanged,
  vendorChanged,
} from '../data/orderDetail'
import { orderPreview } from '../data/orderPreview'
import { getUnsavedChanges } from '../data/saveIndicator'
import { orderSelected } from '../data/selectedOrder'
import OrdersSaveButton from './OrdersSaveButton'

const styles = ({ palette, spacing }) => ({
  alert: {
    marginBottom: spacing(2),
  },
  assignee: {
    opacity: '0.4',
  },
  dateField: {
    marginRight: 20,
    minWidth: 200,
    padding: [spacing(1), 0],
  },
  headerInfo: {
    '& h1': {
      color: '#ff9800',
    },
  },
  history: {
    margin: [spacing(3), 0, spacing(1)],
  },
  orderDetailHead: {
    marginBottom: spacing(2),
  },
  orderDetailContent: {
    paddingBottom: spacing(2),
  },
  orderDetailTitle: {
    borderBottom: `4px solid ${palette.background.default}`,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
    paddingBottom: spacing(1.5),
  },
  orderDetailBody: {
    marginBottom: spacing(1),
  },
  orderForm: {
    padding: [0, spacing(1)],
  },
  padded: {
    padding: [spacing(1), 0, 0],
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(2),
  },
  readonly: {
    color: palette.error.main,
  },
  vendorSearch: {
    flexGrow: 1,
    padding: '8px 0 12px 0',
  },
})

const formatVendorSuggestion = ({
  billingAddress,
  billingAddress2,
  billingCity,
  billingPostalCode,
  billingStateCode,
  fax,
  name,
  phone,
}) => {
  const haveAddress =
    billingAddress && billingCity && billingStateCode && billingPostalCode
  const address =
    haveAddress &&
    `${billingAddress}, ${
      billingAddress2 && `${billingAddress2}, `
    }${billingCity}, ${billingStateCode} ${billingPostalCode}`

  return (
    name &&
    `${name}${address && ` • ${address}`}${phone && ` • phone: ${phone}`}${
      fax && ` • fax: ${fax}`
    }`
  )
}

class OrderDetail extends PureComponent {
  formButton = React.createRef()

  state = {
    actionArgs: null,
    actualDate: '',
    applyingAction: false,
    expectedDate: '',
    hasErrors: false,
  }

  setOutOfNetworkReason = value => this.props.onOutOfNetworkReasonChange(value)

  onExpectedDateChange = expectedDate => {
    this.setState({ expectedDate: expectedDate })
    this.props.onExpectedDateChange(expectedDate)
  }

  onActualDateChange = actualDate => {
    this.setState({ actualDate: actualDate })
    this.props.onActualDateChange(actualDate)
  }

  onFormChange = ({ formData, errors }) => {
    const hasErrors = errors.length > 0 ? true : false
    this.setState({ hasErrors })
    this.props.onFormChange(formData)
  }

  onApplyAction = (action, cancelReason, actionNote) =>
    this.setState(
      { applyingAction: true, actionArgs: [action, cancelReason, actionNote] },
      () => {
        action.requireValidation
          ? this.formButton.click()
          : this.onFormSubmitted()
      }
    )

  onSelectNewVendor = (newVendorId, newVendor) => {
    newVendor?.isInNetwork && this.props.onOutOfNetworkReasonChange(null)
    return this.props.onVendorChanged(newVendorId, newVendor)
  }

  onFormSubmitted = () => {
    this.props.onAction(...this.state.actionArgs)
    this.setState({ applyingAction: true, actionArgs: null })
  }

  onError = () => {
    this.setState({ hasErrors: true, applyingAction: false })
  }

  renderVendor = isHumanaVbid => {
    const {
      canEditDetail,
      detail: { vendor, vendorName },
    } = this.props

    return canEditDetail ? (
      <VendorSearch
        formatSuggestion={formatVendorSuggestion}
        isHumanaVbid={isHumanaVbid}
        typeFilters={['Vendor', 'Hospice']}
        onSelect={this.onSelectNewVendor}
        selectedVendor={vendor}
      />
    ) : (
      <React.Fragment>
        <Typography variant="subtitle1">Vendor</Typography>
        <Typography variant="caption">Name: {vendorName}</Typography>
        {vendor && (
          <Typography variant="caption">
            Phone: {vendor.phone || 'Not available'}
          </Typography>
        )}
        {vendor && (
          <Typography variant="caption">
            Fax: {vendor.fax || 'Not available'}
          </Typography>
        )}
      </React.Fragment>
    )
  }

  renderActions = outOfNetworkReasonRequired => {
    const {
      canApplyAction,
      cancellationReasons,
      classes,
      detail,
      previewOrderPdf,
      orderId,
      row,
      onSelectOrder,
      unsavedChanges,
    } = this.props

    const { hasErrors } = this.state

    return detail && detail.actions ? (
      <div className={classes.padded}>
        {!canApplyAction && (
          <Alert className={classes.alert} severity="error">
            You are currently unable to perform actions on this order.
          </Alert>
        )}
        <ActionButtons
          actions={detail.actions}
          cancellationReasons={cancellationReasons}
          disabled={!canApplyAction}
          hasErrors={hasErrors}
          onAction={this.onApplyAction}
          onSelectOrder={() => onSelectOrder(orderId)}
          orderId={orderId}
          outOfNetworkReasonRequired={outOfNetworkReasonRequired}
          previewOrderPdf={previewOrderPdf}
          status={row.status}
          substatus={row.substatus}
          unsavedChanges={unsavedChanges}
          vendorId={detail.vendorId}
        />
      </div>
    ) : null
  }

  render() {
    const {
      canEditDetail,
      classes,
      coverages,
      detail,
      onNoteChange,
      primaryInsuranceName,
      row,
      orderId,
      unsavedChanges,
    } = this.props
    const isVbidEligible = coverages
      .filter(c => c.vbid !== null)
      .filter(c => c.priority === 1)
      .filter(c => c.vbid.isVbid)

    const isHumanaVbid =
      isVbidEligible.size > 0 &&
      primaryInsuranceName === 'Humana' &&
      row.type === 'hospice'

    const showOutOfNetworkOptions =
      isHumanaVbid &&
      !(!detail?.vendorId || (detail?.vendorId && detail?.vendor?.isInNetwork))

    const outOfNetworkReasonRequired =
      showOutOfNetworkOptions && !detail.outOfNetworkReason

    const { applyingAction } = this.state
    const form = detail && detail.form ? detail.form : null
    const actionApplied = detail && detail.actionApplied
    const formDisabled = applyingAction || actionApplied || !canEditDetail

    return (
      <div>
        <div className={classes.headerInfo}>
          <Typography
            className={classes.orderDetailHead}
            color="primary"
            variant="h5"
          >
            Order Detail
          </Typography>

          {form && !canEditDetail ? (
            <Typography variant="body1" className={classes.readonly}>
              This form is currently in read-only mode.
            </Typography>
          ) : null}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.orderDetailContent}>
            <div className={classes.orderDetailTitle}>
              <Typography variant="h6">{row.description}</Typography>
              {detail && detail.currentlyAssignedToName && (
                <Typography
                  className={classes.assignee}
                  component="span"
                  variant="h6"
                >
                  Current Assignee: {detail.currentlyAssignedToName}
                </Typography>
              )}
            </div>
            <div className={classes.orderDetailBody}>
              {this.renderVendor(isHumanaVbid)}
              {showOutOfNetworkOptions ? (
                <ReasonForSelectionOptions
                  setOutOfNetworkReason={this.setOutOfNetworkReason}
                  outOfNetworkReason={detail.outOfNetworkReason}
                />
              ) : null}
              <DateField
                className={classes.dateField}
                label="Expected Fulfillment Date"
                id="Expected Fulfillment Date"
                value={
                  detail && detail.expectedFulfillmentDate
                    ? detail.expectedFulfillmentDate
                    : this.state.expectedDate
                }
                onChange={this.onExpectedDateChange}
              />
              <DateField
                className={classes.dateField}
                label="Actual Fulfillment Date"
                id="Actual Fulfillment Date"
                value={
                  detail && detail.fulfillmentDate
                    ? detail.fulfillmentDate
                    : this.state.actualDate
                }
                onChange={this.onActualDateChange}
              />
              <div className={classes.padded}>
                <OrderNotes
                  notes={detail && detail.note ? detail.note : ''}
                  onNoteChange={onNoteChange}
                />
              </div>
            </div>
          </div>

          {form ? (
            <JsonForm
              className={classes.orderForm}
              schema={form.schema}
              uiSchema={{
                ...form.uiSchema,
                'ui:disabled': formDisabled,
              }}
              formData={form.data}
              onChange={this.onFormChange}
              onSubmit={this.onFormSubmitted}
              onError={this.onError}
            >
              <button
                style={{ display: 'none' }}
                ref={b => (this.formButton = b)}
                type="submit"
              />
            </JsonForm>
          ) : (
            <Typography variant="h6">Loading order form...</Typography>
          )}
          {detail && detail.history ? (
            <div className={classes.history}>
              <OrderHistory history={detail.history} />
            </div>
          ) : null}

          {this.renderActions(outOfNetworkReasonRequired)}
        </Paper>
        <OrdersSaveButton orderId={orderId} unsavedChanges={unsavedChanges} />
      </div>
    )
  }
}

OrderDetail.propTypes = {
  canApplyAction: PropTypes.bool,
  canEditDetail: PropTypes.bool,
  cancellationReasons: PropTypes.object,
  classes: PropTypes.object,
  coverages: PropTypes.list,
  detail: PropTypes.object,
  onAction: PropTypes.func,
  onActualDateChange: PropTypes.func,
  onExpectedDateChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onNoteChange: PropTypes.func,
  onOutOfNetworkReasonChange: PropTypes.func,
  onSelectOrder: PropTypes.func,
  onVendorChanged: PropTypes.func,
  orderId: PropTypes.number,
  previewOrderPdf: PropTypes.func,
  primaryInsuranceName: PropTypes.string,
  row: PropTypes.object,
  unsavedChanges: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
  const detail = ownProps.orderId
    ? getOrderDetail(state, ownProps.orderId) || {}
    : {}

  return {
    canApplyAction: ownProps.orderId
      ? canApplyAction(state, ownProps.orderId)
      : false,
    cancellationReasons: detail.cancellationReasonKey
      ? getFieldValues(detail.cancellationReasonKey)(state)
      : null,
    canEditDetail: ownProps.orderId
      ? canEditDetail(state, ownProps.orderId)
      : false,
    coverages: getPatientInfoProp(state, 'coverages'),
    detail: detail,
    primaryInsuranceName: getPatientInfoProp(state, 'primaryInsuranceName'),
    unsavedChanges: getUnsavedChanges(state),
    userId: getUserId(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAction: (action, cancelReason, actionNote) =>
    dispatch(
      applyActionRequested(ownProps.orderId, action, cancelReason, actionNote)
    ),
  onFormChange: formData => dispatch(formChanged(ownProps.orderId, formData)),
  onNoteChange: note => dispatch(noteChanged(ownProps.orderId, note)),
  onVendorChanged: (vendorId, vendor) =>
    dispatch(vendorChanged(ownProps.orderId, vendorId, vendor)),
  onOutOfNetworkReasonChange: outOfNetworkReason =>
    dispatch(outOfNetworkReasonChanged(ownProps.orderId, outOfNetworkReason)),
  onExpectedDateChange: expectedFulfillmentDate =>
    dispatch(
      expectedFulfillmentDateChanged(ownProps.orderId, expectedFulfillmentDate)
    ),
  onActualDateChange: fulfillmentDate =>
    dispatch(fulfillmentDateChanged(ownProps.orderId, fulfillmentDate)),
  previewOrderPdf: id => dispatch(orderPreview.requested(id)),
  onSelectOrder: id => dispatch(orderSelected(id)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OrderDetail)
