import { Action, State } from './covid19T'
import * as Status from './covid19/status'
import { Request } from '~/utils/Requestable'
import * as Toast from '~/utils/toast'
import Aspire from '~/resources/aspire-strict'
import { Action as ReduxA, State as ReduxS } from '~/reducerT'
import { flow } from 'fp-ts/lib/function'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import { payloadGet } from '~/utils/type'
import { JsonFormT } from '~/data/form'

export const init: State = {
  status: Status.init,
  vaccine: DE.initial,
}

export const fetchVaccineInfo = (
  patientId: string // TODO: AGHGHGHGHGHG newtypes...
): ReduxA =>
  Request<JsonFormT>(
    Aspire.get(
      `v1/patients/${patientId}/covid19_vaccine/new`,
      JsonFormT.strictType.asDecoder()
    ),
    payload => ReduxA.of.Covid19({ payload: Action.of.Vaccine({ payload }) })
  )

export const saveVaccineInfo = (
  patientId: string // TODO: AGHGHGHGHGHG newtypes...
) => (form: JsonFormT): ReduxA =>
  Request<JsonFormT>(
    Aspire.put(
      `v1/patients/${patientId}/covid19_vaccine`,
      JsonFormT.strictType.asDecoder(),
      { form }
    ),
    payload => ReduxA.of.Covid19({ payload: Action.of.Vaccine({ payload }) }),
    {
      reloader: ReduxS.lensFromPath(['covid19', 'vaccine']).get,
      toast: Toast.message(
        _ => 'COVID-19 vaccine update created.',
        _ => O.some('Could not save COVID-19 vaccines update.')
      ),
    }
  )

export const reducer: (_: Action) => (_: ReduxS) => ReduxS = flow(
  payloadGet,
  Action.matchStrict({
    Status: Status.reducer,
    Vaccine: ({ payload: new_ }) =>
      ReduxS.lensFromPath(['covid19', 'vaccine']).modify(old =>
        DE.isSuccess(old) && DE.isFailure(new_) ? DE.toReplete(old) : new_
      ),
  })
)
export { Status }
