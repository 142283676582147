export default 'generateDocument'
export const defaultFormsKey = 'defaultForms'
export const templateKeys = {
  facilityDischarge: 'Facility_Discharge_Report',
  facilityToHome: 'Facility_To_Home',
  unableToReach: 'Unable_To_Reach',
  hospiceNotification: 'Hospice_Notification',
  carePlan: 'Care_Plan',
  welcomeLetter: 'Welcome_Letter',
}
