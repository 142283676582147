import cx from 'classnames'
import React from 'react'
import { AccordionDetails } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ModifierGroup } from '../types/types'
import ImoModifierGroup from './imoModifierGroup'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexWrap: 'wrap',
    },
  })
)

type Props = {
  id: string
  modifierGroups: ModifierGroup[]
}

const ImoModifierGroups = ({ id, modifierGroups }: Props) => {
  const classes = useStyles()
  return (
    <AccordionDetails className={cx(classes.root)}>
      {modifierGroups.map((modifierGroup: ModifierGroup) =>
        modifierGroup.modifiers.every(modifier => modifier.disabled) ? null : (
          <ImoModifierGroup
            key={modifierGroup.type}
            id={id}
            modifierGroup={modifierGroup}
          />
        )
      )}
    </AccordionDetails>
  )
}

export default ImoModifierGroups
