export default {
  taskDetails: {
    completedOn: null,
    createdAt: '2019-09-12T14:52:15.798585',
    description: 'A new signed HIPAA form is required to be on file.',
    dueOn: '2019-09-13',
    formId: null,
    id: 6,
    modifiedAt: '2019-09-12T14:52:13.143464',
    modifiedBy: 'system',
    ownerId: '0051C000005OfmuQAC',
    ownerRole: 'app',
    patientId: '0011C00001vFs6YQAS',
    priority: null,
    requestedBy: '00515000006lz3QAAQ',
    startOn: '2019-09-13',
    status: 'new',
    taskDefinitionKey: 'hipaa_authorization_required',
    title: 'HIPAA Authorization Required',
  },
}
