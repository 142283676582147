import { OrderedMap, Record, Set } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const PERMISSION_SETS = 'permissionSets'

export const PermissionSet = Record({
  id: null,
  description: null,
  permissionIds: Set(),
})

const transformPermissionSet = ({ permissionLinks, ...permissionSet }: any) => {
  const permissionIds = Set(
    permissionLinks.map(({ permissionId }: any) => permissionId)
  )

  return PermissionSet({ ...permissionSet, permissionIds })
}

const transformPermissionSets = (permissionSets: any) =>
  OrderedMap(
    permissionSets.map((permissionSet: any) => [
      permissionSet.id,
      transformPermissionSet(permissionSet),
    ])
  )

export const fetchPermissionSets = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PERMISSION_SETS',
  operation: () => AspireAPI.get('admin_tools/permission_sets'),
  transform: transformPermissionSets,
  messages: { failed: 'There was an issue fetching permission sets' },
})

export const createPermissionSet = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_PERMISSION_SET',
  requestParams: ['permissionSet'],
  operation: (permissionSet: any) =>
    AspireAPI.post('admin_tools/permission_sets', permissionSet),
  transform: transformPermissionSet,
  messages: {
    succeeded: 'Permission set successfully created',
    failed: 'There was an issue creating this permission set',
  },
})

export const savePermissionSet = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PERMISSION_SET',
  requestParams: ['id', 'permissionSet'],
  operation: (id: any, permissionSet: any) =>
    AspireAPI.put(`admin_tools/permission_sets/${id}`, permissionSet),
  transform: transformPermissionSet,
  messages: {
    succeeded: 'Permission set successfully saved',
    failed: 'There was an issue saving this permission set',
  },
})

export const deletePermissionSet = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_PERMISSION_SET',
  requestParams: ['id'],
  operation: (id: any) => AspireAPI.delete(`admin_tools/permission_sets/${id}`),
  messages: {
    succeeded: 'Permission set successfully deleted',
    failed: 'There was an issue deleting this permission set',
  },
})

export default createReducer(PERMISSION_SETS, OrderedMap(), {
  [fetchPermissionSets.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [createPermissionSet.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set(payload.id, payload),
  [savePermissionSet.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set(payload.id, payload),
  [deletePermissionSet.SUCCEEDED]: (state: any, { meta }: any) =>
    state.delete(meta.request.payload.id),
})

export const getPermissionSets = pipe(getRoot, get(PERMISSION_SETS))
export const getPermissionSetById = (state: any, permissionSetId: any) =>
  getPermissionSets(state).get(permissionSetId, PermissionSet())
