import { Typography } from '@material-ui/core'
import Link from './Link'

const config = {
  h1: {
    component: Typography,
    props: {
      variant: 'h1',
    },
  },
  h2: {
    component: Typography,
    props: {
      variant: 'h2',
    },
  },
  h3: {
    component: Typography,
    props: {
      variant: 'h3',
    },
  },
  h4: {
    component: Typography,
    props: {
      variant: 'h4',
    },
  },
  h5: {
    component: Typography,
    props: {
      variant: 'h5',
    },
  },
  h6: {
    component: Typography,
    props: {
      variant: 'h6',
    },
  },
  span: {
    component: Typography,
    props: {
      component: 'span',
      variant: 'body2',
    },
  },
  p: {
    component: Typography,
  },
  li: {
    component: Typography,
  },
  a: {
    component: Link,
  },
}

export default config
