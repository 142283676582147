import { Map, Record } from 'immutable'
import moment from 'moment'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import {
  action,
  actionCreator,
  creator,
  get,
  getIn,
  into,
  payload,
  typeCreator,
} from '~/utils/data'
import { addBusinessDays } from '~/utils/dates'
import { compose, pipe } from '~/utils/functionalHelpers'
import CALL from '../key'
import { getCall } from './common/shared'
import { Form } from './form'

// Key
const CALLS = 'calls'

// Type
const typePrefix = `${CALL}/${CALLS}`
const type = typeCreator(typePrefix)

export const CALL_SELECTED = type(CALL, 'CALL_SELECTED')

// Actions
export const onCallExpanded = actionCreator(type('EXPANDED'), ['formId', 'id'])
export const callSelected = creator(CALL_SELECTED, 'id')

// Record
export const User = Record({
  id: null,
  name: null,
  role: null,
})

export const Patient = Record({
  id: null,
  name: null,
})

export const Call = Record({
  createdAt: null,
  completedAt: null,
  form: Form(),
  id: null,
  userId: null,
  user: User(),
  patientId: null,
  patient: Patient(),
  type: null,
})

const transformCall = ({
  patient,
  form: { data: formData, ...form },
  user,
  ...call
}) =>
  Call({
    form: Form({ formData, ...form }),
    user: User(user),
    patient: Patient(patient),
    ...call,
  })

// Requests
export const fetchCall = Request({
  typePrefix,
  typeBase: 'FETCH_ONE',
  requestParams: ['id'],
  operation: id => AspireAPI.get(`/v1/patient_forms/${id}`),
  transform: transformCall,
  messages: { failed: 'Failed to load call' },
})

export const fetchCallsForPatient = Request({
  typePrefix,
  typeBase: 'FETCH_ALL_FOR_PATIENT',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`/v1/patients/${patientId}/patient_form`),
  transform: into(transformCall, 'id'),
  messages: {
    failed: 'Failed to load calls for patient',
  },
})

export const fetchCallsForUserId = Request({
  typePrefix,
  typeBase: 'FETCH_ALL_FOR_USER',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`v1/patient_forms`, { params: { userId } }),
  transform: into(transformCall, 'id'),
  messages: {
    failed: 'Failed to load calls for user',
  },
})

// Reducer
export default createReducer(CALLS, Map(), {
  [fetchCall.SUCCEEDED]: (state, { payload }) => state.set(payload.id, payload),
  [fetchCallsForPatient.SUCCEEDED]: compose(payload, action),
  [fetchCallsForUserId.SUCCEEDED]: compose(payload, action),
})

// Selectors
const getCalls = pipe(getCall, get(CALLS))

export const getCallsArray = createSelector(getCalls, calls =>
  calls.toIndexedSeq().toJS()
)

export const getCallById = id => pipe(getCalls, get(id))

// gets counts of on calls in the last two days
// used by the cm dash
export const getRecentCallsCount = createSelector(
  getIn([CALL, CALLS]),
  calls =>
    calls.filter(
      oc => moment(oc.dateTimeOfCall) > addBusinessDays(moment(), -2)
    ).size
)
