import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import InvisibleDivider from '~/components/InvisibleDivider'
import {
  StatisticCard,
  StatisticCardContainer,
} from '~/components/StatisticCards'
import { removeUnderscores, titleCase } from '~/utils/stringManipulation'
import { withStyles } from '@material-ui/styles'
import { tabChanged } from '../../data/currentOrder'
import {
  getAssignedOrderCountsBySubstatus,
  getCountOrdersForPatientContext,
  getPatientOrderCountsBySubstatus,
} from '../../data/orders'
import { getOrdersUserId, userIdChanged } from '../../data/userId'
import OrdersGrid from '../OrdersGrid'

const styles = ({ spacing }) => ({
  badge: {
    paddingRight: spacing(2),
  },
  columns: {
    marginBottom: spacing(2),
  },
  countContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  headerTitle: {
    marginBottom: spacing(2),
  },
})

class Orders extends PureComponent {
  componentDidUpdate() {
    const { dashUserId, userId } = this.props
    if (dashUserId && dashUserId !== userId) {
      this.props.userIdChanged(dashUserId)
    }
  }

  render() {
    const {
      route,
      userId,
      ordersContext,
      patientId,
      countsBySubstatusPatient,
      countsBySubstatusUser,
    } = this.props

    const countsBySubstatus =
      ordersContext == 'patient'
        ? countsBySubstatusPatient
        : countsBySubstatusUser

    return (
      <div>
        <DevXTitleHeader title="Order Summary" />
        {!isEmpty(countsBySubstatus) && (
          <StatisticCardContainer columns={3} dense>
            {Object.keys(countsBySubstatus).map(key => (
              <StatisticCard
                key={key}
                label={titleCase(removeUnderscores(key))}
                value={countsBySubstatus[key]}
              />
            ))}
          </StatisticCardContainer>
        )}
        <InvisibleDivider />
        <DevXTitleHeader title="Orders" />
        <OrdersGrid
          route={route}
          userId={userId}
          ordersContext={ordersContext}
          patientId={patientId}
        />
      </div>
    )
  }
}
Orders.propTypes = {
  route: PropTypes.string,
  userId: PropTypes.string,
  patientId: PropTypes.string,
  ordersContext: PropTypes.string,
  userIdChanged: PropTypes.func,
  dashUserId: PropTypes.string,
  countsBySubstatusUser: PropTypes.object,
  countsBySubstatusPatient: PropTypes.object,
}
const mapStateToProps = state => ({
  userId: getOrdersUserId(state),
  countPatientOrders: getCountOrdersForPatientContext(state),
  countsBySubstatusUser: getAssignedOrderCountsBySubstatus(state),
  countsBySubstatusPatient: getPatientOrderCountsBySubstatus(state),
})

const mapDispatchToProps = dispatch => ({
  onTabChange: value => dispatch(tabChanged(value)),
  userIdChanged: id => dispatch(userIdChanged(id)),
})

Orders.defaultProps = {
  ordersContext: 'user',
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Orders)
