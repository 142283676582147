import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import {
  checkInSnackbarOpened,
  checkIntoEncounter,
  checkOutOfEncounter,
  checkOutSnackbarOpened,
  getRoot,
} from './common/shared'

const key = 'checkInDisabled'

export default createReducer(key, false, {
  [checkInSnackbarOpened]: () => true,
  [checkOutSnackbarOpened]: () => true,
  [checkIntoEncounter.SUCCEEDED]: () => false,
  [checkOutOfEncounter.SUCCEEDED]: () => false,
  [checkIntoEncounter.FAILED]: () => false,
  [checkOutOfEncounter.FAILED]: () => false,
})

export const getCheckInDisabled = pipe(getRoot, get(key))
