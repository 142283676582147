import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { orderSelected } from '~/features/orders'
import { taskSelected } from '~/features/tasks'
import {
  PATIENT_RECORD_ORDER_DETAIL,
  PATIENT_RECORD_TASK_DETAIL,
} from '../router'

const orders = action$ =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_ORDER_DETAIL)),
    pluck('payload', 'params', 'orderId'),
    map(orderSelected)
  )

const tasks = action$ =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_TASK_DETAIL)),
    pluck('payload', 'params', 'taskId'),
    map(taskSelected)
  )

export default combineEpics(orders, tasks)
