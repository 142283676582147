import { combineEpics, ofType } from 'redux-observable'
import { match } from 'redux-routable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getRoute } from '~/data/route'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { snoozePatient as callLogSnoozePatient } from '~/features/callLogger'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { snoozePatient as schedulingSnoozePatient } from '~/features/scheduling/data'
import { patientSnoozed } from '../data/patientList'
import { referralSchedulingRouter } from '../router'

const callLogPatientSnoozed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(callLogSnoozePatient.SUCCEEDED),
    pluck('meta', 'request', 'payload'),
    withLatestFrom(state$),
    filter(([_, state]) => match(getRoute(state), referralSchedulingRouter)),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([payload]: [any]) => any' is no... Remove this comment to see the full error message
    map(([payload]) => patientSnoozed(payload.patientId, payload.snooze))
  )

const schedulingPatientSnoozed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(schedulingSnoozePatient.SUCCEEDED),
    pluck('meta', 'request', 'payload'),
    withLatestFrom(state$),
    filter(([_, state]) => match(getRoute(state), referralSchedulingRouter)),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([payload]: [any]) => any' is no... Remove this comment to see the full error message
    map(([payload]) => patientSnoozed(payload.patientId, payload.snooze))
  )

export default combineEpics(callLogPatientSnoozed, schedulingPatientSnoozed)
