import moment from 'moment'
import React from 'react'
import { CSS_DASHBOARD_APP_SCHEDULING_PATIENT } from '~/apps/cssDashboard/router'
import { PATIENT_RECORD_PATIENT_INFO } from '~/apps/patientRecord/router'
import { PES_DASHBOARD_REFERRAL_SCHEDULING_PATIENT } from '~/apps/pesDashboard/router'
import TypographyLink from '~/components/TypographyLink'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette, spacing }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  patientInfo: {
    display: 'flex',
  },
  label: {
    color: palette.contentText.primaryLabel,
    marginRight: spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
  linkButton: {
    backgroundColor: '#ff9800',
    borderRadius: 4,
    color: '#fff',
    margin: [0, 4],
    padding: [2, 8],
  },
  linkText: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  spacer: {
    margin: [0, spacing(1)],
  },
})

const PrimaryText = ({
  classes,
  patient: { id, appId, fullName, status, subStatus, birthdate },
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.patientInfo}>
        <TypographyLink
          action="open"
          className={classes.linkText}
          route={PATIENT_RECORD_PATIENT_INFO}
          params={{ patientId: id }}
        >
          {fullName}
        </TypographyLink>
        <Typography className={classes.spacer}>•</Typography>

        <Typography>
          {status}/{subStatus}
        </Typography>
        <Typography className={classes.spacer}>•</Typography>

        <Typography className={classes.label}>DOB:</Typography>
        <Typography>{moment(birthdate).format('MM/DD/YYYY')}</Typography>
        <Typography className={classes.spacer}></Typography>
      </div>
      <div className={classes.patientInfo}>
        <TypographyLink
          action="open"
          className={classes.linkButton}
          params={{ appId: appId, patientId: id }}
          route={CSS_DASHBOARD_APP_SCHEDULING_PATIENT}
        >
          APP Scheduling
        </TypographyLink>

        {status === 'Referred' && (
          <TypographyLink
            action="open"
            className={classes.linkButton}
            route={PES_DASHBOARD_REFERRAL_SCHEDULING_PATIENT}
            params={{ appId: appId, patientId: id }}
          >
            Referral Scheduling
          </TypographyLink>
        )}
      </div>
    </div>
  )
}

PrimaryText.propTypes = {
  classes: PropTypes.object.isRequired,
  patient: PropTypes.record.isRequired,
}

export default withStyles(styles)(PrimaryText)
