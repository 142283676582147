import { ORDERS_API_PATH } from 'ahc-config'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import OrdersApi from '~/resources/orders'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getAllOrders } from '../data/orders'
import { getOrderKey } from './common/shared'

export const key = 'selectedOrder'
// ACTIONS
export const ORDER_SELECTED = type(key, 'ORDER_SELECTED')
export const ROUTE_ORDER = type(key, 'ROUTE_ORDER')
export const SUBMIT_ORDER_ACTION_REQUESTED = type(
  key,
  'SUBMIT_ORDER_ACTION_REQUESTED'
)
export const SUBMIT_ORDER_ACTION_SUCCESSFUL = type(
  key,
  'SUBMIT_ORDER_ACTION_SUCCESSFUL'
)

// ACTION CREATORS
export const orderSelected = creator(ORDER_SELECTED, 'id')
export const submitOrderActionRequested = creator(
  SUBMIT_ORDER_ACTION_REQUESTED,
  'actionType',
  'userId',
  'notes',
  'orderId',
  'cancelReason',
  'id'
)
export const submitOrderActionSuccessful = creator(
  SUBMIT_ORDER_ACTION_SUCCESSFUL
)

// REDUCER
const initState = null

export default createReducer(key, initState, {
  [ORDER_SELECTED]: (state, { payload: { id } }) => id,
})

// SELECTORS
export const getSelectedOrder = pipe(getOrderKey, get(key))

export const getSelectedOrderDetails = createSelector(
  [getAllOrders, getSelectedOrder],
  (orders, selectedOrder) => {
    const orderId = parseInt(selectedOrder)
    return orders.get(orderId)
  }
)

export const submitOrderAction = (actionType, orderId, id) => {
  const paths = {
    approve: 'approved_order',
    deny: 'denied_order',
    cancel: 'cancelled',
  }
  const body = {
    thingId: orderId,
    taskId: String(id),
    workflow: 'OrderFulfillment',
    path: paths[actionType],
  }
  const url = `${ORDERS_API_PATH}/orders_workflow/workflow/orderfulfillment`

  return OrdersApi.post(url, body)
}

// ASYNC
// legacy orders
export const submitOrderUpdate = (
  type,
  userId,
  notes,
  orderId,
  cancellationReason
) =>
  AspireAPI.put('v1/orders/update', {
    userId,
    orderId,
    notes,
    type,
    cancellationReason,
  })
