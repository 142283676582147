import { ofType } from 'redux-observable'
import { debounceTime, map, pluck } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { Stakeholder, getRoot } from './common/shared'

const SUGGESTED_STAKEHOLDERS = 'suggestedStakeholders'

const creator = scopedCreator(rootKey)
export const suggestedStakeholderSearchWordChanged = creator(
  'SUGGESTED_STAKEHOLDER_SEARCH_WORD_CHANGED',
  ['searchWord', 'type']
)
export const suggestedStakeholdersCleared = creator(
  'SUGGESTED_STAKEHOLDERS_CLEARED'
)

export const fetchSuggestedStakeholders = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_SUGGESTED_STAKEHOLDERS',
  requestParams: ['searchWord', 'type'],
  operation: (searchWord, type) =>
    AspireAPI.get(`stakeholders/search`, {
      params: { searchWord, type },
    }),
  transform: result => result.map(Stakeholder),
  messages: { failed: 'There was a problem fetching suggested stakeholders' },
})

export default createReducer(SUGGESTED_STAKEHOLDERS, [], {
  [fetchSuggestedStakeholders.SUCCEEDED]: (_state, { payload }) => payload,
  [suggestedStakeholdersCleared]: () => [],
})

const changeSuggestedStakeholderSearchwordEpic = action$ =>
  action$.pipe(
    ofType(suggestedStakeholderSearchWordChanged),
    debounceTime(500),
    pluck('payload'),
    map(({ searchWord, type }) =>
      fetchSuggestedStakeholders.requested(searchWord, type)
    )
  )

export const epic = changeSuggestedStakeholderSearchwordEpic

export const getSuggestedStakeholders = pipe(
  getRoot,
  get(SUGGESTED_STAKEHOLDERS)
)
