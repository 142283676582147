import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'

const UploadConfirmation = ({
  open,
  fileName,
  confirm,
  cancel,
  type,
  insurance,
  faxToPCP,
  toggleFaxToPCP,
}) => {
  const scan = 'SCAN'
  const faxableAttType = 'ACP: POST/POLST/MOST/MOLST'

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={cancel}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">Confirm Upload: {type}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to upload ${fileName}?`}
        </DialogContentText>
        {insurance === scan && type === faxableAttType && (
          <FormControlLabel
            control={
              <Checkbox
                checked={faxToPCP}
                onChange={toggleFaxToPCP}
                name="faxToPCP"
                color="primary"
              />
            }
            label="Fax to PCP?"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button autoFocus color="primary" onClick={confirm}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UploadConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  faxToPCP: PropTypes.bool.isRequired,
  fileName: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  insurance: PropTypes.string,
  toggleFaxToPCP: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default UploadConfirmation
