import { ROUTE_CHANGED } from 'redux-routable'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'

// Key
const key = 'route'

// Reducer
export default createReducer(key, null, {
  [ROUTE_CHANGED]: (state, { payload }) => payload.route,
})

// Selectors
export const getRoute = get(key)
