import { Iterable, Map } from 'immutable'
import {
  Action as UserTimelineEventsA,
  State as UserTimelineEventS,
} from '~/features/userTimeline/data/userTimelineEventsT'
import {
  Action as PlaceOfServiceA,
  State as PlaceOfServiceS,
} from '~/features/placeOfService/data/types'
import {
  Action as Covid19A,
  State as Covid19,
} from '~/features/patientInfo/data/covid19T'
import { Action as LLOSA, State as LLOS } from '~/features/llos/LLOST'
import {
  Action as APPOptionA,
  State as APPOptionS,
} from '~/apps/pesDashboard/data/appOptionsT'
import {
  State as NoticeOfPrivacyPracticesS,
  Action as NoticeOfPrivacyPracticesA,
} from './features/noticeOfPrivacyPractices/data/formT'
import {
  State as AuthToReleaseS,
  Action as AuthToReleaseA,
} from './features/authToRelease/data/formT'
import { State as HIPAAS, Action as HIPAAA } from './features/hipaa/data/formT'

import {
  ADTType,
  AType,
  AsOpaque,
  makeADT,
  ofType,
  EType,
  ReduxAction,
  summon,
} from './utils/type'
import { Async, Resource } from '~/resources/resource-strict'
import { Action as Toast, Msg as ToastS, Fn as ToastFn } from '~/utils/toast'

const State_ = summon(F =>
  F.interface(
    {
      userTimelineEvents: UserTimelineEventS(F),
      placeOfService: PlaceOfServiceS(F),
      covid19: Covid19(F),
      llos: LLOS(F),
      appOption: APPOptionS(F),
      noticeOfPrivacyPractices: NoticeOfPrivacyPracticesS(F),
      authToRelease: AuthToReleaseS(F),
      hipaa: HIPAAS(F),
      toasts: F.array(ToastS(F)),
    },
    'State'
  )
)
export interface State extends AType<typeof State_> {}
export interface StateRaw extends EType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)

export const Action = makeADT('type')({
  Request: ofType<Request>(),

  // add your child reducer definitions here!
  UserTimelineEvent: ofType<UserTimelineEventsA>(),
  PlaceOfService: ofType<PlaceOfServiceA>(),
  Covid19: ofType<Covid19A>(),
  LLOS: ofType<LLOSA>(),
  APPOption: ofType<APPOptionA>(),
  NoticeOfPrivacyPractices: ofType<NoticeOfPrivacyPracticesA>(),
  AuthToRelease: ofType<AuthToReleaseA>(),
  HIPAA: ofType<HIPAAA>(),
  Toast: ofType<Toast>(),

  // Person: Constructor<PersonA>(),
  // Place: Constructor<PlaceA>(),
})
export type Action = ADTType<typeof Action>

// NOTE: combineImmutableReducers
export const ts = (s: Map<string, Iterable.Indexed<State>>): State =>
  s.get('tsReducer').toJS()

export interface Requestable_<A> {
  creator: (a: Async<A>) => Action
  resource: Resource<A>
  reloader?: (_: State) => Async<A>
  toast?: ToastFn<A>
}
export type Requestable = <R>(run: <A>(_: Requestable_<A>) => R) => R

export type Request = ReduxAction<'Request', Requestable>
