import React from 'react'
import { InputAdornment } from '@material-ui/core'

const onChange = ({ onChange, readonly, getValue, formContext }) => (
  ...args
) => {
  if (readonly) return

  formContext.pending.change = true
  onChange(getValue(...args))
}

const onBlur = ({ id, value, readonly, onBlur, formContext }) => () => {
  if (readonly) return

  if (formContext.pending.change) {
    formContext.pending.queue.push(() => onBlur(id, value))
  } else {
    onBlur(id, value)
  }
}

const onFocus = ({ id, value, readonly, onFocus, formContext }) => () => {
  if (readonly) return

  if (formContext.pending.change) {
    formContext.pending.queue.push(() => onFocus(id, value))
  } else {
    onFocus(id, value)
  }
}

const adornment = (text, position) =>
  text && <InputAdornment position={position}>{text}</InputAdornment>

const identity = value => value

export const valueOut = (value, { transformOut = identity, options }) =>
  value === '' ? options.emptyValue : transformOut(value)

export const valueIn = (value, { transformIn = String }) =>
  value === undefined ? '' : transformIn(value)

export const getBaseProps = props => {
  const defaultGetValue = e => valueOut(e.target.value, props)
  const getValue = props.getValue || defaultGetValue

  return {
    autoFocus: props.autofocus,
    onChange: onChange({ ...props, getValue }),
    onBlur: props.onBlur && onBlur(props),
    onFocus: props.onFocus && onFocus(props),
  }
}

export const getInputProps = props => ({
  ...getBaseProps(props),
  id: props.id,
  placeholder: props.placeholder,
  readOnly: props.readonly,
  value: valueIn(props.value, props),
  startAdornment: adornment(props.options.prefix, 'start'),
  endAdornment: adornment(props.options.suffix, 'end'),
})
