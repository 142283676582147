import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error FIXME
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'

const key = 'currentTelephonicEncounterType'
const creator = scopedCreator(key)
export const telephonicEncounterTypeChanged = creator(
  'TELEPHONIC_ENCOUNTER_TYPE_CHANGED'
)

export default createReducer(key, null, {
  [telephonicEncounterTypeChanged.toString()]: (
    _state: any,
    { payload }: any
  ) => payload,
})

export const getCurrentTelephonicEncounterType = pipe(
  get('assessment'),
  get(key)
)
