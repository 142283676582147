import { get, has } from 'lodash'
import { ofType } from 'redux-observable'
import { createSelector } from 'reselect'
import { merge } from 'rxjs'
import { debounceTime, mergeMap } from 'rxjs/operators'
import {
  ProblemDetail,
  getCarePlanProblems,
  getProblemDetailTypeMappings,
  problemDetailAdded,
  problemDetailRemoved,
} from '~/features/problems/data'
import generateId from '~/utils/generateId'
import { switchTo } from '~/utils/operators'
import { getCurrentAssessment } from '../data/common/derived'
import { SECTION_CHANGED } from '../data/common/shared'

// Grab active/historical problems
const filterProblems = createSelector([problems => problems], problems =>
  problems
    .filter(
      ({ status, problemTypeId }) => status !== 'invalid' && problemTypeId
    )
    .toIndexedSeq()
)

// Filter to only include mappings where problems exist
const filterProblemDetailTypeMappings = createSelector(
  [problems => problems, (_problems, mappings) => mappings],
  (problems, mappings) => {
    const problemTypeIds = problems.map(problem => problem.problemTypeId)

    return mappings.filter(
      ({ formTag, problemTypeId }) =>
        formTag && problemTypeIds.includes(problemTypeId)
    )
  }
)

// Decode value
const decodeValue = (value, schema) => {
  if (value === undefined) {
    return undefined
  } else if (get(schema, 'type') === 'array') {
    // Return array element values and concat them
    return value.map(v => decodeValue(v, schema.items)).join(', ')
  } else if (has(schema, 'enum') && has(schema, 'enumNames')) {
    // Return enum label from value
    const index = schema.enum.indexOf(value)
    return has(schema, 'enumShortNames')
      ? schema.enumShortNames[index]
      : schema.enumNames[index]
  } else {
    return value
  }
}

// Get detail or default ot empty object
const getDetail = (details, problemDetailTypeId) =>
  details.find(
    detail => detail.problemDetailTypeId === problemDetailTypeId,
    null,
    ProblemDetail()
  )

export default (action$, state$) =>
  action$.pipe(
    ofType(SECTION_CHANGED),
    debounceTime(500),
    switchTo(state$),
    mergeMap(state => {
      const { formData, schema, tags } = getCurrentAssessment(state)
      const problems = filterProblems(getCarePlanProblems(state))
      const mappings = filterProblemDetailTypeMappings(
        problems,
        getProblemDetailTypeMappings(state)
      )

      // Check assessment tag for each mapping
      return merge(
        mappings
          .filter(
            ({ formTag }) =>
              Boolean(tags[formTag]) &&
              has(schema, tags[formTag].schema.concat('tag')) &&
              has(schema, tags[formTag].schema.concat('tagLabel'))
          )
          .flatMap(
            ({
              id: problemDetailTypeId,
              formTag,
              problemTypeId: mappingProblemTypeId,
              suffix,
              rank,
            }) => {
              const decodedFormValue = decodeValue(
                get(formData, tags[formTag].data),
                get(schema, tags[formTag].schema)
              )
              const value =
                decodedFormValue !== undefined && suffix
                  ? `${decodedFormValue} ${suffix}`
                  : decodedFormValue
              const formTagLabel = get(
                schema,
                tags[formTag].schema.concat('tagLabel')
              )

              return problems
                .filter(
                  ({ problemTypeId }) => mappingProblemTypeId === problemTypeId
                )
                .map(({ id: problemId, details }) => {
                  const existingDetail = getDetail(details, problemDetailTypeId)

                  // Assessment field is populated
                  if (value !== undefined && value !== '') {
                    // Doesn't already exist as a problem detail
                    if (
                      existingDetail.value !== value &&
                      existingDetail.originalValue !== value
                    ) {
                      return problemDetailAdded(
                        problemId,
                        ProblemDetail({
                          id: existingDetail.id || generateId(),
                          problemDetailTypeId,
                          rank,
                          label: formTagLabel,
                          value,
                        })
                      )
                    } else if (
                      existingDetail.modified &&
                      existingDetail.value !== value &&
                      existingDetail.originalValue === value
                    ) {
                      // New detail is equal to existing detail
                      return problemDetailRemoved(problemId, existingDetail.id)
                    }
                  } else if (existingDetail.modified) {
                    // Assessment field is not populated but a new detail exists for it
                    return problemDetailRemoved(problemId, existingDetail.id)
                  }
                })
            }
          )
          .filter(Boolean)
      )
    })
  )
