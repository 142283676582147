import { Set } from 'immutable'
import { CANCELLED, FAILED, REQUESTED, SUCCEEDED } from '~/utils/Request'
import { creator, get, type } from '~/utils/data'

const key = 'pending'

export const LOADING_STARTED = type(key, 'LOADING_STARTED') // DEPRECATED
export const loadingStarted = creator(LOADING_STARTED, ['key']) // DEPRECATED

export const LOADING_STOPPED = type(key, 'LOADING_STOPPED') // DEPRECATED
export const loadingStopped = creator(LOADING_STOPPED, ['key']) // DEPRECATED

const reducer = (state = Set(), { type, meta, payload }) => {
  // DEPRECATED
  if (type === LOADING_STARTED) {
    return state.add(payload.key)
  }

  // DEPRECATED
  if (type === LOADING_STOPPED) {
    return state.delete(payload.key)
  }

  if (!meta || !meta.request) {
    return state
  }

  const { step, key } = meta.request

  switch (step) {
    case REQUESTED:
      return state.add(key)
    case SUCCEEDED:
    case FAILED:
    case CANCELLED:
      return state.delete(key)
  }
}

reducer.key = key

export default reducer

const getRoot = get(key)

export const isPending = state => !getRoot(state).isEmpty()

export const isRequestPending = (state, { key }) => getRoot(state).has(key)

export const areRequestsPending = (state, requests) =>
  requests.some(request => isRequestPending(state, request))
