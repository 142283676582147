import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from '~/utils/propTypes'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import OnCallReadOnly from '../components/OnCallGrid'
import { getOnCallsArray } from '../data/onCalls'

const styles = ({ spacing }) => ({
  paper: {
    marginTop: spacing(1),
  },
})

const OnCallView = ({ classes, onCalls }) => (
  <React.Fragment>
    <Typography variant="h5">On Call Events</Typography>
    <Typography>Count includes two business days</Typography>
    <Paper className={classes.paper}>
      <OnCallReadOnly showPatientName onCalls={onCalls} />
    </Paper>
  </React.Fragment>
)

OnCallView.propTypes = {
  classes: PropTypes.object.isRequired,
  onCalls: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  onCalls: getOnCallsArray(state),
})

export default compose(connect(mapStateToProps), withStyles(styles))(OnCallView)
