import { ofType } from 'redux-observable'
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators'
import { NEW_TASK } from '../constants'
import { dialogOpened } from '../data/dialog'
import { fetchTaskDefinitions } from '../data/taskDefinitions'

export default action$ =>
  action$.pipe(
    ofType(dialogOpened),
    pluck('payload'),
    filter(payload => payload.type === NEW_TASK),
    pluck('patientId'),
    filter(Boolean),
    distinctUntilChanged(),
    map(fetchTaskDefinitions.requested)
  )
