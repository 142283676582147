import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import {
  fetchDischargeRecommendations,
  saveDischargeRecommendation,
} from '../data/discharges'

export default (action$: any) =>
  action$.pipe(
    ofType(saveDischargeRecommendation.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'patientId'),
    map(fetchDischargeRecommendations.requested)
  )
