import React from 'react'
import {
  Checkbox,
  DateField,
  DateTimeField,
  NumberField,
  TimeField,
} from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import { Table, TableEditRow } from '@devexpress/dx-react-grid-material-ui'

const editorComponents = {
  date: DateField,
  datetime: DateTimeField,
  number: NumberField,
  time: TimeField,
  boolean: Checkbox,
}

const EditCell = rawProps => {
  if (rawProps.column.name === 'actions') {
    return <Table.Cell />
  }

  const props = {
    disabled: !rawProps.editingEnabled,
    onChange: rawProps.onValueChange,
    ...rawProps,
  }
  delete props.tableRow
  delete props.tableColumn
  delete props.editingEnabled
  delete props.onValueChange

  if (props.column.editComponent) {
    return (
      <Table.Cell>
        <props.column.editComponent {...props} />
      </Table.Cell>
    )
  }

  const CustomEditor = editorComponents[props.column.type]

  if (CustomEditor) {
    // NOTE(adam): fullWidth is not valid for a checkbox
    const customProps =
      props.column.type === 'boolean' ? props : { ...props, fullWidth: true }

    return (
      <Table.Cell>
        <CustomEditor {...customProps} />
      </Table.Cell>
    )
  }

  return <TableEditRow.Cell {...rawProps} />
}

EditCell.propTypes = {
  tableRow: PropTypes.any,
  tableColumn: PropTypes.any,
  editingEnabled: PropTypes.any,
  onValueChange: PropTypes.any,
  column: PropTypes.object,
}

export default EditCell
