import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InfoDialog } from '~/components/dialogs'
import { isRequestPending } from '~/data/pending'
import { useInput, useUpdateEffect } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Button, Divider, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  fetchNPIPhysicians,
  npiPhysiciansCleared,
} from '../../data/npiPhysicians'
import NPILookupTable from './NPILookupTable'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    marginBottom: spacing(1),
  },
  field: {
    width: 200,
    marginRight: spacing(2),
  },
  smallField: {
    width: 100,
    marginRight: spacing(2),
  },
  divider: {
    margin: [spacing(2), 0],
  },
}))

const inputProps = { maxLength: 2 }

const NPILookup = ({ open, onSelect, ...props }) => {
  const dispatch = useDispatch()

  const [number, setNumber] = useInput()
  const [firstName, setFirstName] = useInput()
  const [lastName, setLastName] = useInput()
  const [stateCode, setStateCode] = useInput()
  const [city, setCity] = useInput()
  const [postalCode, setPostalCode] = useInput()

  const currentlyLookingUpNPI = useSelector(state =>
    isRequestPending(state, fetchNPIPhysicians)
  )

  const classes = useStyles()

  const searchDisabled = useMemo(
    () =>
      (!number.trim() &&
        !firstName.trim() &&
        !lastName.trim() &&
        !stateCode.trim() &&
        !city.trim() &&
        !postalCode.trim()) ||
      currentlyLookingUpNPI,
    [
      number,
      firstName,
      lastName,
      stateCode,
      city,
      postalCode,
      currentlyLookingUpNPI,
    ]
  )

  useUpdateEffect(() => {
    dispatch(npiPhysiciansCleared())
  }, [open])

  const onSearch = useCallback(() => {
    dispatch(
      fetchNPIPhysicians.requested({
        number: number.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        stateCode: stateCode.trim(),
        city: city.trim(),
        postalCode: postalCode.trim(),
      })
    )
  }, [
    number,
    firstName,
    lastName,
    stateCode,
    city,
    postalCode,
    fetchNPIPhysicians.requested,
  ])

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter' && !searchDisabled) onSearch()
    },
    [searchDisabled, onSearch]
  )

  const onClose = useCallback(() => {
    props.onClose()
    setNumber('')
    setFirstName('')
    setLastName('')
    setStateCode('')
    setCity('')
    setPostalCode('')
  }, [
    setNumber,
    setFirstName,
    setLastName,
    setStateCode,
    setCity,
    setPostalCode,
    props.onClose,
  ])

  return (
    <InfoDialog
      open={open}
      title="NPI Lookup"
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <div className={classes.container}>
        <TextField
          className={classes.field}
          type="number"
          label="NPI Number"
          value={number}
          onChange={setNumber}
          onKeyPress={onKeyPress}
        />
        <TextField
          className={classes.field}
          label="First Name"
          value={firstName}
          onChange={setFirstName}
          onKeyPress={onKeyPress}
        />
        <TextField
          className={classes.field}
          label="Last Name"
          value={lastName}
          onChange={setLastName}
          onKeyPress={onKeyPress}
        />
        <TextField
          className={classes.smallField}
          label="State Code"
          value={stateCode}
          onChange={setStateCode}
          onKeyPress={onKeyPress}
          inputProps={inputProps}
        />
        <TextField
          className={classes.field}
          label="City"
          value={city}
          onChange={setCity}
          onKeyPress={onKeyPress}
        />
        <TextField
          className={classes.smallField}
          label="Postal Code"
          value={postalCode}
          onChange={setPostalCode}
          onKeyPress={onKeyPress}
        />

        <Button
          color="primary"
          variant="contained"
          disabled={searchDisabled}
          onClick={onSearch}
        >
          Search
        </Button>
      </div>

      <Divider className={classes.divider} />

      <NPILookupTable onSelect={onSelect} />
    </InfoDialog>
  )
}

NPILookup.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NPILookup
