import React from 'react'
import { AutoComplete as AutoCompleteBase } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import formField from './formField'

class AutoComplete extends React.Component {
  onBlur = (value, e) => this.props.onBlur(this.props.value, e)
  render = () => <AutoCompleteBase {...this.props} onBlur={this.onBlur} />
}

AutoComplete.propTypes = {
  value: PropTypes.any,
  onBlur: PropTypes.func,
}

export default formField(AutoComplete)
