import React from 'react'
import { DateField as CommonDateField } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

const DateField = props => {
  const { value, editing, onBlur, onChange, disabled = false } = props

  return (
    <BaseField {...props}>
      <CommonDateField
        value={value}
        disabled={disabled || !editing}
        onBlur={onBlur}
        onChange={onChange}
      />
    </BaseField>
  )
}

DateField.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

export default DateField
