import createReducer from '~/utils/createReducer'
import { creator } from '~/utils/data'
import TASKS from '../key'
import { getRoot } from './common'

// Key
const key = 'loadedUserId'

// Type
export const USER_ID_CHANGED = `${TASKS}/USER_ID_CHANGED`

// Action
export const userIdChanged = creator(USER_ID_CHANGED, 'userId')

// Reducer
export default createReducer(key, null, {
  [USER_ID_CHANGED]: (state, { payload }) => payload.userId,
})

export const getUserId = state => getRoot(state).get(key)
