import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Diagnosis } from '~/apps/caseManagerDashboard/components/censusGrids/Diagnosis'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import DisplayDate from '~/components/DisplayDate'
import TOCRisk from '~/components/TOCRisk'
import WithTooltip from '~/components/WithTooltip'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import { getSNFs } from '../data/snfs'
import DeleteSNF from './DeleteSNF'

const toArray = createSelector([snfs => snfs], snfs =>
  snfs.toIndexedSeq().toJS()
)

const caremoreColumnExtensions = [
  { columnName: 'authKey', editingEnabled: false, width: 150 },
  { columnName: 'admissionDate', editingEnabled: false, width: 150 },
  { columnName: 'admittingDiagnosis', editingEnabled: false, width: 150 },
  { columnName: 'admissionSource', editingEnabled: false, width: 175 },
  { columnName: 'dischargeDate', editingEnabled: false, width: 150 },
  { columnName: 'dischargeDisposition', editingEnabled: false, width: 200 },
  { columnName: 'facilityName', editingEnabled: false, width: 150 },
  { columnName: 'outOfNetwork', editingEnabled: false, width: 125 },
  { columnName: 'metalTier', editingEnabled: false, width: 125 },
  { columnName: 'notes', editingEnabled: false, width: 150 },
  { columnName: 'authReceivedAt', editingEnabled: false, width: 150 },
  {
    columnName: 'actualDischargeDateEnteredAt',
    editingEnabled: false,
    width: 200,
  },
  {
    columnName: 'dischargeSummaryUploadedAt',
    editingEnabled: false,
    width: 150,
  },
  {
    columnName: 'historyAndPhysicalUploadedAt',
    editingEnabled: false,
    width: 150,
  },
]

const defaultSorting = [{ columnName: ' ', direction: 'desc' }]

const caremoreColumns = [
  {
    name: 'authKey',
    title: 'Auth Key',
    displayComponent: ({ value }) => value || 'Pending',
  },
  {
    name: 'admissionDate',
    title: 'Admission Date',
    displayComponent: DisplayDate,
    type: 'date',
  },
  {
    name: 'admittingDiagnosis',
    title: 'Diagnosis',
    displayComponent: Diagnosis,
  },
  {
    name: 'admissionSource',
    title: 'Admission Source',
    displayComponent: WithTooltip,
  },
  {
    name: 'dischargeDate',
    title: 'Discharge Date',
    displayComponent: DisplayDate,
    type: 'date',
  },
  {
    name: 'dischargeDisposition',
    title: 'Discharge Disposition',
    displayComponent: WithTooltip,
  },
  {
    name: 'facilityName',
    title: 'Facility Name',
    displayComponent: WithTooltip,
  },
  {
    name: 'outOfNetwork',
    title: 'OON Flag',
    displayComponent: ({ value }) => (value || value ? 'Y' : 'N'),
  },
  {
    name: 'metalTier',
    title: 'Metal Tier',
    displayComponent: TOCRisk,
  },
  {
    name: 'notes',
    title: 'Notes',
    displayComponent: WithTooltip,
  },
  {
    name: 'actualDischargeDateEnteredAt',
    title: 'Discharge Notification',
    displayComponent: DisplayDate,
  },
  {
    name: 'authReceivedAt',
    title: 'Received',
    displayComponent: DisplayDate,
    type: 'date',
  },
  {
    name: 'dischargeSummaryUploadedAt',
    title: 'DC Summary',
    displayComponent: DisplayDate,
  },
  {
    name: 'historyAndPhysicalUploadedAt',
    title: 'H&P',
    displayComponent: DisplayDate,
  },
  {
    name: 'calculatedDcPlanningFlag',
    title: 'DC Planning Flag',
  },
]

const SNFs = ({ snfs }) => {
  const [mode, setMode] = useState()
  const [selectedSNF, setSelectedSNF] = useState()

  const clearState = useCallback(() => {
    setSelectedSNF()
    setMode()
  }, [setMode, setSelectedSNF])

  const onClick = useCallback(() => {}, [])
  const disableAddSnf = true

  return (
    <React.Fragment>
      <DevXTitleHeader
        action={onClick}
        buttonText="Add SNF Admission"
        title="SNF Admissions"
        disabled={disableAddSnf}
      />

      <Paper>
        <DevXGrid
          rows={toArray(snfs)}
          columns={caremoreColumns}
          columnExtensions={caremoreColumnExtensions}
          sortable
          defaultSorting={defaultSorting}
          getRowId={({ id }) => id}
        />
      </Paper>

      <DeleteSNF
        open={mode === 'delete'}
        row={selectedSNF}
        onClose={clearState}
      />
    </React.Fragment>
  )
}

SNFs.propTypes = {
  snfs: PropTypes.map.isRequired,
}

const mapStateToProps = state => ({
  snfs: getSNFs(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SNFs)
