import React from 'react'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { subjectChanged } from '../../../data/events'
import {
  BatchSettingsField,
  CreatedModifiedField,
  LocationField,
  StartEndField,
  TextField,
} from '../../EventFields'

const OnCallEventDetail = ({ userId, event, editing }) => {
  const onSubjectChange = useAction(subjectChanged)

  return (
    <React.Fragment>
      <StartEndField event={event} editing={editing} />
      <TextField
        label="Notes"
        value={event.subject}
        editing={editing}
        onChange={onSubjectChange}
      />
      <LocationField value={event.location} editing={editing} />
      <BatchSettingsField userId={userId} event={event} editing={editing} />
      <CreatedModifiedField event={event} />
    </React.Fragment>
  )
}

OnCallEventDetail.propTypes = {
  userId: PropTypes.string.isRequired,
  event: PropTypes.record.isRequired,
  editing: PropTypes.bool,
}

export default OnCallEventDetail
