import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  statistic: {
    marginRight: spacing(3),

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  statisticValue: {
    color: palette.primary.main,
    marginRight: spacing(0.5),
  },
  statisticLabel: {
    fontSize: '0.65em',
  },
}))

const StatusCount = React.forwardRef(({ value, label, compact }, ref) => {
  const classes = useStyles()

  return (
    <Typography
      ref={ref}
      variant={compact ? 'h6' : 'h5'}
      className={classes.statistic}
    >
      <span className={classes.statisticValue}>{value}</span>
      <span className={classes.statisticLabel}>{label}</span>
    </Typography>
  )
})

StatusCount.displayName = 'StatusCount'

StatusCount.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  compact: PropTypes.bool,
}

export default StatusCount
