import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, mergeMapTo } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import {
  admissionDeletedSet,
  admissionDeletedSetFailed,
  isAdmissionDeleted,
  patchDeleted,
} from '../data/admissions'
import { RECYCLE_CONFIRMED } from '../data/admissionsReconciliation'
import {
  getRecycleDialogAdmissionId,
  recycleDialogAdmissionIdCleared,
} from '../data/recycleDialogAdmissionId'

const patchAdmissionDeleted = (admissionId: any, toDeleted: any, reason: any) =>
  from(patchDeleted(admissionId, toDeleted, reason)).pipe(
    mergeMapTo([
      admissionDeletedSet(admissionId, toDeleted, reason),
      recycleDialogAdmissionIdCleared(),
    ]),
    catchError(e => {
      telemetry.error(e)
      return of(admissionDeletedSetFailed())
    })
  )

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(RECYCLE_CONFIRMED),
    mergeMap(({ reason }) => {
      const state = state$.value
      const admissionId = getRecycleDialogAdmissionId(state)
      const toDeleted = !isAdmissionDeleted(state, admissionId)

      return patchAdmissionDeleted(admissionId, toDeleted, reason)
    })
  )
