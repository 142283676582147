export const UNENGAGED_REASONS = [
  'Financial Considerations',
  'Out of Service Area',
  'Outreach Cycle Complete',
  'Patient / Family Decline - Apprehension or Skepticism',
  'Patient / Family Decline - Claims to be Too Healthy',
  'Patient / Family Decline - Patient Unavailable for Visit',
  'Patient / Family Decline - Satisfied with Current Care',
  'Patient / Family Decline - Too Many Providers',
  'Patient / Family Decline - Hard Decline',
  'Patient / Family Decline - Do Not Call',
  'PCP Declined - Other',
  'PCP Declined - Patient Too Healthy',
  'Similar Health Plan Program',
  'Unable to Contact - No Answer',
  'Unable to Contact - Wrong / Disconnected Number',
  'Clinical Monitoring',
]
