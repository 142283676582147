import React from 'react'
import PhoneLinkBase from '~/components/PhoneLink'
import { formatPhoneNumber } from '~/utils/format'
import PropTypes from '~/utils/propTypes'

const PhoneLink = ({ value }) => (
  <PhoneLinkBase value={formatPhoneNumber(value)} />
)

PhoneLink.propTypes = {
  value: PropTypes.string.isRequired,
}

export default React.memo(PhoneLink)
