import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Diagnosis } from '~/apps/caseManagerDashboard/components/censusGrids/Diagnosis'
import AuthNumber from '~/components/CUPAuthLink/CUPAuthNumber'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import DisplayDate from '~/components/DisplayDate'
import WithTooltip from '~/components/WithTooltip'
import Paper from '@material-ui/core/Paper'
import { getHospitalizations } from '../data/hospitalizations'
import CaremoreHospitalizationDetails from './CaremoreHospitalizationDetails'

const columns = [
  {
    name: 'authNumber',
    title: 'Auth Key',
    displayComponent: AuthNumber,
  },
  {
    name: 'admitDate',
    title: 'Admission Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'dischargeDate',
    title: 'Discharge Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'facilityName',
    title: 'Facility Name',
    displayComponent: WithTooltip,
  },
  {
    name: 'ascFlag',
    title: 'ASC Flag',
    type: 'boolean',
    customFilter: 'booleanWithNoOption',
  },
  {
    name: 'placeOfService',
    title: 'Place of Service',
  },
  {
    name: 'admittingDiagnosis',
    title: 'Admitting Diagnosis',
    displayComponent: Diagnosis,
  },
  {
    name: 'admissionSource',
    title: 'Admission Source',
  },
]

const columnExtensions = [
  { columnName: 'authNumber', width: 150 },
  { columnName: 'admitDate', width: 150 },
  { columnName: 'dischargeDate', width: 175 },
  { columnName: 'placeOfService', width: 175 },
  {
    columnName: 'admittingDiagnosisDescription',
    displayComponent: WithTooltip,
  },
  { columnName: 'admissionSource', width: 175 },
]

const defaultSorting = [{ columnName: 'admissionDate', direction: 'desc' }]

const CaremoreHospitalizations = () => {
  const caremoreHospitlizations = useSelector(getHospitalizations)
  const rows = useMemo(() => caremoreHospitlizations.toArray(), [
    caremoreHospitlizations,
  ])

  return (
    <React.Fragment>
      <DevXTitleHeader title="Hospitalizations" />

      <Paper>
        <DevXGrid
          rows={rows}
          columns={columns}
          columnExtensions={columnExtensions}
          sortable
          defaultSorting={defaultSorting}
          detailComponent={CaremoreHospitalizationDetails}
          rowActionsCount={2}
        />
      </Paper>
    </React.Fragment>
  )
}

export default CaremoreHospitalizations
