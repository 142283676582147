import { either, map, ord } from 'fp-ts'
import { pipe as pipeFP } from 'fp-ts/function'
import * as t from 'io-ts'
import { getLenses } from 'io-ts-types'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { mapInsertAt, toMap } from '~/utils/functionalUtils'
import rootKey from '../key'
import { getRoot } from './common/shared'

const PERMISSIONS = 'permissions'

const Action = t.type({
  name: t.string,
  description: t.string,
})

const Permission = t.type({
  id: t.number,
  object: t.string,
  description: t.string,
  action: Action,
})

const PermissionArray = t.array(Permission)

export type Permission = t.TypeOf<typeof Permission>
type PermissionArray = t.TypeOf<typeof PermissionArray>
export type PermissionMap = Map<number, Permission>

export const permissionLenses = getLenses(Permission)

const transformPermissions = (permissions: unknown) =>
  pipeFP(
    permissions,
    PermissionArray.decode,
    toMap<Permission>(),
    // Eric TODO: Change Request to also account for Either to prevent this step
    either.getOrElseW(() => {
      // This will get caught by Request
      throw new TypeError('Failed to decode permissions')
    })
  )

export const fetchPermissions = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PERMISSIONS',
  operation: () => AspireAPI.get('admin_tools/permissions'),
  transform: transformPermissions,
  messages: { failed: 'There was an issue fetching permissions' },
})

export const savePermission = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PERMISSION',
  requestParams: ['id', 'description'],
  operation: (id: number, description: string) =>
    AspireAPI.put(`admin_tools/permissions/${id}`, { description }),
  messages: {
    succeeded: 'Permission successfully saved',
    failed: 'There was an issue saving this permission',
  },
})

export default createReducer(PERMISSIONS, new Map(), {
  [fetchPermissions.SUCCEEDED]: (
    _state: PermissionMap,
    { payload }: any
  ): PermissionMap => payload,
  [savePermission.SUCCEEDED]: (
    state: PermissionMap,
    { meta }: any
  ): PermissionMap => {
    const { id, description } = meta.request.payload
    const permission: Permission | undefined = state.get(id)

    if (permission === undefined) {
      return state
    } else {
      const newPermission = permissionLenses.description.set(description)(
        permission
      )

      return mapInsertAt<Permission>(state, id, newPermission)
    }
  },
})

export const getPermissions = (state: any): PermissionMap =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(PERMISSIONS)

const ordPermission = ord.contramap((p: Permission) => p.description)(
  ord.ordString
)

export const getPermissionValues = (state: any): PermissionArray =>
  pipeFP(state, getPermissions, map.values(ordPermission))
