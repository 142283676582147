import { List, Record } from 'immutable'
import moment from 'moment'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import {
  action,
  actionCreator,
  getIn,
  payload,
  typeCreator,
} from '~/utils/data'
import { addBusinessDays } from '~/utils/dates'
import { compose } from '~/utils/functionalHelpers'
import ON_CALL from '../key'

// Key
const ON_CALLS = 'onCalls'

// Type
const typePrefix = `${ON_CALL}/${ON_CALLS}`
const type = typeCreator(typePrefix)

// Actions
export const onCallExpanded = actionCreator(type('EXPANDED'), ['formId', 'id'])

// Record
export const User = Record({
  id: null,
  name: null,
  role: null,
})

export const Patient = Record({
  id: null,
  name: null,
})

export const LegacyOnCall = Record({
  callDetails: null,
  completedAt: null,
  dateTimeOfCall: null,
  id: null,
  legacy: true,
  nextDayActionNeeded: null,
  numberOfCalls: null,
  onCallActionTaken: null,
  otherActionTaken: null,
  otherNextDayActionNeeded: null,
  otherReasonForCall: null,
  personSpokenTo: null,
  reasonForCall: null,
  taskNeeded: null,
  typeOfCall: null,
  user: User(),
  patient: Patient(),
  patientId: null,
})

export const OnCall = Record({
  callDetails: null,
  completedAt: null,
  dateTimeOfCall: null,
  form: null,
  id: null,
  nextDayActionNeeded: null,
  numberOfCalls: null,
  onCallActionTaken: null,
  otherActionTaken: null,
  otherReasonForCall: null,
  personSpokenTo: null,
  reasonForCall: null,
  taskNeeded: null,
  typeOfCall: null,
  user: User(),
  patientId: null,
  patient: Patient(),
})

const join = onCall =>
  Object.entries(onCall).map(([k, v]) => [
    k,
    Array.isArray(v) ? v.join(', ') : v,
  ])

const transformLegacyOnCall = ({ user, patient, ...legacyOnCall }) =>
  LegacyOnCall([
    ['user', User(user)],
    ['patient', Patient(patient)],
    ...join(legacyOnCall),
  ])

const transformOnCall = ({
  patient,
  form: { data: formData, ...form },
  user,
  ...onCall
}) =>
  OnCall([
    ['form', { formData, ...form }],
    ['user', User(user)],
    ['patient', Patient(patient)],
    ...join(onCall),
  ])

// Requests
export const fetchOnCall = Request({
  typePrefix,
  typeBase: 'FETCH_ONE',
  requestParams: ['id'],
  operation: id => AspireAPI.get(`/v1/on_calls/${id}`),
  transform: transformOnCall,
  messages: { failed: 'Failed to load On Call' },
})

export const fetchOnCallsForPatient = Request({
  typePrefix,
  typeBase: 'FETCH_ALL_FOR_PATIENT',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`/v1/patients/${patientId}/on_calls`),
  transform: ({ legacyOnCalls, onCalls }) =>
    List([
      ...legacyOnCalls.map(transformLegacyOnCall),
      ...onCalls.map(transformOnCall),
    ]),
  messages: {
    failed: 'Failed to load On Calls for patient',
  },
})

export const fetchOnCallsForUserId = Request({
  typePrefix,
  typeBase: 'FETCH_ALL_FOR_USER',
  requestParams: ['userId'],
  operation: userId => AspireAPI.get(`v1/on_calls`, { params: { userId } }),
  transform: ({ legacyOnCalls, onCalls }) =>
    List([
      ...legacyOnCalls.map(transformLegacyOnCall),
      ...onCalls.map(transformOnCall),
    ]),
  messages: {
    failed: 'Failed to load on calls for user',
  },
})

// Reducer
export default createReducer(ON_CALLS, List(), {
  [fetchOnCall.SUCCEEDED]: (state, { payload }) => state.push(payload),
  [fetchOnCallsForPatient.SUCCEEDED]: compose(payload, action),
  [fetchOnCallsForUserId.SUCCEEDED]: compose(payload, action),
})

// Selectors
export const getOnCallsArray = createSelector(
  getIn([ON_CALL, ON_CALLS]),
  onCalls => onCalls.toArray()
)

// gets counts of on calls in the last two days
// used by the cd dash
export const getRecentOnCallsCount = createSelector(
  getIn([ON_CALL, ON_CALLS]),
  onCalls =>
    onCalls.filter(
      oc => moment(oc.dateTimeOfCall) > addBusinessDays(moment(), -2)
    ).size
)
