import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import DevXGrid from '~/components/DevXGrid'
import { InfoDialog } from '~/components/dialogs'
import { Checkbox } from '~/components/fields'
import { formatAddress } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import {
  changeSuggestedHospitalSearchWord,
  clearSuggestedHospitals,
  getSuggestedHospitals,
} from '../data/suggestedHospitals'

const styles = ({ spacing }) => ({
  textField: {
    marginBottom: spacing(1),
  },
  limitMessage: {
    textAlign: 'right',
    margin: [spacing(1), 0],
  },
})

const columns = [
  {
    name: 'name',
    title: 'Hospital Name',
  },
  {
    name: 'phone',
    title: 'Phone',
  },
  {
    name: 'fax',
    title: 'Fax',
  },
  {
    name: 'address',
    title: 'Address',
    getCellValue: formatAddress,
  },
]

class HospitalSearch extends React.PureComponent {
  state = {
    textFieldValue: '',
  }

  onSelect = row => {
    this.props.onSelect(row)
    this.onClose()
  }

  onChange = () => {
    this.props.onChange()
    this.onClose()
  }

  onFetchSuggestedHospitals = event => {
    const {
      changeSuggestedHospitalSearchWord,
      clearSuggestedHospitals,
    } = this.props
    const newTextFieldValue = event.target.value

    if (newTextFieldValue && newTextFieldValue.trim()) {
      changeSuggestedHospitalSearchWord(newTextFieldValue)
    } else {
      clearSuggestedHospitals()
    }

    this.setState({ textFieldValue: newTextFieldValue })
  }

  onClose = () => {
    const { clearSuggestedHospitals, onClose } = this.props

    this.setState({
      textFieldValue: '',
    })
    clearSuggestedHospitals()
    onClose()
  }

  render() {
    const { textFieldValue } = this.state
    const { classes, open, suggestedHospitals } = this.props

    return (
      <InfoDialog
        open={open}
        title="Search Hospitals"
        onClose={this.onClose}
        maxWidth={false}
        fullWidth
      >
        <Checkbox
          label="Unknown Hospital"
          onChange={this.onChange}
          checked={false}
        />
        <TextField
          className={classes.textField}
          label="Search Hospitals"
          value={textFieldValue}
          onChange={this.onFetchSuggestedHospitals}
          fullWidth
        />

        <DevXGrid
          rows={suggestedHospitals}
          columns={columns}
          selectable
          selectSingleRow
          onSelect={this.onSelect}
          sortable
          pageable
          defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
        />

        {suggestedHospitals.length === 100 && (
          <Typography className={classes.limitMessage}>
            Showing first 100 results. Narrow search.
          </Typography>
        )}
      </InfoDialog>
    )
  }
}

HospitalSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestedHospitals: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  changeSuggestedHospitalSearchWord: PropTypes.func.isRequired,
  clearSuggestedHospitals: PropTypes.func.isRequired,
}

export default compose(
  connect(
    state => ({
      suggestedHospitals: getSuggestedHospitals(state),
    }),
    {
      changeSuggestedHospitalSearchWord,
      clearSuggestedHospitals,
    }
  ),
  withStyles(styles)
)(HospitalSearch)
