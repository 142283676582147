import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Select } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Input from './Input'
import memoizedWrapper from './memoizedWrapper'
import { getInputProps } from './props'

const styles = {
  root: {
    '& option': {
      color: 'black',
    },
  },
}

const SelectWidget = props => {
  const { classes, options } = props
  const { enumOptions } = options

  return (
    <Select
      {...getInputProps(props)}
      className={classes.root}
      native
      fullWidth
      input={<Input />}
    >
      <option value="">{props.placeholder}</option>
      {enumOptions.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  )
}

SelectWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.object.isRequired,
}

export default compose(memoizedWrapper, withStyles(styles))(SelectWidget)
