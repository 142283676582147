import { List } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { getRoot, typePrefixCreator } from './common/shared'
import { fetchConfig } from './config'

const VENDORS = 'vendors'

const typePrefix = typePrefixCreator(VENDORS)
export const VENDORS_CLEARED = type(typePrefix, 'VENDORS_CLEARED')
export const vendorsCleared = creator(VENDORS_CLEARED)

export default createReducer(VENDORS, List(), {
  [fetchConfig.SUCCEEDED]: (_state, { payload: { vendors } }) => vendors,
  [VENDORS_CLEARED]: () => List(),
})

export const getVendors = compose(get(VENDORS), getRoot)
