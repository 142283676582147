import moment from 'moment'
import { Location } from '~/features/locationSearch'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { setTime } from '~/utils/dates'
import { pipe } from '~/utils/functionalHelpers'
import { assessmentTypes } from '../../components/EventTypes/Visit/constants'
import { DEFAULT_DURATION } from '../../components/EventTypes/defaults'
import {
  allDayChanged,
  assessmentTypeChanged,
  confirmedChanged,
  currentEventCleared,
  currentEventSet,
  descriptionChanged,
  encounterTypeChanged,
  encounterTypeToAssessmentType,
  endChanged,
  endDateChanged,
  eventTypeChanged,
  getEventsRoot,
  guidanceTypeChanged,
  locationChanged,
  locationPendingChanged,
  maxVisitsChanged,
  newEventCreated,
  patientCleared,
  patientIdChanged,
  recurrenceChanged,
  rideAlongTypeChanged,
  serviceLocationChanged,
  startChanged,
  startDateChanged,
  subjectChanged,
  transformEvent,
  travelNotesChanged,
  urgentChanged,
  userIdsChanged,
  visitDurationChanged,
  visitMethodChanged,
  visitStartChanged,
} from './root'

const CURRENT_EVENT = 'currentEvent'

const setField = field => (state, { payload: { value } }) =>
  state.set(field, value)

const setDuration = (event, newStart, newEnd) => {
  const allDay = event.allDay
  const allDayMinutes = 60 * 24

  const start = newStart || event.start || moment(event.startDate)
  const end = newEnd || event.end || moment(event.endDate)

  if (allDay) {
    return allDayMinutes
  } else if (start && end) {
    return end.diff(start, 'minutes')
  } else {
    return null
  }
}

const getAllDayRange = (event, { allDay, defaultStart, defaultEnd }) => ({
  start: allDay ? null : setTime(event.startDate, defaultStart).utc(),
  end: allDay ? null : setTime(event.endDate, defaultEnd).utc(),
})

const ASSESSMENT_TYPE_DURATIONS = {
  [assessmentTypes.IN_PERSON.value]: DEFAULT_DURATION,
  [assessmentTypes.REMOTE.value]: 30,
}

const changeAssessmentType = (state, assessmentType) => {
  const duration = ASSESSMENT_TYPE_DURATIONS[assessmentType] || state.duration
  return state.set('assessmentType', assessmentType).set('duration', duration)
}

const initState = null

export default createReducer(CURRENT_EVENT, initState, {
  [currentEventSet]: (_state, { payload: { event } }) => event,
  [newEventCreated]: (_state, { payload: { event, date, timeZone } }) =>
    transformEvent(event, date, timeZone),
  [currentEventCleared]: () => initState,
  [recurrenceChanged]: setField('recurrence'),
  [userIdsChanged]: setField('userIds'),
  [confirmedChanged]: setField('confirmed'),
  [descriptionChanged]: setField('description'),
  [encounterTypeChanged]: (state, { payload: { type } }) => {
    const assessmentType = encounterTypeToAssessmentType(type)
    return changeAssessmentType(state, assessmentType).set(
      'encounterType',
      type
    )
  },
  [locationChanged]: (state, { payload: { value } }) => {
    const newState = state.set('location', value)
    return value.address ? newState.set('locationPending', false) : newState
  },
  [locationPendingChanged]: setField('locationPending'),
  [serviceLocationChanged]: setField('serviceLocationId'),

  [eventTypeChanged]: setField('eventType'),
  [guidanceTypeChanged]: (state, { payload: { value } }) =>
    value === 'flex'
      ? state.merge({
          guidanceType: value,
          rideAlongRole: null,
        })
      : state.merge({
          guidanceType: value,
        }),
  [maxVisitsChanged]: setField('maxVisits'),
  [subjectChanged]: setField('subject'),
  [travelNotesChanged]: setField('travelNotes'),
  [visitMethodChanged]: (state, { payload: { value } }) =>
    value === 'telephonic' || value === 'virtual'
      ? state.merge({
          rideAlongRole: null,
          visitMethod: value,
        })
      : state.merge({
          visitMethod: value,
        }),
  [rideAlongTypeChanged]: setField('rideAlongRole'),
  [urgentChanged]: setField('urgent'),
  [patientIdChanged]: setField('patientId'),
  [assessmentTypeChanged]: (state, { payload: { value } }) =>
    changeAssessmentType(state, value),
  [allDayChanged]: (state, { payload }) => {
    const { start, end } = getAllDayRange(state, payload)

    return state.merge({
      allDay: payload.allDay,
      start,
      end,
      endDate: state.startDate,
      duration: setDuration(state, start, end),
    })
  },
  [startChanged]: (state, { payload: { start, startDate } }) =>
    state.merge({
      start,
      startDate,
      duration: setDuration(state, start, null),
    }),
  [startDateChanged]: (state, { payload: { value } }) =>
    state.merge({
      startDate: value,
      duration: setDuration(state, value, null),
    }),
  [endChanged]: (state, { payload: { end, endDate } }) =>
    state.merge({
      end,
      endDate,
      duration: setDuration(state, null, end),
    }),
  [endDateChanged]: (state, { payload: { value } }) =>
    state.merge({
      endDate: value,
      duration: setDuration(state, null, value),
    }),
  [visitStartChanged]: (state, { payload }) => state.merge(payload),
  [visitDurationChanged]: (state, { payload }) => state.merge(payload),
  [patientCleared]: state =>
    state.merge({
      whatId: null,
      patientId: null,
      patientName: null,
      encounterType: null,
      serviceLocationId: null,
      eventType: null,
      subject: null,
      patientContactId: null,
      patientContactInfo: null,
      location: Location(),
      guests: null,
    }),
})

export const getCurrentEvent = pipe(getEventsRoot, get(CURRENT_EVENT))
