import cx from 'classnames'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { titleCase } from '~/utils/stringManipulation'
import { withStyles } from '@material-ui/core/styles'
import { URGENT } from '../constants'

const styles = ({ palette }) => ({
  high: {
    color: palette.severity.error.dynamic,
  },
})

const Priority = ({ classes: { high }, completedOn, priority }) => (
  <span className={cx({ [high]: priority == URGENT && !completedOn })}>
    {titleCase(priority)}
  </span>
)

Priority.propTypes = {
  classes: PropTypes.object.isRequired,
  completedOn: PropTypes.string,
  priority: PropTypes.string.isRequired,
}

export default withStyles(styles)(Priority)
