import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import {
  PRESCRIBER_LOCATIONS_VIEWED,
  fetchPrescriberLocations,
  getPrescriberLocationByPrescriberId,
} from '../data/prescriberLocations'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(PRESCRIBER_LOCATIONS_VIEWED),
    pluck('payload', 'prescriberId'),
    filter(prescriberId => {
      const state = state$.value
      const prescriberLocations = getPrescriberLocationByPrescriberId(
        state,
        prescriberId
      )

      // NOTE(adam): if not found, a fetch has not yet been attempted
      return !prescriberLocations
    }),
    map(fetchPrescriberLocations.requested)
  )
