import React from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  directionsCleared,
  getDirections,
  getLocationA,
  getLocationB,
} from '../../data/quickLocations'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  marginRight: {
    marginRight: spacing(1),
  },
}))

const RouteInfo = () => {
  const classes = useStyles()

  const locationA = useSelector(getLocationA)
  const locationB = useSelector(getLocationB)
  const directions = useSelector(getDirections)
  const clearDirections = useAction(directionsCleared)

  const locationALabel = locationA.label || '?'
  const locationBLabel = locationB.label || '?'
  const distance = directions
    ? directions.routes[0].legs[0].distance.text
    : null
  const duration = directions
    ? directions.routes[0].legs[0].duration.text
    : null

  return (
    <div className={classes.container}>
      {directions && (
        <React.Fragment>
          <Icon className={classes.marginRight}>directions_car</Icon>

          <Typography
            className={classes.marginRight}
          >{`${locationALabel} to ${locationBLabel} - ${distance}, ${duration}`}</Typography>

          <Tooltip title="Clear Directions">
            <IconButton size="small" onClick={clearDirections}>
              <Icon>clear</Icon>
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
    </div>
  )
}

export default React.memo(RouteInfo)
