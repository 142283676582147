import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { getSelectedOrderDetails } from '../../data/orderDetail'
import OrderDetail from '../OrderDetail'

class OrderFullView extends PureComponent {
  render() {
    const { order } = this.props
    return (
      <React.Fragment>
        {order && order.id ? (
          <OrderDetail orderId={order.id} row={order} />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </React.Fragment>
    )
  }
}

OrderFullView.propTypes = {
  order: PropTypes.object,
}
const mapStateToProps = state => ({
  order: getSelectedOrderDetails(state),
})

export default connect(mapStateToProps)(OrderFullView)
