import { ERROR, PENDING } from '~/data/messages'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import { get, messageCreator, scopedCreator } from '~/utils/data'
import key from '../../key'

const creator = scopedCreator(key)
export const encounterCheckInRequested = creator(
  'ENCOUNTER_CHECK_IN_REQUESTED',
  ['encounterId', 'checkInAt', 'checkInLat', 'checkInLng']
)
export const encounterCheckOutRequested = creator(
  'ENCOUNTER_CHECK_OUT_REQUESTED',
  ['encounterId', 'checkOutAt', 'checkOutLat', 'checkOutLng']
)

export const checkInSnackbarOpened = messageCreator(
  `${key}/CHECK_IN_SNACKBAR_OPENED`,
  'Checking in',
  PENDING
)
export const checkOutSnackbarOpened = messageCreator(
  `${key}/CHECK_OUT_SNACKBAR_OPENED`,
  'Checking out',
  PENDING
)

export const permissionGeocodeFailed = messageCreator(
  `${key}/GEOCODE_FAILED`,
  'Failed to access current location - Please check your security permissions',
  ERROR
)
export const geocodeFailed = messageCreator(
  `${key}/GEOCODE_FAILED`,
  'Failed to access current location',
  ERROR
)

export const checkIntoEncounter = Request({
  typePrefix: key,
  typeBase: 'CHECK_INTO_ENCOUNTER',
  requestParams: ['encounterId', 'checkInAt', 'checkInLat', 'checkInLng'],
  operation: (encounterId, checkInAt, checkInLat, checkInLng) =>
    AspireAPI.put(`encounters/${encounterId}/check_in`, {
      checkInAt,
      checkInLat,
      checkInLng,
    }),
  messages: {
    succeeded: 'Successfully checked in',
    failed: ({ response }) =>
      response
        ? 'There was a problem checking in'
        : 'Unable to check in. Please try again when back online',
  },
})

export const checkOutOfEncounter = Request({
  typePrefix: key,
  typeBase: 'CHECK_OUT_OF_ENCOUNTER',
  requestParams: ['encounterId', 'checkOutAt', 'checkOutLat', 'checkOutLng'],
  operation: (encounterId, checkOutAt, checkOutLat, checkOutLng) =>
    AspireAPI.put(`encounters/${encounterId}/check_out`, {
      checkOutAt,
      checkOutLat,
      checkOutLng,
    }),
  messages: {
    succeeded: 'Successfully checked out',
    failed: ({ response }) =>
      response
        ? 'There was a problem checking out'
        : 'Unable to check out. Please try again when back online',
  },
})

export const getRoot = get(key)
