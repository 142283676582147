import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CircleMarker, InfoWindow } from '~/components/GeoMap'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import { getContext } from '../../../../data/common/derived'
import { getCurrentPatientId } from '../../../../data/currentPatientId'
import getLocation from '../../../../utils/getLocation'
import MarkerMenu from '../../MarkerMenu'
import OptionsMenu from '../../OptionsMenu'
import PatientMarker from './PatientMarker'

const useStyles = makeStyles(({ spacing }) => ({
  patientInfo: {
    marginBottom: spacing(1),
  },
  patientListTitle: {
    borderBottom: [1, 'solid', 'black'],
    marginBottom: spacing(0.5),
  },
  patientList: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      height: '1.5em',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
}))

const PatientListMarker = ({
  patients,
  current,
  label,
  PatientInfo,
  getMarker,
  getColor,
  excludedOptions,
  onPatientSelected,
  onPatientCleared,
}) => {
  const classes = useStyles()

  const context = useSelector(getContext)
  const currentPatientId = useSelector(getCurrentPatientId)

  const [hovered, toggleHovered, untoggleHovered] = useToggle()
  const [initialOpen, toggleInitialOpen, untoggleInitialOpen] = useToggle()
  const [menuOpen, toggleMenuOpen, untoggleMenuOpen] = useToggle()

  const onClickFirst = useCallback(() => {
    const patientId = patients.first().id

    onPatientSelected(patientId)
  }, [patients, onPatientSelected])

  const onMenuOpen = useCallback(() => {
    toggleInitialOpen()
    toggleMenuOpen()
  }, [toggleInitialOpen, toggleMenuOpen])

  // InfoPopover immediately fires the onClose event with this nested menu
  const onMenuClosed = useCallback(() => {
    if (initialOpen) untoggleInitialOpen()
    else untoggleMenuOpen()
  }, [initialOpen, untoggleInitialOpen, untoggleMenuOpen])

  const criticalColor = useMemo(
    () =>
      patients
        .map(patient => getColor(patient, context))
        .minBy(color => color.rank).color,
    [patients, context]
  )

  const location = useMemo(() => getLocation(patients.first()), [patients])

  const currentPatient = useMemo(
    () => patients.find(patient => patient.id === currentPatientId),
    [patients, currentPatientId]
  )

  return (
    <CircleMarker
      position={location}
      scale={current ? 1.5 : 1}
      color={criticalColor}
      label={label}
      onClick={onClickFirst}
      onRightClick={onClickFirst}
      onMouseover={toggleHovered}
      onMouseout={untoggleHovered}
    >
      <InfoWindow
        open={current || hovered}
        disableAutoPan={hovered}
        zIndex={hovered ? 2 : 1}
        onClose={onPatientCleared}
      >
        {currentPatient && (
          <div className={classes.patientInfo}>
            <PatientInfo patient={currentPatient} />
          </div>
        )}

        <div className={classes.patientListTitle}>
          Patients at this location:
        </div>

        <div className={classes.patientList}>
          {patients.map(patient => (
            <PatientMarker
              key={patient.id}
              context={context}
              patient={patient}
              open={current}
              current={patient.id === currentPatientId}
              getMarker={getMarker}
              getColor={getColor}
              onPatientClick={onPatientSelected}
              onMenuClick={onMenuOpen}
            />
          ))}
        </div>
      </InfoWindow>

      {currentPatient && (
        <MarkerMenu open={menuOpen} onClose={onMenuClosed}>
          <OptionsMenu
            showPatientOptions
            patient={currentPatient}
            id={currentPatient.id}
            label={currentPatient.name}
            location={location}
            excludedOptions={excludedOptions}
          />
        </MarkerMenu>
      )}
    </CircleMarker>
  )
}

PatientListMarker.propTypes = {
  patients: PropTypes.list.isRequired,
  current: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  PatientInfo: PropTypes.elementType.isRequired,
  getMarker: PropTypes.func.isRequired,
  getColor: PropTypes.func.isRequired,
  onPatientSelected: PropTypes.func.isRequired,
  onPatientCleared: PropTypes.func.isRequired,
  excludedOptions: PropTypes.array,
}

export default React.memo(PatientListMarker)
