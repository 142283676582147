import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { referralSchedulingRouter } from '../router'
import { getRoot } from './common/shared'

const CURRENT_APP_ID = 'currentAPPId'

const reducer = paramsReducer(
  referralSchedulingRouter,
  ({ appId }: any) => appId
)

reducer.key = CURRENT_APP_ID

export const getCurrentAPPId = pipe(getRoot, get(CURRENT_APP_ID))

export default reducer
