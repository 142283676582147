import { of } from 'rxjs'
import { errorAdded } from '~/data/messages'
import telemetry from '~/utils/telemetry'

// Displays error and removes after errorMessageDisplayDuration
export const showError = message => e => {
  if (!__TEST__) telemetry.error(e)

  return of(errorAdded(message))
}
