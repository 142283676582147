import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { fetchEncounterQueries, sendQuery } from '../data/codingQueries'
import {
  fetchEncounterInfo,
  getCodingEncounterInfo,
} from '../data/encounterInfo'

const querySent = (action$: any, state$: any) =>
  action$.pipe(
    ofType(sendQuery.SUCCEEDED),
    switchTo(state$),
    map(getCodingEncounterInfo),
    mergeMap((encounterInfo: any) =>
      of(
        fetchEncounterInfo.requested(encounterInfo.id),
        fetchEncounterQueries.requested(encounterInfo.id)
      )
    )
  )

export default querySent
