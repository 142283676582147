import { get } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'redux-routable-react'
import DevXGrid from '~/components/DevXGrid'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Paper, Tooltip } from '@material-ui/core'
import { CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL } from '../routes'

const DetailLink = ({ row: { id } }) => (
  <Tooltip title={'Open Detail View'}>
    <div>
      <IconButton
        color="primary"
        component={Link}
        params={{ careTeamId: id }}
        route={CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL}
      >
        <Icon>open_in_browser</Icon>
      </IconButton>
    </div>
  </Tooltip>
)

DetailLink.propTypes = {
  row: PropTypes.object,
}

const CareTeamsGrid = ({ careTeams, displayComponent, roles, types }) => {
  const roleColumns = useMemo(() => {
    return Object.values(roles.toJS()).map(role => {
      return {
        name: role.key,
        title: role.label,
        getCellValue: ({ members }) =>
          get(
            members.filter(mem => mem.role == role.key),
            '[0].user.name'
          ),
      }
    })
  }, [roles])

  const baseColumns = [
    {
      name: 'label',
      title: 'Label',
      wrapText: true,
    },
    {
      name: 'primaryMember',
      title: 'Primary Member',
      getCellValue: ({ primaryRole, members }) =>
        get(
          members.filter(mem => mem.role == primaryRole),
          '[0].user.name'
        ),
    },
    {
      name: 'primaryRole',
      title: 'Primary Role',
      getCellValue: ({ primaryRole }) => roles.getIn([primaryRole, 'label']),
    },
    {
      name: 'type',
      title: 'Type',
      getCellValue: ({ type }) => types.getIn([type, 'label']),
    },
    {
      name: 'validStateCodes',
      title: 'States',
      getCellValue: ({ validStateCodes }) =>
        validStateCodes ? validStateCodes.join(', ') : '',
    },
    {
      name: 'market',
      title: 'Market',
    },
  ]

  const columns = roleColumns ? baseColumns.concat(roleColumns) : baseColumns
  return (
    <Paper>
      {roleColumns && roleColumns.length > 0 && (
        <DevXGrid
          rows={careTeams}
          defaultOrder={columns.map(c => c.name)}
          defaultHiddenColumnNames={roleColumns.map(c => c.name)}
          columns={columns}
          detailComponent={displayComponent}
          rowActions={DetailLink}
          rowActionsCount={1}
          pageable
          pageSizes={[10, 25, 50]}
          defaultPageSize={25}
          columnReorderable
          sortable
          searchable
          filterable
          columnHideable
        />
      )}
    </Paper>
  )
}

CareTeamsGrid.propTypes = {
  careTeams: PropTypes.array.isRequired,
  displayComponent: PropTypes.func,
  roles: PropTypes.map,
  types: PropTypes.map,
}

export default CareTeamsGrid
