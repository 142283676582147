import { Map, Record, fromJS } from 'immutable'
import { ofType } from 'redux-observable'
import { createSelector } from 'reselect'
import { from } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { switchTo } from '~/utils/operators'

/* RECORD */
export const Healthplan = Record({
  id: null,
  name: null,
  odsId: null,
  fieldMappings: null,
})

/* CONSTANTS */
const HEALTHPLANS_STORED = 'HEALTHPLANS/HEALTHPLANS_STORED'
const FETCH_HEALTHPLANS = 'HEALTHPLANS/FETCH_HEALTHPLANS'
/* ACTIONS */

export const healthplansStored = healthplans => ({
  type: HEALTHPLANS_STORED,
  healthplans,
})

export const fetchHealthplans = () => ({
  type: FETCH_HEALTHPLANS,
  payload: {},
})

/* KEY */
const key = 'healthplans'

/* REDUCER */
const initState = fromJS({})

export default createReducer(key, initState, {
  [HEALTHPLANS_STORED]: (state, { healthplans }) => state.merge(healthplans),
})

const mapHealthplanData = data =>
  Map(
    data.map(d => [
      d.healthplan,
      Healthplan({
        id: d.id,
        name: d.healthplan,
        odsId: d.odsId,
        fieldMappings: fromJS(
          d.fieldMappings &&
            d.fieldMappings.reduce((values, current) => {
              values[current.referralFieldName] = current
              return values
            }, {})
        ),
      }),
    ])
  )

/* API */
export const fetchHealthplansWithReferralFieldData = () =>
  AspireAPI.get('v1/health_plan/referral').then(mapHealthplanData)

/* SELECTORS */
export const getHealthplans = state => state.get(key)

export const getAutocompleteHealthplans = createSelector(
  [state => getHealthplans(state)],
  healthplans =>
    healthplans
      .valueSeq()
      .map(h => ({ label: h.name, value: h.id }))
      .toJS()
      .sort((a, b) => (a.label > b.label ? 1 : -1))
)

const fetchAndStoreHealthplans = () =>
  from(fetchHealthplansWithReferralFieldData()).pipe(
    map(healthplansStored),
    catchError()
  )

/* EPIC */
export const epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_HEALTHPLANS),
    switchTo(state$),
    map(getHealthplans),
    filter(hp => hp.isEmpty()),
    mergeMap(() => fetchAndStoreHealthplans())
  )
