import { ofType } from 'redux-observable'
import { debounceTime, filter, map, pluck } from 'rxjs/operators'
import {
  patientSearchAutoSuggested,
  searchPatients,
} from '../data/patientSearch'

export default action$ =>
  action$.pipe(
    ofType(patientSearchAutoSuggested),
    debounceTime(500),
    pluck('payload'),
    filter(searchText => searchText.length > 3),
    map(searchPatients.requested)
  )
