import { combineEpics, ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, mapTo, mergeMapTo, takeUntil } from 'rxjs/operators'
import { fetchLocations } from '../data/locations'
import { fetchPrescribers } from '../data/prescribers'
import {
  ADMIN_TOOLS_PRESCRIBERS,
  ADMIN_TOOLS_PRESCRIBING_LOCATIONS,
} from '../router'

const handleFetchLocations = (action$: any) =>
  action$.pipe(
    filter(entered(ADMIN_TOOLS_PRESCRIBING_LOCATIONS)),
    mapTo(fetchLocations.requested()),
    takeUntil(action$.pipe(ofType(fetchLocations.SUCCEEDED)))
  )

const handleFetchPrescribers = (action$: any) =>
  action$.pipe(
    filter(entered(ADMIN_TOOLS_PRESCRIBERS)),
    mergeMapTo(of(fetchPrescribers.requested(), fetchLocations.requested())),
    takeUntil(action$.pipe(ofType(fetchPrescribers.SUCCEEDED)))
  )

export default combineEpics(handleFetchLocations, handleFetchPrescribers)
