import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  STATUS_UPDATE_REQUESTED,
  changeReferralStatus,
  referralCaseStatusUpdated,
} from '../data/referrals'

const updateStatus = (caseId: any, status: any, substatus: any) =>
  from(changeReferralStatus(caseId, status, substatus)).pipe(
    map(referralCaseStatusUpdated),
    catchError(showError('Failed to update case status'))
  )

export default (action$: any) =>
  action$.pipe(
    ofType(STATUS_UPDATE_REQUESTED),
    mergeMap(({ caseId, status, substatus }) =>
      updateStatus(caseId, status, substatus)
    )
  )
