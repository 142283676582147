import { colors } from '@material-ui/core'
import darkTheme from '../../dark'
import leaves from './leaves.jpg'
import pumpkinPie from './pumpkin-pie.png'
import pumpkin from './pumpkin.png'
import woodPlanks from './wood-planks.jpg'

export const fallStyles = () => ({
  '@global': {
    html: {
      minHeight: '100%',
    },
    body: {
      backgroundColor: 'rgba(140, 65, 0, 0.9)',
      backgroundImage: `url(${woodPlanks})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    'input::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },
  },
})

export const fallTheme = () => ({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    holiday: 'fall',
    primary: {
      light: colors.amber[300],
      main: colors.amber[500],
      dark: colors.amber[700],
    },
    secondary: {
      main: colors.lime[900],
    },
    drawer: {
      backgroundClosed: {
        backgroundColor: colors.orange[900],
        backgroundImage: `url(${pumpkin})`,
        backgroundPosition: '48% 104%',
        backgroundSize: '250%',
        backgroundRepeat: 'no-repeat',
      },
      backgroundOpen: {
        background: {
          color: colors.orange[900],
          image: `url(${leaves})`,
          size: 'cover',
        },
        '& $header': {
          backgroundColor: colors.orange[900],
          '& + ul > *:nth-child(5n - 4)': {
            backgroundColor: 'rgba(255, 176, 0, 0.9)', // 'rgba(255, 228, 0, 0.8)',
          },
          '& + ul > *:nth-child(5n - 3)': {
            backgroundColor: 'rgba(140, 65, 0, 0.9)', // 'rgba(68, 43, 13, 0.9)',
          },
          '& + ul > *:nth-child(5n - 2)': {
            backgroundColor: 'rgba(141, 165, 48, 0.9)', // 'rgba(48, 220, 56, 0.8)',
          },
          '& + ul > *:nth-child(5n - 1)': {
            backgroundColor: 'rgba(255, 140, 0, 0.9)', // 'rgba(255, 165, 54, 0.8)',
          },
          '& + ul > *:nth-child(5n - 0)': {
            backgroundColor: 'rgba(193, 35, 0, 0.9)', // 'rgba(130, 10, 10, 0.9)',
          },
        },
      },
    },
    enrolledStatusBadge: {
      backgroundColor: '#653615',
      color: '#fff',
    },
    accordion: {
      color: colors.common.white,
      header: colors.lime[900],
    },
    calendarHead: {
      backgroundColor: colors.lime[900],
    },
    calendarTitleText: {
      color: colors.common.white,
    },
    timelineDetail: {
      backgroundColor: colors.yellow[100],
    },
    panelTabSelected: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    alertsAccordion: {
      backgroundColor: colors.deepOrange[900],
    },
    patientInfoTag: {
      backgroundColor: colors.lightBlue[500],
      '& button:hover': {
        backgroundColor: colors.lightBlue[600],
      },
    },
    formPageTitle: {
      color: colors.deepOrange[900],
    },
    vitalsToolbarHeader: {
      backgroundColor: colors.lime[900],
    },
    vitalsChipContainer: {
      backgroundColor: '#653615',
    },
    vitalsChipChosen: {
      backgroundColor: colors.lime[900],
      color: colors.common.white,
    },
    tabSelected: {
      backgroundColor: darkTheme.palette.shades.dark,
    },
    toolbarHeaderText: {
      color: colors.common.white,
    },
    contentText: {
      primaryLabel: '#653615',
      secondaryLabel: colors.common.white,
      infoLabel: colors.orange[500],
      paperHeader: colors.lime[900],
    },
    notifications: {
      linkText: colors.deepOrange[900],
    },
  },
  overrides: {
    ...darkTheme.overrides,
    MuiAppBar: {
      root: {
        '&.mui-fixed': {
          backgroundColor: colors.orange[900],
          color: colors.common.white,
        },
      },
      colorPrimary: {
        backgroundColor: colors.lime[900],
      },
    },
    MuiBadge: {
      root: {
        '& $badge': {
          backgroundColor: colors.orange[500],
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: colors.amber[600],
      },
      label: {
        color: colors.common.white,
      },
      outlined: {
        borderColor: colors.common.white,
      },
      outlinedPrimary: {
        borderColor: colors.common.white,
        color: colors.common.white,
        backgroundColor: darkTheme.palette.action.selected,
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: colors.lime[900],
      },
    },
    MuiFab: {
      root: {
        '&::after': {
          content: '""',
          backgroundImage: `url(${pumpkinPie})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          bottom: -24,
          height: 100,
          position: 'absolute',
          right: -20,
          width: 100,
          zIndex: -10,
        },
      },
      label: {
        color: colors.common.white,
      },
      primary: {
        backgroundColor: colors.brown[500],
      },
    },
    MuiIconButton: {
      root: {
        '&$colorPrimary': {
          color: colors.deepOrange[900],
        },
      },
    },
    MuiLinearProgress: {
      barColorSecondary: {
        backgroundColor: colors.orange[500],
      },
      colorSecondary: {
        backgroundColor: '#ffd394',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: darkTheme.palette.action.selected,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: colors.deepOrange[900],
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#d4a367',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: colors.amber[600],
        color: colors.common.black,
        '& .material-icons': {
          color: colors.deepOrange[900],
        },
      },
    },
    MuiSvgIcon: {
      root: {
        color: colors.common.white,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #fff',
      },
    },
    MuiTablePagination: {
      toolbar: {
        color: colors.common.white,
        '& button': {
          color: colors.common.white,
        },
        '& svg': {
          color: colors.common.white,
        },
      },
    },
    MuiTab: {
      textColorInherit: {
        color: colors.common.white,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: colors.amber[500],
      },
      root: {
        backgroundColor: colors.lime[900],
      },
    },
  },
})
