import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { fetchRecalcuateStatus } from '~/apps/patientRecord/data/recalculatePatientStatus'
import { scopedCreator } from '~/utils/data'
import rootKey from '../key'

const creator = scopedCreator(rootKey)
export const recalculateStatusLinkClicked = creator(
  'RECALCULATE_STATUS_LINK_CLICKED',
  ['patientId']
)

export default action$ =>
  action$.pipe(
    ofType(recalculateStatusLinkClicked),
    pluck('payload', 'patientId'),
    map(fetchRecalcuateStatus.requested)
  )
