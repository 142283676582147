// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { fetchUsers } from './users'

const USER_COUNT = 'userCount'

export default createReducer(USER_COUNT, 0, {
  [fetchUsers.SUCCEEDED]: (_state: any, { payload: { count } }: any) => count,
})

export const getUserCount = pipe(getRoot, get(USER_COUNT))
