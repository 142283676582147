import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
} from '@material-ui/core'

const TaskOption = ({ checked, disabled, inputLabel, label, onChange }) => (
  <Grid item sm={3} xs={6}>
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={checked} disabled={disabled} onChange={onChange} />
          }
          label={inputLabel}
        />
      </FormGroup>
    </FormControl>
  </Grid>
)

TaskOption.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  inputLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
}

TaskOption.defaultProps = {
  disabled: false,
}

export default TaskOption
