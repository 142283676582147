import { combineReducers } from 'redux-immutable'
import careTeamMembers from './data/careTeamMembers'
import { externalHealthConcern } from './data/externalHealthConcern'
import hcGoal from './data/hcGoal'
import { currentHcGoal, hcGoalInterventions } from './data/hcGoalInterventions'
import key from './key'

const reducer = combineReducers({
  [currentHcGoal.key]: currentHcGoal,
  [externalHealthConcern.key]: externalHealthConcern,
  [hcGoal.key]: hcGoal,
  [hcGoalInterventions.key]: hcGoalInterventions,
  [careTeamMembers.key]: careTeamMembers,
})

reducer.key = key

export default reducer
