import { Record } from 'immutable'
import { ERROR, SUCCESS } from '~/data/messages'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { creator, get, snackbarMessageCreator, type } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { getRoot, typePrefixCreator } from './common/shared'

const CALL_LOG = 'callLog'

export const CallLog = Record({
  phone: '',
  inbound: false,
  intent: null,
  answered: null,
  disposition: null,
  dispositionId: '',
  notes: '',
})

const typePrefix = typePrefixCreator(CALL_LOG)
export const CALL_LOG_CHANGED = type(typePrefix, 'CALL_LOG_CHANGED')
export const CALL_LOG_CLEARED = type(typePrefix, 'CALL_LOG_CLEARED')

export const callLogChanged = creator(CALL_LOG_CHANGED, 'callLog')
export const callLogCleared = creator(CALL_LOG_CLEARED)

export const phoneCopyErrorMessage = snackbarMessageCreator(
  typePrefix,
  'PHONE_NUMBER_COPY_FAILED',
  'There was an error copying the phone number to the clipboard',
  ERROR
)

export const phoneCopySuccessMessage = snackbarMessageCreator(
  typePrefix,
  'PHONE_NUMBER_COPY_SUCCEEDED',
  'Phone number successfully copied',
  SUCCESS
)

export const addCallLog = Request({
  typePrefix,
  typeBase: 'ADD_CALL_LOG',
  requestParams: ['patientId', 'callLog'],
  operation: (patientId, callLog) => {
    return AspireAPI.post(
      `call_logs/patient/${patientId}/quick_call_log`,
      callLog
    )
  },
  messages: { failed: 'There was an issue saving this call log' },
})

export default createReducer(CALL_LOG, CallLog(), {
  [CALL_LOG_CHANGED]: (_state, { payload: { callLog } }) => callLog,
  [CALL_LOG_CLEARED]: () => CallLog(),
})

export const getCallLog = compose(get(CALL_LOG), getRoot)
