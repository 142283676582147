import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { getCallLog } from '../../../data/callLog'
import { ContactInfo } from '../Contacts'
import OtherContacts from '../Contacts/OtherContacts'
import CallLogForm from './CallLogForm'

const useStyles = makeStyles(({ spacing }) => ({
  callInfo: {
    marginBottom: spacing(1),
  },
  callForm: {
    marginBottom: spacing(2),
  },
}))

const ActiveCall = () => {
  const classes = useStyles()

  const callLog = useSelector(getCallLog)

  return (
    <React.Fragment>
      <div className={classes.callInfo}>
        <ContactInfo contact={callLog.activeContact} />
      </div>

      <div className={classes.callForm}>
        <CallLogForm programEnrolled={callLog.programEnrolled} />
      </div>

      <OtherContacts currentNumber={callLog.activeContact.phoneNumber} />
    </React.Fragment>
  )
}

export default ActiveCall
