import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { MAP_BOUNDS_CHANGED } from './map'

const key = 'fetched'

export const FETCHED_KEY = type(rootKey, 'FETCHED_KEY')

export const addFetchedKey = creator(FETCHED_KEY, 'key')

export default createReducer(key, new Set(), {
  [FETCHED_KEY]: (state, { payload: { key } }) => state.add(key),
  [MAP_BOUNDS_CHANGED]: () => new Set(),
})

export const getFetchedKeys = pipe(getRoot, get(key))
