// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { change } from 'redux-form/immutable'
import { combineEpics, ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { of } from 'rxjs'
import { mapTo, mergeMap, pluck } from 'rxjs/operators'
import {
  ADDITIONAL_REFERRAL_SEARCH,
  ADDITIONAL_REFERRAL_SELECTED,
  patientReferralsRequested,
} from '../data/referrals'
import {
  REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL,
  REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_DETAIL,
} from '../router'

const additionalReferralSearch = (action$: any) =>
  action$.pipe(
    ofType(ADDITIONAL_REFERRAL_SEARCH),
    mapTo(push(REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL))
  )

const additionalReferralSelected = (action$: any) =>
  action$.pipe(
    ofType(ADDITIONAL_REFERRAL_SELECTED),
    pluck('payload'),
    mergeMap(patientId =>
      of(
        push(REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_DETAIL, { patientId }),
        patientReferralsRequested(patientId),
        change('additional', 'aspire.patientSfid', patientId)
      )
    )
  )

export default combineEpics(
  additionalReferralSearch,
  additionalReferralSelected
)
