import moment from 'moment'
import React from 'react'
import { DateTimeField as DTField } from '~/components/fields'
import { DATE_PICKER_DATE_FORMAT } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

// NOTE(aoswalt): copied from StartEndField as a band-aid
const isCurrentOrNextYearDefault = date => {
  const thisYear = moment().startOf('year').format(DATE_PICKER_DATE_FORMAT)
  const nextYear = moment()
    .add(1, 'year')
    .endOf('year')
    .format(DATE_PICKER_DATE_FORMAT)

  return moment(date).isBetween(thisYear, nextYear, undefined, '[]')
}

const DateTimeField = props => {
  const {
    disabled = false,
    isCurrentOrNextYear = isCurrentOrNextYearDefault,
    editing,
    onBlur,
    onChange,
    value,
  } = props

  const onDateValidate = newDate => {
    const isBetween = isCurrentOrNextYear(newDate)

    if (newDate && isBetween) {
      onBlur(moment(newDate))
    } else {
      const currentYear = moment().year()
      const resetDate = moment(newDate).year(currentYear)

      onBlur(resetDate)
    }
  }

  return (
    <BaseField {...props}>
      <DTField
        value={value}
        disabled={!editing || disabled}
        onBlur={onDateValidate}
        onChange={onChange}
      />
    </BaseField>
  )
}

DateTimeField.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  isCurrentOrNextYear: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default DateTimeField
