import React from 'react'
import { formatDateFromTimestamp, formatTime } from '~/utils/format'
import PropTypes from '~/utils/propTypes'

export default function DateTime(props) {
  if (!props.dateTime) {
    return null
  }

  return (
    <React.Fragment>
      <span style={{ display: 'block' }}>
        {formatDateFromTimestamp(props.dateTime)}
      </span>
      <span>{formatTime(props.dateTime)}</span>
    </React.Fragment>
  )
}

DateTime.propTypes = {
  dateTime: PropTypes.string,
}
