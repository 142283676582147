import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/re... Remove this comment to see the full error message
import OrcAPI from '~/resources/orc'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// RECORDS
export const OdsAdmission = Record({
  admissionDate: null,
  dischargeDate: null,
  globalMemberId: null,
  id: null,
  isValid: null,
  admissionSourceId: null,
  needsReview: null,
  sourceAuthorizationId: null,
  admissionTypeId: null,
  parentId: null,
  facilityName: null,
})

// CONSTANTS
export const ODS_ADMISSION_STORED = 'odsAdmissions/ODS_ADMISSION_STORED'
export const ODS_ADMISSIONS_STORED = 'odsAdmissions/ODS_ADMISSIONS_STORED'
export const ODS_ADMISSION_UPDATED = 'odsAdmissions/ODS_ADMISSION_UPDATED'
export const ODS_ADMISSION_DELETED = 'odsAdmissions/ODS_ADMISSION_DELETED'
export const ODS_ADMISSIONS_DELETED = 'odsAdmissions/ODS_ADMISSIONS_DELETED'
export const ODS_ADMISSION_FETCH_FAILED =
  'odsAdmissions/ODS_ADMISSION_FETCH_FAILED'

// ACTION CREATORS
export const odsAdmissionStored = (odsAdmission: any) => ({
  type: ODS_ADMISSION_STORED,
  odsAdmission,
})
export const odsAdmissionsStored = (odsAdmissions: any) => ({
  type: ODS_ADMISSIONS_STORED,
  odsAdmissions,
})
export const odsAdmissionUpdated = (
  odsAdmissionId: any,
  odsAdmission: any
) => ({
  type: ODS_ADMISSION_UPDATED,
  odsAdmissionId,
  odsAdmission,
})
export const odsAdmissionDeleted = (odsAdmissionId: any) => ({
  type: ODS_ADMISSION_DELETED,
  odsAdmissionId,
})
export const odsAdmissionsDeleted = (odsAdmissionIds: any) => ({
  type: ODS_ADMISSIONS_DELETED,
  odsAdmissionIds,
})
export const odsAdmissionFetchFailed = () => ({
  type: ODS_ADMISSION_FETCH_FAILED,
})

// KEY
export const key = 'odsAdmissions'

// REDUCER
export default createReducer(key, Map(), {
  [ODS_ADMISSION_STORED]: (state: any, { odsAdmission }: any) =>
    state.set(odsAdmission.id, odsAdmission),
  [ODS_ADMISSIONS_STORED]: (state: any, { odsAdmissions }: any) =>
    state.mergeDeep(odsAdmissions),
  [ODS_ADMISSION_UPDATED]: (
    state: any,
    { odsAdmissionId, odsAdmission }: any
  ) =>
    state.update(odsAdmissionId, (existingOdsAdmission: any) =>
      existingOdsAdmission.mergeDeep(odsAdmission)
    ),
  [ODS_ADMISSION_DELETED]: (state: any, { odsAdmissionId }: any) =>
    state.delete(odsAdmissionId),
  [ODS_ADMISSIONS_DELETED]: (state: any, { odsAdmissionIds }: any) =>
    state.filter(
      (odsAdmission: any) => !odsAdmissionIds.contains(odsAdmission.id)
    ),
})

const mapData = (data: any) =>
  Map(
    data.map((d: any) => [
      d.masterAdmissionsId,
      OdsAdmission({
        admissionDate: d.admissionDate,
        dischargeDate: d.dischargeDate,
        globalMemberId: d.globalMemberId,
        id: d.masterAdmissionsId,
        isValid: d.isValid,
        admissionSourceId: d.masterAdmissionsSourceId,
        needsReview: d.needsReview,
        sourceAuthorizationId: d.sourceAuthId,
        admissionTypeId: d.admissionTypeId,
        parentId: d.parentId,
        facilityName: d.facilityName,
      }),
    ])
  )

// API
export const fetchMergeOdsAdmissions = () =>
  OrcAPI.get('admissions/merge')
    .then((d: any) => (Array.isArray(d) ? d : []))
    .then(mapData)

export const fetchUnmergeOdsAdmissions = (contactId: any) =>
  OrcAPI.get(`admissions/unmerge/${contactId}`)
    .then((d: any) => (Array.isArray(d) ? d : []))
    .then(mapData)

export const discardOdsAdmission = (odsAdmissionId: any) =>
  OrcAPI.delete(`admissions/merge/${odsAdmissionId}`)

// SELECTORS
export const getAllOdsAdmissions = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
export const getOdsAdmissionById = (state: any, id: any) =>
  getAllOdsAdmissions(state).get(id)
export const getOdsAdmissionsByMemberId = createSelector(
  [getAllOdsAdmissions, (_: any, memberId: any) => memberId],
  (odsAdmissions, memberId) =>
    odsAdmissions.filter((a: any) => a.globalMemberId === memberId)
)
