import { combineEpics, ofType } from 'redux-observable'
import { changedTo, exited } from 'redux-routable'
import { concat, of, timer } from 'rxjs'
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchDailyEvents } from '~/features/encounterWidget'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
import { fetchHighRiskHuddle } from '~/features/highRiskHuddle/data'
import {
  fetchCensusByRoleName,
  fetchPatientLists,
  // @ts-expect-error no export
} from '~/features/patientCensus'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import {
  DASHBOARD_REFRESH_REQUESTED,
  getSelectedUserId,
  pharmacistDashRefreshRequested,
} from '../data/dashUserId'
import router, {
  PHARMACIST_DASHBOARD_MY_LISTS,
  PHARMACIST_DASHBOARD_PATIENT_LIST,
} from '../router'

const REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes

const userIdChanged = (action$: any) =>
  action$.pipe(
    filter(changedTo(router)),
    pluck('payload', 'params', 'userId'),
    filter(userId => userId !== undefined),
    distinctUntilChanged(),
    switchMap(userId =>
      timer(0, REFRESH_INTERVAL).pipe(
        takeUntil(action$.pipe(filter(exited(router)))),
        mapTo(pharmacistDashRefreshRequested(userId))
      )
    )
  )

const refreshRequested = (action$: any) =>
  action$.pipe(
    ofType(DASHBOARD_REFRESH_REQUESTED),
    pluck('payload', 'userId'),
    mergeMap(userId =>
      concat(
        of(fetchEpisodesOfCare.requested({ ownerId: userId })),
        of(ownerTasksFetchRequested(userId)),
        of(fetchPatientLists.requested(userId)),
        of(fetchHighRiskHuddle.requested(userId)),
        fetchDailyEvents(userId)
      )
    )
  )

const loadPharmacistCensus = (action$: any, state$: any) =>
  action$.pipe(
    filter(
      changedTo([
        PHARMACIST_DASHBOARD_PATIENT_LIST,
        PHARMACIST_DASHBOARD_MY_LISTS,
      ])
    ),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(getSelectedUserId),
    mergeMap(userId =>
      of(
        fetchCensusByRoleName.requested(userId, 'phar'),
        fetchPatientLists.requested(userId)
      )
    )
  )

export default combineEpics(
  userIdChanged,
  refreshRequested,
  loadPharmacistCensus
)
