import { changedTo } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchCallList } from '~/features/callList'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { localDateAndTimeToUTC } from '~/utils/dates'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { getCurrentUserId } from '../data/currentUserId'
import { IOC_DASHBOARD_CALL_LIST } from '../router'

const localDate = new Date().toDateString()
const fromDate = localDateAndTimeToUTC(localDate, ' 00:00:00')
const throughDate = localDateAndTimeToUTC(localDate, ' 23:59:59')

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(changedTo(IOC_DASHBOARD_CALL_LIST)),
    switchTo(state$),
    map(getCurrentUserId),
    map(userId => fetchCallList.requested(userId, fromDate, throughDate))
  )
