import React, { useMemo, useState } from 'react'
// @ts-expect-error Migrate import to typescript
import DevXGrid from '~/components/DevXGrid'
import { Paper, makeStyles } from '@material-ui/core'
import {
  ACTIVE,
  DELETED,
  INACTIVE,
  PENDING,
  RECENTLY_UPDATED,
  caremoreRxStatusMap,
  // @ts-expect-error Migrate import to typescript
} from '../../utils/medListConstants'
// @ts-expect-error Migrate import to typescript
import MedDetail from './MedDetail'
import {
  addDefaults,
  historicalIntegratedSortingColumnExtensions,
  historicalMedListColumns,
  // @ts-expect-error Migrate import to typescript
} from '../../utils/common'

const useStyles = makeStyles(({ spacing }) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: spacing(1),
    alignItems: 'center',
  },
  whiteSpace: {
    '& td': {
      whiteSpace: 'normal',
    },
  },
}))

const initialFilterState = {
  [RECENTLY_UPDATED]: true,
  [PENDING]: true,
  [ACTIVE]: true,
  [INACTIVE]: false,
  [DELETED]: false,
}

export const historicalSorting = [
  { columnName: 'prescriptionDate', direction: 'desc' },
]
/**
 * NOTE:
 *
 * This components is pretty similar to the MedListGrid (I know) the reason is is it's own thing is because
 * this one is only meant for displaying medication data coming from external systems like NextGen and
 * EDH for Claims. This table will eventually disappear after we move from those systems and rely only
 * on the EMR. This one will also be different in that it will allow the user to import information from
 * the external systems into the EMR later on.
 */

interface IProps {
  // TODO: Create types for medications received
  medList: any[]
}

const HistoricalMedListGrid = ({ medList }: IProps) => {
  const classes = useStyles()
  const [filters] = useState(initialFilterState)

  const filteredPatientRxArray = useMemo(
    () =>
      medList
        .filter(
          x =>
            (filters[RECENTLY_UPDATED] && x.recentlyUpdated) ||
            filters[caremoreRxStatusMap.getIn([x.status, 'status'])]
        )
        .map(addDefaults),
    [medList, filters]
  )

  return (
    <React.Fragment>
      <Paper>
        <DevXGrid
          shouldDisableEditButton={() => true}
          shouldDisableDeleteButton={() => true}
          className={classes.whiteSpace}
          rows={filteredPatientRxArray}
          columns={historicalMedListColumns}
          columnExtensions={historicalIntegratedSortingColumnExtensions}
          getRowId={(row: any) => row.id}
          searchable
          sortable
          groupable
          detailComponent={MedDetail}
          defaultGrouping={[{ columnName: 'source' }]}
          defaultSorting={historicalSorting}
          defaultExpandedGroups={['Claims']}
          searchPlaceholder="Search Med List..."
          hideColumnsWhenGrouped
          pageable
          defaultPageSize={15}
          pageSizes={[15, 25, 50]}
          collapsible
          columnHideable
          indentColumnWidth={18}
          defaultHiddenColumnNames={[
            'primaryIndicationOther',
            'secondaryIndication',
            'secondaryIndicationOther',
          ]}
          localSettingsKey="patientHistoricalMedList"
        />
      </Paper>
    </React.Fragment>
  )
}

export default HistoricalMedListGrid
