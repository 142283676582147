import { combineEpics, ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
import {
  fetchCrossCoverageInfo,
  fetchHealthPlanAlerts,
  fetchOpenTOCTasks,
  fetchReminders,
  fetchUserAPPs,
} from '../data/crossCoverage'
import { getCurrentUserId } from '../data/currentUserId'
import { CSS_DASHBOARD_CROSS_COVERAGE_DASHBOARD } from '../router'

const crossCoverageChangedEpic = (action$: any) =>
  action$.pipe(
    filter(changedTo(CSS_DASHBOARD_CROSS_COVERAGE_DASHBOARD)),
    pluck('payload', 'params', 'userId'),
    distinctUntilChanged(),
    map(fetchUserAPPs.requested)
  )
const fetchUserAPPsSucceededEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(fetchUserAPPs.SUCCEEDED),
    pluck('payload'),
    filter(appIds => (appIds as any).length > 0),
    withLatestFrom(state$),
    mergeMap(([appIds, state]) => {
      const userId = getCurrentUserId(state)
      const actions = []
      actions.push(fetchReminders.requested(userId, appIds))
      appIds.forEach((appId: any) => {
        actions.push(fetchCrossCoverageInfo.requested(appId))
        actions.push(fetchHealthPlanAlerts.requested(appId))
        actions.push(fetchOpenTOCTasks.requested(appId))
      })
      return actions
    })
  )
export default combineEpics(
  crossCoverageChangedEpic,
  fetchUserAPPsSucceededEpic
)
