import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, first, map, mergeMap, pluck, tap } from 'rxjs/operators'
import { fetchCareTeam } from '~/data/careTeams'
import {
  fetchAttachmentTypes,
  fetchPatientAttachments,
} from '~/features/attachments'
import { fetchPatientAttachments as fetchNextGenPatientAttachments } from '~/features/attachmentsNextGen'
import { fetchAlerts } from '~/features/patientAlerts'
import { fetchPatient } from '~/features/patientInfo'
import { fetchPatientTimeline } from '~/features/patientTimeline/data'
import { patientChanged } from '../data/currentPatientId'
import { fetchPatientInfo } from '../data/patientInfo'

const patientInfoEpic = action$ =>
  action$.pipe(
    ofType(patientChanged),
    pluck('payload', 'patientId'),
    filter(Boolean),
    tap(() => {
      fetchPatientInfo.cancelLatest()
      fetchPatientAttachments.cancelLatest()
      fetchPatientTimeline.cancelLatest()
      fetchCareTeam.cancelLatest()
      fetchAlerts.cancelLatest()
    }),
    mergeMap(patientId => {
      return of(
        fetchPatientInfo.requested(patientId),
        fetchPatientAttachments.requested(patientId),
        fetchNextGenPatientAttachments.requested(patientId),

        fetchPatientTimeline.requested(patientId),
        fetchCareTeam.requested(patientId),
        fetchAlerts.requested(patientId),
        fetchPatient.requested(patientId)
      )
    })
  )

const attachmentTypesEpic = action$ =>
  action$.pipe(
    ofType(patientChanged),
    first(),
    map(fetchAttachmentTypes.requested)
  )

export default combineEpics(patientInfoEpic, attachmentTypesEpic)
