import React from 'react'
import { NumberField as BaseNumberField } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

const NumberField = props => {
  const { value, min, max, editing } = props

  const onChange = value => {
    props.onChange(Number(value))
  }

  return (
    <BaseField {...props}>
      <BaseNumberField
        fullWidth
        min={min}
        max={max}
        disabled={!editing}
        onChange={onChange}
        value={value || 0}
      />
    </BaseField>
  )
}

NumberField.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
}

export default NumberField
