import { combineReducers } from 'redux-immutable'
import stakeholders from './data/stakeholders'
import suggestedStakeholders from './data/suggestedStakeholders'
import key from './key'

const reducer = combineReducers({
  [stakeholders.key]: stakeholders,
  [suggestedStakeholders.key]: suggestedStakeholders,
})

reducer.key = key

export default reducer
