export { default as OnCall } from './containers/OnCall'
export { default as OnCallReadOnly } from './components/OnCallGrid'
export { default as OnCallView } from './containers/OnCallView'
export { default as reducer } from './reducer'
export {
  fetchOnCallsForPatient,
  fetchOnCallsForUserId,
  getOnCallsArray,
  getRecentOnCallsCount,
} from './data/onCalls'
