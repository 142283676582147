import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getAppFinder } from './root'

// KEY
export const key = 'potentialChannels'

// RECORD
export const PotentialChannel = Record({
  channelId: null,
  primaryContract: null,
})

// TYPE
export const POTENTIAL_CHANNELS_FETCH_FAILED = `${key}/POTENTIAL_CHANNELS_FETCH_FAILED`
export const POTENTIAL_CHANNELS_FETCH_REQUESTED = `${key}/POTENTIAL_CHANNELS_FETCH_REQUESTED`
export const POTENTIAL_CHANNELS_STORED = `${key}/POTENTIAL_CHANNELS_STORED`

// ACTION
export const potentialChannelsFetchFailed = () => ({
  type: POTENTIAL_CHANNELS_FETCH_FAILED,
})

export const potentialChannelsFetchRequested = ({
  healthplanId,
  marketId,
}) => ({
  type: POTENTIAL_CHANNELS_FETCH_REQUESTED,
  payload: { healthplanId, marketId },
})

export const potentialChannelsStored = potentialChannels => ({
  type: POTENTIAL_CHANNELS_STORED,
  payload: potentialChannels,
})

// SELECTOR
export const selectPotentialChannels = pipe(getAppFinder, get(key))

// REDUCER
const createEachPotentialChannel = ({ channelId, primaryContract }) =>
  PotentialChannel({
    channelId,
    primaryContract,
  })

const createListOfPotentialChannels = (_state, { payload }) =>
  List(payload.map(createEachPotentialChannel))

export default createReducer(key, List(), {
  [POTENTIAL_CHANNELS_FETCH_FAILED]: () => List(),
  [POTENTIAL_CHANNELS_STORED]: createListOfPotentialChannels,
})

// API
export const fetchPotentialChannels = ({ healthplanId, marketId }) =>
  AspireAPI.get('v1/referral/channels', {
    params: {
      healthplan_id: healthplanId,
      market_id: marketId,
    },
  })
