import { ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import { showError } from '~/utils/alertOnError'
import loading from '~/utils/loading'
import {
  POTENTIAL_MARKETS_FETCH_REQUESTED,
  fetchPotentialMarkets,
  potentialMarketsFetchFailed,
  potentialMarketsStored,
} from '../data/potentialMarkets'

const handleResponse = (markets = []) =>
  markets.length
    ? of(potentialMarketsStored(markets))
    : of(potentialMarketsFetchFailed())

const handleError = (e = { message: 'Unknown Error' }) =>
  merge(of(potentialMarketsFetchFailed()), showError(e.message)(e))

const fetchAndHandleResponse = payload =>
  from(fetchPotentialMarkets(payload)).pipe(
    mergeMap(handleResponse),
    catchError(handleError)
  )

export default action$ =>
  action$.pipe(
    ofType(POTENTIAL_MARKETS_FETCH_REQUESTED),
    pluck('payload'),
    mergeMap(payload => loading(fetchAndHandleResponse(payload)))
  )
