import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
} from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { requestMiddleware } from '~/utils/Request'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createStore = (
  reducer,
  { routableMiddleware, epic, epicDependencies, otherMiddleware = [] },
  initialState
) => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: epicDependencies,
  })

  const store = createReduxStore(
    reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        requestMiddleware,
        epicMiddleware,
        routableMiddleware,
        ...otherMiddleware
      )
    )
  )

  epicMiddleware.run(epic)

  return store
}

export default createStore
