import React from 'react'
import { useAction } from '~/hooks'
import { Button } from '@material-ui/core'
import { newEventOpened } from '../../data/events'

const AddEventButton = props => {
  const openNewEvent = useAction(newEventOpened)

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={openNewEvent}
      {...props}
    >
      Add Event
    </Button>
  )
}

export default AddEventButton
