import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'selectedMarkets'

export const MARKET_SELECTED = type(rootKey, 'MARKET_SELECTED')

export const setSelectedMarkets = creator(MARKET_SELECTED, 'markets')

export default createReducer(key, new Set(), {
  [MARKET_SELECTED]: (state, { payload: { markets } }) => new Set(markets),
})

export const getSelectedMarkets = pipe(getRoot, get(key))
