import { Record } from 'immutable'

export const APP = Record({
  id: null,
  name: null,
})

export const CaseManager = Record({
  id: null,
  healthPlan: null,
  name: null,
  phone: null,
})

export const Market = Record({
  id: null,
  name: null,
})

export const Name = Record({
  firstName: null,
  lastName: null,
  middleName: null,
  salutation: null,
  suffix: null,
})

export const NextTargetedVisit = Record({
  date: null,
  other: null,
  reason: null,
})

export const Physician = Record({
  id: null,
  name: null,
  phone: null,
  fax: null,
})

export const ReferringPhysician = Record({
  id: null,
  name: null,
  phone: null,
})

export const ReferringEntity = Record({
  id: null,
  name: null,
})
