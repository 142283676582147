import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { Icon, IconButton, Tooltip } from '@material-ui/core'

const RefreshButton = props => {
  const { onRefresh } = props

  return (
    <Plugin name="Refresh">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {() => (
            <Tooltip placement="bottom" title="Refresh Grid">
              <IconButton onClick={onRefresh} size="small">
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  )
}

RefreshButton.propTypes = {
  onRefresh: PropTypes.func.isRequired,
}

export default RefreshButton
