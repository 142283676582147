import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// RECORDS
export const Substatus = Record({
  id: null,
  value: null,
  label: null,
})

// CONSTANTS
export const SUBSTATUSES_STORED = 'substatuses/SUBSTATUSES_STORED'

// ACTION CREATORS
export const substatusesStored = (substatuses: any) => ({
  type: SUBSTATUSES_STORED,
  substatuses,
})

// KEY
export const key = 'substatuses'

// REDUCER
export default createReducer(key, Map(), {
  [SUBSTATUSES_STORED]: (state: any, { substatuses }: any) =>
    state.mergeDeep(substatuses),
})

export const mapData = (data: any) =>
  Map(data.map((d: any) => [d.id, Substatus(d)]))

// API
export const fetchSubstatuses = () =>
  AspireAPI.get('v1/referral/substatuses').then(mapData)

// SELECTORS
export const getAllSubstatuses = (state: any) =>
  getReferralManagement(state).get(key)
export const getSubstatusValueLabelMap = createSelector(
  [getAllSubstatuses],
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'm' implicitly has an 'any' type.
  substatuses => substatuses.mapEntries(([, m]) => [m.value, m.label])
)
