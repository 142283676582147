import { combineEpics, ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map, mapTo, takeUntil } from 'rxjs/operators'
import { getShouldLoadInactiveUsers } from '~/apps/userSettings/data/userListSettings'
import { fetchUsers, getUsers } from '~/data/users'
import { switchTo } from '~/utils/operators'
import {
  REQUEST_FULL_CARE_TEAM_LIST,
  fetchCareTeamRoles,
  fetchCareTeamTypes,
  fetchCareTeams,
  getRoles,
  getTypes,
  requestFullCareTeamList,
} from '../data/careTeams'
import {
  CARE_TEAM_MANAGEMENT,
  CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL,
  CARE_TEAM_MANAGEMENT_MEMBER_REPLACEMENT,
  CARE_TEAM_MANAGEMENT_NEW_CARE_TEAM,
  CARE_TEAM_POD_MANAGER,
} from '../routes'

const careTeamRoutes = [
  CARE_TEAM_MANAGEMENT,
  CARE_TEAM_MANAGEMENT_NEW_CARE_TEAM,
  CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL,
  CARE_TEAM_MANAGEMENT_MEMBER_REPLACEMENT,
  CARE_TEAM_POD_MANAGER,
]

const getCareTeams = action$ =>
  action$.pipe(
    ofType(REQUEST_FULL_CARE_TEAM_LIST),
    map(fetchCareTeams.requested),
    takeUntil(action$.pipe(ofType(fetchCareTeams.SUCCEEDED)))
  )

const getCareTeamRoles = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_FULL_CARE_TEAM_LIST),
    switchTo(state$),
    map(getRoles),
    filter(roles => roles.isEmpty()),
    map(fetchCareTeamRoles.requested),
    takeUntil(action$.pipe(ofType(fetchCareTeamRoles.SUCCEEDED)))
  )

const getCareTeamTypes = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_FULL_CARE_TEAM_LIST),
    switchTo(state$),
    map(getTypes),
    filter(types => types.isEmpty()),
    map(fetchCareTeamTypes.requested),
    takeUntil(action$.pipe(ofType(fetchCareTeamTypes.SUCCEEDED)))
  )

const loadUsers = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_FULL_CARE_TEAM_LIST),
    switchTo(state$),
    map(getUsers),
    filter(users => users.isEmpty()),
    switchTo(state$),
    map(getShouldLoadInactiveUsers),
    map(fetchUsers.requested),
    takeUntil(action$.pipe(ofType(fetchUsers.SUCCEEDED)))
  )

const enteredCareTeams = action$ =>
  action$.pipe(
    filter(entered(careTeamRoutes)),
    mapTo(requestFullCareTeamList())
  )

export default combineEpics(
  enteredCareTeams,
  getCareTeamRoles,
  getCareTeamTypes,
  getCareTeams,
  loadUsers
)
