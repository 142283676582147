import { Map } from 'immutable'
import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { fetchMdtSingleSignOn } from '../../data/mdtSingleSignOn'
import { getPatientId, getPrescriberId } from '../../data/patientInformation'
import { getPatientRxById } from '../../data/patientRx'
import { rxMedicationSelected } from '../../data/selectedMedication'
import { isPending } from '../../utils/medListConstants'
import ActionItem from '../ActionItem'

const merge = props => map => map.merge(props)

const RowActions = ({
  getPatientRxById,
  mdtSingleSignOn,
  medication,
  patientId,
  prescriberId,
  selectMedication,
}) => {
  const useSelectMedication = props =>
    useCallback(
      () =>
        pipe(
          getPatientRxById,
          Map,
          merge(props),
          selectMedication
        )(medication.id),
      [getPatientRxById, selectMedication, medication]
    )

  const editMedication = useSelectMedication({
    isEditing: true,
  })

  const refillMedication = useSelectMedication({
    status: 'Pending',
    id: null,
    partnerMedId: null,
    prescriberId: null,
    prescriptionDate: null,
    routeDetail: null,
    patientId: patientId,
    isRefilling: medication.id,
  })

  const goToMDToolbox = useCallback(
    () =>
      mdtSingleSignOn({
        header: 'no_edit',
        partnerMedId: medication.id,
        patientId: patientId,
        prescriberId: prescriberId,
      }),
    [mdtSingleSignOn, medication, patientId, prescriberId]
  )

  const medIsPending = useMemo(() => isPending(medication.status), [medication])

  return (
    <React.Fragment>
      <ActionItem
        key="edit"
        toolTip="Edit Medication Details"
        icon="file_copy"
        onClick={editMedication}
        mdtSFAuth
      />
      {medIsPending ? (
        <ActionItem
          key="mdToolBox"
          toolTip="Manage Medication from MDToolbox"
          icon="local_pharmacy"
          onClick={goToMDToolbox}
          mdtSFAuth
        />
      ) : (
        <ActionItem
          key="refil"
          toolTip="Refill medication - Add As New Pending"
          icon="refresh"
          onClick={refillMedication}
          mdtSFAuth
        />
      )}
    </React.Fragment>
  )
}

RowActions.propTypes = {
  getPatientRxById: PropTypes.func.isRequired,
  mdtSingleSignOn: PropTypes.func.isRequired,
  medication: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  prescriberId: PropTypes.string,
  selectMedication: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  getPatientRxById: getPatientRxById(state),
  patientId: getPatientId(state),
  prescriberId: getPrescriberId(state),
})

const mapDispatchToProps = {
  mdtSingleSignOn: fetchMdtSingleSignOn.requested,
  selectMedication: rxMedicationSelected,
}

export default connect(mapStateToProps, mapDispatchToProps)(RowActions)
