// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import currentReportId from './data/currentReportId'
import metabaseSecret from './data/metabaseSecret'
import reports from './data/reports'
import key from './key'

const reducer = combineReducers({
  metabaseSecret,
  currentReportId,
  reports,
})

reducer.key = key

export default reducer
