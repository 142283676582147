import { ofType } from 'redux-observable'
import { distinctUntilChanged, map, pluck } from 'rxjs/operators'
import { fetchEpisodesOfCare, myPatientsClicked } from '../data/episodesOfCare'

const request = ownerId =>
  fetchEpisodesOfCare.requested({ careTeamMemberId: ownerId })

export default action$ => {
  return action$.pipe(
    ofType(myPatientsClicked),
    pluck('payload', 'ownerId'),
    distinctUntilChanged('ownerId'),
    map(request)
  )
}
