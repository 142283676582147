import React from 'react'
import { useSelector } from 'react-redux'
import { getDialog } from '../../data/createSnfDialog'
import SNFForm from './SNFForm'

const AddSNF = () => {
  const dialog = useSelector(getDialog)

  return dialog.open ? (
    <SNFForm open={dialog.open} patientId={dialog.patientId} />
  ) : null
}

export default AddSNF
