import React, { useMemo } from 'react'
import { createSelector } from 'reselect'
import { SelectField } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

const DropDownField = props => {
  const {
    value,
    values,
    getValue,
    getLabel,
    sorted,
    allowNone,
    editing,
    onChange,
  } = props

  const getSortedValues = useMemo(() =>
    createSelector([values => values], values =>
      values.sort((a, b) =>
        a.get('text').toLowerCase().localeCompare(b.get('text').toLowerCase())
      )
    )
  )

  const dropDownValues = sorted ? getSortedValues(values) : values

  return (
    <BaseField {...props}>
      <SelectField
        native
        fullWidth
        rowsMax={10}
        disabled={!editing}
        onChange={onChange}
        value={String(value) || ''}
      >
        {allowNone && <option value="">None</option>}
        {dropDownValues.toIndexedSeq().map(value => (
          <option key={getValue(value)} value={String(getValue(value))}>
            {getLabel(value)}
          </option>
        ))}
      </SelectField>
    </BaseField>
  )
}

DropDownField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.oneOfType([
    PropTypes.map,
    PropTypes.orderedMap,
    PropTypes.list,
  ]).isRequired,
  getValue: PropTypes.func,
  getLabel: PropTypes.func,
  sorted: PropTypes.bool,
  allowNone: PropTypes.bool,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
}

DropDownField.defaultProps = {
  sorted: false,
  allowNone: true,
  getValue: option => option.get('id'),
  getLabel: option => option.get('text'),
}

export default DropDownField
