import { compareDesc } from 'date-fns'
import { Record } from 'immutable'
import { eq } from 'lodash/fp'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { LAST_DETECTED_ACTIVITY_TIME, getItem, setItem } from '~/utils/storage'
import { appInitialized } from './root'

const AUTHENTICATED = 'AUTHENTICATED'
const EXPIRED = 'EXPIRED'
const UNAUTHENTICATED = 'UNAUTHENTICATED'

// Key
const SESSION = 'session'

// Actions
const creator = scopedCreator(SESSION)
export const activityDetected = creator('ACTIVITY_DETECTED')
export const continueUnauthenticated = creator(
  'CONTINUE_UNAUTHENTICATED',
  false
)
export const loggedIn = creator('LOGGED_IN', ['user'])
export const loggedOut = creator('LOGGED_OUT')

// Requests
export const fetchUser = Request({
  typePrefix: SESSION,
  typeBase: 'FETCH_USER',
  requestParams: ['userId'],
  operation: userId => AspireAPI.get(`v1/user/${userId}`),
  messages: {
    failed: 'Could not fetch user',
  },
})

export const saveUserOfflinePassphrase = Request({
  typePrefix: SESSION,
  typeBase: 'SAVE_USER_OFFLINE_PASSPHRASE',
  requestParams: ['userId', 'hash'],
  operation: (userId, hash) =>
    AspireAPI.put(`v1/user/${userId}/offline_passphrase`, { hash }),
  messages: {
    succeeded: 'Successfully set offline passphrase',
    failed: 'Could not save passphrase',
  },
})

// Records
export const User = Record({
  id: null,
  name: null,
  aspireRole: null,
  role: null,
  offlinePassphrase: null,
  lastActivity: new Date(),
})

// Reducer
export const Session = Record({
  status: null,
  user: null,
})

export default createReducer(SESSION, Session(), {
  [appInitialized]: (state, { payload: { initialData } }) =>
    initialData.user
      ? state.merge({
          status: state.status || EXPIRED,
          user: User(initialData.user),
        })
      : state,
  [continueUnauthenticated]: state =>
    state
      .set('status', UNAUTHENTICATED)
      .update('user', user => user.set('lastActivity', new Date())),
  [fetchUser.SUCCEEDED]: (
    state,
    { payload: { aspireRole, role, offlinePassphrase } }
  ) =>
    state.update('user', user =>
      user.merge({
        aspireRole,
        role,
        offlinePassphrase,
      })
    ),
  [saveUserOfflinePassphrase.SUCCEEDED]: (state, { meta }) =>
    state.update('user', user =>
      user.set('offlinePassphrase', meta.request.payload.hash)
    ),
  [activityDetected]: state =>
    state.update('user', user => {
      const now = new Date()
      setItem(`LAST_DETECTED_ACTIVITY_TIME`, now)
      return user.set('lastActivity', now)
    }),
  [loggedIn]: (state, { payload: { user } }) =>
    state.set('status', AUTHENTICATED).set('user', User(user)),
  [loggedOut]: state => state.set('status', EXPIRED),
})

// Selectors
const getSession = keys => getIn([SESSION].concat(keys))

const isStatus = status => compose(eq(status), getSession('status'))

export const getUser = getSession('user')
export const getUserId = getSession(['user', 'id'])
export const getUserRole = getSession(['user', 'role'])
export const getUserOfflinePassphrase = getSession([
  'user',
  'offlinePassphrase',
])
export const getUserLastActivity = state => {
  const last_activity_in_tab = getSession(['user', 'lastActivity'])(state)
  const last_activity_cross_tabs = getItem(LAST_DETECTED_ACTIVITY_TIME)

  if (last_activity_in_tab && last_activity_cross_tabs) {
    compareDesc(last_activity_in_tab, last_activity_cross_tabs) == 1
      ? last_activity_cross_tabs
      : last_activity_in_tab
  } else last_activity_in_tab
}

export const isAuthenticated = isStatus(AUTHENTICATED)
export const isUnauthenticated = isStatus(UNAUTHENTICATED)
export const isExpired = isStatus(EXPIRED)
