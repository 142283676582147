import { hasClaim } from '../../../utils'

const canCreate = claims => canApprove(claims) && !isScheduling(claims)
const canEdit = (event, claims) => canApprove(claims) && !isScheduling(claims)
const canSave = (event, claims) => canApprove(claims) && !isScheduling(claims)
const canDelete = (event, claims) => canApprove(claims) && !isScheduling(claims)

const canApprove = claims => hasClaim(claims, 'calendar', 'approve')
const isScheduling = claims => hasClaim(claims, 'calendar', 'scheduling')

export { canCreate, canEdit, canSave, canDelete }
