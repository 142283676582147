import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMapTo } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/encounterTypes' or its ... Remove this comment to see the full error message
import { fetchEncounterTypes } from '~/data/encounterTypes'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/root' or its correspond... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
import { fetchPermissions } from '../data/permissions'

const appInitializedEpic = (action$: any) =>
  action$.pipe(
    ofType(appInitialized),
    mergeMapTo(
      of(fetchPermissions.requested(), fetchEncounterTypes.requested())
    )
  )

export default appInitializedEpic
