// import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const HEALTH_CONCERN_GOALS = 'healthConcernGoals'

export const fetchHealthConcernGoals = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ASSOCIATED_GOALS',
  requestParams: ['id'],
  operation: (id: any) => AspireAPI.get(`admin_tools/health_concern/${id}`),
  messages: { failed: 'There was an issue fetching associated goals' },
})

export default createReducer(HEALTH_CONCERN_GOALS, [], {
  [fetchHealthConcernGoals.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getHealthConcernGoals = pipe(getRoot, get(HEALTH_CONCERN_GOALS))
