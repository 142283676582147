import { SNF } from '../data/snfs'

export const getValuesFromSNF = () => SNF()

export const getSNFFromValues = (values, snf = SNF()) => {
  return snf.merge({
    facilityName: values.get('facilityName'),
    notes: values.get('notes'),
    admissionDate: values.get('admissionDate'),
    dischargeDate: values.get('dischargeDate'),
  })
}
