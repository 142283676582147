// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import contracts from './data/contracts'
import copiedUser from './data/copiedUser'
import currentPermissionSetId from './data/currentPermissionSetId'
import { currrentRecord } from './data/currentRecord'
import currentRoleId from './data/currentRoleId'
import currentUser from './data/currentUser'
import healthConcerns from './data/healthConcern'
import icd10ProblemTypes from './data/icd10ProblemTypes'
import interventions from './data/intervention'
import outcomes from './data/outcome'
import healthConcernGoals from './data/healthConcernGoals'
import goalAssociations from './data/goalAssociations'
import interventionGoals from './data/interventionGoals'
import outcomeGoals from './data/outcomeGoals'
import { carePlanGoals } from './data/careplanGoals'
import locations from './data/locations'
import permissionSets from './data/permissionSets'
import permissions from './data/permissions'
import prescriberLocations from './data/prescriberLocations'
import prescribers from './data/prescribers'
import roles from './data/roles'
import snackbarMessage from './data/snackbarMessage'
import triggerLog from './data/triggerLog'
import userCount from './data/userCount'
import userQuery from './data/userQuery'
import users from './data/users'
import key from './key'

const reducer = combineReducers({
  [contracts.key]: contracts,
  [copiedUser.key]: copiedUser,
  [currentPermissionSetId.key]: currentPermissionSetId,
  [currrentRecord.key]: currrentRecord,
  [currentRoleId.key]: currentRoleId,
  [currentUser.key]: currentUser,
  [carePlanGoals.key]: carePlanGoals,
  [healthConcernGoals.key]: healthConcernGoals,
  [goalAssociations.key]: goalAssociations,
  [interventionGoals.key]: interventionGoals,
  [outcomeGoals.key]: outcomeGoals,
  [healthConcerns.key]: healthConcerns,
  [icd10ProblemTypes.key]: icd10ProblemTypes,
  [interventions.key]: interventions,
  [outcomes.key]: outcomes,
  [locations.key]: locations,
  [permissionSets.key]: permissionSets,
  [permissions.key]: permissions,
  [prescriberLocations.key]: prescriberLocations,
  [prescribers.key]: prescribers,
  [roles.key]: roles,
  [snackbarMessage.key]: snackbarMessage,
  [triggerLog.key]: triggerLog,
  [userCount.key]: userCount,
  [userQuery.key]: userQuery,
  [users.key]: users,
})

reducer.key = key

export default reducer
