import { Map } from 'immutable'
import momentTZ from 'moment-timezone'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Button, Divider, Icon, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getTimeZone, timeZoneSet } from '../../../data/calendar'
import { TimeZoneNote } from '../../../data/common/shared'
import timeZones from './timeZones'

const DEFAULT_TIME_ZONE_NOTE = TimeZoneNote({
  note: 'My Computer',
  condition: timeZone => timeZone === momentTZ.tz.guess(),
})

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    minWidth: 300,
  },
}))

const TimeZoneSelector = ({ timeZoneNotes }) => {
  const classes = useStyles()

  const timeZone = useSelector(getTimeZone)
  const onTimeZoneChange = useAction(timeZoneSet)

  const groupedTimeZones = useMemo(
    () =>
      timeZones.groupBy(timeZone =>
        [...timeZoneNotes, DEFAULT_TIME_ZONE_NOTE].reduce(
          (hasNote, { condition }) =>
            hasNote || condition(timeZone.get('value')),
          false
        )
      ),
    [timeZone, timeZoneNotes]
  )

  const renderTimeZone = timeZone => {
    const notes = [...timeZoneNotes, DEFAULT_TIME_ZONE_NOTE]
      .filter(({ condition }) => condition(timeZone.value))
      .map(({ note }) => note)

    const utcOffsetLabel = `[UTC ${timeZone.offsetLabel}]`
    const zoneLabel = timeZone.zoneLabel
    const notesLabel = notes.length > 0 ? ` (${notes.join(', ')})` : ''
    const timeZoneLabel = `${utcOffsetLabel} ${zoneLabel}${notesLabel}`

    return (
      <MenuItem
        key={timeZone.value}
        onClick={() => onTimeZoneChange(timeZone.value)}
        value={timeZone.value}
        label={zoneLabel}
      >
        {timeZoneLabel}
      </MenuItem>
    )
  }

  const mainTimeZones = groupedTimeZones.get(true, Map())
  const otherTimeZones = groupedTimeZones.get(false, Map())

  const timeZoneMapping = zone => {
    switch (zone) {
      case 'America/New_York':
        return 'ET'
      case 'America/Chicago':
        return 'CT'
      case 'America/Denver':
        return 'MT'
      case 'America/Phoenix':
        return 'MT AZ'
      case 'America/Los_Angeles':
        return 'PT AZ'
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<Icon>access_time</Icon>}
      >
        {timeZoneMapping(timeZone)}
      </Button>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        {mainTimeZones.toIndexedSeq().map(renderTimeZone)}
        <Divider />
        {otherTimeZones.toIndexedSeq().map(renderTimeZone)}
      </Menu>
    </div>
  )
}

TimeZoneSelector.propTypes = {
  timeZoneNotes: PropTypes.arrayOf(TimeZoneNote),
}

TimeZoneSelector.defaultProps = {
  timeZoneNotes: [],
}

export default TimeZoneSelector
