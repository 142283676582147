import { combineEpics } from 'redux-observable'
import channelJoined from './epics/channelJoined'
import clearNotificationsRequested from './epics/clearNotificationsRequested'
import fetchNextNotificationsRequested from './epics/fetchNextNotificationsRequested'
import markNotificationUnreadRequested from './epics/markNotificationUnreadRequested'
import statusSet from './epics/statusSet'
import userListSettingsToggle from './epics/userListSettingsToggle'
import userSignatureEntered from './epics/userSignatureEntered'

export default combineEpics(
  channelJoined,
  clearNotificationsRequested,
  fetchNextNotificationsRequested,
  markNotificationUnreadRequested,
  statusSet,
  userListSettingsToggle,
  userSignatureEntered
)
