export default {
  tasks: {
    60: {
      id: 60,
      name: 'Call Back Returned Missed Call',
      users: [{ id: 'stu', name: 'Wrimk McSketchins', role: 'NSS' }],
    },
    119: {
      id: 119,
      name: 'CMRN Check-In Symptoms and Compliance',
      users: [
        { id: 'mno', name: "Hairishable Qu'aim", role: 'PCC RN' },
        { id: 'abc', name: "Borpo T'arr", role: 'APP' },
      ],
    },
    96: {
      id: 96,
      name: 'APP Update HIPAA Form',
      users: [{ id: 'mno', name: "Hairishable Qu'aim", role: 'PCC RN' }],
    },
    14: {
      id: 14,
      name: 'Support Medical Records Request',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
    45: {
      id: 45,
      name: 'SW Advance Care Planning',
      users: [
        { id: 'def', name: 'Crungle Ferp', role: 'PCC CC' },
        { id: 'pqr', name: 'Tungus Amunkus', role: 'PCC OA' },
        { id: 'stu', name: 'Wrimk McSketchins', role: 'NSS' },
      ],
    },
    78: {
      id: 78,
      name: 'SW Check-In Hospice Eligible',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
    79: {
      id: 79,
      name: 'SW Check-in Hotspotter',
      users: [
        { id: 'def', name: 'Crungle Ferp', role: 'PCC CC' },
        { id: 'stu', name: 'Wrimk McSketchins', role: 'NSS' },
      ],
    },
    51: {
      id: 51,
      name: 'SW Crisis Intervention',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
    49: {
      id: 49,
      name: 'SW Family Support',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
    81: {
      id: 81,
      name: 'SW Hospice Education',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
    82: {
      id: 82,
      name: 'SW Patient Psychosocial Assessment',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
  },
  patient: {
    id: '0011500001nPFZ5AAO',
    age: null,
    name: 'WILLENE BRITTAN',
    phone: '(715) 317-4434',
    gender: 'Female',
    address: '1396 Frayser Blvd, Memphis, Tennessee',
    dateOfBirth: '1942-08-10',
    patientScore: null,
    lastVisitDate: '2018-05-21',
    nextTargetedVisitDate: '2018-06-08',
  },
  contacts: {
    patient: [
      {
        id: 'a011500000cQDJb5AO',
        name: null,
        type: 'Home',
        active: true,
        preferred: true,
        phiApproved: false,
        phoneNumber: '(715) 317-4434',
        relationship: null,
        phiVoicemailApproved: false,
      },
      {
        id: 'a011500000cQDJbA5O',
        name: null,
        type: 'Home',
        active: true,
        preferred: true,
        phiApproved: false,
        phoneNumber: '(836) 421-6123',
        relationship: null,
        phiVoicemailApproved: false,
      },
    ],
    stakeholder: [
      // {
      //   id: 'a011500000cQDJbAAO',
      //   name: null,
      //   type: 'Home',
      //   active: true,
      //   preferred: true,
      //   phiApproved: false,
      //   phoneNumber: '(715) 317-2222',
      //   relationship: null,
      //   phiVoicemailApproved: false,
      // },
    ],
    pcp: [
      {
        id: 'a011500000cQDJbAA5',
        name: null,
        type: 'Home',
        active: true,
        preferred: true,
        phiApproved: false,
        phoneNumber: '(715) 317-4444',
        relationship: null,
        phiVoicemailApproved: false,
      },
      {
        id: 'a011500000cQ5JbAAO',
        name: null,
        type: 'Office',
        active: true,
        preferred: true,
        phiApproved: false,
        phoneNumber: '(836) 421-6666',
        relationship: null,
        phiVoicemailApproved: false,
      },
      {
        id: 'a0115000005QDJbAAO',
        name: null,
        type: null,
        active: true,
        preferred: false,
        phiApproved: false,
        phoneNumber: '(836) 421-3333',
        relationship: null,
        phiVoicemailApproved: false,
      },
    ],
    vendor: [
      {
        id: 'a011500000cQDJ4AAO',
        name: null,
        type: 'Home',
        active: true,
        preferred: true,
        phiApproved: false,
        phoneNumber: '(715) 317-9999',
        relationship: null,
        phiVoicemailApproved: false,
      },
      {
        id: 'a0115000004QDJbAAO',
        name: null,
        type: 'Home',
        active: true,
        preferred: true,
        phiApproved: false,
        phoneNumber: '(836) 421-0000',
        relationship: null,
        phiVoicemailApproved: false,
      },
    ],
  },
  encounter: {},
  workflowHistory: {
    callAttempt: 1,
  },
  assignedUserRole: 'pcc_rn',
  defaultRescheduleDate: '2019-07-15',
  callNotes: 'test',
}
