import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette, spacing }) => ({
  statistic: {
    marginRight: spacing(3),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  value: {
    color: palette.primary.main,
    marginRight: spacing(0.5),
  },
  label: {
    fontSize: '0.65em',
  },
})

const Count = ({ classes, value, label, compact }) => (
  <Typography variant={compact ? 'h6' : 'h5'} className={classes.statistic}>
    <span className={classes.value}>{value}</span>
    <span className={classes.label}>{label}</span>
  </Typography>
)

Count.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  compact: PropTypes.bool,
}

Count.defaultProps = {
  label: 'Results',
}

export default React.memo(withStyles(styles)(Count))
