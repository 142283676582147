import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import TASKS from '../key'
import { getRoot } from './common'
import { USER_ID_CHANGED } from './userId'

// Key
const TOGGLES = 'toggles'

// Record
export const Toggles = Record({
  cancelled: false,
  completed: false,
})

// Types
export const COMPLETED_TOGGLED = `${TASKS}/${TOGGLES}/COMPLETED_TOGGLED`
export const CANCELLED_TOGGLED = `${TASKS}/${TOGGLES}/CANCELLED_TOGGLED`

// Actions
export const completedToggled = creator(COMPLETED_TOGGLED)
export const cancelledToggled = creator(CANCELLED_TOGGLED)

// Reducer
export default createReducer(TOGGLES, Toggles(), {
  [CANCELLED_TOGGLED]: state => state.update('cancelled', c => !c),
  [COMPLETED_TOGGLED]: state => state.update('completed', c => !c),
  [USER_ID_CHANGED]: () => Toggles(),
})

// Selector
export const getToggles = compose(get(TOGGLES), getRoot)
