import { format, parse } from 'date-fns'
import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatZipcode } from '~/utils/format'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { PHONE_REGEXP, STATUS_ACTIVE } from './constants'
import {
  ProviderAddressSeachResults,
  ProviderAddressSearchInput,
} from './interfaces'

// KEY
const PROVIDER = 'providerList'

// KEY
const PROVIDERSEARCH = 'providerAddress'
const creator = scopedCreator(PROVIDERSEARCH)
export const clearAddressSearch = creator('CLEAR_ADDRESS_SEARCH')
export const selectAddress = creator('SELECT_ADDRESS')

const creatorr = scopedCreator(PROVIDER)
export const clearAddressList = creatorr('CLEAR_ADDRESS_LIST')

export const ProviderAddress = Record({
  name: null,
  address: null,
  city: null,
  state: null,
  zipcode: null,
  phone: null,
  distance: null,
  status: null,
  facilityType: null,
})

// TRANSFORMER
const transformAddress = (providerAddress: any) => {
  const emptyAddress = ``
  if (!providerAddress) return emptyAddress

  if (providerAddress.providerAddress1) {
    let address = providerAddress.providerAddress1
    address = providerAddress.providerAddress2
      ? `${address}, ${providerAddress.providerAddress2}`
      : address
    address = providerAddress.providerAddress3
      ? `${address}, ${providerAddress.providerAddress3}`
      : address
    return address
  } else {
    if (providerAddress.providerAddress2) {
      let address = providerAddress.providerAddress2
      address = providerAddress.providerAddress3
        ? `${address}, ${providerAddress.providerAddress3}`
        : address
      return address
    }
    return providerAddress.providerAddress3
  }
}

const mapSearchResults = (results: [ProviderAddressSeachResults]) =>
  List(
    results?.map(providerAddress =>
      ProviderAddress({
        name: providerAddress?.providerName,
        address: transformAddress(providerAddress),
        city: providerAddress?.providerCity,
        zipcode: formatZipcode(providerAddress?.providerZip),
        phone: providerAddress?.providerContactNumber?.replace(
          PHONE_REGEXP,
          ''
        ),
        distance: providerAddress?.distanceOfSourceZip
          ? Math.round(providerAddress?.distanceOfSourceZip)
          : null,
        state: providerAddress?.providerState,
        facilityType: providerAddress?.facilityTypeDesc,
        status:
          providerAddress?.active === 'YES'
            ? STATUS_ACTIVE
            : `Address termed on ${formatTermedDate(
                providerAddress?.providerAddressTermDate
              )}`,
      })
    )
  )

const formatTermedDate = (termedDate: string) => {
  return format(
    parse(termedDate, 'yyyy-MM-dd HH:mm:ss.SSS', new Date()),
    'MM/dd/yyyy'
  )
}

// REQUEST
export const providerAddressList = Request({
  typePrefix: rootKey,
  typeBase: 'PROVIDER_LIST',
  requestParams: [
    'location_type',
    'source_city',
    'source_zipcode',
    'member_state',
  ],
  operation: (
    location_type: any,
    sourceCity: any,
    sourceZipcode: any,
    memberState: any
  ) => {
    let url = `transportation/provider_address/${location_type}`
    if (sourceZipcode && sourceZipcode.length == 5) {
      url = memberState
        ? `${url}?source_city=${sourceCity}&source_zipcode=${sourceZipcode}&member_state=${memberState}`
        : `${url}?source_city=${sourceCity}&source_zipcode=${sourceZipcode}`
    } else {
      url = memberState
        ? `${url}?source_city=${sourceCity}&member_state=${memberState}`
        : `${url}`
    }
    return AspireAPI.get(url)
  },
  transform: mapSearchResults,
  messages: { failed: 'Failed to search ' },
})

// REDUCER
export const providerList = createReducer(PROVIDER, List(), {
  [providerAddressList.SUCCEEDED]: compose(payload, action),
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearAddressList]: () => List(),
})

// SELECTORS
export const getListResults = pipe(getRoot, get(PROVIDER))

// REQUEST
export const providerAddressSearch = Request({
  typePrefix: rootKey,
  typeBase: 'PROVIDER_ADDRESS',
  requestParams: ['data', 'source_city', 'source_zipcode'],
  operation: (
    data: ProviderAddressSearchInput,
    sourceCity: string,
    sourceZipcode: string
  ) => {
    const url = sourceZipcode
      ? `transportation/search_providers?source_city=${sourceCity}&source_zipcode=${sourceZipcode}`
      : 'transportation/search_providers'
    return AspireAPI.post(url, { ...data })
  },
  transform: mapSearchResults,
  messages: { failed: 'Failed to search ' },
})

// REDUCER
export const providerAddress = createReducer(PROVIDERSEARCH, List(), {
  [providerAddressSearch.SUCCEEDED]: compose(payload, action),
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearAddressSearch]: () => List(),
  // @ts-expect-error can we deal with the toString being automatically called?
  [selectAddress]: () => ProviderAddress,
})

// SELECTORS
export const getSearchResults = pipe(getRoot, get(PROVIDERSEARCH))
