import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getOrderKey } from './common/shared'

export const NewOrder = Record({
  type: null,
  subtype: null,
  patientId: null,
  userId: null,
  id: null,
})

export const key = 'newOrder'

export const createOrder = Request({
  typePrefix: key,
  typeBase: 'CREATE_ORDER',
  requestParams: ['type', 'subtype', 'patientId'],
  operation: (type, subtype, patientId) =>
    AspireAPI.post('v1/orders', {
      order_type: type,
      subtype: subtype,
      patient_id: patientId,
    }),
  transform: NewOrder,
  messages: {
    failed: 'Could not create order.',
    succeeded: 'New order successfully created.',
  },
})

const init = NewOrder()

export default createReducer(key, init, {
  [createOrder.SUCCEEDED]: (_state, { payload }) => payload,
  [createOrder.REQUESTED]: () => NewOrder(),
})

export const getNewOrder = state => getOrderKey(state).get(key)
