import { Set } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const SELECTED_APPS = 'selectedAPPs'

const transformSelectedAPPs = data => Set((data || {}).npList)

export const fetchSelectedAPPs = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_SELECTED_APPS',
  requestParams: ['userId'],
  operation: userId => AspireAPI.get(`v1/user/${userId}/app/scheduling`),
  transform: transformSelectedAPPs,
  messages: {
    failed: 'There was an issue fetching your selected APPs',
  },
})

export const saveSelectedAPPs = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_SELECTED_APPS',
  requestParams: ['userId', 'selectedAPPs'],
  operation: (userId, selectedAPPs) =>
    AspireAPI.put(`v1/user/${userId}/app/scheduling`, {
      npList: selectedAPPs,
    }),
  messages: {
    succeeded: 'Successfully saved selected APPs',
    failed: 'There was an issue saving your selected APPs',
  },
})

const initState = Set()
export default createReducer(SELECTED_APPS, initState, {
  [fetchSelectedAPPs.REQUESTED]: () => initState,
  [fetchSelectedAPPs.SUCCEEDED]: (_state, { payload }) => payload,
  [saveSelectedAPPs.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => payload.selectedAPPs,
})

export const getSelectedAPPs = pipe(getRoot, get(SELECTED_APPS))
