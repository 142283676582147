import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request from '~/utils/Request'
import key from '../key'

export const updatePlaceOfService = Request({
  typePrefix: key,
  typeBase: 'UPDATE_PLACE_OF_SERVICE',
  requestParams: ['encounterId', 'placeOfService'],
  operation: (encounterId: number, placeOfService: string) =>
    AspireAPI.patch(`coding_dashboard/${encounterId}`, {
      place_of_service: placeOfService,
    }),
  messages: {
    succeeded: 'Place of service updated successfully',
    failed: 'There was an issue updating the place of service',
  },
})
