import { combineEpics, ofType } from 'redux-observable'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  ORDERS_FETCH_REQUESTED,
  fetchPatientOrders,
  fetchUserOrders,
  ordersFetchSucceeded,
} from '../data/orders'

const userRefresh = action$ =>
  action$.pipe(
    ofType(ORDERS_FETCH_REQUESTED),
    pluck('payload', 'userId'),
    filter(Boolean),
    mergeMap(userId =>
      loading(
        fetchUserOrders(userId).then(ordersFetchSucceeded),
        'Problem loading orders'
      )
    )
  )
const patientRefresh = action$ =>
  action$.pipe(
    ofType(ORDERS_FETCH_REQUESTED),
    pluck('payload', 'patientId'),
    filter(Boolean),
    mergeMap(patientId =>
      loading(
        fetchPatientOrders(patientId).then(ordersFetchSucceeded),
        'Problem loading orders'
      )
    )
  )

export default combineEpics(userRefresh, patientRefresh)
