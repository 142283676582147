import cx from 'classnames'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import getRiskLevel from '../utils/riskLevelValues'

const styles = ({ palette }) => ({
  withScore: {
    color: ({ isCaremoreBrand, score }) => {
      const finalScore = score === null ? 4 : score
      return isCaremoreBrand
        ? palette.caremoreRiskLevel[finalScore]
        : palette.aspireRiskLevel[finalScore]
    },
    marginRight: 10,
  },
})

const RiskLevelIcon = ({
  isCaremoreBrand,
  score,
  classes,
  className,
  overview,
}) => {
  const { icon, overviewIcon } = getRiskLevel(isCaremoreBrand, score)

  return (
    <Icon
      classes={{
        root: cx(className, {
          [classes.withScore]:
            (isCaremoreBrand, Boolean(score) || score === null),
          [classes.withoutScore]: !score,
        }),
      }}
    >
      {overview ? overviewIcon : icon}
    </Icon>
  )
}

RiskLevelIcon.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  overview: PropTypes.bool,
  isCaremoreBrand: PropTypes.bool,
}

RiskLevelIcon.defaultProps = {
  className: '',
  score: 0,
}

export default withStyles(styles)(RiskLevelIcon)
