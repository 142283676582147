import { clamp } from 'lodash'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CommandItem from './CommandItem'

export const fuzzySearch = (
  search,
  options,
  getOptionValue = option => option
) => {
  const searchText = search.trim().toLowerCase()
  if (!searchText) return options

  const words = searchText.split(' ').map(word => word.trim())
  return options.filter(option => {
    const value = getOptionValue(option).toLowerCase()
    return words.every(word => value.includes(word))
  })
}

const styles = {
  search: {
    margin: '0 0 1rem',
  },
  dialog: {
    padding: '1rem',
  },
  list: {
    height: 300,
    overflowY: 'auto',
  },
}

export class CommandPalette extends React.Component {
  state = {
    searchText: '',
    selectedCommand: null,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  moveSelection(offset) {
    this.setState(({ selectedCommand }) => {
      const { filteredCommands } = this
      const curIdx = filteredCommands.indexOf(selectedCommand)
      const nxtIdx = clamp(curIdx + offset, 0, filteredCommands.length - 1)

      return { selectedCommand: filteredCommands[nxtIdx] }
    })
  }

  get filteredCommands() {
    return fuzzySearch(
      this.state.searchText,
      this.props.commands,
      command => command.label
    )
  }

  onKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown':
        this.moveSelection(1)
        break
      case 'ArrowUp':
        this.moveSelection(-1)
        break
      case 'Enter':
        if (this.state.selectedCommand) {
          this.props.onRunCommand(this.state.selectedCommand)
        }
        break
    }
  }

  onSearchChange = e => {
    this.setState({ searchText: e.target.value }, () => {
      // reset selection incase the currently selected value is no longer
      // present in the filtered results.
      this.moveSelection(0)
    })
  }

  render() {
    const { classes, onClose, onRunCommand } = this.props
    const { searchText, selectedCommand } = this.state

    return (
      <Dialog open fullWidth onClose={onClose}>
        <div className={classes.dialog}>
          <TextField
            InputProps={{ className: classes.search }}
            fullWidth
            autoFocus
            value={searchText}
            onChange={this.onSearchChange}
            placeholder="Start typing a command..."
          />
          <List className={classes.list} dense>
            {this.filteredCommands.map((command, idx) => (
              <CommandItem
                key={idx}
                command={command}
                onClick={onRunCommand}
                isSelected={command === selectedCommand}
              />
            ))}
          </List>
        </div>
      </Dialog>
    )
  }
}

CommandPalette.propTypes = {
  commands: PropTypes.array.isRequired,
  onRunCommand: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(CommandPalette)
