import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const PRACTICES = 'practices'

export const Practice = Record({
  id: null,
  name: null,
  practiceNpi: null,
  phone: null,
  fax: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  callNotes: null,
  managedStatus: null,
  relationshipStatus: null,
  owner: null,
  ceoAdministrator: null,
  nursingLeader: null,
  physicianLeader: null,
  champion: null,
  groupId: null,
  group: null,
  ipaId: null,
  ipa: null,
  notes: null,
  nextTargetedContactDate: null,
})

const transformPractice = ({ group, ipa, ...practice }) =>
  Practice({
    ...practice,
    groupId: getIn(group, 'id'),
    group: getIn(group, 'name'),
    ipaId: getIn(ipa, 'id'),
    ipa: getIn(ipa, 'name'),
  })

export const fetchPractices = Request({
  typePrefix: key,
  typeBase: 'FETCH_PRACTICES',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/practices`, {
      params: { searchWord },
    }),
  transform: into(Practice, 'id'),
  messages: { failed: 'There was a problem fetching practices' },
})

export const fetchPractice = Request({
  typePrefix: key,
  typeBase: 'FETCH_PRACTICE',
  requestParams: ['practiceId'],
  operation: practiceId => AspireAPI.get(`contacts/practices/${practiceId}`),
  transform: transformPractice,
  messages: { failed: 'There was a problem fetching this practice' },
})

export const addPractice = Request({
  typePrefix: key,
  typeBase: 'ADD_PRACTICE',
  requestParams: ['practice'],
  operation: practice => AspireAPI.post('contacts/practices', { practice }),
  messages: {
    succeeded: 'Successfully added new practice',
    failed: 'There was a problem adding this practice',
  },
})

export const savePractice = Request({
  typePrefix: key,
  typeBase: 'SAVE_PRACTICE',
  requestParams: ['practice'],
  operation: practice =>
    AspireAPI.put(`contacts/practices/${practice.id}`, { practice }),
  messages: {
    succeeded: 'Successfully saved practice',
    failed: 'There was a problem saving this practice',
  },
})

export const deletePractice = Request({
  typePrefix: key,
  typeBase: 'DELETE_PRACTICE',
  requestParams: ['practiceId'],
  operation: practiceId => AspireAPI.delete(`contacts/practices/${practiceId}`),
  messages: {
    succeeded: 'Successfully deleted practice',
    failed: 'There was a problem deleting this practice',
  },
})

const creator = scopedCreator(key)
export const practicesCleared = creator('PRACTICES_CLEARED')

const initState = Map()
export default createReducer(PRACTICES, initState, {
  [fetchPractices.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchPractice.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [savePractice.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { practice },
        },
      },
    }
  ) => state.set(practice.id, practice),
  [deletePractice.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { practiceId },
        },
      },
    }
  ) => state.delete(practiceId),
  [practicesCleared]: () => initState,
})

export const getPractices = pipe(getRoot, get(PRACTICES))

export const getPracticesArr = createSelector([getPractices], practices =>
  practices.toIndexedSeq().toArray()
)

export const getPracticeById = (state, id) =>
  getPractices(state).get(id, Practice())
