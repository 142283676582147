// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import { PATIENT_FILTERS } from './root'
import selected from './selected'
import stored from './stored'
import values from './values'

const reducer = combineReducers({
  [stored.key]: stored,
  [selected.key]: selected,
  [values.key]: values,
})

reducer.key = PATIENT_FILTERS

export default reducer

export { filtersLoadedFromStorage, filtersCleared } from './root'
export * from './stored'
export * from './selected'
export * from './values'
