import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import { fetchPatientOutlierReviewHistory } from '../data/outlierReviewHistory'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_OUTLIER_REVIEWS } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_OUTLIER_REVIEWS)),
    switchTo(state$),
    map(getPatientId),
    map(fetchPatientOutlierReviewHistory.requested)
  )
