import { intersection } from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getFieldValues } from '~/data/fieldValues'
import { getUserById } from '~/data/users'
import { patientInfoRequested } from '~/features/panelContent/epics/loadPatientInfoForPanel'
import PropTypes from '~/utils/propTypes'
import { AppBar, Badge, Tab, Tabs } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import ActionDialog from '../../components/ActionDialog'
import OrderTable from '../../components/OrderTable'
import { applyActionRequested } from '../../data/orderDetail'
import { orderPreview } from '../../data/orderPreview'
import {
  getAllHospiceOrdersAsJS,
  getAllOrdersAsJS,
  getCurrentlyAssignedOrdersAsJS,
  getNonAssignedOrdersAsJS,
  ordersFetchRequested,
} from '../../data/orders'
import { orderSelected } from '../../data/selectedOrder'
import SuccessContainer from '../SuccessContainer'

const styles = ({ palette, spacing }) => ({
  dashboardContainer: {
    overflow: 'scroll',
    padding: spacing(1),
  },
  card: {
    padding: 2 * spacing(1),
    overflow: 'auto',
  },
  badge: {
    paddingRight: spacing(2),
  },
  tabSelected: {
    backgroundColor: palette.tabSelected.backgroundColor,
    fontWeight: 'bold',
  },
  order: {
    float: 'left',
  },
  pendingOrders: {
    float: 'right',
    padding: '8px',
    marginLeft: '15px',
  },
  avatar: {
    float: 'right',
    padding: '8px',
    marginLeft: '15px',
    width: 35,
    height: 35,
  },
  orangeAvatar: {
    backgroundColor: palette.secondary.dark,
    float: 'right',
    padding: '8px',
    marginLeft: '15px',
    width: 35,
    height: 35,
  },
  createOrder: {
    float: 'right',
    padding: '8px',
    marginTop: '15px',
  },
  createOrderButton: {
    float: 'left',
    width: 55,
    height: 55,
  },
})

class OrdersGrid extends PureComponent {
  state = {
    showActionDialog: false,
    orderId: null,
    action: null,
    filter: 'mine',
  }

  componentDidMount() {
    const { ordersContext } = this.props
    ordersContext === 'patient' && this.setState({ filter: 'all' })
  }

  onTabChange = (_event, value) => this.setState({ filter: value })

  onAction = (id, action) => {
    action.requireReason || action.requireConfirmation
      ? this.setState({
          showActionDialog: true,
          orderId: id,
          action: action,
        })
      : this.props.onAction(id, action)
  }

  onClose = () => {
    this.setState({
      showActionDialog: false,
      orderId: null,
      action: null,
    })
  }

  onConfirmAction = (id, action, cancelReason) => {
    this.onClose()
    this.props.onAction(id, action, cancelReason)
  }

  render() {
    const {
      allOrders,
      assignedOrders,
      cancellationReasons,
      classes,
      ordersContext,
      hospiceCancellationReasons,
      hospiceOrders,
      nonAssignedOrders,
      onPanelOpen,
      onSelectOrder,
      route,
      patientId,
      previewOrderPdf,
      refreshOrders,
      userId,
      userInfo,
    } = this.props

    const { filter } = this.state

    const orders =
      this.state.filter == 'all'
        ? allOrders
        : filter == 'mine'
        ? assignedOrders
        : filter == 'hospice'
        ? hospiceOrders
        : nonAssignedOrders

    const useHospiceReasons =
      this.state.action &&
      this.state.action.reasonKey === 'hospice_order_cancellation_reasons'

    const showHospiceTab =
      intersection(userInfo.careTeamRoles, ['nss', 'hospice_nss', 'hospice_rn'])
        .length > 0

    return (
      <div>
        {ordersContext !== 'patient' && (
          <AppBar position="static">
            <Tabs value={filter} onChange={this.onTabChange}>
              <Tab
                classes={{ selected: classes.tabSelected }}
                label={
                  <Badge
                    className={classes.badge}
                    color="secondary"
                    badgeContent={allOrders.length}
                  >
                    All
                  </Badge>
                }
                value="all"
              />
              <Tab
                classes={{ selected: classes.tabSelected }}
                label={
                  <Badge
                    className={classes.badge}
                    color="secondary"
                    badgeContent={assignedOrders.length}
                  >
                    Mine
                  </Badge>
                }
                value="mine"
              />
              <Tab
                classes={{ selected: classes.tabSelected }}
                label={
                  <Badge
                    className={classes.badge}
                    color="secondary"
                    badgeContent={nonAssignedOrders.length}
                  >
                    Others
                  </Badge>
                }
                value="others"
              />
              {showHospiceTab && (
                <Tab
                  classes={{ selected: classes.tabSelected }}
                  label={
                    <Badge
                      className={classes.badge}
                      color="secondary"
                      badgeContent={hospiceOrders.length}
                    >
                      Hospice
                    </Badge>
                  }
                  value="hospice"
                />
              )}
            </Tabs>
          </AppBar>
        )}
        <Paper className={classes.dashboardContainer}>
          <Grid container spacing={1}>
            <OrderTable
              orders={orders}
              onAction={this.onAction}
              selectOrder={onSelectOrder}
              userId={userId}
              onPanelOpen={onPanelOpen}
              route={route}
              refresh={refreshOrders}
              ordersContext={ordersContext}
              patientId={patientId}
              previewOrderPdf={previewOrderPdf}
            />
          </Grid>
          <ActionDialog
            open={this.state.showActionDialog}
            onClose={this.onClose}
            onConfirm={this.onConfirmAction}
            action={this.state.action}
            id={this.state.orderId}
            reasons={
              useHospiceReasons
                ? hospiceCancellationReasons
                : cancellationReasons
            }
          />
          <SuccessContainer />
        </Paper>
      </div>
    )
  }
}

OrdersGrid.propTypes = {
  allOrders: PropTypes.array.isRequired,
  assignedOrders: PropTypes.array.isRequired,
  cancellationReasons: PropTypes.object,
  classes: PropTypes.object.isRequired,
  ordersContext: PropTypes.string.isRequired,
  hospiceCancellationReasons: PropTypes.object,
  hospiceOrders: PropTypes.array.isRequired,
  nonAssignedOrders: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  onPanelOpen: PropTypes.func.isRequired,
  onSelectOrder: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  previewOrderPdf: PropTypes.func,
  refreshOrders: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userInfo: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => ({
  assignedOrders: getCurrentlyAssignedOrdersAsJS(state),
  nonAssignedOrders: getNonAssignedOrdersAsJS(state),
  allOrders: getAllOrdersAsJS(state),
  hospiceOrders: getAllHospiceOrdersAsJS(state),
  hospiceCancellationReasons: getFieldValues(
    'hospice_order_cancellation_reasons'
  )(state),
  cancellationReasons: getFieldValues('order_cancellation_reasons')(state),
  userInfo: getUserById(state, ownProps.userId),
})

const mapDispatchToProps = dispatch => ({
  onSelectOrder: id => dispatch(orderSelected(id)),
  onAction: (id, action, cancelReason) =>
    dispatch(applyActionRequested(id, action, cancelReason)),
  onPanelOpen: patientId => dispatch(patientInfoRequested(patientId)),
  refreshOrders: (userId, patientId) =>
    dispatch(ordersFetchRequested(userId, patientId)),
  previewOrderPdf: id => dispatch(orderPreview.requested(id)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OrdersGrid)
