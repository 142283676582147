import { Map } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'

const key = 'appLayout'

const creator = scopedCreator(key)

export const panelOpened = creator('PANEL_OPENED', false)
export const panelClosed = creator('PANEL_CLOSED', false)
export const panelToggled = creator('PANEL_TOGGLED', false)
export const drawerToggled = creator('DRAWER_TOGGLED')

const defaultState = Map({
  panelOpen: false,
  drawerOpen: false,
})

export default createReducer(key, defaultState, {
  [panelOpened]: state => state.set('panelOpen', true),
  [panelClosed]: state => state.set('panelOpen', false),
  [panelToggled]: state => state.update('panelOpen', open => !open),
  [drawerToggled]: (state, { payload: value }) =>
    value === undefined
      ? state.update('drawerOpen', open => !open)
      : state.set('drawerOpen', Boolean(value)),
})

const getAppLayoutState = get(key)

export const isPanelOpen = pipe(getAppLayoutState, get('panelOpen'))

export const isDrawerOpen = pipe(getAppLayoutState, get('drawerOpen'))
