import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { patientOutlierPanelOpened } from '~/data/patientPanel'
import { fetchPatientOutlierFinalDecision } from '../data/currentPatientOutlierFinalDecision'

export default (action$: any) =>
  action$.pipe(
    ofType(fetchPatientOutlierFinalDecision.SUCCEEDED),
    pluck('payload'),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ patientId, patientOutlierRevi... Remove this comment to see the full error message
    map(({ patientId, patientOutlierReviewId }) =>
      patientOutlierPanelOpened(patientId, patientOutlierReviewId)
    )
  )
