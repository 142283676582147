import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { mapTo } from 'rxjs/operators'
import { createUser, saveUser } from '../data/currentUser'
import { ADMIN_TOOLS_USERS } from '../router'

export default (action$: any) =>
  action$.pipe(
    ofType(createUser.SUCCEEDED, saveUser.SUCCEEDED),
    mapTo(push(ADMIN_TOOLS_USERS))
  )
