import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import { CURRENT_YEAR } from '../data/constants'
import { getCurrentPatient } from '../data/currentPatient'
import { fetchNotes, resetNotes } from '../data/memberInfo'
import { saveMemberNoteEntry } from '../data/memberNoteDialog'

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const refreshPatientInfo = (action$, state$) =>
  action$.pipe(
    ofType(saveMemberNoteEntry.SUCCEEDED),
    pluck('payload'),
    switchTo(state$),
    map(getCurrentPatient),
    map((patient: any) => {
      const requestParams = {
        benefitYear: CURRENT_YEAR,
        classplanProductId: patient?.classplanProductId,
        eligibilityEffectiveDate: patient?.eligibilityEffectiveDate,
        productDescription: patient?.productDescription,
      }
      resetNotes()
      return fetchNotes.requested(patient?.patientId, requestParams)
    })
  )
