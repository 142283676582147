import React from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from './TextField'

const inputLabelProps = { shrink: true }

const transformIn = value =>
  value ? value.replace(/:\d{2}(?:\.\d+)?Z?$/, '') : ''

const transformOut = value => value + ':00'

class TimeField extends React.Component {
  onChange = (value, e) => this.props.onChange(value && transformOut(value), e)

  onBlur = (value, e) => this.props.onBlur(value && transformOut(value), e)

  render = () => {
    const { onChange, onBlur, value } = this.props

    return (
      <TextField
        {...this.props}
        InputLabelProps={inputLabelProps}
        onBlur={onBlur && this.onBlur}
        onChange={onChange && this.onChange}
        type="time"
        value={value && transformIn(value)}
      />
    )
  }
}

TimeField.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default TimeField
