import React from 'react'
import DisplayDate from '~/components/DisplayDate'
import Info from '~/components/text/Info'
import PropTypes from '~/utils/propTypes'
import { splitOnNewLines, titleCase } from '~/utils/stringManipulation'
import { withStyles } from '@material-ui/core/styles'
import PatientLink from './PatientLink'
import Priority from './Priority'

const styles = ({ spacing }) => ({
  section: {
    paddingBottom: spacing(1),
    '& span p': {
      margin: 0,
    },
  },
})

// Using an index for a key is generally frowned upon, however the results here are static.
const lines = (line, i) => <p key={i}>{line}</p>

class TaskInfo extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    task: PropTypes.record.isRequired,
  }

  render() {
    const { classes, task: t } = this.props
    const { requestedUser, modifiedUser, patient, ownerUser } = t

    return (
      <section className={classes.section}>
        <Info label="Patient">
          <PatientLink patientId={patient.id}>{patient.name}</PatientLink>
        </Info>
        <Info label="Description">
          {splitOnNewLines(t.description).map(lines)}
        </Info>
        <Info label="Due Date">
          <DisplayDate value={t.dueOn} />
        </Info>
        <Info label="Status">{titleCase(t.status)}</Info>
        {Boolean(t.completedOn) && (
          <Info label="Completed On">
            <DisplayDate value={t.completedOn} />
          </Info>
        )}
        <Info label="Priority">
          <Priority completedOn={t.completedOn} priority={t.priority} />
        </Info>
        <Info label="Assignee">
          {ownerUser.name}
          {ownerUser.role && ` - ${ownerUser.role}`}
        </Info>
        <Info label="Created By">{requestedUser.name}</Info>
        <Info label="Created Date">
          <DisplayDate value={t.startOn} />
        </Info>
        <Info label="Modified By">{modifiedUser.name}</Info>
        <Info label="Modified Date">
          <DisplayDate value={t.modifiedAt} />
        </Info>
      </section>
    )
  }
}

export default withStyles(styles)(TaskInfo)
