import cx from 'classnames'
import React from 'react'
import { Button, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
    minHeight: 38,
  },
  title: {
    alignSelf: 'center',
  },
  children: {
    alignSelf: 'center',
    flex: 1,
    textAlign: 'right',
    '& > *': {
      marginRight: spacing(2),
    },
    '& span > button': {
      padding: 0,
    },
  },
  noButton: {
    '& > *:last-child': {
      marginRight: 0,
    },
  },
}))

type Props = {
  title: string
  children?: React.ReactNode
  action?: any
  buttonText?: string
  disabled?: boolean
  hideTitle?: boolean
}

export default function DevXTitleHeader({
  action,
  buttonText,
  disabled = false,
  children,
  hideTitle = false,
  title,
}: Props) {
  const classes = useStyles()

  return (
    <div className={classes.flexContainer}>
      {!hideTitle && (
        <Typography className={classes.title} color="primary" variant="h5">
          {title}
        </Typography>
      )}

      {/* All children individually provided will have right margin applied, unless specifically overridden in the source component styling; to handle multiple buttons or chips as a set, put them in a span  */}
      {children && (
        <span
          className={cx(classes.children, { [classes.noButton]: !buttonText })}
        >
          {children}
        </span>
      )}

      {action && buttonText && (
        <Button variant="outlined" onClick={action} disabled={disabled}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
