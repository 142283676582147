import { List, Record } from 'immutable'
import moment from 'moment'
import { createSelector } from 'reselect'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'
import { startAssessment } from '~/apps/assessment/data/assessments'
import { isRequestPending } from '~/data/pending'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { scopedCreator } from '~/utils/data'
import loading from '~/utils/loading'
import rootKey from '../key'
import {
  checkIntoEncounter,
  checkOutOfEncounter,
  getRoot,
} from './common/shared'

// KEY
const key = 'dailyEvents'

// RECORDS
const Contact = Record({
  patientId: null,
  effectiveEndDate: null,
  expired: null,
  active: null,
  name: null,
  phiMessageApproved: null,
  lastModifiedDate: null,
  hipaaAuthExpired: null,
  phoneDescription: null,
  phiContact: null,
  phoneNumber: null,
  isPreferred: null,
  relationship: null,
  type: null,
})

const Alert = Record({
  createdAt: null,
  severity: null,
  description: null,
})

const Order = Record({
  taskId: null,
  orderId: null,
  id: null,
  type: null,
  subtype: null,
  subtypeOther: null,
  deliveryDate: null,
  createdDate: null,
  isOverdue: null,
  orderInstructions: null,
  lengthOfNeed: null,
  vendor: null,
  justification: null,
  notes: null,
  internalNotes: null,
  fulfillmentStatus: null,
  faxDate: null,
})

const Patient = Record({
  patientId: null,
  healthplan: null,
  physician: null,
  name: null,
  visitInstructions: null,
  status: null,
  location: null,
  dob: null,
  clinicalDirector: null,
  market: null,
  appId: null,
  openOrders: List(),
  contacts: List(),
  alerts: List(),
})

export const DailyEvent = Record({
  start: null,
  patient: Patient(),
  prepComplete: null,
  caseId: null,
  subject: null,
  ownerId: null,
  location: null,
  locationExact: false,
  title: null,
  type: null,
  end: null,
  id: null,
  allDay: null,
  description: null,
  confirmed: null,
  encounterId: null,
  encounterType: null,
  encounterTypeLabel: null,
  encounterTypeModality: null,
  encounterTypePhase: null,
  encounterCheckInAt: null,
  encounterCheckOutAt: null,
  assessmentStatus: null,
})

const transformContacts = contacts =>
  contacts ? List(contacts.map(c => Contact(c))) : List()

const transformAlerts = alerts =>
  alerts ? List(alerts.map(a => Alert(a))) : List()

const transformOrders = orders =>
  orders ? List(orders.map(o => Order(o))) : List()

const transformPatient = ({ openOrders, contacts, alerts, ...patient }) =>
  Patient({
    ...patient,
    openOrders: transformOrders(openOrders),
    contacts: transformContacts(contacts),
    alerts: transformAlerts(alerts),
  })

const transformEvent = ({
  patient,
  encounterCheckInAt,
  encounterCheckOutAt,
  ...event
}) =>
  DailyEvent({
    ...event,
    patient: transformPatient(patient),
    encounterCheckInAt:
      encounterCheckInAt && new Date(`${encounterCheckInAt}Z`),
    encounterCheckOutAt:
      encounterCheckOutAt && new Date(`${encounterCheckOutAt}Z`),
  })

const mapData = events =>
  List(events.map(transformEvent)).sort((a, b) =>
    a.get('start', '') === null || b.get('start', '') === null
      ? 1
      : b.get('start', '').localeCompare(a.get('start', ''))
  )

// ASYNC
const fetchEvents = app_id =>
  AspireAPI.get('dashboard/daily_event_list', {
    params: { app_id },
  })

export const logEventPrep = event_id =>
  AspireAPI.post('dashboard/log_event_prep', { event_id })

export const fetchDailyEvents = appId =>
  loading(
    from(fetchEvents(appId)).pipe(map(fetchedDailyEvents)),
    'Problem loading events.'
  )

// ACTION CREATORS
const creator = scopedCreator(rootKey)
const fetchedDailyEvents = creator('FETCHED_DAILY_EVENTS', ['events'])
export const requestLogEventPrep = creator('REQUEST_LOG_PREP', ['eventId'])
export const logPrepCompleted = creator('LOG_PREP_COMPLETED', ['eventId'])

// REDUCER
const initState = List()

export default createReducer(key, initState, {
  [fetchedDailyEvents]: (state, { payload }) => mapData(payload.events), // FromJS supports nested objects
  [logPrepCompleted]: (state, { payload }) =>
    state.map(event =>
      event.get('id') == payload.eventId
        ? event.set('prepComplete', true)
        : event
    ),
  [checkIntoEncounter.SUCCEEDED]: (state, { meta }) => {
    const index = state.findIndex(
      event => event.encounterId === meta.request.payload.encounterId
    )

    return index > -1
      ? state.update(index, event =>
          event.merge({
            encounterCheckInAt: new Date(meta.request.payload.checkInAt),
            encounterCheckOutAt: null,
          })
        )
      : state
  },
  [checkOutOfEncounter.SUCCEEDED]: (state, { meta }) => {
    const index = state.findIndex(
      event => event.encounterId === meta.request.payload.encounterId
    )

    return index > -1
      ? state.update(index, event =>
          event.set(
            'encounterCheckOutAt',
            new Date(meta.request.payload.checkOutAt)
          )
        )
      : state
  },
  [startAssessment.SUCCEEDED]: (
    state,
    { meta, payload: { id, status: assessmentStatus } }
  ) =>
    state.update(
      state.findIndex(event => event.id === meta.request.payload.eventId),
      event => event.merge({ encounterId: id, assessmentStatus })
    ),
})

// SELECTORS
export const getDailyEvents = state => getRoot(state).get(key)

export const getTodaysEvents = createSelector([getDailyEvents], events =>
  events.filter(event => {
    const today = moment().format('YYYYMMDD')
    const startDay = moment.utc(event.get('start')).local().format('YYYYMMDD')

    return startDay === today
  })
)

export const getUpcomingEvents = createSelector([getDailyEvents], events =>
  events.filter(event => {
    const today = moment().format('YYYYMMDD')
    const startDay = moment.utc(event.get('start')).local().format('YYYYMMDD')

    return startDay > today
  })
)

export const getPastEvents = createSelector([getDailyEvents], events =>
  events.filter(event => {
    const today = moment().format('YYYYMMDD')
    const startDay = moment.utc(event.get('start')).local().format('YYYYMMDD')

    return startDay < today
  })
)

export const isStartingAssessment = state =>
  isRequestPending(state, startAssessment)
