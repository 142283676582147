import { Map, Record } from 'immutable'
import { pick } from 'lodash'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const key = 'patientERVisits'
const manual_key = id => 'Manual_' + id.toString()

export const ERVisit = Record({
  id: null,
  key: null,
  serviceDate: null,
  source: null,
  facilityId: null,
  facilityName: null,
  diagnosisCode: null,
  diagnosisDescription: null,
  dischargeDate: null,
  dischargeDisposition: null,
  outOfNetwork: null,
  highUtilizerFlag: null,
  highUtilizerValue: null,
  notes: null,
  createdAt: null,
  createdBy: null,
  cancelledAt: null,
  cancelledBy: null,
  modifiedAt: null,
  modifiedBy: null,
  type: null,
  edhSaved: null,
  ascFlag: null,
  paerFlag: null,
  chronicFlag: null,
  metalTier: null,
})

export const addERVisit = Request({
  typePrefix: key,
  typeBase: 'ADD_ER_VISIT',
  requestParams: ['patientId', 'erVisit'],
  operation: (patientId, erVisit) =>
    AspireAPI.post(`er_visits/${patientId}`, { erVisit }),
  transform: ERVisit,
  messages: {
    succeeded: 'Sucessfully added ER Visit',
    failed: 'There was a problem adding this ER Visit',
  },
})

export const fetchERVisits = Request({
  typePrefix: key,
  typeBase: 'FETCH_ER_VISITS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`er_visits/patient/${patientId}`),
  transform: into(ERVisit, 'key'),
  messages: { failed: 'There was a problem fetching Active ER Visits' },
})

export const fetchCancelledERVisits = Request({
  typePrefix: key,
  typeBase: 'FETCH_CANCELLED_ER_VISITS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`er_visits/patient/${patientId}/cancelled`),
  transform: into(ERVisit, 'key'),
  messages: { failed: 'There was a problem fetching Cancelled ER Visits' },
})

export const saveERVisit = Request({
  typePrefix: rootKey,
  typeBase: 'UPDATE_ER_VISIT',
  requestParams: ['erVisit'],
  operation: erVisit => AspireAPI.put(`er_visits/${erVisit.id}`, { erVisit }),
  messages: {
    succeeded: 'Successfully saved this ER Visit',
    failed: 'Could not save this ER Visit',
  },
})

export const cancelERVisit = Request({
  typePrefix: rootKey,
  typeBase: 'CANCEL_ER_VISIT',
  requestParams: ['id'],
  operation: id => AspireAPI.put(`er_visits/${id}/cancel`),
  messages: {
    succeeded: 'Successfully canceled this ER Visit',
    failed: 'Could not cancel this ER Visit',
  },
})

export default createReducer(key, Map(), {
  [addERVisit.SUCCEEDED]: (state, { payload }) =>
    state.set(
      manual_key(payload.id),
      ERVisit(payload).set('key', manual_key(payload.id)).set('type', 'Manual')
    ),
  [cancelERVisit.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.delete(manual_key(payload.id)),
  [fetchERVisits.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchCancelledERVisits.SUCCEEDED]: (_state, { payload }) => payload,
  [saveERVisit.SUCCEEDED]: (state, { payload, meta }) =>
    state.update(manual_key(payload.id), erVisit =>
      erVisit
        .merge(meta.request.payload.erVisit)
        .merge(pick(payload, ['modifiedAt', 'modifiedBy', 'edhSaved']))
    ),
})

export const getERVisits = pipe(get(rootKey), get(key))
