import AvailabilityDefaults from './Availability/defaults'
import AvailabilityInfo from './Availability/info'
import IncentiveDayDefaults from './IncentiveDay/defaults'
import IncentiveDayInfo from './IncentiveDay/info'
import OnCallDefaults from './OnCall/defaults'
import OnCallInfo from './OnCall/info'
import SchedulingGuidanceDefaults from './SchedulingGuidance/defaults'
import SchedulingGuidanceInfo from './SchedulingGuidance/info'
import UnavailabilityDefaults from './Unavailability/defaults'
import UnavailabilityInfo from './Unavailability/info'
import VisitDefaults from './Visit/defaults'
import VisitInfo from './Visit/info'
import VisitPlaceholderDefaults from './VisitPlaceholder/defaults'
import VisitPlaceholderInfo from './VisitPlaceholder/info'

export const eventTypeDefaults = {
  [AvailabilityDefaults.type]: AvailabilityDefaults,
  [IncentiveDayDefaults.type]: IncentiveDayDefaults,
  [OnCallDefaults.type]: OnCallDefaults,
  [SchedulingGuidanceDefaults.type]: SchedulingGuidanceDefaults,
  [UnavailabilityDefaults.type]: UnavailabilityDefaults,
  [VisitDefaults.type]: VisitDefaults,
  [VisitPlaceholderDefaults.type]: VisitPlaceholderDefaults,
}

export const eventTypeInfo = {
  [AvailabilityDefaults.type]: AvailabilityInfo,
  [IncentiveDayDefaults.type]: IncentiveDayInfo,
  [OnCallDefaults.type]: OnCallInfo,
  [SchedulingGuidanceDefaults.type]: SchedulingGuidanceInfo,
  [UnavailabilityDefaults.type]: UnavailabilityInfo,
  [VisitDefaults.type]: VisitInfo,
  [VisitPlaceholderDefaults.type]: VisitPlaceholderInfo,
}

export const recordTypeToEventType = {
  [AvailabilityDefaults.recordType]: AvailabilityDefaults.type,
  [IncentiveDayDefaults.recordType]: IncentiveDayDefaults.type,
  [OnCallDefaults.recordType]: OnCallDefaults.type,
  [SchedulingGuidanceDefaults.recordType]: SchedulingGuidanceDefaults.type,
  [UnavailabilityDefaults.recordType]: UnavailabilityDefaults.type,
  [VisitDefaults.recordType]: VisitDefaults.type,
  [VisitPlaceholderDefaults.recordType]: VisitPlaceholderDefaults.type,
}
