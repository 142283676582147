import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot, patientCleared } from './common/shared'

const CURRENT_PATIENT_ID = 'currentPatientId'

const creator = scopedCreator(key)
export const patientChanged = creator('PATIENT_CHANGED', ['patientId'])

const initState = null
export default createReducer(CURRENT_PATIENT_ID, initState, {
  [patientCleared]: () => initState,
  [patientChanged]: (_state, { payload: { patientId } }) => patientId,
})

export const getCurrentPatientId = pipe(getRoot, get(CURRENT_PATIENT_ID))
