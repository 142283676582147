import { connect } from 'react-redux'
import TasksDashboard from '../components/TasksDashboard'
import {
  getPatientTasksAsArray,
  taskCollapsed,
  taskExpanded,
} from '../data/tasks'

const mapStateToProps = state => ({
  tasks: getPatientTasksAsArray(state),
  tasksContext: 'patient',
})

export default connect(mapStateToProps, {
  taskCollapsed,
  taskExpanded,
})(TasksDashboard)
