import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

export const BATCH_DETAILS = 'batchDetails'

const BATCH_DETAILS_CLEARED = type(key, 'BATCHES_CLEARED')

const creator = scopedCreator(key)
export const batchDetailsCleared = creator(BATCH_DETAILS_CLEARED)

const BatchDetail = Record({
  batchDate: null,
  batchId: null,
  batchResponseUrl: null,
  claimId: null,
  configName: null,
  description: null,
  details: {
    batchId: null,
    caseId: null,
    caseNumber: null,
    codes: null,
    dateOfService: null,
    insuranceAccountNumber: null,
    patientName: null,
  },
  status: null,
  title: null,
})

export const fetchBatchDetails = Request({
  typePrefix: key,
  typeBase: 'FETCH_BATCH_DETAILS',
  requestParams: ['batchId'],
  operation: (batchId: number) =>
    AspireAPI.get(`claims/batch_details/${batchId}`),
  transform: BatchDetail,
  messages: {
    failed: 'There was an issue fetching batch details',
  },
})

export const getBatchDetails = pipe(getRoot, get(BATCH_DETAILS))

const initState = List()

export default createReducer(BATCH_DETAILS, initState, {
  // @ts-expect-error fix me
  [batchDetailsCleared]: () => initState,
  [fetchBatchDetails.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
