// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import appList from './data/appList'
import currentAPPId from './data/currentAPPId'
import currentPatientId from './data/currentPatientId'
import currentUserId from './data/currentUserId'
import patientFilters from './data/patientFilters'
import patientList from './data/patientList'
import patientSnoozeReasons from './data/patientSnoozeReasons'
import pendingSelectedAPPs from './data/pendingSelectedAPPs'
import userSettings from './data/userSettings'
import key from './key'

const reducer = combineReducers({
  [appList.key]: appList,
  [currentAPPId.key]: currentAPPId,
  [currentPatientId.key]: currentPatientId,
  [currentUserId.key]: currentUserId,
  [patientFilters.key]: patientFilters,
  [patientList.key]: patientList,
  [patientSnoozeReasons.key]: patientSnoozeReasons,
  [pendingSelectedAPPs.key]: pendingSelectedAPPs,
  [userSettings.key]: userSettings,
})

reducer.key = key

export default reducer
