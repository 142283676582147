// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'vaMemberDialog'

export const getDialogOpenValue = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const vaMemberDialogOpened = creator('VA_MEMBER_DIALOG_OPENED')
export const vaMemberDialogClosed = creator('VA_MEMBER_DIALOG_CLOSED')

// REDUCER
export const vaMemberDialog = createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [vaMemberDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [vaMemberDialogOpened]: () => true,
})
