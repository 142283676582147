import React, { ChangeEvent, useMemo } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

type EnumOption = { label: string; value: string }

export interface ISelectSearchProps {
  value: string | string[] | null
  onChange(value: string | string[]): void
  required: boolean
  disabled: boolean
  multiple?: boolean
  options: {
    enumOptions: EnumOption[]
    disabled?: boolean
  }
}

export const SelectSearchWidget = (props: ISelectSearchProps) => {
  const { enumOptions } = props.options
  const disabled = props.options?.disabled || props.disabled

  const values = useMemo(
    () =>
      Array.isArray(props.value)
        ? enumOptions.filter((opt: EnumOption) =>
            props.value?.includes(opt.value)
          )
        : enumOptions.find((opt: EnumOption) => opt.value === props.value),
    [props?.value, props?.value?.length]
  )

  const handleChange = (
    _event: ChangeEvent<unknown>,
    selection: EnumOption | EnumOption[] | null
  ) => {
    if (!selection) return

    if (Array.isArray(selection)) {
      const values = selection.map(v => v.value)
      props.onChange(values)
    } else {
      props.onChange((selection as EnumOption).value)
    }
  }

  const isOptionSelected = (
    option: EnumOption,
    selection: EnumOption[] | EnumOption
  ) => {
    if (Array.isArray(selection)) {
      const values = selection.map(v => v.value)
      return values.includes(option.value)
    } else {
      return selection.value === option.value
    }
  }

  return (
    <Autocomplete
      multiple={Boolean(props.multiple)}
      options={enumOptions}
      disabled={Boolean(disabled)}
      getOptionLabel={option => option.label}
      getOptionSelected={isOptionSelected}
      value={values}
      onChange={handleChange}
      renderInput={params => <TextField {...params} />}
    />
  )
}
