import React from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import {
  callLogCleared,
  callLogInitialized,
  isCallLogActive,
} from '~/features/callLogger'
import { syncContacts } from '~/features/callLogger/data/contacts'
import { useAction, usePending, useToggle } from '~/hooks'
import { Button, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getPatientInfo } from '../../../data/patientInfo'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginLeft: spacing(1),
  },
  icon: {
    marginRight: spacing(1),
  },
}))

const AddCallLogButton = props => {
  const classes = useStyles()

  const patientInfo = useSelector(getPatientInfo)
  const active = useSelector(isCallLogActive)
  const onInitializeCallLog = useAction(callLogInitialized)
  const onCallLogCleared = useAction(callLogCleared)
  const syncPatientContacts = useAction(syncContacts.requested)
  const syncContactsPending = usePending(syncContacts)

  const [open, toggleOpen, untoggleOpen] = useToggle()

  const onInit = () => {
    if (patientInfo.id)
      onInitializeCallLog(
        patientInfo.id,
        patientInfo.status,
        patientInfo.programEnrolled
      )
  }

  const onClear = () => {
    onCallLogCleared()
    untoggleOpen()
  }

  const onSyncContacts = () => {
    syncPatientContacts(patientInfo.id)
  }

  return (
    <React.Fragment>
      {active && (
        <React.Fragment>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={syncContactsPending}
            onClick={onSyncContacts}
          >
            <Icon className={classes.icon} fontSize="small">
              sync
            </Icon>
            Sync Contacts
          </Button>
          <Button
            {...props}
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={toggleOpen}
          >
            <Icon className={classes.icon} fontSize="small">
              call_end
            </Icon>
            Cancel
          </Button>
        </React.Fragment>
      )}

      {!active && patientInfo.id && (
        <Button variant="contained" color="primary" onClick={onInit} {...props}>
          <Icon className={classes.icon} fontSize="small">
            phone
          </Icon>
          Log a Call
        </Button>
      )}

      <ActionDialog
        mode="confirm"
        open={open}
        title="Cancel Call Log"
        onConfirm={onClear}
        onClose={untoggleOpen}
      >
        <Typography>
          Are you sure you want to cancel logging this call?
        </Typography>
      </ActionDialog>
    </React.Fragment>
  )
}

export default AddCallLogButton
