import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getReportsDashboard } from './common/shared'

const key = 'reports'

export const Report = Record({
  id: null,
  name: null,
  description: null,
  params: null,
})

export const fetchReports = Request({
  typePrefix: key,
  typeBase: 'FETCH_REPORTS',
  operation: () => AspireAPI.get('/reports_dashboard/reports'),
  transform: into(Report, 'id'),
})

export const fetchReport = Request({
  typePrefix: key,
  typeBase: 'FETCH_REPORT_PARAMS',
  requestParams: ['id'],
  operation: (id: any) => AspireAPI.get(`/reports_dashboard/reports/${id}`),
})

const reducer = createReducer(key, Map(), {
  [fetchReports.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [fetchReport.SUCCEEDED]: (state: any, { meta, payload }: any) =>
    state.set(meta.request.payload.id, Report(payload)),
})

export const getReports = pipe(getReportsDashboard, get(key))

export const getReport = (state: any, id: any) =>
  getReports(state).get(id, Report())

export default reducer
