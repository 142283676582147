import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import { getImoSessionId } from './../data/utils/selectors'
import { sendModifierSelected, updateModifiersInGroup } from '../data/imo'

export const sendImoModifierSelected = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updateModifiersInGroup),
    pluck('payload'),
    withLatestFrom(state$),
    map(([{ problemId, modifierId }, state]: any) =>
      sendModifierSelected.requested(
        getImoSessionId(state),
        problemId,
        modifierId
      )
    )
  )
