import React from 'react'
import { useSelector } from 'react-redux'
import { getFieldValues } from '~/data/fieldValues'
import PropTypes from '~/utils/propTypes'
import { eventTypes } from '../EventTypes'

const CustomEvent = props => {
  const { event } = props

  const newType = event.type === 'Other' ? 'Unavailability' : event.type
  const type = eventTypes[newType]

  const CalendarComponent = type ? type.calendarComponent : null
  const subtypeField = event ? type.subtypeField : null
  const subtypes = useSelector(getFieldValues(subtypeField))

  const getVisitMethods = useSelector(getFieldValues('modality'))
  const getRideAlongRoles = useSelector(getFieldValues('ride_along_role'))
  const visitMethods =
    event.type === 'SchedulingGuidance' ? getVisitMethods : null
  const rideAlongRoles =
    event.type === 'SchedulingGuidance' ? getRideAlongRoles : null

  const title = type
    ? type.title(event, subtypes, visitMethods, rideAlongRoles)
    : null

  return CalendarComponent ? (
    <CalendarComponent {...props} title={title} />
  ) : null
}

CustomEvent.propTypes = {
  event: PropTypes.record.isRequired,
}

export default CustomEvent
