import PropTypes from '~/utils/propTypes'
import getRiskLevel from '../utils/riskLevelValues'

const RiskLevel = ({ isCaremoreBrand, score }) =>
  getRiskLevel(isCaremoreBrand, score).display

RiskLevel.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default RiskLevel
