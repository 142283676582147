import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { appInitialized } from '~/data/root'
import { PATIENT_TIMELINE_SWIMLANE } from '~/utils/storage'
import { swimlaneSet } from '../data/swimlane'

export default action$ =>
  action$.pipe(
    ofType(appInitialized),
    pluck('payload', 'initialData', PATIENT_TIMELINE_SWIMLANE),
    filter(Boolean),
    map(swimlaneSet)
  )
