import { debounce } from 'lodash'
import { useCallback } from 'react'
import useInput from './useInput'
import useUpdateEffect from './useUpdateEffect'

export default function useDebouncedInput(
  initialValue = '',
  setter: (...args: any[]) => any,
  delay = 500
) {
  const [localValue, localSet] = useInput(initialValue)

  const debouncedSetter = useCallback(
    debounce(value => {
      setter(value)
    }, delay),
    [setter]
  )

  useUpdateEffect(() => {
    debouncedSetter(localValue)
  }, [localValue])

  return [localValue, localSet]
}
