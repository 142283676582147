import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { LocationType } from './interfaces'

// KEY
const LOCATIONTYPE = 'locationType'

export const LocationTypes = Record({
  type: null,
  id: null,
  code: null,
  activeInd: null,
})

// TRANSFORMER
const mapLocationTypes = (results: [LocationType]) =>
  List(
    results?.map(trip =>
      LocationTypes({
        id: trip?.id,
        code: trip?.code,
        activeInd: trip?.activeInd,
        type: trip?.type,
      })
    )
  )

// REQUEST
export const locationTypeList = Request({
  typePrefix: rootKey,
  typeBase: 'LOCATIONTYPE',
  operation: () => AspireAPI.get('transportation/lookup/location_type'),
  transform: mapLocationTypes,
  messages: { failed: 'There was an issue fetching Location Types' },
})

// REDUCER
export const locationType = createReducer(LOCATIONTYPE, List(), {
  [locationTypeList.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getLocationTypes = pipe(getRoot, get(LOCATIONTYPE))
