import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { formatDate, formatPhoneNumberToUS } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { getPatientRecord } from './common/shared'

const key = 'patientContact'

export const PatientContacts = Record({
  id: null,
  patientId: null,
  type: null,
  name: null,
  relationship: null,
  notes: null,
  preferred: null,
  decisionMaker: null,
  delete: null,
  scheduling: null,
  phones: List(),
  emails: List(),
  addresses: List(),
  emailAsked: null,
  active: null,
})

export const PatientPhone = Record({
  id: null,
  phone: null,
  phoneFormatted: null,
  type: null,
  textingApproved: null,
  primary: null,
  active: null,
  delete: null,
  phiVoicemailApproved: null,
  hipaaAuthExpiration: null,
  createdAt: null,
  sourceLabel: null,
  cpsuiStatusLabel: null,
  inactiveReason: null,
  index: null,
})

export const PatientEmail = Record({
  id: null,
  email: null,
  type: null,
  primary: null,
  active: null,
  delete: null,
  createdAt: null,
  index: null,
})

export const PatientAddress = Record({
  id: null,
  street: null,
  street2: null,
  city: null,
  state: null,
  zip: null,
  lat: null,
  long: null,
  type: null,
  primary: null,
  active: null,
  createdAt: null,
  index: null,
  delete: null,
})

export const transformPatientContacts = (results: any) =>
  List(
    results?.map((contact: any) =>
      PatientContacts({
        id: contact?.id,
        patientId: contact?.patientId,
        type: contact?.type,
        name: contact?.name,
        relationship: contact?.relationship,
        notes: contact?.notes,
        preferred: contact?.preferred,
        decisionMaker: contact?.decisionMaker,
        scheduling: contact?.scheduling,
        phones: transformPatientPhones(contact?.phones),
        emails: transformPatientEmails(contact?.emails),
        addresses: transformPatientAddresses(contact?.addresses),
        emailAsked: contact?.emailAsked,
        active: contact?.active,
        delete: contact?.delete,
      })
    )
  )

const transformPatientPhones = (results: any) =>
  List(
    results?.map((phone: any, index: number) => {
      const isMasked = phone?.cpsuiStatus === 'reassigned_number'
      return PatientPhone({
        id: phone?.id,
        phone: formatPhoneNumberToUS(phone?.phone),
        phoneFormatted: formatPhoneNumberToUS(phone?.phone, isMasked),
        type: phone?.type,
        textingApproved: phone?.textingApproved,
        primary: phone?.primary,
        active: phone?.active,
        delete: phone?.delete,
        phiVoicemailApproved: phone?.phiVoicemailApproved,
        hipaaAuthExpiration: phone?.hipaaAuthExpiration,
        createdAt: formatDate(phone?.createdAt),
        sourceLabel: phone?.sourceLabel,
        cpsuiStatusLabel: phone?.cpsuiStatusLabel,
        inactiveReason: phone?.inactiveReason,
        index: index + 1,
      })
    })
  )
const transformPatientEmails = (results: any) =>
  List(
    results?.map((email: any, index: number) =>
      PatientEmail({
        id: email?.id,
        email: email?.email,
        type: email?.type,
        primary: email?.primary,
        active: email?.active,
        delete: email?.delete,
        createdAt: formatDate(email?.createdAt),
        index: index + 1,
      })
    )
  )

const transformPatientAddresses = (results: any) =>
  List(
    results?.map((address: any, index: number) =>
      PatientAddress({
        id: address?.id,
        street: address?.street,
        street2: address?.street2,
        city: address?.city,
        state: address?.state,
        zip: address?.zip,
        lat: address?.lat,
        long: address?.long,
        type: address?.type,
        primary: address?.primary,
        active: address?.active,
        createdAt: formatDate(address?.createdAt),
        delete: address?.delete,
        index: index + 1,
      })
    )
  )

export const fetchPatientContacts = Request({
  typePrefix: key,
  typeBase: 'FETCH_CONTACTS',
  requestParams: ['patientId'],
  operation: (patientId: any) => {
    return AspireAPI.get(`v1/patient/${patientId}/contacts`)
  },
  transform: transformPatientContacts,
  messages: {
    failed: 'Failed to fetch patient contacts',
  },
})

export const syncPatientContacts = Request({
  typePrefix: key,
  typeBase: 'SYNC_CONTACTS',
  requestParams: ['patientId'],
  operation: (patientId: any) => AspireAPI.get(`v1/sync_contacts/${patientId}`),
  transform: transformPatientContacts,
  messages: {
    failed: 'Failed to sync patient contacts',
  },
})

export const patientContact = createReducer(key, List(), {
  [fetchPatientContacts.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [syncPatientContacts.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getContacts = pipe(getPatientRecord, get(key))
