import { isEmpty } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Unengaged, getUnengaged } from '~/features/patientInfo'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { UNENGAGED_REASONS } from './constants'
import { getRequestProcessingValue } from './data/common/requestProcessing'
import {
  closeUnengaged,
  getDialogOpenValue,
  updateUnengaged,
  updateUnengagedDialogClosed,
} from './data/updateUnengagedDialog'

const styles = ({ palette, spacing }) => ({
  category: {
    fontWeight: 'bold',
    marginRight: spacing(1),
  },
  dialogActions: {
    margin: 0,
    padding: spacing(1),
  },
  dialogTitle: {
    padding: [spacing(2), spacing(3)],
  },
  lighterText: {
    color: palette.grey[500],
    marginLeft: spacing(1.5),
  },
  textField: {
    marginTop: 20,
    '& div': {
      lineHeight: '1.4em',
      padding: [12, spacing(2)],
    },
  },
})

class UpdateUnengagedDialog extends React.Component {
  state = {
    notes: this.props.unengaged.notes,
    reason: this.props.unengaged.reason,
    reengagedNotes: '',
    close: false,
  }

  componentDidUpdate = prevProps =>
    this.props.unengaged.notes !== prevProps.unengaged.notes &&
    this.setState({ notes: this.props.unengaged.notes })

  resetState = () =>
    this.setState({
      notes: this.props.unengaged.notes,
      reason: this.props.unengaged.reason,
      reengagedNotes: '',
      close: false,
    })

  closeDialog = () => {
    this.resetState()
    this.props.onClose()
  }

  toggleResetClose = () =>
    this.setState(prevState => ({
      close: !prevState.close,
    }))

  updateNotes = event => this.setState({ notes: event.target.value })
  createReengagedNotes = event =>
    this.setState({ reengagedNotes: event.target.value })
  pickReason = event => this.setState({ reason: event.target.value })

  updateUnengagedRecord = () => {
    const {
      updateUnengaged,
      unengaged: { id },
    } = this.props
    const { notes, reason } = this.state

    updateUnengaged(id, notes, reason)
  }

  closeUnengagedRecord = () => {
    const {
      closeUnengaged,
      unengaged: { id },
    } = this.props
    const { notes, reason, reengagedNotes } = this.state

    closeUnengaged(id, notes, reason, reengagedNotes)
  }

  render() {
    const {
      classes,
      open,
      requestIsProcessing,
      unengaged: { fromDate },
    } = this.props
    const { notes, reason, reengagedNotes, close } = this.state
    const requiredFieldsEmpty = isEmpty(notes) && isEmpty(reengagedNotes) // TODO

    return (
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="sm"
        onClose={this.closeDialog}
        onExited={this.resetState}
        open={open}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          Update Unengaged Case
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className={classes.category}>Unengaged Start Date: </span>
            {formatDate(fromDate)}
          </DialogContentText>
          <InputLabel htmlFor="unengaged-reason" shrink>
            Choose an Unengaged Reason
          </InputLabel>
          <Select
            classes={{ root: classes.selectRoot }}
            className={classes.select}
            input={<Input name="reason" id="unengaged-reason" />}
            fullWidth
            name="unengaged-reason"
            onChange={this.pickReason}
            required
            value={reason}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {UNENGAGED_REASONS.map((reason, index) => (
              <MenuItem key={index} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
          <InputLabel shrink>Notes</InputLabel>
          <TextField
            className={classes.textField}
            disabled={close}
            fullWidth
            multiline
            onChange={this.updateNotes}
            rows="4"
            variant="outlined"
            value={notes}
          />
          <FormControlLabel
            control={
              <Checkbox checked={close} onChange={this.toggleResetClose} />
            }
            label="Do you want to re-engage this patient?"
          />
          {close && <InputLabel shrink>Reengaged Notes</InputLabel> && (
            <TextField
              className={classes.textField}
              disabled={!close}
              fullWidth
              multiline
              onChange={this.createReengagedNotes}
              placeholder="Enter Reengaged Notes (Required)"
              rows="4"
              variant="outlined"
            />
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button color="secondary" onClick={this.closeDialog}>
            Close
          </Button>
          <Button
            color="primary"
            disabled={requiredFieldsEmpty || requestIsProcessing || close}
            onClick={this.updateUnengagedRecord}
            variant="contained"
          >
            Update
          </Button>
          <Button
            color="primary"
            disabled={requestIsProcessing || !close}
            onClick={this.closeUnengagedRecord}
            variant="contained"
          >
            Re-Engage Patient
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UpdateUnengagedDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeUnengaged: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  requestIsProcessing: PropTypes.bool.isRequired,
  unengaged: PropTypes.instanceOf(Unengaged),
  updateUnengaged: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  open: getDialogOpenValue(state),
  requestIsProcessing: getRequestProcessingValue(state),
  unengaged: getUnengaged(state),
})

const mapDispatchToProps = {
  closeUnengaged: closeUnengaged.requested,
  onClose: updateUnengagedDialogClosed,
  updateUnengaged: updateUnengaged.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UpdateUnengagedDialog)
