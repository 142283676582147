import React from 'react'
import PropTypes from '~/utils/propTypes'
import CalendarEvent from '../../Events/CalendarEvent'

const VisitPlaceHolderCalendarEvent = ({ event, ...props }) => (
  <CalendarEvent {...props} event={event} />
)

VisitPlaceHolderCalendarEvent.propTypes = {
  event: PropTypes.record.isRequired,
}

export default VisitPlaceHolderCalendarEvent
