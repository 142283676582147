import React from 'react'
import { useDispatch } from 'react-redux'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/components/DevXGrid' or its ... Remove this comment to see the full error message
import DevXGrid from '~/components/DevXGrid'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'
import { Icon, IconButton, Paper, Tooltip } from '@material-ui/core'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { applyActionRequested } from '~/features/orders/data/orderDetail'

type FailedFaxProps = {
  failedFaxes: any
  mapFaxLabels: (key: string) => string
}

const FailedFaxesGrid = ({ failedFaxes, mapFaxLabels }: FailedFaxProps) => {
  const dispatch = useDispatch()

  const columns = [
    {
      name: 'type',
      title: 'Fax Type',
      getCellValue: (row: any) => mapFaxLabels(row.type),
    },
    {
      name: 'faxTrackingId',
      title: 'Fax Tracking Id',
    },
    {
      name: 'internalTrackingId',
      title: 'Internal Tracking Id',
    },
    {
      name: 'createdAt',
      title: 'Created Date',
      getCellValue: (row: any) =>
        row.createdAt ? formatDate(row.createdAt) : '',
    },
  ]

  const resendFax = (faxId: any) =>
    dispatch(applyActionRequested(faxId, { key: 'resubmit_fax' }))

  const userRowActions = ({ row }: any) => {
    return [
      <Tooltip title={'Resend Fax'} key="resendFax" placement="bottom">
        <div style={{ textAlign: 'center' }}>
          <IconButton
            onClick={() => resendFax(row.internalTrackingId)}
            color="secondary"
            disabled={['closed', 'cancelled'].includes(row.type)}
          >
            <Icon>refresh</Icon>
          </IconButton>
        </div>
      </Tooltip>,
    ]
  }

  return (
    <Paper>
      <DevXGrid
        columns={columns}
        columnAutoWidth={true}
        rows={failedFaxes}
        groupable
        defaultGrouping={[{ columnName: 'type' }]}
        searchable
        sortable
        rowActions={userRowActions}
      />
    </Paper>
  )
}

export default FailedFaxesGrid
