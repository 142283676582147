import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error no export
import { getPatientId as getPatientIdForAssessments } from '~/features/patientInfo'
// @ts-expect-error no export
import { getCurrentPatientId } from '~/features/scheduling/data/index'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import { addContact, updateContact } from '../data/patientContactDialog'
import { fetchPatientContacts } from '../data/patientContacts'
import {
  deleteContactRequest,
  fetchDecisionMaker,
  fetchPreferred,
} from '../data/patientContactsContext'
// @ts-expect-error no export
import { getPatientId } from '../data/patientId'

export const setDecisionMaker = (action$: any, state$: any) =>
  action$.pipe(
    ofType(fetchDecisionMaker.SUCCEEDED),
    switchTo(state$),
    map((state: any) =>
      fetchPatientContacts.requested(
        getPatientId(state) ||
          getCurrentPatientId(state) ||
          getPatientIdForAssessments(state)
      )
    )
  )

export const setPreferred = (action$: any, state$: any) =>
  action$.pipe(
    ofType(fetchPreferred.SUCCEEDED),
    switchTo(state$),
    map((state: any) =>
      fetchPatientContacts.requested(
        getPatientId(state) ||
          getCurrentPatientId(state) ||
          getPatientIdForAssessments(state)
      )
    )
  )

export const savePatientContact = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addContact.SUCCEEDED),
    switchTo(state$),
    map((state: any) =>
      fetchPatientContacts.requested(
        getPatientId(state) ||
          getCurrentPatientId(state) ||
          getPatientIdForAssessments(state)
      )
    )
  )

export const updatePatientContact = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updateContact.SUCCEEDED),
    switchTo(state$),
    map((state: any) =>
      fetchPatientContacts.requested(
        getPatientId(state) ||
          getCurrentPatientId(state) ||
          getPatientIdForAssessments(state)
      )
    )
  )

export const onDeleteContact = (action$: any, state$: any) =>
  action$.pipe(
    ofType(deleteContactRequest.SUCCEEDED),
    switchTo(state$),
    map((state: any) =>
      fetchPatientContacts.requested(
        getPatientId(state) ||
          getCurrentPatientId(state) ||
          getPatientIdForAssessments(state)
      )
    )
  )
