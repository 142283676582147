import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { appInitialized } from '~/data/root'
import { VITALS_CHART } from '~/utils/storage'
import { chartSet } from '../data/chart'

export default action$ =>
  action$.pipe(
    ofType(appInitialized),
    pluck('payload', 'initialData', VITALS_CHART),
    filter(Boolean),
    map(chartSet)
  )
