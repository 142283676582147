import React from 'react'
import setDisplayName from '~/utils/setDisplayName'

let script

const apiKey = 'AIzaSyCC7yc9PNU2LOofU6tzNanJ5fG-88vfN6A'
const libraries = 'drawing,places,visualization'

const addScript = listener => {
  if (!script) {
    script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}`

    document.getElementsByTagName('head')[0].appendChild(script)
  }

  script.addEventListener('load', listener)
}

const withGoogle = BaseComponent => {
  class ComponentWithGoogle extends React.Component {
    state = { google: null }

    componentDidMount() {
      if (!window.google) {
        addScript(this.onGoogleLoaded)
      } else {
        this.onGoogleLoaded()
      }
    }

    onGoogleLoaded = () => this.setState({ google: window.google })

    render() {
      const { google } = this.state
      return google ? <BaseComponent {...this.props} google={google} /> : null
    }
  }

  setDisplayName(BaseComponent, ComponentWithGoogle, 'withGoogle')

  return ComponentWithGoogle
}

export default withGoogle
