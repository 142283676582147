import { List, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { searchOfflineSucceeded } from './offlineDrugs'
import { getMedications, handleMedMessage } from './root'

/* KEY */
const key = 'medSearch'
const creator = scopedCreator(key)

export const searchResultsCleared = creator('SEARCH_RESULTS_CLEARED')
export const searchDrugs = creator('SEARCH_DRUGS')

/* DEFAULT STATE */
const MedSearch = Record({
  searchResults: List(),
  hasSearchResults: false,
})

/* API */
export const searchMedsApi = ({ query, options }) =>
  AspireAPI.get('medications/search', {
    params: {
      query: query,
      begins_contains: options.beginsContains,
      search_flag: options.searchFlag,
      name_flag: options.nameFlag,
      rx_flag: options.rxFlag,
      search_name_flag: options.searchNameFlag,
      active_only: options.activeOnly,
      page_number: options.pageNumber,
      mdt: options.mdt,
    },
  })

/* TRANSFORMER */
const mapKeys = data =>
  data.map(({ deaclasscode, ...rest }) => ({
    schedule: deaclasscode,
    ...rest,
  }))

/* REQUESTS */
export const fetchSearchResults = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['queryObj'],
  operation: searchMedsApi,
  transform: mapKeys,
  messages: {
    failed: handleMedMessage('There was a problem fetching search results'),
    requested: 'Fetching Search Results',
  },
})

export const fetchAdditionalResults = Request({
  typePrefix: key,
  typeBase: 'ADDITIONAL',
  requestParams: ['queryObj'],
  operation: searchMedsApi,
  transform: mapKeys,
  messages: {
    failed: handleMedMessage('There was a problem fetching additional results'),
  },
})

/* REDUCER */
export default createReducer(key, MedSearch(), {
  [fetchSearchResults.SUCCEEDED]: (_state, { payload }) =>
    MedSearch({ searchResults: List(payload), hasSearchResults: true }),
  [fetchAdditionalResults.SUCCEEDED]: (state, { payload }) =>
    state.update('searchResults', results => results.concat(List(payload))),
  [searchResultsCleared]: () => MedSearch(),
  [searchOfflineSucceeded]: (state, { payload }) =>
    state
      .set('searchResults', List(mapKeys(payload)))
      .set('hasSearchResults', true),
})

/* SELECTORS */
const getMedSearch = pipe(getMedications, get(key))
const getMedSearchResults = pipe(getMedSearch, get('searchResults'))
export const hasMedSearchResults = pipe(getMedSearch, get('hasSearchResults'))
export const getMedSearchResultsArray = createSelector(
  [getMedSearchResults],
  medList => medList.toJS()
)
