import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { ORDERED, rxStatusMap } from '../utils/medListConstants'

const styles = {
  fullWidth: {
    width: '100%',
  },
}

const RxStatusOptions = ({ disabledStatuses }) => {
  const disabledValues = useMemo(() => [ORDERED, ...disabledStatuses], [
    disabledStatuses,
  ])

  return (
    <React.Fragment>
      <option />
      {rxStatusMap
        .map(rxStatus => (
          <option
            key={rxStatus.get('key')}
            value={rxStatus.get('value')}
            disabled={disabledValues.includes(rxStatus.get('value'))}
          >
            {rxStatus.get('display')}
          </option>
        ))
        .toList()}
    </React.Fragment>
  )
}

RxStatusOptions.propTypes = {
  disabledStatuses: PropTypes.array,
}

RxStatusOptions.defaultProps = {
  disabledStatuses: [],
}

export default withStyles(styles)(RxStatusOptions)
