import { combineReducers } from 'redux-immutable'
import onCalls from './data/onCalls'
import ON_CALL from './key'

const reducer = combineReducers({
  [onCalls.key]: onCalls,
})

reducer.key = ON_CALL

export default reducer
