import React from 'react'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../widgets/memoizedWrapper'

const MarkupField = ({ formData }) => {
  return <div dangerouslySetInnerHTML={{ __html: formData }}></div>
}

MarkupField.propTypes = {
  formData: PropTypes.string,
}

export default memoizedWrapper(MarkupField)
