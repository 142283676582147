import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { loadSavedPolygon } from './selectedPolygon'

const key = 'visibleProjectPolygons'

export const VISIBLE_POLYGONS_SET = type(rootKey, 'VISIBLE_POLYGONS_SET')
export const VISIBLE_POLYGON_REMOVED = type(rootKey, 'VISIBLE_POLYGON_REMOVED')

export const setVisibleProjectPolygons = creator(VISIBLE_POLYGONS_SET, 'polys')
export const removeVisibleProjectPolygon = creator(
  VISIBLE_POLYGON_REMOVED,
  'polygon'
)

export default createReducer(key, new Set(), {
  [VISIBLE_POLYGONS_SET]: (state, { payload: { polys } }) => new Set(polys),
  [VISIBLE_POLYGON_REMOVED]: (state, { payload: { polygon } }) =>
    state.delete(polygon),
  [loadSavedPolygon]: (state, { payload: { polygon } }) =>
    state.add(polygon.np_id),
})

export const getVisibleProjectPolygons = pipe(getRoot, get(key))
