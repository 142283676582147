import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Avatar,
  Icon,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import PrimaryText from './PrimaryText'
import SecondaryText from './SecondaryText'

const APPListItem = ({ app }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <Icon>business_center</Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<PrimaryText app={app} />}
        secondary={<SecondaryText app={app} />}
      />
    </ListItem>
  )
}

APPListItem.propTypes = {
  app: PropTypes.record.isRequired,
}

export default React.memo(APPListItem)
