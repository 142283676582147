import { combineEpics } from 'redux-observable'
import { entered, exited } from 'redux-routable'
import { filter, map, mapTo, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/operators' or its corr... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { copiedUserCleared, getCopiedUser } from '../data/copiedUser'
import { currentUserCleared, fetchUser } from '../data/currentUser'
import { getActive, getPage, getSearchWord } from '../data/userQuery'
import { fetchUsers } from '../data/users'
import {
  ADMIN_TOOLS_EDIT_USER,
  ADMIN_TOOLS_NEW_USER,
  ADMIN_TOOLS_USERS,
} from '../router'

const fetchUsersEpic = (action$: any, state$: any) =>
  action$.pipe(
    filter(entered(ADMIN_TOOLS_USERS)),
    switchTo(state$),
    map(state =>
      fetchUsers.requested(
        getSearchWord(state),
        getActive(state),
        getPage(state)
      )
    )
  )

const copiedUserClearedEpic = (action$: any, state$: any) =>
  action$.pipe(
    filter(exited([ADMIN_TOOLS_NEW_USER, ADMIN_TOOLS_EDIT_USER])),
    switchTo(state$),
    map(getCopiedUser),
    filter(Boolean),
    mapTo(copiedUserCleared())
  )

const fetchUserEpic = (action$: any) =>
  action$.pipe(
    filter(entered(ADMIN_TOOLS_EDIT_USER)),
    pluck('payload', 'params', 'userId'),
    map(fetchUser.requested)
  )

const currentUserClearedEpic = (action$: any) =>
  action$.pipe(
    filter(exited(ADMIN_TOOLS_EDIT_USER)),
    mapTo(currentUserCleared())
  )

export default combineEpics(
  fetchUsersEpic,
  fetchUserEpic,
  copiedUserClearedEpic,
  currentUserClearedEpic
)
