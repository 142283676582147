import { Map, Record } from 'immutable'
import { isEmpty, omitBy } from 'lodash'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { Filter } from './filters'
import { STATUS_UPDATE_COMPLETED } from './referrals/constants'
import { getReferralManagement } from './root'
// RECORDS
export const ReferralCase = Record({
  assignedAppName: null,
  createdById: null,
  createdByName: null,
  createdDate: null,
  dob: null,
  firstName: null,
  healthplanId: null,
  healthplanName: null,
  id: null,
  lastName: null,
  patientSfid: null,
  patientPgid: null,
  source: null,
  status: null,
  substatus: null,
  validationIssues: null,
  address: null,
})
// CONSTANTS
export const REFERRAL_CASE_STORED = 'referralCases/REFERRAL_CASE_STORED'
export const REFERRAL_CASES_STORED = 'referralCases/REFERRAL_CASES_STORED'
export const REFERRAL_CASE_DELETED = 'referralCases/REFERRAL_CASE_DELETED'
export const REFERRAL_CASE_FETCH_FAILED =
  'referralCases/REFERRAL_CASE_FETCH_FAILED'
// ACTION CREATORS
export const referralCaseStored = (referralCase: any) => ({
  type: REFERRAL_CASE_STORED,
  referralCase,
})
export const referralCasesStored = (referralCases: any) => ({
  type: REFERRAL_CASES_STORED,
  referralCases,
})
export const referralCaseRemoved = (referralCaseId: any) => ({
  type: REFERRAL_CASE_DELETED,
  referralCaseId,
})
export const referralCaseFetchFailed = () => ({
  type: REFERRAL_CASE_FETCH_FAILED,
})
// KEY
export const key = 'referralCases'
// REDUCER
export default createReducer(key, Map(), {
  [REFERRAL_CASES_STORED]: (state: any, { referralCases }: any) =>
    state.mergeDeep(referralCases),
  [REFERRAL_CASE_DELETED]: (state: any, { referralCaseId }: any) =>
    state.delete(referralCaseId),
  [STATUS_UPDATE_COMPLETED]: (
    state: any,
    { referralCaseId, status, substatus }: any
  ) =>
    state
      .setIn([referralCaseId, 'status'], status)
      .setIn([referralCaseId, 'substatus', substatus], substatus),
})
const mapData = (data: any) =>
  Map(
    data.map((d: any) => [
      d.caseId,
      ReferralCase({
        assignedAppName: d.assignedAppName,
        createdById: d.createdById,
        createdByName: d.createdByName,
        createdDate: d.createdDate,
        dob: d.dob,
        firstName: d.firstName,
        healthplanId: d.healthplanId,
        healthplanName: d.healthplanName,
        id: d.caseId,
        lastName: d.lastName,
        patientSfid: d.patientSfid,
        patientPgid: d.patientPgid,
        source: d.source,
        status: d.status,
        statusId: d.statusId,
        substatus: d.substatus,
        substatusId: d.substatusId,
        validationIssues: d.validationIssues,
        address: d.address,
      }),
    ])
  )
// API
export const fetchReferralCases = () =>
  AspireAPI.get('v1/referral').then(mapData)
export const fetchFilteredReferralCases = (filter = Filter()) =>
  AspireAPI.get('v1/referral', {
    params: omitBy(
      {
        healthplan_ids: (filter as any).healthplanIds.toJS(),
        status_ids: (filter as any).statusIds.toJS(),
        substatus_ids: (filter as any).substatusIds.toJS(),
      },
      isEmpty
    ),
  }).then(mapData)
export const archiveReferral = (caseId: any, status: any, substatus: any) =>
  AspireAPI.post('v1/referral/status', {
    caseId,
    status,
    substatus,
  }).then(() => caseId)
// SELECTORS
export const getAllReferralCases = (state: any) =>
  getReferralManagement(state).get(key)
export const getReferralCaseById = (state: any, id: any) =>
  getAllReferralCases(state).get(id)
