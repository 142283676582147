import { ofType } from 'redux-observable'
import { ignoreElements, pluck, tap } from 'rxjs/operators'
import { LOAD_INACTIVE_USERS, setPermanentItem } from '~/utils/storage'
import { LOAD_INACTIVE_USERS_TOGGLED } from '../data/userListSettings'

const storeLocally = (value: any) =>
  setPermanentItem(LOAD_INACTIVE_USERS, value)

export default (action$: any) =>
  action$.pipe(
    ofType(LOAD_INACTIVE_USERS_TOGGLED),
    pluck('payload', 'value'),
    tap(storeLocally),
    ignoreElements()
  )
