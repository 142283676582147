import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import PropTypes from '~/utils/propTypes'
import { Grid, Icon, IconButton, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const HALF_SIZE = 6
const FULL_SIZE = 12

const useStyles = makeStyles(({ spacing }) => ({
  gridItem: {
    maxHeight: 800,
    transition: 'all .25s ease-out',
  },
  paper: {
    height: '100%',
    padding: spacing(2),
  },
  overflow: {
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  title: {
    flex: 1,
  },
  action: {
    marginRight: spacing(1),
  },
}))

const GridItem = ({
  className,
  title,
  children,
  fullWidth,
  setWidth,
  actions = [],
}) => {
  const classes = useStyles()

  const [size, setSize] = useState(fullWidth ? FULL_SIZE : HALF_SIZE)

  const onResize = useCallback(() => {
    const newSize = size === FULL_SIZE ? HALF_SIZE : FULL_SIZE
    setSize(newSize)
    setWidth(newSize)
  }, [size, setSize])

  const icon = size === FULL_SIZE ? 'fullscreen_exit' : 'fullscreen'

  return (
    <Grid className={classes.gridItem} item xs={12} sm={size}>
      <Paper className={classnames(className, classes.paper)}>
        <div className={classes.overflow}>
          <div className={classes.header}>
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>

            {actions.map((Action, i) => (
              <div key={i} className={classes.action}>
                <Action />
              </div>
            ))}

            <IconButton size="small" onClick={onResize}>
              <Icon>{icon}</Icon>
            </IconButton>
          </div>

          {children}
        </div>
      </Paper>
    </Grid>
  )
}

GridItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  setWidth: PropTypes.func,
  fullWidth: PropTypes.bool,
  actions: PropTypes.array,
}

export default GridItem
