import React from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuList,
} from '@material-ui/core'
import { getClaims } from '../../../data/calendar'
import { newEventClosed } from '../../../data/events'
import { eventTypes } from '../../EventTypes'
import EventType from './EventType'

const NewEventDialog = ({ userId }) => {
  const claims = useSelector(getClaims)
  const closeNewEvent = useAction(newEventClosed)

  return (
    <React.Fragment>
      <DialogTitle>Select Event Type</DialogTitle>

      <DialogContent>
        <MenuList>
          {Object.keys(eventTypes)
            .map(key => eventTypes[key])
            .filter(eventType => eventType.permissions.canCreate(claims))
            .map(eventType => (
              <EventType
                key={eventType.type}
                eventType={eventType}
                userId={userId}
              />
            ))}
        </MenuList>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeNewEvent}>Close</Button>
      </DialogActions>
    </React.Fragment>
  )
}

NewEventDialog.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default NewEventDialog
