import { ofType } from 'redux-observable'
import { open } from 'redux-routable'
import { map, pluck } from 'rxjs/operators'
import { createAssessment, startAssessment } from '../data/assessments'
import { ASSESSMENT_FORM } from '../router'

export default action$ =>
  action$.pipe(
    ofType(startAssessment.SUCCEEDED, createAssessment.SUCCEEDED),
    pluck('payload', 'id'),
    map(assessmentId => open(ASSESSMENT_FORM, { assessmentId }))
  )
