import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  ACTIVE_FILTER_CLEAR_REQUESTED,
  ACTIVE_FILTER_MAKE_DEFAULT_REQUESTED,
  ACTIVE_FILTER_RESET_REQUESTED,
  ACTIVE_FILTER_SAVE_REQUESTED,
  ACTIVE_FILTER_SET,
  ACTIVE_FILTER_SET_REQUESTED,
  activeFilterCleared,
  activeFilterSet,
  getActiveFilter,
} from '../data/activeFilter'
import { APP_DATA_STORED, filterMakeDefaultRequested } from '../data/appData'
import {
  fetchFilterById,
  filterStored,
  getFilterById,
  saveFilter,
} from '../data/filters'
import {
  PROMPT_CONFIRMED,
  promptCleared,
  promptInputSet,
  promptSet,
} from '../data/prompt'
import {
  DASHBOARD_PAGE_NAVIGATED,
  getCurrentUserAppData,
  isActiveFilterOwnedByCurrentUser,
  isActiveFilterSettable,
} from '../data/referralManagement'
const referralManagementAppDataKey = 'referral_management'
const storeActiveFilter = (filter: any) =>
  from(saveFilter(filter)).pipe(
    mergeMap(filter =>
      of(filterStored(filter), activeFilterSet(filter), promptCleared())
    ),
    catchError(showError("Could not save user's filter"))
  )
const fetchAndStoreActiveFilter = (filterId: any) =>
  from(fetchFilterById(filterId)).pipe(
    mergeMap(filter => of(filterStored(filter), activeFilterSet(filter))),
    catchError(showError("Error! Problem loading user's filter"))
  )
const loadActiveFilterAfterAppData = (action$: any) =>
  action$.pipe(
    ofType(APP_DATA_STORED),
    filter(({ key }) => key === referralManagementAppDataKey),
    first(),
    mergeMap(({ appData }) => {
      const filterId = appData && appData.get('defaultFilterId')
      return filterId
        ? fetchAndStoreActiveFilter(filterId)
        : of(activeFilterCleared())
    })
  )
const loadActiveFilter = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DASHBOARD_PAGE_NAVIGATED),
    first(),
    mergeMap(() => {
      const state = state$.value
      const appData = getCurrentUserAppData(state, referralManagementAppDataKey)
      return appData
        ? fetchAndStoreActiveFilter(appData.get('defaultFilterId'))
        : loadActiveFilterAfterAppData(action$)
    })
  )
const saveActiveFilter = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ACTIVE_FILTER_SAVE_REQUESTED),
    mergeMap(() => {
      const state = state$.value
      const filter = getActiveFilter(state)
      const namePrompt$ = of(
        promptInputSet('name', 'Filter Name', 'Name of the filter:')
      )
      if (filter.id) {
        if (isActiveFilterOwnedByCurrentUser(state) && !filter.name)
          return namePrompt$
        if (isActiveFilterOwnedByCurrentUser(state))
          return storeActiveFilter(filter)
        return of(
          promptSet(
            'copy',
            'Copy Filter',
            'Make a copy of this filter that you own?'
          )
        )
      } else {
        return namePrompt$
      }
    })
  )
const promptFilterNameForCopy = (action$: any) =>
  action$.pipe(
    ofType(PROMPT_CONFIRMED),
    filter(({ action }) => action === 'copy'),
    mapTo(promptInputSet('name', 'Filter Name', 'Name of the filter:'))
  )
const promptFilterName = (action$: any, state$: any) =>
  action$.pipe(
    ofType(PROMPT_CONFIRMED),
    filter(({ action }) => action === 'name'),
    mergeMap(({ value }) => {
      const state = state$.value
      const filter = getActiveFilter(state).set('name', value)
      return storeActiveFilter(filter)
    })
  )
const checkOkBeforeSet = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ACTIVE_FILTER_SET_REQUESTED),
    pluck('filter'),
    withLatestFrom(state$),
    map(([filter, state]: [any, any]) =>
      isActiveFilterSettable(state)
        ? activeFilterSet(filter)
        : promptSet(
            'set',
            'Set Filter',
            `Set your active filter to ${filter.name}?`,
            filter
          )
    )
  )
const setActiveFilter = (action$: any) =>
  action$.pipe(
    ofType(PROMPT_CONFIRMED),
    filter(({ action }) => action === 'set'),
    mergeMap(({ value }) => of(activeFilterSet(value), promptCleared()))
  )
const makeDefaultAfterSet = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ACTIVE_FILTER_SET),
    pluck('filter'),
    filter(filter => {
      if (!(filter as any).id) return false
      const state = state$.value
      const appData = getCurrentUserAppData(state, referralManagementAppDataKey)
      return !appData
    }),
    map(filterMakeDefaultRequested)
  )
const makeActiveFilterDefault = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ACTIVE_FILTER_MAKE_DEFAULT_REQUESTED),
    map(() => {
      const state = state$.value
      const filter = getActiveFilter(state)
      return filterMakeDefaultRequested(filter)
    })
  )
const promptClearActiveFilter = (action$: any) =>
  action$.pipe(
    ofType(ACTIVE_FILTER_CLEAR_REQUESTED),
    mapTo(
      promptSet(
        'clear',
        'Clear Filter',
        'This will clear all fields currently being filtered.'
      )
    )
  )
const clearActiveFilter = (action$: any) =>
  action$.pipe(
    ofType(PROMPT_CONFIRMED),
    filter(({ action }) => action === 'clear'),
    mergeMapTo(of(activeFilterCleared(), promptCleared()))
  )
const promptResetActiveFilter = (action$: any) =>
  action$.pipe(
    ofType(ACTIVE_FILTER_RESET_REQUESTED),
    mapTo(
      promptSet(
        'reset',
        'Reset Filter',
        'This will reset the filter to its previously saved state.'
      )
    )
  )
const resetActiveFilter = (action$: any, state$: any) =>
  action$.pipe(
    ofType(PROMPT_CONFIRMED),
    filter(({ action }) => action === 'reset'),
    mergeMap(() => {
      const state = state$.value
      const activeFilter = getActiveFilter(state)
      if (activeFilter.id) {
        const sourceFilter = getFilterById(state, activeFilter.id)
        return of(activeFilterSet(sourceFilter), promptCleared())
      } else {
        return of(activeFilterCleared(), promptCleared())
      }
    })
  )
export default combineEpics(
  loadActiveFilter,
  saveActiveFilter,
  promptClearActiveFilter,
  clearActiveFilter,
  promptResetActiveFilter,
  resetActiveFilter,
  promptFilterName,
  promptFilterNameForCopy,
  makeDefaultAfterSet,
  checkOkBeforeSet,
  setActiveFilter,
  makeActiveFilterDefault
)
