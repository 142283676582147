import { Record } from 'immutable'
import { get } from '~/utils/data'
import key from '../../key'

export const TimeZoneNote = Record({
  note: null,
  condition: null,
})

export const getRoot = get(key)
