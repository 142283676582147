import React from 'react'
import FieldWrapper from '~/components/JsonForm/FieldWrapper'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  container: {
    margin: spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
})

class FormFieldWrapper extends React.Component {
  state = {
    key: 0,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCollapsed != this.props.isCollapsed) {
      this.setState(({ key }) => ({ key: ++key }))
    }
  }

  get schema() {
    return { title: this.props.title }
  }

  get uiSchema() {
    return {
      'ui:options': {
        collapsible:
          'nonCollapsible' in this.props ? !this.props.nonCollapsible : true,
        defaultCollapsed:
          'isCollapsed' in this.props ? this.props.isCollapsed : true,
      },
    }
  }

  render() {
    const { classes, children, title } = this.props

    return (
      <FieldWrapper
        key={`${title}_${this.state.key}`}
        schema={this.schema}
        uiSchema={this.uiSchema}
      >
        <div className={classes.container}>{children}</div>
      </FieldWrapper>
    )
  }
}

FormFieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool,
  nonCollapsible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

FormFieldWrapper.defaultProps = {
  isCollapsed: false,
  nonCollapsible: false,
}

export default withStyles(styles)(FormFieldWrapper)
