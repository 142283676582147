import { FieldValueProps } from '~/data/fieldValues'
import { makeStyles } from '@material-ui/core'

export const findPhoneType = (phoneTypeFields: any, phoneType: string) =>
  phoneTypeFields.find((phone: FieldValueProps) => phone.value === phoneType)

export const getPhoneTypeLabel = (phoneTypeFields: any, phoneType: string) => {
  const filteredPhoneType = findPhoneType(phoneTypeFields, phoneType)
  return filteredPhoneType ? filteredPhoneType.label : ''
}

export const findContactPhoneInactiveReason = (
  contactPhoneInactiveReasonFields: any,
  contactPhoneInactiveReason: string
) =>
  contactPhoneInactiveReasonFields.find(
    (inactiveReason: FieldValueProps) =>
      inactiveReason.value === contactPhoneInactiveReason
  )

export const getContactPhoneInactiveReasonLabel = (
  contactPhoneInactiveReasonFields: any,
  contactPhoneInactiveReason: string
) => {
  const filteredContactPhoneInactiveReason = findContactPhoneInactiveReason(
    contactPhoneInactiveReasonFields,
    contactPhoneInactiveReason
  )
  return filteredContactPhoneInactiveReason
    ? filteredContactPhoneInactiveReason.label
    : ''
}

export const findAddressType = (addressTypeFields: any, addressType: string) =>
  addressTypeFields.find(
    (address: FieldValueProps) => address.value === addressType
  )

export const getAddressTypeLabel = (
  addressTypeFields: any,
  addressType: string
) => {
  const filteredAddressType = findAddressType(addressTypeFields, addressType)
  return filteredAddressType ? filteredAddressType.label : ''
}

export const findEmailType = (emailTypeFields: any, emailType: any) =>
  emailTypeFields.find((email: FieldValueProps) => email.value === emailType)

export const getEmailTypeLabel = (emailTypeFields: any, emailType: string) => {
  const filteredEmailType = findEmailType(emailTypeFields, emailType)
  return filteredEmailType ? filteredEmailType.label : ''
}

export const findRelationTypeByType = (
  relationTypeFields: any,
  relationType: string
) =>
  relationTypeFields.find(
    (relation: FieldValueProps) => relation && relation.value === relationType
  )

export const getRelationTypeLabel = (
  relationTypeFields: any,
  relationType: string
) => {
  const filteredRelationType = findRelationTypeByType(
    relationTypeFields,
    relationType
  )
  return filteredRelationType ? filteredRelationType.label : ''
}

export const patientContactFormUseStyles = makeStyles((theme: any) => ({
  floatingLabelFocusStyle: {
    color: 'grey',
  },
  submitButton: {
    float: 'right',
  },
  primaryBox: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  textFieldBox: {
    flex: '1',
    marginRight: theme.spacing(1),
  },
  locationFieldBox: {
    flex: '2',
    marginRight: theme.spacing(1),
  },
  textFieldBoxNew: {
    flex: '0.5',
    marginRight: theme.spacing(1),
  },
  marginBottomCls: { marginBottom: theme.spacing(2) },
}))
