import React from 'react'
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette }) => ({
  statCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContentRoot: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    flex: 1,
    display: 'flex',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardTall: {
    minHeight: 75,
  },
  value: {
    alignSelf: 'flex-end',
    margin: 0,
    padding: spacing(2, 0),
  },
  label: {
    alignSelf: 'flex-end',
    flex: 1,
    padding: spacing(0, 2, 1, 0),
  },
  denseValue: {
    display: 'inline-block',
    marginBottom: 0,
  },
  denseLabel: {
    display: 'inline-block',
    lineHeight: 1.3,
  },
  denseFlexSmall: {
    backgroundColor: palette.shades.dark,
    display: 'flex',
    padding: spacing(0, 2),
    '& > *': {
      alignSelf: 'center',
      margin: 'auto',
    },
  },
  denseFlexLarge: {
    display: 'flex',
    flex: 1,
    '& > *': {
      alignSelf: 'center',
      padding: spacing(1.5, 2),
    },
  },
}))

type Props = {
  dense?: boolean
  label: string
  value: string | number
}

const StatisticCard = ({ dense, label, value }: Props) => {
  const classes = useStyles()

  return (
    <Card className={classes.statCard}>
      {dense ? (
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <div className={classes.denseFlexSmall}>
            <Typography
              variant="h4"
              className={classes.denseValue}
              gutterBottom
            >
              {value}
            </Typography>
          </div>
          <div className={classes.denseFlexLarge}>
            <Typography className={classes.denseLabel} variant="h6">
              {label}
            </Typography>
          </div>
        </CardContent>
      ) : (
        <CardContent
          classes={{ root: classes.cardContentRoot }}
          className={classes.cardTall}
        >
          <Typography variant="h2" className={classes.value}>
            {value}
          </Typography>
          <Typography className={classes.label} variant="h5">
            {label}
          </Typography>
        </CardContent>
      )}
    </Card>
  )
}

export default StatisticCard
