import { Async } from '~/resources/resource-strict'
import {
  summon,
  AType,
  EType,
  AsOpaque,
  AOfMorphADT,
  ReduxAction,
} from '~/utils/type'

const APPOption_ = summon(F =>
  F.interface(
    {
      label: F.string(),
      value: F.string(),
    },
    'APPOption'
  )
)

export interface APPOption extends AType<typeof APPOption_> {}
export interface APPOptionRaw extends EType<typeof APPOption_> {}
export const APPOption = AsOpaque<APPOptionRaw, APPOption>()(APPOption_)

const Index = Async(summon(F => F.array(APPOption(F))))
export type Index = AOfMorphADT<typeof Index>

const State_ = summon(F =>
  F.interface(
    {
      index: Index(F),
    },
    'State'
  )
)

export interface State extends AType<typeof State_> {}
export interface StateRaw extends EType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)

export type Action = ReduxAction<'APPOption', AType<typeof Index>>
