import {
  createDashUserReducer,
  createDashUserSelector,
} from '~/features/dashboards/utils'
import { scopedCreator } from '~/utils/data'
import rootKey from '../key'
import router from '../router'
import { getRoot } from './common/shared'

const DASH_USER_ID = 'dashUserId'

const creator = scopedCreator(rootKey)
export const dashboardRefreshRequested = creator(
  'DASHBOARD_REFRESH_REQUESTED',
  ['userId']
)

const reducer = createDashUserReducer(router, DASH_USER_ID)

export const getDashUserId = createDashUserSelector(getRoot, DASH_USER_ID)

export default reducer
