export default {
  patient: {
    id: '005123456789012abc',
    name: 'Some Person',
  },
  app: {
    id: '001987654321098def',
    name: 'Dee Mouser',
  },
  assignedRole: 'nss',
  numberOfAttempts: 3,
  lastAttempt: {
    date: '2019-21-02',
    disposition: 'Call Back Later',
    notes: "No answer at patient's home",
  },
  lastAssessment: {
    date: '2019-01-03',
    medicationAdherence: 'yes',
    medicationInteractionConcern: 'no',
  },
  contacts: [
    {
      id: '1',
      active: true,
      preferred: true,
      type: 'Home',
      name: 'Bob Test',
      relationship: undefined,
      phoneNumber: '(555) 123-4567',
      phiApproved: true,
      phiVoicemailApproved: true,
    },
    {
      id: '2',
      active: true,
      preferred: false,
      type: 'Family Member',
      name: 'Bobby Joe',
      relationship: 'Son',
      phoneNumber: '(555) 234-5678',
      phiApproved: true,
      phiVoicemailApproved: false,
    },
    {
      id: '3',
      active: true,
      preferred: false,
      type: 'Home',
      name: 'Linda Johnson',
      relationship: 'Neighbor',
      phoneNumber: '(555) 234-5678',
      phiApproved: false,
      phiVoicemailApproved: false,
    },
  ],
}
