import classnames from 'classnames'
import { debounce } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import AutoComplete from '~/components/AutoComplete'
import {
  Checkbox,
  DateField,
  SelectField,
  TextField,
} from '~/components/fields'
import {
  fetchHospices,
  getHospicesArr,
  hospicesCleared,
} from '~/features/contacts'
import { REASON_FOR_CHOOSING_HOSPICE } from '~/features/discharges/constants'
import { useAction, useInput, useUpdateEffect } from '~/hooks'
import { isPreviousYearThroughToday } from '~/utils/dates'
import { formatDatepickerDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import { DECEASED, HOSPICE, REENGAGE_SUB_DISPOSITION_REASONS } from './consts'

const useStyles = makeStyles(({ spacing }) => ({
  field: {
    width: '100%',
    marginBottom: spacing(1),
  },
  notes: {
    marginTop: spacing(1),
  },
}))

const Discharge = ({ dischargeReason, onFormChange }) => {
  const classes = useStyles()

  const hospices = useSelector(getHospicesArr)
  const clearHospices = useAction(hospicesCleared)
  const fetchHospicesRequested = useAction(fetchHospices.requested)

  const [blurred, setBlurred] = useState(false)
  const [dischargeDate, setDischargeDate] = useInput()
  const [dischargeNotes, setDischargeNotes] = useInput()
  const [dateOfDeath, setDateOfDeath] = useInput(null)
  const [query, setQuery] = useInput()
  const [hospiceLabel, setHospiceLabel] = useInput()
  const [hospiceId, setHospiceId] = useInput()
  const [hospiceReason, setHospiceReason] = useInput()
  const [reengagePotential, setReengagePotential] = useInput()
  const [doNotCall, setDoNotCall] = useInput(false)

  const filteredHospices = useMemo(
    () => hospices.map(({ id, name }) => ({ value: id, label: name })),
    [hospices]
  )

  const searchHospices = useCallback(
    debounce(searchWord => {
      fetchHospicesRequested(searchWord)
    }, 500),
    [fetchHospicesRequested]
  )

  const pickDischargeDate = date => {
    const newDate = formatDatepickerDate(
      isPreviousYearThroughToday(date) ? date : moment()
    )
    return setDischargeDate(newDate)
  }

  const pickDateOfDeath = date => {
    const newDate = formatDatepickerDate(
      isPreviousYearThroughToday(date) ? date : moment()
    )
    return setDateOfDeath(newDate)
  }

  const onQueryChange = value => {
    setQuery(value)
    if (value.trim()) searchHospices(value)
  }

  const onQueryBlur = () => {
    clearHospices()
    setBlurred(true)
  }

  const onQueryClear = () => {
    setHospiceId('')
    setHospiceLabel('')
    setQuery('')
  }

  const onSelectHospice = ({ suggestion: { label, value } }) => {
    setHospiceId(value)
    setHospiceLabel(label)
    setQuery(label)
  }

  useEffect(() => {
    if (blurred) {
      if (query !== hospiceLabel) {
        setQuery(hospiceLabel)
      }

      setBlurred(false)
    }
  }, [blurred, query, hospiceLabel, setQuery])

  useUpdateEffect(() => {
    setDateOfDeath(null)
    setHospiceId('')
    setHospiceLabel('')
    setHospiceReason('')
    setReengagePotential('')
  }, [
    dischargeReason,
    setDateOfDeath,
    setHospiceId,
    setHospiceLabel,
    setHospiceReason,
    setReengagePotential,
  ])

  useUpdateEffect(() => {
    const dateOfDeathValid = dischargeReason !== DECEASED || dateOfDeath
    const hospiceValid = dischargeReason !== HOSPICE || hospiceId
    const hospiceReasonValid = dischargeReason !== HOSPICE || hospiceReason
    const reengagePotentialValid =
      !REENGAGE_SUB_DISPOSITION_REASONS.includes(dischargeReason) ||
      reengagePotential
    const valid =
      dischargeDate &&
      dischargeNotes &&
      reengagePotentialValid &&
      dateOfDeathValid &&
      hospiceValid &&
      hospiceReasonValid

    onFormChange({
      dischargeDate,
      dischargeNotes,
      dateOfDeath,
      hospiceId,
      hospiceLabel,
      hospiceReason,
      reengagePotential,
      doNotCall,
      valid,
    })
  }, [
    dischargeReason,
    dischargeDate,
    dischargeNotes,
    dateOfDeath,
    hospiceId,
    hospiceLabel,
    hospiceReason,
    reengagePotential,
    doNotCall,
  ])

  return (
    <React.Fragment>
      {/* Discharge Date */}
      <DateField
        className={classes.field}
        label="Discharge Date"
        onChange={pickDischargeDate}
        value={dischargeDate}
        error={!dischargeDate}
      />

      {/* Discharge Notes */}
      <TextField
        className={classnames(classes.field, classes.notes)}
        variant="outlined"
        label="Discharge Notes"
        onChange={setDischargeNotes}
        value={dischargeNotes}
        multiline
        rowsMax={6}
        error={!dischargeNotes}
      />

      {/* Date of Death */}
      {dischargeReason === DECEASED && (
        <DateField
          className={classes.field}
          label="Date of Death"
          onChange={pickDateOfDeath}
          value={dateOfDeath}
          error={!dateOfDeath}
        />
      )}

      {dischargeReason === HOSPICE && (
        <React.Fragment>
          {/* Hospice Search */}
          <div className={classes.field}>
            <AutoComplete
              inputLabel="Hospice Search"
              value={query}
              onChange={onQueryChange}
              onBlur={onQueryBlur}
              onClear={onQueryClear}
              availableSuggestions={filteredHospices}
              onSelectValue={onSelectHospice}
              error={!hospiceId}
            />
          </div>

          {/* Hospice Reason */}
          <SelectField
            className={classes.field}
            label="Hospice Reason"
            value={hospiceReason}
            onChange={setHospiceReason}
            native
            error={!hospiceReason}
          >
            <option />
            {REASON_FOR_CHOOSING_HOSPICE.map(reason => (
              <option key={reason}>{reason}</option>
            ))}
          </SelectField>
        </React.Fragment>
      )}

      {/* Potential to Reengage */}
      {REENGAGE_SUB_DISPOSITION_REASONS.includes(dischargeReason) && (
        <SelectField
          className={classes.field}
          label="Potential to Reengage?"
          value={reengagePotential}
          onChange={setReengagePotential}
          native
          error={!reengagePotential}
        >
          <option />
          <option>Yes</option>
          <option>No</option>
        </SelectField>
      )}

      {/* Add to Health Plan Do Not Call List */}
      <Checkbox
        className={classes.field}
        label="Add to Health Plan Do Not Call List?"
        value={doNotCall}
        onChange={setDoNotCall}
      />
    </React.Fragment>
  )
}

Discharge.propTypes = {
  dischargeReason: PropTypes.string.isRequired,
  onFormChange: PropTypes.func.isRequired,
}

export default Discharge
