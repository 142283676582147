import { combineEpics, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import {
  ACTIVE_REFERRAL_CASE_REQUESTED,
  activeReferralCaseStored,
  fetchActiveReferral,
  saveReferral,
  activeReferralCaseRequested,
} from '../data/referrals'

const fetchReferral = (caseId: any) =>
  from(fetchActiveReferral(caseId)).pipe(
    map(activeReferralCaseStored),
    catchError(showError('Error! Unable to load case data'))
  )

export const loadActiveReferral = (action$: any) =>
  action$.pipe(
    ofType(ACTIVE_REFERRAL_CASE_REQUESTED),
    pluck('caseId'),
    mergeMap(caseId => loading(fetchReferral(caseId)))
  )

export const mapSaveReferralToActiveReferralCaseRequested = (action$: any) =>
  action$.pipe(
    ofType(saveReferral.SUCCEEDED),
    pluck('payload'),
    map(activeReferralCaseRequested)
  )

export default combineEpics(
  loadActiveReferral,
  mapSaveReferralToActiveReferralCaseRequested
)
