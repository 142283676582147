import { fetchPatient } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, getIn, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { createUnengaged } from './createUnengagedDialog'

// KEYS
const key = 'updateUnengagedDialog'
const dialogOpenKey = 'updateUnengagedDialogOpen'
const unengagedKey = 'isUnengaged'

export const getDialogOpenValue = getIn([rootKey, dialogOpenKey])
export const getUnengagedStatus = getIn([rootKey, unengagedKey])

// CREATORS
const creator = scopedCreator(key)

export const updateUnengagedDialogOpened = creator(
  'COMPLETE_UNENGAGED_DIALOG_OPENED'
)
export const updateUnengagedDialogClosed = creator(
  'COMPLETE_UNENGAGED_DIALOG_CLOSED'
)

// REQUEST
export const updateUnengaged = Request({
  typePrefix: key,
  typeBase: 'UPDATE_UNENGAGED',
  requestParams: ['id', 'notes', 'reason'],
  operation: (id, notes, reason) =>
    AspireAPI.put(`v1/unengaged/${id}`, {
      notes,
      reason,
    }),
  messages: {
    succeeded: 'Unengaged record successfully updated',
    failed: 'Could not update Unengaged record',
  },
})

export const closeUnengaged = Request({
  typePrefix: key,
  typeBase: 'CLOSE_UNENGAGED',
  requestParams: ['id', 'notes', 'reason', 'reengagedNotes'],
  operation: (id, notes, reason, reengagedNotes) =>
    AspireAPI.put(`v1/unengaged/${id}/close`, {
      notes,
      reason,
      reengagedNotes,
    }),
  messages: {
    succeeded: 'Unengaged record successfully completed',
    failed: 'Could not close Unengaged record',
  },
})

// REDUCER
export const completeDialog = createReducer(dialogOpenKey, false, {
  [updateUnengagedDialogOpened]: () => true,
  [updateUnengagedDialogClosed]: () => false,
  [updateUnengaged.SUCCEEDED]: () => false,
  [closeUnengaged.SUCCEEDED]: () => false,
})

export const isUnengaged = createReducer(unengagedKey, false, {
  [fetchPatient.SUCCEEDED]: (
    _state,
    {
      payload: {
        unengaged: { open },
      },
    }
  ) => open === true,
  [createUnengaged.SUCCEEDED]: () => true,
  [closeUnengaged.SUCCEEDED]: () => false,
})

export const getIsUnengaged = pipe(getRoot, get(unengagedKey))
