import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatICD10Code } from '~/utils/format'

const ICD10_TO_PROBLEM_TYPES = 'icd10ToProblemTypes'

export const Icd10ToProblemType = Record({
  icd10Code: null,
  icd10Description: null,
  problemTypeId: null,
  problemType: null,
  problemCategory: null,
  problemSubCategory: null,
  deleted: null,
})

const transformIcd10ProblemTypes = (icd10ToProblemTypes: any) => {
  return List(
    icd10ToProblemTypes.map((icd10ToProblemType: any) => {
      return Icd10ToProblemType({
        icd10Code: formatICD10Code(icd10ToProblemType?.icd10Code),
        icd10Description: icd10ToProblemType?.icd10Description,
        problemTypeId: icd10ToProblemType?.problemTypeId,
        problemType: icd10ToProblemType?.problemType,
        problemCategory: icd10ToProblemType?.category,
        problemSubCategory: icd10ToProblemType?.subcategory,
        deleted: icd10ToProblemType?.isDeleted ? 'Yes' : 'No',
      })
    })
  )
}

const transformErrorResponse = (error: any) => {
  const genericSaveError =
    'There was an issue adding ICD10 to Problem Type mapping.'
  return error?.response?.data?.message?.icd10Definition[0] || genericSaveError
}

export const fetchIcd10ToProblemTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ICD10_TO_PROBLEM_TYPES',
  requestParams: ['icd_10_code'],
  operation: (icd_10_code: any) =>
    AspireAPI.get('admin_tools/icd_10_code_problem_types', {
      params: { icd_10_code },
    }),
  transform: transformIcd10ProblemTypes,
  messages: {
    failed: 'There was an issue fetching ICD10 to Problem Types mapping.',
  },
})

export const addIcd10ToProblemType = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_ICD10_TO_PROBLEM_TYPE',
  requestParams: ['icd10ToProblemType'],
  operation: (icd10ToProblemType: any) =>
    AspireAPI.post('admin_tools/icd_10_code_problem_type', {
      ...icd10ToProblemType,
    }),
  messages: {
    succeeded: 'ICD10 to Problem Type mapping added successfully',
    failed: (error: any) => transformErrorResponse(error),
  },
})

export const editIcd10ToProblemType = Request({
  typePrefix: rootKey,
  typeBase: 'EDIT_ICD10_TO_PROBLEM_TYPE',
  requestParams: ['icd10ToProblemType'],
  operation: (icd10ToProblemType: any) =>
    AspireAPI.put('admin_tools/icd_10_code_problem_type', {
      ...icd10ToProblemType,
    }),
  messages: {
    succeeded: 'ICD10 to Problem Type mapping updated successfully',
    failed: 'There was an issue updating ICD10 to Problem Type mapping.',
  },
})

export default createReducer(ICD10_TO_PROBLEM_TYPES, List(), {
  [fetchIcd10ToProblemTypes.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getIcd10ToProblemTypes = pipe(getRoot, get(ICD10_TO_PROBLEM_TYPES))
