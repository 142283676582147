import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    textAlign: 'center',
    width: 24,
    marginRight: spacing(1),
  },
  icon: {
    opacity: ({ opacity }) => opacity,
  },
}))

const LegendIcon = props => {
  const { title, icon } = props

  const classes = useStyles(props)

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <img className={classes.icon} src={icon.url} />
      </div>

      <Typography>{title}</Typography>
    </div>
  )
}

LegendIcon.propTypes = {
  title: PropTypes.string.isRequired,
  opacity: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  icon: PropTypes.object.isRequired,
}

export default LegendIcon
