import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import {
  fetchCarePlan,
  fetchLastCarePlan,
  problemsInitialized,
} from '~/features/problems/data'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_CARE_PLAN } from '../router'
import lastEncounterEpic from './lastEncounter'

const carePlanEpic = (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_CARE_PLAN)),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId =>
      of(
        problemsInitialized(),
        fetchLastCarePlan.requested(patientId),
        fetchCarePlan.requested(patientId)
      )
    )
  )
export default combineEpics(
  carePlanEpic,
  lastEncounterEpic(PATIENT_RECORD_CARE_PLAN)
)
