import React from 'react'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import JsonForm from '~/components/JsonForm'
import Tooltip from '~/components/Tooltip'
import PropTypes from '~/utils/propTypes'
import { Button, Fab, Icon, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Form } from '../data/form'
import * as videoVisitConsentFields from './fields'

const styles = ({ spacing, zIndex: { drawer } }) => ({
  button: {
    marginLeft: spacing(1),
  },
  fab: {
    bottom: spacing(5),
    position: 'fixed',
    right: spacing(5),
    zIndex: drawer + 1,
  },
  formPaper: {
    marginBottom: spacing(2),
    padding: spacing(2),
  },
  gridPaper: {
    padding: spacing(2),
  },
  headerTitle: {
    marginBottom: spacing(2),
  },
  icon: { marginRight: spacing(1) },
  panelButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const VideoVisitConsent = props => {
  const onCancel = () => props.formCancelled()

  const onChange = ({ errors, formData }) =>
    props.formUpdated(formData, Boolean(errors.length))

  const onError = errors => props.formErrored(Boolean(errors.length))

  const onFetch = () => props.formRequested(props.patientId)

  const onSubmit = ({ formData }) =>
    props.formCompletionRequested(formData, props.patientId)

  const { classes, form, pending, submitting } = props
  return (
    <React.Fragment>
      <DevXTitleHeader title={'Video Visit Consent Form'}></DevXTitleHeader>

      {form ? (
        <Paper className={classes.formPaper}>
          <JsonForm
            context={form.context}
            fields={videoVisitConsentFields}
            formData={form.formData}
            onChange={onChange}
            onError={onError}
            onSubmit={onSubmit}
            schema={form.schema}
            uiSchema={{ ...form.uiSchema, 'ui:disabled': submitting }}
          >
            {form.errored && (
              <Typography color="error" variant="body1">
                Errors exist on this form.
              </Typography>
            )}
            <Button
              className={classes.button}
              onClick={onCancel}
              variant="outlined"
            >
              <Icon className={classes.icon}>cancel</Icon> Cancel
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={form.errored || submitting}
              type="submit"
              variant="contained"
            >
              <Icon className={classes.icon}>done</Icon>
              Complete
            </Button>
          </JsonForm>
        </Paper>
      ) : (
        <Tooltip title="New Video Visit Consent Form" disabled={pending}>
          <Fab
            aria-label="New Video Visit Consent Form"
            className={classes.fab}
            color="primary"
            disabled={pending}
            onClick={onFetch}
          >
            <Icon>create</Icon>
          </Fab>
        </Tooltip>
      )}
    </React.Fragment>
  )
}

VideoVisitConsent.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.instanceOf(Form),
  formCancelled: PropTypes.func.isRequired,
  formErrored: PropTypes.func.isRequired,
  formCompletionRequested: PropTypes.func.isRequired,
  formRequested: PropTypes.func.isRequired,
  formUpdated: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default withStyles(styles)(VideoVisitConsent)
