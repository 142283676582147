import { combineEpics } from 'redux-observable'
import appEntered from './epics/appEntered'
import appListEntered from './epics/appListEntered'
import callAttemptCompleted from './epics/callAttemptCompleted'
import callListEntered from './epics/callListEntered'
import dashboardChanged from './epics/dashboardChanged'
import dashboardEntered from './epics/dashboardEntered'
import dashboardExited from './epics/dashboardExited'
import dashboardPatientChangeRequested from './epics/dashboardPatientChangeRequested'
import patientFiltersChanged from './epics/patientFiltersChanged'
import patientListEntered from './epics/patientListEntered'
import patientSNFAdmission from './epics/patientSNFAdmission'
import patientSnoozed from './epics/patientSnoozed'
import taskSelected from './epics/taskSelected'
import userIdRouteChanged from './epics/userIdRouteChanged'

export default combineEpics(
  appEntered,
  appListEntered,
  callAttemptCompleted,
  callListEntered,
  dashboardChanged,
  dashboardEntered,
  dashboardExited,
  dashboardPatientChangeRequested,
  patientFiltersChanged,
  patientListEntered,
  patientSnoozed,
  patientSNFAdmission,
  taskSelected,
  userIdRouteChanged
)
