import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import {
  REFERRAL_NOTE_CREATION_REQUEST,
  createReferralNote,
  referralCaseNoteSaved,
} from '../data/referralNotes'

const saveNote = (caseId: any, note: any) =>
  from(createReferralNote(caseId, note)).pipe(
    map(referralCaseNoteSaved),
    catchError(showError('Error! Unable to load case data'))
  )

export default (action$: any) =>
  action$.pipe(
    ofType(REFERRAL_NOTE_CREATION_REQUEST),
    mergeMap(({ note, caseId }) => loading(saveNote(caseId, note)))
  )
