import cx from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { saveButtonPressed } from '../data/orderDetail'

const styles = ({ palette, spacing }) => ({
  disabled: {
    '&.Mui-disabled': {
      backgroundColor: palette.background.default,
      color: palette.action.disabled,
    },
  },
  footer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: spacing(1),
  },
  iconButton: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    marginRight: spacing(2),
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
})

const SaveButton = ({ classes, unsavedChanges, onSave, orderId, icon }) => (
  <React.Fragment>
    {icon ? (
      <div>
        <Tooltip title="Save Order" key="saveOrder">
          <span>
            <IconButton
              classes={{ root: cx({ [classes.disabled]: !unsavedChanges }) }}
              className={classes.iconButton}
              disabled={!unsavedChanges}
              label="Preview PDF"
              onClick={() => onSave(orderId)}
            >
              <Icon>save</Icon>
            </IconButton>
          </span>
        </Tooltip>
      </div>
    ) : (
      <div className={classes.footer}>
        <Button
          onClick={() => onSave(orderId)}
          color="primary"
          variant="contained"
          size="small"
          disabled={!unsavedChanges}
        >
          {unsavedChanges ? 'Save' : 'Saved'}
        </Button>
      </div>
    )}
  </React.Fragment>
)

SaveButton.propTypes = {
  classes: PropTypes.object.isRequired,
  unsavedChanges: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  icon: PropTypes.bool,
}

SaveButton.defaultProps = {
  icon: false,
}

export default compose(
  connect(null, dispatch => ({
    onSave: id => dispatch(saveButtonPressed(id)),
  })),
  withStyles(styles)
)(SaveButton)
