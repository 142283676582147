import { ofType } from 'redux-observable'
import { entered, push } from 'redux-routable'
import { combineLatest, filter, map } from 'rxjs/operators'
import { ADMIN_TOOLS_USERS } from '~/apps/adminTools/router'
import { APP_DASHBOARD_HOME } from '~/apps/appDashboard/router'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ap... Remove this comment to see the full error message
import { CASE_MANAGER_DASHBOARD_HOME } from '~/apps/caseManagerDashboard/router'
import { CLINICAL_DIRECTOR_DASHBOARD_HOME } from '~/apps/clinicalDirectorDashboard/router'
import { COMMUNITY_HW_DASHBOARD_HOME } from '~/apps/communityHWDashboard/router'
import { CSS_DASHBOARD_APP_SCHEDULING_LIST } from '~/apps/cssDashboard/router'
import { IOC_DASHBOARD_ENCOUNTER_REVIEWS } from '~/apps/iocDashboard/router'
import { NOT_FOUND_ROLE } from '~/apps/notFound/router'
import { NURSE_DASHBOARD_HOME } from '~/apps/nurseDashboard/router'
import { PES_DASHBOARD_APP_LIST } from '~/apps/pesDashboard/router'
import { PHARMACIST_DASHBOARD_HOME } from '~/apps/pharmacistDashboard/router'
import { PHYSICIAN_DASHBOARD_HOME } from '~/apps/physicianDashboard/router'
import { REFERRAL_MANAGEMENT_HOME } from '~/apps/referralManagement/router'
import { SW_DASHBOARD_HOME } from '~/apps/swDashboard/router'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { fetchUser, getUser } from '~/data/session'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import router from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(entered(router)),
    combineLatest(action$.pipe(ofType(fetchUser.SUCCEEDED))),
    switchTo(state$),
    map(getUser),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ aspireRole }: { aspireRole: a... Remove this comment to see the full error message
    map(({ aspireRole }) => {
      switch (aspireRole) {
        case 'APP':
        case 'Field-APP':
        case 'Field-Other':
        case 'Field-Team Lead':
          return push(APP_DASHBOARD_HOME)
        case 'Carelon-Case-Manager':
          return push(CASE_MANAGER_DASHBOARD_HOME)
        case 'CHW':
          return push(COMMUNITY_HW_DASHBOARD_HOME)
        case 'CLINICAL DIRECTOR':
          return push(CLINICAL_DIRECTOR_DASHBOARD_HOME)
        case 'ADMIN':
        case 'Corporate-Other':
          return push(ADMIN_TOOLS_USERS)
        case 'Corporate-Provider Relations':
          return push(REFERRAL_MANAGEMENT_HOME)
        case 'Field-MD':
          return push(PHYSICIAN_DASHBOARD_HOME)
        case 'Field-RN':
        case 'PCC-RN':
        case 'PCC-NSS':
          return push(NURSE_DASHBOARD_HOME)
        case 'PCC-SW':
        case 'Field-SW':
          return push(SW_DASHBOARD_HOME)
        case 'CARE SUPPORT SPECIALIST':
        case 'PCC-CSS':
        case 'PCC-Other':
          return push(CSS_DASHBOARD_APP_SCHEDULING_LIST)
        case 'PCC-ICC':
        case 'PCC-OC':
          return push(IOC_DASHBOARD_ENCOUNTER_REVIEWS)
        case 'PCC-SC':
        case 'SCHEDULING COORDINATOR':
          return push(PES_DASHBOARD_APP_LIST)
        case 'PHARMACIST':
          return push(PHARMACIST_DASHBOARD_HOME)
        default:
          return push(NOT_FOUND_ROLE)
      }
    })
  )
