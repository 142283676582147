import cx from 'classnames'
import { isEmpty } from 'lodash'
import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { H6, Subtitle1 } from '~/components/CustomTypography'
import JsonForm from '~/components/JsonForm'
import { areRequestsPending } from '~/data/pending'
import {
  PatientSearchBar,
  getPatientSearchResults,
  patientSearchAutoSuggested,
} from '~/features/search'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Icon,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { NEW_TASK } from '../constants'
import {
  NewTaskForm,
  createNewTask,
  createNewTaskErrored,
  createNewTaskFormUpdated,
  createNewTaskSubmitted,
  createNewTaskUpdated,
  fetchNewTaskForm,
  getCreateNewTaskForm,
  getFormDataByTag,
} from '../data/createNewTask'
import { dialogClosed, getDialogState } from '../data/dialog'
import { getTaskDefinitionsList } from '../data/taskDefinitions'
import PatientSelect from './PatientSelect'
import TaskSelect from './TaskSelect'

const createNewTaskIsOpen = state => {
  const { open, type } = getDialogState(state)
  return type == NEW_TASK && open
}

const createNewTaskForPatient = state => {
  const { patientId } = getDialogState(state)
  return Boolean(patientId)
}

const NewTaskDialog = ({ classes }) => {
  const formRef = useRef(null)
  const taskDefinitionsList = useSelector(getTaskDefinitionsList)
  const createNewTaskForm = useSelector(getCreateNewTaskForm)
  const open = useSelector(createNewTaskIsOpen)
  const selectedPatient = useSelector(createNewTaskForPatient)
  const patients = useSelector(getPatientSearchResults)
  const pending = useSelector(state =>
    areRequestsPending(state, [fetchNewTaskForm, createNewTask])
  )

  const onUpdated = useAction(createNewTaskUpdated)
  const onReset = () => onUpdated(NewTaskForm())
  const onSelectPatient = ({ target: { value } }) =>
    onUpdated({ patientId: value })
  const onResetPatient = () => onUpdated({ patientId: null })
  const onSelectTaskDefinition = ({ target: { value } }) =>
    onUpdated({ taskDefinitionKey: value })

  const onFormChanged = useAction(createNewTaskFormUpdated)

  const onPatienSearchAutosuggested = useAction(patientSearchAutoSuggested)

  const onChangeSearch = value => {
    onResetPatient()
    onPatienSearchAutosuggested(value)
  }

  const onSubmit = useAction(createNewTaskSubmitted)
  const onClose = useAction(dialogClosed)
  const onNewTaskErrored = useAction(createNewTaskErrored)
  const onDialogClosed = useCallback(() => {
    onReset()
    onClose()
  }, [onReset, onClose])

  const disabled = isEmpty(createNewTaskForm.schema) || pending

  const subheadingText = createNewTaskForm.patientId
    ? 'Select a Task'
    : 'Select a Patient, and Task'

  if (!open) return null

  return (
    <Dialog
      aria-labelledby="new-task-form"
      disableBackdropClick
      fullWidth
      maxWidth="lg"
      onClose={dialogClosed}
      onExited={onReset}
      open={open}
    >
      <DialogTitle disableTypography>
        <H6>Create Task</H6>
        <Subtitle1>{subheadingText}</Subtitle1>
      </DialogTitle>
      <DialogContent>
        {!selectedPatient && (
          <React.Fragment>
            <FormControl className={classes.patientSearch} required>
              <PatientSearchBar
                autoFocus
                onChangeSearch={onChangeSearch}
                onSearch={onResetPatient}
              />
              <FormHelperText className={classes.helper}>
                Type to automatically search
              </FormHelperText>
            </FormControl>
            <PatientSelect
              className={cx(classes.field, classes.helper)}
              onChange={onSelectPatient}
              patientId={createNewTaskForm.patientId}
              patients={patients}
            />
          </React.Fragment>
        )}
        {createNewTaskForm.patientId && (
          <TaskSelect
            className={classes.field}
            taskDefinitionsList={taskDefinitionsList}
            onChange={onSelectTaskDefinition}
            taskDefinitionKey={createNewTaskForm.taskDefinitionKey}
          />
        )}
        {createNewTaskForm.schema && (
          <JsonForm
            compact
            debounce
            formRef={formRef}
            context={createNewTaskForm.context}
            formData={createNewTaskForm.formData}
            getFormDataByTag={getFormDataByTag}
            onChange={onFormChanged}
            onError={errors => onNewTaskErrored(Boolean(errors.length))}
            onSubmit={onSubmit}
            schema={createNewTaskForm.schema}
            uiSchema={{
              ...createNewTaskForm.uiSchema,
              'ui:disabled': disabled,
            }}
          />
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        {createNewTaskForm.errored && (
          <Typography color="error" variant="body1">
            Errors exist on this form.
          </Typography>
        )}
        <Button
          className={classes.button}
          onClick={onDialogClosed}
          variant="outlined"
        >
          <Icon className={classes.icon}>cancel</Icon> Cancel
        </Button>
        <Button
          className={classes.button}
          color="primary"
          disabled={disabled}
          type="submit"
          variant="contained"
          onClick={() => formRef.current.submit()}
        >
          <Icon className={classes.icon}>done</Icon>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewTaskDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = ({ spacing }) => ({
  field: {
    marginBottom: spacing(2),
    width: '100%',
  },
  helper: {
    marginBottom: spacing(2),
  },
  patientSearch: { width: '100%' },
})

export default compose(withStyles(styles), React.memo)(NewTaskDialog)
