import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { CONTACT_MANAGEMENT_HOSPICE_RECORD } from '../router'
import { getRoot } from './common/shared'

const key = 'currentHospiceId'

const reducer = paramsReducer(
  CONTACT_MANAGEMENT_HOSPICE_RECORD,
  ({ hospiceId }: any) => hospiceId
)

reducer.key = key

export const getCurrentHospiceId = pipe(getRoot, get(key))

export default reducer
