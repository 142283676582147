import { combineReducers } from 'redux-immutable'
import accessAuthorization from './data/accessAuthorization'
import admissions from './data/admissions'
import claims from './data/claims'
import outlierReviewHistory from './data/outlierReviewHistory'
import panelAvailable from './data/panelAvailable'
import { currentContact } from './data/patientContactData'
import { contactDialog, contactDialogType } from './data/patientContactDialog'
import {
  patientContactFormAddresses,
  patientContactFormEmails,
  patientContactFormPhones,
} from './data/patientContactForm'
import { patientContact } from './data/patientContacts'
import {
  deleteContact,
  setDecisionMaker,
  setPreferred,
} from './data/patientContactsContext'
import patientId from './data/patientId'
import referrals from './data/referrals'
import searchFields from './data/searchFields'
import key from './key'

const reducer = combineReducers({
  [admissions.key]: admissions,
  [claims.key]: claims,
  [outlierReviewHistory.key]: outlierReviewHistory,
  [panelAvailable.key]: panelAvailable,
  [patientId.key]: patientId,
  [accessAuthorization.key]: accessAuthorization,
  [referrals.key]: referrals,
  [searchFields.key]: searchFields,
  [contactDialog.key]: contactDialog,
  [contactDialogType.key]: contactDialogType,
  [patientContact.key]: patientContact,
  [patientContactFormAddresses.key]: patientContactFormAddresses,
  [patientContactFormEmails.key]: patientContactFormEmails,
  [patientContactFormPhones.key]: patientContactFormPhones,
  [setDecisionMaker.key]: setDecisionMaker,
  [setPreferred.key]: setPreferred,
  [currentContact.key]: currentContact,
  [deleteContact.key]: deleteContact,
})

reducer.key = key

export default reducer
