import React from 'react'
import { Tooltip, Typography } from '@material-ui/core'

export default function DisplayComponentWithTooltip(
  Component: React.ElementType
) {
  const WithTooltip = (props: unknown) => (
    <Tooltip title={<Component {...props} />}>
      <Typography variant="inherit" component="span">
        <Component {...props} />
      </Typography>
    </Tooltip>
  )

  return WithTooltip
}
