/* Ported from Material UI v0 colorManipulator utils*/

function convertHexToRGB(color) {
  if (color.length === 4) {
    var extendedColor = '#'
    for (var i = 1; i < color.length; i++) {
      extendedColor += color.charAt(i) + color.charAt(i)
    }
    color = extendedColor
  }

  var values = {
    r: parseInt(color.substr(1, 2), 16),
    g: parseInt(color.substr(3, 2), 16),
    b: parseInt(color.substr(5, 2), 16),
  }

  return 'rgb(' + values.r + ', ' + values.g + ', ' + values.b + ')'
}

function decomposeColor(color) {
  if (color.charAt(0) === '#') {
    return decomposeColor(convertHexToRGB(color))
  }

  var marker = color.indexOf('(')

  var type = color.substring(0, marker)
  var values = color.substring(marker + 1, color.length - 1).split(',')
  values = values.map(function (value) {
    return parseFloat(value)
  })

  return { type: type, values: values }
}

function convertColorToString(color) {
  var type = color.type,
    values = color.values

  if (type.indexOf('rgb') > -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    for (var i = 0; i < 3; i++) {
      values[i] = parseInt(values[i])
    }
  }

  var colorString = void 0

  if (type.indexOf('hsl') > -1) {
    colorString =
      color.type + '(' + values[0] + ', ' + values[1] + '%, ' + values[2] + '%'
  } else {
    colorString =
      color.type + '(' + values[0] + ', ' + values[1] + ', ' + values[2]
  }

  if (values.length === 4) {
    colorString += ', ' + color.values[3] + ')'
  } else {
    colorString += ')'
  }

  return colorString
}

function clamp(value, min, max) {
  if (value < min) {
    return min
  }
  if (value > max) {
    return max
  }
  return value
}

function fade(color, value) {
  color = decomposeColor(color)
  value = clamp(value, 0, 1)

  if (color.type === 'rgb' || color.type === 'hsl') {
    color.type += 'a'
  }
  color.values[3] = value

  return convertColorToString(color)
}

export default fade
