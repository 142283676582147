import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { withTheme } from '@material-ui/core/styles'
import Marker from '../Marker'
import createIcon from './createIcon'

const PlaceMarker = ({ theme, ...props }) => {
  const { color, scale } = props

  const icon = useMemo(() => PlaceMarker.renderIcon(theme, color, scale), [
    theme,
    color,
    scale,
  ])

  return <Marker {...props} icon={icon} />
}

PlaceMarker.renderIcon = (theme, color, scale) =>
  createIcon({
    scale,
    size: { height: 24, width: 24 },
    icon: `
    <path fill="${color}" stroke="${theme.palette.map.markers.stroke}" stroke-width="1" d="M12 2C8.13 2 5
    5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38
    0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5
    2.5z"/>`,
  })

PlaceMarker.propTypes = {
  theme: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  scale: PropTypes.number,
}

PlaceMarker.defaultProps = {
  scale: 1,
}

export default withTheme(PlaceMarker)
