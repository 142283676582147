import cx from 'classnames'
import React from 'react'
import { Link } from 'redux-routable-react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.severity.warning.dynamic,
    textDecoration: 'none',
  },
}))

type Props = {
  className?: string
  action?: 'open' | 'push'
  params?: any
  route?: string
  children: string
}

const TypographyLink = (props: Props) => {
  const classes = useStyles()
  const { className = '', children } = props

  return (
    <Typography
      {...props}
      className={cx(classes.root, className)}
      component={'route' in props ? Link : 'a'}
      variant="body2"
    >
      {children}
    </Typography>
  )
}

export default TypographyLink
