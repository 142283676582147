import moment from 'moment'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
})
const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mma'

const OrderHistory = ({ history, classes }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">History</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table className={classes.root} size="small">
          <TableHead>
            <TableRow>
              <TableCell size="small">Description</TableCell>
              <TableCell size="small">User</TableCell>
              <TableCell size="small">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map(row => {
              return (
                <TableRow className={classes.row} key={row.id}>
                  <TableCell size="small">{row.description}</TableCell>
                  <TableCell size="small">{row.userName}</TableCell>
                  <TableCell size="small">
                    {moment.utc(row.createdAt).local().format(DATE_TIME_FORMAT)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

OrderHistory.propTypes = {
  history: PropTypes.array,
  classes: PropTypes.object,
}

export default withStyles(styles)(OrderHistory)
