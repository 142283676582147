import React from 'react'
import JsonForm from '~/components/JsonForm'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  container: {
    margin: [22, 0],
    padding: 16,
  },
}

const HistoricalOnCallForm = ({ classes, form }) => (
  <Paper className={classes.container} square>
    <JsonForm
      context={form.context}
      formData={form.formData}
      schema={form.schema}
      uiSchema={{
        ...form.uiSchema,
        'ui:disabled': true,
      }}
    />
  </Paper>
)

HistoricalOnCallForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default withStyles(styles)(HistoricalOnCallForm)
