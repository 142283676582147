import React from 'react'
import Flag from '~/containers/Flag'
import { Typography } from '@material-ui/core'
import Orders from '../Orders'

// feature toggle for orders components
const FlagOrders = props => {
  const NoPermissions = () => (
    <Typography variant="h5">
      You do not have the proper permissions to see orders.
    </Typography>
  )

  return (
    <Flag
      flagName="canViewOrders"
      component={Orders}
      fallbackComponent={NoPermissions}
      {...props}
    />
  )
}

export default FlagOrders
