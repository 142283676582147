import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { PATIENT_RECORD_STAKEHOLDERS } from '~/apps/patientRecord/router'
import {
  fetchStakeholders,
  stakeholdersInitialized,
} from '~/features/stakeholders'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_STAKEHOLDERS)),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId =>
      of(stakeholdersInitialized(), fetchStakeholders.requested(patientId))
    )
  )
