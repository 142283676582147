import { get } from 'lodash'

export default ({ formData, tags, schema }, tag, options = {}) => {
  const { defaultVal, decode = false } = options

  if (tag in tags) {
    const paths = tags[tag]
    const subSchema = get(schema, paths.schema)
    const value = get(formData, paths.data, defaultVal)

    if (decode && value !== undefined && 'enum' in subSchema) {
      return decodeValue(value, subSchema)
    } else if (decode && value !== undefined && subSchema.type === 'array') {
      return value.map(v => decodeValue(v, subSchema.items))
    } else {
      return value
    }
  } else {
    return defaultVal
  }
}

const decodeValue = (value, schema) => {
  if (schema.enum && schema.enumNames) {
    const index = schema.enum.indexOf(value)
    return schema.enumNames[index]
  }
  if (schema.type == 'object') {
    return Object.entries(value).reduce((acc, [k, v]) => {
      acc[k] = decodeValue(v, schema.properties[k])
      return acc
    }, {})
  }
  return value
}
