// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import fetchFailedFaxes from './data/faxes'
import key from './key'

const reducer = combineReducers({
  [fetchFailedFaxes.key]: fetchFailedFaxes,
})

reducer.key = key

export default reducer
