export default {
  contacts: [
    {
      name: 'Mychigan J. Frung',
      phiVoicemailApproved: null,
      phone: '(234) 567-8901',
      relationship: 'Friend of a friend',
    },
  ],
  demographics: {
    name: 'Reginald Tayynt',
    dateOfBirth: '1950-11-14',
    phone: '(666) 666-6669',
  },
}
