import React from 'react'
import { useDialogState } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import EventActions from '../../Events/EventActions'
import CancellationDialog from './CancellationDialog'
import RescheduleDialog from './RescheduleDialog'

const VisitEventActions = props => {
  const {
    cancelVisitRequested,
    canDelete,
    canSave,
    creating,
    editing,
    editVisitRequested,
    event,
    invalidEvent,
    newPermissions,
    noMaxVisits,
    noVisitsRemaining,
    onConfirmSave,
    onVisitSave,
    renderRescheduleDialog,
    rescheduleDialogProps,
    rescheduleReasonRequired,
    virtualVisitRequirementsNotMet,
  } = props

  const [
    renderCancellationDialog,
    openCancellationDialog,
    cancellationDialogProps,
  ] = useDialogState()

  const onRescheduleVisit = reschedule => {
    editVisitRequested(event, reschedule)
    props.onClose()
  }

  const onCancelVisit = cancellation => {
    cancelVisitRequested(event.id, cancellation)
    props.onClose()
  }

  const isEditing = editing && !event.cancelled
  const cancelDisabled = !(
    (isEditing || newPermissions.canCancelVisit) &&
    canDelete &&
    event.id
  )

  const savingDisabled =
    (!noMaxVisits && creating && noVisitsRemaining) ||
    virtualVisitRequirementsNotMet ||
    event.locationPending

  const rescheduleDisabled =
    !creating &&
    !(
      (isEditing || newPermissions.canCancelVisit) &&
      canSave &&
      event.patientId &&
      event.encounterType
    )

  return (
    <EventActions
      {...props}
      leftActions={
        <Button
          disabled={cancelDisabled}
          color="secondary"
          onClick={openCancellationDialog}
        >
          Cancel Visit...
        </Button>
      }
      rightActions={
        <Button
          disabled={rescheduleDisabled || savingDisabled}
          color="primary"
          onClick={invalidEvent ? onConfirmSave : onVisitSave}
        >
          {rescheduleReasonRequired ? 'Save and Reschedule...' : 'Save'}
        </Button>
      }
    >
      {renderRescheduleDialog && (
        <RescheduleDialog
          onRescheduleVisit={onRescheduleVisit}
          {...rescheduleDialogProps}
        />
      )}

      {renderCancellationDialog && (
        <CancellationDialog
          onCancelVisit={onCancelVisit}
          {...cancellationDialogProps}
        />
      )}
    </EventActions>
  )
}

VisitEventActions.propTypes = {
  cancelVisitRequested: PropTypes.func,
  canCreate: PropTypes.bool,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  canSave: PropTypes.bool,
  creating: PropTypes.bool,
  editing: PropTypes.bool,
  editVisitRequested: PropTypes.func,
  event: PropTypes.record.isRequired,
  invalidEvent: PropTypes.string,
  newPermissions: PropTypes.object,
  noMaxVisits: PropTypes.bool,
  noVisitsRemaining: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirmSave: PropTypes.func,
  onEventChange: PropTypes.func,
  onVisitSave: PropTypes.func,
  renderRescheduleDialog: PropTypes.bool,
  rescheduleDialogProps: PropTypes.object,
  rescheduleReasonRequired: PropTypes.bool,
  virtualVisitRequirementsNotMet: PropTypes.bool,
}

export default VisitEventActions
