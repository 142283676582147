import React, { PureComponent } from 'react'
import PropTypes from '~/utils/propTypes'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'

class SuccessSnackbar extends PureComponent {
  render = () => {
    const { classes, successMessages } = this.props

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!successMessages.isEmpty()}
        message={
          <div>
            {/* <span className={classes.header} hidden={successMessages.isEmpty()}>
              Completed
            </span> */}
            {successMessages.map(message => (
              <p key={message} className={classes.noMargin}>
                {message}
              </p>
            ))}
          </div>
        }
      />
    )
  }
}

SuccessSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  successMessages: PropTypes.set.isRequired,
}

const styles = {
  noMargin: {
    margin: 0,
  },
}

export default withStyles(styles)(SuccessSnackbar)
