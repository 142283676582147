import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import {
  Assessment,
  transformAssessment,
  // @ts-expect-error no export
} from '~/apps/assessment/data/assessments'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { Patient, transformPatient } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  closePendingStakeholderEncounterReview,
  getRoot,
} from './common/shared'
import { getCurrentEncounterReviewId } from './currentEncounterReviewId'

const ENCOUNTER_REVIEWS = 'encounterReviews'

const User = Record({
  id: null,
  name: null,
})

export const EncounterReview = Record({
  id: null,
  encounterFormData: {},
  encounterReviewFormData: {},
  modifiedAt: null,
  encounterId: null,
  encounter: Assessment(),
  patientId: null,
  patient: Patient(),
  physician: User(),
  notes: null,
})

const transformEncounterReview = ({
  encounter,
  patient,
  physician,
  form,
  ...rest
}: any) =>
  EncounterReview({
    ...rest,
    encounter: transformAssessment(encounter),
    patient: transformPatient(patient),
    physician: User(physician),
  })

export const fetchPendingStakeholderEncounterReviews = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PENDING_STAKEHOLDER_ENCOUNTER_REVIEWS',
  operation: () =>
    AspireAPI.get('encounters/encounter_reviews/pending_stakeholder'),
  transform: into(transformEncounterReview as any, 'id'),
  messages: {
    failed: 'There was an issue getting the encounter review list',
  },
})

const initState = Map()
export default createReducer(ENCOUNTER_REVIEWS, initState, {
  [fetchPendingStakeholderEncounterReviews.SUCCEEDED]: (
    _state: any,
    { payload }: any
  ) => payload,
  [closePendingStakeholderEncounterReview.SUCCEEDED]: (
    state: any,
    {
      meta: {
        request: {
          payload: { encounterReviewId },
        },
      },
    }: any
  ) => state.delete(encounterReviewId),
})

export const getEncounterReviews = pipe(getRoot, get(ENCOUNTER_REVIEWS))
export const getEncounterReviewsCount = createSelector(
  [getEncounterReviews],
  encounterReviews =>
    (encounterReviews as any).filter(({ status }: any) => status !== 'closed')
      .size
)
export const getCurrentEncounterReview = (state: any) =>
  getEncounterReviews(state).get(
    getCurrentEncounterReviewId(state),
    EncounterReview()
  )
