import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BaseField from './BaseField'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginRight: spacing(1),
  },
}))

const MultiSelectField = ({ values, editing, ...props }) => {
  const classes = useStyles()

  const onChange = (value, selected) => () => {
    props.onChange(
      selected ? props.selected.remove(value) : props.selected.add(value)
    )
  }

  const renderNewValue = value => {
    const id = value.get('id')
    const text = value.get('text')
    const selected = props.selected.includes(id)
    const variant = selected ? 'contained' : undefined
    const color = selected ? 'secondary' : undefined

    return (
      <Button
        key={id}
        className={classes.button}
        size="small"
        disabled={!editing}
        variant={variant}
        color={color}
        onClick={onChange(id, selected)}
      >
        {text}
      </Button>
    )
  }

  return (
    <BaseField {...props} error={props.selected.isEmpty()}>
      {values.toIndexedSeq().map(renderNewValue)}
    </BaseField>
  )
}

MultiSelectField.propTypes = {
  selected: PropTypes.set.isRequired,
  values: PropTypes.map.isRequired,
  editing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default MultiSelectField
