import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

export const BATCH_LIST = 'batchList'

const BATCH_LIST_CLEARED = type(key, 'BATCHES_CLEARED')

const creator = scopedCreator(key)
export const batchListCleared = creator(BATCH_LIST_CLEARED)

const Batch = Record({
  batchId: null,
  claimGroup: null,
  title: null,
  description: null,
  status: null,
  batchDate: null,
  batchURL: null,
})

export type BatchType = {
  batchId: string
  claimGroup: string
  title: string
  description: string
  status: string
  batchDate: Date
  batchURL: string
}

const transformBatches = (batches: BatchType[]) => List(batches.map(Batch))

export const fetchBatches = Request({
  typePrefix: key,
  typeBase: 'FETCH_BATCHES',
  operation: () => AspireAPI.get('claims/batches'),
  transform: transformBatches,
  messages: {
    failed: 'There was an issue fetching batches',
  },
})

export const getBatches = pipe(getRoot, get(BATCH_LIST))

const initState = List()

export default createReducer(BATCH_LIST, initState, {
  // @ts-expect-error fix me
  [batchListCleared]: () => initState,
  [fetchBatches.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
