import { Redirect, Route, Router, Scope } from 'redux-routable'
import key from './key'

export const SW_DASHBOARD_APP_SCHEDULING_LIST = `${key}.appSchedulingList`
export const SW_DASHBOARD_CALENDAR = `${key}.calendar`
export const SW_DASHBOARD_CENSUS = `${key}.census`
export const SW_DASHBOARD_HIGH_RISK_HUDDLE = `${key}.highRiskHuddle`
export const SW_DASHBOARD_EPISODES_OF_CARE = `${key}.episodesOfCare`
export const SW_DASHBOARD_HOME = `${key}.home`
export const SW_DASHBOARD_MY_LISTS = `${key}.myLists`
export const SW_DASHBOARD_ON_CALL = `${key}.onCall`
export const SW_DASHBOARD_REFERRALS = `${key}.referrals`
export const SW_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const SW_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const SW_DASHBOARD_USER_TIMELINE = `${key}.userTimeline`

export const SW_DASHBOARD_TASKS_ROUTES = [
  SW_DASHBOARD_TASKS_BY_USER,
  SW_DASHBOARD_TASK_DETAIL,
]

export default Router([
  Scope(
    '/:userId?',
    Router([
      Route(SW_DASHBOARD_HOME, '/home'),
      Route(SW_DASHBOARD_EPISODES_OF_CARE, '/episodes-of-care'),
      Route(SW_DASHBOARD_CALENDAR, '/calendar'),
      Route(SW_DASHBOARD_APP_SCHEDULING_LIST, '/app-list'),
      Route(SW_DASHBOARD_CENSUS, '/census'),
      Route(SW_DASHBOARD_HIGH_RISK_HUDDLE, '/high-risk-huddle'),
      Route(SW_DASHBOARD_MY_LISTS, '/my-lists'),
      Route(SW_DASHBOARD_REFERRALS, '/referrals'),
      Route(SW_DASHBOARD_TASKS_BY_USER, '/tasks'),
      Route(SW_DASHBOARD_TASK_DETAIL, '/tasks/:taskId'),
      Route(SW_DASHBOARD_ON_CALL, '/on_call'),
      Route(SW_DASHBOARD_USER_TIMELINE, '/user-timeline'),
    ])
  ),
  Redirect(SW_DASHBOARD_HOME),
])
