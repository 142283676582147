import { Map } from 'immutable'
import { createSelector } from 'reselect'
import { getUsers } from '~/data/users'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import appKey from '../key'
import { transformCareTeam } from './careTeams'
import { getRoot } from './common/shared'

const key = 'careTeamAssignment'

export const fetchAvailableCareTeams = Request({
  typePrefix: appKey,
  typeBase: 'FETCH_ASSIGNMENT_CARE_TEAMS',
  requestParams: ['type', 'patientId'],
  operation: (type, patientId) =>
    AspireAPI.get(`care_teams/patient/${patientId}/care_team_assignment`, {
      params: { type },
    }),
  transform: into(transformCareTeam, 'id'),
  messages: { failed: 'There was a problem fetching potential care teams' },
})

const availableCareTeamsReducer = createReducer(key, Map(), {
  [fetchAvailableCareTeams.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchAvailableCareTeams.REQUESTED]: () => Map(), // clear on new requests
})

export const getAssignmentCareTeams = pipe(getRoot, get(key))

export const getAssignmentCareTeamsWithUsers = createSelector(
  [getAssignmentCareTeams, getUsers],
  (careTeams, users) => {
    return careTeams
      .map(ct => {
        const updatedMembers = ct.members.map(member => {
          const userDetail = users.get(member.userId)
          return member.set('user', userDetail)
        })

        return ct.set('members', updatedMembers.toList())
      })
      .toList()
      .toJS()
  }
)

export default availableCareTeamsReducer
