import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import {
  createTripsFetchInit,
  createTripsFetchSuccess,
} from '../data/fetchTripsStatus'
import { fetchPatientTrips } from '../data/trips'

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const fetchTripsSuccess = action$ =>
  action$.pipe(
    ofType(fetchPatientTrips.SUCCEEDED),
    map(() => createTripsFetchSuccess())
  )
// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const fetchTripsRequest = action$ =>
  action$.pipe(
    ofType(fetchPatientTrips.requested),
    map(() => createTripsFetchInit())
  )
