import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import {
  catchError,
  map,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  fetchPolygonCounts,
  polygonAreaSelected,
  polygonCountsFetched,
} from '../data/selectedPolygonsArea'
import { getSelectedPrograms } from '../data/selectedPrograms'
import showError from '../utils/showError'
import stringifyPath from '../utils/stringifyPath'

const polygonSelectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(polygonAreaSelected),
    pluck('payload'),
    withLatestFrom(state$),
    mergeMap(([{ polygon, polygonId }, state]) => {
      const path = stringifyPath(polygon.get('path'))
      const programs = getSelectedPrograms(state)

      return loading(
        from(fetchPolygonCounts(path, programs)).pipe(
          map(counts => polygonCountsFetched(polygonId, counts)),
          catchError(showError('Failed to fetch Polygon Counts.'))
        )
      )
    })
  )

export default polygonSelectedEpic
