import React from 'react'
import AuthorizedBase from '../containers/AuthorizedBase'

export default (config: any) => (Component: any) => {
  const AuthorizedComponent = (props: any) => (
    <AuthorizedBase {...config}>
      <Component {...props} />
    </AuthorizedBase>
  )

  return AuthorizedComponent
}
