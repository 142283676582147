import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { b64toBlob } from '../../../utils/data'
import {
  previewGenDocForm,
  submitGenDocForm,
} from '../data/generateDocumentForm'

export default action$ =>
  action$.pipe(
    ofType(previewGenDocForm.SUCCEEDED, submitGenDocForm.SUCCEEDED),
    tap(({ payload }) => {
      const blob = b64toBlob(payload.docContent, 'application/pdf')

      const url = window.URL.createObjectURL(blob)
      window.open(url)

      // Cleanup the url after a minute to avoid memory leaks over time
      setTimeout(() => window.URL.revokeObjectURL(url), 1000 * 60)
    }),
    ignoreElements()
  )
