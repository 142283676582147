import { parseISO } from 'date-fns'
import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const SNF = 'SNF'

const PATIENT_LIST = 'patientList'

export const PatientListItem = Record({
  id: null,
  callAttemptCount: null,
  callCountSinceLastCancelledVisit: null,
  callCountSinceLastResearchCallReset: null,
  calledToday: null,
  dischargeRecommendationReason: null,
  city: null,
  healthPlan: null,
  healthPlanSpecificPrograms: null,
  internalSubstatus: null,
  lastCallDate: null,
  lat: null,
  lng: null,
  market: null,
  name: null,
  nextScheduledVisitDateTime: null,
  nextTargetedVisitDate: null,
  noCallsSinceCallBackRequested: null,
  npId: null,
  npName: null,
  pcp: null,
  pcpGroup: null,
  postalCode: null,
  priority: null,
  programEnrolled: null,
  rank: null,
  referralDate: null,
  receiptOfReferralDate: null,
  mailingDate: null,
  mailingType: null,
  referralType: null,
  reinstated: null,
  reinstatementType: null,
  snoozeDate: null,
  state: null,
  status: null,
  score: null,
  substatus: null,
  visitLength: null,
  visitType: null,
  visitUnits: null,
  preferredEmailMissing: false,
  preferredPhoneMissing: false,
  virtualCapable: false,
  timeZone: null,
  primaryContract: null,
})

const transformPatientList = (data: any) =>
  Map(
    data.map(
      ({
        id,
        lastCallDate,
        nextScheduledVisitDateTime,
        nextTargetedVisitDate,
        ...patient
      }: any) => [
        id,
        PatientListItem({
          id: id,
          lastCallDate: lastCallDate && parseISO(lastCallDate),
          nextScheduledVisitDateTime:
            nextScheduledVisitDateTime && parseISO(nextScheduledVisitDateTime),
          nextTargetedVisitDate:
            nextTargetedVisitDate && parseISO(nextTargetedVisitDate),
          ...patient,
        }),
      ]
    )
  )

export const fetchPatientList = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_LIST',
  requestParams: ['userId', 'appId'],
  operation: (userId: any, appIds: any) =>
    AspireAPI.get('referral_scheduling/patients', {
      params: {
        user_id: userId,
        np_ids: appIds,
      },
    }),
  transform: transformPatientList,
  messages: {
    failed: 'There was an issue fetching the patient list',
  },
})

const creator = scopedCreator(rootKey)
export const patientSNFSubstatusSet = creator('PATIENT_SNF_SUBSTATUS_SET', [
  'patientId',
])
export const patientSnoozed = creator('PATIENT_SNOOZED', [
  'patientId',
  'snooze',
])
export const callAttemptCompleted = creator('CALL_ATTEMPT_COMPLETED', [
  'patientId',
])

const initState = Map()
export default createReducer(PATIENT_LIST, initState, {
  [fetchPatientList.REQUESTED]: () => initState,
  [fetchPatientList.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientSNFSubstatusSet]: (state: any, { payload: { patientId } }: any) =>
    state.update(patientId, (patient: any) => patient.set('substatus', SNF)),
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientSnoozed]: (state: any, { payload: { patientId, snooze } }: any) =>
    state.update(patientId, (patient: any) =>
      patient.set('snoozeDate', snooze)
    ),
  // @ts-expect-error can we deal with the toString being automatically called?
  [callAttemptCompleted]: (state: any, { payload: { patientId } }: any) =>
    state.update(patientId, (patient: any) => patient.set('calledToday', true)),
})

export const getPatientList = pipe(getRoot, get(PATIENT_LIST))
export const getPatientById = (state: any, id: any) =>
  getPatientList(state).get(id)
