import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { createStatusMapping, idToStatusName } from '../utils/statusMapping'
import { getRoot } from './common/shared'

const key = 'patientStatuses'

export const patientStatuses = ['Active', 'Scheduled', 'Referred']

// Create an object array where each object has a name and id key
export const patientStatusMappings = createStatusMapping(patientStatuses)
export const getPatientStatusName = idToStatusName(patientStatusMappings)

export const EMR_STATUS_SELECTED = type(rootKey, 'EMR_STATUS_SELECTED')

export const setSelectedPatientStatuses = creator(
  EMR_STATUS_SELECTED,
  'statuses'
)

export default createReducer(key, new Set(), {
  [EMR_STATUS_SELECTED]: (state, { payload: { statuses } }) =>
    new Set(statuses),
})

export const getSelectedPatientStatuses = pipe(getRoot, get(key))

export const getSelectedPatientStatusNames = state =>
  getSelectedPatientStatuses(state).map(getPatientStatusName)
