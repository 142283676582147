import { combineReducers } from 'redux-immutable'
import fetchDrugAlertsReducer from './data/alerts'
import allergy from './data/allergy'
import assessment from './data/assessment'
import mdtRefill from './data/mdtRefillRequests'
import medSearch from './data/medSearchBar'
import patientInformationReducer from './data/patientInformation'
import patientRx from './data/patientRx'
import pharmacySearch from './data/pharmacySearch'
import practiceSelection from './data/practiceSelection'
import selectedMedicationReducer from './data/selectedMedication'
import key from './key'

const reducer = combineReducers({
  [medSearch.key]: medSearch,
  [pharmacySearch.key]: pharmacySearch,
  [fetchDrugAlertsReducer.key]: fetchDrugAlertsReducer,
  [mdtRefill.key]: mdtRefill,
  [patientInformationReducer.key]: patientInformationReducer,
  [patientRx.key]: patientRx,
  [practiceSelection.key]: practiceSelection,
  [selectedMedicationReducer.key]: selectedMedicationReducer,
  [assessment.key]: assessment,
  [allergy.key]: allergy,
})

reducer.key = key

export default reducer
