import VisitCalendarEvent from './VisitCalendarEvent'
import VisitEventActions from './VisitEventActions'
import VisitEventDetail from './VisitEventDetail'
import defaults from './defaults'
import * as info from './info'
import * as permissions from './permissions'

export default {
  ...info,
  type: defaults.type,
  defaults: defaults,
  permissions: permissions,
  detailComponent: VisitEventDetail,
  calendarComponent: VisitCalendarEvent,
  actionsComponent: VisitEventActions,
}
