import React from 'react'
import { useSelector } from 'react-redux'
import JsonForm from '~/components/JsonForm'
// @ts-expect-error no export
import { getUserId } from '~/data/session'
import { useAction, useMountEffect } from '~/hooks'
import { Button, Typography, makeStyles } from '@material-ui/core'
import { Cancel, Done } from '@material-ui/icons'
import {
  cancelNomination,
  completeNomination,
  fetchNomination,
  getNomination,
  modifyDiscussion,
} from '../data'
import Nomination from './Nomination'

const useStyles = makeStyles(({ spacing }) => ({
  container: (gridWidth: number) => ({
    // padding on td + 1 extra spacing unit for right margin.
    maxWidth: gridWidth - spacing(5),
  }),
  button: {
    marginRight: spacing(1),
  },
}))

type Props = {
  nominationId: number
  gridWidth?: number
}

export default function Details({ nominationId, gridWidth = 1000 }: Props) {
  const classes = useStyles(gridWidth)

  const update = useAction(modifyDiscussion)
  const complete = useAction(completeNomination.requested)
  const cancel = useAction(cancelNomination.requested)

  const requestNomination = useAction(fetchNomination.requested)
  useMountEffect(() => requestNomination(nominationId))

  // Defaulting because http
  const nomination: any = useSelector(getNomination(nominationId)) || {}
  const { context, data, errored, schema, uiSchema } = nomination.form || {}

  const userId = useSelector(getUserId)
  const currentUserIsNominator = nomination.nominatedById === userId

  const done = !!(nomination.completedAt || nomination.cancelledAt)

  const showActions = currentUserIsNominator && !done

  return (
    <div className={classes.container}>
      <Nomination id={nomination.id} readOnly />

      {schema ? (
        <JsonForm
          compact={gridWidth < 600}
          schema={schema}
          uiSchema={{ ...uiSchema, 'ui:disabled': done }}
          formData={data}
          context={context}
          onChange={({ formData, errored }: any) =>
            // NOTE: see updateDiscussionEpic for persisting each note.
            update(nomination.id, formData, errored)
          }
          debounce
          onSubmit={() => complete(nomination.id)}
        >
          {errored && (
            <Typography color="error" variant="body1">
              Errors exist on this form.
            </Typography>
          )}

          {showActions && (
            <>
              <Button
                className={classes.button}
                color="primary"
                disabled={errored}
                type="submit"
                variant="contained"
                startIcon={<Done />}
              >
                Complete
              </Button>

              <Button
                onClick={() => cancel(nomination.id)}
                variant="outlined"
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </>
          )}
        </JsonForm>
      ) : (
        'Loading discussion...'
      )}
    </div>
  )
}
