import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL } from '../routes'
import { getRoot } from './common/shared'

// KEY
const key = 'currentCareTeamId'

// REDUCER
const reducer = paramsReducer(
  CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL,
  ({ careTeamId }) => Number(careTeamId)
)

reducer.key = key

// SELECTORS
export const getCurrentCareTeamId = pipe(getRoot, get(key))

export default reducer
