import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import {
  fetchInsuranceCoverages,
  saveNewInsuranceCoverage,
  updateInsuranceCoverage,
} from '../data/insuranceCoverages'

export default action$ =>
  action$.pipe(
    ofType(
      saveNewInsuranceCoverage.SUCCEEDED,
      updateInsuranceCoverage.SUCCEEDED
    ),
    pluck('payload', 'patientId'),
    mergeMap(id => of(fetchInsuranceCoverages.requested(id)))
  )
