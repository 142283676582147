import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchHealthPlans, getHealthPlans } from '../../data/healthPlans'

const columns = [{ name: 'name', title: 'Health Plan Name' }]
const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

const HealthPlanLookup = ({
  includeWrapper,
  rows,
  fetchHealthPlans,
  onSelect,
}) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search Health Plans"
    columns={columns}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchHealthPlans}
    onSelect={onSelect}
  />
)

HealthPlanLookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchHealthPlans: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
}

export default connect(
  state => ({
    rows: getHealthPlans(state),
  }),
  { fetchHealthPlans: fetchHealthPlans.requested }
)(HealthPlanLookup)
