import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Directions } from '~/components/GeoMap'
import { useAction } from '~/hooks'
import { colors } from '@material-ui/core'
import {
  directionsSet,
  getLocationA,
  getLocationB,
} from '../../data/quickLocations'

const QuickLocationDirections = () => {
  const locationA = useSelector(getLocationA)
  const locationB = useSelector(getLocationB)
  const setDirections = useAction(directionsSet)

  const locationACoords = useMemo(() => {
    const { lat, lng } = locationA
    return lat && lng ? { lat, lng } : null
  }, [locationA])

  const locationBCoords = useMemo(() => {
    const { lat, lng } = locationB
    return lat && lng ? { lat, lng } : null
  }, [locationB])

  return (
    <Directions
      origin={locationACoords}
      destination={locationBCoords}
      preserveViewport
      suppressMarkers
      strokeColor={colors.orange['500']}
      strokeOpacity={0.75}
      strokeWeight={5}
      onDirectionsChanged={setDirections}
    />
  )
}

export default React.memo(QuickLocationDirections)
