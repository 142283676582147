import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import rootKey from '../key'
import { getRoot } from './common/shared'

const USER_SIGNATURE = 'userSignature'

const UserSignature = Record({
  signature: null,
  signatureDatetime: null,
  acknowledgeSignature: false,
})

export const fetchUserSignature = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_USER_SIGNATURE',
  requestParams: ['userId'],
  operation: (userId: string) => AspireAPI.get(`v1/user/${userId}/signature`),
  transform: UserSignature,
  messages: { failed: 'There was an issue fetching your signature' },
})

export const saveUserSignature = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_USER_SIGNATURE',
  requestParams: ['userId', 'signature'],
  operation: (userId: string, signature: string) =>
    AspireAPI.put(`v1/user/${userId}/signature`, { signature }),
  transform: UserSignature,
  messages: {
    succeeded: 'Successfully saved your new signature',
    failed: 'There was an issue saving your signature',
  },
})

export default createReducer(USER_SIGNATURE, UserSignature(), {
  [fetchUserSignature.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [saveUserSignature.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getUserSignature = (state: any): any =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(USER_SIGNATURE)
