import { entered } from 'redux-routable'
import { filter, first, map, mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import * as Form from '~/features/hipaa/data/form'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_HIPAA_AUTHORIZATION } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_HIPAA_AUTHORIZATION)),
    first(),
    switchTo(state$),
    map(getPatientId),
    map(Form.fetch),
    mapTo(
      fetchDistinctFieldValues([
        'contact_type',
        'contact_address_type',
        'contact_phone_type',
        'contact_email_type',
      ])
    )
  )
