import { combineEpics } from 'redux-observable'
import cancellationReasons from './epics/cancellationReasons'
import clearOrdersState from './epics/clearOrdersState'
import createOrderDialogOpened from './epics/createOrderDialogOpened'
import execAction from './epics/execAction'
import fetchAllOrdersForPatient from './epics/fetchAllOrdersForPatient'
import fetchAllOrdersForUser from './epics/fetchAllOrdersForUser'
import fetchOrder from './epics/fetchOrder'
import loadPatientDetails from './epics/loadPatientDetails'
import onOrderActions from './epics/onOrderActions'
import orderSelected from './epics/orderSelected'
import previewPdf from './epics/previewPdf'
import refreshOrders from './epics/refreshOrders'
import saveIndicator from './epics/saveIndicator'
import saveOrder from './epics/saveOrder'

export default combineEpics(
  cancellationReasons,
  clearOrdersState,
  createOrderDialogOpened,
  execAction,
  fetchAllOrdersForPatient,
  fetchAllOrdersForUser,
  fetchOrder,
  loadPatientDetails,
  onOrderActions,
  orderSelected,
  previewPdf,
  refreshOrders,
  saveIndicator,
  saveOrder
)
