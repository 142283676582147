import { colors as muiColors } from '@material-ui/core'

export const colors = {
  text: 'white',
  background: muiColors.green['A700'],
}

export const label = 'Availability'

export const layer = 0

export const title = event => {
  const maxVisits = event.maxVisits
  const maxVisitSummary = maxVisits ? ` (${maxVisits} Max)` : ''

  return `${label}${maxVisitSummary}`
}

export default { colors, label, layer, title }
