import { ofType } from 'redux-observable'
import { concat, empty, of } from 'rxjs'
import {
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  merge,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  EXPECTED_FULFILLMENT_DATE_CHANGED,
  FULFILLMENT_DATE_CHANGED,
  ORDER_DETAIL_FORM_CHANGED,
  ORDER_NOTE_CHANGED,
  ORDER_SAVE_BUTTON_USED,
  OUT_OF_NETWORK_REASON_CHANGED,
  VENDOR_CHANGED,
  formSaveFailed,
  formSaveRequested,
  formSaveSuccess,
  getOrderDetail,
  saveOrder,
} from '../data/orderDetail'

const ON_CHANGE_AUTOSAVE_DELAY = 5000
const changeActions = [
  EXPECTED_FULFILLMENT_DATE_CHANGED,
  FULFILLMENT_DATE_CHANGED,
  ORDER_DETAIL_FORM_CHANGED,
  ORDER_NOTE_CHANGED,
  OUT_OF_NETWORK_REASON_CHANGED,
  VENDOR_CHANGED,
]

export default (action$, state$) => {
  const changeAction$ = action$.pipe(
    ofType(...changeActions, ORDER_SAVE_BUTTON_USED),
    debounceTime(ON_CHANGE_AUTOSAVE_DELAY),
    filter(({ type }) => type !== ORDER_SAVE_BUTTON_USED),
    pluck('payload', 'id')
  )

  const manualRequest$ = action$.pipe(
    ofType(ORDER_SAVE_BUTTON_USED),
    pluck('payload', 'id')
  )

  return changeAction$.pipe(
    merge(manualRequest$),
    withLatestFrom(state$),
    mergeMap(([id, state]) => {
      const detail = getOrderDetail(state, id)

      // It is possible for detail to not exist if the user manually refreshes the grid during a save cycle.
      // These checks and the empty() option keep the app from crashing when that happens
      const formData = detail && detail.form ? detail.form.data : null

      if (formData) {
        return loading(
          concat(
            of(formSaveRequested(id, formData)),
            saveOrder(
              id,
              formData,
              detail.note,
              detail.vendorId,
              detail.fulfillmentDate,
              detail.expectedFulfillmentDate,
              detail.outOfNetworkReason
            ).pipe(
              delay(1500),
              map(res => formSaveSuccess(id, res)),
              catchError(err => of(formSaveFailed(id, err)))
            )
          )
        )
      } else {
        return empty()
      }
    })
  )
}
