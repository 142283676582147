import { Record } from 'immutable'
import { changeFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error no export
import Request from '~/utils/Request'
// @ts-expect-error no export
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error no export
import rootKey from '../key'
// @ts-expect-error no export
import { getScreenings } from './root'

const HRA_SCREENING = 'hraScreening'

const creator = scopedCreator(rootKey)
export const hraFormUpdated = creator('HRA_FORM_UPDATED')
export const hraFormSubmitted = creator('HRA_FORM_SUBMITTED')
export const hraFormErrored = creator('HRA_FORM_ERRORED')
export const hraFormCleared = creator('HRA_FORM_CLEARED')

export const HRAForm = Record({
  patientId: null,
  context: {},
  errored: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
})

const transformHRAForm = (form: any) =>
  changeFormData(HRAForm(form), null, form.formData)

export const fetchNewHRAForm = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_HRA_SCREENING',
  requestParams: ['patientId', 'selected'],
  operation: (patientId: string, selected: string) =>
    AspireAPI.get(`patient_record/${patientId}/screenings/hra`, {
      params: { selected },
    }),
  transform: transformHRAForm,
  messages: { failed: 'Failed to Start New HRA Screening' },
})

export const createHRAForm = Request({
  typePrefix: rootKey,
  typeBase: 'SUBMIT_HRA_SCREENING',
  requestParams: ['patientId', 'form', 'selected'],
  operation: (patient_id: string, form: any, selected: any) =>
    AspireAPI.post(`patient_record/${patient_id}/screenings/hra`, {
      patient_id,
      form,
      selected,
    }),
  messages: {
    failed: (e: any) => {
      return e.response.data.message || 'Failed to save HRA'
    },
    succeeded: (e: any) =>
      e === 'hospice_hra' ? 'Hospice HRA Saved' : 'HRA Saved',
  },
})

const initState = HRAForm()
export default createReducer(HRA_SCREENING, initState, {
  [fetchNewHRAForm.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [hraFormUpdated]: (state: any, { payload: { errors, formData } }: any) =>
    changeFormData(state, null, formData).set(
      'errored',
      Boolean(errors.length)
    ),
  // @ts-expect-error can we deal with the toString being automatically called?
  [hraFormErrored]: (state: any, { payload }: any) =>
    state.set('errored', payload),
  // @ts-expect-error can we deal with the toString being automatically called?
  [hraFormCleared]: () => initState,
})

export const getCreateHRAForm = pipe(getScreenings, get(HRA_SCREENING))
