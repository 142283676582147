import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import PatientSearchBar from '../containers/PatientSearchBar'
import { getPatientSearchResults } from '../data/patientSearch'
import BasicSearchResults from './BasicSearchResults'

const styles = {
  popover: {
    maxHeight: '500px',
    overflow: 'auto',
  },
}

class QuickPatientSearch extends PureComponent {
  state = {
    searchPopoverOpen: false,
  }

  onSelectPatient = ptId => {
    this.setState({ searchPopoverOpen: false })
    this.props.onSelectPatient && this.props.onSelectPatient(ptId)
  }

  changePopover = newValue => () => {
    this.setState({ searchPopoverOpen: newValue })
  }

  searchRef = ref => {
    this.anchorEl = ref
  }

  render() {
    const {
      classes,
      searchResults,
      linkDisabled,
      autoFocus,
      contrast,
      compact,
    } = this.props
    const { searchPopoverOpen } = this.state

    return (
      <React.Fragment>
        <PatientSearchBar
          searchRef={this.searchRef}
          compact={compact}
          contrast={contrast}
          autoFocus={autoFocus}
          onSearch={this.changePopover(true)}
        />
        <Popover
          open={searchPopoverOpen && !searchResults.isEmpty()}
          anchorEl={this.anchorEl}
          onClose={this.changePopover(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.popover}>
            <BasicSearchResults
              linkDisabled={linkDisabled}
              searchResults={searchResults}
              selectItem={this.onSelectPatient}
            />
          </div>
        </Popover>
      </React.Fragment>
    )
  }
}

QuickPatientSearch.propTypes = {
  classes: PropTypes.object,
  searchResults: PropTypes.list,
  onSelectPatient: PropTypes.func,
  linkDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  contrast: PropTypes.bool,
  compact: PropTypes.bool,
}

QuickPatientSearch.defaultProps = {
  contrast: false,
  compact: false,
  autoFocus: false,
}

const stateToProps = state => ({
  searchResults: getPatientSearchResults(state),
})

export default connect(stateToProps)(withStyles(styles)(QuickPatientSearch))
