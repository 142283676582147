import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'markets'

export const FETCHED_MARKETS = type(rootKey, 'FETCHED_MARKETS')

export const loadMarkets = creator(FETCHED_MARKETS, 'markets')

export default createReducer(key, new Map(), {
  [FETCHED_MARKETS]: (state, { payload: { markets } }) => normalize(markets),
})

export const getMarkets = pipe(getRoot, get(key))

export const fetchMarkets = () => AspireAPI.get('v1/market')
