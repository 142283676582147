import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchMarkets, getMarkets } from '../../data/markets'

const columns = [
  { name: 'marketName', title: 'Market Name' },
  { name: 'leadPhysician', title: 'Lead Physician' },
  { name: 'marketDirector', title: 'Market Director' },
  { name: 'phone', title: 'Phone' },
  { name: 'fax', title: 'Fax' },
  { name: 'hotlinePhone', title: 'Hotline Phone' },
]
const defaultSorting = [{ columnName: 'marketName', direction: 'asc' }]

const MarketLookup = ({ includeWrapper, rows, fetchMarkets, onSelect }) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search Markets"
    columns={columns}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchMarkets}
    onSelect={onSelect}
  />
)

MarketLookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchMarkets: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
}

export default connect(
  state => ({
    rows: getMarkets(state),
  }),
  { fetchMarkets: fetchMarkets.requested }
)(MarketLookup)
