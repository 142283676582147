import React from 'react'
import {
  createStyles,
  Divider,
  Grid,
  GridSize,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    grid: {
      paddingTop: 6,
      paddingBottom: 3,
    },
    divider: {
      color: palette.divider,
    },
  })
)

type Props = {
  label: string
  children?: React.ReactNode
  labelWidth?: GridSize
  childrenWidth?: GridSize
  dividerWidth?: GridSize
}

const Info = ({
  label,
  children,
  labelWidth = 2,
  childrenWidth = 10,
  dividerWidth = 12,
}: Props) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid className={classes.grid} alignItems="center" container>
        <Grid item md={12} lg={3} xl={labelWidth}>
          <Typography color="textSecondary" noWrap variant="caption">
            {label}
          </Typography>
        </Grid>
        <Grid item md={12} lg={9} xl={childrenWidth}>
          <Typography component="span" variant="body2">
            {children}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12} lg={12} xl={dividerWidth}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Info
