import { ofType } from 'redux-observable'
import { first, mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import {
  fetchAPPPatientCensus,
  fetchCDPatientCensus,
  fetchCensusByRoleName,
  fetchMDPatientCensus,
} from '../data/census'

export default action$ =>
  action$.pipe(
    ofType(
      fetchAPPPatientCensus.REQUESTED,
      fetchCDPatientCensus.REQUESTED,
      fetchMDPatientCensus.REQUESTED,
      fetchCensusByRoleName.REQUESTED
    ),
    first(),
    mapTo(
      fetchDistinctFieldValues([
        'census_patient_list_type',
        'hospice_eligible',
        'provider_prognosis',
      ])
    )
  )
