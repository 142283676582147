import { creator, get, type } from '~/utils/data'
import key from '../../key'

export const OPEN_STATUSES = ['created', 'in_progress']
export const SIGNED_STATUSES = ['pending_md_review', 'closed']

export const THOS_ALLOWED_TYPES = ['rn']

export const SECTION_CHANGED = type(key, 'SECTION_CHANGED')
export const sectionChanged = creator(
  SECTION_CHANGED,
  'assessmentId',
  'section',
  'info'
)

export const FORM_DATA_CHANGED_BY_TAG = type(key, 'FORM_DATA_CHANGED_BY_TAG')
export const formDataChangedByTag = creator(
  FORM_DATA_CHANGED_BY_TAG,
  'assessmentId',
  'tag',
  'value'
)

export const ASSESSMENT_SIGNED = type(key, 'ASSESSMENT_SIGNED')
export const assessmentSigned = creator(
  ASSESSMENT_SIGNED,
  'ensurePhysicianReview'
)

export const ASSESSMENT_CANCELLED = type(key, 'ASSESSMENT_CANCELLED')
export const assessmentCancelled = creator(
  ASSESSMENT_CANCELLED,
  'reason',
  'reasonOther'
)

export const getAssessment = get(key)
