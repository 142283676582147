import React from 'react'
import { Map } from 'immutable'
import { FieldValueProps } from '~/data/fieldValues'

type IFieldValue = {
  label: React.ReactNode
  value: string
}

const FieldValue = ({ value = '', label }: IFieldValue) => (
  <option key={value as string} value={value}>
    {label}
  </option>
)

// TODO: Please fix the any types :D
export default (
  fieldValues: Map<string, any>,
  filterArray: FieldValueProps[] = []
) =>
  fieldValues
    .toIndexedSeq()
    .filter(({ value }) => !filterArray.includes(value))
    .map(FieldValue)
