import { List, Map } from 'immutable'
import { get as getIn } from 'lodash'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { Problem, ProblemDetail, getRoot, mapProblems } from '../common/shared'

// KEYS
export const CARE_PLAN = 'carePlan'

// ACTIONS
const creator = scopedCreator(rootKey)
export const carePlanSet = creator('CARE_PLAN_SET', ['problems', 'legacy'])
export const carePlanCleared = creator('CARE_PLAN_CLEARED')

// SELECTORS
export const getCarePlan = pipe(getRoot, get(CARE_PLAN))

// TRANSFORMERS
export const mapAssessmentCarePlan = ({ formData, tags }: any) => {
  const legacyData = getIn(formData, tags.problemsGoalsActions?.data)
  const data = getIn(formData, tags.assessmentAndPlanProblemsList?.data)

  return { carePlan: mapCarePlan(legacyData, data), legacy: !!legacyData }
}

export const mapCarePlan = (legacyData: any, data: any) => {
  const carePlan = legacyData
    ? mapLegacyCarePlan(legacyData)
    : mapProblems(data)

  return carePlan
}

const mapLegacyCarePlan = (data: any) => {
  const {
    problems,
    selectedProblems,
    selectedGoals,
    selectedActions,
    assessmentNotes,
  } = data

  let newProblems = []

  if (problems) {
    newProblems = problems.map((problem: any) => {
      const noteId = String(problem.id)

      return {
        ...problem,
        selected: selectedProblems.some(({ id }: any) => problem.id === id),
        problemAssessmentNotes: assessmentNotes?.problemNotes?.[noteId],
        actionAssessmentNotes: assessmentNotes?.actionNotes?.[noteId],
        goals: selectedGoals
          .filter((goal: any) => goal.problemId === problem.id)
          .map((goal: any) => ({
            ...goal,
            actions: selectedActions.filter(
              (action: any) => action.goalId === goal.id
            ),
          })),
      }
    })
  }

  return mapProblems(newProblems)
}

export const mapCarePlanProblemDetail = ({
  problemDetailType,
  ...detail
}: any) => ProblemDetail({ ...detail, rank: problemDetailType.rank })

const mapCarePlanProblemDetails = (details: any) =>
  details ? List(details.map(mapCarePlanProblemDetail)) : List()

export const mapCarePlanProblem = ({
  problemCategory,
  problemType,
  icd10Definition,
  details,
  ...problem
}: any) =>
  Problem({
    ...problem,
    category: problemCategory.category,
    subcategory: problemCategory.subcategory,
    problemTypeValid: problemType?.valid || problemType === null,
    icd10Description: icd10Definition?.description,
    details: mapCarePlanProblemDetails(details),
  })

const transformCarePlan = ({ locked, carePlanDisabled, problems }: any) => {
  const newProblems = Map(
    problems.map((problem: any) => [problem.id, mapCarePlanProblem(problem)])
  )

  return {
    locked,
    carePlanDisabled,
    problems: newProblems,
  }
}

// REQUESTS
export const fetchCarePlan = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CURRENT_CARE_PLAN',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`care_plan/patients/${patientId}`),
  transform: transformCarePlan,
  messages: { failed: 'There was an issue fetching the current care plan' },
})
