import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { EmailDetails } from '../../utils/interfaces'
import { ContactMethodList } from '../ContactMethodList'
import { EmailItem } from './EmailItem'

interface EmailListPropTypes {
  emails: PropTypes.list.isRequired
  asked: PropTypes.bool.isRequired
}

export const EmailList = ({ emails, asked }: EmailListPropTypes) => {
  return (
    <>
      {emails.length === 0 ? (
        asked ? (
          <ContactMethodList icon="email" label="Emails">
            {'<No email available>'}
          </ContactMethodList>
        ) : null
      ) : (
        <ContactMethodList icon="email" label="Emails">
          {emails
            .sort((_a: EmailDetails, b: EmailDetails) => (b.primary ? 1 : -1))
            .map((email: EmailDetails) => (
              <EmailItem key={email.id} email={email} />
            ))}
        </ContactMethodList>
      )}
    </>
  )
}
