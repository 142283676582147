import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Dialog, DialogTitle } from '@material-ui/core/'
import { CANCELLED } from '../constants'
import TaskDialogActions from './TaskDialogActions'

export default class CancelTaskDialog extends React.Component {
  static propTypes = {
    dialogClosed: PropTypes.func.isRequired,
    task: PropTypes.record.isRequired,
    taskUpdateRequested: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
  }

  onSubmit = () => {
    const {
      task: { id: taskId, status: currentStatus },
      taskUpdateRequested,
    } = this.props
    taskUpdateRequested(taskId, currentStatus, CANCELLED)
  }

  render() {
    const { dialogClosed, open, pending } = this.props

    return (
      <Dialog
        aria-labelledby="cancel-task-form"
        onClose={dialogClosed}
        open={open}
      >
        <DialogTitle id="cancel-task-form">Cancel this task?</DialogTitle>
        <TaskDialogActions
          disabled={pending}
          onCancel={dialogClosed}
          onSubmit={this.onSubmit}
        />
      </Dialog>
    )
  }
}
