import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { PATIENT_RECORD_ORDER_DETAIL } from '~/apps/patientRecord/router'
import { SelectField } from '~/components/fields'
import { OpenLink } from '~/components/links'
import PropTypes from '~/utils/propTypes'
import { DialogContentText, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import { NEW_ORDER, dialogClosed, getOrderDialogType } from '../data/dialog'
import { NewOrder, createOrder, getNewOrder } from '../data/newOrder'
import { getOrderSubTypes, getOrderTypes } from '../data/orderTypes'

const styles = {
  verticalContainer: { display: 'flex', flexDirection: 'column' },
}
class NewOrderDialog extends React.PureComponent {
  state = { orderType: '', orderSubtype: '', submitted: false }

  submitNewOrder = () => {
    const { orderType, orderSubtype } = this.state
    const { createOrder, patientId } = this.props
    createOrder(orderType, orderSubtype, patientId)
    this.setState({ submitted: true, orderType: '', orderSubtype: '' })
  }

  filterOrderSubTypesByType = type =>
    this.props.orderSubtypes.filter(x => x.get('type') == type)

  closeDialogAndResetState = () => {
    this.setState({ submitted: false, orderType: '', orderSubtype: '' })
    this.props.closeDialog()
  }

  render() {
    const { open, orderTypes, classes, newOrder } = this.props
    const { orderType, orderSubtype, submitted } = this.state

    const orderSubtypes = this.filterOrderSubTypesByType(orderType)

    const typesLoaded = orderTypes.size > 0

    return (
      <Dialog
        open={open}
        onClose={this.closeDialogAndResetState}
        disableBackdropClick={submitted}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>New Order</DialogTitle>
        {!submitted ? (
          <DialogContent>
            {typesLoaded ? (
              <div className={classes.verticalContainer}>
                <SelectField
                  label="Order Type"
                  value={this.state.orderType}
                  onChange={t =>
                    this.setState({ orderType: t, orderSubtype: '' })
                  }
                  native
                >
                  <option value="" disabled />
                  {orderTypes.entrySeq().map(([k, v]) => (
                    <option value={k} key={k}>
                      {v}
                    </option>
                  ))}
                </SelectField>
                {orderSubtypes.size > 0 && (
                  <SelectField
                    label="Order Subtype"
                    value={this.state.orderSubtype}
                    onChange={s => this.setState({ orderSubtype: s })}
                    native
                  >
                    <option value="" disabled />
                    {orderSubtypes.map(v => (
                      <option value={v.get('subtype')} key={v.get('subtype')}>
                        {v.get('description')}
                      </option>
                    ))}
                  </SelectField>
                )}
              </div>
            ) : (
              <DialogContentText>Loading order types...</DialogContentText>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <div className={classes.verticalContainer}>
              {newOrder.id ? (
                <Typography variant="body1">Order created</Typography>
              ) : (
                <Typography variant="body1">
                  Order submitted. Awaiting response.
                </Typography>
              )}
            </div>
          </DialogContent>
        )}
        {!submitted ? (
          <DialogActions>
            <Button color="secondary" onClick={this.closeDialogAndResetState}>
              Close
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.submitNewOrder}
              disabled={!(orderType && orderSubtype)}
            >
              Create
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button color="secondary" onClick={this.closeDialogAndResetState}>
              Close
            </Button>
            {newOrder.id && (
              <Button
                onClick={this.closeDialogAndResetState}
                params={{
                  orderId: newOrder.id,
                  patientId: newOrder.patientId,
                }}
                variant="contained"
                color="primary"
                component={OpenLink}
                route={PATIENT_RECORD_ORDER_DETAIL}
              >
                Go to Order Form
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

NewOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  orderTypes: PropTypes.map.isRequired,
  patientId: PropTypes.string.isRequired,
  createOrder: PropTypes.func.isRequired,
  orderSubtypes: PropTypes.list.isRequired,
  classes: PropTypes.object.isRequired,
  newOrder: PropTypes.instanceOf(NewOrder).isRequired,
}

export default compose(
  connect(
    state => ({
      newOrder: getNewOrder(state),
      open: getOrderDialogType(state) === NEW_ORDER,
      orderTypes: getOrderTypes(state),
      orderSubtypes: getOrderSubTypes(state),
    }),
    {
      closeDialog: dialogClosed,
      createOrder: createOrder.requested,
    }
  ),
  withStyles(styles)
)(NewOrderDialog)
