import React from 'react'
import { Link } from 'redux-routable-react'
import { CSS_DASHBOARD_APP_SCHEDULING_APP } from '~/apps/cssDashboard/router'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'

const APPLink = ({ appId, name, action }) => (
  <Typography
    component={Link}
    action={action}
    route={CSS_DASHBOARD_APP_SCHEDULING_APP}
    params={{ appId }}
    color="secondary"
    variant="body2"
  >
    {name}
  </Typography>
)

APPLink.propTypes = {
  appId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
}

export default React.memo(APPLink)
