import { connect } from 'react-redux'
import MedSearch from '../components/MedSearch'
import {
  fetchAdditionalResults,
  getMedSearchResultsArray,
  hasMedSearchResults,
  searchDrugs,
  searchResultsCleared,
} from '../data/medSearchBar'

const mapStateToProps = state => ({
  searchResults: getMedSearchResultsArray(state),
  hasSearchResults: hasMedSearchResults(state),
})

const mapDispatchToProps = {
  clearSearchResults: searchResultsCleared,
  genericDrugSearch: searchDrugs,
  loadAdditional: fetchAdditionalResults.requested,
  search: searchDrugs,
}

export default connect(mapStateToProps, mapDispatchToProps)(MedSearch)
