import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'emrStatusesFilter'

export const EMR_STATUS_FILTER_SELECTED = type(
  rootKey,
  'EMR_STATUS_FILTER_SELECTED'
)

export const EMR_STATUS_FILTER_UNSELECTED = type(
  rootKey,
  'EMR_STATUS_FILTER_UNSELECTED'
)

export const setSelectedEMRStatusFilter = creator(
  EMR_STATUS_FILTER_SELECTED,
  'status'
)
export const unselectPatientStatusesFilter = creator(
  EMR_STATUS_FILTER_UNSELECTED,
  'status'
)

export default createReducer(key, new Set(), {
  [EMR_STATUS_FILTER_SELECTED]: (state, { payload: { status } }) =>
    state.add(status),
  [EMR_STATUS_FILTER_UNSELECTED]: (state, { payload: { status } }) =>
    state.delete(status),
})

export const getSelectedEMRStatusesFilter = pipe(getRoot, get(key))
