import React from 'react'
import { connect } from 'react-redux'
import { FormName, formValueSelector } from 'redux-form/immutable'
import PropTypes from '~/utils/propTypes'

const DependantField = ({ formValueSelectorProp, on, when, children }) => (
  <FormName>
    {({ form }) =>
      when(formValueSelectorProp(form, on)) && (
        <React.Fragment>{children}</React.Fragment>
      )
    }
  </FormName>
)

DependantField.propTypes = {
  on: PropTypes.string.isRequired,
  when: PropTypes.func,
  formValueSelectorProp: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

DependantField.defaultProps = {
  when: Boolean,
}

export default connect(state => ({
  formValueSelectorProp: (form, field) => formValueSelector(form)(state, field),
}))(DependantField)
