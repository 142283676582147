import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { mapTo } from 'rxjs/operators'
import { createRole, deleteRole, saveRole } from '../data/roles'
import { ADMIN_TOOLS_ROLES } from '../router'

export default (action$: any) =>
  action$.pipe(
    ofType(createRole.SUCCEEDED, saveRole.SUCCEEDED, deleteRole.SUCCEEDED),
    mapTo(push(ADMIN_TOOLS_ROLES))
  )
