import React, { useCallback } from 'react'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Tooltip } from '@material-ui/core'

const ViewRowActions = ({ row, ...props }) => {
  const onEdit = useCallback(() => props.onEdit(row), [row, props.onEdit])
  const onDelete = useCallback(() => props.onDelete(row), [row, props.onDelete])

  return (
    <React.Fragment>
      <Tooltip title="Edit">
        <IconButton onClick={onEdit}>
          <Icon>edit</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={onDelete}>
          <Icon>delete</Icon>
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

ViewRowActions.propTypes = {
  row: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default React.memo(ViewRowActions)
