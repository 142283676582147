// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import notificationsReducer from './notifications'
import offestReducer from './offset'
import { NOTIFICATION_LIST } from './root'
import statusReducer from './status'

const notificationListReducer = combineReducers({
  [notificationsReducer.key]: notificationsReducer,
  [offestReducer.key]: offestReducer,
  [statusReducer.key]: statusReducer,
})

notificationListReducer.key = NOTIFICATION_LIST

export default notificationListReducer

export * from './root'
export * from './notifications'
export * from './offset'
export * from './status'
