import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
import rootKey from '../key'

const transformErrorResponse = (error: any) => {
  const genericSaveError = 'Could not add intervention'
  return error?.response?.data?.message?.label[0] || genericSaveError
}

const transformEditErrorResponse = (error: any) => {
  const genericSaveError = 'Could not update intervention'
  return error?.response?.data?.message.label[0] || genericSaveError
}

export const addIntervention = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_INTERVENTION',
  requestParams: ['interventionInputs'],
  operation: (interventionInputs: any) =>
    AspireAPI.post('admin_tools/intervention', {
      ...interventionInputs,
    }),
  messages: {
    succeeded: 'Intervention added successfully',
    failed: (error: any) => transformErrorResponse(error),
  },
})

export const editIntervention = Request({
  typePrefix: rootKey,
  typeBase: 'EDIT_INTERVENTION',
  requestParams: ['interventionInputs'],
  operation: (interventionInputs: any) =>
    AspireAPI.post('admin_tools/intervention', {
      ...interventionInputs,
    }),
  messages: {
    succeeded: 'Intervention updated successfully',
    failed: (error: any) => transformEditErrorResponse(error),
  },
})
