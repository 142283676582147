import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { HomeMarker, InfoWindow } from '~/components/GeoMap'
import { User } from '~/data/users'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { colors } from '@material-ui/core'
import { createGetLocationLabel } from '../../data/common/derived'
import MarkerMenu from './MarkerMenu'
import OptionsMenu from './OptionsMenu'

const APPMarker = ({ app: { id, name, lat, lng }, color }) => {
  const getLocationLabel = useMemo(createGetLocationLabel)

  const label = useSelector(state => getLocationLabel(state, id))

  const [menuOpen, toggleMenuOpenTrue, toggleMenuOpenFalse] = useToggle()
  const [hovered, toggleHoveredTrue, toggleHoveredFalse] = useToggle()

  const location = { lat, lng }

  return id ? (
    <HomeMarker
      position={location}
      color={color}
      label={label}
      onMouseover={toggleHoveredTrue}
      onMouseout={toggleHoveredFalse}
      onRightClick={toggleMenuOpenTrue}
    >
      <InfoWindow open={hovered} disableAutoPan zIndex={hovered ? 2 : 1}>
        {`${name} - Home`}
      </InfoWindow>

      <MarkerMenu open={menuOpen} onClose={toggleMenuOpenFalse}>
        <OptionsMenu id={id} label={name} location={location} />
      </MarkerMenu>
    </HomeMarker>
  ) : null
}

APPMarker.propTypes = {
  app: PropTypes.instanceOf(User),
  color: PropTypes.string,
}

APPMarker.defaultProps = {
  app: User(),
  color: colors.cyan['300'],
}

export default APPMarker
