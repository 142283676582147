import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    marginRight: spacing(0.5),
  },
})

const SecondaryText = ({ classes, app: { npi } }) => (
  <div className={classes.container}>
    <Typography className={classes.label} color="primary" variant="body2">
      NPI:
    </Typography>
    <Typography color="textSecondary" variant="body2">
      {npi}
    </Typography>
  </div>
)

SecondaryText.propTypes = {
  app: PropTypes.record.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SecondaryText)
