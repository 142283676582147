// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import activeFilter from './data/activeFilter'
import appData from './data/appData'
import caseManagers from './data/caseManagers'
import dashUserId from './data/dashUserId'
import filterHealthplanIds from './data/filterHealthplanIds'
import filterUserOnly from './data/filterUserOnly'
import filters from './data/filters'
import physicians from './data/physicians'
import popupMessages from './data/popupMessages'
import prompt from './data/prompt'
import referralCases from './data/referralCases'
import referralNotes from './data/referralNotes'
import referrals from './data/referrals/reducer'
import referringEntities from './data/referringEntities'
import statuses from './data/statuses'
import substatuses from './data/substatuses'
import key from './key'

const reducer = combineReducers({
  [activeFilter.key]: activeFilter,
  [appData.key]: appData,
  [caseManagers.key]: caseManagers,
  [dashUserId.key]: dashUserId,
  [filterHealthplanIds.key]: filterHealthplanIds,
  [filters.key]: filters,
  [filterUserOnly.key]: filterUserOnly,
  [physicians.key]: physicians,
  [popupMessages.key]: popupMessages,
  [prompt.key]: prompt,
  [referralCases.key]: referralCases,
  [referralNotes.key]: referralNotes,
  [referringEntities.key]: referringEntities,
  [statuses.key]: statuses,
  [substatuses.key]: substatuses,
  referrals,
})

reducer.key = key

export default reducer
