import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  container: {
    width: '100%',
  },
  spacer: {
    paddingRight: spacing(3),
  },
})

class AttachmentTypeSelector extends PureComponent {
  render() {
    const {
      hideLabel,
      selected,
      types,
      handleChange,
      classes,
      disabled,
    } = this.props
    return (
      <div className={classnames(classes.container, classes.spacer)}>
        <FormControl className={classes.container}>
          {!hideLabel && (
            <InputLabel htmlFor="att-type">Choose Attachment Type</InputLabel>
          )}
          <Select
            disabled={disabled}
            error={!selected}
            input={<Input id="att-type" />}
            onChange={handleChange}
            value={selected}
          >
            {types.map(t => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }
}

AttachmentTypeSelector.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  types: PropTypes.array,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withStyles(styles)(AttachmentTypeSelector)
