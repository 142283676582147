import { combineReducers } from 'redux-immutable'
import chart from './data/chart'
import vitalsChart from './data/vitals'
import key from './key'

const reducer = combineReducers({
  [vitalsChart.key]: vitalsChart,
  [chart.key]: chart,
})

reducer.key = key

export default reducer
