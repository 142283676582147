import { Map } from 'immutable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from '../common/shared'

export const EVENT_TYPES = 'eventTypes'

export const transformTypes = data =>
  Map(data.map(type => [type, Map({ id: type, text: type })]))

export const getEventTypes = pipe(getRoot, get(EVENT_TYPES))
