import { List } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { fetchConfig } from './config'

const CARETEAM = 'careTeam'

export default createReducer(CARETEAM, List(), {
  [fetchConfig.SUCCEEDED]: (_state, { payload: { careTeam } }) => careTeam,
})

export const getCareTeam = compose(get(CARETEAM), getRoot)
