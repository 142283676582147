export default {
  action: {
    supplement: 'some supplemental text',
    labelSelection: 'say hello to my little friend',
    labelPlan: 'Adjusted',
    id: 1,
  },
  goal: { label: 'custom goal description' },
  problem: {
    notes: 'some problem note',
    label: 'borpo',
  },
}
