import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import { isOffline } from '~/data/connectivity'
import { fetchSearchResults, searchDrugs } from '../data/medSearchBar'
import { searchOffline } from '../data/offlineDrugs'

export default (action$, state$) =>
  action$.pipe(
    ofType(searchDrugs),
    pluck('payload'),
    withLatestFrom(state$),
    map(([payload, state]) =>
      isOffline(state)
        ? searchOffline(payload.query)
        : fetchSearchResults.requested(payload)
    )
  )
