import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import {
  ORDER_DETAIL_FETCH_REQUESTED,
  fetchOrderDetail,
  orderDetailFetchFailed,
  orderDetailFetchSucceeded,
} from '../data/orderDetail'

export default action$ =>
  action$.pipe(
    ofType(ORDER_DETAIL_FETCH_REQUESTED),
    pluck('payload', 'id'),
    filter(id => !isNaN(id)), // Prevents fetching legacy orders
    mergeMap(id => {
      return from(
        fetchOrderDetail(id)
          .then(order => orderDetailFetchSucceeded(order))
          .catch(err => orderDetailFetchFailed(err))
      )
    })
  )
