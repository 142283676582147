import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import {
  dialogOpened,
  fetchPatientConfig,
} from '../data/createEpisodeOfCareDialog'

export default action$ =>
  action$.pipe(
    ofType(dialogOpened),
    pluck('payload', 'patientId'),
    mergeMap(patientId =>
      of(
        fetchPatientConfig.requested(patientId),
        fetchDistinctFieldValues([
          'episode_of_care_referral_source',
          'episode_of_care_type',
        ])
      )
    )
  )
