import { ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { combineLatest, filter, map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { fetchConfigOptions } from '../data/configOptions'
import router from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(changedTo(router)),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(() => fetchConfigOptions.requested())
  )
