import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentAPPId as getCSSCurrentAPPId } from '~/apps/cssDashboard/data/currentAPPId'
import { getCurrentAPPId as getPESCurrentAPPId } from '~/apps/pesDashboard/data/currentAPPId'
import { InfoWindow } from '~/components/GeoMap'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { getContext } from '../../../data/common/derived'
import getLocation from '../../../utils/getLocation'
import MarkerMenu from '../MarkerMenu'
import OptionsMenu from '../OptionsMenu'

const PatientSingleMarker = ({
  patient,
  current,
  label,
  PatientInfo,
  getMarker,
  getColor,
  getOpacity,
  onPatientSelected,
  onPatientCleared,
  excludedOptions,
}) => {
  const context = useSelector(getContext)

  const [hovered, toggleHoveredTrue, toggleHoveredFalse] = useToggle()
  const [menuOpen, toggleMenuOpenTrue, toggleMenuOpenFalse] = useToggle()

  const onClick = useCallback(() => {
    onPatientSelected(patient.id)
  }, [patient, onPatientSelected])

  const location = useMemo(() => getLocation(patient), [patient])
  const patientAppId = patient.get('npId') || patient.get('appId')
  const pesCurrentAppId = useSelector(getPESCurrentAPPId)
  const cssCurrentAppId = useSelector(getCSSCurrentAPPId)

  const color = useMemo(() => getColor(patient, context).color, [
    patient,
    context,
  ])

  // If we are viewing the GeoMap in the PES or CSS dashboard we want to render
  // the secondary APP's patients with a slight opacity until we port the polygonator
  // polygons over to the map
  const opacity = useMemo(() => {
    if (pesCurrentAppId && pesCurrentAppId !== patientAppId) {
      return 0.6
    } else if (cssCurrentAppId && cssCurrentAppId !== patientAppId) {
      return 0.6
    }
    return getOpacity(patient, context).opacity
  }, [patient, context])

  const MarkerComponent = getMarker(patient)
  const open = current || hovered

  return (
    <MarkerComponent
      position={location}
      scale={current ? 1.5 : 1}
      color={color}
      opacity={opacity}
      label={label}
      onClick={onClick}
      onMouseover={toggleHoveredTrue}
      onMouseout={toggleHoveredFalse}
      onRightClick={toggleMenuOpenTrue}
    >
      <InfoWindow
        open={open}
        disableAutoPan={hovered}
        zIndex={hovered ? 2 : 1}
        onClose={onPatientCleared}
      >
        {open && <PatientInfo patient={patient} />}
      </InfoWindow>

      <MarkerMenu open={menuOpen} onClose={toggleMenuOpenFalse}>
        <OptionsMenu
          showPatientOptions
          patient={patient}
          id={patient.id}
          label={patient.name}
          location={location}
          excludedOptions={excludedOptions}
        />
      </MarkerMenu>
    </MarkerComponent>
  )
}

PatientSingleMarker.propTypes = {
  patient: PropTypes.record.isRequired,
  current: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  PatientInfo: PropTypes.elementType.isRequired,
  getMarker: PropTypes.func.isRequired,
  getColor: PropTypes.func.isRequired,
  getOpacity: PropTypes.func,
  onPatientSelected: PropTypes.func.isRequired,
  onPatientCleared: PropTypes.func.isRequired,
  excludedOptions: PropTypes.array,
}

PatientSingleMarker.defaultProps = {
  getOpacity: () => ({
    opacity: 1,
  }),
}

export default React.memo(PatientSingleMarker)
