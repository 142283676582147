import { combineEpics } from 'redux-observable'
import attachmentTypeChangedEpic from './epics/attachmentTypeChangedEpic'
import downloadAttachment from './epics/downloadAttachment'
import fetchAttachmentDocumentSucceeded from './epics/fetchAttachmentDocumentSucceeded.js'
import fileSelectedEpic from './epics/fileSelectedEpic'
import onLoadAttachments from './epics/onLoadAttachments'

export default combineEpics(
  attachmentTypeChangedEpic,
  downloadAttachment,
  fetchAttachmentDocumentSucceeded,
  fileSelectedEpic,
  onLoadAttachments
)
