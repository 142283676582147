import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import {
  fetchDischargeRecommendationById,
  getDischargeRecById,
} from '../data/discharges'
import { editDischargeRecDialogIdChanged } from '../data/recommendationUpdateDialog'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(editDischargeRecDialogIdChanged),
    pluck('payload'),
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    filter(caseId => {
      const state = state$.value
      const availableCase = getDischargeRecById(state, caseId)
      return caseId && !availableCase
    }),
    map(fetchDischargeRecommendationById.requested)
  )
