import React from 'react'
import PropTypes from '~/utils/propTypes'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  container: { padding: spacing(2) },
  title: { textAlign: 'center', textDecoration: 'underline' },
  subtitle: { fontWeight: 'bold' },
}))

const VideoVisitConsentLanguage = ({ formContext }) => {
  const classes = useStyles()
  const {
    context: { language },
  } = formContext

  return (
    <div className={classes.container}>
      <Typography className={classes.title} paragraph variant="h5">
        {language.title}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.overviewSubtitle}
      </Typography>
      <Typography paragraph variant="body2">
        {language.overview}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.usesSubtitle}
      </Typography>
      <Typography paragraph variant="body2">
        {language.uses}
      </Typography>
      <List dense>
        {language.usesList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.benefitsSubtitle}
      </Typography>
      <List dense>
        {language.benefitsList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.risksSubtitle}
      </Typography>
      <List dense>
        {language.risksList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.privacySubtitle}
      </Typography>
      <List dense>
        {language.privacyList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.cancelSubtitle}
      </Typography>
      <List dense>
        {language.cancelList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.costSubtitle}
      </Typography>
      <List dense>
        {language.costList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.signingRequiredSubtitle}
      </Typography>
      <Typography paragraph variant="body2">
        {language.signingRequired}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.signingMeaningSubtitle}
      </Typography>
      <Typography paragraph variant="body2">
        {language.signingMeaning}
      </Typography>
      <List dense>
        {language.signingMeaningList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography paragraph variant="body2">
        {language.signingMeaningCopy}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.signingInstructionsSubtitle}
      </Typography>
    </div>
  )
}

VideoVisitConsentLanguage.propTypes = {
  formContext: PropTypes.object.isRequired,
}

export default VideoVisitConsentLanguage
