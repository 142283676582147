import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mapTo, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  DELETE_CONTACT_REQUESTED,
  deleteContact,
  deleteContactCompleted,
  getReferralFormValues,
} from '../data/referrals'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_CONTACT_REQUESTED),
    mergeMap(({ form, index }) => {
      const state = state$.value
      const contactId = getReferralFormValues(state, form).getIn([
        'contacts',
        index,
        'id',
      ])

      if (!contactId) {
        return of(deleteContactCompleted(index))
      }

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      return from(deleteContact(contactId, index)).pipe(
        mapTo(deleteContactCompleted(index)),
        catchError(showError('Problem deleting contact'))
      )
    })
  )
