import { OrderedMap, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { EncounterCodingStatus } from '~/fieldValues/encounter_coding_status'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request, { flattenErrors } from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

export const CODING_ENCOUNTER_INFO = 'codingEncounterInfo'

const ENCOUNTER_INFO_CLEARED = type(key, 'CODING_ENCOUNTER_INFO_CLEARED')

export const encounterInfoCleared = creator(key, ENCOUNTER_INFO_CLEARED)

export const EncounterInfo = Record({
  codedBy: null,
  codingQueue: null,
  codingStatus: null,
  dateOfService: null,
  id: null,
  legacy: null,
  patientDateOfBirth: null,
  patientId: null,
  patientName: null,
  provider: null,
  placeOfService: null,
  type: null,
})

export type EncounterInfoType = {
  codedBy?: string
  codingQueue: string
  codingStatus: EncounterCodingStatus
  dateOfService: string
  id: number
  legacy: boolean
  patientDateOfBirth: string
  patientId: string
  patientName: string
  placeOfService: string
  provider: {
    time_zone: string
    role: string
    name: string
    email: string
    credentials: string
  }
  type: string
}

export const fetchEncounterInfo = Request({
  typePrefix: key,
  typeBase: 'FETCH_CODING_ENCOUNTER_INFO',
  requestParams: ['encounterId'],
  operation: (encounterId: number) =>
    AspireAPI.get(`coding_dashboard/${encounterId}/encounter_info`),
  transform: EncounterInfo,
  messages: {
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was an issue fetching encounter information',
  },
})

export const updateEncounterCodingStatus = Request({
  typePrefix: key,
  typeBase: 'UPDATE_ENCOUNTER_CODING_STATUS',
  requestParams: ['encounterId', 'newStatus'],
  operation: (encounterId: number, newStatus: EncounterCodingStatus) =>
    AspireAPI.post(`coding_dashboard/${encounterId}/status_update`, {
      newStatus: newStatus,
    }),
  messages: {
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was an issue adding the encounter code',
  },
})

const initState = OrderedMap()

export const getCodingEncounterInfo = pipe(getRoot, get(CODING_ENCOUNTER_INFO))

export default createReducer(CODING_ENCOUNTER_INFO, initState, {
  [ENCOUNTER_INFO_CLEARED]: () => initState,
  [fetchEncounterInfo.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
