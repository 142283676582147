import moment from 'moment'
import createReducer from '~/utils/createReducer'
import creating from '../creating'
import currentEvent from '../currentEvent'
import encounterTypes from '../encounterTypes'
import { fetchPatientInfo } from '../patientInfo'
import { encounterTypeChanged } from '../root'
import { fetchServiceLocations } from '../serviceLocations'

export default createReducer('common', undefined, {
  [encounterTypeChanged]: (state, { payload: { type } }) => {
    const encType = state.getIn([encounterTypes.key, type])

    return state.update(currentEvent.key, event =>
      event.merge({
        encounterType: encType ? encType.type : null,
        encounterTypeModality: encType ? encType.modality : null,
        duration: encType ? encType.defaultVisitDuration : event.duration,
        end:
          encType && encType.defaultVisitDuration
            ? moment(event.start)
                .utc()
                .add(encType.defaultVisitDuration, 'minutes')
            : event.end,
      })
    )
  },
  [fetchPatientInfo.SUCCEEDED]: (state, { payload }) => {
    const creatingState = state.get(creating.key)

    return creatingState
      ? state.update(currentEvent.key, event =>
          event.merge({
            whatId: payload.id,
            subject: payload.visitType,
            patientId: payload.id,
            patientName: payload.name,
            patientContactId: payload.patientContactId,
            patientContactInfo: payload.patientContactInfo,
            patientEmail: payload.email,
            patientPhone: payload.phone,
            location: payload.location,
            textingApproved: payload.textingApproved,
          })
        )
      : state
  },
  [fetchServiceLocations.SUCCEEDED]: (state, { payload }) => {
    const creatingState = state.get(creating.key)
    const defaultServiceLocation = payload.first()?.id

    return creatingState
      ? state.update(currentEvent.key, event =>
          event.set('serviceLocationId', defaultServiceLocation)
        )
      : state
  },
})
