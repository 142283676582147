import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox } from '~/components/fields'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ACTIVE, getContacts } from '../../../data/contacts'
import Contact from './Contact'
import ManualAttempts from './ManualAttempts'
import ManualContact from './ManualContact'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    marginBottom: spacing(1),
  },
  divider: {
    marginBottom: spacing(1),
  },
  contactList: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 210px',
    columnGap: spacing(2),
  },
}))

const sorter = (a, b) => {
  if (a.isPreferred) return -1
  if (b.isPreferred) return 1
  if (a.type.includes('patient') && !b.type.includes('patient')) return -1
  if (!a.type.includes('patient') && b.type.includes('patient')) return 1
  if (a.name && !b.name) return -1
  if (b.name && !a.name) return 1
  if (a.name && b.name) return a.name.localeCompare(b.name)
  return a.phoneNumber.localeCompare(b.phoneNumber)
}

const Contacts = () => {
  const classes = useStyles()

  const contacts = useSelector(getContacts)

  const [includeInactive, setIncludeInactive] = useState(false)

  const filteredContacts = useMemo(
    () =>
      includeInactive
        ? contacts
        : contacts.filter(
            ({ active, expired }) => active === ACTIVE && !expired
          ),
    [contacts, includeInactive]
  )

  return (
    <div className={classes.container}>
      <Checkbox
        label="Include Inactive/Expired Contacts"
        value={includeInactive}
        onChange={value => {
          setIncludeInactive(value)
        }}
      />

      <Divider className={classes.divider} />

      <div className={classes.contactList}>
        {filteredContacts
          .sort(sorter)
          .toIndexedSeq()
          .map(contact => (
            <Contact key={contact.phoneNumber} contact={contact} />
          ))}

        <ManualAttempts />

        <ManualContact />
      </div>
    </div>
  )
}

export default Contacts
