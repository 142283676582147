import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { distinctUntilChanged, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import { METRICS_PAGE_NAVIGATED } from '../data/common/shared'
import { fetchAppMetrics, fetchedMetrics } from '../data/metrics'

const fetchAndStoreAPPMetrics = (appId: any) =>
  loading(
    from(fetchAppMetrics(appId)).pipe(map(fetchedMetrics)),
    'There was an issue loading APP Metrics.'
  )

const initialLoad = (action$: any) =>
  action$.pipe(
    ofType(METRICS_PAGE_NAVIGATED),
    pluck('payload', 'appId'),
    distinctUntilChanged(),
    mergeMap(fetchAndStoreAPPMetrics)
  )

export default initialLoad
