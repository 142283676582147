import React, { useEffect, useState } from 'react'
import { DateField, TextField } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'

const BLANK_REMINDER = { description: '', dueDate: '' }

const PatientReminderDialog = ({
  open,
  patient,
  reminder: previousReminder,
  onSubmit,
  onDelete,
  onClose,
  isSubmitting,
}) => {
  const [reminder, setReminder] = useState(BLANK_REMINDER)

  useEffect(() => {
    setReminder(previousReminder ? previousReminder.toJS() : BLANK_REMINDER)
  }, [previousReminder])

  const onChangeDescription = description =>
    setReminder({ ...reminder, description })
  const onChangeDate = dueDate => setReminder({ ...reminder, dueDate })

  const reminderExists = Boolean(previousReminder)
  const patientName = patient && patient.patientName
  const isValid = reminder.description && reminder.dueDate

  return (
    <Dialog open={open} onClose={onClose}>
      {isSubmitting && <LinearProgress variant="query" />}
      <DialogTitle>{reminderExists ? 'Update' : 'Add'} Reminder</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Quickly {reminderExists ? 'update the' : 'add a'} reminder for{' '}
          {patientName}.
        </DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          label="Description"
          fullWidth
          value={reminder.description}
          onChange={onChangeDescription}
        />
        <DateField
          margin="normal"
          label="Due Date"
          fullWidth
          value={reminder.dueDate}
          onChange={onChangeDate}
        />
      </DialogContent>
      <DialogActions>
        {reminderExists && (
          <Button
            color="secondary"
            onClick={() => onDelete(previousReminder.id)}
            disabled={isSubmitting}
          >
            Delete
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            onSubmit({
              ...reminder,
              patientId: patient.patientId,
            })
          }
          disabled={!isValid || isSubmitting}
        >
          {reminderExists ? 'Update' : 'Add'} Reminder
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PatientReminderDialog.propTypes = {
  open: PropTypes.bool,
  patient: PropTypes.recordOf({ patientName: PropTypes.string }),
  reminder: PropTypes.recordOf({
    id: PropTypes.number,
    description: PropTypes.string,
    dueDate: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
}

export default PatientReminderDialog
