import { ofType } from 'redux-observable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
import { fetchCareTeam, getCareTeams } from '~/data/careTeams'
import { reassignTaskDialogOpened } from '../data/dialog'

export default (action$, state$) =>
  action$.pipe(
    ofType(reassignTaskDialogOpened),
    pluck('payload', 'patientId'),
    withLatestFrom(state$),
    filter(([patientId, state]) => !getCareTeams(state).has(patientId)),
    map(([patientId]) => patientId),
    map(fetchCareTeam.requested)
  )
