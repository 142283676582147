import { ofType } from 'redux-observable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
import { getTaskById, saveTask, taskSaved } from '../data/tasks'
import { getSaveData, isIncomplete, isUnsaved } from '../utils/saveTask'

const getTask = ([id, state]) => getTaskById(id)(state)

// Manually save an incomplete, unsaved Task
export default (action$, state$) =>
  action$.pipe(
    ofType(taskSaved),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    map(getTask),
    filter(isIncomplete),
    filter(isUnsaved),
    map(getSaveData),
    map(saveTask.requested)
  )
