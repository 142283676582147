import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { CallerRelation } from './interfaces'

// KEY
const CALLERRELATION = 'callerRelation'

export const CallerRelationType = Record({
  activeInd: null,
  id: null,
  relation: null,
  relationCode: null,
})

// TRANSFORMER
const mapCallerRelations = (results: [CallerRelation]) =>
  List(
    results?.map(rel =>
      CallerRelationType({
        id: rel?.id,
        relation: rel?.relation,
        relationCode: rel?.relationCode,
        activeInd: rel?.activeInd,
      })
    )
  )

// REQUEST
export const callerRelationList = Request({
  typePrefix: rootKey,
  typeBase: 'CALLERRELATIONLIST',
  operation: () => AspireAPI.get('transportation/lookup/contact_relation'),
  transform: mapCallerRelations,
  messages: { failed: 'There was an issue fetching Caller Relation' },
})

// REDUCER
export const callerRelation = createReducer(CALLERRELATION, List(), {
  [callerRelationList.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getCallerRelation = pipe(getRoot, get(CALLERRELATION))
