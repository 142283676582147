import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { TripDistanceDetails } from './interfaces'

// KEY
const key = 'DistanceCalculate'

// CREATORS
const creator = scopedCreator(key)

export const resetDistance = creator('RESET_DISTANCE')

export const DistanceCalc = Record({
  destination: null,
  distance: null,
  duration: null,
  durationText: null,
  distanceText: null,
  origin: null,
})

// TRANSFORMER
const mapCalcDistance = (result: TripDistanceDetails) =>
  DistanceCalc({
    destination: result?.destination,
    distance: result?.distance,
    distanceText: result?.distanceText,
    durationText: result?.durationText,
    duration: result?.duration,
    origin: result?.origin,
  })

// REQUEST
export const calculateDistance = Request({
  typePrefix: key,
  typeBase: 'CALCULATEDISTANCE',
  requestParams: ['pickup', 'destination'],
  operation: (pickup: string, destination: string) =>
    AspireAPI.get(
      `transportation/trips/calc_distance?pickup=${pickup}&destination=${destination}`
    ),
  transform: mapCalcDistance,
  messages: { failed: 'Failed to Calculate Distance ' },
})

// REDUCER
export const calcDistance = createReducer(key, null, {
  [calculateDistance.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetDistance]: () => null,
})

// SELECTORS
export const getDistance = pipe(getRoot, get(key))
