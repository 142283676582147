const permittedRoles = [
  'System Admin',
  'Nurse Practitioner',
  'Physician',
  'Physician Admin',
  'Registered Nurse',
  'Patient Care Coordinator',
  'Lead Patient Care Coordinator',
  'Market Director',
  'Coder',
]

export const isPermittedRole = role => permittedRoles.includes(role)
