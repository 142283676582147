import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Avatar,
  Icon,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import PrimaryText from './PrimaryText'
import SecondaryText from './SecondaryText'

const EventListItem = ({ event }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <Icon>today</Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<PrimaryText event={event} />}
        secondary={<SecondaryText event={event} />}
      />
    </ListItem>
  )
}

EventListItem.propTypes = {
  event: PropTypes.record.isRequired,
}

export default React.memo(EventListItem)
