// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import { generateDocumentReducer } from './data/generateDocumentDialog'
import {
  fetchGenDocFormReducer,
  submitGenDocFormReducer,
  previewGenDocFormReducer,
} from './data/generateDocumentForm'
import key from './key'

const reducer = combineReducers({
  [generateDocumentReducer.key]: generateDocumentReducer,
  [fetchGenDocFormReducer.key]: fetchGenDocFormReducer,
  [submitGenDocFormReducer.key]: submitGenDocFormReducer,
  [previewGenDocFormReducer.key]: previewGenDocFormReducer,
})

reducer.key = key

export default reducer
