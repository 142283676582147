import React, { useCallback } from 'react'
import { ActionDialog } from '~/components/dialogs'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { dialogClosed } from '../../data/dialog'
import { deletePatientListItem } from '../../data/patientLists'

const DeleteListDialog = ({ rowId, patientName, open }) => {
  const onDialogClosed = useAction(dialogClosed)
  const onDeletePtListItem = useAction(deletePatientListItem.requested)

  const onDelete = useCallback(() => {
    onDeletePtListItem(rowId)
    onDialogClosed()
  }, [rowId, onDialogClosed, onDeletePtListItem])

  return (
    <ActionDialog
      open={open}
      title={`Delete ${patientName} From List`}
      mode="delete"
      onDelete={onDelete}
      onClose={onDialogClosed}
    >
      <Typography>
        Are you sure you want to delete {patientName} from your list?
      </Typography>
    </ActionDialog>
  )
}

DeleteListDialog.propTypes = {
  open: PropTypes.bool,
  patientName: PropTypes.string,
  rowId: PropTypes.number,
}

export default DeleteListDialog
