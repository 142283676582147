import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change } from 'redux-form'
import { InfoDialog } from '~/components/dialogs'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import TextFieldWithAdornment from '../../components/TextFieldWithAdornment'
import { marketsCleared } from '../../data/markets'
import MarketLookup from './MarketLookup'

const MarketField = ({ className, form, changeValue, marketsCleared }) => {
  const [open, toggleOpenTrue, toggleOpenFalse] = useToggle()

  const onClose = useCallback(() => {
    toggleOpenFalse()
    marketsCleared()
  }, [marketsCleared])

  const onSelect = useCallback(
    market => {
      changeValue(form, 'marketId', market.id)
      changeValue(form, 'market', market.marketName)

      onClose()
    },
    [form, changeValue, onClose]
  )

  const onClear = useCallback(() => {
    changeValue(form, 'marketId', '')
    changeValue(form, 'market', '')
  }, [form, changeValue])

  return (
    <React.Fragment>
      <Field
        className={className}
        component={TextFieldWithAdornment}
        name="market"
        label="Market"
        onSearch={toggleOpenTrue}
        onClear={onClear}
        disabled
      />

      <InfoDialog
        open={open}
        title="Market Lookup"
        onClose={onClose}
        maxWidth="lg"
        fullWidth
      >
        <MarketLookup onSelect={onSelect} />
      </InfoDialog>
    </React.Fragment>
  )
}

MarketField.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  marketsCleared: PropTypes.func.isRequired,
}

export default compose(
  connect(null, {
    changeValue: change,
    marketsCleared,
  })
)(MarketField)
