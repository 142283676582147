import { format } from 'date-fns'

const CANCELLATION_ASPIRE_DRIVEN = 'Cancellation - Carelon Driven'
const CANCELLATION_PATIENT_DRIVEN = 'Cancellation - Patient Driven'
const CANCELLED_VISIT = 'Cancelled Visit'
const RESCHEDULED = 'Rescheduled'
const SPECIFIC_DATE = 'Specific Date'
const SPECIFY_DATE = 'Specify Date'
const UNABLE_TO_SCHEDULE = 'Unable to Schedule'
const HOME_SAFETY_RISK = 'Home Safety Risk'
const PCP_DECLINED_OTHER = 'PCP Declined - Other'
const PCP_DECLINED_PATIENT_TOO_HEALTHY = 'PCP Declined - Patient too healthy'
const PATIENT_FAMILY_DECLINED_APPREHENSION_OR_SKEPTICISM =
  'Patient / Family Declined - Apprehension or Skepticism'
const PATIENT_FAMILY_DECLINED_CLAIMS_TO_BE_TOO_HEALTHY =
  'Patient / Family Declined - Claims to be too healthy'
const PATIENT_FAMILY_DECLINED_PATIENT_UNAVAILABLE_FOR_VISIT =
  'Patient / Family Declined - Patient Unavailable for Visit'
const PATIENT_FAMILY_DECLINED_SATISFIED_WITH_CURRENT_CARE =
  'Patient / Family Declined - Satisfied with Current Care'
const PATIENT_FAMILY_DECLINED_TOO_MANY_PROVIDERS =
  'Patient / Family Declined - Too Many Providers'

export const DISCHARGE = 'Discharge'
export const RECOMMEND_DISCHARGE = 'Recommend Discharge'
export const PATIENT_FAMILY_DECLINED_SAFETY_CONCERNS =
  'Patient / Family Declined - Safety Concerns'
export const TEMPORARY_GEOGRAPHIC_HOLD = 'Temporary Geographic Hold'
export const VIRTUAL_CARE = 'Virtual Care'

export const DECEASED = 'Deceased'
export const HOSPICE = 'Hospice'
export const INELIGIBLE_INSURANCE = 'Ineligible Insurance'
export const OUTREACH_CYCLE_COMPLETE = 'Outreach Cycle Complete'
export const RESCHEDULED_CANCELLATION_DISPOSITIONS = [
  RESCHEDULED,
  CANCELLATION_ASPIRE_DRIVEN,
  CANCELLATION_PATIENT_DRIVEN,
]

export const CALLBACK_DISPOSITION_REASONS = [
  CANCELLED_VISIT,
  UNABLE_TO_SCHEDULE,
]

export const CALLBACK_SUB_DISPOSITION_REASONS = [SPECIFIC_DATE, SPECIFY_DATE]

export const REENGAGE_SUB_DISPOSITION_REASONS = [
  HOME_SAFETY_RISK,
  PCP_DECLINED_OTHER,
  PCP_DECLINED_PATIENT_TOO_HEALTHY,
  PATIENT_FAMILY_DECLINED_APPREHENSION_OR_SKEPTICISM,
  PATIENT_FAMILY_DECLINED_CLAIMS_TO_BE_TOO_HEALTHY,
  PATIENT_FAMILY_DECLINED_PATIENT_UNAVAILABLE_FOR_VISIT,
  PATIENT_FAMILY_DECLINED_SATISFIED_WITH_CURRENT_CARE,
  PATIENT_FAMILY_DECLINED_TOO_MANY_PROVIDERS,
]

export const TODAY = format(new Date(), 'yyyy-MM-dd')
