import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const SELECTED_ODS_ADMISSION_ID_TOGGLED =
  'reconciliation/SELECTED_ODS_ADMISSION_ID_TOGGLED'
export const SELECTED_ODS_ADMISSION_ID_CLEARED =
  'reconciliation/SELECTED_ODS_ADMISSION_ID_CLEARED'
export const SELECTED_ODS_ADMISSION_IDS_CLEARED =
  'reconciliation/SELECTED_ODS_ADMISSION_IDS_CLEARED'

// ACTION CREATORS
export const selectedOdsAdmissionIdToggled = (odsAdmissionId: any) => ({
  type: SELECTED_ODS_ADMISSION_ID_TOGGLED,
  odsAdmissionId,
})
export const selectedOdsAdmissionIdCleared = (odsAdmissionId: any) => ({
  type: SELECTED_ODS_ADMISSION_ID_CLEARED,
  odsAdmissionId,
})
export const selectedOdsAdmissionIdsCleared = (odsAdmissionIds: any) => ({
  type: SELECTED_ODS_ADMISSION_IDS_CLEARED,
  odsAdmissionIds,
})

// KEY
export const key = 'selectedOdsAdmissionIds'

const toggleSet = (set: any, id: any) =>
  set.has(id) ? set.delete(id) : set.add(id)

// REDUCERS
export default createReducer(key, Set(), {
  [SELECTED_ODS_ADMISSION_ID_TOGGLED]: (state: any, { odsAdmissionId }: any) =>
    toggleSet(state, odsAdmissionId),
  [SELECTED_ODS_ADMISSION_ID_CLEARED]: (state: any, { odsAdmissionId }: any) =>
    state.delete(odsAdmissionId),
  [SELECTED_ODS_ADMISSION_IDS_CLEARED]: (
    state: any,
    { odsAdmissionIds }: any
  ) => state.subtract(odsAdmissionIds),
})

// SELECTORS
export const getAllSelectedOdsAdmissionIds = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
export const isOdsAdmissionIdSelected = (state: any, odsAdmissionId: any) =>
  getAllSelectedOdsAdmissionIds(state).has(odsAdmissionId)
