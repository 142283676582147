import moment from 'moment'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/session' or its corresp... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/notifications' or i... Remove this comment to see the full error message
import { transformChannelData } from '~/features/notifications'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/notifications/utils... Remove this comment to see the full error message
import getChannelName from '~/features/notifications/utils/getChannelName'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/alertOnError' or its c... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/channels' or its corre... Remove this comment to see the full error message
import { pushToChannel } from '~/utils/channels'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/operators' or its corr... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { FETCH_NEXT_NOTIFICATIONS_REQUESTED } from '../data/common/shared'
import {
  getOffset,
  getStatus,
  notificationsFetched,
  offsetSet,
} from '../data/notificationList'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(FETCH_NEXT_NOTIFICATIONS_REQUESTED),
    debounceTime(250),
    switchTo(state$),
    mergeMap((state: any) => {
      const userId = getUserId(state)
      const status = getStatus(state)
      const offset = getOffset(state)

      return from(
        pushToChannel(getChannelName(userId), 'get_all_notifications', {
          offset,
          status,
        })
      ).pipe(
        map(transformChannelData),
        mergeMap(data => {
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          const lastNotification = data.last()
          const offset = getOffset(state$.value)
          const newOffset = lastNotification
            ? moment(lastNotification.createdAt).toISOString()
            : offset

          return of(notificationsFetched(data), offsetSet(newOffset))
        }),
        catchError(showError('Unable to fetch notifications'))
      )
    })
  )
