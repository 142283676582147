import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change } from 'redux-form'
import { InfoDialog } from '~/components/dialogs'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import TextFieldWithAdornment from '../../components/TextFieldWithAdornment'
import { groupsCleared } from '../../data/groups'
import GroupLookup from './GroupLookup'

const GroupField = ({ className, form, changeValue, groupsCleared }) => {
  const [open, toggleOpenTrue, toggleOpenFalse] = useToggle()

  const onClose = useCallback(() => {
    toggleOpenFalse()
    groupsCleared()
  }, [groupsCleared])

  const onSelect = useCallback(
    group => {
      changeValue(form, 'groupId', group.id)
      changeValue(form, 'group', group.name)

      onClose()
    },
    [form, changeValue, onClose]
  )

  const onClear = useCallback(() => {
    changeValue(form, 'groupId', '')
    changeValue(form, 'group', '')
  }, [form, changeValue])

  return (
    <React.Fragment>
      <Field
        className={className}
        component={TextFieldWithAdornment}
        name="group"
        label="Group"
        onSearch={toggleOpenTrue}
        onClear={onClear}
        disabled
      />

      <InfoDialog
        open={open}
        title="Group Lookup"
        onClose={onClose}
        maxWidth="lg"
        fullWidth
      >
        <GroupLookup onSelect={onSelect} />
      </InfoDialog>
    </React.Fragment>
  )
}

GroupField.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  groupsCleared: PropTypes.func.isRequired,
}

export default compose(
  connect(null, {
    changeValue: change,
    groupsCleared,
  })
)(GroupField)
