import { Record } from 'immutable'
import { ofType } from 'redux-observable'
import { iif, of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, getIn, payload } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import CAREMORE_LOG_COMMUNICATION from '../key'
import { completeForm } from './form'

// Key
const key = 'lastLogCommunication'

// Type
const typePrefix = `${key}`

// Record
export const LastLogCommuncation = Record({
  lastLogCommunication: {},
})

// Requests

export const fetchLastLogCommunication = Request({
  typePrefix,
  typeBase: 'FORM_DATA_FETCH',
  requestParams: ['patientId'],
  operation: patientId => {
    return AspireAPI.get(`v1/patients/${patientId}/last_log_communication`)
  },
  transform: lastLogCommunication => {
    return LastLogCommuncation({
      lastLogCommunication,
    })
  },
  messages: {
    failed: 'Failed to fetch last logged communication',
  },
})

// Reducer
export default createReducer(key, null, {
  [fetchLastLogCommunication.SUCCEEDED]: compose(payload, action),
})

// Selectors
export const getLastLogCommunication = getIn([CAREMORE_LOG_COMMUNICATION, key])

// Epics
const lastLogCommFormCompleted = action$ =>
  action$.pipe(
    ofType(completeForm.SUCCEEDED),
    pluck('meta'),
    mergeMap(res =>
      iif(
        () => res?.request?.payload?.type === 'patient',
        of(fetchLastLogCommunication.requested(res.request.payload?.patientId))
      )
    )
  )

export const epic = lastLogCommFormCompleted
