import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const key = 'icd10Codes'

const creator = scopedCreator(key)

export const ICD10 = Record({
  code: null,
  description: null,
})

export const fetchICD10ByCode = Request({
  typePrefix: key,
  typeBase: 'FETCH_ICD10_CODES',
  requestParams: ['searchFor'],
  operation: searchFor =>
    AspireAPI.get(`er_visits/icd_10_search/codes`, {
      params: { searchFor },
    }),
  transform: result => result.map(ICD10),
  messages: { failed: 'There was a problem fetching ICD 10 information' },
})

export const fetchICD10ByCodeAndDesc = Request({
  typePrefix: key,
  typeBase: 'FETCH_ICD10_CODES_DESCRIPTIONS',
  requestParams: ['searchForCode', 'searchForDesc'],
  operation: (searchForCode, searchForDesc) =>
    AspireAPI.get(`er_visits/icd_10_search/codes_descriptions`, {
      params: { searchForCode, searchForDesc },
    }),
  transform: result => result.map(ICD10),
  messages: { failed: 'There was a problem fetching ICD 10 information' },
})

export const fetchICD10ByDescription = Request({
  typePrefix: key,
  typeBase: 'FETCH_ICD10_DESCRIPTIONS',
  requestParams: ['searchFor'],
  operation: searchFor =>
    AspireAPI.get(`er_visits/icd_10_search/descriptions`, {
      params: { searchFor },
    }),
  transform: result => result.map(ICD10),
  messages: { failed: 'There was a problem fetching ICD 10 information' },
})

export const clearSuggestedICD10s = creator('CLEAR_SUGGESTED_ICD10S')

export default createReducer(key, [], {
  [fetchICD10ByCode.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchICD10ByCodeAndDesc.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchICD10ByDescription.SUCCEEDED]: (_state, { payload }) => payload,
  [clearSuggestedICD10s]: () => [],
})

export const getICD10s = pipe(get(rootKey), get(key))
