import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import {
  admissionFetchFailed,
  admissionsStored,
  fetchAdmissions,
} from '../data/admissions'
import { RECYCLE_SEARCHED } from '../data/admissionsReconciliation'
import {
  fetchPatient,
  patientFetchFailed,
  patientStored,
} from '../data/patients'
import { recycleContactIdSet } from '../data/recycleContactId'

const fetchAndStorePatient = (contactId: any) =>
  from(fetchPatient(contactId)).pipe(
    map(patientStored),
    catchError(e => {
      telemetry.error(e)
      return of(patientFetchFailed())
    })
  )

const fetchAndStoreRecycleAdmissions = (contactId: any) =>
  from(fetchAdmissions(contactId)).pipe(
    map(admissionsStored),
    catchError(e => {
      telemetry.error(e)
      return of(admissionFetchFailed())
    })
  )

export default (action$: any) =>
  action$.pipe(
    ofType(RECYCLE_SEARCHED),
    mergeMap(({ contactId }) =>
      concat(
        of(recycleContactIdSet(contactId)),
        fetchAndStorePatient(contactId),
        fetchAndStoreRecycleAdmissions(contactId)
      )
    )
  )
