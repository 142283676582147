import moment from 'moment'
import { localToUtc, setTime, utcToLocal } from '~/utils/dates'

export const DEFAULT_DURATION = 60

export const defaultStart = ({ timeZone, date }) => {
  const localDateTime = utcToLocal(moment.utc(), timeZone)
  const shiftedDateTime = setTime(moment(date), localDateTime)
  const utcDateTime = localToUtc(shiftedDateTime, timeZone)
  const startTimeRemainder = 30 - (utcDateTime.minute() % 30)

  return utcDateTime.add(startTimeRemainder, 'minutes')
}

export const defaultEnd = ({ timeZone, date, duration = DEFAULT_DURATION }) => {
  const localDateTime = utcToLocal(moment.utc(), timeZone)
  const shiftedDateTime = setTime(moment(date), localDateTime)
  const utcDateTime = localToUtc(shiftedDateTime, timeZone)

  return utcDateTime.startOf('hour').add(duration, 'minutes')
}
