import React from 'react'
import PropTypes from '~/utils/propTypes'
import MenuItem from '@material-ui/core/MenuItem'

const FieldValue = ({ value, label }) => (
  <MenuItem key={value} value={value}>
    {label}
  </MenuItem>
)
FieldValue.propTypes = {
  value: PropTypes.any,
  label: PropTypes.node,
}

export default fieldValues => fieldValues.toIndexedSeq().map(FieldValue)
