import { ofType } from 'redux-observable'
import { exited } from 'redux-routable'
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import { currentAssessmentFetched } from '~/apps/assessment/data/assessments'
import { ASSESSMENT_FORM } from '~/apps/assessment/router'
import { isOnline } from '~/data/connectivity'
import { getAssessmentMedicationsInitialized } from '../data/assessment'
import { getPatientUnsavedRx, putPatientRx } from '../data/patientRx'

const notEmpty = map => !map.isEmpty()

const onlineAndInitialized = state =>
  isOnline(state) && getAssessmentMedicationsInitialized(state)
const canSaveMeds = (newState, oldState) =>
  onlineAndInitialized(newState) === onlineAndInitialized(oldState)

export default (action$, state$) => {
  const pageExited$ = action$.pipe(filter(exited(ASSESSMENT_FORM)))

  return action$.pipe(
    ofType(currentAssessmentFetched),
    switchMap(() =>
      state$.pipe(
        distinctUntilChanged(canSaveMeds),
        filter(isOnline),
        map(getPatientUnsavedRx),
        filter(notEmpty),
        mergeMap(patienUnsavedRx =>
          patienUnsavedRx.reduce(
            (acc, med) => [...acc, putPatientRx.requested(med, false)],
            []
          )
        ),
        takeUntil(pageExited$)
      )
    )
  )
}
