import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const RECALCULATE_PATIENT_STATUS = 'recalculatePatientStatus'

export const fetchRecalcuateStatus = Request({
  typePrefix: rootKey,
  typeBase: 'RECALCULATE_PATIENT_STATUS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`/v1/patient/${patientId}/recalculate_status`),
  messages: {
    failed: 'There was a problem recalculating patient status',
    succeeded: payload => payload,
  },
})

export default createReducer(RECALCULATE_PATIENT_STATUS, null, {
  [fetchRecalcuateStatus.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getRecalcuateStatus = pipe(
  get(rootKey),
  get(RECALCULATE_PATIENT_STATUS)
)
