import React from 'react'
import { PATIENT_RECORD_PATIENT_INFO } from '~/apps/patientRecord/router'
import TypographyLink from '~/components/TypographyLink'
import PropTypes from '~/utils/propTypes'

const PatientLink = ({ children, patientId }) => (
  <TypographyLink
    action="open"
    params={{ patientId }}
    route={PATIENT_RECORD_PATIENT_INFO}
  >
    {children}
  </TypographyLink>
)

PatientLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  patientId: PropTypes.string.isRequired,
}

export default PatientLink
