import { Map, Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'
import rootKey from '../key'
import { getRoot } from './common/shared'

// RECORDS
export const Action = Record({
  name: null,
  description: null,
})

// KEY
const key = 'panelTabs'

// ACTIONS
export const ADD_PANEL_TAB = type(rootKey, 'ADD_PANEL_TAB')
export const REMOVE_PANEL_TAB = type(rootKey, 'REMOVE_PANEL_TAB')

// ACTION CREATORS
export const addPanelTab = creator(ADD_PANEL_TAB, 'tab')
export const removePanelTab = creator(REMOVE_PANEL_TAB, 'key')

// REDUCER
const initState = Map()

export default createReducer(key, initState, {
  [ADD_PANEL_TAB]: (state: any, { payload: { tab } }: any) =>
    tab.key ? state.set(tab.key, tab) : state,
  [REMOVE_PANEL_TAB]: (state: any, { payload: { key } }: any) =>
    state.delete(key),
})

// SELECTORS
// @ts-expect-error Object is unknown
export const getTabs = (state: any) => getRoot(state).get(key).toList().toJS()

// @ts-expect-error Object is unknown
export const tabsAvailable = (state: any) => getRoot(state).get(key).size > 0
