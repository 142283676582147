import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { PhoneDetails } from '../../utils/interfaces'
import { ContactMethodList } from '../ContactMethodList'
import { PhoneItem } from './PhoneItem'

interface PhoneListPropTypes {
  phones: PropTypes.list.isRequired
  isCaremoreBrand: PropTypes.Boolean
}

export const PhoneList = ({ phones, isCaremoreBrand }: PhoneListPropTypes) => {
  return (
    <ContactMethodList icon="phone" label="Phone Numbers">
      {phones
        .sort((_a: PhoneDetails, b: PhoneDetails) => (b.primary ? 1 : -1))
        .map((phone: PhoneDetails) => (
          <PhoneItem
            key={phone.id}
            phone={phone}
            isCaremoreBrand={isCaremoreBrand}
          />
        ))}
    </ContactMethodList>
  )
}
