import { init as review, reducer as Review } from './data/review'
import {
  init as finalization,
  reducer as Finalization,
} from './data/finalization'
import { Action as LLOS } from './LLOST'
import { flow } from 'fp-ts/lib/function'
import { payloadGet } from '~/utils/type'
import { State as ReduxS } from '~/reducerT'

// module Recommendation =
//   FieldValue.Make({
//     const key = "llos_recommendation";
//   })

export const init = {
  review,
  finalization,
}

export const reducer: (_: LLOS) => (_: ReduxS) => ReduxS = flow(
  payloadGet,
  LLOS.matchStrict({
    Review,
    Finalization,
  })
)
