import React from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from '@material-ui/core/TextField'

export const AttachmentsFilter = ({ handleChange }) => {
  return <TextField fullWidth label="Filter" onChange={handleChange} />
}

AttachmentsFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
}
