import { paramsReducer } from 'redux-routable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
import router from '../router'
import { getRoot } from './common/shared'

const CURRENT_USER_ID = 'currentUserId'

export const currentUserId = paramsReducer(
  router,
  // @ts-expect-error ts-migrate(7006) Parameter 'userId' implicitly has an 'any' type
  ({ userId }) => userId || null
)

currentUserId.key = CURRENT_USER_ID

// @ts-expect-error ts-migrate(7006) Parameter 'state' implicitly has an 'any' type
export const getCurrentUserId = state =>
  // @ts-expect-error Object is unknown
  getRoot(state)?.get(CURRENT_USER_ID) || getUserId(state)
