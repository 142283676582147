// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'TripAddEdit'

export const getTripAddEdit = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const createTripAdd = creator('CREATE_TRIP_ADD')
export const createTripEdit = creator('CREATE_TRIP_EDIT')
export const createTripNoAction = creator('CREATE_TRIP_NO_ACTION')

// REDUCER
export const tripAddEditStatus = createReducer(key, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [createTripAdd]: () => 'Add',
  // @ts-expect-error can we deal with the toString being automatically called?
  [createTripEdit]: () => 'Edit',
  // @ts-expect-error can we deal with the toString being automatically called?
  [createTripNoAction]: () => '',
})
