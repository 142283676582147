import { clone, setWith } from 'lodash'
import calcFormData from './calcFormData'

// Accepts an Immutable Record
export default (record, path, value) => {
  const { schema, formData, context, tags } = record
  const changed = path ? setWith(clone(formData), path, value, clone) : value
  const newFormData = calcFormData({ formData: changed, schema, context, tags })

  return record.set('formData', newFormData)
}
