import { Record } from 'immutable'
import { clone, setWith, toString } from 'lodash'
import { calcFormData, getTaggedValue } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import * as mockContexts from '../utils/mockContexts'
import { getFormsDevelopment } from './common/shared'

const key = 'form'

export const Form = Record({
  schema: null,
  uiSchema: null,
  tags: null,
  formData: null,
  context: null,
  templateKey: null,
})

const setContext = (entity: any) => (data: any) => ({
  ...data,
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  context: mockContexts[entity],
})

const transformForm = ({ data, ...rest }: any) =>
  Form({ ...rest, formData: data })

const initializeForm = (form: any) =>
  form.set(
    'formData',
    calcFormData({
      formData: form.formData,
      schema: form.schema,
      context: form.context,
      tags: form.tags,
    })
  )

export const fetchMockForm = Request({
  typePrefix: key,
  typeBase: 'FETCH_MOCK_FORM',
  requestParams: ['templateKey', 'entity'],
  operation: (templateKey: any, entity: any) =>
    AspireAPI.get(`forms_development/${templateKey}/mock`).then(
      setContext(entity)
    ),
  transform: pipe(transformForm, initializeForm),
  messages: { failed: toString },
})

export const FORM_DATA_CHANGED = type(key, 'FORM_DATA_CHANGED')
export const formDataChanged = creator(FORM_DATA_CHANGED, 'formData')

export const FORM_DATA_CHANGED_BY_TAG = type(key, 'FORM_DATA_CHANGED_BY_TAG')
export const formDataChangedByTag = creator(
  FORM_DATA_CHANGED_BY_TAG,
  'tag',
  'value'
)

export const FORM_REFRESHED = type(key, 'FORM_REFRESHED')
export const formRefreshed = creator(FORM_REFRESHED)

const changeFormData = (form: any, path: any, value: any) => {
  const { schema, formData, context, tags } = form
  const changed = path ? setWith(clone(formData), path, value, clone) : value
  const newFormData = calcFormData({ formData: changed, schema, context, tags })

  return form.set('formData', newFormData)
}

export default createReducer(key, null, {
  [fetchMockForm.REQUESTED]: () => null,
  [fetchMockForm.SUCCEEDED]: (_state: any, { payload: form }: any) => form,
  [FORM_DATA_CHANGED]: (state: any, { payload: { formData } }: any) =>
    changeFormData(state, null, formData),
  [FORM_DATA_CHANGED_BY_TAG]: (state: any, { payload: { tag, value } }: any) =>
    changeFormData(state, state.tags[tag].data, value),
})

export const getForm = pipe(getFormsDevelopment, get(key))

export const getFormDataByTag = (state: any, tag: any, options: any) => {
  const form = getForm(state)
  return getTaggedValue(form, tag, options)
}
