import { partition } from 'lodash'
import { ofType } from 'redux-observable'
import { from, merge } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import { addPatientLatLongError } from '../data/patientErrors'
import { PATIENT_FILTER_SEARCH_CLICKED } from '../data/patientFilteredSearch'
import {
  PATIENT_STATUS_FILTER_SELECTED,
  getPatientStatusFilterName,
  getSelectedPatientStatusesFilter,
} from '../data/patientStatusesFilter'
import { fetchPatientsByFilteredCareTeam, loadPatients } from '../data/patients'
import { getPolygons } from '../data/polygons'
import { getSelectedMarketPolygons } from '../data/selectedMarketPolygons'
import showError from '../utils/showError'

const searchButtonClicked = (action$, state$) =>
  action$.pipe(
    ofType(PATIENT_FILTER_SEARCH_CLICKED),
    mergeMap(() => {
      const filteredPolygons = getSelectedMarketPolygons(state$.value)
      const selectedStatusus = getSelectedPatientStatusesFilter(state$.value)
      const selectedCareTeams = getPolygons(state$.value)
        .filter(p => filteredPolygons.includes(p.get('id')))
        .map(p => p.get('care_team_id'))
        .toSet()
        .flatMap(x => x)
        .toArray()
      return getPatients(selectedStatusus, selectedCareTeams)
    })
  )

const patientStatusFilterSelected = (action$, state$) =>
  action$.pipe(
    ofType(PATIENT_STATUS_FILTER_SELECTED),
    pluck('payload', 'statuses'),
    mergeMap(statuses => {
      const filteredPolygons = getSelectedMarketPolygons(state$.value)
      const selectedCareTeams = getPolygons(state$.value)
        .filter(p => filteredPolygons.includes(p.get('id')))
        .map(p => p.get('care_team_id'))
        .toSet()
        .flatMap(x => x)
        .toArray()
      return getPatients(statuses, selectedCareTeams)
    })
  )

export { searchButtonClicked, patientStatusFilterSelected }

const getPatients = (selectedStatusus, selectedCareTeams) => {
  return loading(
    merge(
      ...selectedStatusus.map(id =>
        from(
          fetchPatientsByFilteredCareTeam(
            getPatientStatusFilterName(id),
            selectedCareTeams
          )
        )
          .pipe(
            map(patients => {
              const [missingGeoloc, hasGeoloc] = partition(
                patients,
                patient => {
                  return !patient.latitude || !patient.longitude
                }
              )
              return { hasGeoloc, missingGeoloc }
            })
          )
          .pipe(
            mergeMap(({ hasGeoloc, missingGeoloc }) => {
              return missingGeoloc.length
                ? [
                    loadPatients(hasGeoloc),
                    addPatientLatLongError(missingGeoloc),
                  ]
                : [loadPatients(hasGeoloc)]
            })
          )
      )
    ).pipe(catchError(showError('Failed to fetch EMR Patients.')))
  )
}
