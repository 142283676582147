import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import { List, Record } from 'immutable'
import rootKey from '../key'

// KEY
const key = 'benefitUploadDialog'

export const getDialogOpenValue = getIn([rootKey, key])

export const BenefitUploadTemplate = Record({
  contract_year: null,
  classplan_product_id: null,
  classplan_product_name: null,
  county_name: null,
  destination_type: null,
  limit: null,
})

export const sampleBenefitUploadTemplateData = List([
  BenefitUploadTemplate({
    contract_year: '',
    classplan_product_id: '',
    classplan_product_name: '',
    county_name: '',
    destination_type: '',
    limit: '',
  }),
])

// CREATORS
const creator = scopedCreator(key)

export const benefitUploadDialogOpened = creator('BENEFIT_UPLOAD_DIALOG_OPENED')
export const benefitUploadDialogClosed = creator('BENEFIT_UPLOAD_DIALOG_CLOSED')

// REQUEST
export const uploadBenefit = Request({
  typePrefix: key,
  typeBase: 'UPLOAD_BENEFIT',
  requestParams: ['benefitFileInput'],
  operation: (benefitFileInput: any) =>
    AspireAPI.post(`transportation/upload_benefits`, benefitFileInput),
  messages: {
    succeeded: 'Benefit uploaded successfully',
    failed: 'Benefit upload failed',
  },
})

// REDUCER
export const benefitUploadDialog = createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [benefitUploadDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [benefitUploadDialogOpened]: () => true,
  [uploadBenefit.succeeded]: () => false,
})
