import React from 'react'
// @ts-expect-error no export
import { PATIENT_RECORD_PATIENT_INFO } from '~/apps/patientRecord/router'
// @ts-expect-error no export
import { OpenLink } from '~/components/links'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette }) => ({
  patientLink: {
    color: palette.severity.warning.dynamic,
  },
}))

type Props = {
  row: {
    patientId: string
  }
  value: string
}

// Used in DevXGrids - only for patient name so far
function PatientCell({ row, value }: Props) {
  const classes = useStyles()

  return (
    <Button
      color="primary"
      component={OpenLink}
      params={{ patientId: row.patientId }}
      className={classes.patientLink}
      route={PATIENT_RECORD_PATIENT_INFO}
    >
      {value || ''}
    </Button>
  )
}

export default React.memo(PatientCell)
