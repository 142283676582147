import { ofType } from 'redux-observable'
import { distinctUntilChanged, map, pluck } from 'rxjs/operators'
import { scopedCreator } from '~/utils/data'
import { calculateDistance } from '../data/getDistance'

const key = 'calcDistance'

export const distanceCalculation = scopedCreator(
  key
)('DISTANCE_CALCULATION_REQUESTED', ['pickup', 'dest'])

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const distanceCalc = action$ =>
  action$.pipe(
    ofType(distanceCalculation),
    pluck('payload'),
    distinctUntilChanged(),
    map(payload => {
      // @ts-expect-error ts-migrate(7006) Parameter 'payload' implicitly has an 'any' type
      return calculateDistance.requested(payload?.pickup, payload?.dest)
    })
  )
