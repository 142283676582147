import { concat, from, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { loadingStarted, loadingStopped } from '~/data/pending'
import { showError } from '~/utils/alertOnError'
import generateId from '~/utils/generateId'

export default (obs, errorMessage) => {
  const key = generateId()

  return concat(
    of(loadingStarted(key)),
    from(obs).pipe(catchError(showError(errorMessage))),
    of(loadingStopped(key))
  )
}
