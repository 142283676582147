import { isEmpty } from 'lodash'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { FormHelperText } from '@material-ui/core'
import memoizedWrapper from '../widgets/memoizedWrapper'

const SearchField = props => {
  const {
    disabled,
    readonly,
    registry,
    schema,
    uiSchema,
    formData,
    errorSchema,
  } = props
  if (!schema.properties) {
    throw 'Properties need to be defined for search field'
  }

  if (!uiSchema['ui:options']['searchWidget']) {
    throw 'Need to provide search widget in ui:options'
  }

  const requiredProperties = schema.required
  const options = uiSchema['ui:options']

  return (
    <div>
      <registry.fields.StringField
        {...props}
        uiSchema={{
          ...uiSchema,
          'ui:widget':
            disabled || readonly || requiredProperties.every(p => formData[p])
              ? 'ClearableFieldWidget'
              : options.searchWidget,
        }}
      />
      {!isEmpty(errorSchema) && (
        <FormHelperText error>
          {options.customErrorMessage || 'a search option must selected'}
        </FormHelperText>
      )}
    </div>
  )
}

SearchField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  errorSchema: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  uiSchema: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  formData: PropTypes.object,
  registry: PropTypes.object.isRequired,
}

SearchField.defaultProps = {
  formData: {},
}

export default memoizedWrapper(SearchField)
