import cx from 'classnames'
import { List } from 'immutable'
import React from 'react'
import { useFieldValuesOld } from '~/hooks'
import { formatDateTime } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { Avatar, Divider, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { categoryToIcon } from '../../constants'
import { colorize } from '../../utils/colorize'
import { eventLabel, eventStatus, swimlaneCategory } from '../../utils/event'

const useStyles = makeStyles(({ colors, palette, spacing, typography }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(1),
  },
  avatar: {
    borderRadius: typography.fontSize,
    height: typography.fontSize * 2,
    width: typography.fontSize * 2,
    marginRight: spacing(1),
  },
  avatarIcon: {
    fontSize: typography.fontSize,
  },
  caption: {
    color: palette.text.secondary,
  },
  divider: {
    margin: spacing(1, 0),
  },
  ...colorize(colors),
}))
const PatientEvent = ({ patientEvent }) => {
  const classes = useStyles()

  const fieldValues = useFieldValuesOld('timeline_event_type')

  const label = eventLabel(fieldValues, patientEvent)

  const { category, details, createdByName, eventDate } = patientEvent

  const status = eventStatus(category, details)

  const ownerName = (details || {}).ownerName || createdByName

  const isProgramEnrolled = category === 'program_enrolled'

  const notes = patientEvent.notes
    ?.reduce((list, { note }) => list.push(note), List())
    .join('\n')

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Avatar
          className={cx(
            classes.avatar,
            classes[`${swimlaneCategory(patientEvent)}Color`]
          )}
        >
          <Icon
            className={cx(
              classes.avatarIcon,
              classes[`${swimlaneCategory(patientEvent)}IconColor`]
            )}
          >
            {categoryToIcon(category)}
          </Icon>
        </Avatar>

        <div>
          {isProgramEnrolled ? (
            <Typography>{`${label}`}</Typography>
          ) : (
            <Typography>{`${label} - ${status}`}</Typography>
          )}
          <Typography className={classes.caption} variant="caption">
            {`By ${ownerName} on ${formatDateTime(eventDate)}`}
          </Typography>
        </div>
      </div>

      <Typography>{notes}</Typography>

      <Divider className={classes.divider} />
    </React.Fragment>
  )
}

PatientEvent.propTypes = {
  patientEvent: PropTypes.record.isRequired,
}

export default PatientEvent
