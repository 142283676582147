import React, { useRef, useState } from 'react'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  legend: {
    marginBottom: spacing(2),
  },
  paper: {
    padding: [spacing(1.5), spacing(2)],
    width: 300,
  },
}))

const Legend = ({ children }) => {
  const classes = useStyles()

  const [legendOpen, setLegendOpen] = useState(false)
  const [popperFocused, focusPopper, unfocusPopper] = useToggle()
  const legendRef = useRef()

  return (
    <React.Fragment>
      <Button
        ref={e => {
          legendRef.current = e
        }}
        className={classes.legend}
        color="primary"
        onClick={() => setLegendOpen(legendOpen => !legendOpen)}
        variant="contained"
      >
        View Map Legend
      </Button>

      <Popper
        open={legendOpen || popperFocused}
        anchorEl={legendRef.current}
        placement="bottom-end"
        onMouseEnter={focusPopper}
        onMouseLeave={unfocusPopper}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setLegendOpen(false)}>
            <Fade {...TransitionProps} timeout={200}>
              <Paper className={classes.paper} elevation={12}>
                {children}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  )
}

Legend.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Legend
