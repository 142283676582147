import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getMedications } from './root'

const key = 'assesmentInitialized'
const creator = scopedCreator(key)

export const medAssessmentSet = creator('MED_ASSESSMENT_SET')

export default createReducer(key, false, {
  [medAssessmentSet]: () => true,
})

export const getAssessmentMedicationsInitialized = pipe(
  getMedications,
  get(key)
)
