import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import TASKS from '../key'
import { getRoot } from './common'
import { reassignTask } from './tasks'

// Key
const REASSIGNMENT_USER = 'reassignmentUser'
const creator = scopedCreator(`${TASKS}/${REASSIGNMENT_USER}`)

const ReassignmentUser = Record({
  id: '',
  role: '',
})

// Actions
export const reassignmentUserDeselected = creator(
  'REASSIGNMENT_USER_DESELECTED'
)

export const reassignmentUserSelected = creator('REASSIGNMENT_USER_SELECTED', [
  'id',
  'role',
])

// Reducer
export default createReducer(REASSIGNMENT_USER, ReassignmentUser(), {
  [reassignTask.SUCCEEDED]: () => ReassignmentUser(),
  [reassignmentUserDeselected]: () => ReassignmentUser(),
  [reassignmentUserSelected]: pipe(action, payload, ({ id, role }) =>
    ReassignmentUser({ id, role })
  ),
})

// Selector
export const getReassignmentUser = pipe(getRoot, get(REASSIGNMENT_USER))
export const getReassignmentUserId = pipe(getReassignmentUser, get('id'))
