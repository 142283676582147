import { fromEvent, Subscription } from 'rxjs'
import { filter, first, tap } from 'rxjs/operators'

const messages = {
  INIT_LOGIN_REQUESTED: 'INIT_LOGIN_REQUESTED',
  INIT_SUCCEEDED: 'INIT_SUCCEEDED',
  INIT_FAILED: 'INIT_FAILED',
  LOGGED_OUT: 'LOGGED_OUT',
  LOGGED_IN: 'LOGGED_IN',
}

export interface RxCallbacks {
  onMessage: (message: string, callback: (x: unknown) => void) => Subscription
  onFirstMessage: (
    message: string,
    callback: (x: unknown) => void
  ) => Subscription
}

export const createOnMessage = (target: Window): RxCallbacks => {
  const message$ = fromEvent<Window>(target, 'message')
  const create = (extraOperators: any) => (
    message: string,
    callback: (x: unknown) => void
  ) =>
    message$
      // @ts-expect-error description
      .pipe(
        filter(({ data }: any) => data === message),
        tap(callback),
        ...extraOperators
      )
      .subscribe()

  return {
    onMessage: create([]),
    onFirstMessage: create([first()]),
  }
}

export const postMessage = (target: Window, message: string) =>
  target.postMessage(message, window.location.origin)

export default messages
