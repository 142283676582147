import {
  fetchCaseManager,
  fetchGroup,
  fetchHospice,
  fetchHospital,
  fetchIPA,
  fetchPhysician,
  fetchPractice,
  fetchVendor,
  // @ts-expect-error no export
} from '~/features/contacts'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const RECORD_LOADED = 'recordLoaded'

const creator = scopedCreator(rootKey)
export const recordCleared = creator('RECORD_CLEARED')

export default createReducer(RECORD_LOADED, false, {
  [fetchCaseManager.SUCCEEDED]: () => true,
  [fetchGroup.SUCCEEDED]: () => true,
  [fetchHospice.SUCCEEDED]: () => true,
  [fetchHospital.SUCCEEDED]: () => true,
  [fetchIPA.SUCCEEDED]: () => true,
  [fetchPhysician.SUCCEEDED]: () => true,
  [fetchPractice.SUCCEEDED]: () => true,
  [fetchVendor.SUCCEEDED]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [recordCleared]: () => false,
})

export const getRecordLoaded = pipe(getRoot, get(RECORD_LOADED))
