import { Map, Record } from 'immutable'
import { ofType } from 'redux-observable'
import { first, map } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'

const MARKETS = 'markets'

export const Market = Record({
  id: null,
  name: null,
})

const fetchMarkets = Request({
  typePrefix: MARKETS,
  typeBase: 'FETCH_MARKETS',
  operation: () => AspireAPI.get('v1/market'),
  transform: into(Market, 'id'),
  messages: {
    failed: 'There was an issue fetching markets',
  },
})

const creator = scopedCreator(MARKETS)
export const marketsRequested = creator('MARKETS_REQUESTED')

export default createReducer(MARKETS, Map(), {
  [fetchMarkets.SUCCEEDED]: (_state, { payload }) => payload,
})

export const epic = action$ =>
  action$.pipe(ofType(marketsRequested), first(), map(fetchMarkets.requested))

export const getMarkets = get(MARKETS)
