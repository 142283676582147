import React, { useMemo } from 'react'
import DevXGrid from '~/components/DevXGrid'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import { pharmacyColumns } from '../../utils/pharmacyConstants'

const PharmacySearch = ({ preferredPharmacy }) => {
  const pharmacyRow = useMemo(() => [preferredPharmacy], [preferredPharmacy])
  const defaultSorting = [{ columnName: 'type', direction: 'asc' }]

  return (
    <Paper>
      <DevXGrid
        rows={pharmacyRow}
        columns={pharmacyColumns}
        defaultSorting={defaultSorting}
        collapsible
      />
    </Paper>
  )
}

PharmacySearch.propTypes = {
  preferredPharmacy: PropTypes.object.isRequired,
}

export default PharmacySearch
