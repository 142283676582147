import { Record } from 'immutable'
import { ofType } from 'redux-observable'
import { open } from 'redux-routable'
import { map, pluck } from 'rxjs/operators'
import { PATIENT_RECORD_VIDEO_VISIT_CONSENT } from '~/apps/patientRecord/router'
import { calcFormData } from '~/components/JsonForm'
import { isRequestPending } from '~/data/pending'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, getIn, payload, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import VIDEO_VISIT_CONSENT from '../key'

// Key
const FORM = 'form'

// Type
const typePrefix = `${VIDEO_VISIT_CONSENT}/${FORM}`
const creator = scopedCreator(typePrefix)

// Actions
export const formCancelled = creator('CANCELLED')

export const formEntered = creator('ENTERED', ['patientId'])

export const formErrored = creator('ERRORED')

export const formUpdated = creator('UPDATED', ['formData', 'errored'])

// Record
export const Form = Record({
  context: {},
  errored: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
})

// Requests
export const completeForm = Request({
  typePrefix,
  typeBase: 'COMPLETION',
  requestParams: ['data', 'patientId'],
  operation: (data, patientId) => {
    const type = 'video_visit_consent'

    return AspireAPI.post(`/v1/patients/${patientId}/contracts`, {
      data: { ...data, type },
    })
  },
  messages: {
    failed: 'Failed to complete Video Visit Consent Form',
    succeeded: 'Completed Video Visit Consent Form',
  },
})

export const fetchForm = Request({
  typePrefix,
  typeBase: 'FORM_FETCH',
  requestParams: ['patientId'],
  operation: patientId => {
    return AspireAPI.get(
      `v1/patients/${patientId}/contract_forms?type=video_visit_consent`
    )
  },
  transform: ({ data: formData, ...form }) =>
    Form({
      ...form,
      formData: calcFormData({
        context: form.context,
        formData,
        schema: form.schema,
        tags: form.tags,
      }),
    }),
  messages: {
    failed: 'Failed to load Video Visit Consent form',
  },
})

// Reducer
export default createReducer(FORM, null, {
  [completeForm.SUCCEEDED]: () => null,
  [fetchForm.REQUESTED]: () => null,
  [fetchForm.SUCCEEDED]: compose(payload, action),
  [formCancelled]: () => null,
  [formErrored]: (state, action) => state.set('errored', action.payload),
  [formUpdated]: (state, { payload: { errored, formData } }) =>
    state
      .set(
        'formData',
        calcFormData({
          context: state.context,
          formData,
          schema: state.schema,
          tags: state.tags,
        })
      )
      .set('errored', errored),
})

// Selectors
export const getForm = getIn([VIDEO_VISIT_CONSENT, FORM])

const isPending = request => state => isRequestPending(state, request)

export const isFormPending = isPending(fetchForm)

export const isFormSubmitting = isPending(completeForm)

// Epic
const formEnteredEpic = action$ =>
  action$.pipe(
    ofType(formEntered),
    pluck('payload', 'patientId'),
    map(patientId => open(PATIENT_RECORD_VIDEO_VISIT_CONSENT, { patientId }))
  )

export const epic = formEnteredEpic
