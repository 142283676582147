import React from 'react'
import PatientLinkBase from '~/components/PatientLink'
import PropTypes from '~/utils/propTypes'

const PatientLink = ({ value, row: { patientId } }) => (
  <PatientLinkBase patientId={patientId}>{value}</PatientLinkBase>
)

PatientLink.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
}

export default React.memo(PatientLink)
