import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import { distinctUntilChanged, filter, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { patientInfoCleared } from '~/features/patientInfo'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { patientTimelineCleared } from '~/features/patientTimeline/data'
import { carePlanCleared } from '~/features/problems/data'
import { fetchEncounterReview } from '../data/currentEncounterReview'
import { PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(changedTo(PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS)),
    pluck('payload', 'params', 'encounterReviewId'),
    filter(id => id !== undefined),
    distinctUntilChanged(),
    mergeMap(id =>
      of(
        fetchEncounterReview.requested(id),
        patientInfoCleared(),
        patientTimelineCleared(),
        carePlanCleared()
      )
    )
  )
