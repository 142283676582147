import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { changePatientProgramAndCareTeam } from '~/features/careTeamManagement/data/careTeams'
import { fetchPatient } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'

export default (action$, state$) =>
  action$.pipe(
    ofType(changePatientProgramAndCareTeam.SUCCEEDED),
    switchTo(state$),
    map(getPatientId),
    map(fetchPatient.requested)
  )
