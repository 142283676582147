import moment from 'moment'
import React, { useMemo } from 'react'
import DevXGrid from '~/components/DevXGrid'
import { pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'

const VitalsTableBase = ({ vitals, patientBrand }) => {
  const vitalsArray = useMemo(() => vitals.toIndexedSeq().toJS(), [vitals])

  const getVital = vital =>
    vitalsArray.map(v => ({
      [v.dateOfService]: v[vital],
    }))

  const getRow = vital => vital.reduce((r, c) => Object.assign(r, c), {})

  const vitalsValues = [
    { key: 'ppsScore', label: 'PPS' },
    { key: 'weight', label: 'Weight' },
    { key: 'bmi', label: 'BMI' },
    { key: 'pulseOximetryScore', label: 'Pulse Oximetry' },
    { key: 'pulse', label: 'Pulse' },
    { key: 'associatedActivitySob', label: 'Shortness of Breath' },
    { key: 'severityCurrentPain', label: 'Current Pain' },
    { key: 'systolic', label: 'Systolic' },
    { key: 'diastolic', label: 'Diastolic' },
    { key: 'midArmCircumference', label: 'Mid-Arm Circumference' },
  ]

  if (patientBrand === 'caremore') {
    vitalsValues.splice(
      2,
      0,
      { key: 'height', label: 'Height' },
      { key: 'respiratoryRate', label: 'Respiratory Rate' }
    )
  }

  const vitalsRows = useMemo(
    () =>
      vitalsValues.map(arr => ({
        Vitals: arr['label'],
        ...pipe(getVital, getRow)(arr['key']),
      })),
    [vitalsArray]
  )

  const columnExtensions = [{ columnName: 'Vitals', width: 210 }]

  const vitalsTableColumns = useMemo(
    () => [
      { name: 'Vitals', title: ' ' },
      ...vitalsArray
        .sort((a, b) => new Date(a.dateOfService) - new Date(b.dateOfService))
        .map(b => ({
          name: b.dateOfService,
          title: moment(b.dateOfService).format('M/DD/YYYY'),
        })),
    ],
    [vitalsArray]
  )

  const leftColumns = ['Vitals']

  return (
    <Paper>
      <DevXGrid
        rows={vitalsRows}
        columns={vitalsTableColumns}
        columnExtensions={columnExtensions}
        leftColumns={leftColumns}
      />
    </Paper>
  )
}

VitalsTableBase.propTypes = {
  vitals: PropTypes.map,
  patientBrand: PropTypes.string,
}

export default VitalsTableBase
