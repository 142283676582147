import { includes } from 'lodash/fp'
import { capitalizeFirst, removeUnderscores } from '~/utils/stringManipulation'
import {
  CALL,
  CALLS_CLINICAL,
  CALLS_OTHER,
  HOSPICE,
  ON_CALL,
  ORDER,
  TASK,
} from '../constants'

// Generate label based on category/visit type
export const eventLabel = (
  fvs,
  { userRole, category, details: { label, visitType, callIntent } }
) => {
  const FIELD_VALUES = Object.fromEntries(
    fvs.valueSeq().map(fv => [fv.value, fv.label])
  )

  const CATEGORY_LABELS_W_FIELD_VALUES = {
    [HOSPICE]: 'Hospice',
    [ON_CALL]: 'On Call (After Hours)',
    [ORDER]: 'Order',
    [TASK]: 'Task',
    ...FIELD_VALUES,
  }
  return visitType
    ? visitType
    : category == TASK
    ? label
    : category == CALL
    ? isCallClinical(callIntent, userRole)
      ? 'Call (Clinical)'
      : 'Call (Other)'
    : // key comes from API event "category", value is friendly name
      CATEGORY_LABELS_W_FIELD_VALUES[category]
}

// Generate status based on call/event type
export const eventStatus = (category, { status, inbound }) =>
  category === CALL
    ? inbound
      ? 'Inbound'
      : 'Outbound'
    : category === ON_CALL
    ? includes('Non', status) // pass on_call category for status arg here.
      ? 'Non-Clinical'
      : includes('Notice', status)
      ? 'Notice Of Event'
      : status
    : capitalizeFirst(removeUnderscores(status))

export const isCallClinical = (intent, userRole) => {
  const isClinicalIntent = [
    'Clinical Triage',
    'Meds & Orders Mgmt',
    'Central Care Mgmt',
    'SW',
  ].includes(intent)

  const isClinicalRole = [
    'APP',
    'Field-APP',
    'Field-MD',
    'Field-RN',
    'Field-SW',
    'Field-Team Lead',
    'PCC-RN',
    'PCC-SW',
  ].includes(userRole)

  return isClinicalIntent || (intent === 'General' && isClinicalRole)
}

export const swimlaneCategory = pe =>
  pe.category === CALL
    ? isCallClinical(pe.details.callIntent, pe.createdByRole)
      ? CALLS_CLINICAL
      : CALLS_OTHER
    : pe.category
