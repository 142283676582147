import cx from 'classnames'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field } from 'redux-form/immutable'
import {
  AutoComplete,
  Checkbox,
  SelectField,
  TextField,
} from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import {
  directionSuggestionSelector,
  required,
  validateDirections,
} from '../../../utils/formValidators'
import { formSelector } from '../../../utils/formValueSelectors'
import { PENDING } from '../../../utils/medListConstants'
import RxStatusOptions from '../../RxStatusOptions'
import FormFieldWrapper from './FormFieldWrapper'

const styles = ({ spacing }) => ({
  formSpan: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    top: spacing(1),
    right: spacing(2),
    '&:hover': {
      top: spacing(1),
      left: spacing(1) * -2,
    },
  },
  fullWidth: {
    width: '100%',
    overflow: 'auto',
  },
  halfWidth: {
    width: '48%',
  },
})

const PrimaryRxFields = ({ classes, directionsValue, isEditing }) => {
  const disabledStatuses = useMemo(() => (isEditing ? [PENDING] : []), [
    isEditing,
  ])

  return (
    <FormFieldWrapper title="Primary Rx Fields - Required" isCollapsed={false}>
      <span className={classes.formSpan}>
        <span className={classes.halfWidth}>
          <Field
            component={SelectField}
            className={classes.fullWidth}
            name="status"
            label="Rx Status"
            native
            validate={[required]}
          >
            <RxStatusOptions disabledStatuses={disabledStatuses} />
          </Field>
        </span>
        <span className={cx(classes.formSpan, classes.halfWidth)}>
          <Field
            component={Checkbox}
            className={classes.checkbox}
            name="eKit"
          />
          <Field
            component={TextField}
            className={classes.fullWidth}
            label="E-Kit Medication - INTERNAL USE ONLY"
            name="eKitPrompt"
            disabled
          />
        </span>
      </span>
      <Field
        component={AutoComplete}
        className={classes.fullWidth}
        label="Direction"
        name="directions"
        availableSuggestions={directionSuggestionSelector(directionsValue)}
        fuzzy
        validate={[required, validateDirections]}
      />
    </FormFieldWrapper>
  )
}

PrimaryRxFields.propTypes = {
  classes: PropTypes.object.isRequired,
  directionsValue: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  directionsValue: formSelector(state, 'directions'),
})

export default compose(
  React.memo,
  connect(mapStateToProps),
  withStyles(styles)
)(PrimaryRxFields)
