import { format } from 'date-fns'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core'
import TaskDialogActions from './TaskDialogActions'

export default class SnoozeTaskDialog extends React.Component {
  static propTypes = {
    dialogClosed: PropTypes.func.isRequired,
    task: PropTypes.record.isRequired,
    newDueDate: PropTypes.string.isRequired,
    newDueDateSelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    snoozeTaskRequested: PropTypes.func.isRequired,
  }

  get disabled() {
    return this.props.pending
  }

  setNewDueDate = ({ target: { value } }) =>
    value && value >= format(new Date(), 'yyyy-MM-dd')
      ? this.props.newDueDateSelected(event.target.value)
      : null

  submit = () => {
    const {
      task: { id },
      newDueDate,
      snoozeTaskRequested,
    } = this.props

    snoozeTaskRequested(id, newDueDate)
  }

  DueDate = () => (
    <FormControl>
      <TextField
        id="snooze-due-date"
        label="New Due Date"
        onChange={this.setNewDueDate}
        required
        type="date"
        value={this.props.newDueDate}
      />
    </FormControl>
  )

  render() {
    const {
      disabled,
      DueDate,
      props: { dialogClosed, open },
      submit,
    } = this

    return (
      <Dialog
        aria-labelledby="snooze-task-form"
        onClose={dialogClosed}
        open={open}
      >
        <DialogTitle id="snooze-task-form">Snooze this task?</DialogTitle>
        <DialogContent>
          <DueDate />
        </DialogContent>
        <TaskDialogActions
          disabled={disabled}
          onCancel={dialogClosed}
          onSubmit={submit}
        />
      </Dialog>
    )
  }
}
