import { ofType } from 'redux-observable'
import { filter, map, merge, withLatestFrom } from 'rxjs/operators'
import {
  EXPECTED_FULFILLMENT_DATE_CHANGED,
  FULFILLMENT_DATE_CHANGED,
  ORDER_DETAIL_FORM_CHANGED,
  ORDER_DETAIL_FORM_SAVE_FAILED,
  ORDER_DETAIL_FORM_SAVE_REQUESTED,
  ORDER_DETAIL_FORM_SAVE_SUCCEEDED,
  ORDER_NOTE_CHANGED,
  OUT_OF_NETWORK_REASON_CHANGED,
  VENDOR_CHANGED,
} from '../data/orderDetail'
import {
  getUnsavedChanges,
  unsavedChangesDetected,
} from '../data/saveIndicator'

const changeActions = [
  EXPECTED_FULFILLMENT_DATE_CHANGED,
  FULFILLMENT_DATE_CHANGED,
  ORDER_DETAIL_FORM_CHANGED,
  ORDER_NOTE_CHANGED,
  OUT_OF_NETWORK_REASON_CHANGED,
  VENDOR_CHANGED,
]
export default (action$, state$) => {
  const changeOutsideSave$ = action$.pipe(
    ofType(...changeActions),
    filter(() => !getUnsavedChanges(state$.value))
  )

  const changeDuringSave$ = action$.pipe(
    ofType(ORDER_DETAIL_FORM_SAVE_FAILED, ORDER_DETAIL_FORM_SAVE_SUCCEEDED),
    withLatestFrom(
      action$.pipe(ofType(...changeActions, ORDER_DETAIL_FORM_SAVE_REQUESTED))
    ),
    map(([, action]) => action),
    filter(({ type }) => changeActions.includes(type))
  )

  return changeOutsideSave$.pipe(
    merge(changeDuringSave$),
    map(() => {
      return unsavedChangesDetected()
    })
  )
}
