import React from 'react'
import Checkbox from '~/components/fields/Checkbox'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  checkbox: {
    margin: '-8px 0',
    '& span': {
      padding: 0,
    },
  },
})

const InlineCheckbox = ({ classes, ...props }) => (
  <Checkbox className={classes.checkbox} {...props} />
)

InlineCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(InlineCheckbox)
