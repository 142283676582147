import { debounce, get, isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import validateFormData from 'react-jsonschema-form/lib/validate'
import { useSelector } from 'react-redux'
import { ofType } from 'redux-observable'
import { Subject } from 'rxjs'
import { ignoreElements, map, switchMap, tap } from 'rxjs/operators'
import { isRequestPending } from '~/data/pending'
import { getUser } from '~/data/session'
import { useAction, usePrevious } from '~/hooks'
import { requestSucceeded } from '~/utils/operators'
import PropTypes from '~/utils/propTypes'
import { Button, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import memoizedWrapper from '../widgets/memoizedWrapper'

const isSavedObject = formData => get(formData, ['itemSaved', 'saved'], false)

// call the react-jsonschema-form onChange function setting the `savedItem`
// metadata
const change = (onChange, fieldData, user) => {
  onChange({
    ...fieldData,
    itemSaved: {
      saved: true,
      savedAt: new Date().toISOString(),
      savedUserName: user.name,
      savedUserId: user.id,
    },
  })
}

const useStyles = makeStyles(({ spacing }) => ({
  inlineBlock: {
    display: 'inline-block',
  },
  margins: {
    margin: spacing(1),
  },
}))

const SaveableObjectField = ({ onClick, isPending, onSave, ...props }) => {
  const { registry, schema, formData, uiSchema, onChange } = props

  const classes = useStyles(props)
  const user = useSelector(getUser)

  const wasUnsaved = usePrevious(!formData.itemSaved.saved)
  const isSaved = useMemo(() => isSavedObject(formData), [formData])

  const debouncedOnSave = debounce(onSave, 100)

  useEffect(() => {
    if (wasUnsaved && isSaved) {
      debouncedOnSave()
    }
  }, [wasUnsaved, isSaved, debouncedOnSave])

  const newSchema = useMemo(
    () => ({
      ...schema,
      ...(isSaved && {
        title: `${schema.title} : ${get(formData, [
          'itemSaved',
          'savedDetails',
        ])}`,
      }),
    }),
    [schema, formData]
  )

  const newUiSchemaOptions = useMemo(
    () => ({
      ...props.uiSchema['ui:options'],
      ...(isSaved && { defaultCollapsed: true }),
    }),
    [uiSchema, formData]
  )

  // `saved` is a required property to block completion of a form if a
  // saveableObject has not been saved, so that error must be filtered out to
  // determine if the remainder of the subsection is valid
  const isValid = useMemo(() => {
    const { errors } = validateFormData(formData, schema)
    return isEmpty(errors.filter(e => e.property != '.itemSaved.saved'))
  }, [formData, schema])

  return isSaved ? (
    <registry.fields.ObjectField
      {...props}
      schema={newSchema}
      uiSchema={{ ...uiSchema, 'ui:options': newUiSchemaOptions }}
      disabled={true}
    />
  ) : (
    <React.Fragment>
      <registry.fields.ObjectField {...props} />
      <span>
        <Button
          disabled={!isValid || isPending}
          classes={{ root: classes.margins }}
          size="small"
          variant="contained"
          onClick={() =>
            onClick ? onClick() : change(onChange, formData, user)
          }
        >
          {get(uiSchema, 'ui:options.buttonText') || 'Save'}
        </Button>
        <FormHelperText error classes={{ root: classes.inlineBlock }}>
          this item must be completed or deleted
        </FormHelperText>
      </span>
    </React.Fragment>
  )
}

SaveableObjectField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  uiSchema: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  formData: PropTypes.object,
  registry: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  isPending: PropTypes.bool,
}

SaveableObjectField.defaultProps = {
  formData: {},
  isPending: false,
  onSave: () => {},
}

const MemoizedSaveableObjectField = memoizedWrapper(SaveableObjectField)
export default MemoizedSaveableObjectField

// Create read only copy of the action$ to be streamed into the component
// Keeping this in this file for the time being to not expose this to the
// rest of the application at this time, is this too much power?
const readOnlyAction$ = new Subject()

// Epic that pushes all actions into the readOnlyAction$
export const epic = action$ =>
  action$.pipe(
    tap(v => readOnlyAction$.next(v)),
    ignoreElements()
  )

// When a request object has been passed in the component will call the requested
// action onClick and subscribe to the readOnlyAction$ to call the `change`
// function if the request.SUCCEEDED action is dispatched
export const saveableObjectFieldWithRequest = (request, onSave) => {
  const SaveableObjectFieldWithRequest = props => {
    const { onChange, formData, formContext } = props

    const user = useSelector(getUser)
    const isPending = useSelector(state => isRequestPending(state, request))

    useEffect(() => {
      if (isSavedObject(formData)) return undefined

      const subscription = readOnlyAction$
        .pipe(
          ofType(request.REQUESTED),
          switchMap(() =>
            readOnlyAction$.pipe(
              requestSucceeded(request),
              map(() => change(onChange, formData, user))
            )
          )
        )
        .subscribe()

      return () => subscription.unsubscribe()
    }, [request, onChange, formData, user])

    const onClick = useAction(() =>
      request.requested(formData, formContext.context)
    )

    return (
      <MemoizedSaveableObjectField
        {...props}
        onClick={onClick}
        onSave={onSave}
        isPending={isPending}
      />
    )
  }

  SaveableObjectFieldWithRequest.propTypes = {
    request: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
  }

  return memoizedWrapper(SaveableObjectFieldWithRequest)
}
