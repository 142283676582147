import React from 'react'
import { connect } from 'react-redux'
import Count from '~/components/Count'
import DevXGrid from '~/components/DevXGrid'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { getNPIPhysicians } from '../../data/npiPhysicians'

const styles = ({ spacing }) => ({
  count: {
    marginBottom: spacing(1),
  },
})

const columns = [
  { name: 'number', title: 'NPI Number' },
  { name: 'firstName', title: 'First Name' },
  { name: 'lastName', title: 'Last Name' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'postalCode', title: 'Postal Code' },
  { name: 'specialty', title: 'Specialty' },
]
const defaultSorting = [{ columnName: 'number', direction: 'asc' }]

const NPILookupTable = ({ classes, rows, onSelect }) => {
  return (
    <React.Fragment>
      <div className={classes.count}>
        <Count className={classes.count} value={rows.length} />
      </div>

      <DevXGrid
        rows={rows}
        columns={columns}
        selectable
        selectSingleRow
        onSelect={onSelect}
        sortable
        pageable
        defaultSorting={defaultSorting}
      />
    </React.Fragment>
  )
}

NPILookupTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default compose(
  connect(state => ({
    rows: getNPIPhysicians(state),
  })),
  withStyles(styles)
)(NPILookupTable)
