import { combineEpics } from 'redux-observable'
import encounterCheckInOutRequested from './epics/encounterCheckInOutRequested'
import logPrepWork from './epics/logPrepWork'
import persistEncounterCheckInOut from './epics/persistEncounterCheckInOut'
import removeLocalEncounterCheckInOut from './epics/removeLocalEncounterCheckInOut'

export default combineEpics(
  encounterCheckInOutRequested,
  logPrepWork,
  persistEncounterCheckInOut,
  removeLocalEncounterCheckInOut
)
