import moment from 'moment'
import { combineEpics, ofType } from 'redux-observable'
import { ignoreElements, map, mergeMapTo, pluck, tap } from 'rxjs/operators'
import { appInitialized } from '~/data/root'
import { USERS_LIST, getItemWithMetadata, setItem } from '~/utils/storage'
import { fetchUsers, localUsersLoaded, usersRequested } from '../data/users'

const initializeUsers = action$ =>
  action$.pipe(
    ofType(appInitialized),
    mergeMapTo(getItemWithMetadata(USERS_LIST)),
    map(users => {
      if (
        users &&
        users.metadata.timestamp &&
        moment(users.metadata.timestamp).isValid &&
        moment(users.metadata.timestamp) >= moment().subtract(10, 'hour')
      ) {
        return localUsersLoaded(users.data)
      } else {
        return fetchUsers.requested()
      }
    })
  )

const storeLocally = payload => {
  setItem(USERS_LIST, payload.toJS())
}

const refreshLocalStorage = action$ =>
  action$.pipe(
    ofType(fetchUsers.SUCCEEDED),
    pluck('payload'),
    tap(storeLocally),
    ignoreElements()
  )

const refreshUsers = action$ =>
  action$.pipe(
    ofType(usersRequested),
    pluck('payload', 'includeInactive'),
    map(fetchUsers.requested)
  )

export default combineEpics(initializeUsers, refreshLocalStorage, refreshUsers)
