import React from 'react'
import { useSelector } from 'react-redux'
import { getUserById } from '~/data/users'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Icon, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getDate, getTimeZone } from '../../../data/calendar'
import { newEventCreated } from '../../../data/events'

const useStyles = makeStyles(({ spacing }) => ({
  icon: ({ eventType }) => ({
    color: eventType.colors.text,
    backgroundColor: eventType.colors.background,
    borderRadius: 4,
    width: 32,
    height: 32,
    padding: spacing(0.5),
  }),
}))

const EventType = props => {
  const { eventType, userId } = props

  const classes = useStyles(props)

  const timeZone = useSelector(getTimeZone)
  const date = useSelector(getDate)
  const user = useSelector(state => getUserById(state, userId))
  const createNewEvent = useAction(newEventCreated)

  const onClick = () => {
    const defaultEvent = eventType.defaults
    const ownerId = user.id
    const ownerName = user.name

    createNewEvent({ ...defaultEvent, ownerId, ownerName }, date, timeZone)
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Icon className={classes.icon}>add_circle</Icon>
      </ListItemIcon>
      <ListItemText>{eventType.label}</ListItemText>
    </MenuItem>
  )
}

EventType.propTypes = {
  eventType: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
}

export default EventType
