import React, { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import renderFieldValues from '~/components/fields/renderFieldValues'
import { Section } from '~/components/text'
import { VirtualCarePlanning, savePatientInfo } from '~/features/patientInfo'
import { useAction, useFieldValuesOld, useToggle } from '~/hooks'
import { Button, FormHelperText, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getPatientInfo } from '../../../data/patientInfo'
import Reminder from './Reminder'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  grid: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: spacing(2),
    rowGap: `${spacing(1)}px`,

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  nonEditableFields: {
    paddingTop: `${spacing(1)}px`,
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  submitButton: {
    display: 'none',
  },
  fieldName: {
    paddingRight: `${spacing(1)}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '20%',
  },
}))

const getValues = patient => ({
  textingApproved: patient.textingApproved || '',
  videoAptInterest: patient.videoAptInterest || '',
  virtualCapable: patient.virtualCapable || 'not_yet_asked',
})

const Scheduling = () => {
  const classes = useStyles()

  const [edit, toggleEdit, toggleEditOff] = useToggle()
  const patientInfo = useSelector(getPatientInfo)
  const savePatientInfoRequested = useAction(savePatientInfo.requested)
  const virtualCapableOptions = useFieldValuesOld('virtual_capable')
  const textingApprovedOptions = useFieldValuesOld('texting_approved')
  const videoAptInterestOptions = useFieldValuesOld('video_apt_interest')
  const buttonRef = useRef()

  const { control, handleSubmit, reset, errors } = useForm({
    defaultValues: getValues(patientInfo),
  })

  useEffect(() => {
    if (patientInfo) {
      reset(getValues(patientInfo))
    }
  }, [patientInfo])

  const onSave = formData => {
    toggleEditOff()
    savePatientInfoRequested(patientInfo.id, {
      virtualCarePlanning: VirtualCarePlanning({
        ...formData,
        patientId: patientInfo.id,
      }),
    })
  }

  return (
    <React.Fragment>
      <Section title="Visit Instructions">
        {patientInfo.visitInstructions ? (
          <Typography>{patientInfo.visitInstructions}</Typography>
        ) : (
          <Typography variant="caption">No Visit Instructions</Typography>
        )}
      </Section>
      <Section title="Virtual Care Planning">
        <form onSubmit={handleSubmit(onSave)}>
          <div className={classes.grid}>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.fieldName}>
                Virtual Capable:
              </Typography>
              <Controller
                control={control}
                label="Virtual Capable"
                name="virtualCapable"
                render={({ value, onChange }) => (
                  <Select
                    value={value}
                    disabled={!edit}
                    onChange={e => onChange(e.target.value)}
                  >
                    {renderFieldValues(virtualCapableOptions)}
                  </Select>
                )}
              />
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.fieldName}>
                Texting Approved:
              </Typography>
              <Controller
                control={control}
                label="Texting Approved"
                name="textingApproved"
                render={({ value, onChange }) => (
                  <>
                    <Select
                      value={value}
                      disabled={!edit || !patientInfo.phone}
                      onChange={e => onChange(e.target.value)}
                      error={Boolean(errors.textingApproved)}
                    >
                      {renderFieldValues(textingApprovedOptions)}
                    </Select>
                    {(!patientInfo.phone || errors.textingApproved) && (
                      <FormHelperText error>
                        {!patientInfo.phone
                          ? 'Please ensure there is a preferred contact with a preferred phone number'
                          : errors.textingApproved?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
                rules={{
                  validate: value =>
                    value === 'yes' &&
                    !patientInfo.hasPreferredPhoneTextingApproved
                      ? 'Please mark the phone number as texting approved in order to receive updates and appointment information.'
                      : undefined,
                }}
              />
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.fieldName}>
                Video Appointment Interest:
              </Typography>
              <Controller
                control={control}
                label="Video Appointment Interest"
                name="videoAptInterest"
                render={({ value, onChange }) => (
                  <Select
                    value={value}
                    disabled={!edit}
                    onChange={e => onChange(e.target.value)}
                  >
                    {renderFieldValues(videoAptInterestOptions)}
                  </Select>
                )}
              />
            </div>
            <br />
            <div className={classes.buttons}>
              <Button
                color="primary"
                variant="contained"
                onClick={toggleEdit}
                disabled={edit}
              >
                Edit
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!edit}
                onClick={() => buttonRef.current.click()}
              >
                Save
              </Button>
            </div>
            <button
              className={classes.submitButton}
              type="button"
              ref={e => {
                buttonRef.current = e
              }}
            />
          </div>
        </form>
      </Section>
      <Section title="Reminder">
        <Reminder patientId={patientInfo.id} />
      </Section>
    </React.Fragment>
  )
}

export default Scheduling
