import { get } from 'lodash'
import React from 'react'
import ArrayField from '~/components/JsonForm/fields/ArrayField'
import PropTypes from '~/utils/propTypes'
import ArrayFieldTemplate from '../ArrayFieldTemplate'

const getIsSaved = data => get(data, ['itemSaved', 'saved'], false)

// Custom ArrayFieldTemplate to add the neccessary functionality
// - disable all items in the array that have been saved
// - remove the `Add Item` button if not all items have been saved
const SaveableArrayFieldTemplate = ({ items: originalItems, ...props }) => {
  const { formData } = props

  const items = originalItems.map((item, index) => ({
    ...item,
    disabled: getIsSaved(props.formData[index]),
  }))

  const canAdd = formData.every(getIsSaved)

  return <ArrayFieldTemplate {...props} canAdd={canAdd} items={items} />
}

SaveableArrayFieldTemplate.propTypes = {
  uiSchema: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  canAdd: PropTypes.bool.isRequired,
  formData: PropTypes.array.isRequired,
}

// Custom field needed to apply the custom ArrayFieldTemplate - cannot include
// this in the ui:schema as expected because the value needs to be the
// component
const SaveableArrayField = ({ uiSchema, ...props }) => (
  <ArrayField
    {...props}
    uiSchema={{
      ...uiSchema,
      'ui:ArrayFieldTemplate': SaveableArrayFieldTemplate,
    }}
  />
)

SaveableArrayField.propTypes = {
  uiSchema: PropTypes.object.isRequired,
}

export default SaveableArrayField
