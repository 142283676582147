import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import {
  Hospitalization,
  deleteHospitalization,
} from '../data/hospitalizations'

const DeleteHospitalization = ({ row, onClose, deleteHospitalization }) => {
  const [open, setOpen] = useState(true)
  const onDelete = useCallback(() => {
    deleteHospitalization(row.id)
    setOpen(false)
  }, [row, deleteHospitalization, setOpen])

  const hospitalName = row.hospitalName ? row.hospitalName : 'this'

  return (
    <ActionDialog
      open={open}
      title="Delete Hospitalization"
      mode="delete"
      onDelete={onDelete}
      onClose={onClose}
    >
      <Typography>
        {`Are you sure you want to delete ${hospitalName} as a hospitalization?`}
      </Typography>
    </ActionDialog>
  )
}

DeleteHospitalization.propTypes = {
  row: PropTypes.record,
  deleteHospitalization: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DeleteHospitalization.defaultProps = {
  row: Hospitalization(),
}

export default connect(null, {
  deleteHospitalization: deleteHospitalization.requested,
})(DeleteHospitalization)
