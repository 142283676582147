import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
import { Record } from 'immutable'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, getIn, scopedCreator } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016)
import key from '../key'

const HC_GOAL_INTERVENTIONS = 'hc_goal_intervention'

const creator = scopedCreator(key)
export const selectedRecord = creator('SELECTED_HC_GOAL')

export const addGoalIntervention = Request({
  typePrefix: key,
  typeBase: 'ADD_GOAL_INTERVENTION',
  requestParams: ['hcGoalId', 'goalInterventionParams'],
  operation: (hcGoalId: any, goalInterventionParams: any) =>
    AspireAPI.post(`care_plan/hc_goal/${hcGoalId}/intervention`, {
      ...goalInterventionParams,
    }),
  messages: {
    succeeded: 'Patient goal intervention saved successfully',
    failed: 'Could not save patient goal intervention.',
  },
})

export const addGoalInterventionNote = Request({
  typePrefix: key,
  typeBase: 'ADD_GOAL_INTERVENTION_NOTE',
  requestParams: ['interventionId', 'interventionNote'],
  operation: (interventionId: any, interventionNote: any) =>
    AspireAPI.post(`care_plan/hc_goal/${interventionId}/intervention/note`, {
      ...interventionNote,
    }),
  messages: {
    succeeded: 'Intervention Note saved successfully',
    failed: 'Could not save Intervention Note.',
  },
})

export const HcGoalIntervention = Record({
  carePlanGoalId: null,
  id: null,
  interventionId: null,
  targetDate: null,
  status: null,
  source: null,
  modifiedBy: null,
  modified: null,
  statement: null,
  created: null,
  createdBy: null,
  completedDate: null,
  closureReason: null,
  closedBy: null,
  assignee: null,
  assigneeId: null,
  assigneeRole: null,
  closedByRole: null,
  createdByRole: null,
  modifiedByRole: null,
  priority: null,
  deferralReason: null,
  assigneeWithRole: null,
  intervention: null,
  notes: null,
})

const transformHcGoalInterventions = (hcGoalInterventions: any) => {
  return hcGoalInterventions.map((hcGoalIntervention: any) => {
    return HcGoalIntervention({
      carePlanGoalId: hcGoalIntervention?.carePlanGoalId,
      id: hcGoalIntervention?.id,
      interventionId: hcGoalIntervention?.interventionId,
      targetDate: hcGoalIntervention?.targetDate,
      status: hcGoalIntervention?.status,
      source: hcGoalIntervention?.source,
      notes: hcGoalIntervention?.notes,
      modifiedBy: `${hcGoalIntervention?.modifiedBy} - ${
        hcGoalIntervention?.modifiedByRole || ''
      }`,
      modified: formatDate(hcGoalIntervention?.modifiedAt),
      statement: hcGoalIntervention?.statement,
      createdBy: `${hcGoalIntervention?.createdBy} - ${
        hcGoalIntervention?.createdByRole || ''
      }`,
      created: formatDate(hcGoalIntervention?.createdAt),
      completedDate: formatDate(hcGoalIntervention?.completedDate),
      closureReason: hcGoalIntervention?.closureReason,
      closedBy: hcGoalIntervention?.closedBy
        ? `${hcGoalIntervention?.closedBy?.name} - ${
            hcGoalIntervention?.closedByRole || ''
          }`
        : '',
      assignee: hcGoalIntervention?.assignee,
      assigneeId: hcGoalIntervention?.assigneeId,
      assigneeRole: hcGoalIntervention?.assigneeRole,
      assigneeWithRole: `${hcGoalIntervention?.assignee.name} - ${
        hcGoalIntervention?.assigneeRole
          ? hcGoalIntervention?.assigneeRole[1]
          : ''
      }`,
      priority: hcGoalIntervention?.priority,
      deferralReason: hcGoalIntervention?.deferralReason,
      intervention: hcGoalIntervention?.intervention,
    })
  })
}

export const fetchHcGoalInterventions = Request({
  typePrefix: key,
  typeBase: 'FETCH_HC_GOAL_INTERVENTION',
  requestParams: ['care_plan_goal_id'],
  operation: (care_plan_goal_id: number) =>
    AspireAPI.get(`care_plan/hc_goal/${care_plan_goal_id}/interventions`),
  transform: transformHcGoalInterventions,
  messages: { failed: 'There was an issue fetching hc goal interventions' },
})

export const hcGoalInterventions = createReducer(HC_GOAL_INTERVENTIONS, [], {
  [fetchHcGoalInterventions.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

// REDUCER
export const currentHcGoal = createReducer('currentHcGoal', 0, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [selectedRecord]: (_state: any, { payload }: any) => payload,
})

export const getHcGoalInterventions = pipe(get(key), get(HC_GOAL_INTERVENTIONS))
export const getCurrentHcGoal = getIn([key, 'currentHcGoal'])
