import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import { getVitals } from './root'

// Key
export const key = 'chart'

// Actions
const creator = scopedCreator(key)

export const chartSet = creator('CHART_SET')
export const chartDisplayToggled = creator('CHART_DISPLAY_TOGGLED')
export const chartPageChanged = creator('CHART_PAGE_CHANGED')

// Reducer
export const ChartState = Record({
  displayed: true,
  page: 0,
})

// Selector
export const getChart = pipe(getVitals, get(key))

const toggle = key => state => state.update(key, value => !value)
const setFromPayload = key => (state, action) => state.set(key, action.payload)

export default createReducer(key, ChartState(), {
  [chartDisplayToggled]: toggle('displayed'),
  [chartPageChanged]: setFromPayload('page'),
  [chartSet]: compose(ChartState, payload, action),
})
