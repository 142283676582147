import { List, Record } from 'immutable'
import { combineEpics, ofType } from 'redux-observable'
import { createSelector } from 'reselect'
import { of } from 'rxjs'
import {
  debounceTime,
  filter,
  map,
  mapTo,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { switchTo } from '~/utils/operators'
import { getPatientRecord } from './common/shared'

const key = 'patientRecordSearch'
const returnJS = value => value.toJS()
const getSearchFields = pipe(getPatientRecord, get(key))

const SearchFields = Record({
  assignableApps: List(),
  caseManagers: List(),
  markets: List(),
  referringPhysicians: List(),
  referringEntities: List(),
})

const fetchMarketsApi = () => AspireAPI.get('patient_record/markets')

export const fetchMarkets = Request({
  typePrefix: key,
  typeBase: 'FETCH_MARKETS',
  requestParams: ['markets'],
  operation: fetchMarketsApi,
  transform: markets => ({ markets }),
  messages: { failed: 'There was a problem fetching Aspire Markets' },
})

export const getMarkets = pipe(getSearchFields, get('markets'))
export const getMarketsJS = createSelector([getMarkets], returnJS)

const fetchReferringPhysicianApi = referring_physician_name =>
  AspireAPI.get('patient_record/referring_physicians', {
    params: { referring_physician_name },
  })

export const fetchReferringPhysician = Request({
  typePrefix: key,
  typeBase: 'FETCH_PHYSICIAN',
  requestParams: ['referringPhysician'],
  operation: fetchReferringPhysicianApi,
  transform: referringPhysicians => ({ referringPhysicians }),
  messages: { failed: 'There was a problem fetching referring physicians' },
})

export const getReferringPhysicians = pipe(
  getSearchFields,
  get('referringPhysicians')
)
export const getReferringPhysiciansJS = createSelector(
  [getReferringPhysicians],
  returnJS
)

const fetchReferringEntityApi = referring_entity_name =>
  AspireAPI.get('patient_record/referring_entities', {
    params: { referring_entity_name },
  })

export const fetchReferringEntity = Request({
  typePrefix: key,
  typeBase: 'FETCH_ENTITIES',
  requestParams: ['referringEntities'],
  operation: fetchReferringEntityApi,
  transform: referringEntities => ({ referringEntities }),
  messages: { failed: 'There was a problem fetching referring entities' },
})

export const getReferringEntities = pipe(
  getSearchFields,
  get('referringEntities')
)
export const getReferringEntitiesJS = createSelector(
  [getReferringEntities],
  returnJS
)

const fetchCaseManagersApi = searchWord =>
  AspireAPI.get('contacts/case_managers', {
    params: { searchWord },
  })

export const fetchCaseManagers = Request({
  typePrefix: key,
  typeBase: 'FETCH_CASE_MANAGERS',
  requestParams: ['searchWord'],
  operation: fetchCaseManagersApi,
  transform: caseManagers => ({ caseManagers }),
  messages: { failed: 'There was a problem fetching case_managers' },
})

export const getCaseManagers = pipe(getSearchFields, get('caseManagers'))

export const getCaseManagersJS = createSelector([getCaseManagers], returnJS)

const MARKET_SEARCH_REQUESTED = type(key, 'MARKET_SEARCH_REQUESTED')
export const marketSearchRequested = creator(MARKET_SEARCH_REQUESTED)

const SEARCH_TEXT_UPDATED = type(key, 'SEARCH_TEXT_UPDATED')
export const searchTextUpdated = creator(SEARCH_TEXT_UPDATED, 'query', 'action')

const marketSearchRequestedEpic = (action$, state$) =>
  action$.pipe(
    ofType(MARKET_SEARCH_REQUESTED),
    switchTo(state$),
    map(getMarkets),
    filter(marketList => marketList.isEmpty()),
    mapTo(fetchMarkets.requested()),
    takeUntil(action$.pipe(ofType(fetchMarkets.SUCCEEDED)))
  )

const searchTextUpdateEpic = action$ =>
  action$.pipe(
    ofType(SEARCH_TEXT_UPDATED),
    pluck('payload'),
    debounceTime(500),
    switchMap(({ query, action }) => of(action(query)))
  )

export const epic = combineEpics(
  marketSearchRequestedEpic,
  searchTextUpdateEpic
)

export default createReducer(key, SearchFields(), {
  [fetchCaseManagers.SUCCEEDED]: (state, { payload }) => state.merge(payload),
  [fetchMarkets.SUCCEEDED]: (state, { payload }) => state.merge(payload),
  [fetchReferringPhysician.SUCCEEDED]: (state, { payload }) =>
    state.merge(payload),
  [fetchReferringEntity.SUCCEEDED]: (state, { payload }) =>
    state.merge(payload),
})
