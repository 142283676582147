import classNames from 'classnames'
import React, { useMemo } from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Icon } from '@material-ui/core'
import { makeStyles, withTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(({ colors, spacing }) => ({
  selected: {
    backgroundColor: colors.grey['300'],
  },
  marker: {
    marginRight: spacing(0.5),
  },
  flex: {
    flex: '1',
  },
}))

const PatientMarker = ({
  theme,
  context,
  patient,
  open,
  current,
  getMarker,
  getColor,
  onPatientClick,
  onMenuClick,
}) => {
  const classes = useStyles()

  const MarkerComponent = getMarker(patient)

  const color = useMemo(() => getColor(patient, context).color, [
    patient,
    context,
  ])

  return (
    <div
      className={classNames({ [classes.selected]: current })}
      onClick={() => {
        onPatientClick(patient.id)
      }}
    >
      <img
        className={classes.marker}
        src={MarkerComponent.renderIcon(theme, color, 1).url}
      />

      {patient.name}

      <span className={classes.flex} />

      {open && <Icon onClick={onMenuClick}>more_vert</Icon>}
    </div>
  )
}

PatientMarker.propTypes = {
  theme: PropTypes.object.isRequired,
  patient: PropTypes.record.isRequired,
  context: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  current: PropTypes.bool.isRequired,
  getMarker: PropTypes.func.isRequired,
  getColor: PropTypes.func.isRequired,
  onPatientClick: PropTypes.func.isRequired,
  onMenuClick: PropTypes.func.isRequired,
}

export default compose(React.memo, withTheme)(PatientMarker)
