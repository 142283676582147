import { ROUTE_CHANGED, paramsReducer } from 'redux-routable'
import { calcFormData, changeFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS } from '../router'
import {
  EncounterReview,
  getRoot,
  transformEncounterReview,
} from './common/shared'

const CURRENT_ENCOUNTER_REVIEW = 'currentEncounterReview'

const creator = scopedCreator(rootKey)

export const encounterReviewFormDataChanged = creator(
  'ENCOUNTER_REVIEW_FORM_DATA_CHANGED',
  ['formData']
)
export const encounterReviewFormDataChangedByTag = creator(
  'ENCOUNTER_REVIEW_FORM_DATA_CHANGED_BY_TAG',
  ['tag', 'value']
)

const initializeEncounterReview = (encounterReview: any) =>
  encounterReview.set(
    'formData',
    calcFormData({
      formData: encounterReview.formData,
      schema: encounterReview.schema,
      context: encounterReview.context,
      tags: encounterReview.tags,
    })
  )

export const fetchEncounterReview = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ENCOUNTER_REVIEW',
  requestParams: ['id'],
  operation: (id: any) => AspireAPI.get(`encounters/encounter_reviews/${id}`),
  transform: pipe(transformEncounterReview, initializeEncounterReview),
  messages: {
    failed: 'There was an issue getting the encounter review',
  },
})

export const saveEncounterReview = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_ENCOUNTER_REVIEW',
  requestParams: ['encounterReview'],
  operation: ({ id, formData }: any) =>
    AspireAPI.put(`encounters/encounter_reviews/${id}`, {
      data: formData,
    }),
  messages: {
    failed: 'There was an issue saving the encounter review',
  },
})

export const signEncounterReview = Request({
  typePrefix: rootKey,
  typeBase: 'SIGN_ENCOUNTER_REVIEW',
  requestParams: ['id', 'defer', 'notes'],
  operation: (id: any, defer: any, notes: any) =>
    AspireAPI.put(`encounters/encounter_reviews/${id}/sign`, {
      defer,
      notes,
    }),
  messages: {
    succeeded: 'Successfully signed encounter review',
    failed: 'There was an issue signing the encounter review',
  },
})

export const generateEncounterReviewPdf = Request({
  typePrefix: rootKey,
  typeBase: 'GENERATE_ENCOUNTER_REVIEW_PDF',
  requestParams: ['id'],
  operation: (id: any) =>
    AspireAPI.get(`encounters/encounter_reviews/${id}/pdf`, {
      responseType: 'blob',
    }),
  messages: {
    failed: 'There was an issue generating the PDF',
  },
})

const initState = EncounterReview()

export default createReducer(CURRENT_ENCOUNTER_REVIEW, initState, {
  [ROUTE_CHANGED]: paramsReducer(
    PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS,
    initState,
    ({ encounterReviewId }: any) =>
      encounterReviewId
        ? EncounterReview({ id: Number(encounterReviewId) })
        : initState
  ),
  [fetchEncounterReview.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [encounterReviewFormDataChanged]: (
    state: any,
    { payload: { formData } }: any
  ) => changeFormData(state, null, formData),
  // @ts-expect-error can we deal with the toString being automatically called?
  [encounterReviewFormDataChangedByTag]: (
    state: any,
    { payload: { tag, value } }: any
  ) => changeFormData(state, state.tags[tag].data, value),
  [signEncounterReview.SUCCEEDED]: () => initState,
})

export const getCurrentEncounterReview = pipe(
  getRoot,
  get(CURRENT_ENCOUNTER_REVIEW)
)

export const getCurrentEncounterReviewId = (state: any) =>
  getCurrentEncounterReview(state).id
