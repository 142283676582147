import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { downloadAttachment } from '../data/currentAttachments'

export default action$ =>
  action$.pipe(
    ofType(downloadAttachment.SUCCEEDED),
    tap(({ payload }) => {
      const url = window.URL.createObjectURL(payload)
      window.open(url)

      setTimeout(() => window.URL.revokeObjectURL(url), 1000 * 60)
    }),
    ignoreElements()
  )
