import { AsOpaque, AType, EType, summon } from '~/utils/type'
import { User } from './userT'

const Demographics_ = summon(F =>
  F.interface(
    {
      id: F.string(),
      name: F.string(),
    },
    'Demographics'
  )
)
export interface Demographics extends AType<typeof Demographics_> {}
export interface DemographicsRaw extends EType<typeof Demographics_> {}
export const Demographics = AsOpaque<DemographicsRaw, Demographics>()(
  Demographics_
)

const Healthplan_ = summon(F =>
  F.interface(
    {
      label: F.string(),
    },
    'Healthplan'
  )
)
export interface Healthplan extends AType<typeof Healthplan_> {}
export interface HealthplanRaw extends EType<typeof Healthplan_> {}
export const Healthplan = AsOpaque<HealthplanRaw, Healthplan>()(Healthplan_)

const Contract_ = summon(F =>
  F.interface(
    {
      healthPlan: F.nullable(Healthplan(F)),
    },
    'Contract'
  )
)
export interface Contract extends AType<typeof Contract_> {}
export interface ContractRaw extends EType<typeof Contract_> {}
export const Contract = AsOpaque<ContractRaw, Contract>()(Contract_)

const Coverage_ = summon(F =>
  F.interface(
    {
      contract: F.nullable(Contract(F)),
    },
    'Coverage'
  )
)
export interface Coverage extends AType<typeof Coverage_> {}
export interface CoverageRaw extends EType<typeof Coverage_> {}
export const Coverage = AsOpaque<CoverageRaw, Coverage>()(Coverage_)

const Patient_ = summon(F =>
  F.interface(
    {
      id: F.string(),
      demographics: F.nullable(Demographics(F)),
      app: F.nullable(User(F)),
      coverages: F.nullable(F.array(Coverage(F))),
    },
    'Patient'
  )
)
export interface Patient extends AType<typeof Patient_> {}
export interface PatientRaw extends EType<typeof Patient_> {}
export const Patient = AsOpaque<PatientRaw, Patient>()(Patient_)
