import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import lastEncounterEpic from '~/apps/patientRecord/epics/lastEncounter'
import { PATIENT_RECORD_CALL_DETAIL } from '~/apps/patientRecord/router'
import { switchTo } from '~/utils/operators'
import { fetchCall } from '../data/calls'
import { getCurrentCallId } from '../data/currentCallId'

const callSelected = (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_CALL_DETAIL)),
    switchTo(state$),
    map(getCurrentCallId),
    map(fetchCall.requested)
  )

export default combineEpics(
  lastEncounterEpic(PATIENT_RECORD_CALL_DETAIL),
  callSelected
)
