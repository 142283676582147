import { Record } from 'immutable'

const PharmacySearchOptionsDefaults = Record({
  activeOnly: true,
  includeRetail: true,
  includeMailOrder: false,
  includeFax: false,
  includeSpecialty: false,
  includeLongTerm: false,
  includeTwentyFourHour: false,
})

export default PharmacySearchOptionsDefaults
