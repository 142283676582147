import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { mapTo } from 'rxjs/operators'
import { closePendingStakeholderEncounterReview } from '../data/common/shared'
import { IOC_DASHBOARD_ENCOUNTER_REVIEWS } from '../router'

export default (action$: any) =>
  action$.pipe(
    ofType(closePendingStakeholderEncounterReview.SUCCEEDED),
    mapTo(push(IOC_DASHBOARD_ENCOUNTER_REVIEWS))
  )
