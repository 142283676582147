import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Search from '~/components/Search'
import PropTypes from '~/utils/propTypes'
import { searchPatients } from '../data/patientSearch'

class PatientSearchBar extends PureComponent {
  state = { value: '' }

  onChangeSearch = value => {
    this.setState({ value })
    this.props.onChangeSearch(value)
  }

  search = text => {
    this.props.onSearch && this.props.onSearch(text)
    this.props.search(text)
  }

  render() {
    const { autoFocus, contrast, compact, searchRef } = this.props
    return (
      <Search
        placeholder="Patient Search"
        label=""
        value={this.state.value}
        onChangeSearch={this.onChangeSearch}
        onSubmit={this.search}
        autoFocus={autoFocus}
        contrast={contrast}
        compact={compact}
        searchRef={searchRef}
      />
    )
  }
}

PatientSearchBar.propTypes = {
  autoFocus: PropTypes.bool,
  onChangeSearch: PropTypes.func,
  onSearch: PropTypes.func,
  search: PropTypes.func.isRequired,
  contrast: PropTypes.bool,
  compact: PropTypes.bool,
  searchRef: PropTypes.func,
}

PatientSearchBar.defaultProps = {
  autoFocus: false,
  compact: false,
  contrast: false,
}

PatientSearchBar.defaultProps = {
  onChangeSearch: () => {},
}

export default connect(null, {
  search: searchPatients.requested,
})(PatientSearchBar)
