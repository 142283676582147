import { ofType } from 'redux-observable'
import { Observable, from, merge, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { appInitialized } from '~/data/root'
import { getUserId } from '~/data/session'
import { showError } from '~/utils/alertOnError'
import { joinOrGetChannel } from '~/utils/channels'
import { switchTo } from '~/utils/operators'
import { channelJoined } from '../data/common/shared'
import {
  connectionLost,
  connectionSuccessful,
} from '../data/connectionLostSnackbarOpened'
import getChannelName from '../utils/getChannelName'

export default (action$, state$) =>
  action$.pipe(
    ofType(appInitialized),
    switchTo(state$),
    map(getUserId),
    mergeMap(userId =>
      from(joinOrGetChannel(getChannelName(userId))).pipe(
        mergeMap(({ socket }) => {
          const socketStream = Observable.create(observer => {
            socket.onOpen(() => {
              observer.next(connectionSuccessful())
              observer.next(channelJoined())
            })
            socket.onError(() => observer.next(connectionLost()))
          })

          return merge(socketStream, of(channelJoined()))
        }),
        catchError(showError('Unable to join notification channel'))
      )
    )
  )
