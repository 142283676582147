import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
import { Record } from 'immutable'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016)
import key from '../key'

const HC_GOAL = 'hc_goal'

export const addHcGoal = Request({
  typePrefix: key,
  typeBase: 'ADD_HC_GOAL',
  requestParams: ['hcGoalParams'],
  operation: (hcGoalParams: any) =>
    AspireAPI.post('care_plan/hc_goal', {
      ...hcGoalParams,
    }),
  messages: {
    succeeded: 'Health Concern Goal added successfully',
    failed: 'Could not add patient health concern goal.',
  },
})

export const addHcGoalNote = Request({
  typePrefix: key,
  typeBase: 'ADD_HC_GOAL_NOTE',
  requestParams: ['hcGoalId', 'hcGoalNote'],
  operation: (hcGoalId: any, hcGoalNote: any) =>
    AspireAPI.post(`care_plan/hc_goal/${hcGoalId}/note`, {
      ...hcGoalNote,
    }),
  messages: {
    succeeded: 'Goal Note saved successfully',
    failed: 'Could not save Goal Note.',
  },
})

export const HcGoal = Record({
  id: null,
  healthConcern: null,
  goal: null,
  label: null,
  status: null,
  statement: null,
  priority: null,
  deferralReason: null,
  trigger: null,
  patientAcknowledgement: null,
  source: null,
  closureReason: null,
  notes: null,
  nextReviewDate: null,
  closedDate: null,
  barriers: null,
  barrierOther: null,
  owner: null,
  ownerRole: null,
  outcomes: null,
  closedBy: null,
  closedByRole: null,
  createdBy: null,
  createdByRole: null,
  evaluatedBy: null,
  modifiedByRole: null,
  created: null,
  modified: null,
})

const transformHcGoals = (hcGoals: any) => {
  return hcGoals.map((hcGoal: any) => {
    return HcGoal({
      id: hcGoal?.id,
      healthConcern: hcGoal?.carePlanHealthConcern,
      goal: hcGoal?.goal,
      label: hcGoal?.label,
      status: hcGoal?.status,
      statement: hcGoal?.statement,
      priority: hcGoal?.priority,
      deferralReason: hcGoal?.deferralReason,
      trigger: hcGoal?.trigger,
      patientAcknowledgement: hcGoal?.patientAcknowledgement,
      source: hcGoal?.source,
      closureReason: hcGoal?.closureReason,
      notes: hcGoal?.notes,
      nextReviewDate: hcGoal?.dueDate,
      closedDate: formatDate(hcGoal?.closedDate),
      barriers: hcGoal?.barriers,
      barrierOther: hcGoal?.barrierOther,
      outcomes: hcGoal?.outcomes,
      owner: hcGoal?.owner,
      ownerRole: hcGoal?.ownerRole,
      closedBy: hcGoal?.closedBy?.name,
      closedByRole: hcGoal?.closedByRole,
      createdBy: hcGoal?.createdBy,
      createdByRole: hcGoal?.createdByRole,
      evaluatedBy: hcGoal?.modifiedBy ? hcGoal?.modifiedBy : hcGoal?.createdBy,
      created: formatDate(hcGoal?.createdAt),
      modified: formatDate(
        hcGoal?.modifiedAt ? hcGoal?.modifiedAt : hcGoal?.createdAt
      ),
      modifiedByRole: hcGoal?.modifiedByRole,
    })
  })
}

export const fetchHcGoals = Request({
  typePrefix: key,
  typeBase: 'FETCH_HC_GOAL',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`care_plan/hc_goal/${patientId}`),
  transform: transformHcGoals,
  messages: { failed: 'There was an issue fetching hc goals' },
})

export default createReducer(HC_GOAL, [], {
  [fetchHcGoals.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getHcGoals = pipe(get(key), get(HC_GOAL))
