import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getAssessment } from './common/shared'

const key = 'legacyAssessments'

export const LegacyAssessment = Record({
  encounterId: null,

  dateOfService: null,
  providerName: null,
  recordtype: null,
  id: null,
  patientId: null,
  // HPI
  chiefComplaint: null,
  historyProvidedBy: null,
  historyProvidedByOther: null,
  historyOfPresentIllness: null,
  // PREVIOUS ASSESSMENT AND PLAN
  primaryDiagnosis: null,
  primaryDiagnosisOther: null,
  secondaryDiagnosis: null,
  secondaryDiagnosisOther: null,
  tertiaryDiagnosis: null,
  tertiaryDiagnosisOther: null,
  providerPrognosticEstimation: null,
  providerPrognosticEstimationOther: null,
  patientPrognosticAwareness: null,
  patientPrognosticAwarenessOther: null,
  surprisedDiedHospitalized90Days: null,
  isPatientHospiceEligible: null,
  whatIsPreventingHospiceTransition: null,
  isPatientHospiceEligibleOther: null,
  assessmentOfPatientCondition: null,
  patientManagementPlan: null,
  // VITALS
  bloodPressure: null,
  bmi: null,
  height: null,
  levelOfOxygen: null,
  pulse: null,
  pulseOximetry: null,
  respiratoryRate: null,
  temperature: null,
  weight: null,
  // ACTIVE CONDITIONS
  cardiac: null,
  pulmonary: null,
  endocrine: null,
  type: null,
  burningNumbnessTinglingAndOrOther: null,
  wideSwingInBpAndOrTemperature: null,
  esophegalDysmotilityEgDysphagia: null,
  intermittentClaudicationImpairedWalk: null,
  currentAndOrPastHistoryOfWounds: null,
  anyEyeDisease: null,
  historyOfRetinopathy: null,
  eyeSurgeries: null,
  eyeSurgeriesSpecify: null,
  renal: null,
  patientAdvisedToTakeMedicationOrModifyDiet: null,
  historyOfIncreasedProteinInUrine: null,
  anyHistoryOfCkdNephropathyAndOrEsrd: null,
  ckdStage: null,
  recentEgrfValueNumber: null,
  egrfDate: null,
  ifYesToEyeDiseaseHistoryOfRetinopathy: null,
  typeOfDialysis: null,
  dialysisSchedule: null,
  renalSpecialists: null,
  psychological: null,
  neurological: null,
  cancer: null,
  currentlyReceivingTreatmentForCancer: null,
  whereWasTheCancer: null,
  whereWasTheCancerSpecify: null,
  howWasTheCancerTreated: null,
  howWasTheCancerTreatedSpecify: null,
  whenDidTheLastTreatmentConclude: null,
  whereIsTheCancer: null,
  whereIsTheCancerSpecify: null,
  currentMetastaticCancer: null,
  currentMetastaticCancerSpecify: null,
  currentTreatmentPlan: null,
  currentTreatmentPlanSpecify: null,
  specialistsInvolvedInCare: null,
  additionalActiveConditionInformation: null,
  // SOCIAL HISTORY
  socialHistory: null,
  economicSituation: null,
  etoh: null,
  ifCurrentEtohDrinksPerDay: null,
  tobaccoUse: null,
  ifCurrentTobaccoUserPpd: null,
  ifPastTobaccoUserDateStopped: null,
  // SURGICAL HISTORY
  mastectomy: null,
  rightLeft: null,
  rightDate: null,
  leftDate: null,
  bilateralDate: null,
  transplant: null,
  renalDate: null,
  transplantOther: null,
  transplantOtherDate: null,
  vascularAccess: null,
  artificialOpenings: null,
  artificialOpeningsOther: null,
  amputations: null,
  amputationCause: null,
  amputationCauseOther: null,
  pacemaker: null,
  proceduresToAddressOcclusive: null,
  angioplastyLocation: null,
  angioplastyLocationOther: null,
  sentPlacementLocation: null,
  sentPlacementLocationOther: null,
  bypassLocation: null,
  bypassLocationOther: null,
  vascularDiseaseOtherLocation: null,
  additionalSurgicalInformation: null,
})

export const LegacySWAssessment = Record({
  encounterId: null,

  providerName: null,
  additionalNeedsAssessed: null,
  advanceCarePlanning: null,
  advanceCarePlanningOptions: null,
  assessmentSignedDate: null,
  assessmentSw: null,
  assessmentType: null,
  assessmentTypeC: null,
  careCoordination: null,
  caregiverSupport: null,
  communicationsInformationNeeds: null,
  crisisDeEscalation: null,
  crisisDeEscalationNotes: null,
  crisisDeEscalationOptions: null,
  dateOfService: null,
  easeOfCommunicationWithinFamily: null,
  economicSituation: null,
  emotionalSuppCounselingWCareOthers: null,
  emotionalSuppCounselingWCaregiver: null,
  emotionalSupportCounselingNotes: null,
  emotionalSupportCounselingOther: null,
  emotionalSupportCounselingWPatient: null,
  etoh: null,
  expectationSetting: null,
  expectationSettingOptions: null,
  experienceComfortWithHealthcareSys: null,
  faceToFaceTime: null,
  facilitationOfFamilyMeeting: null,
  fearsConcerns: null,
  financialInsuranceSupport: null,
  goalsHopesExpectations: null,
  gocHospiceDiscussion: null,
  gocHospiceNotes: null,
  griefSupportCounseling: null,
  id: null,
  identifiedSocialWorkNeed: null,
  ifCurrentEtohDrinksPerDay: null,
  ifCurrentTobaccoUsePpd: null,
  impactOfIllnessOnDailyLiving: null,
  knownOrAnticipatedBarriersIfOther: null,
  knownOrAnticipatedBarriersToCare: null,
  mileage: null,
  nameOfFacility: null,
  necessaryFollowUpNeeded: null,
  notesAdvanceCarePlanning: null,
  notesCareCooridnation: null,
  notesCaregiverSupport: null,
  notesCaregivingNeeds: null,
  notesEmotionalSupportWCaregiver: null,
  notesExpectationSetting: null,
  notesFacilitationOfFamilyMeeting: null,
  notesFinancialNeeds: null,
  notesGriefSupportCounseling: null,
  notesHousingLivingArrangementNeeds: null,
  notesIntervention: null,
  notesNoneAdditionalNeeds: null,
  notesPatientCaregiverEducationNotes: null,
  notesResourceReferral: null,
  notesTransportationNeeds: null,
  optionsCareCoordination: null,
  optionsGriefSupport: null,
  otherIntervention: null,
  parkingFees: null,
  patientCaregiverEducation: null,
  patientCaregiverEducationOptions: null,
  patientId: null,
  personSPresentForAssessment: null,
  personSPresentForAssessmentOther: null,
  placeOfService: null,
  preferredDayAndTimeForVisit: null,
  previousAnticipatedLosses: null,
  psychologicalIssuesSupportNeeds: null,
  recordtype: null,
  resourceReferral: null,
  resourceReferralOptions: null,
  selfCare: null,
  socialHistory: null,
  spiritualBeliefs: null,
  strengthsCopingStrategies: null,
  supportSystem: null,
  supportSystemOther: null,
  tobaccoUse: null,
  tollFees: null,
  totalTime: null,
  understandingOfDiagnosisPrognosis: null,
})

export const OtherEcounterType = Record({
  encounterId: null,

  cancellationNotes: null,
  cancelledBy: null,
  cancelledDate: null,
  cancelledReason: null,
  cancelledReasonOther: null,
  dateOfService: null,
  id: null,
  mileage: null,
  mitigationStrategy: null,
  parking: null,
  patientId: null,
  patientName: null,
  providerName: null,
  providerRole: null,
  recordtype: null,
  recordtypeName: null,
  tolls: null,
  type: null,
})

const PALLIATIVE_RECORD_TYPE = 'Encounter'
const SOCIAL_WORKER_RECORD_TYPE = 'Social_Worker_Visit'

export const fetchLegacyAssessment = Request({
  typePrefix: key,
  typeBase: 'FETCH_LEGACY_ASSESSMENT',
  requestParams: ['id', 'current'],
  operation: id => AspireAPI.get(`encounters/legacy_assessment/${id}`),
  transform: data =>
    getIn(
      {
        [PALLIATIVE_RECORD_TYPE]: LegacyAssessment,
        [SOCIAL_WORKER_RECORD_TYPE]: LegacySWAssessment,
      },
      data.recordtype,
      OtherEcounterType
    )(data),
  messages: { failed: 'There was a problem fetching the legacy assessment' },
})

export default createReducer(key, Map(), {
  [fetchLegacyAssessment.SUCCEEDED]: (state, { meta, payload: assessment }) => {
    const encounterId = meta.request.payload.id
    return state.set(encounterId, assessment.set('encounterId', encounterId))
  },
})

const getAllLegacyAssessments = pipe(getAssessment, get(key))

export const getLegacyAssessmentById = (state, id) =>
  pipe(getAllLegacyAssessments, legacyAssessments =>
    legacyAssessments.find(
      ({ id: caseId, encounterId }) => [caseId, encounterId].includes(id),
      null,
      LegacyAssessment()
    )
  )(state)

export const getLegacySWAssessmentById = (state, id) =>
  getAllLegacyAssessments(state).get(id, LegacySWAssessment())
