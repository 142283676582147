import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

// KEY
const TRIPSTATE = 'tripState'

// REQUEST
export const tripStateList = Request({
  typePrefix: rootKey,
  typeBase: 'TRIPSTATELIST',
  operation: () => AspireAPI.get('transportation/trip_form/states'),
  transform: (results: [string]) => List(results),
  messages: { failed: 'There was an issue fetching States' },
})

// REDUCER
export const tripState = createReducer(TRIPSTATE, List(), {
  [tripStateList.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getTripStates = pipe(getRoot, get(TRIPSTATE))
