const riskLevel = {
  1: {
    icon: 'lens',
    overviewIcon: 'arrow_upward',
    display: 'Red',
  },
  2: {
    icon: 'lens',
    overviewIcon: 'arrow_upward',
    display: 'Orange',
  },
  3: {
    icon: 'lens',
    overviewIcon: 'arrow_upward',
    display: 'Yellow',
  },
}
const aspireRiskLevel = {
  1: {
    icon: 'lens',
    overviewIcon: 'arrow_upward',
    display: 'Red',
  },
  2: {
    icon: 'lens',
    overviewIcon: 'arrow_upward',
    display: 'Yellow',
  },
  3: {
    icon: 'lens',
    overviewIcon: 'arrow_upward',
    display: 'Green',
  },
}

const unknownValues = {
  icon: 'lens',
  overviewIcon: 'help_outline',
  display: 'Unknown',
}

export default (isCaremoreBrand, score) =>
  isCaremoreBrand
    ? riskLevel[score] || unknownValues
    : aspireRiskLevel[score] || unknownValues
