import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  NEW_REFERRAL_ODS_LOOKUP_REQUESTED,
  newReferralOdsLookupRequested,
  odsLookup,
  odsLookupCompleted,
  odsLookupMatchesFound,
} from '../data/referrals'

const matchRanks = { Eligible: -1, 'Not Eligible': 1 }
const additionalIdsToCheck: any = { 57: 112, 112: 57 }
export default (action$: any) =>
  action$.pipe(
    ofType(NEW_REFERRAL_ODS_LOOKUP_REQUESTED),
    pluck('params'),
    mergeMap((params: any) =>
      from(odsLookup(params)).pipe(
        map(extractAndSortResults),
        map(matches => {
          if (matches.length >= 1) {
            return odsLookupMatchesFound(matches)
          }
          const alternateIdToLookup = additionalIdsToCheck[params.odsId]
          if (!alternateIdToLookup || params.secondTry) {
            return odsLookupCompleted()
          }
          params.odsId = alternateIdToLookup
          params.secondTry = true
          return newReferralOdsLookupRequested(params)
        }),
        catchError(showError('Problem loading matching ODS records'))
      )
    )
  )
const extractAndSortResults = (data: any) => {
  const matches = data.results || []
  return matches.sort(
    // Default sort Eligible to top of list
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (a: any, b: any) => matchRanks[a.ELIGIBILITY] - matchRanks[b.ELIGIBILITY]
  )
}
