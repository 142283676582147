import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ActionDialog } from '~/components/dialogs'
import { pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  createTimelineEntryDialogClosed,
  getDialogOpenValue,
  saveManualTimelineEntry,
} from './data/timelineEntryDialog'

const styles = ({ spacing }) => ({
  textField: {
    paddingTop: 2,
    '& div': {
      lineHeight: '1.4em',
      padding: [12, spacing(2)],
    },
    '& p': {
      lineHeight: '1.4em',
    },
  },
})

const CreateTimelineEntryDialog = ({
  classes,
  onClose,
  open,
  patientId,
  saveManualTimelineEntry,
}) => {
  const [timelineEntry, setTimelineEntry] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const createNote = event => setTimelineEntry(event.target.value)

  const addEntry = () => {
    saveManualTimelineEntry(patientId, timelineEntry)
    setIsDisabled(true)
  }

  return (
    <ActionDialog
      mode="add"
      open={open}
      title="Add Timeline Note"
      onClose={pipe(onClose, () => setIsDisabled(false))}
      onAdd={!isDisabled && addEntry}
      maxWidth="sm"
      fullWidth
    >
      <TextField
        className={classes.textField}
        helperText="Once submitted, your note will appear as an 'Other' event in the Timeline"
        placeholder="Enter Text Here"
        multiline
        rows="4"
        variant="outlined"
        fullWidth
        onChange={createNote}
      />
    </ActionDialog>
  )
}

CreateTimelineEntryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patientId: PropTypes.string.isRequired,
  saveManualTimelineEntry: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  open: getDialogOpenValue(state),
})

const mapDispatchToProps = {
  onClose: createTimelineEntryDialogClosed,
  saveManualTimelineEntry: saveManualTimelineEntry.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CreateTimelineEntryDialog)
