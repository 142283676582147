import { Codec, GetType, array, number } from 'purify-ts'
import AspireAPI from '~/resources/aspire'
import { createRequest } from '~/utils/createRequest'

export const HraProblemTypes = Codec.interface({
  acp: array(number),
  chf: array(number),
  copd: array(number),
  depression: array(number),
  diabetes: array(number),
  esrd: array(number),
  exercise: array(number),
  falls: array(number),
  frailty: array(number),
  hospital: array(number),
  medications: array(number),
  memory: array(number),
  pain: array(number),
  socioeconomic: array(number),
  walking_assistance: array(number),
})

export type HraProblemTypes = GetType<typeof HraProblemTypes>

export const fetchHraProblemTypes = createRequest<[], HraProblemTypes>(
  () => AspireAPI.get('care_plan/problem_types/hra_types'),
  HraProblemTypes
)
