import PropTypes from 'prop-types'
import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import Input from './Input'
import debounce from './debounce'
import memoizedWrapper from './memoizedWrapper'
import { getInputProps } from './props'

const TextareaWidget = props => (
  <Input
    {...getInputProps(props)}
    fullWidth
    multiline
    rows={props.options.rows}
    rowsMax={props.options.rowsMax}
    type="text"
  />
)

TextareaWidget.propTypes = {
  options: PropTypes.object.isRequired,
}

export default compose(memoizedWrapper, debounce)(TextareaWidget)
