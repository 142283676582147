import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import { addBenefit, updateBenefit } from '../data/addBenefitDialog'
import { CURRENT_YEAR } from '../data/constants'
import { getCurrentPatient } from '../data/currentPatient'
import { resetBenefits, fetchMemberBenefits } from '../data/memberInfo'
import { updateTripStatus } from '../data/tripStatus'

const refreshBenefits = (patientDetails: any) => {
  if (!patientDetails || !patientDetails.patientId) return resetBenefits
  const requestParams = {
    benefitYear: CURRENT_YEAR,
    classplanProductId: patientDetails.classplanProductId,
    eligibilityEffectiveDate: patientDetails.eligibilityEffectiveDate,
    productDescription: patientDetails.productDescription,
    shouldRefreshEEBenefit: false,
  }
  return fetchMemberBenefits.requested(patientDetails.patientId, requestParams)
}

export const refreshMemberBenefitsOnAddingNewBenefit = (
  action$: any,
  state$: any
) =>
  action$.pipe(
    ofType(addBenefit.SUCCEEDED),
    switchTo(state$),
    // @ts-expect-error state is unknown
    map(state => refreshBenefits(getCurrentPatient(state)))
  )

export const refreshMemberBenefitsOnUpdatingBenefit = (
  action$: any,
  state$: any
) =>
  action$.pipe(
    ofType(updateBenefit.SUCCEEDED),
    switchTo(state$),
    // @ts-expect-error state is unknown
    map(state => refreshBenefits(getCurrentPatient(state)))
  )

export const refreshMemberBenefitsOnUpdatingTripStatus = (
  action$: any,
  state$: any
) =>
  action$.pipe(
    ofType(updateTripStatus.SUCCEEDED),
    switchTo(state$),
    // @ts-expect-error state is unknown
    map(state => refreshBenefits(getCurrentPatient(state)))
  )
