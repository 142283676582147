// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import admissionSources from './data/admissionSources'
import admissionTypes from './data/admissionTypes'
import admissions from './data/admissions'
import discardOdsAdmissionId from './data/discardOdsAdmissionId'
import mergeDialogPatientId from './data/mergeDialogPatientId'
import mergeOdsAdmissionIds from './data/mergeOdsAdmissionIds'
import odsAdmissions from './data/odsAdmissions'
import openAdmissionIds from './data/openAdmissionIds'
import openPatientIds from './data/openPatientIds'
import patients from './data/patients'
import recycleContactId from './data/recycleContactId'
import recycleDialogAdmissionId from './data/recycleDialogAdmissionId'
import selectedOdsAdmissionIds from './data/selectedOdsAdmissionIds'
import unmergeContactId from './data/unmergeContactId'
import unmergeDialogOdsAdmissionId from './data/unmergeDialogOdsAdmissionId'
import unmergeOdsAdmissionIds from './data/unmergeOdsAdmissionIds'
import key from './key'

const reducer = combineReducers({
  [admissions.key]: admissions,
  [admissionSources.key]: admissionSources,
  [admissionTypes.key]: admissionTypes,
  [discardOdsAdmissionId.key]: discardOdsAdmissionId,
  [mergeDialogPatientId.key]: mergeDialogPatientId,
  [mergeOdsAdmissionIds.key]: mergeOdsAdmissionIds,
  [odsAdmissions.key]: odsAdmissions,
  [openAdmissionIds.key]: openAdmissionIds,
  [openPatientIds.key]: openPatientIds,
  [patients.key]: patients,
  [recycleContactId.key]: recycleContactId,
  [recycleDialogAdmissionId.key]: recycleDialogAdmissionId,
  [selectedOdsAdmissionIds.key]: selectedOdsAdmissionIds,
  [unmergeContactId.key]: unmergeContactId,
  [unmergeDialogOdsAdmissionId.key]: unmergeDialogOdsAdmissionId,
  [unmergeOdsAdmissionIds.key]: unmergeOdsAdmissionIds,
})

reducer.key = key

export default reducer
