import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'

const key = 'practiceSelection'

const scoped = scopedCreator(key)

export const selectorOpened = scoped('SELECTOR_OPENED')
export const selectorClosed = scoped('SELECTOR_CLOSED')
export const practiceSelected = scoped('PRACTICE_SELECTED', ['practice'])

export default createReducer(key, false, {
  [selectorOpened]: () => true,
  [selectorClosed]: () => false,
})

export const isPracticeSelectionOpen = getIn(['medications', key])
