import React from 'react'
import { FormSection } from 'redux-form/immutable'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette, spacing }) => ({
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: spacing(2),
  },
  formSectionLarge: {
    maxWidth: '1200px',
  },
  label: {
    color: palette.contentText.primaryLabel,
  },
})

class StyledFormSection extends React.PureComponent {
  render = () => {
    const { classes, label, name, children, large } = this.props

    return (
      <FormSection
        className={`${classes.formSection} ${
          large ? classes.formSectionLarge : ''
        }`}
        name={name}
      >
        <Typography className={classes.label} variant="subtitle1">
          {' '}
          {label}{' '}
        </Typography>
        {children}
      </FormSection>
    )
  }
}

StyledFormSection.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  large: PropTypes.bool,
}

export default withStyles(styles)(StyledFormSection)
