import { useCallback, useState } from 'react'

export default (initValue = '') => {
  const [value, setValue] = useState(initValue)

  const setValueWithTarget = useCallback(
    (event: any) => {
      setValue(event?.target?.value ?? event)
    },
    [setValue]
  )

  const values: [any, (e: any) => void] = [value, setValueWithTarget]

  return values
}
