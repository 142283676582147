import { Map } from 'immutable'
import { Location } from '~/features/locationSearch'
import { Physician } from '../data/physicians'

export const getValuesFromPhysician = (physician = Physician()) =>
  Map({
    salutation: physician.salutation,
    firstName: physician.firstName,
    middleName: physician.middleName,
    lastName: physician.lastName,
    name: physician.name,
    suffix: physician.suffix,
    title: physician.title,
    specialty: physician.specialty,
    npiNumber: physician.npiNumber,
    practiceId: physician.practiceId,
    practice: physician.practice,
    ipaId: physician.ipaId,
    ipa: physician.ipa,
    groupId: physician.groupId,
    group: physician.group,
    marketId: physician.marketId,
    market: physician.market,
    missionPoint: physician.missionPoint,
    physicianNotes: physician.physicianNotes,
    nextTargetedContactDate: physician.nextTargetedContactDate,
    phone: physician.phone,
    mobilePhone: physician.mobilePhone,
    fax: physician.fax,
    email: physician.email,
    address: Location({
      street: physician.street,
      city: physician.city,
      state: physician.state,
      postalCode: physician.postalCode,
    }),
  })

export const getPhysicianFromValues = (values, physician = Physician()) =>
  physician.merge({
    salutation: values.get('salutation'),
    firstName: values.get('firstName'),
    middleName: values.get('middleName'),
    lastName: values.get('lastName'),
    name: `${values.get('firstName')} ${values.get('lastName')}`,
    suffix: values.get('suffix'),
    title: values.get('title'),
    specialty: values.get('specialty'),
    npiNumber: values.get('npiNumber'),
    practiceId: values.get('practiceId'),
    practice: values.get('practice'),
    ipaId: values.get('ipaId'),
    ipa: values.get('ipa'),
    groupId: values.get('groupId'),
    group: values.get('group'),
    marketId: values.get('marketId'),
    market: values.get('market'),
    missionPoint: values.get('missionPoint'),
    physicianNotes: values.get('physicianNotes'),
    nextTargetedContactDate: values.get('nextTargetedContactDate'),
    phone: values.get('phone'),
    mobilePhone: values.get('mobilePhone'),
    fax: values.get('fax'),
    email: values.get('email'),
    street: values.getIn(['address', 'street']),
    city: values.getIn(['address', 'city']),
    state: values.getIn(['address', 'state']),
    postalCode: values.getIn(['address', 'postalCode']),
    latitude: values.getIn(['address', 'latitude']),
    longitude: values.getIn(['address', 'longitude']),
  })
