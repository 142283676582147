import { get, nthArg } from 'lodash/fp'
import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useSelector } from 'react-redux'
import {
  Physician,
  getPhysiciansTransformed,
  physiciansReset,
} from '~/data/physicians'
import { useAction } from '~/hooks'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import {
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PhysicianInfo from '../components/PhysicianInfo'
import { physicianSearchUpdated } from '../data/physicianSearchUpdated'

const styles = ({ spacing }) => ({
  container: { marginBottom: spacing(2) },
  formControl: { width: '100%' },
  suggestionsContainerOpen: { marginBotton: spacing(2) },
  suggestionsList: { listStyleType: 'none', margin: 0, padding: 0 },
})

const getSuggestionValue = ({
  fax,
  mailingStreet,
  mailingCity,
  mailingPostalCode,
  mailingStateCode,
  name,
  phone,
}) =>
  [
    name,
    [
      mailingStreet,
      [mailingCity, mailingStateCode].filter(Boolean).join(', '),
      mailingPostalCode,
    ]
      .filter(Boolean)
      .join(' '),
    phone,
    fax,
  ]
    .filter(Boolean)
    .join(' | ')

// Autosuggest is too eager when fetching suggestions
const ifInputChanged = f => ({ value, reason }) =>
  reason == 'input-changed' && f(value)

const PhysicianSearch = ({
  classes,
  onSelect,
  formatSuggestion,
  selectedPhysician,
}) => {
  const physicians = useSelector(getPhysiciansTransformed('toArray'))
  const onClick = useAction(physiciansReset)
  const onUpdate = useAction(physicianSearchUpdated)
  const [search, setSearch] = useState('')

  return (
    <React.Fragment>
      <Autosuggest
        getSuggestionValue={formatSuggestion}
        focusInputOnSuggestionClick={false}
        id="new-task-physician-autosuggest"
        inputProps={{
          onChange: compose(setSearch, get('newValue'), nthArg(1)),
          value: search,
        }}
        onSuggestionSelected={(x, y) => {
          y.suggestion && onSelect(y.suggestion.id, y.suggestion)
        }}
        // This prop is required, but undesirable
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={ifInputChanged(onUpdate)}
        renderInputComponent={({ ref, ...inputProps }) => (
          <FormControl className={classes.formControl} required>
            <TextField
              {...inputProps}
              id="physician-search"
              inputRef={ref}
              helperText="Type to automatically search"
              label="Physician"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Clear"
                      onClick={() => {
                        onSelect('')
                        setSearch('')
                        onClick()
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
        renderSuggestion={physician => (
          <MenuItem component="div" divider>
            <Typography noWrap>{formatSuggestion(physician)}</Typography>
          </MenuItem>
        )}
        suggestions={physicians}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
        }}
      />
      {selectedPhysician && selectedPhysician.id && (
        <PhysicianInfo vendor={selectedPhysician} />
      )}
    </React.Fragment>
  )
}

PhysicianSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  formatSuggestion: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  selectedPhysician: PropTypes.instanceOf(Physician),
}

PhysicianSearch.defaultProps = {
  formatSuggestion: getSuggestionValue,
}

export default withStyles(styles)(PhysicianSearch)
