import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const HOSPITALS = 'hospitals'

export const Hospital = Record({
  id: null,
  name: null,
  phone: null,
  fax: null,
  erPhone: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  county: null,
  bedCount: null,
  website: null,
  providerIdentificationNumber: null,
  notes: null,
})

export const fetchHospitals = Request({
  typePrefix: key,
  typeBase: 'FETCH_HOSPITALS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/hospitals`, {
      params: { searchWord },
    }),
  transform: into(Hospital, 'id'),
  messages: { failed: 'There was a problem fetching hospitals' },
})

export const fetchHospital = Request({
  typePrefix: key,
  typeBase: 'FETCH_HOSPITAL',
  requestParams: ['hospitalId'],
  operation: hospitalId => AspireAPI.get(`contacts/hospitals/${hospitalId}`),
  transform: Hospital,
  messages: { failed: 'There was a problem fetching this hospital' },
})

export const addHospital = Request({
  typePrefix: key,
  typeBase: 'ADD_HOSPITAL',
  requestParams: ['hospital'],
  operation: hospital => AspireAPI.post('contacts/hospitals', { hospital }),
  messages: {
    succeeded: 'Successfully added new hospital',
    failed: 'There was a problem adding this hospital',
  },
})

export const saveHospital = Request({
  typePrefix: key,
  typeBase: 'SAVE_HOSPITAL',
  requestParams: ['hospital'],
  operation: hospital =>
    AspireAPI.put(`contacts/hospitals/${hospital.id}`, { hospital }),
  messages: {
    succeeded: 'Successfully saved hospital',
    failed: 'There was a problem saving this hospital',
  },
})

export const deleteHospital = Request({
  typePrefix: key,
  typeBase: 'DELETE_HOSPITAL',
  requestParams: ['hospitalId'],
  operation: hospitalId => AspireAPI.delete(`contacts/hospitals/${hospitalId}`),
  messages: {
    succeeded: 'Successfully deleted hospital',
    failed: 'There was a problem deleting this hospital',
  },
})

const creator = scopedCreator(key)
export const hospitalsCleared = creator('HOSPITALS_CLEARED')

const initState = Map()
export default createReducer(HOSPITALS, initState, {
  [fetchHospitals.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchHospital.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [saveHospital.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { hospital },
        },
      },
    }
  ) => state.set(hospital.id, hospital),
  [deleteHospital.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { hospitalId },
        },
      },
    }
  ) => state.delete(hospitalId),
  [hospitalsCleared]: () => initState,
})

export const getHospitals = pipe(getRoot, get(HOSPITALS))

export const getHospitalsArr = createSelector([getHospitals], hospitals =>
  hospitals.toIndexedSeq().toArray()
)

export const getHospitalById = (state, id) =>
  getHospitals(state).get(id, Hospital())
