import { Map, Record, Set } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// RECORDS
export const Filter = Record({
  id: null,
  name: null,
  ownerId: null,
  ownerName: null,
  archived: false,
  healthplanIds: Set(),
  statusIds: Set(),
  substatusIds: Set(),
})

export const emptyFilter = Filter()

export const isEmpty = (filter: any) => !filter || filter === emptyFilter

// CONSTANTS
export const FILTER_STORED = 'filters/FILTER_STORED'
export const FILTERS_STORED = 'filters/FILTERS_STORED'
export const FILTER_ARCHIVED = 'filters/FILTER_ARCHIVED'
export const FILTER_UNARCHIVED = 'filters/FILTER_UNARCHIVED'
export const ARCHIVED_FILTERS_FETCH_REQUESTED =
  'filters/ARCHIVED_FILTERS_FETCH_REQUESTED'
export const UNARCHIVED_FILTERS_FETCH_REQUESTED =
  'filters/UNARCHIVED_FILTERS_FETCH_REQUESTED'

// ACTION CREATORS
export const filterStored = (filter: any) => ({
  type: FILTER_STORED,
  filter,
})
export const filtersStored = (filters: any) => ({
  type: FILTERS_STORED,
  filters,
})
export const filterArchived = (filterId: any) => ({
  type: FILTER_ARCHIVED,
  filterId,
})
export const filterUnarchived = (filterId: any) => ({
  type: FILTER_UNARCHIVED,
  filterId,
})
export const archivedFiltersFetchRequested = () => ({
  type: ARCHIVED_FILTERS_FETCH_REQUESTED,
})
export const unarchivedFiltersFetchRequested = () => ({
  type: UNARCHIVED_FILTERS_FETCH_REQUESTED,
})

// KEY
export const key = 'filters'

// REDUCER
export default createReducer(key, Map(), {
  [FILTER_STORED]: (state: any, { filter }: any) =>
    state.set(filter.id, filter),
  [FILTERS_STORED]: (state: any, { filters }: any) => state.mergeDeep(filters),
  [FILTER_ARCHIVED]: (state: any, { filterId }: any) =>
    state.update(filterId, (f: any) => f.set('archived', true)),
  [FILTER_UNARCHIVED]: (state: any, { filterId }: any) =>
    state.update(filterId, (f: any) => f.set('archived', false)),
})

const fromRaw = (data: any) =>
  Filter({
    id: data.id,
    name: data.name,
    archived: data.archived,
    ownerId: data.createdBy,
    ownerName: data.createdByName,
    healthplanIds: Set(data.healthplanIds),
    statusIds: Set(data.statusIds),
    substatusIds: Set(data.substatusIds),
  })

export const mapData = (data: any) =>
  Map(data.map((d: any) => [d.id, fromRaw(d)]))

// API
export const fetchFilters = (archived: any) => {
  const params = archived === undefined ? {} : { archived }
  return AspireAPI.get('v1/referral/filters', { params }).then(mapData)
}

export const fetchFilterById = (filterId: any) =>
  AspireAPI.get(`v1/referral/filters/${filterId}`).then(fromRaw)

export const saveFilter = (filter: any) => {
  const body = filter.toJS()
  const request = filter.id
    ? AspireAPI.put(`v1/referral/filters/${filter.id}`, body)
    : AspireAPI.post('v1/referral/filters', body)

  return request.then(fromRaw).then((res: any) => res || filter)
}

// SELECTORS
export const getAllFilters = (state: any) =>
  getReferralManagement(state).get(key)
export const getFilterById = (state: any, id: any) =>
  getAllFilters(state).get(id)
