import React from 'react'
import PropTypes from '~/utils/propTypes'

export default (
  inputProp,
  getValuesFromInput,
  getInputFromValues
) => Component => {
  class FormValuesTransform extends React.Component {
    onSubmit = values => {
      const { [inputProp]: input } = this.props

      this.props.onSubmit(getInputFromValues(values, input))
    }

    render = () => {
      const { [inputProp]: input } = this.props

      return (
        <Component
          {...this.props}
          initialValues={getValuesFromInput(input)}
          onSubmit={this.onSubmit}
        />
      )
    }
  }

  FormValuesTransform.propTypes = {
    [inputProp]: PropTypes.any,
    onSubmit: PropTypes.func,
  }

  return FormValuesTransform
}
