import { List, Map } from 'immutable'
import { ofType } from 'redux-observable'
import { mergeMap, pluck, withLatestFrom } from 'rxjs/operators'
import uuid from 'uuid/v4'
import { ASSESSMENT_FORM } from '~/apps/assessment/router'
import { isOffline } from '~/data/connectivity'
import { getRoute } from '~/data/route'
import { formatDatepickerDate } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import {
  getPatientRxById,
  putPatientRx,
  saveOffline,
  savePatientMedication,
} from '../data/patientRx'
import {
  hasSelectedMedication,
  rxMedicationCleared,
} from '../data/selectedMedication'
import { isPending } from '../utils/medListConstants'

export default (action$, state$) =>
  action$.pipe(
    ofType(savePatientMedication),
    pluck('payload'),
    withLatestFrom(state$),
    mergeMap(([{ medication, mdtSingleSignOnRequest, ngFlag }, state]) => {
      const prescription = createPrescription(
        medication?.set
          ? medication.set('ngFlag', ngFlag || medication.ngFlag)
          : { ...medication, ngFlag: ngFlag || medication.ngFlag },
        state
      )
      return shouldSaveOffline(state)
        ? [
            saveOffline(prescription),
            hasSelectedMedication(state) && rxMedicationCleared(),
          ].filter(Boolean)
        : [putPatientRx.requested(prescription, mdtSingleSignOnRequest)]
    })
  )

const shouldSaveOffline = state =>
  isOffline(state) && getRoute(state) === ASSESSMENT_FORM

const createPrescription = (medication, state) =>
  pipe(
    Map,
    maybeAddUuid,
    stringValues,
    assignCsmdDateIfPending,
    checkOverrideReasonIfPending,
    removePrescriberIfNotPending(state),
    applyDefaultValues
  )(medication)

const applyTransormationWhenPending = func => med =>
  isPending(med.get('status')) ? func(med) : med

const valueOr = defaultValue => value => value || defaultValue

const maybeAddUuid = med => med.update('id', valueOr(uuid()))

const DONT_STRINGIFY = List(['compound', 'supply', 'substitutionAllowed'])
const stringValues = med =>
  med.map(
    (value, key) =>
      value && (DONT_STRINGIFY.includes(key) ? value : value.toString())
  )

const assignCsmdDateIfPending = applyTransormationWhenPending(med =>
  med.get('csmdAttestationCheck') && !med.get('csmdAttestationDate')
    ? med.set('csmdAttestationDate', formatDatepickerDate())
    : med
)

const checkOverrideReasonIfPending = applyTransormationWhenPending(med =>
  !med.has('alertCheck') ? med.set('overrideReason', null) : med
)

// Remove prescriber information if new med is created outside of pending
// status or medication is changed from pending -> nonpending manually
const shouldRemovePrescriber = (med, state) => {
  const originalMedication = getPatientRxById(state)(med.get('id'))
  const currentlyPending = isPending(med.get('status'))

  return originalMedication
    ? isPending(originalMedication.status) !== currentlyPending
    : !currentlyPending
}
const removePrescriberIfNotPending = state => med =>
  shouldRemovePrescriber(med, state)
    ? med.merge({ prescriberId: null, locationId: null })
    : med

// Default values needed to appease MDToolbox
const applyDefaultValues = applyTransormationWhenPending(med =>
  med
    .update('compound', valueOr(false))
    .update('daysSupply', valueOr('0'))
    .update('ekit', valueOr(false))
    .update('prescriptionDate', valueOr(new Date().toISOString()))
    .update('qty', valueOr('0'))
    .update('qtyQual', valueOr('n/a'))
    .update('refills', valueOr('0'))
    .update('substitutionAllowedFlag', valueOr(1))
    .update('supply', valueOr(false))
)
