import { combineReducers } from 'redux-immutable'
import claims from './claims'
import date from './date'
import { CALENDAR } from './root'
import timeZone from './timeZone'

const reducer = combineReducers({
  [claims.key]: claims,
  [date.key]: date,
  [timeZone.key]: timeZone,
})

reducer.key = CALENDAR

export default reducer

export * from './root'
export * from './claims'
export * from './date'
export * from './timeZone'
