import React from 'react'
import { useSelector } from 'react-redux'
import { getFieldValues } from '~/data/fieldValues'
import PropTypes from '~/utils/propTypes'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BaseField from './BaseField'

const useStyles = makeStyles(() => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioButtonLabel: {
    position: 'relative',
    top: 1,
  },
}))

const RadioOptions = props => {
  const classes = useStyles(props)
  const { allowNone, disabled, field, onChange, value } = props
  const fieldValues = useSelector(getFieldValues(field))

  const getTargetValue = e => onChange(e.target.value, e)

  const FieldValue = ({ value, label }) => (
    <FormControlLabel
      classes={{ label: classes.radioButtonLabel }}
      key={value}
      value={value}
      control={<Radio />}
      label={label}
    />
  )

  FieldValue.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
  }

  const renderOptions = fieldValues =>
    fieldValues.toIndexedSeq().map(FieldValue)

  return (
    <BaseField {...props}>
      <FormControl disabled={disabled}>
        <RadioGroup
          aria-label="radioButtonGroup"
          name="radioButtonGroup"
          className={classes.radioGroup}
          value={value || 'none'}
          onChange={onChange && getTargetValue}
        >
          {allowNone && (
            <FormControlLabel
              classes={{ label: classes.radioButtonLabel }}
              key="none"
              value="none"
              control={<Radio />}
              label="None"
            />
          )}
          {renderOptions(fieldValues)}
        </RadioGroup>
      </FormControl>
    </BaseField>
  )
}

RadioOptions.propTypes = {
  allowNone: PropTypes.bool,
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

RadioOptions.defaultProps = {
  allowNone: false,
  disabled: false,
}

export default RadioOptions
