import { colors } from '@material-ui/core'
import lightTheme from '../../light'
import cloversBackground from './clovers-lite.png'
import sky from './sky.jpg'

export const stPatricksDayStyles = () => ({
  '@global': {
    html: {
      minHeight: '100%',
    },
    body: {
      backgroundColor: colors.green[100],
      background: `url(${cloversBackground})`,
      backgroundSize: '20%',
    },
    '.aspire-icon': {
      color: colors.green[500],
    },
    '.holidayIcon': {
      animation: 'spin 2s infinite linear',
      backgroundColor: '#D4AF37',
      border: '1px solid #a88924',
      borderRadius: '50%',
      height: 24,
      width: 24,
    },
    '@keyframes spin': {
      '0%': { transform: 'rotateY(0)' },
      '50%': { transform: 'rotateY(180deg)' },
      '100%': { transform: 'rotateY(0)' },
    },
    '@media (min-width: 960px)': {
      body: {
        backgroundSize: '10%',
      },
    },
  },
})

export const stPatricksDayTheme = () => ({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    holiday: 'stpatricks',
    primary: {
      light: colors.green[300],
      main: colors.green[500],
      dark: colors.green[700],
    },
    secondary: {
      main: '#D4AF37',
    },
    drawer: {
      backgroundClosed: {
        backgroundColor: colors.green[700],
      },
      backgroundOpen: {
        background: {
          color: colors.lightBlue[100],
          image: `url(${sky})`,
          size: 'cover',
        },
        '& $header': {
          backgroundColor: '#107ebe',
          '& + ul > *:nth-child(6n - 5)': {
            backgroundColor: 'rgba(123, 31, 162, 0.5)',
          },
          '& + ul > *:nth-child(6n - 4)': {
            backgroundColor: 'rgba(48, 63, 159, 0.5)',
          },
          '& + ul > *:nth-child(6n - 3)': {
            backgroundColor: 'rgba(56, 142, 60, 0.5)',
          },
          '& + ul > *:nth-child(6n - 2)': {
            backgroundColor: 'rgba(251, 192, 45, 0.5)',
          },
          '& + ul > *:nth-child(6n - 1)': {
            backgroundColor: 'rgba(245, 124, 0, 0.5)',
          },
          '& + ul > *:nth-child(6n - 0)': {
            backgroundColor: 'rgba(211, 47, 47, 0.5)',
          },
        },
      },
    },
    enrolledStatusBadge: {
      backgroundColor: colors.green[800],
      color: '#fff',
    },
    accordion: {
      color: colors.common.white,
      detailsBackground: colors.green[50],
      header: colors.green[700],
    },
    alertsAccordion: {
      backgroundColor: colors.green[800],
    },
    toolbarHeaderText: {
      color: colors.common.white,
    },
    contentText: {
      primaryLabel: colors.green[700],
      secondaryLabel: '#D4AF37',
    },
    calendarHead: {
      backgroundColor: colors.green[700],
    },
    calendarTitleText: {
      color: colors.green[500],
    },
    formPageTitle: {
      color: colors.green[500],
    },
    notifications: {
      linkText: colors.green[500],
    },
    patientSearchProgramBadge: {
      backgroundColor: colors.green[700],
      color: colors.common.white,
    },
    tabSelected: {
      backgroundColor: colors.green[700],
    },
    vitalsToolbarHeader: {
      backgroundColor: colors.green[700],
    },
    vitalsChipContainer: {
      backgroundColor: colors.green[300],
    },
    vitalsChipChosen: {
      backgroundColor: colors.green[700],
      color: colors.common.white,
    },
  },
  props: {
    MuiFab: {
      disableRipple: true,
    },
  },
  overrides: {
    ...lightTheme.overrides,
    MuiAppBar: {
      colorPrimary: {
        color: colors.common.white,
      },
    },
    MuiButton: {
      outlined: {
        borderColor: colors.green[500],
        color: colors.green[500],
      },
      root: {
        '&:hover': {
          backgroundColor: colors.green[700],
          color: colors.common.white,
        },
      },
    },
    MuiFab: {
      label: {
        color: colors.common.white,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: colors.green[100],
        },
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: colors.green[100],
        '& .material-icons': {
          color: colors.green[500],
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: colors.green[100],
      },
    },
  },
})
