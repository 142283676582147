import { combineEpics, ofType } from 'redux-observable'
import { match } from 'redux-routable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getRoute } from '~/data/route'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { updateSNFSubstatus } from '~/features/scheduling/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { addSNF } from '~/features/snfs'
import { patientSNFSubstatusSet } from '../data/patientList'
import { referralSchedulingRouter } from '../router'

const addSNFEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addSNF.SUCCEEDED),
    pluck('meta', 'request', 'payload'),
    withLatestFrom(state$),
    filter(([_, state]) => match(getRoute(state), referralSchedulingRouter)),
    filter(([{ snf }]) => !snf.dischargeDate),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([{ patientId }]: [{ patientId: ... Remove this comment to see the full error message
    map(([{ patientId }]) => patientSNFSubstatusSet(patientId))
  )

const updateSNFSubstatusEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updateSNFSubstatus.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'patientId'),
    withLatestFrom(state$),
    filter(([_, state]) => match(getRoute(state), referralSchedulingRouter)),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([patientId]: [any]) => any' is ... Remove this comment to see the full error message
    map(([patientId]) => patientSNFSubstatusSet(patientId))
  )

export default combineEpics(addSNFEpic, updateSNFSubstatusEpic)
