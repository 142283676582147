import {
  differenceInDays,
  isPast,
  isToday,
  isTomorrow,
  parseISO,
  parseJSON,
} from 'date-fns'
import { List, Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { defaultTo } from 'lodash/fp'
import { createSelector } from 'reselect'
import { calcFormData, changeFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
import Request, { flattenErrors } from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { isActive, isInactive } from '../utils'
import { User, getRoot, transformCareTeamMembers } from './common'

const EPISODES_OF_CARE = 'episodesOfCare'

const creator = scopedCreator(rootKey)
export const saveForm = creator('SAVE_FORM', ['id'])
export const formUpdated = creator('FORM_UPDATED', ['id', 'params'])
export const includeToggleClicked = creator('INCLUDE_TOGGLE_CLICKED', [
  'ownerId',
  'status',
  'include',
])
export const myPatientsClicked = creator('MY_PATIENTS_CLICKED', ['ownerId'])

const Event = Record({
  active: false,
  category: null,
  createdAt: null,
  createdBy: null,
  createdByName: null,
  description: null,
  link: null,
  linkEntity: null,
  linkId: null,
  modifiedByName: null,
  modifiedAt: null,
  modifiedBy: null,
  status: null,
  type: null,
})

export const Form = Record({
  context: {},
  errored: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
  saved: true,
})

export const Owner = Record({
  id: null,
  episodeOfCareId: null,
  primaryOwner: false,
  objective: null,
  nextTargetedOutreach: null,
  status: null,
  statusLabel: null,
  statusReason: null,
  statusReasonLabel: null,
  statusNote: null,
  createdAt: null,
  completedAt: null,
  cancelledAt: null,
  user: User(),
})

export const Program = Record({
  id: null,
  episodeOfCareId: null,
  owner: User(),
  programLabel: null,
  status: null,
  statusLabel: null,
  statusReason: null,
  createdAt: null,
  startDate: null,
  endDate: null,
  closedDate: null,
  closedBy: User(),
  comments: List(),
})

export const Patient = Record({
  id: null,
  name: null,
  programEnrolled: null,
  status: null,
  substatus: null,
})

export const EpisodeOfCare = Record({
  id: null,
  current: false,
  mostRecentAdmitDischargeDate: null,
  mostRecentAdmitFacilityName: null,
  mostRecentAdmitType: null,
  nextTargetedOutreach: null,
  objective: null,
  referralSource: null,
  status: null,
  statusLabel: null,
  statusNote: null,
  statusReason: null,
  statusReasonLabel: null,
  type: null,
  typeLabel: null,
  activeDays: null,
  patient: Patient(),
  patientId: null,
  careTeamMembers: List(),
  primaryOwner: Owner(),
  secondaryOwners: List(),
  programs: List(),
  userOwner: Owner(),
  requestedUser: User(),
  requestedBy: null,
  createdUser: User(),
  createdBy: null,
  createdAt: null,
  completedUser: User(),
  completedBy: null,
  completedAt: null,
  cancelledUser: User(),
  cancelledBy: null,
  cancelledAt: null,
  modifiedUser: User(),
  modifiedBy: null,
  modifiedAt: null,
  events: List(),
  history: List(),
  form: null,
  formId: null,
  consentDate: null,
})

// Transformers
const transformEvents = events =>
  Array.isArray(events) ? List(events.map(e => Event(e))) : List()

const transformHistory = history =>
  Array.isArray(history) ? List(history.map(h => History(h))) : List()

const transformForm = form =>
  form &&
  Form({
    ...form,
    formData: calcFormData({
      formData: form.data,
      schema: form.schema,
      tags: form.tags,
    }),
  })

const transformOwner = ({
  createdAt,
  completedAt,
  cancelledAt,
  nextTargetedOutreach,
  user,
  ...owner
}) => ({
  ...owner,
  createdAt: createdAt && parseJSON(createdAt),
  completedAt: completedAt && parseJSON(completedAt),
  cancelledAt: cancelledAt && parseJSON(cancelledAt),
  nextTargetedOutreach: nextTargetedOutreach && parseISO(nextTargetedOutreach),
  user: User(user),
})

const transformProgram = ({
  startDate,
  endDate,
  closedDate,
  createdAt,
  owner,
  closedBy,
  comments,
  ...program
}) => {
  return {
    ...program,
    createdAt: createdAt && parseISO(createdAt),
    closedDate: closedDate && parseISO(closedDate),
    startDate: startDate && parseISO(startDate),
    endDate: endDate && parseISO(endDate),
    owner: User(owner),
    closedBy: User(closedBy),
    comments: comments
      .map(transformProgramComments)
      .sort((a, b) => b.createdAt - a.createdAt),
  }
}

const transformProgramComments = ({ createdBy, createdAt, ...comment }) => ({
  ...comment,
  createdAt: createdAt && parseISO(createdAt),
  createdBy: User(createdBy),
})

const transformEpisodeOfCare = ({
  createdAt,
  completedAt,
  cancelledAt,
  primaryOwner,
  secondaryOwners,
  programs,
  userNextTargetedOutreach,
  cancelledUser,
  completedUser,
  events,
  history,
  form,
  modifiedUser,
  patient,
  createdUser,
  requestedUser,
  ...episodeOfCare
}) => ({
  ...episodeOfCare,
  createdAt: parseJSON(createdAt),
  completedAt: completedAt && parseJSON(completedAt),
  cancelledAt: cancelledAt && parseJSON(cancelledAt),
  primaryOwner: transformOwner(primaryOwner),
  secondaryOwners: List(secondaryOwners.map(transformOwner)).sortBy(
    owner => owner.id
  ),
  programs: sortPrograms(List(programs.map(transformProgram))),
  userNextTargetedOutreach:
    userNextTargetedOutreach && parseISO(userNextTargetedOutreach),
  activeDays: isActive(episodeOfCare.status)
    ? differenceInDays(new Date(), parseJSON(createdAt)) + 1
    : null,
  history: transformHistory(history),
  events: transformEvents(events),
  form: transformForm(form),
  completedUser: User(completedUser),
  cancelledUser: User(cancelledUser),
  modifiedUser: User(modifiedUser),
  createdUser: User(createdUser),
  requestedUser: User(requestedUser),
  patient: Patient({
    ...patient,
    name: getIn(patient, ['demographics', 'name']),
    status: getIn(patient, ['aspire', 'status']),
    substatus: getIn(patient, ['aspire', 'substatus']),
  }),
  careTeamMembers: transformCareTeamMembers(patient),
})

const sortPrograms = programs => {
  return programs
    .filter(program => ['created'].includes(program.status))
    .sort((a, b) => a.startDate - b.startDate)
    .concat(
      programs
        .filter(program => ['completed', 'closed'].includes(program.status))
        .sortBy((a, b) => a.startDate - b.startDate)
    )
}

const transformSwappedOwners = ({ oldPrimaryOwner, newPrimaryOwner }) => ({
  oldPrimaryOwner: transformOwner(oldPrimaryOwner),
  newPrimaryOwner: newPrimaryOwner && transformOwner(newPrimaryOwner),
})

const createEpisodeOfCare = pipe(transformEpisodeOfCare, EpisodeOfCare)

// Requests
export const fetchEpisodesOfCare = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_EPISODES_OF_CARE',
  requestParams: ['params'],
  operation: params => AspireAPI.get('episodes_of_care', { params: params }),
  transform: into(createEpisodeOfCare, 'id'),
  messages: { failed: 'Could not fetch episodes of care' },
})

export const fetchEpisodeOfCare = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_EPISODE_OF_CARE',
  requestParams: ['id'],
  operation: id => AspireAPI.get(`episodes_of_care/${id}`),
  transform: createEpisodeOfCare,
  messages: { failed: 'Could not fetch episode of care' },
})

export const syncContactsInEOCForm = Request({
  typePrefix: rootKey,
  typeBase: 'SYNC_CONTACTS_IN_EOC_FORM',
  requestParams: ['id'],
  operation: id => AspireAPI.get(`episodes_of_care/${id}/sync_contacts`),
  messages: { failed: 'Could not Sync Contacts.' },
})

export const saveEpisodeOfCare = Request({
  typePrefix: rootKey,
  typeBase: 'UPDATE_EPISODE_OF_CARE',
  requestParams: ['id', 'data'],
  operation: (id, data) => AspireAPI.put(`episodes_of_care/${id}`, data),
  transform: createEpisodeOfCare,
  messages: {
    succeeded: 'Successfully saved episode of care',
    failed: 'Could not save episode of care',
  },
})

export const cancelEpisodeOfCare = Request({
  typePrefix: rootKey,
  typeBase: 'CANCEL_EPISODE_OF_CARE',
  requestParams: ['id', 'statusReason', 'statusNote'],
  operation: (id, statusReason, statusNote) =>
    AspireAPI.put(`episodes_of_care/${id}/cancel`, {
      statusReason,
      statusNote,
    }),
  transform: createEpisodeOfCare,
  messages: {
    succeeded: 'Successfully closed episode of care',
    failed: 'Could not close episode of care',
  },
})

export const createSecondaryOwner = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_SECONDARY_OWNER',
  requestParams: ['id', 'data'],
  operation: (id, data) =>
    AspireAPI.post(`episodes_of_care/${id}/owners`, data),
  transform: transformOwner,
  messages: {
    succeeded: 'Successfully created secondary owner',
    failed: 'Could not create secondary owner',
  },
})

export const addProgram = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_PROGRAM',
  requestParams: ['id', 'data'],
  operation: (id, data) =>
    AspireAPI.post(`episodes_of_care/${id}/programs`, data),
  transform: transformProgram,
  messages: {
    succeeded: 'Successfully added program',
    failed: 'Could not add program',
  },
})

export const saveProgram = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PROGRAM',
  requestParams: ['episodeOfCareId', 'id', 'data'],
  operation: (episodeOfCareId, id, data) =>
    AspireAPI.put(`episodes_of_care/${episodeOfCareId}/programs/${id}`, data),
  transform: transformOwner,
  messages: {
    succeeded: 'Successfully saved program',
    failed: 'Could not save program',
  },
})

export const closeOrComplete = Request({
  typePrefix: rootKey,
  typeBase: 'PROGRAM_STATUS_CHANGE',
  requestParams: ['episodeOfCareId', 'id', 'data'],
  operation: (episodeOfCareId, id, data) =>
    AspireAPI.put(
      `episodes_of_care/${episodeOfCareId}/programs/${id}/closeOrComplete`,
      data
    ),
  transform: transformOwner,
  messages: {
    succeeded: 'Successfully saved program',
    failed: 'Could not save program',
  },
})

export const saveOwner = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_OWNER',
  requestParams: ['episodeOfCareId', 'id', 'data'],
  operation: (episodeOfCareId, id, data) =>
    AspireAPI.put(`episodes_of_care/${episodeOfCareId}/owners/${id}`, data),
  transform: transformOwner,
  messages: {
    succeeded: 'Successfully saved owner',
    failed: 'Could not save owner',
  },
})

export const promoteOwner = Request({
  typePrefix: rootKey,
  typeBase: 'PROMOTE_OWNER',
  requestParams: ['episodeOfCareId', 'id'],
  operation: (episodeOfCareId, id) =>
    AspireAPI.put(`episodes_of_care/${episodeOfCareId}/owners/${id}/promote`),
  transform: transformSwappedOwners,
  messages: {
    succeeded: 'Owner promoted to primary owner',
    failed: 'Could not promote owner',
  },
})

export const completeOwner = Request({
  typePrefix: rootKey,
  typeBase: 'COMPLETE_OWNER',
  requestParams: ['episodeOfCareId', 'id', 'data'],
  operation: (episodeOfCareId, id, data) =>
    AspireAPI.put(
      `episodes_of_care/${episodeOfCareId}/owners/${id}/complete`,
      data
    ),
  transform: transformSwappedOwners,
  messages: {
    succeeded: 'Objective completed for owner',
    failed: 'Could not complete objective for owner',
  },
})

export const cancelOwner = Request({
  typePrefix: rootKey,
  typeBase: 'CANCEL_OWNER',
  requestParams: ['episodeOfCareId', 'id', 'data'],
  operation: (episodeOfCareId, id, data) =>
    AspireAPI.put(
      `episodes_of_care/${episodeOfCareId}/owners/${id}/cancel`,
      data
    ),
  transform: transformSwappedOwners,
  messages: {
    succeeded: 'Objective cancelled for owner',
    failed: 'Could not cancel objective for owner',
  },
})

export const saveEpisodeOfCareForm = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_EPISODE_OF_CARE_FORM',
  requestParams: ['id', 'formData'],
  operation: (id, formData) =>
    AspireAPI.put(`episodes_of_care/${id}/form`, { formData }),
  messages: { failed: 'Failed to save episode of care form' },
})

export const logCallFromEOC = Request({
  typePrefix: 'formAction',
  typeBase: 'LOG_CALL',
  requestParams: ['params', 'formContext'],
  operation: (formData, formContext) =>
    AspireAPI.post(
      `episodes_of_care/${getIn(formContext, [
        'episodeOfCareDetails',
        'id',
      ])}/form/log_call`,
      { params: { ...formData } }
    ),
  messages: {
    succeeded: 'Successfully logged call',
    failed: e =>
      flattenErrors(getIn(e, 'response.data.message')) || 'Failed to log call',
  },
})

const setCurrentOwner = payload => payload.set('current', true)

const updateOwner = (state, owner) =>
  state.update(owner.episodeOfCareId, eoc => {
    const { primaryOwner, secondaryOwners } = eoc

    if (primaryOwner.id === owner.id) {
      return eoc.set('primaryOwner', owner)
    } else {
      const index = secondaryOwners.findIndex(({ id }) => id === owner.id)
      return eoc.setIn(['secondaryOwners', index], owner)
    }
  })

const swapOwners = (state, oldPrimaryOwner, newPrimaryOwner) =>
  state.update(newPrimaryOwner.episodeOfCareId, eoc =>
    eoc
      .set('primaryOwner', newPrimaryOwner)
      .update('secondaryOwners', owners => {
        const index = owners.findIndex(({ id }) => id === newPrimaryOwner.id)

        return owners
          .delete(index)
          .push(oldPrimaryOwner)
          .sortBy(({ id }) => id)
      })
  )

const swapOwnersReducer = (
  state,
  { payload: { oldPrimaryOwner, newPrimaryOwner } }
) =>
  newPrimaryOwner
    ? swapOwners(state, oldPrimaryOwner, newPrimaryOwner)
    : updateOwner(state, oldPrimaryOwner)

const updateProgram = (state, program) =>
  state.update(program.episodeOfCareId, eoc => {
    const { programs } = eoc

    const index = programs.findIndex(({ id }) => id === program.id)
    return eoc.setIn(['programs', index], transformProgram(program))
  })

const updateContactsSchema = (state, action) => {
  return state.setIn(
    [action.meta.request.payload.id, 'form', 'schema'],
    action.payload
  )
}

export default createReducer(EPISODES_OF_CARE, Map(), {
  [fetchEpisodeOfCare.SUCCEEDED]: (state, { payload }) =>
    state
      .map(eoc => eoc.set('current', false))
      .set(payload.id, setCurrentOwner(payload)),
  [fetchEpisodesOfCare.SUCCEEDED]: (state, { payload }) => {
    const currentEOC = state.find(eoc => eoc.current)
    const newPayload = currentEOC ? payload.delete(currentEOC.id) : payload

    return state.merge(newPayload)
  },
  [saveEpisodeOfCare.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, setCurrentOwner(payload)),
  [cancelEpisodeOfCare.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, setCurrentOwner(payload)),
  [syncContactsInEOCForm.SUCCEEDED]: updateContactsSchema,
  // Owners
  [createSecondaryOwner.SUCCEEDED]: (state, { payload }) =>
    state.updateIn([payload.episodeOfCareId, 'secondaryOwners'], owners =>
      owners.push(payload)
    ),
  [saveOwner.SUCCEEDED]: (state, { payload }) => updateOwner(state, payload),
  [promoteOwner.SUCCEEDED]: swapOwnersReducer,
  [completeOwner.SUCCEEDED]: swapOwnersReducer,
  [cancelOwner.SUCCEEDED]: swapOwnersReducer,
  // Programs
  [addProgram.SUCCEEDED]: (state, { payload }) =>
    state.updateIn([payload.episodeOfCareId, 'programs'], programs =>
      programs.push(payload)
    ),
  [saveProgram.SUCCEEDED]: (state, { payload }) =>
    updateProgram(state, payload),
  [closeOrComplete.SUCCEEDED]: (state, { payload }) =>
    updateProgram(state, payload),
  [formUpdated]: (state, { payload: { id, params } }) =>
    state.updateIn([id, 'form'], form =>
      changeFormData(form, null, params.formData)
        .set('errored', Boolean(params.errors.length))
        .set('saved', false)
    ),
  [saveEpisodeOfCareForm.SUCCEEDED]: (state, { payload }) =>
    state.update(payload.id, eoc =>
      eoc.merge({
        status: payload.status,
        statusLabel: payload.statusLabel,
      })
    ),
})

// Selectors
const getEpisodesOfCare = pipe(getRoot, get(EPISODES_OF_CARE))

const patientFilter = patientId => ({ patient }) => patient.id === patientId

const myPatientFilter = careTeamMemberId => ({ careTeamMembers, status }) =>
  careTeamMembers.some(({ id }) => id === careTeamMemberId) && isActive(status)

const ownerFilter = (userId, statusFilters) => ({
  primaryOwner,
  secondaryOwners,
}) =>
  secondaryOwners
    .push(primaryOwner)
    .some(
      ({ user, status }) =>
        user.id === userId && (isActive(status) || statusFilters[status])
    )

const setUserOwner = userId => eoc => {
  const { primaryOwner, secondaryOwners } = eoc

  // Filter through owners to find objectives for current user
  const userOwners = secondaryOwners
    .push(primaryOwner)
    .filter(({ user }) => user.id === userId)
    .sortBy(({ nextTargetedOutreach }) => nextTargetedOutreach)

  // Find most overdue open objective or most recently completed objective
  // Fall back to primary owner if user has no objectives
  const userOwner = userOwners.isEmpty()
    ? primaryOwner
    : userOwners.find(({ status }) => isActive(status)) || userOwners.last()

  return eoc.set('userOwner', userOwner)
}

const createFilteredSelector = filter =>
  createSelector(
    [
      getEpisodesOfCare,
      (_state, entityId) => entityId,
      (_state, _entityId, statusFilters) => statusFilters,
    ],
    (episodesOfCare, entityId, statusFilters = {}) =>
      episodesOfCare
        .sortBy(get('primaryOwner'), (a, b) =>
          a['nextTargetedOutreach'] < b['nextTargetedOutreach'] ? -1 : 1
        )
        .filter(filter(entityId, statusFilters))
        .map(setUserOwner(entityId))
        .toIndexedSeq()
        .toJS()
  )

export const getPatientEpisodesOfCare = createFilteredSelector(patientFilter)

export const getMyPatientsEpisodesOfCare = createFilteredSelector(
  myPatientFilter
)

export const getOwnerEpisodesOfCare = createFilteredSelector(ownerFilter)

export const actionableFilter = ({ userOwner }) => {
  if (!userOwner || isInactive(userOwner.status)) {
    return false
  }

  return userOwner.nextTargetedOutreach
    ? isPast(userOwner.nextTargetedOutreach) ||
        isToday(userOwner.nextTargetedOutreach) ||
        isTomorrow(userOwner.nextTargetedOutreach)
    : true
}

export const getOwnerActionableEpisodesOfCare = createSelector(
  [createFilteredSelector(ownerFilter)],
  episodesOfCare => episodesOfCare.filter(actionableFilter)
)

export const getEpisodeOfCareById = state => id =>
  pipe(getEpisodesOfCare, get(id), defaultTo(EpisodeOfCare()))(state)
