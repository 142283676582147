import localforage from 'localforage'
import { map, zipObject } from 'lodash'
import moment from 'moment'

export const APP_DASHBOARD_CENSUS_FILTERS = 'appDashboardCensusFilters'
export const APP_DASHBOARD_HIGH_RISK_HUDDLE_FILTERS =
  'appDashboardHighRiskHuddleFilters'
export const APP_DASHBOARD_LISTS_FILTERS = 'appDashboardListsFilters'
export const CASE_MANAGEMENT_CENSUS_FILTERS = 'caseMangementCensusFilters'
export const CD_DASHBOARD_CENSUS_FILTERS = 'cdDashboardCensusFilters'
export const CD_DASHBOARD_HIGH_RISK_HUDDLE_FILTERS =
  'cdDashboardHighRiskHuddleFilters'
export const CD_DASHBOARD_LISTS_FILTERS = 'cdDashboardListsFilters'
export const CSS_DASHBOARD_PATIENT_FILTERS = 'cssDashboardPatientFilters'
export const CSS_DASHBOARD_PATIENT_GRID = 'cssDashboardPatientGrid'
export const LAST_DETECTED_ACTIVITY_TIME = 'lastDetectedActivityTime'
export const LOAD_INACTIVE_USERS = 'shouldLoadInactiveUsers'
export const LOCAL_ASSESSMENT = 'localAssessment'
export const LOCAL_ENCOUNTER_CHECK_IN = 'localEncounterCheckIn'
export const LOCAL_ENCOUNTER_CHECK_OUT = 'localEncounterCheckOut'
const LOCAL_STORAGE_TRANSFER_COMPLETE = 'LOCAL_STORAGE_TRANSFER_COMPLETE'
export const NURSE_DASHBOARD_CENSUS_FILTERS = 'nurseDashboardCensusFilters'
export const NURSE_DASHBOARD_HIGH_RISK_HUDDLE_FILTERS =
  'nurseDashboardHighRiskHuddleFilters'
export const NURSE_DASHBOARD_LISTS_FILTERS = 'nurseDashboardListsFilters'
export const PATIENT_TIMELINE_SWIMLANE = 'patientTimelineSwimlane'
export const PES_DASHBOARD_PATIENT_FILTERS = 'pesDashboardPatientFilters'
export const PES_DASHBOARD_PATIENT_GRID = 'pesDashboardPatientGrid'
export const PHYSICIAN_DASHBOARD_CENSUS_FILTERS =
  'physicianDashboardCensusFilters'
export const PHYSICIAN_DASHBOARD_HIGH_RISK_HUDDLE_FILTERS =
  'physicianDashboardHighRiskHuddleFilters'
export const PHYSICIAN_DASHBOARD_LISTS_FILTERS =
  'physicianDashboardListsFilters'
export const SESSION_TOKEN = 'sessionToken'
export const SESSION_USER = 'sessionUser'
export const SW_DASHBOARD_REFERRALS_FILTERS = 'swDashboardReferralsFilters'
export const THEME_HOLIDAY_TYPE = 'themeHolidayType'
export const THEME_TYPE = 'themeType'
export const USERS_LIST = 'usersList'
export const VITALS_CHART = 'vitalsChart'
export const SAFETY_EXISTING = 'safetyExisting'

localforage.config({
  name: 'AspireClientLocalForage',
  storeName: 'AspireClient',
  description: 'Aspire Client browsers storage using localforage / IndexedDB',
})

// Additional metadata is optional, but the permanent flag will keep the data from being cleared after a week
export const setItem = <T>(
  key: string,
  origValue: T,
  additionalMetadata = {}
): Promise<T | undefined | never> => {
  const valueWithMetadata = {
    data: origValue,
    metadata: {
      timestamp: new Date(),
      ...additionalMetadata,
    },
  }

  return localforage
    .setItem(key, valueWithMetadata)
    .then(v => v.data)
    .catch(err => {
      if (__TEST__) {
        return undefined
      }
      throw err
    })
}

export const setPermanentItem = <T>(
  key: string,
  value: T,
  additionalMetadata = {}
): Promise<T | undefined | never> =>
  setItem(key, value, { permanent: true, ...additionalMetadata })

export const getItem = <T>(key: string): Promise<T | undefined | never> =>
  localforage
    .getItem<{ data: T } | null | undefined>(key)
    .then(value => (value ? value.data : undefined))
    .catch(err => {
      if (__TEST__) {
        return undefined
      }
      throw err
    })

export const hasItem = (key: string): Promise<boolean> =>
  localforage.keys().then(keys => keys.includes(key))

export const getItemWithMetadata = <T>(
  key: string
): Promise<T | undefined | never> =>
  localforage.getItem<T>(key).catch(err => {
    if (__TEST__) {
      return undefined
    }
    throw err
  })

export const removeItem = (key: string): Promise<void | undefined | never> =>
  localforage.removeItem(key).catch(err => {
    if (__TEST__) {
      return undefined
    }
    throw err
  })

// Use Iterate to remove any data missing timestamp metadata or timestamped as a week old.
localforage
  .iterate<
    { metadata: { timestamp: Date; permanent: boolean } | undefined },
    void | null
  >((value, key) => {
    if (!value.metadata) {
      removeItem(key)
    } else if (
      value.metadata.timestamp &&
      !value.metadata.permanent &&
      moment(value.metadata.timestamp) < moment().subtract(7, 'd')
    ) {
      removeItem(key)
    }
  })
  .catch(_err => null)

const initDataKeys: string[] = [
  LOAD_INACTIVE_USERS,
  PATIENT_TIMELINE_SWIMLANE,
  VITALS_CHART,
]

interface InitializationData {
  [LOAD_INACTIVE_USERS]?: boolean
  [PATIENT_TIMELINE_SWIMLANE]?: any // should probably be an object or something...
  [VITALS_CHART]?: any // should also probably be an object or something
}

export const getInitializationData = (): Promise<InitializationData> =>
  Promise.all(initDataKeys.map(getItem))
    .then(values => zipObject(initDataKeys, values))
    .catch(_err => ({}))

// The local transfer stuff can all be removed after a week.
// It's just to avoid messing with user's setting as we switch from localstorage
const transferFromLocalStorage = (indexDBKeys: string[]): void => {
  const localStorageKeys = Object.keys(window.localStorage).filter(
    key => !indexDBKeys.includes(key) && key !== USERS_LIST
  ) // all local storage keys not already in indexDB and user list because I changed signature of it

  const localStorageValues = localStorageKeys
    .map(x => window.localStorage.getItem(x))
    .map(value => {
      try {
        const parsedValue = value === null ? null : JSON.parse(value)
        return parsedValue
      } catch (err) {
        return value
      }
    })

  const localStorageObject = zipObject(localStorageKeys, localStorageValues)

  Promise.all(
    map(localStorageObject, (value, key) => setItem(key, value))
  ).then(() => setItem(LOCAL_STORAGE_TRANSFER_COMPLETE, true))
}

const transferIfNeeded = (): void => {
  localforage.keys().then((indexDBKeys: string[]): null | void => {
    if (indexDBKeys.includes(LOCAL_STORAGE_TRANSFER_COMPLETE)) {
      return null
    } else {
      transferFromLocalStorage(indexDBKeys)
    }
  })
}
if (!__TEST__) {
  transferIfNeeded()
}
