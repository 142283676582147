import { Map, Record } from 'immutable'
import moment from 'moment'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/re... Remove this comment to see the full error message
import OrcAPI from '~/resources/orc'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// RECORDS
export const Admission = Record({
  admissionDate: null,
  admissionDiagnoses: null,
  admissionType: null,
  aspireContactedPrior: null,
  aspireContactedPriorNoReason: null,
  authNumber: null,
  avoidabilityComments: null,
  avoidabilityReasons: null,
  caseNumber: null,
  deleted: null,
  dischargeDate: null,
  dischargeDiagnoses: null,
  dischargedTo: null,
  dischargedToOther: null,
  hospital: null,
  hospitalId: null,
  id: null,
  includedObservationStay: null,
  mdReviewStatus: null,
  notes: null,
  notifiedBy: null,
  notifiedByOther: null,
  otherReason: null,
  patientId: null,
  reason: null,
  status: null,
  wasAvoidable: null,
  deletedBy: null,
  deletedDate: null,
  deletionReason: null,
})

// CONSTANTS
export const ADMISSIONS_STORED = 'admissions/ADMISSIONS_STORED'
export const ADMISSION_FETCH_FAILED = 'admissions/ADMISSION_FETCH_FAILED'
export const ADMISSION_DELETED_SET = 'admissions/ADMISSION_DELETED_SET'
export const ADMISSION_DELETED_SET_FAILED =
  'admissions/ADMISSION_DELETED_SET_FAILED'

// ACTION CREATORS
export const admissionsStored = (admissions: any) => ({
  type: ADMISSIONS_STORED,
  admissions,
})
export const admissionFetchFailed = () => ({ type: ADMISSION_FETCH_FAILED })
export const admissionDeletedSet = (
  admissionId: any,
  deleted: any,
  reason: any
) => ({
  type: ADMISSION_DELETED_SET,
  admissionId,
  deleted,
  reason,
})
export const admissionDeletedSetFailed = () => ({
  type: ADMISSION_DELETED_SET_FAILED,
})

// KEY
export const key = 'admissions'

// REDUCER
export default createReducer(key, Map(), {
  [ADMISSIONS_STORED]: (state: any, { admissions }: any) =>
    state.mergeDeep(admissions),
  [ADMISSION_DELETED_SET]: (
    state: any,
    { admissionId, deleted, reason }: any
  ) =>
    state
      .setIn([admissionId, 'deleted'], deleted)
      .setIn([admissionId, 'deletionReason'], reason)
      .setIn([admissionId, 'deletedDate'], moment.utc().format())
      .setIn([admissionId, 'deletedBy'], 'You'),
})

const mapData = (data: any) =>
  Map(
    data.map((d: any) => [
      d.caseId,
      Admission({
        admissionDate: d.hospitalAdmitDate,
        admissionDiagnoses: d.admissionDiagnoses,
        admissionType: d.bedType,
        aspireContactedPrior: d.aspireContactedPrior,
        aspireContactedPriorNoReason: d.aspireContactedPriorNo,
        authNumber: d.authorizationNumber,
        avoidabilityComments: d.avoidabilityComments,
        avoidabilityReasons: d.avoidabilityReasons,
        caseNumber: d.caseNumber,
        deleted: d.softDeleted,
        dischargeDate: d.hospitalDischargeDate,
        dischargeDiagnoses: d.dischargeDiagnoses,
        dischargedTo: d.dischargedFromHospitalTo,
        dischargedToOther: d.dischargedToOther,
        hospital: d.hospital,
        hospitalId: d.hospitalId,
        id: d.caseId,
        includedObservationStay: d.includedObservationStay,
        mdReviewStatus: d.reviewStatus,
        notes: d.hospitalizationNotes,
        notifiedBy: d.notifiedBy,
        notifiedByOther: d.notifiedByOther,
        otherReason: d.otherHospitalizationReason,
        patientId: d.patientId,
        reason: d.reason,
        status: d.status,
        wasAvoidable: d.wasHospitalizationAvoidable,
        deletedBy: d.deletedBy,
        deletedDate: d.deletedDate,
        deletionReason: d.deletionReason,
      }),
    ])
  )

// API
export const fetchAdmissions = (contactId: any) =>
  OrcAPI.get(`emr_admissions/${contactId}`).then(mapData)

export const patchDeleted = (admissionId: any, deleted: any, reason: any) =>
  OrcAPI.patch(`emr_admissions/${admissionId}`, {
    softDelete: deleted,
    deletionReason: reason,
  })

// SELECTORS
export const getAllAdmissions = (state: any) =>
  getAdmissionsReconciliation(state).get('admissions')
export const getAdmissionById = (state: any, id: any) =>
  getAllAdmissions(state).get(id)
export const isAdmissionDeleted = (state: any, admissionId: any) =>
  getAllAdmissions(state).getIn([admissionId, 'deleted'])
