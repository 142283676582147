import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getOrderKey } from './common/shared'

export const key = 'orderDialog'

export const DIALOG_OPENED = type(key, 'DIALOG_OPENED')
export const DIALOG_CLOSED = type(key, 'DIALOG_CLOSED')
export const NEW_ORDER_DIALOG_OPENED = type(key, 'NEW_ORDER_DIALOG_OPENED')

export const dialogOpened = creator(DIALOG_OPENED, 'type')
export const dialogClosed = creator(DIALOG_CLOSED)
export const newOrderDialogOpened = creator(
  NEW_ORDER_DIALOG_OPENED,
  'patientId'
)

export const NEW_ORDER = 'newOrder'

export const OrderDialog = Record({
  open: false,
  type: null,
})

const init = () => OrderDialog()

export default createReducer(key, init(), {
  [DIALOG_OPENED]: (state, { payload: { type } }) =>
    state.merge({ open: true, type }),
  [DIALOG_CLOSED]: init,
})

const getDialogDetails = pipe(getOrderKey, get(key))
export const getOrderDialogType = state => getDialogDetails(state).get('type')
