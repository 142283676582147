import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { b64toBlob } from '../../../utils/data'
import { getAttachmentDocumentById } from '../data/fetchedDocument'

export default action$ =>
  action$.pipe(
    ofType(getAttachmentDocumentById.SUCCEEDED),
    tap(({ payload }) => {
      const blob = b64toBlob(payload.docContent, payload.fileType)

      const url = window.URL.createObjectURL(blob)
      window.open(url)

      // Cleanup the url after a minute to avoid memory leaks over time
      setTimeout(() => window.URL.revokeObjectURL(url), 1000 * 60)
    }),
    ignoreElements()
  )
