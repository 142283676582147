import React from 'react'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  section: {
    display: 'flex',
    flex: '1',
    '&:nth-child(2n)': {
      backgroundColor: 'rgba(127, 127, 127, 0.25)',
    },
  },
})

const DayWrapperStriped = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.section}>{children}</div>
}

DayWrapperStriped.propTypes = {
  children: PropTypes.node,
}

export default DayWrapperStriped
