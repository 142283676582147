import React, { useState } from 'react'
import { Location } from '~/features/locationSearch/data/common/shared'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import {
  guidanceTypeChanged,
  locationChanged,
  rideAlongTypeChanged,
  subjectChanged,
  travelNotesChanged,
  visitMethodChanged,
} from '../../../data/events'
import {
  BatchSettingsField,
  CheckboxField,
  CreatedModifiedField,
  LocationField,
  RadioOptions,
  StartEndField,
  TextField,
} from '../../EventFields'

const SchedulingGuidanceEventDetail = ({ userId, event, editing }) => {
  const onGuidanceTypeChange = useAction(guidanceTypeChanged)
  const onLocationChange = useAction(locationChanged)
  const onRideAlongTypeChange = useAction(rideAlongTypeChanged)
  const onSubjectChange = useAction(subjectChanged)
  const onTravelNotesChange = useAction(travelNotesChanged)
  const onVisitMethodChange = useAction(visitMethodChanged)

  const [travelTerritory, setTravelTerritory] = useState(false)

  const toggleTravelTerritory = value => {
    if (value === true) {
      setTravelTerritory(true)
    } else {
      setTravelTerritory(false)
      onLocationChange(Location())
      onTravelNotesChange(null)
    }
  }

  const hasTravelData = Boolean(
    (event.location.latitude && event.location.longitude) || event.travelNotes
  )

  return (
    <React.Fragment>
      <RadioOptions
        allowNone
        field="scheduling_guidance_type"
        label="Guidance Type"
        onChange={onGuidanceTypeChange}
        value={event.guidanceType}
      />
      <RadioOptions
        allowNone
        disabled={event.guidanceType === 'flex'}
        field="modality"
        label="Visit Method"
        onChange={onVisitMethodChange}
        value={event.visitMethod}
      />
      <RadioOptions
        allowNone
        disabled={
          event.guidanceType === 'flex' ||
          event.visitMethod === 'telephonic' ||
          event.visitMethod === 'virtual'
        }
        field="ride_along_role"
        label="Ride Along Type"
        onChange={onRideAlongTypeChange}
        value={event.rideAlongRole}
      />
      <StartEndField event={event} editing={editing} showAllDay={false} />
      <TextField
        label="Guidance Notes"
        value={event.subject}
        editing={editing}
        onChange={onSubjectChange}
      />
      <CheckboxField
        label="Travel Territory"
        value={travelTerritory || hasTravelData}
        onChange={toggleTravelTerritory}
        editing={editing}
      />
      {(travelTerritory || hasTravelData) && (
        <React.Fragment>
          <LocationField travel value={event.location} editing={editing} />
          <TextField
            label="Travel Notes"
            value={event.travelNotes}
            editing={editing}
            onChange={onTravelNotesChange}
          />
        </React.Fragment>
      )}
      <BatchSettingsField userId={userId} event={event} editing={editing} />
      <CreatedModifiedField event={event} />
    </React.Fragment>
  )
}

SchedulingGuidanceEventDetail.propTypes = {
  userId: PropTypes.string.isRequired,
  event: PropTypes.record.isRequired,
  editing: PropTypes.bool,
}

export default SchedulingGuidanceEventDetail
