import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { fetchPatient, getPatientId } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'
import { fetchResearchCases } from '../data/researchCases'
import { sendToResearch } from '../data/sendToResearchDialog'
import {
  completeResearchCase,
  updateResearchCase,
} from '../data/updateResearchDialog'

export default (action$, state$) =>
  action$.pipe(
    ofType(
      completeResearchCase.SUCCEEDED,
      sendToResearch.SUCCEEDED,
      updateResearchCase.SUCCEEDED
    ),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId =>
      of(
        fetchPatient.requested(patientId),
        fetchResearchCases.requested(patientId)
      )
    )
  )
