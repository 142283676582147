import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    textAlign: 'center',
  },
  children: {
    padding: spacing(1),
  },
}))

const LegendHeader = ({ title, children }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Typography className={classes.title}>{title}</Typography>

      <div className={classes.children}>{children}</div>
    </React.Fragment>
  )
}

LegendHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default LegendHeader
