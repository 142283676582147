import { Map } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { Caption } from '~/components/CustomTypography'
import UserSelector from '~/components/UserSelector'
import { getPatientCareTeam } from '~/data/careTeams'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TaskDialogActions from '../components/TaskDialogActions'
import TaskOption from '../components/TaskOption'
import { appendRole, isActive } from '../utils/user'

class ReassignTaskDialog extends React.Component {
  static propTypes = {
    careTeam: PropTypes.map,
    classes: PropTypes.object.isRequired,
    dialogClosed: PropTypes.func.isRequired,
    task: PropTypes.record.isRequired,
    taskReassignRequested: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    reassignmentUserDeselected: PropTypes.func.isRequired,
    reassignmentUser: PropTypes.record.isRequired,
    reassignmentUserSelected: PropTypes.func.isRequired,
  }

  static defaultProps = {
    careTeam: Map(),
  }

  state = {
    searchForAssignee: false,
  }

  onSubmit = () => {
    const {
      task: { id: taskId },
      taskReassignRequested,
      reassignmentUser: { id, role },
    } = this.props

    taskReassignRequested(taskId, id, role)
  }

  onChange = () => {
    Boolean(this.props.reassignmentUser) &&
      this.props.reassignmentUserDeselected()
  }

  reset = () => {
    this.props.dialogClosed()
    this.props.reassignmentUserDeselected()
  }

  toggle = (_event, searchForAssignee) => {
    this.props.reassignmentUserDeselected()
    this.setState({ searchForAssignee })
  }

  selectAssignee = ({ currentTarget: { value } }) => {
    const assignee = this.props.careTeam.find(ctm => ctm.userId == value)
    this.props.reassignmentUserSelected(assignee.userId, assignee.role)
  }

  AssigneeSelect = () => {
    const { careTeam, classes, reassignmentUser } = this.props
    return (
      <FormControl className={classes.field} required>
        <InputLabel htmlFor="reassign-task-user">Assignee</InputLabel>
        <Select
          inputProps={{ id: 'reassign-task-user', name: 'user' }}
          native
          onChange={this.selectAssignee}
          value={reassignmentUser.id}
        >
          <option disabled value="" />
          {careTeam.toIndexedSeq().map(ctm => (
            <option key={ctm.role} value={ctm.userId}>
              {ctm.userName} ({ctm.roleName})
            </option>
          ))}
        </Select>
      </FormControl>
    )
  }

  render() {
    const {
      AssigneeSelect,
      onChange,
      onSubmit,
      props: {
        classes,
        open,
        reassignmentUser,
        pending,
        reassignmentUserSelected,
      },
      reset,
      state: { searchForAssignee },
      toggle,
    } = this

    return (
      <Dialog
        aria-labelledby="reassign-task-form"
        fullWidth
        onClose={reset}
        open={open}
      >
        <DialogTitle>Reassign this task?</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container>
            <TaskOption
              checked={searchForAssignee}
              inputLabel={searchForAssignee ? 'Yes' : 'No'}
              label={<Caption>Search for Assignee?</Caption>}
              onChange={toggle}
            />
          </Grid>
          {searchForAssignee ? (
            <FormControl className={classes.field} required>
              <UserSelector
                label="Assignee *"
                onChange={onChange}
                onUserSelected={(id, user) =>
                  reassignmentUserSelected(id, user.aspireRole)
                }
                userFilter={isActive}
                userMapper={appendRole}
              />
              <FormHelperText className={classes.helper}>
                Type to search
              </FormHelperText>
            </FormControl>
          ) : (
            <AssigneeSelect />
          )}
        </DialogContent>
        <TaskDialogActions
          disabled={pending || !reassignmentUser.id}
          onCancel={reset}
          onSubmit={onSubmit}
        />
      </Dialog>
    )
  }
}

const styles = ({ spacing }) => ({
  content: {
    height: 500,
    width: 600,
  },
  field: {
    marginBottom: spacing(2),
    width: '100%',
  },
  helper: {
    marginBottom: spacing(2),
  },
})

export default compose(
  connect((state, { task: { patientId } }) => ({
    careTeam: getPatientCareTeam(state, patientId),
  })),
  withStyles(styles)
)(ReassignTaskDialog)
