import { List, Record } from 'immutable'
import { countBy } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const CENSUS = 'census'

const CensusItem = Record({
  patientId: null,
  patientName: null,
  patientLastName: null,
  patientFirstName: null,
  program: null,
  appId: null,
  appName: null,
  assessmentStatus: null,
  market: null,
  channel: null,
  status: null,
  outlier: null,
  activeHospitalization: null,
  recentHospitalization: null,
  activeSnf: null,
  recentSnf: null,
  overdue: null,
  limitedPrognosis: null,
  currentDayVisit: null,
  recommendedForDischarge: null,
  dischargeRecommendationReason: null,
  recentDischarge: null,
  substatus: null,
  hotspotter: null,
  riskLevel: null,
  lastVisitDate: null,
  acpDocuments: null,
  lastCallDate: null,
  city: null,
  state: null,
  nextScheduledVisit: null,
  appNtvd: null,
  appNtvdModalityLabel: null,
  chwNtvd: null,
  chwNtvdModalityLabel: null,
  pccRnNtvd: null,
  pccRnNtvdModalityLabel: null,
  pccSwNtvd: null,
  pccSwNtvdModalityLabel: null,
  healthplan: null,
  lastSwVisitDate: null,
})

const CaseManagerCensusItem = Record({
  patientId: null,
  patientFirstName: null,
  patientLastName: null,
  program: null,
  city: null,
  state: null,
  status: null,
  substatus: null,
  metalTier: null,
  riskLevel: null,
  careTeam: null,
  caremoreCaseManager: null,
  fieldSw: null,
  communityHealthWorker: null,
  careCoordinator: null,
  clinicalOperationsManager: null,
  lastLoggedCommunication: null,
  lastLoggedCommunicationOutcome: null,
  lastLoggedCommunicationLoggedBy: null,
  hraDueDate: null,
  mostRecentHraDate: null,
  lastHraCompletedDate: null,
  lastAssessmentCompletedDate: null,
  lastCarePlanModificationDate: null,
  product: null,
  effectiveDate: null,
  termDate: null,
  lastAdmit: null,
  lastEr: null,
  cmInHospital: null,
  cmInSnf: null,
  lastSnfAdmit: null,
  lastCallDate: null,
  appNtvd: null,
  acpDocuments: null,
  empaneledCcc: null,
})

const transformPatientCensus = patients => List(patients.map(CensusItem))

const transformPatientCaseManagerCensus = patients =>
  List(patients.map(CaseManagerCensusItem))

export const fetchAPPPatientCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_APP_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get('dashboard/app_patient_census', {
      params: {
        userId,
      },
    }),
  transform: transformPatientCensus,
  messages: {
    failed: 'There was an issue fetching the patient census',
  },
})

export const fetchCDPatientCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CD_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get('dashboard/cd_patient_census', {
      params: {
        userId,
      },
    }),
  transform: transformPatientCensus,
  messages: {
    failed: 'There was an issue fetching the patient census',
  },
})

export const fetchCaseManagerCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CASE_MANAGER_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get('dashboard/case_manager_patient_census', {
      params: {
        userId,
      },
    }),
  transform: transformPatientCaseManagerCensus,
  messages: {
    failed: 'There was an issue fetching the patient census',
  },
})

export const fetchMDPatientCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_MD_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get('dashboard/md_patient_census', {
      params: {
        userId,
      },
    }),
  transform: transformPatientCensus,
  messages: {
    requested: 'Fetching patient census...',
    failed: 'There was an issue fetching the patient census',
  },
})

export const fetchCensusByUserId = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_USER_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`dashboard/patient_census_by_user/${userId}`),
  transform: transformPatientCensus,
  messages: {
    requested: 'Fetching patient census...',
    failed: 'There was an issue fetching the patient census',
  },
})

export const fetchCensusByRoleName = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CARE_TEAM_ROLE_CENSUS',
  requestParams: ['userId', 'roleName'],
  operation: (userId, roleName) =>
    AspireAPI.get(`dashboard/patient_census_by_role/${roleName}`, {
      params: {
        userId,
      },
    }),
  transform: transformPatientCensus,
  messages: {
    requested: 'Fetching patient census...',
    failed: 'There was an issue fetching the patient census',
  },
})

const initState = List()

export default createReducer(CENSUS, initState, {
  [fetchAPPPatientCensus.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchCDPatientCensus.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchCaseManagerCensus.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchMDPatientCensus.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchCensusByRoleName.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchCensusByUserId.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchAPPPatientCensus.REQUESTED]: () => initState,
  [fetchCDPatientCensus.REQUESTED]: () => initState,
  [fetchMDPatientCensus.REQUESTED]: () => initState,
  [fetchCensusByRoleName.REQUESTED]: () => initState,
  [fetchCensusByUserId.REQUESTED]: () => initState,
})

export const getCensus = pipe(getRoot, get(CENSUS))

const getCount = (counts, key) => counts[key] || 0

export const getCounts = createSelector([getCensus], patients => {
  const patientsJs = patients.toJS()
  const statusCounts = countBy(patientsJs, 'status')
  const limitedPrognosisCount = countBy(patientsJs, 'limitedPrognosis')
  const activeHospitalizationCount = countBy(
    patientsJs,
    'activeHospitalization'
  )
  const recentHospitalizationCount = countBy(
    patientsJs,
    'recentHospitalization'
  )
  const activeSnfCount = countBy(patientsJs, 'activeSnf')
  const recentSnfCount = countBy(patientsJs, 'recentSnf')
  const overdueCount = countBy(patientsJs, 'overdue')
  const hotspotterCount = countBy(patientsJs, 'hotspotter')
  const recommendedForDischargeCount = countBy(
    patientsJs,
    'recommendedForDischarge'
  )
  const acpDocumentsCount = countBy(patientsJs, 'acpDocuments')
  const assessmentStatusCount = countBy(patientsJs, 'assessmentStatus')

  return {
    active: getCount(statusCounts, 'Active'),
    referred: getCount(statusCounts, 'Referred'),
    assessmentStatus:
      getCount(assessmentStatusCount, 'in_progress') +
      getCount(assessmentStatusCount, 'created'),
    scheduled: getCount(statusCounts, 'Scheduled'),
    discharged: getCount(statusCounts, 'Discharged'),
    limitedPrognosis: getCount(limitedPrognosisCount, true),
    activeHospitalization: getCount(activeHospitalizationCount, true),
    recentHospitalization: getCount(recentHospitalizationCount, true),
    activeSnf: getCount(activeSnfCount, true),
    recentSnf: getCount(recentSnfCount, true),
    overdue: getCount(overdueCount, true),
    hotspotter: getCount(hotspotterCount, true),
    recommendedForDischarge: getCount(recommendedForDischargeCount, true),
    acpDocuments: getCount(acpDocumentsCount, true),
  }
})
