import Availability from './Availability'
import IncentiveDay from './IncentiveDay'
import OnCall from './OnCall'
import SchedulingGuidance from './SchedulingGuidance'
import Unavailability from './Unavailability'
import Visit from './Visit'
import VisitPlaceholder from './VisitPlaceholder'

const eventTypes = {
  [Availability.type]: Availability,
  [SchedulingGuidance.type]: SchedulingGuidance,
  [Unavailability.type]: Unavailability,
  [IncentiveDay.type]: IncentiveDay,
  [OnCall.type]: OnCall,
  [Visit.type]: Visit,
  [VisitPlaceholder.type]: VisitPlaceholder,
}

export { eventTypes }
