import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { addLTC } from './ltcs'

// KEY
const key = 'createLtcDialog'

export const ADD_LTC = 'addLtc'
export const ADD_LTC_FROM_ACTIONS = 'addLtcFromActions'

const Dialog = Record({
  dialogType: null,
  patientId: null,
})

// CREATORS
const creator = scopedCreator(key)

export const createLtcDialogOpened = creator('CREATE_LTC_DIALOG_OPENED', [
  'dialogType',
  'patientId',
])
export const createLtcDialogClosed = creator('CREATE_LTC_DIALOG_CLOSED')

// REDUCER
export default createReducer(key, Dialog(), {
  [createLtcDialogOpened]: (_state, { payload }) => Dialog(payload),
  [createLtcDialogClosed]: () => Dialog(),
  [addLTC.SUCCEEDED]: () => Dialog(),
})

export const getDialog = pipe(getRoot, get(key))
