import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { TripValidate } from './interfaces'
import { TripValidateRec } from './validateTrip'

const VALIDATE_TRIPS = 'multipleRecurTrip'
const GENERATE_RECUR_TRIP = 'recurTrip'
const IS_VALID_RECUR_TRIP = 'IsValidRecurTrip'

// CREATORS
const creator = scopedCreator(VALIDATE_TRIPS)
export const resetRecurTripValidation = creator('RESET_RECUR_TRIP_VALIDATION')
export const setValidRecurTrip = creator('SET_VALID_TRIP')
export const setInvalidRecurTrip = creator('SET_INVALID_TRIP')

// TRANSFORMER
const mapTripValidation = (result: TripValidate) =>
  TripValidateRec({
    patientCovered: result?.patientCovered,
    editTripOverrideAuthorized: result?.editTripOverrideAuthorized,
    distanceInRange: result?.distanceInRange,
    createTripOverrideAuthorized: result?.createTripOverrideAuthorized,
    benefitConfigured: result?.benefitConfigured,
    tripMaxDistance: result?.tripMaxDistance,
    activeTrip: result?.activeTrip,
  })

// REQUEST

export const validateRecurTrips = Request({
  typePrefix: VALIDATE_TRIPS,
  typeBase: 'VALIDATE_RECUR_TRIP',
  requestParams: ['RecurTripData'],
  operation: (RecurTripData: any) =>
    AspireAPI.post(`transportation/trips/validate_recur_trip`, {
      ...RecurTripData,
    }),
  transform: mapTripValidation,
  messages: { failed: 'Failed to Validate Trips' },
})

export const generateRecurTrip = Request({
  typePrefix: GENERATE_RECUR_TRIP,
  typeBase: 'GENERATE_RECUR_TRIP',
  requestParams: ['RecurTripData'],
  operation: (RecurTripData: any) =>
    AspireAPI.post(`transportation/trips/recur`, { ...RecurTripData }),
  messages: {
    succeeded: 'Selected trip(s) recurred successfully',
    failed: 'There was an issue with Trip Recur',
  },
})

// REDUCER
export const validateRecurTrip = createReducer(VALIDATE_TRIPS, null, {
  [validateRecurTrips.REQUESTED]: () => null,
  [validateRecurTrips.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [validateRecurTrips.FAILED]: () => null,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetRecurTripValidation]: () => null,
})

export const isValidRecurTrip = createReducer(IS_VALID_RECUR_TRIP, false, {
  [validateRecurTrip.REQUESTED]: () => false,
  [validateRecurTrip.FAILED]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetRecurTripValidation]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [setValidRecurTrip]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [setInvalidRecurTrip]: () => false,
})

// SELECTORS
export const getRecurTripValidation = pipe(getRoot, get(VALIDATE_TRIPS))
export const getIsRecurTripValid = pipe(getRoot, get(IS_VALID_RECUR_TRIP))
