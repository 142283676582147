import React from 'react'
import { H5 } from '~/components/CustomTypography'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Toolbar, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ChartState } from '../../data/chart'

const styles = ({ palette: { contentText, toolbarHeaderText } }) => ({
  actions: {
    flex: 'none',
    left: 12,
    position: 'relative',
    '& span': {
      color: toolbarHeaderText.color,
    },
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
    '& h2': {
      color: contentText.primaryLabel,
    },
    '& h5': {
      color: toolbarHeaderText.color,
    },
  },
})

const VitalsToolbar = ({
  actions,
  classes,
  chart: { displayed: chartDisplayed },
}) => {
  const chartToolTipTitle = `Show ${chartDisplayed ? 'Table' : 'Chart'}`

  return (
    <Toolbar className={classes.toolbar} disableGutters>
      <div className={classes.title}>
        <H5>Patient Vitals</H5>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <React.Fragment>
          <Tooltip disableFocusListener title={chartToolTipTitle}>
            <IconButton
              aria-label={chartToolTipTitle}
              onClick={actions.chartDisplayToggled}
            >
              <Icon>{chartDisplayed ? 'table_chart' : 'timeline'}</Icon>
            </IconButton>
          </Tooltip>
        </React.Fragment>
      </div>
    </Toolbar>
  )
}

VitalsToolbar.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  chart: PropTypes.instanceOf(ChartState).isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(VitalsToolbar)
