import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import DisplayDate from '~/components/DisplayDate'
import WithTooltip from '~/components/WithTooltip'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import { createSnfDialogOpened } from '../data/createSnfDialog'
import { getSNFs, saveSNF } from '../data/snfs'
import DeleteSNF from './DeleteSNF'

const toArray = createSelector([snfs => snfs], snfs =>
  snfs.toIndexedSeq().toJS()
)

const columnExtensions = [
  { columnName: 'caseNumber', editingEnabled: false, width: 150 },
  { columnName: 'admissionDate', width: 150 },
  { columnName: 'dischargeDate', width: 175 },
  { columnName: 'createdBy', editingEnabled: false, width: 150 },
  { columnName: 'createdAt', editingEnabled: false, width: 150 },
]

const defaultSorting = [{ columnName: 'admissionDate', direction: 'desc' }]

const columns = [
  {
    name: 'caseNumber',
    title: 'Case Number',
    displayComponent: ({ value }) => value || 'Pending',
  },
  {
    name: 'admissionDate',
    title: 'Admission Date',
    displayComponent: DisplayDate,
    type: 'date',
  },
  {
    name: 'dischargeDate',
    title: 'Discharge Date',
    displayComponent: DisplayDate,
    type: 'date',
  },
  {
    name: 'facilityName',
    title: 'Facility Name',
    displayComponent: WithTooltip,
  },
  {
    name: 'notes',
    title: 'Notes',
  },
  {
    name: 'createdBy',
    title: 'Created By',
  },
  {
    name: 'createdAt',
    title: 'Created At',
    getCellValue: ({ createdAt }) =>
      moment(createdAt).format('MM/DD/YYYY hh:mmA'),
  },
]

const SNFs = ({ patientId, saveSNF, snfs, createSnfDialogOpened }) => {
  const [mode, setMode] = useState()
  const [selectedSNF, setSelectedSNF] = useState()

  const onCommitChanges = useCallback(
    ({ changed, deleted }) => {
      if (changed) {
        const [snfId, changes] = Object.entries(changed)[0]

        saveSNF(Number(snfId), { ...snfs.get(snfId), ...changes })
      }
      if (deleted) {
        setSelectedSNF(snfs.get(deleted[0]))
        setMode('delete')
      }
    },
    [snfs, setMode, setSelectedSNF]
  )

  const clearState = useCallback(() => {
    setSelectedSNF()
    setMode()
  }, [setMode, setSelectedSNF])

  const onClick = useCallback(() => {
    createSnfDialogOpened(patientId)
    setMode('add')
  }, [patientId, createSnfDialogOpened])

  return (
    <React.Fragment>
      <DevXTitleHeader
        action={onClick}
        buttonText="Add SNF Admission"
        title="SNF Admissions"
      />

      <Paper>
        <DevXGrid
          editable
          hideAdd
          onCommitChanges={onCommitChanges}
          rows={toArray(snfs)}
          columns={columns}
          columnExtensions={columnExtensions}
          sortable
          defaultSorting={defaultSorting}
          getRowId={({ id }) => id}
        />
      </Paper>

      <DeleteSNF
        open={mode === 'delete'}
        row={selectedSNF}
        onClose={clearState}
      />
    </React.Fragment>
  )
}

SNFs.propTypes = {
  patientId: PropTypes.string.isRequired,
  snfs: PropTypes.map.isRequired,
  saveSNF: PropTypes.func.isRequired,
  createSnfDialogOpened: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  snfs: getSNFs(state),
})

const mapDispatchToProps = {
  saveSNF: saveSNF.requested,
  createSnfDialogOpened,
}

export default connect(mapStateToProps, mapDispatchToProps)(SNFs)
