import React, { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ActionItem from '../components/ActionItem'
import { MedListGrid } from '../components/MedList'
import {
  discardChangesForUnsavedMeds,
  fetchPatientRx,
  getPatientUnsavedRxArray,
  getPatientUnsavedRxById,
  savePatientMedication,
} from '../data/patientRx'
import {
  ACTIVE,
  DELETED,
  INACTIVE,
  PENDING,
  RECENTLY_UPDATED,
} from '../utils/medListConstants'

const styles = ({ spacing }) => ({
  marginLeft: {
    marginLeft: spacing(2),
  },
})

const UnsavedMedList = ({
  disabled,
  discardChangesForUnsavedMeds,
  getDrugById,
  unsavedPatientRxArray,
  savePatientMedication,
  classes,
}) => {
  const [selectable, setSelectable] = useState(false)

  const toggleSelectable = useCallback(
    () => setSelectable(prevSelectable => !prevSelectable),
    [setSelectable]
  )

  const GridActionsComponent = useCallback(
    () => (
      <span>
        <ActionItem
          toolTip="Toggle Checkbox"
          icon="check_box"
          onClick={toggleSelectable}
          mdtSFAuth
        />
      </span>
    ),
    [toggleSelectable]
  )

  const DiscardChanges = useCallback(
    ({ selection }) => (
      <Button
        classes={{ root: classes.marginLeft }}
        variant="outlined"
        onClick={() => discardChangesForUnsavedMeds(selection)}
      >
        Discard Changes For Selected Meds
      </Button>
    ),
    [classes, discardChangesForUnsavedMeds]
  )

  const batchAction = useMemo(() => (selectable ? DiscardChanges : null), [
    selectable,
    DiscardChanges,
  ])

  return (
    <MedListGrid
      additionalGridOptions={{
        selectable: selectable,
        batchActions: batchAction,
      }}
      disabled={disabled}
      getMedById={getDrugById}
      GridActionsComponent={GridActionsComponent}
      medList={unsavedPatientRxArray}
      savePatientMedication={savePatientMedication}
      initialFilterState={{
        [RECENTLY_UPDATED]: true,
        [PENDING]: true,
        [ACTIVE]: true,
        [INACTIVE]: true,
        [DELETED]: true,
      }}
    />
  )
}

UnsavedMedList.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  discardChangesForUnsavedMeds: PropTypes.func.isRequired,
  getDrugById: PropTypes.func.isRequired,
  unsavedPatientRxArray: PropTypes.array,
  savePatientMedication: PropTypes.func.isRequired,
}

UnsavedMedList.defaultProps = {
  disabled: false,
  unsavedPatientRxArray: [],
}

const mapStateToProps = state => ({
  unsavedPatientRxArray: getPatientUnsavedRxArray(state),
  getDrugById: getPatientUnsavedRxById(state),
})
const mapDispatchToProps = {
  discardChangesForUnsavedMeds: discardChangesForUnsavedMeds,
  fetchPatientMedications: fetchPatientRx.requested,
  savePatientMedication: savePatientMedication,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UnsavedMedList)
