import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { CONTACT_MANAGEMENT_IPA_RECORD } from '../router'
import { getRoot } from './common/shared'

const key = 'currentIPAId'

const reducer = paramsReducer(
  CONTACT_MANAGEMENT_IPA_RECORD,
  ({ ipaId }: any) => ipaId
)

reducer.key = key

export const getCurrentIPAId = pipe(getRoot, get(key))

export default reducer
