import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { createStatusMapping, idToStatusName } from '../utils/statusMapping'
import { getRoot } from './common/shared'

const key = 'patientStatusesFilter'

export const patientStatuses = ['Active', 'Scheduled', 'Referred']

// Create an object array where each object has a name and id key
export const patientStatusFilterMappings = createStatusMapping(patientStatuses)
export const getPatientStatusFilterName = idToStatusName(
  patientStatusFilterMappings
)

export const PATIENT_STATUS_FILTER_SELECTED = type(
  rootKey,
  'PATIENT_STATUS_FILTER_SELECTED'
)

export const setSelectedPatientStatusesFilter = creator(
  PATIENT_STATUS_FILTER_SELECTED,
  'statuses'
)

export default createReducer(key, new Set(), {
  [PATIENT_STATUS_FILTER_SELECTED]: (state, { payload: { statuses } }) =>
    new Set(statuses),
})

export const getSelectedPatientStatusesFilter = pipe(getRoot, get(key))

export const getSelectedStatusFilterNames = state =>
  getSelectedPatientStatusesFilter(state).map(getPatientStatusFilterName)
