import { Map, fromJS } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { deletedSelectedPolygon, loadSavedPolygon } from './selectedPolygon'

const key = 'projectPolygons'

export const FETCHED_PROJECT_POLYGONS = type(
  rootKey,
  'FETCHED_PROJECT_POLYGONS'
)
export const PROJECT_POLYGON_UPDATED = type(rootKey, 'PROJECT_POLYGON_UPDATED')

export const loadProjectPolygons = creator(FETCHED_PROJECT_POLYGONS, 'polygons')
export const updateProjectPolygon = creator(
  PROJECT_POLYGON_UPDATED,
  'id',
  'field',
  'value'
)

export default createReducer(key, new Map(), {
  [FETCHED_PROJECT_POLYGONS]: (state, { payload: { polygons } }) =>
    normalize(polygons, { idKey: 'np_id' }),
  [loadSavedPolygon]: (state, { payload: { polygon } }) =>
    state.set(polygon.np_id, fromJS(polygon)),
  [deletedSelectedPolygon]: (state, { payload: { polygon } }) =>
    state.delete(polygon),
})

export const getProjectPolygons = pipe(getRoot, get(key))

export const fetchProjectPolygons = projectId =>
  AspireAPI.get(`polygonator/draft_projects/${projectId}/apps`)
