import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { concat, defer, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import {
  CREATE_NEW_CASE_REQUESTED,
  getReferralFormValues,
  newCaseCreated,
  newCaseId,
  saveNewReferral,
} from '../data/referrals'
import { REFERRAL_MANAGEMENT_REFERRAL_DETAIL } from '../router'

const saveReferral = (values: any) =>
  from(saveNewReferral(values)).pipe(
    map(newCaseCreated),
    catchError(showError('Error! Problem creating new referral case'))
  )

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(CREATE_NEW_CASE_REQUESTED),
    mergeMap(() => {
      const state = state$.value
      const values = getReferralFormValues(state, 'new')

      return concat(
        loading(saveReferral(values)),
        defer(() =>
          of(
            push(REFERRAL_MANAGEMENT_REFERRAL_DETAIL, {
              caseId: newCaseId(state$.value),
            })
          )
        )
      )
    })
  )
