import { format, parseISO } from 'date-fns'

export const formatDate = (date?: Date | string): string => {
  if (date instanceof Date) {
    return format(date, 'M/d/yyyy')
  } else if (typeof date === 'string') {
    const dateObj = parseISO(date)
    return format(dateObj, 'M/d/yyyy')
  } else {
    return ''
  }
}

// TODO: This function does not convert UTC datetime stamps correctly; date-fns has no UTC functions currently
export const formatDateTime = (date?: Date | string): string => {
  if (date instanceof Date) {
    return format(date, 'Pp')
  } else if (typeof date === 'string') {
    const dateObj = parseISO(date)
    return format(dateObj, 'Pp')
  } else {
    return ''
  }
}
