import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'patientFilteredSearch'

export const PATIENT_FILTER_SEARCH_CLICKED = type(
  rootKey,
  'PATIENT_FILTER_SEARCH_CLICKED'
)

export const searchFilteredPatients = creator(PATIENT_FILTER_SEARCH_CLICKED)

export const searchPatientsFiltered = pipe(getRoot, get(key))
