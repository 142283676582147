const ROOFTOP = 'ROOFTOP'
const RANGE_INTERPOLATED = 'RANGE_INTERPOLATED'
const GEOMETRIC_CENTER = 'GEOMETRIC_CENTER'

const VALID_LOCATION_TYPES = [ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER]

// Parse out response from Google Maps API
const getAddressComponent = (components, type) =>
  components.filter(component => component.types.indexOf(type) > -1).pop() || {}

const getApartment = components =>
  getAddressComponent(components, 'subpremise').long_name

const getStreetNumber = (query, components) => {
  let streetNumber = getAddressComponent(components, 'street_number').long_name

  // May not exist for GEOMETRIC_CENTER addresses
  if (!streetNumber) {
    const queryParts = query.split(' ')

    if (queryParts.length > 0 && !isNaN(queryParts[0])) {
      streetNumber = queryParts[0]
    }
  }

  return streetNumber
}

const getStreet = components =>
  getAddressComponent(components, 'route').long_name

const getCity = components =>
  getAddressComponent(components, 'locality').long_name ||
  getAddressComponent(components, 'sublocality').long_name ||
  getAddressComponent(components, 'administrative_area_level_3').long_name

const getState = components =>
  getAddressComponent(components, 'administrative_area_level_1').long_name

const getStateCode = components =>
  getAddressComponent(components, 'administrative_area_level_1').short_name

const getPostalCode = components =>
  getAddressComponent(components, 'postal_code').long_name

const getCounty = components =>
  getAddressComponent(components, 'administrative_area_level_2').long_name

const getFormattedStreet = (streetNumber, components) => {
  const street = getStreet(components)
  const apartment = getApartment(components)

  return `${streetNumber} ${street}${apartment ? ` ${apartment}` : ''}`
}

export default (google, address, query) =>
  new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const [{ address_components, formatted_address, geometry }] = results

        // GEOMETRIC_CENTER addresses may not have street numbers
        const street_number = getStreetNumber(query, address_components)

        VALID_LOCATION_TYPES.includes(geometry.location_type) && street_number
          ? resolve({
              address_components,
              formatted_address,
              apartment: getApartment(address_components),
              street_number,
              street: getStreet(address_components),
              formatted_street: getFormattedStreet(
                street_number,
                address_components
              ),
              city: getCity(address_components),
              state: getState(address_components),
              state_code: getStateCode(address_components),
              postal_code: getPostalCode(address_components),
              county: getCounty(address_components),
              latitude: geometry.location.lat(),
              longitude: geometry.location.lng(),
              precise: geometry.location_type === ROOFTOP,
            })
          : reject({
              status: 'NOT_SPECIFIC',
              message: 'Geocoded address was not specific enough.',
            })
      } else {
        reject({ status, message: `Could not find address.` })
      }
    })
  })
