import React from 'react'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette: { contentText } }) => ({
  link: {
    fontFamily: 'Roboto, sans-serif',
    color: contentText.primaryLabel,
  },
})

const Link = ({ classes, children, href }) => (
  <a
    className={classes.link}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
)

Link.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default withStyles(styles)(Link)
