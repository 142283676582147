import { get } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'redux-routable-react'
import UserSelector from '~/components/UserSelector'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import stateCodes from '~/utils/validation/stateCodes'
import {
  Button,
  FormControl,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { addCareTeam, getRoles, getTypes } from '../data/careTeams'
import { CARE_TEAM_MANAGEMENT } from '../routes'
import CTMembers from './CTMembers'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
}

const styles = ({ spacing }) => ({
  button: {
    marginBottom: spacing(1),
    marginRight: spacing(1),
  },
  bottomButton: {
    marginBottom: spacing(1),
    marginRight: spacing(1),
    marginTop: spacing(1),
  },
})

const CTNewMember = ({ addCareTeamMember, members, classes }) => {
  const [role, setRole] = useState('')
  const [user, setUser] = useState(undefined)
  const [open, setOpen] = useState(false)

  const [submitting, setSubmitting] = useState(false)
  const roles = useSelector(getRoles)

  const rolesArray = useMemo(() => roles.toList().toJS(), [roles])

  const onUserSelected = (id, record) => {
    setUser({ id, name: record.name })
  }

  const onClose = () => {
    setUser(undefined)
    setRole('')
    setOpen(false)
    setSubmitting(false)
  }

  const requestAddCareTeamMember = () => {
    setSubmitting(true)
    addCareTeamMember(role, user)
    onClose()
  }

  const existingRoles = members.map(member => member.role)

  const openRoles = rolesArray.filter(role => !existingRoles.includes(role.key))

  return (
    <React.Fragment>
      <Button
        className={classes.bottomButton}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Add Care Team Member
      </Button>
      <ActionDialog
        open={open}
        title="Add Care Team Member"
        mode="add"
        onAdd={requestAddCareTeamMember}
        onClose={onClose}
        disableAction={!role || !user || submitting}
        maxWidth="md"
        fullWidth
      >
        <FormControl fullWidth>
          <InputLabel htmlFor="role">Role</InputLabel>
          <Select
            inputProps
            fullWidth
            label={'Role'}
            value={role || ''}
            input={<Input id="role" />}
            onChange={e => setRole(e.target.value)}
            MenuProps={MenuProps}
          >
            {openRoles.map(role => (
              <MenuItem key={role.key} value={role.key}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <UserSelector onUserSelected={onUserSelected}></UserSelector>
      </ActionDialog>
    </React.Fragment>
  )
}

CTNewMember.propTypes = {
  addCareTeamMember: PropTypes.func,
  members: PropTypes.array,
  classes: PropTypes.object,
}

const validateNewCareTeam = (members, selectedType, label, types) => {
  const hasLabel = !!label
  const hasSelectedType = !!selectedType
  let hasAllRequiredMembers = true

  if (hasSelectedType) {
    types.get(selectedType).requiredRoles.forEach(role => {
      if (!get(members, `[${role}].userId`)) {
        hasAllRequiredMembers = false
      }
    })
  }

  return hasLabel && hasSelectedType && hasAllRequiredMembers
}

const NewCareTeam = ({ classes }) => {
  const [selectedType, setSelectedType] = useState('')
  const [members, setMembers] = useState({})
  const [label, setLabel] = useState('')
  const [validStateCodes, setValidStateCodes] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const types = useSelector(getTypes)
  const dispatch = useDispatch()

  const membersArray = useMemo(() => Object.values(members), [members])

  const onSubmit = () => {
    setSubmitting(true)
    dispatch(
      addCareTeam.requested(label, selectedType, membersArray, validStateCodes)
    )
  }

  const selectCareTeamType = type => {
    setSelectedType(type)

    if (type) {
      const defaultMembers = {}
      types.get(type).requiredRoles.forEach(role => {
        defaultMembers[role] = {
          role,
          userId: null,
          user: { name: 'Not Yet Selected' },
        }
      })
      setMembers(defaultMembers)
    } else {
      setMembers([])
    }
  }

  const addCareTeamMember = (role, user) => {
    const next = { ...members }
    next[role] = { role, userId: user.id, user }
    return setMembers(next)
  }

  const typesArray = useMemo(() => types.toList().toJS(), [types])

  const onChangeCareTeamMember = (role, userId, name) => {
    const next = { ...members }
    next[role] = { role, userId, user: { name } }
    setMembers(next)
  }

  const onDeleteCareTeamMember = role => {
    const next = { ...members }
    delete next[role]
    setMembers(next)
  }

  const valid = useMemo(
    () => validateNewCareTeam(members, selectedType, label, types),
    [members, selectedType, label, types]
  )

  return (
    <React.Fragment>
      <Tooltip title={'Back to Care Team Management'}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={Link}
          route={CARE_TEAM_MANAGEMENT}
        >
          <Icon className={classes.icon}>arrow_back</Icon> Back
        </Button>
      </Tooltip>
      <Typography variant="h5"> New Care Team</Typography>
      <TextField
        fullWidth
        id="standard-required"
        label="Care Team Title"
        margin="normal"
        value={label}
        onChange={e => setLabel(e.target.value)}
      />
      <FormControl fullWidth>
        <InputLabel htmlFor="ctType">Care Team Type</InputLabel>
        <Select
          fullWidth
          label={'Type'}
          value={selectedType}
          onChange={e => selectCareTeamType(e.target.value)}
          MenuProps={MenuProps}
          input={<Input id="ctType" />}
        >
          <MenuItem key="nullOption" value={''}>
            Not Selected
          </MenuItem>
          {typesArray.map(types => (
            <MenuItem key={types.key} value={types.key}>
              {types.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="validStates">Valid State Codes</InputLabel>
        <Select
          fullWidth
          label="Valid States"
          multiple
          value={validStateCodes || []}
          onChange={e => setValidStateCodes(e.target.value)}
          input={<Input id="validStates" />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {stateCodes.map(code => (
            <MenuItem key={code} value={code}>
              {code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CTMembers
        members={membersArray}
        onChangeCareTeamMember={onChangeCareTeamMember}
        onDeleteCareTeamMember={onDeleteCareTeamMember}
        careTeamType={selectedType}
        editMode
      />
      <CTNewMember
        classes={classes}
        addCareTeamMember={addCareTeamMember}
        members={membersArray}
      />
      <Button
        classNames={classes.bottomButton}
        onClick={onSubmit}
        variant="contained"
        color="primary"
        disabled={!valid || submitting}
      >
        Submit
      </Button>
    </React.Fragment>
  )
}

NewCareTeam.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(NewCareTeam)
