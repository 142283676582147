import { ofType } from 'redux-observable'
import { distinctUntilChanged, map, pluck } from 'rxjs/operators'
import { clearPatientMedications, fetchPatientRx } from '../data/patientRx'

export default action$ =>
  action$.pipe(
    ofType(fetchPatientRx.REQUESTED),
    pluck('payload', 'patientId'),
    distinctUntilChanged(),
    map(clearPatientMedications)
  )
