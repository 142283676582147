import { combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import adminTools from '~/apps/adminTools/epic'
import admissionsReconciliation from '~/apps/admissionsReconciliation/epic'
import appDashboard from '~/apps/appDashboard/epic'
import assessment from '~/apps/assessment/epic'
import caseManagerDashboard from '~/apps/caseManagerDashboard/epic'
import cdDashboard from '~/apps/clinicalDirectorDashboard/epic'
import { default as coderDashboard } from '~/apps/coderDashboard/epic'
import communityHWDashboard from '~/apps/communityHWDashboard/epic'
import contactManagement from '~/apps/contactManagement/epic'
import cssDashboard from '~/apps/cssDashboard/epic'
import dashboard from '~/apps/dashboard/epic'
import formsDevelopment from '~/apps/formsDevelopment/epic'
import iocDashboard from '~/apps/iocDashboard/epic'
import nurseDashboard from '~/apps/nurseDashboard/epic'
import patientRecord from '~/apps/patientRecord/epic'
import pesDashboard from '~/apps/pesDashboard/epic'
import pharmacistDashboard from '~/apps/pharmacistDashboard/epic'
import physicianDashboard from '~/apps/physicianDashboard/epic'
import polygonator from '~/apps/polygonator/epic'
import referralManagement from '~/apps/referralManagement/epic'
import reportsDashboard from '~/apps/reportsDashboard/epic'
import swDashboard from '~/apps/swDashboard/epic'
import transportationDashboard from '~/apps/transportation/epic'
import userSettings from '~/apps/userSettings/epic'
import { epic as action$Proxy } from '~/components/JsonForm/fields/SaveableObjectField'
import { epicCrashed } from '~/data/epicError'
import { epic as fieldValues } from '~/data/fieldValues'
import { epic as healthplansEpic } from '~/data/healthplans'
import { epic as marketsEpic } from '~/data/markets'
import { epic as userRolesEpic } from '~/data/userRoles'
import connectivity from '~/epics/connectivity'
import errorLogger from '~/epics/errorLogger'
import keycloakSession from '~/epics/keycloakSession'
import loggedIn from '~/epics/loggedIn'
import patientPanelOpened from '~/epics/patientPanelOpened'
import toggleLights from '~/epics/toggleLights'
import unauthenticatedRequest from '~/epics/unauthenticatedRequest'
import usersEpic from '~/epics/users'
import { default as actionsAndLinks } from '~/features/actionsAndLinks/epic'
import { epic as addTimelineEntry } from '~/features/addTimelineEntry'
import { default as appFinder } from '~/features/appFinder/epic'
import { epic as attachments } from '~/features/attachments'
import { epic as attachmentsNextGen } from '~/features/attachmentsNextGen'
import { epic as authorization } from '~/features/authorization'
import { epic as calendarWidgetEpic } from '~/features/calendarWidget'
import { epic as callLogger } from '~/features/callLogger'
import { epic as call } from '~/features/calls'
import carePlan from '~/features/carePlan/epic'
import { epic as careTeamManagement } from '~/features/careTeamManagement'
import { epic as logCommunication } from '~/features/caremoreLogCommunication'
import { epic as consentForServices } from '~/features/consentForServices'
import { default as dischargeEpic } from '~/features/discharges/epic'
import { epic as encounterWidget } from '~/features/encounterWidget'
import { epic as episodesOfCare } from '~/features/episodesOfCare/epic'
import { epic as generateDocumentForm } from '~/features/generateDocument'
import highRiskHuddle from '~/features/highRiskHuddle/epic'
import { epic as hospitalizations } from '~/features/hospitalizations'
import { epic as imo } from '~/features/imo'
import { epic as insuranceManagement } from '~/features/insuranceManagement'
import { epic as medications } from '~/features/medications'
import { epic as nextTargetedVisitDate } from '~/features/nextTargetedVisitDate'
import { epic as notifications } from '~/features/notifications'
import { epic as openGaps } from '~/features/openGaps'
import { epic as orders } from '~/features/orders'
import { default as panelContentEpic } from '~/features/panelContent/epic'
import { epic as alerts } from '~/features/patientAlerts'
import { epic as patientCensus } from '~/features/patientCensus'
import { epic as patientInfo } from '~/features/patientInfo'
import { default as patientTimeline } from '~/features/patientTimeline/epics'
import { epic as physicianSearch } from '~/features/physicianSearch'
import { default as problems } from '~/features/problems/epic'
import { default as quickCallLogger } from '~/features/quickCallLogger/epic'
import { epic as research } from '~/features/research'
import { default as scheduling } from '~/features/scheduling/epic'
import { epic as search } from '~/features/search'
import { epic as stakeholders } from '~/features/stakeholders'
import { epic as tasks } from '~/features/tasks'
import { epic as unengaged } from '~/features/unengaged'
import { overrideUserIdEpic } from '~/features/userOverride'
import { epic as vendorSearch } from '~/features/vendorSearch'
import { epic as videoVisitConsent } from '~/features/videoVisitConsent'
import { epic as vitals } from '~/features/vitals'
import { epic as requestable } from '~/utils/Requestable'

// Please play nice and alphabetize your epics
export default (...args) =>
  combineEpics(
    action$Proxy,
    actionsAndLinks,
    addTimelineEntry,
    adminTools,
    admissionsReconciliation,
    alerts,
    appDashboard,
    appFinder,
    assessment,
    attachments,
    attachmentsNextGen,
    authorization,
    calendarWidgetEpic,
    call,
    callLogger,
    careTeamManagement,
    carePlan,
    caseManagerDashboard,
    cdDashboard,
    coderDashboard,
    communityHWDashboard,
    connectivity,
    consentForServices,
    contactManagement,
    dischargeEpic,
    cssDashboard,
    dashboard,
    encounterWidget,
    episodesOfCare,
    errorLogger,
    fieldValues,
    formsDevelopment,
    generateDocumentForm,
    healthplansEpic,
    highRiskHuddle,
    hospitalizations,
    insuranceManagement,
    imo,
    iocDashboard,
    keycloakSession,
    loggedIn,
    logCommunication,
    marketsEpic,
    medications,
    nextTargetedVisitDate,
    notifications,
    nurseDashboard,
    openGaps,
    orders,
    overrideUserIdEpic,
    panelContentEpic,
    patientCensus,
    patientInfo,
    patientPanelOpened,
    patientRecord,
    patientTimeline,
    pesDashboard,
    pharmacistDashboard,
    physicianDashboard,
    physicianSearch,
    polygonator,
    problems,
    quickCallLogger,
    referralManagement,
    reportsDashboard,
    research,
    requestable,
    scheduling,
    search,
    stakeholders,
    swDashboard,
    tasks,
    toggleLights,
    transportationDashboard,
    unauthenticatedRequest,
    unengaged,
    userRolesEpic,
    usersEpic,
    userSettings,
    vendorSearch,
    videoVisitConsent,
    vitals
  )(...args).pipe(catchError(error => of(epicCrashed(error))))
