import React from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { getUserId } from '~/data/session'
import { useAction, useInput, useToggle, useUpdateEffect } from '~/hooks'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  createDialogReminder,
  deleteDialogReminder,
  getReminderTaskDialog,
  reminderDialogClosed,
  saveDialogReminder,
} from '../../../data/reminderTaskDialog'

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    display: 'block',
    marginBottom: spacing(1),
  },
}))

const ReminderDialog = () => {
  const classes = useStyles()

  const userId = useSelector(getUserId)
  const reminder = useSelector(getReminderTaskDialog)
  const closeReminderDialog = useAction(reminderDialogClosed)
  const createDialogReminderRequested = useAction(
    createDialogReminder.requested
  )
  const saveDialogReminderRequested = useAction(saveDialogReminder.requested)
  const deleteDialogReminderRequested = useAction(
    deleteDialogReminder.requested
  )

  const [deleteOpen, openDeleteDialog, closeDeleteDialog] = useToggle()
  const [description, setDescription] = useInput(reminder.description)
  const [dueDate, setDueDate] = useInput(reminder.dueDate)

  const onSave = () => {
    const operation = reminder.id
      ? saveDialogReminderRequested
      : createDialogReminderRequested
    const newReminder = reminder.merge({ description, dueDate })

    operation(userId, newReminder)
    closeReminderDialog()
  }

  const onDelete = () => {
    deleteDialogReminderRequested(reminder.id)
    closeReminderDialog()
    closeDeleteDialog()
  }

  useUpdateEffect(() => {
    setDescription(reminder.description)
    setDueDate(reminder.dueDate)
  }, [reminder, setDescription, setDueDate])

  const reminderExists = Boolean(reminder.id)
  const pendingChanges =
    description !== reminder.description || dueDate !== reminder.dueDate
  const valid = description && dueDate

  return (
    <React.Fragment>
      <Dialog open={reminder.open} maxWidth="md" fullWidth>
        <DialogTitle>{`${
          reminderExists ? 'Edit' : 'Add'
        } Reminder`}</DialogTitle>

        <DialogContent>
          <TextField
            className={classes.textField}
            label="Description"
            multiline
            rowsMax={5}
            fullWidth
            onChange={setDescription}
            value={description}
          />

          <TextField
            className={classes.textField}
            label="Due Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={setDueDate}
            value={dueDate}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={closeReminderDialog}>Close</Button>

          {reminderExists && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={openDeleteDialog}
            >
              Delete
            </Button>
          )}

          <Button
            color="primary"
            variant="contained"
            disabled={!pendingChanges || !valid}
            onClick={onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ActionDialog
        open={deleteOpen}
        mode="delete"
        title="Delete Reminder"
        onDelete={onDelete}
        onClose={closeDeleteDialog}
      >
        <Typography>Are you sure you want to delete this reminder?</Typography>
      </ActionDialog>
    </React.Fragment>
  )
}

export default ReminderDialog
