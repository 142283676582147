import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import {
  PATIENT_EXISTING_REFERRALS_FETCH_REQUESTED,
  existingReferralCaseLookup,
  patientReferralsStored,
} from '../data/referrals'

const fetchPatientReferrals = (patientId: any) =>
  from(existingReferralCaseLookup(patientId)).pipe(
    map(patientReferralsStored),
    catchError(showError('Error! Unable to load patient referrals'))
  )

export default (action$: any) =>
  action$.pipe(
    ofType(PATIENT_EXISTING_REFERRALS_FETCH_REQUESTED),
    pluck('payload'),
    mergeMap(payload =>
      loading(
        concat(of(patientReferralsStored(null)), fetchPatientReferrals(payload))
      )
    )
  )
