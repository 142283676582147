import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import rootKey from '../key'
import { getRoot } from './common/shared'

export const key = `carePlan`

export const fetchCarePlansRequiringAction = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_USER_CARE_PLANS_RA',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/care_plan/hc_goal/user/${userId}/requiring_action`),
})

const initState = {
  requiringAction: [],
}

const set = key => (state, { payload }) => ({ ...state, [key]: payload })

export default createReducer(key, initState, {
  [fetchCarePlansRequiringAction.SUCCEEDED]: set('requiringAction'),
})

const getCarePlans = state => getRoot(state).get(key)

export const getCarePlansRequiringAction = state =>
  getCarePlans(state).requiringAction
