import { ofType } from 'redux-observable'
import { filter, map } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import { saveAssessment } from '../data/assessments'
import { canEdit, getCurrentAssessment } from '../data/common/derived'
import { ASSESSMENT_SAVE_BUTTON_CLICKED } from '../data/saveInfo'

export default (action$, state$) =>
  action$.pipe(
    ofType(ASSESSMENT_SAVE_BUTTON_CLICKED),
    switchTo(state$),
    filter(state => canEdit(state)),
    map(getCurrentAssessment),
    map(saveAssessment.requested)
  )
