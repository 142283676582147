import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { fetchUsers } from '~/data/users'
import { changeCareTeamMember } from '../data/careTeams'

export default action$ =>
  action$.pipe(
    ofType(changeCareTeamMember.SUCCEEDED),
    map(fetchUsers.requested)
  )
