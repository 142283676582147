import { combineEpics } from 'redux-observable'
import appInitialization from './epics/appInitialization'
import discard from './epics/discard'
import merge from './epics/merge'
import mergeInitialization from './epics/mergeInitialization'
import recycle from './epics/recycle'
import recycleSearch from './epics/recycleSearch'
import unmerge from './epics/unmerge'
import unmergeSearch from './epics/unmergeSearch'

export default combineEpics(
  appInitialization,
  discard,
  merge,
  mergeInitialization,
  recycle,
  recycleSearch,
  unmerge,
  unmergeSearch
)
