import moment from 'moment'
import React from 'react'
import PropTypes from '~/utils/propTypes'

const DisplayUTCTimestampAsLocalDate = ({ value }) => (
  <span>{value && moment.utc(value).local().format('M/DD/YYYY')}</span>
)

DisplayUTCTimestampAsLocalDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
}

export default DisplayUTCTimestampAsLocalDate
