import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'redux-routable-react'
import PropTypes from '~/utils/propTypes'
import { Button, Icon, Tooltip, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getCurrentCareTeamDetails } from '../data/careTeams'
import { CARE_TEAM_MANAGEMENT } from '../routes'
import CTDetail from './CTDetail'

const styles = ({ spacing }) => ({
  button: {
    marginBottom: spacing(1),
    marginRight: spacing(1),
  },
  icon: {
    marginRight: spacing(1),
  },
})

const CareTeamDetailPage = ({ classes }) => {
  const careTeamDetails = useSelector(getCurrentCareTeamDetails)

  return (
    <React.Fragment>
      <Tooltip title={'Back to Care Team Management'}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={Link}
          route={CARE_TEAM_MANAGEMENT}
        >
          <Icon className={classes.icon}>arrow_back</Icon> Back
        </Button>
      </Tooltip>
      <Typography variant="h5"> Care Team Detail</Typography>
      {!careTeamDetails ? (
        <Typography variant="body1"> Loading...</Typography>
      ) : (
        <CTDetail careTeamDetails={careTeamDetails} />
      )}
    </React.Fragment>
  )
}

CareTeamDetailPage.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(CareTeamDetailPage)
