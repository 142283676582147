import { paramsReducer } from 'redux-routable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
import { type } from '~/utils/data'
import rootKey from '../key'
import router from '../router'
import { getRoot } from './common/shared'

// KEY
const key = 'nurseDashboardUserId'

// ACTIONS
export const DASHBOARD_REFRESH_REQUESTED = type(
  rootKey,
  'DASHBOARD_REFRESH_REQUESTED'
)

// ACTION CREATORS
export const nurseDashRefreshRequested = (userId: any) => ({
  type: DASHBOARD_REFRESH_REQUESTED,
  payload: { userId },
})

// REDUCER
const reducer = paramsReducer(router, ({ userId }: any) => userId || null)

reducer.key = key

// SELECTORS
export const getSelectedUserId = (state: any) =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(key) || getUserId(state)

export default reducer
