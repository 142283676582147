import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Directions } from '~/components/GeoMap'
import PropTypes from '~/utils/propTypes'
import { colors } from '@material-ui/core'
import { getGeocodedEvents } from '../../data/common/derived'

const EventDirections = ({ app: { lat, lng }, ...props }) => {
  const geocodedEvents = useSelector(getGeocodedEvents)

  // Filter remote events from being included in the waypoint directions
  const waypointedEvents = useMemo(
    () =>
      geocodedEvents
        .filter(event => {
          return event.assessmentType === 'in_person'
        })
        .toIndexedSeq()
        .toJS(),
    [geocodedEvents]
  )

  const eventRoutes = useMemo(() => {
    const appLocation = lat && lng ? { lat, lng } : null

    return {
      origin: appLocation,
      destination: appLocation,
      waypoints: waypointedEvents.map(event => ({
        lat: event.location.latitude,
        lng: event.location.longitude,
      })),
    }
  })

  const onDirectionsChanged = result => {
    const METERS_IN_MILE = 1609.344
    const SECONDS_IN_MINUTE = 60

    const directions = result.routes[0].legs.map(
      ({ distance, duration }, index) => {
        const event = waypointedEvents[index]
        return {
          eventId: event?.id,
          distance: distance.text,
          distanceValue: distance.value / METERS_IN_MILE,
          duration: duration.text,
          durationValue: duration.value / SECONDS_IN_MINUTE,
        }
      }
    )

    props.onDirectionsChanged(directions)
  }

  return (
    <Directions
      {...eventRoutes}
      preserveViewport
      suppressMarkers
      strokeColor={colors.blue['500']}
      strokeOpacity={0.75}
      strokeWeight={5}
      onDirectionsChanged={onDirectionsChanged}
    />
  )
}

EventDirections.propTypes = {
  app: PropTypes.record.isRequired,
  onDirectionsChanged: PropTypes.func,
}

EventDirections.defaultProps = {
  onDirectionsChanged: () => {},
}

export default React.memo(EventDirections)
