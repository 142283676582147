import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'fetchChannelList'

export const getChannelList = pipe(getRoot, get(key))

export const fetchChannelList = Request({
  typePrefix: key,
  typeBase: 'FETCH_CHANNEL_LIST',
  operation: () => AspireAPI.get(`v1/channel_list`),
  messages: {
    failed: 'There was a problem fetching the channel list',
  },
  transform: data =>
    data.map(item => ({
      label: item.primaryContract,
      secondaryLabel: item.healthplan,
      value: item.healthplanCrosswalkId,
    })),
})

export default createReducer(key, null, {
  [fetchChannelList.SUCCEEDED]: (state, { payload }) => payload,
})
