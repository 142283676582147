import { format, parse } from 'date-fns'
import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

// KEY
const key = 'patientLabData'

export const PatientLabDetails = Record({
  id: null,
  observation: null,
  orderingProvider: null,
  loinc: null,
  loincDescription: null,
  observationValue: null,
  units: null,
  abnormalFlag: null,
  referenceRange: null,
  valueType: null,
  orderingProviderId: null,
  comments: null,
})

// TRANSFORM
const transformLabDetails = (data: any) =>
  data.labData && data.labData.length ? prepareLabDetails(data.labData) : List()

const prepareLabDetails = (labDetails: any) =>
  List(
    labDetails?.map((labDetail: any, index: number) =>
      PatientLabDetails({
        id: index + 1,
        observation: labDetail.observationDate
          ? format(
              parse(
                labDetail.observationDate,
                'yyyy-MM-dd HH:mm:ss.SSS',
                new Date()
              ),
              'MM/dd/yyyy'
            )
          : '',
        orderingProvider: labDetail.orderingProviderFirstName
          ? `${labDetail.orderingProviderLastName}, ${labDetail.orderingProviderFirstName}`
          : labDetail.orderingProviderLastName || '',
        loinc: labDetail.loinc || '',
        loincDescription: labDetail.loincDescription || '',
        observationValue: labDetail.observationValue || '',
        units: labDetail.units || '',
        abnormalFlag: labDetail.abnormalFlags || '',
        referenceRange: labDetail.referencesRange || '',
        valueType: labDetail.valueType || '',
        orderingProviderId: labDetail.orderingProviderId || '',
        comments: labDetail.observationComment || '',
      })
    )
  )

// REQUEST
export const fetchPatientLabDetails = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_LAB_DETAILS',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`v1/patient/${patientId}/labs`),
  transform: transformLabDetails,
  messages: { failed: 'Failed to fetch patient lab details' },
})

// REDUCER
export const patientLabDetails = createReducer(key, List(), {
  [fetchPatientLabDetails.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

// SELECTORS
export const getPatientLabDetails = pipe(getRoot, get(key))
