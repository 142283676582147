import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  columns: {
    display: 'flex',

    [breakpoints.up('xs')]: {
      flexDirection: 'column',
      '& div:first-child > div > h6': {
        marginTop: 0,
      },
      '& > *:not(:first-child)': {
        paddingTop: spacing(4),
      },
    },

    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& div > div > h6': {
        marginTop: 0,
      },
      '& > *:not(:first-child)': {
        paddingTop: 0,
      },
      '& > *:not(:last-child)': {
        marginRight: spacing(4),
      },
    },
  },
}))

type Props = {
  children: React.ReactNode
}

export default function Columns({ children }: Props) {
  const classes = useStyles()

  return <div className={classes.columns}>{children}</div>
}
