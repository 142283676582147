import React from 'react'
import { connect } from 'react-redux'
import { panelClosed } from '~/data/patientPanel'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import NewTaskButton from '../components/NewTaskButton'
import { NEW_TASK } from '../constants'
import { dialogOpened } from '../data/dialog'
import PatientTasksDashboard from './PatientTasksDashboard'
import TaskDetails from './TaskDetails'
import UserTasksDashboard from './UserTasksDashboard'

const styles = ({ spacing, zIndex: { drawer } }) => ({
  button: {
    bottom: spacing(5),
    position: 'fixed',
    right: spacing(5),
    zIndex: drawer + 1,
  },
  root: {
    marginBottom: 125,
  },
})

class Tasks extends React.PureComponent {
  componentWillUnmount() {
    this.props.panelClosed()
  }

  openNewTaskDialog = () => {
    this.props.dialogOpened(NEW_TASK, this.props.patientId)
  }

  render() {
    const { classes, Page, route, tasksContext, routeContext } = this.props

    return (
      <div className={classes.root}>
        <Page
          route={route}
          tasksContext={tasksContext}
          routeContext={routeContext}
        />
        <NewTaskButton
          className={classes.button}
          onClick={this.openNewTaskDialog}
        />
      </div>
    )
  }
}

Tasks.propTypes = {
  classes: PropTypes.object.isRequired,
  Page: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  tasksContext: PropTypes.string,
  dialogOpened: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  routeContext: PropTypes.object,
  panelClosed: PropTypes.func.isRequired,
}

Tasks.defaultProps = {
  tasksContext: 'user',
}

const TasksPage = connect(
  (state, { routeContext = {} }) => ({
    patientId: routeContext.key === 'patientId' ? routeContext.value : null,
  }),
  {
    dialogOpened,
    panelClosed,
  }
)(withStyles(styles)(Tasks))

export const TasksByUserPage = props => (
  <TasksPage {...props} Page={UserTasksDashboard} />
)

export const TasksByPatientPage = props => (
  <TasksPage {...props} Page={PatientTasksDashboard} />
)

export const TaskDetailPage = props => (
  <TasksPage {...props} Page={TaskDetails} />
)
