import PropTypes from 'prop-types'
import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from './FormControlLabel'
import memoizedWrapper from './memoizedWrapper'
import { getBaseProps } from './props'

const getValue = (e, checked) => checked

const styles = ({ spacing }) => ({
  checkbox: {
    marginTop: -spacing(1),
    marginBottom: -spacing(1),
  },
})

const CheckboxWidget = ({ classes, ...props }) => (
  <FormControlLabel
    {...getBaseProps({ ...props, getValue })}
    control={
      <Checkbox
        className={classes.checkbox}
        id={props.id}
        checked={props.value}
      />
    }
  />
)

CheckboxWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
}

CheckboxWidget.defaultProps = {
  value: false,
}

export default compose(memoizedWrapper, withStyles(styles))(CheckboxWidget)
