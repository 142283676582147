import { get } from 'lodash'
import createReducer from '~/utils/createReducer'
import assessments from '../assessments'
import sectionsInfo from '../sectionsInfo'
import { FORM_DATA_CHANGED_BY_TAG } from './shared'

export default createReducer('common', undefined, {
  [FORM_DATA_CHANGED_BY_TAG]: (state, { payload: { assessmentId, tag } }) => {
    const assessmentsState = state.get(assessments.key)
    const sectionsInfoState = state.get(sectionsInfo.key)

    // Get the section name
    const tags = assessmentsState.getIn([assessmentId, 'tags'])
    const section = get(tags, [tag, 'data', '0'])

    return section
      ? state.set(
          sectionsInfo.key,
          sectionsInfoState.setIn([section, 'touched'], true)
        )
      : state
  },
})
