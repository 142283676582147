import { get as getIn } from 'lodash'
import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request, { flattenErrors } from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

export const CODE_SEARCH = 'codeSearch'

const CODE_SEARCH_CLEARED = type(key, 'CODE_SEARCH_CLEARED')

const creator = scopedCreator(key)
export const codeSearchCleared = creator(CODE_SEARCH_CLEARED)

export const Code = Record({
  code: null,
  description: null,
  type: null,
})

export type CodeResult = {
  code: string
  description: string
  type: string
}

const transformCodes = (codes: CodeResult[]) => List(codes.map(Code))

export const fetchCodeSearchResults = Request({
  typePrefix: key,
  typeBase: 'FETCH_CODES_FROM_SEARCH',
  requestParams: ['encounterId', 'searchString'],
  operation: (encounterId: number, query: string) =>
    AspireAPI.get(`coding_dashboard/${encounterId}/code_search`, {
      params: { query },
    }),
  transform: transformCodes,
  messages: {
    failed: 'There was an issue fetching encounter codes from search',
  },
})

export const addCode = Request({
  typePrefix: key,
  typeBase: 'ADD_CODE',
  requestParams: ['encounterId', 'code'],
  operation: (encounterId: number, code: CodeResult) =>
    AspireAPI.post(`coding_dashboard/${encounterId}/add`, {
      code: code.code,
      type: code.type,
    }),
  messages: {
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was an issue adding the encounter code',
  },
})

export const getCodesResults = pipe(getRoot, get(CODE_SEARCH))

const initState = List()

export default createReducer(CODE_SEARCH, initState, {
  // @ts-expect-error fix me
  [codeSearchCleared]: () => initState,
  [fetchCodeSearchResults.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})
