import React from 'react'
import PropTypes from '~/utils/propTypes'
import AddListAction from './AddListAction'

const RowActions = ({ showListButton, rowActions: RowActions, ...props }) => (
  <React.Fragment>
    <RowActions {...props} />
    {showListButton && <AddListAction {...props} />}
  </React.Fragment>
)

RowActions.propTypes = {
  rowActions: PropTypes.elementType,
  showListButton: PropTypes.bool,
}

RowActions.defaultProps = {
  rowActions: () => {},
}

export default React.memo(RowActions)
