import React from 'react'
import PropTypes from '~/utils/propTypes'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  container: { padding: spacing(2) },
  title: { textAlign: 'center', textDecoration: 'underline' },
  subtitle: { fontWeight: 'bold' },
}))

const ConsentForServicesLanguage = ({ formContext }) => {
  const classes = useStyles()

  const {
    context: { language },
  } = formContext
  return (
    <div className={classes.container}>
      <Typography className={classes.title} paragraph variant="h5">
        {language.title}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.overviewSubtitle}
      </Typography>
      <Typography paragraph variant="body2">
        {language.overview}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.coverageSubtitle}
      </Typography>
      <Typography paragraph variant="body2">
        {language.coverage}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.consentSubtitle}
      </Typography>
      <List dense>
        {language.consentList.map(line => (
          <ListItem key={line}>
            <ListItemText>{line}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subtitle} variant="subtitle1">
        {language.signatureSubtitle}
      </Typography>
    </div>
  )
}

ConsentForServicesLanguage.propTypes = {
  formContext: PropTypes.object.isRequired,
}

export default ConsentForServicesLanguage
