import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { cancelAssessment } from '../data/assessments'
import { ASSESSMENT_CANCELLED } from '../data/common/shared'
import { getCurrentAssessmentId } from '../data/currentAssessmentId'

export default (action$, state$) =>
  action$.pipe(
    ofType(ASSESSMENT_CANCELLED),
    pluck('payload'),
    map(({ reason, reasonOther }) => {
      const id = getCurrentAssessmentId(state$.value)
      return cancelAssessment.requested(id, reason, reasonOther)
    })
  )
