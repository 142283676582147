import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, getIn, payload, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import rootKey from '../key'

const CARETEAMID = 'careTeamIdKey'
const ADDPOD = 'addCareTeamPodKey'
const DELETEPOD = 'deleteCareTeamPodKey'
const GETCARETEAMPOD = 'getCareTeamKey'

const creator = scopedCreator(CARETEAMID)
export const selectedCareTeamId: any = creator('SELECTED_CARE_TEAM_ID')

export const addCareTeamToPod = Request({
  typePrefix: rootKey,
  typeBase: 'CARETEAM',
  requestParams: ['data'],
  operation: (data: any) =>
    AspireAPI.post(`care_teams/care_team_care_team_pod`, {
      ...data,
    }),

  messages: {
    succeeded: 'Care team added to pod.',
    failed: 'Unable to add care team to selected pod',
  },
})

export const removeCareTeamToPod = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_POD',
  requestParams: ['careteamId', 'podId'],
  operation: (careteamId: any, podId: any) =>
    AspireAPI.delete(
      `care_teams/care_team_care_team_pod/${careteamId}?podId=${podId}`
    ),
  messages: {
    succeeded: `Deleted successfully`,
    failed: `Deleted  failed`,
  },
})

export const fetchCareTeamDetailsById = Request({
  typePrefix: rootKey,
  typeBase: 'GET_POD_DETAIL',
  requestParams: ['careteamId'],
  operation: (careteamId: number) =>
    AspireAPI.get(`care_teams/care_team/${careteamId}`),
  messages: { failed: 'There was an issue fetching Care Team Details' },
})

export const addCareTeamPod = createReducer(ADDPOD, List(), {
  [addCareTeamToPod.SUCCEEDED]: compose(payload, action),
})

export const deleteCareTeamPod = createReducer(DELETEPOD, Boolean, {
  [removeCareTeamToPod.SUCCEEDED]: () => false,
})

export const careTeamDetailsById = createReducer(GETCARETEAMPOD, List(), {
  [fetchCareTeamDetailsById.SUCCEEDED]: compose(payload, action),
})

export const currentCareTeamDetailId = createReducer(CARETEAMID, Number, {
  [selectedCareTeamId]: (_state: any, { payload }: any) => payload,
})

export const getCareTeamDetailsById = getIn([rootKey, GETCARETEAMPOD])
export const getCurrentCareTeamDetailId = getIn([rootKey, CARETEAMID])
