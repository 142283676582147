import { OrderedSet, Record } from 'immutable'
// @ts-expect-error no export
import { FAILED, REQUESTED, SUCCEEDED } from '~/utils/Request'
import { creator, type } from '~/utils/data'

const createType = (prefix: string, type: string): string => `${prefix}/${type}`

const key = 'messages'

export const PENDING = 'pending'
export const SUCCESS = 'success'
export const WARNING = 'warning'
export const ERROR = 'error'

const stepToType = {
  [REQUESTED]: PENDING,
  [SUCCEEDED]: SUCCESS,
  [FAILED]: ERROR,
}

// Don't want to rely on old wrappers, so do it old school until
// we can clean this entire file up

const MESSAGE_ADDED = createType(key, 'MESSAGE_ADDED')

type RequestState = typeof PENDING | typeof SUCCESS | typeof ERROR
export const messageAdded = (state: RequestState, message: string) => ({
  type: MESSAGE_ADDED,
  payload: { state, message },
})

const ERROR_ADDED = type(key, 'ERROR_ADDED') // DEPRECATED
export const errorAdded = creator(ERROR_ADDED, 'message') // DEPRECATED

const MESSAGE_REMOVED = type(key, 'MESSAGE_REMOVED')
export const messageRemoved = creator(MESSAGE_REMOVED)

export const Message = Record({ text: null, type: null })

// Reducer
const reducer = (state = OrderedSet(), { meta, error, payload, type }: any) => {
  if (meta && meta.message) {
    let messageType

    if (error) {
      messageType = ERROR
    } else if (meta.messageType) {
      messageType = meta.messageType
    } else if (meta.request) {
      messageType = stepToType[meta.request.step]
    }

    return state.add(Message({ text: meta.message, type: messageType }))
  }

  if (type === MESSAGE_REMOVED) {
    return state.rest()
  }

  // New stuff crammed in here until we can clean this entire file up
  if (type === MESSAGE_ADDED) {
    const { state: type, message } = payload
    return state.add(Message({ text: message, type }))
  }

  // DEPRECATED
  if (type === ERROR_ADDED) {
    return state.add(Message({ text: payload.message, type: ERROR }))
  }

  return state
}

reducer.key = key

export default reducer

export const getMessage = (state: any) => state.get(key).first()
