import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { getCurrentPatientId } from './currentPatientId'

const SCHEDULING_ALERTS = 'schedulingAlerts'

const SchedulingAlert = Record({
  patientId: null,
  id: null,
  rank: null,
  description: null,
})

const transformSchedulingAlerts = alerts =>
  alerts.reduce(
    (map, alert) =>
      map.update(alert.patientId, Map(), patientAlerts =>
        patientAlerts.set(
          alert.id,
          SchedulingAlert({ patientId: alert.patientId, ...alert })
        )
      ),
    Map()
  )

const transformSchedulingAlert = data => {
  return SchedulingAlert({
    patientId: data?.create_alert?.patient_id,
    id: data?.create_alert?.id,
    rank: data?.create_alert?.rank,
    description: data?.create_alert?.description,
  })
}

const transformSchedulingAlertUpdate = data => {
  return SchedulingAlert({
    patientId: data?.update?.patient_id,
    id: data?.update?.id,
    rank: data?.update?.rank,
    description: data?.update?.description,
  })
}

export const fetchSchedulingAlerts = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_SCHEDULING_ALERTS',
  requestParams: ['appId'],
  operation: (appId, cancelToken) =>
    AspireAPI.get(`np_scheduling/scheduling_alerts/${appId}`, { cancelToken }),
  transform: transformSchedulingAlerts,
  messages: {
    failed: 'There was an issue fetching scheduling alerts',
  },
})

export const createSchedulingAlert = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_SCHEDULING_ALERT',
  requestParams: ['patientId', 'description', 'rank'],
  operation: (patientId, description, rank) =>
    AspireAPI.post('v1/alert', {
      type: 'scheduling',
      patientId,
      description,
      rank,
    }),
  transform: transformSchedulingAlert,
  messages: {
    succeeded: 'Alert successfully created',
    failed: 'There was an issue creating this alert',
  },
})

export const saveSchedulingAlert = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_SCHEDULING_ALERT',
  requestParams: ['id', 'description', 'rank'],
  operation: (id, description, rank) =>
    AspireAPI.put(`v1/alert/${id}`, {
      description,
      rank,
    }),
  transform: transformSchedulingAlertUpdate,
  messages: {
    succeeded: 'Alert successfully saved',
    failed: 'There was an issue saving this alert',
  },
})
export const deleteSchedulingAlert = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_SCHEDULING_ALERT',
  requestParams: ['patientId', 'id'],
  operation: (patientId, id) => AspireAPI.delete(`v1/alert/${id}`),
  messages: {
    succeeded: 'Alert successfully deleted',
    failed: 'There was an issue deleting this alert',
  },
})

const initState = Map()
export default createReducer(SCHEDULING_ALERTS, initState, {
  [fetchSchedulingAlerts.REQUESTED]: () => initState,
  [fetchSchedulingAlerts.SUCCEEDED]: (_state, { payload }) => payload,
  [createSchedulingAlert.SUCCEEDED]: (state, { payload }) =>
    state.setIn([payload.patientId, payload.id], payload),
  [saveSchedulingAlert.SUCCEEDED]: (state, { payload }) =>
    state.setIn([payload.patientId, payload.id], payload),
  [deleteSchedulingAlert.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { patientId, id },
        },
      },
    }
  ) => state.deleteIn([patientId, id]),
})

const getAllSchedulingAlerts = pipe(getRoot, get(SCHEDULING_ALERTS))

export const getSchedulingAlerts = state => {
  const patientId = getCurrentPatientId(state)
  return getAllSchedulingAlerts(state).get(patientId, Map())
}
