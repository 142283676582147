import { combineEpics } from 'redux-observable'
import loadDischargeRecommendationIfNeeded from './epics/loadDischargeRecommendationIfNeeded'
import refreshDischargeRecs from './epics/refreshDischargeRecs'
import refreshDischarges from './epics/refreshDischarges'
import dischargeCreated from './epics/dischargeCreated'
import reinstatementSaved from './epics/reinstatementSaved'
import dischargeRecommendationCreated from './epics/dischargeRecommendationCreated'
import dischargeRecommendationUpdated from './epics/dischargeRecommendationUpdated'

export default combineEpics(
  dischargeCreated,
  dischargeRecommendationCreated,
  dischargeRecommendationUpdated,
  reinstatementSaved,
  loadDischargeRecommendationIfNeeded,
  refreshDischargeRecs,
  refreshDischarges,
  loadDischargeRecommendationIfNeeded
)
