import classnames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useInput } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import {
  Icon,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    display: 'flex',
    position: 'sticky',
    padding: spacing(1),
    top: 0,
    backgroundColor: palette.background.paper,
    zIndex: 1,
  },
  search: {
    flex: 1,
    marginRight: spacing(0.5),
  },
  listItem: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  selectedLabel: {
    margin: spacing(1),
  },
}))

const isItemSelected = selectedItemIds => item =>
  selectedItemIds.includes(item.get('id'))

const SelectList = ({
  items,
  itemsLabel,
  disabled,
  separateSelected,
  batchActions,
  selectedItemIds,
  onItemsSelected,
}) => {
  const classes = useStyles()

  const [filter, setFilter] = useInput('')

  const sortedItems = useMemo(
    () =>
      items.sort((itemA, itemB) =>
        itemA
          .get('name')
          .toLowerCase()
          .localeCompare(itemB.get('name').toLowerCase())
      ),
    [items]
  )

  const filteredItems = useMemo(
    () =>
      sortedItems
        .filter(item =>
          item.get('name').toLowerCase().includes(filter.toLowerCase())
        )
        .toIndexedSeq(),
    [sortedItems, filter]
  )

  const selectedItems = useMemo(
    () =>
      separateSelected
        ? filteredItems.filter(isItemSelected(selectedItemIds))
        : filteredItems,
    [separateSelected, filteredItems, selectedItemIds]
  )

  const unselectedItems = useMemo(
    () =>
      separateSelected
        ? filteredItems.filterNot(isItemSelected(selectedItemIds))
        : filteredItems,
    [separateSelected, filteredItems, selectedItemIds]
  )

  const onBatchAction = useCallback(
    selected => () => {
      const ids = filteredItems.map(item => item.get('id')).toArray()
      onItemsSelected(ids, selected)
    },
    [filteredItems, onItemsSelected]
  )
  const onAllSelected = useCallback(onBatchAction(true), [onBatchAction])
  const onNoneSelected = useCallback(onBatchAction(false), [onBatchAction])

  const Item = item => {
    const id = item.get('id')
    const selected = selectedItemIds.includes(id)
    const onClick = disabled
      ? undefined
      : () => onItemsSelected([id], !selected)

    return (
      <ListItem
        key={id}
        className={classnames({ [classes.listItem]: !disabled })}
        disabled={disabled}
        selected={selected}
        onClick={onClick}
      >
        {item.get('name')}
      </ListItem>
    )
  }

  return (
    <div>
      <div className={classes.header}>
        <TextField
          className={classes.search}
          type="search"
          placeholder={`Filter ${itemsLabel}...`}
          value={filter}
          onChange={setFilter}
        />

        {batchActions && (
          <React.Fragment>
            <Tooltip title="Select All">
              <IconButton
                size="small"
                disabled={disabled}
                onClick={onAllSelected}
              >
                <Icon>done_all</Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Clear">
              <IconButton
                size="small"
                disabled={disabled}
                onClick={onNoneSelected}
              >
                <Icon>clear</Icon>
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
      </div>

      <div>
        {separateSelected ? (
          <React.Fragment>
            {!selectedItems.isEmpty() && (
              <Typography
                className={classes.selectedLabel}
                color="primary"
                variant="h6"
              >
                Selected
              </Typography>
            )}

            {selectedItems.map(Item)}

            {!unselectedItems.isEmpty() && (
              <Typography
                className={classes.selectedLabel}
                color="primary"
                variant="h6"
              >
                Not Selected
              </Typography>
            )}

            {unselectedItems.map(Item)}
          </React.Fragment>
        ) : (
          <React.Fragment>{filteredItems.map(Item)}</React.Fragment>
        )}
      </div>
    </div>
  )
}

SelectList.propTypes = {
  items: PropTypes.map.isRequired,
  itemsLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  separateSelected: PropTypes.bool,
  batchActions: PropTypes.bool,
  selectedItemIds: PropTypes.set.isRequired,
  onItemsSelected: PropTypes.func.isRequired,
}

SelectList.defaultProps = {
  disabled: false,
  separateSelected: false,
  batchActions: true,
}

export default SelectList
