import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { getUserId } from '~/data/session'
import { useAction, useInput, useToggle, useUpdateEffect } from '~/hooks'
import { Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  createReminder as createReminderRequest,
  deleteReminder as deleteReminderRequest,
  fetchReminder,
  getReminderTask,
  saveReminder as saveReminderRequest,
} from '../../../data/reminderTask'

const inputLabelProps = { shrink: true }

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    display: 'block',
    marginBottom: spacing(1),
  },
  button: {
    marginRight: spacing(1),
  },
}))

const Reminder = ({ patientId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const userId = useSelector(getUserId)
  const reminder = useSelector(getReminderTask)
  const createReminderRequested = useAction(createReminderRequest.requested)
  const saveReminderRequested = useAction(saveReminderRequest.requested)
  const deleteReminderRequested = useAction(deleteReminderRequest.requested)

  const [open, toggleOpen, toggleClosed] = useToggle()
  const [description, setDescription] = useInput(reminder.description)
  const [dueDate, setDueDate] = useInput(reminder.dueDate)

  const onSave = useCallback(() => {
    const operation = reminder.id
      ? saveReminderRequested
      : createReminderRequested
    const newReminder = reminder.merge({ patientId, description, dueDate })

    operation(userId, newReminder)
    toggleClosed()
  }, [
    userId,
    reminder,
    description,
    dueDate,
    createReminderRequested,
    saveReminderRequested,
    toggleClosed,
  ])

  const onDelete = useCallback(() => {
    deleteReminderRequested(reminder.id)
    toggleClosed()
  }, [reminder, deleteReminderRequested, toggleClosed])

  const onDiscard = useCallback(() => {
    setDescription(reminder.description)
    setDueDate(reminder.dueDate)
  }, [reminder, setDescription, setDueDate])

  useUpdateEffect(() => {
    setDescription(reminder.description)
    setDueDate(reminder.dueDate)
  }, [reminder, setDescription, setDueDate])

  useEffect(() => {
    if (userId && patientId) {
      dispatch(fetchReminder.requested(userId, patientId))
    }
  }, [userId, patientId])

  const reminderExists = Boolean(reminder.id)
  const pendingChanges =
    description !== reminder.description || dueDate !== reminder.dueDate
  const valid = description && dueDate

  return (
    <React.Fragment>
      <TextField
        className={classes.textField}
        label="Description"
        multiline
        rowsMax={5}
        fullWidth
        onChange={setDescription}
        value={description}
      />

      <TextField
        className={classes.textField}
        label="Due Date"
        type="date"
        fullWidth
        InputLabelProps={inputLabelProps}
        onChange={setDueDate}
        value={dueDate}
      />

      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={!pendingChanges || !valid}
        onClick={onSave}
      >
        Save
      </Button>

      {reminderExists && (
        <Button
          className={classes.button}
          color="secondary"
          variant="outlined"
          onClick={toggleOpen}
        >
          Delete
        </Button>
      )}

      {pendingChanges && (
        <Button className={classes.button} onClick={onDiscard}>
          Discard Changes
        </Button>
      )}

      <ActionDialog
        open={open}
        mode="delete"
        title="Delete Reminder"
        onDelete={onDelete}
        onClose={toggleClosed}
      >
        <Typography>Are you sure you want to delete this reminder?</Typography>
      </ActionDialog>
    </React.Fragment>
  )
}

Reminder.propTypes = {
  patientId: PropTypes.string.isRequired,
}

export default Reminder
