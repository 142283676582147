import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { fetchMdtSingleSignOn } from '../data/mdtSingleSignOn'
import { getPatientRxById, putPatientRx } from '../data/patientRx'
import {
  hasSelectedMedication,
  rxMedicationCleared,
} from '../data/selectedMedication'

const requestMdtSingleSignOn = med =>
  fetchMdtSingleSignOn.requested({
    pharmacyNcpdpId: med.pharmacyNcpdpId,
    partnerMedId: med.partnerMedId,
    prescriberId: med.prescriberId,
    locationId: med.locationId,
    patientId: med.patientId,
    header: 'no_edit',
  })

export default (action$, state$) => {
  const discontinueMedication = prescriptionId => {
    const refilledRx = getPatientRxById(state$.value)(prescriptionId)
    return putPatientRx.requested(
      refilledRx.set('status', 'Discontinued'),
      false
    )
  }

  return action$.pipe(
    ofType(putPatientRx.SUCCEEDED),
    mergeMap(({ meta, payload }) => {
      const metaPayload = meta.request.payload
      const mdtSingleSignOnRequest = metaPayload.mdtSingleSignOnRequest
      const submittedMed = metaPayload.medication

      return [
        mdtSingleSignOnRequest && requestMdtSingleSignOn(payload),
        submittedMed.has('isRefilling') &&
          discontinueMedication(submittedMed.get('isRefilling')),
        hasSelectedMedication(state$.value) && rxMedicationCleared(),
      ].filter(Boolean)
    })
  )
}
