import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Button, DialogActions } from '@material-ui/core'

const TaskDialogActions = ({ disabled, onCancel, onSubmit }) => (
  <DialogActions>
    <Button color="primary" onClick={onCancel}>
      Cancel
    </Button>
    <Button
      color="primary"
      disabled={disabled}
      onClick={onSubmit}
      type="submit"
      variant="contained"
    >
      Save
    </Button>
  </DialogActions>
)

TaskDialogActions.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default TaskDialogActions
