import { List, Record } from 'immutable'
import { ofType } from 'redux-observable'
import { first, map } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

export const CHECK_IN = 'Check-In'
export const VISIT_CONFIRMATION = 'Visit Confirmation'
export const SCHEDULE_INITIAL_VISIT = 'Schedule Initial Visit'
export const SCHEDULE_FOLLOW_UP_VISIT = 'Schedule Follow Up Visit'
export const SCHEDULE_URGENT_TOC_VISIT = 'Schedule Urgent / ToC Visit'

const CALL_INTENTS = 'callIntents'

export const CallIntent = Record({
  id: null,
  label: null,
})

const creator = scopedCreator(rootKey)
export const callIntentsRequested = creator('CALL_INTENTS_REQUESTED')

const transformCallIntents = data => List(data.map(CallIntent))

export const fetchCallIntents = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CALL_INTENTS',
  operation: () => AspireAPI.get('v1/calldispositions/intents'),
  transform: transformCallIntents,
  messages: { failed: 'There was an issue fetching call intents' },
})

export default createReducer(CALL_INTENTS, List(), {
  [fetchCallIntents.SUCCEEDED]: (_state, { payload }) => payload,
})

export const epic = action$ =>
  action$.pipe(
    ofType(callIntentsRequested),
    first(),
    map(fetchCallIntents.requested)
  )

export const getCallIntents = pipe(getRoot, get(CALL_INTENTS))
