import { combineEpics } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { fetchHraDuePatients, fetchUtcPatients } from '../data/hraOutreach'
import {
  CASE_MANAGER_DASHBOARD_HRA_OUTREACH_LIST_HRA_DUE,
  CASE_MANAGER_DASHBOARD_HRA_OUTREACH_LIST_HRA_UTC,
} from '../router'

const fetchHraOutreachData = (route, request) => action$ =>
  action$.pipe(
    filter(changedTo(route)),
    pluck('payload', 'params', 'cmId'),
    map(request.requested)
  )

export default combineEpics(
  fetchHraOutreachData(
    CASE_MANAGER_DASHBOARD_HRA_OUTREACH_LIST_HRA_DUE,
    fetchHraDuePatients
  ),
  fetchHraOutreachData(
    CASE_MANAGER_DASHBOARD_HRA_OUTREACH_LIST_HRA_UTC,
    fetchUtcPatients
  )
)
