import React from 'react'
import Info from '~/components/text/Info'
import { formatDateTime } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { LegacyOnCall } from '../data/onCalls'

const styles = {
  container: {
    margin: [22, 0],
    padding: 16,
    '& > div': {
      padding: [2, 0],
    },
  },
}

const LegacyOnCallInfo = ({ classes, legacyOnCall: l }) => (
  <Paper className={classes.container} square>
    <Info label="Number of Calls">{l.numberOfCalls}</Info>
    <Info label="Type of Call">{l.typeOfCall}</Info>
    <Info label="Reason for Call">{l.reasonForCall}</Info>
    <Info label="Action Taken">{l.onCallActionTaken}</Info>
    <Info label="Other Action Taken">{l.otherActionTaken}</Info>
    <Info label="Next Day Action Needed">{l.nextDayActionNeeded}</Info>
    <Info label="Other Next Day Action Needed">
      {l.otherNextDayActionNeeded}
    </Info>
    <Info label="Date/Time of Call">{formatDateTime(l.dateTimeOfCall)}</Info>
    <Info label="Person Spoken To">{l.personSpokenTo}</Info>
    <Info label="Call Details">{l.callDetails}</Info>
    <Info label="Completed At">{formatDateTime(l.completedAt)}</Info>
  </Paper>
)

LegacyOnCallInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  legacyOnCall: PropTypes.instanceOf(LegacyOnCall).isRequired,
}

export default withStyles(styles)(LegacyOnCallInfo)
