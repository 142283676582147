import { Map } from 'immutable'
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import {
  saveNewInsuranceCoverage,
  updateInsuranceCoverage,
} from './insuranceCoverages'

// KEY
const coveragesKey = 'insuranceCoverageUpdates'
const coverageCreationKey = 'coverageCreation'

export const key = 'insuranceDialogs'

// CREATORS
const creator = scopedCreator(key)

export const coverageSelectedForEditing = creator('COVERAGE_UPDATE_ID_SELECTED')

export const coverageSelectedForCreation = creator('COVERAGE_CREATION_SELECTED')

export const getCoverageId = getIn([rootKey, key, coveragesKey])
export const isCreatingCoverage = getIn([rootKey, key, coverageCreationKey])

// REDUCER
export default createReducer(key, new Map(), {
  [coverageSelectedForEditing]: (state, { payload }) =>
    state.set(coveragesKey, payload || null),
  [updateInsuranceCoverage.SUCCEEDED]: state => state.set(coveragesKey, null),
  [coverageSelectedForCreation]: (state, { payload }) =>
    state.set(coverageCreationKey, payload || null),
  [saveNewInsuranceCoverage.SUCCEEDED]: state =>
    state.set(coverageCreationKey, null),
})
