import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
import { of } from 'rxjs'
import { fetchEncounterInfo } from '../data/encounterInfo'
// @ts-expect-error no export
import { fetchAssessment } from '~/apps/assessment/data/assessments'
// @ts-expect-error no export
import { fetchPatient } from '~/features/patientInfo'

const fetchPatientInfo = (action$: any) =>
  action$.pipe(
    ofType(fetchEncounterInfo.SUCCEEDED),
    pluck('payload'),
    mergeMap((encounterInfo: any) =>
      of(
        fetchPatient.requested(encounterInfo.patientId),
        fetchAssessment.requested(encounterInfo.id, true)
      )
    )
  )

export default fetchPatientInfo
