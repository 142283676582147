import moment from 'moment'
import validate from '~/utils/validate'

const stringRequired = value =>
  value && value.trim().length > 0 ? undefined : 'Required'

const dateCheck = admissionDate => value =>
  admissionDate && value && value && moment(value) < moment(admissionDate)
    ? 'Before Admission Date'
    : undefined

export default values => ({
  facilityName: validate(values.get('facilityName'), [stringRequired]),
  admissionDate: validate(values.get('admissionDate'), [stringRequired]),
  dischargeDate: validate(values.get('dischargeDate'), [
    dateCheck(values.get('admissionDate')),
  ]),
})
