import React from 'react'
import Count from '~/components/Count'
import DevXGrid from '~/components/DevXGrid'
import Search from '~/components/Search'
import useInput from '~/hooks/useInput'
import PropTypes from '~/utils/propTypes'
import { Divider, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  divider: {
    margin: [spacing(2), 0],
  },
  count: {
    marginBottom: spacing(1),
  },
})

const Results = ({
  classes,
  includeWrapper,
  searchLabel,
  columns,
  columnExtensions,
  defaultSorting,
  rows,
  onSearch,
  onSelect,
  rowActions,
  rowActionsCount,
}) => {
  const [value, setValue] = useInput()
  const Wrapper = includeWrapper ? Paper : React.Fragment

  return (
    <React.Fragment>
      <Search
        label={searchLabel}
        value={value}
        onChangeSearch={setValue}
        onSubmit={onSearch}
      />

      <Divider className={classes.divider} />

      <div className={classes.count}>
        <Count value={rows.length} />
      </div>

      <Wrapper>
        <DevXGrid
          rows={rows}
          columns={columns}
          columnExtensions={columnExtensions}
          selectable={Boolean(onSelect)}
          selectSingleRow={Boolean(onSelect)}
          onSelect={onSelect}
          sortable
          pageable
          defaultSorting={defaultSorting}
          rowActions={rowActions}
          rowActionsCount={rowActionsCount}
        />
      </Wrapper>
    </React.Fragment>
  )
}

Results.propTypes = {
  classes: PropTypes.object.isRequired,
  includeWrapper: PropTypes.bool,
  searchLabel: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array,
  defaultSorting: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
}

Results.defaultProps = {
  includeWrapper: false,
}

export default withStyles(styles)(Results)
