import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import router from '../router'
import { getPatientRecord } from './common/shared'

const PATIENT_ID = 'patientId'

const reducer = paramsReducer(router, ({ patientId }) => patientId)

reducer.key = PATIENT_ID

export const getPatientId = pipe(getPatientRecord, get(PATIENT_ID))

export default reducer
