import { paramsReducer } from 'redux-routable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
import router from '../router'
import { getRoot } from './common/shared'

// KEY
const key = 'clinicalDirectorId'

// REDUCER
const reducer = paramsReducer(router, ({ cdId }: any) => cdId || null)

reducer.key = key

// SELECTORS
export const getSelectedCDId = (state: any) =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(key) || getUserId(state)

export default reducer
