import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { completePatientOutlierReview } from '../data/currentPatientOutlierReview'
import { getCurrentUserId } from '../data/currentUserId'
import { fetchPatientOutlierFinalDecisions } from '../data/patientOutlierFinalDecisions'
import { PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS } from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(completePatientOutlierReview.SUCCEEDED),
    switchTo(state$),
    mergeMap(state => {
      const userId = getCurrentUserId(state) || getUserId(state)

      return of(
        push(PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS),
        fetchPatientOutlierFinalDecisions.requested(userId)
      )
    })
  )
