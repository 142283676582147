import { OrderedMap } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { User, getRoot } from './common/shared'
import { transformUser } from './currentUser'

const USERS = 'users'

export const transformUsers = ({ count, users }: any) => ({
  count,
  users: OrderedMap(users.map((user: any) => [user.id, transformUser(user)])),
})

export const fetchUsers = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_USERS',
  requestParams: ['searchWord', 'active', 'page'],
  operation: (searchWord: any, active: any, page: any) =>
    AspireAPI.get('admin_tools/users', {
      params: { searchWord, active, page },
    }),
  transform: transformUsers,
  messages: { failed: 'There was an issue fetching users' },
})

export default createReducer(USERS, OrderedMap(), {
  [fetchUsers.SUCCEEDED]: (_state: any, { payload: { users } }: any) => users,
})

export const getUsers = pipe(getRoot, get(USERS))
export const getUserById = (state: any, userId: any) =>
  getUsers(state).get(userId, User())
