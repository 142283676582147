import { connect } from 'react-redux'
import ConsentForServices from '../components/ConsentForServices'
import {
  completeForm,
  fetchForm,
  formCancelled,
  formErrored,
  formUpdated,
  getForm,
  isFormPending,
  isFormSubmitting,
} from '../data/form'

export default connect(
  state => ({
    form: getForm(state),
    pending: isFormPending(state),
    submitting: isFormSubmitting(state),
  }),
  {
    formCancelled,
    formErrored,
    formCompletionRequested: completeForm.requested,
    formRequested: fetchForm.requested,
    formUpdated,
  }
)(ConsentForServices)
