import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const PHYSICIANS = 'physicians'

export const Physician = Record({
  id: null,
  contactId: null,
  salutation: null,
  firstName: null,
  middleName: null,
  lastName: null,
  name: null,
  suffix: null,
  title: null,
  specialty: null,
  npiNumber: null,
  practiceId: null,
  practice: null,
  ipaId: null,
  ipa: null,
  groupId: null,
  group: null,
  marketId: null,
  market: null,
  createdBy: null,
  missionPoint: false,
  physicianRelationshipStatus: null,
  physicianManagedStatus: null,
  physicianCallNotes: null,
  practiceRelationshipStatus: null,
  practiceManagedStatus: null,
  practiceCallNotes: null,
  physicianNotes: null,
  nextTargetedContactDate: null,
  phone: null,
  mobilePhone: null,
  fax: null,
  faxLastVerifiedDate: null,
  faxVerifiedBy: null,
  email: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,
})

const transformPhysician = ({
  relationshipStatus,
  managedStatus,
  callNotes,
  createdBy,
  faxVerifiedBy,
  practice,
  ipa,
  group,
  market,
  ...physician
}) =>
  Physician({
    ...physician,
    physicianRelationshipStatus: relationshipStatus,
    physicianManagedStatus: managedStatus,
    physicianCallNotes: callNotes,
    createdBy: getIn(createdBy, 'name'),
    faxVerifiedBy: getIn(faxVerifiedBy, 'name'),
    practiceId: getIn(practice, 'id'),
    practice: getIn(practice, 'name'),
    practiceRelationshipStatus: getIn(practice, 'relationshipStatus'),
    practiceManagedStatus: getIn(practice, 'managedStatus'),
    practiceCallNotes: getIn(practice, 'callNotes'),
    ipaId: getIn(ipa, 'id'),
    ipa: getIn(ipa, 'name'),
    groupId: getIn(group, 'id'),
    group: getIn(group, 'name'),
    marketId: getIn(market, 'id'),
    market: getIn(market, 'name'),
  })

export const fetchPhysicians = Request({
  typePrefix: key,
  typeBase: 'FETCH_PHYSICIANS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/physicians`, {
      params: { searchWord },
    }),
  transform: into(Physician, 'id'),
  messages: { failed: 'There was a problem fetching physicians' },
})

export const fetchPhysician = Request({
  typePrefix: key,
  typeBase: 'FETCH_PHYSICIAN',
  requestParams: ['physicianId'],
  operation: physicianId => AspireAPI.get(`contacts/physicians/${physicianId}`),
  transform: transformPhysician,
  messages: { failed: 'There was a problem fetching this physician' },
})

export const addPhysician = Request({
  typePrefix: key,
  typeBase: 'ADD_PHYSICIAN',
  requestParams: ['physician'],
  operation: physician => AspireAPI.post('contacts/physicians', { physician }),
  messages: {
    succeeded: 'Successfully added new physician',
    failed: 'There was a problem adding this physician',
  },
})

export const savePhysician = Request({
  typePrefix: key,
  typeBase: 'SAVE_PHYSICIAN',
  requestParams: ['physician'],
  operation: physician =>
    AspireAPI.put(`contacts/physicians/${physician.id}`, { physician }),
  messages: {
    succeeded: 'Successfully saved physician',
    failed: 'There was a problem saving this physician',
  },
})

export const deletePhysician = Request({
  typePrefix: key,
  typeBase: 'DELETE_PHYSICIAN',
  requestParams: ['physicianId'],
  operation: physicianId =>
    AspireAPI.delete(`contacts/physicians/${physicianId}`),
  messages: {
    succeeded: 'Successfully deleted physician',
    failed: 'There was a problem deleting this physician',
  },
})

const creator = scopedCreator(key)
export const physiciansCleared = creator('PHYSICIANS_CLEARED')

const initState = Map()
export default createReducer(PHYSICIANS, initState, {
  [fetchPhysicians.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchPhysician.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [savePhysician.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { physician },
        },
      },
    }
  ) => state.set(physician.id, physician),
  [deletePhysician.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { physicianId },
        },
      },
    }
  ) => state.delete(physicianId),
  [physiciansCleared]: () => initState,
})

export const getPhysicians = pipe(getRoot, get(PHYSICIANS))

export const getPhysiciansArr = createSelector([getPhysicians], physicians =>
  physicians.toIndexedSeq().toArray()
)

export const getPhysicianById = (state, id) =>
  getPhysicians(state).get(id, Physician())
