import { combineReducers } from 'redux-immutable'
import createLtcDialog from './data/createLtcDialog'
import ltcs from './data/ltcs'
import key from './key'

const reducer = combineReducers({
  [createLtcDialog.key]: createLtcDialog,
  [ltcs.key]: ltcs,
})

reducer.key = key

export default reducer
