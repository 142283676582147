import { Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'currentTransportationPatient'
const NEXT_YEAR_TRIPS_FLAG_KEY = 'nextYearTripsFlagKey'

// CREATORS
const creator = scopedCreator(key)
export const selectedPatient = creator('SELECTED_TRANSPORTATION_PATIENT')
export const resetPatient = creator('RESET_TRANSPORTATION_PATIENT')
export const nextYearTripsFlagCreator: any = creator(NEXT_YEAR_TRIPS_FLAG_KEY)

export const CurrentPatientId = Record({
  patientId: null,
  subscriberId: null,
  fullName: null,
  dob: null,
  firstName: null,
  lastName: null,
  phone: null,
  address: null,
  countyName: null,
  eligibilityEffectiveDate: null,
  groupId: null,
  productDescription: null,
  classplanProductId: null,
  currentPlanIsEligible: null,
})

// REDUCER
export const currentPatient = createReducer(key, CurrentPatientId, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [selectedPatient]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetPatient]: () => CurrentPatientId,
})

export const nextYearTripsFlagReducer = createReducer(
  NEXT_YEAR_TRIPS_FLAG_KEY,
  false,
  {
    [nextYearTripsFlagCreator]: (_state: any, { payload }: any) => payload,
  }
)

export const getCurrentPatient = getIn([rootKey, key])
export const getNextYearTripsFlag = getIn([rootKey, NEXT_YEAR_TRIPS_FLAG_KEY])
