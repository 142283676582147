import React from 'react'
import { useSelector } from 'react-redux'
import {
  SelectField as BaseSelectField,
  renderFieldValueOptions,
} from '~/components/fields'
import { getFieldValues } from '~/data/fieldValues'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

const SelectField = props => {
  const { value, field, allowNone, editing, onChange } = props
  const fieldValues = useSelector(getFieldValues(field))

  return (
    <BaseField {...props}>
      <BaseSelectField
        native
        fullWidth
        rowsMax={10}
        value={value}
        disabled={!editing}
        onChange={onChange}
      >
        {allowNone && <option value="">None</option>}
        {renderFieldValueOptions(fieldValues)}
      </BaseSelectField>
    </BaseField>
  )
}

SelectField.propTypes = {
  value: PropTypes.string,
  field: PropTypes.string.isRequired,
  allowNone: PropTypes.bool,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
}

SelectField.defaultProps = {
  allowNone: true,
}

export default SelectField
