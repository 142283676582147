import classnames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SelectField, renderFieldValueOptions } from '~/components/fields'
import { useAction, usePending } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Switch, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCallIntents } from '../../data/callIntents'
import {
  INBOUND,
  OUTBOUND,
  fetchCallAttempt,
  getCallLog,
  intentChanged,
  originChanged,
} from '../../data/callLog'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(1.5, 0),
  },
  switchLabel: {
    color: palette.text.disabled,
  },
  selected: {
    color: palette.text.primary,
    fontWeight: 'bold',
  },
  inboundLabel: {
    marginRight: spacing(3),
  },
  intent: {
    width: '100%',
  },
}))

const CallLogInfo = ({ intentsFilter, defaultIntent }) => {
  const classes = useStyles()

  const callLog = useSelector(getCallLog)
  const intents = useSelector(getCallIntents)
  const onOriginChange = useAction(originChanged)
  const onIntentChange = useAction(intentChanged)

  const pending = usePending(fetchCallAttempt)

  const filteredIntents = useMemo(
    () =>
      intents
        .filter(
          intent =>
            intentsFilter.length === 0 || intentsFilter.includes(intent.label)
        )
        .map(({ id: value, label }) => ({ value, label })),
    [intents]
  )

  const onToggle = event => {
    onOriginChange(event.target.checked)
  }

  useEffect(() => {
    if (!filteredIntents.isEmpty() && !callLog.intentId) {
      const intent = defaultIntent
        ? filteredIntents.find(intent => intent.label === defaultIntent).value
        : filteredIntents.first().value

      onIntentChange(intent)
    }
  }, [callLog, filteredIntents, onIntentChange])

  const callExists = !callLog.dispositions.isEmpty()

  return (
    <div className={classes.container}>
      <Typography
        className={classnames(classes.switchLabel, {
          [classes.selected]: callLog.callOrigin === OUTBOUND,
        })}
      >
        Outbound
      </Typography>

      <Switch
        disabled={pending || callExists}
        checked={callLog.callOrigin === INBOUND}
        onChange={onToggle}
      />

      <Typography
        className={classnames(classes.inboundLabel, classes.switchLabel, {
          [classes.selected]: callLog.callOrigin === INBOUND,
        })}
      >
        Inbound
      </Typography>

      <SelectField
        className={classes.intent}
        disabled={pending || callExists || filteredIntents.count() === 1}
        label="Intent"
        native
        value={callLog.intentId}
        onChange={onIntentChange}
      >
        {renderFieldValueOptions(filteredIntents)}
      </SelectField>
    </div>
  )
}

CallLogInfo.propTypes = {
  intentsFilter: PropTypes.array,
  defaultIntent: PropTypes.string,
}

CallLogInfo.defaultProps = {
  intentsFilter: [],
}

export default CallLogInfo
