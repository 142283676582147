import { connect } from 'react-redux'
import { areRequestsPending } from '~/data/pending'
import RxWriterDialog from '../components/RxWriter'
import {
  putPatientRx,
  savePatientMedication,
  syncPatientRx,
} from '../data/patientRx'
import {
  getSelectedMedication,
  rxMedicationCleared,
} from '../data/selectedMedication'
import {
  formSelector,
  mapCalculatedInitialValues,
} from '../utils/formValueSelectors'

const mapStateToProps = state => ({
  initialValues: mapCalculatedInitialValues(state),
  rxStatusValue: formSelector(state, 'status'),
  selectedMedication: getSelectedMedication(state),
  areRequestsPending: areRequestsPending(state, [syncPatientRx, putPatientRx]),
})

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(rxMedicationCleared()),
  onSubmit: (...args) => dispatch(savePatientMedication(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RxWriterDialog)
