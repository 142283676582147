import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from '~/utils/propTypes'
import setDisplayName from '~/utils/setDisplayName'

export default BaseComponent => {
  const DebouncedComponent = ({ value, onChange, ...props }) => {
    const { formContext } = props
    const [localValue, setLocalValue] = useState(value)
    const pending = useRef(false)
    const debouncedValue = pending.current ? localValue : value

    const debouncedOnChange = useCallback(debounce(onChange, 1000), [onChange])

    const handleChange = useCallback(
      value => {
        pending.current = true
        setLocalValue(value)
        debouncedOnChange(value)
      },
      [setLocalValue, debouncedOnChange]
    )

    useEffect(() => {
      if (formContext.debounce && value === localValue) {
        pending.current = false
      }
    }, [value, localValue])

    return (
      <BaseComponent
        {...props}
        value={formContext.debounce ? debouncedValue : value}
        onChange={formContext.debounce ? handleChange : onChange}
      />
    )
  }

  DebouncedComponent.propTypes = {
    formContext: PropTypes.object.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }

  setDisplayName(BaseComponent, DebouncedComponent, 'debounced')

  return DebouncedComponent
}
