import { Map } from 'immutable'
import { createSelector } from 'reselect'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getMedications } from './root'

const key = 'selectedMedication'
const creator = scopedCreator(key)

export const rxMedicationSelected = creator('RX_MEDICATION_SELECTED', [key])
export const rxMedicationCleared = creator('RX_MEDICATION_CLEARED')
export const rxMedicationSet = creator('RX_MEDICATION_SET', [key])

export default createReducer(key, Map(), {
  [rxMedicationSet]: (_state, { payload }) => payload.selectedMedication,
  [rxMedicationCleared]: () => Map(),
})

export const getSelectedMedication = pipe(getMedications, get(key))

export const hasSelectedMedication = createSelector(
  [getSelectedMedication],
  med => !med.isEmpty()
)
