import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Divider, Icon, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    flex: 1,
  },
})

const SchedulingAlert = ({ alert, ...props }) => {
  const classes = useStyles()

  const onEdit = () => {
    props.onEdit(alert)
  }

  const onDelete = () => {
    props.onDelete(alert)
  }

  return (
    <React.Fragment key={alert.id}>
      <div className={classes.alert}>
        <Typography className={classes.description} variant="body2">
          {alert.description}
        </Typography>
        <IconButton size="small" onClick={onEdit}>
          <Icon>edit</Icon>
        </IconButton>
        <IconButton size="small" onClick={onDelete}>
          <Icon>delete</Icon>
        </IconButton>
      </div>
      <Divider />
    </React.Fragment>
  )
}
SchedulingAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default SchedulingAlert
