import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { showError } from '~/utils/alertOnError'
import { saveComplete } from '../data/currentAttachments'
import {
  ATTACHMENT_TYPE_CHANGED,
  saveFileAttachmentType,
} from '../data/newAttachment'

const attachmentTypeChangedEpic = ({ id, attachmentType }) =>
  from(saveFileAttachmentType(id, attachmentType)).pipe(
    map(saveComplete),
    catchError(showError('Failed to update attachment type'))
  )

export default action$ =>
  action$.pipe(
    ofType(ATTACHMENT_TYPE_CHANGED),
    mergeMap(attachmentTypeChangedEpic)
  )
