import { List } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { getRoot, typePrefixCreator } from './common/shared'
import { fetchConfig } from './config'

const CONTACTS = 'contacts'

const typePrefix = typePrefixCreator(CONTACTS)
export const CONTACTS_CLEARED = type(typePrefix, 'CONTACTS_CLEARED')
export const contactsCleared = creator(CONTACTS_CLEARED)

export default createReducer(CONTACTS, List(), {
  [fetchConfig.SUCCEEDED]: (_state, { payload: { contacts } }) => contacts,
  [CONTACTS_CLEARED]: () => List(),
})

export const getContacts = compose(get(CONTACTS), getRoot)
