import { Record } from 'immutable'
import { ofType } from 'redux-observable'
import { open } from 'redux-routable'
import { map, pluck } from 'rxjs/operators'
import { PATIENT_RECORD_CALL } from '~/apps/patientRecord/router'
import { calcFormData, getTaggedValue } from '~/components/JsonForm'
import { isRequestPending } from '~/data/pending'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import CALL from '../key'

// Key
const FORM = 'form'

// Type
const typePrefix = `${CALL}/${FORM}`
const creator = scopedCreator(typePrefix)

// Actions
export const formCancelled = creator('CANCELLED')

export const formEntered = creator('ENTERED', ['patientId'])

export const formErrored = creator('ERRORED')

export const formUpdated = creator('UPDATED', ['formData', 'errored'])

// Record
export const Form = Record({
  context: {},
  errored: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
  type: null,
})

// Requests
export const completeForm = Request({
  typePrefix,
  typeBase: 'COMPLETION',
  requestParams: ['data', 'patientId', 'type'],
  operation: (data, patientId, type) =>
    AspireAPI.post(`/v1/patients/${patientId}/patient_form`, {
      ...data,
      params: { type },
    }),
  messages: {
    failed: 'Failed to log call',
    succeeded: 'Call logged',
  },
})

export const fetchForm = Request({
  typePrefix,
  typeBase: 'FORM_FETCH',
  requestParams: ['patientId', 'type', 'syncWithMDX'],
  operation: (patientId, type, syncWithMDX = false) =>
    AspireAPI.get(`v1/patients/${patientId}/patient_form/form`, {
      params: { type, sync_with_mdx: syncWithMDX },
    }),
  transform: ({ data: formData, ...form }) =>
    Form({
      ...form,
      formData: calcFormData({
        context: form.context,
        formData,
        schema: form.schema,
        tags: form.tags,
      }),
    }),
  messages: {
    failed: 'Failed to load call log form',
  },
})

export const syncContactsInCallForm = Request({
  typePrefix,
  typeBase: 'SYNC_CONTACTS_IN_CALL_FORM',
  requestParams: ['patientId', 'type', 'syncWithMDX'],
  operation: (patientId, type, syncWithMDX = false) =>
    AspireAPI.get(`v1/patients/${patientId}/patient_form/form`, {
      params: { type, sync_with_mdx: syncWithMDX },
    }),
  messages: {
    failed: 'Could not Sync Contacts',
  },
})

// Reducer
export default createReducer(FORM, null, {
  [completeForm.SUCCEEDED]: () => null,
  [fetchForm.SUCCEEDED]: (_state, action) =>
    action.payload.set('type', action.meta.request.payload.type),
  [syncContactsInCallForm.SUCCEEDED]: (state, action) =>
    state.set('schema', action.payload.schema),
  [formCancelled]: () => null,
  [formErrored]: (state, action) => state.set('errored', action.payload),
  [formUpdated]: (state, { payload: { errored, formData } }) =>
    state
      .set(
        'formData',
        calcFormData({
          context: state.context,
          formData,
          schema: state.schema,
          tags: state.tags,
        })
      )
      .set('errored', errored),
})

export const getFormDataByTag = (state, tag, options) => {
  const form = getForm(state)
  return getTaggedValue(form, tag, options)
}

export const getHistoricalFormDataByTag = form => (_state, tag, options) =>
  getTaggedValue(form, tag, options)

// Selectors
export const getForm = getIn([CALL, FORM])

const isPending = request => state => isRequestPending(state, request)

export const isFormPending = isPending(fetchForm)

export const isSyncContactsPending = isPending(syncContactsInCallForm)

export const isFormSubmitting = isPending(completeForm)

// Epic
const formEnteredEpic = action$ =>
  action$.pipe(
    ofType(formEntered),
    pluck('payload', 'patientId'),
    map(patientId => open(PATIENT_RECORD_CALL, { patientId }))
  )

export const epic = formEnteredEpic
