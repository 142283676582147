import cx from 'classnames'
import React from 'react'
import { H6 } from '~/components/CustomTypography'
import PropTypes from '~/utils/propTypes'
import { Button, Divider, Icon, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Cancel, Person, Snooze } from '@material-ui/icons'
import { CANCEL, REASSIGN, SNOOZE } from '../constants'
import ConfirmationDialog from '../containers/ConfirmationDialog'
import MissingTask from './MissingTask'
import TaskForm from './TaskForm'
import TaskHistory from './TaskHistory'
import TaskInfo from './TaskInfo'

class TaskDetail extends React.Component {
  static propTypes = {
    createTaskForm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    dialogOpened: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired,
    syncContactsPending: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    reassignTaskDialogOpened: PropTypes.func.isRequired,
    task: PropTypes.record,
    taskFormUpdated: PropTypes.func.isRequired,
    taskFormErrored: PropTypes.func.isRequired,
    taskSaved: PropTypes.func.isRequired,
    syncContactsInTaskForm: PropTypes.func.isRequired,
  }

  onSyncContacts = () => this.props.syncContactsInTaskForm(this.props.task.id)
  start = () => this.props.createTaskForm(this.props.task.id)
  save = () => this.props.taskSaved(this.props.task.id)
  openDialog = event => this.props.dialogOpened(event.currentTarget.value)

  update = (formData, errored) => {
    const {
      task: { id },
      taskFormUpdated,
    } = this.props

    taskFormUpdated(id, formData, errored)
  }

  onFormErrored = () => {
    const {
      task: { id },
      taskFormErrored,
    } = this.props
    taskFormErrored(id)
  }

  openReassignTaskDialog = event => {
    const {
      task: { patientId },
      reassignTaskDialogOpened,
    } = this.props

    this.openDialog(event)
    reassignTaskDialogOpened(patientId)
  }

  render() {
    const {
      classes,
      dialogOpened,
      pending,
      syncContactsPending,
      readOnly,
      reassignTaskDialogOpened,
      task,
    } = this.props

    return task ? (
      <Paper className={classes.paper}>
        <section className={cx(classes.header, classes.bottomMargin)}>
          <H6>{task.title}</H6>
        </section>
        <Divider />
        <TaskInfo task={task} />
        <section className={classes.bottomMargin}>
          {task.form ? (
            <TaskForm
              onChange={this.update}
              onSave={this.save}
              onError={this.onFormErrored}
              readOnly={readOnly}
              task={task}
              dialogOpened={dialogOpened}
              reassignTaskDialogOpened={reassignTaskDialogOpened}
              syncContactsPending={syncContactsPending}
              onSyncContacts={this.onSyncContacts}
            />
          ) : (
            !readOnly && (
              <div className={classes.buttons}>
                <Button
                  disabled={pending}
                  className={classes.button}
                  onClick={this.start}
                  variant="outlined"
                >
                  <Icon className={classes.icon}>playlist_play</Icon>
                  Start
                </Button>
                <Button
                  disabled={pending}
                  onClick={this.openReassignTaskDialog}
                  value={REASSIGN}
                >
                  <Person className={classes.icon} />
                  Reassign
                </Button>
                <Button
                  disabled={pending}
                  onClick={this.openDialog}
                  value={SNOOZE}
                >
                  <Snooze className={classes.icon} />
                  Snooze
                </Button>
                <Button
                  disabled={pending}
                  className={classes.cancel}
                  onClick={this.openDialog}
                  value={CANCEL}
                >
                  <Cancel className={classes.icon} />
                  Cancel
                </Button>
              </div>
            )
          )}
        </section>
        <Divider />
        <TaskHistory history={task.history} />
        <ConfirmationDialog task={task} />
      </Paper>
    ) : pending ? (
      <div />
    ) : (
      <MissingTask />
    )
  }
}

const styles = ({ spacing }) => ({
  buttons: {
    marginTop: spacing(2),
    textAlign: 'right',
    '& button': {
      marginRight: spacing(1),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomMargin: {
    marginBottom: spacing(1),
  },
  paper: {
    margin: [spacing(2), 0],
    padding: spacing(4),
  },
  progress: {
    margin: -spacing(1) * 3,
  },
})

export default withStyles(styles)(TaskDetail)
