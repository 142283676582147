import { ofType } from 'redux-observable'
import { of, zip } from 'rxjs'
import {
  catchError,
  map,
  mapTo,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
import { cacheFailed, cacheRequested, cacheSucceeded } from '~/data/cache'
import { fetchFieldValues } from '~/data/fieldValues'
import { fetchUser, getUserId } from '~/data/session'
import { fetchActionableItems } from '~/features/actionableItems'
import { fetchAlerts } from '~/features/patientAlerts'
import { fetchPatient } from '~/features/patientInfo'
import { fetchPatientTimeline } from '~/features/patientTimeline/data'
import {
  fetchClaimsProblemTypes,
  fetchProblemCategories,
  fetchProblemDetailTypeMappings,
} from '~/features/problems/data'
import { fetchAssessment } from '../data/assessments'
import { fetchLegacyAssessment } from '../data/legacyAssessments'

// Cache responses needed for Assessments
// Each operation should accurately reflect the request that will be made on the Assessment
export default (action$, state$) =>
  action$.pipe(
    ofType(cacheRequested),
    pluck('payload'),
    withLatestFrom(state$),
    map(([payload, state]) => [payload, getUserId(state)]),
    mergeMap(([{ id, patientId }, userId]) =>
      zip(
        fetchActionableItems.operation(patientId),
        fetchAlerts.operation(patientId),
        fetchAssessment.operation(id, true),
        fetchClaimsProblemTypes(id),
        // To cache the expected responses, we must request these separately
        ...[
          // Order is important inside arrays
          [
            'alert_severity',
            'alert_type',
            'alert_type_no_cross_coverage_reason',
          ],
          ['assessment_cancellation_reason'],
          ['encounter_assessment_status'],
          ['ntvd_change'],
          ['problem_status'],
        ].map(fetchFieldValues.operation),
        fetchPatient.operation(patientId).then(({ lastEncounters }) => {
          const lastEncounter = lastEncounters.sort((a, b) =>
            a.dateOfService < b.dateOfService ? 1 : -1
          )[0]

          if (lastEncounter) {
            return lastEncounter.type.includes('legacy')
              ? fetchLegacyAssessment.operation(lastEncounter.encounterId)
              : fetchAssessment.operation(lastEncounter.encounterId)
          }
        }),
        fetchPatientTimeline.operation(patientId),
        fetchProblemCategories(),
        fetchProblemDetailTypeMappings.operation(),
        fetchUser.operation(userId),
        fetch(`/assessment/${id}`)
      ).pipe(
        mapTo(cacheSucceeded()),
        catchError(error => of(cacheFailed(error, id)))
      )
    )
  )
