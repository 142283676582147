import React from 'react'
import { connect } from 'react-redux'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import StatusCount from '../components/StatusCount'
import { getCounts } from '../data/census'

const styles = ({ spacing }) => ({
  countContainer: {
    display: 'flex',
    marginBottom: spacing(1),
    flexWrap: 'wrap',
  },
  smallCountContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const CensusCounts = ({ classes, counts }) => (
  <div>
    <div className={classes.countContainer}>
      <StatusCount label="Active" value={counts.active} />
      <StatusCount label="Scheduled" value={counts.scheduled} />
      <Tooltip
        title="Patients remain on the census list for two weeks after discharge"
        placement="top"
      >
        <StatusCount label="Recently Discharged" value={counts.discharged} />
      </Tooltip>
    </div>
    <div className={classes.smallCountContainer}>
      <StatusCount
        label="Limited Prognoses"
        value={counts.limitedPrognosis}
        compact
      />
      <StatusCount
        label="Active Hospitalizations"
        value={counts.activeHospitalization}
        compact
      />
      <StatusCount
        label="Recent Hospitalizations"
        value={counts.recentHospitalization}
        compact
      />
      <StatusCount label="Active SNF's" value={counts.activeSnf} compact />
      <StatusCount label="Recent SNF's" value={counts.recentSnf} compact />
      <StatusCount label="Overdue" value={counts.overdue} compact />
      <StatusCount label="Hotspotters" value={counts.hotspotter} compact />
      <StatusCount
        label="Recommended For Discharge"
        value={counts.recommendedForDischarge}
        compact
      />
      <StatusCount label="ACP Documents" value={counts.acpDocuments} compact />
    </div>
  </div>
)

CensusCounts.propTypes = {
  classes: PropTypes.object.isRequired,
  counts: PropTypes.object.isRequired,
}

export default compose(
  connect(state => ({
    counts: getCounts(state),
  })),
  withStyles(styles)
)(CensusCounts)
