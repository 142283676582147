import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Dialog, DialogTitle } from '@material-ui/core'
import TaskDialogActions from './TaskDialogActions'

export default class CompleteTaskDialog extends React.Component {
  static propTypes = {
    dialogClosed: PropTypes.func.isRequired,
    task: PropTypes.record.isRequired,
    taskCompleteRequested: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
  }

  onSubmit = () => {
    const {
      task: { id, status },
      taskCompleteRequested,
    } = this.props

    taskCompleteRequested(id, status)
  }

  render() {
    const { dialogClosed, open, pending } = this.props

    return (
      <Dialog
        aria-labelledby="complete-task-form"
        onClose={dialogClosed}
        open={open}
      >
        <DialogTitle id="complete-task-form">Complete this task?</DialogTitle>
        <TaskDialogActions
          disabled={pending}
          onCancel={dialogClosed}
          onSubmit={this.onSubmit}
        />
      </Dialog>
    )
  }
}
