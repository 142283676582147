import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import FileInput from '~/components/fields/FileInput'
import ImagePDF from '~/components/fields/ImagePDF'
import {
  PatientInfo,
  getPatientInfo,
} from '~/features/scheduling/data/patientInfo'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import AttachmentTypeSelector from '../components/AttachmentTypeSelect'
import AttachmentsFilter from '../components/AttachmentsFilter'
import AttachmentsTable from '../components/AttachmentsTable'
import UploadConfirmation from '../components/UploadConfirmation'
import {
  getAttachmentTypes,
  getOrderedAttachmentTypes,
} from '../data/attachmentTypes'
import { downloadAttachment, getAttachments } from '../data/currentAttachments'
import { getAttachmentDocumentById } from '../data/fetchedDocument'
import {
  Attachment,
  fileSelected,
  getAttachment,
  newAttTypeSelected,
  onLoadAttachments,
  updateAttachmentType,
} from '../data/newAttachment'

const styles = ({ spacing }) => ({
  button: {
    marginRight: spacing(3),
    marginTop: 13,
    maxHeight: 36,
    padding: [0, spacing(4)],
  },
  buttonPDF: {
    marginRight: spacing(3),
    marginTop: 13,
    maxHeight: 36,
    width: '30rem',
    padding: 0,
  },
  fileSpacer: {
    paddingRight: spacing(1),
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    paddingRight: spacing(3),
  },
})

class AttachmentWidget extends PureComponent {
  state = {
    completed: 0,
    filter: '',
    dialogOpen: false,
    file: {},
    faxToPCP: false,
    multiPhotoOpen: false,
  }

  UNSAFE_componentWillMount() {
    if (this.props.patientId) {
      this.props.onLoad(this.props.patientId)
    }
  }

  componentDidUpdate() {
    const {
      newAttachment: { attachmentType },
      defaultType,
      attachmentTypes,
    } = this.props

    if (!attachmentType && defaultType) {
      const value = this.getAttachmentTypeValue(attachmentTypes, defaultType)
      value && this.props.newAttTypeSelected(value)
    }
  }
  getAttachmentTypeValue = (attachmentTypes, defaultType) => {
    const list = attachmentTypes
      .toList()
      .toJS()
      .filter(a => a.id == defaultType || a.name == defaultType)
    return list[0] ? list[0].id : undefined
  }

  toggleConfirm = () => {
    this.setState(prevState => ({ dialogOpen: !prevState.dialogOpen }))
  }

  toggleOpenMultiPhoto = () => {
    this.setState(prevState => ({ multiPhotoOpen: !prevState.multiPhotoOpen }))
  }

  isIOSDevice = () => {
    const { platform, userAgent, maxTouchPoints } = navigator

    if (/iPad|iPhone/.test(platform)) {
      return true
    }
    if (userAgent.indexOf('Macintosh') > -1) {
      try {
        document.createEvent('TouchEvent')
        return true
      } catch (e) {
        // continue regardless of error
      }
    }
    return maxTouchPoints > 2 && /MacIntel/.test(platform)
  }

  onFilterChange = ({ target: { value } }) => this.setState({ filter: value })

  onTypeSelected = ({ target: { value } }) => {
    this.setState({ completed: 0 })
    this.props.newAttTypeSelected(value)
  }

  handleChange = file => {
    if (file) {
      this.setState({ file })
    }
  }

  beginUpload = () => {
    const { file, faxToPCP } = this.state
    const {
      newAttachment: { attachmentType },
      patientId,
    } = this.props
    const progressCallback = this.onUploadProgress
    this.toggleConfirm()
    return this.props.onFileSelected({
      faxToPCP,
      file,
      attachmentType,
      progressCallback,
      patientId,
    })
  }

  onUploadProgress = ({ loaded, total }) => {
    if (loaded / total == 1) {
      this.setState({ file: {} })
    }

    this.setState({ completed: (100 * loaded) / total })
  }

  toggleFaxToPCP = () => {
    this.setState(prevState => ({
      faxToPCP: !prevState.faxToPCP,
    }))
  }

  UNSAFE_componentWillReceiveProps = ({ newAttachment: { displayName } }) => {
    // Clears the file input when the attachment is uploaded
    if (displayName === this.props.newAttachment.displayName && this.fileInput)
      this.fileInput.value = ''
  }

  render() {
    const { completed, file, filter, dialogOpen, multiPhotoOpen } = this.state
    const {
      newAttachment,
      updateAttachmentType,
      classes,
      attachmentTypes,
      attachments,
      patient,
      orderedAttachmentTypes,
      onAttachmentClick,
      getAttachmentDocument,
    } = this.props
    const uploading = completed !== 0 && completed !== 100

    const IMAGE_LIMIT = 10

    return (
      <div>
        <DevXTitleHeader title="Attachments" />
        {uploading && (
          <LinearProgress
            mode="determinate"
            value={completed}
            valueBuffer={completed}
          />
        )}
        <div className={classes.selectContainer}>
          <AttachmentTypeSelector
            className={classes.spacer}
            disabled={uploading}
            selected={newAttachment.attachmentType}
            handleChange={this.onTypeSelected}
            types={orderedAttachmentTypes}
          />
          {this.isIOSDevice() && (
            <Button
              className={classes.buttonPDF}
              onClick={this.toggleOpenMultiPhoto}
              variant="outlined"
              disabled={newAttachment.attachmentType === ''}
            >
              Create PDF
            </Button>
          )}

          <FileInput
            className={classes.fileSpacer}
            disabled={newAttachment.attachmentType === '' || uploading}
            onChange={this.handleChange}
            fullWidth
            file={file}
            margin="normal"
          />
          <Button
            className={classes.button}
            onClick={this.toggleConfirm}
            disabled={!file.name || uploading}
            variant="outlined"
          >
            Upload
          </Button>
          {multiPhotoOpen && (
            <ImagePDF
              className={classes.fileSpacer}
              disabled={newAttachment.attachmentType === ''}
              onChange={this.handleChange}
              fullWidth
              file={file}
              margin="normal"
              open={multiPhotoOpen}
              onClose={this.toggleOpenMultiPhoto}
              imageLimit={IMAGE_LIMIT}
              patientId={this.props.patientId}
              attachmentType={newAttachment.attachmentType}
            />
          )}
          <AttachmentsFilter handleChange={this.onFilterChange} />
        </div>

        <AttachmentsTable
          attachmentTypes={attachmentTypes}
          orderedAttachmentTypes={orderedAttachmentTypes}
          attachments={attachments}
          updateAttachmentType={updateAttachmentType}
          filter={filter}
          onAttachmentClick={onAttachmentClick}
          getAttachmentDocument={getAttachmentDocument}
        />
        <UploadConfirmation
          open={dialogOpen}
          fileName={file.name}
          confirm={this.beginUpload}
          cancel={this.toggleConfirm}
          insurance={patient.insurance}
          faxToPCP={this.state.faxToPCP}
          toggleFaxToPCP={this.toggleFaxToPCP}
          type={attachmentTypes.getIn(
            [newAttachment.attachmentType, 'name'],
            ''
          )}
        />
      </div>
    )
  }
}

AttachmentWidget.propTypes = {
  updateAttachmentType: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  newAttTypeSelected: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  attachmentTypes: PropTypes.map,
  attachments: PropTypes.map,
  newAttachment: PropTypes.instanceOf(Attachment),
  patient: PropTypes.instanceOf(PatientInfo),
  orderedAttachmentTypes: PropTypes.array,
  defaultType: PropTypes.string,
  patientId: PropTypes.string,
  onLoad: PropTypes.func,
  getAttachmentDocument: PropTypes.func,
  onAttachmentClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  newAttachment: getAttachment(state),
  attachments: getAttachments(state),
  attachmentTypes: getAttachmentTypes(state),
  patient: getPatientInfo(state),
  orderedAttachmentTypes: getOrderedAttachmentTypes(state),
})

const mapDispatchToProps = {
  newAttTypeSelected: newAttTypeSelected,
  onFileSelected: fileSelected,
  onLoad: onLoadAttachments,
  updateAttachmentType: updateAttachmentType,
  getAttachmentDocument: getAttachmentDocumentById.requested,
  onAttachmentClick: downloadAttachment.requested,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AttachmentWidget))
