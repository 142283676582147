// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import { addBenefitDialogClosed } from './addBenefitDialog'
import { TransportationBenefits } from './interfaces'

// KEY
const key = 'MemberBenefitData'
export const getBenefitData = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)
export const editBenefitDialogOpened = creator('FETCH_MEMBER_BENEFIT_DATA')

// REDUCER
const benefit: TransportationBenefits = {
  benefitId: '',
  classplanProductId: '',
  benefitYear: '',
  pbp: '',
  countyName: '',
  classplanProductName: '',
  destinationType: '',
  benefitLimit: '',
}
export const memberBenefitData = createReducer(key, benefit, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [editBenefitDialogOpened]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [addBenefitDialogClosed]: () => benefit,
})
