import { useSelector } from 'react-redux'
import { getEvents } from '../data'

export default (event, type) => {
  const events = useSelector(getEvents)

  return events
    .toArray()
    .filter(e => e.recordType === type && e.startDate === event.startDate)
}
