import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'polygons'

export const FETCHED_POLYGONS = type(rootKey, 'FETCHED_POLYGONS')

export const loadPolygons = creator(FETCHED_POLYGONS, 'polygons')

export default createReducer(key, new Map(), {
  [FETCHED_POLYGONS]: (state, { payload: { polygons } }) => normalize(polygons),
})

export const getPolygons = pipe(getRoot, get(key))

export const fetchPolygons = (marketIds, programs) =>
  AspireAPI.get('polygonator/polygons', {
    params: { marketIds: marketIds.toJS(), programs: programs.toJS() },
  })
