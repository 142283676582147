import React from 'react'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import JsonForm from '~/components/JsonForm'
import SyncContactsField from '~/components/JsonForm/fields/SyncContactsField'
import PropTypes from '~/utils/propTypes'
import { removeUnderscores, titleCase } from '~/utils/stringManipulation'
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Call } from '../data/calls'
import { Form, getFormDataByTag } from '../data/form'
import CallGrid from './CallGrid'
import * as fields from './fields'

const styles = ({ spacing }) => ({
  button: {
    marginLeft: spacing(1),
  },
  formPaper: {
    marginBottom: spacing(2),
    padding: spacing(2),
  },
  gridPaper: {
    padding: spacing(2),
  },
  icon: { marginRight: spacing(1) },
  panelButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

class Calls extends React.PureComponent {
  onCancel = () => this.props.formCancelled()

  onChange = ({ errors, errorSchema, formData }) => {
    let shouldClearError = false
    const errorKeys = Object.keys(errorSchema)

    if (errorSchema.reasonForCall) {
      const {
        choices,
        clinicalSelected,
        nonclinicalSelected,
        noticeofeventSelected,
      } = formData?.reasonForCall?.callType

      const numberSelected = [
        clinicalSelected,
        nonclinicalSelected,
        noticeofeventSelected,
      ].filter(i => i).length

      shouldClearError = !(choices.length === numberSelected)
    }

    const errorsExist =
      errorKeys.length > 1 || !errorKeys.includes('reasonForCall')
        ? Boolean(errors.length)
        : !shouldClearError

    return this.props.formUpdated(formData, errorsExist)
  }

  onError = errors => this.props.formErrored(Boolean(errors.length))

  onSubmit = ({ formData }) =>
    this.props.formCompletionRequested(
      {
        data: formData,
        context: this.props.form.context,
        type: this.props.form.type,
      },
      this.props.patientId
    )

  onSyncContacts = () =>
    this.props.syncContactsInCallForm(
      this.props.patientId,
      this.props.form.type,
      true
    )

  displayTitle = () =>
    this.props.form.type === 'cmrn_call'
      ? 'CMRN Call'
      : titleCase(removeUnderscores(this.props.form.type))

  render() {
    const {
      classes,
      form,
      onPanelOpen,
      submitting,
      calls,
      syncContactsPending,
    } = this.props

    return (
      <React.Fragment>
        <DevXTitleHeader title="Call Events">
          <Tooltip title="Open Panel" placement="bottom">
            <IconButton onClick={onPanelOpen}>
              <Icon>chrome_reader_mode</Icon>
            </IconButton>
          </Tooltip>
        </DevXTitleHeader>
        {form && (
          <Paper className={classes.formPaper}>
            <Typography variant="h5">New {this.displayTitle()} Form</Typography>
            {syncContactsPending ? (
              <CircularProgress />
            ) : (
              <JsonForm
                debounce
                context={form.context}
                formData={form.formData}
                getFormDataByTag={getFormDataByTag}
                onChange={this.onChange}
                onError={this.onError}
                onSubmit={this.onSubmit}
                fields={{
                  ...fields,
                  syncContactsButton: fieldProps => {
                    return SyncContactsField({
                      ...fieldProps,
                      onSyncContacts: this.onSyncContacts,
                    })
                  },
                }}
                schema={form.schema}
                uiSchema={{
                  ...form.uiSchema,
                  'ui:disabled': submitting,
                }}
              >
                {form.errored && (
                  <Typography color="error" variant="body1">
                    Errors exist on this form.
                  </Typography>
                )}
                <Button
                  className={classes.button}
                  onClick={this.onCancel}
                  variant="outlined"
                >
                  <Icon className={classes.icon}>cancel</Icon> Cancel
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={form.errored || submitting}
                  type="submit"
                  variant="contained"
                >
                  <Icon className={classes.icon}>done</Icon>
                  Complete
                </Button>
              </JsonForm>
            )}
          </Paper>
        )}
        <Paper className={classes.gridPaper}>
          <CallGrid calls={calls} />
        </Paper>
      </React.Fragment>
    )
  }
}

Calls.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.instanceOf(Form),
  formCancelled: PropTypes.func.isRequired,
  formErrored: PropTypes.func.isRequired,
  formCompletionRequested: PropTypes.func.isRequired,
  formUpdated: PropTypes.func.isRequired,
  onPanelOpen: PropTypes.func.isRequired,
  syncContactsInCallForm: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  calls: PropTypes.arrayOf(Call).isRequired,
  syncContactsPending: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Calls)
