import { Record } from 'immutable'
import { Location } from '~/features/locationSearch'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getEventsRoot, patientCleared } from './root'

const PATIENT_INFO = 'patientInfo'

const PatientInfo = Record({
  id: null,
  text: null,
  name: null,
  status: null,
  subStatus: null,
  market: null,
  nextTargetedVisitModality: null,
  location: Location(),
  visitType: null,
  visitLength: null,
  patientContactId: null,
  patientContactInfo: null,
  phone: null,
  email: null,
  textingApproved: false,
  virtualCapable: false,
  virtualCapableLabel: null,
  internetAccessAvailable: null,
  videoDevicesAvailable: null,
  videoDevicesAvailableOther: null,
  isCaseMgmt: null,
})

const transformPatientInfo = ({
  address,
  apartment,
  lat,
  lng,
  locationExact,
  ...patient
}) => {
  const apartmentCheck = apartment === '' ? null : apartment

  return PatientInfo({
    location: Location({
      address,
      apartment: apartmentCheck,
      latitude: lat,
      longitude: lng,
      exact: locationExact,
    }),
    ...patient,
  })
}

export const fetchPatientInfo = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_INFO',
  requestParams: ['patientId', 'ownerId'],
  operation: (patientId, ownerId, cancelToken) =>
    AspireAPI.get(`v1/patients/${patientId}/scheduling_info`, {
      params: { ownerId },
      cancelToken,
    }),
  transform: transformPatientInfo,
  messages: {
    failed: 'There was an issue fetching patient info',
  },
})

const creator = scopedCreator(rootKey)
export const patientInfoCleared = creator('PATIENT_INFO_CLEARED')

const initState = PatientInfo()
export default createReducer(PATIENT_INFO, initState, {
  [fetchPatientInfo.SUCCEEDED]: (_state, { payload }) => payload,
  [patientInfoCleared]: () => initState,
  [patientCleared]: () => initState,
})

export const getPatientInfo = pipe(getEventsRoot, get(PATIENT_INFO))

export const getPatientVirtualCapableError = patientInfo =>
  [
    !patientInfo.phone &&
      !patientInfo.email &&
      'Patient preferred contact is missing phone number or email.',
    !patientInfo.textingApproved &&
      !patientInfo.email &&
      patientInfo.phone &&
      'Patient preferred contact must have an email or a texting approved phone number.',
    !(patientInfo.internetAccessAvailable?.length > 0) &&
      "The Patient's record indicates that the patient does not have internet access.",
    !(patientInfo.videoDevicesAvailable?.length > 0) &&
      !patientInfo.videoDevicesAvailableOther &&
      "The Patient's record indicates that the patient does not have a video capable device.",
  ]
    .filter(Boolean)
    .join(' ')
