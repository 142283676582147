import { combineEpics, ofType } from 'redux-observable'
import { merge } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  pluck,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import {
  careTeamMemberTasksFetchRequested,
  fetchTasks,
  ownerTasksFetchRequested,
} from '../data/tasks'
import {
  CANCELLED_TOGGLED,
  COMPLETED_TOGGLED,
  getToggles,
} from '../data/toggles'
import { USER_ID_CHANGED, userIdChanged } from '../data/userId'

const storeRequestedIdEpic = action$ =>
  action$.pipe(
    ofType(ownerTasksFetchRequested),
    pluck('payload', 'userId'),
    filter(Boolean),
    distinctUntilChanged(),
    map(userIdChanged)
  )

const onRequestOwnerTasksEpic = (action$, state$) =>
  action$.pipe(
    ofType(ownerTasksFetchRequested),
    pluck('payload', 'userId'),
    withLatestFrom(state$),
    mergeMap(([userId, state]) => {
      const toggleState = getToggles(state)

      return [
        fetchTasks.requested({ ownerId: userId, open: !toggleState.completed }),
        fetchTasks.requested({
          requestedById: userId,
          open: !toggleState.completed,
        }),
      ]
    })
  )

// only load completed or cancelled tasks on first toggle
const loadOnce = (action$, state$) =>
  action$.pipe(
    ofType(USER_ID_CHANGED),
    pluck('payload', 'userId'),
    switchMap(userId =>
      merge(
        action$.pipe(
          ofType(careTeamMemberTasksFetchRequested),
          first(),
          map(() => fetchTasks.requested({ careTeamMemberId: userId }))
        ),
        action$.pipe(
          ofType(COMPLETED_TOGGLED, CANCELLED_TOGGLED),
          first(),
          switchTo(state$),
          mergeMap(() => [
            fetchTasks.requested({
              ownerId: userId,
            }),
            fetchTasks.requested({
              requestedById: userId,
            }),
          ])
        )
      )
    )
  )

export default combineEpics(
  onRequestOwnerTasksEpic,
  storeRequestedIdEpic,
  loadOnce
)
