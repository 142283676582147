import { combineEpics } from 'redux-observable'
import loadPotentialAPPs from './epics/potentialAPPs'
import loadPotentialChannels from './epics/potentialChannels'
import loadPotentialMarkets from './epics/potentialMarkets'

export default combineEpics(
  loadPotentialAPPs,
  loadPotentialChannels,
  loadPotentialMarkets
)
