import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import MaskedInput from 'react-text-mask'
import TextField from './TextField'

const MaskedPhoneNumberField = ({ inputRef, ...props }) => {
  const setRef = useCallback(
    ref => {
      inputRef(ref ? ref.inputElement : null)
    },
    [inputRef]
  )

  return (
    <MaskedInput
      {...props}
      ref={setRef}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  )
}

MaskedPhoneNumberField.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

const PhoneNumberField = ({ label, ...props }) => (
  <TextField
    {...props}
    label={label}
    InputProps={{ inputComponent: MaskedPhoneNumberField }}
  />
)

PhoneNumberField.propTypes = {
  label: PropTypes.string,
}

PhoneNumberField.defaultProps = {
  label: 'Phone Number',
}

export default PhoneNumberField
