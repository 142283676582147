import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../../widgets/memoizedWrapper'

const Tasks = props => {
  const { formContext, registry, schema } = props

  const tasks = useMemo(() => Object.values(formContext.context.tasks || {}), [
    formContext,
  ])
  const taskIds = useMemo(() => tasks.map(({ id }) => id), [tasks])
  const taskNames = useMemo(() => tasks.map(({ name }) => name), [tasks])

  return (
    <registry.fields.ArrayField
      {...props}
      schema={{
        ...schema,
        items: {
          ...schema.items,
          enum: taskIds,
          enumNames: taskNames,
        },
      }}
    />
  )
}

Tasks.propTypes = {
  formContext: PropTypes.shape({
    context: PropTypes.shape({
      tasks: PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  registry: PropTypes.shape({
    fields: PropTypes.shape({
      ArrayField: PropTypes.elementType.isRequired,
    }).isRequired,
  }).isRequired,
  schema: PropTypes.object.isRequired,
}

export default memoizedWrapper(Tasks)
