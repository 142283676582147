import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
import rootKey from '../key'

const transformErrorResponse = (error: any) => {
  const genericSaveError = 'Could not add careplan goal'
  return error?.response?.data?.message?.label[0] || genericSaveError
}

const transformEditErrorResponse = (error: any) => {
  const genericSaveError = 'Could not update careplan goal'
  return error?.response?.data?.message?.label[0] || genericSaveError
}

export const addCareplanGoal = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_CAREPLAN_GOAL',
  requestParams: ['goalInputs'],
  operation: (goalInputs: any) =>
    AspireAPI.post('admin_tools/goal', {
      ...goalInputs,
    }),
  messages: {
    succeeded: 'Careplan Goal added successfully',
    failed: (error: any) => transformErrorResponse(error),
  },
})

export const editCareplanGoal = Request({
  typePrefix: rootKey,
  typeBase: 'EDIT_CAREPLAN_GOAL',
  requestParams: ['goalInputs'],
  operation: (goalInputs: any) =>
    AspireAPI.post('admin_tools/goal', {
      ...goalInputs,
    }),
  messages: {
    succeeded: 'Careplan Goal updated successfully',
    failed: (error: any) => transformEditErrorResponse(error),
  },
})
