import React from 'react'
import PropTypes from '~/utils/propTypes'
import FieldWrapper from './FieldWrapper'
import memoizedWrapper from './widgets/memoizedWrapper'

const ObjectFieldTemplate = ({ properties, schema, uiSchema }) => (
  <FieldWrapper schema={schema} uiSchema={uiSchema}>
    {properties.map(({ content }) => content)}
  </FieldWrapper>
)

ObjectFieldTemplate.propTypes = {
  properties: PropTypes.array.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
}

export default memoizedWrapper(ObjectFieldTemplate)
