import { boolean, Codec, string, number, GetType, array } from 'purify-ts'
import AspireAPI from '~/resources/aspire'
import { createRequest } from '~/utils/createRequest'

const CarePlanActionType = Codec.interface({
  id: number,
  label: string,
  autoPopulate: boolean,
})
const CarePlanActionTypes = array(CarePlanActionType)
export type CarePlanActionTypes = GetType<typeof CarePlanActionTypes>

const CarePlanGoalType = Codec.interface({
  id: number,
  label: string,
  autoPopulate: boolean,
  carePlanActionTypes: CarePlanActionTypes,
})
type CarePlanGoalType = GetType<typeof CarePlanGoalType>
export const CarePlanGoalTypes = array(CarePlanGoalType)
export type CarePlanGoalTypes = GetType<typeof CarePlanGoalTypes>

export const fetchCarePlanGoalTypes = createRequest<
  [problemTypeId: number],
  CarePlanGoalTypes
>(
  problemTypeId =>
    AspireAPI.get(
      `care_plan/problem_types/${problemTypeId}/care_plan_goal_types`
    ),
  CarePlanGoalTypes
)

export const fetchCarePlanGoalType = createRequest<
  [carePlanGoalTypeId: number],
  CarePlanGoalType
>(
  carePlanGoalTypeId =>
    AspireAPI.get(
      `care_plan/problem_types/care_plan_goal_types/${carePlanGoalTypeId}`
    ),
  CarePlanGoalType
)
