import React from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
// @ts-expect-error no export
import { getPatientName } from '~/features/patientInfo'
import { useAction } from '~/hooks'
import { closeDialog, createNomination, isDialogOpen } from '../data'
import Nomination from './Nomination'

type Props = {
  patientId: string
}

export default function HighRiskHuddleDialog({ patientId }: Props) {
  const patientName = useSelector(getPatientName)
  const open = useSelector(isDialogOpen)
  const onCreateNomination = useAction(createNomination)
  const onClose = useAction(closeDialog)

  const onSave = () => {
    onCreateNomination(patientId)
  }

  return (
    <ActionDialog
      open={open}
      title={`Nominate ${patientName} for High Risk Huddle`}
      mode="save"
      onSave={onSave}
      onClose={onClose}
    >
      <Nomination />
    </ActionDialog>
  )
}
