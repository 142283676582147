import moment from 'moment'
import validate from '~/utils/validate'

const stringRequired = value =>
  value && value.trim().length > 0 ? undefined : 'Required'

const dateCheck = admissionDate => value =>
  value && moment(value) < moment(admissionDate)
    ? 'Before Admission Date'
    : undefined

const otherCheck = dropdownValue => value =>
  dropdownValue === 'Other' ? stringRequired(value) : undefined

export default values => ({
  reason: validate(values.get('reason'), [stringRequired]),
  otherHospitalizationReason: validate(
    values.get('otherHospitalizationReason'),
    [otherCheck(values.get('reason'))]
  ),
  notifiedBy: validate(values.get('notifiedBy'), [stringRequired]),
  notifiedByOther: validate(values.get('notifiedByOther'), [
    otherCheck(values.get('notifiedBy')),
  ]),
  dischargedFromHospitalToOther: validate(
    values.get('dischargedFromHospitalToOther'),
    [otherCheck(values.get('dischargedFromHospitalTo'))]
  ),
  admissionDate: validate(values.get('admissionDate'), [stringRequired]),
  dischargeDate: validate(values.get('dischargeDate'), [
    dateCheck(values.get('admissionDate')),
  ]),
  hospitalName: validate(values.get('hospitalName'), [stringRequired]),
})
