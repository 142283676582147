import React from 'react'
import PropTypes from '~/utils/propTypes'
import CalendarEvent from '../../Events/CalendarEvent'

const UnavailabilityCalendarEvent = ({ event, expanded, ...props }) => (
  <CalendarEvent {...props} event={event} expanded={expanded} />
)

UnavailabilityCalendarEvent.propTypes = {
  event: PropTypes.record.isRequired,
  expanded: PropTypes.bool,
}

export default UnavailabilityCalendarEvent
