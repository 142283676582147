import { connect } from 'react-redux'
import PreferredPharmacy from '../components/PreferredPharmacy'
import { getPatientPharmacyObject } from '../data/patientInformation'

const mapStateToProps = (state, ownProps) => ({
  preferredPharmacy:
    ownProps.preferredPharmacy || getPatientPharmacyObject(state),
})

export default connect(mapStateToProps)(PreferredPharmacy)
