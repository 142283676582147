import { ofType } from 'redux-observable'
import { match } from 'redux-routable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getRoute } from '~/data/route'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { completeCallAttempt } from '~/features/callLogger'
import { callAttemptCompleted } from '../data/patientList'
import { referralSchedulingRouter } from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(completeCallAttempt.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'patientId'),
    withLatestFrom(state$),
    filter(([_, state]) => match(getRoute(state), referralSchedulingRouter)),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([patientId]: [any]) => any' is ... Remove this comment to see the full error message
    map(([patientId]) => callAttemptCompleted(patientId))
  )
