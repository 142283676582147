import { formatISO } from 'date-fns'
import { OrderedMap } from 'immutable'
import { createSelector } from 'reselect'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/notifications' or i... Remove this comment to see the full error message
import { HIGH, NORMAL } from '~/features/notifications'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getNotificationList } from './root'

const NOTIFICATIONS = 'notifications'

const creator = scopedCreator(rootKey)
export const notificationsEmptied = creator('NOTIFICATIONS_EMPTIED', ['data'])
export const notificationsFetched = creator('NOTIFICATIONS_FETCHED', ['data'])
export const notificationsCleared = creator('NOTIFICATIONS_CLEARED', [
  'notificationIds',
])
export const notificationMarkedUnread = creator('NOTIFICATION_MARKED_UNREAD', [
  'notification',
])
export const notificationAdded = creator('NOTIFICATION_ADDED', ['data'])

const initState = OrderedMap()
export default createReducer(NOTIFICATIONS, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [notificationsEmptied]: () => initState,
  // @ts-expect-error can we deal with the toString being automatically called?
  [notificationsFetched]: (state: any, { payload: { data } }: any) =>
    state.merge(data).sortBy(({ createdAt }: any) => createdAt),
  // @ts-expect-error can we deal with the toString being automatically called?
  [notificationsCleared]: (state: any, { payload: { notificationIds } }: any) =>
    notificationIds.reduce((newState: any, notificationId: any) => {
      const record = state.get(notificationId)
      return newState.set(
        notificationId,
        record.set('viewedAt', formatISO(new Date()))
      )
    }, state),
  // @ts-expect-error can we deal with the toString being automatically called?
  [notificationMarkedUnread]: (
    state: any,
    { payload: { notification } }: any
  ) => state.set(notification.id, notification),
  // @ts-expect-error can we deal with the toString being automatically called?
  [notificationAdded]: (state: any, { payload: { data } }: any) =>
    OrderedMap([[data.id, data]]).merge(state),
})

const createNotificationSelector = (filterFunc: any) =>
  pipe(
    getNotificationList,
    get(NOTIFICATIONS),
    createSelector(
      [notifications => notifications],
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      notifications => notifications.filter(filterFunc).toIndexedSeq()
    )
  )

export const getUnreadHighPriorityNotifications = createNotificationSelector(
  (notification: any) =>
    notification.priority === HIGH && !notification.viewedAt
)

export const getNormalNotifications = createNotificationSelector(
  (notification: any) =>
    notification.priority === NORMAL || notification.viewedAt
)
