import React from 'react'
import PropTypes from '~/utils/propTypes'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import PharmacySearch from './PharmacySearch'
import PreferredPharmacy from './PreferredPharmacy'

const styles = ({ spacing }) => ({
  flex: {
    flex: 1,
  },
  divider: {
    margin: [spacing(3), 0],
  },
})

const Pharmacies = ({ classes }) => (
  <React.Fragment>
    <Typography className={classes.flex} color="primary" variant="h5">
      Patient Preferred Pharmacy
    </Typography>
    <PreferredPharmacy />
    <Divider className={classes.divider} />
    <Typography className={classes.flex} color="primary" variant="h5">
      Select New Patient Preferred Pharmacy
    </Typography>
    <PharmacySearch />
  </React.Fragment>
)

Pharmacies.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Pharmacies)
