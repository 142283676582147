import { List, Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import rootKey from '../key'
import { getOrderKey } from './common/shared'

const key = 'orderTypes'

// RECORDS
export const FullOrderType = Record({
  type: null,
  typeLabel: null,
  description: null,
  subtype: null,
})

const mapData = data => List(data.map(d => FullOrderType(d)))

export const fetchOrderTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ORDER_TYPES',
  operation: () => AspireAPI.get(`v1/orders/types`),
  messages: { failed: 'There was a problem loading order types' },
})

export default createReducer(key, List(), {
  [fetchOrderTypes.SUCCEEDED]: (_state, { payload }) => mapData(payload),
})

const getKey = state => getOrderKey(state).get(key)

export const getOrderTypes = createSelector([getKey], orderTypes =>
  orderTypes.reduce((acc, type) => acc.set(type.type, type.typeLabel), Map())
)

export const getOrderSubTypes = createSelector(
  [getKey],
  orderTypes => orderTypes
)
