import { Record } from 'immutable'
import { prop } from 'lodash/fp'
import { ROUTE_CHANGED } from 'redux-routable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import {
  assessmentScratchNotesChanged,
  currentAssessmentFetched,
  saveAssessment,
} from '../data/assessments'
import {
  assessmentCancelled,
  assessmentSigned,
  formDataChangedByTag,
  sectionChanged,
} from '../data/common/shared'
import { ASSESSMENT_FORM } from '../router'
import { getAssessment } from './common/shared'

const key = 'saveInfo'

export const CHANGED = 'changed'
export const SAVING = 'saving'
export const SAVED = 'saved'
export const SAVED_OFFLINE = 'saved_offline'

export const ASSESSMENT_SAVE_BUTTON_CLICKED = type(
  key,
  'ASSESSMENT_SAVE_BUTTON_CLICKED'
)
export const assessmentSaveButtonClicked = creator(
  ASSESSMENT_SAVE_BUTTON_CLICKED
)

const SaveInfo = Record({
  lastSavedAt: null,
  status: SAVED,
})

const initState = SaveInfo()

const updateStatus = newStatus => oldStatus =>
  oldStatus === SAVING ? newStatus : oldStatus

export default createReducer(key, initState, {
  [currentAssessmentFetched]: (state, { payload: { assessment } }) =>
    state
      .set('lastSavedAt', assessment.modifiedAt)
      .set('status', assessment.local ? SAVED_OFFLINE : SAVED),
  [saveAssessment.REQUESTED]: state => state.set('status', SAVING),
  [saveAssessment.SUCCEEDED]: state =>
    state
      .set('lastSavedAt', new Date().toISOString())
      .update('status', updateStatus(SAVED)),
  [saveAssessment.FAILED]: state =>
    state.update('status', updateStatus(SAVED_OFFLINE)),
  [sectionChanged]: state => state.set('status', CHANGED),
  [formDataChangedByTag]: state => state.set('status', CHANGED),
  [assessmentScratchNotesChanged]: state => state.set('status', CHANGED),
  [assessmentSigned]: () => initState,
  [assessmentCancelled]: () => initState,
  [ROUTE_CHANGED]: (state, { payload }) =>
    payload.route === ASSESSMENT_FORM ? state : initState,
})

const getSaveInfo = pipe(getAssessment, get(key))

export const getLastSavedAt = pipe(getSaveInfo, prop('lastSavedAt'))

export const getStatus = pipe(getSaveInfo, prop('status'))
