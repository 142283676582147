import { combineEpics } from 'redux-observable'
import loadAppData from './epics/loadAppData'
import metricsPageLoad from './epics/metricsPageLoad'
import patientListEntered from './epics/patientListEntered'
import routeParams from './epics/routeParams'

export default combineEpics(
  loadAppData,
  metricsPageLoad,
  routeParams,
  patientListEntered
)
