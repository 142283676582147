import PropTypes from '~/utils/propTypes'

const mapPropTypes = {
  latLng: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  }),
  icon: PropTypes.shape({
    url: PropTypes.string,
    size: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
}

export default mapPropTypes
