import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, first, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import {
  admissionSourceFetchFailed,
  admissionSourcesStored,
  fetchAdmissionSources,
} from '../data/admissionSources'
import {
  admissionTypeFetchFailed,
  admissionTypesStored,
  fetchAdmissionTypes,
} from '../data/admissionTypes'
import {
  MERGE_INITIALIZED,
  RECYCLE_INITIALIZED,
  UNMERGE_INITIALIZED,
} from '../data/admissionsReconciliation'

export default (action$: any) =>
  action$.pipe(
    ofType(MERGE_INITIALIZED, RECYCLE_INITIALIZED, UNMERGE_INITIALIZED),
    first(),
    mergeMap(() =>
      concat(
        from(fetchAdmissionSources()).pipe(
          map(admissionSourcesStored),
          catchError(e => {
            telemetry.error(e)
            return of(admissionSourceFetchFailed())
          })
        ),
        from(fetchAdmissionTypes()).pipe(
          map(admissionTypesStored),
          catchError(e => {
            telemetry.error(e)
            return of(admissionTypeFetchFailed())
          })
        )
      )
    )
  )
