import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { first, mergeMapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { marketsRequested } from '~/data/markets'
import { userRolesRequested } from '~/data/userRoles'
import { calendarInitialized } from '../data/calendar'

export default action$ =>
  action$.pipe(
    ofType(calendarInitialized),
    first(),
    mergeMapTo(
      of(
        userRolesRequested(),
        marketsRequested(),
        fetchDistinctFieldValues([
          'encounter_assessment_status',
          'calendar_event_type',
          'calendar_event_subtype_unavailability',
          'calendar_event_subtype_incentive_day',
          'scheduling_guidance_type',
          'modality',
          'ride_along_role',
        ])
      )
    )
  )
