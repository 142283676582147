import { DISCHARGE_REASONS, TOP_5_DISCHARGE_REASONS } from '../../constants'
import { TIER_ONE, TIER_TWO } from '~/utils/programInfo'

import { get } from '~/utils/data'
import key from '../../key'

export const getRoot = get(key)

export const getDischargeReasonsList = (programEnrolled: string) =>
  programEnrolled === TIER_ONE || programEnrolled === TIER_TWO
    ? TOP_5_DISCHARGE_REASONS
    : DISCHARGE_REASONS
