import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'careTeams'

export const FETCHED_CARE_TEAMS = type(rootKey, 'FETCHED_CARE_TEAMS')

export const loadCTs = creator(FETCHED_CARE_TEAMS, 'careTeams')

export default createReducer(key, new Map(), {
  [FETCHED_CARE_TEAMS]: (s, { payload: { careTeams } }) =>
    normalize(careTeams, { idKey: 'care_team_id' }),
})

export const getCareTeams = pipe(getRoot, get(key))

export const fetchCTs = () => AspireAPI.get('polygonator/apps')
