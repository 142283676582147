import { ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { combineLatest, filter, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ap... Remove this comment to see the full error message
import { fetchAssessments } from '~/apps/assessment/data/assessments'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchOnCallsForUserId } from '~/features/onCall'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerOrdersFetchRequested } from '~/features/orders/data/loaders'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchSelectedAPPs } from '~/features/scheduling/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { dataCleared } from '../data/common/shared'
import { userIdSelected } from '../data/currentUserId'
import { fetchEncounterReviews } from '../data/encounterReviews'
import { fetchPatientOutlierFinalDecisions } from '../data/patientOutlierFinalDecisions'
import { fetchPatientOutlierReviews } from '../data/patientOutlierReviews'
import router from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(entered(router)),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(getUserId),
    mergeMap(userId =>
      of(
        userIdSelected(userId),
        dataCleared(),
        ownerTasksFetchRequested(userId),
        ownerOrdersFetchRequested(userId),
        fetchEncounterReviews.requested(userId),
        fetchOnCallsForUserId.requested(userId),
        fetchPatientOutlierReviews.requested(userId),
        fetchPatientOutlierFinalDecisions.requested(userId),
        fetchSelectedAPPs.requested(userId),
        fetchAssessments.requested({
          provider_ids: [userId],
          statuses: ['not_started', 'created', 'in_progress'],
        }),
        fetchDistinctFieldValues([
          'encounter_review_status',
          'patient_outlier_review_status',
          'patient_outlier_final_decision_status',
          'app_graduation_type',
          'encounter_assessment_status',
        ])
      )
    )
  )
