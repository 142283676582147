import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'signature'

// RECORD
export const Signature = Record({
  id: null,
  hasSignature: null,
})

// ACTION
export const APP_SIGNATURE_FETCHED = type(rootKey, 'APP_SIGNATURE_FETCHED')

// ACTION CREATOR
export const appSignatureFetched = creator(APP_SIGNATURE_FETCHED, 'signature')

// ASYNC
export const fetchOrderSignature = user_id =>
  AspireAPI.get('v1/orders/signature', { params: { user_id } }).then(data =>
    Signature({
      id: user_id,
      hasSignature: data[0] && data[0].hasSignature,
    })
  )

// REDUCER
const initState = null

export default createReducer(key, initState, {
  [APP_SIGNATURE_FETCHED]: (_state, { payload: { signature } }) => signature,
})
