import { Map, Record } from 'immutable'
import { isEmpty } from 'lodash'
import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error no export
import Request from '~/utils/Request'
// @ts-expect-error no export
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'
import { fetchDemographicsOptions } from '~/data/demographicsOptions'

export type FieldValueProps = { value: string; label: string; rank: number }
export const FieldValue = Record({
  value: null,
  label: null,
  rank: null,
})

const key = 'fieldValues'

export const FETCH_DISTINCT_FIELD_VALUES = type(
  key,
  'FETCH_DISTINCT_FIELD_VALUES'
)
export const fetchDistinctFieldValues = creator(
  FETCH_DISTINCT_FIELD_VALUES,
  key
)

const mapFieldValues = (fieldValues: any) =>
  Map(
    fieldValues.map(({ field, values }: any) => [
      field,
      Map(values.map(FieldValue).map((fv: any) => [fv.value, fv])).sortBy(
        (fv: any) => fv.rank
      ),
    ])
  )

// Request
export const fetchFieldValues = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['fields'],
  operation: (fields: string | Array<string>) =>
    AspireAPI.get('/v1/field_values', { params: { fields } }),
  transform: mapFieldValues,
  messages: { failed: 'Could not fetch field values.' },
})

export const epic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(FETCH_DISTINCT_FIELD_VALUES),
    pluck('payload', key),
    map((requestedFieldValues: any) => {
      const fetchedFieldValues = state$.value.get(key).flip().toList()

      return requestedFieldValues.filter(
        (value: any) => !fetchedFieldValues.includes(value)
      )
    }),
    filter(filteredValues => !isEmpty(filteredValues)),
    map(fetchFieldValues.requested)
  )

export default createReducer(key, Map(), {
  [fetchFieldValues.SUCCEEDED]: (state: any, { payload }: any) =>
    state.merge(payload),
  [fetchDemographicsOptions.SUCCEEDED]: (state: any, { payload }: any) =>
    state.merge(payload),
})

export const getFieldValues = (fields: string | Array<string>) => (
  state: any
): any => {
  if (Array.isArray(fields)) {
    return state
      .get(key)
      .reduce(
        (acc: any, v: string, k: string) =>
          fields.includes(k) ? acc.merge(v) : acc,
        Map()
      )
  } else {
    return state.getIn([key, fields], Map())
  }
}
