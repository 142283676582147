import { Route, Router } from 'redux-routable'
import key from './key'

export const REFERRAL_MANAGEMENT_HOME = `${key}.home`
export const REFERRAL_MANAGEMENT_NEW_REFERRAL = `${key}.newReferral`
export const REFERRAL_MANAGEMENT_REFERRAL_DETAIL = `${key}.referralDetail`
export const REFERRAL_MANAGEMENT_MANUAL_ASSIGNMENT_QUEUE = `${key}.manualAssignmentQueue`
export const REFERRAL_MANAGEMENT_ADDRESS = `${key}.address`
export const REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL = `${key}.additionalReferral`
export const REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_DETAIL = `${key}.additionalReferralDetail`
export const REFERRAL_MANAGEMENT_TASKS_BY_USER = `${key}.tasksByUser`
export const REFERRAL_MANAGEMENT_TASK_DETAIL = `${key}.taskDetail`

export const REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_ROUTES = [
  REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL,
  REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_DETAIL,
]

export const REFERRAL_MANAGEMENT_TASKS_ROUTES = [
  REFERRAL_MANAGEMENT_TASKS_BY_USER,
  REFERRAL_MANAGEMENT_TASK_DETAIL,
]

export default Router([
  Route(REFERRAL_MANAGEMENT_HOME),
  Route(REFERRAL_MANAGEMENT_NEW_REFERRAL, '/referral/new'),
  Route(REFERRAL_MANAGEMENT_REFERRAL_DETAIL, '/referral/:caseId'),
  Route(
    REFERRAL_MANAGEMENT_MANUAL_ASSIGNMENT_QUEUE,
    '/manual-assignment-queue'
  ),
  Route(REFERRAL_MANAGEMENT_ADDRESS, '/address'),
  Route(REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL, '/additional-referral'),
  Route(
    REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_DETAIL,
    '/additional-referral/:patientId'
  ),
  Route(REFERRAL_MANAGEMENT_TASKS_BY_USER, '/tasks'),
  Route(REFERRAL_MANAGEMENT_TASK_DETAIL, '/tasks/:taskId'),
])
