import React from 'react'
import Flag from './Flag'

/* eslint-disable react/display-name */
const withFlag = (flagName, BackupComponent) => WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <Flag
          flagName={flagName}
          component={WrappedComponent}
          fallbackComponent={BackupComponent}
          {...this.props}
        />
      )
    }
  }
}

export default withFlag
