import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import colorGenerator from '~/utils/colorGenerator'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'zips'

const colorScheme = [
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
]

const colors = colorGenerator(colorScheme)

const colorize = zip => {
  zip.color = colors.next().value
  return zip
}

export const ZIPS_VISIBLE_SET = type(rootKey, 'ZIPS_VISIBLE_SET')
export const ZIPS_FETCHED = type(rootKey, 'ZIPS_FETCHED')

export const setZipsVisible = creator(ZIPS_VISIBLE_SET, 'isVisible')
export const loadZips = creator(ZIPS_FETCHED, 'zips')

const initialState = new Map({
  isVisible: false,
  zips: new Map(),
})

export default createReducer(key, initialState, {
  [ZIPS_VISIBLE_SET]: (state, { payload: { isVisible } }) =>
    state.set('isVisible', isVisible),
  [ZIPS_FETCHED]: (state, { payload: { zips } }) =>
    state.set('zips', normalize(zips.map(colorize), { idKey: 'zip' })),
})

const getZipsLayer = pipe(getRoot, get(key))
export const getZipsVisible = pipe(getZipsLayer, get('isVisible'))
export const getZips = pipe(getZipsLayer, get('zips'))

export const fetchZips = path =>
  AspireAPI.get('polygonator/polygons/postal_codes', { params: { path } })
