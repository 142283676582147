import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'channelInsuranceMatch'

export const getValidationCheckResult = pipe(getRoot, get(key))

export const checkInsuranceValidity = Request({
  typePrefix: key,
  typeBase: 'GET_CHANNEL_INSURANCE_MATCH',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(
      `v1/patient/${patientId}/channel_and_insurance_validation_check`
    ),
  messages: {
    failed: "There was a problem checking the the patient's insurance/channel",
  },
})

export default createReducer(key, true, {
  [checkInsuranceValidity.SUCCEEDED]: (_state, { payload }) => payload,
})
