import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { fetchUser, loggedIn } from '~/data/session'

export default action$ =>
  action$.pipe(
    ofType(loggedIn),
    pluck('payload', 'user', 'id'),
    map(fetchUser.requested)
  )
