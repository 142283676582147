import { List } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getIn } from '~/utils/data'
import { handleMedMessage } from './root'

/* KEY */
const key = 'pharmacySearch'

/* API */
const cleanPhone = phone => phone && phone.replace(/[^\d]/g, '')

const cleanParams = params => {
  const {
    activeOnly,
    includeRetail,
    includeMailOrder,
    includeFax,
    includeSpecialty,
    includeLongTerm,
    includeTwentyFourHour,
    phone,
    ...rest
  } = params

  return {
    active_only: activeOnly,
    include_retail: includeRetail,
    include_mail_order: includeMailOrder,
    include_fax: includeFax,
    include_specialty: includeSpecialty,
    include_long_term: includeLongTerm,
    include_twenty_four_hour: includeTwentyFourHour,
    phone: cleanPhone(phone),
    ...rest,
  }
}

const pharmacySearchApi = queryObj => {
  const params = cleanParams(queryObj)
  return AspireAPI.get('medications/search_pharmacies', { params })
}

export const fetchPharmacySearch = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['params'],
  operation: pharmacySearchApi,
  transform: List,
  messages: {
    failed: handleMedMessage(
      'There was a problem fetching pharmacy search results'
    ),
    requested: 'Fetching Search Results',
  },
})

/* REDUCER */
export default createReducer(key, List(), {
  [fetchPharmacySearch.SUCCEEDED]: (_state, { payload }) => payload,
})

/* SELECTORS */
export const getPharmacySearchResults = getIn(['medications', key])
export const getPharmacySearchResultsArray = createSelector(
  [getPharmacySearchResults],
  results => results.toJS()
)
