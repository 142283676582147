import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const USER_SETTINGS_USER_SIGNATURE = `${key}.userSignature`
export const USER_SETTINGS_OFFLINE_SETTINGS = `${key}.offlineSettings`
export const USER_SETTINGS_NOTIFICATION_LIST = `${key}.notificationList`
export const USER_SETTINGS_DATA_SETTINGS = `${key}.dataSettings`

export default Router([
  Route(USER_SETTINGS_USER_SIGNATURE, '/user-signature'),
  Route(USER_SETTINGS_OFFLINE_SETTINGS, '/offline'),
  Route(USER_SETTINGS_NOTIFICATION_LIST, '/notifications/:status?'),
  Route(USER_SETTINGS_DATA_SETTINGS, '/data'),
  Redirect(USER_SETTINGS_NOTIFICATION_LIST),
])
