import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchOnCallsForPatient } from '~/features/onCall'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_ON_CALL } from '../router'
import lastEncounterEpic from './lastEncounter'

const onCallEntered = (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_ON_CALL)),
    switchTo(state$),
    map(getPatientId),
    map(fetchOnCallsForPatient.requested)
  )

export default combineEpics(
  lastEncounterEpic(PATIENT_RECORD_ON_CALL),
  onCallEntered
)
