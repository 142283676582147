import React from 'react'
import { connect } from 'react-redux'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Divider, List, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { APP, Event, Patient } from '../../data/common/shared'
import { getSearchResults } from '../../data/search'
import APPListItem from './APPListItem'
import EventListItem from './EventListItem'
import PatientListItem from './PatientListItem'

const styles = ({ spacing }) => ({
  resultsCount: {
    margin: spacing(1),
  },
  helperText: {
    marginTop: spacing(1),
  },
})

const getListItem = result => {
  switch (result.constructor) {
    case Patient:
      return <PatientListItem patient={result} />
    case APP:
      return <APPListItem app={result} />
    case Event:
      return <EventListItem event={result} />
  }
}

const SearchResults = ({ classes, searchResults }) => {
  const count = searchResults.count()

  return count > 0 ? (
    <div>
      <div className={classes.resultsCount}>
        <Typography>{count} Results Found</Typography>
        <Typography className={classes.helperText} variant="caption">
          Add a healthplan, city, or market name to limit the results.
        </Typography>
      </div>

      <Divider />

      <List>
        {searchResults.map(result => (
          <React.Fragment key={result.id}>
            {getListItem(result)}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  ) : null
}

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  searchResults: PropTypes.list.isRequired,
}

export default compose(
  connect(state => ({
    searchResults: getSearchResults(state),
  })),
  withStyles(styles)
)(SearchResults)
