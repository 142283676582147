import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { callLogCleared, getRoot } from './common/shared'

const ORDERS = 'orders'

export const Order = Record({
  orderId: null,
  taskId: null,
  info: {},
})

const transformOrders = data => List(data.map(Order))

export const fetchOrders = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ORDERS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/patient/${patientId}/orders`),
  transform: transformOrders,
  messages: { failed: 'There was an issue fetching patient orders' },
})

const initState = List()
export default createReducer(ORDERS, initState, {
  [callLogCleared]: () => initState,
  [fetchOrders.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getOrders = pipe(getRoot, get(ORDERS))
