import { Record } from 'immutable'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Section } from '~/components/text'
import { AlertDialog, addAlertDialogOpened } from '~/features/patientAlerts'
import { useAction } from '~/hooks'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCurrentPatientId } from '../../../data/currentPatientId'
import { getSchedulingAlerts } from '../../../data/schedulingAlerts'
import SchedulingAlert from './SchedulingAlert'
import SchedulingAlertDialog from './SchedulingAlertDialog'

const Alert = Record({
  mode: 'create',
  title: 'Create',
  patientId: null,
  id: null,
  open: false,
  rank: 0,
  description: '',
})

const useStyles = makeStyles(({ spacing }) => ({
  buttons: {
    display: 'flex',
  },
  button: {
    marginRight: spacing(1),
  },
}))

const SchedulingAlerts = () => {
  const classes = useStyles()

  const patientId = useSelector(getCurrentPatientId)
  const schedulingAlerts = useSelector(getSchedulingAlerts)
  const onOpenAlertDialog = useAction(addAlertDialogOpened)

  const [alert, setAlert] = useState(Alert())

  const onCreate = useCallback(() => {
    setAlert(Alert({ patientId: patientId, open: true }))
  }, [patientId, setAlert])

  const onEdit = useCallback(
    ({ patientId, id, rank, description }) => {
      setAlert(
        Alert({
          mode: 'save',
          title: 'Save',
          open: true,
          patientId,
          id,
          rank,
          description,
        })
      )
    },
    [setAlert]
  )

  const onDelete = useCallback(
    ({ patientId, id }) => {
      setAlert(
        Alert({ mode: 'delete', title: 'Delete', open: true, patientId, id })
      )
    },
    [setAlert]
  )

  const onClose = useCallback(() => {
    setAlert(Alert())
  }, [setAlert])

  return (
    <React.Fragment>
      <Section title="Scheduling Alerts">
        {!schedulingAlerts.isEmpty() ? (
          schedulingAlerts
            .toIndexedSeq()
            .map(alert => (
              <SchedulingAlert
                key={alert.id}
                alert={alert}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))
        ) : (
          <Typography variant="caption">No Scheduling Alerts</Typography>
        )}
      </Section>

      <div className={classes.buttons}>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={onCreate}
        >
          Create Scheduling Alert
        </Button>

        <Button variant="outlined" onClick={onOpenAlertDialog}>
          Create Alert
        </Button>
      </div>

      <SchedulingAlertDialog alert={alert} onClose={onClose} />
      <AlertDialog patientId={patientId} />
    </React.Fragment>
  )
}

export default SchedulingAlerts
