import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, InputAdornment } from '@material-ui/core'
import Input from './Input'
import memoizedWrapper from './memoizedWrapper'

const ClearableFieldWidget = ({ disabled, readonly, onChange, value }) => {
  const formattedValue = useMemo(() => formatValue(value), [value])

  return (
    <Input
      value={formattedValue}
      readOnly
      fullWidth
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={() => onChange(undefined)}
            disabled={disabled || readonly}
          >
            <Icon>clear</Icon>
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

ClearableFieldWidget.propTypes = {
  disabled: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
}

export default memoizedWrapper(ClearableFieldWidget)

// Formatter - this is currently used for Vendor and Physician search, which
// have different shapes, so we have to look at billing or mailing address info
const formatValue = ({
  name,
  phone,
  billingAddress,
  billingCity,
  billingStateCode,
  mailingCity,
  mailingStateCode,
  mailingStreet,
}) => {
  const city = billingCity || mailingCity
  const street = billingAddress || mailingStreet
  const stateCode = billingStateCode || mailingStateCode
  const address = [city, street, stateCode].filter(Boolean).join(' ')

  return [name, phone, address].filter(Boolean).join(' | ')
}
