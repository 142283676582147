// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const RECYCLE_DIALOG_ADMISSION_ID_SET =
  'reconciliation/RECYCLE_DIALOG_ADMISSION_ID_SET'
export const RECYCLE_DIALOG_ADMISSION_ID_CLEARED =
  'reconciliation/RECYCLE_DIALOG_ADMISSION_ID_CLEARED'

// ACTION CREATORS
export const recycleDialogAdmissionIdSet = (AdmissionId: any) => ({
  type: RECYCLE_DIALOG_ADMISSION_ID_SET,
  AdmissionId,
})
export const recycleDialogAdmissionIdCleared = () => ({
  type: RECYCLE_DIALOG_ADMISSION_ID_CLEARED,
})

// KEY
export const key = 'recycleDialogAdmissionId'

// REDUCERS
export default createReducer(key, null, {
  [RECYCLE_DIALOG_ADMISSION_ID_SET]: (_state: any, { AdmissionId }: any) =>
    AdmissionId,
  [RECYCLE_DIALOG_ADMISSION_ID_CLEARED]: () => null,
})

// SELECTORS
export const getRecycleDialogAdmissionId = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
