import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, first, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appsStored, fetchApps } from '~/data/apps'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  ASSIGNMENT_PAGE_NAVIGATED,
  CASE_PAGE_NAVIGATED,
} from '../data/referralManagement'

const fetchAndStoreAPPs = () =>
  from(fetchApps()).pipe(
    map(appsStored),
    catchError(showError('Problem loading APP list'))
  )

export default (action$: any) =>
  action$.pipe(
    ofType(ASSIGNMENT_PAGE_NAVIGATED, CASE_PAGE_NAVIGATED),
    first(),
    mergeMap(fetchAndStoreAPPs)
  )
