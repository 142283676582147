import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  noPadding: {
    padding: 0,
  },
}

const BooleanDisplayCell = ({ classes, value }) => (
  <Checkbox className={classes.noPadding} disabled checked={value} />
)

BooleanDisplayCell.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.bool,
}

export default withStyles(styles)(BooleanDisplayCell)
