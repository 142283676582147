import React from 'react'
import { Tooltip } from '@material-ui/core'

type Props = {
  value?: string
}

const WithTooltip = ({ value }: Props) => (
  <Tooltip title={value || ''}>
    <span>{value}</span>
  </Tooltip>
)

export default WithTooltip
