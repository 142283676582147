import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

export const ProblemDetailTypeMapping = Record({
  id: null,
  problemTypeId: null,
  formTag: null,
  label: null,
  dataType: null,
  field: null,
  suffix: null,
  rank: null,
})

const PROBLEM_DETAIL_TYPE_MAPPINGS = 'problemDetailTypeMappings'

const transformProblemDetailTypeMappings = (detailTypeMappings: any) =>
  List(detailTypeMappings.map(ProblemDetailTypeMapping))

export const fetchProblemDetailTypeMappings = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PROBLEM_DETAIL_TYPE_MAPPINGS',
  operation: () => AspireAPI.get('care_plan/problem_detail_types'),
  transform: transformProblemDetailTypeMappings,
  messages: {
    failed: 'There was an issue fetching problem detail type mappings',
  },
})

export default createReducer(PROBLEM_DETAIL_TYPE_MAPPINGS, List(), {
  [fetchProblemDetailTypeMappings.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getProblemDetailTypeMappings = pipe(
  getRoot,
  get(PROBLEM_DETAIL_TYPE_MAPPINGS)
)
