import React from 'react'
import { formatDate, formatDateTime } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = ({ palette, spacing }) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    '& td': {
      paddingRight: spacing(2),
    },
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: palette.background.default,
    },
  },
})

const TaskHistory = ({ history, classes }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">History</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table className={classes.root} size="small">
          <TableHead>
            <TableRow>
              <TableCell size="small">Status</TableCell>
              <TableCell size="small">Modified At</TableCell>
              <TableCell size="small">Modified By</TableCell>
              <TableCell size="small">Assigned To</TableCell>
              <TableCell size="small">Assigned To Role</TableCell>
              <TableCell size="small">Due On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map(row => (
              <TableRow className={classes.row} key={row.modifiedAt}>
                <TableCell size="small">{row.status}</TableCell>
                <TableCell size="small">
                  {formatDateTime(row.modifiedAt)}
                </TableCell>
                <TableCell size="small">{row.modifiedByName}</TableCell>
                <TableCell size="small">{row.ownerName}</TableCell>
                <TableCell size="small">{row.ownerRole}</TableCell>
                <TableCell size="small">{formatDate(row.dueOn)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

TaskHistory.propTypes = {
  history: PropTypes.list.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TaskHistory)
