import { INBOUND, OUTBOUND } from '../../../data/callLog'
import {
  ACTIVE,
  DISCHARGED,
  REFERRED,
  SCHEDULED,
} from '../../../data/patientInfo'

export const filterIntent = (callLog, disposition) =>
  disposition.intentId === 0 || disposition.intentId === callLog.intentId

export const filterCallOrigin = (callLog, disposition) =>
  (disposition.inbound && callLog.callOrigin === INBOUND) ||
  (disposition.outbound && callLog.callOrigin === OUTBOUND)

export const filterStatus = (patientStatus, disposition) =>
  (disposition.forReferred && patientStatus == REFERRED) ||
  (disposition.forActive && patientStatus == ACTIVE) ||
  (disposition.forScheduled && patientStatus == SCHEDULED) ||
  (disposition.forDischarged && patientStatus == DISCHARGED)
