import { format, parse } from 'date-fns'
import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { PatientSearchResults, PatientSearchInput } from './interfaces'

// KEY
const SEARCH = 'search'
const SEARCH_SUBMIT = 'searchSubmit'

const creator = scopedCreator(SEARCH)
const submitCreator = scopedCreator(SEARCH_SUBMIT)

export const clearPatientSearch = creator('CLEAR_PATIENT_SEARCH')
export const patientSearchSubmit = submitCreator('PATIENT_SEARCH_COMPLETED')
export const patientSearchClear = submitCreator('PATIENT_SEARCH_CLEAR')

export const Patient = Record({
  sfid: null,
  id: null,
  fullName: null,
  dob: null,
  firstName: null,
  lastName: null,
  phone: null,
  address: null,
  countyName: null,
  eligibilityEffectiveDate: null,
  eligibilityTermDate: null,
  groupId: null,
  productDescription: null,
  classplanProductId: null,
  currentPlanIsEligible: null,
})

// TRANSFORMER
const mapSearchResults = (results: [PatientSearchResults]) =>
  List(
    results?.map(patient =>
      Patient({
        sfid: patient?.subscriberId,
        id: patient?.patientId,
        lastName: patient?.lastName,
        fullName: `${patient?.firstName}  ${patient?.lastName}`,
        firstName: patient?.firstName,
        phone: patient?.phone,
        dob: formatDate(patient?.dateOfBirth),
        address: patient?.address,
        countyName: patient?.countyName,
        eligibilityEffectiveDate: formatDate(patient?.eligibilityEffectiveDate),
        eligibilityTermDate: formatDate(patient?.eligibilityTermDate),
        groupId: patient?.groupId,
        productDescription: patient?.productDescription,
        classplanProductId: patient?.classplanProductId,
        currentPlanIsEligible: patient?.currentPlanIsEligible,
      })
    )
  )

const formatDate = (date: string) =>
  format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')

// REQUEST
export const search = Request({
  typePrefix: rootKey,
  typeBase: 'SEARCH',
  requestParams: ['data'],
  operation: (data: PatientSearchInput) =>
    AspireAPI.post('transportation/patient_search', { ...data }),
  transform: mapSearchResults,
  messages: { failed: 'Failed to search ' },
})

// REDUCER
export const patientSearch = createReducer(SEARCH, List(), {
  [search.SUCCEEDED]: compose(payload, action),
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearPatientSearch]: () => List(),
})

export const patientSearchStatus = createReducer(SEARCH_SUBMIT, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientSearchSubmit]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientSearchClear]: () => false,
})

// SELECTORS
export const getSearchResults = pipe(getRoot, get(SEARCH))
export const getSearchSubmitStatus = pipe(getRoot, get(SEARCH_SUBMIT))
