import { ofType } from 'redux-observable'
import { from, timer } from 'rxjs'
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  POLL_FOR_CASE_SYNC_COMPLETION_START,
  POLL_FOR_CASE_SYNC_COMPLETION_STOP,
  activeReferralCaseStored,
  fetchActiveReferral,
} from '../data/referrals'

/**
 Handles polling for the case sfid when a case is submitted
 */
export default (action$: any) =>
  action$.pipe(
    ofType(POLL_FOR_CASE_SYNC_COMPLETION_START),
    switchMap(({ payload }) =>
      timer(0, 1000).pipe(
        takeUntil(action$.pipe(ofType(POLL_FOR_CASE_SYNC_COMPLETION_STOP))),
        exhaustMap(() =>
          from(fetchActiveReferral(payload)).pipe(
            map(activeReferralCaseStored),
            catchError(showError('Error! Unable to load case data'))
          )
        )
      )
    )
  )
