import React, { useRef } from 'react'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Button, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SelectList from './SelectList'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    flex: 1,
    padding: spacing(0, 1),
  },
  button: {
    width: '100%',
  },
  popover: {
    display: 'flex',
    height: '50vh',
  },
}))

const SelectListPopover = ({
  disabled,
  items,
  label,
  selectedItemIds,
  onItemsSelected,
}) => {
  const classes = useStyles()

  const [open, toggleOpen, toggleClosed] = useToggle()
  const buttonRef = useRef()

  return (
    <div className={classes.container}>
      <Button
        disabled={disabled}
        buttonRef={e => {
          buttonRef.current = e
        }}
        variant="contained"
        onClick={toggleOpen}
        className={classes.button}
        color="primary"
      >
        {label}
      </Button>

      <Popover
        open={open}
        className={classes.popover}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={toggleClosed}
      >
        <SelectList
          items={items}
          itemsLabel={label}
          selectedItemIds={selectedItemIds}
          onItemsSelected={onItemsSelected}
        />
      </Popover>
    </div>
  )
}

SelectListPopover.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.map.isRequired,
  label: PropTypes.string.isRequired,
  selectedItemIds: PropTypes.set.isRequired,
  onItemsSelected: PropTypes.func.isRequired,
}

export default SelectListPopover
