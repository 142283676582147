import { ofType } from 'redux-observable'
import { exited } from 'redux-routable'
import { filter, first, mapTo, switchMap } from 'rxjs/operators'
import { panelClosed } from '~/data/patientPanel'
import { getRoute } from '~/data/route'
import { taskSelected } from '../data/currentTaskId'

export default (action$, state$) =>
  action$.pipe(
    ofType(taskSelected),
    switchMap(() =>
      action$.pipe(
        filter(exited(getRoute(state$.value))),
        first(),
        mapTo(panelClosed())
      )
    )
  )
