export const convertToYesNo = (bool: boolean) => {
  switch (bool) {
    case true:
      return 'Yes'
    case false:
      return 'No'
    default:
      return 'N/A'
  }
}
