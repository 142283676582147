import { connect } from 'react-redux'
import { getUserId } from '~/data/session'
import MdtRefillRequestButton from '../components/MdtRefillRequestButton'
import {
  fetchMDTRefillCount,
  getRefillRequestsCount,
  mdtRefillRequestsCleared,
} from '../data/mdtRefillRequests'
import { fetchMdtSingleSignOn } from '../data/mdtSingleSignOn'

const mapStateToProps = state => ({
  userId: getUserId(state),
  mdtRefillRequestsCount: getRefillRequestsCount(state),
})

const mapDispatchToProps = {
  requestRefillCount: fetchMDTRefillCount.requested,
  mdtRefillCleared: mdtRefillRequestsCleared,
  mdtSingleSignOn: fetchMdtSingleSignOn.requested,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MdtRefillRequestButton)
