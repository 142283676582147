import {
  AOfMorphADT,
  AsOpaque,
  AType,
  Ctor,
  EType,
  ReduxAction,
  summon,
  Variant,
} from '~/utils/type'
import { Interval } from '~/types/Interval'
import { Review } from './reviewT'
import { Async } from '~/resources/resource-strict'
import { PatientStats } from './patientStatsT'

export interface Form {
  userId: string
  review: Review
  notes: string
  setNotes: React.Dispatch<React.SetStateAction<string>>
  recommendation: string
  setRecommendation: React.Dispatch<React.SetStateAction<string>>
}

const Identification_ = summon(F =>
  F.interface(
    {
      reviewId: F.number(),
      review: Review(F),
      timeOnService: Interval(F),
      totalReviews: F.number(),
      timeSinceLastReview: F.nullable(Interval(F)),
    },
    'Identification'
  )
)
export interface Identification extends AType<typeof Identification_> {}
export interface IdentificationRaw extends EType<typeof Identification_> {}
export const Identification = AsOpaque<IdentificationRaw, Identification>()(
  Identification_
)

const Index = Async(summon(F => F.array(Identification(F))))
export type Index = AOfMorphADT<typeof Index>

//   // TODO: Consider making this a ziplist
//   type index = Async.t(list(identification));
//   // as:
//   // {
//   //   index: Init,
//   //   currentIdentification: Some(currentIdentification)
//   // }
//   // is an invalid but possible state.
const State_ = summon(F =>
  F.interface(
    {
      index: Index(F),
      currentIdentification: F.nullable(Identification(F)),
      stats: Async(PatientStats)(F),
    },
    'State'
  )
)
export interface State extends AType<typeof State_> {}
export interface StateRaw extends EType<typeof State_> {}
export const State = AsOpaque<StateRaw, State>()(State_)

const Patients = Ctor('Patients', Index)
const Highlight = Ctor('Highlight', Identification)
const Stats = Ctor('Stats', Async(PatientStats))

export const Action = Variant({
  Patients,
  Highlight,
  Stats,
})
export type Action = ReduxAction<'Finalization', AOfMorphADT<typeof Action>>
