import Markdown from 'markdown-to-jsx'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import overrides from './overrides'

const MarkdownToJsx = ({
  options: { overrides: additionalOverrides = {}, ...additionalOptions },
  children,
  ...props
}) => (
  <Markdown
    options={{
      overrides: { ...overrides, ...additionalOverrides },
      ...additionalOptions,
    }}
    {...props}
  >
    {children}
  </Markdown>
)

MarkdownToJsx.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  options: PropTypes.object,
}

MarkdownToJsx.defaultProps = {
  options: {},
}

export default MarkdownToJsx
