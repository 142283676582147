import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'redux-routable-react'
import { areRequestsPending } from '~/data/pending'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import { getCurrentTask, getTaskDetails } from '../data/currentTaskId'
import {
  Task,
  cancelTask,
  createTaskForm,
  fetchTask,
  reassignTask,
  snoozeTask,
  syncContactsInTaskForm,
} from '../data/tasks'
import TaskDetail from './TaskDetail'

const styles = ({ palette, spacing }) => ({
  backButton: {
    color: palette.common.white,
    paddingRight: 12,
  },
  backIcon: {
    color: palette.text.primary,
    marginRight: spacing(1),
  },
})

const TaskDetails = ({
  route,
  pending,
  syncContactsPending,
  task,
  routeContext,
  classes,
  onSeparateRoute,
}) => {
  return (
    <React.Fragment>
      {onSeparateRoute && routeContext && (
        <Button
          color="secondary"
          component={Link}
          className={classes.backButton}
          params={{ [routeContext.key]: routeContext.value }}
          route={route}
          size="small"
          variant="contained"
        >
          <Icon className={classes.backIcon}>arrow_back</Icon>
          Return to Task List
        </Button>
      )}

      <TaskDetail
        pending={pending}
        task={task}
        syncContactsPending={syncContactsPending}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state, props) => {
  // Task detail is used by both DevX grid and independently. If it's in the grid the details
  // are passed in via the row, which is why this matters.
  const onSeparateRoute = !props.row

  return {
    pending: areRequestsPending(state, [
      fetchTask,
      createTaskForm,
      cancelTask,
      reassignTask,
      snoozeTask,
    ]),
    syncContactsPending: areRequestsPending(state, [syncContactsInTaskForm]),
    task: onSeparateRoute
      ? getCurrentTask(state)
      : getTaskDetails(state)(props.row.id),
    onSeparateRoute,
  }
}

TaskDetails.propTypes = {
  route: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSeparateRoute: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  syncContactsPending: PropTypes.bool.isRequired,
  routeContext: PropTypes.object,
  task: PropTypes.instanceOf(Task),
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(TaskDetails)
