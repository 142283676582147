import axios from 'axios'
// @ts-expect-error FIXME: Cannot find module 'jwt-decode' or its ... Remove this comment to see the full error message
import jwtDecode from 'jwt-decode'
import { SESSION_TOKEN, SESSION_USER, setItem } from '~/utils/storage'
import { KeycloakProvider, User } from './provider'

const devAuthTokenUrl = `${window.env.ASPIRE_API_PATH}/dev_auth/token`

let token: string, user: User
let loginListener = () => {}

const setTokenAndUser = (rawToken: string): void => {
  const claims = jwtDecode(rawToken)

  token = rawToken
  user = {
    id: claims.user_id,
    name: claims.name,
  }

  // This is to help enable testing offline caching. Otherwise you get a cannot access property of undefined error
  setItem(SESSION_TOKEN, token)
  setItem(SESSION_USER, user)
}

const mock: KeycloakProvider = {
  onLogin: listener => {
    loginListener = listener
  },
  onLogout: () => {},
  init: () =>
    axios
      .get(devAuthTokenUrl)
      .then(response => response.data.data)
      .then(token => {
        setTokenAndUser(token)
        loginListener()
      }),
  login: () => {},
  logout: () => {},
  get user() {
    return Promise.resolve(user)
  },
  get token() {
    return Promise.resolve(token)
  },
  get expiresAt() {
    return null
  },
  isTokenExpired: () => false,
  refreshToken: () => Promise.resolve(),
}

export default mock
