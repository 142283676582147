import { unparse } from 'papaparse'

/* Turns an array of objects to csv format */
export const createCSV = data => (data ? unparse(data) : null)

/*
  Input data can be in the form of normalized Map or List of Maps
  If it is a Map,
  Returns an array of objects (Immutable List)
*/
const formatDataForCSVDownload = data => data.toList().toJS()

/*
  Creates invisible link to download csv
  Takes data and a filename as args
*/
export const downloadCSV = (data, name = 'file_name.csv') => {
  const csv = createCSV(formatDataForCSVDownload(data))
  const blob = new Blob([csv])
  const href = URL.createObjectURL(blob)

  const temporaryDownloadLink = document.createElement('a')

  temporaryDownloadLink.style.display = 'none'
  temporaryDownloadLink.setAttribute('href', href)
  temporaryDownloadLink.setAttribute('download', name)
  temporaryDownloadLink.setAttribute('type', 'text/csv')

  document.body.appendChild(temporaryDownloadLink)
  temporaryDownloadLink.click()
  document.body.removeChild(temporaryDownloadLink)
}
