import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import CreateFieldValueDisplayComponent from '~/components/CreateFieldValueDisplayComponent'
import DevXGrid from '~/components/DevXGrid'
import DisplayComponentWithTooltip from '~/components/DisplayComponentWithTooltip'
import DisplayDate from '~/components/DisplayDate'
import DisplayUTCTimestampAsLocalDate from '~/components/DisplayUTCTimestampAsLocalDate'
import PatientCell from '~/components/PatientCell'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import RiskCell from '../../components/RiskCell'
import { getCensus } from '../../data/census'
import { ADD_LIST_ITEM, getDialog } from '../../data/dialog'
import PatientDetails from '../PatientDetails'
import AddListDialog from './AddListDialog'
import RowActionsBase from './RowActions'

const BOOLEAN_WIDTH = 115

const baseColumns = [
  { name: 'appName', title: 'APP Name' },
  {
    name: 'patientFirstName',
    title: 'Patient First Name',
    displayComponent: PatientCell,
  },
  {
    name: 'patientLastName',
    title: 'Patient Last Name',
    displayComponent: PatientCell,
  },
  { name: 'program', title: 'Program' },
  { name: 'market', title: 'Market' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'channel', title: 'Channel' },
  { name: 'healthplan', title: 'Health Plan' },
  { name: 'status', title: 'Status' },
  {
    name: 'riskLevel',
    title: 'Risk Level',
    displayComponent: RiskCell,
  },
  {
    name: 'lastCallDate',
    title: 'Last Call Date',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'appNtvd',
    title: 'APP NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'appNtvdModalityLabel',
    title: 'APP NTVM',
  },
  {
    name: 'chwNtvd',
    title: 'CHW NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'chwNtvdModalityLabel',
    title: 'CHW NTVM',
  },
  {
    name: 'pccRnNtvd',
    title: 'RN NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'pccRnNtvdModalityLabel',
    title: 'RN NTVM',
  },
  {
    name: 'pccSwNtvd',
    title: 'SW NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'pccSwNtvdModalityLabel',
    title: 'SW NTVM',
  },
  {
    name: 'lastVisitDate',
    title: 'Last Visit Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'nextScheduledVisit',
    title: 'Next Scheduled Visit',
    displayComponent: DisplayDate,
  },
  {
    name: 'dischargeRecommendationReason',
    title: 'Discharge Recommendation Reason',
    displayComponent: DisplayComponentWithTooltip(
      CreateFieldValueDisplayComponent('discharge_recommendation_reason')
    ),
  },
  {
    name: 'outlier',
    title: 'Current Outlier',
    type: 'boolean',
  },
  {
    name: 'acpDocuments',
    title: 'ACP Documents',
    type: 'boolean',
  },
  {
    name: 'limitedPrognosis',
    title: 'Lim. Prog.',
    type: 'boolean',
  },
  {
    name: 'activeHospitalization',
    title: 'In Hospital',
    type: 'boolean',
  },
  {
    name: 'recentHospitalization',
    title: 'Recent Hosp. DC',
    type: 'boolean',
  },
  { name: 'activeSnf', title: 'In SNF', type: 'boolean' },
  {
    name: 'recentSnf',
    title: 'Recent SNF DC',
    type: 'boolean',
  },
  {
    name: 'recentDischarge',
    title: 'Discharged',
    type: 'boolean',
  },
  {
    name: 'substatus',
    title: 'Unengaged Substatus',
    type: 'boolean',
    getCellValue: ({ substatus }) => substatus === 'Unengaged',
  },
  { name: 'overdue', title: 'Overdue', type: 'boolean' },
  {
    name: 'hotspotter',
    title: 'Hotspotter',
    type: 'boolean',
  },
  {
    name: 'recommendedForDischarge',
    title: 'Rec. For DC',
    type: 'boolean',
  },
  {
    name: 'lastSwVisitDate',
    title: 'Last SW Visit',
    displayComponent: DisplayDate,
  },
]

const columnExtensions = [
  { columnName: 'appName', width: 250 },
  { columnName: 'patientFirstName', width: 150 },
  { columnName: 'patientLastName', width: 150 },
  { columnName: 'program', width: 200 },
  { columnName: 'market', width: 175 },
  { columnName: 'channel', width: 250 },
  { columnName: 'status', width: 150 },
  { columnName: 'outlier', width: 125 },
  { columnName: 'acpDocuments', width: 150 },
  { columnName: 'limitedPrognosis', width: BOOLEAN_WIDTH },
  { columnName: 'activeHospitalization', width: BOOLEAN_WIDTH },
  { columnName: 'recentHospitalization', width: 135 },
  { columnName: 'activeSnf', width: BOOLEAN_WIDTH },
  { columnName: 'recentSnf', width: 125 },
  { columnName: 'recentDischarge', width: BOOLEAN_WIDTH },
  { columnName: 'substatus', width: BOOLEAN_WIDTH },
  { columnName: 'overdue', width: BOOLEAN_WIDTH },
  { columnName: 'hotspotter', width: BOOLEAN_WIDTH },
  { columnName: 'recommendedForDischarge', width: 125 },
  { columnName: 'healthplan', width: 250 },
  { columnName: 'lastSwVisitDate', width: 250 },
]

const defaultHiddenColumnNames = [
  'dischargeRecommendationReason',
  'channel',
  'healthplan',
]

const pageSizes = [25, 50]

const CensusGrid = ({
  userId,
  excludedColumnNames,
  rowActions,
  rowActionsCount,
  localSettingsKey,
  excludedListTypes,
  defaultGrouping,
  showListButton,
}) => {
  const census = useSelector(getCensus)

  const columns = useMemo(
    () => baseColumns.filter(({ name }) => !excludedColumnNames.includes(name)),
    [excludedColumnNames]
  )
  const rows = useMemo(() => census.toArray(), [census])

  const RowActions = useCallback(
    props => (
      <RowActionsBase
        showListButton={showListButton}
        rowActions={rowActions}
        {...props}
      />
    ),
    [rowActions]
  )

  const dialog = useSelector(getDialog)

  return (
    <React.Fragment>
      <Paper>
        <DevXGrid
          columns={columns}
          columnExtensions={columnExtensions}
          rows={rows}
          rowActions={RowActions}
          rowActionsCount={rowActionsCount + (showListButton ? 1 : 0)}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          detailComponent={PatientDetails}
          localSettingsKey={localSettingsKey}
          columnHideable
          columnReorderable
          filterable
          groupable
          pageable
          pageSizes={pageSizes}
          defaultPageSize={25}
          searchable
          sortable
          defaultGrouping={defaultGrouping}
          isVirtual={true}
        />
      </Paper>

      <AddListDialog
        userId={userId}
        excludedListTypes={excludedListTypes}
        open={dialog.dialogType === ADD_LIST_ITEM}
        patientId={dialog.patientId}
        patientName={dialog.patientName}
      />
    </React.Fragment>
  )
}

CensusGrid.propTypes = {
  defaultGrouping: PropTypes.array,
  excludedColumnNames: PropTypes.array,
  excludedListTypes: PropTypes.array,
  localSettingsKey: PropTypes.string,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
  showListButton: PropTypes.bool,
  userId: PropTypes.string,
}

CensusGrid.defaultProps = {
  excludedColumnNames: [],
  rowActionsCount: 0,
  showListButton: false,
}

export default CensusGrid
