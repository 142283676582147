import React from 'react'
import PropTypes from '~/utils/propTypes'
import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette }) => ({
  disabled: {
    '&&': {
      color: palette.text.primary,
    },
  },
})

const FormControlLabel = ({ classes, ...props }) => (
  <MuiFormControlLabel {...props} classes={{ disabled: classes.disabled }} />
)

FormControlLabel.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormControlLabel)
