// @ts-expect-error ts-migrate(2307) FIXME
import { combineReducers } from 'redux-immutable'
import openGaps from './data/openGaps'
import key from './key'

const reducer = combineReducers({
  [openGaps.key]: openGaps,
})

reducer.key = key

export default reducer
