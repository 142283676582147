import { ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import { showError } from '~/utils/alertOnError'
import loading from '~/utils/loading'
import {
  POTENTIAL_CHANNELS_FETCH_REQUESTED,
  fetchPotentialChannels,
  potentialChannelsFetchFailed,
  potentialChannelsStored,
} from '../data/potentialChannels'

const handleResponse = (channels = []) =>
  channels.length
    ? of(potentialChannelsStored(channels))
    : of(potentialChannelsFetchFailed())

const handleError = (e = { message: 'Unknown Error' }) =>
  merge(of(potentialChannelsFetchFailed()), showError(e.message)(e))

const fetchAndHandleResponse = payload =>
  from(fetchPotentialChannels(payload)).pipe(
    mergeMap(handleResponse),
    catchError(handleError)
  )

export default action$ =>
  action$.pipe(
    ofType(POTENTIAL_CHANNELS_FETCH_REQUESTED),
    pluck('payload'),
    mergeMap(payload => loading(fetchAndHandleResponse(payload)))
  )
