import { ofType } from 'redux-observable'
import { pipe } from 'rxjs'
import { filter, first, map, withLatestFrom } from 'rxjs/operators'

export const switchTo = observable =>
  pipe(
    withLatestFrom(observable),
    map(([, value]) => value)
  )

// Reject values that pass predicate function
export const reject = predicate => filter((...args) => !predicate(...args))

// Only emit a value if the first response from a request is successful
export const requestSucceeded = request => action$ =>
  action$.pipe(
    ofType(request.SUCCEEDED, request.FAILED),
    first(),
    ofType(request.SUCCEEDED)
  )
