import cx from 'classnames'
import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
    marginBottom: spacing(2),
  },
  title: {
    marginBottom: spacing(1),
  },
  placeholder: {
    display: 'block',
    height: spacing(5),
  },
}))

type Props = {
  title: string
  children: React.ReactNode
  className?: string
}

const Section = ({ title, children, className: sourceStyles }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {title ? (
        <Typography
          color="primary"
          className={cx(classes.title, sourceStyles)}
          variant="h6"
        >
          {title}
        </Typography>
      ) : (
        <span className={classes.placeholder} />
      )}

      {children}
    </div>
  )
}

export default Section
