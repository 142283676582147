import { merge, of } from 'rxjs'
import { delay } from 'rxjs/operators'
import telemetry from '~/utils/telemetry'
import { addError, deleteError } from '../data/errors'

// Displays error and removes after 5 seconds
const showError = message => e => {
  telemetry.error(new Error(message), e)

  return merge(
    of(addError(message)),
    of(deleteError(message)).pipe(delay(5000))
  )
}

export default showError
