import React from 'react'
import PropTypes from '~/utils/propTypes'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MaterialUISwitch from '@material-ui/core/Switch'

class Switch extends React.Component {
  onChange = (e, v) => {
    const bool = v ? true : false
    this.props.onChange(bool)
  }

  render = () => {
    const {
      className,
      error,
      helperText,
      label,
      onBlur,
      onChange,
      value,
      ...props
    } = this.props

    return (
      <div>
        <FormControlLabel
          className={className}
          control={
            <MaterialUISwitch
              {...props}
              checked={!!value || false}
              value={value ? 'true' : 'false'}
              onChange={onChange && this.onChange}
            />
          }
          label={label}
        />
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : ''}
      </div>
    )
  }
}

Switch.propTypes = {
  value: PropTypes.any,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.array,
  helperText: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
}

export default Switch
