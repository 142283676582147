import { Record } from 'immutable'
import {
  calcFormData,
  changeFormData,
  getTaggedValue,
} from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from '../data/common'
import { dialogOpened } from '../data/dialog'
import TASKS from '../key'

// Key
const CREATE_NEW_TASK = 'createNewTask'

// Type
const typePrefix = `${TASKS}/${CREATE_NEW_TASK}`
const creator = scopedCreator(typePrefix)

// Actions
export const createNewTaskSubmitted = creator('SUBMITTED')
export const createNewTaskErrored = creator('ERRORED')
export const createNewTaskUpdated = creator('TASK_UPDATED', ['data'])
export const createNewTaskFormUpdated = creator('UPDATED')

// Record
export const NewTaskForm = Record({
  patientId: null,
  taskDefinitionKey: null,
  context: {},
  errored: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
  type: null,
})

// Requests
export const createNewTask = Request({
  typePrefix,
  typeBase: 'SUBMIT',
  requestParams: ['patientId', 'taskDefinitionKey', 'formData'],
  operation: (patient_id, task_definition_key, formData) =>
    AspireAPI.post('tasks', { patient_id, task_definition_key, formData }),
  messages: {
    failed: 'Failed to create task',
    succeeded: 'Task created',
  },
})

export const fetchNewTaskForm = Request({
  typePrefix,
  typeBase: 'FORM_FETCH',
  requestParams: ['patientId', 'taskDefinitionKey'],
  operation: (patientId, taskDefinitionKey) =>
    AspireAPI.get(`task_definitions/${taskDefinitionKey}/form`, {
      params: { patient_id: patientId },
    }),
  transform: ({ data: formData, ...form }) =>
    NewTaskForm({
      ...form,
      formData: calcFormData({
        context: form.context,
        formData,
        schema: form.schema,
        tags: form.tags,
      }),
    }),
  messages: {
    failed: 'Failed to load task request form',
  },
})

// Reducer
export default createReducer(CREATE_NEW_TASK, NewTaskForm(), {
  [createNewTask.SUCCEEDED]: () => NewTaskForm(),
  [fetchNewTaskForm.SUCCEEDED]: (
    { patientId, taskDefinitionKey },
    { payload }
  ) => payload.merge({ patientId, taskDefinitionKey }),
  [dialogOpened]: (state, { payload }) =>
    state.update('patientId', pid => payload.patientId || pid),
  [createNewTaskErrored]: (state, { payload }) => state.set('errored', payload),
  [createNewTaskUpdated]: (state, { payload }) => state.merge(payload.data),
  [createNewTaskFormUpdated]: (state, { payload: { errors, formData } }) =>
    changeFormData(state, null, formData).set(
      'errored',
      Boolean(errors.length)
    ),
})

export const getFormDataByTag = (state, tag, options) => {
  const form = getCreateNewTaskForm(state)
  return getTaggedValue(form, tag, options)
}

// Selectors
export const getCreateNewTaskForm = pipe(getRoot, get(CREATE_NEW_TASK))
