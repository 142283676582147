import { Map } from 'immutable'
import { rawThemeWithType } from '~/themes/default'
import { holidayTheme } from '~/themes/holidayThemes'
import createReducer from '~/utils/createReducer'

export const key = 'settings'

export const TOGGLE_LIGHTS = `${key}/TOGGLE_LIGHTS`
export const SET_HOLIDAY_THEME = `${key}/SET_HOLIDAY_THEME`
export const SET_THEME = `${key}/SET_THEME`

export const toggleLights = () => ({ type: TOGGLE_LIGHTS })
export const setHolidayTheme = holiday => ({ type: SET_HOLIDAY_THEME, holiday })
export const setTheme = newType => ({ type: SET_THEME, newType })

const initialState = Map({ theme: rawThemeWithType('light') })

const typeToggles = { light: 'dark', dark: 'light' }

export default createReducer(key, initialState, {
  [TOGGLE_LIGHTS]: state =>
    state.update('theme', theme => {
      const oldType = theme.palette.type
      const newType = typeToggles[oldType]

      return rawThemeWithType(newType)
    }),
  [SET_HOLIDAY_THEME]: (state, { holiday }) =>
    state.set('theme', holidayTheme(holiday)),
  [SET_THEME]: (state, { newType = 'light' }) =>
    state.set('theme', rawThemeWithType(newType)),
})

const getSettings = state => state.get('settings')

export const getTheme = state => getSettings(state).get('theme')

export const getHoliday = state => getTheme(state).palette.holiday
