export default {
  tasks: {
    99: {
      id: 99,
      name: 'On Call Follow Up Call or Returned Call',
      users: [
        { id: 'jkl', name: "G'ooch Slimbody", role: 'CSS' },
        { id: 'abc', name: "Borpo T'arr", role: 'APP' },
        { id: 'def', name: 'Crungle Ferp', role: 'PCC CC' },
        { id: 'mno', name: "Hairishable Qu'aim", role: 'PCC RN' },
        { id: 'ghi', name: 'Grimelda Chrimb', role: 'PCC SW' },
        { id: 'pqr', name: 'Tungus Amunkus', role: 'PCC OA' },
        { id: 'stu', name: 'Wrimk McSketchins', role: 'NSS' },
      ],
    },
    100: {
      id: 100,
      name: 'On Call Follow Up Call Clinical Concern',
      users: [
        { id: 'mno', name: "Hairishable Qu'aim", role: 'PCC RN' },
        { id: 'abc', name: "Borpo T'arr", role: 'APP' },
      ],
    },
    101: {
      id: 101,
      name: 'On Call Follow Up DME or Other Order',
      users: [{ id: 'stu', name: 'Wrimk McSketchins', role: 'NSS' }],
    },
    102: {
      id: 102,
      name: 'On Call Follow Up Hospice Transition',
      users: [{ id: 'mno', name: "Hairishable Qu'aim", role: 'PCC RN' }],
    },
    103: {
      id: 103,
      name: 'On Call Follow Up SW',
      users: [{ id: 'ghi', name: 'Grimelda Chrimb', role: 'PCC SW' }],
    },
    104: {
      id: 104,
      name: 'On Call Schedule Request',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
    105: {
      id: 105,
      name: 'On Call Transition of Care',
      users: [{ id: 'def', name: 'Crungle Ferp', role: 'PCC CC' }],
    },
  },
}
