import { ofType } from 'redux-observable'
import { distinctUntilChanged, map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { panelOpened } from '~/data/appLayout'
import {
  panelClosed,
  patientTransportationPanelOpened,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
} from '~/data/patientPanel'
import { patientDemoRequested } from './loadPatientDemographics'

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const openPanel = action$ =>
  action$.pipe(
    ofType(patientTransportationPanelOpened),
    pluck('payload', 'id'),
    map((id: string) => (id ? panelOpened() : panelClosed()))
  )

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const fetchPatientInfo = action$ =>
  action$.pipe(
    ofType(patientTransportationPanelOpened),
    pluck('payload', 'id'),
    distinctUntilChanged(),
    map(patientDemoRequested)
  )
