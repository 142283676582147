import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getRoot } from '../common/shared'

export const CALENDAR = 'calendar'

const creator = scopedCreator(rootKey)
export const calendarInitialized = creator('CALENDAR_INITIALIZED', ['userId'])
export const calendarUnmounted = creator('CALENDAR_UNMOUNTED')

export const getCalendar = pipe(getRoot, get(CALENDAR))
