import { ofType } from 'redux-observable'
import {
  debounceTime,
  filter,
  map,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
import { getUserId } from '~/data/session'
import {
  checkInWarningSnackbarOpened,
  currentAssessmentFetched,
} from '../data/assessments'
import { OPEN_STATUSES } from '../data/common/shared'

export default (action$, state$) =>
  action$.pipe(
    ofType(currentAssessmentFetched),
    pluck('payload', 'assessment'),
    withLatestFrom(state$),
    filter(
      ([{ providerId, checkedIn, status }, state]) =>
        getUserId(state) === providerId &&
        !checkedIn &&
        OPEN_STATUSES.includes(status)
    ),
    debounceTime(1000),
    map(checkInWarningSnackbarOpened)
  )
