import { combineEpics, ofType } from 'redux-observable'
import { filter, ignoreElements, pluck, tap } from 'rxjs/operators'
import {
  LOCAL_ENCOUNTER_CHECK_IN,
  LOCAL_ENCOUNTER_CHECK_OUT,
  setItem,
} from '~/utils/storage'
import { checkIntoEncounter, checkOutOfEncounter } from '../data/common/shared'

const encounterCheckInEpic = action$ =>
  action$.pipe(
    ofType(checkIntoEncounter.FAILED),
    pluck('meta', 'request', 'payload'),
    filter(payload => Boolean(payload.encounterId && payload.checkInAt)),
    tap(payload => {
      setItem(`${LOCAL_ENCOUNTER_CHECK_IN}-${payload.encounterId}`, payload)
    }),
    ignoreElements()
  )

const encounterCheckOutEpic = action$ =>
  action$.pipe(
    ofType(checkOutOfEncounter.FAILED),
    pluck('meta', 'request', 'payload'),
    filter(payload => Boolean(payload.encounterId && payload.checkOutAt)),
    tap(payload => {
      setItem(`${LOCAL_ENCOUNTER_CHECK_OUT}-${payload.encounterId}`, payload)
    }),
    ignoreElements()
  )

export default combineEpics(encounterCheckInEpic, encounterCheckOutEpic)
