import { combineReducers } from 'redux-immutable'
import patientSearch from './data/patientSearch'
import search from './data/search'
import key from './key'

const reducer = combineReducers({
  [patientSearch.key]: patientSearch,
  [search.key]: search,
})

reducer.key = key

export default reducer
