import { Map, Record, fromJS } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// RECORDS
export const ReferralCaseNotes = Record({
  author: null,
  id: null,
  note: null,
  time: null,
})

// CONSTANTS
export const REFERRAL_NOTES_REQUESTED = 'referralNotes/REFERRAL_NOTES_REQUESTED'
export const REFERRAL_NOTES_LOADED = 'referralNotes/REFERRAL_NOTES_LOADED'
export const REFERRAL_NOTE_CREATION_REQUEST =
  'referralNotes/REFERRAL_NOTE_REQUESTED'
export const REFERRAL_NOTE_SAVED = 'referralNotes/REFERRAL_NOTE_SAVED'
export const REFERRAL_NOTE_DIALOG_TOGGLED = 'referralNotes/DIALOG_TOGGLED'
export const REFERRAL_NOTE_CASEID_CHANGED = 'referralNotes/CASEID_CHANGED'

// ACTION CREATORS
export const referralCaseNotesRequested = (caseId: any) => ({
  type: REFERRAL_NOTES_REQUESTED,
  caseId,
})
export const referralCaseNotesLoaded = ({ caseId, notes }: any) => ({
  type: REFERRAL_NOTES_LOADED,
  caseId,
  notes,
})
export const requestReferralNoteCreation = (caseId: any, note: any) => ({
  type: REFERRAL_NOTE_CREATION_REQUEST,
  caseId,
  note,
})
export const referralCaseNoteSaved = ({ caseId, note }: any) => ({
  type: REFERRAL_NOTE_SAVED,
  caseId,
  note,
})
export const referralNoteDialogToggled = (payload: any) => ({
  type: REFERRAL_NOTE_DIALOG_TOGGLED,
  payload,
})
export const referralNoteCaseIdSelected = (payload: any) => ({
  type: REFERRAL_NOTE_CASEID_CHANGED,
  payload,
})

// KEY
export const key = 'referralCaseNotes'

const initState = fromJS({
  notes: Map(),
  dialogOpen: false,
  selectedCase: null,
})

// REDUCER
export default createReducer(key, initState, {
  [REFERRAL_NOTES_LOADED]: (state: any, { caseId, notes }: any) =>
    state.setIn(['notes', caseId], notes),
  [REFERRAL_NOTE_SAVED]: (state: any, { note, caseId, noteId }: any) =>
    state.setIn(['notes', caseId, noteId], { note: note }),
  [REFERRAL_NOTE_DIALOG_TOGGLED]: (state: any, { payload }: any) =>
    state.set('dialogOpen', payload),
  [REFERRAL_NOTE_CASEID_CHANGED]: (state: any, { payload }: any) =>
    state.set('selectedCase', payload),
})

const mapData = (data: any) =>
  Map(
    data.map((d: any) => [
      d.noteId,
      ReferralCaseNotes({
        author: d.author,
        id: d.noteId,
        note: d.note,
        time: new Date(`${d.time}GMT`),
      }),
    ])
  )

const getId = (data: any) => (data && data[0] && data[0].id ? data[0].id : null)

// API
export const fetchReferralNotes = (caseId: any) =>
  AspireAPI.get('v1/referral/note', { params: { caseId } })
    .then(mapData)
    .then((data: any) => ({
      caseId,
      notes: data,
    }))

export const createReferralNote = (caseId: any, note: any) =>
  AspireAPI.post('v1/referral/note', { caseId, note }).then((data: any) => ({
    caseId,
    note,
    noteId: getId(data),
  }))

// SELECTORS
export const getAllReferralNotes = (state: any) =>
  getReferralManagement(state).getIn([key, 'notes'])
export const getReferralNotesByCaseById = (state: any, id: any) =>
  getAllReferralNotes(state).get(id)
export const getReferralNoteDialogStatus = (state: any) =>
  getReferralManagement(state).getIn([key, 'dialogOpen'], false)
export const getReferralNoteCaseId = (state: any) =>
  getReferralManagement(state).getIn([key, 'selectedCase'], null)
