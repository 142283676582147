import { defaultEnd, defaultStart } from '../defaults'

export default {
  type: 'VisitPlaceholder',
  recordType: 'visit_placeholder',
  confirmed: false,
  urgent: false,
  start: defaultStart,
  end: defaultEnd,
}
