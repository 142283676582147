import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette: { severity } }) => ({
  warning: {
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  colorError: {
    color: severity.error.static,
  },
})
const WARNING_TEXT =
  'Refilling medication will automatically discontinue the selected medication and create a new pending copy'

const WarningComponent = ({ classes, selectedMedication }) => {
  const isRefilling = useMemo(
    () => Boolean(selectedMedication.get('isRefilling')),
    [selectedMedication]
  )

  return (
    isRefilling && (
      <span className={classes.warning}>
        <Typography variant="h6" className={classes.colorError}>
          ** <em>{WARNING_TEXT}</em> **
        </Typography>
      </span>
    )
  )
}

WarningComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedMedication: PropTypes.map.isRequired,
}

export default withStyles(styles)(WarningComponent)
