import React from 'react'
import { Checkbox } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

const CheckboxField = props => {
  const { value, editing, onChange } = props

  const onCheckboxChange = value => {
    onChange(value)
  }

  return (
    <BaseField {...props}>
      <Checkbox disabled={!editing} onChange={onCheckboxChange} value={value} />
    </BaseField>
  )
}

CheckboxField.propTypes = {
  value: PropTypes.bool,
  editing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default CheckboxField
