import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import {
  setEnabledModifier,
  setEnabledModifiers,
  updateModifiersInGroup,
} from '../data/imo'

export const setImoEnabledModifiers = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updateModifiersInGroup),
    pluck('payload'),
    withLatestFrom(state$),
    map(([{ problemId, modifierGroupType }, _state]: any) =>
      setEnabledModifiers({
        problemId: problemId,
        modifierGroupType: modifierGroupType,
      })
    )
  )

export const setImoEnabledModifier = (action$: any, state$: any) =>
  action$.pipe(
    ofType(setEnabledModifiers),
    pluck('payload'),
    withLatestFrom(state$),
    map(([{ problemId }, _state]: any) => setEnabledModifier({ problemId }))
  )
