import { Record } from 'immutable'

export const Location = Record({
  address: null,
  street: null,
  apartment: null,
  city: null,
  state: null,
  stateCode: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  county: null,
  exact: false,
})
