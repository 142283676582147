import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const CURRENT_APP_ID = 'currentAPPId'

const creator = scopedCreator(key)
export const appChanged = creator('APP_CHANGED', ['appId'])

export default createReducer(CURRENT_APP_ID, null, {
  [appChanged]: (_state, { payload: { appId } }) => appId,
})

export const getCurrentAPPId = pipe(getRoot, get(CURRENT_APP_ID))
