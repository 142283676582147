import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const CURRENT_PATIENT_ID = 'currentPatientId'

const creator = scopedCreator(rootKey)

export const patientIdSelected = creator('PATIENT_ID_SELECTED', ['patientId'])
export const patientIdCleared = creator('PATIENT_ID_CLEARED')

const initState = {}

export default createReducer(CURRENT_PATIENT_ID, initState, {
  [patientIdSelected]: (_state, { payload: { patientId } }) => patientId,
  [patientIdCleared]: () => initState,
})

export const getCurrentPatientId = pipe(getRoot, get(CURRENT_PATIENT_ID))
