import React from 'react'
import Typography from '@material-ui/core/Typography'

// Create custom Typography component named after & using Material UI variants
const createWithVariant = variant => {
  const CustomTypography = props => <Typography variant={variant} {...props} />
  CustomTypography.displayName =
    variant.charAt(0).toUpperCase() + variant.slice(1)
  return CustomTypography
}

const Body1 = createWithVariant('body1')
const Body2 = createWithVariant('body2')
const Caption = createWithVariant('caption')
const Subtitle1 = createWithVariant('subtitle1')
const H1 = createWithVariant('h1')
const H2 = createWithVariant('h2')
const H3 = createWithVariant('h3')
const H4 = createWithVariant('h4')
const H5 = createWithVariant('h5')
const H6 = createWithVariant('h6')

export { Body1, Body2, Caption, Subtitle1, H1, H2, H3, H4, H5, H6 }
