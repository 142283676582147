import { combineEpics, ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { isCallLogActive } from '~/features/callLogger'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import {
  callLogSnackbarOpened,
  patientCleared,
  patientSelected,
} from '../data/common/shared'
import { getCurrentAPPId } from '../data/currentAPPId'
import { getCurrentUserId } from '../data/currentUserId'
import {
  CSS_DASHBOARD_APP_SCHEDULING_APP,
  CSS_DASHBOARD_APP_SCHEDULING_PATIENT,
} from '../router'

const patientSelectedEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(patientSelected),
    pluck('payload'),
    withLatestFrom(state$),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([payload, state]: [any, any]) =... Remove this comment to see the full error message
    map(([payload, state]) => {
      const userId = getCurrentUserId(state)
      const appId = payload.appId || getCurrentAPPId(state)
      const patientId = payload.patientId
      const callLogActive = isCallLogActive(state)

      return callLogActive
        ? callLogSnackbarOpened()
        : push(CSS_DASHBOARD_APP_SCHEDULING_PATIENT, {
            userId,
            appId,
            patientId,
          })
    })
  )

const patientClearedEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(patientCleared),
    switchTo(state$),
    map(state => {
      const userId = getCurrentUserId(state)
      const appId = getCurrentAPPId(state)
      const callLogActive = isCallLogActive(state)

      return callLogActive
        ? callLogSnackbarOpened()
        : push(CSS_DASHBOARD_APP_SCHEDULING_APP, { userId, appId })
    })
  )

export default combineEpics(patientSelectedEpic, patientClearedEpic)
