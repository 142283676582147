import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FieldWrapper from './FieldWrapper'
import memoizedWrapper from './widgets/memoizedWrapper'

const styles = ({ spacing }) => ({
  noItems: {
    textAlign: 'center',
    margin: {
      top: spacing(1),
      bottom: spacing(1),
    },
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  item: {
    flex: '1',
  },
  buttons: {
    margin: spacing(1),
    marginBottom: spacing(0.5),
  },
  button: {
    padding: spacing(0.5),
  },
})

class ArrayFieldTemplate extends React.Component {
  renderItem = ({
    children,
    index,
    disabled,
    hasToolbar,
    hasMoveUp,
    hasMoveDown,
    hasRemove,
    onReorderClick,
    onDropIndexClick,
  }) => {
    const { classes } = this.props

    return (
      <div key={index} className={classes.itemWrapper}>
        <div className={classes.item}>{children}</div>
        {hasToolbar && (
          <div className={classes.buttons}>
            {hasMoveUp && !disabled && (
              <Tooltip title="Move Up">
                <IconButton
                  className={classes.button}
                  onClick={onReorderClick(index, index - 1)}
                >
                  <Icon>arrow_upward</Icon>
                </IconButton>
              </Tooltip>
            )}
            {hasMoveDown && !disabled && (
              <Tooltip title="Move Down">
                <IconButton
                  className={classes.button}
                  onClick={onReorderClick(index, index + 1)}
                >
                  <Icon>arrow_downward</Icon>
                </IconButton>
              </Tooltip>
            )}
            {hasRemove && !disabled && (
              <Tooltip title="Delete">
                <IconButton
                  className={classes.button}
                  onClick={onDropIndexClick(index)}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      classes,
      disabled,
      readonly,
      schema,
      uiSchema,
      items,
      canAdd,
      onAddClick,
    } = this.props

    return (
      <FieldWrapper
        schema={schema}
        uiSchema={uiSchema}
        bottomContent={
          canAdd && (
            <Button
              disabled={disabled || readonly}
              size="small"
              onClick={onAddClick}
              variant="contained"
            >
              Add Item
            </Button>
          )
        }
      >
        {items.length === 0 && (
          <Typography className={classes.noItems} variant="subtitle1">
            No Items
          </Typography>
        )}
        {items.map(this.renderItem)}
      </FieldWrapper>
    )
  }
}

ArrayFieldTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  canAdd: PropTypes.bool.isRequired,
  onAddClick: PropTypes.func.isRequired,
}

export default compose(memoizedWrapper, withStyles(styles))(ArrayFieldTemplate)
