import { debounce } from 'lodash'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error ts-migrate(7016)
import { getPatientId } from '~/features/patientInfo'
import { useAction, useInput } from '~/hooks'
import {
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  TextField,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  clearSuggestions,
  fetchImoSessionId,
  fetchSearchProblems,
  setSearchCriteria,
  updateProblemsStatus,
} from '../data/imo'
import { getImoSessionId, getImoSuggestions } from '../data/utils/selectors'

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      alignItems: 'center',
      columnGap: spacing(2),
      marginBottom: spacing(1),
    },
    searchRow: {
      gridColumnStart: 'span 2',
      alignItems: 'center',
      display: 'flex',
    },
    autocomplete: {
      width: '50%',
    },
    button: {
      width: 100,
      height: 40,
    },
    iconButton: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      margin: '4px 0 0 16px',
      '&:hover': {
        backgroundColor: palette.primary.dark,
      },
      '&:disabled': {
        backgroundColor: palette.grey[200],
        color: palette.grey[500],
        opacity: 0.5,
      },
    },
    checkbox: {
      marginLeft: 50,
    },
  })
)

const ImoSearchBar = () => {
  const classes = useStyles()

  const [filterByAge, setfilterByAge] = useState(true)
  const [filterBySex, setfilterBySex] = useState(true)
  const [searchTerm, setSearchTerm] = useInput('')

  const getSessionIdRequested = useAction(fetchImoSessionId.requested)
  const getSearchProblemsRequested = useAction(fetchSearchProblems.requested)
  const setSearch = useAction(setSearchCriteria)
  const resetSuggestions = useAction(clearSuggestions)
  const resetProblemsStatus = useAction(updateProblemsStatus)

  const patientId: string = useSelector(getPatientId)
  const sessionId = useSelector(getImoSessionId)
  const suggestions = useSelector(getImoSuggestions)

  const search = useCallback(debounce(getSessionIdRequested, 500), [
    getSessionIdRequested,
  ])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    getSearchProblemsRequested(
      patientId,
      sessionId,
      searchTerm,
      filterByAge,
      filterBySex
    )
  }

  const onChangeFilterBySex = (_e: React.ChangeEvent<HTMLInputElement>) =>
    setfilterBySex(!filterBySex)
  const onChangeFilterByAge = (_e: React.ChangeEvent<HTMLInputElement>) =>
    setfilterByAge(!filterByAge)

  const reset = () => {
    setSearchTerm('')
    setSearch({})
    resetSuggestions()
    resetProblemsStatus()
  }

  const onSearchSuggestions = (_e: ChangeEvent<unknown>, value: string) => {
    if (value?.trim()) {
      setSearchTerm(value)
      setSearch({ searchTerm: value, filterByAge, filterBySex })
      search(patientId, sessionId, value, filterByAge, filterBySex)
    } else {
      reset()
    }
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className={classes.container}>
        <div className={classes.searchRow}>
          <Autocomplete
            className={classes.autocomplete}
            freeSolo
            options={suggestions ? suggestions : []}
            getOptionLabel={(option: string) => option || ''}
            onInputChange={onSearchSuggestions}
            blurOnSelect
            inputValue={searchTerm}
            renderInput={params => (
              <TextField
                {...params}
                margin="normal"
                variant="outlined"
                value={searchTerm}
                label="Search Problem Types..."
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: e => {
                    if (e.key === 'Enter') {
                      e.stopPropagation()
                    }
                  },
                }}
              />
            )}
          />
          <IconButton
            className={classes.iconButton}
            disabled={!sessionId || !searchTerm}
            type="submit"
          >
            <Icon>search</Icon>
          </IconButton>
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox checked={filterByAge} onChange={onChangeFilterByAge} />
            }
            label="Filter by age"
          />
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox onChange={onChangeFilterBySex} checked={filterBySex} />
            }
            label="Filter by sex"
          />
        </div>
      </div>
    </form>
  )
}

export default ImoSearchBar
