import { combineReducers } from 'redux-immutable'
import { fetchCommunicationsReducer } from '~/features/communications/data/communications'
import key from './key'

const reducer = combineReducers({
  [fetchCommunicationsReducer.key]: fetchCommunicationsReducer,
})

reducer.key = key

export default reducer
