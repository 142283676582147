import { makeStyles } from '@material-ui/core'
import {
  MEMBER_ADDRESS_TYPE,
  PRIMARY_CARE_PROVIDER,
  STATUS_ACTIVE,
} from './data/constants'
import { ClassplanProducts } from './data/interfaces'

export const formatCspiId = (planData: any) => {
  const formattedPlans: any = []
  planData?.toArray().map((plan: ClassplanProducts) => {
    const id = plan.classplanProductId
    const name = plan.classplanProductName
    const planCountyData = name.split('(')
    const countyInfo = plan.countyName
    if (countyInfo) {
      const data = {
        cId: id,
        county: countyInfo,
        planName: planCountyData[0].trim(),
      }
      formattedPlans.push(data)
    }
  })
  return formattedPlans
}

export const useStylesForGrids = makeStyles(({ spacing, palette }) => ({
  header: {
    '& .MuiToolbar-regular': {
      backgroundColor: palette.accordion.header,
      color: palette.accordion.color,
    },
    '& .MuiButtonBase-root': {
      color: palette.accordion.color,
    },
    '& .MuiTableCell-head': {
      whiteSpace: 'break-spaces',
    },
  },
  gridContainer: {
    '& .MuiTableCell-body[class*="TableDetailCell"]': {
      backgroundColor:
        palette.type === 'light' ? palette.grey[200] : palette.grey[700],
      maxWidth: 'fit-content',
      overflowX: 'scroll',
    },
    '& .MuiTableCell-body': {
      whiteSpace: 'break-spaces',
    },
  },

  // @ts-expect-error ts-migrate(7031) Binding element 'width' implicitly has an 'any' type
  container: ({ width }) =>
    width && {
      maxWidth: width - spacing(5),
    },
}))

export const preparePcpDetails = (pcpDetails: any) => [
  {
    type: PRIMARY_CARE_PROVIDER,
    name: pcpDetails?.name,
    address: pcpDetails?.address1,
    address2: pcpDetails?.address2,
    county: pcpDetails?.county,
    city: pcpDetails?.city,
    state: pcpDetails?.state,
    zipcode: pcpDetails?.zipcode,
    phone: pcpDetails?.phone,
    phoneExtension: pcpDetails?.phoneExtension,
    status: STATUS_ACTIVE,
  },
]

export const prepareMemberAddresses = (patientInfo: any) => [
  {
    type: MEMBER_ADDRESS_TYPE,
    name: `${patientInfo?.firstName}  ${patientInfo?.lastName}`,
    phone: patientInfo?.homeAddress.memberContactNumber,
    address: patientInfo?.homeAddress.memberAddress1,
    address2: patientInfo?.homeAddress.memberAddress2,
    state: patientInfo?.homeAddress.memberState,
    zipcode: patientInfo?.homeAddress.memberZipCode,
    county: patientInfo?.homeAddress.memberCounty,
    city: patientInfo?.homeAddress.memberCity,
    status: STATUS_ACTIVE,
  },
  {
    type: MEMBER_ADDRESS_TYPE,
    name: `${patientInfo?.firstName}  ${patientInfo?.lastName}`,
    phone: patientInfo?.mailAddress.memberContactNumber,
    address: patientInfo?.mailAddress.memberAddress1,
    address2: patientInfo?.mailAddress.memberAddress2,
    state: patientInfo?.mailAddress.memberState,
    zipcode: patientInfo?.mailAddress.memberZipCode,
    county: patientInfo?.mailAddress.memberCounty,
    city: patientInfo?.mailAddress.memberCity,
    status: STATUS_ACTIVE,
  },
]

export const filterProviderAddressByStatus = (
  isActive: boolean,
  providerAddressList: any
) =>
  isActive
    ? providerAddressList
        .toArray()
        .filter((search: any) => search.status === STATUS_ACTIVE)
    : providerAddressList.toArray()

export const transformTripStatus = (id: any, status: any, countable: any) => {
  if (id == 40000) {
    return `${status}`
  }

  return `${status}${' - '}${countable ? 'Counts' : 'No Count'}`
}
