import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { fetchInterventions } from '../data/intervention'
import { CASE_MANAGER_DASHBOARD_INTERVENTIONS } from '../router'

export default action$ =>
  action$.pipe(
    filter(entered(CASE_MANAGER_DASHBOARD_INTERVENTIONS)),
    pluck('payload', 'params', 'cmId'),
    map(fetchInterventions.requested)
  )
