import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { fetchMetabaseSecret } from '../data/metabaseSecret'
import { fetchReport, fetchReports } from '../data/reports'
import router, {
  REPORTS_DASHBOARD_HOME,
  REPORTS_DASHBOARD_REPORT,
} from '../router'

const app = (action$: any) =>
  action$.pipe(filter(entered(router)), map(fetchMetabaseSecret.requested))

const home = (action$: any) =>
  action$.pipe(
    filter(entered(REPORTS_DASHBOARD_HOME)),
    map(fetchReports.requested)
  )

const report = (action$: any) =>
  action$.pipe(
    filter(entered(REPORTS_DASHBOARD_REPORT)),
    pluck('payload', 'params', 'reportId'),
    map(Number),
    map(fetchReport.requested)
  )

export default combineEpics(app, home, report)
