import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const CODER_DASHBOARD_HOME = `${key}.codingQueue`
export const CODER_DASHBOARD_BATCHES = `${key}.batches`
export const CODER_DASHBOARD_BATCH_DETAILS = `${key}.batchDetails`
export const CODER_DASHBOARD_BATCH_CREATION = `${key}.batchCreation`
export const CODER_DASHBOARD_ENCOUNTER = `${key}.encounter`
export const CODER_DASHBOARD_ENCOUNTER_ID_SEARCH = `${key}.encounterIdSearch`

export default Router([
  Route(CODER_DASHBOARD_ENCOUNTER, '/encounter/:encounterId'),
  Route(CODER_DASHBOARD_ENCOUNTER_ID_SEARCH, '/encounter-id-search'),
  Route(CODER_DASHBOARD_HOME, '/coding-queue'),
  Route(CODER_DASHBOARD_BATCHES, '/batches'),
  Route(CODER_DASHBOARD_BATCH_CREATION, '/batch-creation'),
  Route(CODER_DASHBOARD_BATCH_DETAILS, '/batches/:batchId'),
  Redirect(CODER_DASHBOARD_HOME),
])
