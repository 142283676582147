import React from 'react'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form/immutable'
import {
  PhoneNumberField,
  SelectField,
  TextField,
} from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import stateCodes from '~/utils/validation/stateCodes'
import { withStyles } from '@material-ui/core/styles'
import ActionItem from '../ActionItem'

const styles = ({ spacing }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: spacing(2),
  },
  container: {
    display: 'flex',
  },
  searchInput: {
    flex: 1,
    margin: spacing(1),
  },
  searchIcon: {
    marginTop: spacing(1),
  },
})

class PharmacySearchForm extends React.Component {
  handleKeyUp = e => {
    if (e.key === 'Enter') {
      this.props.submit()
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.form} onKeyUp={this.handleKeyUp}>
        <div className={classes.container}>
          <ActionItem
            toolTip="Search For Pharmacy"
            icon="search"
            onClick={this.props.submit}
            baseClassName={classes.searchIcon}
          />
          <Field
            className={classes.searchInput}
            component={TextField}
            label="Name"
            name="name"
          />
        </div>
        <div className={classes.container}>
          <Field
            className={classes.searchInput}
            component={TextField}
            label="Address"
            name="address1"
          />
          <Field
            className={classes.searchInput}
            component={PhoneNumberField}
            label="Phone Number"
            name="phone"
          />
        </div>
        <div className={classes.container}>
          <Field
            className={classes.searchInput}
            component={TextField}
            label="City"
            name="city"
          />
          <Field
            className={classes.searchInput}
            component={SelectField}
            label="State"
            name="state"
            native
          >
            <option />
            {stateCodes.map(state => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Field>
          <Field
            className={classes.searchInput}
            component={TextField}
            label="Zip Code"
            name="zip"
          />
        </div>
      </div>
    )
  }
}

PharmacySearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,

  /* eslint-disable react/no-unused-prop-types */
  onSubmit: PropTypes.func.isRequired,
}

export default compose(
  reduxForm({ form: 'medications.pharmacySearch' }),
  withStyles(styles)
)(PharmacySearchForm)
