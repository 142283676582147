import { combineEpics, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { filter, ignoreElements, mergeMap, pluck, tap } from 'rxjs/operators'
import {
  LOCAL_ENCOUNTER_CHECK_IN,
  LOCAL_ENCOUNTER_CHECK_OUT,
  hasItem,
  removeItem,
} from '~/utils/storage'
import { checkIntoEncounter, checkOutOfEncounter } from '../data/common/shared'

const getCheckInId = encounterId => `${LOCAL_ENCOUNTER_CHECK_IN}-${encounterId}`

const getCheckOutId = encounterId =>
  `${LOCAL_ENCOUNTER_CHECK_OUT}-${encounterId}`

const encounterCheckInEpic = action$ =>
  action$.pipe(
    ofType(checkIntoEncounter.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'encounterId'),
    mergeMap(encounterId =>
      from(hasItem(getCheckInId(encounterId))).pipe(
        filter(Boolean),
        tap(() => removeItem(getCheckInId(encounterId))),
        ignoreElements()
      )
    )
  )

const encounterCheckOutEpic = action$ =>
  action$.pipe(
    ofType(checkOutOfEncounter.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'encounterId'),
    mergeMap(encounterId =>
      from(hasItem(getCheckOutId(encounterId))).pipe(
        filter(Boolean),
        tap(() => removeItem(getCheckOutId(encounterId))),
        ignoreElements()
      )
    )
  )

export default combineEpics(encounterCheckInEpic, encounterCheckOutEpic)
