import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchGroups, getGroupsArr } from '../../data/groups'

const columns = [
  { name: 'name', title: 'Group Name' },
  { name: 'phone', title: 'Phone' },
  { name: 'street', title: 'Street' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'postalCode', title: 'Postal Code' },
]
const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

const GroupLookup = ({
  includeWrapper,
  rows,
  fetchGroups,
  onSelect,
  rowActions,
  rowActionsCount,
}) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search Groups"
    columns={columns}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchGroups}
    onSelect={onSelect}
    rowActions={rowActions}
    rowActionsCount={rowActionsCount}
  />
)

GroupLookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
}

export default connect(
  state => ({
    rows: getGroupsArr(state),
  }),
  { fetchGroups: fetchGroups.requested }
)(GroupLookup)
