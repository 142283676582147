import { Map, Set } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getCalendar } from './root'

const CLAIMS = 'claims'

const transformUserClaims = data =>
  Object.keys(data).reduce(
    (claims, domain) => claims.set(domain, Set(data[domain])),
    Map()
  )

export const fetchUserClaims = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_USER_CLAIMS',
  operation: () => AspireAPI.get('v1/user_claims'),
  transform: transformUserClaims,
  messages: {
    failed: 'There was an issue fetching user claims',
  },
})

const creator = scopedCreator(rootKey)
export const claimsRequested = creator('CLAIMS_REQUESTED', ['userId'])

export default createReducer(CLAIMS, Map(), {
  [fetchUserClaims.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getClaims = pipe(getCalendar, get(CLAIMS))
