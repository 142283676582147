import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  SUBMIT_ORDER_ACTION_REQUESTED,
  submitOrderAction,
  submitOrderActionSuccessful,
  submitOrderUpdate,
} from '../data/selectedOrder'

export default action$ =>
  action$.pipe(
    ofType(SUBMIT_ORDER_ACTION_REQUESTED),
    pluck('payload'),
    mergeMap(({ actionType, userId, notes, orderId, cancelReason, id }) =>
      loading(
        submitOrderAction(actionType, orderId, id)
          .then(
            submitOrderUpdate(actionType, userId, notes, orderId, cancelReason)
          )
          .then(submitOrderActionSuccessful),
        'Problem executing order action'
      )
    )
  )
