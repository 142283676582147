import { List, Map } from 'immutable'

const getDropDownValues = values =>
  List(
    Object.values(values).map(({ value, label }) =>
      Map({
        id: value,
        text: label,
      })
    )
  )

const encounterTypes = {
  CHAPLAIN: { value: 'Chaplain Visit', label: 'Chaplain Encounter' },
  NP: { value: 'Encounter', label: 'APP Encounter' },
  RN: { value: 'RN Encounter', label: 'RN Encounter' },
  SW: { value: 'Social Worker Visit', label: 'Social Worker Encounter' },
}

const visitTypes = {
  INITIAL: { value: 'Initial Visit', label: 'Initial Visit' },
  FOLLOW_UP: { value: 'Follow Up Visit', label: 'Follow Up Visit' },
}

const assessmentTypes = {
  IN_PERSON: { value: 'in_person', label: 'In Person' },
  REMOTE: { value: 'remote', label: 'Remote' },
}

encounterTypes.DROP_DOWN_VALUES = getDropDownValues(encounterTypes)
visitTypes.DROP_DOWN_VALUES = getDropDownValues(visitTypes)
assessmentTypes.DROP_DOWN_VALUES = getDropDownValues(assessmentTypes)

export { encounterTypes, visitTypes, assessmentTypes }
