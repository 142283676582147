import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'

// Key
const CARE_TEAMS = 'careTeams'

// Record
export const CareTeamMember = Record({
  patientId: null,
  role: null,
  roleName: null,
  userId: null,
  userName: null,
})

// Request
export const fetchCareTeam = Request({
  typePrefix: CARE_TEAMS,
  typeBase: 'FETCH_CARE_TEAM',
  requestParams: ['patientId'],
  operation: (patientId, cancelToken) =>
    AspireAPI.get(`v1/patients/${patientId}/care_team`, { cancelToken }),
  transform: into(CareTeamMember, 'role'),
  messages: { failed: 'Failed to get care team for patient.' },
})

// Reducer
export default createReducer(CARE_TEAMS, Map(), {
  [fetchCareTeam.SUCCEEDED]: (state, action) =>
    state.set(action.meta.request.payload.patientId, action.payload),
})

// Selectors
export const getCareTeams = get(CARE_TEAMS)

export const getPatientCareTeam = (state, patientId) =>
  getCareTeams(state).get(patientId)
