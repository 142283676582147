import { Map } from 'immutable'
import React, { useState } from 'react'
import { useUpdateEffect } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DropDownField from '../../../EventFields/DropDownField'
import NumberField from '../../../EventFields/NumberField'
import TextLongField from '../../../EventFields/TextLongField'
import conditions from './conditions'
import values from './values'

const fields = {
  cancellationType: {
    isActive: conditions.alwaysTrue,
    isRequired: conditions.alwaysTrue,
  },
  cancelledBy: {
    isActive: conditions.anyType,
    isRequired: conditions.anyType,
  },
  cancellationReason: {
    isActive: conditions.anyDriven,
    isRequired: conditions.anyDriven,
  },
  otherCancellationReason: {
    isActive: conditions.otherCancellationReason,
    isRequired: conditions.otherCancellationReason,
  },
  refusedServiceReason: {
    isActive: conditions.anyRefusal,
    isRequired: conditions.anyRefusal,
  },
  otherRefusedServiceReason: {
    isActive: conditions.otherRefusedServiceReason,
    isRequired: conditions.otherRefusedServiceReason,
  },
  mitigationStrategy: {
    isActive: conditions.mitigationUponRefusal,
    isRequired: conditions.mitigationUponRefusal,
  },
  cancellationNotes: {
    isActive: conditions.alwaysTrue,
    isRequired: conditions.alwaysTrue,
  },
  mileage: {
    isActive: conditions.unsuccessfulVisit,
    isRequired: conditions.alwaysFalse,
  },
  parkingFees: {
    isActive: conditions.unsuccessfulVisit,
    isRequired: conditions.alwaysFalse,
  },
  tollFees: {
    isActive: conditions.unsuccessfulVisit,
    isRequired: conditions.alwaysFalse,
  },
  unsuccessfulVisit: {
    isActive: conditions.alwaysTrue,
    isRequired: conditions.alwaysTrue,
  },
}

const clearInactiveField = (state, key, setValue) => {
  const fieldValues = values[key] || Map()
  const fieldActive = fields[key].isActive
  const valueActive = fieldValues.getIn([state[key], 'isActive'], () => true)

  if (!(fieldActive(state) && valueActive(state)) && state[key]) {
    setValue(null)
  }
}

const CancellationDialog = ({ open, onClose, onExited, ...props }) => {
  const [cancellationType, setCancellationType] = useState(null)
  const [cancelledBy, setCancelledBy] = useState(null)
  const [cancellationReason, setCancellationReason] = useState(null)
  const [otherCancellationReason, setOtherCancellationReason] = useState(null)
  const [refusedServiceReason, setRefusedServiceReason] = useState(null)
  const [otherRefusedServiceReason, setOtherRefusedServiceReason] = useState(
    null
  )
  const [cancellationNotes, setCancellationNotes] = useState(null)
  const [mitigationStrategy, setMitigationStrategy] = useState(null)
  const [mileage, setMileage] = useState(null)
  const [parkingFees, setParkingFees] = useState(null)
  const [tollFees, setTollFees] = useState(null)
  const [unsuccessfulVisit, setUnsuccessfulVisit] = useState(null)

  const state = {
    cancellationType,
    cancelledBy,
    cancellationReason,
    otherCancellationReason,
    refusedServiceReason,
    otherRefusedServiceReason,
    cancellationNotes,
    mitigationStrategy,
    mileage,
    parkingFees,
    tollFees,
    unsuccessfulVisit,
  }

  const fieldValid = key =>
    !(
      fields[key].isRequired(state) &&
      (state[key] === null || state[key] === '')
    )

  const fieldError = key => !fieldValid(key)

  const filterFieldValues = values =>
    values.filter(value => value.get('isActive')(state))

  const formValid = Object.keys(fields).reduce(
    (valid, key) => valid && fieldValid(key),
    true
  )

  const onCancelVisit = () => {
    props.onCancelVisit(state)
    onClose()
  }

  const onCancellationTypeChange = value => {
    setCancellationType(value)

    const unsuccessfulVisit = conditions.unsuccessfulVisit({
      ...state,
      cancellationType: value,
    })

    if (unsuccessfulVisit) {
      const cancelledByValue = values.cancelledBy.getIn([
        'Patient Driven',
        'text',
      ])

      setCancelledBy(cancelledByValue)
    }

    setUnsuccessfulVisit(unsuccessfulVisit)
  }

  useUpdateEffect(() => {
    clearInactiveField(state, 'cancellationType', setCancellationType)
    clearInactiveField(state, 'cancelledBy', setCancelledBy)
    clearInactiveField(state, 'cancellationReason', setCancellationReason)
    clearInactiveField(
      state,
      'otherCancellationReason',
      setOtherCancellationReason
    )
    clearInactiveField(state, 'refusedServiceReason', setRefusedServiceReason)
    clearInactiveField(
      state,
      'otherRefusedServiceReason',
      setOtherRefusedServiceReason
    )
    clearInactiveField(state, 'cancellationNotes', setCancellationNotes)
    clearInactiveField(state, 'mitigationStrategy', setMitigationStrategy)
    clearInactiveField(state, 'mileage', setMileage)
    clearInactiveField(state, 'parkingFees', setParkingFees)
    clearInactiveField(state, 'tollFees', setTollFees)
    clearInactiveField(state, 'unsuccessfulVisit', setUnsuccessfulVisit)
  })

  return (
    <Dialog open={open} onExited={onExited} maxWidth="md" fullWidth>
      <DialogTitle>Cancel Visit</DialogTitle>

      <DialogContent>
        {fields.cancellationType.isActive(state) && (
          <DropDownField
            label="Cancellation Type"
            error={fieldError('cancellationType')}
            value={cancellationType}
            values={filterFieldValues(values.cancellationType)}
            editing
            onChange={onCancellationTypeChange}
          />
        )}

        {fields.cancelledBy.isActive(state) && (
          <DropDownField
            label="Cancelled By"
            error={fieldError('cancelledBy')}
            value={cancelledBy}
            values={filterFieldValues(values.cancelledBy)}
            editing
            onChange={value => {
              setCancelledBy(value)
            }}
          />
        )}

        {fields.cancellationReason.isActive(state) && (
          <DropDownField
            label="Cancellation Reason"
            error={fieldError('cancellationReason')}
            value={cancellationReason}
            values={filterFieldValues(values.cancellationReason)}
            editing
            onChange={value => {
              setCancellationReason(value)
            }}
          />
        )}

        {fields.otherCancellationReason.isActive(state) && (
          <TextLongField
            label="Other Cancellation Reason"
            error={fieldError('otherCancellationReason')}
            value={otherCancellationReason}
            editing
            onChange={value => {
              setOtherCancellationReason(value)
            }}
          />
        )}

        {fields.refusedServiceReason.isActive(state) && (
          <DropDownField
            label="Refused Service Reason"
            error={fieldError('refusedServiceReason')}
            value={refusedServiceReason}
            values={filterFieldValues(values.refusedServiceReason)}
            editing
            onChange={value => {
              setRefusedServiceReason(value)
            }}
          />
        )}

        {fields.otherRefusedServiceReason.isActive(state) && (
          <TextLongField
            label="Other Refused Service Reason"
            error={fieldError('otherRefusedServiceReason')}
            value={otherRefusedServiceReason}
            editing
            inputProps={{ maxLength: 150 }}
            onChange={value => {
              setOtherRefusedServiceReason(value)
            }}
          />
        )}

        {fields.cancellationNotes.isActive(state) && (
          <TextLongField
            label="Cancellation Notes"
            error={fieldError('cancellationNotes')}
            value={cancellationNotes}
            editing
            onChange={value => {
              setCancellationNotes(value)
            }}
          />
        )}

        {fields.mitigationStrategy.isActive(state) && (
          <TextLongField
            label="Mitigation Strategy"
            error={fieldError('mitigationStrategy')}
            value={mitigationStrategy}
            editing
            inputProps={{ maxLength: 500 }}
            onChange={value => {
              setMitigationStrategy(value)
            }}
          />
        )}

        {fields.mileage.isActive(state) && (
          <NumberField
            label="Mileage"
            error={fieldError('mileage')}
            after="miles"
            min={0}
            value={mileage}
            editing
            onChange={value => {
              setMileage(value)
            }}
          />
        )}

        {fields.parkingFees.isActive(state) && (
          <NumberField
            label="Parking Fees"
            error={fieldError('parkingFees')}
            before="$"
            min={0}
            value={parkingFees}
            editing
            onChange={value => {
              setParkingFees(value)
            }}
          />
        )}

        {fields.tollFees.isActive(state) && (
          <NumberField
            label="Toll Fees"
            error={fieldError('tollFees')}
            before="$"
            min={0}
            value={tollFees}
            editing
            onChange={value => {
              setTollFees(value)
            }}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <Button disabled={!formValid} color="primary" onClick={onCancelVisit}>
          Cancel Visit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CancellationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancelVisit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
}

export default CancellationDialog
