import React from 'react'
import PropTypes from '~/utils/propTypes'
import useCurrentDayEventsByType from '../../../hooks/useCurrentDayEventsByType'
import CalendarEvent from '../../Events/CalendarEvent'

const SchedulingGuidanceCalendarEvent = ({ event, expanded, ...props }) => {
  const [currentAvailability] = useCurrentDayEventsByType(event, 'availability')
  const maxVisits = currentAvailability ? currentAvailability.maxVisits : 0

  return (
    <CalendarEvent
      {...props}
      event={event}
      expanded={expanded}
      maxVisits={maxVisits}
    />
  )
}

SchedulingGuidanceCalendarEvent.propTypes = {
  event: PropTypes.record.isRequired,
  expanded: PropTypes.bool,
}

export default SchedulingGuidanceCalendarEvent
