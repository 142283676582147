import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette, spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: palette.contentText.primaryLabel,
    marginRight: spacing(1),
  },
})

const SecondaryText = ({
  classes,
  event: { address, postalcode, stateCode },
}) => {
  const fullAddress = `${address} ${stateCode} ${postalcode}`

  return (
    <div className={classes.container}>
      <Typography
        className={classes.label}
        variant="body2"
        color="textSecondary"
      >
        Address:
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {fullAddress}
      </Typography>
    </div>
  )
}

SecondaryText.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.record.isRequired,
}

export default withStyles(styles)(SecondaryText)
