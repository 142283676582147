import { colors as muiColors } from '@material-ui/core'

export const colors = {
  text: 'white',
  background: muiColors.red['A700'],
}
export const label = 'On Call'

export const layer = 1

export const title = event => {
  const eventType = event.eventType || ''

  return eventType ? eventType : label
}

export default { colors, label, layer, title }
