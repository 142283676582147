import { ofType } from 'redux-observable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
import { getTaskById, taskCollapsed, taskUpdated } from '../data/tasks'

export default (action$, state$) =>
  action$.pipe(
    ofType(taskCollapsed),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    map(([id, state]) => getTaskById(id)(state)),
    filter(Boolean),
    map(taskUpdated)
  )
