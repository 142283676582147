import PropTypes from 'prop-types'
import React from 'react'
import Form from 'react-jsonschema-form'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrayFieldTemplate from './ArrayFieldTemplate'
import FieldTemplate from './FieldTemplate'
import ObjectFieldTemplate from './ObjectFieldTemplate'
import * as customFormats from './customFormats'
import * as baseFields from './fields'
import idToProperty from './utils/idToProperty'
import transformErrors from './utils/transformErrors'
import * as baseWidgets from './widgets'

const styles = ({ spacing }) => ({
  form: {
    marginTop: -spacing(1),
  },
  error: {
    textAlign: 'center',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: ({ children }) => (children ? spacing(2) : 0),
  },
})

class JsonForm extends React.Component {
  formErrored = false
  touched = new Set()
  errors = []
  state = {
    renderErrored: false,
  }

  // Track whether there is a pending onChange event, and queue onBlur and
  // onFocus events until after it is executed. This is here mainly to handle
  // the case of <select> elements on iOS, where the field is blurred
  // immediately after changing.
  pending = {
    change: false,
    queue: [],
  }

  componentDidCatch(error) {
    console.error(error)
    this.setState({ renderErrored: true })
  }

  getErrors = () => {
    return transformErrors(this.errors, this.touched, this.formErrored)
  }

  deleteTouched = id => {
    this.touched.delete(idToProperty(id))
  }

  onChange = event => {
    this.props.onChange(event)
    this.pending.change = false

    if (this.pending.queue.length > 0) {
      this.pending.queue.forEach(operation => operation())
      this.pending.queue = []
    }
  }

  onSubmit = (...args) => {
    if (this.errors.length > 0) {
      this.onError()
    } else if (this.props.onSubmit) {
      this.props.onSubmit(...args)
    }
  }

  onError = () => {
    if (!this.formErrored) {
      this.formErrored = true
      this.setState()
    }

    if (this.props.onError) {
      this.props.onError(this.getErrors())
    }
  }

  onBlur = (id, value) => {
    const property = idToProperty(id)

    if (!this.touched.has(property)) {
      this.touched.add(property)
      this.setState()
    }

    if (this.props.onBlur) {
      this.props.onBlur(id, value, this.getErrors())
    }
  }

  onFocus = (id, value) => {
    if (this.props.onFocus) {
      this.props.onFocus(id, value, this.getErrors())
    }
  }

  transformErrors = errors => {
    this.errors = errors
    return this.getErrors()
  }

  render() {
    const { classes, children, fields, widgets, formRef, ...props } = this.props
    const { renderErrored } = this.state

    if (renderErrored) {
      return (
        <Typography className={classes.error} color="error" variant="subtitle1">
          There was a problem rendering the form.
        </Typography>
      )
    }

    const formContext = {
      compact: this.props.compact,
      debounce: this.props.debounce,
      context: this.props.context,
      deleteTouched: this.deleteTouched,
      getFormDataByTag: this.props.getFormDataByTag,
      onChangeByTag: this.props.onChangeByTag,
      pending: this.pending,
    }

    return (
      <Form
        {...props}
        noHtml5Validate
        liveValidate
        showErrorList={false}
        ref={formRef}
        className={classes.form}
        transformErrors={this.transformErrors}
        fields={{ ...baseFields, ...fields }}
        widgets={{ ...baseWidgets, ...widgets }}
        FieldTemplate={FieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate}
        ArrayFieldTemplate={ArrayFieldTemplate}
        formContext={formContext}
        customFormats={customFormats}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onError={this.onError}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
      >
        <div className={classes.buttons}>{children}</div>
      </Form>
    )
  }
}

JsonForm.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  fields: PropTypes.object,
  widgets: PropTypes.object,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  debounce: PropTypes.bool,
  context: PropTypes.any,
  formRef: PropTypes.any,
  getFormDataByTag: PropTypes.func,
  onChangeByTag: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

JsonForm.defaultProps = {
  compact: false,
  debounce: false,
  fields: {},
  widgets: {},
  getFormDataByTag: () => {},
  onChange: () => {},
  onChangeByTag: () => {},
}

export default withStyles(styles)(JsonForm)
