import { APPOption, Action as APPOptionA } from './appOptionsT'
import { Request } from '~/utils/Requestable'
import Aspire from '~/resources/aspire-strict'
import * as Toast from '~/utils/toast'
import { Action as ReduxA, State as ReduxS } from '~/reducerT'
import * as t from 'io-ts'
import * as O from 'fp-ts/lib/Option'
import { flow } from 'fp-ts/lib/function'
import { payloadGet } from '~/utils/type'
import * as DE from '@nll/datum/DatumEither'

export const fetchAppOptions = (user_id: string) =>
  Request<APPOption[]>(
    Aspire.get(
      `referral_scheduling/app_options`,
      t.array(APPOption.strictType).asDecoder(),
      { params: { user_id } }
    ),
    payload =>
      ReduxA.of.APPOption({
        payload,
      }),
    {
      toast: Toast.error(_ =>
        O.some('There was an issue fetching secondary provider options.')
      ),
    }
  )

export const init = {
  index: DE.initial,
}

export const reducer: (_: APPOptionA) => (_: ReduxS) => ReduxS = flow(
  payloadGet,
  ReduxS.lensFromPath(['appOption', 'index']).set
)
