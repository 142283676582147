import React from 'react'
import { connect } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { SNF, deleteSNF } from '../data/snfs'

class DeleteSNF extends React.PureComponent {
  onDelete = () => {
    const { row, deleteSNF, onClose } = this.props

    deleteSNF(row.id)
    onClose()
  }

  render() {
    const { open, row, onClose } = this.props
    const message = `Are you sure you want to delete ${row.facilityName} as a SNF admission?`

    return (
      <ActionDialog
        open={open}
        title="Delete SNF Admission"
        mode="delete"
        onDelete={this.onDelete}
        onClose={onClose}
      >
        <Typography>{message}</Typography>
      </ActionDialog>
    )
  }
}

DeleteSNF.propTypes = {
  open: PropTypes.bool.isRequired,
  row: PropTypes.record,
  deleteSNF: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DeleteSNF.defaultProps = {
  row: SNF(),
}

export default connect(null, {
  deleteSNF: deleteSNF.requested,
})(DeleteSNF)
