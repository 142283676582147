import { ofType } from 'redux-observable'
import { concat, iif, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import { fetchFailedFaxes } from '~/features/faxes'
import loading from '~/utils/loading'
import {
  ORDER_DETAIL_SAVE_ACTION_REQUESTED,
  applyAction,
  applyActionFailed,
  applyActionSucceeded,
  getOrderDetailNotes,
  noteChanged,
} from '../data/orderDetail'
import showSuccess from '../utils/showSuccess'

const combinedNote = (actionNote, internalNote) => {
  const note = actionNote ? ` • ${actionNote}` : ''
  const combinedNote = `${internalNote}${note}`

  return combinedNote
}

export default (action$, state$) =>
  action$.pipe(
    ofType(ORDER_DETAIL_SAVE_ACTION_REQUESTED),
    pluck('payload'),
    mergeMap(({ id, action, cancelReason, actionNote }) =>
      loading(
        applyAction(id, action.key, cancelReason, actionNote).pipe(
          mergeMap(res => {
            const internalNote = getOrderDetailNotes(
              state$.value,
              res.id,
              res.note
            )
            const notes = combinedNote(actionNote, internalNote)

            return concat(
              of(applyActionSucceeded(res)),
              iif(
                () => action.key === 'resubmit_fax',
                of(fetchFailedFaxes.requested())
              ),
              of(noteChanged(res.id, notes)),
              showSuccess(
                `${action.historyDescription}. Please refresh the table to view updates.`
              )
            )
          }),
          catchError(err => of(applyActionFailed(err)))
        ),
        `Could not ${action.label} order. Please try again.`
      )
    )
  )
