import { OrderedMap, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request, { flattenErrors } from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
const key = 'patientDischarges'
export const getPatientDischarges = pipe(getRoot, get(key))
const createInfoArraySelector = (type: any) =>
  createSelector(getPatientDischarges, dischargeInfo =>
    (dischargeInfo as any)[type].valueSeq().toArray()
  )
export const getPatientDischargeInfoArray = createInfoArraySelector(
  'discharges'
)
export const getPatientRecommendationInfoArray = createInfoArraySelector(
  'recommendations'
)
export const PatientDischarge = Record({
  discharges: OrderedMap(),
  recommendations: OrderedMap(),
})
export const getDischargeRecById = (state: any, id: any) => {
  const discharges = getPatientDischarges(state)
  return id ? discharges.get('recommendations').get(id) : null
}
export const getDischargeById = (state: any, id: any) => {
  const discharges = getPatientDischarges(state)
  return id ? discharges.get('discharges').get(id) : null
}
export const Discharge = Record({
  addToHealthPlanDoNotCallList: null,
  addToHealthplanDoNotCall: null,
  caseNumber: null,
  createdBy: null,
  createdByAspire: null,
  createdByUser: null,
  createddate: null,
  dateOfDeath: null,
  dischargeDate: null,
  dischargeNotes: null,
  dischargeReason: null,
  dischargeRecommendationNotes: null,
  hospice: null,
  hospiceId: null,
  id: null,
  lastmodifieddate: null,
  modifiedBy: null,
  modifiedByAspire: null,
  modifiedByUser: null,
  lastModifiedByUserAspire: null,
  otherDischargeReason: null,
  ownerId: null,
  patientId: null,
  potentialToReengage: null,
  reasonForChoosingHospice: null,
  recordTypeId: null,
  reinstatementDate: null,
  reinstatementReasonLabel: null,
  reinstatementNotes: null,
  status: null,
  statusDuringDischarge: null,
})
export const Recommendation = Record({
  caseNumber: null,
  dateOfDeath: null,
  dischargeDecision: null,
  dischargeDecisionDate: null,
  dischargeDecisionNotes: null,
  dischargeNotes: null,
  dischargeReason: null,
  id: null,
  lastModifiedBy: null,
  otherDischargeReason: null,
  recommendationDate: null,
  recommendedBy: null,
  status: null,
  mayReengage: null,
  patientId: null,
})
const transformRecommendations = (data: any) =>
  into(Recommendation, 'id', OrderedMap)(data)
const transformData = (data: any) => ({
  discharges: into(Discharge, 'id', OrderedMap)(data.discharges),
  recommendations: transformRecommendations(data.recommendations),
})
const createDischarges = pipe(transformData, PatientDischarge)
export const fetchPatientDischarges = Request({
  typePrefix: key,
  typeBase: 'FETCH_DISCHARGES',
  requestParams: ['patientId'],
  operation: (patientId: any) =>
    AspireAPI.get(`v1/patient/${patientId}/discharges`),
  transform: createDischarges,
  messages: {
    failed: "There was a problem fetching the patient's discharge history",
  },
})
export const saveDischarge = Request({
  typePrefix: key,
  typeBase: 'SAVE_DISCHARGE',
  requestParams: ['params'],
  operation: ({
    dischargeNotes,
    dischargeReason,
    dischargeDate,
    dateOfDeath,
    hospiceId,
    hospiceReason,
    addToHealthPlanDoNotCallList,
    potentialToReengage,
    patientId,
  }: any) =>
    AspireAPI.post(`v1/patient/${patientId}/discharge`, {
      dischargeNotes,
      dischargeReason,
      dischargeDate,
      dateOfDeath,
      hospiceId,
      reasonForChoosingHospice: hospiceReason,
      potentialToReengage,
      addToHealthPlanDoNotCallList: addToHealthPlanDoNotCallList === 'Yes',
      otherDischargeReason: '',
      status: 'Closed',
    }),
  messages: {
    succeeded: 'New discharge successfully saved',
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'Could not save discharge case',
  },
})
export const updateDischarge = Request({
  typePrefix: key,
  typeBase: 'UPDATE_DISCHARGE',
  requestParams: ['params'],
  operation: ({
    dischargeNotes,
    dischargeReason,
    dischargeDate,
    dateOfDeath,
    hospiceId,
    hospiceReason,
    addToHealthPlanDoNotCallList,
    patientId,
    potentialToReengage,
    caseId,
  }: any) =>
    AspireAPI.put(`v1/discharge/${caseId}`, {
      dischargeNotes,
      dischargeReason,
      dischargeDate,
      dateOfDeath,
      hospiceId,
      reasonForChoosingHospice: hospiceReason,
      patientId,
      potentialToReengage,
      addToHealthPlanDoNotCallList: addToHealthPlanDoNotCallList === 'Yes',
      otherDischargeReason: '', // needed for legacy endpoint.
    }),
  messages: {
    succeeded: 'Discharge successfully saved',
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'Could not save discharge case',
  },
  transform: Discharge,
})
export const fetchDischargeRecommendationById = Request({
  typePrefix: key,
  typeBase: 'FETCH_DISCHARGE_RECOMMENDATIONBY_ID',
  requestParams: ['case_id'],
  operation: (case_id: any) =>
    AspireAPI.get(`v1/discharge_recommendations/${case_id}`),
  messages: {
    succeeded: 'Discharge Recommendation Loaded',
    failed: 'Could not retreive discharge recommendation',
  },
  transform: Recommendation,
})
export const updateDischargeRecommendation = Request({
  typePrefix: key,
  typeBase: 'EDIT_DISCHARGE_RECOMMENDATION',
  requestParams: [
    'dischargeNotes',
    'dischargeDecision',
    'mayReengage',
    'dischargeDecisionNotes',
    'caseId',
  ],
  operation: (
    dischargeNotes: any,
    dischargeDecision: any,
    mayReengage: any,
    dischargeDecisionNotes: any,
    id: any
  ) =>
    AspireAPI.put(`v1/discharge_recommendation/${id}`, {
      dischargeNotes,
      dischargeDecision,
      mayReengage,
      dischargeDecisionNotes,
      id,
    }),
  messages: {
    succeeded: 'Discharge recommendation successfully updated',
    failed: 'Could not update discharge recommendation',
  },
  transform: Recommendation,
})
// REQUEST
export const saveDischargeRecommendation = Request({
  typePrefix: key,
  typeBase: 'SAVE_DISCHARGE_RECOMMENDATION',
  requestParams: [
    'dischargeNotes',
    'dischargeReason',
    'mayReengage',
    'patientId',
    'recommendationDate',
  ],
  operation: (
    dischargeNotes: any,
    dischargeReason: any,
    mayReengage: any,
    patientId: any,
    recommendationDate: any
  ) =>
    AspireAPI.post(`v1/patient/${patientId}/discharge_recommendation`, {
      notes: dischargeNotes,
      dischargeReason,
      mayReengage,
      recommendationDate,
    }),
  messages: {
    succeeded: 'New discharge recommendation successfully saved',
    failed: 'Could not save discharge recommendation',
  },
})
export const fetchDischargeRecommendations = Request({
  typePrefix: key,
  typeBase: 'FETCH_DISCHARGE_RECOMMENDATIONS',
  requestParams: ['patientId'],
  operation: (patientId: any) =>
    AspireAPI.get(`v1/patient/${patientId}/discharge_recommendations`),
  messages: {
    succeeded: 'New discharge recommendations loaded',
    failed: 'Could not refresh discharge recommendations',
  },
})
export default createReducer(key, PatientDischarge(), {
  [fetchPatientDischarges.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  [fetchDischargeRecommendations.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set('recommendations', transformRecommendations(payload)),
  [fetchDischargeRecommendationById.SUCCEEDED]: (
    state: any,
    { payload }: any
  ) => state.setIn(['recommendations', payload.id], payload),
  [updateDischargeRecommendation.SUCCEEDED]: (state: any, { payload }: any) =>
    state.setIn(['recommendations', payload.id], payload),
  [updateDischarge.SUCCEEDED]: (state: any, { payload }: any) =>
    state.setIn(['discharges', payload.id], payload),
})
