import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME
import { formatDateTimeWithTimeZone } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { TripLock } from './interfaces'

// KEY
const key = 'tripLocks'

export const TripLockRecord = Record({
  tripId: null,
  timeout: null,
  createdBy: null,
  createdAt: null,
  modifiedAt: null,
  modifiedBy: null,
})

// TRANSFORMER
const mapTripLockResults = (results: [TripLock]) =>
  List(
    results?.map(tripLock =>
      TripLockRecord({
        tripId: tripLock?.tripId,
        timeout: tripLock?.timeout,
        createdBy: tripLock?.createdBy,
        createdAt: formatDateTimeWithTimeZone(tripLock?.createdAt),
        modifiedAt: formatDateTimeWithTimeZone(tripLock?.modifiedAt),
        modifiedBy: tripLock?.modifiedBy,
      })
    )
  )

// REQUEST
export const deleteTripLock = Request({
  typePrefix: key,
  typeBase: 'DELETE_TRIP_LOCK',
  requestParams: ['tripId'],
  operation: (tripId: number) =>
    AspireAPI.delete(`transportation/lock_trips/${tripId}`),
  messages: {
    succeeded: `Trip Lock cleared successfully`,
    failed: `Clear trip lock failed`,
  },
})

export const fetchTripLocks = Request({
  typePrefix: key,
  typeBase: 'GET_TRIP_LOCKS',
  operation: () => AspireAPI.get(`transportation/lock_trips`),
  transform: mapTripLockResults,
  messages: {
    failed: 'Fetching TripLocks failed',
  },
})

// REDUCER
export const tripLocks = createReducer(key, List(), {
  [fetchTripLocks.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

// SELECTORS
export const getTripLocks = pipe(getRoot, get(key))

const deleteKey = `delete${key}`
export const tripLocksDelete = createReducer(deleteKey, Boolean, {
  [deleteTripLock.SUCCEEDED]: () => false,
})
