import {
  CAREMORE_AT_HOME,
  COMMERCIAL_PALLIATIVE_CARE,
  HBPC_HYBRID,
  HIGH_RISK_COMPLEX_CARE,
  HOME_BASED_PALLIATIVE_CARE,
  inTopFive,
  TELEPHONIC,
} from '~/utils/programInfo'

export const programEnrolledToNTVDRole = (
  programEnrolled: string
): string[] => {
  const programsWithSameRoles = [
    COMMERCIAL_PALLIATIVE_CARE,
    HBPC_HYBRID,
    HIGH_RISK_COMPLEX_CARE,
    HOME_BASED_PALLIATIVE_CARE,
  ]
  switch (true) {
    case programEnrolled == TELEPHONIC:
      return [
        'app',
        'pcc_cc',
        'pcc_lead',
        'pcc_sw',
        'cd',
        'pcc_rn',
        'pes',
        'hospice_rn',
        'nss',
        'pcc_oa',
        'pes_mgr',
        'md',
      ]
    case programsWithSameRoles.includes(programEnrolled) ||
      inTopFive(programEnrolled):
      return ['app', 'pcc_rn', 'pcc_sw']
    case programEnrolled == CAREMORE_AT_HOME:
      return [
        'app',
        'cd',
        'field_sw',
        'hospice_nss',
        'md',
        'nss',
        'bh_sw',
        'bh_apc',
        'pcc_rn',
        'pcc_sw',
      ]
    default:
      return []
  }
}
