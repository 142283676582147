import { ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import { showError } from '~/utils/alertOnError'
import loading from '~/utils/loading'
import {
  POTENTIAL_APPS_FETCH_REQUESTED,
  fetchPotentialAPPs,
  potentialAPPsFetchFailed,
  potentialAPPsStored,
} from '../data/potentialAPPs'

const handleResponse = (APPs = []) =>
  APPs.length ? of(potentialAPPsStored(APPs)) : of(potentialAPPsFetchFailed())

const handleError = (e = { message: 'Unknown Error' }) =>
  merge(of(potentialAPPsFetchFailed()), showError(e.message)(e))

const fetchAndHandleResponse = payload =>
  from(fetchPotentialAPPs(payload)).pipe(
    mergeMap(handleResponse),
    catchError(handleError)
  )

export default action$ =>
  action$.pipe(
    ofType(POTENTIAL_APPS_FETCH_REQUESTED),
    pluck('payload'),
    mergeMap(payload => loading(fetchAndHandleResponse(payload)))
  )
