import { ofType } from 'redux-observable'
import { filter, mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues, getFieldValues } from '~/data/fieldValues'
import {
  REQUEST_ORDERS_FOR_PATIENT_ID,
  REQUEST_ORDERS_FOR_USER_ID,
} from '../data/loaders'
import { ORDER_DETAIL_FETCH_REQUESTED } from '../data/orderDetail'
import { ORDER_SELECTED } from '../data/selectedOrder'

export default (action$, state$) =>
  action$.pipe(
    ofType(
      REQUEST_ORDERS_FOR_USER_ID,
      REQUEST_ORDERS_FOR_PATIENT_ID,
      ORDER_SELECTED,
      ORDER_DETAIL_FETCH_REQUESTED
    ),
    filter(() =>
      getFieldValues('hospice_order_cancellation_reasons')(
        state$.value
      ).isEmpty()
    ),
    mapTo(
      fetchDistinctFieldValues([
        'hospice_order_cancellation_reasons',
        'order_cancellation_reasons',
      ])
    )
  )
