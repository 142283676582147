import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import SuccessSnackbar from '../components/SuccessSnackbar'
import { getSuccessMessages } from '../data/actionSuccess'

class SuccessContainer extends PureComponent {
  render = () => {
    const { successMessages } = this.props

    return (
      <div>
        <SuccessSnackbar successMessages={successMessages} />
      </div>
    )
  }
}

SuccessContainer.propTypes = {
  successMessages: PropTypes.set.isRequired,
}

const mapStateToProps = state => ({
  successMessages: getSuccessMessages(state),
})

export default connect(mapStateToProps)(SuccessContainer)
