import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import {
  encounterTypeChanged,
  fetchEncounterTypes,
  getCurrentEvent,
} from '../data/events'

export default (action$, state$) =>
  action$.pipe(
    ofType(fetchEncounterTypes.SUCCEEDED),
    pluck('payload'),
    filter(types => {
      const encounterType = getCurrentEvent(state$.value)?.encounterType
      const exists = Boolean(types.find(({ type }) => type === encounterType))

      return !exists
    }),
    map(types =>
      types.isEmpty() ? null : encounterTypeChanged(types.first()?.type)
    )
  )
