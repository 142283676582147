import { Map, Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const PERMISSIONS = 'permissions'
const PERMISSIONS_LOADED = 'permissionsLoaded'

const transformPermissions = (permissions: any) =>
  permissions.reduce((map: any, { object, action }: any) => {
    const perm = map.get(object, Set())
    const newPerm = perm.add(action)

    return map.set(object, newPerm)
  }, Map())

export const fetchPermissions = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PERMISSIONS',
  operation: () => AspireAPI.get('auth/permissions'),
  transform: transformPermissions,
  messages: {
    failed: 'There was an issue fetching permissions',
  },
})

const permissionsReducer = createReducer(PERMISSIONS, Map(), {
  [fetchPermissions.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

const permissionsLoadedReducer = createReducer(PERMISSIONS_LOADED, false, {
  [fetchPermissions.SUCCEEDED]: () => true,
})

export default combineReducers({
  [permissionsReducer.key]: permissionsReducer,
  [permissionsLoadedReducer.key]: permissionsLoadedReducer,
})

export const getPermissions = pipe(getRoot, get(PERMISSIONS))

export type PermissionAction = 'edit' | 'view' | 'create' | 'delete'

export const getHasPermission = (
  state: any,
  object: string,
  action: PermissionAction
): boolean => {
  const permissions = getPermissions(state, Map())
  return checkPermission(permissions, object, action)
}

export const checkPermission = (
  permissions: any,
  object: string,
  action: PermissionAction
): boolean => {
  const permission_string = object.replace(/_other/i, '')
  const actions = permissions.get(permission_string, Set())
  return actions.has(action)
}

export const permissionSelector = (object: string, action: PermissionAction) =>
  createSelector([getPermissions], (permissions: Map<any, any>) =>
    checkPermission(permissions, object, action)
  )

export const can = (action: PermissionAction, object: string) =>
  permissionSelector(object, action)

export const havePermissionsLoaded = pipe(getRoot, get(PERMISSIONS_LOADED))
