import { List, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import patientRecordKey from '../key'
import { getPatientRecord } from './common/shared'
import {
  CaseManager,
  ReferringEntity,
  ReferringPhysician,
} from './patientSummaryRecords'

const key = 'patientReferrals'

export const Referral = Record({
  caseId: null,
  caseManager: null,
  enrollmentDate: null,
  facilitator: null,
  id: null,
  isCurrentCase: null,
  referralDate: null,
  receiptOfReferralDate: null,
  referralListBatchId: null,
  mailingDate: null,
  mailingType: null,
  referralNote: null,
  referringEntity: null,
  referringPhysician: null,
  snoozeDate: null,
  sourceType: null,
  urgent: null,
  timeZone: null,
})

const createReferrals = data =>
  List(
    data.referrals.map(referral =>
      Referral({
        ...referral,
        caseManager: CaseManager({
          id: referral.caseManagerId,
          name: referral.caseManagerName,
        }),
        referringEntity: ReferringEntity({
          id: referral.referringEntityId,
          name: referral.referringEntityName,
        }),
        referringPhysician: ReferringPhysician({
          id: referral.referringPhysicianId,
          name: referral.referringPhysicianName,
          phone: referral.referringPhysicianPhone,
        }),
      })
    )
  )

export const fetchReferralCases = Request({
  typePrefix: patientRecordKey,
  typeBase: 'FETCH_REFERRAL_CASES',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`v1/referral/cases_by_patient_id/${patientId}`),
  transform: createReferrals,
  messages: { failed: 'There was a problem fetching referral cases' },
})

export const updateReferralCase = Request({
  typePrefix: patientRecordKey,
  typeBase: 'UPDATE_REFERRAL_CASE',
  requestParams: ['caseId', 'changes'],
  operation: (caseId, changes) =>
    AspireAPI.patch(`v1/referral/${caseId}`, changes),
  messages: {
    succeeded: 'Referral case successfully updated',
    failed: 'There was a problem updating the referral case',
  },
})

// REDUCER
export default createReducer(key, List(), {
  [fetchReferralCases.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getReferralCases = pipe(getPatientRecord, get(key))

export const getReferralCasesArray = createSelector(getReferralCases, rc =>
  rc.valueSeq().toArray()
)
