import moment from 'moment'
import React, { PureComponent } from 'react'
import { addBusinessDays } from '~/utils/dates'
import PropTypes from '~/utils/propTypes'
import {
  Accordion,
  AccordionSummary,
  Badge,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EventCard from './EventCard'

const styles = ({ spacing }) => ({
  paper: {
    borderBottomRightRadius: 'initial',
    borderBottomLeftRadius: 'initial',
  },
  padding: {
    padding: `0 ${spacing(2)}px`,
  },
})

class Navigator extends PureComponent {
  state = { view: 'today' }

  selectView = view => () => {
    this.setState({ view })
    // updates the calendar view to the next day as well
    const nextBusinessDay = addBusinessDays(moment(), 1)
    const previousBusinessDay = addBusinessDays(moment(), -1)
    const currentDate = moment()
    let newCalendarDate

    if (view === 'today') {
      newCalendarDate = currentDate
    }
    if (view === 'past') {
      newCalendarDate = previousBusinessDay
    }
    if (view === 'upcoming') {
      newCalendarDate = nextBusinessDay
    }

    this.props.changeCalendarDate(newCalendarDate)
  }

  launchMedImports = patientId => () =>
    this.props.mdtSingleSignOn({
      page: 'elighx',
      patientId,
      prescriberId: this.props.dashUserId,
    })

  render() {
    const {
      classes,
      markEventPrepped,
      openPanel,
      pastEvents,
      startAssessment,
      startingAssessment,
      todaysEvents,
      upcomingEvents,
    } = this.props
    const { view } = this.state

    const todaysPrep = todaysEvents.reduce((r, event) => {
      if (
        !event.get('prepComplete') &&
        moment.utc(event.get('start')) > moment()
      ) {
        return r + 1
      }
      return r
    }, 0)

    const upcomingPrep = upcomingEvents.reduce(
      (r, event) => (event.get('prepComplete') ? r : r + 1),
      0
    )

    let noContentMessage
    let events
    switch (view) {
      case 'today':
        noContentMessage = 'No appointments loaded for today.'
        events = todaysEvents
        break
      case 'upcoming':
        noContentMessage = 'No appointments loaded for today.'
        events = upcomingEvents
        break
      case 'past':
        noContentMessage = 'No past events loaded.'
        events = pastEvents
        break
      default:
        events = pastEvents
        noContentMessage = 'No appointments found '
    }

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Tabs
            value={this.state.view}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              value={'past'}
              label={'Past'}
              onClick={this.selectView('past')}
            />
            <Tab
              value={'today'}
              label={
                <Badge
                  className={classes.padding}
                  color="secondary"
                  badgeContent={todaysPrep}
                >
                  Today
                </Badge>
              }
              onClick={this.selectView('today')}
            />

            <Tab
              value={'upcoming'}
              label={
                <Badge
                  className={classes.padding}
                  color="secondary"
                  badgeContent={upcomingPrep}
                >
                  Upcoming
                </Badge>
              }
              onClick={this.selectView('upcoming')}
            />
          </Tabs>
        </Paper>
        {events.size > 0 ? (
          events
            .sort((a, b) => a.get('start').localeCompare(b.get('start')))
            .map(event => {
              return (
                <EventCard
                  key={event.get('id')}
                  event={event.toJS()}
                  startingAssessment={startingAssessment}
                  openPanel={openPanel}
                  markEventPrepped={markEventPrepped}
                  startAssessment={startAssessment}
                  launchMedImports={this.launchMedImports}
                />
              )
            })
        ) : (
          <Accordion disabled expanded={false}>
            <AccordionSummary>
              <Typography>{noContentMessage}</Typography>
            </AccordionSummary>
          </Accordion>
        )}
      </React.Fragment>
    )
  }
}

Navigator.propTypes = {
  changeCalendarDate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dashUserId: PropTypes.string,
  markEventPrepped: PropTypes.func.isRequired,
  mdtSingleSignOn: PropTypes.func.isRequired,
  openPanel: PropTypes.func.isRequired,
  startAssessment: PropTypes.func.isRequired,
  startingAssessment: PropTypes.bool.isRequired,
  todaysEvents: PropTypes.list.isRequired,
  upcomingEvents: PropTypes.list.isRequired,
  pastEvents: PropTypes.list.isRequired,
}

export default withStyles(styles)(Navigator)
