import React from 'react'
import { useSelector } from 'react-redux'
import { PermissionAction, permissionSelector } from '../data/permissions'

type Props = {
  object: string
  action: PermissionAction
  dispatch?: (...args: any[]) => any
  component: (...args: any[]) => any
}

const WithAuthorized = ({
  component: Component,
  // object, action, and dispatch props aren't needed in rendered component
  object,
  action,
  dispatch,
  ...props
}: Props) => (
  <Component
    authorized={useSelector(permissionSelector(object, action))}
    {...props}
  />
)

export default WithAuthorized
