import React, { useCallback, useEffect, useState } from 'react'
import { ActionDialog } from '~/components/dialogs'
import { useAction, useInput } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  createSchedulingAlert,
  deleteSchedulingAlert,
  saveSchedulingAlert,
} from '../../../data/schedulingAlerts'

const InputLabelProps = { shrink: true }
const SelectProps = { native: true }

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    marginBottom: spacing(2),
  },
}))

const SchedulingAlertDialog = ({ alert, onClose }) => {
  const classes = useStyles()

  const createAlert = useAction(createSchedulingAlert.requested)
  const saveAlert = useAction(saveSchedulingAlert.requested)
  const deleteAlert = useAction(deleteSchedulingAlert.requested)

  const [open, setOpen] = useState(false)
  const [description, setDescription] = useInput()
  const [rank, setRank] = useInput()

  const onCreate = useCallback(() => {
    createAlert(alert.patientId, description, rank)
    setOpen(false)
  }, [alert, description, rank, createAlert, setOpen])

  const onSave = useCallback(() => {
    saveAlert(alert.id, description, rank)
    setOpen(false)
  }, [alert, description, rank, saveAlert, setOpen])

  const onDelete = useCallback(() => {
    deleteAlert(alert.patientId, alert.id)
    setOpen(false)
  }, [alert, deleteAlert, setOpen])

  useEffect(() => {
    setDescription(alert.description)
    setRank(alert.rank)
    setOpen(alert.open)
  }, [alert, setDescription, setRank, setOpen])

  return (
    <ActionDialog
      mode={alert.mode}
      open={open}
      title={`${alert.title} Scheduling Alert`}
      onCreate={onCreate}
      onSave={onSave}
      onDelete={onDelete}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      {alert.mode === 'delete' ? (
        <Typography>Are you sure you want to delete this alert?</Typography>
      ) : (
        <React.Fragment>
          <TextField
            className={classes.description}
            label="Description"
            onChange={setDescription}
            value={description}
            fullWidth
          />

          <TextField
            InputLabelProps={InputLabelProps}
            SelectProps={SelectProps}
            label="Rank"
            onChange={setRank}
            value={rank}
            select
            fullWidth
          >
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </TextField>
        </React.Fragment>
      )}
    </ActionDialog>
  )
}

SchedulingAlertDialog.propTypes = {
  alert: PropTypes.record.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SchedulingAlertDialog
