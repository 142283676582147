import { get, includes, sortBy } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import UserSelector from '~/components/UserSelector'
import GenericInfo from '~/components/text/GenericInfoWrapper'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import { getRoles, getTypes } from '../data/careTeams'

const useStyles = makeStyles(({ palette }) => ({
  label: {
    color: palette.contentText.infoLabel,
  },
}))

const CTMemberSelector = ({ onEditValueChange }) => {
  const onUserSelected = (id, record) => {
    onEditValueChange({ id, name: record.name })
  }

  return (
    <React.Fragment>
      <UserSelector onUserSelected={onUserSelected}></UserSelector>
    </React.Fragment>
  )
}

CTMemberSelector.propTypes = {
  onEditValueChange: PropTypes.func,
}

const CTMembers = ({
  careTeamType,
  editMode,
  members,
  onChangeCareTeamMember,
  onDeleteCareTeamMember,
}) => {
  const classes = useStyles()

  const roles = useSelector(getRoles)
  const types = useSelector(getTypes)

  const memberDisplays = sortBy(members, 'role').map(mem => {
    const requestEditCareTeamMember = user =>
      onChangeCareTeamMember(mem.role, user.id, user.name)

    const requestDeleteCareTeamMember = () => onDeleteCareTeamMember(mem.role)

    const requiredRoles = types.getIn([careTeamType, 'requiredRoles'])
    const required = includes(requiredRoles, mem.role)
    const label = roles.getIn([mem.role, 'label']) + (required ? ' *' : '')

    return (
      <React.Fragment key={mem.role}>
        {editMode ? (
          <GenericInfo
            className={classes.label}
            clearable={!required}
            content={get(mem, 'user.name')}
            editDialog={CTMemberSelector}
            editable
            label={label}
            onClear={requestDeleteCareTeamMember}
            onEdit={requestEditCareTeamMember}
          />
        ) : (
          <GenericInfo
            className={classes.label}
            content={get(mem, 'user.name')}
            label={label}
          />
        )}
      </React.Fragment>
    )
  })
  return memberDisplays
}

CTMembers.propTypes = {
  members: PropTypes.array.isRequired,
  onChangeCareTeamMember: PropTypes.func,
  onDeleteCareTeamMember: PropTypes.func,
  careTeamType: PropTypes.string,
  editMode: PropTypes.bool,
}
CTMembers.defaultProps = {
  editMode: false,
}

export default CTMembers
