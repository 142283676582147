import { List, Record } from 'immutable'
import { getRoot } from './common/shared'
import { pipe } from '~/utils/functionalHelpers'
import { get as getIn } from 'lodash'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request, { flattenErrors } from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator, type } from '~/utils/data'
import key from '../key'

export const ENCOUNTER_CODING_QUERIES = 'encounterCodingQueries'

const ENCOUNTER_CODING_QUERIES_CLEARED = type(key, 'CODE_SEARCH_CLEARED')

const creator = scopedCreator(key)
export const encounterCodingQueriesCleared = creator(
  ENCOUNTER_CODING_QUERIES_CLEARED
)

export const EncounterQuery = Record({
  id: null,
  submission: null,
  submittedById: null,
  submittedBy: null,
  submittedAt: null,
  encounterId: null,
  encounter: null,
  response: null,
  respondedById: null,
  respondedBy: null,
  respondedAt: null,
})

export type EncounterCodingQuery = {
  id: number
  submission: string
  submittedById: string
  submittedBy: any
  submittedAt: Date
  encounterId: number
  encounter: any
  response?: string
  respondedById?: string
  respondedBy?: any
  respondedAt?: Date
}

const transformQueries = (queries: EncounterCodingQuery[]) =>
  List(queries.map(EncounterQuery))

export const fetchEncounterQueries = Request({
  typePrefix: key,
  typeBase: 'FETCH_ENCOUNTER_QUERIES',
  requestParams: ['encounterId', 'searchString'],
  operation: (encounterId: number) =>
    AspireAPI.get(`coding_dashboard/queries/${encounterId}`),
  transform: transformQueries,
  messages: {
    failed: 'There was an issue fetching encounter queries',
  },
})

export const fetchEncounterQueriesForWorklist = Request({
  typePrefix: key,
  typeBase: 'FETCH_ENCOUNTER_QUERIES_FOR_WORKLIST',
  requestParams: ['viewerId'],
  operation: (viewerId: string) =>
    AspireAPI.get(`coding_dashboard/queries`, {
      params: { viewingAs: viewerId },
    }),
  transform: transformQueries,
  messages: {
    failed: 'There was an issue fetching encounter queries',
  },
})

export const sendQuery = Request({
  typePrefix: key,
  typeBase: 'SEND_QUERY',
  requestParams: ['encounterId', 'code'],
  operation: (encounterId: number, submission: string) =>
    AspireAPI.post('coding_dashboard/queries', {
      encounterId,
      submission,
    }),
  messages: {
    succeeded: 'Query submitted successfully',
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was an issue sending the query',
  },
})

export const sendQueryResponse = Request({
  typePrefix: key,
  typeBase: 'SEND_QUERY_RESPONSE',
  requestParams: ['response', 'encounterId', 'queryId'],
  operation: (response: string, encounterId: string, queryId: number) =>
    AspireAPI.patch(`coding_dashboard/queries/${queryId}`, {
      response,
      encounterId,
    }),
  messages: {
    failed: 'There was an issue submitting a response',
    succeeded: 'Response submitted successfully',
  },
})

export const getEncounterQueries = pipe(getRoot, get(ENCOUNTER_CODING_QUERIES))

const initState = List()

export default createReducer(ENCOUNTER_CODING_QUERIES, initState, {
  // @ts-expect-error fix me
  [encounterCodingQueriesCleared]: () => initState,
  [fetchEncounterQueries.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [fetchEncounterQueriesForWorklist.SUCCEEDED]: (
    _state: any,
    { payload }: any
  ) => payload,
})
