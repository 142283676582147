import { Map, fromJS } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import stringifyPath from '../utils/stringifyPath'
import { getRoot } from './common/shared'

const key = 'selectedPolygon'

const creator = scopedCreator(rootKey)

export const setSelectedPolygon = creator('POLYGON_SELECTED', ['polygon'])
export const attemptToSaveSelectedPolygon = creator(
  'SELECTED_POLYGON_SAVE_ATTEMPTED',
  ['projectId', 'polygon']
)
export const loadSavedPolygon = creator('POLYGON_SAVED', ['polygon'])
export const updateSelectedPolygon = creator('SELECTED_POLYGON_UPDATED', [
  'field',
  'value',
])

export const assignCareTeamToDraftPoly = creator(
  'CARE_TEAM_ASSIGNED_TO_DRAFT_POLY',
  ['careTeamId']
)

export const attemptToDeleteSelectedPolygon = creator(
  'SELECTED_POLYGON_DELETE_ATTEMPTED',
  ['npId']
)
export const deletedSelectedPolygon = creator('SELECTED_POLYGON_DELETED', [
  'polygon',
])
export const selectedPolygonCleared = creator('SELECTED_POLYGON_CLEARED')

export const selectedPolygonPublished = creator('SELECTED_POLYGON_PUBLISHED', [
  'polygon',
])
export const selectedPolygonPublishComplete = creator(
  'SELECTED_POLYGON_PUBLISH_COMPLETE',
  ['id', 'success']
)

export default createReducer(key, Map(), {
  [setSelectedPolygon]: (state, { payload: { polygon } }) => polygon,
  [loadSavedPolygon]: (state, { payload: { polygon } }) => fromJS(polygon),
  [updateSelectedPolygon]: (state, { payload: { field, value } }) =>
    state.set(field, value),
  [assignCareTeamToDraftPoly]: (state, { payload: { careTeamId } }) =>
    state.set('careTeams', careTeamId),
})

export const getSelectedPolygon = pipe(getRoot, get(key))

export const saveSelectedPolygon = (projectId, polygon) => {
  let body = {}
  // checks to see if this is editing a new polygon or creating a new 'polygon' or polygon
  polygon.has('careTeams')
    ? (body = polygon
        .update('path', null, p => stringifyPath(p))
        .set(
          'care_team_id',
          polygon
            .get('careTeams')
            .map(({ care_team_id }) => parseInt(care_team_id))
        )
        .delete('careTeams'))
    : (body = polygon.update('path', null, p => stringifyPath(p)))

  return AspireAPI.post(
    `polygonator/draft_projects/${projectId}/polygons`,
    body
  )
}

export const deleteSelectedPolygon = id => {
  return AspireAPI.delete(`polygonator/draft_projects/apps/${id}`)
}

export const publishDraftPolygon = polygon => {
  const url = 'polygonator/draft_projects/apps/publish'
  const body = polygon.update('path', null, p => stringifyPath(p))

  return AspireAPI.post(url, body)
}
