import { OrderedMap, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getPatientRecord } from './common/shared'

const key = 'patientAssessments'

export const getPatientAssessments = pipe(getPatientRecord, get(key))

export const getPatientAssessmentsArray = createSelector(
  getPatientAssessments,
  assessments => assessments.valueSeq().toArray()
)

export const PatientAssessment = Record({
  assessmentStatusLabel: null,
  dateOfService: null,
  encounterType: null,
  id: null,
  provider: null,
  providerRole: null,
  modifiedAt: null,
  eventId: null,
})

export const fetchPatientAssessments = Request({
  typePrefix: key,
  typeBase: 'FETCH_ASSESSMENTS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/patient/${patientId}/assessments`),
  transform: assessments =>
    OrderedMap(assessments.map(a => [a.id, PatientAssessment(a)])),
  messages: { failed: 'There was a problem fetching patient assessments' },
})

// REQUESTS
export const startAssessment = Request({
  typePrefix: key,
  typeBase: 'START_ASSESSMENT',
  requestParams: ['eventId'],
  operation: eventId => AspireAPI.post(`encounters`, { eventId }),
  messages: {
    failed: e =>
      e.response.data.message || [
        ' There was a problem starting the assessment.',
      ],
  },
})

export default createReducer(key, OrderedMap(), {
  [fetchPatientAssessments.SUCCEEDED]: (_state, { payload }) => payload,
  [startAssessment.SUCCEEDED]: (state, { payload: { id } }) =>
    state.update(id, encounter =>
      encounter.merge({ assessmentStatusLabel: 'Created' })
    ),
})

export const downloadDocument = Request({
  typePrefix: key,
  typeBase: 'DOWNLOAD_DOCUMENT',
  requestParams: ['encounterId'],
  operation: encounterId =>
    AspireAPI.get(`media/encounters/${encounterId}/pdf`, {
      responseType: 'blob',
    }),
  messages: {
    failed: 'There was a problem downloading the encounter document',
  },
})
