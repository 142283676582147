import { Map, fromJS } from 'immutable'
import OrcAPI from '~/resources/orc'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'polygonPatients'

export const POLYGON_PATIENTS_CLEARED = type(
  rootKey,
  'POLYGON_PATIENTS_CLEARED'
)
export const POLYGON_PATIENTS_FETCHED = type(
  rootKey,
  'POLYGON_PATIENTS_FETCHED'
)

export const polygonPatientsCleared = creator(POLYGON_PATIENTS_CLEARED)
export const loadFetchedPolygonPatients = creator(
  POLYGON_PATIENTS_FETCHED,
  'polygonId',
  'polygonName',
  'patients'
)

const initialState = fromJS({ emr: {}, ods: {} })

export default createReducer(key, initialState, {
  [POLYGON_PATIENTS_CLEARED]: () => initialState,
  [POLYGON_PATIENTS_FETCHED]: (
    state,
    { payload: { patients, polygonId, polygonName } }
  ) => {
    const mappedPatients = patients.map(p => {
      p.polygonName = polygonName
      p.polygonId = polygonId
      return p
    })
    const emr = normalize(
      mappedPatients.filter(p => p.sfid),
      { idKey: 'sfid' }
    )
    const ods = normalize(
      mappedPatients.filter(p => p.Score),
      {
        idKey: 'GlobalMemberID',
      }
    )
    return state
      .updateIn(['emr', polygonId], new Map(), e => e.merge(emr))
      .updateIn(['ods', polygonId], new Map(), o => o.merge(ods))
  },
})

export const getPolygonPatients = pipe(getRoot, get(key))
export const getEMRPolygonPatients = pipe(getPolygonPatients, get('emr'))
export const getODSPolygonPatients = pipe(getPolygonPatients, get('ods'))

export const fetchPolygonPatients = path =>
  OrcAPI.get('polygonator/all_polygon_members', { params: { path } })
