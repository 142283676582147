import React from 'react'
import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core'

type Props = {
  title: string
  disabled?: boolean
  children: React.ReactNode
  placement?: TooltipProps['placement']
}

export default function Tooltip({
  title,
  disabled = false,
  placement = 'left',
  children,
  ...props
}: Props) {
  return disabled ? (
    <>{children}</>
  ) : (
    <MuiTooltip title={title} placement={placement} {...props}>
      <span>{children}</span>
    </MuiTooltip>
  )
}
