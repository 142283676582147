import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import { fetchEncounterCodes } from '../data/encounterCodes'
import {
  fetchEncounterInfo,
  updateEncounterCodingStatus,
} from '../data/encounterInfo'

const codingStatusUpdated = (action$: any, _state$: any) =>
  action$.pipe(
    ofType(updateEncounterCodingStatus.SUCCEEDED),
    pluck('payload', 'id'),
    mergeMap((id: any) =>
      of(fetchEncounterInfo.requested(id), fetchEncounterCodes.requested(id))
    )
  )

export default codingStatusUpdated
