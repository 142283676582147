import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { change, formValueSelector } from 'redux-form'
import { Section } from '~/components/text'
import { LocationSearch } from '~/features/locationSearch'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  location: {
    margin: [spacing(2), 0],
  },
})

const MailingInfo = ({ classes, form, address, changeValue }) => {
  const onChange = useCallback(
    location => {
      changeValue(form, 'address', location)
    },
    [form, changeValue]
  )

  return (
    <Section title="Mailing Information">
      <div className={classes.location}>
        <LocationSearch location={address} onChange={onChange} />
      </div>
    </Section>
  )
}

MailingInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  address: PropTypes.record.isRequired,
  changeValue: PropTypes.func.isRequired,
}

export default compose(
  connect(
    (state, { form }) => {
      const selector = formValueSelector(form)
      return { address: selector(state, 'address') }
    },
    { changeValue: change }
  ),
  withStyles(styles)
)(MailingInfo)
