import { combineEpics } from 'redux-observable'
import caseManagerSelected from './epics/caseManagerSelected'
import deleteCaseManagerSucceeded from './epics/deleteCaseManagerSucceeded'
import deleteGroupSucceeded from './epics/deleteGroupSucceeded'
import deleteHospiceSucceeded from './epics/deleteHospiceSucceeded'
import deleteHospitalSucceeded from './epics/deleteHospitalSucceeded'
import deleteIPASucceeded from './epics/deleteIPASucceeded'
import deletePhysicianSucceeded from './epics/deletePhysicianSucceeded'
import deletePracticeSucceeded from './epics/deletePracticeSucceeded'
import deleteVendorSucceeded from './epics/deleteVendorSucceeded'
import groupSelected from './epics/groupSelected'
import hospiceSelected from './epics/hospiceSelected'
import hospitalSelected from './epics/hospitalSelected'
import ipaSelected from './epics/ipaSelected'
import physicianSelected from './epics/physicianSelected'
import practiceSelected from './epics/practiceSelected'
import vendorSelected from './epics/vendorSelected'

export default combineEpics(
  caseManagerSelected,
  deleteCaseManagerSucceeded,
  deleteGroupSucceeded,
  deleteHospiceSucceeded,
  deleteHospitalSucceeded,
  deleteIPASucceeded,
  deletePhysicianSucceeded,
  deletePracticeSucceeded,
  deleteVendorSucceeded,
  groupSelected,
  hospiceSelected,
  hospitalSelected,
  ipaSelected,
  physicianSelected,
  practiceSelected,
  vendorSelected
)
