import { connect } from 'react-redux'
import { getPatientBrand } from '~/features/patientInfo'
import bindStateToSelectors from '~/utils/bindStateToSelectors'
import Vitals from '../components/Vitals'
import { chartDisplayToggled, chartPageChanged, getChart } from '../data/chart'
import { getPatientVitalsMap } from '../data/vitals'

const mapStateToProps = bindStateToSelectors({
  chart: getChart,
  vitals: getPatientVitalsMap,
  patientBrand: getPatientBrand,
})

const mapDispatchToProps = {
  chartDisplayToggled: chartDisplayToggled,
  chartPageChanged: chartPageChanged,
}

export default connect(mapStateToProps, mapDispatchToProps)(Vitals)
