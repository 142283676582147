import moment from 'moment'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getCalendar } from './root'

const DATE = 'date'

const creator = scopedCreator(rootKey)
export const dateSet = creator('DATE_SET', ['date'])

export default createReducer(DATE, moment().startOf('day'), {
  [dateSet]: (_state, { payload: { date } }) => moment(date),
})

export const getDate = pipe(getCalendar, get(DATE))
