import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const UNMERGE_ODS_ADMISSION_IDS_SET =
  'reconciliation/UNMERGE_ODS_ADMISSION_IDS_SET'
export const UNMERGE_ODS_ADMISSION_IDS_CLEARED =
  'reconciliation/UNMERGE_ODS_ADMISSION_IDS_CLEARED'

// ACTION CREATORS
export const unmergeOdsAdmissionIdsSet = (odsAdmissionIds: any) => ({
  type: UNMERGE_ODS_ADMISSION_IDS_SET,
  odsAdmissionIds,
})
export const unmergeOdsAdmissionIdsCleared = (odsAdmissionIds: any) => ({
  type: UNMERGE_ODS_ADMISSION_IDS_CLEARED,
  odsAdmissionIds,
})

// KEY
export const key = 'unmergeOdsAdmissionIds'

// REDUCERS
export default createReducer(key, Set(), {
  [UNMERGE_ODS_ADMISSION_IDS_SET]: (state: any, { odsAdmissionIds }: any) =>
    state.union(odsAdmissionIds),
  [UNMERGE_ODS_ADMISSION_IDS_CLEARED]: (state: any, { odsAdmissionIds }: any) =>
    state.subtract(odsAdmissionIds),
})

// SELECTORS
export const getAllUnmergeOdsAdmissionIds = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
