import { defaultEnd, defaultStart } from '../defaults'

const availabilityWhatId = '0061500000TdhSAAAZ'

export default {
  type: 'IncentiveDay',
  recordType: 'incentive_day',
  whatId: availabilityWhatId,
  start: defaultStart,
  end: defaultEnd,
}
