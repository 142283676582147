import { List, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getAttachmentsRoot } from './common/shared'

const initialState = Record({
  attachments: List(),
  attachmentTypes: [],
})

export const Attachment = Record({
  id: null,
  patientId: null,
  object: null,
  docCode: null,
  bucket: null,
  link: null,
  displayName: null,
  contentType: null,
  createdBy: null,
  createdByName: null,
  createdDate: null,
  attachmentType: null,
  dateOfService: null,
  source: null,
})

const key = 'attachmentsNextGen/currentAttachments'
const scoped = scopedCreator(key)

const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const transformAttachmentTypes = types => {
  const sortedTypes = types.sort(function (type1, type2) {
    return type1.type.toLowerCase() <= type2.type.toLowerCase() ? -1 : 1
  })
  return sortedTypes.map(type => {
    return {
      id: type.id,
      displayType: toTitleCase(type.type.toLowerCase().replaceAll('_', ' ')),
      type: type.type,
    }
  })
}

export const saveComplete = scoped('SAVE_COMPLETE')

export const fetchNextgenPatientAttachments = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_ATTACHMENTS',
  requestParams: ['patientId', 'docType'],
  operation: (patientId, docType, cancelToken) =>
    AspireAPI.get('/v1/attachments/nextgen_attachments', {
      params: { patient_id: patientId, doc_type: docType || 'NextGen Notes' },
      cancelToken,
    }),
  transform: into(Attachment, 'id'),
  messages: { failed: 'There was a problem fetching Nextgen attachments' },
})

export const fetchNextgenAttachmentsTypes = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_ATTACHMENTS_TYPES',
  requestParams: ['patientId'],
  operation: (patientId, cancelToken) =>
    AspireAPI.get('/v1/attachments/nextgen_attachment_types', {
      params: { patient_id: patientId },
      cancelToken,
    }),
  transform: transformAttachmentTypes,
  messages: {
    failed: 'There was a problem fetching Nextgen attachments types',
  },
})

const set = key => (state, { payload }) => ({ ...state, [key]: payload })

export default createReducer(key, initialState(), {
  [fetchNextgenPatientAttachments.SUCCEEDED]: set('attachments'),
  [fetchNextgenAttachmentsTypes.SUCCEEDED]: set('attachmentTypes'),
  [saveComplete]: (state, { payload }) =>
    state.set(payload.id, Attachment(payload)),
})

export const getAttachmentsBase = pipe(getAttachmentsRoot, get(key))

export const getAttachmentTypes = state =>
  getAttachmentsBase(state).attachmentTypes

export const getAttachments = state => getAttachmentsBase(state).attachments

export const getAttachmentsArray = createSelector(
  [getAttachments],
  attachments => (attachments ? attachments.toIndexedSeq().toJS() : [])
)
