import { merge, of } from 'rxjs'
import { delay } from 'rxjs/operators'
import { actionSuccessful, deleteSuccess } from '../data/actionSuccess'

// Displays error and removes after 5 seconds
const showSuccess = message =>
  merge(
    of(actionSuccessful(message)),
    of(deleteSuccess(message)).pipe(delay(5000))
  )

export default showSuccess
