import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchIPAs, getIPAsArr } from '../../data/ipas'

const columns = [
  { name: 'name', title: 'IPA Name' },
  { name: 'phone', title: 'Phone' },
  { name: 'street', title: 'Street' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'postalCode', title: 'Postal Code' },
]
const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

const IPALookup = ({
  includeWrapper,
  rows,
  fetchIPAs,
  onSelect,
  rowActions,
  rowActionsCount,
}) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search IPAs"
    columns={columns}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchIPAs}
    onSelect={onSelect}
    rowActions={rowActions}
    rowActionsCount={rowActionsCount}
  />
)

IPALookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchIPAs: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
}

export default connect(
  state => ({
    rows: getIPAsArr(state),
  }),
  { fetchIPAs: fetchIPAs.requested }
)(IPALookup)
