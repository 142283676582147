import { List, Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { getCurrentPatientId } from './currentPatientId'

const HEALTHPLAN_ALERTS = 'healthplanAlerts'

const HealthplanAlert = Record({
  patientId: null,
  invoiceDay: null,
  previousVisited: null,
})

const transformHealthplanAlerts = alerts =>
  alerts.reduce(
    (map, alert) =>
      map.update(alert.patientId, List(), patientAlerts =>
        patientAlerts.push(HealthplanAlert(alert))
      ),
    Map()
  )

export const fetchHealthplanAlerts = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_HEALTHPLAN_ALERTS',
  requestParams: ['appId'],
  operation: (appId, cancelToken) =>
    AspireAPI.get(`np_scheduling/healthplan_alerts/${appId}`, { cancelToken }),
  transform: transformHealthplanAlerts,
  messages: {
    failed: 'There was an issue fetching healthplan alerts',
  },
})

const initState = Map()
export default createReducer(HEALTHPLAN_ALERTS, initState, {
  [fetchHealthplanAlerts.REQUESTED]: () => initState,
  [fetchHealthplanAlerts.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getAllHealthplanAlerts = pipe(getRoot, get(HEALTHPLAN_ALERTS))

export const getHealthplanAlerts = state => {
  const patientId = getCurrentPatientId(state)
  return getAllHealthplanAlerts(state).get(patientId, List())
}
