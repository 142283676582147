import cx from 'classnames'
import React from 'react'
// @ts-expect-error ts-migrate(2307) FIXME
import PropTypes from '~/utils/propTypes'
import { AccordionDetails } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import OpenGap from './OpenGap'
import { Gap } from '../data/openGaps'

type Props = {
  gaps: Gap[]
  hiddenDescription: boolean
  hiddenDate: boolean
  classes: any
}

const styles = ({ palette }: any) => ({
  details: {
    display: 'block',
  },
  alertDetails: {
    padding: 0,
    '& > *': {
      backgroundColor: palette.common.white,
      margin: 0,
      display: 'flex',
      padding: [10],
    },
  },
})

const sortByMeasure = (a: Gap, b: Gap) => (a.measure < b.measure ? -1 : 1)

const GapsContent = ({
  gaps,
  hiddenDescription,
  hiddenDate,
  classes,
}: Props) => {
  return (
    <AccordionDetails className={cx(classes.details, classes.alertDetails)}>
      {gaps.sort(sortByMeasure).map((gap: Gap) => (
        <OpenGap
          key={gap.id}
          gap={gap}
          hiddenDescription={hiddenDescription}
          hiddenDate={hiddenDate}
        />
      ))}
    </AccordionDetails>
  )
}

GapsContent.propTypes = {
  gaps: PropTypes.list,
  hiddenDescription: PropTypes.boolean,
  hiddenDate: PropTypes.boolean,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(GapsContent)
