import { changedTo } from 'redux-routable'
import { from, merge, of } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
} from 'rxjs/operators'
import { fetchHighRiskHuddle } from '~/features/highRiskHuddle/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchOnCallsForUserId } from '~/features/onCall'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import { fetchMTM } from '../data/mtm'
import { fetchOpenAssessments } from '../data/openAssessments'
import { fetchPatientProviders, fetchedProviders } from '../data/providers'
import router from '../router'

const fetchAndStoreProviderList = (cdId: any) =>
  loading(
    from(fetchPatientProviders(cdId)).pipe(map(fetchedProviders)),
    'Problem loading provider list.'
  )

const initialLoad = (action$: any) =>
  action$.pipe(
    filter(changedTo(router)),
    pluck('payload', 'params', 'cdId'),
    filter(cdId => cdId !== undefined),
    distinctUntilChanged(),
    mergeMap(userId =>
      merge(
        of(fetchHighRiskHuddle.requested(userId)),
        fetchAndStoreProviderList(userId),
        of(ownerTasksFetchRequested(userId)),
        of(fetchOpenAssessments.requested(userId)),
        of(fetchOnCallsForUserId.requested(userId)),
        of(fetchMTM.requested(userId))
      )
    )
  )

export default initialLoad
