import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import { localToUtc, utcToLocal } from '~/utils/dates'
import {
  DATE_PICKER_DATETIME_FORMAT,
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_TIME_FORMAT,
} from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { getDate, getTimeZone } from '../../data/calendar'
import {
  allDayChanged,
  endChanged,
  endDateChanged,
  startChanged,
  startDateChanged,
} from '../../data/events'
import { eventTypeDefaults } from '../EventTypes/consts'
import CheckboxField from './CheckboxField'
import DateField from './DateField'
import DateTimeField from './DateTimeField'
import TimeField from './TimeField'

const StartEndField = ({ event, editing, showAllDay }) => {
  const date = useSelector(getDate)
  const timeZone = useSelector(getTimeZone)

  const changeAllDay = useAction(allDayChanged)
  const changeStart = useAction(startChanged)
  const changeEnd = useAction(endChanged)

  const changeStartDate = useAction(startDateChanged)
  const changeEndDate = useAction(endDateChanged)

  const { startDate, endDate, recordType } = event

  const start = utcToLocal(event.start, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )
  const end = utcToLocal(event.end, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )

  const [startDatetime, setStartDatetime] = useState(start)
  const [endDatetime, setEndDatetime] = useState(end)

  const validEnd = start && end && moment(end).isAfter(start)
  const validEndDate =
    startDate && endDate && moment(endDate).isSameOrAfter(startDate)
  const validEndTime =
    startDatetime && endDatetime && moment(endDatetime).isAfter(startDatetime)

  const onCallEvent = recordType === 'on_call'

  const changeBothDates = newDate => {
    const date = newDate || event.startDate

    changeStartDate(date)
    changeEndDate(date)
  }

  const isCurrentOrNextYear = date => {
    const thisYear = moment().startOf('year').format(DATE_PICKER_DATE_FORMAT)
    const nextYear = moment()
      .add(1, 'year')
      .endOf('year')
      .format(DATE_PICKER_DATE_FORMAT)

    return moment(date).isBetween(thisYear, nextYear, undefined, '[]')
  }

  const onBlurStart = useCallback(
    value => {
      const newStartTimestamp = localToUtc(value, timeZone)
      const newStartDate = value.format('YYYY-MM-DD')

      setStartDatetime(value.format(DATE_PICKER_DATETIME_FORMAT))

      if (onCallEvent) {
        const startTimestampPlusHour = value.add(60, 'minutes')
        const newEndTimestamp = localToUtc(startTimestampPlusHour, timeZone)

        setEndDatetime(
          startTimestampPlusHour.format(DATE_PICKER_DATETIME_FORMAT)
        )
        changeStart(newStartTimestamp, newStartDate)
        changeEnd(newEndTimestamp, newStartDate)
      } else if (
        moment(start).format('YYYY-MM-DD') ===
          moment(end).format('YYYY-MM-DD') &&
        moment(start).format('YYYY-MM-DD') !== newStartDate
      ) {
        const newEnd = moment(end)
          .date(value.date())
          .month(value.month())
          .year(value.year())

        const newEndTimestamp = localToUtc(newEnd, timeZone)
        setEndDatetime(newEnd.format(DATE_PICKER_DATETIME_FORMAT))
        changeEnd(newEndTimestamp, newStartDate)
        return changeStart(newStartTimestamp, newStartDate)
      } else {
        return changeStart(newStartTimestamp, newStartDate)
      }
    },
    [timeZone, changeStart, start, end]
  )

  const onBlurEnd = useCallback(
    value => {
      const end = localToUtc(value, timeZone)
      const endDate = value.format('YYYY-MM-DD')

      setEndDatetime(value.format(DATE_PICKER_DATETIME_FORMAT))

      return changeEnd(end, endDate)
    },
    [timeZone, changeEnd]
  )

  const onBlurAllDay = newDate => {
    const isBetween = isCurrentOrNextYear(newDate)

    if (newDate && isBetween) {
      changeBothDates(newDate)
    } else {
      const currentYear = moment().year()
      const resetDate = moment(newDate)
        .year(currentYear)
        .format(DATE_PICKER_DATE_FORMAT)

      changeBothDates(resetDate)
    }
  }

  const startTime = moment(startDatetime).format(DATE_PICKER_TIME_FORMAT)
  const endTime = moment(endDatetime).format(DATE_PICKER_TIME_FORMAT)
  const currentStartDate = moment(startDatetime).format(DATE_PICKER_DATE_FORMAT)
  const currentEndDate = moment(endDatetime).format(DATE_PICKER_DATE_FORMAT)

  const onStartTimeChange = newTime => {
    const time = newTime || '00:00:00'
    setStartDatetime(currentStartDate + 'T' + time)
  }

  const onEndTimeChange = newTime => {
    const time = newTime || '00:00:00'
    setEndDatetime(currentEndDate + 'T' + time)
  }

  const onTimeBlurStart = newTime =>
    onBlurStart(moment(currentStartDate + 'T' + newTime))
  const onTimeBlurEnd = newTime =>
    onBlurEnd(moment(currentEndDate + 'T' + newTime))

  const onAllDayChange = useCallback(
    value => {
      const eventDefaults = eventTypeDefaults[event.type]
      const defaultStart = eventDefaults.start({ timeZone, date })
      const defaultEnd = eventDefaults.end({ timeZone, date })

      changeAllDay(value, defaultStart, defaultEnd)
    },
    [event.type, timeZone, date, changeAllDay]
  )

  return (
    <React.Fragment>
      {showAllDay && (
        <CheckboxField
          label="All Day"
          value={event.allDay}
          onChange={onAllDayChange}
          editing={editing && validEndDate}
        />
      )}

      {event.allDay && !event.recurrence ? (
        <React.Fragment>
          <DateField
            label="Start"
            value={startDate}
            error={startDate === null}
            onBlur={onBlurAllDay}
            onChange={changeBothDates}
            editing={editing}
          />
          <DateField label="End" value={endDate} disabled onChange={() => {}} />
        </React.Fragment>
      ) : !event.allDay && event.recurrence ? (
        <React.Fragment>
          <TimeField
            label="Start"
            value={startTime}
            editing={editing}
            error={startTime === null}
            disabled={!editing}
            onBlur={onTimeBlurStart}
            onChange={onStartTimeChange}
          />
          <TimeField
            label="End"
            value={endTime}
            editing={editing}
            error={endTime === null || !validEndTime}
            disabled={!editing}
            onBlur={onTimeBlurEnd}
            onChange={onEndTimeChange}
          />
        </React.Fragment>
      ) : !event.allDay ? (
        <React.Fragment>
          <DateTimeField
            label="Start"
            isCurrentOrNextYear={isCurrentOrNextYear}
            value={startDatetime}
            error={startDatetime === null}
            onBlur={onBlurStart}
            onChange={(v, _e) => setStartDatetime(v)}
            editing={editing}
          />
          <DateTimeField
            label="End"
            isCurrentOrNextYear={isCurrentOrNextYear}
            disabled={onCallEvent}
            value={endDatetime}
            error={endDatetime === null || !validEnd}
            onBlur={onBlurEnd}
            onChange={(v, _e) => setEndDatetime(v)}
            editing={editing}
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

StartEndField.propTypes = {
  event: PropTypes.record,
  editing: PropTypes.bool,
  showAllDay: PropTypes.bool,
}

StartEndField.defaultProps = {
  showAllDay: true,
}

export default StartEndField
