import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { fetchProblemWorkflow, setWorkflowLoaded } from '../data/imo'

export const updateWorkflowStatus = (action$: any, _state$: any) =>
  action$.pipe(
    ofType(fetchProblemWorkflow.SUCCEEDED),
    pluck('payload'),
    map(([payload]: any) => setWorkflowLoaded(payload))
  )
