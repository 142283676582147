import { Map } from 'immutable'
import React, { useCallback, useMemo } from 'react'
import { VendorSearch } from '~/features/vendorSearch'
import PropTypes from '~/utils/propTypes'

const VendorSearchWidget = ({ onChange, options }) => {
  const vendorFilter = useMemo(() => options.vendorFilter || [], [
    options.vendorFilter,
  ])

  const onSelect = useCallback(
    (id, vendor) => id && onChange(Map(vendor).filter(Boolean).toJS()),
    [onChange]
  )

  return <VendorSearch typeFilters={vendorFilter} onSelect={onSelect} />
}

VendorSearchWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
}

export default VendorSearchWidget
