import { Request } from '~/utils/Requestable'
import Aspire from '~/resources/aspire-strict'
import * as Toast from '~/utils/toast'
import * as DE from '@nll/datum/DatumEither'
import { State as ReduxS, Action as ReduxA } from '~/reducerT'
import { JsonFormT } from '~/data/form'
import * as O from 'fp-ts/lib/Option'
import { Action } from './formT'
import * as t from 'io-ts'

// Requests
export const fetch = (patientId: string) =>
  Request<JsonFormT>(
    Aspire.get(
      `v1/patients/${patientId}/contract_forms?type=notice_of_privacy_practices`,
      JsonFormT.strictType.asDecoder()
    ),
    payload => ReduxA.of.NoticeOfPrivacyPractices({ payload }),
    {
      toast: Toast.error(_ =>
        O.some('Failed to load Notice of Privacy Practices')
      ),
    }
  )

export const submit = (form: JsonFormT, patientId: string) =>
  Request<number>(
    Aspire.post(`/v1/patients/${patientId}/contracts`, t.number.asDecoder(), {
      data: { ...(form.data as any), type: 'notice_of_privacy_practices' },
    }),
    payload =>
      ReduxA.of.NoticeOfPrivacyPractices({
        payload: DE.isSuccess(payload)
          ? DE.initial
          : DE.map(() => form)(payload),
      }),
    {
      toast: Toast.message(
        () => 'Completed Notice of Privacy Practices',
        () => O.some('Failed to complete Notice of Privacy Practices')
      ),
    }
  )

export const init = DE.initial
export const reducer = ({ payload }: Action) =>
  ReduxS.lensFromProp('noticeOfPrivacyPractices').set(payload)
