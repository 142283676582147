import Aspire from '~/resources/aspire-strict'
import { Request } from '~/utils/Requestable'
import { Action, State, UserTimelineEvent } from './userTimelineEventsT'
import { Action as ReduxAction, State as ReduxState } from '~/reducerT'
import * as Toast from '~/utils/toast'
import { RequestError } from '~/resources/resource-strict'
import * as O from 'fp-ts/lib/Option'
import * as t from 'io-ts'
import { flow } from 'fp-ts/lib/function'
import { payloadGet } from '~/utils/type'
import { initial } from '@nll/datum/DatumEither'

export const init = State.build({
  index: initial,
  // show: initial // ^
})
export const rootLens = ReduxState.lensFromProp('userTimelineEvents')
// exposes: lens.get, lens.set, lens.modify functions for doing typesafe immutable object modifications.

// NOTE: should be able to use pipe but I guess morphic-ts isn't updated with monocle's pipe-based interface yet?
// EG: pipe(rootL, State.lensFromProp('index')) should compile but it doesn't yet...
export const indexLens = rootLens.compose(State.lensFromProp('index'))
// indexL.get, indexL.set, indexL.modify, etc...
// export const showL = rootL.compose(State.lensFromProp('show'))

export const fetchUserTimelineEvents = (userId: string) =>
  Request<UserTimelineEvent[]>(
    Aspire.get(
      `/user_timeline/${userId}`,
      t.array(UserTimelineEvent.strictType).asDecoder()
    ),
    payload =>
      ReduxAction.of.UserTimelineEvent({
        payload: Action.of.Index({ payload }),
      }),
    {
      toast: Toast.error(
        RequestError.matchStrict({
          ServerError: () =>
            O.some('There was a problem fetching the user timeline.'),
          FailedRequest: () =>
            O.some('There was a problem fetching the user timeline.'),
          DecodeError: () => O.none,
        })
      ),
    }
  )

export const reducer: (_: Action) => (_: ReduxState) => ReduxState = flow(
  payloadGet,
  Action.matchStrict({
    Index: flow(payloadGet, indexLens.set),
    // Show: flow(payloadGet, showL.set),
  })
)
