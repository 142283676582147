import React from 'react'
import PropTypes from '~/utils/propTypes'
import MaterialUICheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  removePadding: {
    padding: [0, 12],
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}

class Checkbox extends React.Component {
  onChange = (e, v) => {
    this.props.onChange(v, e)
  }

  render() {
    const {
      classes,
      value,
      inline,
      onChange,
      helperText,
      label,
      className,
      checkboxClassName,
      error,
      ...props
    } = this.props

    return (
      <React.Fragment>
        <FormControlLabel
          className={className}
          control={
            <MaterialUICheckbox
              {...props}
              classes={{ root: inline && classes.removePadding }}
              className={checkboxClassName}
              checked={!!value}
              disableRipple
              value={value ? 'true' : 'false'}
              onChange={this.onChange}
            />
          }
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </React.Fragment>
    )
  }
}

Checkbox.propTypes = {
  value: PropTypes.any,
  error: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
}

Checkbox.defaultProps = {
  inline: false,
  onChange: () => {},
}

export default withStyles(styles)(Checkbox)
