// @ts-expect-error fix me
import { combineReducers } from 'redux-immutable'
import batchCreation from './data/batchCreation'
import batchDetails from './data/batchDetails'
import batchList from './data/batchList'
import codeSearch from './data/codeSearch'
import encounterCodingQueries from './data/codingQueries'
import codingQueue from './data/codingQueue'
import configOptions from './data/configOptions'
import encounterCodes from './data/encounterCodes'
import encounterInfo from './data/encounterInfo'
import key from './key'

const reducer = combineReducers({
  [batchCreation.key]: batchCreation,
  [batchDetails.key]: batchDetails,
  [batchList.key]: batchList,
  [codeSearch.key]: codeSearch,
  [codingQueue.key]: codingQueue,
  [configOptions.key]: configOptions,
  [encounterInfo.key]: encounterInfo,
  [encounterCodes.key]: encounterCodes,
  [encounterCodingQueries.key]: encounterCodingQueries,
})

reducer.key = key

export default reducer
