import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { currentEventCleared, fetchPatientInfo } from '../data/events'

export default action$ =>
  action$.pipe(
    ofType(currentEventCleared),
    tap(() => {
      fetchPatientInfo.cancelLatest()
    }),
    ignoreElements()
  )
