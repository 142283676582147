import { List, Record } from 'immutable'
import moment from 'moment'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { dataCleared, getRoot } from './common/shared'

const APP_GRADUATION = 'appGraduation'

const APP = Record({
  appId: null,
  appName: null,
  graduationCategory: null,
  eligibilityDate: null,
  eligible: false,
})

const transformAPPs = (apps: any) =>
  List(
    apps.map((app: any) =>
      APP({
        ...app,
        eligible: Boolean(
          app.eligibilityDate && moment(app.eligibilityDate).isBefore(moment())
        ),
      })
    )
  )

export const fetchAPPsForGraduation = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_APPS_FOR_GRADUATION',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('physician_dashboard/graduation', {
      params: {
        userId,
      },
    }),
  transform: transformAPPs,
  messages: {
    failed: 'There was an issue fetching APPs for graduation',
  },
})

export const setAPPGraduation = Request({
  typePrefix: rootKey,
  typeBase: 'SET_APP_GRADUATION',
  requestParams: ['appId', 'graduationCategory'],
  operation: (appId: any, graduationCategory: any) =>
    AspireAPI.put('physician_dashboard/graduation', {
      appId,
      graduationCategory,
    }),
  messages: {
    succeeded: "Successfully saved APP's graduation",
    failed: "There was an issue saving this APP's graduation",
  },
})

const initState = List()

export default createReducer(APP_GRADUATION, initState, {
  [fetchAPPsForGraduation.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  [setAPPGraduation.SUCCEEDED]: (
    state: any,
    {
      meta: {
        request: {
          payload: { appId, graduationCategory },
        },
      },
    }: any
  ) => {
    const index = state.findIndex((app: any) => app.appId === appId)
    const app = state.get(index)
    return state.set(index, app.set('graduationCategory', graduationCategory))
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [dataCleared]: () => initState,
})

export const getAPPsForGraduation = pipe(getRoot, get(APP_GRADUATION))
