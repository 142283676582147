import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from './common/key'

const key = 'appointments'
const getRoot = get(rootKey)

const transformAppointments = (appointments: any) => {
  if (appointments && appointments != null) {
    const consolidatedAppointments: any[] = []
    appointments.lastKeptAppointment.items.map((lka: any) => {
      consolidatedAppointments.push({
        date: lka.appointmentDate,
        time: lka.endTime,
        type: lka.eventName,
        appontmentstatus: lka.workflowStatus,
      })
    })

    appointments.futureAppointment.items.map((fa: any) => {
      consolidatedAppointments.push({
        date: fa.appointmentDate,
        time: fa.endTime,
        type: fa.eventName,
        appontmentstatus: fa.workflowStatus,
      })
    })
    return [consolidatedAppointments]
  }
  return []
}

const fetchAppointmentsApi = (patient_id: any) =>
  AspireAPI.get('medications/appointments', {
    params: { patient_id },
  })

export const fetchAppointments = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: fetchAppointmentsApi,
  transform: transformAppointments,
  messages: { failed: 'There was an issue fetching appointments' },
})

export default createReducer(key, [], {
  [fetchAppointments.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getAppointments = (state: any) =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(key)
export const getDischargeAppointments = (state: any) => getAppointments(state)
export const getAllAppointments = pipe(get('assessment'), get(key))
