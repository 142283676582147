import EventActions from '../../Events/EventActions'
import IncentiveDayCalendarEvent from './IncentiveDayCalendarEvent'
import IncentiveDayEventDetail from './IncentiveDayEventDetail'
import defaults from './defaults'
import * as info from './info'
import * as permissions from './permissions'

export default {
  ...info,
  type: defaults.type,
  defaults: defaults,
  permissions: permissions,
  detailComponent: IncentiveDayEventDetail,
  calendarComponent: IncentiveDayCalendarEvent,
  actionsComponent: EventActions,
}
