import React from 'react'
import PropTypes from '~/utils/propTypes'
import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui'

const GroupDisplayCell = props => {
  const {
    column: {
      groupByComponent: GroupByComponent,
      displayComponent: DisplayComponent,
    },
    ...otherProps
  } = props

  const sym = Object.getOwnPropertySymbols(props.row).find(
    s => String(s) === 'Symbol(Symbol(group)_collapsedRows)'
  )

  const collapsedRowCount =
    sym && props.row[sym] && props.row[sym].length
      ? props.row[sym].length
      : null

  if (GroupByComponent) {
    return (
      <TableGroupRow.Cell {...props}>
        <GroupByComponent
          value={props.row.value}
          collapsedRowCount={collapsedRowCount}
          {...otherProps}
        />
      </TableGroupRow.Cell>
    )
  } else if (DisplayComponent) {
    return (
      <TableGroupRow.Cell {...props}>
        <DisplayComponent value={props.row.value} {...otherProps} />
      </TableGroupRow.Cell>
    )
  }

  return <TableGroupRow.Cell {...props} />
}

GroupDisplayCell.propTypes = {
  column: PropTypes.object,
  row: PropTypes.object,
}

export default GroupDisplayCell
