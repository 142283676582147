import { debounce } from 'lodash'
import React, { useEffect, useRef } from 'react'
import PropTypes from '~/utils/propTypes'

export default function OnResize({ children, callback }) {
  const ref = useRef(null)

  useEffect(() => {
    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(
        debounce(callback, 500, { leading: true })
      )

      resizeObserver.observe(ref.current)

      return () => resizeObserver.unobserve(ref.current)
    }
  }, [callback, ref.current])

  return <div ref={ref}>{children}</div>
}

OnResize.propTypes = {
  children: PropTypes.node.isRequired,
  callback: PropTypes.func.isRequired,
}
