import { combineEpics } from 'redux-observable'
import appEntered from './epics/appEntered'
import callListEntered from './epics/callListEntered'
import crossCoverageChanged from './epics/crossCoverageChanged'
import dashboardChanged from './epics/dashboardChanged'
import dashboardEntered from './epics/dashboardEntered'
import dashboardExited from './epics/dashboardExited'
import dashboardPatientChangeRequested from './epics/dashboardPatientChangeRequested'
import patientFiltersChanged from './epics/patientFiltersChanged'
import patientSNFAdmission from './epics/patientSNFAdmission'
import taskSelected from './epics/taskSelected'
import userIdRouteChanged from './epics/userIdRouteChanged'

export default combineEpics(
  appEntered,
  callListEntered,
  crossCoverageChanged,
  dashboardChanged,
  dashboardEntered,
  dashboardExited,
  dashboardPatientChangeRequested,
  patientFiltersChanged,
  patientSNFAdmission,
  taskSelected,
  userIdRouteChanged
)
