import { Map } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'selectedProject'

export const DRAFT_PROJECT_SELECTED = type(rootKey, 'DRAFT_PROJECT_SELECTED')
export const SELECTED_PROJECT_CLEARED = type(
  rootKey,
  'SELECTED_PROJECT_CLEARED'
)

export const setSelectedProject = creator(DRAFT_PROJECT_SELECTED, 'project')
export const clearProject = creator(SELECTED_PROJECT_CLEARED)

export default createReducer(key, new Map(), {
  [DRAFT_PROJECT_SELECTED]: (state, { payload: { project } }) => project,
  [SELECTED_PROJECT_CLEARED]: () => new Map(),
})

export const getSelectedProject = pipe(getRoot, get(key))
