// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const CURRENT_USER_ID = 'currentUserId'

const creator = scopedCreator(rootKey)
export const userIdSelected = creator('USER_ID_SELECTED', ['userId'])
export const userIdCleared = creator('USER_ID_CLEARED')

const initState = null

export default createReducer(CURRENT_USER_ID, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [userIdSelected]: (_state: any, { payload: { userId } }: any) => userId,
  // @ts-expect-error can we deal with the toString being automatically called?
  [userIdCleared]: () => initState,
})

export const getCurrentUserId = pipe(getRoot, get(CURRENT_USER_ID))
