import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, mergeMapTo, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  PROJECT_DELETE_ATTEMPTED,
  deleteProject,
  projectDeleted,
} from '../data/draftProjects'
import { selectedPolygonCleared } from '../data/selectedPolygon'
import { clearProject } from '../data/selectedProject'
import showError from '../utils/showError'

const draftProjectDeletedEpic = action$ =>
  action$.pipe(
    ofType(PROJECT_DELETE_ATTEMPTED),
    pluck('payload', 'projectId'),
    mergeMap(projectId =>
      loading(
        from(deleteProject(projectId)).pipe(
          mergeMapTo(
            of(
              projectDeleted(projectId),
              clearProject(),
              selectedPolygonCleared()
            )
          ),
          catchError(showError('Failed to delete project.'))
        )
      )
    )
  )
export default draftProjectDeletedEpic
