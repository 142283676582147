import { useAction } from '~/hooks'
import { geocodeFailed, permissionGeocodeFailed } from '../data/common/shared'

export default (encounterId, snackbarActionCreator, requestActionCreator) => {
  const requestMessage = useAction(snackbarActionCreator)
  const failedPermissionMessage = useAction(permissionGeocodeFailed)
  const failedMessage = useAction(geocodeFailed)
  const requestAction = useAction(requestActionCreator)

  return () => {
    requestMessage()

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords, timestamp }) => {
          requestAction(
            encounterId,
            new Date(timestamp).toISOString(),
            coords.latitude,
            coords.longitude
          )
        },
        ({ code }) => {
          // Permission denied
          if (code === 1) {
            failedPermissionMessage()
          } else {
            failedMessage()
          }

          requestAction(encounterId, new Date().toISOString())
        }
      )
    } else {
      requestAction(encounterId, new Date().toISOString())
    }
  }
}
