import { isAfter, parseISO } from 'date-fns'
import { Map, Record } from 'immutable'
import _ from 'lodash/fp'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { formatPhone } from '../utils/format.js'
import { callLogCleared, getRoot } from './common/shared'

export const ACTIVE = 'Active'
export const INACTIVE = 'Inactive'

const CONTACTS = 'contacts'

export const Contact = Record({
  active: null,
  effectiveEndDate: null,
  expired: null,
  hipaaAuthExpired: null,
  isPreferred: false,
  lastModifiedDate: null,
  name: null,
  patientId: null,
  phiContact: null,
  phiMessageApproved: null,
  phoneDescription: null,
  phoneNumber: null,
  phoneNumberFormatted: null,
  relationship: null,
  type: null,
  sourceLabel: null,
  cpsuiStatusLabel: null,
})

const mapToLegacy = _.flatMap(contact =>
  _.map(phone => {
    const hipaaExpiration =
      phone.hipaaAuthExpiration && parseISO(phone.hipaaAuthExpiration)
    const expired =
      phone.hipaaAuthExpiration &&
      isAfter(new Date(), parseISO(phone.hipaaAuthExpiration))
    const isMasked = phone.cpsuiStatus === 'reassigned_number'

    return {
      active: phone.active ? 'Active' : 'Inactive',
      effectiveEndDate: expired && hipaaExpiration,
      expired,
      hipaaAuthExpired: expired,
      isPreferred: contact.preferred && phone.primary,
      lastModifiedDate: phone.modifiedAt && parseISO(phone.modifiedAt),
      name: contact.name,
      patientId: contact.patientId,
      phiContact: !!phone.hipaaAuthExpiration,
      phiMessageApproved: phone.phiVoicemailApproved,
      phoneDescription: contact.notes,
      phoneNumber: formatPhone(phone.phone),
      phoneNumberFormatted: formatPhone(phone.phone, isMasked),
      relationship: contact.relationship,
      type: contact.typeLabel + ' - ' + phone.typeLabel,
      sourceLabel: phone?.sourceLabel,
      cpsuiStatusLabel: phone?.cpsuiStatusLabel,
    }
  })(contact.phones)
)

const transformContacts = data => {
  const contacts = mapToLegacy(data)

  return Map(contacts.map(contact => [contact.phoneNumber, Contact(contact)]))
}

export const fetchContacts = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CONTACTS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/patient/${patientId}/contacts`),
  transform: transformContacts,
  messages: { failed: 'There was an issue fetching patient contacts' },
})

export const syncContacts = Request({
  typePrefix: rootKey,
  typeBase: 'SYNC_CONTACTS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/sync_contacts/${patientId}`),
  transform: transformContacts,
  messages: { failed: 'There was an issue syncing patient contacts' },
})

const initState = Map()
export default createReducer(CONTACTS, initState, {
  [callLogCleared]: () => initState,
  [fetchContacts.SUCCEEDED]: (_state, { payload }) => payload,
  [syncContacts.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getContacts = pipe(getRoot, get(CONTACTS))

export const getContact = (state, phoneNumber) =>
  getContacts(state).get(phoneNumber)
