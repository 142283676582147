import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { additionalReferralSelected, caseIdChanged } from '../data/referrals'
import {
  REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_DETAIL,
  REFERRAL_MANAGEMENT_REFERRAL_DETAIL,
} from '../router'

const referralDetail = (action$: any) =>
  action$.pipe(
    filter(entered(REFERRAL_MANAGEMENT_REFERRAL_DETAIL)),
    pluck('payload', 'params', 'caseId'),
    map(caseIdChanged)
  )

const additionalReferralDetail = (action$: any) =>
  action$.pipe(
    filter(entered(REFERRAL_MANAGEMENT_ADDITIONAL_REFERRAL_DETAIL)),
    pluck('payload', 'params', 'patientId'),
    map(additionalReferralSelected)
  )

export default combineEpics(referralDetail, additionalReferralDetail)
