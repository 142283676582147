import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getOrderKey } from './common/shared'

const key = 'actionSuccess'

export const ACTION_SUCCESSFUL = type(key, 'ACTION_SUCCESSFUL')
export const SUCCESS_DISPLAYED = type(key, 'SUCCESS_DISPLAYED')

export const actionSuccessful = creator(ACTION_SUCCESSFUL, 'message')
export const deleteSuccess = creator(SUCCESS_DISPLAYED, 'message')

export default createReducer(key, new Set(), {
  [ACTION_SUCCESSFUL]: (state, { payload: { message } }) => state.add(message),
  [SUCCESS_DISPLAYED]: (state, { payload: { message } }) =>
    state.delete(message),
})

export const getSuccessMessages = pipe(getOrderKey, get(key))
