import React, { useEffect } from 'react'
// @ts-expect-error ts-migrate(2307) FIXME
import PropTypes from '~/utils/propTypes'
import {
  Accordion,
  AccordionSummary,
  Icon,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import GapsContent from './GapsContent'
import { useSelector } from 'react-redux'
import { getOpenGapsList, Gap } from '../data/openGaps'

type Props = {
  gaps: Gap[]
  getGapsCount: any
  classes: any
  hidePanelSummary: boolean
  patientPanelGapsOpen: boolean
  hiddenDescription: boolean
  hiddenDate: boolean
}

const styles = ({ palette }: any) => ({
  gaps: {
    flex: '1',
  },
  root: {
    '&:before': {
      height: 0,
    },
  },
  summaryRoot: {
    backgroundColor: palette.primary.dark,
    height: 48,
    minHeight: '0 !important',
    padding: [0, 16],
    '& h3': {
      color: palette.common.white,
    },
  },
  summaryContent: {
    margin: [4, 0],
  },
  expanded: {
    margin: 0,
  },
  hidePanelSummaryRoot: {
    backgroundColor: palette.primary.dark,
    display: 'none',
  },
  gapColor: {
    backgroundColor: palette.primary.dark,
    borderBottom: '0 !important',
    '&$expanded': {
      margin: 0,
    },
  },
  noSummaryGapColor: {
    backgroundColor: palette.primary.light,
    borderBottom: '0 !important',
    borderRadius: '0 !important',
  },
  numberCircle: {
    backgroundColor: palette.shades.darker,
    borderRadius: '50%',
    display: 'inline-block',
    fontWeight: 'bold',
    height: 32,
    verticalAlign: 'middle',
    width: 32,
  },
  numbers: {
    color: palette.common.white,
    display: 'inline-block',
    fontWeight: 'bold',
    position: 'relative',
    right: 32,
    textAlign: 'center',
    verticalAlign: 'middle',
    width: 32,
  },
  gapMessage: {
    color: palette.common.white,
    display: 'inline-block',
    position: 'relative',
    right: 24,
    top: 1,
  },
  colorWhite: {
    color: palette.common.white,
  },
})

const OpenGaps = ({
  hiddenDescription,
  hiddenDate,
  classes,
  getGapsCount,
  hidePanelSummary,
  patientPanelGapsOpen,
}: Props) => {
  const gaps = useSelector(getOpenGapsList)
  useEffect(() => {
    getGapsCount && getGapsCount(gaps.size)
  }, [gaps.size])

  return (
    !gaps.isEmpty() &&
    (hidePanelSummary ? (
      <Accordion
        expanded={patientPanelGapsOpen || false}
        classes={{ expanded: classes.expanded, root: classes.root }}
        className={classes.noSummaryGapColor}
        elevation={0}
      >
        <AccordionSummary
          classes={{
            root: classes.hidePanelSummaryRoot,
          }}
        ></AccordionSummary>
        <GapsContent
          gaps={gaps}
          hiddenDescription={hiddenDescription}
          hiddenDate={hiddenDate}
        />
      </Accordion>
    ) : (
      <Accordion
        classes={{ expanded: classes.expanded, root: classes.root }}
        className={classes.gapColor}
        elevation={0}
      >
        <AccordionSummary
          classes={{
            content: classes.summaryContent,
            root: classes.summaryRoot,
            expandIcon: classes.colorWhite,
          }}
          className={classes.gapColor}
          expandIcon={<Icon>expand_more</Icon>}
        >
          <Typography variant="subtitle1">
            <div className={classes.numberCircle} />
            <div className={classes.numbers}>{gaps.size}</div>
            <div className={classes.gapMessage}>OPEN GAPS</div>
          </Typography>
        </AccordionSummary>
        <GapsContent
          gaps={gaps}
          hiddenDescription={hiddenDescription}
          hiddenDate={hiddenDate}
        />
      </Accordion>
    ))
  )
}

OpenGaps.propTypes = {
  classes: PropTypes.object.isRequired,
  hidePanelSummary: PropTypes.bool,
  patientPanelGapsOpen: PropTypes.bool,
}

OpenGaps.defaultProps = {
  hidePanelSummary: false,
  patientPanelGapsOpen: false,
  hiddenDescription: false,
  hiddenDate: false,
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ palette }: any) =>
export default withStyles(styles)(OpenGaps)
