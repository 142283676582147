import { OrderedMap, List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error FIXME
import Request from '~/utils/Request'
// @ts-expect-error FIXME
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'

const key = 'virtualEncounterTypes'

const creator = scopedCreator(key)

export const VirtualEncounterTypesCleared = creator(
  'VIRTUAL_ENCOUNTER_TYPES_CLEARED',
  false
)

export const VirtualEncounterType = Record({
  type: null,
  label: null,
  modality: null,
  defaultVisitDuration: null,
})

const filterVirtualEncounterTypes = (types: any) =>
  List(
    types
      .filter((type: any) => type.modality === 'virtual')
      .map((type: any) => VirtualEncounterType({ ...type }))
  )

export const fetchVirtualEncounterTypes = Request({
  typePrefix: key,
  typeBase: 'FETCH_VIRTUAL_ENCOUNTER_TYPES',
  requestParams: ['userId', 'patientId', 'dateOfService'],
  operation: (userId: string, patientId: string, dateOfService: string) =>
    AspireAPI.get('encounters/type', {
      params: {
        user_id: userId,
        patient_id: patientId,
        date_of_service: dateOfService,
        scheduling: true,
      },
    }),
  transform: filterVirtualEncounterTypes,
  messages: {
    failed: 'There was a problem fetching virtual encounter types',
  },
})

export default createReducer(key, OrderedMap(), {
  [fetchVirtualEncounterTypes.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  // @ts-expect-error FIXME
  [VirtualEncounterTypesCleared]: () => OrderedMap(),
})

export const getVirtualEncounterTypes = pipe(get('assessment'), get(key))
