import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import Input from './Input'
import debounce from './debounce'
import memoizedWrapper from './memoizedWrapper'
import { getInputProps } from './props'

const MonthWidget = props => (
  <Input {...getInputProps(props)} fullWidth type="month" />
)
export default compose(memoizedWrapper, debounce)(MonthWidget)
