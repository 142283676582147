import { fromJS } from 'immutable'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  NEW_PROJECT_SAVE_ATTEMPTED,
  loadNewProject,
  saveNewProject,
} from '../data/draftProjects'
import { setSelectedProject } from '../data/selectedProject'
import showError from '../utils/showError'

const draftProjectSavedEpic = action$ =>
  action$.pipe(
    ofType(NEW_PROJECT_SAVE_ATTEMPTED),
    pluck('payload', 'name'),
    mergeMap(name =>
      loading(
        from(saveNewProject(name)).pipe(
          mergeMap(project =>
            of(loadNewProject(project), setSelectedProject(fromJS(project)))
          ),
          catchError(showError('Failed to save project.'))
        )
      )
    )
  )

export default draftProjectSavedEpic
