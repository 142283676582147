import { isAfter, parseISO } from 'date-fns'
import validator from 'validator'
import stateCodes from './stateCodes'

export const alpha = value =>
  value && /[^a-zA-Z]/i.test(value)
    ? 'Names cannot include special characters'
    : undefined

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined

export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined

export const postalCode = value =>
  value && !validator.isPostalCode(value, 'US')
    ? 'Please use a valid US postal code'
    : undefined

export const required = value => (value ? undefined : 'Required')

export const requiredString = value =>
  value && value.trim().length > 0 ? undefined : 'Required'

export const requiredArray = value =>
  value && value.length > 0 ? undefined : 'Required'

export const isStateCode = value =>
  value && !validator.isIn(value.toUpperCase(), stateCodes)
    ? 'Invalid State Code'
    : undefined

export const isValidMbi = value =>
  value &&
  !/^[1-9]{1}(?![BILOSZ])[A-Z]{1}(?![BILOSZ])[A-Z0-9]{1}\d{1}(?![BILOSZ])[A-Z]{1}(?![BILOSZ])[A-Z0-9]{1}\d{1}(?![BILOSZ])[A-Z](?![BILOSZ])[A-Z]\d{2}$/i.test(
    value
  )
    ? 'Incorrect format for MBI'
    : undefined

export const validDate = value =>
  value && !validator.toDate(value) ? 'Must be a valid date' : undefined

export const validBirthday = value =>
  value && validator.isAfter(value)
    ? 'Must be in the past'
    : validator.isBefore(value, '1900-01-01')
    ? 'Must be on or after 1900'
    : undefined

export const futureDate = value =>
  value && validator.isBefore(value) ? 'Must be in the future' : undefined

export const hasLetters = value => value && /[a-zA-Z]+/g.test(value)

export const pastDate = value =>
  value && isAfter(parseISO(value), new Date())
    ? 'Must be in the past'
    : undefined
