import { Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const OPEN_ADMISSION_ID_TOGGLED =
  'reconciliation/OPEN_ADMISSION_ID_TOGGLED'

// ACTION CREATORS
export const openAdmissionIdToggled = (admissionId: any) => ({
  type: OPEN_ADMISSION_ID_TOGGLED,
  admissionId,
})

// KEY
export const key = 'openAdmissionIds'

const toggleSet = (set: any, id: any) =>
  set.has(id) ? set.delete(id) : set.add(id)

// REDUCERS
export default createReducer(key, Set(), {
  [OPEN_ADMISSION_ID_TOGGLED]: (state: any, { admissionId }: any) =>
    toggleSet(state, admissionId),
})

// SELECTORS
export const getAllOpenAdmissionIds = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
export const isAdmissionIdOpen = (state: any, admissionId: any) =>
  getAllOpenAdmissionIds(state).has(admissionId)
