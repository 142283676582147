import { ofType } from 'redux-observable'
import { filter, map } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import {
  createNewTaskUpdated,
  fetchNewTaskForm,
  getCreateNewTaskForm,
} from '../data/createNewTask'

const hasPatientAndKey = createNewTask =>
  createNewTask.patientId && createNewTask.taskDefinitionKey

export default (action$, state$) =>
  action$.pipe(
    ofType(createNewTaskUpdated),
    switchTo(state$),
    map(getCreateNewTaskForm),
    filter(hasPatientAndKey),
    map(({ patientId, taskDefinitionKey }) =>
      fetchNewTaskForm.requested(patientId, taskDefinitionKey)
    )
  )
