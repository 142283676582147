import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import rootKey from '../key'

const key = 'setDecisionMaker'
const preferredKey = 'setPreferred'
const deleteContactKey = 'deleteContact'

export const fetchDecisionMaker = Request({
  typePrefix: key,
  typeBase: 'FETCH_DECISION_MAKER',
  requestParams: ['patientId', 'contactId', 'data'],
  operation: (patientId: any, contactId: any, data: any) => {
    return AspireAPI.patch(`v1/patient/${patientId}/contacts/${contactId}`, {
      ...data,
    })
  },

  messages: {
    failed: 'Failed to fetch Decision Maker',
  },
})

export const setDecisionMaker = createReducer(key, List(), {
  [fetchDecisionMaker.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const fetchPreferred = Request({
  typePrefix: key,
  typeBase: 'FETCH_Preferred',
  requestParams: ['patientId', 'contactId', 'data'],
  operation: (patientId: any, contactId: any, data: any) => {
    return AspireAPI.patch(`v1/patient/${patientId}/contacts/${contactId}`, {
      ...data,
    })
  },

  messages: {
    failed: (e: any) => e.response.data.message || 'Failed to fetch Preferred',
  },
})

export const setPreferred = createReducer(preferredKey, List(), {
  [fetchPreferred.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const deleteContactRequest = Request({
  typePrefix: key,
  typeBase: 'FETCH_Preferred',
  requestParams: ['patientId', 'contactId', 'data'],
  operation: (patientId: any, contactId: any, data: any) => {
    return AspireAPI.delete(`v1/patient/${patientId}/contacts/${contactId}`, {
      ...data,
    })
  },

  messages: {
    failed: 'Failed to fetch Preferred',
  },
})

export const deleteContact = createReducer(deleteContactKey, List(), {
  [deleteContactRequest.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getDecisionMaker = getIn([rootKey, key])
