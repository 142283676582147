// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/root' or its correspond... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'
import { LOAD_INACTIVE_USERS } from '~/utils/storage'
import { getRoot } from './common/shared'
// Key
const USERS_LIST_SETTINGS = 'userListSettings'

const parseInitState = ({ initialData }: any) =>
  initialData[LOAD_INACTIVE_USERS] || false

// Types
export const LOAD_INACTIVE_USERS_TOGGLED = type(
  USERS_LIST_SETTINGS,
  'LOAD_INACTIVE_USERS_TOGGLED'
)

// Actions
export const toggleLoadInactiveUsers = creator(
  LOAD_INACTIVE_USERS_TOGGLED,
  'value'
)

// Reducer
export default createReducer(USERS_LIST_SETTINGS, false, {
  [LOAD_INACTIVE_USERS_TOGGLED]: (_state: any, { payload }: any) =>
    payload.value,
  [appInitialized]: (_state: any, { payload }: any) => parseInitState(payload),
})

// Selectors
export const getShouldLoadInactiveUsers = (state: any) =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(USERS_LIST_SETTINGS)
