import { List } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { fetchConfig } from './config'

const EXTENSIVISTS = 'extensivists'

export default createReducer(EXTENSIVISTS, List(), {
  [fetchConfig.SUCCEEDED]: (_state, { payload: { extensivists } }) =>
    extensivists,
})

export const getExtensivists = compose(get(EXTENSIVISTS), getRoot)
