import { combineReducers } from 'redux-immutable'
import potentialAPPs from './data/potentialAPPs'
import potentialChannels from './data/potentialChannels'
import potentialMarkets from './data/potentialMarkets'
import key from './key'

const reducer = combineReducers({
  [potentialAPPs.key]: potentialAPPs,
  [potentialChannels.key]: potentialChannels,
  [potentialMarkets.key]: potentialMarkets,
})

reducer.key = key

export default reducer
