/*
  Produces an infinte generator of colors given an initial array of colors
  Useful when you want to assign random colors for visualization.
  const colorScheme = [ '#8dd3c7', '#ffffb3', '#bebada' ]
  const colors = colorGenerator(colorScheme)
  colors.next().value // '#8dd3c7'
  colors.next().value // '#ffffb3'
  colors.next().value // '#bebada'
  colors.next().value // '#8dd3c7'
*/

export default function* colorGenerator(colorScheme) {
  checkColorGeneratorParams(colorScheme)

  let index = -1
  const numColors = colorScheme.length
  while (true) {
    if (index === numColors) {
      index = 0
    } else {
      index++
    }
    yield colorScheme[index]
  }
}

function checkColorGeneratorParams(colorScheme) {
  if (!colorScheme || typeof colorScheme !== 'object' || !colorScheme.length)
    throw new TypeError(`
      Error in colorGenerator util.
      ColorScheme must be an array of colors ie ['#8dd3c7'].
      Instead received ${colorScheme}.`)
}
