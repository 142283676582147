import { ofType } from 'redux-observable'
import { ROUTE_CHANGED } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { PATIENT_RECORD_EPISODE_OF_CARE_DETAIL } from '~/apps/patientRecord/router'
import { episodeOfCareSelected } from '../data/currentEpisodeOfCareId'

export default action$ =>
  action$.pipe(
    ofType(ROUTE_CHANGED),
    pluck('payload'),
    filter(({ route }) => route == PATIENT_RECORD_EPISODE_OF_CARE_DETAIL),
    pluck('params', 'episodeOfCareId'),
    map(Number),
    map(episodeOfCareSelected)
  )
