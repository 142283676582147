// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import dashUserId from './data/dashUserId'
import telehealthPatients from './data/telehealthPatients'
import key from './key'

const reducer = combineReducers({
  [dashUserId.key]: dashUserId,
  [telehealthPatients.key]: telehealthPatients,
})

reducer.key = key

export default reducer
