import React from 'react'
import { FieldValue } from '~/data/fieldValues'
import { values as reportTypesCaremore } from '~/fieldValues/stakeholder_report_type_cm'
import PropTypes from '~/utils/propTypes'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

class EditStakeholderRow extends React.PureComponent {
  onChange = event => {
    this.props.onChange(event.target.value)
  }

  render() {
    const { className, items, value, isCaremoreBrand } = this.props

    return (
      <Select
        multiple
        className={className}
        onChange={this.onChange}
        value={value}
      >
        {isCaremoreBrand
          ? reportTypesCaremore.map(({ value, label }) => (
              <MenuItem key={value} value={label}>
                {label}
              </MenuItem>
            ))
          : items.toIndexedSeq().map(({ value, label }) => (
              <MenuItem key={value} value={label}>
                {label}
              </MenuItem>
            ))}
      </Select>
    )
  }
}

EditStakeholderRow.propTypes = {
  className: PropTypes.string.isRequired,
  items: PropTypes.mapOf(FieldValue),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  isCaremoreBrand: PropTypes.bool,
}

export default EditStakeholderRow
