import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'

// RECORDS
export const Channel = Record({
  id: null,
  name: null,
  healthplan_name: null,
  healthplan_id: null,
})

// TYPES
export const CHANNELS_STORED = 'channels/CHANNELS_STORED'

// ACTIONS
export const storeChannels = channels => ({ type: CHANNELS_STORED, channels })

// KEY
export const key = 'channels'

// REDUCER
export default createReducer(key, Map(), {
  [CHANNELS_STORED]: (state, { channels }) => state.merge(channels),
})

const mapData = data => Map(data.map(d => [d.id, Channel(d)]))

// API
export const fetchChannelsByName = channelName =>
  AspireAPI.get('v1/channel', { params: { channelName } }).then(mapData)

// SELECTORS
export const getAllChannels = state => state.get(key)
