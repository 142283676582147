import { combineEpics } from 'redux-observable'
import bulkUpdateSuccess from './epics/bulkUpdateSuccess'
import {
  addCareTeamManagement,
  removeCareTeamManagement,
} from './epics/careTeamManagement'
import { addCareTeamPodEpic, editCareTeamPodEpic } from './epics/careTeamPods'
import careTeamsEntered from './epics/careTeamsEntered'
import onPatientIdSelected from './epics/onPatientIdSelected'
import redirectOnCareTeamCreation from './epics/redirectOnCareTeamCreation'
import reloadCareTeamOnUpdate from './epics/reloadCareTeamOnUpdate'
import updateCareTeamUsers from './epics/updateCareTeamUsers'

export default combineEpics(
  bulkUpdateSuccess,
  careTeamsEntered,
  onPatientIdSelected,
  redirectOnCareTeamCreation,
  reloadCareTeamOnUpdate,
  updateCareTeamUsers,
  addCareTeamPodEpic,
  editCareTeamPodEpic,
  addCareTeamManagement,
  removeCareTeamManagement
)
