import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { handleMedMessage } from './root'

/* KEY */
const key = 'allergyData'

export const Allergy = Record({
  description: null,
  onsetDate: null,
  reactionDescription: null,
  resolvedDate: null,
  resolutionStatus: null,
})

const transformAllergies = allergies =>
  Array.isArray(allergies) ? List(allergies.map(Allergy)) : List()

export const fetchAllergy = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: patient_id =>
    AspireAPI.get(`medications/allergies?patient_id=${patient_id}`),
  transform: transformAllergies,
  messages: {
    failed: handleMedMessage('There was a problem fetching Allergies'),
  },
})

/* REDUCER */
export default createReducer(key, List(), {
  [fetchAllergy.SUCCEEDED]: (_state, { payload }) => payload,
})

/* SELECTOR*/
export const getPatientAllergies = pipe(get(rootKey), get(key))
