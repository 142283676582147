import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { FETCHED_POLYGONS } from './polygons'

const key = 'selectedMarketPolygons'

export const MARKET_POLYGON_SELECTED = type(rootKey, 'MARKET_POLYGON_SELECTED')

export const setSelectedMarketPolygons = creator(
  MARKET_POLYGON_SELECTED,
  'markets'
)

export default createReducer(key, new Set(), {
  [MARKET_POLYGON_SELECTED]: (state, { payload: { markets } }) => Set(markets),
  [FETCHED_POLYGONS]: (state, { payload: { polygons } }) =>
    polygons.reduce((set, polygon) => set.add(polygon.id), Set()),
})

export const getSelectedMarketPolygons = pipe(getRoot, get(key))
