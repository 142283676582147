import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DiamondMarker, InfoWindow } from '~/components/GeoMap'
import { eventTypes } from '~/features/calendarWidget'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { colors } from '@material-ui/core'
import { createGetLocationLabel } from '../../data/common/derived'
import MarkerMenu from './MarkerMenu'
import OptionsMenu from './OptionsMenu'

const EventMarker = ({ event, color }) => {
  const {
    id,
    location: { latitude, longitude },
  } = event
  const getLocationLabel = useMemo(createGetLocationLabel)

  const label = useSelector(state => getLocationLabel(state, id))

  const [menuOpen, toggleMenuOpenTrue, toggleMenuOpenFalse] = useToggle()
  const [hovered, toggleHoveredTrue, toggleHoveredFalse] = useToggle()

  const info = eventTypes[event.type].title(event)
  const location = { lat: latitude, lng: longitude }

  return id ? (
    <DiamondMarker
      position={location}
      color={color}
      label={label}
      onMouseover={toggleHoveredTrue}
      onMouseout={toggleHoveredFalse}
      onRightClick={toggleMenuOpenTrue}
    >
      <InfoWindow open={hovered} disableAutoPan zIndex={hovered ? 2 : 1}>
        {info}
      </InfoWindow>

      <MarkerMenu open={menuOpen} onClose={toggleMenuOpenFalse}>
        <OptionsMenu id={id} label={info} location={location} />
      </MarkerMenu>
    </DiamondMarker>
  ) : null
}

EventMarker.propTypes = {
  event: PropTypes.record.isRequired,
  color: PropTypes.string,
}

EventMarker.defaultProps = {
  color: colors.blue['500'],
}

export default EventMarker
