import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { fetchInsuranceCoverages } from '~/features/insuranceManagement'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_CHANNEL_INSURANCE } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_CHANNEL_INSURANCE)),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId => of(fetchInsuranceCoverages.requested(patientId)))
  )
