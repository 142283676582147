import { Map, Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/re... Remove this comment to see the full error message
import OrcAPI from '~/resources/orc'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// RECORDS
export const AdmissionSource = Record({
  id: null,
  name: null,
})

// CONSTANTS
export const ADMISSION_SOURCES_STORED =
  'admissionSources/ADMISSION_SOURCES_STORED'
export const ADMISSION_SOURCE_FETCH_FAILED =
  'admissionSources/ADMISSION_SOURCE_FETCH_FAILED'

// ACTION CREATORS
export const admissionSourcesStored = (admissionSources: any) => ({
  type: ADMISSION_SOURCES_STORED,
  admissionSources,
})
export const admissionSourceFetchFailed = () => ({
  type: ADMISSION_SOURCE_FETCH_FAILED,
})

// KEY
export const key = 'admissionSources'

// REDUCER
export default createReducer(key, Map(), {
  [ADMISSION_SOURCES_STORED]: (state: any, { admissionSources }: any) =>
    state.mergeDeep(admissionSources),
})

const mapData = (data: any) =>
  Map(
    data.map((d: any) => [
      d.admissionsSourceId,
      AdmissionSource({
        id: d.admissionsSourceId,
        name: d.admissionsSourceName,
      }),
    ])
  )

// API
export const fetchAdmissionSources = () =>
  OrcAPI.get('admissions/admission_sources').then(mapData)

// SELECTORS
export const getAllAdmissionSources = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
export const getAdmissionSourceById = (state: any, id: any) =>
  getAllAdmissionSources(state).get(id)
