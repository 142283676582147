import { List } from 'immutable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { ModifierGroup, Problem } from '../../types/types'
import { getRoot } from '../common/shared'

export const getImoSessionId = pipe(getRoot, get('sessionId'))
export const getImoRequestId = pipe(getRoot, get('requestId'))
export const getImoSuggestions = pipe(getRoot, get('suggestions'))
export const getImoProblems = pipe(getRoot, get('problems'))
export const getImoSelectedProblems = pipe(getRoot, get('selectedProblems'))
export const getImoSearchCriteria = pipe(getRoot, get('searchCriteria'))
export const getProblemsLoaded = pipe(getRoot, get('problemsLoaded'))

export const getImoSelectedProblem = (id: string) => (state: any) =>
  pipe(getImoSelectedProblems, problems =>
    List(problems).get(
      problems.findIndex((problem: Problem) => problem.id === id)
    )
  )(state)

export const getImoModifierGroups = (problemId: string) => (state: any) =>
  pipe(getImoSelectedProblem(problemId), get('modifierGroups'))(state)

export const getWorkflowLoaded = (problemId: string) => (state: any) =>
  pipe(getImoSelectedProblem(problemId), get('workflowLoaded'))(state)

export const getModifierSelected = (problemId: string) => (state: any) =>
  pipe(getImoSelectedProblem(problemId), get('modifierSelected'))(state)

export const getImoDiagnoses = (problemId: string) => (state: any) =>
  pipe(getImoSelectedProblem(problemId), get('diagnoses'))(state)

export const getImoModifierGroup = (problemId: string, type: string) => (
  state: any
) =>
  pipe(getImoModifierGroups(problemId), (modifierGroups: List<ModifierGroup>) =>
    modifierGroups.get(
      modifierGroups.findIndex(
        (modifierGroup: ModifierGroup | undefined) =>
          modifierGroup?.type === type
      )
    )
  )(state)

export const getImoModifiers = (problemId: string, type: string) => (
  state: any
) => pipe(getImoModifierGroup(problemId, type), get('modifiers'))(state)
