import { Map, Set } from 'immutable'
import { ERROR } from '~/data/messages'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
import { get, messageCreator, scopedCreator } from '~/utils/data'
import key from '../../key'

export const SELECTED_APPS_KEY = 'selectedAPPs'
export const APP_KEY = 'referral_scheduling'

const transformUserSettings = (data: any) => {
  const { selectedNPs } = data || {}
  return Map({ [SELECTED_APPS_KEY]: Set(selectedNPs) })
}

export const fetchUserSettings = Request({
  typePrefix: key,
  typeBase: 'FETCH_USER_SETTINGS',
  requestParams: ['userId'],
  operation: (userId: any) => AspireAPI.get(`v1/user/${userId}/app/${APP_KEY}`),
  transform: transformUserSettings,
  messages: {
    failed: 'There was an issue fetching user settings',
  },
})

export const saveUserSettings = Request({
  typePrefix: key,
  typeBase: 'SAVE_SELECTED_APPS_USER_SETTINGS',
  requestParams: ['userId', 'selectedAPPs'],
  operation: (userId: any, selectedAPPs: any) =>
    AspireAPI.put(`v1/user/${userId}/app/${APP_KEY}`, {
      selectedNPs: selectedAPPs,
    }),
  messages: {
    succeeded: 'Successfully saved selected APPs',
    failed: 'There was an issue saving user settings',
  },
})

const creator = scopedCreator(key)
export const patientSelected = creator('PATIENT_SELECTED', [
  'patientId',
  'appId',
])
export const patientCleared = creator('PATIENT_CLEARED', false)
export const callLogSnackbarOpened = messageCreator(
  `${key}/CALL_LOG_SNACKBAR_OPEN`,
  'A call is currently being logged for this patient',
  ERROR
)

export const getRoot = get(key)
