import { Map } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import filters from '../../utils/filterDefinitions'
import {
  filtersCleared,
  filtersLoadedFromStorage,
  getPatientFilters,
} from './root'
const VALUES = 'values'
const initState = Map({
  [(filters as any).scheduledFrom.key]: '',
  [(filters as any).scheduledTo.key]: '',
  [(filters as any).ntvdFrom.key]: '',
  [(filters as any).ntvdTo.key]: '',
})
const creator = scopedCreator(rootKey)
export const valueUpdated = creator('VALUE_UPDATED', ['key', 'value'])
export default createReducer(VALUES, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [filtersCleared]: () => initState,
  // @ts-expect-error can we deal with the toString being automatically called?
  [filtersLoadedFromStorage]: (_state: any, { payload: { values } }: any) =>
    Map(values),
  // @ts-expect-error can we deal with the toString being automatically called?
  [valueUpdated]: (state: any, { payload: { key, value } }: any) =>
    state.set(key, value),
})
export const getFilterValues = pipe(getPatientFilters, get(VALUES))
export const getValue = (state: any, key: any) =>
  // @ts-expect-error Object is unknown
  getFilterValues(state).get(key, null)
