import React, { useState } from 'react'
import { useAction } from '~/hooks'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createAssessment } from '../../../../apps/assessment/data/assessments'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: [spacing(2), 0, spacing(1)],
  },
}))

const initState = {
  openedRNAssessment: false,
}
const CreateRNAssessment = formContext => {
  const classes = useStyles()
  const [state, setState] = useState(initState)
  const disableAction = state.openedRNAssessment
  const type = 'rn'
  const patientId = formContext.rNAssessmentButton.task.patientId
  const userId = formContext.rNAssessmentButton.task.ownerId

  const requestCreateAssessment = useAction(createAssessment.requested)
  const createRNAssessment = () => {
    requestCreateAssessment(patientId, userId, type)
    setState({
      ...state,
      openedRNAssessment: true,
    })
  }

  return (
    <div className={classes.container}>
      <Button
        disabled={disableAction}
        size="small"
        variant="contained"
        onClick={createRNAssessment}
      >
        Create RN Assessment
      </Button>
    </div>
  )
}

export default CreateRNAssessment
