import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { getUserId } from '~/data/session'
import { getUserById, getUsers } from '~/data/users'
import { useAction, useMountEffect, useUpdateEffect } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Dialog } from '@material-ui/core'
import {
  calendarUnmounted,
  fetchUserClaims,
  getDate,
  getTimeZone,
  timeZoneSet,
} from '../data/calendar'
import {
  eventsFetchRequested,
  getCreating,
  getCurrentEvent,
} from '../data/events'
import Calendar from './Calendar'
import { EventDialog, NewEventDialog } from './Events'

const CALENDAR = 'calendar'
const TYPE_SELECTOR = 'type_selector'
const CREATING_EVENT = 'creating_event'
const EDITING_EVENT = 'editing_event'

const selectCalendarDialogView = (creating, event) => {
  if (!creating && !event) {
    return CALENDAR
  } else if (creating && !event) {
    return TYPE_SELECTOR
  } else if (creating && event) {
    return CREATING_EVENT
  } else {
    return EDITING_EVENT
  }
}

const CalendarWidget = ({
  editing,
  hideAdd,
  viewLocked,
  defaultView,
  dayHeader,
  dayWrapper,
  width,
  ...props
}) => {
  const userId = useSelector(state => props.userId || getUserId(state))
  const users = useSelector(getUsers)
  const user = useSelector(state => getUserById(state, userId))
  const date = useSelector(getDate)
  const timeZone = useSelector(getTimeZone)
  const creating = useSelector(getCreating)
  const event = useSelector(getCurrentEvent)

  const dialogView = selectCalendarDialogView(creating, event)

  const getEvents = useAction(eventsFetchRequested)
  const getUserClaims = useAction(fetchUserClaims.requested)
  const setTimeZone = useAction(timeZoneSet)
  const unmountCalendar = useAction(calendarUnmounted)

  const timeZoneRef = useRef(timeZone)

  useMountEffect(() => {
    getEvents(userId, date)
    getUserClaims()

    if (user.timeZone) {
      setTimeZone(user.timeZone)
      timeZoneRef.current = user.timeZone
    }
  }, unmountCalendar)

  useUpdateEffect(() => {
    getEvents(userId, date)
  }, [userId, date])

  useUpdateEffect(() => {
    getUserClaims(userId)
  }, [userId])

  useUpdateEffect(() => {
    if (user.timeZone && user.timeZone !== timeZoneRef.current) {
      setTimeZone(user.timeZone)
      timeZoneRef.current = user.timeZone
    }
  }, [user, users])

  return (
    <React.Fragment>
      <Calendar
        viewLocked={viewLocked}
        defaultView={defaultView}
        hideAdd={!editing || hideAdd}
        dayHeader={dayHeader}
        dayWrapper={dayWrapper}
        width={width}
      />
      <Dialog
        open={dialogView !== CALENDAR}
        maxWidth={dialogView === TYPE_SELECTOR ? 'sm' : 'md'}
        fullWidth
      >
        {dialogView === TYPE_SELECTOR && <NewEventDialog userId={userId} />}
        {dialogView === CREATING_EVENT && (
          <EventDialog userId={userId} creating={true} editing={editing} />
        )}
        {dialogView === EDITING_EVENT && (
          <EventDialog userId={userId} creating={false} editing={editing} />
        )}
      </Dialog>
    </React.Fragment>
  )
}

CalendarWidget.propTypes = {
  userId: PropTypes.string,
  editing: PropTypes.bool,
  hideAdd: PropTypes.bool,
  viewLocked: PropTypes.bool,
  defaultView: PropTypes.string,
  dayHeader: PropTypes.elementType,
  dayWrapper: PropTypes.elementType,
  width: PropTypes.number,
}

export default CalendarWidget
