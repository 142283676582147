import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, mergeMap, mergeMapTo, pluck } from 'rxjs/operators'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { QUICK_CALL_LOG, getRoot, typePrefixCreator } from './common/shared'
import { fetchConfig } from './config'
import { contactsCleared } from './contacts'
import { pcpCleared } from './pcp'
import { stakeholdersCleared } from './stakeholders'

// KEY
const DIALOG = 'dialog'

// TYPES
const typePrefix = typePrefixCreator(DIALOG)
export const DIALOG_OPENED = type(typePrefix, 'DIALOG_OPENED')
export const DIALOG_CLOSED = type(typePrefix, 'DIALOG_CLOSED')
export const QUICK_CALL_LOG_DIALOG_OPENED = type(
  typePrefix,
  'QUICK_CALL_LOG_DIALOG_OPENED'
)
export const QUICK_CALL_LOG_DIALOG_CLOSED = type(
  typePrefix,
  'QUICK_CALL_LOG_DIALOG_CLOSED'
)

// CREATORS
export const dialogOpened = creator(DIALOG_OPENED, 'type')
export const dialogClosed = creator(DIALOG_CLOSED)
export const quickCallLogDialogOpened = creator(
  QUICK_CALL_LOG_DIALOG_OPENED,
  'patientId'
)
export const quickCallLogDialogClosed = creator(QUICK_CALL_LOG_DIALOG_CLOSED)

// REDUCER
export default createReducer(DIALOG, null, {
  [DIALOG_OPENED]: (_state, { payload: { type } }) => type,
  [DIALOG_CLOSED]: () => null,
})

// SELECTORS
export const getDialogState = compose(get(DIALOG), getRoot)

// EPICS
const dialogOpenedEpic = action$ =>
  action$.pipe(
    ofType(QUICK_CALL_LOG_DIALOG_OPENED),
    pluck('payload', 'patientId'),
    filter(Boolean),
    mergeMap(patientId =>
      of(fetchConfig.requested(patientId), dialogOpened(QUICK_CALL_LOG))
    )
  )

const dialogClosedEpic = action$ =>
  action$.pipe(
    ofType(QUICK_CALL_LOG_DIALOG_CLOSED),
    mergeMapTo(
      of(contactsCleared(), pcpCleared(), stakeholdersCleared(), dialogClosed())
    )
  )

export const epic = combineEpics(dialogOpenedEpic, dialogClosedEpic)
