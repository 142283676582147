import { Redirect, Route, Router, Scope } from 'redux-routable'
import key from './key'

export const IOC_DASHBOARD_ENCOUNTER_REVIEWS = `${key}.encounterReviews`
export const IOC_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const IOC_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const IOC_DASHBOARD_PATIENT_SEARCH = `${key}.patientSearch`
export const IOC_DASHBOARD_CALL_LIST = `${key}.callList`
export const IOC_DASHBOARD_CALENDAR = `${key}.calendar`
export const IOC_DASHBOARD_APP_FINDER = `${key}.appFinder`
export const IOC_DASHBOARD_USER_TIMELINE = `${key}.userTimeline`

export const tasksRouter = Router([
  Route(IOC_DASHBOARD_TASKS_BY_USER, '/'),
  Route(IOC_DASHBOARD_TASK_DETAIL, '/:taskId'),
])

export default Router([
  Route(IOC_DASHBOARD_ENCOUNTER_REVIEWS, '/:userId?/encounter-reviews'),
  Scope('/:userId?/tasks', tasksRouter),
  Route(IOC_DASHBOARD_PATIENT_SEARCH, '/:userId?/patient-search'),
  Route(IOC_DASHBOARD_CALL_LIST, '/:userId?/call-list'),
  Route(IOC_DASHBOARD_CALENDAR, '/:userId?/calendar'),
  Route(IOC_DASHBOARD_APP_FINDER, '/:userId?/app-finder'),
  Route(IOC_DASHBOARD_USER_TIMELINE, '/:userId?/user-timeline'),
  Redirect(IOC_DASHBOARD_ENCOUNTER_REVIEWS),
])
