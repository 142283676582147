import moment from 'moment'
import { ofType } from 'redux-observable'
import { filter, ignoreElements, pluck, tap } from 'rxjs/operators'
import { LOCAL_ASSESSMENT, setItem } from '~/utils/storage'
import { saveAssessment } from '../data/assessments'

const getUTCISOString = () =>
  moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS')

const convertAssessment = assessment =>
  assessment.set('modifiedAt', getUTCISOString()).toJSON()

export default action$ =>
  action$.pipe(
    ofType(saveAssessment.REQUESTED),
    pluck('payload', 'assessment'),
    filter(Boolean),
    tap(assessment => {
      setItem(
        `${LOCAL_ASSESSMENT}-${assessment.id}`,
        convertAssessment(assessment)
      )
    }),
    ignoreElements()
  )
