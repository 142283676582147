import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ breakpoints, palette, spacing }) => ({
  title: {
    color: palette.primary.main,
    marginBottom: spacing(2),
  },
  placeholder: {
    display: 'block',
    height: spacing(6),
  },
  [breakpoints.down('xs')]: {
    placeholder: {
      display: 'inline',
    },
  },
})

const Section = ({ className, classes, children, title }) => (
  <div className={className}>
    {title ? (
      <Typography variant="h5" color="textSecondary" className={classes.title}>
        {title}
      </Typography>
    ) : (
      <span className={classes.placeholder} />
    )}
    {children}
  </div>
)

Section.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default withStyles(styles)(Section)
