import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import {
  UNMERGE_CONFIRMED,
  getUnmergeOdsAdmissionIdsForDialog,
  submitOdsAdmissionUnmerge,
  unmergeSaveFailed,
  unmergeSaved,
} from '../data/admissionsReconciliation'
import { unmergeDialogOdsAdmissionIdCleared } from '../data/unmergeDialogOdsAdmissionId'
import { unmergeOdsAdmissionIdsCleared } from '../data/unmergeOdsAdmissionIds'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(UNMERGE_CONFIRMED),
    mergeMap(() => {
      const unmergeOdsAdmissionIds = getUnmergeOdsAdmissionIdsForDialog(
        state$.value
      )

      return concat(
        from(submitOdsAdmissionUnmerge(unmergeOdsAdmissionIds)).pipe(
          map(unmergeSaved)
        ),
        of(unmergeOdsAdmissionIdsCleared(unmergeOdsAdmissionIds)),
        of(unmergeDialogOdsAdmissionIdCleared()),
        catchError(e => {
          telemetry.error(e)
          return of(unmergeSaveFailed())
        })
      )
    })
  )
