import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { fetchLTCs } from '~/features/ltcs'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_HISTORY_ADMITS } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_HISTORY_ADMITS)),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId => of(fetchLTCs.requested(patientId)))
  )
