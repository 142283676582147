import { ofType } from 'redux-observable'
import { from, merge } from 'rxjs'
import { catchError, filter, map, mergeMap, pluck } from 'rxjs/operators'
import { getUserId } from '~/data/session'
import { showError } from '~/utils/alertOnError'
import { getChannelStream, pushToChannel } from '~/utils/channels'
import { switchTo } from '~/utils/operators'
import { CHANNEL_JOINED, HIGH } from '../data/common/shared'
import { highPrioritySnackbarOpened } from '../data/highPrioritySnackbarOpened'
import {
  mapNotificationToRecord,
  notificationAdded,
  notificationMarkedUnread,
  notificationsCleared,
  notificationsFetched,
  transformChannelData,
} from '../data/notifications'
import getChannelName from '../utils/getChannelName'

export default (action$, state$) =>
  action$.pipe(
    ofType(CHANNEL_JOINED),
    switchTo(state$),
    mergeMap(state => {
      const userId = getUserId(state)
      const channelName = getChannelName(userId)

      return merge(
        from(pushToChannel(channelName, 'get_new_notifications')).pipe(
          map(transformChannelData),
          map(notificationsFetched),
          catchError(showError('Unable to fetch notifications'))
        ),
        getChannelStream(channelName, 'new_notification').pipe(
          map(mapNotificationToRecord),
          map(notificationAdded)
        ),
        getChannelStream(channelName, 'new_notification').pipe(
          filter(data => data.priority === HIGH),
          map(highPrioritySnackbarOpened)
        ),
        getChannelStream(channelName, 'clear_notifications').pipe(
          pluck('notificationIds'),
          map(notificationsCleared)
        ),
        getChannelStream(channelName, 'mark_notification_unread').pipe(
          map(({ notification: [data] }) => data),
          map(mapNotificationToRecord),
          map(notificationMarkedUnread)
        )
      )
    })
  )
