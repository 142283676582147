import { List, Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { getCurrentPatientId } from './currentPatientId'

const PATIENT_ALERTS = 'patientAlerts'

const PatientAlert = Record({
  patientId: null,
  description: null,
})

const transformPatientAlerts = alerts =>
  alerts.reduce(
    (map, alert) =>
      map.update(alert.patientId, List(), patientAlerts =>
        patientAlerts.push(PatientAlert(alert))
      ),
    Map()
  )

export const fetchPatientAlerts = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_ALERTS',
  requestParams: ['appId'],
  operation: (appId, cancelToken) =>
    AspireAPI.get(`np_scheduling/patient_alerts/${appId}`, { cancelToken }),
  transform: transformPatientAlerts,
  messages: {
    failed: 'There was an issue fetching patient alerts',
  },
})

const initState = Map()
export default createReducer(PATIENT_ALERTS, initState, {
  [fetchPatientAlerts.REQUESTED]: () => initState,
  [fetchPatientAlerts.SUCCEEDED]: (_state, { payload }) => payload,
})

const getAllPatientAlerts = pipe(getRoot, get(PATIENT_ALERTS))

export const getPatientAlerts = state => {
  const patientId = getCurrentPatientId(state)
  return getAllPatientAlerts(state).get(patientId, List())
}
