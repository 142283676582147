import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getPatientRecord } from './common/shared'

const OUTLIER_REVIEW_HISTORY = 'outlierReviewHistory'

const User = Record({
  id: null,
  name: null,
})

const PatientOutlierFinalDecision = Record({
  id: null,
  status: null,
  completedAt: null,
})

const PatientOutlierReview = Record({
  id: null,
  status: null,
  createdAt: null,
  physician: User(),
  patientOutlierFinalDecision: PatientOutlierFinalDecision(),
  patientOutlierReviewFormData: {},
  patientOutlierFinalDecisionFormData: {},
})

const transformPatientOutlierReview = ({
  physician,
  patientOutlierFinalDecision,
  patientOutlierReviewFormData,
  patientOutlierFinalDecisionFormData,
  ...review
}) =>
  PatientOutlierReview({
    ...review,
    physician: User(physician),
    patientOutlierFinalDecision: PatientOutlierFinalDecision(
      patientOutlierFinalDecision
    ),
    patientOutlierReviewFormData: patientOutlierReviewFormData || {},
    patientOutlierFinalDecisionFormData:
      patientOutlierFinalDecisionFormData || {},
  })

const transformPatientOutlierReviews = reviews =>
  List(reviews.map(transformPatientOutlierReview))

export const fetchPatientOutlierReviewHistory = Request({
  typePrefix: OUTLIER_REVIEW_HISTORY,
  typeBase: 'FETCH_PATIENT_OUTLIER_REVIEW_HISTORY',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`patient_outliers/reviews/patient/${patientId}`),
  transform: transformPatientOutlierReviews,
  messages: {
    failed: 'There was a problem fetching the outlier review history',
  },
})

export default createReducer(OUTLIER_REVIEW_HISTORY, List(), {
  [fetchPatientOutlierReviewHistory.REQUESTED]: () => List(),
  [fetchPatientOutlierReviewHistory.SUCCEEDED]: (_state, { payload }) =>
    payload,
})

export const getOutlierReviews = pipe(
  getPatientRecord,
  get(OUTLIER_REVIEW_HISTORY)
)

export const getOutlierReviewsArray = state =>
  getOutlierReviews(state).toArray()
