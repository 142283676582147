import React from 'react'
import { formatDate } from '~/utils/dateFnDates'

type Props = {
  value: string | Date
}

export default function DisplayDate({ value }: Props) {
  return <span>{value && formatDate(value)}</span>
}
