import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { addBenefit, updateBenefit } from '../data/addBenefitDialog'
import { uploadBenefit } from '../data/benefitUploadDialog'
import { fetchBenefits } from '../data/benefits'
import { CURRENT_YEAR } from '../data/constants'
import { deleteBenefit } from '../data/deleteBenefit'

export const addBenefitSuccess = (action$: any) =>
  action$.pipe(
    ofType(addBenefit.SUCCEEDED),
    map(() => fetchBenefits.requested(CURRENT_YEAR, null, null))
  )

export const updateBenefitSuccess = (action$: any) =>
  action$.pipe(
    ofType(updateBenefit.SUCCEEDED),
    map(() => fetchBenefits.requested(CURRENT_YEAR, null, null))
  )

export const uploadBenefitSuccess = (action$: any) =>
  action$.pipe(
    ofType(uploadBenefit.SUCCEEDED),
    map(() => fetchBenefits.requested(CURRENT_YEAR, null, null))
  )

export const refreshBenefitDelete = (action$: any) =>
  action$.pipe(
    ofType(deleteBenefit.SUCCEEDED),
    map(() => fetchBenefits.requested(CURRENT_YEAR, null, null))
  )
