import { Set } from 'immutable'
import { createSelector } from 'reselect'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

const key = 'popupMessages'

export const MESSAGE_CREATED = 'GLOBAL/MESSAGE_CREATED'
export const MESSAGE_DISMISSED = 'GLOBAL/MESSAGE_DISMISSED'

export const addMessage = (message: any) => ({
  type: MESSAGE_CREATED,
  message,
})
export const deleteMessage = (message: any) => ({
  type: MESSAGE_DISMISSED,
  message,
})

// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
export default createReducer(key, new Set(), {
  [MESSAGE_CREATED]: (state: any, { message }: any) => state.add(message),
  [MESSAGE_DISMISSED]: (state: any, { message }: any) => state.delete(message),
})

export const getPopupMessages = createSelector(
  [state => getReferralManagement(state).get(key)],
  errorMessage => errorMessage
)
