import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import {
  Dialog,
  getHospitalizationDialog,
  modeSet,
} from '../data/hospitalizationDialog'
import {
  addHospitalization,
  saveHospitalization,
} from '../data/hospitalizations'
import DeleteHospitalization from './DeleteHospitalization'
import HospitalizationForm from './HospitalizationForm'

const HospitalizationModal = ({
  hospitalizationDialog: { mode, patientId, selectedHospitalization },
  ...props
}) => {
  const onAdd = useCallback(
    hospitalization => props.onAdd(patientId, hospitalization),
    [props.onAdd, patientId]
  )
  const onSave = useCallback(hospitalization => props.onSave(hospitalization), [
    props.onSave,
  ])
  const onClose = useCallback(() => props.modeSet(null), [props.modeSet])

  switch (mode) {
    case 'add': {
      return (
        <HospitalizationForm
          form="hospitalization.new"
          mode="add"
          onSubmit={onAdd}
          onClose={onClose}
        />
      )
    }
    case 'edit':
      return (
        <HospitalizationForm
          form="hospitalization.edit"
          mode="save"
          hospitalization={selectedHospitalization}
          onSubmit={onSave}
          onClose={onClose}
        />
      )
    case 'delete':
      return (
        <DeleteHospitalization
          row={selectedHospitalization}
          onClose={onClose}
        />
      )
    default:
      return null
  }
}

HospitalizationModal.propTypes = {
  hospitalizationDialog: PropTypes.instanceOf(Dialog).isRequired,
  modeSet: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default connect(
  state => ({
    hospitalizationDialog: getHospitalizationDialog(state),
  }),
  {
    modeSet,
    onAdd: addHospitalization.requested,
    onSave: saveHospitalization.requested,
  }
)(HospitalizationModal)
