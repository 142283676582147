// Program
export const PROGRAM_LABELS = [
  'BH',
  'CKD/ESRD-CM',
  'CKD/ESRD-One DMP',
  'COPD-CM',
  'COPD-One DMP',
  'Diabetes-CM',
  'Diabetes-One DMP',
  'ECM-Like',
  'Heart Failure-CM',
  'Heart Failure-One DMP',
  'Palliative',
  'RPM',
  'SNPCCM',
  'SNPTCM',
]

export const PROGRAM_COMPLETE_REASONS = [
  'Complete: Goals Met',
  'Identified Issues Addressed',
  'No Issues Identified',
]

export const PROGRAM_CLOSE_REASONS = [
  'Created in Error',
  'No Longer a Patient',
  'No Longer Meets Criteria',
  'Patient Deceased',
  'Patient Requests Do Not Call',
  'Referred to Outside Program',
  'Refused-Not Interested',
  'Transferred to Hospice',
  'Unable to Reach',
]

const PROGRAM_COMPLETED = 'completed'
const CREATED = 'created'
export const CLOSED = 'closed'

const PROGRAM_ACTIVE_STATUSES = [CREATED]
const PROGRAM_INACTIVE_STATUSES = [CLOSED, PROGRAM_COMPLETED]

export const isProgramActive = status =>
  PROGRAM_ACTIVE_STATUSES.includes(status)
export const isProgramInactive = status =>
  PROGRAM_INACTIVE_STATUSES.includes(status)

// Statuses
const IN_PROGRESS = 'in_progress'
const REASSIGNED = 'reassigned'

export const CANCELLED = 'cancelled'
export const SNOOZED = 'snoozed'

const ACTIVE_STATUSES = [CREATED, IN_PROGRESS, REASSIGNED]
const INACTIVE_STATUSES = [CLOSED, CANCELLED, SNOOZED]
const VALID_CARE_TEAM_ROLES = ['pcc_rn', 'pcc_cc', 'field_sw']

export const isActive = status => ACTIVE_STATUSES.includes(status)
export const isInactive = status => INACTIVE_STATUSES.includes(status)

export const getCareTeamMemberFieldValues = (
  careTeamMembers,
  shouldFilter,
  user
) => {
  let fieldValues = careTeamMembers
    .filter(({ role }) => !shouldFilter || VALID_CARE_TEAM_ROLES.includes(role))
    .groupBy(({ id }) => id)
    .map(users => {
      // Some users have multiple roles on a care team
      const roles = users.map(({ roleLabel }) => roleLabel).join(', ')

      return users.first().update('name', name => `${name} - ${roles}`)
    })
    .toList()

  // Add current user if not already present
  if (!fieldValues.some(({ id }) => id === user.id)) {
    fieldValues = fieldValues.push(user)
  }

  return fieldValues
    .sortBy(({ name }) => name)
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
}
