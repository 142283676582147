import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import { fetchInsuranceCoverages } from '../data/insuranceCoverages'
import { checkInsuranceValidity } from '../data/validation'

export default action$ =>
  action$.pipe(
    ofType(fetchInsuranceCoverages.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'patientId'),
    mergeMap(id => of(checkInsuranceValidity.requested(id)))
  )
