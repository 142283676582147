import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const FORMS_DEVELOPMENT_FORM = `${key}.form`
export const FORMS_DEVELOPMENT_HOME = `${key}.home`

export default Router([
  Route(FORMS_DEVELOPMENT_HOME, '/'),
  Route(FORMS_DEVELOPMENT_FORM, '/:entity/:templateKey'),
  Redirect(FORMS_DEVELOPMENT_HOME),
])
