import React from 'react'
import { Location, LocationSearch } from '~/features/locationSearch'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import { locationChanged, locationPendingChanged } from '../../data/events'
import BaseField from './BaseField'
import TextField from './TextField'

const useStyles = makeStyles({
  location: {
    width: '100%',
  },
})

const LocationField = props => {
  const { value, editing, travel = false } = props

  const classes = useStyles()

  const onLocationChange = useAction(locationChanged)
  const onLocationPendingChange = useAction(locationPendingChanged)

  const onApartmentChange = newApartmentValue => {
    const apartment = newApartmentValue === '' ? null : newApartmentValue
    onLocationChange(value.set('apartment', apartment))
  }

  return (
    <React.Fragment>
      <BaseField {...props} label={travel ? 'Travel Location' : 'Location'}>
        <div className={classes.location}>
          <LocationSearch
            disabled={!editing}
            location={value}
            onChange={location => {
              onLocationChange(location)
            }}
            onTextChange={() => onLocationPendingChange(true)}
          />
        </div>
      </BaseField>

      {value.address && (
        <TextField
          label="Apartment/Suite"
          indent={1}
          value={value.apartment}
          editing={editing}
          onChange={onApartmentChange}
        />
      )}
    </React.Fragment>
  )
}

LocationField.propTypes = {
  editing: PropTypes.bool,
  travel: PropTypes.bool,
  value: PropTypes.instanceOf(Location).isRequired,
}

export default LocationField
