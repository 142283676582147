import { exited } from 'redux-routable'
import { filter, mapTo } from 'rxjs/operators'
import { CODER_DASHBOARD_HOME } from '../router'
import { codingQueueStateCleared } from '../data/codingQueue'

export default (action$: any) =>
  action$.pipe(
    filter(exited(CODER_DASHBOARD_HOME)),
    mapTo(codingQueueStateCleared())
  )
