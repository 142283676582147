import { zonedTimeToUtc } from 'date-fns-tz/fp'
import { addDays, format, setHours } from 'date-fns/fp'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { DateField } from '~/components/fields'
import { getUserById } from '~/data/users'
import { useAction } from '~/hooks'
import { pipe } from '~/utils/functionalHelpers'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { snoozePatient } from '../data/common/shared'
import { getCurrentAPPId } from '../data/currentAPPId'
import { getSnoozeDialog, snoozeDialogClosed } from '../data/snoozeDialog'

const initSnoozeDate = pipe(addDays(1), format('yyyy-MM-dd'))(Date.now())

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    display: 'block',
    marginBottom: spacing(2),
  },
  snoozeDateContainer: {
    display: 'flex',
    alignItems: 'baseline',
    whiteSpace: 'nowrap',
  },
  snoozeDate: {
    marginRight: spacing(1),
  },
}))

const SnoozePatientDialog = () => {
  const classes = useStyles()

  const snoozeDialog = useSelector(getSnoozeDialog)
  const currentAPPId = useSelector(getCurrentAPPId)
  const app = useSelector(state => getUserById(state, currentAPPId))
  const snoozePatientRequested = useAction(snoozePatient.requested)
  const closeSnoozeDialog = useAction(snoozeDialogClosed)

  const [snoozeDate, setSnoozeDate] = useState(initSnoozeDate)

  const onSave = () => {
    // Set to APP's 8AM
    const utcSnoozeDate = pipe(
      setHours(8),
      zonedTimeToUtc(app.timeZone)
    )(new Date(`${snoozeDate} 00:00:00`)).toISOString()

    snoozePatientRequested(snoozeDialog.patientId, utcSnoozeDate)

    onClose()
  }

  const onClose = () => {
    setSnoozeDate(initSnoozeDate)
    closeSnoozeDialog()
  }

  return snoozeDialog.open ? (
    <ActionDialog
      open={snoozeDialog.open}
      title={`Snooze ${snoozeDialog.patientName}`}
      mode="save"
      onSave={onSave}
      onClose={onClose}
      disableAction={!snoozeDate}
      maxWidth="sm"
      fullWidth
    >
      <div className={classes.textField}>
        <div className={classes.snoozeDateContainer}>
          <DateField
            className={classes.snoozeDate}
            required
            error={!snoozeDate}
            fullWidth
            label="Snooze Date"
            value={snoozeDate}
            onChange={value => {
              setSnoozeDate(value)
            }}
          />

          <Typography>{`(8AM APP's local time)`}</Typography>
        </div>
      </div>
    </ActionDialog>
  ) : null
}

export default SnoozePatientDialog
