import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { getSelectedDashApp } from '~/apps/appDashboard/data/dashAppId'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import {
  fetchEncounterQueriesForWorklist,
  sendQueryResponse,
} from '../data/codingQueries'

const querySent = (action$: any, state$: any) =>
  action$.pipe(
    ofType(sendQueryResponse.SUCCEEDED),
    switchTo(state$),
    map(getSelectedDashApp),
    map((userId: string) => fetchEncounterQueriesForWorklist.requested(userId))
  )

export default querySent
