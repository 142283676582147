import { Record } from 'immutable'
import { changeFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRPM } from './root'

const RMP_FORM = 'rpmForm'

const creator = scopedCreator(rootKey)
export const rpmFormUpdated: any = creator('RPM_FORM_UPDATED')
export const rpmFormSubmitted = creator('RPM_FORM_SUBMITTED')
export const rpmFormErrored: any = creator('RPM_FORM_ERRORED')
export const rpmFormCleared: any = creator('RPM_FORM_CLEARED')

export const RpmForm = Record({
  context: {},
  errored: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
})

const transformRpmForm = (form: any) =>
  changeFormData(RpmForm(form), null, form.formData)

export const fetchRpmForm = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_RPM',
  requestParams: [],
  operation: () => AspireAPI.get(`patient_record/rpm`),
  transform: transformRpmForm,
  messages: { failed: 'Failed to Start New RPM' },
})

export const createRpmForm = Request({
  typePrefix: rootKey,
  typeBase: 'SUBMIT_RPM',
  requestParams: ['patientId', 'form', 'selected'],
  operation: (form: any) =>
    AspireAPI.post(`patient_record/rpm`, {
      form,
    }),
  messages: {
    failed: (e: any) => {
      return e.response.data.message || 'Failed to save RPM'
    },
    succeeded: () => 'RPM Saved',
  },
})

const initState = RpmForm()
export default createReducer(RMP_FORM, initState, {
  [fetchRpmForm.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [rpmFormUpdated]: (state: any, { payload: { errors, formData } }: any) =>
    changeFormData(state, null, formData).set(
      'errored',
      Boolean(errors.length)
    ),
  [rpmFormErrored]: (state: any, { payload }: any) =>
    state.set('errored', payload),
  [rpmFormCleared]: () => initState,
})

export const getCreateRpmForm = pipe(getRPM, get(RMP_FORM))
