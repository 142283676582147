import { changedTo } from 'redux-routable'
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  withLatestFrom,
} from 'rxjs/operators'
import { getCurrentUserId } from '../data/currentUserId'
import { fetchPatientList } from '../data/patientList'
import { referralSchedulingRouter } from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(changedTo(referralSchedulingRouter)),
    pluck('payload', 'params', 'appId'),
    distinctUntilChanged(),
    withLatestFrom(state$),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([appId, state]: [any, any]) => ... Remove this comment to see the full error message
    map(([appId, state]) => {
      const userId = getCurrentUserId(state)

      return fetchPatientList.requested(userId, [appId])
    })
  )
