import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
import {
  panelClosed,
  patientTransportationPanelOpened,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
} from '~/data/patientPanel'
import { scopedCreator } from '~/utils/data'
import {
  fetchPatientDemographics,
  patientInfoCleared,
} from '../data/patientInfo'
const key = 'patientInfo'

export const searchClearRequested = scopedCreator(key)('SEARCH_CLEAR_REQUESTED')

export const patientDemoRequested = scopedCreator(
  key
)('PATIENT_PANEL_DATA_REQUESTED', ['id'])

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const loadPatientDemographics = action$ =>
  action$.pipe(
    ofType(patientDemoRequested),
    pluck('payload', 'id'),
    mergeMap((id: string) => {
      if (id) {
        return [patientInfoCleared(), fetchPatientDemographics.requested(id)]
      } else {
        return [patientInfoCleared()]
      }
    })
  )

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const clearSearch = action$ =>
  action$.pipe(
    ofType(searchClearRequested),
    pluck('payload'),
    mergeMap(() => {
      return [patientTransportationPanelOpened(''), panelClosed()]
    })
  )
