import { Record } from 'immutable'
import { transformLastCarePlan } from '~/features/problems/data'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const PATIENT_OUTLIER_PANEL = 'patientOutlierPanel'

const PatientOutlierPanel = Record({
  patientId: null,
  patientName: null,
  status: null,
  substatus: null,
  primaryDiagnosis: null,
  primaryDiagnosisOther: null,
  riskLevel: null,
  prognosis: null,
  hospiceEligible: null,
  codeStatus: null,
  codeStatusOther: null,
  uploadedAcpDocuments: null,
  carePlan: null,
  appName: null,
  cdName: null,
  healthplan: null,
  primaryContract: null,
  lastHospiceDiscussionDate: null,
  hospitalizationTwelveMonthCount: null,
  onCallCount: null,
  palliativeVisitCount: null,
  socialWorkerVisitCount: null,
  socialWorkerTaskCount: null,
  hospitalizationNinetyDayCount: null,
  cmrnTaskCount: null,
  cmrnCallCount: null,
  orderCount: null,
  medicationCount: null,
  costInformation: null,
  outlierStartDate: null,
})

const creator = scopedCreator(rootKey)
export const patientOutlierPanelCleared = creator(
  'PATIENT_OUTLIER_PANEL_CLEARED'
)

const transformPatientOutlierPanel = ({
  carePlan,
  costInformation,
  ...panelInfo
}: any) =>
  PatientOutlierPanel({
    carePlan: transformLastCarePlan(carePlan),
    costInformation: costInformation || {},
    ...panelInfo,
  })

export const fetchPatientOutlierPanel = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_OUTLIER_PANEL',
  requestParams: ['reviewId'],
  operation: (reviewId: any) =>
    AspireAPI.get(`patient_outliers/panel/${reviewId}`),
  transform: transformPatientOutlierPanel,
  messages: {
    failed: 'There was an issue fetching the patient outlier panel',
  },
})

const initState = PatientOutlierPanel()

export default createReducer(PATIENT_OUTLIER_PANEL, initState, {
  [fetchPatientOutlierPanel.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientOutlierPanelCleared]: () => initState,
})

export const getPatientOutlierPanel = pipe(getRoot, get(PATIENT_OUTLIER_PANEL))
