import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'

export const Document = Record({
  id: null,
  fileType: null,
  fileName: null,
  docContent: null,
})

const key = 'fetchedDocument'

export const getAttachmentDocumentById = Request({
  typePrefix: key,
  typeBase: 'FETCH_NEXTGEN_ATTACHMENT_DOCUMENT',
  requestParams: ['documentId', 'docCode'],
  operation: (id, docCode) =>
    AspireAPI.get(`/v1/attachments/nextgen_document/${id}`, {
      params: { doc_code: docCode },
    }),
  transform: Document,
  messages: {
    failed: 'There was a problem fetching Nextgen attachment document',
  },
})
