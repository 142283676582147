import { Map } from 'immutable'
import React, { useState } from 'react'
import { useUpdateEffect } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { DropDownField, NumberField, TextLongField } from '../../../EventFields'
import conditions from './conditions'
import values from './values'

const fields = {
  rescheduleType: {
    isActive: conditions.alwaysTrue,
    isRequired: conditions.alwaysTrue,
  },
  rescheduledBy: {
    isActive: conditions.anyType,
    isRequired: conditions.anyType,
  },
  rescheduleReason: {
    isActive: conditions.anyDriven,
    isRequired: conditions.anyDriven,
  },
  otherRescheduleReason: {
    isActive: conditions.otherRescheduleReason,
    isRequired: conditions.otherRescheduleReason,
  },
  refusedServiceReason: {
    isActive: conditions.anyRefusal,
    isRequired: conditions.anyRefusal,
  },
  otherRefusedServiceReason: {
    isActive: conditions.otherRefusedServiceReason,
    isRequired: conditions.otherRefusedServiceReason,
  },
  rescheduleNotes: {
    isActive: conditions.alwaysTrue,
    isRequired: conditions.alwaysTrue,
  },
  mileage: {
    isActive: conditions.unsuccessfulVisit,
    isRequired: conditions.alwaysFalse,
  },
  parkingFees: {
    isActive: conditions.unsuccessfulVisit,
    isRequired: conditions.alwaysFalse,
  },
  tollFees: {
    isActive: conditions.unsuccessfulVisit,
    isRequired: conditions.alwaysFalse,
  },
  unsuccessfulVisit: {
    isActive: conditions.alwaysTrue,
    isRequired: conditions.alwaysTrue,
  },
}

const clearInactiveField = (state, key, setValue) => {
  const fieldValues = values[key] || Map()
  const fieldActive = fields[key].isActive
  const valueActive = fieldValues.getIn([state[key], 'isActive'], () => true)

  if (!(fieldActive(state) && valueActive(state)) && state[key]) {
    setValue(null)
  }
}

const RescheduleDialog = ({ open, onClose, onExited, ...props }) => {
  const [rescheduleType, setRescheduleType] = useState(null)
  const [rescheduledBy, setRescheduledBy] = useState(null)
  const [rescheduleReason, setRescheduleReason] = useState(null)
  const [otherRescheduleReason, setOtherRescheduleReason] = useState(null)
  const [refusedServiceReason, setRefusedServiceReason] = useState(null)
  const [otherRefusedServiceReason, setOtherRefusedServiceReason] = useState(
    null
  )
  const [rescheduleNotes, setRescheduleNotes] = useState(null)
  const [mileage, setMileage] = useState(null)
  const [parkingFees, setParkingFees] = useState(null)
  const [tollFees, setTollFees] = useState(null)
  const [unsuccessfulVisit, setUnsuccessfulVisit] = useState(null)

  const state = {
    rescheduleType,
    rescheduledBy,
    rescheduleReason,
    otherRescheduleReason,
    refusedServiceReason,
    otherRefusedServiceReason,
    rescheduleNotes,
    mileage,
    parkingFees,
    tollFees,
    unsuccessfulVisit,
  }

  const fieldValid = key =>
    !(
      fields[key].isRequired(state) &&
      (state[key] === null || state[key] === '')
    )

  const fieldError = key => !fieldValid(key)

  const filterFieldValues = values =>
    values.filter(value => value.get('isActive')(state))

  const formValid = Object.keys(fields).reduce(
    (valid, key) => valid && fieldValid(key),
    true
  )

  const onRescheduleVisit = () => {
    props.onRescheduleVisit(state)
    onClose()
  }

  const onRescheduleTypeChange = value => {
    setRescheduleType(value)

    const unsuccessfulVisit = conditions.unsuccessfulVisit({
      ...state,
      rescheduleType: value,
    })

    if (unsuccessfulVisit) {
      const rescheduledByValue = values.rescheduledBy.getIn([
        'Patient Driven',
        'text',
      ])

      setRescheduledBy(rescheduledByValue)
    }

    setUnsuccessfulVisit(unsuccessfulVisit)
  }

  useUpdateEffect(() => {
    clearInactiveField(state, 'rescheduleType', setRescheduleType)
    clearInactiveField(state, 'rescheduledBy', setRescheduledBy)
    clearInactiveField(state, 'rescheduleReason', setRescheduleReason)
    clearInactiveField(state, 'otherRescheduleReason', setOtherRescheduleReason)
    clearInactiveField(state, 'refusedServiceReason', setRefusedServiceReason)
    clearInactiveField(
      state,
      'otherRefusedServiceReason',
      setOtherRefusedServiceReason
    )
    clearInactiveField(state, 'rescheduleNotes', setRescheduleNotes)
    clearInactiveField(state, 'mileage', setMileage)
    clearInactiveField(state, 'parkingFees', setParkingFees)
    clearInactiveField(state, 'tollFees', setTollFees)
    clearInactiveField(state, 'unsuccessfulVisit', setUnsuccessfulVisit)
  })

  return (
    <Dialog open={open} onExited={onExited} maxWidth="md" fullWidth>
      <DialogTitle>Reschedule Visit</DialogTitle>

      <DialogContent>
        {fields.rescheduleType.isActive(state) && (
          <DropDownField
            label="Reschedule Type"
            error={fieldError('rescheduleType')}
            value={rescheduleType}
            values={filterFieldValues(values.rescheduleType)}
            editing
            onChange={onRescheduleTypeChange}
          />
        )}

        {fields.rescheduledBy.isActive(state) && (
          <DropDownField
            label="Rescheduled By"
            error={fieldError('rescheduledBy')}
            value={rescheduledBy}
            values={filterFieldValues(values.rescheduledBy)}
            editing
            onChange={value => {
              setRescheduledBy(value)
            }}
          />
        )}

        {fields.rescheduleReason.isActive(state) && (
          <DropDownField
            label="Reschedule Reason"
            error={fieldError('rescheduleReason')}
            value={rescheduleReason}
            values={filterFieldValues(values.rescheduleReason)}
            editing
            onChange={value => {
              setRescheduleReason(value)
            }}
          />
        )}

        {fields.otherRescheduleReason.isActive(state) && (
          <TextLongField
            label="Other Reschedule Reason"
            error={fieldError('otherRescheduleReason')}
            value={otherRescheduleReason}
            editing
            onChange={value => {
              setOtherRescheduleReason(value)
            }}
          />
        )}

        {fields.refusedServiceReason.isActive(state) && (
          <DropDownField
            label="Refused Service Reason"
            error={fieldError('refusedServiceReason')}
            value={refusedServiceReason}
            values={filterFieldValues(values.refusedServiceReason)}
            editing
            onChange={value => {
              setRefusedServiceReason(value)
            }}
          />
        )}

        {fields.otherRefusedServiceReason.isActive(state) && (
          <TextLongField
            label="Other Refused Service Reason"
            error={fieldError('otherRefusedServiceReason')}
            value={otherRefusedServiceReason}
            editing
            inputProps={{ maxLength: 150 }}
            onChange={value => {
              setOtherRefusedServiceReason(value)
            }}
          />
        )}

        {fields.rescheduleNotes.isActive(state) && (
          <TextLongField
            label="Reschedule Notes"
            error={fieldError('rescheduleNotes')}
            value={rescheduleNotes}
            editing
            onChange={value => {
              setRescheduleNotes(value)
            }}
          />
        )}

        {fields.mileage.isActive(state) && (
          <NumberField
            label="Mileage"
            error={fieldError('mileage')}
            after="miles"
            min={0}
            value={mileage}
            editing
            onChange={value => {
              setMileage(value)
            }}
          />
        )}

        {fields.parkingFees.isActive(state) && (
          <NumberField
            label="Parking Fees"
            error={fieldError('parkingFees')}
            before="$"
            min={0}
            value={parkingFees}
            editing
            onChange={value => {
              setParkingFees(value)
            }}
          />
        )}

        {fields.tollFees.isActive(state) && (
          <NumberField
            label="Toll Fees"
            error={fieldError('tollFees')}
            before="$"
            min={0}
            value={tollFees}
            editing
            onChange={value => {
              setTollFees(value)
            }}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <Button
          disabled={!formValid}
          color="primary"
          onClick={onRescheduleVisit}
        >
          Save and Reschedule
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RescheduleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRescheduleVisit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
}

export default RescheduleDialog
