import { combineReducers } from 'redux-immutable'
import { isRequestProcessing } from './data/common/requestProcessing'
import createUnengagedDialog from './data/createUnengagedDialog'
import { completeDialog, isUnengaged } from './data/updateUnengagedDialog'
import key from './key'

const reducer = combineReducers({
  [completeDialog.key]: completeDialog,
  [isUnengaged.key]: isUnengaged,
  [isRequestProcessing.key]: isRequestProcessing,
  [createUnengagedDialog.key]: createUnengagedDialog,
})

reducer.key = key

export default reducer
