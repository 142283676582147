import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { FORMS_DEVELOPMENT_FORM } from '../router'
import { getFormsDevelopment } from './common/shared'

const key = 'templateKey'

const reducer = paramsReducer(
  FORMS_DEVELOPMENT_FORM,
  ({ templateKey }: any) => templateKey
)

reducer.key = key

export const getTemplateKey = pipe(getFormsDevelopment, get(key))

export default reducer
