import classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import HasOpenAdmitsDialog from '~/components/dialogs/HasOpenAdmitsDialog'
import { DateField, TextField } from '~/components/formFields'
import formValuesTransform from '~/utils/formValuesTransform'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { createLtcDialogClosed } from '../data/createLtcDialog'
import { addLTC } from '../data/ltcs'
import { getLTCFromValues, getValuesFromLTC } from '../utils/selectors'
import validate from '../utils/validator'

const form = 'ltc.new'

const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
  },
  marginRight: {
    marginRight: spacing(2),
  },
  field: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  date: {
    width: spacing(30),
  },
})

const AddLTC = ({
  classes,
  open,
  pristine,
  handleSubmit,
  createLtcDialogClosed,
  reset,
}) => {
  const onClose = () => {
    createLtcDialogClosed()
    reset()
  }

  const onSubmit = ltc => {
    const failed = handleSubmit(ltc)
    if (!failed) onClose()
  }

  return (
    <HasOpenAdmitsDialog
      open={open}
      title="Add LTC Admission"
      mode="add"
      onAdd={onSubmit}
      onClose={onClose}
      disableAction={pristine}
      maxWidth="md"
      fullWidth
    >
      <form>
        <div className={classes.container}>
          {/* Facility Name */}
          <Field
            component={TextField}
            name="facilityName"
            label="Facility Name"
            fullWidth
            className={classnames(
              classes.marginRight,
              classes.field,
              classes.facilityName
            )}
          />

          {/* Admission Date */}
          <Field
            component={DateField}
            name="admissionDate"
            label="Admission Date"
            className={classnames(
              classes.field,
              classes.marginRight,
              classes.date
            )}
          />

          {/* Discharge Date */}
          <Field
            component={DateField}
            name="dischargeDate"
            label="Discharge Date"
            className={classnames(classes.field, classes.date)}
          />
        </div>

        {/* Notes */}
        <Field
          component={TextField}
          name="notes"
          label="Notes"
          multiline
          fullWidth
          rowsMax={6}
          className={classes.field}
        />
      </form>
    </HasOpenAdmitsDialog>
  )
}

AddLTC.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  open: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  createLtcDialogClosed: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, { patientId }) => ({
  onSubmit: ltc => dispatch(addLTC.requested(patientId, ltc)),
  createLtcDialogClosed: () => dispatch(createLtcDialogClosed()),
})

export default compose(
  connect(null, mapDispatchToProps),
  formValuesTransform('ltc', getValuesFromLTC, getLTCFromValues),
  reduxForm({ form, enableReinitialize: true, validate }),
  withStyles(styles)
)(AddLTC)
