import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import { addFetchedKey, getFetchedKeys } from '../data/fetched'
import { getMapBounds } from '../data/map'
import {
  ODS_VISIBLE,
  RANGE_CHANGED,
  fetchODSPatients,
  getODSRange,
  loadMembers,
} from '../data/odsLayer'
import showError from '../utils/showError'

// TODO add cancellation logic and figure out how to add the fetched key
const odsLayerEpic = (action$, state$) =>
  action$.pipe(
    ofType(ODS_VISIBLE, RANGE_CHANGED),
    pluck('payload'),
    mergeMap(({ isVisible, range }) => {
      if (!isVisible && !range) return []

      const state = state$.value
      const bounds = getMapBounds(state)
      const odsRange = getODSRange(state)
      const fetched = getFetchedKeys(state)
      const fetchKey = `ODS_RANGE_FETCHED_${odsRange.join()}`

      if (fetched.includes(fetchKey)) return []

      return loading(
        from(fetchODSPatients(bounds, odsRange)).pipe(
          mergeMap(pats => of(loadMembers(pats), addFetchedKey(fetchKey))),
          catchError(showError('Failed to fetch ODS Patients.'))
        )
      )
    })
  )

export default odsLayerEpic
