import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload, scopedCreator } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

// KEY
const EDITTRIPLOCK = 'editTripLock'

export const TripLockInfo = Record({
  tripId: null,
  modifiedBy: null,
  modifiedAt: null,
  createdBy: null,
  createdAt: null,
  tripLockedByUserName: null,
  tripLockedByUserId: null,
})

// CREATORS
const creator = scopedCreator(EDITTRIPLOCK)

export const clearTripLock = creator('CLEAR_TRIP_LOCK')

export const transformEditTripLock = (tripLock: any) => {
  return TripLockInfo({
    tripId: tripLock?.tripLockUpsert?.trip_id,
    modifiedBy: tripLock?.tripLockUpsert?.modified_by,
    modifiedAt: tripLock?.tripLockUpsert?.modified_at,
    createdBy: tripLock?.tripLockUpsert?.created_by,
    createdAt: tripLock?.tripLockUpsert?.created_at,
    tripLockedByUserName: tripLock?.tripLockedByUserName,
    tripLockedByUserId: tripLock?.tripLockedByUserId,
  })
}

// REQUEST
export const createTripLock = Request({
  typePrefix: rootKey,
  typeBase: 'TRIPEDITLOCK',
  requestParams: ['tripId'],
  operation: (tripId: string) =>
    AspireAPI.post(`transportation/lock_trips/${tripId}`),
  transform: transformEditTripLock,
  messages: { failed: 'There was an issue to get lock on Trip' },
})

// REQUEST
export const deleteTripLock = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_EDIT_TRIP_LOCK',
  requestParams: ['tripId'],
  operation: (tripId: number) =>
    AspireAPI.delete(`transportation/lock_trips/${tripId}`),
  messages: {
    succeeded: `Trip Lock cleared successfully`,
    failed: `Clear trip lock failed`,
  },
})

// REDUCER
export const tripLockEdit = createReducer(EDITTRIPLOCK, TripLockInfo(), {
  [createTripLock.SUCCEEDED]: compose(payload, action),
  [createTripLock.FAILED]: () => TripLockInfo(),
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearTripLock]: () => TripLockInfo(),
  [createTripLock.REQUESTED]: () => TripLockInfo(),
})

// SELECTORS
export const getTripLock = pipe(getRoot, get(EDITTRIPLOCK))
