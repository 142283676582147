import { Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { callLogCleared, getRoot } from './common/shared'

export const REFERRED = 'Referred'
export const ACTIVE = 'Active'
export const SCHEDULED = 'Scheduled'
export const DISCHARGED = 'Discharged'

const PATIENT_INFO = 'patientInfo'

const PatientInfo = Record({
  patientID: null,
  caretaker1Name: null,
  caretaker1Relationship: null,
  caretaker2Name: null,
  caretaker2Relationship: null,
  caretaker3Name: null,
  caretaker3Relationship: null,
  market: null,
  name: null,
  status: null,
})

const transformPatientInfo = data => PatientInfo(data[0])

export const fetchPatientInfo = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_INFO',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get('v1/patientdemographics', {
      params: {
        patientIDs: patientId,
      },
    }),
  transform: transformPatientInfo,
  messages: {
    failed: 'There was an issue fetching patient info',
  },
})

const initState = PatientInfo()
export default createReducer(PATIENT_INFO, initState, {
  [callLogCleared]: () => initState,
  [fetchPatientInfo.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getPatientInfo = pipe(getRoot, get(PATIENT_INFO))

export const getPHIContacts = createSelector(
  [getPatientInfo],
  ({
    caretaker1Name: name1,
    caretaker1Relationship: relationship1,
    caretaker2Name: name2,
    caretaker2Relationship: relationship2,
    caretaker3Name: name3,
    caretaker3Relationship: relationship3,
  }) => {
    const contacts = []

    if (name1) contacts.push({ name: name1, relationship: relationship1 })
    if (name2) contacts.push({ name: name2, relationship: relationship2 })
    if (name3) contacts.push({ name: name3, relationship: relationship3 })

    return contacts
  }
)
