import { combineEpics, ofType } from 'redux-observable'
import { distinctUntilChanged, map, pluck } from 'rxjs/operators'
import { patientInfoRequested } from '~/features/panelContent/epics/loadPatientInfoForPanel'
import { panelOpened } from '../data/appLayout'
import { patientPanelOpened } from '../data/patientPanel'

const openPanel = action$ =>
  action$.pipe(ofType(patientPanelOpened), map(panelOpened))

const fetchPatientInfo = action$ =>
  action$.pipe(
    ofType(patientPanelOpened),
    pluck('payload', 'patientId'),
    distinctUntilChanged(),
    map(patientInfoRequested)
  )

export default combineEpics(fetchPatientInfo, openPanel)
