import { ofType } from 'redux-observable'
import { from, merge } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  EMR_STATUS_FILTER_SELECTED,
  EMR_STATUS_FILTER_UNSELECTED,
} from '../data/emrStatusesFilter'
import { getMapBounds } from '../data/map'
import { fetchEMRPatients, loadPatients } from '../data/patients'
import showError from '../utils/showError'

const emrStatusSelectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(EMR_STATUS_FILTER_SELECTED, EMR_STATUS_FILTER_UNSELECTED),
    pluck('payload', 'status'),
    mergeMap(status =>
      loading(
        merge(
          from(fetchEMRPatients(status, getMapBounds(state$.value))).pipe(
            map(loadPatients)
          )
        ).pipe(catchError(showError('Failed to fetch EMR Patients.')))
      )
    )
  )

export default emrStatusSelectedEpic
