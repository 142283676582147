import React, { useState } from 'react'
import { ActionDialog } from '~/components/dialogs'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { TextField, Typography } from '@material-ui/core'
import {
  deferPrescribedMedication,
  importPrescribedMedication,
} from '../../data/patientRx'
import { IMPORT } from '../../utils/medListConstants'

// @ts-expect-error ts-migrate(7016)

export const MedImportForm = ({
  isDialogOpened,
  setIsDialogOpened,
  dialogAction,
  medId,
}) => {
  const deferMedication = useAction(deferPrescribedMedication.requested)
  const importMedication = useAction(importPrescribedMedication.requested)

  const [deferReason, setDeferReason] = useState('')

  const handleDeferReasonChange = event => setDeferReason(event.target.value)

  const importFlag = dialogAction == IMPORT
  const errorFlag = deferReason == '' || deferReason === null

  const onSubmit = () => {
    if (importFlag) {
      importMedication(medId)
      onClose()
    } else if (!errorFlag) {
      deferMedication(medId, {
        deferReason: deferReason,
      })
      onClose()
    }
  }

  const onClose = () => {
    setDeferReason('')

    setIsDialogOpened(false)
  }

  return (
    <>
      <ActionDialog
        open={isDialogOpened}
        mode={importFlag ? 'add' : 'confirm'}
        title={`${dialogAction} Medication`}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        onAdd={onSubmit}
        onConfirm={onSubmit}
      >
        {importFlag ? (
          <Typography>
            Are you sure you want to import this medication?
          </Typography>
        ) : (
          <TextField
            id="defer-reason-input"
            label="Deferral Reason"
            value={deferReason}
            InputLabelProps={{ shrink: true }}
            type="text"
            required={true}
            InputProps={{
              inputProps: { maxLength: 250 },
              required: true,
              multiline: true,
              rows: '4',
            }}
            variant="outlined"
            onChange={handleDeferReasonChange}
            fullWidth
            error={errorFlag}
          />
        )}
      </ActionDialog>
    </>
  )
}

MedImportForm.propTypes = {
  isDialogOpened: PropTypes.boolean,
  setIsDialogOpened: PropTypes.func,
  dialogAction: PropTypes.func,
  medId: PropTypes.string,
}
