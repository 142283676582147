import PropTypes from 'prop-types'
import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import { Checkbox, FormGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from './FormControlLabel'
import memoizedWrapper from './memoizedWrapper'
import { getBaseProps } from './props'

const addValue = (values, value) => [...values, value]

const deleteValue = (values, value) => {
  const index = values.indexOf(value)
  const start = values.slice(0, index)
  const end = values.slice(index + 1)

  return [...start, ...end]
}

const getValue = (values, value) => (e, checked) =>
  checked ? addValue(values, value) : deleteValue(values, value)

const styles = ({ spacing }) => ({
  checkbox: {
    marginTop: -spacing(1),
    marginBottom: -spacing(1),
  },
})

const CheckboxesWidgets = props => {
  const { classes, id, options, value: values } = props
  const { enumOptions, inline = true } = options

  return (
    <FormGroup row={inline}>
      {enumOptions.map(({ label, value }) => {
        const checkboxId = id && `${id}_${value}`

        return (
          <FormControlLabel
            {...getBaseProps({ ...props, getValue: getValue(values, value) })}
            key={value}
            label={label}
            control={
              <Checkbox
                className={classes.checkbox}
                id={checkboxId}
                checked={values.includes(value)}
              />
            }
          />
        )
      })}
    </FormGroup>
  )
}

CheckboxesWidgets.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
}

export default compose(memoizedWrapper, withStyles(styles))(CheckboxesWidgets)
