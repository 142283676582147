import moment from 'moment'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'
import { logDownload } from '../data/currentAttachments'

const useStyles = makeStyles(({ palette: { contentText } }) => ({
  link: {
    color: contentText.secondaryLabel,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}))

function AttachmentLink({ row, value, onClick }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  return row.object === 'SALESFORCE' ? (
    <span className={classes.link} onClick={() => onClick(row.id)}>
      {value}
    </span>
  ) : (
    <a
      className={classes.link}
      href={row.link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => dispatch(logDownload.requested(row.id))}
    >
      {value}
    </a>
  )
}

AttachmentLink.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  onClick: PropTypes.func,
}

const AttachmentsGrid = ({ attachments, onAttachmentClick }) => {
  const attachmentsGridColumns = useMemo(() => {
    const nameDisplayComponent = props => (
      <AttachmentLink {...props} onClick={onAttachmentClick} />
    )

    return [
      {
        name: 'displayName',
        title: 'Name',
        displayComponent: nameDisplayComponent,
      },
      {
        name: 'attachmentType',
        title: 'Attachment Type',
      },
      {
        name: 'createdDate',
        title: 'Created Date',
        getCellValue: ({ createdDate }) => {
          const formattedDate = new moment(createdDate)
          return formattedDate.format('YYYY/MM/DD')
        },
      },
    ]
  }, [onAttachmentClick])

  return (
    <DevXGrid
      sortable
      rows={attachments}
      columns={attachmentsGridColumns}
      defaultSorting={[{ columnName: 'createdDate', direction: 'desc' }]}
    />
  )
}

AttachmentsGrid.propTypes = {
  attachments: PropTypes.array.isRequired,
  onAttachmentClick: PropTypes.func.isRequired,
}

export default AttachmentsGrid
