import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Input as MuiInput } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette }) => ({
  disabled: {
    color: palette.text.primary,
  },
})

const Input = ({ classes, ...props }) => (
  <MuiInput {...props} classes={{ disabled: classes.disabled }} />
)

Input.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Input)
