import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  pluck,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  CASE_MANAGER_QUERY_CHANGED,
  caseManagersStored,
  fetchCaseManagersByName,
} from '../data/caseManagers'

export default (action$: any) =>
  action$.pipe(
    ofType(CASE_MANAGER_QUERY_CHANGED),
    debounceTime(750),
    pluck('caseManagerName'),
    filter(
      (caseManagerName: any) => caseManagerName && caseManagerName.length >= 3
    ),
    mergeMap(caseManagerName =>
      from(fetchCaseManagersByName(caseManagerName)).pipe(
        map(caseManagersStored),
        catchError(showError('Unable to load Case Managers'))
      )
    )
  )
