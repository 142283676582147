import { List } from 'immutable'
import { ofType } from 'redux-observable'
import { map, pluck, withLatestFrom } from 'rxjs/operators'
import {
  fetchProblemInformation,
  fetchProblemWorkflow,
  sendWorkflowPresented,
} from './../data/imo'
import { getImoProblems, getImoSessionId } from './../data/utils/selectors'
import { sendProblemSelected } from '../data/imo'
import { Problem } from '../types/types'

export const sendImoProblemSelected = (action$: any, state$: any) =>
  action$.pipe(
    ofType(fetchProblemWorkflow.REQUESTED, fetchProblemInformation.REQUESTED),
    pluck('payload'),
    withLatestFrom(state$),
    map(([payload, state]: any) => {
      const problems = getImoProblems(state)
      const {
        sessionId,
        imoLexicalCode: lexicalCode,
        icd10Code: icd10,
      } = payload
      const rank = sessionId
        ? problems?.findIndex(
            ({ imoLexicalCode }: Problem) => lexicalCode === imoLexicalCode
          )
        : problems?.findIndex(({ icd10Code }: Problem) => icd10Code === icd10)
      const id = List<Problem>(problems).get(rank).imoLexicalCode
      return sendProblemSelected.requested(getImoSessionId(state), id, rank)
    })
  )

export const sendImoWorkflowPresented = (action$: any, state$: any) =>
  action$.pipe(
    ofType(fetchProblemWorkflow.SUCCEEDED),
    pluck('payload'),
    withLatestFrom(state$),
    map(([payload, state]: any) => {
      const problems = getImoProblems(state)
      const rank = problems?.findIndex(
        ({ imoLexicalCode }: Problem) => imoLexicalCode === payload.id
      )
      return sendWorkflowPresented.requested(
        getImoSessionId(state),
        payload.id,
        rank
      )
    })
  )
