// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import appId from './data/dashAppId'
import appMetrics from './data/metrics'
import key from './key'

const reducer = combineReducers({
  [appId.key]: appId,
  [appMetrics.key]: appMetrics,
})

reducer.key = key

export default reducer
