import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Columns, { Column } from '~/apps/patientRecord/components/Columns'
import TextArea from '~/apps/patientRecord/components/TextArea'
import Info from '~/apps/patientRecord/containers/PatientInfo/Info'
import { getPatientId } from '~/apps/patientRecord/data/patientId'
import SelectField from '~/components/fields/SelectField'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { Button, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  completeResearchCase,
  updateResearchCase,
} from '../data/updateResearchDialog'

const styles = ({ spacing }) => ({
  buttons: {
    padding: spacing(2, 0, 0, 1),
    textAlign: 'right',
    '& button:last-child': {
      marginLeft: spacing(1),
    },
  },
})

const ResearchCaseDetails = ({
  classes,
  completeCase,
  patientId,
  row,
  updateCase,
}) => {
  const initialState = row
  const [currentState, setCurrentState] = useState(initialState)

  useEffect(() => {
    setCurrentState(row)
  }, [row])

  const changeState = (stateKey, stateValue) =>
    setCurrentState(prevState => prevState.set(stateKey, stateValue))

  const compareStates = (initialState, currentState) =>
    initialState.equals(currentState)

  const isStateSame = useMemo(() => compareStates(initialState, currentState), [
    initialState,
    currentState,
  ])

  const updateResearchInfo = () => {
    const { id, notes, resetCallAttempts } = currentState
    updateCase(id, { notes, resetCallAttempts })
  }

  const closeResearchCase = () => {
    const { id, notes, resetCallAttempts } = currentState

    completeCase(id, { notes, patientId, resetCallAttempts })
  }

  const {
    caseNumber,
    closedDate,
    createdBy,
    createdDate,
    lastModifiedBy,
    lastModifiedDate,
    notes,
    resetCallAttempts,
    status,
  } = currentState

  const canEdit = status !== 'Closed'
  const yesNoOptions = ['Yes', 'No']
  const yesNoFieldValue = resetCallAttempts ? 'Yes' : 'No'

  return (
    <div>
      <Columns>
        <Column>
          <div>
            <Info
              label="Case Number"
              content={caseNumber ? caseNumber : 'Pending'}
            />
            <Info label="Status" content={status} />
            <Info label="Closed Date" content={formatDate(closedDate)} />
            {notes.includes('Call Cadence Rule') && (
              <Info
                content={yesNoFieldValue}
                editable={canEdit}
                fieldName="resetCallAttempts"
                inputComponent={SelectField}
                label="Reset Call Attempts When Research Is Completed"
                onChange={() =>
                  changeState('resetCallAttempts', !resetCallAttempts)
                }
                value={yesNoFieldValue}
              >
                {yesNoOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Info>
            )}
            <Info
              changeState={changeState}
              content={notes}
              editable={canEdit}
              inputComponent={TextArea}
              fieldName="researchNotes"
              label="Research Notes"
              value={notes || ''}
              valueKey="notes"
            />
          </div>
        </Column>

        <Column>
          <div>
            <Info label="Created Date" content={formatDate(createdDate)} />
            <Info label="Created By" content={createdBy} />
            <Info
              label="Last Modified Date"
              content={formatDate(lastModifiedDate)}
            />
            <Info label="Last Modified By" content={lastModifiedBy} />
          </div>
        </Column>
      </Columns>
      {canEdit && (
        <div className={classes.buttons}>
          <Button
            color="secondary"
            disabled={isStateSame}
            onClick={() => setCurrentState(initialState)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isStateSame}
            onClick={updateResearchInfo}
          >
            Update
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={closeResearchCase}
          >
            Complete Research
          </Button>
        </div>
      )}
    </div>
  )
}

ResearchCaseDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  completeCase: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  row: PropTypes.record.isRequired,
  updateCase: PropTypes.func.isRequired,
}

export default compose(
  connect(
    state => ({
      patientId: getPatientId(state),
    }),
    {
      completeCase: completeResearchCase.requested,
      updateCase: updateResearchCase.requested,
    }
  ),
  withStyles(styles)
)(ResearchCaseDetails)
