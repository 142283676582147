import React, { useCallback } from 'react'
import PropTypes from '~/utils/propTypes'
import FilterBase from './FilterBase'

const BooleanFilter = ({ color, title, filter, value, ...props }) => {
  const onChange = useCallback(() => {
    props.onChange(filter.key)
  }, [filter.key, props.onChange])

  return (
    <FilterBase color={color} title={title} value={value} onClick={onChange} />
  )
}

BooleanFilter.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default BooleanFilter
