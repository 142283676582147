import cx from 'classnames'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form/immutable'
import { ActionDialog, InfoDialog } from '~/components/dialogs'
import Checkbox from '~/components/fields/Checkbox'
import { Checkbox as ReduxCheckbox } from '~/components/formFields'
import { getHasPermission } from '~/features/authorization/data/permissions'
import useToggle from '~/hooks/useToggle'
import { capitalizeFirst, decamelize } from '~/utils/stringManipulation'
import { Icon, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InlineCheckbox from './InlineCheckbox'

const flexDirections = {
  top: 'column',
  left: 'row',
}

const getContent = ({ content, formatContent, input = {} }) =>
  formatContent(
    Object.prototype.hasOwnProperty.call(input, 'value') ? input.value : content
  ) || ''

const filterProps = ({ ...props }) => {
  delete props.classes
  delete props.fieldName
  delete props.label
  delete props.editable
  delete props.inputComponent
  delete props.clearable
  delete props.content
  delete props.formatContent
  delete props.labelFlex
  delete props.valueFlex
  delete props.labelPosition
  delete props.isAuthorized
  delete props.dispatch

  return props
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  info: {
    display: 'flex',
    flexDirection: ({ labelPosition }) => flexDirections[labelPosition],
    padding: [6, 0, 3],
    borderBottom: {
      style: 'solid',
      width: 1,
      color: palette.divider,
    },
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  value: {
    display: 'flex',
    flex: ({ valueFlex }) => valueFlex,
    justifyContent: 'space-between',
  },
  label: {
    flex: ({ labelFlex }) => labelFlex,
    color: palette.contentText.infoLabel,
    marginRight: ({ labelPosition }) =>
      labelPosition === 'left' ? spacing(1) : 'initial',
    lineHeight: ({ labelPosition }) =>
      labelPosition === 'left' ? `${spacing(3)}px` : 'initial',
  },
  readValue: {
    minHeight: spacing(3),
    boxSizing: 'border-box',
    paddingTop: spacing(0.25),
    paddingBottom: spacing(0.25),
    width: '100%',
  },
  colorBool: {
    color: ({ content = '', input = {} }) => {
      const value = input.value ? input.value : content
      return (
        typeof value === 'boolean' &&
        palette.severity.success.dynamicHighContrast
      )
    },
    fontSize: '1rem',
  },
  button: {
    alignSelf: 'flex-start',
    marginLeft: spacing(1),
    marginTop: '-2px',
    padding: spacing(0.5),
  },
  icon: {
    color: palette.secondary.main,
    fontSize: '0.75em',
  },
  notesLabel: {
    margin: [3, 0],
  },
}))

const Info = props => {
  const {
    className: labelStyles,
    contentClassName: valueStyles,
    label,
    required,
    meta,
    input,
    isAuthorized,
    dispatch,
    editDialog: EditDialogComponent,
    selectDialog: SelectDialogComponent,
    inputComponent: Input,
  } = props
  const classes = useStyles(props)
  const [disabled, setDisabled] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [confirmSelection, setConfirmSelection] = useState(() => {})
  const [editing, toggleEditingTrue, toggleEditingFalse] = useToggle(false)

  const clearable = isAuthorized && props.clearable
  const editable = isAuthorized && props.editable
  const editDialogDisplayed = Boolean(editing && EditDialogComponent)
  const selectDialogDisplayed = Boolean(editing && SelectDialogComponent)
  const editComponentDisplayed = Boolean(editing && Input)

  const onClear = useCallback(() => {
    dispatch(change(meta.form, input.name, Map()))
  }, [meta, input, dispatch, change])

  const onConfirm = useCallback(() => {
    confirmSelection(Map(selectedRow))
    setDisabled(true)
    toggleEditingFalse()
  }, [selectedRow, confirmSelection, setDisabled, toggleEditingFalse])

  const nonReduxOnConfirm = useCallback(() => {
    props.onConfirm(selectedRow)
    setDisabled(true)
    toggleEditingFalse()
  }, [selectedRow, confirmSelection, setDisabled, toggleEditingFalse])

  const onCancel = useCallback(() => {
    setDisabled(true)
    setSelectedRow({})
    setConfirmSelection(() => {})
    toggleEditingFalse()
  }, [setDisabled, setSelectedRow, setConfirmSelection, toggleEditingFalse])

  const onSetActionDialogState = useCallback(
    (selectedRow, confirmSelection) => {
      setSelectedRow(selectedRow)
      setConfirmSelection(() => confirmSelection)
    },
    [setSelectedRow, setConfirmSelection]
  )

  const onSetIsDisabled = useCallback(
    itemSelected => {
      setDisabled(!itemSelected)
    },
    [setDisabled]
  )

  const inputProps = useMemo(() => {
    const { input, ...filteredProps } = filterProps(props)
    const isFullWidth =
      Input !== ReduxCheckbox && Input !== Checkbox && Input !== InlineCheckbox

    return {
      ...filteredProps,
      ...(isFullWidth ? { fullWidth: true } : {}),
      autoFocus: true,
      input: {
        ...input,
      },
    }
  }, [props])

  const displayContent = getContent(props)

  return (
    <div className={classes.info} data-testid={label}>
      <Typography
        color={meta && meta.dirty ? 'secondary' : 'initial'}
        variant="body2"
        className={cx(classes.label, labelStyles, {
          [classes.notesLabel]: label === 'Last Call Notes',
        })}
      >
        {label}
        {required ? <sup>*</sup> : ''}
      </Typography>
      <div className={classes.value}>
        {editComponentDisplayed ? (
          <Input {...inputProps} />
        ) : (
          <Typography
            className={cx(classes.readValue, valueStyles, {
              [classes.colorBool]: displayContent === '✓',
            })}
            onDoubleClick={editable ? toggleEditingTrue : undefined}
            variant="body2"
          >
            {capitalizeFirst(displayContent)}
          </Typography>
        )}
        {clearable && (
          <IconButton className={classes.button} onClick={onClear}>
            <Icon className={classes.icon}>delete</Icon>
          </IconButton>
        )}
        {editable && (
          <IconButton
            aria-label={editing ? 'clear' : 'edit'}
            className={classes.button}
            onClick={editing ? toggleEditingFalse : toggleEditingTrue}
          >
            <Icon className={classes.icon}>{editing ? 'clear' : 'edit'}</Icon>
          </IconButton>
        )}
      </div>
      {editDialogDisplayed && (
        <InfoDialog
          fullWidth
          maxWidth="md"
          onClose={toggleEditingFalse}
          open={editDialogDisplayed}
          title={`Edit ${label}`}
        >
          <EditDialogComponent onClose={toggleEditingFalse} />
        </InfoDialog>
      )}
      {selectDialogDisplayed && (
        <ActionDialog
          disableAction={disabled}
          fullWidth
          maxWidth="md"
          mode="confirm"
          onConfirm={props.onConfirm ? nonReduxOnConfirm : onConfirm}
          onCancel={onCancel}
          onClose={onCancel}
          open={selectDialogDisplayed}
          title={`Select ${label}`}
        >
          <SelectDialogComponent
            onClose={onCancel}
            setActionDialogState={onSetActionDialogState}
            setIsDisabled={onSetIsDisabled}
          />
        </ActionDialog>
      )}
    </div>
  )
}

Info.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  clearable: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  dispatch: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  editDialog: PropTypes.elementType,
  fieldName: PropTypes.string,
  formatContent: PropTypes.func,
  input: PropTypes.object,
  inputComponent: PropTypes.elementType,
  isAuthorized: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelFlex: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left']),
  meta: PropTypes.object,
  onConfirm: PropTypes.func,
  selectDialog: PropTypes.elementType,
  value: PropTypes.any,
  valueFlex: PropTypes.string,
  required: PropTypes.bool,
}

Info.defaultProps = {
  clearable: false,
  editable: false,
  formatContent: content => content,
  labelFlex: '1',
  labelPosition: 'left',
  valueFlex: '1',
}

const mapStateToProps = (state, { input, fieldName }) => {
  const name = fieldName ? fieldName : input ? input.name : false
  return {
    isAuthorized:
      name &&
      getHasPermission(state, `patient_record:${decamelize(name)}`, 'edit'),
  }
}

export default connect(mapStateToProps)(Info)
