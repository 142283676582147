import { combineReducers } from 'redux-immutable'
import createNewTask from './data/createNewTask'
import currentTaskId from './data/currentTaskId'
import dialog from './data/dialog'
import newDueDate from './data/newDueDate'
import reassignmentUser from './data/reassignmentUser'
import taskDefinitions from './data/taskDefinitions'
import tasks from './data/tasks'
import toggles from './data/toggles'
import userId from './data/userId'
import key from './key'

const reducer = combineReducers({
  [currentTaskId.key]: currentTaskId,
  [dialog.key]: dialog,
  [taskDefinitions.key]: taskDefinitions,
  [newDueDate.key]: newDueDate,
  [createNewTask.key]: createNewTask,
  [reassignmentUser.key]: reassignmentUser,
  [toggles.key]: toggles,
  [tasks.key]: tasks,
  [userId.key]: userId,
})

reducer.key = key

export default reducer
