import { Map } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import normalize from '~/utils/normalize'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'patientErrors'

export const PATIENT_LAT_LONG_ERROR_OCCURRED = type(
  rootKey,
  'PATIENT_LAT_LONG_ERROR_OCCURRED'
)

export const addPatientLatLongError = creator(
  PATIENT_LAT_LONG_ERROR_OCCURRED,
  'patients'
)

export default createReducer(key, Map(), {
  [PATIENT_LAT_LONG_ERROR_OCCURRED]: (state, { payload: { patients } }) => {
    return normalize(patients, { accumulator: state })
  },
})

export const getPatientErrors = pipe(getRoot, get(key))
