// ERIC NOTE 3-12-21: This is temporary until all assessments using the old care plan structures are signed.
// Then we don't worry about writing the form data back to its old structure

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { carePlanSet, getCarePlan } from './root'

const LEGACY = 'legacy'

export default createReducer(LEGACY, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [carePlanSet]: (_state: any, { payload: { legacy } }: any) => legacy,
})

export const getLegacy = pipe(getCarePlan, get(LEGACY))
