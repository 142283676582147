import { Map } from 'immutable'
import { FieldValue } from '~/data/fieldValues'
import { colors as muiColors } from '@material-ui/core'

export const colors = {
  text: 'white',
  background: muiColors.amber['A700'],
}

export const label = 'Incentive Day'

export const subtypeField = 'calendar_event_subtype_incentive_day'

export const layer = 1

export const title = (event, subtypes = Map()) => {
  const eventType = subtypes.get(event.eventType, FieldValue()).label || ''

  return eventType ? eventType : label
}

export default { colors, label, layer, title }
