import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { scopedCreator } from '~/utils/data'
import { saveNewTrip, updateTrip } from '../data/tripOperation'
import {
  TripValidation,
  setInvalidTrip,
  setValidTrip,
} from '../data/validateTrip'

const key = 'ValidTrip'

export const setTripValid = scopedCreator(key)('SET_TRIP_VALID')

// @ts-expect-error ts-migrate(7006) Parameter 'action' implicitly has an 'any' type
export const tripOverRideValid = action$ =>
  action$.pipe(
    ofType(setTripValid),
    map(() => setValidTrip())
  )
// @ts-expect-error ts-migrate(7006) Parameter 'action' implicitly has an 'any' type
export const tripValidated = action$ =>
  action$.pipe(
    ofType(TripValidation.SUCCEEDED),
    pluck('payload'),
    map((payload: any) => {
      if (
        payload?.distanceInRange === 'Yes' &&
        payload?.benefitConfigured &&
        payload?.patientCovered
      ) {
        return setValidTrip()
      } else return setInvalidTrip()
    })
  )
// @ts-expect-error ts-migrate(7006) Parameter 'action' implicitly has an 'any' type
export const tripSaved = action$ =>
  action$.pipe(
    ofType(saveNewTrip.SUCCEEDED),
    map(() => setInvalidTrip())
  )

// @ts-expect-error ts-migrate(7006) Parameter 'action' implicitly has an 'any' type
export const newTripSaveFailed = action$ =>
  action$.pipe(
    ofType(saveNewTrip.FAILED),
    map(() => setInvalidTrip())
  )

// @ts-expect-error ts-migrate(7006) Parameter 'action' implicitly has an 'any' type
export const tripUpdateSucceeded = action$ =>
  action$.pipe(
    ofType(updateTrip.SUCCEEDED),
    map(() => setInvalidTrip())
  )

// @ts-expect-error ts-migrate(7006) Parameter 'action' implicitly has an 'any' type
export const tripUpdateFailed = action$ =>
  action$.pipe(
    ofType(updateTrip.FAILED),
    map(() => setInvalidTrip())
  )
