import { combineEpics, ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { saveTask, taskFormUpdated } from '../data/tasks'

const onBeforeUnload = e => {
  e.preventDefault()
  e.returnValue = ''
}

const block = action$ =>
  action$.pipe(
    ofType(taskFormUpdated),
    tap(() => window.addEventListener('beforeunload', onBeforeUnload)),
    ignoreElements()
  )

const unblock = action$ =>
  action$.pipe(
    ofType(saveTask.SUCCEEDED),
    tap(() => window.removeEventListener('beforeunload', onBeforeUnload)),
    ignoreElements()
  )

export default combineEpics(block, unblock)
