import { combineEpics, ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import {
  assessmentScratchNotesChanged,
  cancelAssessment,
  saveAssessment,
  signAssessment,
} from '../data/assessments'
import {
  FORM_DATA_CHANGED_BY_TAG,
  SECTION_CHANGED,
} from '../data/common/shared'

const onBeforeUnload = e => {
  e.preventDefault()
  e.returnValue = ''
}

const block = action$ =>
  action$.pipe(
    ofType(
      SECTION_CHANGED,
      FORM_DATA_CHANGED_BY_TAG,
      assessmentScratchNotesChanged
    ),
    tap(() => window.addEventListener('beforeunload', onBeforeUnload)),
    ignoreElements()
  )

const unblock = action$ =>
  action$.pipe(
    ofType(
      saveAssessment.SUCCEEDED,
      saveAssessment.FAILED,
      signAssessment.SUCCEEDED,
      cancelAssessment.SUCCEEDED
    ),
    tap(() => window.removeEventListener('beforeunload', onBeforeUnload)),
    ignoreElements()
  )

export default combineEpics(block, unblock)
