import React from 'react'
import { createSelector } from 'reselect'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import Search from '~/components/Search'
import PropTypes from '~/utils/propTypes'
import { Button, Paper, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MedicationSearchOptionsDefaults from '../../utils/medicationSearchOptionsDefaults'
import ActionItem from '../ActionItem'
import RowActions from './RowActions'
import SearchOptions from './SearchOptions'

const styles = ({ spacing, palette }) => ({
  root: {
    width: '100%',
  },
  input: {
    clear: 'both',
    paddingRight: spacing(3),
    paddingBottom: spacing(1),
  },
  searchResults: {
    '& thead': {
      '& tr': {
        '& th': {
          '&:nth-child(1)': {
            width: '30%',
          },
          '&:nth-child(2)': {
            width: '15%',
          },
          '&:nth-child(3)': {
            width: '10%',
          },
          '&:nth-child(4)': {
            width: '10%',
          },
        },
      },
    },
  },
  floatRight: {
    float: 'right',
    position: 'relative',
    bottom: spacing(2),
  },
  searchOptionsLogo: {
    color: palette.secondary.main,
  },
  mdtSearchButton: {
    margin: [0, spacing(2)],
  },
})

const medSearchColumns = [
  { name: 'drug', title: 'Full Name' },
  { name: 'genericname', title: 'Generic Name' },
  { name: 'className', title: 'Class Name', searchedBy: 'Class' },
  { name: 'condition', title: 'Condition', searchedBy: 'Diagnosis' },
  { name: 'rxOtcStatusCode', title: 'Rx / OTC' },
  { name: 'brandGenericCode', title: 'Brand / Generic' },
  { name: 'dosage', title: 'Dosage' },
  { name: 'route', title: 'Route' },
  { name: 'dosageform', title: 'Form' },
]

const caseInsensitiveSort = (a, b) => a && a.localeCompare(b)
const statusSort = (a, b) => b && b.localeCompare(a)

const integratedSortingColumnExtensions = [
  { columnName: 'brandGenericCode', compare: statusSort },
  { columnName: 'drug', compare: caseInsensitiveSort },
]

const defaultSorting = [{ columnName: 'brandGenericCode', direction: 'asc' }]

const getSearchedBy = state => state.searchOptionsValue.searchFlag

const getColumnsSelector = createSelector([getSearchedBy], searchedBy =>
  medSearchColumns.filter(x => !x.searchedBy || x.searchedBy === searchedBy)
)

const defaultSearchOptions = MedicationSearchOptionsDefaults()

class MedSearch extends React.Component {
  state = {
    searchOptionsOpen: false,
    searchOptionsValue: defaultSearchOptions,
    searchBarValue: '',
    searchBarKey: 0,
  }

  genericDrugSearch = () =>
    this.props.genericDrugSearch({
      query: this.state.searchBarValue,
      options: this.state.searchOptionsValue,
    })

  clearSearchResults = () =>
    this.setState(
      ({ searchBarKey }) => ({
        searchBarValue: '',
        searchBarKey: searchBarKey + 1,
      }),
      this.props.clearSearchResults
    )

  searchByGeneric = row => {
    this.setState(
      ({ searchBarKey }) => ({
        searchBarKey: searchBarKey + 1,
        searchBarValue: row.genericname,
        searchOptionsValue: defaultSearchOptions.set(
          'searchNameFlag',
          'GenericName'
        ),
      }),
      this.genericDrugSearch
    )
  }

  rowActions = ({ row }) => (
    <RowActions searchByGeneric={this.searchByGeneric} medication={row} />
  )

  toggleSearchOptions = () => {
    this.setState(prevState => ({
      searchOptionsOpen: !prevState.filterMenuOpen,
    }))
  }

  updateSearchOptions = event => {
    const { name: property, value } = event.target
    this.setState(prevState => ({
      searchOptionsValue: prevState.searchOptionsValue.set(property, value),
    }))
  }

  resetSearchOptionDefaults = () =>
    this.setState({
      searchOptionsValue: defaultSearchOptions,
    })

  loadAdditional = () => {
    const { searchResults } = this.props
    const { searchOptionsValue, searchBarValue } = this.state

    return this.props.loadAdditional({
      query: searchBarValue,
      options: searchOptionsValue.set(
        'pageNumber',
        searchResults.length / 100 + 1
      ),
    })
  }

  closeSearchOptions = () => this.setState({ searchOptionsOpen: false })

  onSearch = () => {
    const { search } = this.props
    const { searchOptionsValue: options, searchBarValue: query } = this.state

    search({ query, options })
  }

  onMDTSearch = () => {
    const { search } = this.props
    const { searchBarValue: query } = this.state
    const options = this.state.searchOptionsValue.set('mdt', true)

    search({ query, options })
  }

  onChangeSearch = text => this.setState({ searchBarValue: text })

  buttonRef = ref => (this.searchOptionsButton = ref)

  renderMDTSearchButton = () => {
    return (
      <Tooltip title="Look up drug in MD Toolbox when not found in Aspire's drug list">
        <Button
          className={this.props.classes.mdtSearchButton}
          variant="outlined"
          onClick={this.onMDTSearch}
        >
          Look Up In MD Toolbox
        </Button>
      </Tooltip>
    )
  }

  get notDefault() {
    return !defaultSearchOptions.equals(this.state.searchOptionsValue)
  }

  get canPaginate() {
    const { searchResults } = this.props
    return (
      this.state.searchOptionsValue.searchFlag === 'Name' &&
      searchResults.length > 0 &&
      searchResults.length % 100 === 0
    )
  }

  getRowId = row => row.id

  render() {
    const { classes, searchResults, hasSearchResults } = this.props
    const {
      searchOptionsOpen,
      searchOptionsValue,
      searchBarValue,
      searchBarKey,
    } = this.state

    return (
      <React.Fragment>
        <DevXTitleHeader title="Add New Medication">
          <ActionItem
            toolTip="Clear Search Results"
            icon="clear"
            disabled={!hasSearchResults}
            onClick={this.clearSearchResults}
          />
          <ActionItem
            toolTip="Load Additional Search Results"
            icon="forward_10"
            disabled={!this.canPaginate}
            onClick={this.loadAdditional}
          />
          <ActionItem
            toolTip="Search Options"
            buttonRef={this.buttonRef}
            onClick={this.toggleSearchOptions}
            icon="filter_list"
            className={this.notDefault ? classes.searchOptionsLogo : null}
          />
        </DevXTitleHeader>
        <Paper classes={{ root: classes.root }}>
          <div className={classes.input}>
            <Search
              key={searchBarKey}
              label="Quick Search"
              value={searchBarValue || ''}
              onChangeSearch={this.onChangeSearch}
              onSubmit={this.onSearch}
              tooltip="Search within Aspire's drug list"
            />
          </div>
          {hasSearchResults && (
            <DevXGrid
              className={classes.searchResults}
              rows={searchResults}
              getRowId={this.getRowId}
              columns={getColumnsSelector(this.state)}
              columnExtensions={integratedSortingColumnExtensions}
              defaultSorting={defaultSorting}
              searchable
              sortable
              groupable
              rowActions={this.rowActions}
              rowActionsCount={3}
              pageable
              searchPlaceholder="Search In Results..."
              batchActions={this.renderMDTSearchButton}
            />
          )}
        </Paper>

        <SearchOptions
          open={searchOptionsOpen}
          onClose={this.closeSearchOptions}
          anchorEl={this.searchOptionsButton}
          searchOptionsValue={searchOptionsValue}
          onChange={this.updateSearchOptions}
          resetDefaults={this.resetSearchOptionDefaults}
        />
      </React.Fragment>
    )
  }
}

MedSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
  genericDrugSearch: PropTypes.func.isRequired,
  hasSearchResults: PropTypes.bool.isRequired,
  loadAdditional: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
}

export default withStyles(styles)(MedSearch)
