import React from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import { Button } from '@material-ui/core'
import {
  getPendingSelectedAPPs,
  selectedAPPsCleared,
} from '../../data/pendingSelectedAPPs'

const ClearSelectedAPPs = () => {
  const pendingSelectedAPPs = useSelector(getPendingSelectedAPPs)
  const onSelectedAPPsCleared = useAction(selectedAPPsCleared)

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={pendingSelectedAPPs.isEmpty()}
      onClick={onSelectedAPPsCleared}
    >
      Clear Selected APPs
    </Button>
  )
}

export default ClearSelectedAPPs
