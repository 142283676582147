import { List, Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
import { getIn, scopedCreator, get } from '~/utils/data'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { formatDateTime } from '~/utils/format'

// KEY
const key = 'benefitFalloutDialog'
const BENEFIT_FALLOUT = 'benefitFallout'

export const getDialogOpenValue = getIn([rootKey, key])

export const BenefitUpdateStatus = Record({
  totalCount: null,
  status: null,
  modifiedBy: null,
  modifiedAt: null,
  fileName: null,
  failedCount: null,
  createdBy: null,
  createdAt: null,
})

// TRANSFORMERS
export const transformBenefitUpdateStatus = (benefitStatuses: any) => {
  return Array.isArray(benefitStatuses)
    ? List(
        benefitStatuses.map(benefitStatus => {
          return BenefitUpdateStatus({
            totalCount: benefitStatus?.totalCount
              ? benefitStatus?.totalCount
              : '',
            status: benefitStatus?.status,
            modifiedBy: benefitStatus?.modifiedBy,
            modifiedAt: formatDateTime(benefitStatus?.modifiedAt),
            fileName: benefitStatus?.fileName,
            failedCount: benefitStatus?.failedCount
              ? benefitStatus?.failedCount
              : '',
            createdBy: benefitStatus?.createdBy,
            createdAt: formatDateTime(benefitStatus?.createdAt),
          })
        })
      )
    : List()
}

// CREATORS
const creator = scopedCreator(key)

export const benefitFalloutDialogOpened = creator(
  'BENEFIT_FALLOUT_DIALOG_OPENED'
)
export const benefitFalloutDialogClosed = creator(
  'BENEFIT_FALLOUT_DIALOG_CLOSED'
)

// REQUEST

export const fetchBenefitUploadStatus = Request({
  typePrefix: BENEFIT_FALLOUT,
  typeBase: 'FETCH_BENEFIT_UPLOAD_STATUS',
  operation: () => AspireAPI.get(`transportation/upload_benefits_status`),
  transform: transformBenefitUpdateStatus,
  messages: { failed: 'Failed to fetch destination type ' },
})

// REDUCER
export const benefitFalloutDialog = createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [benefitFalloutDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [benefitFalloutDialogOpened]: () => true,
})

export const benefitFallout = createReducer(BENEFIT_FALLOUT, List(), {
  [fetchBenefitUploadStatus.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

// SELECTORS
export const getBenefitsUpdateStatus = pipe(getRoot, get(BENEFIT_FALLOUT))
