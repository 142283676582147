import { List, Map, Record } from 'immutable'
import generateId from '~/utils/generateId'
import { Modifier, ModifierGroup, Suggestion } from '../../types/types'
import { getCombinations } from './helpers'
export const ImoModifierCombination = Record({
  allowedModifiers: List(),
  id: null,
  result: null,
})

export const ImoModifierGroup = Record({
  enabledModifiers: List(),
  modifiers: List(),
  title: null,
  type: null,
})

export const ImoModifier = Record({
  code: null,
  combinations: List(),
  disabled: false,
  title: null,
  type: null,
})

export const ImoWorkflow = Record({
  enabledModifiers: List(),
  id: null,
  modifierCombinations: Map(),
  modifierGroups: List(),
  workflowLoaded: false,
})

export const ImoCategory = Record({
  category: null,
  icd10Code: null,
  problemCategoryId: null,
  problemType: null,
  problemTypeId: null,
  subcategory: null,
})

export const ImoDiagnosis = Record({
  icd10Code: null,
  description: null,
  problemTypeId: null,
  isDeleted: false,
})

export const ImoProblem = Record({
  additionalICDCodes: null,
  categories: List(),
  categoriesLoaded: false,
  diagnoses: List(),
  diagnosesLoaded: 0,
  enabledModifiers: List(),
  error: false,
  icd10Code: null,
  id: null,
  imoLexicalCode: null,
  modifierCombinations: Map(),
  modifierGroups: List(),
  modifierSelected: false,
  modifierWorkflowNeeded: false,
  secondaryCat1Loaded: false,
  secondaryCat2Loaded: false,
  secondaryCat3Loaded: false,
  secondaryCat4Loaded: false,
  secondaryCategoriesError: false,
  secondaryICDCode1: null,
  secondaryICDCode2: null,
  secondaryICDCode3: null,
  secondaryICDCode4: null,
  secondaryICDCodeCategories1: List(),
  secondaryICDCodeCategories2: List(),
  secondaryICDCodeCategories3: List(),
  secondaryICDCodeCategories4: List(),
  secondaryICDText1: null,
  secondaryICDText2: null,
  secondaryICDText3: null,
  secondaryICDText4: null,
  title: null,
  workflowLoaded: false,
})

export const Imo = Record({
  problems: Map(),
  problemsLoaded: false,
  requestId: null,
  searchCriteria: Map(),
  selectedProblems: List(),
  sessionId: null,
  suggestions: [],
  problemSelected: null,
})

const transformSuggestions = (suggestions: Suggestion[]) =>
  suggestions.map((suggestion: Suggestion) => suggestion.suggestionText)

export const transformImoSearch = (search: any) =>
  Imo({
    problemsLoaded: false,
    sessionId: search.sessionId,
    requestId: search.requestId,
    suggestions: transformSuggestions(search.suggestions),
  })

export const transformImoProblems = (imoProblems: any) =>
  imoProblems.items.map((imoProblem: any) =>
    ImoProblem({
      additionalICDCodes: imoProblem?.SECONDARY_ICD10_CODE1 ? true : false,
      categories: List(),
      categoriesLoaded: false,
      enabledModifiers: List(),
      icd10Code: imoProblem?.ICD10CM_CODE,
      id: generateId(),
      imoLexicalCode: imoProblem?.IMO_LEXICAL_CODE,
      modifierCombinations: Map(),
      modifierGroups: List(),
      modifierWorkflowNeeded:
        imoProblem?.POST_COORD_LEX_FLAG === '3' ? true : false,
      title: imoProblem?.title,
      secondaryICDCode1: imoProblem?.SECONDARY_ICD10_CODE1,
      secondaryICDText1: imoProblem?.SECONDARY_ICD10_TEXT1,
      secondaryICDCode2: imoProblem?.SECONDARY_ICD10_CODE2,
      secondaryICDText2: imoProblem?.SECONDARY_ICD10_TEXT2,
      secondaryICDCode3: imoProblem?.SECONDARY_ICD10_CODE3,
      secondaryICDText3: imoProblem?.SECONDARY_ICD10_TEXT3,
      secondaryICDCode4: imoProblem?.SECONDARY_ICD10_CODE4,
      secondaryICDText4: imoProblem?.SECONDARY_ICD10_TEXT4,
      workflowLoaded: false,
    })
  )

export const transformImoModifiers = (imoModifiers: Modifier[]) =>
  imoModifiers.map(({ title, code, combinations }: Modifier) =>
    ImoModifier({
      title,
      code,
      combinations,
      disabled: false,
    })
  )

export const transformImoModifierGroups = (
  imoModifierGroups: any,
  modifierCombinations: any
) =>
  imoModifierGroups.map(({ type, modifiers, title }: ModifierGroup) =>
    ImoModifierGroup({
      type,
      modifiers: transformImoModifiers(modifiers),
      title,
      enabledModifiers: getCombinations(modifierCombinations),
    })
  )

export const transformImoModifierCombinations = (modifierCombinations: any) =>
  Object.entries(modifierCombinations).map(([key, combination]: any) =>
    ImoModifierCombination({
      id: key,
      result: transformImoWorkflowResult(combination?.item),
      allowedModifiers: combination?.allowedModifiers,
    })
  )

export const transformImoWorkflowResult = (imoProblem: any) =>
  ImoProblem({
    additionalICDCodes: imoProblem?.SECONDARY_ICD10_CODE1 ? true : false,
    categories: List(),
    categoriesLoaded: false,
    enabledModifiers: List(),
    icd10Code: imoProblem?.ICD10CM_CODE,
    id: generateId(),
    imoLexicalCode: imoProblem?.code,
    modifierCombinations: Map(),
    modifierGroups: List(),
    modifierWorkflowNeeded:
      imoProblem?.POST_COORD_LEX_FLAG === '3' ? true : false,
    title: imoProblem?.title,
    secondaryICDCode1: imoProblem?.SECONDARY_ICD10_CODE1,
    secondaryICDText1: imoProblem?.SECONDARY_ICD10_TEXT1,
    secondaryICDCode2: imoProblem?.SECONDARY_ICD10_CODE2,
    secondaryICDText2: imoProblem?.SECONDARY_ICD10_TEXT2,
    secondaryICDCode3: imoProblem?.SECONDARY_ICD10_CODE3,
    secondaryICDText3: imoProblem?.SECONDARY_ICD10_TEXT3,
    secondaryICDCode4: imoProblem?.SECONDARY_ICD10_CODE4,
    secondaryICDText4: imoProblem?.SECONDARY_ICD10_TEXT4,
    workflowLoaded: false,
  })

export const transformImoWorkflow = ({
  code,
  modifierGroups,
  modifierCombinations,
}: any) =>
  ImoWorkflow({
    id: code,
    modifierGroups: transformImoModifierGroups(
      modifierGroups,
      modifierCombinations
    ),
    modifierCombinations: transformImoModifierCombinations(
      modifierCombinations
    ),
    enabledModifiers: List(),
    workflowLoaded: true,
  })

export const transformProblemInformation = (categories: any) =>
  categories.map(
    ({
      category,
      icd10Code,
      problemCategoryId,
      problemType,
      problemTypeId,
      subcategory,
    }: any) =>
      ImoCategory({
        category,
        icd10Code,
        problemCategoryId,
        problemType,
        problemTypeId,
        subcategory,
      })
  )

export const transformProblemTypes = (problemTypes: any) =>
  problemTypes
    .map(({ icd10Codes }: any) =>
      icd10Codes.map(({ problemTypeId, icd10Definition, isDeleted }: any) =>
        ImoDiagnosis({
          icd10Code: icd10Definition.code,
          description: icd10Definition.description,
          problemTypeId,
          isDeleted: isDeleted,
        })
      )
    )
    .flat()

export const transformImoSelectedProblem = (imoProblem: any) =>
  ImoProblem({
    additionalICDCodes: imoProblem.additionalICDCodes,
    categories: imoProblem.categories,
    enabledModifiers: imoProblem.enabledModifiers,
    icd10Code: imoProblem?.icd10Code,
    id: imoProblem?.id,
    imoLexicalCode: imoProblem?.imoLexicalCode,
    modifierCombinations: imoProblem?.modifierCombinations,
    modifierGroups: imoProblem?.modifierGroups,
    modifierWorkflowNeeded: imoProblem?.modifierWorkflowNeeded,
    title: imoProblem?.title,
    diagnoses: imoProblem?.diagnoses,
    secondaryICDCode1: imoProblem?.secondaryICDCode1,
    secondaryICDText1: imoProblem?.secondaryICDText1,
    secondaryICDCode2: imoProblem?.secondaryICDCode2,
    secondaryICDText2: imoProblem?.secondaryICDText2,
    secondaryICDCode3: imoProblem?.secondaryICDCode3,
    secondaryICDText3: imoProblem?.secondaryICDText3,
    secondaryICDCode4: imoProblem?.secondaryICDCode4,
    secondaryICDText4: imoProblem?.secondaryICDText4,
    workflowLoaded: false,
  })
