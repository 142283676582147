import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'healthplanList'

export const getHealthplanList = pipe(getRoot, get(key))

export const fetchHealthplans = Request({
  typePrefix: key,
  typeBase: 'FETCH_HEALTHPLAN_LIST',
  operation: () => AspireAPI.get(`v1/health_plan/accounts`),
  messages: {
    failed: 'There was a problem fetching the healthplan list',
  },
  transform: data => data.map(item => ({ label: item.name, value: item.sfid })),
})

export default createReducer(key, null, {
  [fetchHealthplans.SUCCEEDED]: (_state, { payload }) => payload,
})
