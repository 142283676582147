import { List, Record, Set } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { formatPhone, normalizePhone } from '../utils/format'
import { callLogCleared, getRoot } from './common/shared'

export const INBOUND = 'Inbound'
export const OUTBOUND = 'Outbound'

const CALL_LOG = 'callLog'

const Disposition = Record({
  disposition: null,
  dispositionId: null,
  phoneNumber: null,
  subdisposition: null,
  subDispositionId: null,
})

export const CallLog = Record({
  activeContact: null,
  attemptCount: null,
  attemptId: null,
  callOrigin: OUTBOUND,
  createdAt: null,
  createdBy: null,
  dispositions: List(),
  intentId: 0,
  lastMessageDate: null,
  lastUnsuccessfulVisitDate: null,
  patientId: null,
  patientStatus: null,
  programEnrolled: null,
  skippedContacts: Set(),
})

const transformResponse = data => data[0] || {}

const transformCallLogStatus = ({
  lastMessageDate,
  lastUnsuccessfulVisitDate,
  ...data
}) => ({
  ...data,
  lastMessageDate: lastMessageDate && new Date(lastMessageDate),
  lastUnsuccessfulVisitDate:
    lastUnsuccessfulVisitDate && new Date(lastUnsuccessfulVisitDate),
})

export const fetchCallLogStatus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CALL_LOG_STATUS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`v1/calldispositions/${patientId}/log_call_status`),
  transform: pipe(transformResponse, transformCallLogStatus),
  messages: { failed: 'There was an issue fetching the call log status' },
})

export const fetchCallAttempt = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CALL_ATTEMPT',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`v1/calldispositions/${patientId}/call_attempt`),
  transform: transformResponse,
  messages: { failed: 'There was an issue fetching the call attempt' },
})

export const saveCall = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_CALL',
  requestParams: [
    'patientId',
    'callLog',
    'phoneNumber',
    'dispositionId',
    'subDispositionId',
    'disposition',
    'notes',
  ],
  operation: (
    patientId,
    callLog,
    dispositionId,
    subDispositionId,
    disposition,
    notes
  ) =>
    AspireAPI.post(`v1/calldispositions/${patientId}/call`, {
      callDate: new Date().toISOString(),
      attemptId: callLog.attemptId,
      intentId: callLog.intentId,
      callOrigin: callLog.callOrigin,
      phoneNumber: normalizePhone(callLog.activeContact.phoneNumber),
      dispositionId,
      subDispositionId,
      disposition,
      notes,
    }),
  transform: transformResponse,
  messages: {
    succeeded: 'Successfully saved call',
    failed: 'There was an issue saving this call',
  },
})

export const completeCallAttempt = Request({
  typePrefix: rootKey,
  typeBase: 'COMPLETE_CALL_ATTEMPT',
  requestParams: ['patientId', 'callLog'],
  operation: (patientId, { attemptId, callOrigin, patientStatus }) =>
    AspireAPI.post(`v1/calldispositions/${patientId}/call_attempt`, {
      attemptId,
      callOrigin,
      patientStatus,
    }),
  transform: transformResponse,
  messages: {
    succeeded: 'Successfully completed call attempt',
    failed: 'There was an issue completing the call attempt',
  },
})

const creator = scopedCreator(rootKey)
export const callLogInitialized = creator('CALL_LOG_INITIALIZED', [
  'patientId',
  'patientStatus',
  'programEnrolled',
])
export const originChanged = creator('ORIGIN_CHANGED', ['value'])
export const intentChanged = creator('INTENT_CHANGED', ['value'])
export const contactSelected = creator('CONTACT_SELECTED', ['contact'])
export const contactCleared = creator('CONTACT_CLEARED', false)
export const contactSkipped = creator('CONTACT_SKIPPED', ['phoneNumber'])

const transformDispositions = dispositions =>
  List(
    dispositions.map(({ phoneNumber, ...disposition }) =>
      Disposition({
        phoneNumber: formatPhone(phoneNumber),
        ...disposition,
      })
    )
  )

const initState = CallLog()
export default createReducer(CALL_LOG, initState, {
  [callLogCleared]: () => initState,
  [callLogInitialized]: (_state, { payload }) => CallLog(payload),
  [originChanged]: (state, { payload: { value } }) =>
    state.set('callOrigin', value ? INBOUND : OUTBOUND),
  [intentChanged]: (state, { payload: { value } }) =>
    state.set('intentId', Number(value)),
  [contactSelected]: (state, { payload: { contact } }) => {
    const skippedContacts = state.get('skippedContacts')

    return state.merge({
      activeContact: contact,
      skippedContacts: skippedContacts.delete(contact.phoneNumber),
    })
  },
  [contactCleared]: state => state.set('activeContact', null),
  [contactSkipped]: (state, { payload: { phoneNumber } }) =>
    state.update('skippedContacts', skippedContacts =>
      skippedContacts.add(phoneNumber)
    ),
  [fetchCallLogStatus.SUCCEEDED]: (
    state,
    { payload: { attemptCount, lastMessageDate, lastUnsuccessfulVisitDate } }
  ) =>
    state.merge({
      attemptCount,
      lastMessageDate,
      lastUnsuccessfulVisitDate,
    }),
  [fetchCallAttempt.SUCCEEDED]: (state, { payload }) => {
    const {
      attemptId,
      createdDate,
      createdBy,
      intent: intentId,
      dispositions,
      inbound,
    } = payload
    let overrides = {}

    if (intentId) {
      overrides = { ...overrides, intentId }
    }

    if (dispositions) {
      overrides = {
        ...overrides,
        callOrigin: inbound ? INBOUND : OUTBOUND,
        dispositions: transformDispositions(dispositions),
      }
    }

    return state.merge({
      attemptId,
      createdAt: new Date(createdDate),
      createdBy,
      ...overrides,
    })
  },
  [saveCall.SUCCEEDED]: (state, { payload }) => {
    const { attemptId, createdDate, createdBy, dispositions } = payload

    return state.merge({
      attemptId,
      createdAt: new Date(createdDate),
      createdBy,
      dispositions: transformDispositions(dispositions),
      activeContact: null,
    })
  },
  [completeCallAttempt.SUCCEEDED]: () => initState,
})

export const getCallLog = pipe(getRoot, get(CALL_LOG))

export const isCallLogActive = state =>
  Boolean(getCallLog(state).get('patientId'))
