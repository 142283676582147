import React from 'react'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { pharmacyColumns } from '../../utils/pharmacyConstants'
import PharmacySearchOptionsDefaults from '../../utils/pharmacySearchOptionsDefaults'
import ActionItem from '../ActionItem'
import PharmacySearchForm from './PharmacySearchForm'
import PharmacySearchOptions from './PharmacySearchOptions'

const styles = ({ spacing, palette }) => ({
  root: {
    width: '100%',
    clear: 'both',
  },
  input: {
    margin: {
      left: spacing(1),
      right: spacing(1),
    },
  },
  searchResults: {
    '& thead': {
      '& tr': {
        '& th': {
          '&:nth-child(1)': {
            width: '25%',
          },
          '&:nth-child(2)': {
            width: '20%',
          },
          '&:nth-child(4)': {
            width: '8%',
          },
          '&:nth-child(5)': {
            width: '8%',
          },
        },
      },
    },
  },
  floatRight: {
    float: 'right',
    position: 'relative',
    bottom: spacing(2),
  },
  flex: {
    flex: 1,
  },
  searchOptionsLogo: {
    color: palette.secondary.main,
  },
})

const defaultSearchOptions = new PharmacySearchOptionsDefaults()

class PharmacySearch extends React.Component {
  state = {
    searchOptionsOpen: false,
    searchOptionsValue: defaultSearchOptions,
  }

  toggleSearchOptions = () => {
    this.setState(({ searchOptionsOpen }) => ({
      searchOptionsOpen: !searchOptionsOpen,
    }))
  }

  updateSearchOptions = event => {
    const { value: property, checked: value } = event.target
    this.setState(prevState => ({
      searchOptionsValue: prevState.searchOptionsValue.set(property, value),
    }))
  }

  resetSearchOptionDefaults = () =>
    this.setState({
      searchOptionsValue: defaultSearchOptions,
    })

  searchRequested = formValues =>
    this.props.searchRequested({
      ...formValues.toJS(),
      ...this.state.searchOptionsValue.toJS(),
    })

  selectPatientPreferredPharmacy = pharmacy =>
    this.props.selectPatientPreferredPharmacy({
      ...pharmacy,
      patientId: this.props.patientId,
    })

  buttonRef = ref => (this.searchOptionsButton = ref)

  rowActions = ({ row }) => (
    <ActionItem
      toolTip="Set As Patient's Preferred Pharmacy"
      icon={
        row.ncpdpId === this.props.patientPreferredPharmacyId
          ? 'check'
          : 'add_circle_outline'
      }
    />
  )

  get notDefault() {
    return !defaultSearchOptions.equals(this.state.searchOptionsValue)
  }

  render() {
    const {
      classes,
      searchResults,
      reset,
      patientPreferredPharmacyId,
      isCaseMgmt,
      ...props
    } = this.props
    const { searchOptionsOpen, searchOptionsValue } = this.state

    return isCaseMgmt ? null : (
      <React.Fragment>
        <DevXTitleHeader title="Select New Patient Preferred Pharmacy">
          <ActionItem
            toolTip="Clear Pharmacy Search Fields"
            icon="clear"
            onClick={reset}
          />
          <ActionItem
            toolTip="Pharmacy Search Options"
            icon="filter_list"
            buttonRef={this.buttonRef}
            className={this.notDefault ? classes.searchOptionsLogo : null}
            onClick={this.toggleSearchOptions}
          />
        </DevXTitleHeader>
        <Paper classes={{ root: classes.root }}>
          <PharmacySearchForm
            className={classes.input}
            onSubmit={this.searchRequested}
            {...props}
          />
          <DevXGrid
            key={patientPreferredPharmacyId}
            className={classes.searchResults}
            rows={searchResults}
            columns={pharmacyColumns}
            searchable
            sortable
            groupable
            rowActions={this.rowActions}
            rowActionsCount={1}
            pageable
            searchPlaceholder="Filter Results"
            selectable
            selectSingleRow
            highlightSelectedRows
            onSelect={this.selectPatientPreferredPharmacy}
          />
        </Paper>

        <PharmacySearchOptions
          open={searchOptionsOpen}
          onClose={this.toggleSearchOptions}
          anchorEl={this.searchOptionsButton}
          pharmacySearchOptionsValue={searchOptionsValue}
          onChange={this.updateSearchOptions}
          resetDefaults={this.resetSearchOptionDefaults}
        />
      </React.Fragment>
    )
  }
}

PharmacySearch.propTypes = {
  classes: PropTypes.object.isRequired,
  patientId: PropTypes.string,
  isCaseMgmt: PropTypes.bool,
  patientPreferredPharmacyId: PropTypes.string,
  reset: PropTypes.func.isRequired,
  searchRequested: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  selectPatientPreferredPharmacy: PropTypes.func.isRequired,
}

export default withStyles(styles)(PharmacySearch)
