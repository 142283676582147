import React from 'react'
import PropTypes from '~/utils/propTypes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  column: {
    flex: ({ flex }) => flex,
    '& > *:not(:last-child)': {
      marginBottom: spacing(4),
    },
  },
}))

const Column = props => {
  const { children } = props
  const classes = useStyles(props)

  return <div className={classes.column}>{children}</div>
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
  flex: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
}

Column.defaultProps = {
  flex: '1',
}

export default Column
