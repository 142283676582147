import { get } from 'lodash'
import React, { useCallback, useState } from 'react'
import OnResize from './OnResize'

export default Component =>
  function WithWidth(props) {
    const [width, setWidth] = useState()

    const callback = useCallback(
      entries =>
        entries.forEach(e => setWidth(get(e, ['contentRect', 'width']))),
      [setWidth]
    )

    return (
      <OnResize callback={callback}>
        <Component {...props} width={width} />
      </OnResize>
    )
  }
