import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { TextField } from '~/components/fields'
import { Location, LocationSearch } from '~/features/locationSearch'
import { useAction, useToggle } from '~/hooks'
import { formatAddress } from '~/utils/format'
import { Button, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getPatientInfo, savePatientAddress } from '../../../data/patientInfo'

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(1),
  },
  apartment: {
    margin: spacing(1, 0),
  },
}))

const getCoords = location =>
  `${location.latitude.toFixed(10)}, ${location.longitude.toFixed(10)}`

const ChangeAddressButton = props => {
  const classes = useStyles()

  const patientInfo = useSelector(getPatientInfo)
  const savePatientAddressRequested = useAction(savePatientAddress.requested)

  const [location, setLocation] = useState(Location())
  const [open, toggleOpen, untoggleOpen] = useToggle()

  const onApartmentChange = value => {
    setLocation(location.set('apartment', value))
  }

  const onSave = () => {
    savePatientAddressRequested(
      patientInfo.id,
      location.set('address', formatAddress(location))
    )
    untoggleOpen()
  }

  const onClose = () => {
    setLocation(patientInfo.location)
    untoggleOpen()
  }

  useEffect(() => {
    setLocation(patientInfo.location)
  }, [patientInfo.location, setLocation])

  return patientInfo.location.address ? (
    <React.Fragment>
      <Button
        {...props}
        variant="contained"
        color="primary"
        onClick={toggleOpen}
      >
        <Icon className={classes.icon} fontSize="small">
          contact_mail
        </Icon>
        Change Address
      </Button>

      <ActionDialog
        mode="save"
        open={open}
        title="Change Address"
        onSave={onSave}
        onClose={onClose}
        disableAction={!location.address}
        maxWidth="sm"
        fullWidth
      >
        <LocationSearch location={location} onChange={setLocation} />

        <TextField
          className={classes.apartment}
          label="Apartment/Unit"
          value={location.apartment}
          onChange={onApartmentChange}
          fullWidth
        />

        {location && location.longitude && location.latitude && (
          <Typography variant="subtitle1">
            {`Coordinates: ${getCoords(location)}`}
          </Typography>
        )}
      </ActionDialog>
    </React.Fragment>
  ) : null
}

export default ChangeAddressButton
