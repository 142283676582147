import { Map } from 'immutable'
import createReducer from '~/utils/createReducer'
import { scopedCreator } from '~/utils/data'
import rootKey from '../key'

// Key
export const key = 'encountersFilter'

// Actions
const creator = scopedCreator(rootKey)

export const encountersFiltersAllSelected = creator(
  'ENCOUNTERS_FILTERS_ALL_SELECTED',
  ['encounterTypes']
)

export const encountersFilterChanged = creator('ENCOUNTERS_FILTER_CHANGED', [
  'category',
])

export const encountersFilterReset = creator('ENCOUNTERS_FILTER_RESET', false)

// Reducer
const setAllKeys = (_state, { payload: { encounterTypes } }) => {
  const allKeys = encounterTypes.map(type => ({ [type]: true }))

  return Map(Object.assign({}, ...allKeys))
}

const setOrDeleteKey = (state, { payload: { category } }) =>
  state.has(category) ? state.delete(category) : state.set(category, true)

export default createReducer(key, Map(), {
  [encountersFiltersAllSelected]: setAllKeys,
  [encountersFilterChanged]: setOrDeleteKey,
  [encountersFilterReset]: () => Map(),
})
