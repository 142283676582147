import { get } from 'lodash/fp'
import { ofType } from 'redux-observable'
import {
  distinctUntilKeyChanged,
  filter,
  groupBy,
  map,
  mergeMap,
  pluck,
} from 'rxjs/operators'
import {
  fetchEpisodesOfCare,
  includeToggleClicked,
} from '../data/episodesOfCare'

const request = ({ ownerId, status }) =>
  fetchEpisodesOfCare.requested({ ownerId, status: [status] })

export default action$ => {
  return action$.pipe(
    ofType(includeToggleClicked),
    pluck('payload'),
    filter(get('include')),
    groupBy(get('status')),
    mergeMap(group =>
      group.pipe(distinctUntilKeyChanged('ownerId'), map(request))
    )
  )
}
