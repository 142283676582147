import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  attemptToSaveSelectedPolygon,
  loadSavedPolygon,
  saveSelectedPolygon,
} from '../data/selectedPolygon'
import showError from '../utils/showError'

const draftPolygonSaved = action$ =>
  action$.pipe(
    ofType(attemptToSaveSelectedPolygon),
    pluck('payload'),
    mergeMap(({ polygon, projectId }) =>
      loading(
        from(saveSelectedPolygon(projectId, polygon)).pipe(
          map(({ create_update_polygon }) =>
            loadSavedPolygon(create_update_polygon)
          ),
          catchError(showError('Failed to save NP.'))
        )
      )
    )
  )

export default draftPolygonSaved
