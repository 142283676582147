import { List, Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const CROSS_COVERAGE = 'crossCoverage'

const VisitInfo = Record({
  date: null,
  maxVisits: null,
  followUpVisits: null,
  initialVisits: null,
  visitUnits: null,
  unconfirmedInitialVisits: null,
  placeholders: null,
})

const Reminder = Record({
  id: null,
  appId: null,
  patientId: null,
  patientName: null,
  description: null,
  dueDate: null,
})

const HealthPlanAlert = Record({
  patientId: null,
  patientName: null,
  invoiceDay: null,
  previousVisited: false,
})

const OpenTOCTask = Record({
  taskId: null,
  taskStatus: null,
  patientId: null,
  patientName: null,
})

const CrossCoverageAPP = Record({
  id: null,
  name: null,
  market: null,
  title: null,
  location: {},
  activeCount: 0,
  referredCount: 0,
  overdueCount: 0,
  comingDueCount: 0,
  directReferralsNotCalledCount: 0,
  overdueToCallCount: 0,
  visits: List(),
  reminders: List(),
  healthPlanAlerts: List(),
  openTOCTasks: List(),
})

const transformSelectedAPPs = (data: any) => (data ? data.npList : [])

const transformVisits = (data: any) =>
  List(
    data.map(
      ({
        appointmentDate: date,
        maxVisits,
        followupVisits: followUpVisits,
        initialVisits,
        visitUnits,
        unconfirmedIvs: unconfirmedInitialVisits,
        placeholders,
      }: any) =>
        VisitInfo({
          date,
          maxVisits,
          followUpVisits,
          initialVisits,
          visitUnits,
          unconfirmedInitialVisits,
          placeholders,
        })
    )
  )

const transformReminders = (data: any) => List(data.map(Reminder))

const transformHealthPlanAlerts = (data: any) => List(data.map(HealthPlanAlert))

const transformOpenTOCTasks = (data: any) =>
  List(
    data.map(
      ({
        id: taskId,
        status: taskStatus,
        patient: {
          demographics: { id: patientId, name: patientName },
        },
      }: any) =>
        OpenTOCTask({
          taskId,
          taskStatus,
          patientId,
          patientName,
        })
    )
  )

const transformCrossCoverageAPP = (data: any) => {
  const {
    npInfo: { npId: id, name, market, title, location },
    patientInfo: {
      active: activeCount = 0,
      referred: referredCount = 0,
      overdue: overdueCount = 0,
    },
    schedulingSummary: {
      comingDueCount = 0,
      directReferralsNotCalled: directReferralsNotCalledCount = 0,
      overdueToCallCount = 0,
    },
    visitInfo: visits,
  } = data[0]
  return CrossCoverageAPP({
    id,
    name,
    market,
    title,
    location,
    activeCount,
    referredCount,
    overdueCount,
    comingDueCount,
    directReferralsNotCalledCount,
    overdueToCallCount,
    visits: transformVisits(visits),
  })
}
export const fetchUserAPPs = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_USER_APPS',
  requestParams: ['userId'],
  operation: (userId: any) => AspireAPI.get(`v1/user/${userId}/app/scheduling`),
  transform: transformSelectedAPPs,
  messages: {
    failed: "There was an issue fetching this user's APPs",
  },
})
export const fetchCrossCoverageInfo = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CROSS_COVERAGE_INFO',
  requestParams: ['appId'],
  operation: (appId: any) =>
    AspireAPI.get(`np_scheduling/dashboard_info/${appId}`),
  transform: transformCrossCoverageAPP,
  messages: {
    failed: 'There was an issue fetching information for this APP',
  },
})
export const fetchReminders = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_REMINDERS',
  requestParams: ['userId', 'appIds'],
  operation: (userId: any, appIds: any) =>
    AspireAPI.get('np_scheduling/reminder', {
      params: {
        user_id: userId,
        np_ids: appIds,
      },
    }),
  transform: transformReminders,
  messages: {
    failed: 'There was an issue fetching reminders',
  },
})
export const deleteReminder = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_REMINDER',
  requestParams: ['appId', 'id'],
  operation: (_: any, id: any) => AspireAPI.delete(`reminder/${id}`),
  messages: {
    succeeded: 'Successfully deleted reminder',
    failed: 'There was an issue deleting the reminder',
  },
})
export const fetchHealthPlanAlerts = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_HEALTH_PLAN_ALERTS',
  requestParams: ['appId'],
  operation: (appId: any) =>
    AspireAPI.get(`np_scheduling/healthplan_alerts/${appId}`),
  transform: transformHealthPlanAlerts,
  messages: {
    failed: 'There was an issue fetching health plan alerts',
  },
})
export const fetchOpenTOCTasks = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_OPEN_TOC_TASKS',
  requestParams: ['appId'],
  operation: (appId: any) =>
    AspireAPI.get(`np_scheduling/open_toc_tasks/${appId}`),
  transform: transformOpenTOCTasks,
  messages: {
    failed: 'There was an issue fetching open TOC tasks',
  },
})
const initState = Map()
export default createReducer(CROSS_COVERAGE, initState, {
  [fetchUserAPPs.REQUESTED]: () => initState,
  [fetchUserAPPs.SUCCEEDED]: (_state: any, { payload }: any) =>
    Map(payload.map((id: any) => [id, CrossCoverageAPP({ id })])),
  [fetchCrossCoverageInfo.SUCCEEDED]: (
    state: any,
    {
      payload: {
        id,
        name,
        market,
        title,
        location,
        activeCount,
        referredCount,
        overdueCount,
        comingDueCount,
        directReferralsNotCalledCount,
        overdueToCallCount,
        visits,
      },
    }: any
  ) =>
    state.update(id, CrossCoverageAPP(), (app: any) =>
      app.merge({
        id,
        name,
        market,
        title,
        location,
        activeCount,
        referredCount,
        overdueCount,
        comingDueCount,
        directReferralsNotCalledCount,
        overdueToCallCount,
        visits,
      })
    ),
  [fetchReminders.SUCCEEDED]: (state: any, { payload }: any) =>
    payload.isEmpty()
      ? state
      : payload.reduce(
          (newState: any, reminder: any) =>
            newState.update(reminder.appId, CrossCoverageAPP(), (app: any) =>
              app.update('reminders', List(), (reminders: any) =>
                reminders.push(reminder)
              )
            ),
          state
        ),
  [deleteReminder.SUCCEEDED]: (state: any, { meta: { request } }: any) =>
    state.updateIn([request.payload.appId, 'reminders'], (reminders: any) =>
      reminders.filter((reminder: any) => reminder.id !== request.payload.id)
    ),
  [fetchHealthPlanAlerts.SUCCEEDED]: (state: any, { meta, payload }: any) =>
    state.update(meta.request.payload.appId, CrossCoverageAPP(), (app: any) =>
      app.set('healthPlanAlerts', payload)
    ),
  [fetchOpenTOCTasks.SUCCEEDED]: (state: any, { meta, payload }: any) =>
    state.update(meta.request.payload.appId, CrossCoverageAPP(), (app: any) =>
      app.set('openTOCTasks', payload)
    ),
})
export const getCrossCoverageAPPs = pipe(getRoot, get(CROSS_COVERAGE))
export const getCrossCoverageAPPsArray = createSelector(
  [getCrossCoverageAPPs],
  apps =>
    (apps as any).every(({ name }: any) => Boolean(name))
      ? (apps as any)
          .sortBy((app: any) => app.name)
          .toIndexedSeq()
          .toJS()
      : []
)
