import React from 'react'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Paper, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { OnCall as OnCallEvent } from '../data/onCalls'
import OnCallGrid from './OnCallGrid'

const styles = ({ spacing }) => ({
  gridPaper: {
    padding: spacing(2),
  },
  icon: { marginRight: spacing(1) },
  panelButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

class OnCall extends React.PureComponent {
  render() {
    const { classes, onCalls, onPanelOpen } = this.props

    return (
      <React.Fragment>
        <DevXTitleHeader title="On Call Events">
          <Tooltip title="Open Panel" placement="bottom">
            <IconButton onClick={onPanelOpen}>
              <Icon>chrome_reader_mode</Icon>
            </IconButton>
          </Tooltip>
        </DevXTitleHeader>
        <Paper className={classes.gridPaper}>
          <OnCallGrid onCalls={onCalls} />
        </Paper>
      </React.Fragment>
    )
  }
}

OnCall.propTypes = {
  classes: PropTypes.object.isRequired,
  onCalls: PropTypes.arrayOf(OnCallEvent).isRequired,
  onPanelOpen: PropTypes.func.isRequired,
}

export default withStyles(styles)(OnCall)
