import React, { Component } from 'react' // eslint-disable-line
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import mapPropTypes from './mapPropTypes'
import { createPropsToOptions } from './objectHelpers'
import withGoogle from './withGoogle'
import withMap from './withMap'

const propsToOptions = createPropsToOptions(props => ({
  preserveViewport: props.preserveViewport,
  suppressMarkers: props.suppressMarkers,
  polylineOptions: {
    strokeColor: props.strokeColor,
    strokeOpacity: props.strokeOpacity,
    strokeWeight: props.strokeWeight,
  },
}))

class Directions extends Component {
  constructor(props) {
    super(props)

    const options = propsToOptions(props)
    const renderer = new props.google.maps.DirectionsRenderer(options)
    const service = new props.google.maps.DirectionsService()

    this.state = { renderer, service }
  }

  componentDidMount() {
    this.renderDirections()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.preserveViewport !== this.props.preserveViewport ||
      prevProps.suppressMarkers !== this.props.suppressMarkers ||
      prevProps.strokeColor !== this.props.strokeColor ||
      prevProps.strokeOpacity !== this.props.strokeOpacity ||
      prevProps.strokeWeight !== this.props.strokeWeight
    ) {
      this.state.renderer.setOptions(propsToOptions(this.props, prevProps))
      this.state.renderer.setDirections(this.state.renderer.getDirections())
    }

    if (
      prevProps.origin !== this.props.origin ||
      prevProps.destination !== this.props.destination ||
      prevProps.waypoints !== this.props.waypoints
    ) {
      this.renderDirections(this.props)
    }
  }

  componentWillUnmount() {
    this.renderDirections({ ...this.props, origin: null, destination: null })
  }

  onDirectionsChanged = directions => {
    this.props.onDirectionsChanged && this.props.onDirectionsChanged(directions)
  }

  renderDirections = (
    { google, map, origin, destination, waypoints } = this.props
  ) => {
    const { renderer, service } = this.state

    if (origin && destination) {
      const request = {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints ? waypoints.map(location => ({ location })) : null,
      }

      service.route(request, (directions, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          renderer.setDirections(directions)
          renderer.setMap(map)

          this.onDirectionsChanged(directions)
        }
      })
    } else {
      renderer.setMap(null)
    }
  }

  render() {
    return null
  }
}

Directions.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  origin: mapPropTypes.latLng,
  destination: mapPropTypes.latLng,
  waypoints: PropTypes.arrayOf(mapPropTypes.latLng),
  preserveViewport: PropTypes.bool,
  suppressMarkers: PropTypes.bool,
  strokeColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  strokeWeight: PropTypes.number,
  onDirectionsChanged: PropTypes.func,
}

export default compose(withMap, withGoogle)(Directions)
