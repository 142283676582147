import React from 'react'
import { FieldValue } from '~/data/fieldValues'
import PropTypes from '~/utils/propTypes'
import { Input, Select } from '@material-ui/core'

class MultiSelectField extends React.Component {
  onChange = e => {
    const { onChange } = this.props
    const { value } = e.target

    return onChange(value.includes(null) ? [] : value, e)
  }

  onBlur = e => this.props.onBlur(e.target.value, e)

  render = () => {
    const {
      children,
      fullWidth,
      onBlur,
      onChange,
      options,
      MenuProps,
      value,
    } = this.props

    return (
      <Select
        fullWidth={fullWidth}
        input={<Input />}
        MenuProps={MenuProps}
        multiple
        onBlur={onBlur && this.onBlur}
        onChange={onChange && this.onChange}
        renderValue={selected =>
          selected.map(value => options.get(value).label).join(', ')
        }
        value={value}
      >
        {children}
      </Select>
    )
  }
}

MultiSelectField.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  MenuProps: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.mapOf(PropTypes.instanceOf(FieldValue)),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

export default MultiSelectField
