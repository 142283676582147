import { combineEpics } from 'redux-observable'
import { getImoProblemSuggestions } from './epics/getImoProblemSuggestions'
import { sendImoModifierSelected } from './epics/sendImoModifierSelected'
import {
  sendImoProblemSelected,
  sendImoWorkflowPresented,
} from './epics/sendImoProblemSelected'
import { sendImoSuggestionSelected } from './epics/sendImoSuggestionSelected'
import {
  updateProblemCategoriesStatus,
  updateSecondaryCategories,
  updateSecondaryProblemCategoriesStatus,
  updateSecondaryProblemDiagnosisStatus,
} from './epics/updateCategoriesStatus'
import {
  setImoEnabledModifier,
  setImoEnabledModifiers,
} from './epics/updateImoEnabledModifiers'
import { updateProblemsState } from './epics/updateProblemsStatus'
import { updateWorkflowStatus } from './epics/updateWorkflowStatus'

export default combineEpics(
  getImoProblemSuggestions,
  sendImoModifierSelected,
  sendImoProblemSelected,
  sendImoSuggestionSelected,
  sendImoWorkflowPresented,
  setImoEnabledModifier,
  setImoEnabledModifiers,
  updateProblemCategoriesStatus,
  updateProblemsState,
  updateSecondaryCategories,
  updateSecondaryProblemCategoriesStatus,
  updateSecondaryProblemDiagnosisStatus,
  updateWorkflowStatus
)
