import * as Toast from '~/utils/toast'
import { RequestError } from '~/resources/resource-strict'
import * as O from 'fp-ts/lib/Option'
import { payloadGet } from '~/utils/type'
import Aspire from '~/resources/aspire-strict'
import { Request } from '~/utils/Requestable'
import { Action as ReduxA, State as ReduxS } from '~/reducerT'
import { Action, PlaceOfService } from './types'
import { flow } from 'fp-ts/lib/function'
import { initial } from '@nll/datum/DatumEither'

// import * as t from 'io-ts'
// import * as Toast from '~/utils/toast'
// import { RequestError } from '~/resources/resource-strict'
// import * as O from 'fp-ts/lib/Option'
export const init = initial
export const fetchPlaceOfService = (encounterId: number) =>
  Request<PlaceOfService>(
    Aspire.get(
      `coding_dashboard/${encounterId}`,
      PlaceOfService.strictType.asDecoder()
    ),
    payload => ReduxA.of.PlaceOfService({ payload }),
    { reloader: ReduxS.lensFromProp('placeOfService').get }
  )

export const reducer: (_: Action) => (_: ReduxS) => ReduxS = flow(
  payloadGet,
  ReduxS.lensFromProp('placeOfService').set
)

// export const reducer = (action: Action) => (state: State) => {
//   switch (action.payload.type) {
//     case 'Fetch':
//       return { ...state, fetch: { type: 'Fetch', payload: action.payload } }
//     case 'Update':
//       return { ...state, update: { type: 'Update', payload: action.payload } }
//   }
// }

export const updatePlaceOfService = (encounterId: number) => (
  placeOfService: string
) =>
  Request<PlaceOfService>(
    Aspire.patch(
      `coding_dashboard/${encounterId}`,
      PlaceOfService.strictType.asDecoder(),
      { place_of_service: placeOfService }
    ),
    payload => ReduxA.of.PlaceOfService({ payload }),
    {
      reloader: x => x.placeOfService,
      toast: Toast.message(
        () => 'Successfully updated Place of Service',
        RequestError.matchStrict({
          ServerError: () => O.some('Place of service could not be updated'),
          FailedRequest: () => O.some('Place of service could not be updated'),
          DecodeError: () => O.none,
        })
      ),
    }
  )
