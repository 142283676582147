import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const APP_DASHBOARD_HOME = `${key}.home`
export const APP_DASHBOARD_CALENDAR = `${key}.calendar`
export const APP_DASHBOARD_EPISODES_OF_CARE = `${key}.episodesOfCare`
export const APP_DASHBOARD_RISK_GROUPS = `${key}.riskGroups`
export const APP_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const APP_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const APP_DASHBOARD_ORDERS_INDEX = `${key}.ordersIndex`
export const APP_DASHBOARD_ORDER_DETAIL = `${key}.orderDetail`
export const APP_DASHBOARD_METRICS = `${key}.metrics`
export const APP_DASHBOARD_APP_SCHEDULING_LIST = `${key}.appSchedulingList`
export const APP_DASHBOARD_CENSUS = `${key}.census`
export const APP_DASHBOARD_MY_LISTS = `${key}.myLists`
export const APP_DASHBOARD_HIGH_RISK_HUDDLE = `${key}.highRiskHuddle`
export const APP_DASHBOARD_ON_CALL = `${key}.onCall`
export const APP_DASHBOARD_LLOS = `${key}.llos`
export const APP_DASHBOARD_USER_TIMELINE = `${key}.userTimeline`

export const APP_DASHBOARD_ORDERS_ROUTES = [
  APP_DASHBOARD_ORDERS_INDEX,
  APP_DASHBOARD_ORDER_DETAIL,
]

export const APP_DASHBOARD_TASKS_ROUTES = [
  APP_DASHBOARD_TASKS_BY_USER,
  APP_DASHBOARD_TASK_DETAIL,
]

export default Router([
  Route(APP_DASHBOARD_HOME, '/:appId?/home'),
  Route(APP_DASHBOARD_CALENDAR, '/:appId?/calendar'),
  Route(APP_DASHBOARD_RISK_GROUPS, '/:appId?/risk-groups'),
  Route(APP_DASHBOARD_EPISODES_OF_CARE, '/:appId?/episodes-of-care'),
  Route(APP_DASHBOARD_TASKS_BY_USER, '/:appId?/tasks'),
  Route(APP_DASHBOARD_TASK_DETAIL, '/:appId?/tasks/:taskId'),
  Route(APP_DASHBOARD_ORDERS_INDEX, '/:appId?/orders'),
  Route(APP_DASHBOARD_ORDER_DETAIL, '/:appId?/orders/:orderId'),
  Route(APP_DASHBOARD_METRICS, '/:appId?/metrics'),
  Route(APP_DASHBOARD_CENSUS, '/:appId?/census'),
  Route(APP_DASHBOARD_APP_SCHEDULING_LIST, '/:appId?/app-list'),
  Route(APP_DASHBOARD_HIGH_RISK_HUDDLE, '/:appId?/high-risk-huddle'),
  Route(APP_DASHBOARD_MY_LISTS, '/:appId?/my-lists'),
  Route(APP_DASHBOARD_ON_CALL, '/:appId?/on_call'),
  Route(APP_DASHBOARD_LLOS, '/:appId?/llos'),
  Route(APP_DASHBOARD_USER_TIMELINE, '/:appId?/user-timeline'),
  Redirect(APP_DASHBOARD_HOME),
])
