import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import { patientInfoRequested } from '~/features/panelContent/epics/loadPatientInfoForPanel'
import { getPatientId } from '~/features/patientInfo/'
import { ORDER_DETAIL_FETCH_SUCCEEDED } from '../data/orderDetail'

export default (action$, state$) =>
  action$.pipe(
    ofType(ORDER_DETAIL_FETCH_SUCCEEDED),
    pluck('payload', 'order', 'patientId'),
    filter(patientId => getPatientId(state$.value) !== patientId),
    map(patientInfoRequested)
  )
