import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import { Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ACTIVE, getContacts } from '../../../data/contacts'
import ContactInfo from './ContactInfo'

const useStyles = makeStyles(({ spacing }) => ({
  divider: {
    margin: spacing(1, 0),
  },
}))

const OtherContacts = ({ currentNumber }) => {
  const classes = useStyles()

  const contacts = useSelector(getContacts)

  const filteredContacts = useMemo(
    () =>
      contacts.filter(
        ({ active, expired, phoneNumber }) =>
          active === ACTIVE && !expired && phoneNumber !== currentNumber
      ),
    [contacts]
  )

  return (
    <React.Fragment>
      <Typography variant="h6">Other Contacts</Typography>

      {filteredContacts.toIndexedSeq().map(contact => (
        <React.Fragment key={contact.phoneNumber}>
          <ContactInfo contact={contact} />

          <Divider className={classes.divider} />
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

OtherContacts.propTypes = {
  currentNumber: PropTypes.string.isRequired,
}

export default OtherContacts
