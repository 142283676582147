import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import Section from '~/apps/patientRecord/components/Section'
import DevXGrid from '~/components/DevXGrid'
import DisplayDate from '~/components/DisplayDate'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import { getResearchCases } from '../data/researchCases'
import ResearchCaseDetails from './ResearchCaseDetails'

const columns = [
  {
    name: 'status',
    title: 'Status',
  },
  {
    name: 'closedDate',
    title: 'Closed Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'createdDate',
    title: 'Created Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'createdBy',
    title: 'Created By',
  },
  {
    name: 'lastModifiedDate',
    title: 'Last Modified Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'lastModifiedBy',
    title: 'Last Modified By',
  },
]

const columnExtensions = [
  { columnName: 'closedDate', width: 140 },
  { columnName: 'createdDate', width: 140 },
  { columnName: 'lastModifiedDate', width: 175 },
]

const ResearchCasesGrid = ({ researchCases }) => {
  const rows = useMemo(() => researchCases.toArray(), [researchCases])

  return (
    <Section title="Research Cases">
      <Paper>
        <DevXGrid
          rows={rows}
          columns={columns}
          columnExtensions={columnExtensions}
          sortable
          detailComponent={ResearchCaseDetails}
        />
      </Paper>
    </Section>
  )
}

ResearchCasesGrid.propTypes = {
  researchCases: PropTypes.map.isRequired,
}

const mapStateToProps = state => ({
  researchCases: getResearchCases(state),
})

export default connect(mapStateToProps, null)(ResearchCasesGrid)
