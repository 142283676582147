import { combineEpics, ofType } from 'redux-observable'
import {
  catchError,
  ignoreElements,
  map,
  mergeMapTo,
  tap,
} from 'rxjs/operators'
import { showError } from '~/utils/alertOnError'
import { switchTo } from '~/utils/operators'
import {
  THEME_HOLIDAY_TYPE,
  THEME_TYPE,
  getItem,
  setPermanentItem,
} from '~/utils/storage'
import { appInitialized } from '../data/root'
import {
  SET_HOLIDAY_THEME,
  TOGGLE_LIGHTS,
  getHoliday,
  getTheme,
  setHolidayTheme,
  setTheme,
} from '../data/settings'

const toggle = (action$, state$) =>
  action$.pipe(
    ofType(TOGGLE_LIGHTS),
    switchTo(state$),
    map(getTheme),
    tap(theme => setPermanentItem(THEME_TYPE, theme.palette.type)),
    ignoreElements()
  )

const toggleHoliday = (action$, state$) =>
  action$.pipe(
    ofType(SET_HOLIDAY_THEME, TOGGLE_LIGHTS),
    switchTo(state$),
    map(getHoliday),
    tap(holiday => setPermanentItem(THEME_HOLIDAY_TYPE, holiday)),
    ignoreElements()
  )

const init = action$ =>
  action$.pipe(
    ofType(appInitialized),
    mergeMapTo(Promise.all([getItem(THEME_TYPE), getItem(THEME_HOLIDAY_TYPE)])),
    map(([themeType, themeHolidayType]) =>
      themeHolidayType ? setHolidayTheme(themeHolidayType) : setTheme(themeType)
    ),
    catchError(showError('Unable to load local theme settings'))
  )

export default combineEpics(init, toggle, toggleHoliday)
