import { AxiosError } from 'axios'
import { Map } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { Action, CarePlanGoal, Goal, Problem } from '../common/shared'
import {
  carePlanCleared,
  carePlanSet,
  fetchCarePlan,
  getCarePlan,
  mapCarePlanProblem,
  mapCarePlanProblemDetail,
} from './root'

// KEY
const PROBLEMS = 'problems'
const ACTIVE = 'active'
const INVALID = 'invalid'

// ACTIONS
const scoped = scopedCreator(rootKey)
export const problemsSet = scoped('PROBLEM_SET', ['problems'])
export const problemSaved = scoped('PROBLEM_SAVED', ['problem'])
export const goalSaved = scoped('GOAL_SAVED', ['problemId', 'goal'])
export const goalRemoved = scoped('GOAL_REMOVED', ['problemId', 'goalId'])
export const actionSaved = scoped('ACTION_SAVED', [
  'problemId',
  'goalId',
  'action',
])
export const actionRemoved = scoped('ACTION_REMOVED', [
  'problemId',
  'goalId',
  'actionId',
])
export const carePlanGoalSaved = scoped('CARE_PLAN_GOAL_SAVED', [
  'problemId',
  'carePlanGoal',
])
export const potentialProblemCarePlanSaved = scoped(
  'POTENTIAL_PROBLEM_CARE_PLAN_SAVED',
  ['problemId', 'carePlanGoals']
)
export const potentialProblemCarePlanRemoved = scoped(
  'POTENTIAL_PROBLEM_CARE_PLAN_REMOVED',
  ['problemId']
)
export const conditionProblemRemoved = scoped('CONDITION_PROBLEM_REMOVED', [
  'conditionId',
])
export const potentialProblemRemoved = scoped('POTENTIAL_PROBLEM_REMOVED', [
  'problemTypeId',
])
export const problemMigrated = scoped('PROBLEM_MIGRATED', [
  'oldProblemId',
  'newProblem',
])
export const problemDetailAdded = scoped('PROBLEM_DETAIL_ADDED', [
  'problemId',
  'problemDetail',
])
export const problemDetailRemoved = scoped('PROBLEM_DETAIL_REMOVED', [
  'problemId',
  'problemDetailId',
  'manualDelete',
])

// TRANSFORMERS
const transformMigratedProblems = ({ oldProblem, newProblem }: any) => ({
  oldProblem: mapCarePlanProblem(oldProblem),
  newProblem: mapCarePlanProblem(newProblem),
})

// REQUESTS
export const addProblem = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_PROBLEM',
  requestParams: ['patientId', 'problem'],
  operation: (patientId: any, problem: any) =>
    AspireAPI.post(`care_plan/problems/patients/${patientId}`, { problem }),
  transform: mapCarePlanProblem,
  messages: {
    failed: 'There was an issue creating this problem',
    succeeded: 'Problem successfully created',
  },
})

export const saveProblem = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PROBLEM',
  requestParams: ['problem'],
  operation: (problem: any) =>
    AspireAPI.put(`care_plan/problems/${problem.id}`, { problem }),
  transform: mapCarePlanProblem,
  messages: {
    failed: 'There was an issue saving this problem',
    succeeded: 'Problem successfully saved',
  },
})

export const migrateProblem = Request({
  typePrefix: rootKey,
  typeBase: 'MIGRATE_PROBLEM',
  requestParams: ['problemId', 'problemTypeId'],
  operation: (problemId: number, problemTypeId: number) =>
    AspireAPI.post(`care_plan/problems/${problemId}/migrate/${problemTypeId}`),
  transform: transformMigratedProblems,
  messages: {
    failed: 'There was an issue migrating this problem',
    succeeded: 'Problem successfully migrated',
  },
})

export const saveProblemDetail = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PROBLEM_DETAIL',
  requestParams: ['problemId', 'detail'],
  operation: (problemId: number, detail: any) =>
    AspireAPI.put(`care_plan/problems/${problemId}/details`, {
      detail,
    }),
  transform: mapCarePlanProblemDetail,
  messages: {
    failed: 'There was an issue saving this problem detail',
    succeeded: 'Problem detail successfully saved',
  },
})

export const deleteProblemDetail = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_PROBLEM_DETAIL',
  requestParams: ['problemId', 'detailId'],
  operation: (problemId: any, detailId: any) =>
    AspireAPI.delete(`care_plan/problems/${problemId}/details/${detailId}`),
  messages: {
    failed: 'There was an issue deleting this problem detail',
    succeeded: 'Problem detail successfully deleted',
  },
})

export const saveCarePlanGoal = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_CARE_PLAN_GOAL',
  requestParams: ['problemId', 'carePlanGoal'],
  operation: (problemId: number, carePlanGoal: CarePlanGoal) =>
    AspireAPI.put(`care_plan/problems/${problemId}/care_plan`, {
      carePlanGoal,
    }),
  messages: {
    failed: (error: AxiosError) =>
      error?.message || 'There was an issue saving this care plan',
    succeeded: 'Care plan successfully saved',
  },
})

const getGoalIndex = (state: any, problemId: number | string, goalId: string) =>
  state
    .getIn([problemId, 'goals'])
    .findIndex((goal: Goal) => goal.id === goalId)

const getActionIndex = (
  state: any,
  problemId: number | string,
  goalId: string,
  actionId: string
) => {
  const goalIndex = getGoalIndex(state, problemId, goalId)

  return state
    .getIn([problemId, 'goals', goalIndex, 'actions'])
    .findIndex((action: Action) => action.id === actionId)
}

// REDUCER
const initState = Map()
export default createReducer(PROBLEMS, initState, {
  // Assessment Actions
  // @ts-expect-error can we deal with the toString being automatically called?
  [carePlanCleared]: () => initState,
  // @ts-expect-error can we deal with the toString being automatically called?
  [problemsSet]: (_state: any, { payload: { problems } }: any) => problems,
  // @ts-expect-error can we deal with the toString being automatically called?
  [carePlanSet]: (_state: any, { payload: { problems } }: any) => problems,
  // @ts-expect-error can we deal with the toString being automatically called?
  [problemSaved]: (state: any, { payload: { problem } }: any) =>
    state.set(
      problem.id,
      problem.status === INVALID ? problem.set('rank', null) : problem
    ),
  // @ts-expect-error can we deal with the toString being automatically called?
  [goalSaved]: (state: any, { payload: { problemId, goal } }: any) =>
    state.updateIn([problemId, 'goals'], (goals: any) => {
      const index = getGoalIndex(state, problemId, goal.id)
      return index > -1 ? goals.set(index, goal) : goals.push(goal)
    }),
  // @ts-expect-error can we deal with the toString being automatically called?
  [goalRemoved]: (state: any, { payload: { problemId, goalId } }: any) => {
    const index = getGoalIndex(state, problemId, goalId)
    return state.deleteIn([problemId, 'goals', index])
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [actionSaved]: (
    state: any,
    { payload: { problemId, goalId, action } }: any
  ) => {
    const goalIndex = getGoalIndex(state, problemId, goalId)

    return state.updateIn(
      [problemId, 'goals', goalIndex, 'actions'],
      (actions: any) => {
        const actionIndex = getActionIndex(state, problemId, goalId, action.id)

        return actionIndex > -1
          ? actions.set(actionIndex, action)
          : actions.push(action)
      }
    )
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [actionRemoved]: (
    state: any,
    { payload: { problemId, goalId, actionId } }: any
  ) => {
    const goalIndex = getGoalIndex(state, problemId, goalId)
    const actionIndex = getActionIndex(state, problemId, goalId, actionId)

    return state.deleteIn([
      problemId,
      'goals',
      goalIndex,
      'actions',
      actionIndex,
    ])
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [conditionProblemRemoved]: (
    state: any,
    { payload: { conditionId } }: any
  ) => {
    const problem = state.find(
      (problem: Problem) => problem.conditionId === conditionId
    )

    return problem ? state.delete(problem.id) : state
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [potentialProblemRemoved]: (
    state: any,
    { payload: { problemTypeId } }: any
  ) => {
    const problem = state.find(
      (problem: Problem) =>
        problem.problemTypeId === problemTypeId && problem.isPotentialProblem
    )

    return problem ? state.delete(problem.id) : state
  },
  // @ts-expect-error can we deal with the toString being automatically called?
  [problemMigrated]: (
    state: any,
    { payload: { oldProblemId, newProblem } }: any
  ) =>
    state
      .update(oldProblemId, (problem: any) =>
        problem.merge({ status: INVALID, rank: null })
      )
      .set(newProblem.id, newProblem),
  // @ts-expect-error can we deal with the toString being automatically called?
  [problemDetailAdded]: (
    state: any,
    { payload: { problemId, problemDetail } }: any
  ) =>
    state.updateIn([problemId, 'details'], (details: any) => {
      const index = details.findIndex(
        (detail: any) => detail.id === problemDetail.id
      )

      return index > -1
        ? details.update(index, (detail: any) =>
            detail.merge({
              value: problemDetail.value,
              originalValue: detail.new
                ? null
                : detail.originalValue || detail.value,
              modified: true,
            })
          )
        : details.push(
            problemDetail.merge({
              modified: true,
              new: true,
            })
          )
    }),
  // @ts-expect-error can we deal with the toString being automatically called?
  [problemDetailRemoved]: (
    state: any,
    { payload: { problemId, problemDetailId, manualDelete } }: any
  ) =>
    state.updateIn([problemId, 'details'], (details: any) => {
      const index = details.findIndex(
        (detail: any) => detail.id === problemDetailId
      )
      const detail = details.get(index)

      return detail.new || manualDelete
        ? details.delete(index)
        : details.set(
            index,
            detail.merge({
              value: detail.originalValue,
              originalValue: null,
              modified: false,
            })
          )
    }),
  // @ts-expect-error can we deal with the toString being automatically called?
  [carePlanGoalSaved]: (
    state: any,
    { payload: { problemId, carePlanGoal } }: any
  ) =>
    state.updateIn([problemId, 'carePlanGoals'], (goals: CarePlanGoal[]) => {
      const exists = goals.some(goal => goal.id === carePlanGoal.id)

      return exists
        ? goals.map(goal => (goal.id === carePlanGoal.id ? carePlanGoal : goal))
        : goals.concat(carePlanGoal)
    }),
  // @ts-expect-error can we deal with the toString being automatically called?
  [potentialProblemCarePlanSaved]: (
    state: any,
    { payload: { problemId, carePlanGoals } }: any
  ) =>
    state.updateIn(
      [problemId, 'carePlanGoals'],
      (goals: CarePlanGoal[]): CarePlanGoal[] => {
        const autoPopulatedGoals: CarePlanGoal[] = carePlanGoals

        const newGoals = autoPopulatedGoals.filter(
          autoPopulatedGoal =>
            !goals.some(
              goal =>
                goal.status === 'active' &&
                goal.carePlanGoalTypeId === autoPopulatedGoal.carePlanGoalTypeId
            )
        )

        const updatedGoals = goals.map(goal => {
          const autoPopulatedGoal = autoPopulatedGoals.find(
            autoPopulatedGoal =>
              autoPopulatedGoal.carePlanGoalTypeId === goal.carePlanGoalTypeId
          )

          return goal.status === 'active' && !!autoPopulatedGoal
            ? {
                ...goal,
                dueDate: autoPopulatedGoal.dueDate,
                originalDueDate: goal.dueDate,
                autoPopulated: true,
              }
            : goal
        })

        return updatedGoals.concat(newGoals)
      }
    ),
  // @ts-expect-error can we deal with the toString being automatically called?
  [potentialProblemCarePlanRemoved]: (
    state: any,
    { payload: { problemId } }: any
  ) =>
    state.updateIn(
      [problemId, 'carePlanGoals'],
      (goals: CarePlanGoal[]): CarePlanGoal[] => {
        return goals
          .filter(goal => !goal.autoPopulated || goal.originalDueDate)
          .map(goal =>
            goal.originalDueDate
              ? {
                  ...goal,
                  dueDate: goal.originalDueDate,
                  originalDueDate: undefined,
                  autoPopulated: false,
                }
              : goal
          )
      }
    ),
  // Patient Record Actions
  [fetchCarePlan.SUCCEEDED]: (_state: any, { payload: { problems } }: any) =>
    problems,
  [addProblem.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set(payload.id, payload),
  [saveProblem.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set(payload.id, payload),
  [migrateProblem.SUCCEEDED]: (
    state: any,
    { payload: { oldProblem, newProblem } }: any
  ) => state.set(oldProblem.id, oldProblem).set(newProblem.id, newProblem),
  [saveProblemDetail.SUCCEEDED]: (state: any, { meta, payload }: any) => {
    const { problemId } = meta.request.payload
    const details = state.getIn([problemId, 'details'])
    const index = details.findIndex((detail: any) => detail.id === payload.id)

    return index > -1
      ? state.setIn([problemId, 'details', index], payload)
      : state.setIn([problemId, 'details'], details.push(payload))
  },
  [deleteProblemDetail.SUCCEEDED]: (state: any, { meta }: any) => {
    const { problemId, detailId } = meta.request.payload
    const index = state
      .getIn([problemId, 'details'])
      .findIndex((detail: any) => detail.id === detailId)

    return state.deleteIn([problemId, 'details', index])
  },
  [saveCarePlanGoal.SUCCEEDED]: (state: any, { payload }: any) =>
    state.updateIn(
      [payload.problemId, 'carePlanGoals'],
      (goals: CarePlanGoal[]) => {
        const exists = goals.some(goal => goal.id === payload.id)

        return exists
          ? goals.map(goal => (goal.id === payload.id ? payload : goal))
          : goals.concat(payload)
      }
    ),
})

// SELECTORS
export const getCarePlanProblems = pipe(getCarePlan, get(PROBLEMS))

// Get an associated problem for an ePAHAF condition
export const getAssociatedConditionProblem = createSelector(
  [
    getCarePlanProblems,
    (_state: any, icd10Code: string) => icd10Code,
    (_state: any, _icd10Code: string, conditionId: number) => conditionId,
  ],
  (problems: any, icd10Code: string, conditionId: number) =>
    problems.find(
      (problem: Problem) =>
        (problem.status === ACTIVE && problem.icd10Code === icd10Code) ||
        problem.conditionId === conditionId
    )
)
