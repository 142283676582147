import React from 'react'
import { InfoDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'

const DrugAlertDialog = ({ displayedAlert, onClick }) =>
  displayedAlert && (
    <InfoDialog open={true} title={displayedAlert.title} onClose={onClick}>
      <Typography>{displayedAlert.description}</Typography>
    </InfoDialog>
  )

DrugAlertDialog.propTypes = {
  displayedAlert: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

export default DrugAlertDialog
