import { OrderedMap, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error FIXME
import Request from '~/utils/Request'
// @ts-expect-error FIXME
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error FIXME
import { currentEventCleared, getEventsRoot } from './root'

const key = 'encounterTypes'

const creator = scopedCreator(key)

export const encounterTypesCleared = creator('ENCOUNTER_TYPES_CLEARED', false)

export const EncounterType = Record({
  type: null,
  label: null,
  modality: null,
  defaultVisitDuration: null,
})

export const fetchEncounterTypes = Request({
  typePrefix: key,
  typeBase: 'FETCH_ENCOUNTER_TYPES',
  requestParams: ['userId', 'patientId', 'dateOfService'],
  operation: (userId: any, patientId: any, dateOfService: any) =>
    AspireAPI.get('encounters/type', {
      params: {
        user_id: userId,
        patient_id: patientId,
        date_of_service: dateOfService,
        scheduling: true,
      },
    }),
  transform: into(EncounterType, 'type'),
  messages: {
    failed: 'There was a problem fetching encounter types',
  },
})

export default createReducer(key, OrderedMap(), {
  [fetchEncounterTypes.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [currentEventCleared]: () => OrderedMap(),
  // @ts-expect-error can we deal with the toString being automatically called?
  [encounterTypesCleared]: () => OrderedMap(),
})

export const getEncounterTypes = pipe(getEventsRoot, get(key))
