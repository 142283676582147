import { CSSProperties } from 'react'
import { colors, createMuiTheme } from '@material-ui/core'

type Type = 'light' | 'dark'

type CSSColor = CSSProperties['color']
const createChoose = (type: Type) => (
  light: CSSColor,
  dark: CSSColor
): CSSColor => (type === 'light' ? light : dark)

export const rawThemeWithType = (type: Type) => {
  const choose = createChoose(type)

  return {
    colors,
    palette: {
      type,
      holiday: null,
      primary: {
        light: colors.blue[300],
        main: colors.blue[500],
        dark: colors.blue[700],
      },
      secondary: {
        light: colors.orange[300],
        main: colors.orange[500],
        dark: colors.orange[700],
      },
      drawer: {
        header: {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
        },
        backgroundClosed: {
          backgroundColor: colors.blue[700],
        },
        backgroundOpen: {
          background: {
            color: colors.blue[700],
          },
        },
      },
      enrolledStatusBadge: {
        backgroundColor: choose('rgba(0, 0, 0, 0.125)', 'rgba(0, 0, 0, 0.25)'),
        color: choose('rgba(0, 0, 0, 0.87)', '#fff'),
      },
      accordion: {
        color: choose('rgba(0, 0, 0, 0.87)', '#fff'),
        detailsBackground: choose(colors.grey[200], colors.grey[800]),
        header: choose(colors.grey[200], '#3c3c3c'),
      },
      alertsAccordion: {
        backgroundColor: colors.red[700],
      },
      calendarHead: {
        backgroundColor: colors.blue[500],
      },
      calendarTitleText: {
        color: colors.blue[500],
      },
      patientInfoTag: {
        backgroundColor: colors.blue[700],
      },
      patientSearchProgramBadge: {
        backgroundColor: choose('rgba(0, 0, 0, 0.125)', 'rgba(0, 0, 0, 0.25)'),
        color: choose('rgba(0, 0, 0, 0.87)', '#fff'),
      },
      formPageTitle: {
        color: colors.blue[500],
      },
      tabSelected: {
        backgroundColor: colors.blue[700],
      },
      toolbarHeaderText: {
        color: colors.blue[500],
      },
      vitalsToolbarHeader: {
        backgroundColor: 'rgba(0, 0, 0, 0.125)',
      },
      vitalsChipContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.125)',
      },
      vitalsChipChosen: {
        backgroundColor: choose('#bdbdbd', '#616161'),
        color: choose('rgba(0, 0, 0, 0.87)', '#fff'),
      },
      notifications: {
        linkText: colors.orange[500],
      },
      severity: {
        error: {
          static: colors.red[700],
          dynamic: choose(colors.red[500], colors.red[300]),
          dynamicHighContrast: choose(colors.red['A700'], colors.red['A200']),
        },
        success: {
          static: colors.green[500],
          dynamic: choose(colors.lightGreen[500], colors.lightGreen[300]),
          dynamicHighContrast: choose(
            colors.lightGreen['A700'],
            colors.lightGreen['A400']
          ),
        },
        warning: {
          static: colors.orange[500],
          dynamic: choose(colors.orange[500], colors.orange[300]),
          dynamicHighContrast: choose(
            colors.orange['A700'],
            colors.orange['A200']
          ),
        },
        none: colors.grey[500],
      },
      contentText: {
        primaryLabel: choose(colors.blue[500], colors.blue[300]),
        secondaryLabel: choose(colors.orange[700], colors.orange[300]),
        infoLabel: choose(colors.blue[500], colors.blue[300]),
        paperHeader: choose(colors.blue[500], colors.blue[300]),
        faded: choose('rgba(0, 0, 0, 0.375)', 'rgba(255, 255, 255, 0.375)'),
      },
      shades: {
        almostDark: 'rgba(0, 0, 0, 0.05)',
        dark: 'rgba(0, 0, 0, 0.125)',
        darker: 'rgba(0, 0, 0, 0.25)',
        darkest: 'rgba(0, 0, 0, 0.375)',
        dynamicLight: choose('rgba(0, 0, 0, 0.1)', 'rgba(255, 255, 255, 0.1)'),
      },
      riskLevel: {
        1: choose(colors.red['A700'], colors.red['A200']),
        2: choose(colors.orange['A700'], colors.orange['A400']),
        3: choose(colors.yellow['A700'], colors.yellow['A400']),
        4: colors.grey[500],
      },
      caremoreRiskLevel: {
        1: choose(colors.red['A700'], colors.red['A200']),
        2: choose(colors.orange['A700'], colors.orange['A400']),
        3: choose(colors.yellow['A700'], colors.yellow['A400']),
        4: colors.grey[500],
      },
      aspireRiskLevel: {
        1: choose(colors.red['A700'], colors.red['A200']),
        2: choose(colors.yellow['A700'], colors.yellow['A400']),
        3: choose(colors.green['A700'], colors.green['A400']),
        4: colors.grey[500],
      },
      environments: {
        development: colors.purple[500],
        testing: colors.green[800],
        staging: colors.orange[800],
      },
      map: {
        geometry: choose(colors.grey['100'], colors.grey['900']),
        borders: choose(colors.grey['700'], colors.grey['300']),
        labelsTextFill: choose('#212121', '#FFFFFF'),
        labelsTextStroke: choose(colors.grey['100'], colors.grey['900']),
        poiGeometry: choose(colors.grey['200'], colors.grey['800']),
        roadGeometryFill: choose(colors.grey['300'], colors.grey['700']),
        waterGeometry: choose(colors.blueGrey['100'], colors.blueGrey['900']),
        waterLabelsTextFill: choose(
          colors.blueGrey['700'],
          colors.blueGrey['300']
        ),
        markers: {
          severity: {
            1: colors.deepPurple['A400'],
            2: colors.purple['A400'],
            3: colors.red['A700'],
            4: colors.orange['A700'],
            5: colors.yellow['700'],
            6: colors.green['500'],
            7: colors.blue['500'],
            8: colors.grey['500'],
          },
          stroke: choose(colors.common.black, colors.common.white),
        },
      },
    },
    overrides: {
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      },
      MuiSelect: {
        select: {
          '& option': {
            color: choose(colors.common.black, colors.common.white),
          },
        },
      },
      TableDetailCell: {
        active: {
          backgroundColor: choose(
            'rgba(0, 0, 0, 0.05)',
            'rgba(0, 0, 0, 0.375)'
          ),
        },
      },
    },
  }
}

const defaultThemeOptions = rawThemeWithType('light')
type AspireTheme = typeof defaultThemeOptions

declare module '@material-ui/core/styles/createPalette' {
  // We should move all of this stuff out of palette
  interface Palette {
    type: Type
    holiday: string
    accordion: AspireTheme['palette']['accordion']
    alertsAccordion: AspireTheme['palette']['alertsAccordion']
    calendarHead: AspireTheme['palette']['calendarHead']
    calendarTitleText: AspireTheme['palette']['calendarTitleText']
    contentText: AspireTheme['palette']['contentText']
    drawer: AspireTheme['palette']['drawer']
    enrolledStatusBadge: AspireTheme['palette']['enrolledStatusBadge']
    environments: AspireTheme['palette']['environments']
    formPageTitle: AspireTheme['palette']['formPageTitle']
    map: AspireTheme['palette']['map']
    notifications: AspireTheme['palette']['notifications']
    patientInfoTag: AspireTheme['palette']['patientInfoTag']
    patientSearchProgramBadge: AspireTheme['palette']['patientSearchProgramBadge']
    riskLevel: AspireTheme['palette']['riskLevel']
    caremoreRiskLevel: AspireTheme['palette']['caremoreRiskLevel']
    aspireRiskLevel: AspireTheme['palette']['aspireRiskLevel']
    severity: AspireTheme['palette']['severity']
    shades: AspireTheme['palette']['shades']
    tabSelected: AspireTheme['palette']['tabSelected']
    toolbarHeaderText: AspireTheme['palette']['toolbarHeaderText']
    vitalsChipChosen: AspireTheme['palette']['vitalsChipChosen']
    vitalsChipContainer: AspireTheme['palette']['vitalsChipContainer']
    vitalsToolbarHeader: AspireTheme['palette']['vitalsToolbarHeader']
  }
}

export const theme = createMuiTheme(defaultThemeOptions)
export type Theme = typeof theme
