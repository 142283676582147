import { useCallback, useState } from 'react'

const CLOSED = 'closed'
const CLOSING = 'closing'
const OPEN = 'open'

export default (initOpen = false) => {
  const [openState, setOpenState] = useState(initOpen ? OPEN : CLOSED)

  const openDialog = useCallback(() => {
    setOpenState(OPEN)
  }, [setOpenState])

  const onClose = useCallback(() => {
    setOpenState(CLOSING)
  }, [setOpenState])

  const onExited = useCallback(() => {
    setOpenState(CLOSED)
  }, [setOpenState])

  const open = openState === OPEN
  const render = openState !== CLOSED

  return [render, openDialog, { open, onClose, onExited }]
}
