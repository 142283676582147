import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or ... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../key' or ... Remove this comment to see the full error message
import rootKey from '../key'

const key = 'erFacilitiesNonClassic'

const creator = scopedCreator(key)

export const Facility = Record({
  facilityId: null,
  facilityName: null,
  networkStatus: null,
})

export const fetchFacilitiesNonClassic = Request({
  typePrefix: key,
  typeBase: 'FETCH_FACILITIES_NON_CLASSIC',
  requestParams: ['facilityName', 'patientId'],
  operation: (facilityName: string, patientId: string) =>
    AspireAPI.get(`er_visits/facilities_non_classic`, {
      params: { facilityName, patientId },
    }),
  transform: (result: any[]) => result.map(Facility),
  messages: { failed: 'There was a problem fetching facilities' },
})

export const clearFacility: any = creator('CLEAR_FACILITIES')

export default createReducer(key, [], {
  [fetchFacilitiesNonClassic.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  [clearFacility]: () => [],
})

export const getFacilitiesNonClassic = pipe(get(rootKey), get(key))
