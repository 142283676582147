import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
import { fetchPatientInfo } from '~/features/scheduling/data/patientInfo'
import { ATTACHMENTS_LOAD } from '../data/attachmentEvents'
import {
  fetchNextgenAttachmentsTypes,
  fetchNextgenPatientAttachments,
} from '../data/fetchAttachments'

export default action$ =>
  action$.pipe(
    ofType(ATTACHMENTS_LOAD),
    pluck('payload'),
    mergeMap(payload => {
      const { patientId, docType } = payload
      return [
        fetchNextgenPatientAttachments.requested(patientId, docType),
        fetchNextgenAttachmentsTypes.requested(patientId),
        fetchPatientInfo.requested(patientId),
      ]
    })
  )
