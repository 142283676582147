import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const TRIGGERLOG = 'triggerlog'

export type StatusType = {
  id: number
  status: string
}

export type TriggerLogType = {
  id: string
  changes: string
  table: string
  status: string
  action: string
  sfid: string
  recordtypeid: string
}

export const TriggerLog = Record({
  id: null,
  changes: null,
  table: null,
  status: null,
  action: null,
  sfid: null,
  recordtypeid: null,
})

const transformTriggerLog = (triggerLogs: TriggerLogType[]) => {
  return List(
    triggerLogs.map((triggerLog: TriggerLogType) => {
      return TriggerLog({
        id: triggerLog?.id,
        changes: triggerLog?.changes,
        table: triggerLog?.table,
        status: triggerLog?.status,
        action: triggerLog?.action,
        sfid: triggerLog?.sfid,
        recordtypeid: triggerLog.recordtypeid,
      })
    })
  )
}

export const fetchTriggerLogs = Request({
  typePrefix: rootKey,
  typeBase: 'TRIGGERLOG',
  operation: () => AspireAPI.get('admin_tools/unprocessed_trigger_log_events'),
  transform: transformTriggerLog,
  messages: { failed: 'There was an issue fetching trigger log' },
})

export default createReducer(TRIGGERLOG, List(), {
  [fetchTriggerLogs.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const editTriggerLog = Request({
  typePrefix: rootKey,
  typeBase: 'EDIT_TRIGGERLOG',
  requestParams: ['triggerLog'],
  operation: (triggerLog: StatusType) =>
    AspireAPI.post('admin_tools/update_triggerlog_status', {
      ...triggerLog,
    }),
  messages: {
    succeeded: 'Trigger log updated successfully',
    failed: 'Could not update trigger log',
  },
})

export const getTriggerLogs = pipe(getRoot, get(TRIGGERLOG))
