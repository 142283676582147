import { changedTo } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { fetchPatientOutlierReview } from '../data/currentPatientOutlierReview'
import { PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(changedTo(PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS)),
    pluck('payload', 'params', 'patientOutlierReviewId'),
    filter(Boolean),
    map(fetchPatientOutlierReview.requested)
  )
