import { get } from 'lodash'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { filter, ignoreElements, mergeMap, tap } from 'rxjs/operators'
import { LOCAL_ASSESSMENT, hasItem, removeItem } from '~/utils/storage'
import { signAssessment } from '../data/assessments'

const getStoredId = request =>
  `${LOCAL_ASSESSMENT}-${get(request, 'meta.request.payload.assessment.id')}`

export default action$ =>
  action$.pipe(
    ofType(signAssessment.SUCCEEDED),
    mergeMap(request =>
      from(hasItem(getStoredId(request))).pipe(
        filter(Boolean),
        tap(() => removeItem(getStoredId(request))),
        ignoreElements()
      )
    )
  )
