import React from 'react'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { maxVisitsChanged, subjectChanged } from '../../../data/events'
import {
  BatchSettingsField,
  CreatedModifiedField,
  NumberField,
  StartEndField,
  TextField,
} from '../../EventFields'

const AvailabilityEventDetail = ({
  event,
  editing,
  numberVisitsScheduled,
  userId,
}) => {
  const onMaxVisitsChange = useAction(maxVisitsChanged)
  const onSubjectChange = useAction(subjectChanged)

  return (
    <React.Fragment>
      <StartEndField event={event} editing={editing} />
      <NumberField
        label="Max Visits"
        value={event.maxVisits}
        after="visit units"
        min={numberVisitsScheduled}
        max={20}
        editing={editing}
        onChange={onMaxVisitsChange}
      />
      <TextField
        label="Notes"
        value={event.subject}
        editing={editing}
        onChange={onSubjectChange}
      />
      <BatchSettingsField userId={userId} event={event} editing={editing} />
      <CreatedModifiedField event={event} />
    </React.Fragment>
  )
}

AvailabilityEventDetail.propTypes = {
  editing: PropTypes.bool,
  event: PropTypes.record.isRequired,
  numberVisitsScheduled: PropTypes.number,
  userId: PropTypes.string.isRequired,
}

export default AvailabilityEventDetail
