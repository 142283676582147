import { combineReducers } from 'redux-immutable'
import census from './data/census'
import dialog from './data/dialog'
import patientDetails from './data/patientDetails'
import patientLists from './data/patientLists'
import key from './key'

const reducer = combineReducers({
  [census.key]: census,
  [dialog.key]: dialog,
  [patientDetails.key]: patientDetails,
  [patientLists.key]: patientLists,
})

reducer.key = key

export default reducer
