import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const CONTACT_MANAGEMENT_PHYSICIANS = `${key}.physicians`
export const CONTACT_MANAGEMENT_PHYSICIAN_RECORD = `${key}.physicianRecord`
export const CONTACT_MANAGEMENT_PRACTICES = `${key}.practices`
export const CONTACT_MANAGEMENT_PRACTICE_RECORD = `${key}.practiceRecord`
export const CONTACT_MANAGEMENT_VENDORS = `${key}.vendors`
export const CONTACT_MANAGEMENT_VENDOR_RECORD = `${key}.vendorRecord`
export const CONTACT_MANAGEMENT_HOSPICES = `${key}.hospices`
export const CONTACT_MANAGEMENT_HOSPICE_RECORD = `${key}.hospiceRecord`
export const CONTACT_MANAGEMENT_HOSPITALS = `${key}.hospitals`
export const CONTACT_MANAGEMENT_HOSPITAL_RECORD = `${key}.hospitalRecord`
export const CONTACT_MANAGEMENT_CASE_MANAGERS = `${key}.caseManagers`
export const CONTACT_MANAGEMENT_CASE_MANAGER_RECORD = `${key}.caseManagerRecord`
export const CONTACT_MANAGEMENT_GROUPS = `${key}.groups`
export const CONTACT_MANAGEMENT_GROUP_RECORD = `${key}.groupRecord`
export const CONTACT_MANAGEMENT_IPAS = `${key}.ipas`
export const CONTACT_MANAGEMENT_IPA_RECORD = `${key}.ipaRecord`

export default Router([
  Route(CONTACT_MANAGEMENT_PHYSICIANS, '/physicians'),
  Route(CONTACT_MANAGEMENT_PHYSICIAN_RECORD, '/physician/:physicianId'),
  Route(CONTACT_MANAGEMENT_PRACTICES, '/practices'),
  Route(CONTACT_MANAGEMENT_PRACTICE_RECORD, '/practice/:practiceId'),
  Route(CONTACT_MANAGEMENT_VENDORS, '/vendors'),
  Route(CONTACT_MANAGEMENT_VENDOR_RECORD, '/vendor/:vendorId'),
  Route(CONTACT_MANAGEMENT_HOSPICES, '/hospices'),
  Route(CONTACT_MANAGEMENT_HOSPICE_RECORD, '/hospice/:hospiceId'),
  Route(CONTACT_MANAGEMENT_HOSPITALS, '/hospitals'),
  Route(CONTACT_MANAGEMENT_HOSPITAL_RECORD, '/hospital/:hospitalId'),
  Route(CONTACT_MANAGEMENT_CASE_MANAGERS, '/case-managers'),
  Route(CONTACT_MANAGEMENT_CASE_MANAGER_RECORD, '/case-manager/:caseManagerId'),
  Route(CONTACT_MANAGEMENT_GROUPS, '/groups'),
  Route(CONTACT_MANAGEMENT_GROUP_RECORD, '/group/:groupId'),
  Route(CONTACT_MANAGEMENT_IPAS, '/ipas'),
  Route(CONTACT_MANAGEMENT_IPA_RECORD, '/ipa/:ipaId'),
  Redirect(CONTACT_MANAGEMENT_PHYSICIANS),
])
