import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'

// KEY
const key = 'deleteBenefit'

// REQUEST
export const deleteBenefit = Request({
  typePrefix: key,
  typeBase: 'DELETE_BENEFIT',
  requestParams: ['benefitId'],
  operation: (benefitId: number) =>
    AspireAPI.delete(`transportation/benefits/${benefitId}`),
  messages: {
    succeeded: `Benefit Deleted successfully`,
    failed: `Benefit Deleted  failed`,
  },
})

export const benefitDelete = createReducer(key, Boolean, {
  [deleteBenefit.SUCCEEDED]: () => false,
})
