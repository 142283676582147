import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import { PatientNote } from './interfaces'

// KEY
const key = 'noteEntryDialog'
export const getDialogOpenValue = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)
export const createMemberNoteEntryDialogOpened = creator(
  'CREATE_NOTE_ENTRY_DIALOG_OPENED'
)
export const createMemberNoteEntryDialogClosed = creator(
  'CREATE_NOTE_ENTRY_DIALOG_CLOSED'
)

// REQUEST
export const saveMemberNoteEntry = Request({
  typePrefix: key,
  typeBase: 'SAVE_MEMBER_NOTE_ENTRY',
  requestParams: ['noteEntry'],
  operation: (noteEntry: PatientNote) =>
    AspireAPI.post(`transportation/note`, { ...noteEntry }),
  messages: {
    succeeded: 'Please wait while the Member Note refreshes',
    failed: 'Could not save new note entry',
  },
})

// REDUCER
export const memberNoteDialog = createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [createMemberNoteEntryDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [createMemberNoteEntryDialogOpened]: () => true,
  [saveMemberNoteEntry.SUCCEEDED]: () => false,
})
