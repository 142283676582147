// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import rootKey from '../key'

const PHONES_FORM_KEY = 'patientContactFormPhones'
const ADDRESSES_FORM_KEY = 'patientContactFormAddresses'
const EMAILS_FORM_KEY = 'patientContactFormEmails'

export const patientContactFormPhonesCreator: any = scopedCreator(
  PHONES_FORM_KEY
)('PATIENT_CONTACT_FORM_PHONES')
export const patientContactFormAddressesCreator: any = scopedCreator(
  ADDRESSES_FORM_KEY
)('PATIENT_CONTACT_FORM_ADDRESSES')
export const patientContactFormEmailsCreator: any = scopedCreator(
  EMAILS_FORM_KEY
)('PATIENT_CONTACT_FORM_EMAILS')

export const patientContactFormPhones = createReducer(PHONES_FORM_KEY, [], {
  [patientContactFormPhonesCreator]: (_state: any, { payload }: any) => payload,
})
export const patientContactFormAddresses = createReducer(
  ADDRESSES_FORM_KEY,
  [],
  {
    [patientContactFormAddressesCreator]: (_state: any, { payload }: any) =>
      payload,
  }
)
export const patientContactFormEmails = createReducer(EMAILS_FORM_KEY, [], {
  [patientContactFormEmailsCreator]: (_state: any, { payload }: any) => payload,
})

export const getPatientContactFormPhones = getIn([rootKey, PHONES_FORM_KEY])
export const getPatientContactFormAddresses = getIn([
  rootKey,
  ADDRESSES_FORM_KEY,
])
export const getPatientContactFormEmails = getIn([rootKey, EMAILS_FORM_KEY])
