import { combineEpics, ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { getShouldLoadInactiveUsers } from '~/apps/userSettings/data/userListSettings'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { fetchUsers, getUsers } from '~/data/users'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import router from '../router'

const loadUsers = (action$: any, state$: any) =>
  action$.pipe(
    filter(entered(router)),
    switchTo(state$),
    map(getUsers),
    filter(users => (users as any).isEmpty()),
    switchTo(state$),
    map(getShouldLoadInactiveUsers),
    map(fetchUsers.requested),
    takeUntil(action$.pipe(ofType(fetchUsers.SUCCEEDED)))
  )
const getFieldValues = (action$: any) =>
  action$.pipe(
    filter(entered(router)),
    mergeMap(() =>
      of(
        fetchDistinctFieldValues([
          'aspire_program_enrolled',
          'referral_facilitator',
          'referral_source_type',
        ])
      )
    )
  )
export default combineEpics(getFieldValues, loadUsers)
