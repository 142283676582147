import React, { useState } from 'react'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  textArea: {
    marginTop: spacing(1),
    '& div': {
      fontSize: '0.875rem',
      padding: spacing(2),
    },
  },
}))

const TextArea = ({ changeState, value, valueKey, ...props }) => {
  const classes = useStyles()
  const [currentValue, setCurrentValue] = useState(value)

  const changeValue = newValue => {
    const stateValue = newValue === '' ? null : newValue
    setCurrentValue(newValue)
    return changeState(valueKey, stateValue)
  }

  return (
    <TextField
      className={classes.textArea}
      multiline
      onChange={e => changeValue(e.target.value)}
      variant="outlined"
      value={currentValue}
      {...props}
    />
  )
}

TextArea.propTypes = {
  changeState: PropTypes.func.isRequired,
  value: PropTypes.string,
  valueKey: PropTypes.string.isRequired,
}

export default TextArea
