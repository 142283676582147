import { List, Record, Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { scopedCreator } from '~/utils/data'
import { ENCOUNTER } from '../constants'
import PATIENT_TIMELINE from '../key'
import {
  expandDates,
  formatDate,
  formatTimestamp,
  sortByDate,
} from '../utils/date'
import { swimlaneCompressionToggled } from './swimlane'

// Key
export const key = 'dates'

// Actions
const creator = scopedCreator(PATIENT_TIMELINE)

export const patientEventsStateUpdated = creator('PATIENT_EVENTS_STATE_UPDATED')

// Reducer
const formatDateFromPatientEvent = patientEvent =>
  patientEvent.interpretAsTimeStamp
    ? formatTimestamp(patientEvent.eventDate)
    : formatDate(patientEvent.eventDate)

const getUniqueDates = (state, action) =>
  state.set(
    'compressed',
    Set(
      action.payload
        .filter(
          pe =>
            pe.category != ENCOUNTER ||
            (pe.category == ENCOUNTER &&
              (pe.details.source != 'Caremore' ||
                (pe.details.source == 'Caremore' &&
                  pe.details.status != 'Cancelled' &&
                  pe.details.status != 'No Show')))
        )
        .map(formatDateFromPatientEvent)
    )
      .toList()
      .sort(sortByDate)
  )

// Only create all dates if it hasn't been done before.
const createExpandedDates = state =>
  state.get('all').isEmpty()
    ? state.set('all', expandDates(state.get('compressed')))
    : state

// Record
export const Dates = Record({
  all: List(),
  compressed: List(),
})

// Reducer
export default createReducer(key, Dates(), {
  [patientEventsStateUpdated]: getUniqueDates,
  [swimlaneCompressionToggled]: createExpandedDates,
})
