import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { ADMIN_TOOLS_EDIT_PERMISSION_SET } from '../router'
import { getRoot } from './common/shared'

const CURRENT_PERMISSION_SET_ID = 'currentPermissionSetId'

const reducer = paramsReducer(
  ADMIN_TOOLS_EDIT_PERMISSION_SET,
  ({ permissionSetId }: any) => Number(permissionSetId)
)

reducer.key = CURRENT_PERMISSION_SET_ID

export const getCurrentPermissionSetId = pipe(
  getRoot,
  get(CURRENT_PERMISSION_SET_ID)
)

export default reducer
