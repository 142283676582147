import { colors } from '@material-ui/core'
import lightTheme from '../../light'
import airplane from './aspire-airplane.png'
import beachball from './beach-ball.png'
import beach from './beach-sand.png'
import cloud2 from './big-cloud-2.png'
import sun from './sun.png'
import umbrella from './umbrella.png'

export const summerStyles = () => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      backgroundColor: '#e7f0fd',
      backgroundImage: `url(${sun})`,
      backgroundPosition: '200px -70px',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      overflowX: 'hidden',
      '&::after': {
        content: '""',
        animation: 'airplaneBanner 200s linear infinite',
        animationDirection: 'reverse',
        backgroundImage: `url(${airplane})`,
        left: -700,
        backgroundRepeat: 'no-repeat',
        height: '32%',
        position: 'absolute',
        top: 112,
        width: '100%',
        zIndex: -10,
      },
    },
    '#root': {
      height: '100%',
      '& > div': {
        height: '100%',
      },
      '&::before': {
        backgroundImage: `url(${umbrella})`,
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '75%',
        content: '""',
        height: '70%',
        position: 'absolute',
        width: '100%',
        zIndex: -5,
      },
      '&::after': {
        content: '""',
        animation: 'animatedCloud 210s linear 18s infinite',
        backgroundImage: `url(${cloud2})`,
        backgroundRepeat: 'no-repeat',
        height: '50%',
        left: -700,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: -15,
      },
    },
    '.content-root': {
      backgroundImage: `url(${beach})`,
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      minHeight: '100%',
    },
    '@keyframes animatedCloud': {
      from: { transform: 'translate(-700px, 80%)' },
      to: { transform: 'translate(calc(100% + 700px), 78%)' },
    },
    '@keyframes airplaneBanner': {
      from: { transform: 'translate(-1000px, 20%)' },
      to: { transform: 'translate(calc(100% + 1000px), 20%)' },
    },
  },
})

export const summerTheme = () => ({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    holiday: 'summer',
    primary: {
      light: '#f5e7d6',
      main: '#d3a26a',
      dark: '#d89d5a',
    },
    secondary: {
      // includes Action buttons and edit pencils
      main: '#6aa4e2',
    },
    drawer: {
      backgroundClosed: {
        backgroundColor: 'rgba(130, 178, 230, 0.5)',
        '& $header': {
          visibility: 'hidden',
        },
      },
      backgroundOpen: {
        backgroundColor: 'rgba(130, 178, 230, 1.0)',
        '& $header': {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          visibility: 'visible',
        },
        '@media (min-width: 1280px)': {
          backgroundColor: 'rgba(130, 178, 230, 0.5)',
        },
      },
    },
    enrolledStatusBadge: {
      backgroundColor: '#82b2e6',
      color: '#fff',
    },
    accordion: {
      color: colors.common.white,
      header: '#6aa4e2',
    },
    timelineDetail: {
      backgroundColor: '#d4e5f7',
    },
    panelTabSelected: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    tabSelected: {
      backgroundColor: '#c29460',
    },
    patientSearchProgramBadge: {
      backgroundColor: '#c29460',
      color: colors.common.white,
    },
    alertsAccordion: {
      backgroundColor: '#82b2e6',
    },
    patientInfoTag: {
      backgroundColor: '#82b2e6',
      '& button:hover': {
        backgroundColor: '#6aa4e2',
      },
    },
    toolbarHeaderText: {
      color: colors.common.white,
    },
    contentText: {
      primaryLabel: '#6aa4e2',
      secondaryLabel: '#82b2e6',
    },
    notifications: {
      linkText: '#6aa4e2',
    },
    calendarHead: {
      backgroundColor: '#d3a26a',
    },
    calendarTitleText: {
      color: '#d3a26a',
    },
    formPageTitle: {
      color: '#d3a26a',
    },
    vitalsToolbarHeader: {
      backgroundColor: '#d3a26a',
    },
    vitalsChipContainer: {
      backgroundColor: '#f5e7d6',
    },
    vitalsChipChosen: {
      backgroundColor: '#d3a26a',
      color: colors.common.white,
    },
  },
  overrides: {
    ...lightTheme.overrides,
    MuiAppBar: {
      root: {
        '&.mui-fixed': {
          backgroundColor: 'rgba(226, 182, 131, 0.7)',
          color: colors.common.white,
          transition: 'background-color 1s linear',
          '&.holiday-app-bar': {
            backgroundColor: 'rgba(226, 182, 131, 1.0)',
          },
        },
      },
    },
    MuiBadge: {
      root: {
        '& $badge': {
          backgroundColor: colors.orange[500],
        },
      },
    },
    MuiButton: {
      outlined: {
        borderColor: '#82b2e6',
        color: '#82b2e6',
      },
      root: {
        '&:hover': {
          backgroundColor: '#aacbee',
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#d4e5f7',
        backgroundImage: `url(${cloud2})`,
        backgroundPosition: 'center -20%',
        backgroundRepeat: 'no-repeat',
      },
    },
    MuiDialogTitle: {
      root: {
        color: '#d3a26a',
      },
    },
    MuiDrawer: {
      paperAnchorRight: {
        backgroundColor: '#d4e5f7',
      },
    },
    MuiFab: {
      root: {
        '&::after': {
          content: '""',
          backgroundImage: `url(${beachball})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          bottom: -20,
          height: 100,
          position: 'absolute',
          right: -20,
          width: 100,
          zIndex: -10,
        },
      },
      label: {
        color: colors.common.white,
      },
      primary: {
        backgroundColor: '#82b2e6',
      },
    },
    MuiIconButton: {
      root: {
        '&$colorPrimary': {
          color: '#6aa4e2',
        },
      },
    },
    MuiLinearProgress: {
      barColorSecondary: {
        backgroundColor: colors.orange[500],
      },
      colorSecondary: {
        backgroundColor: '#ffd394',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#aacbee',
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'rgba(212, 229, 247, 0.8)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#d4e5f7',
        '& .material-icons': {
          color: '#6aa4e2',
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        color: colors.common.white,
        '& button': {
          color: colors.common.white,
        },
        '& svg': {
          color: colors.common.white,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#f5e7d6',
      },
    },
  },
})
