import { paramsReducer } from 'redux-routable'
import { PATIENT_RECORD_CALL_DETAIL } from '~/apps/patientRecord/router'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getCallById } from './calls'
import { getCall } from './common/shared'

const key = 'currentCallId'

const reducer = paramsReducer(PATIENT_RECORD_CALL_DETAIL, ({ callId }) =>
  Number(callId)
)

reducer.key = key

export const getCurrentCallId = pipe(getCall, get(key))

export const getCallByCurrentCallId = state => {
  const currentCall = getCurrentCallId(state)
  return getCallById(currentCall)(state) || {}
}

export default reducer
