import { getRoute } from '~/data/route'
import { getSelectedOrder } from '~/features/orders/data/selectedOrder'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import {
  PATIENT_RECORD_CALL,
  PATIENT_RECORD_CARE_PLAN,
  PATIENT_RECORD_CARE_PLAN_NEW,
  PATIENT_RECORD_EPISODES_OF_CARE,
  PATIENT_RECORD_EPISODE_OF_CARE_DETAIL,
  PATIENT_RECORD_ON_CALL,
  PATIENT_RECORD_ORDERS_ROUTES,
  PATIENT_RECORD_TASKS_BY_PATIENT,
} from '../router'
import { getPatientRecord } from './common/shared'

const key = 'patientRecordPanelOpen'

// Type
export const TOGGLE_PANEL_AVAILABLE = type(key, 'TOGGLE_PANEL_AVAILABLE')
export const togglePanelAvailable = creator(TOGGLE_PANEL_AVAILABLE, 'available')

// Reducer
export default createReducer(key, false, {
  [TOGGLE_PANEL_AVAILABLE]: (state, { payload: { available } }) =>
    Boolean(available),
})

const isPanelOpen = compose(get(key), getPatientRecord)

export const panelAvailable = state => {
  const route = getRoute(state)
  const panelAvailableOn = [
    PATIENT_RECORD_CALL,
    PATIENT_RECORD_CARE_PLAN,
    PATIENT_RECORD_CARE_PLAN_NEW,
    PATIENT_RECORD_EPISODE_OF_CARE_DETAIL,
    PATIENT_RECORD_EPISODES_OF_CARE,
    PATIENT_RECORD_ON_CALL,
    PATIENT_RECORD_TASKS_BY_PATIENT,
    PATIENT_RECORD_TASKS_BY_PATIENT,
  ]
  const isOrdersPage = PATIENT_RECORD_ORDERS_ROUTES.includes(route)

  return (
    (isOrdersPage && !!getSelectedOrder(state)) ||
    (panelAvailableOn.includes(route) && isPanelOpen(state))
  )
}
