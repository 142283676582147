import React, { useCallback, useEffect } from 'react'
import { ActionDialog } from '~/components/dialogs'
import { useAction } from '~/hooks'
import useInput from '~/hooks/useInput'
import PropTypes from '~/utils/propTypes'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { EDIT_LIST_ITEM, dialogClosed } from '../../data/dialog'
import { savePatientListItem } from '../../data/patientLists'

const styles = ({ spacing }) => ({
  marginTop: {
    marginTop: spacing(1),
  },
})

const EditListDialog = ({
  classes,
  dialogType,
  patientName,
  open,
  rowId,
  ...props
}) => {
  const [reason, setReason] = useInput(props.reason)

  const onDialogClosed = useAction(dialogClosed)
  const onSavePtListItem = useAction(savePatientListItem.requested)

  const onClose = useCallback(() => {
    onDialogClosed()
    setReason('')
  }, [onDialogClosed, setReason])

  const onSave = useCallback(() => {
    onSavePtListItem(rowId, reason)
    onDialogClosed()
  }, [rowId, reason, onSavePtListItem, onDialogClosed])

  useEffect(() => {
    if (dialogType === EDIT_LIST_ITEM) return setReason(props.reason)
  }, [dialogType, props.reason])

  return (
    <ActionDialog
      mode="save"
      title={`Edit ${patientName} List Reason`}
      open={open}
      onSave={onSave}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <TextField
        className={classes.marginTop}
        fullWidth
        multiline
        rowsMax={3}
        variant="outlined"
        label="Reason"
        onChange={setReason}
        value={reason}
      />
    </ActionDialog>
  )
}

EditListDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  reason: PropTypes.string,
  rowId: PropTypes.number,
  patientName: PropTypes.string,
  dialogType: PropTypes.string,
}

export default withStyles(styles)(EditListDialog)
