import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import { scopedCreator } from '~/utils/data'
import { redirect } from '../utils/redirect'
import { handleMedMessage } from './root'

const key = 'mdtSingleSignOn'
const creator = scopedCreator(key)

export const directSSORequested = creator('DIRECT_SSO_REQUESTED')

const fetchMdtSingleSignOnApi = (params = {}) =>
  AspireAPI.get('medications/mdt_sso/', {
    params: {
      prescriber_id: params.prescriberId,
      location_id: params.locationId,
      patient_id: params.patientId,
      rx_id: params.partnerMedId,
      pharmacy_ncpdp_id: params.pharmacyNcpdpId,
      page:
        params.page === undefined
          ? params.partnerMedId
            ? 'newrx'
            : 'rx'
          : params.page,
      menu: params.menu || 'hide',
      menu_bottom: params.menu_bottom || 'hide_all',
      header: params.header || 'hidden',
      state: params.state,
    },
  })

export const fetchMdtSingleSignOn = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['params'],
  operation: fetchMdtSingleSignOnApi,
  transform: redirect,
  messages: {
    failed: handleMedMessage(
      'There was a problem fetching MDToolbox Single Sign On Url'
    ),
  },
})
