import React from 'react'
import { connect, useSelector } from 'react-redux'
import { getFlags } from '~/data/flagSelector'
import { havePermissionsLoaded } from '~/features/authorization/data/permissions'
import PropTypes from '~/utils/propTypes'

const Flag = ({
  flags,
  flagName,
  children,
  component,
  fallbackComponent,
  ...props
}) => {
  const permissionsLoaded = useSelector(havePermissionsLoaded)

  if (flags && flags[flagName] && permissionsLoaded && component) {
    const Component = component
    return <Component {...props}>{children}</Component>
  } else if (flags && permissionsLoaded && fallbackComponent) {
    const Component = fallbackComponent
    return <Component {...props}>{children}</Component>
  } else {
    return null
  }
}

Flag.propTypes = {
  flags: PropTypes.object,
  children: PropTypes.node,
  fallbackComponent: PropTypes.elementType,
  component: PropTypes.elementType.isRequired,
  flagName: PropTypes.string.isRequired,
}

export default connect(
  state => ({
    flags: getFlags(state),
  }),
  {}
)(Flag)
