// Create a function that maps props to options (to be used with the
// setOptions(...) method on Google Maps objects). If previous props are given,
// only options that have changed will be kept (using shallow comparison).
const createPropsToOptions = propsToOptions => (props, prevProps) => {
  const options = propsToOptions(props)

  if (prevProps !== undefined) {
    const prevOptions = propsToOptions(prevProps)

    Object.keys(options).forEach(option => {
      if (prevOptions[option] === options[option]) {
        delete options[option]
      }
    })
  }

  return options
}

// Create a function that calls a listener function from props if it exists (to
// be used with the addListener(...) method on Google Maps objects).
const createListener = (instance, listenerName) => (...args) => {
  const listener = instance.props[listenerName]

  if (listener !== undefined) {
    listener(...args)
  }
}

export { createPropsToOptions, createListener }
