// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { fetchCarePlan, getCarePlan } from './root'

const LOCKED = 'locked'

export default createReducer(LOCKED, false, {
  [fetchCarePlan.SUCCEEDED]: (_state: any, { payload: { locked } }: any) =>
    locked,
})

export const getLocked = pipe(getCarePlan, get(LOCKED))
