import classnames from 'classnames'
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
// @ts-expect-error fix me
import { pure } from 'recompose'
import { usePrevious } from '~/hooks'
// @ts-expect-error fix me
import telemetry from '~/utils/telemetry'
import {
  Icon,
  IconButton,
  TextField,
  Tooltip,
  TooltipProps,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  compact: {
    maxWidth: '250px',
  },
  compactIcon: {
    top: 10,
    padding: 6,
  },
  searchInput: {
    flex: 1,
  },
  inheritColor: {
    borderColor: 'inherit',
    color: 'inherit',
    // NOTE(Mike): this is to prevent iOS 15 from screwing up the search field on mobile devices; remove when bug fixed
    '& input': {
      '-webkit-appearance': 'none',
    },
  },
}

const Hint = ({ children, title }: TooltipProps) => {
  return title === '' ? children : <Tooltip title={title}>{children}</Tooltip>
}

interface SearchProps {
  classes?: any
  label: string
  placeholder: string
  onChangeSearch: (v: string) => void
  onSubmit: (v: unknown) => void
  value: string
  autoFocus: boolean
  contrast: boolean
  compact: boolean
  searchRef: any
  tooltip: TooltipProps['title']
}

const Search = ({
  value = '',
  classes,
  label = 'Search',
  placeholder = '',
  onChangeSearch,
  onSubmit = (v: unknown) => telemetry.warn('default search submit', v),
  autoFocus,
  contrast = false,
  compact = false,
  searchRef,
  tooltip = '',
}: SearchProps) => {
  const [searchString, setSearchString] = useState(value)

  const previousValue = usePrevious(value)

  useEffect(() => {
    if (previousValue !== value) {
      setSearchString(value)
    }
  }, [value])

  const onKeyDown = (searchString: string) => (
    e: KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === 'Enter') {
      onSubmit(searchString)
    }
  }

  const currentSearchString =
    typeof searchString === 'string' ? searchString : value

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChangeSearch) {
      onChangeSearch(e.target.value)
    }
    setSearchString(e.target.value)
  }

  return (
    <div
      data-track-content
      data-content-name="Search"
      className={classnames(classes.container, {
        [classes.compact]: compact,
      })}
      ref={node => {
        searchRef && searchRef(node)
      }}
    >
      <Hint title={tooltip}>
        <IconButton
          className={classnames({ [classes.compactIcon]: Boolean(label) })}
          color={contrast ? 'inherit' : 'default'}
          onClick={() => onSubmit(currentSearchString)}
        >
          <Icon>search</Icon>
        </IconButton>
      </Hint>
      <TextField
        label={label}
        data-content-piece={label}
        type="search"
        className={classes.searchInput}
        onChange={handleSearchChange}
        onKeyDown={onKeyDown(currentSearchString)}
        value={currentSearchString}
        autoFocus={!!autoFocus}
        InputLabelProps={
          contrast
            ? {
                classes: {
                  root: classes.inheritColor,
                },
              }
            : undefined
        }
        InputProps={
          contrast
            ? {
                classes: {
                  root: classes.inheritColor,
                },
                disableUnderline: true,
                placeholder: placeholder,
                margin: 'none',
              }
            : {
                placeholder: placeholder,
              }
        }
      />
    </div>
  )
}

export default withStyles(styles)(pure(Search))
