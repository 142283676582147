import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Fab, Icon } from '@material-ui/core'

const NewTaskButton = ({ className, onClick }) => (
  <Fab aria-label="New Task" color="primary" {...{ className, onClick }}>
    <Icon>playlist_add</Icon>
  </Fab>
)

NewTaskButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default NewTaskButton
