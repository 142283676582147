import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change } from 'redux-form'
import { InfoDialog } from '~/components/dialogs'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import TextFieldWithAdornment from '../../components/TextFieldWithAdornment'
import { practicesCleared } from '../../data/practices'
import PracticeLookup from './PracticeLookup'

const PracticeField = ({ className, form, changeValue, practicesCleared }) => {
  const [open, toggleOpenTrue, toggleOpenFalse] = useToggle()

  const onClose = useCallback(() => {
    toggleOpenFalse()
    practicesCleared()
  }, [practicesCleared])

  const onSelect = useCallback(
    practice => {
      changeValue(form, 'practiceId', practice.id)
      changeValue(form, 'practice', practice.name)

      onClose()
    },
    [form, changeValue, onClose]
  )

  const onClear = useCallback(() => {
    changeValue(form, 'practiceId', '')
    changeValue(form, 'practice', '')
  }, [form, changeValue])

  return (
    <React.Fragment>
      <Field
        className={className}
        component={TextFieldWithAdornment}
        name="practice"
        label="Practice"
        onSearch={toggleOpenTrue}
        onClear={onClear}
        disabled
      />

      <InfoDialog
        open={open}
        title="Practice Lookup"
        onClose={onClose}
        maxWidth="lg"
        fullWidth
      >
        <PracticeLookup onSelect={onSelect} />
      </InfoDialog>
    </React.Fragment>
  )
}

PracticeField.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  practicesCleared: PropTypes.func.isRequired,
}

export default compose(
  connect(null, {
    changeValue: change,
    practicesCleared,
  })
)(PracticeField)
