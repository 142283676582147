import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { patientContactFormAddressesCreator } from '~/apps/patientRecord/data/patientContactForm'
import { getFieldValues } from '~/data/fieldValues'
import { Authorized, actions, objects } from '~/features/authorization'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { Location, LocationSearch } from '~/features/locationSearch'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { useAction } from '~/hooks'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { patientContactFormUseStyles } from '../utils/utils'

interface PatientAddressFormPropTypes {
  addressList: PropTypes.object.isRequired
  control: PropTypes.object.isRequired
  handleAddressFieldChange: PropTypes.func.isRequired
}

export const PatientAddressForm = ({
  addressList,
  control,
  handleAddressFieldChange,
}: PatientAddressFormPropTypes) => {
  const classes = patientContactFormUseStyles()

  const [addresses, setAddresses]: any = useState(addressList)
  const setAddressListToState = useAction(patientContactFormAddressesCreator)
  const addressesRef = useRef(addresses)
  const addressTypeFields = useSelector(
    getFieldValues(['contact_address_type'])
  )

  const setAllAddresses = (addressList: any) => {
    setAddresses(addressList)
    setAddressListToState(addressList)
  }

  useEffect(() => {
    const updatedAddress: any[] = addressList.map((el: any) => {
      const location = Location({
        street: el.street,
        city: el.city,
        state: el.state,
        stateCode: el.state,
        postalCode: el.zip,
        latitude: el.lat,
        longitude: el.long,
      })
      const temp = {
        id: el.id,
        type: el.type,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        createdAt: el.createdAt,
        index: el.index,
        location: location,
        city: el.city,
        street2: el.street2,
        street: el.street,
        state: el.state,
        stateCode: el.state,
        zip: el.zip,
        lat: el.lat,
        long: el.long,
      }
      return temp
    })
    addressesRef.current = updatedAddress
    setAllAddresses(updatedAddress)
  }, [addressList])

  const removeAddress = (currentIndex: any, isPrimary: any) => {
    const item = addresses.find(
      (a: any) => a.index !== currentIndex && !a.delete
    )
    const updatedAddresses: any[] = addresses.map((el: any) => {
      const temp = Object.assign({}, el)
      if (temp.index === currentIndex) {
        temp.primary = false
        temp.delete = true
      }

      if (isPrimary && item && temp.index === item.index) {
        temp.primary = true
      }
      return temp
    })
    addressesRef.current = updatedAddresses
    setAllAddresses(updatedAddresses)
    handleAddressFieldChange()
  }

  const markPrimaryAddress = (currentIndex: any) => {
    const updatedAddress: any[] = addresses.map((el: any) => {
      const temp = Object.assign({}, el)
      temp.index === currentIndex
        ? (temp.primary = true)
        : (temp.primary = false)

      return temp
    })
    addressesRef.current = updatedAddress
    setAllAddresses(updatedAddress)
    handleAddressFieldChange()
  }

  const onLocationChange = (currentIndex: number, location: Location) => {
    const addressesClone = [...addressesRef.current]
    const updatedAddress: any[] = addressesClone.map((el: any) => {
      const temp = Object.assign({}, el)

      if (temp.index === currentIndex) {
        temp.location = location
        temp.street = location?.street
        temp.city = location?.city
        temp.state = location?.stateCode
        temp.zip = location?.postalCode
        temp.lat = location?.latitude
        temp.long = location?.longitude
      }

      return temp
    })
    addressesRef.current = updatedAddress
    setAllAddresses(updatedAddress)
    handleAddressFieldChange()
  }

  const handleAddressChange = (event: any, currentIndex: number) => {
    const updatedAddress: any[] = addresses.map((el: any) => {
      const temp = Object.assign({}, el)
      if (temp.index === currentIndex) {
        switch (event.target.name) {
          case 'street2':
            temp.street2 = event.target.value
            break
          case '':
            temp.type = event.target.value.toLowerCase()
            break
          case 'active':
            temp.active = event.target.checked
            break
        }
      }

      return temp
    })

    addressesRef.current = updatedAddress
    setAllAddresses(updatedAddress)
    handleAddressFieldChange()
  }

  const addAddress = () => {
    const addressesClone = [...addressesRef.current]
    const indexes = addressesClone.map((el: any) => el.index)
    const index =
      addressesClone && addressesClone.length ? Math.max(...indexes) + 1 : 1

    const addAddresses: any = [
      ...addressesClone,
      {
        id: null,
        primary: !(addressesClone && addressesClone.length),
        active: true,
        delete: false,
        index: index,
        location: Location(),
      },
    ]
    addressesRef.current = addAddresses
    setAllAddresses(addAddresses)
    handleAddressFieldChange()
  }

  const displayAddress = (address: any) => {
    return (
      <React.Fragment key={address?.index || address?.id}>
        <Box
          display="flex"
          className={classes.marginBottomCls}
          key={address?.index || address?.id}
        >
          <IconButton onClick={() => markPrimaryAddress(address?.index)}>
            <Icon color={address.primary ? 'primary' : 'action'}> star </Icon>
          </IconButton>
          <Box clone={true} className={classes.locationFieldBox}>
            <LocationSearch
              variant="filled"
              location={address.location}
              onChange={(selectedLocation: Location) => {
                onLocationChange(address?.index, selectedLocation)
              }}
            />
          </Box>

          <Box className={classes.textFieldBox}>
            <TextField
              variant="filled"
              fullWidth={true}
              label="Street Line 2"
              value={address.street2}
              name="street2"
              onChange={e => handleAddressChange(e, address?.index)}
            />
          </Box>
          <Box className={classes.textFieldBoxNew}>
            <Controller
              render={({ onChange }) => (
                <TextField
                  label="Type"
                  onChange={value => {
                    onChange(value)
                    handleAddressChange(value, address?.index)
                  }}
                  value={address.type}
                  variant="filled"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  select
                  SelectProps={{ native: true }}
                  required={true}
                  fullWidth
                >
                  <option value="" key="" />
                  {addressTypeFields.entrySeq().map(([k, v]: any) => (
                    <option value={v.value} key={k}>
                      {v.label}
                    </option>
                  ))}
                </TextField>
              )}
              control={control}
              name="addressType"
              rules={{ required: 'Please fill out this field.' }}
              variant="filled"
              defaultValue={address?.type || ''}
            ></Controller>
          </Box>

          <Tooltip
            title={address.id ? 'Active Contact Method' : 'New Contact Method'}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={address.active}
                  disabled={address.id ? false : true}
                  name="active"
                  onChange={e => handleAddressChange(e, address?.index)}
                />
              }
              label="Active"
            />
          </Tooltip>
          <Authorized object={objects.PATIENT_CONTACTS} action={actions.DELETE}>
            <IconButton
              onClick={() => removeAddress(address?.index, address?.primary)}
            >
              <Icon> close </Icon>
            </IconButton>
          </Authorized>
        </Box>
      </React.Fragment>
    )
  }

  const displayAddresses = () => {
    return addresses
      ?.filter((a: any) => !a.delete)
      .map((address: any) => displayAddress(address))
  }

  return (
    <>
      <Button
        onClick={() => {
          addAddress()
        }}
        id="addAddress"
      >
        ADD ADDRESS
      </Button>
      {displayAddresses()}
    </>
  )
}
