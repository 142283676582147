import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import rootKey from '../key'
import { getRoot } from './common/shared'

export const key = `census`

export const fetchAdmissionCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ADMISSION_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/census/admission`),
})

export const fetchDischargeCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_DISCHARGE_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/census/discharge`),
})

export const fetchERCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_ER_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/census/er`),
})

export const fetchInpatientCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_INPATIENT_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/census/inpatient`),
})

export const fetchSNFCensus = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_SNF_CENSUS',
  requestParams: ['userId'],
  operation: userId =>
    AspireAPI.get(`/case_manager_dashboard/${userId}/census/snf`),
})

export const saveAdmissionAcknowledgement = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_ADMISSION_ACKNOWLEDGEMENT',
  requestParams: ['userId', 'censusId', 'patientId'],
  operation: (userId, censusId, patientId) =>
    AspireAPI.put(
      `/case_manager_dashboard/${userId}/census/admission/acknowledge`,
      { censusId, patientId }
    ),
  messages: {
    failed: 'There was a problem saving admission acknowledgement',
  },
})

const initState = {
  admission: [],
  discharge: [],
  er: [],
  inpatient: [],
  snf: [],
}

const set = key => (state, { payload }) => ({ ...state, [key]: payload })

export default createReducer(key, initState, {
  [fetchAdmissionCensus.SUCCEEDED]: set('admission'),
  [fetchDischargeCensus.SUCCEEDED]: set('discharge'),
  [fetchERCensus.SUCCEEDED]: set('er'),
  [fetchInpatientCensus.SUCCEEDED]: set('inpatient'),
  [fetchSNFCensus.SUCCEEDED]: set('snf'),
  [saveAdmissionAcknowledgement.SUCCEEDED]: (state, { meta }) => ({
    ...state,
    admission: state.admission.filter(
      a => a.id !== meta.request.payload.censusId
    ),
  }),
})

const getCensus = state => getRoot(state).get(key)

export const getAdmissionCensus = state => getCensus(state).admission
export const getDischargeCensus = state => getCensus(state).discharge
export const getErCensus = state => getCensus(state).er
export const getInpatientCensus = state => getCensus(state).inpatient
export const getSnfCensus = state => getCensus(state).snf
