import cx from 'classnames'
import React from 'react'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { isPastDue } from '../utils/date'

const styles = ({ palette }) => ({
  overdue: {
    color: palette.severity.error.dynamic,
  },
})

const DueOnDate = ({ classes: { overdue }, row: { dueOn, status }, value }) => (
  <span className={cx({ [overdue]: isPastDue(status, dueOn) })}>
    {formatDate(value)}
  </span>
)

DueOnDate.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
}

export default withStyles(styles)(DueOnDate)
