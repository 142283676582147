// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import currentCaseManagerId from './data/currentCaseManagerId'
import currentGroupId from './data/currentGroupId'
import currentHospiceId from './data/currentHospiceId'
import currentHospitalId from './data/currentHospitalId'
import currentIPAId from './data/currentIPAId'
import currentPhysicianId from './data/currentPhysicianId'
import currentPracticeId from './data/currentPracticeId'
import currentVendorId from './data/currentVendorId'
import recordLoaded from './data/recordLoaded'
import key from './key'

const reducer = combineReducers({
  [currentCaseManagerId.key]: currentCaseManagerId,
  [currentGroupId.key]: currentGroupId,
  [currentHospiceId.key]: currentHospiceId,
  [currentHospitalId.key]: currentHospitalId,
  [currentIPAId.key]: currentIPAId,
  [currentPhysicianId.key]: currentPhysicianId,
  [currentPracticeId.key]: currentPracticeId,
  [currentVendorId.key]: currentVendorId,
  [recordLoaded.key]: recordLoaded,
})

reducer.key = key

export default reducer
