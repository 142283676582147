import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import fuzzySort from 'fuzzysort'
import PropTypes from 'prop-types'
import React, { useMemo, useRef, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useUpdateEffect } from '~/hooks'
import { MenuItem, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const fuzzyFilter = val => suggestion => {
  const parsedVal = val.toLowerCase()
  const parsedSuggestion = (suggestion.label || suggestion).toLowerCase()

  const result = fuzzySort.single(parsedVal, parsedSuggestion)

  return result && !(result.score === null)
}

const getSuggestions = (suggestions, filterFunc, value) =>
  suggestions.filter(filterFunc(value)).slice(0, 10)

const useStyles = makeStyles(({ spacing, palette, zIndex }) => ({
  container: {
    position: 'relative',
    flex: 1,
    width: '100%',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    transition: 'all .25s ease-out',
    marginTop: spacing(1),
    marginBottom: spacing(3),
    left: 0,
    right: 0,
    overflow: 'auto',
    zIndex: zIndex.modal,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
  warningText: {
    color: palette.error.main,
  },
  highlight: {
    color: palette.secondary.main,
  },
  nonHighlight: {
    whiteSpace: 'pre',
  },
  inputUnderline: {
    '&:before': {
      borderColor: palette.common.white,
    },
    '&:after': {
      borderColor: palette.common.white,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: palette.common.white,
    },
  },
}))

const AutoComplete = ({
  disabled,
  editInputStyles,
  error,
  fuzzy,
  labelKey,
  inputLabel,
  availableSuggestions,
  value,
  onBlur,
  inputAdornment,
  ...props
}) => {
  const classes = useStyles()

  const filterFunc = useMemo(() => (fuzzy ? fuzzyFilter : props.filterFunc), [
    fuzzy,
    props.filterFunc,
  ])

  const [inputValue, setInputValue] = useState(value)
  const [suggestions, setSuggestions] = useState(() =>
    getSuggestions(availableSuggestions, filterFunc, inputValue)
  )
  const inputRef = useRef()

  useUpdateEffect(() => {
    setInputValue(value)
  }, [value, setInputValue])

  useUpdateEffect(() => {
    const newSuggestions = getSuggestions(
      availableSuggestions,
      filterFunc,
      inputValue
    )

    setSuggestions(newSuggestions)
  }, [availableSuggestions, filterFunc, inputValue, setSuggestions])

  /* eslint-disable react/prop-types */
  const renderInputComponent = ({
    disabled,
    error,
    autoFocus,
    value,
    ref,
    helperText,
    inputLabel,
    onChange,
    inputAdornment,
    ...inputProps
  }) => (
    <TextField
      className={classes.textField}
      disabled={disabled}
      error={error}
      autoFocus={autoFocus}
      label={!editInputStyles ? inputLabel : undefined}
      placeholder={editInputStyles ? inputLabel : undefined}
      helperText={helperText}
      value={value}
      onChange={onChange}
      inputRef={e => {
        ref(e)
        inputRef.current = e
      }}
      FormHelperTextProps={{ className: classes.warningText }}
      InputProps={{
        endAdornment: inputAdornment,
        classes: {
          underline: editInputStyles && classes.inputUnderline,
        },
        ...inputProps,
      }}
    />
  )

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  )
  /* eslint-enable react/prop-types */

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const suggestionText = suggestion[labelKey]
    const matches = match(suggestionText, query)
    const parts = parse(suggestionText, matches)

    return (
      <MenuItem dense selected={isHighlighted} component="div">
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={index} className={classes.highlight}>
              {part.text}
            </span>
          ) : (
            <span key={index} className={classes.nonHighlight}>
              {part.text}
            </span>
          )
        )}
      </MenuItem>
    )
  }

  const getSuggestionValue = suggestion => suggestion[labelKey]

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (props.onChange) props.onChange(value)
  }

  const handleSuggestionsClearRequested = () => {
    if (!inputValue.trim() && props.onClear) props.onClear()

    setSuggestions([])
  }

  const onChange = ({ target: { value } }) => {
    if (value !== undefined) setInputValue(value)
  }

  const handleSuggestionSelected = (e, suggestion) => {
    props.onSelectValue(suggestion)

    // To remove focus after suggestion is selected
    inputRef.current.blur()
  }

  return (
    <Autosuggest
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderSuggestion={renderSuggestion}
      suggestions={suggestions}
      getSuggestionValue={getSuggestionValue}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      onSuggestionSelected={handleSuggestionSelected}
      inputProps={{
        disabled,
        error,
        inputLabel,
        value: inputValue,
        onChange,
        onBlur,
        inputAdornment,
      }}
    />
  )
}

AutoComplete.propTypes = {
  disabled: PropTypes.bool,
  editInputStyles: PropTypes.bool,
  error: PropTypes.bool,
  fuzzy: PropTypes.bool,
  labelKey: PropTypes.string,
  inputLabel: PropTypes.string,
  availableSuggestions: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  onSelectValue: PropTypes.func.isRequired,
  filterFunc: PropTypes.func,
  inputAdornment: PropTypes.node,
}

AutoComplete.defaultProps = {
  labelKey: 'label',
  filterFunc: val => suggestion => {
    const parsedVal = val.toString().toLowerCase()
    const parsedSuggestion = (suggestion.label || suggestion).toLowerCase()

    return parsedSuggestion.includes(parsedVal)
  },
}

export default AutoComplete
