import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CalendarWidget, DayHeaderButton } from '~/features/calendarWidget'
import { getCurrentAPPId } from '../../data/currentAPPId'
import GridItem from './GridItem'

const CalendarGridItem = () => {
  const appId = useSelector(getCurrentAPPId)
  const [width, setWidth] = useState(6)

  return (
    <GridItem setWidth={setWidth} title="Calendar">
      <CalendarWidget
        userId={appId}
        defaultView="week"
        viewLocked
        editing
        hideAdd
        dayHeader={DayHeaderButton}
        width={width}
      />
    </GridItem>
  )
}

export default CalendarGridItem
