import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import BooleanFilterCell from './customControls/BooleanFilterCell'
import { BooleanWithNoOptionFilterCell } from './customControls/BooleanWithNoOptionFilterCell'
import TextFieldFilterCell from './customControls/TextFieldFilterCell'

const filterComponents = {
  boolean: BooleanFilterCell,
  booleanWithNoOption: BooleanWithNoOptionFilterCell,
}

const FilterCell = rawProps => {
  const {
    column: { name: columnName, filterComponent },
    onFilter,
    filter,
  } = rawProps

  const props = {
    onChange: e => onFilter({ columnName, value: e.target.value }),
    value: filter && filter.value,
    ...rawProps,
  }

  if (columnName === 'actions') {
    return <Table.Cell />
  }

  if (filterComponent) {
    return (
      <Table.Cell>
        <props.column.filterComponent {...props} />
      </Table.Cell>
    )
  }

  const TypeFilter =
    filterComponents[props.column.customFilter] ||
    filterComponents[props.column.type] ||
    TextFieldFilterCell

  return (
    <Table.Cell>
      <TypeFilter {...props} />
    </Table.Cell>
  )
}

FilterCell.propTypes = {
  column: PropTypes.object,
}

export default FilterCell
