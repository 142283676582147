import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change } from 'redux-form'
import { Checkbox, DateField, TextField } from '~/components/formFields'
import { Section } from '~/components/text'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import TextFieldWithAdornment from '../../../components/TextFieldWithAdornment'
import { GroupField } from '../../Groups'
import { IPAField } from '../../IPAs'
import { MarketField } from '../../Markets'
import { PracticeField } from '../../Practices'

const styles = ({ spacing, breakpoints }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: spacing(2),
    rowGap: `${spacing(2)}px`,

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  spanTwo: {
    gridColumnStart: 'span 2',

    [breakpoints.down('sm')]: {
      gridColumnStart: 'auto',
    },
  },
  spanFour: {
    gridColumnStart: 'span 4',

    [breakpoints.down('sm')]: {
      gridColumnStart: 'auto',
    },
  },
})

const OtherInfo = ({ classes, form, onSearchNPI, changeValue }) => {
  const onClearNPI = useCallback(() => {
    changeValue(form, 'npiNumber', '')
  }, [form, changeValue])

  return (
    <Section title="Other Information">
      <div className={classes.container}>
        {/* NPI Number */}
        <Field
          className={classes.spanTwo}
          type="number"
          component={TextFieldWithAdornment}
          name="npiNumber"
          label="NPI Number"
          onSearch={onSearchNPI}
          onClear={onClearNPI}
        />

        {/* Practice */}
        <PracticeField className={classes.spanTwo} form={form} />

        {/* IPA */}
        <IPAField className={classes.spanTwo} form={form} />

        {/* Group */}
        <GroupField className={classes.spanTwo} form={form} />

        {/* Physician Market */}
        <MarketField className={classes.spanTwo} form={form} />

        {/* Next Targeted Contact Date */}
        <Field
          component={DateField}
          name="nextTargetedContactDate"
          label="Next Targeted Contact Date"
        />

        {/* MissionPoint Physician */}
        <Field
          component={Checkbox}
          name="missionPoint"
          label="Mission Point Physician"
        />

        {/* Physician Notes */}
        <Field
          className={classes.spanFour}
          component={TextField}
          name="physicianNotes"
          label="Physician Notes"
          variant="outlined"
          multiline
          rowsMax={4}
        />
      </div>
    </Section>
  )
}

OtherInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  onSearchNPI: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
}

export default compose(
  connect(null, { changeValue: change }),
  withStyles(styles)
)(OtherInfo)
