import { Record } from 'immutable'

const MedicationSearchOptionsDefaults = Record({
  beginsContains: 'BeginsWith',
  searchFlag: 'Name',
  nameFlag: 'Both',
  activeOnly: 'true',
  rxFlag: 'Both',
  searchNameFlag: 'Both',
  pageNumber: 1,
  mdt: false,
})

export default MedicationSearchOptionsDefaults
