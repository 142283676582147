import { entered } from 'redux-routable'
import { from, merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import loading from '~/utils/loading'
import { fetchCTs, loadCTs } from '../data/careTeams'
import { fetchProjects, loadProjects } from '../data/draftProjects'
import { fetchHealthPlans, loadHealthPlans } from '../data/healthPlans'
import { fetchMarkets, loadMarkets } from '../data/markets'
import { fetchPrograms } from '../data/programs'
import router from '../router'
import showError from '../utils/showError'

const appInitEpic = action$ =>
  action$.pipe(
    filter(entered(router)),
    mergeMap(() =>
      loading(
        merge(
          of(fetchPrograms.requested()),
          from(fetchMarkets()).pipe(
            map(loadMarkets),
            catchError(showError('Failed to fetch Markets.'))
          ),
          from(fetchHealthPlans()).pipe(
            map(loadHealthPlans),
            catchError(showError('Failed to fetch Health Plans.'))
          ),
          from(fetchProjects()).pipe(
            map(loadProjects),
            catchError(showError('Failed to fetch Projects.'))
          ),
          from(fetchCTs()).pipe(
            map(loadCTs),
            catchError(showError('Failed to fetch NPs.'))
          )
        )
      )
    )
  )
export default appInitEpic
