// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'FetchTripsStatus'

export const getTripsFetchStatus = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const createTripsFetchSuccess = creator('CREATE_TRIPS_FETCH_SUCCESS')
export const createTripsFetchInit = creator('CREATE_TRIPS_FETCH_INIT')

// REDUCER
export const tripsLoadStatus = createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [createTripsFetchSuccess]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [createTripsFetchInit]: () => false,
})
