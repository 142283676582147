import React from 'react'
import BaseSchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../widgets/memoizedWrapper'

class OptimizedSchemaField extends BaseSchemaField {
  shouldComponentUpdate(nextProps) {
    return Object.entries(nextProps).some(
      ([key, nextVal]) => nextVal !== this.props[key]
    )
  }
}

class SchemaField extends BaseSchemaField {
  componentWillUnmount() {
    const { registry, idSchema } = this.props
    const { deleteTouched } = registry.formContext

    deleteTouched(idSchema.$id)
  }

  render() {
    return <OptimizedSchemaField {...this.props} />
  }
}

SchemaField.propTypes = {
  registry: PropTypes.object.isRequired,
  idSchema: PropTypes.object.isRequired,
}

export default memoizedWrapper(SchemaField)
