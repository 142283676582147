import { combineReducers } from 'redux-immutable'
import channelList from './data/channelList'
import dialogs from './data/dialogs'
import healthplanSpecificPrograms from './data/healthplanSpecificPrograms'
import healthplans from './data/healthplans'
import insuranceCoverages from './data/insuranceCoverages'
import validation from './data/validation'
import key from './key'

const reducer = combineReducers({
  [channelList.key]: channelList,
  [dialogs.key]: dialogs,
  [healthplans.key]: healthplans,
  [healthplanSpecificPrograms.key]: healthplanSpecificPrograms,
  [insuranceCoverages.key]: insuranceCoverages,
  [validation.key]: validation,
})

reducer.key = key

export default reducer
