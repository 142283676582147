import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import { redirect } from '../utils/redirect'
import { handleMedMessage } from './root'

const key = 'csmdAttestation'

export const fetchCsmdUrlApi = patientState =>
  AspireAPI.get(`medications/csmd_url/${patientState}`)

export const fetchCsmdUrl = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientState'],
  operation: fetchCsmdUrlApi,
  transform: redirect,
  messages: {
    failed: handleMedMessage('There was a problem fetching CSMD URL'),
  },
})
