import React, { useCallback, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import DisplayDate from '~/components/DisplayDate'
import DisplayUTCTimestampAsLocalDate from '~/components/DisplayUTCTimestampAsLocalDate'
import PatientCell from '~/components/PatientCell'
import { getRiskLevel } from '~/features/patientInfo'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import RiskCell from '../../components/RiskCell'
import { DELETE_LIST_ITEM, EDIT_LIST_ITEM, getDialog } from '../../data/dialog'
import { getPatientLists } from '../../data/patientLists'
import DeleteListDialog from './DeleteListDialog'
import EditListDialog from './EditListDialog'
import RowActionsBase from './RowActions'

const columns = [
  { name: 'list', title: 'List' },
  {
    name: 'patientFirstName',
    title: 'Patient First Name',
    displayComponent: PatientCell,
  },
  {
    name: 'patientLastName',
    title: 'Patient Last Name',
    displayComponent: PatientCell,
  },
  { name: 'program', title: 'Program' },
  { name: 'dateOfBirth', title: 'Patient DOB', displayComponent: DisplayDate },
  { name: 'appName', title: 'APP Name' },
  { name: 'market', title: 'Market' },
  { name: 'city', title: 'City' },
  { name: 'channel', title: 'Channel' },
  {
    name: 'riskLevel',
    title: 'Risk Level',
    displayComponent: RiskCell,
    getCellValue: ({ riskLevel }) => getRiskLevel(riskLevel).display,
  },
  {
    name: 'lastCallDate',
    title: 'Last Call Date',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'nextTargetedVisitDate',
    title: 'Primary NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'nextTargetedVisitModalityLabel',
    title: 'NTVM',
  },
  {
    name: 'lastVisitDate',
    title: 'Last Visit Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'nextScheduledVisit',
    title: 'Next Scheduled Visit',
    displayComponent: DisplayDate,
  },
  {
    name: 'swNtvd',
    title: 'Social Worker NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'acpDocuments',
    title: 'ACP Documents',
    type: 'boolean',
  },
  { name: 'createdDate', title: 'Created At', displayComponent: DisplayDate },
  { name: 'reason', title: 'Reason', wrapText: true },
]

const columnExtensions = [
  { columnName: 'list', width: 200 },
  { columnName: 'patientFirstName', width: 150 },
  { columnName: 'patientLastName', width: 150 },
  { columnName: 'program', width: 200 },
  { columnName: 'appName', width: 250 },
  { columnName: 'channel', width: 200 },
  { columnName: 'reason', width: 250 },
]
const pageSizes = [25, 50]
const defaultGrouping = [{ columnName: 'list' }]
const defaultSorting = [{ columnName: 'list', direction: 'asc' }]

const PatientListsGrid = ({ lists, localSettingsKey, rowActions }) => {
  const rows = useMemo(() => lists.toArray(), [lists])

  const RowActions = useCallback(
    props => <RowActionsBase rowActions={rowActions} {...props} />,
    [rowActions]
  )

  const dialog = useSelector(getDialog)

  return (
    <React.Fragment>
      <Paper>
        <DevXGrid
          columns={columns}
          columnExtensions={columnExtensions}
          rows={rows}
          rowActions={RowActions}
          rowActionsCount={2}
          localSettingsKey={localSettingsKey}
          columnHideable
          columnReorderable
          groupable
          defaultGrouping={defaultGrouping}
          searchable
          pageable
          pageSizes={pageSizes}
          defaultPageSize={25}
          filterable
          sortable
          defaultSorting={defaultSorting}
          isVirtual={true}
        />
      </Paper>

      <EditListDialog
        open={dialog.dialogType === EDIT_LIST_ITEM}
        patientId={dialog.patientId}
        patientName={dialog.patientName}
        reason={dialog.reason}
        rowId={dialog.rowId}
        dialogType={dialog.dialogType}
      />
      <DeleteListDialog
        open={dialog.dialogType === DELETE_LIST_ITEM}
        patientId={dialog.patientId}
        patientName={dialog.patientName}
        reason={dialog.reason}
        rowId={dialog.rowId}
      />
    </React.Fragment>
  )
}

PatientListsGrid.propTypes = {
  lists: PropTypes.list.isRequired,
  localSettingsKey: PropTypes.string,
  rowActions: PropTypes.elementType,
}

export default connect(state => ({
  lists: getPatientLists(state),
}))(PatientListsGrid)
