import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import key from '../key'

export const NonPhysician = Record({
  id: null,

  salutation: null,
  firstName: null,
  middleName: null,
  lastName: null,
  suffix: null,

  title: null,

  phone: null,
  mobilePhone: null,
  fax: null,
  email: null,

  street: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,

  practiceId: null,
  practice: null,

  notes: null,

  createdBy: null,
})

export const addNonPhysician = Request({
  typePrefix: key,
  typeBase: 'ADD_NON_PHYSICIAN',
  requestParams: ['nonPhysician'],
  operation: nonPhysician =>
    AspireAPI.post('contacts/non_physicians', nonPhysician),
  transform: NonPhysician,
  messages: {
    succeeded: 'Successfully added new non-physician',
    failed: error => error.response.data.message,
  },
})
