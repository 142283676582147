import classnames from 'classnames'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: 300,
    padding: spacing(0.75),
    marginBottom: spacing(0.5),
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: palette.shades.dark,
  },
  selected: {
    color: palette.common.white,
    backgroundColor: ({ color }) => color || palette.primary.dark,
  },
  icon: {
    marginRight: spacing(1),
  },
  title: {
    marginRight: spacing(1),
    whiteSpace: 'nowrap',
  },
}))

const FilterBase = props => {
  const { title, value, children, onClick } = props

  const classes = useStyles(props)

  const icon = value ? 'check_box' : 'check_box_outline_blank'

  return (
    <div
      className={classnames(classes.container, {
        [classes.selected]: Boolean(value),
      })}
      onClick={onClick}
    >
      <Icon className={classes.icon}>{icon}</Icon>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </div>
  )
}

FilterBase.propTypes = {
  color: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
}

export default FilterBase
