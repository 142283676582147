import { loggedOut } from '~/data/session'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'

// 3 minutes
export const SECONDS_UNTIL_EXPIRATION = 3 * 60

const SESSION_EXTENSION_DIALOG = 'sessionExtensionDialog'

const creator = scopedCreator(SESSION_EXTENSION_DIALOG)
export const dialogOpened = creator('DIALOG_OPENED')
export const dialogClosed = creator('DIALOG_CLOSED')

export default createReducer(SESSION_EXTENSION_DIALOG, false, {
  [dialogOpened]: () => true,
  [dialogClosed]: () => false,
  [loggedOut]: () => false,
})

export const isDialogOpen = get(SESSION_EXTENSION_DIALOG)
