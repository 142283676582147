import { combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { ignoreElements, mergeMap, pluck, tap } from 'rxjs/operators'
import { downloadCSV } from '~/utils/csv'
import loading from '~/utils/loading'
import { DOWNLOAD_REQUESTED } from '../data/common/shared'
import {
  fetchDraftProjectPatients,
  getDraftProjPatients,
} from '../data/draftProjectPatients'
import { polygonPatientsCleared } from '../data/polygonPatients'

const downloadRequestedEpic = action$ =>
  action$.pipe(
    ofType(DOWNLOAD_REQUESTED),
    pluck('payload', 'project_id'),
    mergeMap(project_id =>
      concat(
        of(polygonPatientsCleared()),
        loading(of(fetchDraftProjectPatients.requested(project_id)))
      )
    )
  )

const downloadFinishedEpic = (action$, state$) =>
  action$.pipe(
    ofType(fetchDraftProjectPatients.SUCCEEDED),
    tap(() => {
      const state = state$.value
      const patients = getDraftProjPatients(state)
      if (patients.size > 0) downloadCSV(patients, 'draft_project_patients.csv')
    }),
    ignoreElements()
  )

export default combineEpics(downloadRequestedEpic, downloadFinishedEpic)
