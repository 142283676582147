import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const key = 'patientLtcs'

export const LTC = Record({
  id: null,
  sfid: null,
  patientId: null,
  caseNumber: null,
  facilityName: null,
  notes: null,
  admissionDate: null,
  dischargeDate: null,
  createdBy: null,
  createdAt: null,
})

export const fetchLTCs = Request({
  typePrefix: key,
  typeBase: 'FETCH_LTCS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get('ltcs', { params: { patientId } }),
  transform: into(LTC, 'id'),
  messages: { failed: 'There was a problem fetching LTCs' },
})

export const addLTC = Request({
  typePrefix: key,
  typeBase: 'ADD_LTC',
  requestParams: ['patientId', 'ltc'],
  operation: (patientId, ltc) =>
    AspireAPI.post('ltcs', { ltc }, { params: { patientId } }),
  transform: LTC,
  messages: {
    failed: 'There was a problem adding this LTC',
    succeeded: 'Sucessfully added LTC record',
  },
})

export const saveLTC = Request({
  typePrefix: key,
  typeBase: 'SAVE_LTC',
  requestParams: ['ltcId', 'changes'],
  operation: (ltcId, changes = {}) =>
    AspireAPI.put(`ltcs/${ltcId}`, {
      ...changes,
    }),
  messages: { failed: 'There was a problem saving this LTC' },
})

export const deleteLTC = Request({
  typePrefix: key,
  typeBase: 'DELETE_LTC',
  requestParams: ['ltcId'],
  operation: ltcId => AspireAPI.delete(`ltcs/${ltcId}`),
  messages: { failed: 'There was a problem deleting this LTC' },
})

export default createReducer(key, Map(), {
  [fetchLTCs.SUCCEEDED]: (_state, { payload }) => payload,
  [addLTC.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, LTC(payload)),
  [saveLTC.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.mergeIn([payload.ltcId], payload.changes),
  [deleteLTC.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.delete(payload.ltcId),
})

export const getLTCs = pipe(get(rootKey), get(key))
