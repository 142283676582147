import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { fetchReferralCases } from '../data/referrals'
import { PATIENT_RECORD_REFERRAL_INFO } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_REFERRAL_INFO)),
    switchTo(state$),
    map(getPatientId),
    map(fetchReferralCases.requested)
  )
