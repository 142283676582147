import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'

const INTERVENTIONS = 'interventions'

export const Intervention = Record({
  id: null,
  label: null,
  status: null,
  goals: null,
  createdBy: null,
  modifiedBy: null,
  createdAt: null,
  modifiedAt: null,
})

const transformInterventions = (interventions: any) => {
  return List(
    interventions.map((intervention: any) => {
      return Intervention({
        id: intervention?.id,
        label: intervention?.label,
        status: intervention?.status ? 'Active' : 'Deactivated',
        goals: intervention?.goals,
        createdBy: intervention?.createdBy,
        modifiedBy: intervention?.modifiedBy,
        createdAt: formatDate(intervention?.createdAt),
        modifiedAt: formatDate(intervention?.modifiedAt),
      })
    })
  )
}

export const fetchInterventions = Request({
  typePrefix: rootKey,
  typeBase: 'INTERVENTION',
  operation: () => AspireAPI.get('admin_tools/interventions'),
  transform: transformInterventions,
  messages: { failed: 'There was an issue fetching interventions' },
})

export default createReducer(INTERVENTIONS, List(), {
  [fetchInterventions.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getInterventions = pipe(getRoot, get(INTERVENTIONS))
