import React from 'react'
// @ts-expect-error ts(7016) FIXME: '~/utils/telemetry' implicitly has an 'any' type... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'

/**
 * React component that can be used to wrap child components that may crash
 * (throw errors while rendering). Uncaught errors will be logged and the
 * component will prevent the error from bubbling further up the tree (which
 * in React 16+ could cause the whole application to unmount).
 *
 * @example
 * <ErrorBoundary>
 *   <ComponentThatMayCrash />
 * </ErrorBoundary>
 *
 * @example
 * <ErrorBoundary renderError={() => <p>Something went wrong!</p>}>
 *   <ComponentThatMayCrash />
 * </ErrorBoundary>
 */

class ErrorBoundary extends React.Component<
  {
    onError?: (err: unknown, info: unknown) => void
    renderError?: (err: unknown) => JSX.Element
  },
  { error: unknown }
> {
  state = {
    error: null,
  }

  componentDidCatch(error: unknown, info: unknown) {
    telemetry.error(new Error('Component crashed'), { error, info })
    this.setState({ error })
    if (this.props.onError) {
      this.props.onError(error, info)
    }
  }

  render() {
    const { children = null, renderError } = this.props
    const { error } = this.state

    if (error) {
      return !!renderError && renderError(error)
    }
    return children
  }
}

export default ErrorBoundary
