import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Box, Icon, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  boxRightMargin: { marginRight: theme.spacing(1) },
  boxLeftMargin: { marginBottom: theme.spacing(1) },
  strikeThrough: { textDecoration: 'line-through' },
}))

interface PrimaryAddressPropTypes {
  primaryAddress: PropTypes.object.isRequired
}

export const PrimaryAddress = ({ primaryAddress }: PrimaryAddressPropTypes) => {
  const classes = useStyles()

  return (
    <>
      {primaryAddress ? (
        <Box clone={true} flex="1" display="flex" alignItems="center">
          <Typography>
            <Box clone={true} className={classes.boxRightMargin}>
              <Icon fontSize="inherit"> home </Icon>
            </Box>
            <Box
              component={'span'}
              className={primaryAddress.active ? '' : classes.strikeThrough}
            >
              {`${primaryAddress.street}${' '}${primaryAddress.street2}${' '}${
                primaryAddress.city
              }${', '}${primaryAddress.state}${' '}${primaryAddress.zip}`}
            </Box>
          </Typography>
        </Box>
      ) : (
        <Box flex="1 1 0%">&nbsp;</Box>
      )}
    </>
  )
}
