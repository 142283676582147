// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getNotificationList } from './root'

const OFFSET = 'offset'

const creator = scopedCreator(rootKey)
export const offsetSet = creator('OFFSET_SET', ['offset'])

export default createReducer(OFFSET, null, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [offsetSet]: (_state: any, { payload: { offset } }: any) => offset,
})

export const getOffset = pipe(getNotificationList, get(OFFSET))
