import { List, Record, Set } from 'immutable'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/locationSearch' or ... Remove this comment to see the full error message
import { Location } from '~/features/locationSearch'
import { get } from '~/utils/data'
import key from '../../key'

export const User = Record({
  id: null,
  status: null,
  firstName: null,
  middleName: null,
  lastName: null,
  name: null,
  email: null,
  phone: null,
  phoneExtension: null,
  mobilePhone: null,
  location: Location(),
  timeZone: null,
  hireDate: null,
  terminationDate: null,
  active: false,
  role: null,
  aspireRole: null,
  market: null,
  fteStatus: null,
  calendarApprover: false,
  credentials: null,
  npiNumber: null,
  firstSignedAssessment: null,
  appGraduationCategory: null,
  graduatedForPhysicianReview: false,
  signatureDatetime: null,
  acknowledgeSignature: false,
  usDomainId: null,
  contracts: List(),
  roleIds: Set(),
  roles: List(),
  permissionSetIds: Set(),
  permissionSets: List(),
})

export const getRoot = get(key)
