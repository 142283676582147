import { ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { switchTo } from '~/utils/operators'
import { CURRENT_YEAR } from '../data/constants'
import { getCurrentPatient, getNextYearTripsFlag } from '../data/currentPatient'
import { fetchMemberBenefits } from '../data/memberInfo'
import { generateRecurTrip } from '../data/recurPopup'
import { saveNewTrip, updateTrip } from '../data/tripOperation'
import {
  clearTripAction,
  fetchNextYearPatientTrips,
  fetchPatientTripCounts,
  fetchPatientTrips,
  patientTripsCleared,
} from '../data/trips'

const clearAndFetchTrips = (data: any) => {
  patientTripsCleared()
  clearTripAction()
  const payload = data.currentPatient
  const requestParams = {
    benefitYear: CURRENT_YEAR,
    classplanProductId: payload.classplanProductId,
    eligibilityEffectiveDate: payload.eligibilityEffectiveDate,
    productDescription: payload.productDescription,
    shouldRefreshEEBenefit: false,
  }
  const tripsParam = [
    payload?.patientId,
    payload?.eligibilityEffectiveDate,
    payload?.classplanProductId,
    payload?.countyName,
  ]
  return [
    data.nextYearTripsFlag
      ? fetchNextYearPatientTrips.requested(...tripsParam)
      : fetchPatientTrips.requested(...tripsParam),
    fetchMemberBenefits.requested(payload.patientId, requestParams),
    fetchPatientTripCounts.requested(payload.patientId, CURRENT_YEAR + 1),
  ]
}

export const refreshTrips = (action$: any, state$: any) =>
  action$.pipe(
    ofType(saveNewTrip.SUCCEEDED),
    switchTo(state$),
    map((state: any) => {
      return {
        currentPatient: getCurrentPatient(state),
        nextYearTripsFlag: getNextYearTripsFlag(state),
      }
    }),
    mergeMap((payload: any) => clearAndFetchTrips(payload))
  )

export const refreshTripsOnUpdate = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updateTrip.SUCCEEDED),
    switchTo(state$),
    map((state: any) => {
      return {
        currentPatient: getCurrentPatient(state),
        nextYearTripsFlag: getNextYearTripsFlag(state),
      }
    }),
    mergeMap((payload: any) => clearAndFetchTrips(payload))
  )

export const refreshTripsOnRecur = (action$: any, state$: any) =>
  action$.pipe(
    ofType(generateRecurTrip.SUCCEEDED),
    switchTo(state$),
    map((state: any) => {
      return {
        currentPatient: getCurrentPatient(state),
        nextYearTripsFlag: getNextYearTripsFlag(state),
      }
    }),
    mergeMap((payload: any) => clearAndFetchTrips(payload))
  )
