import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Columns, { Column } from '~/apps/patientRecord/components/Columns'
import Section from '~/apps/patientRecord/components/Section'
import Info from '~/apps/patientRecord/containers/PatientInfo/Info'
import {
  Patient as PatientInfoRecord,
  getIsCaremoreBrand,
  getPatientInfo,
} from '~/features/patientInfo'
import { formatDate } from '~/utils/format'
import { convertToYesNo } from '~/utils/formatBoolean'
import PropTypes from '~/utils/propTypes'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { coverageSelectedForEditing } from '../data/dialogs'

const styles = ({ spacing }) => ({
  buttons: {
    padding: spacing(2, 0, 0, 1),
    textAlign: 'right',
    '& button:last-child': {
      marginLeft: spacing(1),
    },
  },
})

const CoverageDetails = ({
  classes,
  onRequestEdit,
  row,
  patientInfo: {
    demographics: { dateOfBirth, name },
  },
  isCaremoreBrand,
}) => {
  const formattedVbid = convertToYesNo(row.vbid)

  return (
    <div>
      <Columns>
        <Column>
          <Section title="Channel">
            <Info label="Channel Name" content={row.primaryContract} />
            <Info label="Healthplan Name" content={row.healthplan} />
            <Info label="Healthplan Primary ID" content={row.accountId} />
            {!isCaremoreBrand && (
              <Info
                label="Healthplan Account ID"
                content={row.healthplanAccountId}
              />
            )}

            <Info
              label={isCaremoreBrand ? 'Group Name' : 'Healthplan Group Name'}
              content={row.groupName}
            />

            <Info
              label={
                isCaremoreBrand ? 'Group Number' : 'Healthplan Group Number'
              }
              content={row.groupNumber}
            />

            {isCaremoreBrand && (
              <Info label="CMS Plan ID" content={row.cmsPlanId} />
            )}
            <Info
              label="Healthplan Alternate ID"
              content={row.healthplanPatientId}
            />
            {isCaremoreBrand && (
              <Info label="Healthplan Type" content={row.healthplanType} />
            )}
            {!isCaremoreBrand && (
              <React.Fragment>
                <Info label="MBI" content={row.mbi} />
                <Info
                  label="Healthplan Specific Programs"
                  content={row.healthplanSpecificPrograms}
                />
              </React.Fragment>
            )}
            <Info
              label="Effective Date"
              content={formatDate(row.effectiveDate)}
            />
            <Info
              label="Expiration Date"
              content={formatDate(row.expirationDate)}
            />
            {isCaremoreBrand && (
              <React.Fragment>
                <Info label="Product Name" content={row.productName} />
                <Info label="Product Number" content={row.productNumber} />
              </React.Fragment>
            )}
            <Info label="Priority" content={row.priority} />
            <Info
              label="Value Based Insurance Design"
              content={formattedVbid}
            />
            {!isCaremoreBrand && (
              <Info label="Product Line" content={row.productLineC} />
            )}
          </Section>
        </Column>
        <Column>
          <Section title="Subscriber Info">
            <Info label="Subscriber" content={row.subscriberChoice} />
            <Info
              label="Subscriber Name"
              content={isCaremoreBrand ? name : row.subscriberName}
            />
            <Info
              label="Subscriber DOB"
              content={isCaremoreBrand ? dateOfBirth : row.subscriberDob}
            />
          </Section>
          {isCaremoreBrand && (
            <Section title="COB Details">
              <Info
                label="Insurance Name"
                content={
                  row.coverageOfBenefits && row.coverageOfBenefits.mcreName
                }
              />
              <Info
                label="Policy #"
                content={
                  row.coverageOfBenefits && row.coverageOfBenefits.mecbPolicyId
                }
              />
              <Info
                label="Insurance Order"
                content={
                  row.coverageOfBenefits &&
                  row.coverageOfBenefits.mecbInsurOrder
                }
              />
              <Info
                label="Effective Date"
                content={
                  row.coverageOfBenefits &&
                  formatDate(row.coverageOfBenefits.effectiveDate)
                }
              />
              <Info
                label="Term Date"
                content={
                  row.coverageOfBenefits &&
                  formatDate(row.coverageOfBenefits.termDate)
                }
              />
            </Section>
          )}
          {!isCaremoreBrand && (
            <Section title="Insurance Notes">
              <Info
                label="Last Modified By"
                content={row.lastModifiedByUser.name}
              />
              <Info label="Notes" content={row.insuranceNotes} />
            </Section>
          )}
        </Column>
      </Columns>
      {!isCaremoreBrand && (
        <div className={classes.buttons}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onRequestEdit(row.id)}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  )
}

CoverageDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestEdit: PropTypes.func,
  row: PropTypes.record.isRequired,
  patientInfo: PropTypes.instanceOf(PatientInfoRecord).isRequired,
  isCaremoreBrand: PropTypes.bool.isRequired,
}

export default compose(
  connect(
    state => ({
      patientInfo: getPatientInfo(state),
      isCaremoreBrand: getIsCaremoreBrand(state),
    }),
    {
      onRequestEdit: coverageSelectedForEditing,
    }
  ),
  withStyles(styles)
)(CoverageDetails)
