import { combineEpics, ofType } from 'redux-observable'
import { changedTo, exited } from 'redux-routable'
import { concat, of, pipe, timer } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ap... Remove this comment to see the full error message
import { fetchAssessments } from '~/apps/assessment/data/assessments'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchDailyEvents } from '~/features/encounterWidget'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
import { fetchHighRiskHuddle } from '~/features/highRiskHuddle/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchOnCallsForUserId } from '~/features/onCall'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerOrdersFetchRequested } from '~/features/orders/data/loaders'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { userIdChanged } from '~/features/orders/data/userId'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchSelectedAPPs } from '~/features/scheduling/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested } from '~/features/tasks'
import {
  DASHBOARD_REFRESH_REQUESTED,
  appDashRefreshRequested,
} from '../data/dashAppId'
// import { fetchAppMetrics, fetchedMetrics } from '../data/metrics'
import router from '../router'

const REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes

// const fetchAndStoreAPPMetrics = appId =>
//   loading(
//     from(fetchAppMetrics(appId)).pipe(map(fetchedMetrics)),
//     'There was an issue loading APP Metrics.'
//   )

const appIdChanges = pipe(
  filter(changedTo(router)),
  pluck('payload', 'params', 'appId'),
  filter(appId => appId !== undefined),
  distinctUntilChanged()
)

const autoRefresh = (action$: any) =>
  action$.pipe(
    appIdChanges,
    switchMap(appId =>
      timer(0, REFRESH_INTERVAL).pipe(
        takeUntil(action$.pipe(filter(exited(router)))),
        mapTo(appDashRefreshRequested(appId))
      )
    )
  )

const updateOrdersUser = (action$: any) =>
  action$.pipe(appIdChanges, map(userIdChanged))

const refreshRequested = (action$: any) =>
  action$.pipe(
    ofType(DASHBOARD_REFRESH_REQUESTED),
    pluck('payload', 'userId'),
    mergeMap(userId =>
      concat(
        of(
          fetchHighRiskHuddle.requested(userId),
          ownerTasksFetchRequested(userId),
          fetchEpisodesOfCare.requested({ ownerId: userId }),
          ownerOrdersFetchRequested(userId),
          fetchOnCallsForUserId.requested(userId),
          fetchSelectedAPPs.requested(userId),
          fetchDistinctFieldValues(['encounter_assessment_status']),
          fetchAssessments.requested({
            provider_ids: [userId],
            statuses: ['not_started', 'created', 'in_progress'],
          })
        ),
        // fetchAndStoreAPPMetrics(userId),
        fetchDailyEvents(userId)
      )
    )
  )

export default combineEpics(autoRefresh, refreshRequested, updateOrdersUser)
