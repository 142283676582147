import React, { useState } from 'react'
import { PhoneNumberField } from '~/components/fields'
import { useAction } from '~/hooks'
import { Button, Divider, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { contactSelected } from '../../../data/callLog'
import { Contact } from '../../../data/contacts'

const useStyles = makeStyles(({ spacing }) => ({
  phoneField: {
    gridColumnStart: 'span 2',
  },
  button: {
    marginRight: spacing(1),
  },
  icon: {
    marginRight: spacing(0.5),
  },
  divider: {
    margin: spacing(1, 0),
    gridColumnStart: 'span 3',
  },
}))

const ManualContact = () => {
  const classes = useStyles()

  const selectContact = useAction(contactSelected)

  const [phone, setPhone] = useState('')

  const onSetActive = () => {
    const contact = Contact({ phoneNumber: phone })

    selectContact(contact)
  }

  const disabled = phone.trim().length !== 14

  return (
    <React.Fragment>
      <div className={classes.phoneField}>
        <PhoneNumberField
          label="Other Phone Number"
          value={phone}
          onChange={value => {
            setPhone(value)
          }}
        />
      </div>

      <div>
        <Button
          disabled={disabled}
          className={classes.button}
          color="primary"
          variant="contained"
          size="small"
          onClick={onSetActive}
        >
          <Icon className={classes.icon} fontSize="small">
            phone_enabled
          </Icon>
          Attempt
        </Button>

        <Button
          disabled={phone === ''}
          color="secondary"
          variant="contained"
          size="small"
          onClick={() => {
            setPhone('')
          }}
        >
          <Icon className={classes.icon} fontSize="small">
            phone_disabled
          </Icon>
          Clear
        </Button>
      </div>

      <Divider className={classes.divider} />
    </React.Fragment>
  )
}

export default ManualContact
