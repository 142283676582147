export default {
  language: {
    title: 'Patient Verbal Consent for Services',
    overviewSubtitle: 'I. Overview of Advanced Illness Care',
    overview:
      'Advanced illness care is a patient-centered, interdisciplinary approach to care that specializes in managing serious illness and the symptoms they cause as well as advanced care planning. Our goal is to assist you with any troubling symptoms of your illness, whether physical, emotional, social or spiritual and, thus, improve the quality of your life. Through ongoing communication with you, your personal support system, and your current medical providers, we will develop a plan to help achieve your goals of care. This process may include co-management of your disease and symptoms as well as recommendations for care to other providers. It is important that you make your needs and concerns known to the Aspire Health staff so we can best meet your expectations of care. If you have any questions regarding this information or the plan of care, we will be glad to provide more information.',
    coverageSubtitle: 'II. Advanced Illness Case Coverage',
    coverage:
      'Aspire Health participates in most commercial insurance programs as well as Medicare and Medicaid and will bill these entities directly for services provided. Upon receipt of payment, a statement if any remaining balances due will be provided to you.',
    consentSubtitle: 'III. Consent for Services',
    consentList: [
      'I have received a full explanation of advanced illness care.',
      'I choose to receive advanced illness care from Aspire Health.',
      'I have received a copy of the Aspire Health Notice of Privacy Practices.',
      'I give consent for the release of all medical information for the purposes of medical treatment, payment, and for regulatory agencies upon request.',
      'Aspire Health may represent me in discussions with insurance providers regarding service eligibility.',
      'The Aspire Health interdisciplinary team may perform necessary procedures and treatments as prescribed and included in my plan of care.',
      'I have had an opportunity to have my questions answered by an Aspire Health representative.',
      'I will notify Aspire Health of any changes in my insurance coverage.',
      'I give permission for Aspire Health to leave messages at my above phone number.',
    ],
  },
}
