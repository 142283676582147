import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { switchTo } from '~/utils/operators'
import { chartPageChanged } from '../data/chart'
import { fetchVitals } from '../data/vitals'

export default (action$, state$) =>
  action$.pipe(
    ofType(fetchVitals.SUCCEEDED),
    switchTo(state$),
    map(chartPageChanged)
  )
