import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import { get, scopedCreator } from '~/utils/data'
import key from '../../key'

export const snoozePatient = Request({
  typePrefix: key,
  typeBase: 'SNOOZE_PATIENT',
  requestParams: ['patientId', 'snooze'],
  operation: (patientId, snooze) =>
    AspireAPI.put(`v1/patient/${patientId}/snooze`, { snooze }),
  messages: {
    succeeded: 'Successfully snoozed patient',
    failed: 'There was a problem snoozing this patient',
  },
})

const creator = scopedCreator(key)
export const callLogCleared = creator('CALL_LOG_CLEARED', false)

export const getRoot = get(key)
