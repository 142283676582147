export const TIER_ONE = 'Five Percent Tier One'
export const TIER_TWO = 'Five Percent Tier Two'
export const TIER_THREE = 'Five Percent Tier Three'
export const CAREMORE_AT_HOME = 'Carelon Health At Home'
export const CAREMORE_BRAND = 'caremore'
export const HBPC_HYBRID = 'HBPC Hybrid'
export const HOME_BASED_PALLIATIVE_CARE = 'Home Based Palliative Care'
const ONCOLOGY = 'Oncology'
export const TELEPHONIC = 'Telephonic'
export const CAREMORE_HEALTH_NETWORK = 'Carelon Health Network'
export const COMMERCIAL_PALLIATIVE_CARE = 'Commercial Palliative Care'
export const HIGH_RISK_COMPLEX_CARE = 'High Risk Complex Care'
export const MEDICAID_AT_HOME = 'Medicaid At Home'
const PALLIATIVE_ABBREVIATION = 'PAL'
const CAREMORE_AT_HOME_ABBREVIATION = 'CMH'
const HBPC_HYBRID_ABBREVIATION = 'HPH'
const TIER_ONE_ABBREVIATION = 'T5 / 1'
const TIER_TWO_ABBREVIATION = 'T5 / 2'
const TIER_THREE_ABBREVIATION = 'T5 / 3'
const ONCOLOGY_ABBREVIATION = 'ONC'
const TELEPHONIC_ABBREVIATION = 'TEL'
const DEFAULT_ABBREVIATION = 'N/A'
const COMMERCIAL_PALLIATIVE_ABBREVIATION = 'CPC'
const HIGH_RISK_COMPLEX_CARE_ABBREVIATION = 'HRC'
export const PC_PLUS = 'PC+'
const HEALTH_NETWORK_ABBREVIATION = 'CLA'
export const PC_PLUS_ABBREVIATION = 'PC+'

export const TOP_FIVE_TIERS = [TIER_ONE, TIER_TWO, TIER_THREE]

export const UNENGAGED_PROGRAMS = [
  TIER_ONE,
  TIER_TWO,
  TIER_THREE,
  CAREMORE_AT_HOME,
]
export const ASPIRE_RN_ASSESSMENT_PROGRAMS = [
  COMMERCIAL_PALLIATIVE_CARE,
  HIGH_RISK_COMPLEX_CARE,
  HOME_BASED_PALLIATIVE_CARE,
  HBPC_HYBRID,
  TELEPHONIC,
  CAREMORE_AT_HOME,
]

export const PRE_VISIT_ASSESSMENT_PROGRAMS = [
  HOME_BASED_PALLIATIVE_CARE,
  HBPC_HYBRID,
]

export const EPISODE_OF_CARE_PROGRAMS = [
  CAREMORE_AT_HOME,
  CAREMORE_HEALTH_NETWORK,
  HBPC_HYBRID,
  TIER_ONE,
  TIER_TWO,
  TIER_THREE,
  TELEPHONIC,
  PC_PLUS,
  HIGH_RISK_COMPLEX_CARE,
]

export const NON_CLASSIC_PROGRAMS = [CAREMORE_AT_HOME, PC_PLUS]

export const CASE_MANAGEMENT_PROGRAMS = [CAREMORE_HEALTH_NETWORK, PC_PLUS]

export const getProgramEnrolledAbbreviation = (programEnrolled: string) => {
  switch (programEnrolled) {
    case HOME_BASED_PALLIATIVE_CARE:
      return PALLIATIVE_ABBREVIATION
    case CAREMORE_HEALTH_NETWORK:
      return HEALTH_NETWORK_ABBREVIATION
    case PC_PLUS:
      return PC_PLUS_ABBREVIATION
    case ONCOLOGY:
      return ONCOLOGY_ABBREVIATION
    case TELEPHONIC:
      return TELEPHONIC_ABBREVIATION
    case TIER_ONE:
      return TIER_ONE_ABBREVIATION
    case TIER_TWO:
      return TIER_TWO_ABBREVIATION
    case TIER_THREE:
      return TIER_THREE_ABBREVIATION
    case CAREMORE_AT_HOME:
      return CAREMORE_AT_HOME_ABBREVIATION
    case HBPC_HYBRID:
      return HBPC_HYBRID_ABBREVIATION
    case COMMERCIAL_PALLIATIVE_CARE:
      return COMMERCIAL_PALLIATIVE_ABBREVIATION
    case HIGH_RISK_COMPLEX_CARE:
      return HIGH_RISK_COMPLEX_CARE_ABBREVIATION
    default:
      return DEFAULT_ABBREVIATION
  }
}

export const inTopFive = (programEnrolled: string) =>
  TOP_FIVE_TIERS.includes(programEnrolled)

export const caremoreAtHome = (programEnrolled: string) =>
  CAREMORE_AT_HOME.includes(programEnrolled)
