import { fromJS } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { change } from 'redux-form/immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { titleCase } from '~/utils/stringManipulation'

const odsAutofill = (
  dispatch: any,
  form: any,
  data: any,
  customHealthplanFields: any
) => {
  const customFields = customHealthplanFields
    .filter((V: any) => V.get('fieldType') === 'custom')
    .toList()
  customFields.forEach((field: any) => {
    const location = `healthplan.primaryInsurance.custom.${field.get(
      'referralFieldName'
    )}`
    const value = data.get(field.get('odsAutoFillName'))
    dispatch(change(form, location, value))
  })

  dispatch(change(form, 'address.street1', titleCase(data.get('ADDRESS1'))))
  dispatch(change(form, 'address.street2', titleCase(data.get('ADDRESS2'))))
  dispatch(change(form, 'patient.dob', data.get('BIRTHDATE')))
  dispatch(change(form, 'address.city', titleCase(data.get('CITY'))))
  dispatch(change(form, 'patient.firstName', titleCase(data.get('FIRSTNAME'))))
  dispatch(change(form, 'patient.lastName', titleCase(data.get('LASTNAME'))))
  dispatch(change(form, 'patient.sex', data.get('SEX') === 'Male' ? 'M' : 'F'))
  dispatch(
    change(form, 'referralInfo.globalMemberId', data.get('GLOBALMEMBERID'))
  )
  dispatch(
    change(
      form,
      'healthplan.primaryInsurance.program',
      data.get('HP_SPECIFIC_PROGRAM')
    )
  )
  dispatch(change(form, 'diagnosis.codes', data.get('PRIMARY_DIAGNOSIS')))
  dispatch(
    change(
      form,
      'healthplan.primaryInsurance.accountNumber',
      data.get('UMI') || data.get('ANTHEM_ELIGIBILITYID')
    )
  )
  dispatch(
    change(
      form,
      'healthplan.primaryInsurance.accountId',
      data.get('HEALTH_PLAN_ACCOUNT_ID')
    )
  )
  dispatch(change(form, 'healthplan.primaryInsurance.mbi', data.get('MBI')))
  dispatch(
    change(form, 'healthplan.primaryInsurance.maPcpi', data.get('MA_PCPI'))
  )
  dispatch(
    change(
      form,
      'healthplan.primaryInsurance.secondaryId',
      data.get('SECONDARY_ID')
    )
  )
  dispatch(change(form, 'address.state', data.get('STATE')))
  dispatch(change(form, 'address.postalCode', data.get('ZIP')))

  const alternateFieldMappings = customHealthplanFields
    .filter((V: any) => V.get('fieldType') === 'override')
    .toList()
  alternateFieldMappings.forEach((field: any) => {
    const location = `healthplan.primaryInsurance.${field.get(
      'referralFieldName'
    )}`
    const value = data.get(field.get('odsAutoFillName'))
    dispatch(change(form, location, value))
  })

  const contacts = []

  if (data.get('PHI_PHONE')) {
    const contact = {
      type: 'Healthplan PHI',
      firstName: data.get('PHI_FIRST_NAME'),
      lastName: data.get('PHI_FIRST_NAME'),
      phoneNumber: data.get('PHI_PHONE'),
    }
    contacts.push(contact)
  }

  if (data.get('PHONE')) {
    const contact = {
      type: 'Home',
      phoneNumber: data.get('PHONE'),
    }
    contacts.push(contact)
  }

  dispatch(change(form, 'contacts', fromJS(contacts)))
}

export default odsAutofill
