import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import rootKey from '../key'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { getRoot } from './common/shared'

const GETCARETEAMPODS = 'FetchCareTeamPods'
const ADDEDITCARETEAMPODS = 'AddEditCareTeamPods'

const CareTeamPodsRecord = Record({
  id: null,
  name: null,
})

const mapCareTeamPods = (results: any) =>
  List(
    results?.map((result: any) =>
      CareTeamPodsRecord({
        id: result?.id,
        name: result?.name,
      })
    )
  )

export const fetchCareTeamPods = Request({
  typePrefix: rootKey,
  typeBase: 'GET_ALL_PODS',
  requestParams: [],
  operation: () => AspireAPI.get('care_teams/pods'),
  transform: mapCareTeamPods,
  messages: { failed: 'Failed to fetch all care team pods' },
})

export const saveNewCareTeamPod = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_NEW_POD',
  requestParams: ['data'],
  operation: (data: any) => AspireAPI.post('care_teams/pods', { ...data }),
  messages: {
    failed: 'Problem creating the new care team pod',
    succeeded: 'New pod created',
  },
})

export const editCareTeamPod = Request({
  typePrefix: rootKey,
  typeBase: 'EDIT_POD',
  requestParams: ['id', 'data'],
  operation: (id: number, data: any) =>
    AspireAPI.put(`care_teams/pods/${id}`, { ...data }),
  messages: {
    failed: 'Problem updating pod name',
    succeeded: 'Pod name updated',
  },
})

export const careTeamPods = createReducer(GETCARETEAMPODS, List(), {
  [fetchCareTeamPods.SUCCEEDED]: compose(payload, action),
})

export const addEditCareTeamPods = createReducer(ADDEDITCARETEAMPODS, Boolean, {
  [saveNewCareTeamPod.SUCCEEDED]: () => false,
  [editCareTeamPod.SUCCEEDED]: () => false,
})

export const getCareTeamPods = pipe(getRoot, get(GETCARETEAMPODS))
