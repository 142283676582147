import { Map } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { momentToDate, removeSecondsFromDate } from '~/utils/dates'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getEventsRoot, transformEvent } from './root'

const EVENTS = 'events'
const VISIT_TYPES = ['visit', 'visit_placeholder']

const transformRequestEvent = (event, reschedule) => {
  return {
    type: event.recordType,
    subtype: event.eventType,
    startAt: transformEventDate(event.start),
    endAt: transformEventDate(event.end),
    startDate: event.startDate,
    endDate: event.endDate,
    notes: VISIT_TYPES.includes(event.recordType)
      ? event.description
      : event.subject,
    userId: event.ownerId,
    serviceLocationId: event.serviceLocationId
      ? parseInt(event.serviceLocationId)
      : null,
    location:
      event.location && event.location.address
        ? {
            address: event.location.address,
            apartment: event.location.apartment,
            lat: event.location.latitude,
            lng: event.location.longitude,
            exact: event.location.exact,
          }
        : null,
    availability:
      event.recordType === 'availability'
        ? { maxVisits: event.maxVisits }
        : null,
    schedulingGuidance:
      event.recordType === 'scheduling_guidance'
        ? {
            guidanceType: event.guidanceType,
            rideAlongRole: event.rideAlongRole,
            travelNotes: event.travelNotes,
            visitMethod: event.visitMethod,
          }
        : null,
    visitPlaceholder:
      event.recordType === 'visit_placeholder'
        ? {
            confirmed: event.confirmed,
            location: event.assessmentType || 'in_person',
            encounterTypeKey: event.encounterType,
            patientId: event.patientId,
          }
        : null,
    visit:
      event.recordType === 'visit'
        ? {
            confirmed: event.confirmed,
            location: event.assessmentType || 'in_person',
            encounter: {
              type: event.encounterType,
              patientId: event.patientId,
            },
            guests: event.guests,
            reschedule: reschedule
              ? {
                  type: reschedule.rescheduleType,
                  rescheduleReason: reschedule.rescheduleReason,
                  motivation: reschedule.rescheduledBy,
                  otherRescheduleReason: reschedule.otherRescheduleReason,
                  notes: reschedule.rescheduleNotes,
                  mileage: reschedule.mileage,
                  parkingFees: reschedule.parkingFees,
                  tollFees: reschedule.tollFees,
                }
              : null,
          }
        : null,
  }
}

const transformEventDate = eventDate =>
  removeSecondsFromDate(momentToDate(eventDate)).toISOString()

const transformBatchRequestEvent = event => ({
  event: transformRequestEvent(event),
  userIds:
    !event.userIds || event.userIds.isEmpty() ? [event.ownerId] : event.userIds,
  recurrence: event.recurrence,
})

const transformResponseEvent = response => {
  const extraOptions = (type => {
    switch (type) {
      case 'visit':
        return {
          confirmed: response.visit.confirmed,
          assessmentType: response.visit.modality,
          encounterStatus: response.visit.encounter.assessmentStatus,
          encounterType: response.visit.encounter.type,
          encounterTypeLabel: response.visit.encounter.encounterType.label,
          patientId: response.visit.encounter.patient.id,
          patientName: response.visit.encounter.patient.demographics.name,
          serviceLocationId: response.visit.serviceLocationId,
        }
      case 'visit_placeholder':
        return {
          assessmentType: response.visitPlaceholder.location,
          encounterType: response.visitPlaceholder.encounterType.type,
          encounterTypeLabel: response.visitPlaceholder.encounterType.label,
          patientId: response.visitPlaceholder.patient.id,
          patientName: response.visitPlaceholder.patient.demographics.name,
          serviceLocationId: response.visitPlaceholder.serviceLocationId,
        }
      default:
        return {}
    }
  })(response.type)

  return transformEvent({
    id: response.id,
    recordType: response.type,
    eventType: response.subtype,
    subject: VISIT_TYPES.includes(response.type) ? null : response.notes,
    description: VISIT_TYPES.includes(response.type) ? response.notes : null,
    start: response.startAt,
    startDate: response.startDate,
    end: response.endAt,
    endDate: response.endDate,
    ownerId: response.userId,
    createdBy: response.createdBy,
    createdAt: response.createdAt,
    modifiedBy: response.modifiedBy,
    modifiedAt: response.modifiedAt,
    maxVisits: response.availability ? response.availability.maxVisits : null,
    guidanceType: response.schedulingGuidance
      ? response.schedulingGuidance.guidanceType
      : null,
    visitMethod: response.schedulingGuidance
      ? response.schedulingGuidance.visitMethod
      : null,
    rideAlongRole: response.schedulingGuidance
      ? response.schedulingGuidance.rideAlongRole
      : null,
    travelNotes: response.schedulingGuidance
      ? response.schedulingGuidance.travelNotes
      : null,
    location: response.location
      ? {
          address: response.location.address,
          apartment: response.location.apartment,
          latitude: response.location.lat,
          longitude: response.location.lng,
          exact: response.location.exact,
        }
      : null,
    batchId: response.batchId,
    batch: response.batch,
    ...extraOptions,
  })
}

export const fetchEvents = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_EVENTS',
  requestParams: ['userId', 'startDate', 'endDate'],
  operation: (userId, startDate, endDate) =>
    AspireAPI.get('calendar/event', {
      params: {
        user_id: userId,
        start_date: startDate,
        end_date: endDate,
      },
    }),
  transform: events =>
    Map(events.map(event => [event.id, transformResponseEvent(event)])),
  messages: {
    failed: 'There was an issue fetching events',
  },
})

export const createEvent = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_EVENT',
  requestParams: ['event'],
  operation: event =>
    AspireAPI.post('calendar/event', transformRequestEvent(event)),
  messages: {
    succeeded: 'Event successfully created',
    failed: 'There was an issue creating the event',
  },
})

export const createBatchEvent = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_BATCH_EVENT',
  requestParams: ['event', 'filters'],
  operation: (event, filters) =>
    AspireAPI.post('calendar/event/batch', transformBatchRequestEvent(event), {
      params: {
        user_id: filters.userId,
        start_date: filters.startDate,
        end_date: filters.endDate,
      },
    }),
  transform: events =>
    Map(events.map(event => [event.id, transformResponseEvent(event)])),
  messages: {
    succeeded: 'Batch events successfully created',
    failed: 'There was an issue creating the batch events',
  },
})

export const saveEvent = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_EVENT',
  requestParams: ['event'],
  operation: event =>
    AspireAPI.put(`calendar/event/${event.id}`, transformRequestEvent(event)),
  messages: {
    succeeded: 'Event successfully saved',
    failed: 'There was an issue saving the event',
  },
})

export const saveBatchEvent = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_BATCH_EVENT',
  requestParams: ['event', 'filters'],
  operation: (event, filters) =>
    AspireAPI.put(
      `calendar/event/batch/${event.batchId}`,
      transformBatchRequestEvent(event),
      {
        params: {
          user_id: filters.userId,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
      }
    ),
  transform: events =>
    Map(events.map(event => [event.id, transformResponseEvent(event)])),
  messages: {
    succeeded: 'Batch events successfully saved',
    failed: 'There was an issue saving the batch events',
  },
})

export const deleteEvent = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_EVENT',
  requestParams: ['eventId'],
  operation: eventId => AspireAPI.delete(`calendar/event/${eventId}`),
  messages: {
    succeeded: 'Event successfully deleted',
    failed: 'There was an issue deleting the event',
  },
})

export const deleteBatchEvent = Request({
  typePrefix: rootKey,
  typeBase: 'DELETE_BATCH_EVENT',
  requestParams: ['batchId'],
  operation: batchId => AspireAPI.delete(`calendar/event/batch/${batchId}`),
  messages: {
    succeeded: 'Batch events successfully deleted',
    failed: 'There was an issue deleting the batch events',
  },
})

export const scheduleVisit = Request({
  typePrefix: rootKey,
  typeBase: 'SCHEDULE_VISIT',
  requestParams: ['event'],
  operation: event =>
    AspireAPI.post('calendar/event/visit', transformRequestEvent(event)),
  messages: {
    succeeded: 'Visit successfully scheduled',
    failed: error =>
      error.response.data.message || 'There was an issue scheduling the visit',
  },
})

export const editVisit = Request({
  typePrefix: rootKey,
  typeBase: 'SCHEDULE_VISIT',
  requestParams: ['event', 'reschedule'],
  operation: (event, reschedule) =>
    AspireAPI.put(
      `calendar/event/${event.id}/visit`,
      transformRequestEvent(event, reschedule)
    ),
  messages: {
    succeeded: 'Visit successfully edited',
    failed: error =>
      error.response.data.message || 'There was an issue editing the visit',
  },
})

export const cancelVisit = Request({
  typePrefix: rootKey,
  typeBase: 'CANCEL_VISIT',
  requestParams: ['eventId', 'cancellation'],
  operation: (eventId, cancellation) =>
    AspireAPI.put(`calendar/event/${eventId}/visit/cancel`, {
      type: cancellation.cancellationType,
      cancellationReason: cancellation.cancellationReason,
      motivation: cancellation.cancelledBy,
      otherCancellationReason: cancellation.otherCancellationReason,
      refusedServiceReason: cancellation.refusedServiceReason,
      otherRefusedServiceReason: cancellation.otherRefusedServiceReason,
      mitigationStrategy: cancellation.mitigationStrategy,
      notes: cancellation.cancellationNotes,
      mileage: cancellation.mileage,
      parkingFees: cancellation.parkingFees,
      tollFees: cancellation.tollFees,
    }),
  messages: {
    succeeded: 'Visit successfully cancelled',
    failed: 'There was an issue cancelling the visit',
  },
})

const creator = scopedCreator(rootKey)
export const eventsFetchRequested = creator('EVENTS_FETCH_REQUESTED', [
  'userId',
  'date',
])
export const eventsCleared = creator('EVENTS_CLEARED')

const initState = Map()
export default createReducer(EVENTS, initState, {
  [eventsCleared]: () => initState,
  [fetchEvents.SUCCEEDED]: (_state, { payload }) => payload,
  [createEvent.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, transformResponseEvent(payload)),
  [createBatchEvent.SUCCEEDED]: (state, { payload }) => state.merge(payload),
  [saveEvent.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, transformResponseEvent(payload)),
  [saveBatchEvent.SUCCEEDED]: (state, { meta, payload }) =>
    state
      .filter(({ batchId }) => batchId !== meta.request.payload.event.batchId)
      .merge(payload),
  [deleteEvent.SUCCEEDED]: (state, { meta }) =>
    state.delete(meta.request.payload.eventId),
  [deleteBatchEvent.SUCCEEDED]: (state, { meta }) =>
    state.filter(({ batchId }) => batchId !== meta.request.payload.batchId),
  [scheduleVisit.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, transformResponseEvent(payload)),
  [editVisit.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, transformResponseEvent(payload)),
  [cancelVisit.SUCCEEDED]: (state, { meta }) =>
    state.delete(meta.request.payload.eventId),
})

export const getEvents = pipe(getEventsRoot, get(EVENTS))
