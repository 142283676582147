import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016)
import key from '../key'

const EXTERNAL_HC = 'external_hc'

export const deferExternalHealthConcern = Request({
  typePrefix: key,
  typeBase: 'DEFER_HEALTH_CONCERN',
  requestParams: ['patient_id', 'id', 'deferReason'],
  operation: (patient_id: any, id: any, deferReason: any) =>
    AspireAPI.post(`care_plan/external_health_concerns/${patient_id}/${id}`, {
      ...deferReason,
    }),
  messages: {
    succeeded: 'Health concern saved successfully',
    failed: 'Could not save health concern.',
  },
})

export const ExternalHc = Record({
  id: null,
  healthConcern: null,
  status: null,
  source: null,
  recommendedDate: null,
  deferredDate: null,
  deferredReason: null,
  deferredBy: null,
  role: null,
  patientId: null,
  goals: null,
})

const transformExternalHc = (healthConcerns: any) => {
  return healthConcerns.map((healthConcern: any) => {
    return ExternalHc({
      id: healthConcern?.id,
      healthConcern: healthConcern?.label
        ? healthConcern?.label
        : healthConcern?.healthConcern.label,
      status:
        healthConcern?.status == 'Closed'
          ? 'Not Active'
          : healthConcern?.status,
      source: healthConcern?.source,
      recommendedDate: formatDate(healthConcern?.createdAt),
      deferredDate:
        healthConcern?.status == 'Deferred'
          ? formatDate(healthConcern?.modifiedAt)
          : '',
      deferredReason:
        healthConcern?.status == 'Deferred'
          ? healthConcern?.deferredReason
          : '',
      deferredBy:
        healthConcern?.status == 'Deferred'
          ? healthConcern?.modifiedBy.split('|')[0]
          : '',
      role:
        healthConcern?.status == 'Deferred'
          ? healthConcern?.modifiedBy.split('|')[1]
          : '',
      patientId: healthConcern?.patientId,
      goals: healthConcern?.goals,
    })
  })
}

export const fetchExternalHealthConcerns = Request({
  typePrefix: key,
  typeBase: 'FETCH_EXTERNAL_HC',
  requestParams: ['patient_id'],
  operation: (patient_id: any) =>
    AspireAPI.get(`care_plan/external_health_concerns/${patient_id}/`),
  transform: transformExternalHc,
  messages: { failed: 'There was an issue fetching external health concerns' },
})

export const externalHealthConcern = createReducer(EXTERNAL_HC, [], {
  [fetchExternalHealthConcerns.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getExternalHealthConcerns = pipe(get(key), get(EXTERNAL_HC))
