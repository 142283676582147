import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { of } from 'rxjs'
import { mergeMapTo } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { deleteVendor } from '~/features/contacts'
import { recordCleared } from '../data/recordLoaded'
import { CONTACT_MANAGEMENT_VENDORS } from '../router'

export default (action$: any) =>
  action$.pipe(
    ofType(deleteVendor.SUCCEEDED),
    mergeMapTo(of(push(CONTACT_MANAGEMENT_VENDORS), recordCleared()))
  )
