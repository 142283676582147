import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'sendToResearchDialog'
const dialogOpenKey = 'sendToResearchDialogOpen'

export const getDialogOpenValue = getIn([rootKey, dialogOpenKey])

// CREATORS
const creator = scopedCreator(key)

export const sendToResearchDialogOpened = creator(
  'SEND_TO_RESEARCH_DIALOG_OPENED'
)
export const sendToResearchDialogClosed = creator(
  'SEND_TO_RESEARCH_DIALOG_CLOSED'
)

// REQUEST
export const sendToResearch = Request({
  typePrefix: key,
  typeBase: 'SEND_TO_RESEARCH',
  requestParams: ['patientId', 'notes'],
  operation: (patientId, notes) =>
    AspireAPI.post(`v1/patient/${patientId}/research`, { notes }),
  messages: {
    succeeded: 'Patient successfully sent to research',
    failed: 'Could not send patient to research',
  },
})

// REDUCER
export default createReducer(dialogOpenKey, false, {
  [sendToResearchDialogClosed]: () => false,
  [sendToResearchDialogOpened]: () => true,
  [sendToResearch.SUCCEEDED]: () => false,
})
