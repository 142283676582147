import classnames from 'classnames'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    maxWidth: '100vw',
    display: 'flex',
    marginBottom: -1,
    borderBottom: `1px solid ${palette.divider}`,
  },
  requiredContainer: {
    borderBottom: `1px solid ${palette.severity.error.static}`,
  },
  field: {
    display: 'flex',
    flex: '1',
  },
  fullField: {
    flexDirection: 'row',
    minHeight: 48,
  },
  compactField: {
    flexDirection: 'column',
    minHeight: 80,
  },
  label: {
    display: 'flex',
  },
  requiredLabel: {
    color: palette.severity.error.static,
  },
  fullLabel: {
    height: 48,
    alignItems: 'center',
    flex: 1,
  },
  compactLabel: {
    height: 32,
    fontSize: '0.85em',
    alignItems: 'flex-end',
  },
  value: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    minHeight: 48,
  },
  before: {
    marginRight: '0.25em',
    flexShrink: '0',
  },
  after: {
    marginLeft: '0.25em',
    flexShrink: '0',
  },
  indent: {
    width: ({ indent }) => spacing(3) * indent,
  },
}))

const BaseField = props => {
  const {
    label,
    valueClassName,
    compact,
    error,
    before,
    after,
    children,
  } = props

  const classes = useStyles(props)

  const containerClassNames = classnames(classes.container, {
    [classes.requiredContainer]: error,
  })

  const fieldClassNames = classnames(classes.field, {
    [classes.compactField]: compact,
    [classes.fullfield]: !compact,
  })

  const labelClassNames = classnames(classes.label, {
    [classes.compactLabel]: compact,
    [classes.fullLabel]: !compact,
    [classes.requiredLabel]: error,
  })

  return (
    <div className={containerClassNames}>
      {compact && <span className={classes.indent} />}

      <div className={fieldClassNames}>
        <div className={labelClassNames}>
          {!compact && <span className={classes.indent} />}

          <Typography variant="body1">{label}:</Typography>
        </div>

        <div className={classnames(classes.value, valueClassName)}>
          {before && (
            <Typography variant="body1" className={classes.before}>
              {before}
            </Typography>
          )}

          {children}

          {after && <Typography className={classes.after}>{after}</Typography>}
        </div>
      </div>
    </div>
  )
}

BaseField.propTypes = {
  label: PropTypes.string.isRequired,
  indent: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  valueClassName: PropTypes.string,
  compact: PropTypes.bool,
  error: PropTypes.bool,
  before: PropTypes.node,
  after: PropTypes.node,
  children: PropTypes.node,
}

BaseField.defaultProps = {
  indent: 0,
  compact: false,
  error: false,
}

export default BaseField
