import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016)
import key from '../key'

const AUTO_CARE_PLAN_HEALTH_CONCERNS = 'autoCarePlanHealthConcerns'

export const fetchAiCarePlanHealthConcerns = Request({
  typePrefix: key,
  typeBase: 'FETCH_AI_CARE_PLAN_HEALTH_CONCERNS',
  requestParams: ['patientId'],
  operation: (patientId: any) =>
    AspireAPI.get(`care_plan/auto_care_plan/${patientId}/load_auto_care_plan`),
  messages: {
    succeeded: 'AI Care Plan Health Concerns successfully imported',
    failed: 'There was an issue fetching AI Care Plan Health Concerns',
  },
})

export default createReducer(AUTO_CARE_PLAN_HEALTH_CONCERNS, List(), {
  [fetchAiCarePlanHealthConcerns.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})

export const getAutoCarePlanHealthConcerns = pipe(
  get(key),
  get(AUTO_CARE_PLAN_HEALTH_CONCERNS)
)
