import { creator, get, type } from '~/utils/data'
import key from '../../key'

export const getRoot = get(key)

// ACTION
export const METRICS_PAGE_NAVIGATED = type(key, 'METRICS_PAGE_NAVIGATED')

// ACTION CREATORS
export const metricsNavigated = creator(METRICS_PAGE_NAVIGATED, 'appId')
