import { List, OrderedMap, Record } from 'immutable'
import {
  Assessment,
  transformAssessment,
  // @ts-expect-error no export
} from '~/apps/assessment/data/assessments'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { Patient, transformPatient } from '~/features/patientInfo'
import { get, scopedCreator } from '~/utils/data'
import key from '../../key'

// RECORDS
const User = Record({
  id: null,
  name: null,
})

export const EncounterReview = Record({
  id: null,
  status: null,
  encounterFormData: {},
  modifiedAt: null,
  encounterId: null,
  encounter: Assessment(),
  patientId: null,
  patient: Patient(),
  physicianId: null,
  physician: User(),
  schema: null,
  uiSchema: null,
  tags: null,
  formData: null,
  context: null,
})

export const PatientOutlierReview = Record({
  id: null,
  status: null,
  createdAt: null,
  modifiedAt: null,
  patientId: null,
  patient: Patient(),
  physicianId: null,
  physician: User(),
  schema: null,
  uiSchema: null,
  tags: null,
  formData: null,
  context: null,
})

const OutlierTask = Record({
  id: null,
  startDate: null,
  taskName: null,
  ownerName: null,
  status: null,
})

export const PatientOutlierFinalDecision = Record({
  id: null,
  status: null,
  createdAt: null,
  modifiedAt: null,
  patientId: null,
  patient: Patient(),
  physicianId: null,
  physician: User(),
  schema: null,
  uiSchema: null,
  tags: null,
  formData: null,
  context: null,
  patientOutlierReviewId: null,
  patientOutlierReview: PatientOutlierReview(),
  patientOutlierReviewFormData: {},
  patientOutlierFinalDecisionFormData: {},
  outlierTasks: List(),
})

// TRANSFORMERS
export const transformEncounterReview = ({
  encounter = {},
  patient = {},
  physician,
  form = {},
  ...rest
}: any) =>
  EncounterReview({
    ...rest,
    modifiedAt: form.modifiedAt || rest.modifiedAt,
    encounter: transformAssessment(encounter),
    patient: transformPatient(patient),
    physician: User(physician),
    schema: form.schema,
    uiSchema: form.uiSchema,
    tags: form.tags,
    formData: form.data,
    context: form.context,
  })

export const transformEncounterReviews = (encounterReviews: any) =>
  OrderedMap(
    encounterReviews.map((encounterReview: any) => [
      encounterReview.id,
      transformEncounterReview(encounterReview),
    ])
  )

export const transformPatientOutlierReview = ({
  patient = {},
  physician,
  form = {},
  ...rest
}: any) =>
  PatientOutlierReview({
    ...rest,
    modifiedAt: form.modifiedAt || rest.modifiedAt,
    patient: transformPatient(patient),
    physician: User(physician),
    schema: form.schema,
    uiSchema: form.uiSchema,
    tags: form.tags,
    formData: form.data,
    context: form.context,
  })

export const transformPatientOutlierReviews = (patientOutlierReviews: any) =>
  OrderedMap(
    patientOutlierReviews.map((patientOutlierReview: any) => [
      patientOutlierReview.id,
      transformPatientOutlierReview(patientOutlierReview),
    ])
  )

export const transformPatientOutlierFinalDecision = ({
  patient,
  physician,
  form = {},
  patientOutlierReview = {},
  outlierTasks,
  ...rest
}: any) =>
  PatientOutlierFinalDecision({
    ...rest,
    modifiedAt: form.modifiedAt || rest.modifiedAt,
    patient: transformPatient(patient),
    physician: User(physician),
    schema: form.schema,
    uiSchema: form.uiSchema,
    tags: form.tags,
    formData: form.data,
    context: form.context,
    patientOutlierReview: transformPatientOutlierReview(patientOutlierReview),
    outlierTasks: List((outlierTasks || []).map(OutlierTask)),
  })

export const transformPatientOutlierFinalDecisions = (
  patientOutlierFinalDecisions: any
) =>
  OrderedMap(
    patientOutlierFinalDecisions.map((patientOutlierFinalDecision: any) => [
      patientOutlierFinalDecision.id,
      transformPatientOutlierFinalDecision(patientOutlierFinalDecision),
    ])
  )

// ACTION CREATORS
const creator = scopedCreator(key)
export const censusRefreshRequested = creator('CENSUS_REFRESH_REQUESTED')
export const dataCleared = creator('DATA_CLEARED')

// SELECTORS
export const getRoot = get(key)
