import React, { RefObject, useRef } from 'react'
import Form, {
  AjvError,
  FormProps,
  IChangeEvent,
  ISubmitEvent,
  UiSchema,
} from 'react-jsonschema-form'
import ErrorBoundary from '~/components/ErrorBoundary'
import { JsonFormT } from '~/data/form'
import { Typography, makeStyles } from '@material-ui/core'
// @ts-expect-error ts(7016) FIXME: './ArrayFieldTemplate' implicitly has an 'any' type... Remove this comment to see the full error message
import ArrayFieldTemplate from './ArrayFieldTemplate'
// @ts-expect-error ts(7016) FIXME: './FieldTemplate' implicitly has an 'any' type... Remove this comment to see the full error message
import FieldTemplate from './FieldTemplate'
// @ts-expect-error ts(7016) FIXME: './ObjectFieldTemplate' implicitly has an 'any' type... Remove this comment to see the full error message
import ObjectFieldTemplate from './ObjectFieldTemplate'
// @ts-expect-error ts(7016) FIXME: './customFormats' implicitly has an 'any' type... Remove this comment to see the full error message
import * as customFormats from './customFormats'
// @ts-expect-error ts(7016) FIXME: './fields' implicitly has an 'any' type... Remove this comment to see the full error message
import * as baseFields from './fields'
// @ts-expect-error ts(7016) FIXME: './utils/calcFormData' implicitly has an 'any' type... Remove this comment to see the full error message
import calcFormData from './utils/calcFormData'
// @ts-expect-error ts(7016) FIXME: './widgets' implicitly has an 'any' type... Remove this comment to see the full error message
import * as baseWidgets from './widgets'

const useStyles = makeStyles(({ spacing }) => ({
  form: {
    marginTop: -spacing(1),
  },
  error: {
    textAlign: 'center',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(2),
  },
}))

const clearEnumMessage = (error: AjvError) =>
  error.name === 'enum' ? { ...error, message: '' } : error

const transformErrors = (
  errors: AjvError[],
  touched: Set<string>,
  errored: boolean
): AjvError[] => {
  const isTouched = ({ property }: AjvError): boolean => touched.has(property)
  const filtered = errored ? errors : errors.filter(isTouched)

  return filtered.map(clearEnumMessage)
}

const idToProperty = (id: string): string =>
  id
    .split('_')
    .slice(1)
    .map((part: string) => (isNaN(Number(part)) ? `.${part}` : `[${part}]`))
    .join('')

interface JsonFormProps
  extends Omit<
    FormProps<unknown>,
    | 'onChange'
    | 'onSubmit'
    | 'onError'
    | 'onBlur'
    | 'onFocus'
    | 'formData'
    | 'schema'
    | 'uiSchema'
  > {
  children?: React.ReactNode
  form: JsonFormT
  classes?: unknown
  context?: unknown
  compact?: boolean
  debounce?: boolean
  formRef?: RefObject<Form<unknown>>
  getFormDataByTag?: any // FIXME: This needs a more strict function specification
  onChangeByTag?: (x: string, y?: unknown) => void
  onChange: (form: JsonFormT) => void
  onError?: (errs: any[]) => void
  onSubmit?: (form: JsonFormT, evt: ISubmitEvent<unknown>) => void
  onBlur?: (
    id: string,
    value: boolean | number | string | null,
    errors: AjvError[]
  ) => void
  onFocus?: (
    id: string,
    value: boolean | number | string | null,
    errors: AjvError[]
  ) => void
}

function JsonForm({
  children,
  formRef,
  fields = {},
  widgets = {},
  onChangeByTag = () => {},
  getFormDataByTag = () => {},
  compact = false,
  debounce = false,
  form,
  ...props
}: JsonFormProps) {
  const classes = useStyles()

  const { schema, uiSchema, data, context } = form

  const formErrored = useRef<boolean>(false)

  const touched = useRef<Set<string>>(new Set())

  // Track whether there is a pending onChange event, and queue onBlur and
  // onFocus events until after it is executed. This is here mainly to handle
  // the case of <select> elements on iOS, where the field is blurred
  // immediately after changing.
  const pending = useRef<{ change: boolean; queue: (() => void)[] }>({
    change: false,
    queue: [],
  })
  const errors = useRef<AjvError[]>([])

  const getErrors = () => {
    return transformErrors(errors.current, touched.current, formErrored.current)
  }

  const deleteTouched = (id: string) => {
    touched.current.delete(idToProperty(id))
  }

  const onChange = ({ formData, errors }: IChangeEvent<unknown>) => {
    props.onChange(
      JsonFormT.build({
        ...form,
        data: calcFormData({ ...form, formData }),
        errored: Boolean(errors.length),
      })
    )

    pending.current.change = false

    if (pending.current.queue.length > 0) {
      pending.current.queue.forEach(operation => operation())
      pending.current.queue = []
    }
  }

  const onError = () => {
    if (!formErrored.current) {
      formErrored.current = true
    }

    props.onError?.(getErrors())
  }

  const onSubmit = (evt: ISubmitEvent<unknown>) =>
    errors.current.length > 0 ? onError() : props.onSubmit?.(form, evt)

  const onBlur = (
    id: string,
    value: boolean | number | string | null
  ): void => {
    const property = idToProperty(id)

    if (!touched.current.has(property)) {
      touched.current.add(property)
    }

    props.onBlur?.(id, value, getErrors())
  }

  const onFocus = (
    id: string,
    value: boolean | number | string | null
  ): void => {
    props.onFocus?.(id, value, getErrors())
  }

  const transformErrors_ = (err: AjvError[]): AjvError[] => {
    errors.current = err
    return getErrors()
  }

  const formContext = {
    compact,
    debounce,
    deleteTouched,
    getFormDataByTag,
    onChangeByTag,
    pending: pending.current,
    context,
  }

  return (
    <ErrorBoundary
      renderError={() => (
        <Typography className={classes.error} color="error" variant="subtitle1">
          There was a problem rendering the form.
        </Typography>
      )}
    >
      <Form
        {...props}
        schema={schema as FormProps<unknown>['schema']}
        uiSchema={uiSchema as UiSchema}
        formData={data}
        noHtml5Validate
        liveValidate
        showErrorList={false}
        ref={formRef}
        className={classes.form}
        transformErrors={transformErrors_}
        fields={{ ...baseFields, ...fields }}
        widgets={{ ...baseWidgets, ...widgets }}
        FieldTemplate={FieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate}
        ArrayFieldTemplate={ArrayFieldTemplate}
        formContext={formContext}
        customFormats={customFormats}
        onChange={onChange}
        onSubmit={onSubmit}
        onError={onError}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={classes.buttons}>{children}</div>
      </Form>
    </ErrorBoundary>
  )
}

export default JsonForm
