import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const CONTRACTS = 'contracts'

export const Contract = Record({
  id: null,
  label: null,
})

const transformContracts = (data: any) => List(data.map(Contract))

export const fetchContracts = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CONTRACTS',
  requestParams: ['searchWord'],
  operation: (searchWord: any) =>
    AspireAPI.get('admin_tools/contracts', { params: { searchWord } }),
  transform: transformContracts,
  messages: { failed: 'There was an issue fetching contracts' },
})

const creator = scopedCreator(rootKey)
export const contractsCleared = creator('CONTRACTS_CLEARED')

const initState = List()
export default createReducer(CONTRACTS, initState, {
  [fetchContracts.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [contractsCleared]: () => initState,
})

export const getContracts = pipe(getRoot, get(CONTRACTS))
