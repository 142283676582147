import { changedTo } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { fetchPatientOutlierFinalDecision } from '../data/currentPatientOutlierFinalDecision'
import { PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(changedTo(PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS)),
    pluck('payload', 'params', 'patientOutlierFinalDecisionId'),
    filter(Boolean),
    map(fetchPatientOutlierFinalDecision.requested)
  )
