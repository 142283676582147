import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { Address, UserActivityData } from './interfaces'
// KEY
const key = 'userActivity'

export const UserActivityRecord = Record({
  tripId: null,
  subscriberId: null,
  pickupType: null,
  pickupAddress: null,
  middleName: null,
  lastName: null,
  modifiedBy: null,
  firstName: null,
  effectiveDate: null,
  createdBy: null,
  appointmentDate: null,
})

const transformPickupAddress = (pickupAddress: Address) =>
  `${pickupAddress?.address1}${
    pickupAddress?.address2 && pickupAddress?.address2.length > 0
      ? pickupAddress?.address2
      : ''
  },${pickupAddress?.city},${pickupAddress?.state},${pickupAddress?.zipcode}`

// TRANSFORMER
const mapUserActivityResults = (results: [UserActivityData]) =>
  List(
    results?.map(userActivity =>
      UserActivityRecord({
        tripId: userActivity?.tripId,
        subscriberId: userActivity?.subscriberId,
        pickupType: userActivity?.pickupType,
        pickupAddress: transformPickupAddress(userActivity?.pickupAddress),
        middleName: userActivity?.middleName,
        lastName: userActivity?.lastName,
        modifiedBy: userActivity?.modifiedBy,
        firstName: userActivity?.firstName,
        effectiveDate: userActivity?.effectiveDate,
        createdBy: userActivity?.createdBy,
        appointmentDate: userActivity?.appointmentDate,
      })
    )
  )

export const fetchUserActivity = Request({
  typePrefix: key,
  typeBase: 'GET_USER_ACTIVITY',
  requestParams: ['activityDate'],
  operation: (activityDate: string) =>
    AspireAPI.get(`transportation/user_activity?activity_date=${activityDate}`),
  transform: mapUserActivityResults,
  messages: {
    failed: 'Fetching User Activity details failed',
  },
})

// REDUCER
export const userActivity = createReducer(key, List(), {
  [fetchUserActivity.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

// SELECTORS
export const getUserActivity = pipe(getRoot, get(key))
