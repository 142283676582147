import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchResearchCases } from '~/features/research'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_RESEARCH_CASES } from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_RESEARCH_CASES)),
    switchTo(state$),
    map(getPatientId),
    map(fetchResearchCases.requested)
  )
