import { CUP_PATH } from 'ahc-config'
import React, { useState } from 'react'
import UnauthenticatedRequestError from '~/errors/UnauthenticatedRequestError'
import PropTypes from '~/utils/propTypes'
import keycloak from '~/utils/security/keycloak'
import Button from '@material-ui/core/Button'

const CUPAuthForm = ({ authNumber }) => {
  const [token, setToken] = useState('')
  const formName = `CUPform_${authNumber}`
  const handleSubmit = event => {
    event.preventDefault()
    keycloak
      .refreshToken()
      .catch(() => {
        throw new UnauthenticatedRequestError(
          'Attempted to make an unauthenticated request'
        )
      })
      .then(() => {
        keycloak.token.then(token => {
          setToken(token)
          document.forms[formName].submit()
        })
      })
  }

  const data = JSON.stringify({
    jwt: token,
    params: [{ key: 'AUTH', value: authNumber }],
  })

  return (
    <form
      id={formName}
      action={CUP_PATH}
      method="POST"
      rel="noopener noreferrer"
      target="_blank"
      onSubmit={handleSubmit}
    >
      <input type="hidden" value={data} name="data" />
      <Button variant="text" color="primary" type="submit">
        {authNumber}
      </Button>
    </form>
  )
}

CUPAuthForm.propTypes = {
  authNumber: PropTypes.string.isRequired,
}

export default CUPAuthForm
