// Append role to user name. Used as userMapper function for UserSelector.
export const appendRole = user => {
  const role = user.aspireRole ? ` (${user.aspireRole})` : ''
  return {
    label: user.label + role,
    value: user.value,
  }
}

// Keep only active users. Used as userFilter function for UserSelector.
export const isActive = user => user.active
