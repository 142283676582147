import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
import rootKey from '../key'

const transformErrorResponse = (error: any) => {
  const genericSaveError = 'Could not add health concern'
  return error?.response?.data?.message?.label[0] || genericSaveError
}

const transformEditErrorResponse = (error: any) => {
  const genericSaveError = 'Could not update health concern'
  return error?.response?.data?.message.label[0] || genericSaveError
}

export const addHealthConcern = Request({
  typePrefix: rootKey,
  typeBase: 'ADD_HEALTH_CONCERN',
  requestParams: ['healthConcernInputs'],
  operation: (healthConcernInputs: any) =>
    AspireAPI.post('admin_tools/health_concern', {
      ...healthConcernInputs,
    }),
  messages: {
    succeeded: 'Health concern added successfully',
    failed: (error: any) => transformErrorResponse(error),
  },
})

export const editHealthConcern = Request({
  typePrefix: rootKey,
  typeBase: 'EDIT_HEALTH_CONCERN',
  requestParams: ['healthConcernInputs'],
  operation: (healthConcernInputs: any) =>
    AspireAPI.post('admin_tools/health_concern', {
      ...healthConcernInputs,
    }),
  messages: {
    succeeded: 'Health concern updated successfully',
    failed: (error: any) => transformEditErrorResponse(error),
  },
})
