import createReducer from '~/utils/createReducer'
import { getIn } from '~/utils/data'
import rootKey from '../../key'
import { createUnengaged } from '../createUnengagedDialog'
import { closeUnengaged, updateUnengaged } from '../updateUnengagedDialog'

// KEYS
const key = 'requestIsProcessing'

export const getRequestProcessingValue = getIn([rootKey, key])

// REDUCER
export const isRequestProcessing = createReducer(key, false, {
  [closeUnengaged.REQUESTED]: () => true,
  [closeUnengaged.SUCCEEDED]: () => false,
  [createUnengaged.REQUESTED]: () => true,
  [createUnengaged.SUCCEEDED]: () => false,
  [updateUnengaged.REQUESTED]: () => true,
  [updateUnengaged.SUCCEEDED]: () => false,
})
