import { paramsReducer } from 'redux-routable'
import { getUserId } from '~/data/session'
import router from '../router'
import { getRoot } from './common/shared'

// KEY
const key = 'caseManagerId'

// REDUCER
const reducer = paramsReducer(router, ({ cmId }) => cmId || null)

reducer.key = key

// SELECTORS
export const getSelectedCMId = state =>
  getRoot(state).get(key) || getUserId(state)

export default reducer
