import { combineReducers } from 'redux-immutable'
import callLog from './data/callLog'
import careteam from './data/careTeam'
import contacts from './data/contacts'
import dialog from './data/dialog'
import dispositions from './data/dispositions'
import extensivists from './data/extensivist'
import pcp from './data/pcp'
import preferredPharmacy from './data/preferredPharmacy'
import stakeholders from './data/stakeholders'
import vendors from './data/vendors'
import key from './key'

const reducer = combineReducers({
  [callLog.key]: callLog,
  [careteam.key]: careteam,
  [contacts.key]: contacts,
  [dialog.key]: dialog,
  [dispositions.key]: dispositions,
  [extensivists.key]: extensivists,
  [pcp.key]: pcp,
  [stakeholders.key]: stakeholders,
  [vendors.key]: vendors,
  [preferredPharmacy.key]: preferredPharmacy,
})

reducer.key = key

export default reducer
