import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, Form, reduxForm } from 'redux-form'
import Dependant from '~/components/ReduxFormDependantField'
import { ActionDialog } from '~/components/dialogs'
import { renderFieldValueOptions } from '~/components/fields'
import { SelectField, TextField } from '~/components/formFields'
import { FieldValue, getFieldValues } from '~/data/fieldValues'
import PropTypes from '~/utils/propTypes'
import { required } from '~/utils/validation/formValidators'
import { withStyles } from '@material-ui/core/styles'
import {
  closedAlertDialog,
  getIsAlertDialogDisplayed,
  getSelectedAlert,
} from '../data/alertDialog'
import { Alert, createAlert, deactivateAlert } from '../data/patientAlerts'

const styles = ({ spacing }) => ({
  padding: {
    paddingBottom: spacing(1),
  },
})

const NO_CROSS_COVERAGE = 'No Cross Coverage'
const isNoCrossCoverage = val => val === NO_CROSS_COVERAGE

class AlertDialog extends React.Component {
  createAlert = values => {
    const { patientId, createAlert } = this.props
    createAlert(patientId, values)
    this.onClose()
  }

  onClose = () => {
    this.props.reset()
    this.props.onClose()
  }

  deactivateAlert = () => {
    const { deactivateAlert, initialValues } = this.props
    deactivateAlert(initialValues.get('id'))
    this.onClose()
  }

  get isDeleting() {
    return Boolean(this.props.initialValues)
  }

  get mode() {
    return this.isDeleting ? 'delete' : 'add'
  }

  get title() {
    return this.isDeleting ? 'Delete Alert' : 'Add New Alert'
  }

  render() {
    const {
      alertSeverities,
      alertTypes,
      handleSubmit,
      noCrossCoverageReasons,
      open,
      classes,
    } = this.props

    return (
      <ActionDialog
        mode={this.mode}
        open={open}
        title={this.title}
        onClose={this.onClose}
        onAdd={handleSubmit(this.createAlert)}
        onDelete={this.deactivateAlert}
        maxWidth="sm"
        fullWidth
      >
        <Form onSubmit={handleSubmit(this.createAlert)}>
          <Field
            name="type"
            label="Alert Type"
            disabled={this.isDeleting}
            component={SelectField}
            validate={required}
            className={classes.padding}
            fullWidth
            native
          >
            <option disabled />
            {renderFieldValueOptions(alertTypes)}
          </Field>
          <Dependant on="type" when={isNoCrossCoverage}>
            <Field
              name="noCrossCoverageReason"
              label="No Cross Coverage Reason"
              disabled={this.isDeleting}
              component={SelectField}
              fullWidth
              validate={required}
              className={classes.padding}
              native
            >
              <option disabled />
              {renderFieldValueOptions(noCrossCoverageReasons)}
            </Field>
          </Dependant>
          <Field
            name="severity"
            label="Alert Severity"
            disabled={this.isDeleting}
            component={SelectField}
            validate={required}
            className={classes.padding}
            fullWidth
            native
          >
            <option disabled />
            {renderFieldValueOptions(alertSeverities)}
          </Field>
          <Field
            name="description"
            label="Alert Description"
            disabled={this.isDeleting}
            component={TextField}
            validate={required}
            className={classes.padding}
            fullWidth
            multiline
          />
        </Form>
      </ActionDialog>
    )
  }
}

AlertDialog.propTypes = {
  alertSeverities: PropTypes.mapOf(FieldValue).isRequired,
  alertTypes: PropTypes.mapOf(FieldValue).isRequired,
  createAlert: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  deactivateAlert: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Alert),
  noCrossCoverageReasons: PropTypes.mapOf(FieldValue).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patientId: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
}

const form = 'patientRecord.createAlert'

const mapStateToProps = state => ({
  alertTypes: getFieldValues('alert_type')(state),
  alertSeverities: getFieldValues('alert_severity')(state),
  initialValues: getSelectedAlert(state),
  noCrossCoverageReasons: getFieldValues('alert_type_no_cross_coverage_reason')(
    state
  ),
  open: getIsAlertDialogDisplayed(state),
})

const mapDispatchToProps = {
  createAlert: createAlert.requested,
  deactivateAlert: deactivateAlert.requested,
  onClose: closedAlertDialog,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: form, enableReinitialize: true }),
  withStyles(styles)
)(AlertDialog)
