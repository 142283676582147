import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  publishDraftPolygon,
  selectedPolygonPublishComplete,
  selectedPolygonPublished,
} from '../data/selectedPolygon'
import showError from '../utils/showError'

const draftPolygonPublished = action$ =>
  action$.pipe(
    ofType(selectedPolygonPublished),
    pluck('payload', 'polygon'),
    mergeMap(polygon =>
      loading(
        from(publishDraftPolygon(polygon)).pipe(
          map(({ success }) =>
            selectedPolygonPublishComplete(polygon.get('np_id'), success)
          ),
          catchError(showError('Failed to publish NP.'))
        )
      )
    )
  )
export default draftPolygonPublished
