import { get as getIn } from 'lodash/fp'
import createReducer from '~/utils/createReducer'
import { action, creator, get } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import TASKS from '../key'
import { getRoot } from './common'
import { getTaskById } from './tasks'

// Key
const CURRENT_TASK_ID = 'currentTaskId'

// Type
const TASK_SELECTED = `${TASKS}/${CURRENT_TASK_ID}/TASK_SELECTED`

// Action
export const taskSelected = creator(TASK_SELECTED, 'id')

// Reducer
export default createReducer(CURRENT_TASK_ID, null, {
  [TASK_SELECTED]: compose(Number, getIn(['payload', 'id']), action),
})

// Selectors
export const getCurrentTaskId = compose(get(CURRENT_TASK_ID), getRoot)

export const getTaskDetails = state => id => getTaskById(id)(state)

export const getCurrentTask = state =>
  compose(getTaskDetails(state), getCurrentTaskId)(state)
