import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(0.5),
  },
}))

export const BooleanWithNoOptionFilterCell = ({
  filteringEnabled,
  filter,
  onFilter,
}) => {
  const classes = useStyles()
  const value = filter && filter.value
  const hasFilter = {
    value,
    nextValue: value
      ? { value: false }
      : value === null
      ? { value: true }
      : null,
    render: value ? (
      <React.Fragment>
        <Icon className={classes.icon}>check_box</Icon> Only
      </React.Fragment>
    ) : value === null ? (
      'Filter'
    ) : (
      <React.Fragment>
        <Icon className={classes.icon}>crop_square</Icon> Only
      </React.Fragment>
    ),
  }

  const toggle = () => {
    return onFilter(hasFilter.nextValue)
  }

  return (
    <Button
      size="small"
      color={hasFilter.value !== null ? 'primary' : undefined}
      disabled={!filteringEnabled}
      variant="outlined"
      onClick={toggle}
    >
      {hasFilter.render}
    </Button>
  )
}

BooleanWithNoOptionFilterCell.propTypes = {
  filter: PropTypes.object,
  filteringEnabled: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
}
