import { creator, get, type } from '~/utils/data'
import key from '../../key'

// CONSTANTS
export const CLEAR_NOTIFICATIONS_REQUESTED = type(
  key,
  'CLEAR_NOTIFICATIONS_REQUESTED'
)
export const CLEAR_NOTIFICATIONS_SUCCEEDED = type(
  key,
  'CLEAR_NOTIFICATIONS_SUCCEEDED'
)
export const MARK_NOTIFICATION_UNREAD_REQUESTED = type(
  key,
  'MARK_NOTIFICATION_UNREAD_REQUESTED'
)
export const MARK_NOTIFICATION_UNREAD_SUCCEEDED = type(
  key,
  'MARK_NOTIFICATION_UNREAD_SUCCEEDED'
)
export const FETCH_NEXT_NOTIFICATIONS_REQUESTED = type(
  key,
  'FETCH_NEXT_NOTIFICATIONS_REQUESTED'
)

// ACTION CREATORS
export const clearNotificationsRequested = creator(
  CLEAR_NOTIFICATIONS_REQUESTED,
  'notificationIds'
)
export const clearNotificationsSucceeded = creator(
  CLEAR_NOTIFICATIONS_SUCCEEDED
)
export const markNotificationUnreadRequested = creator(
  MARK_NOTIFICATION_UNREAD_REQUESTED,
  'notificationId'
)
export const markNotificationUnreadSucceeded = creator(
  MARK_NOTIFICATION_UNREAD_SUCCEEDED
)
export const fetchNextNotificationsRequested = creator(
  FETCH_NEXT_NOTIFICATIONS_REQUESTED
)

// SELECTORS
export const getRoot = get(key)
