import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ breakpoints, palette, spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    [breakpoints.down('md')]: {
      alignItems: 'normal',
      flexDirection: 'column',
    },
  },
  spacer: {
    margin: [0, spacing(1)],
  },
  label: {
    color: palette.contentText.primaryLabel,
    marginRight: spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
  flex: {
    flex: 1,
  },
  textGroup: {
    marginRight: spacing(2),
  },
})

const SecondaryText = ({
  classes,
  patient: {
    appName,
    contactId,
    primaryContract,
    primaryInsuranceCarrier,
    programEnrolled,
  },
}) => {
  const channelInsurance = primaryInsuranceCarrier
    ? `${primaryContract} / ${primaryInsuranceCarrier}`
    : primaryContract

  return (
    <div className={classes.container}>
      <div className={classes.textGroup}>
        <Typography
          className={classes.label}
          color="textSecondary"
          component="span"
          variant="body2"
        >
          APP:
        </Typography>
        <Typography color="textSecondary" component="span" variant="body2">
          {appName}
        </Typography>
      </div>

      <div className={classes.textGroup}>
        <Typography
          className={classes.label}
          color="textSecondary"
          component="span"
          variant="body2"
        >
          Program:
        </Typography>
        <Typography color="textSecondary" component="span" variant="body2">
          {programEnrolled}
        </Typography>
      </div>

      <div className={classes.textGroup}>
        <Typography
          className={classes.label}
          color="textSecondary"
          component="span"
          variant="body2"
        >
          ID:
        </Typography>
        <Typography color="textSecondary" component="span" variant="body2">
          {contactId}
        </Typography>
      </div>

      <div className={classes.textGroup}>
        <Typography
          className={classes.label}
          color="textSecondary"
          component="span"
          variant="body2"
        >
          Channel/Insurance:
        </Typography>
        <Typography color="textSecondary" component="span" variant="body2">
          {channelInsurance}
        </Typography>
      </div>
    </div>
  )
}

SecondaryText.propTypes = {
  classes: PropTypes.object.isRequired,
  patient: PropTypes.record.isRequired,
}

export default withStyles(styles)(SecondaryText)
