import React from 'react'

export const indications = [
  {
    name: 'Agitation / Behavioral Disturbances',
    value: 'Agitation / Behavioral Disturbances',
  },
  { name: 'ALS', value: 'ALS' },
  { name: 'Anemia', value: 'Anemia' },
  { name: 'Angina Pectoris', value: 'Angina pectoris' },
  { name: 'Anxiety', value: 'Anxiety' },
  { name: 'Appetite Stimulant', value: 'Appetite stimulant' },
  { name: 'Arrthymia (Including A-Fib)', value: 'Arrthymia (including a-fib)' },
  { name: 'Asthma', value: 'Asthma' },
  {
    name: 'Atherosclerotic Disease (Including PVD, Carotid Stenosis)',
    value: 'Atherosclerotic disease (including PVD, carotid stenosis)',
  },
  { name: 'B12 Deficiency', value: 'B12 deficiency' },
  { name: 'Bipolar Disorder', value: 'Bipolar disorder' },
  { name: 'BPH', value: 'BPH' },
  { name: 'Bronchitis', value: 'Bronchitis' },
  { name: 'CAD', value: 'CAD' },
  { name: 'Cancer', value: 'Cancer' },
  { name: 'Cirrhosis Liver', value: 'Cirrhosis liver' },
  { name: 'CKD', value: 'CKD' },
  { name: 'Constipation', value: 'Constipation' },
  { name: 'COPD', value: 'COPD' },
  { name: "Crohn's", value: "Crohn's" },
  { name: 'Delirium', value: 'delirium' },
  { name: 'Dementia / Alzheimers', value: 'Dementia/ Alzheimers' },
  { name: 'Depression', value: 'Depression' },
  { name: 'Diarrhea', value: 'diarrhea' },
  {
    name: 'Dietary / Nutritional Supplement',
    value: 'Dietary/ nutritional suppliment',
  },
  { name: 'DM', value: 'DM' },
  { name: 'Edema', value: 'Edema' },
  { name: 'Electrolyte Imbalance', value: 'Electrolyte Imbalance' },
  { name: 'Emphysema', value: 'Emphysema' },
  { name: 'End Stage Liver Disease', value: 'End stage liver disease' },
  { name: 'ESRD', value: 'ESRD' },
  { name: 'Gatroparesis', value: 'Gatroparesis' },
  { name: 'GERD', value: 'GERD' },
  { name: 'Glaucoma', value: 'Glaucoma' },
  { name: 'Gout', value: 'Gout' },
  {
    name: 'Heart Failure (Including CHF)',
    value: 'Heart failure (Including CHF)',
  },
  { name: 'Hepatitis', value: 'Hepatitis' },
  { name: 'HIV+/ AIDs', value: 'HIV+/ AIDs' },
  { name: 'Hyperlipidemia', value: 'Hyperlipidemia' },
  { name: 'Hypertension', value: 'Hypertension' },
  { name: 'Hyperthyroidism', value: 'Hyperthyroidism' },
  { name: 'Hypothyroidism', value: 'Hypothyroidism' },
  { name: 'IBS', value: 'IBS' },
  { name: 'In home emergency kit', value: 'In home emergency kit' },
  { name: 'Infection - Bladder', value: 'Infection - Bladder' },
  { name: 'Infection - Bowel', value: 'Infection - Bowel' },
  { name: 'Infection - Lung', value: 'Infection - Lung' },
  { name: 'Infection - Other', value: 'Infection - Other' },
  { name: 'Infection - Skin', value: 'Infection - Skin' },
  { name: 'Lupus', value: 'Lupus' },
  { name: 'Multiple Sclerosis', value: 'Multiple sclerosis' },
  { name: 'Myasthenia Gravis', value: 'Myasthenia gravis' },
  {
    name: 'Nasal Congestion (Including Allergic Rhinitis)',
    value: 'Nasal congestion (including allergic rhinitis)',
  },
  { name: 'Nausea', value: 'Nausea' },
  { name: 'Neuropathy', value: 'Neuropathy' },
  { name: 'Obstructive Sleep Apnea', value: 'Obstructive sleep apnea' },
  { name: 'Osteoarthritis', value: 'Osteoarthritis' },
  { name: 'Osteopenia', value: 'Osteopenia' },
  { name: 'Osteoporosis', value: 'Osteoporosis' },
  { name: 'Pain - Acute and/or Chronic', value: 'Pain - acute and/or chronic' },
  { name: 'Pancreatitis', value: 'Pancreatitis' },
  { name: "Parkinson's Disease", value: "Parkinson's Disease" },
  { name: 'Psoriatic Arthritis', value: 'Psoriatic arthritis' },
  { name: 'Rheumatoid Arthritis (RA)', value: 'Rheumatoid arthritis (RA)' },
  { name: 'Schizophrenia', value: 'Schizophrenia' },
  { name: 'Seizures', value: 'Seizures' },
  { name: 'Shingles / Herpes Zoster', value: 'Shingles/Herpes Zoster' },
  { name: 'Ulcerative colitis', value: 'Ulcerative colitis' },
  { name: 'Urinary  incontinence', value: 'Urinary incontinence' },
  { name: 'Wound', value: 'Wound' },
  { name: 'Other (specify)', value: 'Other (specify)' },
]

export const indicationOptions = (
  <React.Fragment>
    <option value="" />
    {indications.map(i => (
      <option key={i.value} value={i.value}>
        {i.name}
      </option>
    ))}
  </React.Fragment>
)
