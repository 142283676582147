import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error no export
import { getPatientId } from '~/features/patientInfo'
import { Problem as ProblemToSave } from '~/features/problems/data'
import { labels as problemStatusLabels } from '~/fieldValues/problem_status'
import { useAction } from '~/hooks'
import {
  Accordion,
  AccordionSummary,
  Button,
  Fade,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Add,
  ArrowForwardIosSharp,
  CheckCircleOutline,
  WarningOutlined,
} from '@material-ui/icons'
import {
  addSelectedProblem,
  fetchProblemInformation,
  fetchProblemTypes,
  fetchProblemWorkflow,
  fetchSecondaryICD10Information,
  fetchSecondaryProblemTypes,
  saveImoDetail,
  sendResultSelected,
  updateSelectedProblems,
} from '../data/imo'
import {
  getSecondaryICD10Code,
  isIcd10CodeMatching,
} from '../data/utils/helpers'
import { getImoSelectedProblem, getImoSessionId } from '../data/utils/selectors'
import {
  Category,
  Combination,
  Diagnosis,
  ModifierGroup,
  Problem,
} from '../types/types'
import ImoSelectedProblem from './imoSelectedProblem'

const useStyles = makeStyles(({ palette, typography, spacing }) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      marginRight: 200,
      wordBreak: 'break-word',
    },
    secondaryHeading: {
      fontSize: typography.pxToRem(15),
      color: palette.text.secondary,
      display: 'flex',
    },
    addtICDIcon: {
      color: '#ff9800',
    },
    details: {
      margin: 0,
      display: 'grid',
      gridTemplateColumns: '90% 10%',
    },
    summaryContent: {
      display: 'grid',
      gridTemplateColumns: '2fr 0.5fr 0.3fr',
      width: '100%',
      alignItems: 'center',
    },
    addButton: {
      padding: 0,
      maxWidth: 50,
      maxHeight: 24,
    },
    resultsContainer: {
      margin: '1em',
    },
    accordionSummary: {
      flexDirection: 'row-reverse',
      marginLeft: spacing(1),
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: spacing(1),
      },
    },
    arrowIcon: {
      fontSize: '1rem',
    },
    space: {
      width: '0.9rem',
      minHeight: '0.9rem',
    },
    addIcon: {
      color: palette.severity.success.static,
    },
    messageContainer: {
      color: palette.severity.success.static,
      display: 'flex',
      alignItems: 'center',
      '& span': {
        marginLeft: '0.5em',
      },
    },
    alertContainer: {
      color: palette.severity.warning.static,
      display: 'flex',
      alignItems: 'center',
      '& span': {
        marginLeft: '0.5em',
      },
    },
    errorContainer: {
      color: palette.severity.error.static,
    },
    buttonContainer: {
      display: 'flex',
    },
    loading: {
      color: palette.text.secondary,
      '&::after': {
        overflow: 'hidden',
        display: 'inline-block',
        verticalAlign: 'bottom',
        animation: '$ellipsis steps(4,end) 900ms infinite',
        content: '"..."',
        width: '0px',
      },
    },
    '@keyframes ellipsis': {
      to: {
        width: '1.25em',
      },
    },
  })
)

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: 'pre-wrap',
  },
}))(Tooltip)

interface Props {
  problem: {
    additionalICDCodes: boolean
    categories: Category[]
    enabledModifiers: []
    error: boolean
    icd10Code: string
    imoLexicalCode: string
    id: string
    modifierCombinations: Combination[]
    modifierGroups: ModifierGroup[]
    modifierWorkflowNeeded: boolean
    title: string
    secondaryICDCode1: string
    secondaryICDCode2: string
    secondaryICDCode3: string
    secondaryICDCode4: string
    secondaryICDText1: string
    secondaryICDText2: string
    secondaryICDText3: string
    secondaryICDText4: string
  }
  onSubmit: (problem: any) => void
}

const ImoProblem = ({ onSubmit, problem }: Props) => {
  const classes = useStyles()

  const {
    icd10Code,
    imoLexicalCode,
    id,
    modifierWorkflowNeeded,
    title,
    additionalICDCodes,
    secondaryICDCode1,
    secondaryICDCode2,
    secondaryICDCode3,
    secondaryICDCode4,
    secondaryICDText1,
    secondaryICDText2,
    secondaryICDText3,
    secondaryICDText4,
  } = problem

  const [expanded, setExpanded] = useState(false)
  const [imoProblem, setImoProblem] = useState<any>()
  const [isProblemSaved, setIsProblemSaved] = useState(false)
  const [isProblemSaving, setIsProblemSaving] = useState(false)

  const requestProblemWorkflow = useAction(fetchProblemWorkflow.requested)
  const requestProblemInfo = useAction(fetchProblemInformation.requested)
  const fetchProblemTypesRequested = useAction(fetchProblemTypes.requested)
  const sendSelectedResultToImo = useAction(sendResultSelected.requested)
  const saveImoDetailRequested = useAction(saveImoDetail.requested)
  const fetchSecondaryProblemTypesRequested = useAction(
    fetchSecondaryProblemTypes.requested
  )
  const requestSecondaryCodeInfo = useAction(
    fetchSecondaryICD10Information.requested
  )
  const addSelectedProblemId = useAction(addSelectedProblem)
  const updateProblems = useAction(updateSelectedProblems)

  const patientId = useSelector(getPatientId)
  const sessionId: string = useSelector(getImoSessionId)
  const selectedProblem: any = useSelector(getImoSelectedProblem(id))

  const onCreate = ({ icd10Code }: Diagnosis) => {
    const selectedCategory = getCategoryPerCode(
      icd10Code,
      imoProblem.categories
    )
    if (selectedCategory) {
      const problemCategoryId = Number(selectedCategory?.problemCategoryId)
      const problemTypeId = Number(selectedCategory?.problemTypeId)
      const statusLabel: string = problemStatusLabels['active']
      const icd10Description = imoProblem.diagnoses.find(
        (diagnosis: Diagnosis) =>
          isIcd10CodeMatching(diagnosis.icd10Code, icd10Code)
      )?.description

      const problem = ProblemToSave(selectedCategory).merge({
        problemCategoryId: problemCategoryId,
        category: selectedCategory?.category,
        subcategory: selectedCategory?.subcategory,
        problemTypeId: problemTypeId,
        label: selectedCategory?.problemType,
        statusLabel,
        icd10Description,
        imoLexicalCode: Number(imoProblem.imoLexicalCode),
        imoOrder: Number(getSecondaryICD10Code(imoProblem, icd10Code)),
      })
      onSubmit(problem)
    }
  }

  const onSaveImoDetail = () => {
    const imoDetail = {
      imoLexicalCode: Number(imoProblem.imoLexicalCode),
      icd10Code: imoProblem.icd10Code,
      icd10Title: imoProblem.title,
      secondaryIcd10Code1: imoProblem.secondaryICDCode1,
      secondaryIcd10Text1: imoProblem.secondaryICDText1,
      secondaryIcd10Code2: imoProblem.secondaryICDCode2,
      secondaryIcd10Text2: imoProblem.secondaryICDText2,
      secondaryIcd10Code3: imoProblem.secondaryICDCode3,
      secondaryIcd10Text3: imoProblem.secondaryICDText3,
      secondaryIcd10Code4: imoProblem.secondaryICDCode4,
      secondaryIcd10Text4: imoProblem.secondaryICDText4,
    }

    return new Promise(resolve => {
      resolve(saveImoDetailRequested(imoDetail))
    })
  }

  const toggleExpanded = (imoLexicalCode: string) => {
    if (!expanded) {
      addSelectedProblemId(problem.id)
      updateProblems(problem)
      requestProblemWorkflow(patientId, sessionId, imoLexicalCode)
    }
    setExpanded(!expanded)
  }

  const toggleClicked = (icd10Code: string) => {
    setIsProblemSaving(true)
    addSelectedProblemId(problem.id)
    updateProblems(problem)
    requestProblemInfo(icd10Code)
    problem.secondaryICDCode1 &&
      requestSecondaryCodeInfo(problem.secondaryICDCode1)
    problem.secondaryICDCode2 &&
      requestSecondaryCodeInfo(problem.secondaryICDCode2)
    problem.secondaryICDCode3 &&
      requestSecondaryCodeInfo(problem.secondaryICDCode3)
    problem.secondaryICDCode4 &&
      requestSecondaryCodeInfo(problem.secondaryICDCode4)
  }

  const checkDiagnosesLoaded = ({
    diagnosesLoaded,
    secondaryICDCodeCategories1,
    secondaryICDCodeCategories2,
    secondaryICDCodeCategories3,
    secondaryICDCodeCategories4,
  }: Problem) => {
    const mainCategory = 1
    const secCategory1 = fromJS(secondaryICDCodeCategories1).size > 0 ? 1 : 0
    const secCategory2 = fromJS(secondaryICDCodeCategories2).size > 0 ? 1 : 0
    const secCategory3 = fromJS(secondaryICDCodeCategories3).size > 0 ? 1 : 0
    const secCategory4 = fromJS(secondaryICDCodeCategories4).size > 0 ? 1 : 0

    return (
      diagnosesLoaded ===
      secCategory1 + secCategory2 + secCategory3 + secCategory4 + mainCategory
    )
  }

  const checkEmptyCategories = ({
    secondaryICDCodeCategories1,
    secondaryICDCodeCategories2,
    secondaryICDCodeCategories3,
    secondaryICDCodeCategories4,
    secondaryICDCode1,
    secondaryICDCode2,
    secondaryICDCode3,
    secondaryICDCode4,
  }: Problem) => {
    const categories = [
      { code: secondaryICDCode1, category: secondaryICDCodeCategories1 },
      { code: secondaryICDCode2, category: secondaryICDCodeCategories2 },
      { code: secondaryICDCode3, category: secondaryICDCodeCategories3 },
      { code: secondaryICDCode4, category: secondaryICDCodeCategories4 },
    ]
    const emptyCategories: string[] = []
    categories.map(({ code, category }) => {
      fromJS(category).size === 0 && emptyCategories.push(code)
    })

    return emptyCategories.reduce((code, accum) => `${accum}, ${code}`)
  }

  const getCategoryPerCode = (icd10Code: string, categories: Category[]) =>
    categories?.find((category: Category) =>
      isIcd10CodeMatching(category?.icd10Code as string, icd10Code)
    )

  const requestProblemTypes = (
    problem: Problem,
    icd10Code: string,
    fetchProblemsRequest: (problemCategoryId: string) => void
  ) => {
    if (!problem.error && fromJS(problem.categories).size > 0) {
      const category = getCategoryPerCode(icd10Code, problem.categories)
      fetchProblemsRequest(category?.problemCategoryId as string)
    }
  }

  useEffect(() => {
    if (selectedProblem?.id === id) {
      setImoProblem(selectedProblem)
    }
  }, [imoProblem, selectedProblem])

  useEffect(() => {
    imoProblem &&
      requestProblemTypes(
        imoProblem,
        imoProblem?.icd10Code,
        fetchProblemTypesRequested
      )
  }, [imoProblem?.categoriesLoaded])

  useEffect(() => {
    imoProblem &&
      requestProblemTypes(
        imoProblem,
        imoProblem?.secondaryICDCode1,
        fetchSecondaryProblemTypesRequested
      )
  }, [imoProblem?.secondaryCat1Loaded])

  useEffect(() => {
    imoProblem &&
      requestProblemTypes(
        imoProblem,
        imoProblem?.secondaryICDCode2,
        fetchSecondaryProblemTypesRequested
      )
  }, [imoProblem?.secondaryCat2Loaded])

  useEffect(() => {
    imoProblem &&
      requestProblemTypes(
        imoProblem,
        imoProblem?.secondaryICDCode3,
        fetchSecondaryProblemTypesRequested
      )
  }, [imoProblem?.secondaryCat3Loaded])

  useEffect(() => {
    imoProblem &&
      requestProblemTypes(
        imoProblem,
        imoProblem?.secondaryICDCode4,
        fetchSecondaryProblemTypesRequested
      )
  }, [imoProblem?.secondaryCat4Loaded])

  useEffect(() => {
    if (
      imoProblem &&
      !imoProblem.error &&
      imoProblem?.diagnoses.size > 0 &&
      !isProblemSaved &&
      checkDiagnosesLoaded(imoProblem)
    ) {
      onSaveImoDetail().then(() => {
        imoProblem.diagnoses.map((diagnosis: Diagnosis) => {
          onCreate(diagnosis)
        })
        sendSelectedResultToImo(sessionId, imoLexicalCode)
        setIsProblemSaving(false)
        setIsProblemSaved(true)
      })
    }
  }, [imoProblem?.diagnosesLoaded, imoProblem?.diagnoses])

  const maybeAddSecondary = (code: string, text: string) =>
    code ? `${code} - ${text}\n` : ''

  return (
    <Accordion expanded={expanded} key={id}>
      <AccordionSummary
        onClick={() => modifierWorkflowNeeded && toggleExpanded(imoLexicalCode)}
        className={classes.accordionSummary}
        expandIcon={
          modifierWorkflowNeeded ? (
            <ArrowForwardIosSharp className={classes.arrowIcon} />
          ) : (
            <div className={classes.space}></div>
          )
        }
      >
        <div className={classes.summaryContent}>
          <Typography className={classes.heading}>{title}</Typography>
          <Typography className={classes.secondaryHeading}>
            {icd10Code}
            {additionalICDCodes && (
              <CustomTooltip
                title={
                  <>
                    <b>Secondary ICD-10</b> <br />
                    {maybeAddSecondary(secondaryICDCode1, secondaryICDText1) +
                      maybeAddSecondary(secondaryICDCode2, secondaryICDText2) +
                      maybeAddSecondary(secondaryICDCode3, secondaryICDText3) +
                      maybeAddSecondary(secondaryICDCode4, secondaryICDText4)}
                  </>
                }
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <Add className={classes.addIcon} />
              </CustomTooltip>
            )}
          </Typography>
          {!modifierWorkflowNeeded && (
            <div className={classes.buttonContainer}>
              {imoProblem?.error && !isProblemSaved ? (
                <div className={classes.errorContainer}>
                  Please contact support to map ICD10 {icd10Code} to a problem
                  type.
                </div>
              ) : isProblemSaved && imoProblem?.secondaryCategoriesError ? (
                <span className={classes.alertContainer}>
                  <CustomTooltip
                    title={
                      <div>
                        Please contact support to map secondary ICD10{' '}
                        {checkEmptyCategories(imoProblem)} to a problem type.
                      </div>
                    }
                  >
                    <WarningOutlined />
                  </CustomTooltip>
                  <span>Added with errors</span>
                </span>
              ) : isProblemSaved ? (
                <span className={classes.messageContainer}>
                  <CheckCircleOutline />
                  <span>Added</span>
                </span>
              ) : isProblemSaving ? (
                <div className={classes.loading}>Saving</div>
              ) : (
                <Button
                  fullWidth={false}
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.addButton}
                  onClick={() => toggleClicked(icd10Code)}
                >
                  Add
                </Button>
              )}
            </div>
          )}
        </div>
      </AccordionSummary>
      {imoProblem && imoProblem?.icd10Code && (
        <ImoSelectedProblem
          onSubmit={onSubmit}
          key={id}
          id={id}
          ImoProblem={ImoProblem}
        />
      )}
    </Accordion>
  )
}

export default ImoProblem
