import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, first, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import { MERGE_INITIALIZED } from '../data/admissionsReconciliation'
import { mergeOdsAdmissionIdsSet } from '../data/mergeOdsAdmissionIds'
import {
  fetchMergeOdsAdmissions,
  odsAdmissionFetchFailed,
  odsAdmissionsStored,
} from '../data/odsAdmissions'
import {
  fetchMergePatients,
  patientFetchFailed,
  patientsStored,
} from '../data/patients'
const fetchAndStoreMergePatients = () =>
  from(fetchMergePatients()).pipe(
    map(patientsStored),
    catchError(e => {
      telemetry.error(e)
      return of(patientFetchFailed())
    })
  )
const fetchAndStoreMergeOdsAdmissions = () =>
  from(fetchMergeOdsAdmissions()).pipe(
    mergeMap(odsAdmissions => {
      const keys = (odsAdmissions as any).keySeq().toSetSeq()
      return [mergeOdsAdmissionIdsSet(keys), odsAdmissionsStored(odsAdmissions)]
    }),
    catchError(e => {
      telemetry.error(e)
      return of(odsAdmissionFetchFailed())
    })
  )
export default (action$: any) =>
  action$.pipe(
    ofType(MERGE_INITIALIZED),
    first(),
    mergeMap(() =>
      concat(fetchAndStoreMergePatients(), fetchAndStoreMergeOdsAdmissions())
    )
  )
