import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { messageAdded } from '~/data/messages'
import { Request } from '~/utils/createRequest'

const initial = { status: 'initial' } as const
type Initial = typeof initial

const pending = { status: 'pending' } as const
type Pending = typeof pending

type Success<T> = {
  status: 'success'
  data: T
}
function success<T>(data: T) {
  return { status: 'success', data } as const
}

const successNoData = { status: 'successNoData' } as const
type SuccessNoData = typeof successNoData

function failed(error: Error) {
  return { status: 'failed', error } as const
}
type Failed = ReturnType<typeof failed>

export type RequestStatus<T> =
  | Initial
  | Pending
  | Success<T>
  | SuccessNoData
  | Failed

type ToastMessages = {
  onPending?: string
  onSuccess?: string
  onError?: string
}

type RequestConfig = {
  messages?: ToastMessages
}

export function useRequest<P extends any[], T>(
  request: Request<P, T>,
  config?: RequestConfig
): [RequestStatus<T>, (...params: P) => void, () => void] {
  const [status, setStatus] = useState<RequestStatus<T>>(initial)
  const dispatch = useDispatch()

  const trigger = useCallback(
    (...params: P) => {
      setStatus(pending)

      if (config?.messages?.onPending) {
        dispatch(messageAdded('pending', config.messages.onPending))
      }

      request(...params)
        .then(data => {
          if (data) {
            setStatus(success(data))
          } else {
            setStatus(successNoData)
          }

          if (config?.messages?.onSuccess) {
            dispatch(messageAdded('success', config.messages.onSuccess))
          }

          return data
        })
        .catch((error: Error) => {
          setStatus(failed(error))

          dispatch(
            messageAdded('error', config?.messages?.onError || error.message)
          )
        })
    },
    [request]
  )

  const reset = useCallback(() => {
    setStatus(initial)
  }, [])

  return [status, trigger, reset]
}
