import { combineEpics, ofType } from 'redux-observable'
import { changedTo, exited } from 'redux-routable'
import { concat, of, timer } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import { fetchDailyEvents } from '~/features/encounterWidget'
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
import {
  DASHBOARD_REFRESH_REQUESTED,
  appDashRefreshRequested,
} from '../data/caseManagerDashboardAppId'
import router from '../router'

const REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes

const appIdChange = action$ =>
  action$.pipe(
    filter(changedTo(router)),
    pluck('payload', 'params', 'cmId'),
    filter(cmId => cmId !== undefined),
    distinctUntilChanged(),
    switchMap(cmId =>
      timer(0, REFRESH_INTERVAL).pipe(
        takeUntil(action$.pipe(filter(exited(router)))),
        mapTo(appDashRefreshRequested(cmId))
      )
    )
  )

const refreshRequested = action$ =>
  action$.pipe(
    ofType(DASHBOARD_REFRESH_REQUESTED),
    pluck('payload', 'userId'),
    mergeMap(userId =>
      concat(
        of(fetchEpisodesOfCare.requested({ ownerId: userId })),
        fetchDailyEvents(userId)
      )
    )
  )

export default combineEpics(appIdChange, refreshRequested)
