import { combineReducers } from 'redux-immutable'
import erFacilities from './data/erFacilities'
import erFacilitiesNonClassic from './data/erFacilities_NonClassic'
import erVisitDialog from './data/erVisitDialog'
import erVisits from './data/erVisits'
import icd10s from './data/icd10s'
import key from './key'

const reducer = combineReducers({
  [erVisitDialog.key]: erVisitDialog,
  [erVisits.key]: erVisits,
  [erFacilities.key]: erFacilities,
  [icd10s.key]: icd10s,
  [erFacilitiesNonClassic.key]: erFacilitiesNonClassic,
})

reducer.key = key

export default reducer
