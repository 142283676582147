import { Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { getAttachmentsRoot } from './common/shared'

/* Record */
const Attachment = Record({
  id: null,
  userId: null,
  patientId: null,
  bucket: null,
  object: null,
  displayName: '',
  ext: null,
  attachmentType: '',
  contentType: null,
  signedUrl: null,
  error: null,
  errorMessages: null,
  successful: null,
})

/* Constants */
const key = 'newAttachment'

const NEW_ATTACHMENT_TYPE_SELECTED = 'attachments/NEW_ATTACHMENT_TYPE_SELECTED'
const FILE_SELECTED = 'attachments/FILE_SELECTED'
const NEW_ATTACHMENT_UPLOADED = 'attachments/NEW_ATTACHMENT_UPLOADED'
const ATTACHMENT_TYPE_CHANGED = 'attachments/ATTACHMENT_TYPE_CHANGED'
const ATTACHMENTS_LOAD = 'attachments/ATTACHMENTS_LOAD'

/* Action Creators */
const newAttTypeSelected = attachmentType => ({
  type: NEW_ATTACHMENT_TYPE_SELECTED,
  attachmentType,
})
const newAttachmentUploaded = () => ({
  type: NEW_ATTACHMENT_UPLOADED,
})
const onLoadAttachments = (patientId, caseId) => ({
  type: ATTACHMENTS_LOAD,
  payload: { patientId, caseId },
})
const fileSelected = opts => ({ type: FILE_SELECTED, ...opts })

const updateAttachmentType = (id, attachmentType) => ({
  type: ATTACHMENT_TYPE_CHANGED,
  id,
  attachmentType,
})

/* Async Actions */
// Get a signed url for the upload
const fetchSignedURL = ({ file, patientId, attachmentType }) =>
  AspireAPI.get('/v1/attachments/signed_policy', {
    params: {
      attachmentType,
      patientId,
      fileName: file.name,
      contentType: file.type,
    },
  })

// Upload the file to s3 using a signed url generated server side
const putFile = (attachment, file, onProgress) => {
  const xhr = new XMLHttpRequest()
  return new Promise((res, rej) => {
    xhr.open('PUT', attachment.signedUrl)

    xhr.upload.onprogress = onProgress
    xhr.upload.onload = () =>
      res(Object.assign(attachment, { successful: true }))
    xhr.upload.onerror = () =>
      rej(Object.assign(attachment, { successful: false }))

    xhr.send(file)
  })
}
// Save the success or failure of the upload to the database
const saveUploadedFile = ({ id, successful }, faxToPCP) =>
  AspireAPI.post(
    `/v1/attachments/upload_complete?id=${id}&successful=${successful}`,
    { faxToPCP }
  )

const saveFileAttachmentType = (id, attachmentType) =>
  AspireAPI.post(`/v1/attachments?id=${id}&attachmentType=${attachmentType}`)

/* Reducer */
export default createReducer(key, Attachment(), {
  [NEW_ATTACHMENT_TYPE_SELECTED]: (state, { attachmentType }) =>
    state.set('attachmentType', attachmentType),

  [FILE_SELECTED]: (state, { file }) => state.set('displayName', file.name),

  [NEW_ATTACHMENT_UPLOADED]: () => Attachment(),
})

/* Selectors */
const getAttachment = createSelector(
  [state => getAttachmentsRoot(state).get(key)],
  file => file
)

export {
  Attachment,
  newAttTypeSelected,
  ATTACHMENTS_LOAD,
  FILE_SELECTED,
  ATTACHMENT_TYPE_CHANGED,
  NEW_ATTACHMENT_UPLOADED,
  fileSelected,
  updateAttachmentType,
  fetchSignedURL,
  saveUploadedFile,
  saveFileAttachmentType,
  getAttachment,
  putFile,
  onLoadAttachments,
  newAttachmentUploaded,
}
