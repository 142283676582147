import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { CSS_DASHBOARD_APP_SCHEDULING_PATIENT } from '../router'
import { getRoot } from './common/shared'

const CURRENT_PATIENT_ID = 'currentPatientId'

const reducer = paramsReducer(
  CSS_DASHBOARD_APP_SCHEDULING_PATIENT,
  ({ patientId }: any) => patientId
)

reducer.key = CURRENT_PATIENT_ID

export const getCurrentPatientId = pipe(getRoot, get(CURRENT_PATIENT_ID))

export default reducer
