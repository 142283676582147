import { combineReducers } from 'redux-immutable'
import form from './data/form'
import lastLogCommunication from './data/lastLogCommunication'
import CAREMORE_LOG_COMMUNICATION from './key'

const reducer = combineReducers({
  [form.key]: form,
  [lastLogCommunication.key]: lastLogCommunication,
})

reducer.key = CAREMORE_LOG_COMMUNICATION

export default reducer
