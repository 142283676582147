// import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const GOAL_ASSOCIATIONS = 'goalAssociations'

export const fetchGoalAssociations = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_GOAL_ASSOCIATIONS',
  requestParams: ['id'],
  operation: (id: any) => AspireAPI.get(`admin_tools/goal_associations/${id}`),
  messages: { failed: 'There was an issue fetching goal associations' },
})

export default createReducer(GOAL_ASSOCIATIONS, [], {
  [fetchGoalAssociations.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getGoalAssociations = pipe(getRoot, get(GOAL_ASSOCIATIONS))
