import { ofType } from 'redux-observable'
import { changedTo, exited } from 'redux-routable'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  carePlanSet,
  getCarePlanProblems,
  getLegacy,
  problemsSet,
} from '~/features/problems/data'
import { mapLegacyCarePlanFormData } from '~/features/problems/utils'
// @ts-expect-error no export
import { switchTo } from '~/utils/operators'
// @ts-expect-error no export
import { formDataChangedByTag } from '../data/common/shared'
// @ts-expect-error no export
import { getCurrentAssessmentId } from '../data/currentAssessmentId'
// @ts-expect-error no export
import { ASSESSMENT_FORM } from '../router'

const LEGACY_CARE_PLAN_TAG = 'problemsGoalsActions'
const CARE_PLAN_TAG = 'assessmentAndPlanProblemsList'
const PROBLEMS_LIST_TAG = 'problemsList'

export const updateCarePlan = (action$: any, state$: any) =>
  action$.pipe(
    ofType(carePlanSet),
    switchTo(state$),
    map(getCurrentAssessmentId),
    filter(Boolean),
    switchMap(() =>
      state$.pipe(
        distinctUntilChanged(
          (a, b) => getCarePlanProblems(a) === getCarePlanProblems(b)
        ),
        skip(1),
        debounceTime(1000),
        map(state => {
          const assessmentId = getCurrentAssessmentId(state)
          // @ts-expect-error state is unknown
          const problems = getCarePlanProblems(state).valueSeq().toJS()
          const isLegacy = getLegacy(state)

          // Temporarily map legacy care plan for open assessments
          const TAG = isLegacy ? LEGACY_CARE_PLAN_TAG : CARE_PLAN_TAG
          const carePlan = isLegacy
            ? mapLegacyCarePlanFormData(problems)
            : problems

          return formDataChangedByTag(assessmentId, TAG, carePlan)
        }),
        takeUntil(action$.pipe(filter(changedTo(ASSESSMENT_FORM)))),
        takeUntil(action$.pipe(filter(exited(ASSESSMENT_FORM))))
      )
    )
  )

export const updateProblems = (action$: any, state$: any) =>
  action$.pipe(
    ofType(problemsSet),
    switchTo(state$),
    map(getCurrentAssessmentId),
    filter(Boolean),
    switchMap(() =>
      state$.pipe(
        distinctUntilChanged(
          (a, b) => getCarePlanProblems(a) === getCarePlanProblems(b)
        ),
        skip(1),
        debounceTime(1000),
        map(state => {
          const assessmentId = getCurrentAssessmentId(state)
          // @ts-expect-error state is unknown
          const problems = getCarePlanProblems(state).valueSeq().toJS()
          return formDataChangedByTag(assessmentId, PROBLEMS_LIST_TAG, problems)
        }),
        takeUntil(action$.pipe(filter(changedTo(ASSESSMENT_FORM)))),
        takeUntil(action$.pipe(filter(exited(ASSESSMENT_FORM))))
      )
    )
  )
