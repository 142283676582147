import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import {
  cancelOwner,
  completeOwner,
  fetchEpisodeOfCare,
} from '../data/episodesOfCare'

// Last owner on case is closed so refetch case itself
export default action$ =>
  action$.pipe(
    ofType(completeOwner.SUCCEEDED, cancelOwner.SUCCEEDED),
    pluck('payload'),
    filter(
      ({ oldPrimaryOwner, newPrimaryOwner }) =>
        oldPrimaryOwner.primaryOwner && !newPrimaryOwner
    ),
    map(({ oldPrimaryOwner: { episodeOfCareId } }) =>
      fetchEpisodeOfCare.requested(episodeOfCareId)
    )
  )
