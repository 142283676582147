import { isEqual } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserSelector from '~/components/UserSelector'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import stateCodes from '~/utils/validation/stateCodes'
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CareTeamPodManager } from '../components/CareTeamPodManager'
import {
  addCareTeamMember,
  changeCareTeam,
  changeCareTeamMember,
  deleteCareTeamMember,
  getRoles,
  getTypes,
} from '../data/careTeams'
import CTMembers from './CTMembers'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
}

const styles = ({ spacing }) => ({
  bottomButton: {
    margin: `${spacing(1)}px ${spacing(1)}px ${spacing(1)}px 0`,
  },
  dialogBox: {
    minHeight: 210,
  },
})

const CTMemberSelector = ({ onEditValueChange }) => {
  const onUserSelected = (id, _name) => {
    onEditValueChange(id)
  }

  return (
    <React.Fragment>
      <UserSelector onUserSelected={onUserSelected}></UserSelector>
    </React.Fragment>
  )
}

CTMemberSelector.propTypes = {
  onEditValueChange: PropTypes.func,
}

const CTNewMemberUnstyled = ({ careTeamDetails, classes }) => {
  const [role, setRole] = useState(undefined)
  const [userId, setUserId] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const roles = useSelector(getRoles)

  const onUserSelected = (id, _name) => {
    setUserId(id)
  }

  const onClose = () => {
    setUserId(undefined)
    setRole(undefined)
    setOpen(false)
    setSubmitting(false)
  }

  const requestAddCareTeamMember = () => {
    setSubmitting(true)
    dispatch(addCareTeamMember.requested(careTeamDetails.id, role, userId))
    onClose()
  }

  const openRoles = useMemo(() => {
    const existingRoles = careTeamDetails.members.map(member => member.role)
    return roles.filter(role => !existingRoles.includes(role.key))
  }, [roles, careTeamDetails.members])

  return (
    <React.Fragment>
      <Button
        className={classes.bottomButton}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Add Care Team Member
      </Button>
      <ActionDialog
        open={open}
        title="Add Care Team Member"
        mode="add"
        onAdd={requestAddCareTeamMember}
        onClose={onClose}
        disableAction={!role || !userId || submitting}
        maxWidth="md"
        fullWidth
      >
        <div className={classes.dialogBox}>
          <Select
            fullWidth
            label={'Role'}
            value={role || ''}
            onChange={e => setRole(e.target.value)}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {openRoles.map(role => (
              <MenuItem key={role.key} value={role.key}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
          <UserSelector onUserSelected={onUserSelected}></UserSelector>
        </div>
      </ActionDialog>
    </React.Fragment>
  )
}

CTNewMemberUnstyled.propTypes = {
  careTeamDetails: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

const CTNewMember = withStyles(styles)(CTNewMemberUnstyled)

const CTTitleUnstyled = ({ careTeamDetails, classes }) => {
  const [newLabel, setNewLabel] = useState(careTeamDetails.label)
  const [newValidStateCodes, setNewValidStateCodes] = useState(
    careTeamDetails.validStateCodes
  )

  const dispatch = useDispatch()

  const requestEditCareTeam = () =>
    dispatch(
      changeCareTeam.requested(careTeamDetails.id, newLabel, newValidStateCodes)
    )

  const showSave = useMemo(
    () =>
      newLabel !== careTeamDetails.label ||
      (newValidStateCodes &&
        !isEqual(careTeamDetails.validStateCodes || [], newValidStateCodes)),
    [careTeamDetails, newLabel, newValidStateCodes]
  )

  return (
    <React.Fragment>
      <TextField
        fullWidth
        id="standard-required"
        label="Care Team Title"
        margin="normal"
        value={newLabel}
        onChange={e => setNewLabel(e.target.value)}
      />
      <FormControl fullWidth>
        <InputLabel htmlFor="validStates">Valid States</InputLabel>
        <Select
          fullWidth
          label="Valid States"
          multiple
          value={newValidStateCodes || []}
          onChange={e => setNewValidStateCodes(e.target.value)}
          input={<Input id="validStates" />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {stateCodes.map(code => (
            <MenuItem key={code} value={code}>
              {code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showSave && (
        <Button
          className={classes.bottomButton}
          variant="contained"
          color="primary"
          onClick={requestEditCareTeam}
        >
          Save Changes
        </Button>
      )}
    </React.Fragment>
  )
}

CTTitleUnstyled.propTypes = {
  careTeamDetails: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

const CTTitle = withStyles(styles)(CTTitleUnstyled)

const CTDetail = ({ careTeamDetails }) => {
  const dispatch = useDispatch()

  const types = useSelector(getTypes)
  const type = types.get(careTeamDetails.type)

  const onChangeCareTeamMember = (role, userId) =>
    dispatch(changeCareTeamMember.requested(careTeamDetails.id, role, userId))
  const onDeleteCareTeamMember = role =>
    dispatch(deleteCareTeamMember.requested(careTeamDetails.id, role))

  return (
    <React.Fragment>
      <Typography>Type: {type.label}</Typography>
      <CTTitle careTeamDetails={careTeamDetails} />
      <CareTeamPodManager careTeamDetails={careTeamDetails} />
      <CTMembers
        members={careTeamDetails.members}
        onChangeCareTeamMember={onChangeCareTeamMember}
        onDeleteCareTeamMember={onDeleteCareTeamMember}
        careTeamType={careTeamDetails.type}
        editMode
      />
      <CTNewMember careTeamDetails={careTeamDetails} />
    </React.Fragment>
  )
}

CTDetail.propTypes = {
  careTeamDetails: PropTypes.object.isRequired,
}

export default CTDetail
