import { ofType } from 'redux-observable'
import { open } from 'redux-routable'
import { merge, of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import {
  createAssessment,
  fetchAssessments,
} from '~/apps/assessment/data/assessments'
import { ASSESSMENT_FORM } from '~/apps/assessment/router'
import { getUserId } from '~/data/session'
import { scopedCreator } from '~/utils/data'
import { requestSucceeded } from '~/utils/operators'
import key from '../key'

export const assessmentLinkClicked = scopedCreator(
  key
)('ASSESSMENT_LINK_CLICKED', ['patientId', 'encounterType'])

export default (action$, state$) => {
  return action$.pipe(
    ofType(assessmentLinkClicked),
    pluck('payload'),
    mergeMap(({ patientId, encounterType }) =>
      merge(
        of(
          fetchAssessments.requested({
            patient_ids: [patientId],
            statuses: ['in_progress', 'created'],
            types: [encounterType],
          })
        ),
        action$.pipe(
          requestSucceeded(fetchAssessments),
          pluck('payload'),
          mergeMap(([encounter]) => {
            if (encounter) {
              return of(open(ASSESSMENT_FORM, { assessmentId: encounter.id }))
            } else {
              const userId = getUserId(state$.value)

              return of(
                createAssessment.requested(patientId, userId, encounterType)
              )
            }
          })
        )
      )
    )
  )
}
