import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  column: {
    flex: ({ flex }: any) => flex || '1',

    '& > *:not(:last-child)': {
      marginBottom: spacing(4),
    },
  },
}))

type Props = {
  children: React.ReactNode
  flex?: string
}

export default function Column(props: Props) {
  const { children } = props
  const classes = useStyles(props)

  return <div className={classes.column}>{children}</div>
}
