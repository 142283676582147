import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { getPatientId } from '~/apps/patientRecord/data/patientId'
import { fetchPatient } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'
import { createUnengaged } from '../data/createUnengagedDialog'
import { closeUnengaged, updateUnengaged } from '../data/updateUnengagedDialog'

export default (action$, state$) =>
  action$.pipe(
    ofType(
      closeUnengaged.SUCCEEDED,
      createUnengaged.SUCCEEDED,
      updateUnengaged.SUCCEEDED
    ),
    switchTo(state$),
    map(getPatientId),
    map(fetchPatient.requested)
  )
