import { combineReducers } from 'redux-immutable'
import checkInDisabled from './data/checkInDisabled'
import dailyEvents from './data/dailyEvents'
import key from './key'

const reducer = combineReducers({
  [checkInDisabled.key]: checkInDisabled,
  [dailyEvents.key]: dailyEvents,
})

reducer.key = key

export default reducer
