import { combineEpics } from 'redux-observable'
import {
  saveHcGoal,
  saveHcGoalIntervention,
  saveExternalHealthConcern,
} from './epics/carePlanEpic'

export default combineEpics(
  saveHcGoal,
  saveHcGoalIntervention,
  saveExternalHealthConcern
)
