import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'

const HEALTH_CONCERNS = 'healthConcerns'

export const HealthConcern = Record({
  id: null,
  label: null,
  type: null,
  status: null,
  goals: null,
  createdBy: null,
  modifiedBy: null,
  createdAt: null,
  modifiedAt: null,
})

const transformHealthConcerns = (healthConcerns: any) => {
  return List(
    healthConcerns.map((healthConcern: any) => {
      return HealthConcern({
        id: healthConcern?.id,
        label: healthConcern?.label,
        type: healthConcern?.type,
        status: healthConcern?.status ? 'Active' : 'Deactivated',
        goals: healthConcern?.goals,
        createdBy: healthConcern?.createdBy,
        modifiedBy: healthConcern?.modifiedBy,
        createdAt: formatDate(healthConcern?.createdAt),
        modifiedAt: formatDate(healthConcern?.modifiedAt),
      })
    })
  )
}

export const fetchHealthConcerns = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_HEALTH_CONCERNS',
  operation: () => AspireAPI.get('admin_tools/health_concerns'),
  transform: transformHealthConcerns,
  messages: { failed: 'There was an issue fetching health concerns' },
})

export default createReducer(HEALTH_CONCERNS, List(), {
  [fetchHealthConcerns.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getHealthConcerns = pipe(getRoot, get(HEALTH_CONCERNS))
