import PropTypes from 'prop-types'
import React from 'react'
import { InfoPopover } from '~/components/GeoMap'
import { Paper } from '@material-ui/core'

const OFFSET = { horizontal: 'left', vertical: 'top' }

const MarkerMenu = ({ children, open, onClose }) =>
  open ? (
    <InfoPopover open={open} offset={OFFSET} zIndex={10} onClose={onClose}>
      <Paper onClick={onClose}>{children}</Paper>
    </InfoPopover>
  ) : null

MarkerMenu.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MarkerMenu
