import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'redux-routable-react'
import { patientPanelOpened } from '~/data/patientPanel'
import PropTypes from '~/utils/propTypes'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CANCELLED, COMPLETED } from '../constants'

const styles = ({ spacing }) => ({
  actions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'left',
    padding: [spacing(1), 0, spacing(1), spacing(0.5)],
    '& button': {
      height: 40,
      padding: [spacing(1), spacing(1)],
    },
    '& a': {
      height: 40,
      padding: [spacing(1.5), spacing(1)],
    },
    '& div:first-child': {
      marginRight: spacing(1),
    },
  },
})

const Actions = ({
  classes,
  route,
  patientPanelOpened,
  row: { id: taskId, patientId, status },
}) => {
  const openPatientPanel = event => {
    const {
      currentTarget: { value: patientId },
    } = event
    patientPanelOpened(patientId)
  }
  const [color, title] = [COMPLETED, CANCELLED].includes(status)
    ? ['primary', 'Go To Completed Task']
    : ['secondary', 'Go To Incomplete Task']

  return (
    <div className={classes.actions}>
      <Tooltip title={title}>
        <div>
          <IconButton
            component={Link}
            params={{ taskId, patientId }}
            route={route}
            target="_blank"
            rel="noopener"
          >
            <Icon color={color}>open_in_browser_icon</Icon>
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Open Patient Info">
        <div>
          <IconButton onClick={openPatientPanel} value={patientId}>
            <Icon color={color}>chrome_reader_mode</Icon>
          </IconButton>
        </div>
      </Tooltip>
    </div>
  )
}

Actions.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  patientPanelOpened: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
}

export default connect(null, { patientPanelOpened })(
  withStyles(styles)(Actions)
)
