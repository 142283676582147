import React, { PureComponent } from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import mapPropTypes from './mapPropTypes'
import withGoogle from './withGoogle'
import withMap from './withMap'
import withMarker from './withMarker'

const offsetFactors = {
  horizontal: { left: 0, middle: 0.5, right: 1 },
  vertical: { top: 0, center: 0.5, bottom: 1 },
}

const doNothing = () => {}

const styles = {
  container: { position: 'absolute' },
}

// Eric note (10-23-19): This boi is slow so recommend not mounting
// it until popover is open until we can gut and rebuild GeoMap
class InfoPopover extends PureComponent {
  constructor(props) {
    super(props)

    const overlay = new props.google.maps.OverlayView()

    overlay.draw = this.setOffset
    overlay.onAdd = this.setOffset
    overlay.onRemove = doNothing

    this.state = { overlay, left: null, top: null }
  }

  componentDidMount() {
    this.state.overlay.setMap(this.props.map)
    this.updateExternalListeners()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position) {
      this.setOffset(this.props)
    }

    if (prevProps.open !== this.props.open) {
      this.updateExternalListeners(this.props)
      this.setOffset(this.props)
    }
  }

  componentWillUnmount() {
    this.state.overlay.setMap(null)
    this.updateExternalListeners({ ...this.props, open: false })
  }

  updateExternalListeners = ({ map, marker, open } = this.props) => {
    if (open) {
      window.addEventListener('click', this.onClickAway)

      if (map) {
        this.mapListener = map.addListener('center_changed', this.setOffset)
      }
      if (marker) {
        this.markerListener = marker.addListener(
          'position_changed',
          this.setOffset
        )
      }
    } else {
      window.removeEventListener('click', this.onClickAway)
      if (this.mapListener) {
        this.mapListener.remove()
      }
      if (this.markerListener) {
        this.markerListener.remove()
      }
    }
  }

  setOffset = ({ marker, position, offset } = this.props) => {
    if (marker && offset) {
      this.setState(this.getOffset(marker.getPosition(), offset))
    } else if (position && offset) {
      this.setState(this.getOffset(position, offset))
    }
  }

  getOffset = (latLng, offset) => {
    const projection = this.state.overlay.getProjection()

    if (projection) {
      const mapElement = this.props.map.getDiv()
      const position =
        latLng instanceof this.props.google.maps.LatLng
          ? latLng
          : new this.props.google.maps.LatLng(latLng)

      const {
        x: relativeLeft,
        y: relativeTop,
      } = projection.fromLatLngToContainerPixel(position)
      const shiftLeft =
        this.containerElement.offsetWidth *
        offsetFactors.horizontal[offset.horizontal]
      const shiftTop =
        this.containerElement.offsetHeight *
        offsetFactors.vertical[offset.vertical]

      return {
        left: relativeLeft - shiftLeft + mapElement.offsetLeft,
        top: relativeTop - shiftTop + mapElement.offsetTop,
      }
    } else {
      return { left: 0, top: 0 }
    }
  }

  onClickAway = e => {
    if (!this.containerElement.contains(e.target)) {
      this.props.onClose && this.props.onClose()
    }
  }

  render() {
    const { classes, children, open, zIndex } = this.props
    const { left, top } = this.state

    return (
      <div
        className={classes.container}
        style={{ left, top, zIndex }}
        ref={ce => {
          this.containerElement = ce
        }}
      >
        {open && left && top ? children : null}
      </div>
    )
  }
}

InfoPopover.propTypes = {
  google: PropTypes.object,
  classes: PropTypes.object,
  children: PropTypes.node,
  offset: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  map: PropTypes.object,
  marker: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  position: mapPropTypes.latLng,
  open: PropTypes.bool,
  zIndex: PropTypes.number,
  onClose: PropTypes.func,
}

InfoPopover.defaultProps = {
  offset: { horizontal: 'middle', vertical: 'bottom' },
}

export default compose(
  withMarker,
  withMap,
  withGoogle,
  withStyles(styles)
)(InfoPopover)
