import React from 'react'
import Markdown from '~/components/MarkdownToJsx'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../widgets/memoizedWrapper'

const MarkdownField = ({ formData }) => {
  return <Markdown>{formData}</Markdown>
}

MarkdownField.propTypes = {
  formData: PropTypes.string,
}

export default memoizedWrapper(MarkdownField)
