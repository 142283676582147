import { connect } from 'react-redux'
import RxWriter from '../components/RxWriter/RxWriter'
import { getDrugAlerts } from '../data/alerts'
import { formSelector } from '../utils/formValueSelectors'

const mapStateToProps = state => ({
  rxStatusValue: formSelector(state, 'status'),
  drugAlerts: getDrugAlerts(state),
})

export default connect(mapStateToProps)(RxWriter)
