// Task Statuses
export const NEW = 'new'
export const STARTED = 'started'
export const REASSIGNED = 'reassigned'
export const SNOOZED = 'snoozed'
export const CANCELLED = 'cancelled'
export const COMPLETED = 'completed'

// Task Priorities
export const URGENT = 'urgent'
export const NORMAL = 'normal'

// Dialog Types
export const CANCEL = 'cancel'
export const COMPLETE = 'complete'
export const NEW_TASK = 'new task'
export const REASSIGN = 'reassign'
export const SNOOZE = 'snooze'

// Defaults
export const UNSPECIFIED_PHYSICIAN = 'Unspecified Physician'
export const UNSPECIFIED_VENDOR = 'Unspecified Vendor'
