import { OrderedMap, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, actionCreator, get, into, payload } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'

// Key
const VENDORS = 'vendors'

// Record
export const Vendor = Record({
  billingAddress: null,
  billingAddress2: null,
  billingCity: null,
  billingPostalCode: null,
  billingStateCode: null,
  currentPatientNetwork: null,
  fax: null,
  id: null,
  isInNetwork: null,
  name: null,
  phone: null,
  type: null,
  website: null,
})

// Action
export const vendorsReset = actionCreator(`${VENDORS}/RESET`)

// Request
export const searchVendors = Request({
  typePrefix: VENDORS,
  typeBase: 'SEARCH_VENDORS',
  requestParams: ['searchText', 'type', 'patientId'],
  // type is allowed to be undefined
  // should be a string or array with these options ['Hospice', 'Hospital', 'Pharmacy', 'Vendor']
  operation: (searchText, type, patientId) =>
    AspireAPI.get('v1/vendors', { params: { searchText, type, patientId } }),
  transform: into(Vendor, 'id', OrderedMap),
  messages: {
    failed: 'Could not fetch vendors.',
  },
})

// Reducer
export default createReducer(VENDORS, OrderedMap(), {
  [searchVendors.SUCCEEDED]: compose(payload, action),
  [vendorsReset]: () => OrderedMap(),
})

// Selector
export const getVendors = get(VENDORS)

// transformer can be any of the `to*` methods on an Immutable OrderedMap
export const getVendorsTransformed = transformer =>
  createSelector(getVendors, vendors => vendors[transformer]())
