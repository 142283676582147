import cx from 'classnames'
import { toLower } from 'lodash'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { AccordionDetails } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Alert from './Alert'

const styles = ({ palette }) => ({
  details: {
    display: 'block',
  },
  alertDetails: {
    padding: 0,
    '& > *': {
      backgroundColor: palette.shades.darker,
      borderBottom: [1, 'solid', palette.shades.darker],
      margin: 0,
      display: 'flex',
      padding: [16, 18, 16, 20],
    },
  },
})

const AlertsContent = ({ alerts, classes }) => {
  // REF: https://jira.aspirehealth.io/browse/NAD-614
  const safetyAlerts = alerts.filter(a => toLower(a.type) === 'safety')
  const otherAlerts = alerts
    .filter(a => toLower(a.type) !== 'safety')
    .sortBy(a => a.severity)

  return (
    <AccordionDetails className={cx(classes.details, classes.alertDetails)}>
      {safetyAlerts.map(alert => (
        <Alert key={alert.id} alert={alert} />
      ))}
      {otherAlerts.map(alert => (
        <Alert key={alert.id} alert={alert} />
      ))}
    </AccordionDetails>
  )
}

AlertsContent.propTypes = {
  alerts: PropTypes.list,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AlertsContent)
