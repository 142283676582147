import { ofType } from 'redux-observable'
import { debounceTime, filter, map, pluck } from 'rxjs/operators'
import { fetchPhysiciansByName } from '../data/physicians'
import { PHYSICIAN_QUERY_CHANGED } from '../data/referrals'
export default (action$: any) =>
  action$.pipe(
    ofType(PHYSICIAN_QUERY_CHANGED),
    debounceTime(750),
    pluck('physicianName'),
    filter(physicianName => (physicianName as any).length > 2),
    map(fetchPhysiciansByName.requested)
  )
