import React from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from './TextField'

class NumberField extends React.Component {
  onChange = (value, e) => this.props.onChange(Number(value), e)

  onBlur = (value, e) => this.props.onBlur(Number(value), e)

  render = () => {
    const {
      disabled,
      fullWidth,
      max,
      min,
      onBlur,
      onChange,
      value,
    } = this.props

    return (
      <TextField
        {...this.props}
        disabled={disabled}
        fullWidth={fullWidth}
        InputProps={{ inputProps: { min: min, max: max } }}
        onBlur={onBlur && this.onBlur}
        onChange={onChange && this.onChange}
        type="number"
        value={value || value === 0 ? String(value) : value}
      />
    )
  }
}

NumberField.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default NumberField
