export const drugForms = [
  { id: 'C48473', name: 'Ampule' },
  { id: 'C62412', name: 'Applicator' },
  { id: 'C78783', name: 'Applicatorful' },
  { id: 'C48474', name: 'Bag' },
  { id: 'C48475', name: 'Bar' },
  { id: 'C53495', name: 'Bead' },
  { id: 'C54564', name: 'Blister' },
  { id: 'C53498', name: 'Block' },
  { id: 'C48476', name: 'Bolus' },
  { id: 'C48477', name: 'Bottle' },
  { id: 'C48478', name: 'Box' },
  { id: 'C48479', name: 'Can' },
  { id: 'C62413', name: 'Canister' },
  { id: 'C64696', name: 'Caplet' },
  { id: 'C48480', name: 'Capsule' },
  { id: 'C54702', name: 'Carton' },
  { id: 'C48481', name: 'Cartridge' },
  { id: 'C62414', name: 'Case' },
  { id: 'C69093', name: 'Cassette' },
  { id: 'C48484', name: 'Container' },
  { id: 'C48489', name: 'Cylinder' },
  { id: 'C16830', name: 'Device' },
  { id: 'C48490', name: 'Disk' },
  { id: 'C62417', name: 'Pack' },
  { id: 'C96265', name: 'Pack' },
  { id: 'C64933', name: 'Each' },
  { id: 'C53499', name: 'Film' },
  { id: 'C48494', name: 'Ounce' },
  { id: 'C101680', name: 'French' },
  { id: 'C48580', name: 'Gallon' },
  { id: 'C48155', name: 'Gram' },
  { id: 'C69124', name: 'Gum' },
  { id: 'C48499', name: 'Implant' },
  { id: 'C48501', name: 'Inhalation' },
  { id: 'C62275', name: 'Inhaler' },
  { id: 'C62418', name: 'Refill' },
  { id: 'C62276', name: 'Insert' },
  { id: 'C67283', name: 'Bag' },
  { id: 'C28252', name: 'Kilogram' },
  { id: 'C48504', name: 'Kit' },
  { id: 'C120263', name: 'Lancet' },
  { id: 'C48505', name: 'Liter' },
  { id: 'C48506', name: 'Lozenge' },
  { id: 'C48491', name: 'Drop' },
  { id: 'C48512', name: 'Milliequivalent' },
  { id: 'C28253', name: 'Milligram' },
  { id: 'C28254', name: 'Milliliter' },
  { id: 'C28251', name: 'Millimeter' },
  { id: 'C71204', name: 'Nebule' },
  { id: 'C100052', name: 'Injection' },
  { id: 'C69086', name: 'System' },
  { id: 'C48519', name: 'Ounce' },
  { id: 'C48520', name: 'Package' },
  { id: 'C48521', name: 'Packet' },
  { id: 'C65032', name: 'Pad' },
  { id: 'C82484', name: 'Paper' },
  { id: 'C48524', name: 'Patch' },
  { id: 'C48529', name: 'Pint' },
  { id: 'C48530', name: 'Pouch' },
  { id: 'C48531', name: 'Pound' },
  { id: 'C97717', name: 'Syringe' },
  { id: 'C65060', name: 'Puff' },
  { id: 'C111984', name: 'Pump' },
  { id: 'C48534', name: 'Quart' },
  { id: 'C62609', name: 'Ring' },
  { id: 'C71324', name: 'Sachet' },
  { id: 'C48536', name: 'Scoopful' },
  { id: 'C53502', name: 'Sponge' },
  { id: 'C48537', name: 'Spray' },
  { id: 'C53503', name: 'Stick' },
  { id: 'C48538', name: 'Strip' },
  { id: 'C48539', name: 'Suppository' },
  { id: 'C53504', name: 'Swab' },
  { id: 'C48540', name: 'Syringe' },
  { id: 'C48541', name: 'Tablespoon' },
  { id: 'C48542', name: 'Tablet' },
  { id: 'C62421', name: 'Tabminder' },
  { id: 'C48543', name: 'Tampon' },
  { id: 'C48544', name: 'Teaspoon' },
  { id: 'C54704', name: 'Tray' },
  { id: 'C48548', name: 'Troche' },
  { id: 'C48549', name: 'Tube' },
  { id: 'C48551', name: 'Vial' },
  { id: 'C48552', name: 'Wafer' },
]

export const routes = [
  { name: 'Orally' },
  { name: 'By Mouth' },
  { name: 'Chewed' },
  { name: 'Applied Topically' },
  { name: 'Applied to Affected Area' },
  { name: 'In Each Eye' },
  { name: 'In Affected Eye' },
  { name: 'In Each Ear' },
  { name: 'In Affected Ear' },
  { name: 'By Injection' },
  { name: 'Intramuscularly' },
  { name: 'Intravenously' },
  { name: 'Intranasally' },
  { name: 'Intravaginally' },
  { name: 'Sublingually' },
  { name: 'Rectally' },
  { name: 'Inhaled' },
]

export const options = [
  { name: '' },
  { name: '(with meals)' },
  { name: '(with food)' },
  { name: '(after meals)' },
  { name: '(before meals)' },
  { name: '(at bedtime)' },
  { name: '(in the morning)' },
  { name: '(in the evening)' },
  { name: '(as needed)' },
]
