import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import mapPropTypes from './mapPropTypes'
import { createListener, createPropsToOptions } from './objectHelpers'
import withGoogle from './withGoogle'
import withMap from './withMap'
import withMarker from './withMarker'

const styles = {
  container: { position: 'absolute' },
  hidden: { display: 'none' },
  text: { color: 'black' },
}

const propsToOptions = createPropsToOptions(props => ({
  position: props.position,
  disableAutoPan: props.disableAutoPan,
  zIndex: props.zIndex,
}))

class InfoWindow extends PureComponent {
  constructor(props) {
    super(props)

    const options = propsToOptions(props)
    const infoWindow = new props.google.maps.InfoWindow(options)

    infoWindow.addListener('closeclick', createListener(this, 'onClose'))

    this.state = { infoWindow }
  }

  componentDidMount() {
    this.state.infoWindow.setContent(this.contentElement)
    this.updateOpen()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.position !== this.props.position ||
      prevProps.disableAutoPan !== this.props.disableAutoPan ||
      prevProps.zIndex !== this.props.zIndex
    ) {
      this.state.infoWindow.setOptions(propsToOptions(this.props, prevProps))
    }

    if (prevProps.open !== this.props.open) {
      this.updateOpen(this.props)
    }
  }

  componentWillUnmount() {
    this.state.infoWindow.setContent(null)
    this.updateOpen({ ...this.props, open: false })
    this.props.google.maps.event.clearInstanceListeners(this.state.infoWindow)
  }

  updateOpen = ({ map, marker, open } = this.props) => {
    if (open) {
      this.state.infoWindow.open(map, marker)
    } else {
      this.state.infoWindow.close()
    }
  }

  render() {
    const { classes, children, open } = this.props

    const containerClassName = classNames({
      [classes.container]: true,
      [classes.hidden]: !open,
    })

    return (
      <div className={containerClassName}>
        <div
          className={classes.text}
          ref={ce => {
            this.contentElement = ce
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

InfoWindow.propTypes = {
  google: PropTypes.object,
  classes: PropTypes.object,
  children: PropTypes.node,
  map: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  marker: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  open: PropTypes.bool,
  position: mapPropTypes.latLng,
  disableAutoPan: PropTypes.bool,
  zIndex: PropTypes.number,
  onClose: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
}

export default compose(
  withMarker,
  withMap,
  withGoogle,
  withStyles(styles)
)(InfoWindow)
