import { Route, Router } from 'redux-routable'
import key from './key'

export const PHYSICIAN_DASHBOARD_HOME = `${key}.home`
export const PHYSICIAN_DASHBOARD_CALENDAR = `${key}.calendar`
export const PHYSICIAN_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const PHYSICIAN_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const PHYSICIAN_DASHBOARD_ORDERS_INDEX = `${key}.ordersIndex`
export const PHYSICIAN_DASHBOARD_ORDER_DETAIL = `${key}.orderDetail`
export const PHYSICIAN_DASHBOARD_EOC_INDEX = `${key}.eocIndex`
export const PHYSICIAN_DASHBOARD_APP_SCHEDULING_LIST = `${key}.appSchedulingList`
export const PHYSICIAN_DASHBOARD_CENSUS = `${key}.census`
export const PHYSICIAN_DASHBOARD_HIGH_RISK_HUDDLE = `${key}.highRiskHuddle`
export const PHYSICIAN_DASHBOARD_MY_LISTS = `${key}.myLists`
export const PHYSICIAN_DASHBOARD_GRADUATION = `${key}.graduation`
export const PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS = `${key}.encounterReviews`
export const PHYSICIAN_DASHBOARD_ON_CALL = `${key}.onCall`
export const PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS = `${key}.patientOutlierReviews`
export const PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS = `${key}.patientOutlierFinalDecisions`
export const PHYSICIAN_DASHBOARD_LLOS = `${key}.llos`
export const PHYSICIAN_DASHBOARD_USER_TIMELINE = `${key}.userTimeline`

export const PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_ROUTES = [
  PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS,
  PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS,
]

export default Router([
  Route(PHYSICIAN_DASHBOARD_HOME),
  Route(PHYSICIAN_DASHBOARD_CALENDAR, '/:userId?/calendar'),
  Route(PHYSICIAN_DASHBOARD_TASKS_BY_USER, '/tasks'),
  Route(PHYSICIAN_DASHBOARD_TASK_DETAIL, '/tasks/:taskId'),
  Route(PHYSICIAN_DASHBOARD_ORDERS_INDEX, '/orders'),
  Route(PHYSICIAN_DASHBOARD_ORDER_DETAIL, '/orders/:orderId'),
  Route(PHYSICIAN_DASHBOARD_EOC_INDEX, '/episodes-of-care'),
  Route(PHYSICIAN_DASHBOARD_APP_SCHEDULING_LIST, '/app-list'),
  Route(PHYSICIAN_DASHBOARD_CENSUS, '/census'),
  Route(PHYSICIAN_DASHBOARD_HIGH_RISK_HUDDLE, '/high-risk-huddle'),
  Route(PHYSICIAN_DASHBOARD_MY_LISTS, '/my-lists'),
  Route(PHYSICIAN_DASHBOARD_GRADUATION, '/graduation'),
  Route(
    PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS,
    '/encounter-reviews/:encounterReviewId?'
  ),
  Route(PHYSICIAN_DASHBOARD_ON_CALL, '/on_call'),
  Route(
    PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS,
    '/patient-outlier-reviews/:patientOutlierReviewId?'
  ),
  Route(
    PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS,
    '/patient-outlier-final-decisions/:patientOutlierFinalDecisionId?'
  ),
  Route(PHYSICIAN_DASHBOARD_LLOS, '/:userId?/llos'),
  Route(PHYSICIAN_DASHBOARD_USER_TIMELINE, '/:userId?/user-timeline'),
])
