import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, creator, get, payload, type } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatZipcode } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import { format, getYear, parse } from 'date-fns'
import { getRoot } from './common/shared'
import { MemberAddress, TransportationHospice } from './interfaces'

const key = 'patientInfo'

export const PATIENT_INFO_CLEARED = type(key, 'PATIENT_INFO_CLEARED')

export const patientInfoCleared = creator(PATIENT_INFO_CLEARED)

export const Limited = Record({
  benefitName: null,
  benefitLimit: null,
  hasHitlimit: null,
  hasHitHalfway: null,
  remaining: null,
  taken: null,
})

export const Address = Record({
  memberAddress1: null,
  memberAddress2: null,
  memberCity: null,
  memberState: null,
  memberZipCode: null,
  memberCounty: null,
  memberAddressType: null,
  memberContactNumber: null,
  memberEmail: null,
})

export const PcpDemographics = Record({
  name: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zipcode: null,
  county: null,
  phone: null,
  phoneExtension: null,
})

export const PatientInfo = Record({
  id: null,
  subscriberId: null,
  status: null,
  firstName: null,
  lastName: null,
  middleName: null,
  title: null,
  dateOfBirth: null,
  gender: null,
  language: null,
  dod: null,
  classPlanId: null,
  productDesc: null,
  poaLastName: null,
  poaFirstName: null,
  poaPhone: null,
  poaFax: null,
  pcpName: null,
  pcpPhone: null,
  pcpFax: null,
  mailAddress: Address,
  homeAddress: Address,
  pcpDetails: PcpDemographics,
  notes: List(),
  hospiceList: List(),
  hospiceIndicator: null,
  pcpAddress: null,
  pcpState: null,
  pcpCity: null,
  pcpZip: null,
  timezone: null,
  authorizationNumber: null,
})

export const Note = Record({
  createdAt: null,
  createdBy: null,
  note: null,
})

export const HospiceDetails = Record({
  alerts: null,
  alertEffectiveDate: null,
  alertTermDate: null,
})

const transformAddress = (address: MemberAddress) =>
  Address({
    memberAddress1: address.memberAddress1,
    memberAddress2: address.memberAddress2,
    memberCity: address.memberCity,
    memberState: address.memberState,
    memberZipCode: formatZipcode(address.memberZipCode),
    memberCounty: address.memberCounty,
    memberAddressType: address.memberAddressType,
    memberContactNumber: address.memberContactNumber,
    memberEmail: address.memberEmail,
  })

const transformPcpDetails = (pcpDetails: any) => {
  const pcpAddress = pcpDetails ? pcpDetails.memberPCPAdress[0] : null
  return PcpDemographics({
    name: pcpDetails?.providerName,
    address1: pcpAddress?.pcpAddress1,
    address2: pcpAddress?.pcpAddress2,
    city: pcpAddress?.pcpCity,
    state: pcpAddress?.pcpState,
    zipcode: formatZipcode(pcpAddress?.pcpZip),
    county: pcpAddress?.pcpCounty,
    phone: pcpAddress?.pcpContactNumber,
    phoneExtension: pcpAddress?.pcpContactNumberExt,
  })
}

const transformHospices = (hospices: any) =>
  Array.isArray(hospices) ? List(hospices.map(HospiceDetails)) : List()

const filterMemberAddress = (
  addressList: [MemberAddress],
  memberAddressType: string
) =>
  addressList && Array.isArray(addressList)
    ? (addressList.find(
        a => a.memberAddressType === memberAddressType
      ) as MemberAddress)
    : <MemberAddress>{}

const filterHospices = (alertList: [TransportationHospice]) =>
  alertList && Array.isArray(alertList)
    ? alertList.filter(a => a.alerts === 'Hospice Patient')
    : []

export const transformPatient = (patient: any) => {
  const memberContact = patient.memberInfo.memberContact
  const pcpDetail = patient.memberInfo.memberCurrentPcpDetails
  const pcpAddressDetails =
    pcpDetail.length > 0 ? pcpDetail[0].memberPCPAdress : {}
  const {
    memberRelationLastName = '',
    memberRelationFirstName = '',
    memberRelationContactNumber = '',
  } = memberContact.length > 0 ? memberContact[0] : {}

  const { providerName = '' } =
    pcpDetail && pcpDetail.length > 0 ? pcpDetail[0] : {}

  const { pcpContactNumber = '' } =
    pcpAddressDetails && pcpAddressDetails.length > 0
      ? pcpAddressDetails[0]
      : {}

  const { pcpFaxNumber = '' } =
    pcpAddressDetails && pcpAddressDetails.length > 0
      ? pcpAddressDetails[0]
      : {}

  const memberAddress: [MemberAddress] = patient.memberInfo?.memberAddress
  const hospices: TransportationHospice[] = filterHospices(
    patient.memberInfo?.memberAlerts
  )

  return PatientInfo({
    id: patient.memberInfo?.patientId,
    subscriberId: patient.memberInfo?.memberId,
    status: patient.memberInfo?.active ? 'Active' : 'InActive',
    firstName: patient.memberInfo?.firstName,
    lastName: patient.memberInfo?.lastName,
    middleName: patient.memberInfo?.middleName,
    title: patient.memberInfo?.title,
    dateOfBirth: format(
      parse(
        patient.memberInfo?.memberDateOfBirth,
        'yyyy-MM-dd HH:mm:ss.SSS',
        new Date()
      ),
      'MM/dd/yyyy'
    ),
    gender: patient.memberInfo?.memberGender,
    language: patient.memberInfo?.memberLanguage,
    dod: patient.memberInfo?.death
      ? format(
          parse(
            patient.memberInfo?.death,
            'yyyy-MM-dd HH:mm:ss.SSS',
            new Date()
          ),
          'MM/dd/yyyy'
        )
      : '',
    classPlanId: patient.memberInfo?.memberActiveHealthPlan[0]?.memberPlanId,
    productDesc: patient.memberInfo?.memberActiveHealthPlan[0]?.productDesc,
    poaLastName: memberRelationLastName,
    poaFirstName: memberRelationFirstName,
    poaPhone: memberRelationContactNumber,
    poaFax: memberRelationContactNumber,
    pcpName: providerName,
    pcpPhone: pcpContactNumber,
    pcpFax: pcpFaxNumber,
    mailAddress: transformAddress(
      filterMemberAddress(memberAddress, 'Mail Address')
    ),
    homeAddress: transformAddress(
      filterMemberAddress(memberAddress, 'Home Address')
    ),
    pcpDetails: transformPcpDetails(
      patient.memberInfo?.memberCurrentPcpDetails[0]
    ),
    hospiceList: transformHospices(hospices),
    hospiceIndicator: hospices && hospices.length ? 'Y' : 'N',
    timezone: patient.memberInfo?.timezone,
    authorizationNumber: patient.memberInfo?.authorizationNumber,
  })
}

export const fetchPatientDemographics = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_DEMOGRAPHICS',
  requestParams: ['id'],
  operation: (id: string) =>
    AspireAPI.get(
      `transportation/patient_info/${id}?benefitYear=${getYear(new Date())}`
    ),
  transform: transformPatient,
  messages: {
    failed: 'There was an issue fetching the patient info panel',
  },
})

export const patientInfo = createReducer(key, PatientInfo(), {
  [fetchPatientDemographics.SUCCEEDED]: pipe(action, payload),
  [PATIENT_INFO_CLEARED]: () => PatientInfo(),
})

export const getPatientInfo = pipe(getRoot, get(key))

export const getSelectedPatientId = pipe(getPatientInfo, get('id'))
