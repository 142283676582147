import { combineEpics } from 'redux-observable'
import appEntered from './epics/appEntered'
import downloadDraftProjectCensus from './epics/downloadDraftProjectCensus'
import downloadRequested from './epics/downloadRequested'
import draftPolygonDeleted from './epics/draftPolygonDeleted'
import draftPolygonPublished from './epics/draftPolygonPublished'
import draftPolygonSaved from './epics/draftPolygonSaved'
import draftProjectDeleted from './epics/draftProjectDeleted'
import draftProjectSaved from './epics/draftProjectSaved'
import emrStatusSelected from './epics/emrStatusSelected'
import {
  patientStatusFilterSelected,
  searchButtonClicked,
} from './epics/filteredPatientsRequested'
import marketSelected from './epics/marketSelected'
import mmStatusSelected from './epics/mmStatusSelected'
import odsLayer from './epics/odsLayer'
import polygonAreaSelected from './epics/polygonAreaSelected'
import projectSelected from './epics/projectSelected'
import zips from './epics/zips'

export default combineEpics(
  appEntered,
  downloadDraftProjectCensus,
  downloadRequested,
  draftPolygonDeleted,
  draftPolygonPublished,
  draftPolygonSaved,
  draftProjectDeleted,
  draftProjectSaved,
  emrStatusSelected,
  marketSelected,
  mmStatusSelected,
  odsLayer,
  patientStatusFilterSelected,
  polygonAreaSelected,
  projectSelected,
  searchButtonClicked,
  zips
)
