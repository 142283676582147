import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { createListener, createPropsToOptions } from './objectHelpers'
import withGoogle from './withGoogle'
import withMap from './withMap'

const propsToOptions = createPropsToOptions(props => ({
  drawingMode: props.drawingMode,
  drawingControl: props.drawingControl,
  drawingControlOptions: props.drawingControlOptions,
  circleOptions: props.circleOptions,
  markerOptions: props.markerOptions,
  polygonOptions: props.polygonOptions,
  polylineOptions: props.polylineOptions,
  rectangleOptions: props.rectangleOptions,
}))

class Drawing extends React.PureComponent {
  constructor(props) {
    super(props)

    const options = propsToOptions(props)
    const drawingManager = new props.google.maps.drawing.DrawingManager(options)

    drawingManager.setMap(props.map)
    drawingManager.addListener(
      'overlaycomplete',
      compose(createListener(this, 'onOverlayComplete'), this.onOverlayComplete)
    )
    drawingManager.addListener(
      'circlecomplete',
      compose(createListener(this, 'onCircleComplete'), this.onDrawingComplete)
    )
    drawingManager.addListener(
      'markercomplete',
      compose(createListener(this, 'onMarkerComplete'), this.onDrawingComplete)
    )
    drawingManager.addListener(
      'polygoncomplete',
      compose(createListener(this, 'onPolygonComplete'), this.onDrawingComplete)
    )
    drawingManager.addListener(
      'polylinecomplete',
      compose(
        createListener(this, 'onPolylineComplete'),
        this.onDrawingComplete
      )
    )
    drawingManager.addListener(
      'rectanglecomplete',
      compose(
        createListener(this, 'onRectangleComplete'),
        this.onDrawingComplete
      )
    )

    this.state = { drawingManager }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.drawingMode !== this.props.drawingMode ||
      prevProps.drawingControl !== this.props.drawingControl ||
      prevProps.drawingControlOptions !== this.props.drawingControlOptions ||
      prevProps.circleOptions !== this.props.circleOptions ||
      prevProps.markerOptions !== this.props.markerOptions ||
      prevProps.polygonOptions !== this.props.polygonOptions ||
      prevProps.polylineOptions !== this.props.polylineOptions ||
      prevProps.rectangleOptions !== this.props.rectangleOptions
    ) {
      this.state.drawingManager.setOptions(
        propsToOptions(this.props, prevProps)
      )
    }
  }

  componentWillUnmount() {
    this.state.drawingManager.setMap(null)
    this.props.google.maps.event.clearInstanceListeners(
      this.state.drawingManager
    )
  }

  onOverlayComplete = event => {
    event.overlay.setMap(null)
    return event
  }

  onDrawingComplete = overlay => {
    overlay.setMap(null)
    return overlay
  }

  render() {
    return null
  }
}

Drawing.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object,
  drawingMode: PropTypes.oneOf([
    'marker',
    'polygon',
    'polyline',
    'rectangle',
    'circle',
    null,
  ]),
  drawingControl: PropTypes.bool,
  drawingControlOptions: PropTypes.object,
  circleOptions: PropTypes.object,
  markerOptions: PropTypes.object,
  polygonOptions: PropTypes.object,
  polylineOptions: PropTypes.object,
  rectangleOptions: PropTypes.object,
  onOverlayComplete: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onCircleComplete: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onMarkerComplete: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onPolygonComplete: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onPolylineComplete: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onRectangleComplete: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
}

export default compose(withMap, withGoogle)(Drawing)
