// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getUserQuery } from './userQuery'

const SEARCH_WORD = 'searchWord'

const creator = scopedCreator(rootKey)
export const searchWordChanged = creator('SEARCH_WORD_CHANGED', ['searchWord'])

export default createReducer(SEARCH_WORD, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [searchWordChanged]: (_state: any, { payload: { searchWord } }: any) =>
    searchWord,
})

export const getSearchWord = pipe(getUserQuery, get(SEARCH_WORD))
