import React from 'react'
import { connect } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import Results from '../../components/Results'
import { fetchPhysicians, getPhysiciansArr } from '../../data/physicians'

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'practice', title: 'Practice Name' },
  { name: 'specialty', title: 'Specialty' },
  { name: 'title', title: 'Title' },
  { name: 'phone', title: 'Phone' },
  { name: 'fax', title: 'Fax' },
  { name: 'email', title: 'Email' },
]
const columnExtensions = [
  { columnName: 'specialty', width: 200 },
  { columnName: 'title', width: 200 },
  { columnName: 'phone', width: 150 },
  { columnName: 'fax', width: 150 },
  { columnName: 'email', width: 250 },
]
const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

const PhysicianLookup = ({
  includeWrapper,
  rows,
  fetchPhysicians,
  onSelect,
  rowActions,
  rowActionsCount,
}) => (
  <Results
    includeWrapper={includeWrapper}
    searchLabel="Search Physicians"
    columns={columns}
    columnExtensions={columnExtensions}
    defaultSorting={defaultSorting}
    rows={rows}
    onSearch={fetchPhysicians}
    onSelect={onSelect}
    rowActions={rowActions}
    rowActionsCount={rowActionsCount}
  />
)

PhysicianLookup.propTypes = {
  includeWrapper: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  fetchPhysicians: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
}

export default connect(
  state => ({
    rows: getPhysiciansArr(state),
  }),
  { fetchPhysicians: fetchPhysicians.requested }
)(PhysicianLookup)
