import { Map, Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/re... Remove this comment to see the full error message
import OrcAPI from '~/resources/orc'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// RECORDS
export const AdmissionType = Record({
  id: null,
  name: null,
  description: null,
})

// CONSTANTS
export const ADMISSION_TYPES_STORED = 'admissionTypes/ADMISSION_TYPES_STORED'
export const ADMISSION_TYPE_FETCH_FAILED =
  'admissionTypes/ADMISSION_TYPE_FETCH_FAILED'

// ACTION CREATORS
export const admissionTypesStored = (admissionTypes: any) => ({
  type: ADMISSION_TYPES_STORED,
  admissionTypes,
})
export const admissionTypeFetchFailed = () => ({
  type: ADMISSION_TYPE_FETCH_FAILED,
})

// KEY
export const key = 'admissionTypes'

// REDUCER
export default createReducer(key, Map(), {
  [ADMISSION_TYPES_STORED]: (_state: any, { admissionTypes }: any) =>
    admissionTypes,
})

const mapData = (data: any) =>
  Map(
    data.map((d: any) => [
      d.admissionTypeId,
      AdmissionType({
        id: d.admissionTypeId,
        name: d.admissionTypeName,
        description: d.admissionTypeDescription,
      }),
    ])
  )

// API
export const fetchAdmissionTypes = () =>
  OrcAPI.get('admissions/admission_types').then(mapData)

// SELECTORS
export const getAllAdmissionTypes = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
export const getAdmissionTypeById = (state: any, id: any) =>
  getAllAdmissionTypes(state).get(id)
