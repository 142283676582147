import { ofType } from 'redux-observable'
import { filter, mapTo, pluck } from 'rxjs/operators'
import {
  differentTerritorySnackbarOpened,
  savePatientAddress,
} from '../data/patientInfo'

export default action$ =>
  action$.pipe(
    ofType(savePatientAddress.SUCCEEDED),
    pluck('payload', 'sameTerritory'),
    filter(sameTerritory => !sameTerritory),
    mapTo(differentTerritorySnackbarOpened())
  )
