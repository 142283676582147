import { Map, fromJS } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// CONSTANTS
export const APP_DATA_STORED = 'appData/APP_DATA_STORED'
export const FILTER_MAKE_DEFAULT_REQUESTED =
  'appData/FILTER_MAKE_DEFAULT_REQUESTED'

// ACTION CREATORS
export const appDataStored = (userId: any, key: any, appData: any) => ({
  type: APP_DATA_STORED,
  userId,
  key,
  appData,
})
export const filterMakeDefaultRequested = (filter: any) => ({
  type: FILTER_MAKE_DEFAULT_REQUESTED,
  filter,
})

// KEY
export const key = 'appData'

// REDUCER
export default createReducer(key, Map(), {
  [APP_DATA_STORED]: (state: any, { userId, key, appData = null }: any) =>
    state.setIn([userId, key], appData),
})

// API
export const fetchAppData = (userId: any, appKey: any) =>
  AspireAPI.get(`v1/user/${userId}/app/${appKey}`).then(fromJS)

export const saveAppData = (userId: any, appKey: any, appData: any) =>
  AspireAPI.put(`v1/user/${userId}/app/${appKey}`, appData.toJS()).then(
    () => appData
  )

// SELECTORS
export const getAppData = (state: any, userId: any, appKey: any) =>
  getReferralManagement(state).getIn([key, userId, appKey])
