import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Box, Icon, Link, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  boxRightMargin: { marginRight: theme.spacing(1) },
  boxLeftMargin: { marginBottom: theme.spacing(1) },
  strikeThrough: { textDecoration: 'line-through' },
}))

interface PrimaryEmailPropTypes {
  primaryEmail: PropTypes.object.isRequired
}

export const PrimaryEmail = ({ primaryEmail }: PrimaryEmailPropTypes) => {
  const classes = useStyles()

  return (
    <>
      {primaryEmail ? (
        <Box clone={true} flex="1" display="flex" alignItems="center">
          <Typography>
            <Box clone={true} className={classes.boxRightMargin}>
              <Icon fontSize="inherit"> email </Icon>
            </Box>
            <Link
              href={'mailto:' + primaryEmail.email}
              color="inherit"
              className={primaryEmail.active ? '' : classes.strikeThrough}
            >
              {primaryEmail.email}
            </Link>
          </Typography>
        </Box>
      ) : (
        <Box flex="1 1 0%">&nbsp;</Box>
      )}
    </>
  )
}
