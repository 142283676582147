import React from 'react'
import { connect } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { LTC, deleteLTC } from '../data/ltcs'

class DeleteLTC extends React.PureComponent {
  onDelete = () => {
    const { row, deleteLTC, onClose } = this.props

    deleteLTC(row.id)
    onClose()
  }

  render() {
    const { open, row, onClose } = this.props
    const message = `Are you sure you want to delete ${row.facilityName} as a LTC admission?`

    return (
      <ActionDialog
        open={open}
        title="Delete LTC Admission"
        mode="delete"
        onDelete={this.onDelete}
        onClose={onClose}
      >
        <Typography>{message}</Typography>
      </ActionDialog>
    )
  }
}

DeleteLTC.propTypes = {
  open: PropTypes.bool.isRequired,
  row: PropTypes.record,
  deleteLTC: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DeleteLTC.defaultProps = {
  row: LTC(),
}

export default connect(null, {
  deleteLTC: deleteLTC.requested,
})(DeleteLTC)
