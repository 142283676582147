import { List } from 'immutable'
import { ofType } from 'redux-observable'
import { filter, map, pluck, withLatestFrom } from 'rxjs/operators'
import { getCarePlanProblems, problemSaved } from '~/features/problems/data'

// Remove from Assessment and Plan when invalidated
export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(problemSaved),
    pluck('payload', 'problem'),
    withLatestFrom(state$),
    map(([problem, state]: any) => [problem, getCarePlanProblems(state)]),
    filter(
      ([{ id, status }, problems]: any) =>
        problems.get(id).selected && status === 'invalid'
    ),
    map(([{ id }, problems]: any) => {
      const problem = problems.get(id).merge({
        goals: List(),
        selected: false,
        problemAssessmentNotes: null,
        actionAssessmentNotes: null,
      })

      return problemSaved(problem)
    })
  )
