import classNames from 'classnames'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { compose } from '~/utils/functionalHelpers'
import { withStyles, withTheme } from '@material-ui/core/styles'
import GeoMapContext from './GeoMapContext'
import createMapStyles from './createMapStyles'
import mapPropTypes from './mapPropTypes'
import { createListener, createPropsToOptions } from './objectHelpers'
import withGoogle from './withGoogle'

const propsToOptions = createPropsToOptions(props => ({
  center: props.center,
  zoom: props.zoom,
  disableDefaultUI: props.disableDefaultUI,
  zoomControl: props.zoomControl,
  gestureHandling: 'greedy',
}))

const styles = {
  container: {
    height: '100%',
    width: '100%',
  },
  map: {
    height: '100%',
    width: '100%',
  },
}

class GeoMap extends PureComponent {
  constructor(props) {
    super(props)

    this.state = { map: null, bounds: Map() }
  }

  componentDidMount() {
    const options = {
      ...propsToOptions(this.props),
      styles: createMapStyles(this.props.theme),
    }

    const map = new this.props.google.maps.Map(this.mapElement, options)

    map.addListener('click', createListener(this, 'onClick'))
    map.addListener('rightclick', createListener(this, 'onRightClick'))
    map.addListener('bounds_changed', () => {
      !this.state.dragging && this.onBoundsChanged()
    })
    map.addListener('dragstart', this.startDrag)
    map.addListener('dragend', compose(this.endDrag, this.onBoundsChanged))

    this.setState({ map, dragging: false })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.zoomKey !== this.props.zoomKey &&
      this.state.bounds.size > 0
    ) {
      this.zoomMap()
    }

    if (
      this.props.zoomKey &&
      prevState.bounds.size === 0 &&
      this.state.bounds.size > 0
    ) {
      this.zoomMap()
    }

    if (prevProps.resizeKey !== this.props.resizeKey) {
      this.props.google.maps.event.trigger(this.state.map, 'resize')
    }

    if (
      prevProps.center !== this.props.center ||
      prevProps.zoom !== this.props.zoom ||
      prevProps.disableDefaultUI !== this.props.disableDefaultUI ||
      prevProps.zoomControl !== this.props.zoomControl
    ) {
      this.state.map.setOptions(propsToOptions(this.props, prevProps))
    }

    if (prevProps.theme !== this.props.theme) {
      this.state.map.setOptions({ styles: createMapStyles(this.props.theme) })
    }
  }

  componentWillUnmount() {
    this.props.google.maps.event.clearInstanceListeners(this.state.map)
  }

  zoomMap = () => {
    if (this.state.bounds.size > 0) {
      const mapBounds = new this.props.google.maps.LatLngBounds()

      this.state.bounds.forEach(bounds => {
        mapBounds.union(bounds)
      })
      this.state.map.fitBounds(mapBounds)
    }
  }

  startDrag = () => this.setState({ dragging: true })
  endDrag = () => this.setState({ dragging: false })

  setBounds = (key, newBounds) =>
    this.setState(({ bounds }) => ({ bounds: bounds.set(key, newBounds) }))
  deleteBounds = key =>
    this.setState(({ bounds }) => ({ bounds: bounds.delete(key) }))

  onBoundsChanged = () => {
    if (this.props.onBoundsChanged !== undefined) {
      this.props.onBoundsChanged(this.state.map.getBounds())
    }
  }

  render() {
    const { classes, className, children } = this.props
    const { map } = this.state

    return (
      <div className={classNames(classes.container, className)}>
        <div
          className={this.props.classes.map}
          ref={me => {
            this.mapElement = me
          }}
        />
        {map ? (
          <GeoMapContext.Provider
            value={{
              map,
              setBounds: this.setBounds,
              deleteBounds: this.deleteBounds,
            }}
          >
            {children}
          </GeoMapContext.Provider>
        ) : null}
      </div>
    )
  }
}

GeoMap.propTypes = {
  google: PropTypes.object,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  center: mapPropTypes.latLng,
  zoom: PropTypes.number,
  disableDefaultUI: PropTypes.bool,
  zoomControl: PropTypes.bool,
  zoomKey: PropTypes.any,
  resizeKey: PropTypes.any,
  onClick: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onRightClick: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onBoundsChanged: PropTypes.func,
}

export default compose(withGoogle, withTheme, withStyles(styles))(GeoMap)
