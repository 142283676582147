import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from '~/utils/propTypes'
import { createGetLocationLabels } from '../../../data/common/derived'
import PatientListMarker from './PatientListMarker'
import PatientSingleMarker from './PatientSingleMarker'

const PatientsMarker = ({ patients, current, ...props }) => {
  const getLocationLabels = useMemo(createGetLocationLabels)

  const labels = useSelector(state => getLocationLabels(state, patients))

  const patientList = useMemo(() => patients.toList(), [patients])

  // Choose highest priority label
  let label = ''
  if (labels.includes('A')) label = 'A'
  else if (labels.includes('B')) label = 'B'
  else if (!labels.isEmpty()) label = labels.first()

  return patients.size === 1 ? (
    <PatientSingleMarker
      {...props}
      patient={patientList.first()}
      current={current}
      label={label}
    />
  ) : (
    <PatientListMarker
      {...props}
      patients={patientList}
      current={current}
      label={label}
    />
  )
}

PatientsMarker.propTypes = {
  patients: PropTypes.map.isRequired,
  current: PropTypes.bool.isRequired,
}

export default React.memo(PatientsMarker)
