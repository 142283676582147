import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import { AddBenefitInputs, UpdateBenefitInputs } from './interfaces'

// KEY
const key = 'addBenefitDialog'

export const getDialogOpenValue = getIn([rootKey, key])
export const getDialogTypeValue = getIn([rootKey, 'benefitDialogType'])

// CREATORS
const creator = scopedCreator(key)

export const addBenefitDialogOpened = creator('BENEFIT_DIALOG_OPENED')
export const addBenefitDialogClosed = creator('BENEFIT_DIALOG_CLOSED')

export const editBenefitDialogType = creator('EDIT_BENEFIT_DIALOG_TYPE')
export const addBenefitDialogType = creator('ADD_BENEFIT_DIALOG_TYPE')

const transformErrorResponse = (error: any, isUpdate: boolean) => {
  const genericSaveError = !isUpdate
    ? 'Could not add benefit'
    : 'Could not update benefit'
  return error &&
    error.response &&
    error.response.data &&
    error.response.data.message
    ? error.response.data.message.contractYear[0]
    : genericSaveError
}

// REQUEST
export const addBenefit = Request({
  typePrefix: key,
  typeBase: 'ADD_BENEFIT',
  requestParams: ['benefitInputs'],
  operation: (benefitInputs: AddBenefitInputs) =>
    AspireAPI.post(`transportation/benefits`, { ...benefitInputs }),
  messages: {
    succeeded: 'Benefit added successfully',
    failed: (error: any) => transformErrorResponse(error, false),
  },
})

export const updateBenefit = Request({
  typePrefix: key,
  typeBase: 'UPDATE_BENEFIT',
  requestParams: ['id', 'benefitInputs'],
  operation: (id: string, benefitInputs: UpdateBenefitInputs) =>
    AspireAPI.put(`transportation/benefits/${id}`, { ...benefitInputs }),
  messages: {
    succeeded: 'Benefit updated successfully',
    failed: (error: any) => transformErrorResponse(error, true),
  },
})

// REDUCER
export const benefitDialog = createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [addBenefitDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [addBenefitDialogOpened]: () => true,
  [addBenefit.SUCCEEDED]: () => false,
  [updateBenefit.SUCCEEDED]: () => false,
})

export const benefitDialogType = createReducer('benefitDialogType', 'Edit', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [editBenefitDialogType]: () => 'Edit',
  // @ts-expect-error can we deal with the toString being automatically called?
  [addBenefitDialogType]: () => 'Add',
})
