import { combineReducers } from 'redux-immutable'
import hraScreening from './data/hraScreening'
import screenings from './data/screenings'
import key from './key'

const reducer = combineReducers({
  [screenings.key]: screenings,
  [hraScreening.key]: hraScreening,
})

reducer.key = key

export default reducer
