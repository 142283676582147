export { fetchPatientTimeline, patientTimelineCleared } from './patientEvents'
export { getPatientId } from './patientId'

import { flatten } from 'lodash/fp'
import moment from 'moment'
import { combineReducers } from 'redux-immutable'
import { createSelector } from 'reselect'
import { getPatientInfo } from '~/features/patientInfo'
import { get, getIn } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import {
  CATEGORIES_IN_SWIMLANE,
  ROLES_IN_ENCOUNTER_TYPES,
  SINCE_LAST_VISIT,
} from '../constants'
import key from '../key'
import { getTimeframes } from '../utils/date'
import { isCallClinical } from '../utils/event'
import dates from './dates'
import encountersFilter from './encountersFilter'
import patientEvents from './patientEvents'
import patientId from './patientId'
import swimlane from './swimlane'
import visibilityFilter from './visibilityFilter'

// Selector
export const getDates = getIn([key, dates.key])
export const getCompressedDates = getIn([key, dates.key, 'compressed'])
export const getAllDates = getIn([key, dates.key, 'all'])
export const getLastVisitDate = compose(
  get('lastVisitDate'),
  get('scheduling'),
  getPatientInfo
)
export const patientEventsScope = getIn([key, patientEvents.key])
export const flattenPatientEvents = map =>
  map
    .valueSeq()
    .flatten(true) // shallow flatten
    .filter(pe => pe.category)
    .toList()
    .sort((a, b) => moment(a.eventDate).diff(moment(b.eventDate)))
    .reverse()

export const getPatientEvents = pipe(patientEventsScope, flattenPatientEvents)
export const getSwimlane = getIn([key, swimlane.key])
export const getVisibilityFilter = getIn([key, visibilityFilter.key])
export const getEncountersFilter = getIn([key, encountersFilter.key])

// Returns filtered List of PatientEvents (currently by events since last visit and/or category)
const filterPatientEvents = (
  events,
  visibilityFilters,
  encounterFilters,
  lastVisitDate
) => {
  if (visibilityFilters.isEmpty()) {
    return events
  }

  const dateFiltered = visibilityFilters.has(SINCE_LAST_VISIT)
    ? events.filter(
        event => event.createdAt.format('YYYY-MM-DD') >= lastVisitDate
      )
    : events

  const categoriesToSearch = flatten(
    Object.keys(CATEGORIES_IN_SWIMLANE) // Swimlanes
      .filter(category => [...visibilityFilters.keys()].includes(category))
      .map(category => CATEGORIES_IN_SWIMLANE[category])
  )

  const encounterCategoriesToSearch = flatten(
    Object.keys(ROLES_IN_ENCOUNTER_TYPES) // Swimlanes
      .filter(role => [...encounterFilters.keys()].includes(role))
      .map(role => ROLES_IN_ENCOUNTER_TYPES[role])
  )

  const encounterRoles = flatten(Object.values(ROLES_IN_ENCOUNTER_TYPES))

  const categoryMatches = ({
    category,
    createdByRole,
    details: { callIntent },
  }) => {
    if (category === 'call') {
      const callType = isCallClinical(callIntent, createdByRole)
        ? 'calls_clinical'
        : 'calls_other'

      return categoriesToSearch.includes(callType)
    } else if (category !== 'encounter') {
      return categoriesToSearch.includes(category)
    } else {
      return false
    }
  }

  const encounterTypeMatches = ({ category, details: { ownerRole } }) =>
    category === 'encounter' &&
    (encounterCategoriesToSearch.includes(ownerRole) ||
      (!encounterRoles.includes(ownerRole) &&
        encounterFilters.has('other_encounters')))

  return (visibilityFilters.has(SINCE_LAST_VISIT) &&
    visibilityFilters.count() > 1) ||
    !(visibilityFilters.has(SINCE_LAST_VISIT) && visibilityFilters.count() > 0)
    ? dateFiltered.filter(
        event => categoryMatches(event) || encounterTypeMatches(event)
      )
    : dateFiltered
}

export const getFilteredPatientEvents = createSelector(
  getPatientEvents,
  getVisibilityFilter,
  getEncountersFilter,
  getLastVisitDate,
  filterPatientEvents
)

// Returns either all dates or compressed dates by timeframe depending on value of `compressed` in swimlane.
const getCompressedOrAllDatesByTimeframe = (swimlane, dates) => {
  const timeframes = swimlane.compressed
    ? getTimeframes(dates.compressed, swimlane.timeframe)
    : getTimeframes(dates.all, swimlane.timeframe)

  return swimlane.reversed ? timeframes.reverse() : timeframes
}

export const getFilteredDates = createSelector(
  getSwimlane,
  getDates,
  getCompressedOrAllDatesByTimeframe
)

// Reducer
const patientTimeline = combineReducers({
  [dates.key]: dates,
  [encountersFilter.key]: encountersFilter,
  [patientEvents.key]: patientEvents,
  [patientId.key]: patientId,
  [swimlane.key]: swimlane,
  [visibilityFilter.key]: visibilityFilter,
})

patientTimeline.key = key

export default patientTimeline
