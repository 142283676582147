import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getEventTypes } from './root'

const REQUIRED_RESCHEDULE_REASON = 'requiredRescheduleReason'

const creator = scopedCreator(rootKey)
export const requiredRescheduleReasonSet = creator(
  'REQUIRED_RESCHEDULE_REASON_SET',
  ['required']
)

export default createReducer(REQUIRED_RESCHEDULE_REASON, false, {
  [requiredRescheduleReasonSet]: (_state, { payload: { required } }) =>
    required,
})

export const getRequiredRescheduleReason = pipe(
  getEventTypes,
  get(REQUIRED_RESCHEDULE_REASON)
)
