import React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Avatar,
  Icon,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import PrimaryText from './PrimaryText'
import SecondaryText from './SecondaryText'

const PatientListItem = ({ patient }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <Icon>person</Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<PrimaryText patient={patient} />}
        secondary={<SecondaryText patient={patient} />}
      />
    </ListItem>
  )
}

PatientListItem.propTypes = {
  patient: PropTypes.record.isRequired,
}

export default React.memo(PatientListItem)
