import { changedTo } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
import { fetchBatchDetails } from '../data/batchDetails'
import { CODER_DASHBOARD_BATCH_DETAILS } from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(changedTo(CODER_DASHBOARD_BATCH_DETAILS)),
    pluck('payload', 'params', 'batchId'),
    map((batchId: string) => fetchBatchDetails.requested(batchId))
  )
