import { List, Record } from 'immutable'
import { Aspire } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
import Request, { createFailedMessage } from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot, transformCareTeamMembers } from './common'

const CREATE_EPISODE_OF_CARE_DIALOG = 'createEpisodeOfCareDialog'

const creator = scopedCreator(rootKey)
export const dialogClosed = creator('DIALOG_CLOSED', false)
export const dialogOpened = creator('DIALOG_OPENED', ['patientId'])
export const submitted = creator('SUBMITTED', false)
export const updated = creator('UPDATED', false)

export const Dialog = Record({
  open: false,
  patientId: null,
  patientName: null,
  careTeamMembers: List(),
  aspire: Aspire(),
})

export const createEpisodeOfCare = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_EPISODE_OF_CARE',
  requestParams: ['data'],
  operation: data => AspireAPI.post('episodes_of_care', data),
  messages: {
    failed: createFailedMessage('Failed to create episode of care'),
    succeeded: 'Episode of care successfully created',
  },
})

export const fetchPatientConfig = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_CONFIG',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`episodes_of_care/config`, { params: { patientId } }),
  transform: patient =>
    Dialog({
      open: true,
      patientId: patient.id,
      patientName: patient.demographics.name,
      careTeamMembers: transformCareTeamMembers(patient),
      aspire: Aspire(patient.aspire),
    }),
  messages: {
    failed: 'Failed to fetch episode of care config data',
  },
})

const initState = Dialog()
export default createReducer(CREATE_EPISODE_OF_CARE_DIALOG, initState, {
  [dialogClosed]: () => initState,
  [createEpisodeOfCare.SUCCEEDED]: () => initState,
  [fetchPatientConfig.SUCCEEDED]: (state, { payload }) => payload,
})

export const getDialogState = pipe(getRoot, get(CREATE_EPISODE_OF_CARE_DIALOG))
