import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  icon: {
    marginRight: spacing(1),
  },
})

const MdtRefillRequests = ({
  classes,
  color,
  mdtRefillCleared,
  mdtRefillRequestsCount,
  mdtSingleSignOn,
  prescriberId,
  requestRefillCount,
  variant,
}) => {
  useEffect(() => {
    prescriberId && requestRefillCount(prescriberId)
  }, [prescriberId])

  const signOn = useCallback(() => {
    mdtSingleSignOn({
      page: 'refillreq',
      prescriberId,
    })
    mdtRefillCleared()
  }, [prescriberId])

  const hideBage = useMemo(
    () => [null, undefined].includes(mdtRefillRequestsCount),
    [mdtRefillRequestsCount]
  )

  return (
    <Badge
      badgeContent={mdtRefillRequestsCount}
      invisible={hideBage}
      color="secondary"
    >
      <Button variant={variant} color={color} size={'small'} onClick={signOn}>
        <Icon classes={{ root: classes.icon }}>local_pharmacy</Icon>
        Rx Refills
      </Button>
    </Badge>
  )
}

MdtRefillRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  mdtRefillCleared: PropTypes.func,
  mdtRefillRequestsCount: PropTypes.number,
  mdtSingleSignOn: PropTypes.func,
  prescriberId: PropTypes.string,
  requestRefillCount: PropTypes.func,
  variant: PropTypes.string,
}

MdtRefillRequests.defaultProps = {
  color: 'primary',
  variant: 'contained',
}

export default withStyles(styles)(MdtRefillRequests)
