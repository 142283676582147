import { Record } from 'immutable'
import { get } from '~/utils/data'
import key from '../../key'

export const Patient = Record({
  id: null,
  fullName: null,
  score: null,
  address: null,
  appId: null,
  appName: null,
  birthdate: null,
  city: null,
  contactId: null,
  firstName: null,
  internalSubStatus: null,
  lastName: null,
  market: null,
  phone: null,
  postalcode: null,
  primaryContract: null,
  primaryInsuranceCarrier: null,
  programEnrolled: null,
  state: null,
  stateCode: null,
  status: null,
  street: null,
  subStatus: null,
})

export const APP = Record({
  id: null,
  fullName: null,
  score: null,
  npi: null,
  market: null,
  licenseNumber: null,
  isActive: false,
  deaNumber: null,
})

export const Event = Record({
  id: null,
  title: null,
  score: null,
  address: null,
  appId: null,
  appName: null,
  birthdate: null,
  city: null,
  contactId: null,
  firstName: null,
  internalSubStatus: null,
  lastName: null,
  market: null,
  patientId: null,
  phone: null,
  postalcode: null,
  primaryContract: null,
  primaryInsuranceCarrier: null,
  programEnrolled: null,
  startDatetime: null,
  state: null,
  stateCode: null,
  status: null,
  street: null,
  subStatus: null,
})

export const transformPatient = patient =>
  Patient({
    ...patient.tags,
    id: patient.id,
    fullName: patient.title,
    score: patient.total_score,
  })

export const transformAPP = app =>
  APP({
    ...app.tags,
    id: app.id,
    fullName: app.title,
    score: app.total_score,
  })

export const transformEvent = event =>
  Event({
    ...event.tags,
    id: event.id,
    title: event.title,
    score: event.total_score,
  })

export const getRoot = get(key)
