import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { ActionDialog } from '~/components/dialogs'
import { Checkbox } from '~/components/formFields'
import { capitalizeFirst } from '~/utils/stringManipulation'
import { Icon, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

/*
This component is a copy of the Patient Record Info component
This was ported here for use in the care teams feature because I wanted
a visually consistent UI across current/future care teams.
*/

const flexDirections = {
  top: 'column',
  left: 'row',
}

const getContent = ({ content, formatContent, input = {} }) =>
  formatContent(
    Object.prototype.hasOwnProperty.call(input, 'value') ? input.value : content
  ) || ''

const filterProps = ({ ...props }) => {
  delete props.classes
  delete props.label
  delete props.editable
  delete props.inputComponent
  delete props.clearable
  delete props.content
  delete props.formatContent
  delete props.labelFlex
  delete props.valueFlex
  delete props.labelPosition
  delete props.isAuthorized
  delete props.dispatch

  return props
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  info: {
    display: 'flex',
    flexDirection: ({ labelPosition }) => flexDirections[labelPosition],
    padding: [6, 0, 3],
    borderBottom: {
      style: 'solid',
      width: 1,
      color: palette.divider,
    },
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  value: {
    display: 'flex',
    flex: ({ valueFlex }) => valueFlex,
    justifyContent: 'space-between',
  },
  label: {
    flex: ({ labelFlex }) => labelFlex,
    color: palette.contentText.primaryLabel,
    marginRight: ({ labelPosition }) =>
      labelPosition === 'left' ? spacing(1) : 'initial',
    lineHeight: ({ labelPosition }) =>
      labelPosition === 'left' ? `${spacing(3)}px` : 'initial',
  },
  readValue: {
    minHeight: spacing(3),
    boxSizing: 'border-box',
    paddingTop: spacing(0.25),
    paddingBottom: spacing(0.25),
    width: '100%',
  },
  colorBool: {
    color: ({ input = {} }) =>
      typeof input.value === 'boolean'
        ? input.value
          ? palette.severity.success.dynamicHighContrast
          : palette.severity.error.dynamicHighContrast
        : '',
  },
  button: {
    padding: spacing(0.5),
  },
  icon: {
    color: palette.secondary.main,
    fontSize: '0.75em',
  },
  notesLabel: {
    margin: [3, 0],
  },
  dialogBox: {
    minHeight: 210,
  },
}))

const GenericInfo = props => {
  const {
    className: sourceStyles,
    editDialog: EditDialogComponent,
    editMode,
    input,
    inputComponent: Input,
    isAuthorized,
    label,
    meta,
    onClear,
    onEdit,
  } = props

  const classes = useStyles(props)

  const [editing, setEditing] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [editValue, setEditValue] = useState(null)

  const clearable = isAuthorized && props.clearable
  const editable = isAuthorized && props.editable
  const editDialogDisplayed = Boolean(editing && EditDialogComponent)
  const editComponentDisplayed = Boolean(editing && Input)

  const editValueUpdate = useCallback(v => {
    setEditValue(v)
  }, [])

  const onCancelEdit = useCallback(() => {
    setEditValue(null)
    setEditing(false)
    setDisabled(false)
  }, [])

  const onEditClicked = useCallback(() => {
    setEditing(true)
  }, [])

  const onClearClicked = useCallback(() => {
    onClear && onClear()
  }, [])

  const onEditAction = useCallback(() => {
    onEdit && onEdit(editValue)
    setDisabled(true)
    onCancelEdit()
  }, [editValue, onCancelEdit])

  const onBlur = useCallback(
    (...args) => {
      onCancelEdit()
      if (input) input.onBlur(...args)
    },
    [input, onCancelEdit]
  )

  const inputProps = useMemo(() => {
    const { input, ...filteredProps } = filterProps(props)
    const isFullWidth = Input !== Checkbox

    return {
      ...filteredProps,
      ...(isFullWidth ? { fullWidth: true } : {}),
      autoFocus: true,
      input: {
        ...input,
        onBlur: onBlur,
      },
    }
  }, [props, onBlur])

  return (
    <div className={classes.info}>
      <Typography
        color={meta && meta.dirty ? 'secondary' : 'initial'}
        variant="body2"
        className={cx(classes.label, sourceStyles, {
          [classes.notesLabel]: label === 'Last Call Notes',
        })}
      >
        {label}
      </Typography>
      <div className={classes.value}>
        {editComponentDisplayed ? (
          <Input {...inputProps} />
        ) : (
          <Typography
            className={cx(classes.readValue, classes.colorBool)}
            onDoubleClick={onEditClicked}
            variant="body2"
          >
            {capitalizeFirst(getContent(props))}
          </Typography>
        )}
        {clearable && (
          <IconButton className={classes.button} onClick={onClearClicked}>
            <Icon className={classes.icon}>clear</Icon>
          </IconButton>
        )}
        {editable && (
          <IconButton className={classes.button} onClick={onEditClicked}>
            <Icon className={classes.icon}>edit</Icon>
          </IconButton>
        )}
      </div>
      {editDialogDisplayed && (
        <ActionDialog
          disableAction={disabled || !editValue}
          onConfirm={onEditAction}
          onCancel={onCancelEdit}
          onCreate={onEditAction}
          onAdd={onEditAction}
          onSave={onEditAction}
          onDelete={onEditAction}
          mode={editMode}
          fullWidth
          maxWidth="md"
          onClose={onCancelEdit}
          open={editDialogDisplayed}
          title={`Edit ${label}`}
        >
          <div className={classes.dialogBox}>
            <EditDialogComponent onEditValueChange={editValueUpdate} />
          </div>
        </ActionDialog>
      )}
    </div>
  )
}

GenericInfo.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func,
  isAuthorized: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  content: PropTypes.node,
  clearable: PropTypes.bool,
  editable: PropTypes.bool,
  meta: PropTypes.object,
  input: PropTypes.object,
  inputComponent: PropTypes.elementType,
  labelFlex: PropTypes.string,
  valueFlex: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left']),
  formatContent: PropTypes.func,
  editDialog: PropTypes.elementType,
  onEdit: PropTypes.func,
  onClear: PropTypes.func,
  editMode: PropTypes.oneOf([
    'confirm',
    'cancel',
    'create',
    'add',
    'save',
    'delete',
  ]),
}

GenericInfo.defaultProps = {
  editMode: 'confirm',
  clearable: false,
  editable: false,
  labelFlex: '1',
  valueFlex: '1',
  labelPosition: 'left',
  formatContent: content => content,
  isAuthorized: true, // Allow full use of component unless there is a reason to do otherwise
}

export default GenericInfo
