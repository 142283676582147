import { format, parse } from 'date-fns'
import { List, Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const key = 'caremoreAuthsData'

export const Authorizations = Record({
  id: null,
  authNumber: null,
  authDate: null,
  expirationDate: null,
  authType: null,
  authCategory: null,
  placeOfSvc: null,
  authStatus: null,
  referredFromName: null,
  referredToName: null,
  referredToSpecialty: null,
  diagnosisCodes: null,
  procedureCodes: null,
})

const transformAuthDetails = data => {
  return data && data.length ? prepareAuthDetails(data) : List()
}

const prepareAuthDetails = authDetails =>
  List(
    authDetails?.map(authDetail =>
      Authorizations({
        id: authDetail.authNumber,
        authNumber: authDetail.authNumber,
        authDate: authDetail.authDate
          ? format(
              parse(authDetail.authDate, 'yyyy-MM-dd', new Date()),
              'MM/dd/yyyy'
            )
          : '',
        expirationDate: authDetail.expirationDate,
        authType: authDetail.authType,
        authCategory: authDetail.authCategory,
        placeOfSvc: authDetail.placeOfSvc,
        authStatus: authDetail.authStatus,
        referredFromName: authDetail.referredFromName,
        referredToName: authDetail.referredToName,
        referredToSpecialty: authDetail.referredToSpecialty,
        diagnosisCodes: authDetail.diagnosisCodes,
        procedureCodes: authDetail.procedureCodes,
      })
    )
  )

export const fetchAuthsRequest = Request({
  typePrefix: key,
  typeBase: 'FETCH_AUTHS',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`/v1/patient/${patientId}/auths`),
  transform: transformAuthDetails,
  messages: { failed: 'There was a problem fetching Caremore Auths' },
})

export default createReducer(key, Map(), {
  [fetchAuthsRequest.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getAuths = pipe(get(rootKey), get(key))
