import { combineEpics, ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { merge, of } from 'rxjs'
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
} from 'rxjs/operators'
import { APP_DASHBOARD_ORDERS_INDEX } from '~/apps/appDashboard/router'
import { CSS_DASHBOARD_ORDERS_INDEX } from '~/apps/cssDashboard/router'
import { NURSE_DASHBOARD_ORDERS_INDEX } from '~/apps/nurseDashboard/router'
import { PHYSICIAN_DASHBOARD_ORDERS_INDEX } from '~/apps/physicianDashboard/router'
import { REQUEST_ORDERS_FOR_USER_ID } from '../data/loaders'
import {
  fetchUserOrders,
  fetchUserOrdersForCareTeamPatients,
  ordersFetchFailed,
  ordersFetchSucceeded,
} from '../data/orders'
import { appSignatureFetched, fetchOrderSignature } from '../data/signature'
import { USER_ID_CHANGED } from '../data/userId'

// used in app dash whenever the APP id is changed via the "view as" features
const loadUsersOrders = action$ =>
  action$.pipe(
    ofType(REQUEST_ORDERS_FOR_USER_ID),
    pluck('payload', 'userId'),
    mergeMap(appId =>
      merge(
        fetchOrderSignature(appId).then(appSignatureFetched),
        fetchUserOrders(appId).then(ordersFetchSucceeded)
      ).pipe(catchError(err => of(ordersFetchFailed(err))))
    )
  )

const loadCareTeamUsersOrders = action$ => {
  const id$ = action$.pipe(ofType(USER_ID_CHANGED))

  return action$.pipe(
    filter(
      changedTo([
        PHYSICIAN_DASHBOARD_ORDERS_INDEX,
        NURSE_DASHBOARD_ORDERS_INDEX,
        CSS_DASHBOARD_ORDERS_INDEX,
        APP_DASHBOARD_ORDERS_INDEX,
      ])
    ),
    combineLatest(id$),
    map(([_routeChanged, userIdChaged]) => userIdChaged),
    pluck('payload', 'userId'),
    distinctUntilChanged(),
    mergeMap(id =>
      merge(
        fetchUserOrdersForCareTeamPatients(id).then(ordersFetchSucceeded)
      ).pipe(catchError(err => of(ordersFetchFailed(err))))
    )
  )
}

export default combineEpics(loadUsersOrders, loadCareTeamUsersOrders)
