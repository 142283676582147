import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot, rootKey } from './common/shared'

const key = 'patientInsuranceCoverages'

const CoverageOfBenefits = Record({
  effectiveDate: null,
  mcreId: null,
  mcreName: null,
  mecbInsurOrder: null,
  mecbPolicyId: null,
  termDate: null,
})

const Relationship = Record({
  name: null,
  id: null,
})

export const InsuranceCoverage = Record({
  accountId: null,
  cmsPlanId: null,
  coverageOfBenefits: CoverageOfBenefits,
  createdByUser: Relationship,
  createdByUserId: null,
  createdDate: null,
  effectiveDate: null,
  expirationDate: null,
  groupName: null,
  groupNumber: null,
  healthplan: null,
  healthplanAccountId: null,
  healthplanCrosswalkId: null,
  healthplanId: null,
  healthplanPatientId: null,
  healthplanSpecificPrograms: null,
  healthplanType: null,
  id: null,
  insuranceNotes: null,
  lastModifiedByUser: Relationship,
  lastModifiedByUserId: null,
  lastModifiedDate: null,
  mbi: null,
  mbrSnp: null,
  patientId: null,
  primaryContract: null,
  priority: null,
  productLineC: null,
  productName: null,
  productNumber: null,
  subscriberChoice: null,
  subscriberDob: null,
  subscriberName: null,
  vbid: null,
})

const transformCoverage = data =>
  InsuranceCoverage({
    ...data,
    lastModifiedByUser: Relationship(data.lastModifiedByUser),
    createdByUser: Relationship(data.createdByUser),
    coverageOfBenefits: CoverageOfBenefits(data.coverageOfBenefits),
  })

const transformCoverageList = data =>
  data.reduce((acc, item) => acc.set(item.id, transformCoverage(item)), Map())

export const getInsuranceCoverages = pipe(getRoot, get(key))

export const getInsuranceCoveragesArray = createSelector(
  getInsuranceCoverages,
  ics => ics.valueSeq().toArray()
)

// SELECTORS
export const getCoverageById = (state, id) => state.getIn([rootKey, key, id])

export const fetchInsuranceCoverages = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_INSURANCE_COVERAGES',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`v1/patient/${patientId}/insurance_coverages`),
  transform: transformCoverageList,
  messages: {
    failed: "There was a problem fetching the patient's insurance coverages",
  },
})

export const updateInsuranceCoverage = Request({
  typePrefix: key,
  typeBase: 'UPDATE_PATIENT_INSURANCE_COVERAGES',
  requestParams: ['changes'],

  operation: rawChanges => {
    const {
      accountId,
      effectiveDate,
      expirationDate,
      groupNumber,
      groupName,
      healthplanPatientId,
      healthplanSpecificPrograms,
      insuranceNotes,
      priority,
      productLineC,
      subscriberChoice,
      subscriberDob,
      subscriberName,
      vbid,
    } = rawChanges

    return AspireAPI.put(
      `v1/patient/${rawChanges.patientId}/insurance_coverages/${rawChanges.id}`,
      {
        accountId,
        effectiveDate: effectiveDate || null, // prevent sending falsy empty string, which won't be parsed correctly by the server,
        expirationDate: expirationDate || null, // prevent sending falsy empty string, which won't be parsed correctly by the server
        groupNumber,
        groupName,
        healthplanPatientId,
        healthplanSpecificPrograms,
        insuranceNotes,
        priority,
        productLineC,
        subscriberChoice,
        subscriberDob,
        subscriberName,
        vbid,
      }
    )
  },
  messages: {
    succeeded: 'Insurance coverage successfully updated',
    failed: 'Could not update insurance coverage',
  },
  transform: transformCoverage,
})

// REQUEST
export const saveNewInsuranceCoverage = Request({
  typePrefix: key,
  typeBase: 'SAVE_NEW_PATIENT_INSURANCE_COVERAGES',
  requestParams: ['details'],
  operation: rawCoverage => {
    const {
      accountId,
      effectiveDate,
      expirationDate,
      groupNumber,
      groupName,
      healthplanCrosswalkId,
      insuranceNotes,
      priority,
      subscriberChoice,
      subscriberDob,
      subscriberName,
      healthplanPatientId,
      healthplanSpecificPrograms,
      termPrevCoverage,
      productLineC,
    } = rawCoverage

    return AspireAPI.post(
      `v1/patient/${rawCoverage.patientId}/insurance_coverages`,
      {
        accountId,
        effectiveDate: effectiveDate || null, // prevent sending falsy empty string, which won't be parsed correctly by the server,
        expirationDate: expirationDate || null, // prevent sending falsy empty string, which won't be parsed correctly by the server
        groupNumber,
        groupName,
        healthplanCrosswalkId,
        insuranceNotes,
        priority,
        subscriberChoice,
        subscriberDob,
        subscriberName,
        healthplanPatientId,
        healthplanSpecificPrograms,
        termPrevCoverage,
        productLineC,
      }
    )
  },
  messages: {
    succeeded: 'New insurance coverage saved',
    failed: 'Could not save new insurance coverage',
  },
  transform: transformCoverage,
})

export default createReducer(key, Map(), {
  [fetchInsuranceCoverages.SUCCEEDED]: (_state, { payload }) => payload,
  [updateInsuranceCoverage.SUCCEEDED]: (state, { payload }) =>
    state.setIn([payload.id], payload),
  [saveNewInsuranceCoverage.SUCCEEDED]: (state, { payload }) =>
    state.setIn([payload.id], payload),
})
