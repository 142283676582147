import { creator, get, type } from '~/utils/data'
import key from '../../key'

export const DOWNLOAD_REQUESTED = type(key, 'DOWNLOAD_REQUESTED')
export const DOWNLOAD_FINISHED = type(key, 'DOWNLOAD_FINISHED')

export const downloadRequested = creator(DOWNLOAD_REQUESTED)
export const downloadFinished = creator(DOWNLOAD_FINISHED)

export const getRoot = get(key)
