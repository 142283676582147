export default {
  weight: 145,
  height: 67,
  bmi: 16.5,
  oxygen: 90,
  pps: '50',
  patientName: 'Jane Doe',
  dob: '12-10-1950',
  gender: 'female',
  age: '77',
}
