import React from 'react'
// @ts-expect-error TS7016: Could not find a declaration file for module '~/utils/format'.
import { formatDateTime } from '~/utils/format'

type Props = {
  value: string | Date
}

export default function DisplayDateTime({ value }: Props) {
  return <span>{value && formatDateTime(value)}</span>
}
