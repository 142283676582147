import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { generateEncounterReviewPdf } from '../data/currentEncounterReview'

export default (action$: any) =>
  action$.pipe(
    ofType(generateEncounterReviewPdf.SUCCEEDED),
    tap(({ payload }) => {
      const url = window.URL.createObjectURL(payload)
      window.open(url)

      // Cleanup the url after a minute to avoid memory leaks over time
      setTimeout(() => window.URL.revokeObjectURL(url), 1000 * 60)
    }),
    ignoreElements()
  )
