import { Map } from 'immutable'
import React from 'react'
import { Field } from 'redux-form/immutable'
import { SelectField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { AdherenceOptions } from '../../../utils/adherence'
import { required } from '../../../utils/formValidators'
import { camelToWord } from '../../../utils/transformers'
import FormFieldWrapper from './FormFieldWrapper'

const styles = {
  formSpan: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '48%',
  },
}

export const AdherenceField = ({ classes, selectedMedication, isRequired }) => {
  const adherence = selectedMedication.get('adherence')
  return (
    <React.Fragment>
      <FormFieldWrapper title="Adherence" isCollapsed={false}>
        <span className={classes.formSpan}>
          <span className={classes.halfWidth}>
            <Field
              component={SelectField}
              className={classes.fullWidth}
              label={
                adherence
                  ? `${camelToWord(adherence)} - INTERNAL USE ONLY`
                  : 'Set Adherence - INTERNAL USE ONLY'
              }
              name="adherence"
              multiple={false}
              native={true}
              validate={isRequired ? [required] : null}
            >
              {AdherenceOptions}
            </Field>
          </span>
        </span>
      </FormFieldWrapper>
    </React.Fragment>
  )
}

AdherenceField.propTypes = {
  classes: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  selectedMedication: PropTypes.map,
}

AdherenceField.defaultProps = {
  isRequired: false,
  selectedMedication: Map(),
}

export default withStyles(styles)(AdherenceField)
