import { AOfMorphADT, ReduxAction, summon } from '~/utils/type'
import { Async } from '~/resources/resource-strict'

export const PlaceOfService = summon(F => F.string())
export type PlaceOfService = string

export const State = Async(PlaceOfService)
export type State = typeof State

export type Action = ReduxAction<'PlaceOfService', AOfMorphADT<State>>
