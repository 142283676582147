import React from 'react'
import { formatDate } from '~/utils/format'
import { NativeSelect, TextField } from '@material-ui/core'
import RxStatusOptions from '../components/RxStatusOptions'
import { AdherenceOptions } from './adherence'
import { indicationOptions } from './indications'
import {
  PENDING,
  RECENTLY_UPDATED,
  caremoreRxStatusMap,
  importStatusMap,
  rxStatusList,
  rxStatusMap,
  sourceMap,
} from './medListConstants'

/* GRID EDIT COMPONENTS */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name*/
const NativeSelectComponent = Options => ({ onChange, ...rawProps }) => (
  <NativeSelect {...rawProps} onChange={e => onChange(e.target.value)}>
    {Options}
  </NativeSelect>
)
/* eslint-enable react/prop-types */
/* eslint-enable react/display-name*/

/* eslint-disable react/prop-types */
const MultiLineTextField = ({ onChange, ...rawProps }) => (
  <TextField multiline onChange={e => onChange(e.target.value)} {...rawProps} />
)
/* eslint-enable react/prop-types */

const PendingDisabledStatusOptions = (
  <RxStatusOptions disabledStatuses={[PENDING]} />
)

/* GRID SORTING FUNCTION */

const caseInsensitiveSort = (a, b) =>
  a && b && a.localeCompare(b, 'en', { sensitivity: 'accent' })

const dateSort = (a, b) => a && b && new Date(a) - new Date(b)

const statusSort = (a, b) => rxStatusList.indexOf(a) - rxStatusList.indexOf(b)

const displayToStatus = caremoreRxStatusMap.mapEntries(([_key, value]) => [
  value.display,
  value.key,
])

const substatusSort = (a, b) => displayToStatus.get(a) - displayToStatus.get(b)

/* GRID CONFIGURATION */
export const caseMgmtIntegratedSortingColumnExtensions = [
  { columnName: 'drug', compare: caseInsensitiveSort, editingEnabled: false },
  { columnName: 'source', compare: caseInsensitiveSort, editingEnabled: false },
  { columnName: 'primaryIndication', compare: caseInsensitiveSort },
  { columnName: 'primaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndication', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'category', compare: statusSort },
  { columnName: 'status', compare: substatusSort },
  { columnName: 'prescriptionDate', compare: dateSort },
]

export const integratedSortingColumnExtensions = [
  { columnName: 'drug', compare: caseInsensitiveSort, editingEnabled: false },
  { columnName: 'primaryIndication', compare: caseInsensitiveSort },
  { columnName: 'primaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndication', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'category', compare: statusSort },
  { columnName: 'status', compare: substatusSort },
  { columnName: 'prescriptionDate', compare: dateSort },
  { columnName: 'aspirePrescribed', editingEnabled: false },
  { columnName: 'startDate', compare: dateSort, editingEnabled: false },
  { columnName: 'stopDate', compare: dateSort, editingEnabled: false },
  { columnName: 'adherence', compare: caseInsensitiveSort },
  { columnName: 'modifiedAt', compare: dateSort, editingEnabled: false },
  {
    columnName: 'modifiedByName',
    compare: caseInsensitiveSort,
    editingEnabled: false,
  },
]

export const historicalIntegratedSortingColumnExtensions = [
  { columnName: 'drug', compare: caseInsensitiveSort, editingEnabled: false },
  { columnName: 'source', compare: caseInsensitiveSort, editingEnabled: false },
  { columnName: 'primaryIndication', compare: caseInsensitiveSort },
  { columnName: 'primaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndication', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'startDate', compare: dateSort, editingEnabled: false },
  { columnName: 'stopDate', compare: dateSort, editingEnabled: false },
  { columnName: 'category', compare: statusSort },
  { columnName: 'status', compare: substatusSort },
  { columnName: 'prescriptionDate', compare: dateSort },
]

export const prescribedIntegratedSortingColumnExtensions = [
  { columnName: 'drug', compare: caseInsensitiveSort, editingEnabled: false },
  { columnName: 'source', compare: caseInsensitiveSort, editingEnabled: false },
  { columnName: 'primaryIndication', compare: caseInsensitiveSort },
  { columnName: 'primaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndication', compare: caseInsensitiveSort },
  { columnName: 'secondaryIndicationOther', compare: caseInsensitiveSort },
  { columnName: 'startDate', compare: dateSort, editingEnabled: false },
  { columnName: 'stopDate', compare: dateSort, editingEnabled: false },
  { columnName: 'category', compare: statusSort },
  { columnName: 'status', compare: substatusSort },
  { columnName: 'prescriptionDate', compare: dateSort },
  { columnName: 'importStatus', compare: caseInsensitiveSort },
  { columnName: 'importDate', compare: dateSort },
]

export const defaultSorting = [
  { columnName: 'category', direction: 'asc' },
  { columnName: 'status', direction: 'asc' },
  { columnName: 'prescriptionDate', direction: 'desc' },
  { columnName: 'drug', direction: 'asc' },
]

export const caseMgmtMedListColumns = [
  {
    name: 'category',
    title: 'Category',
    getCellValue: ({ status, recentlyUpdated }) =>
      recentlyUpdated
        ? RECENTLY_UPDATED
        : caremoreRxStatusMap.getIn([status, 'status']),
  },
  {
    name: 'status',
    title: 'Status',
    getCellValue: ({ status }) =>
      caremoreRxStatusMap.getIn([status, 'display']),
    editComponent: ({ value, ...props }) => {
      const mappedValue = caremoreRxStatusMap
        .find(rx => rx.get('display') == value)
        .get('value')
      return NativeSelectComponent(PendingDisabledStatusOptions)({
        ...props,
        value: mappedValue,
      })
    },
  },
  {
    name: 'source',
    title: 'Source',
    getCellValue: ({ source }) => sourceMap[source],
  },
  {
    name: 'drug',
    title: 'Name',
    displayWhenCollapsed: true,
    editComponent: MultiLineTextField,
  },
  {
    name: 'directions',
    title: 'Directions',
    editComponent: MultiLineTextField,
  },
  {
    name: 'primaryIndication',
    title: 'Primary Indication',
    editComponent: NativeSelectComponent(indicationOptions),
  },
  {
    name: 'primaryIndicationOther',
    title: 'Primary Indication Other',
    editComponent: MultiLineTextField,
  },
  {
    name: 'secondaryIndication',
    title: 'Secondary Indication',
    editComponent: NativeSelectComponent(indicationOptions),
  },
  {
    name: 'secondaryIndicationOther',
    title: 'Secondary Indication Other',
    editComponent: MultiLineTextField,
  },
  {
    name: 'prescriptionDate',
    title: 'Rx Date',
    type: 'date',
    getCellValue: ({ prescriptionDate }) => formatDate(prescriptionDate),
  },
]

export const medListColumns = [
  {
    name: 'category',
    title: 'Category',
    getCellValue: ({ status, recentlyUpdated }) =>
      recentlyUpdated
        ? RECENTLY_UPDATED
        : rxStatusMap.getIn([status, 'status']),
  },
  {
    name: 'status',
    title: 'Status',
    getCellValue: ({ status }) => rxStatusMap.getIn([status, 'display']),
    editComponent: ({ value, ...props }) => {
      const mappedValue = rxStatusMap
        .find(rx => rx.get('display') == value)
        .get('value')
      return NativeSelectComponent(PendingDisabledStatusOptions)({
        ...props,
        value: mappedValue,
      })
    },
  },
  {
    name: 'drug',
    title: 'Name',
    displayWhenCollapsed: true,
    editComponent: MultiLineTextField,
  },
  {
    name: 'directions',
    title: 'Directions',
    editComponent: MultiLineTextField,
  },
  {
    name: 'primaryIndication',
    title: 'Primary Indication',
    editComponent: NativeSelectComponent(indicationOptions),
  },
  {
    name: 'primaryIndicationOther',
    title: 'Primary Indication Other',
    editComponent: MultiLineTextField,
  },
  {
    name: 'secondaryIndication',
    title: 'Secondary Indication',
    editComponent: NativeSelectComponent(indicationOptions),
  },
  {
    name: 'secondaryIndicationOther',
    title: 'Secondary Indication Other',
    editComponent: MultiLineTextField,
  },
  {
    name: 'prescriptionDate',
    title: 'Rx Date',
    type: 'date',
    getCellValue: ({ prescriptionDate }) => formatDate(prescriptionDate),
  },
  { name: 'eKit', title: 'E-Kit', type: 'boolean' },
  {
    name: 'aspirePrescribed',
    title: 'Internally Prescribed',
    type: 'boolean',
    getCellValue: ({ routeDetail }) =>
      String(routeDetail).substr(0, 6) === 'e-Sent',
  },
  {
    name: 'startDate',
    title: 'Start Date',
    type: 'date',
    getCellValue: ({ startDate }) => formatDate(startDate),
  },
  {
    name: 'stopDate',
    title: 'Stop Date',
    type: 'date',
    getCellValue: ({ stopDate }) => formatDate(stopDate),
  },
  {
    name: 'adherence',
    title: 'Adherence',
    editComponent: NativeSelectComponent(AdherenceOptions),
  },
  {
    name: 'modifiedAt',
    title: 'Updated',
    type: 'date',
    getCellValue: ({ modifiedAt }) => formatDate(modifiedAt),
  },
  {
    name: 'modifiedByName',
    title: 'Updated By',
    type: 'text',
  },
]

export const historicalMedListColumns = [
  {
    name: 'category',
    title: 'Category',
    getCellValue: ({ status, recentlyUpdated }) =>
      recentlyUpdated
        ? RECENTLY_UPDATED
        : caremoreRxStatusMap.getIn([status, 'status']),
  },
  {
    name: 'status',
    title: 'Status',
    getCellValue: ({ status }) =>
      caremoreRxStatusMap.getIn([status, 'display']),
    editComponent: ({ value, ...props }) => {
      const mappedValue = caremoreRxStatusMap
        .find(rx => rx.get('display') == value)
        .get('value')
      return NativeSelectComponent(PendingDisabledStatusOptions)({
        ...props,
        value: mappedValue,
      })
    },
  },
  {
    name: 'source',
    title: 'Source',
    getCellValue: ({ source }) => sourceMap[source],
  },
  {
    name: 'drug',
    title: 'Name',
    displayWhenCollapsed: true,
    editComponent: MultiLineTextField,
  },
  {
    name: 'directions',
    title: 'Directions',
    editComponent: MultiLineTextField,
  },
  {
    name: 'prescriptionDate',
    title: 'Rx/Fill Date',
    type: 'date',
    getCellValue: ({ prescriptionDate }) => formatDate(prescriptionDate),
  },
  {
    name: 'startDate',
    title: 'Start Date',
    type: 'date',
    getCellValue: ({ startDate }) => formatDate(startDate),
  },
  {
    name: 'stopDate',
    title: 'Stop Date',
    type: 'date',
    getCellValue: ({ stopDate }) => formatDate(stopDate),
  },
  {
    name: 'refills',
    title: 'Refills',
    type: 'text',
  },
  {
    name: 'qty',
    title: 'Quantity',
    type: 'text',
  },
  {
    name: 'providerName',
    title: 'Provider Name',
    type: 'text',
  },
]

export const prescribedMedListColumns = [
  {
    name: 'category',
    title: 'Category',
    getCellValue: ({ status, recentlyUpdated }) =>
      recentlyUpdated
        ? RECENTLY_UPDATED
        : caremoreRxStatusMap.getIn([status, 'status']),
  },
  {
    name: 'status',
    title: 'Status',
    getCellValue: ({ status }) =>
      caremoreRxStatusMap.getIn([status, 'display']),
    editComponent: ({ value, ...props }) => {
      const mappedValue = caremoreRxStatusMap
        .find(rx => rx.get('display') == value)
        .get('value')
      return NativeSelectComponent(PendingDisabledStatusOptions)({
        ...props,
        value: mappedValue,
      })
    },
  },
  {
    name: 'source',
    title: 'Source',
    getCellValue: ({ source }) => sourceMap[source],
  },
  {
    name: 'drug',
    title: 'Name',
    displayWhenCollapsed: true,
    editComponent: MultiLineTextField,
  },
  {
    name: 'directions',
    title: 'Directions',
    editComponent: MultiLineTextField,
  },
  {
    name: 'prescriptionDate',
    title: 'Rx/Fill Date',
    type: 'date',
    getCellValue: ({ prescriptionDate }) => formatDate(prescriptionDate),
  },
  {
    name: 'startDate',
    title: 'Start Date',
    type: 'date',
    getCellValue: ({ startDate }) => formatDate(startDate),
  },
  {
    name: 'stopDate',
    title: 'Stop Date',
    type: 'date',
    getCellValue: ({ stopDate }) => formatDate(stopDate),
  },
  {
    name: 'refills',
    title: 'Refills',
    type: 'text',
  },
  {
    name: 'qty',
    title: 'Quantity',
    type: 'text',
  },
  {
    name: 'providerName',
    title: 'Provider Name',
    type: 'text',
  },
  {
    name: 'importStatus',
    title: 'Review Status',
    getCellValue: ({ importStatus }) => importStatusMap[importStatus],
  },
  {
    name: 'importDate',
    title: 'Review Date',
    getCellValue: ({ importDate }) => formatDate(importDate),
  },
]
// Inline editing fields require a value to work in controlled mode
// and only supplies changes to onCommitChanges function, so these
// default display values do not get persisted when changes are saved
export const addDefaults = ({
  directions,
  eKit,
  prescriptionDate,
  primaryIndication,
  primaryIndicationOther,
  secondaryIndication,
  secondaryIndicationOther,
  adherence,
  modifiedAt,
  modifiedByName,
  startDate,
  stopDate,
  ...med
}) => ({
  directions: directions || '',
  eKit: Boolean(eKit),
  prescriptionDate: prescriptionDate || '',
  primaryIndication: primaryIndication || '',
  primaryIndicationOther: primaryIndicationOther || '',
  secondaryIndication: secondaryIndication || '',
  secondaryIndicationOther: secondaryIndicationOther || '',
  adherence: adherence || '',
  modifiedAt: modifiedAt || '',
  modifiedByName: modifiedByName || '',
  startDate: startDate || '',
  stopDate: stopDate || '',
  ...med,
})
