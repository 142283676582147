// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { change, resetSection } from 'redux-form/immutable'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import { addMessage } from '../data/popupMessages'
import {
  ADDITIONAL_REFERRAL_SAVE_REQUESTED,
  additonalReferralSaveCompleted,
  getReferralFormValues,
  patientReferralsRequested,
  saveAdditionalReferral,
} from '../data/referrals'

const saveReferral = (values: any, shouldOverwrite: any) => {
  const patientId = values.getIn(['aspire', 'patientSfid'], null)
  if (patientId) {
    return from(saveAdditionalReferral(values, shouldOverwrite)).pipe(
      mergeMap(data => {
        return of(
          additonalReferralSaveCompleted(data),
          addMessage('Additional Referral Recorded'),
          patientReferralsRequested(
            values.getIn(['aspire', 'patientSfid'], null)
          ),
          resetSection('additional', 'referralInfo'),
          change(
            'additional',
            'aspire.patientSfid',
            values.getIn(['aspire', 'patientSfid'], null)
          )
        )
      }),
      catchError(
        showError('Error! Problem creating an additional referral case')
      )
    )
  } else {
    return showError('Patient ID missing in additional referral form')
  }
}

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(ADDITIONAL_REFERRAL_SAVE_REQUESTED),
    pluck('payload'),
    mergeMap(payload => {
      const state = state$.value
      const values = getReferralFormValues(state, 'additional')

      return loading(saveReferral(values, payload))
    })
  )
