import * as _ from 'lodash'
import { fetchPatient } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, getIn, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

// KEYS
const key = 'updateResearchDialog'
const dialogOpenKey = 'updateResearchDialogOpen'
const inResearchKey = 'isInResearch'

export const getDialogOpenValue = getIn([rootKey, dialogOpenKey])
export const getResearchStatus = getIn([rootKey, inResearchKey])

// CREATORS
const creator = scopedCreator(key)

export const updateResearchDialogOpened = creator(
  'COMPLETE_RESEARCH_DIALOG_OPENED'
)
export const updateResearchDialogClosed = creator(
  'COMPLETE_RESEARCH_DIALOG_CLOSED'
)

// REQUEST
export const updateResearchCase = Request({
  typePrefix: key,
  typeBase: 'UPDATE_RESEARCH_CASE',
  requestParams: ['id', 'changes'],
  operation: (id, changes) => AspireAPI.patch(`v1/research/${id}`, changes),
  messages: {
    succeeded: 'Research case successfully updated',
    failed: 'Could not update research case',
  },
})

export const completeResearchCase = Request({
  typePrefix: key,
  typeBase: 'COMPLETE_RESEARCH_CASE',
  requestParams: ['id', 'changes'],
  operation: (id, changes) =>
    AspireAPI.put(`v1/research/${id}/complete`, changes),
  messages: {
    succeeded: 'Research case successfully completed',
    failed: 'Could not complete research case',
  },
})

// REDUCER
export const completeDialog = createReducer(dialogOpenKey, false, {
  [updateResearchDialogClosed]: () => false,
  [updateResearchDialogOpened]: () => true,
  [updateResearchCase.SUCCEEDED]: () => false,
  [completeResearchCase.SUCCEEDED]: () => false,
})

export const isInResearch = createReducer(inResearchKey, false, {
  [fetchPatient.SUCCEEDED]: (_state, { payload }) =>
    _.get(payload, ['currentResearchCase', 'status']) === 'New',
})

export const getIsResearch = pipe(getRoot, get(inResearchKey))
