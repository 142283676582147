import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, creator, get, payload, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { CURRENT_YEAR } from './constants'
import { MemberBenefitHistory } from './interfaces'
import {
  transformLimited,
  transformNotes,
  transformUnlimited,
} from './memberInfo'

const key = 'patientBenefitHistory'

export const BENEFIT_INFO_CLEARED = type(key, 'BENEFIT_INFO_CLEARED')

export const BenefitInfoCleared = creator(BENEFIT_INFO_CLEARED)

export const BenefitInfo = Record({
  benefitYear: null,
  notes: List(),
  limited: List(),
  unlimited: List(),
})

export const transformMemberBenefitHistory = (
  memberBenefitHist: MemberBenefitHistory
) => {
  return BenefitInfo({
    benefitYear: memberBenefitHist.benefitYear,
    notes: transformNotes(memberBenefitHist?.note),
    limited: transformLimited(memberBenefitHist?.limited),
    unlimited: transformUnlimited(memberBenefitHist?.unlimited),
  })
}

export const fetchPatientBenefitHistory = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT_BENEFIT_HISTORY',
  requestParams: ['id', 'effectDate'],
  operation: (id: string) =>
    AspireAPI.get(
      `transportation/benefit_history/${id}?benefitYear=${CURRENT_YEAR - 1}`
    ),
  transform: transformMemberBenefitHistory,
  messages: {
    failed: 'There was an issue fetching the patient benefit history ',
  },
})
export const patientBenefitHistory = createReducer(key, BenefitInfo(), {
  [fetchPatientBenefitHistory.SUCCEEDED]: pipe(action, payload),
})

export const getPatientBenefitHistory = pipe(getRoot, get(key))
