import { ROUTE_CHANGED, paramsReducer } from 'redux-routable'
import { calcFormData, changeFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS } from '../router'
import {
  PatientOutlierFinalDecision,
  getRoot,
  transformPatientOutlierFinalDecision,
} from './common/shared'

const CURRENT_PATIENT_OUTLIER_FINAL_DECISION =
  'currentPatientOutlierFinalDecision'

const creator = scopedCreator(rootKey)
export const patientOutlierFinalDecisionFormDataChanged = creator(
  'PATIENT_OUTLIER_FINAL_DECISION_FORM_DATA_CHANGED',
  ['formData']
)

const initializePatientOutlierFinalDecision = (
  patientOutlierFinalDecision: any
) =>
  patientOutlierFinalDecision.set(
    'formData',
    calcFormData({
      formData: patientOutlierFinalDecision.formData,
      schema: patientOutlierFinalDecision.schema,
      context: patientOutlierFinalDecision.context,
      tags: patientOutlierFinalDecision.tags,
    })
  )

export const fetchPatientOutlierFinalDecision = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_OUTLIER_FINAL_DECISION',
  requestParams: ['id'],
  operation: (id: any) =>
    AspireAPI.get(`patient_outliers/final_decisions/${id}`),
  transform: pipe(
    transformPatientOutlierFinalDecision,
    initializePatientOutlierFinalDecision
  ),
  messages: {
    failed: 'There was an issue getting the patient outlier final decision',
  },
})

export const savePatientOutlierFinalDecision = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PATIENT_OUTLIER_FINAL_DECISION',
  requestParams: ['id', 'formData'],
  operation: ({ id, formData }: any) =>
    AspireAPI.put(`patient_outliers/final_decisions/${id}`, {
      data: formData,
    }),
  messages: {
    failed: 'There was an issue saving the patient outlier final decision',
  },
})

export const completePatientOutlierFinalDecision = Request({
  typePrefix: rootKey,
  typeBase: 'COMPLETE_PATIENT_OUTLIER_FINAL_DECISION',
  requestParams: ['id'],
  operation: (id: any) =>
    AspireAPI.put(`patient_outliers/final_decisions/${id}/complete`),
  messages: {
    succeeded: 'Successfully completed patient outlier final decision',
    failed: 'There was an issue completing the patient outlier final decision',
  },
})

const initState = PatientOutlierFinalDecision()

export default createReducer(
  CURRENT_PATIENT_OUTLIER_FINAL_DECISION,
  initState,
  {
    [ROUTE_CHANGED]: paramsReducer(
      PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_FINAL_DECISIONS,
      initState,
      ({ patientOutlierFinalDecisionId }: any) =>
        patientOutlierFinalDecisionId
          ? PatientOutlierFinalDecision({
              id: Number(patientOutlierFinalDecisionId),
            })
          : initState
    ),
    [fetchPatientOutlierFinalDecision.SUCCEEDED]: (
      _state: any,
      { payload }: any
    ) => payload,
    // @ts-expect-error can we deal with the toString being automatically called?
    [patientOutlierFinalDecisionFormDataChanged]: (
      state: any,
      { payload: { formData } }: any
    ) => changeFormData(state, null, formData),
    [completePatientOutlierFinalDecision.SUCCEEDED]: () => initState,
  }
)

export const getCurrentPatientOutlierFinalDecision = pipe(
  getRoot,
  get(CURRENT_PATIENT_OUTLIER_FINAL_DECISION)
)

export const getCurrentPatientOutlierFinalDecisionId = (state: any) =>
  getCurrentPatientOutlierFinalDecision(state).id
