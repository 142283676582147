import React from 'react'
import Info from '~/components/text/Info'
import { Vendor } from '~/data/vendors'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette: { contentText }, spacing }) => ({
  container: {
    marginBottom: spacing(2),
  },
  link: {
    color: contentText.secondaryLabel,
    textDecoration: 'none',
  },
})

const VendorInfo = ({ classes, isHumanaVbid, vendor: v }) => (
  <div className={classes.container}>
    <Typography variant="subtitle1">Selected Vendor</Typography>
    <Info label="Type">{v.type}</Info>
    <Info label="Name">{v.name}</Info>
    <Info label="Address">{v.billingAddress}</Info>
    <Info label="Address 2">{v.billingAddress2}</Info>
    <Info label="City">{v.billingCity}</Info>
    <Info label="State">{v.billingStateCode}</Info>
    <Info label="ZIP">{v.billingPostalCode}</Info>
    <Info label="Fax">{v.fax}</Info>
    <Info label="Phone">{v.phone}</Info>
    <Info label="Website">
      <a
        className={classes.link}
        href={v.website}
        rel="noopener noreferrer"
        target="_blank"
      >
        {v.website}
      </a>
    </Info>
    {isHumanaVbid && (
      <Info label="Is In Network">{v.isInNetwork ? 'Yes' : 'No'}</Info>
    )}
  </div>
)

VendorInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  isHumanaVbid: PropTypes.bool,
  vendor: PropTypes.instanceOf(Vendor).isRequired,
}

export default withStyles(styles)(VendorInfo)
