import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, creator, get, payload, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
import { getRoot } from './common/shared'
import { MemberTermDetails } from './interfaces'

const key = 'memberTermedDetails'

export const TERMED_MEMBER_INFO_CLEARED = type(
  key,
  'TERMED_MEMBER_INFO_CLEARED'
)

export const BenefitInfoCleared = creator(TERMED_MEMBER_INFO_CLEARED)

export const MemberTermedInfo = Record({
  memberPlanEffectiveDate: null,
  productId: null,
  productDescription: null,
  memberPlanTerminationDate: null,
})

export const transformMemberTermedDetails = (
  memberTermedData: MemberTermDetails
) => {
  return MemberTermedInfo({
    memberPlanEffectiveDate: memberTermedData?.memberPlanEffectiveDate.split(
      ' '
    )[0],
    productId: memberTermedData?.productId,
    productDescription: memberTermedData?.productDesc,
    memberPlanTerminationDate: memberTermedData?.memberPlanTerminationDate.split(
      ' '
    )[0],
  })
}

export const fetchMemberTermedDetails = Request({
  typePrefix: key,
  typeBase: 'FETCH_MEMBER_TERMED_DETAILS',
  requestParams: ['patientId', 'subscriberId'],
  operation: (patientId: string, subscriberId: string) =>
    AspireAPI.get(
      `transportation/termed_details/${patientId}?subscriber_id=${subscriberId}`
    ),
  transform: transformMemberTermedDetails,
  messages: {
    failed: 'There was an issue fetching  member termed details ',
  },
})
export const memberTermedDetails = createReducer(key, MemberTermedInfo(), {
  [fetchMemberTermedDetails.SUCCEEDED]: pipe(action, payload),
})

export const getTermedMemberDetails = pipe(getRoot, get(key))
