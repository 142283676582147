import { combineEpics, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { map, mergeMap, pluck } from 'rxjs/operators'
import {
  LOCAL_ENCOUNTER_CHECK_IN,
  LOCAL_ENCOUNTER_CHECK_OUT,
  getItem,
} from '~/utils/storage'
import {
  checkIntoEncounter,
  checkOutOfEncounter,
  encounterCheckInRequested,
  encounterCheckOutRequested,
} from '../data/common/shared'

const encounterCheckInEpic = action$ =>
  action$.pipe(
    ofType(encounterCheckInRequested),
    pluck('payload'),
    mergeMap(payload =>
      from(getItem(`${LOCAL_ENCOUNTER_CHECK_IN}-${payload.encounterId}`)).pipe(
        map(localCheckIn => {
          const { encounterId, checkInAt, checkInLat, checkInLng } =
            localCheckIn || payload

          return checkIntoEncounter.requested(
            encounterId,
            checkInAt,
            checkInLat,
            checkInLng
          )
        })
      )
    )
  )

const encounterCheckOutEpic = action$ =>
  action$.pipe(
    ofType(encounterCheckOutRequested),
    pluck('payload'),
    mergeMap(payload =>
      from(getItem(`${LOCAL_ENCOUNTER_CHECK_OUT}-${payload.encounterId}`)).pipe(
        map(localCheckOut => {
          const { encounterId, checkOutAt, checkOutLat, checkOutLng } =
            localCheckOut || payload

          return checkOutOfEncounter.requested(
            encounterId,
            checkOutAt,
            checkOutLat,
            checkOutLng
          )
        })
      )
    )
  )

export default combineEpics(encounterCheckInEpic, encounterCheckOutEpic)
