import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { patientPanelOpened } from '~/data/patientPanel'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { fetchPatientRx } from '~/features/medications'
import {
  fetchStakeholders,
  stakeholdersInitialized,
  // @ts-expect-error no export
} from '~/features/stakeholders'
import { fetchEncounterReview } from '../data/currentEncounterReview'

const patientInfoEpic = (action$: any) =>
  action$.pipe(
    ofType(fetchEncounterReview.SUCCEEDED),
    pluck('payload', 'patientId'),
    mergeMap(patientId =>
      of(
        stakeholdersInitialized(),
        fetchStakeholders.requested(patientId),
        patientPanelOpened(patientId)
      )
    )
  )

// Fetch live medications when form data from assessment is empty
const medicationsEpic = (action$: any) =>
  action$.pipe(
    ofType(fetchEncounterReview.SUCCEEDED),
    pluck('payload'),
    filter(
      ({ encounterFormData: { medList } }) => !medList || medList.length === 0
    ),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ patientId }: { patientId: any... Remove this comment to see the full error message
    map(({ patientId }) => fetchPatientRx.requested(patientId))
  )

export default combineEpics(patientInfoEpic, medicationsEpic)
