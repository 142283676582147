import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchCallsForPatient } from '~/features/calls'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_CALL } from '../router'
import lastEncounterEpic from './lastEncounter'

const callEntered = (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_CALL)),
    switchTo(state$),
    map(getPatientId),
    map(fetchCallsForPatient.requested)
  )

export default combineEpics(lastEncounterEpic(PATIENT_RECORD_CALL), callEntered)
