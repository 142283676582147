import { defaultEnd, defaultStart } from '../defaults'

const availabilityWhatId = '0061500000TdhSAAAZ'

export default {
  type: 'SchedulingGuidance',
  recordType: 'scheduling_guidance',
  whatId: availabilityWhatId,
  start: defaultStart,
  end: defaultEnd,
}
