// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const FIRST_NAME = 'fname'
const LAST_NAME = 'lname'
const PATIENT_ID = 'patientId'
const DOB = 'patientDob'
const PHONE = 'phone'
const ADDRESS = 'address'

export const getFirstName = getIn([rootKey, FIRST_NAME])
export const getLastName = getIn([rootKey, LAST_NAME])
export const getPatientId = getIn([rootKey, PATIENT_ID])
export const getDob = getIn([rootKey, DOB])
export const getPhone = getIn([rootKey, PHONE])
export const getAddress = getIn([rootKey, ADDRESS])

// CREATORS
const firstNameCreator = scopedCreator(FIRST_NAME)
export const clearFirstnameField = firstNameCreator('CLEAR_FIRST_NAME')
export const setFirstNameField = firstNameCreator('MAP_FIRST_NAME')

const lastNameCreator = scopedCreator(LAST_NAME)
export const clearLastNameField = lastNameCreator('CLEAR_LAST_NAME')
export const setLastNameField = lastNameCreator('MAP_LAST_NAME')

const patientIdCreator = scopedCreator(PATIENT_ID)
export const clearPatientIdField = patientIdCreator('CLEAR_PATIENT_ID')
export const setPatientIdField = patientIdCreator('MAP_PATIENT_ID')

const dobCreator = scopedCreator(DOB)
export const clearDobField = dobCreator('CLEAR_DOB')
export const setDobField = dobCreator('MAP_DOB')

const phoneCreator = scopedCreator(PHONE)
export const clearPhoneField = phoneCreator('CLEAR_PHONE')
export const setPhoneField = phoneCreator('MAP_PHONE')

const addressCreator = scopedCreator(ADDRESS)
export const clearAddressField = addressCreator('CLEAR_ADDRESS')
export const setAddressField = addressCreator('MAP_ADDRESS')

// REDUCER

export const mapFirstNameToState = createReducer(FIRST_NAME, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [setFirstNameField]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearFirstnameField]: () => '',
})
export const mapLastNameToState = createReducer(LAST_NAME, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [setLastNameField]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearLastNameField]: () => '',
})
export const mapPatientIdToState = createReducer(PATIENT_ID, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [setPatientIdField]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearPatientIdField]: () => '',
})
export const mapDobToState = createReducer(DOB, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [setDobField]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearDobField]: () => '',
})
export const mapPhoneToState = createReducer(PHONE, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [setPhoneField]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearPhoneField]: () => '',
})
export const mapAddressToState = createReducer(ADDRESS, '', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [setAddressField]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [clearAddressField]: () => '',
})
