import { combineEpics, ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
import { creator, type } from '~/utils/data'

const key = 'patientUpload'

export const UPLOAD_APP_PATIENTS = type(key, 'UPLOAD_APP_PATIENTS')
export const UPLOAD_PATIENT = type(key, 'UPLOAD_APP_PATIENT')

export const uploadAppPatients = creator(UPLOAD_APP_PATIENTS, 'appId')
export const uploadPatient = creator(UPLOAD_PATIENT, 'patientId')

export const uploadPatients = Request({
  typePrefix: key,
  typeBase: 'UPLOAD_PATIENTS',
  requestParams: ['params'],
  operation: (params: any) =>
    AspireAPI.post('medications/mdt_initial_upload', params),
})

const appUpload = (action$: any) =>
  action$.pipe(
    ofType(UPLOAD_APP_PATIENTS),
    pluck('payload'),
    map(uploadPatients.requested)
  )

const patientUpload = (action$: any) =>
  action$.pipe(
    ofType(UPLOAD_PATIENT),
    pluck('payload'),
    map(uploadPatients.requested)
  )

export const epic = combineEpics(appUpload, patientUpload)
