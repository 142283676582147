import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import parseMapBounds from '../utils/parseMapBounds'
import { getRoot } from './common/shared'

const key = 'bounds'

export const MAP_BOUNDS_CHANGED = type(rootKey, 'MAP_BOUNDS_CHANGED')

export const setMapBounds = creator(MAP_BOUNDS_CHANGED, 'boundaries')

export default createReducer(key, null, {
  [MAP_BOUNDS_CHANGED]: (state, { payload: { boundaries } }) =>
    parseMapBounds(boundaries),
})

export const getMapBounds = pipe(getRoot, get(key))
