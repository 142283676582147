import moment from 'moment'
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error no export
import DevXGrid from '~/components/DevXGrid'
import DisplayDate from '~/components/DisplayDate'
import PatientCell from '~/components/PatientCell'
import WithTooltip from '~/components/WithTooltip'
import { NominationReason, labels } from '~/fieldValues/nomination_reason'
import { Paper, makeStyles } from '@material-ui/core'
import { getHighRiskHuddle } from '../data'
import Details from './Details'

const BOOLEAN_WIDTH = 115

const columns = [
  {
    name: 'patientName',
    title: 'Patient',
    displayComponent: PatientCell,
  },
  { name: 'program', title: 'Program' },
  { name: 'market', title: 'Market' },
  {
    name: 'nominatedAt',
    title: 'Nomination Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'nominatedBy',
    title: 'Nominated By',
  },
  {
    name: 'reason',
    title: 'Reason',
    getCellValue: ({ reason }: { reason: NominationReason }) => labels[reason],
    displayComponent: WithTooltip,
  },
  {
    name: 'targetDiscussionDate',
    title: 'Review Date',
    displayComponent: DisplayDate,
  },
  { name: 'notes', title: 'Nomination Notes' },
  {
    name: 'nextTargetedVisitDate',
    title: 'Primary NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'lastVisitDate',
    title: 'Last Visit Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'limitedPrognosis',
    title: 'Lim. Prog.',
    type: 'boolean',
  },
  {
    name: 'activeHospitalization',
    title: 'In Hospital',
    type: 'boolean',
  },
  {
    name: 'recentHospitalization',
    title: 'Recent Hosp. DC',
    type: 'boolean',
  },
  { name: 'activeSnf', title: 'In SNF', type: 'boolean' },
  {
    name: 'recentSnf',
    title: 'Recent SNF DC',
    type: 'boolean',
  },
  { name: 'overdue', title: 'Overdue', type: 'boolean' },
  { name: 'status', title: 'Status' },
  { name: 'openEoc', title: 'Open EOC', type: 'boolean' },
  { name: 'careTeamLabel', title: 'Care Team' },
]

const columnExtensions = [
  { columnName: 'patientName', width: 200 },
  { columnName: 'program', width: 200 },
  { columnName: 'nominatedAt', width: 125 },
  { columnName: 'lastVisitDate', width: 125 },
  { columnName: 'limitedPrognosis', width: BOOLEAN_WIDTH },
  { columnName: 'activeHospitalization', width: BOOLEAN_WIDTH },
  { columnName: 'recentHospitalization', width: 135 },
  { columnName: 'activeSnf', width: BOOLEAN_WIDTH },
  { columnName: 'recentSnf', width: 125 },
  { columnName: 'overdue', width: BOOLEAN_WIDTH },
  { columnName: 'status', width: 150 },
  { columnName: 'openEoc', width: BOOLEAN_WIDTH },
]

const pageSizes = [25, 50]

const useStyles = makeStyles({
  red: {
    '& > *': { color: 'red' },
  },
})

type Props = {
  localSettingsKey: string
  rowActions: React.ReactNode
}

export default function HighRiskHuddle({
  rowActions,
  localSettingsKey,
}: Props) {
  const classes = useStyles()

  const highRiskHuddle: any = useSelector(getHighRiskHuddle)

  const rows = useMemo(() => highRiskHuddle.toIndexedSeq().toArray(), [
    highRiskHuddle,
  ])

  const ref = useRef<any>(null)

  return (
    <Paper ref={ref}>
      <DevXGrid
        columns={columns}
        columnExtensions={columnExtensions}
        rows={rows}
        rowActions={rowActions}
        rowStyles={(row: any) =>
          // red if today is after targetDiscussionDate
          moment(row.targetDiscussionDate) < moment() ? classes.red : ''
        }
        detailComponent={({ row }: any) => (
          <Details nominationId={row.id} gridWidth={ref.current?.offsetWidth} />
        )}
        localSettingsKey={localSettingsKey}
        columnHideable
        columnReorderable
        filterable
        groupable
        pageable
        pageSizes={pageSizes}
        defaultPageSize={25}
        searchable
        sortable
        defaultGrouping={[{ columnName: 'careTeamLabel' }]}
      />
    </Paper>
  )
}
