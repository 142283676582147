import { combineEpics } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import {
  appChanged,
  patientChanged,
  patientCleared,
  // @ts-expect-error no export
} from '~/features/scheduling/data'
import {
  PES_DASHBOARD_REFERRAL_SCHEDULING_APP,
  PES_DASHBOARD_REFERRAL_SCHEDULING_PATIENT,
} from '../router'

const appChangedEpic = (action$: any) =>
  action$.pipe(
    filter(changedTo(PES_DASHBOARD_REFERRAL_SCHEDULING_APP)),
    pluck('payload', 'params', 'appId'),
    mergeMap(appId => of(appChanged(appId), patientCleared()))
  )

const patientChangedEpic = (action$: any) =>
  action$.pipe(
    filter(changedTo(PES_DASHBOARD_REFERRAL_SCHEDULING_PATIENT)),
    pluck('payload', 'params'),
    mergeMap(({ appId, patientId }) =>
      of(appChanged(appId), patientChanged(patientId))
    )
  )

export default combineEpics(appChangedEpic, patientChangedEpic)
