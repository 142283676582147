import { combineEpics } from 'redux-observable'
import appEntered from './epics/appEntered'
import censusEntered from './epics/censusEntered'
import completePatientOutlierFinalDecisionSucceeded from './epics/completePatientOutlierFinalDecisionSucceeded'
import completePatientOutlierReviewSucceeded from './epics/completePatientOutlierReviewSucceeded'
import encounterReviewSelected from './epics/encounterReviewSelected'
import fetchEncounterReviewSucceeded from './epics/fetchEncounterReviewSucceeded'
import fetchPatientOutlierFinalDecisionSucceeded from './epics/fetchPatientOutlierFinalDecisionSucceeded'
import fetchPatientOutlierReviewSucceeded from './epics/fetchPatientOutlierReviewSucceeded'
import generateEncounterReviewPdfSucceeded from './epics/generateEncounterReviewPdfSucceeded'
import graduationEntered from './epics/graduationEntered'
import initializeCarePlan from './epics/initializeCarePlan'
import panelPageExited from './epics/panelPageExited'
import patientOutlierFinalDecisionSelected from './epics/patientOutlierFinalDecisionSelected'
import patientOutlierReviewSelected from './epics/patientOutlierReviewSelected'
import patientPanelOpened from './epics/patientPanelOpened'
import routeParams from './epics/routeParams'
import signEncounterReviewSucceeded from './epics/signEncounterReviewSucceeded'
import updateCarePlan from './epics/updateCarePlan'
import userIdSelected from './epics/userIdSelected'

export default combineEpics(
  appEntered,
  censusEntered,
  completePatientOutlierFinalDecisionSucceeded,
  completePatientOutlierReviewSucceeded,
  encounterReviewSelected,
  fetchEncounterReviewSucceeded,
  fetchPatientOutlierFinalDecisionSucceeded,
  fetchPatientOutlierReviewSucceeded,
  generateEncounterReviewPdfSucceeded,
  graduationEntered,
  initializeCarePlan,
  panelPageExited,
  patientOutlierFinalDecisionSelected,
  patientOutlierReviewSelected,
  patientPanelOpened,
  routeParams,
  signEncounterReviewSucceeded,
  updateCarePlan,
  userIdSelected
)
