import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useToggle } from '~/hooks'
import { Icon, IconButton, Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getPHIContacts } from '../../../data/patientInfo'

const useStyles = makeStyles(({ typography, spacing }) => ({
  button: {
    marginLeft: spacing(0.5),
  },
  icon: {
    fontSize: typography.body2.fontSize,
  },
  popover: {
    padding: spacing(1),
  },
}))

const PHIPopover = () => {
  const classes = useStyles()

  const phiContacts = useSelector(getPHIContacts)

  const [open, toggleOpen, toggleClosed] = useToggle()
  const buttonRef = useRef()

  return (
    <React.Fragment>
      <IconButton
        className={classes.button}
        size="small"
        buttonRef={e => {
          buttonRef.current = e
        }}
        onClick={toggleOpen}
        color="primary"
      >
        <Icon className={classes.icon}>security</Icon>
      </IconButton>

      <Popover
        open={open}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={toggleClosed}
      >
        <div className={classes.popover}>
          <Typography>Authorized Contacts:</Typography>

          {phiContacts.map(({ name, relationship }) => (
            <Typography key={`${name}-${relationship}`} variant="body2">
              {name} - {relationship}
            </Typography>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  )
}

export default PHIPopover
