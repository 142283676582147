import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import { getPatientInfo } from '~/features/patientInfo'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  addDefaults,
  caseMgmtIntegratedSortingColumnExtensions,
  caseMgmtMedListColumns,
  defaultSorting,
  integratedSortingColumnExtensions,
  medListColumns,
} from '../../utils/common'
import {
  ACTIVE,
  DELETED,
  INACTIVE,
  PENDING,
  RECENTLY_UPDATED,
  caremoreRxStatusMap,
} from '../../utils/medListConstants'
import FilterChips from './FilterChips'
import MedDetail from './MedDetail'

const styles = ({ spacing }) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: spacing(1),
    alignItems: 'center',
  },
  whiteSpace: {
    '& td': {
      whiteSpace: 'normal',
    },
  },
})

const MedListGrid = ({
  additionalGridOptions,
  classes,
  disabled,
  getMedById,
  GridActionsComponent,
  initialFilterState,
  medList,
  savePatientMedication,
}) => {
  const patientInfo = useSelector(getPatientInfo)
  const [filters, setFilters] = useState(initialFilterState)

  const shouldDisableButton = ({ source }) => {
    if (patientInfo.isCaseMgmt) {
      if (source == 'Legacy EMR' || source == 'Claims') {
        return true
      }
    }
    return false
  }

  const onCommitChanges = useCallback(
    ({ changed, deleted }) => {
      if (changed) {
        const [medId, changes] = Object.entries(changed)[0]
        const med = getMedById(medId)

        if (changes?.status) {
          savePatientMedication(med.merge(changes), undefined, true)
        } else {
          savePatientMedication(med.merge(changes))
        }
      }
      if (deleted) {
        const med = getMedById(deleted[0])

        savePatientMedication(med.merge({ status: 'Deleted' }), undefined, true)
      }
    },
    [getMedById, savePatientMedication]
  )

  const filteredPatientRxArray = useMemo(
    () =>
      medList
        .filter(
          x =>
            (filters[RECENTLY_UPDATED] && x.recentlyUpdated) ||
            filters[caremoreRxStatusMap.getIn([x.status, 'status'])]
        )
        .map(addDefaults),
    [medList, filters]
  )

  return (
    <React.Fragment>
      <span className={classes.flexContainer}>
        <FilterChips
          medList={medList}
          setFilters={setFilters}
          filters={filters}
          isCaseMgmt={patientInfo.isCaseMgmt}
        />
        {!disabled && <GridActionsComponent />}
      </span>
      <Paper>
        <DevXGrid
          shouldDisableEditButton={shouldDisableButton}
          shouldDisableDeleteButton={shouldDisableButton}
          className={classes.whiteSpace}
          rows={filteredPatientRxArray}
          columns={
            patientInfo.isCaseMgmt ? caseMgmtMedListColumns : medListColumns
          }
          columnExtensions={
            patientInfo.isCaseMgmt
              ? caseMgmtIntegratedSortingColumnExtensions
              : integratedSortingColumnExtensions
          }
          getRowId={row => row.id}
          searchable
          sortable
          groupable
          detailComponent={MedDetail}
          defaultGrouping={[{ columnName: 'category' }]}
          defaultSorting={defaultSorting}
          defaultExpandedGroups={[RECENTLY_UPDATED, PENDING, ACTIVE]}
          searchPlaceholder="Search Med List..."
          hideColumnsWhenGrouped
          pageable
          defaultPageSize={15}
          pageSizes={[15, 25, 50]}
          collapsible
          columnHideable
          indentColumnWidth={18}
          defaultHiddenColumnNames={[
            'primaryIndicationOther',
            'secondaryIndication',
            'secondaryIndicationOther',
          ]}
          localSettingsKey="patientMedList"
          {...(!disabled && {
            editable: true,
            hideAdd: true,
            onCommitChanges: onCommitChanges,
            ...additionalGridOptions,
          })}
        />
      </Paper>
    </React.Fragment>
  )
}

MedListGrid.propTypes = {
  additionalGridOptions: PropTypes.object,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  getMedById: PropTypes.func.isRequired,
  GridActionsComponent: PropTypes.func,
  initialFilterState: PropTypes.object,
  medList: PropTypes.array,
  savePatientMedication: PropTypes.func.isRequired,
}

MedListGrid.defaultProps = {
  additionalGridOptions: {},
  disabled: false,
  GridActionsComponent: () => {},
  initialFilterState: {
    [RECENTLY_UPDATED]: true,
    [PENDING]: true,
    [ACTIVE]: true,
    [INACTIVE]: false,
    [DELETED]: false,
  },
  medList: {},
}

export default withStyles(styles)(MedListGrid)
