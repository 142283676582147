import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { fetchPatientTimeline } from '~/features/patientTimeline/data'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { PATIENT_RECORD_PATIENT_INFO, PATIENT_RECORD_TIMELINE } from '../router'

const fetchStream = state$ => action$ =>
  action$.pipe(
    switchTo(state$),
    map(getPatientId),
    map(fetchPatientTimeline.requested)
  )

const timelineEpic = (action$, state$) =>
  action$.pipe(filter(entered(PATIENT_RECORD_TIMELINE)), fetchStream(state$))

const patientInfoEpic = (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_PATIENT_INFO)),
    fetchStream(state$)
  )

export default combineEpics(timelineEpic, patientInfoEpic)
