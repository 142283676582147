import { Route, Router } from 'redux-routable'
import key from './key'

export const RPM_DASHBOARD_RPM_APPROVAL = `${key}.rpm-approval`
export const RPM_DASHBOARD_COORDINATOR_APPROVAL = `${key}.coordinator-approval`

export default Router([
  Route(RPM_DASHBOARD_RPM_APPROVAL, '/rpm-approval'),
  Route(RPM_DASHBOARD_COORDINATOR_APPROVAL, '/coordinator-approval'),
])
