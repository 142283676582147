import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { mergeMap, pluck } from 'rxjs/operators'
import { PATIENT_RECORD_CALL } from '~/apps/patientRecord/router'
import { fetchForm } from '../data/form'
import { logCallStartedActionCreator } from '../data/logCall'

export default action$ =>
  action$.pipe(
    ofType(logCallStartedActionCreator),
    pluck('payload'),
    mergeMap(({ patientId, type }) => [
      fetchForm.requested(patientId, type),
      push(PATIENT_RECORD_CALL, { patientId }),
    ])
  )
