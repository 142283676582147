/*
  This styles function accepts optional styles, depending on if the user is on the patient panel "trends" tab, or the patient records vitals url. When JSS version 10 is out, we can add function logic to media queries. See: https://github.com/cssinjs/jss/issues/706
*/

export default (optionalStyles = {}) => ({ palette, spacing }) => ({
  card: {
    marginTop: spacing(2),
    overflow: 'hidden',
    '& th': {
      paddingLeft: spacing(3),
      paddingRight: 0,
    },
    '& td': {
      paddingLeft: spacing(3),
      paddingRight: 0,
    },
  },
  root: {
    margin: spacing(2),
    padding: spacing(2),
  },
  headerDark: {
    backgroundColor: '#3c3c3c',
    borderBottom: '1px solid #323232',
  },
  headerLight: {
    backgroundColor: palette.grey[200],
    borderBottom: '1px solid #e0e0e0',
  },
  header: {
    backgroundColor: palette.vitalsToolbarHeader.backgroundColor,
    borderBottom: [1, 'solid', palette.shades.dark],
    padding: [spacing(0), spacing(2)],
    width: '100%',
  },
  title: {
    color: palette.primary.main,
  },
  vitalsContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  chipContainer: {
    backgroundColor: palette.vitalsChipContainer.backgroundColor,
    padding: [10, 20],
  },
  chip: {
    border:
      palette.type === 'light'
        ? '1px solid rgba(0,0,0,0.4)'
        : '1px solid rgba(255,255,255,0.4)',
    borderRadius: 0,
    display: 'inline-block',
    margin: [spacing(1), 0],
    opacity: 0.4,
    width: '100%',
    '& span': {
      display: 'block',
      padding: [spacing(1), spacing(2)],
      textAlign: 'center',
    },
  },
  chipChosen: {
    backgroundColor: palette.vitalsChipChosen.backgroundColor,
    color: palette.vitalsChipChosen.color,
    opacity: 1,
    '&:focus': {
      backgroundColor: palette.vitalsChipChosen.backgroundColor,
    },
  },
  chartBlock: {
    display: 'inline-block',
    marginBottom: spacing(2),
  },
  noData: {
    alignItems: 'center',
    backgroundColor: palette.shades.dark,
    display: 'flex',
    height: '100%',
    minHeight: 100,
    justifyContent: 'center',
    width: '100%',
  },
  chart: {
    display: 'flex',
    justifyContent: 'center',
    margin: [spacing(2), 'auto', 0],
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    '& span': {
      color: palette.text.primary,
    },
    '& text': {
      fill: palette.text.primary,
    },
  },
  hintContainer: {
    borderRadius: 2,
    bottom: 20,
    left: 'calc(100% / 2)',
    padding: [spacing(1), spacing(2)],
    position: 'relative',
    '& h6': {
      color: palette.common.white,
    },
  },
  hintPointer: {
    content: '',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: '0 auto',
    width: 0,
    height: 0,
    borderTop: 'solid 10px',
    borderLeft: 'solid 10px transparent',
    borderRight: 'solid 10px transparent',
  },
  hintText: {
    color: palette.common.white,
    fontSize: '0.95rem',
  },
  legendContainer: {
    marginTop: spacing(2),
    textAlign: 'center',
  },
  legendBackground: {
    backgroundColor: palette.shades.dark,
    display: 'inline-block',
  },
  legend: {
    borderRadius: 4,
    flexWrap: 'wrap',
    margin: [0, 'auto'],
    padding: [spacing(1), spacing(1), 0],
    width: '78%',
    '& div': {
      display: 'block',
      padding: [spacing(1), spacing(2)],
    },
    '& div > span': {
      borderRadius: '50%',
      height: 14,
      marginBottom: spacing(1),
    },
  },
  actions: {
    flex: 'none',
    left: 12,
    position: 'relative',
  },
  ...optionalStyles,
})
