import cx from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  statisticsContainer: {
    display: 'grid',
    columnGap: spacing(2),
    marginBottom: spacing(2),
    [breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
    rowGap: `${spacing(2)}px`,
  },
  twoColumn: {
    gridTemplateColumns: '1fr 1fr',
  },
  threeColumn: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}))

type Props = {
  children: React.ReactElement | React.ReactElement[]
  dense?: boolean
  columns?: number
}

const StatisticCardContainer = ({
  children,
  dense = false,
  columns,
}: Props) => {
  const classes = useStyles()

  return (
    <div
      className={cx(classes.statisticsContainer, {
        [classes.twoColumn]: columns === 2,
        [classes.threeColumn]: columns === 3,
      })}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, { dense })
      )}
    </div>
  )
}

export default StatisticCardContainer
