import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatDate } from '~/utils/format'

const CAREPLAN_GOALS = 'carePlanGoals'

export const CareplanGoal = Record({
  id: null,
  label: null,
  statement: null,
  healthConcerns: null,
  interventions: null,
  outcomes: null,
  status: null,
  createdBy: null,
  modifiedBy: null,
  createdAt: null,
  modifiedAt: null,
})

const transformCareplanGoals = (careplanGoals: any) => {
  return List(
    careplanGoals.map((careplanGoal: any) => {
      return CareplanGoal({
        id: careplanGoal?.id,
        label: careplanGoal?.label,
        statement: careplanGoal?.statement,
        healthConcerns: careplanGoal?.healthConcerns,
        interventions: careplanGoal?.interventions,
        outcomes: careplanGoal?.outcomes,
        status: careplanGoal?.status ? 'Active' : 'Deactivated',
        createdBy: careplanGoal?.createdBy,
        modifiedBy: careplanGoal?.modifiedBy,
        createdAt: formatDate(careplanGoal?.createdAt),
        modifiedAt: formatDate(careplanGoal?.modifiedAt),
      })
    })
  )
}

export const fetchCarePlanGoals = Request({
  typePrefix: CAREPLAN_GOALS,
  typeBase: 'FETCH_CARE_PLAN_GOALS',
  operation: () => AspireAPI.get('admin_tools/goals'),
  transform: transformCareplanGoals,
  messages: { failed: 'Failed to fetch care plan goals' },
})

export const carePlanGoals = createReducer(CAREPLAN_GOALS, List(), {
  [fetchCarePlanGoals.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

export const getCarePlanGoals = pipe(getRoot, get(CAREPLAN_GOALS))
