export default ({ palette: { map } }) => [
  // Defaults
  {
    elementType: 'geometry',
    stylers: [{ color: map.geometry }],
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: map.labelsTextFill }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: map.labelsTextStroke }],
  },
  // Borders
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [{ color: map.borders }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry',
    stylers: [{ color: map.borders }],
  },
  // Points of Interest
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: map.poiGeometry }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  // Roads
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [{ color: map.roadGeometryFill }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  // Water
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: map.waterGeometry }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: map.waterLabelsTextFill }],
  },
  // Transit
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  },
]
