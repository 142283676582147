import { forEach, get, isObject } from 'lodash'
import { retrieveSchema } from 'react-jsonschema-form/lib/utils'

const formDataKeyToSchemaPath = key =>
  Number.isInteger(key) ? ['items'] : ['properties', key]

const deepEntries = (
  schema,
  value,
  definitions,
  schemaPath = [],
  valuePath = [],
  entries = []
) => {
  const resolvedSchema = schema
    ? retrieveSchema(schema, definitions, value)
    : schema

  entries.push({ schema: resolvedSchema, value, schemaPath, valuePath })

  if (isObject(value)) {
    forEach(value, (subValue, key) => {
      const subSchemaPath = formDataKeyToSchemaPath(key)
      const subSchema = get(resolvedSchema, subSchemaPath)

      deepEntries(
        subSchema,
        subValue,
        definitions,
        [...schemaPath, ...subSchemaPath],
        [...valuePath, key],
        entries
      )
    })
  }

  return entries
}

export default deepEntries
