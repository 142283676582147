import { addWeeks, format, startOfISOWeek } from 'date-fns/fp'
import { Map, Set } from 'immutable'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import { pipe } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Switch, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getDate } from '../../../data/calendar'
import { Recurrence, recurrenceChanged } from '../../../data/events'
import BaseField from '../BaseField'
import DateField from '../DateField'
import MultiSelectField from '../MultiSelectField'
import NumberField from '../NumberField'

const DATE_FORMAT = 'yyyy-MM-dd'

const RECURRING_DAYS = Map([
  [7, Map({ id: 7, text: 'Sun', summary: 'Su' })],
  [1, Map({ id: 1, text: 'Mon', summary: 'Mo' })],
  [2, Map({ id: 2, text: 'Tue', summary: 'Tu' })],
  [3, Map({ id: 3, text: 'Wed', summary: 'We' })],
  [4, Map({ id: 4, text: 'Thu', summary: 'Th' })],
  [5, Map({ id: 5, text: 'Fri', summary: 'Fr' })],
  [6, Map({ id: 6, text: 'Sat', summary: 'Sa' })],
])

const pluralize = count => (count == 1 ? '' : 's')

const useStyles = makeStyles({
  spacer: {
    flex: 1,
  },
})

const RecurrenceSettingsField = ({ editing, event }) => {
  const classes = useStyles()

  const date = useSelector(getDate)
  const changeRecurrence = useAction(recurrenceChanged)

  const recurrence = event.recurrence || Recurrence()

  const onToggleRecurrence = () => {
    // ERIC NOTE - CALENDAR WIDGET DATE SHOULD BE NATIVE JS DATE IN THE FUTURE
    const convertedDate = date.toDate()

    const startDate = pipe(startOfISOWeek(), format(DATE_FORMAT))(convertedDate)

    const endDate = pipe(
      addWeeks(4),
      startOfISOWeek(),
      format(DATE_FORMAT)
    )(convertedDate)

    // Need the funky ternary to convert JS value to ISO value
    const day = convertedDate.getDay()
    const days = day === 0 ? Set([7]) : Set([day])

    const payload = event.recurrence
      ? null
      : Recurrence({
          startDate,
          endDate,
          days,
        })
    changeRecurrence(payload)
  }

  const onRecurrenceChange = key => value => {
    changeRecurrence(recurrence.set(key, value))
  }
  const onStartDateChange = onRecurrenceChange('startDate')
  const onEndDateChange = onRecurrenceChange('endDate')
  const onDaysChange = onRecurrenceChange('days')
  const onPeriodChange = onRecurrenceChange('period')

  const periodSummary = `week${pluralize(recurrence.period)}`
  const recurrenceSummary = () => {
    const period = recurrence.period
    const days = recurrence.days

    const daysSummary = RECURRING_DAYS.filter((day, key) => days.includes(key))
      .map(day => day.get('summary'))
      .join(', ')

    return `Every ${period} ${periodSummary} on ${daysSummary}`
  }

  const isSelected = Boolean(event.recurrence)
  const error =
    !recurrence.startDate ||
    !recurrence.endDate ||
    moment(recurrence.startDate).isAfter(recurrence.endDate)

  return (
    <React.Fragment>
      <BaseField label="Recurring Event">
        <Switch
          disabled={!editing}
          checked={isSelected}
          onChange={onToggleRecurrence}
        />

        {isSelected && (
          <React.Fragment>
            <span className={classes.spacer} />
            <Typography variant="body1">{recurrenceSummary()}</Typography>
          </React.Fragment>
        )}
      </BaseField>

      {isSelected && (
        <React.Fragment>
          <NumberField
            label="Repeat Every"
            indent={1}
            value={recurrence.period}
            after={periodSummary}
            min={1}
            max={12}
            editing={editing}
            onChange={onPeriodChange}
          />

          <MultiSelectField
            label="Repeat On"
            indent={1}
            selected={recurrence.days}
            values={RECURRING_DAYS}
            editing={editing}
            onChange={onDaysChange}
          />

          <DateField
            error={error}
            label="Recurring Start"
            indent={1}
            value={recurrence.startDate}
            editing={editing}
            onChange={onStartDateChange}
          />

          <DateField
            error={error}
            label="Recurring End"
            indent={1}
            value={recurrence.endDate}
            editing={editing}
            onChange={onEndDateChange}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RecurrenceSettingsField.propTypes = {
  editing: PropTypes.bool,
  event: PropTypes.record.isRequired,
}

export default RecurrenceSettingsField
