import { List, Range } from 'immutable'
import moment from 'moment'

const FORMAT = 'ddd M/DD/YY'

// Convert to Moment from string with specified format
const momentFrom = date => moment(date, FORMAT)

export const fromDate = date => moment(date)

export const fromUTC = date => moment.utc(date).local()

export const formatTimestamp = date => fromUTC(date).format(FORMAT)

export const formatDate = date => moment(date).format(FORMAT)

export const sortByDate = (a, b) => momentFrom(a).diff(momentFrom(b), 'days')

export const todayOrFuture = date => {
  const diff = moment().diff(momentFrom(date), 'days', true)

  return {
    future: diff < 0,
    today: diff > 0 && diff <= 1, // diff is a float. will never === 1
  }
}

// Creates List of dates from first recorded to last recorded and every date in between
export const expandDates = dates => {
  if (dates.size < 2) {
    return dates
  }

  const firstDate = dates.first()
  const lastDate = dates.last()

  const diff = Math.abs(sortByDate(firstDate, lastDate))

  return Range(0, diff + 1)
    .map(day => formatTimestamp(momentFrom(firstDate).add(day, 'days')))
    .toList()
}

// Returns weeks/months/years
export const getTimeframes = (dates, timeframe) =>
  timeframe === 'day'
    ? dates
    : dates.reduce((all, date) => {
        const start = momentFrom(date).startOf(timeframe).format(FORMAT)
        return all.includes(start) ? all : all.push(start)
      }, List())

// Returns true if testDate is in the same timeframe
export const isSameTimeframe = (testDate, date, timeframe) =>
  testDate.isSame(momentFrom(date), timeframe)

// Create label data for PatientSwimlane table headers
export const labelBasedOnTimeframe = (date, timeframe) => {
  switch (timeframe) {
    case 'day':
      return date
    case 'week':
      return `Week of ${date}`
    case 'month':
      return momentFrom(date).format('MMM YYYY')
    case 'year':
      return momentFrom(date).format('YYYY')
  }
}
