import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { withTheme } from '@material-ui/core/styles'
import Marker from '../Marker'
import createIcon from './createIcon'
import createLabel from './createLabel'

const StarMarker = ({ theme, ...props }) => {
  const { color, scale, label } = props

  const icon = useMemo(
    () => StarMarker.renderIcon(theme, color, scale, label),
    [theme, color, scale, label]
  )

  return <Marker {...props} icon={icon} />
}

StarMarker.renderIcon = (theme, color, scale, label) =>
  createIcon({
    scale: scale * 0.8 * (label ? 1.66 : 1),
    size: { height: 24, width: 24 },
    icon: `
    <path fill="${color}" stroke="${
      theme.palette.map.markers.stroke
    }" stroke-width="1" d="
      M12 17.27
      L18.18 21
      l-1.64-7.03
      L22 9.24
      l-7.19-.61
      L12 2 9.19 8.63 2 9.24
      l5.46 4.73
      L5.82 21
      z"
    />${label ? createLabel(label, 12, 14) : ''}`,
  })

StarMarker.propTypes = {
  theme: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  scale: PropTypes.number,
  label: PropTypes.string,
}

StarMarker.defaultProps = {
  scale: 0.8,
  label: '',
}

export default withTheme(StarMarker)
