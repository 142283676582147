import { List, Set } from 'immutable'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/locationSearch' or ... Remove this comment to see the full error message
import { Location, getAddress } from '~/features/locationSearch'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { User, getRoot } from './common/shared'
import { Contract } from './contracts'
import { PermissionSet } from './permissionSets'
import { Role } from './roles'

const CURRENT_USER = 'currentUser'

const creator = scopedCreator(rootKey)
export const currentUserCleared = creator('CURRENT_USER_CLEARED', false)

export const transformUser = ({
  clinician = {},
  contracts = [],
  roleLinks = [],
  roles = [],
  permissionSetLinks = [],
  permissionSets = [],
  ...user
}) =>
  User({
    ...user,
    ...clinician,
    signatureDatetime:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'signatureDatetime' does not exist on typ... Remove this comment to see the full error message
      clinician.signatureDatetime &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'signatureDatetime' does not exist on typ... Remove this comment to see the full error message
      new Date(clinician.signatureDatetime).toISOString().slice(0, -1),
    status: user.active ? 'Active' : 'Inactive',
    location: user.street
      ? Location({ ...user, exact: true }).update((location: any) =>
          location.merge({ address: getAddress(location) })
        )
      : Location(),
    contracts: List(contracts.map(Contract)),
    roleIds: Set(roleLinks.map(({ roleId }) => roleId)),
    roles: List(roles.map(Role)),
    permissionSetIds: Set(
      permissionSetLinks.map(({ permissionSetId }) => permissionSetId)
    ),
    permissionSets: List(permissionSets.map(PermissionSet)),
  })

export const fetchUser = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_USER',
  requestParams: ['userId'],
  operation: (userId: any) => AspireAPI.get(`admin_tools/users/${userId}`),
  transform: transformUser,
  messages: { failed: 'There was an issue fetching this user' },
})

export const createUser = Request({
  typePrefix: rootKey,
  typeBase: 'CREATE_USER',
  requestParams: ['user'],
  operation: (user: any) => AspireAPI.post('admin_tools/users', { user }),
  transform: transformUser,
  messages: {
    succeeded: 'User successfully created',
    failed: 'There was an issue creating this user',
  },
})

export const saveUser = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_USER',
  requestParams: ['userId', 'user'],
  operation: (userId: any, user: any) =>
    AspireAPI.put(`admin_tools/users/${userId}`, { user }),
  transform: transformUser,
  messages: {
    succeeded: 'User successfully saved',
    failed: 'There was an issue saving this user',
  },
})

const initState = User()
export default createReducer(CURRENT_USER, initState, {
  [fetchUser.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [currentUserCleared]: () => initState,
})

export const getCurrentUser = pipe(getRoot, get(CURRENT_USER))
