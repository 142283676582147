import moment from 'moment'
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error no export
import DevXGrid from '~/components/DevXGrid'
import DisplayDate from '~/components/DisplayDate'
import DisplayDateTime from '~/components/DisplayDateTime'
import WithTooltip from '~/components/WithTooltip'
import { NominationReason, labels } from '~/fieldValues/nomination_reason'
import { useAction, useMountEffect } from '~/hooks'
import { Paper, Typography, makeStyles } from '@material-ui/core'
import { fetchNominations, getNominations } from '../data'
import Details from './Details'

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    marginBottom: spacing(2),
  },
  red: {
    '& > *': {
      color: 'red',
    },
  },
}))

const BOOLEAN_WIDTH = 115

const columns = [
  {
    name: 'nominatedBy',
    title: 'Nominated By',
  },
  {
    name: 'reason',
    title: 'Reason',
    getCellValue: ({ reason }: { reason: NominationReason }) => labels[reason],
    displayComponent: WithTooltip,
  },
  { name: 'program', title: 'Program' },
  {
    name: 'nominatedAt',
    title: 'Nomination Date',
    displayComponent: DisplayDateTime,
  },
  {
    name: 'targetDiscussionDate',
    title: 'Review Date',
    displayComponent: DisplayDate,
  },
  { name: 'notes', title: 'Nomination Notes' },
  {
    name: 'nextTargetedVisitDate',
    title: 'Primary NTVD',
    displayComponent: DisplayDate,
  },
  {
    name: 'lastVisitDate',
    title: 'Last Visit Date',
    displayComponent: DisplayDate,
  },
  {
    name: 'limitedPrognosis',
    title: 'Lim. Prog.',
    type: 'boolean',
  },
  {
    name: 'activeHospitalization',
    title: 'In Hospital',
    type: 'boolean',
  },
  {
    name: 'recentHospitalization',
    title: 'Recent Hosp. DC',
    type: 'boolean',
  },
  { name: 'activeSnf', title: 'In SNF', type: 'boolean' },
  {
    name: 'recentSnf',
    title: 'Recent SNF DC',
    type: 'boolean',
  },
  { name: 'overdue', title: 'Overdue', type: 'boolean' },
  { name: 'status', title: 'Status' },
  { name: 'openEoc', title: 'Open EOC', type: 'boolean' },
]

const columnExtensions = [
  { columnName: 'nominatedBy', width: 200 },
  { columnName: 'program', width: 200 },
  { columnName: 'nominatedAt', width: 150 },
  { columnName: 'lastVisitDate', width: 125 },
  { columnName: 'limitedPrognosis', width: BOOLEAN_WIDTH },
  { columnName: 'activeHospitalization', width: BOOLEAN_WIDTH },
  { columnName: 'recentHospitalization', width: 135 },
  { columnName: 'activeSnf', width: BOOLEAN_WIDTH },
  { columnName: 'recentSnf', width: 125 },
  { columnName: 'overdue', width: BOOLEAN_WIDTH },
  { columnName: 'status', width: 150 },
  { columnName: 'openEoc', width: BOOLEAN_WIDTH },
]

const pageSizes = [25, 50]

type Props = {
  patientId: string
  localSettingsKey: string
  rowActions: React.ReactNode
}

export default function Nominations({
  patientId,
  rowActions,
  localSettingsKey,
}: Props) {
  const classes = useStyles()

  const nominations: any = useSelector(getNominations)
  const requestNominations = useAction(fetchNominations.requested)

  const rows = useMemo(() => nominations.toIndexedSeq().toArray(), [
    nominations,
  ])

  const ref = useRef<any>(null)

  useMountEffect(() => {
    requestNominations(patientId)
  })

  return (
    <div>
      <Typography className={classes.header} color="primary" variant="h6">
        Nominations
      </Typography>

      <Paper ref={ref}>
        <DevXGrid
          columns={columns}
          columnExtensions={columnExtensions}
          rows={rows}
          rowActions={rowActions}
          rowStyles={(row: any) =>
            // red if today is after targetDiscussionDate
            moment(row.targetDiscussionDate) < moment() ? classes.red : ''
          }
          detailComponent={({ row }: any) => (
            <Details
              nominationId={row.id}
              gridWidth={ref.current?.offsetWidth}
            />
          )}
          localSettingsKey={localSettingsKey}
          columnHideable
          columnReorderable
          filterable
          groupable
          pageable
          pageSizes={pageSizes}
          defaultPageSize={25}
          searchable
          sortable
        />
      </Paper>
    </div>
  )
}
