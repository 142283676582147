import { connect } from 'react-redux'
import TaskDetail from '../components/TaskDetail'
import { CANCELLED, COMPLETED } from '../constants'
import { dialogOpened, reassignTaskDialogOpened } from '../data/dialog'
import {
  createTaskForm,
  syncContactsInTaskForm,
  taskFormErrored,
  taskFormUpdated,
  taskSaved,
} from '../data/tasks'

export default connect(
  (_state, { task = {} }) => ({
    readOnly: [CANCELLED, COMPLETED].includes(task.status),
  }),
  {
    createTaskForm: createTaskForm.requested,
    syncContactsInTaskForm: syncContactsInTaskForm.requested,
    dialogOpened,
    taskFormUpdated,
    taskFormErrored,
    taskSaved,
    reassignTaskDialogOpened,
  }
)(TaskDetail)
