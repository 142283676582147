import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { TransportationBenefits } from './interfaces'

// KEY
const key = 'benefit'

export const Benefits = Record({
  benefitId: null,
  benefitYear: null,
  classplanProductId: null,
  pbp: null,
  countyName: null,
  classplanProductName: null,
  destinationType: null,
  benefitLimit: null,
})

const transformPbp = (benefit: TransportationBenefits) =>
  benefit && benefit.classplanProductId
    ? benefit.classplanProductId.substring(
        benefit.classplanProductId.length - 3,
        benefit.classplanProductId.length
      )
    : ''

// TRANSFORMER
const transformBenefits = (results: [TransportationBenefits]) =>
  List(
    results?.map(benefit =>
      Benefits({
        benefitId: benefit?.benefitId,
        benefitYear: benefit?.benefitYear,
        classplanProductId: benefit?.classplanProductId,
        pbp: transformPbp(benefit),
        classplanProductName: benefit?.classplanProductName,
        destinationType: benefit?.destinationType,
        benefitLimit: benefit?.benefitLimit,
        countyName: benefit?.countyName,
      })
    )
  )

// REQUEST
export const fetchBenefits = Request({
  typePrefix: key,
  typeBase: 'FETCH_BENEFITS',
  requestParams: ['year', 'classplanProductName', 'countyName'],
  operation: (
    year: string,
    classplanProductName: string,
    countyName: string
  ) => {
    const product = classplanProductName
      ? `classplanProductName=${classplanProductName}`
      : ``
    const plan = countyName ? `countyName=${countyName}` : ``
    return AspireAPI.get(`transportation/benefits/${year}?${product}&${plan}`)
  },
  transform: transformBenefits,
  messages: {
    failed: 'Failed to fetch Transportation Benefits',
  },
})

// REDUCER
export const benefit = createReducer(key, List(), {
  [fetchBenefits.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

// SELECTORS
export const getBenefits = pipe(getRoot, get(key))
