import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { showError } from '~/utils/alertOnError'
import { saveComplete } from '../data/currentAttachments'
import {
  FILE_SELECTED,
  fetchSignedURL,
  newAttachmentUploaded,
  putFile,
  saveUploadedFile,
} from '../data/newAttachment'

const fileUploadRequested = ({
  faxToPCP,
  file,
  progressCallback,
  attachmentType,
  patientId,
  caseId,
}) =>
  from(
    fetchSignedURL({ file, patientId, caseId, attachmentType })
      .then(att => putFile(att, file, progressCallback))
      .then(att => saveUploadedFile(att, faxToPCP))
  ).pipe(
    map(saveComplete),
    catchError(showError(`Failed to upload ${file.name}`))
  )

export default action$ =>
  action$.pipe(
    ofType(FILE_SELECTED),
    mergeMap(payload =>
      concat(fileUploadRequested(payload), of(newAttachmentUploaded()))
    )
  )
