// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// CONSTANTS
export const FILTER_USER_ONLY_SET = 'filterUserOnly/FILTER_USER_ONLY_SET'
export const FILTER_USER_ONLY_TOGGLED =
  'filterUserOnly/FILTER_USER_ONLY_TOGGLED'

// ACTION CREATORS
export const filterUserOnlySet = (setTo: any) => ({
  type: FILTER_USER_ONLY_SET,
  setTo,
})
export const filterUserOnlyToggled = () => ({ type: FILTER_USER_ONLY_TOGGLED })

// KEY
export const key = 'filterUserOnly'

// REDUCER
export default createReducer(key, true, {
  [FILTER_USER_ONLY_SET]: (_state: any, { setTo }: any) => setTo,
  [FILTER_USER_ONLY_TOGGLED]: (state: any) => !state,
})

// SELECTORS
export const isFilterUserOnly = (state: any) =>
  getReferralManagement(state).get(key)
