import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import Input from './Input'
import debounce from './debounce'
import memoizedWrapper from './memoizedWrapper'
import { getInputProps } from './props'

const transformIn = value => value.slice(0, -8)
const transformOut = value => value + ':00.000Z'

const DateTimeWidget = props => (
  <Input
    {...getInputProps({ ...props, transformIn, transformOut })}
    fullWidth
    type="datetime-local"
  />
)

export default compose(memoizedWrapper, debounce)(DateTimeWidget)
