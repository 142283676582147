import * as O from 'fp-ts/lib/Option'
import { Action as ReduxA, State as ReduxS } from '~/reducerT'
import Aspire from '~/resources/aspire-strict'
import { Request } from '~/utils/Requestable'
import {
  getOrElse,
  RequestError,
  ServerErrorMessage,
} from '~/resources/resource-strict'
import * as Toast from '~/utils/toast'
import { Identification, State, Action as Finalization } from './finalizationT'
import { Action as LLOS } from '../LLOST'
import { PatientStats } from './patientStatsT'
import { flow } from 'fp-ts/lib/function'
import { payloadGet } from '~/utils/type'
import * as t from 'io-ts'

import * as DE from '@nll/datum/DatumEither'

export const index = (userId: string) =>
  Request<Identification[]>(
    Aspire.get(
      `/v1/llos/finalization`,
      t.array(Identification.strictType).asDecoder(),
      { params: { userId } }
    ),
    payload =>
      ReduxA.of.LLOS({
        payload: LLOS.of.Finalization({
          payload: Finalization.of.Patients({
            payload,
          }),
        }),
      }),
    { toast: Toast.error(_ => O.some("Couldn't get patient list.")) }
  )

export const new_ = (reviewId: number) =>
  Request<PatientStats>(
    Aspire.get(
      '/v1/llos/finalization/new',
      PatientStats.strictType.asDecoder(),
      { params: { reviewId } }
    ),
    payload =>
      ReduxA.of.LLOS({
        payload: LLOS.of.Finalization({
          payload: Finalization.of.Stats({ payload }),
        }),
      }),
    {
      toast: Toast.error(
        RequestError.matchStrict({
          FailedRequest: () => O.some("Couldn't fetch review details"),
          DecodeError: () => O.none,
          ServerError: flow(
            payloadGet,
            ServerErrorMessage.matchStrict({
              StringError: ({ message }) => message,
              VarseError: ({ message }) => message.join(', '),
              ChangesetError: ({ message }) => {
                console.error(message) // eslint-disable-line no-console
                return Object.entries(message)
                  .map(([k, v]) => `${k}${k ? '.' : ''}${v}`)
                  .join(', ')
              },
            }),
            O.some
          ),
        })
      ),
    }
  )

// const patients: ReducerT.selector(FinalizationT.index) =
//   x => x.llos.finalization.index

export const submit = (
  userId: string,
  reviewId: number,
  notes: string,
  recommendation: string
) =>
  Request<Identification[]>(
    Aspire.post(
      '/v1/llos/finalization',
      t.array(Identification.strictType).asDecoder(),
      {
        reviewId,
        notes,
        recommendation,
        userId,
      }
    ),
    payload =>
      ReduxA.of.LLOS({
        payload: LLOS.of.Finalization({
          payload: Finalization.of.Patients({
            payload,
          }),
        }),
      }),
    {
      toast: Toast.message(
        _ => 'Successfully submitted LLOS Review.',

        RequestError.matchStrict({
          FailedRequest: () => O.some("Couldn't submit finalization"),
          DecodeError: () => O.none,
          ServerError: flow(
            payloadGet,
            ServerErrorMessage.matchStrict({
              StringError: ({ message }) => message,
              VarseError: ({ message }) => message.join(', '),
              ChangesetError: ({ message }) => {
                console.error(message) // eslint-disable-line no-console
                return Object.entries(message)
                  .map(([k, v]) => `${k}${k ? '.' : ''}${v}`)
                  .join(', ')
              },
            }),
            O.some
          ),
        })
      ),
      reloader: ReduxS.lensFromPath(['llos', 'finalization', 'index']).get,
    }
  )

export const init: State = {
  index: DE.initial,
  currentIdentification: O.none,
  stats: DE.initial,
}

export const reducer: (_: Finalization) => (_: ReduxS) => ReduxS = flow(
  payloadGet,
  Finalization.matchStrict({
    Patients: ({ payload: index }) =>
      ReduxS.lensFromPath(['llos', 'finalization']).modify(old =>
        DE.isRepleteRight(index)
          ? {
              ...old,
              index,
              currentIdentification: O.none,
            }
          : DE.isPending(index)
          ? {
              ...old,
              stats: DE.toRefresh(old.stats),
            }
          : DE.isSuccess(old.index) && DE.isFailure(index)
          ? {
              ...old,
              stats: DE.isPending(old.stats)
                ? DE.toReplete(old.stats)
                : old.stats,
            }
          : { ...old, index }
      ),
    Highlight: ({ payload }) =>
      ReduxS.lensFromPath([
        'llos',
        'finalization',
        'currentIdentification',
      ]).set(O.some(payload)),
    Stats: flow(
      payloadGet,
      ReduxS.lensFromPath(['llos', 'finalization', 'stats']).set
    ),
  })
)

export const numOfReviews = flow(
  ReduxS.lensFromPath(['llos', 'finalization', 'index']).get,
  getOrElse<Identification[]>([]),
  a => a.length
)
