import React from 'react'
import JsonForm from '~/components/JsonForm'
import SyncContactsField from '~/components/JsonForm/fields/SyncContactsField'
import PropTypes from '~/utils/propTypes'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Form, getHistoricalFormDataByTag } from '../data/form'
import * as fields from './fields'

const styles = ({ spacing }) => ({
  container: {
    margin: [22, 0],
    padding: 16,
  },
  loading: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
})

const HistoricalCallForm = ({ classes, form }) =>
  form ? (
    <Paper className={classes.container} square>
      <JsonForm
        context={form.context}
        getFormDataByTag={getHistoricalFormDataByTag(form)}
        formData={form.formData}
        fields={{
          ...fields,
          syncContactsButton: () => SyncContactsField({ disabled: true }),
        }}
        schema={form.schema}
        uiSchema={{
          ...form.uiSchema,
          'ui:disabled': true,
        }}
      />
    </Paper>
  ) : (
    <Typography className={classes.loading} variant="h6">
      Loading Call Form
    </Typography>
  )

HistoricalCallForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.instanceOf(Form),
}

export default withStyles(styles)(HistoricalCallForm)
