import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { action, payload, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import PATIENT_TIMELINE from '../key'

// Key
export const key = 'swimlane'

// Actions
const creator = scopedCreator(PATIENT_TIMELINE)

export const swimlaneSet = creator('SWIMLANE_SET')
export const swimlaneCompressionToggled = creator(
  'SWIMLANE_COMPRESSION_TOGGLED'
)
export const swimlaneDisplayToggled = creator('SWIMLANE_DISPLAY_TOGGLED')
export const swimlaneOrderReversed = creator('SWIMLANE_ORDER_REVERSED')
export const swimlanePageChanged = creator('SWIMLANE_PAGE_CHANGED')
export const swimlaneRowsPerPageChanged = creator(
  'SWIMLANE_ROWS_PER_PAGE_CHANGED'
)
export const swimlaneTimeframeChanged = creator('SWIMLANE_TIMEFRAME_CHANGED')

// Reducer
export const SwimlaneState = Record({
  compressed: true,
  displayed: true,
  page: 0,
  reversed: false,
  rowsPerPage: 10,
  timeframe: 'day',
})

const toggle = key => state => state.update(key, value => !value)
const setFromPayload = key => (state, action) => state.set(key, action.payload)

export default createReducer(key, SwimlaneState(), {
  [swimlaneCompressionToggled]: toggle('compressed'),
  [swimlaneDisplayToggled]: toggle('displayed'),
  [swimlaneOrderReversed]: toggle('reversed'),
  [swimlanePageChanged]: setFromPayload('page'),
  [swimlaneRowsPerPageChanged]: setFromPayload('rowsPerPage'),
  [swimlaneSet]: compose(SwimlaneState, payload, action),
  [swimlaneTimeframeChanged]: setFromPayload('timeframe'),
})
