import { Record } from 'immutable'
import { ofType } from 'redux-observable'
import { debounceTime, map, pluck } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import hospitalizationsKey from '../key'

const key = 'suggestedHospitals'

export const Hospital = Record({
  id: null,
  name: null,
  phone: null,
  fax: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
})

export const fetchSuggestedHospitals = Request({
  typePrefix: key,
  typeBase: 'FETCH_SUGGESTED_HOSPITALS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`hospitalizations/hospitals/search`, {
      params: { search_word: searchWord },
    }),
  transform: result => result.map(Hospital),
  messages: { failed: 'There was a problem fetching suggested hospitals' },
})

const CHANGE_SUGGESTED_HOSPITAL_SEARCH_WORD = type(
  key,
  'CHANGE_SUGGESTED_HOSPITAL_SEARCH_WORD'
)
const CLEAR_SUGGESTED_HOSPITALS = type(key, 'CLEAR_SUGGESTED_HOSPITALS')

export const changeSuggestedHospitalSearchWord = creator(
  CHANGE_SUGGESTED_HOSPITAL_SEARCH_WORD,
  'searchWord'
)
export const clearSuggestedHospitals = creator(CLEAR_SUGGESTED_HOSPITALS)

export default createReducer(key, [], {
  [fetchSuggestedHospitals.SUCCEEDED]: (_state, { payload }) => payload,
  [CLEAR_SUGGESTED_HOSPITALS]: () => [],
})

const changeSuggestedHospitalSearchwordEpic = action$ =>
  action$.pipe(
    ofType(CHANGE_SUGGESTED_HOSPITAL_SEARCH_WORD),
    debounceTime(500),
    pluck('payload', 'searchWord'),
    map(fetchSuggestedHospitals.requested)
  )

export const epic = changeSuggestedHospitalSearchwordEpic

export const getSuggestedHospitals = pipe(get(hospitalizationsKey), get(key))
