import { Record } from 'immutable'
import { get as getIn } from 'lodash'
import { calcFormData, changeFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import ASSESSMENT from '../key'
import { signAssessment } from './assessments'
import { getAssessment } from './common/shared'

// Key
const key = 'manifest'
const typePrefix = `${ASSESSMENT}/${key}`
const creator = scopedCreator(typePrefix)

// Actions
export const clearManifest = creator('CLEAR')
export const formUpdated = creator('FORM_UPDATED')

export const Form = Record({
  context: {},
  errored: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
})

// Requests
export const fetchManifest = Request({
  typePrefix,
  typeBase: 'FETCH_MANIFEST',
  requestParams: ['encounterId'],
  operation: encounterId => AspireAPI.get(`encounters/${encounterId}/manifest`),
  messages: {
    failed: e =>
      getIn(e, 'response.data.message', 'Could not fetch action manifest.'),
  },
})

const transform = form =>
  Form({
    ...form,
    formData: calcFormData({
      formData: form.data,
      schema: form.schema,
      tags: form.tags,
    }),
  })

export default createReducer(key, null, {
  [fetchManifest.SUCCEEDED]: (_state, { payload }) => transform(payload),
  [clearManifest]: () => null,
  [signAssessment.SUCCEEDED]: () => null,
  [formUpdated]: (state, { payload }) =>
    state.update(form =>
      changeFormData(form, null, payload.formData).set(
        'errored',
        Boolean(payload.errors.length)
      )
    ),
})

// Selector
export const getManifest = pipe(getAssessment, get(key))

export const getManifestData = pipe(getAssessment, get(key), get('formData'))

export const getDisplayManifest = pipe(getAssessment, get(key), Boolean)
