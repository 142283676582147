import { paramsReducer } from 'redux-routable'
import { getUserId } from '~/data/session'
import { type } from '~/utils/data'
import rootKey from '../key'
import router from '../router'
import { getRoot } from './common/shared'

// KEY
const key = 'caseManagerDashboardAppId'

// ACTIONS
export const DASHBOARD_REFRESH_REQUESTED = type(
  rootKey,
  'DASHBOARD_REFRESH_REQUESTED'
)

// ACTION CREATORS
export const appDashRefreshRequested = userId => ({
  type: DASHBOARD_REFRESH_REQUESTED,
  payload: { userId },
})

// REDUCER
const reducer = paramsReducer(router, ({ appId }) => appId || null)

reducer.key = key

// SELECTORS
export const getSelectedDashApp = state =>
  getRoot(state).get(key) || getUserId(state)

export default reducer
