import { OrderedMap } from 'immutable'
import { Hospitalization } from '../data/hospitalizations'

export const getValuesFromHospitalization = (
  hospitalization = Hospitalization()
) =>
  OrderedMap({
    id: hospitalization.id,
    hospitalId: hospitalization.hospitalId,
    hospitalName: hospitalization.hospitalName,
    bedType: hospitalization.bedType,
    reason: hospitalization.reason,
    otherHospitalizationReason: hospitalization.otherHospitalizationReason,
    admissionDate: hospitalization.admissionDate,
    dischargeDate: hospitalization.dischargeDate,
    notifiedBy: hospitalization.notifiedBy,
    notifiedByOther: hospitalization.notifiedByOther,
    dischargedFromHospitalTo: hospitalization.dischargedFromHospitalTo,
    dischargedFromHospitalToOther:
      hospitalization.dischargedFromHospitalToOther,
    wasHospitalizationAvoidable: hospitalization.wasHospitalizationAvoidable,
    aspireContactedPrior: hospitalization.aspireContactedPrior,
    aspireContactedPriorNo: hospitalization.aspireContactedPriorNo,
    otherHospitalizationInfo: hospitalization.otherHospitalizationInfo,
    includedObservationStay: hospitalization.includedObservationStay,
    admissionDiagnoses: hospitalization.admissionDiagnoses,
    dischargeDiagnoses: hospitalization.dischargeDiagnoses,
    authorizationNumber: hospitalization.authorizationNumber,
  })

export const getHospitalizationFromValues = (
  values,
  hospitalization = Hospitalization()
) =>
  hospitalization.merge({
    id: values.get('id'),
    hospitalId: values.get('hospitalId'),
    hospitalName: values.get('hospitalName'),
    bedType: values.get('bedType'),
    reason: values.get('reason'),
    otherHospitalizationReason: values.get('otherHospitalizationReason'),
    admissionDate: values.get('admissionDate'),
    dischargeDate: values.get('dischargeDate'),
    notifiedBy: values.get('notifiedBy'),
    notifiedByOther: values.get('notifiedByOther'),
    dischargedFromHospitalTo: values.get('dischargedFromHospitalTo'),
    dischargedFromHospitalToOther: values.get('dischargedFromHospitalToOther'),
    wasHospitalizationAvoidable: values.get('wasHospitalizationAvoidable'),
    aspireContactedPrior: values.get('aspireContactedPrior'),
    aspireContactedPriorNo: values.get('aspireContactedPriorNo'),
    otherHospitalizationInfo: values.get('otherHospitalizationInfo'),
    includedObservationStay: values.get('includedObservationStay'),
    admissionDiagnoses: values.get('admissionDiagnoses'),
    dischargeDiagnoses: values.get('dischargeDiagnoses'),
    authorizationNumber: values.get('authorizationNumber'),
  })
