import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { filter, map, mergeMap, pluck } from 'rxjs/operators'
import { fetchAppointments } from '~/apps/assessment/data/appointments'
import { fetchAssessment } from '~/apps/assessment/data/assessments'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { fetchActionableItems } from '~/features/actionableItems'
import {
  fetchAttachmentTypes,
  fetchPatientAttachments,
} from '~/features/attachments'
import { fetchPatientAttachments as fetchNextGenPatientAttachments } from '~/features/attachmentsNextGen'
import { fetchPatientCareTeam } from '~/features/careTeamManagement'
import { fetchPatientRx } from '~/features/medications'
import { fetchNextTargetedVisitDates } from '~/features/nextTargetedVisitDate'
import {
  fetchPatient,
  getPatientLastEncounter,
  patientInfoCleared,
} from '~/features/patientInfo'
import { fetchPatientTimeline } from '~/features/patientTimeline/data'
import { fetchScreenings } from '~/features/screenings/data'
import { fetchStakeholders } from '~/features/stakeholders'
import { fetchVitals } from '~/features/vitals'
import { scopedCreator } from '~/utils/data'
import { reject, requestSucceeded, switchTo } from '~/utils/operators'
import key from '../key'

export const patientInfoRequested = scopedCreator(
  key
)('PATIENT_PANEL_DATA_REQUESTED', ['patientId'])

export default (action$, state$) =>
  action$.pipe(
    ofType(patientInfoRequested),
    pluck('payload', 'patientId'),
    mergeMap(patientId =>
      merge(
        of(
          patientInfoCleared(),
          fetchPatient.requested(patientId),
          fetchPatientTimeline.requested(patientId),
          fetchStakeholders.requested(patientId),
          fetchActionableItems.requested(patientId),
          fetchAppointments.requested(patientId),
          fetchPatientRx.requested(patientId),
          fetchPatientAttachments.requested(patientId),
          fetchAttachmentTypes.requested(),
          fetchNextGenPatientAttachments.requested(patientId),
          fetchVitals.requested(patientId),
          fetchPatientCareTeam.requested(patientId),
          fetchNextTargetedVisitDates.requested(patientId),
          fetchScreenings.requested(patientId),
          fetchDistinctFieldValues(['hospice_eligible', 'provider_prognosis'])
        ),
        action$.pipe(
          requestSucceeded(fetchPatient),
          switchTo(state$),
          map(getPatientLastEncounter),
          reject(({ type }) => !type || type.includes('legacy')),
          map(({ encounterId }) => {
            if (encounterId)
              return fetchAssessment.requested(encounterId, false)
            else return null
          }),
          filter(x => x)
        )
      )
    )
  )
