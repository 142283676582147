import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, type, creator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'
import { EncounterCodingStatus } from '~/fieldValues/encounter_coding_status'

const CODING_QUEUE_LIST = 'codingQueueList'
// ACTIONS
export const CODING_QUEUE_STATE_CLEARED = type(
  key,
  'CODING_QUEUE_STATE_CLEARED'
)

// ACTION CREATORS
export const codingQueueStateCleared = creator(CODING_QUEUE_STATE_CLEARED)

export const CodingQueueItem = Record({
  id: null,
  patientId: null,
  patientName: null,
  marketName: null,
  dateOfService: null,
  codingStatus: null,
  codedBy: null,
  encounterType: null,
  caseId: null,
})

export type CodingQueueItemType = {
  id: number
  patientId: string
  patientName: string
  marketName: string
  dateOfService: string
  codingStatus: EncounterCodingStatus
  codedBy: string
  encounterType: string
  caseId: string
}

export const transformCodingQueue = (encounters: Array<CodingQueueItemType>) =>
  List(encounters.map(CodingQueueItem))

export const fetchCodingQueue = Request({
  typePrefix: key,
  typeBase: 'FETCH_CODING_QUEUE_LIST',
  requestParams: ['configId'],
  operation: (configId: number) =>
    AspireAPI.get('coding_dashboard/queue_encounters', {
      params: { configId },
    }),
  transform: transformCodingQueue,
  messages: {
    failed: 'There was an issue fetching Coding Queue encounters',
  },
})

export const getCodingQueueList = pipe(getRoot, get(CODING_QUEUE_LIST))

export default createReducer(CODING_QUEUE_LIST, List(), {
  // @ts-expect-error fix me
  [codingQueueStateCleared]: () => List(),
  [fetchCodingQueue.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
