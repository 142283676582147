import React from 'react'
import { useSelector } from 'react-redux'
import { usePending } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { CircularProgress, Typography } from '@material-ui/core'
import {
  fetchCallAttempt,
  fetchCallLogStatus,
  getCallLog,
} from '../../data/callLog'
import { syncContacts } from '../../data/contacts'
import { fetchPatientInfo } from '../../data/patientInfo'
import ActiveCall from './ActiveCall'
import CallLogInfo from './CallLogInfo'
import CompleteAttemptButton from './CompleteAttemptButton'
import Contacts from './Contacts'
import Header from './Header'

const LogCall = ({ intentsFilter, defaultIntent }) => {
  const callLog = useSelector(getCallLog)

  const statusPending = usePending(fetchCallLogStatus)
  const attemptPending = usePending(fetchCallAttempt)
  const infoPending = usePending(fetchPatientInfo)
  const syncContactsPending = usePending(syncContacts)

  const loaded =
    callLog.patientId && !statusPending && !attemptPending && !infoPending

  return loaded ? (
    <React.Fragment>
      <Header />
      <CallLogInfo
        intentsFilter={intentsFilter}
        defaultIntent={defaultIntent}
      />

      {syncContactsPending ? (
        <CircularProgress />
      ) : (
        <>
          {callLog.activeContact ? <ActiveCall /> : <Contacts />}
          <CompleteAttemptButton />
        </>
      )}
    </React.Fragment>
  ) : callLog.patientId ? (
    <Typography variant="h6">Loading...</Typography>
  ) : null
}

LogCall.propTypes = {
  intentsFilter: PropTypes.array,
  defaultIntent: PropTypes.string,
}

export default LogCall
