import { ofType } from 'redux-observable'
import { from, merge } from 'rxjs'
import { catchError, map, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import { getMapBounds } from '../data/map'
import { MM_STATUS_SELECTED, getMMStatusName } from '../data/mmStatuses'
import { fetchMMPatients, loadPatients } from '../data/patients'
import showError from '../utils/showError'

const mmStatusSelectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(MM_STATUS_SELECTED),
    pluck('payload', 'statuses'),
    mergeMap(statuses =>
      loading(
        merge(
          ...statuses.map(id =>
            from(
              fetchMMPatients(getMMStatusName(id), getMapBounds(state$.value))
            ).pipe(map(loadPatients))
          )
        ).pipe(catchError(showError('Failed to fetch MM Patients.')))
      )
    )
  )

export default mmStatusSelectedEpic
