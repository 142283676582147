import cx from 'classnames'
import React, { PureComponent } from 'react'
import {
  WithAuthorized,
  actions as permissionActions,
} from '~/features/authorization'
import PropTypes from '~/utils/propTypes'
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import ActionDialog from './ActionDialog'

const actionStyles = ({ palette, spacing }) => ({
  alert: {
    marginBottom: spacing(2),
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  disabled: {
    '&.Mui-disabled': {
      backgroundColor: palette.background.default,
      color: palette.action.disabled,
    },
  },
  iconButtons: {
    display: 'flex',
  },
  iconButton: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    marginRight: spacing(2),
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  sideMargin: {
    marginLeft: spacing(1),
  },
  warning: {
    color: palette.severity.error.static,
  },
})

class ActionButtons extends PureComponent {
  state = {
    showActionDialog: false,
    action: null,
  }

  onAction = action => {
    action.requireReason || action.requireConfirmation
      ? this.setState({
          showActionDialog: true,
          action: action,
        })
      : this.props.onAction(action, null, null)
  }

  onClose = () => {
    this.setState({
      showActionDialog: false,
      action: null,
    })
  }

  onConfirmAction = (action, cancelReason, actionNote) => {
    this.props.onAction(action, cancelReason, actionNote)
    this.onClose()
  }

  render() {
    const {
      actions,
      cancellationReasons,
      classes,
      disabled,
      hasErrors,
      orderId,
      outOfNetworkReasonRequired,
      previewOrderPdf,
      status,
      substatus,
      unsavedChanges,
      vendorId,
    } = this.props

    const sortingArray = [
      'Resend Fax',
      'Send Fax (Auto)',
      'Send Fax (Manual)',
      'Change Vendor',
      'Confirm Vendor Received',
      'Patient Notified',
      'Delivery Date Confirmed',
      'Declined - Patient',
      'Declined - Vendor',
      'Decline',
      'Submit to NSS',
      'Approve - (Ready for APP Approval)',
      'Approve - (Ready for MD Approval)',
      'Approve (Send to MD/APP)',
      'Approve',
      'Approved - Submitted to Vendor',
      'Create',
      'Cancel',
      'Close - Success',
    ]

    const buttonDisabled = ['closed', 'cancelled'].includes(status)

    const newActions = actions
      .filter(n => n.label !== 'Fax Failed')
      .filter(n =>
        substatus === 'pending_automated_vendor_fax'
          ? n.label === 'Cancel'
          : true
      )
      .sort(
        (a, b) => sortingArray.indexOf(a.label) - sortingArray.indexOf(b.label)
      )

    return (
      <React.Fragment>
        {hasErrors && (
          <Alert className={classes.alert} severity="error">
            Form is currently invalid
          </Alert>
        )}
        <div className={classes.buttonContainer}>
          <div className={classes.iconButtons}>
            <Tooltip title="Create Preview PDF" key="orderPdf">
              <span>
                <IconButton
                  classes={{ root: cx({ [classes.disabled]: buttonDisabled }) }}
                  className={classes.iconButton}
                  disabled={buttonDisabled}
                  label="Preview PDF"
                  onClick={() => {
                    previewOrderPdf(orderId)
                  }}
                >
                  <Icon>file_copy</Icon>
                </IconButton>
              </span>
            </Tooltip>
          </div>

          <div className={classes.actionButtons}>
            {newActions.map(action => {
              const completeButtons = ['Cancel', 'Close - Success'].includes(
                action.label
              )
              const buttonColor =
                action.label === 'Close - Success'
                  ? 'primary'
                  : action.label === 'Cancel'
                  ? 'secondary'
                  : 'default'

              const Component = ({ authorized }) => (
                <Button
                  className={classes.sideMargin}
                  variant={completeButtons ? 'contained' : 'outlined'}
                  color={buttonColor}
                  key={action.key}
                  disabled={
                    (!authorized ||
                      disabled ||
                      outOfNetworkReasonRequired ||
                      unsavedChanges ||
                      (action.requireValidation && hasErrors) ||
                      (action.requireVendorSelection && !vendorId)) &&
                    !action.alwaysAvailable
                  }
                  onClick={() => this.onAction(action)}
                >
                  {action.label}
                  {action.requireVendorSelection && !vendorId
                    ? ' (Requires Vendor)'
                    : ''}
                  {!authorized ? ' (Requires Authorization)' : ''}
                </Button>
              )
              if (action.requiredPermission) {
                return (
                  <WithAuthorized
                    action={permissionActions.EDIT}
                    component={Component}
                    key={action.key}
                    object={action.requiredPermission}
                  />
                )
              } else {
                return <Component key={action.key} authorized />
              }
            })}
          </div>
        </div>
        <ActionDialog
          action={this.state.action}
          id={orderId}
          note
          onClose={this.onClose}
          onConfirm={this.onConfirmAction}
          open={this.state.showActionDialog}
          reasons={cancellationReasons}
        />
      </React.Fragment>
    )
  }
}

ActionButtons.propTypes = {
  actions: PropTypes.array,
  cancellationReasons: PropTypes.object,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool.isRequired,
  onAction: PropTypes.func,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  outOfNetworkReasonRequired: PropTypes.bool,
  previewOrderPdf: PropTypes.func,
  status: PropTypes.string,
  substatus: PropTypes.string,
  unsavedChanges: PropTypes.bool,
  vendorId: PropTypes.string,
}

export default withStyles(actionStyles)(ActionButtons)
