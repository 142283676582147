import { ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { appInitialized } from '~/data/root'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { fetchAPPsForGraduation } from '../data/appGraduation'
import { getCurrentUserId } from '../data/currentUserId'
import { PHYSICIAN_DASHBOARD_GRADUATION } from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    filter(entered(PHYSICIAN_DASHBOARD_GRADUATION)),
    combineLatest(action$.pipe(ofType(appInitialized))),
    switchTo(state$),
    map(getCurrentUserId),
    distinctUntilChanged(),
    map(fetchAPPsForGraduation.requested)
  )
