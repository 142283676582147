import { combineEpics, ofType } from 'redux-observable'
import { changedTo, entered, exited } from 'redux-routable'
import { of, timer } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { ownerTasksFetchRequested, taskSelected } from '~/features/tasks'
import {
  DASHBOARD_REFRESH_REQUESTED,
  referralManagementRefreshRequested,
} from '../data/dashUserId'
import router, { REFERRAL_MANAGEMENT_TASK_DETAIL } from '../router'

const REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes

const userIdChanged = (action$: any) =>
  action$.pipe(
    filter(changedTo(router)),
    pluck('payload', 'params', 'userId'),
    filter(userId => userId !== undefined),
    distinctUntilChanged(),
    switchMap(userId =>
      timer(0, REFRESH_INTERVAL).pipe(
        takeUntil(action$.pipe(filter(exited(router)))),
        mapTo(referralManagementRefreshRequested(userId))
      )
    )
  )

const refreshRequested = (action$: any) =>
  action$.pipe(
    ofType(DASHBOARD_REFRESH_REQUESTED),
    pluck('payload', 'userId'),
    mergeMap(userId => of(ownerTasksFetchRequested(userId)))
  )

const taskDetail = (action$: any) =>
  action$.pipe(
    filter(entered(REFERRAL_MANAGEMENT_TASK_DETAIL)),
    pluck('payload', 'params', 'taskId'),
    map(taskSelected)
  )

export default combineEpics(userIdChanged, refreshRequested, taskDetail)
