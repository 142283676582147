import { isAfter, isValid, parseISO } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { getFieldValues } from '~/data/fieldValues'
// @ts-expect-error no export
import { formatDate, formatPhoneNumberToUS } from '~/utils/format'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import {
  Box,
  Icon,
  Link,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  getPhoneTypeLabel,
  getContactPhoneInactiveReasonLabel,
} from '../../utils/utils'
import { MethodItem } from '../MethodItem'

const useStyles = makeStyles(theme => ({
  boxLeftMargin: { marginLeft: theme.spacing(1) },
  strikeThrough: { textDecoration: 'line-through' },
  methodInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  typeStyle: { flexBasis: theme.spacing(12) },
  boldLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
    display: 'inline-block',
  },
  inlineElement: {
    display: 'inline-block',
  },
}))

interface PhoneItemPropTypes {
  phone: PropTypes.object.isRequired
  isCaremoreBrand: PropTypes.Boolean
}

export const PhoneItem = ({ phone, isCaremoreBrand }: PhoneItemPropTypes) => {
  const classes = useStyles()
  const phoneTypeFields = useSelector(getFieldValues(['contact_phone_type']))
  const contactPhoneInactiveReason = useSelector(
    getFieldValues(['contact_phone_inactive_reason'])
  )
  const expirationMessage = `${'Expiration: '}${formatDate(
    phone.hipaaAuthExpiration
  )}`

  const valid =
    isValid(parseISO(phone?.hipaaAuthExpiration)) &&
    isAfter(parseISO(phone?.hipaaAuthExpiration), new Date())

  const isMasked = phone?.cpsuiStatus === 'reassigned_number'
  const phoneFormatted =
    phone?.phoneFormatted || formatPhoneNumberToUS(phone.phone, isMasked)

  return (
    <MethodItem isPrimary={phone.primary}>
      <div className={classes.methodInfoContainer}>
        <Box clone={true} flex="1">
          <Typography>
            <Link
              href={'tel:' + phone.phone}
              color="inherit"
              className={phone.active ? '' : classes.strikeThrough}
            >
              {phoneFormatted}
            </Link>
          </Typography>
        </Box>
        {!phone.active && isCaremoreBrand && (
          <Box flex="1">
            <Typography className={classes.boldLabel}>
              Phone# Deactivated Due to
            </Typography>
            <Typography className={classes.inlineElement}>
              {getContactPhoneInactiveReasonLabel(
                contactPhoneInactiveReason,
                phone.inactiveReason || null
              )}
            </Typography>
          </Box>
        )}
        <Box flex="1">
          <Typography className={classes.boldLabel}>Source</Typography>
          <Typography className={classes.inlineElement}>
            {phone.sourceLabel}
          </Typography>
        </Box>
        <Box flex="1">
          <Typography className={classes.boldLabel}>CPSUI Status</Typography>
          <Typography className={classes.inlineElement}>
            {phone.cpsuiStatusLabel}
          </Typography>
        </Box>
        <Box className={classes.typeStyle}>
          {getPhoneTypeLabel(phoneTypeFields, phone.type || null)}
        </Box>
        <Box className={classes.boxLeftMargin}>
          {phone.hipaaAuthExpiration ? (
            <Tooltip title={expirationMessage}>
              <Icon color={valid ? 'primary' : 'disabled'}>security</Icon>
            </Tooltip>
          ) : (
            <Icon />
          )}
        </Box>
        <Icon> {phone.phiVoicemailApproved ? 'voicemail' : ''} </Icon>
        <Icon> {phone.textingApproved ? 'chat' : ''} </Icon>
        {phone.createdAt}
      </div>
    </MethodItem>
  )
}
