import { get as getIn } from 'lodash'
import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error fix me
import Request, { flattenErrors } from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { transformCodingQueue } from './codingQueue'
import { getRoot } from './common/shared'

const BATCH_CREATION_ENCOUNTERS = 'batchCreationEncounters'

// ACTIONS
export const BATCH_CREATION_ENCOUNTERS_CLEARED = type(
  key,
  'BATCH_CREATION_ENCOUNTERS_CLEARED'
)

// ACTION CREATORS
export const batchCreationEncountersCleared = creator(
  BATCH_CREATION_ENCOUNTERS_CLEARED
)

export const fetchBatchCreationEncounters = Request({
  typePrefix: key,
  typeBase: 'FETCH_BATCH_CREATION_ENCOUNTERS',
  requestParams: ['configId'],
  operation: (configId: number) =>
    AspireAPI.get('coding_dashboard/batch_creation_encounters', {
      params: { configId },
    }),
  transform: transformCodingQueue,
  messages: {
    failed: 'There was an issue fetching Batch Creation encounters',
  },
})

export const createBatch = Request({
  typePrefix: key,
  typeBase: 'CREATE_BATCH',
  requestParams: ['configId', 'batchTitle', 'batchDescription', 'caseIds'],
  operation: (
    configId: number,
    batchTitle: string,
    batchDescription: string,
    caseIds: string[]
  ) =>
    AspireAPI.post(`claims/batch`, {
      configId,
      batchTitle,
      batchDescription,
      caseIds,
    }),
  messages: {
    succeeded: 'Batch created successfully!',
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was an issue creating the batch',
  },
})

export const getBatchCreationEncounters = pipe(
  getRoot,
  get(BATCH_CREATION_ENCOUNTERS)
)

export default createReducer(BATCH_CREATION_ENCOUNTERS, List(), {
  // @ts-expect-error fix me
  [batchCreationEncountersCleared]: () => List(),
  [fetchBatchCreationEncounters.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
})
