import { OrderedMap, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const APP_OPTIONS = 'appOptions'

export const AppOption = Record({
  value: null,
  label: null,
  appTimeZone: null,
})

const transformAppOptions = (data: any) =>
  into(AppOption, 'value', OrderedMap)(data)

export const fetchAppOptions = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_APP_OPTIONS',
  operation: () => AspireAPI.get('v1/np/app_options'),
  transform: transformAppOptions,
  messages: {
    failed: 'There was an issue fetching the APP options',
  },
})

export const getAppOptions = pipe(getRoot, get(APP_OPTIONS))
export default createReducer(APP_OPTIONS, OrderedMap(), {
  [fetchAppOptions.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})
