import { combineEpics } from 'redux-observable'
import EMRDupeCheck from './epics/EMRDupeCheck'
import odsLookup from './epics/OdsLookup'
import activeFilter from './epics/activeFilter'
import additionalReferralSearch from './epics/additionalReferralSearch'
import appData from './epics/appData'
import appEntered from './epics/appEntered'
import appSearch from './epics/appSearch'
import archiveCase from './epics/archiveCase'
import assignment from './epics/assignment'
import caseManagerSearch from './epics/caseManagerSearch'
import channelSearch from './epics/channelSearch'
import deleteContact from './epics/deleteContact'
import loadActiveReferral from './epics/loadActiveReferral'
import loadCaseNotes from './epics/loadCaseNotes'
import loadDetails from './epics/loadDetails'
import loadFilters from './epics/loadFilters'
import loadReferralCases from './epics/loadReferralCases'
import loadTasksForUser from './epics/loadTasksForUser'
import manualAssignment from './epics/manualAssignment'
import patientReferralsLookup from './epics/patientReferralsLookup'
import physicianSearch from './epics/physicianSearch'
import referringEntitySearch from './epics/referringEntitySearch'
import routeParams from './epics/routeParams'
import saveAdditionalReferral from './epics/saveAdditionalReferral'
import saveCaseNote from './epics/saveCaseNote'
import saveNewReferral from './epics/saveNewReferral'
import statusUpdate from './epics/statusUpdate'
import submitReferral from './epics/submitReferral'
import uploadPolling from './epics/uploadPolling'

export default combineEpics(
  activeFilter,
  additionalReferralSearch,
  appData,
  appEntered,
  appSearch,
  archiveCase,
  assignment,
  caseManagerSearch,
  channelSearch,
  deleteContact,
  EMRDupeCheck,
  loadActiveReferral,
  loadCaseNotes,
  loadDetails,
  loadFilters,
  loadReferralCases,
  loadTasksForUser,
  manualAssignment,
  odsLookup,
  patientReferralsLookup,
  physicianSearch,
  referringEntitySearch,
  routeParams,
  saveAdditionalReferral,
  saveCaseNote,
  saveNewReferral,
  statusUpdate,
  submitReferral,
  uploadPolling
)
