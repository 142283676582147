import { useEffect, useRef } from 'react'

export default (effect: any, deps: any, cleanup?: any) => {
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) effect()
    else mounted.current = true

    return cleanup
  }, deps)
}
