// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getNotificationList } from './root'

const STATUS = 'status'

const creator = scopedCreator(rootKey)
export const statusSet = creator('STATUS_SET', ['status'])

export default createReducer(STATUS, 'all', {
  // @ts-expect-error can we deal with the toString being automatically called?
  [statusSet]: (_state: any, { payload: { status } }: any) => status,
})

export const getStatus = pipe(getNotificationList, get(STATUS))
