import React from 'react'
import { TimeField as CommonTimeField } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import BaseField from './BaseField'

const TimeField = props => {
  const { disabled, onBlur, onChange, value } = props

  return (
    <BaseField {...props}>
      <CommonTimeField
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
    </BaseField>
  )
}

TimeField.propTypes = {
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default TimeField
