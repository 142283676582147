import React, { PureComponent } from 'react'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  popover: {
    padding: '16px',
  },
  popoverButton: {
    'margin-right': '8px',
  },
})

// TODO: Create dashboard for Claims
class ConfirmationButton extends PureComponent {
  state = {
    popoverOpen: false,
  }

  buttonRef = ref => (this.button = ref)

  handleClickButton = () => {
    this.setState({
      popoverOpen: true,
    })
  }

  handleRequestClose = () => {
    this.setState({
      popoverOpen: false,
    })
  }

  onConfirm = () => {
    this.handleRequestClose()
    this.props.actionOnConfirm()
  }

  render = () => {
    const {
      classes,
      message,
      buttonLabel,
      caption,
      confirmationButtonText,
    } = this.props
    const { ...buttonProps } = this.props.buttonProps
    return (
      <div className={this.props.className}>
        <div>
          <Button
            buttonRef={this.buttonRef}
            data-track-content
            data-content-name={buttonLabel}
            {...buttonProps}
            onClick={() => this.handleClickButton()}
          >
            {buttonLabel}
          </Button>
          {caption ? (
            <Typography align="center" variant="caption">
              {caption}
            </Typography>
          ) : (
            ''
          )}
        </div>
        <Popover
          open={this.state.popoverOpen}
          anchorEl={this.button}
          onClose={this.handleRequestClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div
            data-track-content
            data-content-name="Confirmation Popover"
            className={classes.popover}
          >
            <Typography className={classes.warningContent}>
              {message || 'Are you sure?'}
            </Typography>
            <br />
            <Button
              data-content-piece="cancel"
              className={classes.popoverButton}
              color="secondary"
              onClick={this.handleRequestClose}
            >
              <Icon className={classes.popoverButton}>cancel</Icon>
              Cancel
            </Button>
            <Button
              data-content-piece="confirm"
              className={classes.popoverButton}
              color="primary"
              onClick={() => this.onConfirm()}
            >
              <Icon className={classes.popoverButton}>done</Icon>
              {confirmationButtonText ? confirmationButtonText : 'Confirm'}
            </Button>
          </div>
        </Popover>
      </div>
    )
  }
}

ConfirmationButton.propTypes = {
  classes: PropTypes.object,
  actionOnConfirm: PropTypes.func,
  message: PropTypes.string,
  confirmationButtonText: PropTypes.string,
  buttonLabel: PropTypes.string,
  caption: PropTypes.string,
  className: PropTypes.string,
  buttonProps: PropTypes.object,
}

const ConfirmationButtonStyled = withStyles(styles)(ConfirmationButton)
export default ConfirmationButtonStyled
