import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'timelineEntryDialog'

export const getDialogOpenValue = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const createTimelineEntryDialogOpened = creator(
  'CREATE_TIMELINE_ENTRY_DIALOG_OPENED'
)
export const createTimelineEntryDialogClosed = creator(
  'CREATE_TIMELINE_ENTRY_DIALOG_CLOSED'
)

// REQUEST
export const saveManualTimelineEntry = Request({
  typePrefix: key,
  typeBase: 'SAVE_MANUAL_TIMELINE_ENTRY',
  requestParams: ['patientId', 'timelineEntry'],
  operation: (patientId, timelineEntry) =>
    AspireAPI.post(`v1/patient/${patientId}/timeline`, { timelineEntry }),
  messages: {
    succeeded: 'Please wait while the Timeline refreshes',
    failed: 'Could not save new timeline entry',
  },
})

// REDUCER
export default createReducer(key, false, {
  [createTimelineEntryDialogClosed]: () => false,
  [createTimelineEntryDialogOpened]: () => true,
  [saveManualTimelineEntry.SUCCEEDED]: () => false,
})
