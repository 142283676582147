import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { withStyles } from '@material-ui/core/styles'
import BooleanDisplayCell from './customControls/BooleanDisplayCell'

const styles = {
  wrapText: {
    whiteSpace: 'normal',
  },
}

const displayComponents = {
  boolean: BooleanDisplayCell,
}

const DisplayCell = props => {
  const {
    actions,
    column: { displayComponent, wrapText },
    className,
    classes,
    ...passThroughProps
  } = props

  if (displayComponent) {
    return (
      <Table.Cell
        className={className}
        classes={wrapText && { cell: classes.wrapText }}
      >
        <props.column.displayComponent {...passThroughProps} />
      </Table.Cell>
    )
  }

  const CustomDisplay = displayComponents[props.column.type]
  if (CustomDisplay) {
    return (
      <Table.Cell
        className={className}
        classes={wrapText && { cell: classes.wrapText }}
      >
        <CustomDisplay {...passThroughProps} />
      </Table.Cell>
    )
  }

  return (
    <Table.Cell
      className={className}
      classes={wrapText && { cell: classes.wrapText }}
      {...passThroughProps}
    />
  )
}

DisplayCell.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.object,
  actions: PropTypes.func,
}

export default withStyles(styles)(DisplayCell)
