import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core'

const useStyles = makeStyles(({ palette, spacing }) => ({
  margin: {
    backgroundColor: palette.shades.dynamicLight,
    margin: spacing(2, 0),
    padding: spacing(2, 2, 1),
  },
}))

type Props = {
  setOutOfNetworkReason: (arg: any) => any
  outOfNetworkReason: (arg: string) => any
}

const ReasonForSelectionOptions = ({
  setOutOfNetworkReason,
  outOfNetworkReason,
}: Props) => {
  const classes = useStyles()

  const handleChange = (value: any) => setOutOfNetworkReason(value)

  return (
    <div className={classes.margin}>
      <FormControl required>
        <FormLabel id="oon-selection-reason">
          Reason for Out Of Network selection?
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="oon-selection-reason"
          name="oon-selection-options"
          defaultValue="patient_preference"
          value={outOfNetworkReason}
          onChange={event => handleChange(event.target.value)}
        >
          <FormControlLabel
            value="patient_preference"
            control={<Radio />}
            label="Patient Preference"
          />
          <FormControlLabel
            value="pcp_preference"
            control={<Radio />}
            label="PCP Preference"
          />
          <FormControlLabel
            value="network_hospice_unavailable"
            control={<Radio />}
            label="Network Hospice Unavailable"
          />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default ReasonForSelectionOptions
