import { entered } from 'redux-routable'
import { filter, map } from 'rxjs/operators'
import { PATIENT_RECORD_EPISODES_OF_CARE } from '~/apps/patientRecord/router'
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_EPISODES_OF_CARE)),
    switchTo(state$),
    map(getPatientId),
    map(patientId => fetchEpisodesOfCare.requested({ patientId }))
  )
