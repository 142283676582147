import React from 'react'
import { Link } from 'redux-routable-react'
import {
  PATIENT_RECORD_HISTORY_ASSESSMENTS,
  PATIENT_RECORD_PATIENT_INFO,
} from '~/apps/patientRecord/router'
import { formatDate } from '~/utils/format'
import { getProgramEnrolledAbbreviation } from '~/utils/programInfo'
import PropTypes from '~/utils/propTypes'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing, palette }) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  listItem: {
    borderBottom: `1px solid ${palette.shades.dark}`,
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
  undecoratedButton: {
    color: palette.text.primary,
    textDecoration: 'none',
  },
  paddedIcon: { marginRight: spacing(1) },
  secondaryTextSpan: { display: 'block' },
  searchResult: {
    alignItems: 'center',
    display: 'flex',
  },
  enrolled: {
    alignItems: 'center',
    backgroundColor: palette.patientSearchProgramBadge.backgroundColor,
    borderRadius: 4,
    color: palette.patientSearchProgramBadge.color,
    display: 'flex',
    fontSize: '1.2em',
    fontWeight: 'bold',
    justifyContent: 'center',
    margin: [0, 16, 0, 0],
    minHeight: 60,
    minWidth: 60,
    padding: [4, 8],
  },
})

const getPatientRecordRoute = () => {
  const path = window.location.pathname
  if (path.includes('coder-dashboard')) {
    return PATIENT_RECORD_HISTORY_ASSESSMENTS
  }
  return PATIENT_RECORD_PATIENT_INFO
}

const buildListItems = (searchResults, selectItem, linkDisabled, classes) =>
  searchResults.map(patient => {
    const name = patient.get('fullName')
    const dob = formatDate(patient.get('birthdate'))
    const market = patient.get('market')
    const status = patient.get('status')
    const substatus = patient.get('subStatus')
    const internalSubstatus = patient.get('internalSubStatus')
    const assignedApp = patient.get('appName')
    const programEnrolled = patient.get('programEnrolled')
    const programEnrolledAbbreviation = getProgramEnrolledAbbreviation(
      programEnrolled
    )

    const primaryText = `${name} (${dob})`

    const patientRecordRoute = getPatientRecordRoute()

    const secondaryText = (
      <React.Fragment>
        <span className={classes.secondaryTextSpan}>
          Status: {status}/{substatus}
          {internalSubstatus ? `/${internalSubstatus}` : ''}
        </span>
        <span className={classes.secondaryTextSpan}>
          Program: {programEnrolled}
        </span>
        <span className={classes.secondaryTextSpan}>
          APP: {assignedApp} ({market})
        </span>
      </React.Fragment>
    )

    return (
      <ListItem
        button
        className={classes.listItem}
        key={patient.get('id')}
        onClick={() => selectItem(patient.get('id'))}
      >
        {linkDisabled ? (
          <ListItemText primary={primaryText} secondary={secondaryText} />
        ) : (
          <Link
            action="open"
            className={classes.undecoratedButton}
            route={patientRecordRoute}
            params={{ patientId: patient.get('id') }}
          >
            <div className={classes.searchResult}>
              <div className={classes.enrolled}>
                {programEnrolledAbbreviation || ''}
              </div>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </div>
          </Link>
        )}
      </ListItem>
    )
  })

class BasicSearchResult extends React.PureComponent {
  render() {
    const { searchResults, selectItem, linkDisabled, classes } = this.props
    const rows = buildListItems(
      searchResults,
      selectItem,
      linkDisabled,
      classes
    )

    const hasResultsToDisplay = searchResults.size > 0
    if (!hasResultsToDisplay) return null

    return <List dense>{rows}</List>
  }
}

BasicSearchResult.propTypes = {
  classes: PropTypes.object,
  selectItem: PropTypes.func,
  searchResults: PropTypes.list,
  linkDisabled: PropTypes.bool,
}

export default withStyles(styles)(BasicSearchResult)
