import { paramsReducer } from 'redux-routable'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { ASSESSMENT_FORM } from '../router'
import { getAssessment } from './common/shared'

const key = 'currentAssessmentId'

const reducer = paramsReducer(ASSESSMENT_FORM, ({ assessmentId }) =>
  Number(assessmentId)
)

reducer.key = key

export const getCurrentAssessmentId = pipe(getAssessment, get(key))

export default reducer
