import { List, Map, fromJS } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import {
  ACTIVE_REFERRAL_CASE_STORED,
  ADDITIONAL_REFERRAL_SEARCH,
  ADDITIONAL_REFERRAL_SELECTED,
  CASE_ID_CHANGED,
  CHANNEL_SELECTION_DIALOG_REQUESTED,
  CLOSE_CHANNEL_SELECTION_DIALOG,
  DUPE_CHECK_COMPLETED,
  EMR_DUPLICATES_FOUND,
  NEW_CASE_CREATED,
  NEW_REFERRAL_FORM_RESET,
  NEW_REFERRAL_ODS_LOOKUP_REQUESTED,
  NEW_REFERRAL_VALIDATION_REQUESTED,
  ODS_LOOKUP_COMPLETED,
  ODS_LOOKUP_MATCHES_FOUND,
  PATIENT_EXISTING_REFERRALS_FETCHED,
  POLL_FOR_CASE_SYNC_COMPLETION_START,
  POLL_FOR_CASE_SYNC_COMPLETION_STOP,
  POTENTIAL_CARE_TEAMS_UPDATED,
  POTENTIAL_CHANNELS_UPDATED,
  RESET_DUPE_CHECK,
  STATUS_UPDATE_COMPLETED,
} from './constants'

const initState = {
  newReferral: fromJS({
    potentialDuplicates: [],
    duplicateCheckComplete: false,
    newCaseId: null,
    searchingOds: false,
  }),
}

const newReferral = createReducer('newReferral', initState.newReferral, {
  [EMR_DUPLICATES_FOUND]: (state: any, { data }: any) =>
    state.set('potentialDuplicates', fromJS(data)),
  [DUPE_CHECK_COMPLETED]: (state: any) =>
    state.set('duplicateCheckComplete', true),
  [RESET_DUPE_CHECK]: (state: any) =>
    state.merge({
      duplicateCheckComplete: false,
      potentialDuplicates: fromJS([]),
    }),
  [NEW_REFERRAL_VALIDATION_REQUESTED]: (state: any) =>
    state.set('duplicateCheckComplete', false),
  [NEW_REFERRAL_ODS_LOOKUP_REQUESTED]: (state: any) =>
    state.set('odsMatches', null).set('searchingOds', true),
  [ODS_LOOKUP_MATCHES_FOUND]: (state: any, { results }: any) =>
    state.set('odsMatches', fromJS(results)).set('searchingOds', false),
  [ODS_LOOKUP_COMPLETED]: (state: any) => state.set('searchingOds', false),
  [NEW_REFERRAL_FORM_RESET]: () => initState.newReferral,
  [NEW_CASE_CREATED]: (state: any, { caseId }: any) =>
    state.set('newCaseId', caseId),
})

const activeCase = createReducer('activeCase', Map(), {
  [CASE_ID_CHANGED]: (_state: any, { payload: caseId }: { payload: number }) =>
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    new Map({ caseId: Number(caseId) }),

  [ACTIVE_REFERRAL_CASE_STORED]: (_state: any, { data }: any) => fromJS(data),
  [STATUS_UPDATE_COMPLETED]: (state: any, { status, substatus }: any) =>
    state.mergeWith((oldVal: any, newVal: any) => newVal || oldVal, {
      status,
      substatus,
    }),
})

const additionalReferral = createReducer('additionalReferral', Map(), {
  [ADDITIONAL_REFERRAL_SEARCH]: (state: any) =>
    state.set('selectedPatientId', null),
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'patientId' implicitly has an 'any... Remove this comment to see the full error message
  [ADDITIONAL_REFERRAL_SELECTED]: (state: any, { payload: patientId }) =>
    state.set('selectedPatientId', patientId),
  [PATIENT_EXISTING_REFERRALS_FETCHED]: (state: any, { payload }: any) =>
    state.set('selectedPatient', fromJS(payload)),
})

const assignment = createReducer('assignment', Map(), {
  [POTENTIAL_CHANNELS_UPDATED]: (state: any, { payload }: any) =>
    state.set('potentialChannels', fromJS(payload) || List()),
  [POTENTIAL_CARE_TEAMS_UPDATED]: (state: any, { payload }: any) =>
    state.set('potentialCareTeams', fromJS(payload) || List()),
  [CHANNEL_SELECTION_DIALOG_REQUESTED]: (state: any) =>
    state.set('channelSelectionDialogOpen', true),
  [CLOSE_CHANNEL_SELECTION_DIALOG]: (state: any) =>
    state.set('channelSelectionDialogOpen', false),
})

const polling = createReducer('upload_polling', false, {
  [POLL_FOR_CASE_SYNC_COMPLETION_START]: () => true,
  [POLL_FOR_CASE_SYNC_COMPLETION_STOP]: () => false,
})

export default combineReducers({
  activeCase,
  newReferral,
  additionalReferral,
  assignment,
  polling,
})
