import { Record } from 'immutable'
import { calcFormData } from '~/components/JsonForm'
import { isRequestPending } from '~/data/pending'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { action, getIn, payload, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import CAREMORE_LOG_COMMUNICATION from '../key'

// Key
const FORM = 'form'

// Type
const typePrefix = `${CAREMORE_LOG_COMMUNICATION}/${FORM}`
const creator = scopedCreator(typePrefix)

// Actions
export const formCancelled = creator('CANCELLED')

export const formEntered = creator('ENTERED', ['patientId'])

export const formErrored = creator('ERRORED')

export const formUpdated = creator('UPDATED', ['formData', 'errored'])

// Record
export const Form = Record({
  errored: false,
  formData: {},
  schema: {},
  uiSchema: {},
  lastLogCommunication: {},
})

// Requests
export const completeForm = Request({
  typePrefix,
  typeBase: 'COMPLETION',
  requestParams: ['patientId', 'formData', 'source', 'type', 'contextData'],
  operation: (patientId, formData, source, type, contextData) => {
    const formType = `log_communication_${type}`
    return AspireAPI.post(
      `/v1/patients/${patientId}/save_log_communication_form`,
      { data: { ...formData, formType, source, ...contextData } }
    )
  },
  messages: {
    failed: 'Failed to complete Log Communication',
    succeeded: 'Completed Log Communication',
  },
})

export const completeCareTeamForm = Request({
  typePrefix,
  typeBase: 'COMPLETION',
  requestParams: [
    'patientId',
    'formData',
    'source',
    'type',
    'careteam',
    'extensivist',
  ],
  operation: (patientId, formData, source, type, careteam, extensivist) => {
    const formType = `log_communication_${type}`

    return AspireAPI.post(
      `/v1/patients/${patientId}/save_log_communication_form`,
      { data: { ...formData, formType, source, careteam, extensivist } }
    )
  },
  messages: {
    failed: 'Failed to complete Log Communication',
    succeeded: 'Completed Log Communication',
  },
})

export const fetchForm = Request({
  typePrefix,
  typeBase: 'FORM_FETCH',
  requestParams: ['patientId', 'type'],
  operation: (patientId, type) => {
    const formType = `log_communication_${type}`

    return AspireAPI.get(
      `v1/patients/${patientId}/log_communication_form?type=${formType}`
    )
  },
  transform: ({ data: formData, ...form }) =>
    Form({
      ...form,
      formData: calcFormData({
        formData,
        schema: form.schema,
        tags: form.tags,
      }),
    }),
  messages: {
    failed: 'Failed to load Log Communication form',
  },
})

export const fetchLastLogCommunication = Request({
  typePrefix,
  typeBase: 'FORM_DATA_FETCH',
  requestParams: ['patientId'],
  operation: patientId => {
    return AspireAPI.get(`v1/patients/${patientId}/last_log_communication`)
  },
  transform: lastLogCommunication => {
    return Form({
      lastLogCommunication,
    })
  },
  messages: {
    failed: 'Failed to fetch last logged communication',
  },
})

// Reducer
export default createReducer(FORM, null, {
  [completeCareTeamForm.SUCCEEDED]: () => null,
  [completeForm.SUCCEEDED]: () => null,
  [fetchForm.REQUESTED]: () => null,
  [fetchForm.SUCCEEDED]: compose(payload, action),
  [fetchLastLogCommunication.SUCCEEDED]: compose(payload, action),
  [formCancelled]: () => null,
  [formErrored]: (state, action) => state.set('errored', action.payload),
  [formUpdated]: (state, { payload: { errored, formData } }) =>
    state
      .set(
        'formData',
        calcFormData({
          formData,
          schema: state.schema,
          tags: state.tags,
        })
      )
      .set('errored', errored),
})

// Selectors
export const getForm = getIn([CAREMORE_LOG_COMMUNICATION, FORM])

const isPending = request => state => isRequestPending(state, request)

export const isFormPending = isPending(fetchForm)

export const isFormSubmitting = isPending(completeForm)
export const isCareTeamFormSubmitting = isPending(completeCareTeamForm)
