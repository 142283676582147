import { combineEpics } from 'redux-observable'
import autosaveOnTaskFormUpdated from './epics/autosaveOnTaskFormUpdated'
import blockNavigation from './epics/blockNavigation'
import closeTaskDialog from './epics/closeTaskDialog'
import createNewTaskDialogOpened from './epics/createNewTaskDialogOpened'
import createNewTaskSubmitted from './epics/createNewTaskSubmitted'
import createNewTaskUpdated from './epics/createNewTaskUpdated'
import fetchNewTaskForm from './epics/fetchNewTaskForm'
import fetchOwnerTasks from './epics/fetchOwnerTasks'
import reassignTaskDialogOpened from './epics/reassignTaskDialogOpened'
import taskCollapsed from './epics/taskCollapsed'
import taskDetailEntered from './epics/taskDetailEntered'
import taskDetailExited from './epics/taskDetailExited'
import taskDetailFetchSucceeded from './epics/taskDetailFetchSucceeded'
import taskExpanded from './epics/taskExpanded'
import taskSaved from './epics/taskSaved'
import taskSubmitted from './epics/taskSubmitted'
import taskUpdated from './epics/taskUpdated'

export default combineEpics(
  autosaveOnTaskFormUpdated,
  blockNavigation,
  closeTaskDialog,
  createNewTaskDialogOpened,
  createNewTaskSubmitted,
  createNewTaskUpdated,
  fetchNewTaskForm,
  fetchOwnerTasks,
  reassignTaskDialogOpened,
  taskCollapsed,
  taskDetailEntered,
  taskDetailExited,
  taskDetailFetchSucceeded,
  taskExpanded,
  taskSaved,
  taskSubmitted,
  taskUpdated
)
