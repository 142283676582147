import { Map, Record } from 'immutable'
import { ofType } from 'redux-observable'
import { first, map } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'

const USER_ROLES = 'userRoles'

export const UserRole = Record({
  id: null,
  name: null,
})

const fetchUserRoles = Request({
  typePrefix: USER_ROLES,
  typeBase: 'FETCH_USER_ROLES',
  operation: () => AspireAPI.get('v1/user_role'),
  transform: into(UserRole, 'id'),
  messages: {
    failed: 'There was an issue fetching user roles',
  },
})

const creator = scopedCreator(USER_ROLES)
export const userRolesRequested = creator('USER_ROLES_REQUESTED')

export default createReducer(USER_ROLES, Map(), {
  [fetchUserRoles.SUCCEEDED]: (_state, { payload }) => payload,
})

export const epic = action$ =>
  action$.pipe(
    ofType(userRolesRequested),
    first(),
    map(fetchUserRoles.requested)
  )

export const getUserRoles = get(USER_ROLES)
