import { ofType } from 'redux-observable'
import { changedTo } from 'redux-routable'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  carePlanSet,
  getCarePlanProblems,
  getLegacy,
} from '~/features/problems/data'
import { mapLegacyCarePlanFormData } from '~/features/problems/utils'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import {
  encounterReviewFormDataChangedByTag,
  getCurrentEncounterReviewId,
} from '../data/currentEncounterReview'
import { PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS } from '../router'

const LEGACY_CARE_PLAN_TAG = 'problemsGoalsActions'
const CARE_PLAN_TAG = 'assessmentAndPlanProblemsList'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(carePlanSet),
    switchTo(state$),
    map(getCurrentEncounterReviewId),
    filter(Boolean),
    switchMap(() =>
      state$.pipe(
        distinctUntilChanged(
          (a, b) => getCarePlanProblems(a) === getCarePlanProblems(b)
        ),
        skip(1),
        debounceTime(1000),
        map(state => {
          // @ts-expect-error state is unknown
          const problems = getCarePlanProblems(state).valueSeq().toJS()
          const isLegacy = getLegacy(state)

          // Temporarily map legacy care plan for open encounter reviews
          const TAG = isLegacy ? LEGACY_CARE_PLAN_TAG : CARE_PLAN_TAG
          const carePlan = isLegacy
            ? mapLegacyCarePlanFormData(problems)
            : problems

          return encounterReviewFormDataChangedByTag(TAG, carePlan)
        }),
        takeUntil(
          action$.pipe(filter(changedTo(PHYSICIAN_DASHBOARD_ENCOUNTER_REVIEWS)))
        )
      )
    )
  )
