import { Map, Record } from 'immutable'
import { ofType } from 'redux-observable'
import { createSelector } from 'reselect'
import { map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME
import { fetchPatient } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
const key = 'openGaps'

export const OpenGaps = Record({
  measure: null,
  description: null,
  lastUpdatedDate: null,
  id: null,
})

export type Gap = {
  measure: string
  description: string
  lastUpdatedDate: string
  id: number
}

export const fetchOpenGaps = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`/patient_record/${patientId}/open_quality_measures`),
  transform: into(OpenGaps, 'id'),
  messages: { failed: 'There was a problem fetching open gaps' },
})

const fetchOpenGapsForPatient = (action$: any) =>
  action$.pipe(
    ofType(fetchPatient.SUCCEEDED),
    pluck('payload', 'id'),
    map(fetchOpenGaps.requested)
  )

export const epic = fetchOpenGapsForPatient

export default createReducer(key, Map(), {
  [fetchOpenGaps.REQUESTED]: () => Map(),
  [fetchOpenGaps.SUCCEEDED]: (_state: any, { payload }: any) => payload,
})

const getOpenGaps = pipe(getRoot, get(key))
export const getOpenGapsList = createSelector([getOpenGaps], gaps =>
  gaps.toList()
)
