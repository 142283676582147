import { Map, Set } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import {
  SELECTED_APPS_KEY,
  fetchUserSettings,
  getRoot,
  saveUserSettings,
} from './common/shared'

const USER_SETTINGS = 'userSettings'

export default createReducer(USER_SETTINGS, Map(), {
  [fetchUserSettings.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [saveUserSettings.SUCCEEDED]: (
    state: any,
    {
      meta: {
        request: { payload },
      },
    }: any
  ) => state.set(SELECTED_APPS_KEY, payload.selectedAPPs),
})

export const getUserSettings = pipe(getRoot, get(USER_SETTINGS))

export const getSelectedAPPs = (state: any) =>
  getUserSettings(state).get(SELECTED_APPS_KEY, Set())
