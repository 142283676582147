import { Map, Record } from 'immutable'
import momentTZ from 'moment-timezone'

const timeZoneInfo = {
  'America/Los_Angeles': { label: 'Pacific Time' },
  'America/Denver': { label: 'Mountain Time' },
  'America/Phoenix': { label: 'Mountain Time - AZ' },
  'America/Chicago': { label: 'Central Time' },
  'America/New_York': { label: 'Eastern Time' },
}

const TimeZone = Record({
  value: null,
  zoneLabel: null,
  offsetLabel: null,
  offset: null,
})

const timeZoneInfoToTimeZone = ({ label }, key) => {
  const date = momentTZ().tz(key)

  return TimeZone({
    value: key,
    zoneLabel: label,
    offsetLabel: date.format('Z'),
    offset: date.utcOffset(),
  })
}

const sortTimeZones = (a, b) => a.offset - b.offset

const timeZones = Map(timeZoneInfo)
  .map(timeZoneInfoToTimeZone)
  .sort(sortTimeZones)

export default timeZones
