import React from 'react'
import { SvgIcon } from '@material-ui/core'

const WazeIcon = () => (
  <SvgIcon>
    <path d="M 14 2 C 6.6 2 4 7.2 4 12 C 4 13.8 2.5992188 14 2.1992188 14 L 0.30078125 14 L 1.4003906 15.599609 C 1.4913234 15.690542 3.016704 17.757252 7.046875 19.035156 A 2.5 2.5 0 0 0 7 19.5 A 2.5 2.5 0 0 0 9.5 22 A 2.5 2.5 0 0 0 11.962891 19.910156 C 12.610064 19.960443 13.281033 20 14 20 C 14.057515 20 14.466307 19.987705 15.033203 19.892578 A 2.5 2.5 0 0 0 17.5 22 A 2.5 2.5 0 0 0 20 19.5 A 2.5 2.5 0 0 0 19.574219 18.107422 C 21.353914 16.878015 23 14.737493 23 11 C 23 4.1 17.1 2.1 14 2 z M 14 4 C 14.3 4 21 4.3 21 11 C 21 13.821473 19.854113 15.877239 17.490234 17 A 2.5 2.5 0 0 0 15.869141 17.609375 C 14.784302 17.997392 13.900391 18 13.900391 18 C 13.056648 18 12.212883 17.926064 11.369141 17.84375 A 2.5 2.5 0 0 0 9.5 17 A 2.5 2.5 0 0 0 8.2636719 17.330078 C 6.369821 16.75369 5.0197769 16.047039 4.1992188 15.5 C 5.1992187 14.9 6 13.8 6 12 C 6 9.6 6.8 4 14 4 z M 11 9 A 1 1 0 0 0 10 10 A 1 1 0 0 0 11 11 A 1 1 0 0 0 12 10 A 1 1 0 0 0 11 9 z M 16 9 A 1 1 0 0 0 15 10 A 1 1 0 0 0 16 11 A 1 1 0 0 0 17 10 A 1 1 0 0 0 16 9 z M 12 11.900391 L 10 12 C 10.1 13.2 11 15 13.5 15 C 16 15 17 13.199609 17 12.099609 L 15 12 C 15 12.1 14.9 13 13.5 13 C 12.2 13 12 12.200391 12 11.900391 z" />
  </SvgIcon>
)

export default WazeIcon
