import React from 'react'
import Tooltip from '~/components/Tooltip'
import PropTypes from '~/utils/propTypes'
import {
  Icon,
  IconButton,
  InputAdornment as MuiInputAdornment,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  iconButton: {
    padding: spacing(0.5),
  },
})

const mapsLink = (latitude, longitude) =>
  `https://www.google.com/maps/place/${latitude},${longitude}`

const InputAdornment = ({
  classes,
  disabled,
  geocoded,
  latitude,
  longitude,
  onClear,
}) => {
  return (
    <MuiInputAdornment position="end">
      {geocoded && (
        <Tooltip title="Clear Address">
          <IconButton
            className={classes.iconButton}
            disabled={disabled}
            onClick={onClear}
          >
            <Icon>clear</Icon>
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="View on Map" disabled={!geocoded}>
        <IconButton
          className={classes.iconButton}
          disabled={!geocoded}
          href={mapsLink(latitude, longitude)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon>{geocoded ? 'location_on' : 'location_off'}</Icon>
        </IconButton>
      </Tooltip>
    </MuiInputAdornment>
  )
}

InputAdornment.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  geocoded: PropTypes.bool.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  onClear: PropTypes.func.isRequired,
}

export default withStyles(styles)(InputAdornment)
