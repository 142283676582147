import React from 'react'
import setDisplayName from '~/utils/setDisplayName'
import MarkerContext from './MarkerContext'

const withMarker = BaseComponent => {
  const ComponentWithMarker = props => (
    <MarkerContext.Consumer>
      {values => {
        const marker = values ? values.marker : undefined
        return <BaseComponent {...props} marker={marker} />
      }}
    </MarkerContext.Consumer>
  )

  setDisplayName(BaseComponent, ComponentWithMarker, 'withMarker')

  return ComponentWithMarker
}

export default withMarker
