import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  pluck,
} from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  REFERRING_ENTITY_QUERY_CHANGED,
  fetchReferringEntitiesByName,
  referringEntitiesStored,
} from '../data/referringEntities'

export default (action$: any) =>
  action$.pipe(
    ofType(REFERRING_ENTITY_QUERY_CHANGED),
    debounceTime(750),
    pluck('referringEntityName'),
    filter(
      (referringEntityName: any) =>
        referringEntityName && referringEntityName.length >= 3
    ),
    mergeMap(referringEntityName =>
      from(fetchReferringEntitiesByName(referringEntityName)).pipe(
        map(referringEntitiesStored),
        catchError(showError('Problem loading referring entities'))
      )
    )
  )
