import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import loading from '~/utils/loading'
import {
  MANUAL_ASSIGNMENT_REQUESTED,
  REFERRAL_CASES_REQUESTED,
  manualAssignment,
  manualAssignmentComplete,
} from '../data/referrals'

const assignApp = (caseId: any, appId: any, channelId: any, careTeamId: any) =>
  from(manualAssignment({ caseId, appId, channelId, careTeamId })).pipe(
    map(manualAssignmentComplete),
    catchError(showError('Failed to update case status'))
  )

export default (action$: any) =>
  action$.pipe(
    ofType(MANUAL_ASSIGNMENT_REQUESTED),
    mergeMap(({ caseId, appId, channelId, careTeamId }) =>
      loading(
        concat(
          assignApp(caseId, appId, channelId, careTeamId),
          of({ type: REFERRAL_CASES_REQUESTED })
        )
      )
    )
  )
