import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { withTheme } from '@material-ui/core/styles'
import Marker from '../Marker'
import createIcon from './createIcon'
import createLabel from './createLabel'

const SquareMarker = ({ theme, ...props }) => {
  const { color, scale, label } = props

  const icon = useMemo(
    () => SquareMarker.renderIcon(theme, color, scale, label),
    [theme, color, scale, label]
  )

  return <Marker {...props} icon={icon} />
}

SquareMarker.renderIcon = (theme, color, scale, label) =>
  createIcon({
    scale,
    size: label ? { height: 18, width: 18 } : { height: 12, width: 12 },
    icon: label
      ? `
    <rect width="18" height="18" style="stroke: ${
      theme.palette.map.markers.stroke
    }; stroke-width: 2; fill: ${color}"/>
    ${createLabel(label, 12, 12)}`
      : `
    <rect width="12" height="12" style="stroke: ${theme.palette.map.markers.stroke}; stroke-width: 2; fill: ${color}"/>`,
  })

SquareMarker.propTypes = {
  theme: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  scale: PropTypes.number,
}

SquareMarker.defaultProps = {
  label: '',
  scale: 1,
}

export default withTheme(SquareMarker)
