import moment from 'moment'
import React, { useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { Button, ButtonGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  actions: {
    display: 'flex',
  },
  button: {
    marginRight: spacing(1),
  },
  spacer: {
    flex: '1',
  },
}))

const EventActions = ({
  canDelete,
  canSave,
  children,
  creating,
  editing,
  event,
  isVisitType,
  leftActions,
  noMaxVisits,
  noVisitsRemaining,
  onClose,
  onDelete,
  onDeleteBatch,
  onGoBack,
  onSave,
  onSaveBatch,
  rightActions,
}) => {
  const classes = useStyles()

  const validEvent = useMemo(() => {
    let rangeValid

    if (event.recurrence) {
      const { startDate, endDate, days } = event.recurrence
      const datesValid =
        startDate && endDate && moment(startDate).isSameOrBefore(endDate)
      const daysValid = !days.isEmpty()
      rangeValid = Boolean(datesValid && daysValid)
    } else {
      const start = event.start
      const end = event.end
      rangeValid =
        event.allDay ||
        Boolean(start && end && moment(start).isSameOrBefore(end))
    }

    // Checks if current event is a batch event
    const hasBatch =
      event.recurrence || (event.userIds && event.userIds.count() > 1)

    const userIdsValid = !event.userIds || hasBatch

    const batchValid = !event.batchId || hasBatch

    return rangeValid && userIdsValid && batchValid
  }, [event])

  const isBatchEvent = event.recurrence || event.userIds

  const deleteDisabled = !(editing && canDelete && event.id)

  const saveDisabled =
    !(editing && canSave && validEvent) ||
    (creating && isVisitType && !noMaxVisits && noVisitsRemaining)

  return (
    <div className={classes.actions}>
      {creating ? (
        <Button color="secondary" onClick={onGoBack}>
          Back
        </Button>
      ) : (
        leftActions ||
        (event.batchId ? (
          <ButtonGroup color="secondary">
            <Button disabled={deleteDisabled} onClick={onDeleteBatch}>
              Delete Batch
            </Button>
            <Button disabled={deleteDisabled} onClick={onDelete}>
              Delete
            </Button>
          </ButtonGroup>
        ) : (
          <Button
            disabled={deleteDisabled}
            color="secondary"
            onClick={onDelete}
          >
            Delete
          </Button>
        ))
      )}

      <span className={classes.spacer} />

      {children}

      <Button className={classes.button} onClick={onClose}>
        Cancel
      </Button>

      {rightActions ||
        (event.batchId ? (
          <ButtonGroup color="primary">
            <Button disabled={saveDisabled} onClick={onSaveBatch}>
              Save Batch
            </Button>
            <Button disabled={saveDisabled} onClick={onSave}>
              Save
            </Button>
          </ButtonGroup>
        ) : (
          <Button disabled={saveDisabled} color="primary" onClick={onSave}>
            {isBatchEvent ? 'Save Batch' : 'Save'}
          </Button>
        ))}
    </div>
  )
}

EventActions.propTypes = {
  canDelete: PropTypes.bool,
  canSave: PropTypes.bool,
  children: PropTypes.node,
  creating: PropTypes.bool,
  editing: PropTypes.bool,
  event: PropTypes.record,
  isVisitType: PropTypes.bool,
  leftActions: PropTypes.node,
  noMaxVisits: PropTypes.bool,
  noVisitsRemaining: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteBatch: PropTypes.func,
  onGoBack: PropTypes.func,
  onSave: PropTypes.func,
  onSaveBatch: PropTypes.func,
  rightActions: PropTypes.node,
}

export default EventActions
