// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import { saveDischarge } from './discharges'

// KEY
const key = 'dischargeCreationDialog'

export const getDialogOpenValue = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const createDischargeDialogOpened = creator(
  'CREATE_DISCHARGE_DIALOG_OPENED'
)
export const createDischargeDialogClosed = creator(
  'CREATE_DISCHARGE_DIALOG_CLOSED'
)

// REDUCER
export default createReducer(key, false, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [createDischargeDialogClosed]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [createDischargeDialogOpened]: () => true,
  [saveDischarge.SUCCEEDED]: () => false,
})
