import createReducer from '~/utils/createReducer'
import { creator } from '~/utils/data'
import mainKey from '../key'
import { getOrderKey } from './common/shared'

// Key
const key = 'ordersPatientId'

// Type
export const PATIENT_ID_CHANGED = `${mainKey}/PATIENT_ID_CHANGED`

// Action
export const userIdChanged = creator(PATIENT_ID_CHANGED, 'patientId')

// Reducer
export default createReducer(key, null, {
  [PATIENT_ID_CHANGED]: (state, { payload }) => payload.patientId,
})

export const getPatientId = state => getOrderKey(state).get(key)
