import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { panelOpened } from '~/data/appLayout'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { patientOutlierPanelOpened } from '~/data/patientPanel'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { patientInfoRequested } from '~/features/panelContent/epics/loadPatientInfoForPanel'
import {
  fetchPatientOutlierPanel,
  patientOutlierPanelCleared,
} from '../data/patientOutlierPanel'

export default (action$: any) =>
  action$.pipe(
    ofType(patientOutlierPanelOpened),
    pluck('payload'),
    mergeMap(({ patientId, reviewId }) => [
      patientOutlierPanelCleared(),
      fetchPatientOutlierPanel.requested(reviewId),
      patientInfoRequested(patientId),
      panelOpened(),
    ])
  )
