import React from 'react'
import PropTypes from '~/utils/propTypes'
import MaterialUIIcon from '@material-ui/core/Icon'
import AspireIcon from './AspireIcon'

const PREFIX_PATTERN = /^([^:]+):(.*)/

const components = {
  material: MaterialUIIcon,
  aspire: AspireIcon,
}

const Empty = () => null

const Icon = ({ children, ...props }) => {
  const result = PREFIX_PATTERN.exec(children)

  if (result) {
    const [, prefix, icon] = PREFIX_PATTERN.exec(children)
    const Component = components[prefix] || Empty

    return <Component {...props}>{icon}</Component>
  } else {
    return null
  }
}

Icon.propTypes = {
  children: PropTypes.string,
}

export default Icon
