import classnames from 'classnames'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  caption: {
    alignSelf: 'center',
  },
  minHeight: {
    minHeight: 20,
  },
}

const DetailItem = ({ classes, label, value }) => (
  <React.Fragment>
    <Typography
      variant="caption"
      className={classnames(classes.caption, {
        [classes.minHeight]: !value,
      })}
    >
      {label}
    </Typography>
    <Typography>{value}</Typography>
  </React.Fragment>
)

DetailItem.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default withStyles(styles)(DetailItem)
