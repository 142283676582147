import { LTC } from '../data/ltcs'

export const getValuesFromLTC = () => LTC()

export const getLTCFromValues = (values, ltc = LTC()) => {
  return ltc.merge({
    facilityName: values.get('facilityName'),
    notes: values.get('notes'),
    admissionDate: values.get('admissionDate'),
    dischargeDate: values.get('dischargeDate'),
  })
}
