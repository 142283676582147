export { default as Calls } from './containers/Calls'
export { default as epic } from './epic'
export { default as reducer } from './reducer'
export {
  fetchCall,
  fetchCallsForPatient,
  fetchCallsForUserId,
  getCallsArray,
  getRecentCallsCount,
} from './data/calls'
export {
  logTriageCallStarted,
  logInboundCallStarted,
  logCMRNCallStarted,
  logOnCallStarted,
} from './data/logCall'
export { formEntered, fetchForm, Form } from './data/form'
export { default as callSelected } from './data/calls'
export { default as CallDetail } from './containers/CallDetail'
export { default as getCallByCurrentCallId } from './data/currentCallId'
