import 'react-virtualized/styles.css'

import React, { PureComponent } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { getIsCaseMgmt } from '~/features/patientInfo'
import { formatDate, formatDateTime } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { logDownload } from '../data/currentAttachments'
import AttachmentTypeSelector from './AttachmentTypeSelect'

/* eslint-disable react/prop-types */
function AttachmentLink({ className, attachmentId, children, link }) {
  const dispatch = useDispatch()

  return (
    <Typography
      className={className}
      type="body2"
      component="a"
      rel="noopener noreferrer"
      target="_blank"
      href={link}
      onClick={() => dispatch(logDownload.requested(attachmentId))}
    >
      {children}
    </Typography>
  )
}

class AttachmentsTable extends PureComponent {
  state = {
    sort: {
      sortBy: 'createdDate',
      sortDirection: 'DESC',
    },
  }

  formatDate = ({ cellData }) =>
    !cellData ? null : (
      <Typography type="body1">{formatDateTime(cellData)}</Typography>
    )

  formatShortDate = ({ cellData }) =>
    !cellData ? null : (
      <Typography type="body1">{formatDate(cellData)}</Typography>
    )

  getAttList = createSelector(
    [props => props.attachments.toList(), props => props.filter],
    (attachments, filter) =>
      !filter
        ? attachments
        : attachments.filter(a =>
            a.valueSeq().join().toLowerCase().includes(filter.toLowerCase())
          )
  )

  setSort = sort => this.setState({ sort })

  renderHeader = headerText => (
    <Typography className={this.props.classes.header} type="body2">
      {headerText}
    </Typography>
  )

  renderName = ({ rowData: { id, object, link, displayName, source } }) =>
    object === 'SALESFORCE' ? (
      <Typography
        className={this.props.classes.link}
        type="body2"
        onClick={() => this.props.onAttachmentClick(id)}
      >
        {displayName}
      </Typography>
    ) : this.props.isCaseMgmt && source != null ? (
      <Typography
        className={this.props.classes.link}
        type="body2"
        onClick={() => this.props.getAttachmentDocument(id)}
      >
        {displayName}
      </Typography>
    ) : (
      <AttachmentLink
        className={this.props.classes.link}
        displayName={displayName}
        attachmentId={id}
        link={link}
      >
        {displayName}
      </AttachmentLink>
    )

  toggleChangeAttachmentType = id => this.setState({ editingAttachment: id })

  updateAttachmentType = (id, type) => {
    this.props.updateAttachmentType(id, type)
    this.setState({ editingAttachment: null })
  }

  attachmentTypeToId = type =>
    type
      ? this.props.attachmentTypes.find(a => a.name === type, { id: 0 }).id
      : 0

  renderAttachmentType = ({
    rowData: { id, attachmentType, object, source },
  }) => {
    const { editingAttachment } = this.state
    const { classes, orderedAttachmentTypes, isCaseMgmt } = this.props

    if (editingAttachment === id)
      return (
        <AttachmentTypeSelector
          selected={this.attachmentTypeToId(attachmentType)}
          handleChange={({ target: { value } }) =>
            this.updateAttachmentType(id, value)
          }
          types={orderedAttachmentTypes}
          hideLabel={true}
        />
      )

    return (
      <div className={classes.attachmentType}>
        {object === 'SALESFORCE' || (isCaseMgmt && source != null) ? null : (
          <Tooltip title="Change Type" placement="right">
            <IconButton onClick={() => this.toggleChangeAttachmentType(id)}>
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
        )}
        <Typography type="body1">{attachmentType}</Typography>
      </div>
    )
  }

  renderText = ({ cellData }) => (
    <Typography type="body1">{cellData}</Typography>
  )

  caremoreAttachmentsTable(classes, { sortDirection, sortBy }, attList) {
    return (
      <div className={classes.tableContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              sortBy={sortBy}
              sortDirection={sortDirection}
              sort={this.setSort}
              rowHeight={45}
              width={width}
              height={height}
              headerHeight={20}
              rowCount={attList.size}
              rowGetter={({ index }) => attList.get(index)}
            >
              <Column
                headerRenderer={() => this.renderHeader('Date of Service')}
                dataKey="dateOfService"
                width={1}
                cellRenderer={this.formatShortDate}
                flexGrow={1}
              />

              <Column
                headerRenderer={() => this.renderHeader('Name')}
                dataKey="displayName"
                cellRenderer={this.renderName}
                width={1}
                flexGrow={1}
              />
              <Column
                headerRenderer={() => this.renderHeader('Attachment Type')}
                dataKey="attachmentType"
                cellRenderer={this.renderAttachmentType}
                width={1}
                flexGrow={1}
              />
              <Column
                headerRenderer={() => this.renderHeader('Source')}
                dataKey="source"
                width={1}
                cellRenderer={this.renderText}
                flexGrow={1}
              />
              <Column
                headerRenderer={() => this.renderHeader('Created Date')}
                dataKey="createdDate"
                cellRenderer={this.formatDate}
                width={1}
                flexGrow={1}
              />
              <Column
                headerRenderer={() => this.renderHeader('Created By')}
                dataKey="createdByName"
                width={1}
                cellRenderer={this.renderText}
                flexGrow={1}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    )
  }

  aspireAttachmentsTable(classes, { sortDirection, sortBy }, attList) {
    return (
      <div className={classes.tableContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              sortBy={sortBy}
              sortDirection={sortDirection}
              sort={this.setSort}
              rowHeight={45}
              width={width}
              height={height}
              headerHeight={20}
              rowCount={attList.size}
              rowGetter={({ index }) => attList.get(index)}
            >
              <Column
                headerRenderer={() => this.renderHeader('Name')}
                dataKey="displayName"
                cellRenderer={this.renderName}
                width={1}
                flexGrow={1}
              />
              <Column
                headerRenderer={() => this.renderHeader('Attachment Type')}
                dataKey="attachmentType"
                cellRenderer={this.renderAttachmentType}
                width={1}
                flexGrow={1}
              />
              <Column
                headerRenderer={() => this.renderHeader('Created Date')}
                dataKey="createdDate"
                cellRenderer={this.formatDate}
                width={1}
                flexGrow={1}
              />
              <Column
                headerRenderer={() => this.renderHeader('Created By')}
                dataKey="createdByName"
                width={1}
                cellRenderer={this.renderText}
                flexGrow={1}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    )
  }

  render() {
    const { classes, isCaseMgmt } = this.props
    const dataList = this.getAttList(this.props)

    const { sortDirection, sortBy } = this.state.sort

    const attList =
      sortDirection === 'DESC'
        ? dataList.sortBy(item => item.get(sortBy)).reverse()
        : dataList.sortBy(item => item.get(sortBy))

    if (isCaseMgmt)
      return this.caremoreAttachmentsTable(classes, this.state.sort, attList)
    else return this.aspireAttachmentsTable(classes, this.state.sort, attList)
  }
}

AttachmentsTable.propTypes = {
  attachments: PropTypes.map, // eslint-disable-line react/no-unused-prop-types
  attachmentTypes: PropTypes.map,
  updateAttachmentType: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  orderedAttachmentTypes: PropTypes.array,
  onAttachmentClick: PropTypes.func.isRequired,
  isCaseMgmt: PropTypes.bool,
}

const styles = ({ palette }) => ({
  tableContainer: {
    height: '500px',
    'margin-top': '16px',
  },
  header: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  link: {
    color: palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  attachmentType: {
    display: 'flex',
    'align-items': 'center',
  },
})

export default compose(
  connect(state => ({ isCaseMgmt: getIsCaseMgmt(state) })),
  withStyles(styles)
)(AttachmentsTable)
