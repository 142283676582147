import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import {
  MERGE_CLICKED,
  mergeSaveFailed,
  mergeSaved,
  submitOdsAdmissionMerge,
} from '../data/admissionsReconciliation'
import { mergeDialogPatientIdCleared } from '../data/mergeDialogPatientId'
import { mergeOdsAdmissionIdsCleared } from '../data/mergeOdsAdmissionIds'
import { selectedOdsAdmissionIdsCleared } from '../data/selectedOdsAdmissionIds'

export default (action$: any) =>
  action$.pipe(
    ofType(MERGE_CLICKED),
    mergeMap(({ mergedFields, odsAdmissionIds }) =>
      concat(
        from(submitOdsAdmissionMerge(mergedFields, odsAdmissionIds)).pipe(
          map(mergeSaved)
        ),
        of(mergeOdsAdmissionIdsCleared(odsAdmissionIds)),
        of(selectedOdsAdmissionIdsCleared(odsAdmissionIds)),
        of(mergeDialogPatientIdCleared()),
        catchError(e => {
          telemetry.error(e)
          return of(mergeSaveFailed())
        })
      )
    )
  )
