import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { AddressDetails } from '../../utils/interfaces'
import { ContactMethodList } from '../ContactMethodList'
import { AddressItem } from './AddressItem'
interface AddressListPropTypes {
  addresses: PropTypes.list.isRequired
}

export const AddressList = ({ addresses }: AddressListPropTypes) => {
  return (
    <ContactMethodList icon="home" label="Addresses">
      {addresses
        .sort((_a: AddressDetails, b: AddressDetails) => (b.primary ? 1 : -1))
        .map((address: AddressDetails) => (
          <AddressItem key={address.id} address={address} />
        ))}
    </ContactMethodList>
  )
}
