import React from 'react'
import { useSelector } from 'react-redux'
import { getFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Box, Link, makeStyles } from '@material-ui/core'
import { getEmailTypeLabel } from '../../utils/utils'
import { MethodItem } from '../MethodItem'

const useStyles = makeStyles(theme => ({
  boxWidth: { minWidth: theme.spacing(12) },
  strikeThrough: { textDecoration: 'line-through' },
  methodInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface EmailItemPropTypes {
  email: PropTypes.object.isRequired
}

export const EmailItem = ({ email }: EmailItemPropTypes) => {
  const classes = useStyles()
  const emailTypeFields = useSelector(getFieldValues(['contact_email_type']))

  return (
    <MethodItem isPrimary={email.primary}>
      <div className={classes.methodInfoContainer}>
        <Box flex="1">
          <Link
            href={'mailto:' + email.email}
            color="inherit"
            className={email.active ? '' : classes.strikeThrough}
          >
            {email.email}
          </Link>
        </Box>
        <Box className={classes.boxWidth}>
          {getEmailTypeLabel(emailTypeFields, email.type || null)}
        </Box>
        {email.createdAt}
      </div>
    </MethodItem>
  )
}
