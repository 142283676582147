import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  getImoSelectedProblem,
  getModifierSelected,
  getWorkflowLoaded,
} from '../data/utils/selectors'
import { Combination, Problem } from '../types/types'
import ImoModifierGroups from './imoModifierGroups'
import ImoProblemList from './imoProblemList'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    justifyContent: 'center',
  },
  form: {
    width: '100%',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: spacing(2),
    rowGap: `${spacing(1)}px`,
  },
  spanTwo: {
    gridColumnStart: 'span 2',
  },
  notes: {
    gridColumnStart: 'span 2',
    marginTop: spacing(1),
  },
  buttonContainer: {
    gridColumnEnd: '5',
    textAlign: 'center',
  },
  messageContainer: {
    color: palette.severity.success.static,
    gridColumnEnd: '4',
    textAlign: 'right',
    justifyContent: 'right',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: '0.5em',
    },
  },
  errorContainer: {
    margin: '1em',
    color: palette.severity.error.static,
  },
  resultsContainer: {
    margin: '1em',
  },
  noResultsContainer: {
    marginTop: '1em',
  },
}))

interface Props {
  id: string
  ImoProblem: React.ElementType
  onSubmit: (problem: any) => void
}

const ImoSelectedProblem = ({ onSubmit, id, ImoProblem }: Props) => {
  const classes = useStyles()
  const problem: any = useSelector(getImoSelectedProblem(id))
  const workflowLoaded: boolean = useSelector(getWorkflowLoaded(id))
  const modifierSelected: boolean = useSelector(getModifierSelected(id))
  const [imoResults, setImoResults] = useState<any>([])

  const {
    enabledModifiers,
    icd10Code,
    modifierCombinations,
    modifierWorkflowNeeded,
  } = problem

  useEffect(() => {
    if (modifierWorkflowNeeded) {
      setImoResults(getWorkflowResult(modifierCombinations, enabledModifiers))
    }
  }, [enabledModifiers, modifierCombinations])

  const getWorkflowResult = (
    modifierCombinations: Combination[],
    enabledModifiers: string[]
  ) => {
    if (modifierCombinations) {
      const results: Problem[] = []
      modifierCombinations.map(({ allowedModifiers, result }: Combination) => {
        if (fromJS(allowedModifiers).isSubset(enabledModifiers)) {
          results.push(result)
        }
      })
      return results
    }
    return []
  }

  return problem && icd10Code ? (
    <div>
      {modifierWorkflowNeeded && workflowLoaded ? (
        <div>
          <ImoModifierGroups
            key={id}
            id={id}
            modifierGroups={problem.modifierGroups}
          />
          {modifierSelected && imoResults && (
            <div className={classes.resultsContainer}>
              <ImoProblemList
                onSubmit={onSubmit}
                problems={imoResults}
                ImoProblem={ImoProblem}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  ) : null
}

export default ImoSelectedProblem
