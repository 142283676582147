import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import {
  EncounterInfoType,
  fetchEncounterInfo,
  getCodingEncounterInfo,
} from '../data/encounterInfo'
import { updatePlaceOfService } from '../data/placeOfService'

const updatePlaceOfServiceSucceeded = (action$: any, state$: any) =>
  action$.pipe(
    ofType(updatePlaceOfService.SUCCEEDED),
    switchTo(state$),
    map(getCodingEncounterInfo),
    map((encounterInfo: EncounterInfoType) =>
      fetchEncounterInfo.requested(encounterInfo.id)
    )
  )

export default updatePlaceOfServiceSucceeded
