import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { catchError, mapTo, mergeMap } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import { archiveReferral, referralCaseRemoved } from '../data/referralCases'
import { ARCHIVE_CASE_REQUESTED } from '../data/referrals'

const archive = (caseId: any, status: any, substatus: any) =>
  from(archiveReferral(caseId, status, substatus)).pipe(
    mapTo(referralCaseRemoved(caseId)),
    catchError(showError('Failed to Archive Case'))
  )

export default (action$: any) =>
  action$.pipe(
    ofType(ARCHIVE_CASE_REQUESTED),
    mergeMap(({ caseId, status, substatus }) =>
      archive(caseId, status, substatus)
    )
  )
