import React from 'react'
import { useFieldValuesOld } from '~/hooks'
import PropTypes from '~/utils/propTypes'

const CreateFieldValueDisplayComponent = fieldValues => {
  const DecodedFieldValue = ({ value }) => {
    const values = useFieldValuesOld(fieldValues)

    return <span>{values.getIn([value, 'label'], value)}</span>
  }

  DecodedFieldValue.propTypes = {
    value: PropTypes.string,
  }

  return DecodedFieldValue
}

export default CreateFieldValueDisplayComponent
