import React from 'react'
import PropTypes from '~/utils/propTypes'
import { removeUnderscores, titleCase } from '~/utils/stringManipulation'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const EventConfirmDialog = ({
  event,
  message,
  onClose,
  onSave,
  onSaveBatch,
  onVisitSave,
  open,
  rescheduleReasonRequired,
}) => {
  const title = titleCase(removeUnderscores(event.recordType))
  const isBatchEvent = event.recurrence || event.userIds
  const onClick =
    event.recordType === 'visit' || rescheduleReasonRequired
      ? onVisitSave
      : isBatchEvent
      ? onSaveBatch
      : onSave

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm {title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{`${message} Please click "Cancel" below to reschedule, or "Confirm" to create this event.`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button autoFocus color="primary" onClick={onClick}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EventConfirmDialog.propTypes = {
  event: PropTypes.record.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveBatch: PropTypes.func.isRequired,
  onVisitSave: PropTypes.func.isRequired,
  rescheduleReasonRequired: PropTypes.bool,
}

export default EventConfirmDialog
