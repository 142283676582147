import React from 'react'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(2, 0, 1),
  },
}))

type Props = {
  onSyncContacts: any
  disabled: boolean
  readonly: boolean
}

const SyncContactsField = ({ onSyncContacts, disabled, readonly }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Button
        size="small"
        variant="contained"
        onClick={onSyncContacts}
        disabled={disabled || readonly}
      >
        <Icon>sync</Icon>Sync Contacts
      </Button>
    </div>
  )
}

export default SyncContactsField
