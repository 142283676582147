import React from 'react'
import BaseObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from '../widgets/memoizedWrapper'

// This is currently only necessary because extraneous ui:order properties cause
// rendering of ObjectField to fail.
//
// This issue details the problem:
// https://github.com/mozilla-services/react-jsonschema-form/issues/823
//
// This PR fixes the issue:
// https://github.com/mozilla-services/react-jsonschema-form/pull/814
//
// This should be removed when the issue is fixed.

const filterOrder = (properties, order) =>
  order.filter(prop => prop === '*' || properties.includes(prop))

const ObjectField = ({ uiSchema, ...props }) => {
  const properties = Object.keys(props.schema.properties)
  const order = uiSchema['ui:order']
  const newUISchema = order
    ? { ...uiSchema, ['ui:order']: filterOrder(properties, order) }
    : uiSchema

  return <BaseObjectField {...props} uiSchema={newUISchema} />
}

ObjectField.propTypes = {
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
}

ObjectField.defaultProps = {
  schema: {},
  uiSchema: {},
}

export default memoizedWrapper(ObjectField)
