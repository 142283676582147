import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { action, get, payload } from '~/utils/data'
import { compose, pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { TripType } from './interfaces'

// KEY
const TRIPTYPE = 'tripType'

export const TripTypes = Record({
  activeInd: null,
  code: null,
  id: null,
  passengerType: null,
  type: null,
})

// TRANSFORMER
const mapTripTypes = (results: [TripType]) =>
  List(
    results?.map(trip =>
      TripTypes({
        id: trip?.id,
        code: trip?.code,
        activeInd: trip?.activeInd,
        passengerType: trip?.passengerType,
        type: trip?.type,
      })
    )
  )

// REQUEST
export const tripTypeList = Request({
  typePrefix: rootKey,
  typeBase: 'TRIPTYPELIST',
  operation: () => AspireAPI.get('transportation/lookup/trip_type'),
  transform: mapTripTypes,
  messages: { failed: 'There was an issue fetching Trip Types' },
})

// REDUCER
export const tripType = createReducer(TRIPTYPE, List(), {
  [tripTypeList.SUCCEEDED]: compose(payload, action),
})

// SELECTORS
export const getTripTypes = pipe(getRoot, get(TRIPTYPE))
