import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { distinctUntilChanged, mergeMap, pluck, tap } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { appChanged } from '../data/currentAPPId'
import { fetchHealthplanAlerts } from '../data/healthplanAlerts'
import { fetchPatientAlerts } from '../data/patientAlerts'
import { fetchSchedulingAlerts } from '../data/schedulingAlerts'

export default action$ =>
  action$.pipe(
    ofType(appChanged),
    pluck('payload', 'appId'),
    distinctUntilChanged(),
    tap(() => {
      fetchPatientAlerts.cancelLatest()
      fetchHealthplanAlerts.cancelLatest()
      fetchSchedulingAlerts.cancelLatest()
    }),
    mergeMap(appId =>
      of(
        fetchPatientAlerts.requested(appId),
        fetchHealthplanAlerts.requested(appId),
        fetchSchedulingAlerts.requested(appId),
        fetchDistinctFieldValues([
          'contact_type',
          'contact_address_type',
          'contact_phone_type',
          'contact_email_type',
        ])
      )
    )
  )
