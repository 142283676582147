import React from 'react'
import { useSelector } from 'react-redux'
import { ActionDialog } from '~/components/dialogs'
import { useToggle } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Button, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCheckInDisabled } from '../data/checkInDisabled'
import {
  checkInSnackbarOpened,
  checkOutSnackbarOpened,
  encounterCheckInRequested,
  encounterCheckOutRequested,
} from '../data/common/shared'
import useCheckInCallback from '../hooks/useCheckInCallback'

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(0.5),
  },
}))

const EncounterCheckIn = ({ encounterId, checkedIn, checkedOut }) => {
  const classes = useStyles()

  const disabled = useSelector(getCheckInDisabled)

  const [open, toggleOpen, toggleClosed] = useToggle()

  const onCheckIn = useCheckInCallback(
    encounterId,
    checkInSnackbarOpened,
    encounterCheckInRequested
  )

  const onCheckOut = useCheckInCallback(
    encounterId,
    checkOutSnackbarOpened,
    encounterCheckOutRequested
  )

  const onConfirm = () => {
    toggleClosed()
    onCheckIn()
  }

  return (
    <React.Fragment>
      {checkedOut ? (
        <Button
          variant="contained"
          color="primary"
          onClick={toggleOpen}
          disabled={disabled}
        >
          <Icon className={classes.icon} fontSize="small">
            check
          </Icon>
          Re-Check In
        </Button>
      ) : !checkedIn ? (
        <Button
          variant="contained"
          color="primary"
          onClick={onCheckIn}
          disabled={disabled}
        >
          <Icon className={classes.icon} fontSize="small">
            check
          </Icon>
          Check In
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={onCheckOut}
          disabled={disabled}
        >
          <Icon className={classes.icon} fontSize="small">
            exit_to_app
          </Icon>
          Check Out
        </Button>
      )}

      <ActionDialog
        mode="confirm"
        open={open}
        title="Confirm Check In"
        onConfirm={onConfirm}
        onClose={toggleClosed}
      >
        <Typography>
          You have already checked out of this encounter. Are you sure you wish
          to check in again?
        </Typography>
        <br />
        <Typography>
          This will clear your previous check in information.
        </Typography>
      </ActionDialog>
    </React.Fragment>
  )
}

EncounterCheckIn.propTypes = {
  encounterId: PropTypes.number.isRequired,
  checkedIn: PropTypes.bool.isRequired,
  checkedOut: PropTypes.bool.isRequired,
}

export default EncounterCheckIn
