import { paramsReducer } from 'redux-routable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
import router from '../router'
import { getRoot } from './common/shared'

const CURRENT_USER_ID = 'currentUserId'

const reducer = paramsReducer(router, ({ userId }: any) => userId || null)

reducer.key = CURRENT_USER_ID

export const getCurrentUserId = (state: any) =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(CURRENT_USER_ID) || getUserId(state)

export default reducer
