import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import { fetchTask, taskExpanded } from '../data/tasks'

export default action$ =>
  action$.pipe(
    ofType(taskExpanded),
    pluck('payload', 'id'),
    map(fetchTask.requested)
  )
