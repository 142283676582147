import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import StatusCount from '../components/StatusCount'
import { getCounts } from '../data/census'

const useStyles = makeStyles(({ spacing }) => ({
  countContainer: {
    display: 'flex',
    marginBottom: spacing(1),
    flexWrap: 'wrap',
  },
}))

const SWCensusCounts = () => {
  const classes = useStyles()
  const counts = useSelector(getCounts)
  return (
    <div>
      <div className={classes.countContainer}>
        <StatusCount label="Active" value={counts.active} />
        <StatusCount label="Referred" value={counts.referred} />
        <StatusCount label="Open Assessments" value={counts.assessmentStatus} />
      </div>
    </div>
  )
}
export default SWCensusCounts
