import { Map, fromJS } from 'immutable'

/*
  Convert an array of objects into a normalized immutable Map.

  objArray - array of objects to normalize
  options - optional options object
    idKey - the key of the object id field (default `id`)
    type - a function to normalize each object (default `Immutable.fromJS`)
*/
const defaults = {
  idKey: 'id',
  type: fromJS,
  accumulator: new Map(),
}
function normalize(objArray, options = {}) {
  const { idKey, type, accumulator } = Object.assign({}, defaults, options)

  return objArray.reduce(
    (acc, obj) => acc.set(obj[idKey], type(obj)),
    accumulator
  )
}

export default normalize
