import { combineEpics, ofType } from 'redux-observable'
import { entered } from 'redux-routable'
import { concat, from, merge, of } from 'rxjs'
import { catchError, filter, first, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { fetchHealthplans } from '~/data/healthplans'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { showError } from '~/utils/alertOnError'
import {
  ASSIGNMENT_PAGE_NAVIGATED,
  CASE_PAGE_NAVIGATED,
  DASHBOARD_PAGE_NAVIGATED,
} from '../data/referralManagement'
import {
  CASE_ID_CHANGED,
  activeReferralCaseRequested,
  potentialChannelsUpdated,
  requestStopPolling,
} from '../data/referrals'
import { fetchStatuses, statusesStored } from '../data/statuses'
import { fetchSubstatuses, substatusesStored } from '../data/substatuses'
import router from '../router'

const fetchAndStoreStatuses = () =>
  from(fetchStatuses()).pipe(
    map(statusesStored),
    catchError(showError('Problem loading statuses.'))
  )

const fetchAndStoreSubstatuses = () =>
  from(fetchSubstatuses()).pipe(
    map(substatusesStored),
    catchError(showError('Problem loading substatuses.'))
  )

const getStatusesAndSubstatuses = (action$: any) =>
  action$.pipe(
    ofType(
      CASE_PAGE_NAVIGATED,
      DASHBOARD_PAGE_NAVIGATED,
      ASSIGNMENT_PAGE_NAVIGATED
    ),
    first(),
    mergeMap(() => merge(fetchAndStoreStatuses(), fetchAndStoreSubstatuses()))
  )

const getHealthplans = (action$: any) =>
  action$.pipe(
    filter(entered(router)),
    first(),
    mergeMap(() => of(fetchHealthplans()))
  )

const caseIdChanged = (action$: any) =>
  action$.pipe(
    ofType(CASE_ID_CHANGED),
    pluck('payload'),
    mergeMap(payload =>
      concat(
        of(activeReferralCaseRequested(payload)),
        of(potentialChannelsUpdated(null)),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        of(requestStopPolling())
      )
    )
  )

export default combineEpics(
  getHealthplans,
  getStatusesAndSubstatuses,
  caseIdChanged
)
