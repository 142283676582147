import { createSelector } from 'reselect'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
import { getActiveFilter, isActiveFilterEmpty } from './activeFilter'
import { getAppData } from './appData'
import { getFilterById } from './filters'
import { getCases } from './referrals'
import { getAllStatuses } from './statuses'
import { getAllSubstatuses } from './substatuses'

export const DASHBOARD_PAGE_NAVIGATED =
  'referralManagement/DASHBOARD_PAGE_NAVIGATED'
export const ASSIGNMENT_PAGE_NAVIGATED =
  'referralManagement/ASSIGNMENT_PAGE_NAVIGATED'
export const CASE_PAGE_NAVIGATED = 'referralManagement/CASE_PAGE_NAVIGATED'
export const NEW_FORM_PAGE_NAVIGATED =
  'referralManagement/NEW_FORM_PAGE_NAVIGATED'

export const dashboardPageNavigated = () => ({ type: DASHBOARD_PAGE_NAVIGATED })
export const assignmentPageNavigated = () => ({
  type: ASSIGNMENT_PAGE_NAVIGATED,
})
export const casePageNavigated = () => ({ type: CASE_PAGE_NAVIGATED })
export const newFormPageNavigated = () => ({ type: NEW_FORM_PAGE_NAVIGATED })

const emptyOrHas = (set: any, key: any) => (referral: any) =>
  set.size === 0 || set.has(referral.get(key))

export const getFilteredCases = createSelector(
  [getCases, getActiveFilter, getAllStatuses, getAllSubstatuses],
  (cases, filter, statuses, substatuses) => {
    const statusValues = statuses
      .valueSeq()
      .filter(
        (s: any) =>
          filter.statusIds.size === 0 || filter.statusIds.includes(s.id)
      )
      .map((s: any) => s.value)
      .toSet()

    const substatusValues = substatuses
      .valueSeq()
      .filter(
        (s: any) =>
          filter.substatusIds.size === 0 || filter.substatusIds.includes(s.id)
      )
      .map((s: any) => s.value)
      .toSet()

    return cases
      .filter(emptyOrHas(filter.healthplanIds, 'healthplanId'))
      .filter(emptyOrHas(statusValues, 'status'))
      .filter(emptyOrHas(substatusValues, 'substatus'))
  }
)

export const getCurrentUserAppData = (state: any, key: any) => {
  const currentUserId = getUserId(state)
  return getAppData(state, currentUserId, key)
}

export const isActiveFilterSavable = (state: any) => {
  const activeFilter = getActiveFilter(state)
  if (isActiveFilterEmpty(state)) return false
  if (activeFilter === getFilterById(state, activeFilter.id)) return false
  return true
}

export const isActiveFilterResettable = (state: any) => {
  const activeFilter = getActiveFilter(state)
  if (!activeFilter.id) return false
  if (isActiveFilterEmpty(state)) return false
  if (activeFilter === getFilterById(state, activeFilter.id)) return false
  return true
}

export const isActiveFilterOwnedByCurrentUser = (state: any) => {
  const activeFilter = getActiveFilter(state)
  const userId = getUserId(state)
  return activeFilter.ownerId === userId
}

export const isActiveFilterSettable = (state: any) => {
  const activeFilter = getActiveFilter(state)
  if (isActiveFilterEmpty(state)) return true
  if (activeFilter === getFilterById(state, activeFilter.id)) return true
  return false
}
