import React from 'react'
import PropTypes from '~/utils/propTypes'
import { Avatar, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  alert: {
    backgroundColor: palette.severity.error.dynamic,
    width: spacing(3),
    height: spacing(3),
  },
}))

const getDiagnosisValue = diagnosis => {
  const diagnosisCodes = []
  const diagnosisDescriptions = []
  if (diagnosis && diagnosis.includes('|') && diagnosis.includes('~~')) {
    diagnosis.split('|').map(el => {
      const tmp = el.split('~~')
      diagnosisCodes.push(tmp[0])
      diagnosisDescriptions.push(tmp[1])
    })
  } else if (diagnosis && diagnosis.includes('|')) {
    diagnosisCodes.push(diagnosis.split('|')[0]),
      diagnosisDescriptions.push(diagnosis.split('|')[1])
  } else if (diagnosis && diagnosis.includes('~~')) {
    const tmp = diagnosis.split('~~')
    diagnosisCodes.push(tmp[0])
    diagnosisDescriptions.push(tmp[1])
  } else if (diagnosis) {
    diagnosisCodes.push(diagnosis)
  }
  return [diagnosisCodes, diagnosisDescriptions]
}

const getTitleValue = (diagnosisCodes, diagnosisDescriptions) =>
  diagnosisCodes.map((diagnos, index) => (
    <Typography key={diagnos}>
      {diagnosisCodes[index]}
      {diagnosisCodes[index] && diagnosisDescriptions[index] && ': '}
      {diagnosisDescriptions[index]}
    </Typography>
  ))

export const Diagnosis = ({ value: diagnosis }) => {
  const classes = useStyles()
  const [diagnosisCodes, diagnosisDescriptions] = React.useMemo(
    () => getDiagnosisValue(diagnosis),
    [diagnosis]
  )

  const count = diagnosisCodes.length

  const title = React.useMemo(
    () => getTitleValue(diagnosisCodes, diagnosisDescriptions),
    [diagnosisCodes, diagnosisDescriptions]
  )

  return (
    <Tooltip title={title}>
      {count <= 1 ? (
        <span>
          {diagnosisCodes[0]}
          {diagnosisCodes[0] && diagnosisDescriptions[0] && ': '}
          {diagnosisDescriptions[0]}
        </span>
      ) : (
        <Avatar className={classes.alert}>{count}</Avatar>
      )}
    </Tooltip>
  )
}

Diagnosis.propTypes = {
  value: PropTypes.string,
}
