import React from 'react'
import { useSelector } from 'react-redux'
import { getFieldValues } from '~/data/fieldValues'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { getAddressTypeLabel } from '../../utils/utils'
import { MethodItem } from '../MethodItem'

const useStyles = makeStyles(theme => ({
  boxWidth: { minWidth: theme.spacing(12) },
  strikeThrough: { textDecoration: 'line-through' },
  methodInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface AddressItemPropTypes {
  address: PropTypes.object.isRequired
}

export const AddressItem = ({ address }: AddressItemPropTypes) => {
  const classes = useStyles()
  const addressTypeFields = useSelector(
    getFieldValues(['contact_address_type'])
  )

  return (
    <MethodItem isPrimary={address.primary}>
      <div className={classes.methodInfoContainer}>
        <Box flex="1" className={address.active ? '' : classes.strikeThrough}>
          <Typography>{address.street}</Typography>
          <Typography>{`${address.city}${', '}${address.state}`}</Typography>
          <Typography>{address.zip}</Typography>
        </Box>
        <Box className={classes.boxWidth}>
          {getAddressTypeLabel(addressTypeFields, address.type || null)}
        </Box>
        {address.createdAt}
      </div>
    </MethodItem>
  )
}
