import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import Request from '~/utils/Request'
import { Record } from 'immutable'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
// @ts-expect-error ts-migrate(7016)
import key from '../key'

const CTM = 'ctm'

export const CareTeamMemberInfo = Record({
  assignees: null,
  roles: null,
})

const transformCTM = (ctm: any) => {
  return CareTeamMemberInfo({
    assignees: ctm?.assignees,
    roles: ctm?.roles,
  })
}

export const fetchCareTeamMemberInfo = Request({
  typePrefix: key,
  typeBase: 'FETCH_CTM',
  requestParams: ['patientId'],
  operation: (patientId: string) =>
    AspireAPI.get(`care_plan/patients/${patientId}/care_team_members`),
  transform: transformCTM,
  messages: { failed: 'There was an issue fetching CareTeam Member Info' },
})

export default createReducer(
  CTM,
  {},
  {
    [fetchCareTeamMemberInfo.SUCCEEDED]: (_state: any, { payload }: any) =>
      payload,
  }
)

export const getCareTeamMemberInfo = pipe(get(key), get(CTM))
