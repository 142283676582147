import { SOCKET_PATH } from 'ahc-config'
import { Socket } from 'phoenix'
import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { combineLatest, endWith, switchMap, takeUntil } from 'rxjs/operators'
import {
  connected,
  disconnected,
  isOffline,
  isOnline,
  neverOnline,
} from '~/data/connectivity'
import { appInitialized } from '~/data/root'
import { loggedIn, loggedOut } from '~/data/session'

const UNLOADED = 'unloaded'

const socketObservable = state$ =>
  Observable.create(observer => {
    const socket = new Socket(SOCKET_PATH, { heartbeatIntervalMs: 10000 })

    // NOTE (chris): Override Phoenix Socket abnormalClose method because the
    // WebSocket close handshake takes ~30 seconds to time out when connectivity
    // is lost, this change causes the socket to initiate the close and
    // imediately invokes all of the onClose callbacks before the timeout.
    socket.abnormalClose = reason => {
      socket.closeWasClean = false
      const callback =
        reason == UNLOADED
          ? () => socket.reconnectTimer.scheduleTimeout()
          : () => socket.onConnClose()
      socket.teardown(callback, 1000, reason)
    }

    socket.onOpen(() => {
      const state = state$.value
      return observer.next(
        connected({
          shouldDisplayMessage: !neverOnline(state) && isOffline(state),
        })
      )
    })

    socket.onClose(
      () => isOnline(state$.value) && observer.next(disconnected())
    )

    socket.connect()

    // NOTE(adam): teardown logic
    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  })

export default (action$, state$) =>
  action$.pipe(
    ofType(appInitialized),
    combineLatest(action$.pipe(ofType(loggedIn))),
    switchMap(() =>
      socketObservable(state$).pipe(
        takeUntil(action$.pipe(ofType(loggedOut))),
        endWith(disconnected())
      )
    )
  )
