// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import appOptions from './data/appOptions'
import crossCoverage from './data/crossCoverage'
import currentAPPId from './data/currentAPPId'
import currentPatientId from './data/currentPatientId'
import currentUserId from './data/currentUserId'
import eventDirections from './data/eventDirections'
import patientFilters from './data/patientFilters'
import patientList from './data/patientList'
import key from './key'

const reducer = combineReducers({
  [crossCoverage.key]: crossCoverage,
  [currentAPPId.key]: currentAPPId,
  [currentPatientId.key]: currentPatientId,
  [currentUserId.key]: currentUserId,
  [eventDirections.key]: eventDirections,
  [patientFilters.key]: patientFilters,
  [patientList.key]: patientList,
  [appOptions.key]: appOptions,
})

reducer.key = key

export default reducer
