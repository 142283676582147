import { combineReducers } from 'redux-immutable'
import hospitalizationDialog from './data/hospitalizationDialog'
import hospitalizations from './data/hospitalizations'
import suggestedHospitals from './data/suggestedHospitals'
import key from './key'

const reducer = combineReducers({
  [hospitalizationDialog.key]: hospitalizationDialog,
  [hospitalizations.key]: hospitalizations,
  [suggestedHospitals.key]: suggestedHospitals,
})

reducer.key = key

export default reducer
