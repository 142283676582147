import { ofType } from 'redux-observable'
import { first, mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { fetchEpisodeOfCare, fetchEpisodesOfCare } from '../data/episodesOfCare'

export default action$ =>
  action$.pipe(
    ofType(fetchEpisodesOfCare.REQUESTED, fetchEpisodeOfCare.REQUESTED),
    first(),
    mapTo(
      fetchDistinctFieldValues([
        'episode_of_care_type',
        'episode_of_care_status',
        'episode_of_care_status_reason',
        'episode_of_care_referral_source',
      ])
    )
  )
