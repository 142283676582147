import React, { PureComponent } from 'react'
import { CalendarWidget } from '~/features/calendarWidget'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  dashboardContainer: { height: '100%', width: '100%', overflow: 'auto' },
})

class DayViewCalendar extends PureComponent {
  render() {
    const { classes, dashUserId } = this.props

    return (
      <div className={classes.dashboardContainer}>
        <CalendarWidget
          userId={dashUserId}
          defaultView="day"
          viewLocked={true}
        />
      </div>
    )
  }
}

DayViewCalendar.propTypes = {
  classes: PropTypes.object,
  dashUserId: PropTypes.string,
}

export default withStyles(styles)(DayViewCalendar)
