import { ofType } from 'redux-observable'
import { mergeMap, pluck } from 'rxjs/operators'
import { scopedCreator } from '~/utils/data'
import { checkTripExist, resetCheck } from '../data/tripExists'

const key = 'TripAppointmentDate'

export const checkTripAppointmentDate = scopedCreator(
  key
)('PATIENT_PANEL_DATA_REQUESTED', ['checkTrip'])

// @ts-expect-error ts-migrate(7006) Parameter 'action$' implicitly has an 'any' type
export const validateAppointmentDate = action$ =>
  action$.pipe(
    ofType(checkTripAppointmentDate),
    pluck('payload', 'checkTrip'),
    mergeMap(checkTrip => [resetCheck(), checkTripExist.requested(checkTrip)])
  )
