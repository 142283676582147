import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { mapTo, mergeMap, pluck } from 'rxjs/operators'
import { getPatientId } from '~/apps/patientRecord/data/patientId'
import { requestSucceeded } from '~/utils/operators'
import {
  PATIENT_INFO_SAVE_REQUESTED,
  fetchPatient,
  getPatientInfo,
  patientInfoStored,
  savePatientInfo,
} from '../data/patient'
import diffRecord from '../utils/diffRecord'

export default (action$, state$) =>
  action$.pipe(
    ofType(PATIENT_INFO_SAVE_REQUESTED),
    pluck('payload', 'patientInfo'),
    mergeMap(patientInfo => {
      const state = state$.value
      const patientId = getPatientId(state)
      const oldPatientInfo = getPatientInfo(state)
      const changes = diffRecord(oldPatientInfo, patientInfo)
      const filteredChanges = changes
        .delete('app')
        .delete('market')
        .delete('primaryCarePhysician')
        .delete('referringEntity')
        .delete('referringPhysician')

      return merge(
        of(savePatientInfo.requested(patientId, filteredChanges)),
        action$.pipe(
          requestSucceeded(savePatientInfo),
          mapTo(patientInfoStored(patientInfo)),
          mapTo(fetchPatient.requested(patientId))
        )
      )
    })
  )
