import { Record } from 'immutable'
import {
  andConditions,
  notCondition,
  orConditions,
  // @ts-expect-error no export
} from '~/features/scheduling'
import {
  calledToday,
  comingDueNTVD,
  hasInternalSubstatus,
  hasNTVD,
  hasNextScheduledVisitDateTime,
  hasSnooze,
  internalSubstatusPatientAlignment,
  internalSubstatusRemotePatient,
  isOrange,
  isRed,
  isYellow,
  isGreen,
  matchesCity,
  matchesHPSPrograms,
  matchesHealthPlan,
  matchesPostalCode,
  matchesState,
  noScore,
  ntvdFrom,
  ntvdTo,
  referralTypeAlgorithm,
  referralTypeDirect,
  snoozeDateAfterTomorrow,
  snoozeDateBeforeTomorrow,
  snoozeDateTomorrow,
  statusActive,
  statusReferred,
  statusScheduled,
  statusUnengaged,
  substatusHospital,
  substatusInProcess,
  substatusNew,
  substatusOnService,
  substatusSNF,
  virtualPatient,
  fivePercentTierThree,
  fivePercentTierTwo,
  fivePercentTierOne,
  telephonic,
  homeBasedPalliativeCare,
  pcPlus,
  commercialPalliativeCare,
  hbpcHybrid,
  highRiskComplexCare,
  careMoreHealthNetwork,
  careMoreAtHome,
} from './patientConditions'
const Filter = Record({
  key: null,
  condition: null,
})
const FilterDefinitions = Record({
  referredDirect: Filter(),
  referredAlgorithm: Filter(),
  referredNew: Filter(),
  referredInProcess: Filter(),
  referredSNFHospital: Filter(),
  referredRemotePatient: Filter(),
  referredUncallable: Filter(),
  snoozeDateBeforeTomorrow: Filter(),
  snoozeDateTomorrow: Filter(),
  snoozeDateAfterTomorrow: Filter(),
  matchesCity: Filter(),
  matchesState: Filter(),
  matchesPostalCode: Filter(),
  matchesHealthPlan: Filter(),
  matchesHPSPrograms: Filter(),
  ntvdFrom: Filter(),
  ntvdTo: Filter(),
  includeGreen: Filter(),
  includeYellow: Filter(),
  includeOrange: Filter(),
  includeRed: Filter(),
  noScore: Filter(),
  activeComingDue: Filter(),
  activeNotComingDue: Filter(),
  excludeCalledToday: Filter(),
  scheduled: Filter(),
  fivePercentTierThree: Filter(),
  fivePercentTierTwo: Filter(),
  fivePercentTierOne: Filter(),
  telephonic: Filter(),
  homeBasedPalliativeCare: Filter(),
  pcPlus: Filter(),
  commercialPalliativeCare: Filter(),
  hbpcHybrid: Filter(),
  highRiskComplexCare: Filter(),
  careMoreHealthNetwork: Filter(),
  careMoreAtHome: Filter(),
})
const referralSubstatusDefinitions = {
  referredNew: Filter({
    key: 'referredNew',
    condition: andConditions([
      statusReferred,
      substatusNew,
      orConditions([notCondition(hasInternalSubstatus), virtualPatient]),
    ]),
  }),
  referredInProcess: Filter({
    key: 'referredInProcess',
    condition: andConditions([
      statusReferred,
      substatusInProcess,
      orConditions([notCondition(hasInternalSubstatus), virtualPatient]),
    ]),
  }),
  referredSNFHospital: Filter({
    key: 'referredSNFHospital',
    condition: andConditions([
      statusReferred,
      orConditions([substatusSNF, substatusHospital]),
      orConditions([notCondition(hasInternalSubstatus), virtualPatient]),
    ]),
  }),
  referredRemotePatient: Filter({
    key: 'referredRemotePatient',
    condition: andConditions([statusReferred, internalSubstatusRemotePatient]),
  }),
}
export default FilterDefinitions({
  // Referral Type
  referredDirect: Filter({
    key: 'referredDirect',
    condition: andConditions([statusReferred, referralTypeDirect]),
  }),
  referredAlgorithm: Filter({
    key: 'referredAlgorithm',
    condition: andConditions([statusReferred, referralTypeAlgorithm]),
  }),
  // Referral Substatus
  ...referralSubstatusDefinitions,
  referredUncallable: Filter({
    key: 'referredUncallable',
    condition: andConditions([
      orConditions([
        statusReferred,
        statusUnengaged,
        internalSubstatusPatientAlignment,
      ]),
      notCondition(
        orConditions([
          (referralSubstatusDefinitions.referredNew as any).condition,
          (referralSubstatusDefinitions.referredInProcess as any).condition,
          (referralSubstatusDefinitions.referredSNFHospital as any).condition,
          (referralSubstatusDefinitions.referredRemotePatient as any).condition,
        ])
      ),
    ]),
  }),
  // Next Target Visit Date
  ntvdFrom: Filter({ key: 'ntvdFrom', condition: ntvdFrom }),
  ntvdTo: Filter({ key: 'ntvdTo', condition: ntvdTo }),
  // Risk Stratification Score
  includeGreen: Filter({
    key: 'includeGreen',
    condition: isGreen,
  }),
  includeYellow: Filter({
    key: 'includeYellow',
    condition: isYellow,
  }),
  includeOrange: Filter({
    key: 'includeOrange',
    condition: isOrange,
  }),
  includeRed: Filter({
    key: 'includeRed',
    condition: isRed,
  }),
  noScore: Filter({
    key: 'noScore',
    condition: noScore,
  }),
  // Snooze Date
  snoozeDateBeforeTomorrow: Filter({
    key: 'snoozeDateBeforeTomorrow',
    condition: orConditions([
      snoozeDateBeforeTomorrow,
      andConditions([statusReferred, substatusNew]),
      notCondition(hasSnooze),
    ]),
  }),
  snoozeDateTomorrow: Filter({
    key: 'snoozeDateTomorrow',
    condition: orConditions([
      snoozeDateTomorrow,
      andConditions([statusReferred, substatusNew]),
    ]),
  }),
  snoozeDateAfterTomorrow: Filter({
    key: 'snoozeDateAfterTomorrow',
    condition: orConditions([
      snoozeDateAfterTomorrow,
      andConditions([statusReferred, substatusNew]),
    ]),
  }),
  // Patient Attributes
  matchesCity: Filter({
    key: 'matchesCity',
    condition: matchesCity,
  }),
  matchesState: Filter({
    key: 'matchesState',
    condition: matchesState,
  }),
  matchesPostalCode: Filter({
    key: 'matchesPostalCode',
    condition: matchesPostalCode,
  }),
  matchesHealthPlan: Filter({
    key: 'matchesHealthPlan',
    condition: matchesHealthPlan,
  }),
  matchesHPSPrograms: Filter({
    key: 'matchesHPSPrograms',
    condition: matchesHPSPrograms,
  }),
  // Active Patients
  activeComingDue: Filter({
    key: 'activeComingDue',
    condition: andConditions([
      statusActive,
      substatusOnService,
      orConditions([notCondition(hasNTVD), comingDueNTVD]),
    ]),
  }),
  activeNotComingDue: Filter({
    key: 'activeNotComingDue',
    condition: andConditions([
      statusActive,
      substatusOnService,
      notCondition(comingDueNTVD),
    ]),
  }),
  // Called Patients
  excludeCalledToday: Filter({
    key: 'excludeCalledToday',
    condition: notCondition(andConditions([statusReferred, calledToday])),
  }),
  // Scheduled patients
  scheduled: Filter({
    key: 'scheduled',
    condition: orConditions([statusScheduled, hasNextScheduledVisitDateTime]),
  }),
  // Program
  fivePercentTierThree: Filter({
    key: 'fivePercentTierThree',
    condition: fivePercentTierThree,
  }),
  fivePercentTierTwo: Filter({
    key: 'fivePercentTierTwo',
    condition: fivePercentTierTwo,
  }),
  fivePercentTierOne: Filter({
    key: 'fivePercentTierOne',
    condition: fivePercentTierOne,
  }),
  telephonic: Filter({
    key: 'telephonic',
    condition: telephonic,
  }),
  homeBasedPalliativeCare: Filter({
    key: 'homeBasedPalliativeCare',
    condition: homeBasedPalliativeCare,
  }),
  pcPlus: Filter({
    key: 'pcPlus',
    condition: pcPlus,
  }),
  commercialPalliativeCare: Filter({
    key: 'commercialPalliativeCare',
    condition: commercialPalliativeCare,
  }),
  hbpcHybrid: Filter({
    key: 'hbpcHybrid',
    condition: hbpcHybrid,
  }),
  highRiskComplexCare: Filter({
    key: 'highRiskComplexCare',
    condition: highRiskComplexCare,
  }),
  careMoreHealthNetwork: Filter({
    key: 'careMoreHealthNetwork',
    condition: careMoreHealthNetwork,
  }),
  careMoreAtHome: Filter({
    key: 'careMoreAtHome',
    condition: careMoreAtHome,
  }),
})
