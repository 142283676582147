import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const CASE_MANAGERS = 'caseManagers'

export const CaseManager = Record({
  id: null,
  contactId: null,
  salutation: null,
  firstName: null,
  middleName: null,
  lastName: null,
  name: null,
  suffix: null,
  title: null,
  healthPlanId: null,
  healthPlan: null,
  phone: null,
  mobilePhone: null,
  fax: null,
  email: null,
})

const transformCaseManager = ({ healthPlan, ...caseManager }) =>
  CaseManager({
    ...caseManager,
    healthPlanId: getIn(healthPlan, 'id'),
    healthPlan: getIn(healthPlan, 'name'),
  })

export const fetchCaseManagers = Request({
  typePrefix: key,
  typeBase: 'FETCH_CASE_MANAGERS',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/case_managers`, {
      params: { searchWord },
    }),
  transform: into(CaseManager, 'id'),
  messages: { failed: 'There was a problem fetching case_managers' },
})

export const fetchCaseManager = Request({
  typePrefix: key,
  typeBase: 'FETCH_CASE_MANAGER',
  requestParams: ['caseManagerId'],
  operation: caseManagerId =>
    AspireAPI.get(`contacts/case_managers/${caseManagerId}`),
  transform: transformCaseManager,
  messages: { failed: 'There was a problem fetching this case manager' },
})

export const addCaseManager = Request({
  typePrefix: key,
  typeBase: 'ADD_CASE_MANAGER',
  requestParams: ['caseManager'],
  operation: caseManager =>
    AspireAPI.post('contacts/case_managers', { caseManager }),
  messages: {
    succeeded: 'Successfully added new case manager',
    failed: 'There was a problem adding this case manager',
  },
})

export const saveCaseManager = Request({
  typePrefix: key,
  typeBase: 'SAVE_CASE_MANAGER',
  requestParams: ['caseManager'],
  operation: caseManager =>
    AspireAPI.put(`contacts/case_managers/${caseManager.id}`, { caseManager }),
  messages: {
    succeeded: 'Successfully saved case manager',
    failed: 'There was a problem saving this case manager',
  },
})

export const deleteCaseManager = Request({
  typePrefix: key,
  typeBase: 'DELETE_CASE_MANAGER',
  requestParams: ['caseManagerId'],
  operation: caseManagerId =>
    AspireAPI.delete(`contacts/case_managers/${caseManagerId}`),
  messages: {
    succeeded: 'Successfully deleted case manager',
    failed: 'There was a problem deleting this case manager',
  },
})

const creator = scopedCreator(key)
export const caseManagersCleared = creator('CASE_MANAGERS_CLEARED')

const initState = Map()
export default createReducer(CASE_MANAGERS, initState, {
  [fetchCaseManagers.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchCaseManager.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [saveCaseManager.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { caseManager },
        },
      },
    }
  ) => state.set(caseManager.id, caseManager),
  [deleteCaseManager.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { caseManagerId },
        },
      },
    }
  ) => state.delete(caseManagerId),
  [caseManagersCleared]: () => initState,
})

export const getCaseManagers = pipe(getRoot, get(CASE_MANAGERS))

export const getCaseManagersArr = createSelector(
  [getCaseManagers],
  caseManagers => caseManagers.toIndexedSeq().toArray()
)

export const getCaseManagerById = (state, id) =>
  getCaseManagers(state).get(id, CaseManager())
