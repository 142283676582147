import { createSelector } from 'reselect'
import { getUserId } from '~/data/session'
import createReducer from '~/utils/createReducer'
import { creator } from '~/utils/data'
import mainKey from '../key'
import { getOrderKey } from './common/shared'

// Key
const key = 'ordersUserId'

// Type
export const USER_ID_CHANGED = `${mainKey}/USER_ID_CHANGED`

// Action
export const userIdChanged = creator(USER_ID_CHANGED, 'userId')

// Reducer
export default createReducer(key, null, {
  [USER_ID_CHANGED]: (state, { payload }) => payload.userId,
})

export const getOrdersUserId = createSelector(
  [state => getOrderKey(state).get(key), getUserId],
  (ordersUser, runningUser) => ordersUser || runningUser
)
