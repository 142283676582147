import { ofType } from 'redux-observable'
import { mergeMapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { problemsInitialized } from '../data/common/shared'
import { fetchProblemDetailTypeMappings } from '../data/problemDetailTypeMappings'

export default (action$: any) =>
  action$.pipe(
    ofType(problemsInitialized),
    mergeMapTo([
      fetchDistinctFieldValues(['problem_status']),
      fetchProblemDetailTypeMappings.requested(),
    ])
  )
