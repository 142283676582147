import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
import { get } from '~/utils/data'
import key from '../../key'

export const closePendingStakeholderEncounterReview = Request({
  typePrefix: key,
  typeBase: 'CLOSE_PENDING_STAKEHOLDER_ENCOUNTER_REVIEW',
  requestParams: ['encounterReviewId'],
  operation: (encounterReviewId: any) =>
    AspireAPI.put(
      `encounters/encounter_reviews/pending_stakeholder/${encounterReviewId}`
    ),
  messages: {
    succeeded: 'Successfully closed encounter review',
    failed: 'There was an issue closing the encounter review',
  },
})

export const getRoot = get(key)
