import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field } from 'redux-form/immutable'
import { SelectField, TextField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import { required } from '../../../utils/formValidators'
import { formSelector } from '../../../utils/formValueSelectors'
import { indicationOptions } from '../../../utils/indications'
import { camelToWord } from '../../../utils/transformers'

const styles = {
  fullWidth: {
    width: '100%',
  },
}

class IndicationField extends React.PureComponent {
  get indicationIsOther() {
    return this.props.indicationValue === 'Other (specify)'
  }

  render() {
    const { classes, name, isRequired } = this.props

    return (
      <React.Fragment>
        <Field
          component={SelectField}
          className={classes.fullWidth}
          label={`${camelToWord(name)} - INTERNAL USE ONLY`}
          name={name}
          validate={isRequired ? [required] : null}
          inputRef={this.inputRef}
          native
        >
          {indicationOptions}
        </Field>
        {this.indicationIsOther && (
          <Field
            component={TextField}
            className={classes.fullWidth}
            label="Reason For Other"
            name={`${name}Other`}
            validate={[required]}
          />
        )}
      </React.Fragment>
    )
  }
}

IndicationField.propTypes = {
  classes: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  indicationValue: PropTypes.string,
}

IndicationField.defaultProps = {
  isRequired: false,
}

const mapStateToProps = (state, ownProps) => ({
  indicationValue: formSelector(state, ownProps.name),
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(IndicationField)
