import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

const key = 'healthplanProgramList'

export const getHealthplanProgramList = pipe(getRoot, get(key))

export const fetchHealthplanSpecificPrograms = Request({
  typePrefix: key,
  typeBase: 'FETCH_HEALTHPLAN_PROGRAM_LIST',
  operation: () => AspireAPI.get(`v1/health_plan/healthplan_specific_programs`),
  messages: {
    failed: 'There was a problem fetching the healthplan specific program list',
  },
  transform: data =>
    data.map(item => ({
      label: item.healthPlanSpecificProgram,
      value: item.healthPlanSpecificProgram,
      healthplanId: item.salesforceHealthplanId,
    })),
})

export default createReducer(key, null, {
  [fetchHealthplanSpecificPrograms.SUCCEEDED]: (_state, { payload }) => payload,
})
