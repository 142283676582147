// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import { benefitDialog, benefitDialogType } from './data/addBenefitDialog'
import { appointmentTypesList } from './data/appointmentType'
import { benefitClassplanProduct } from './data/benefitClassplanProduct'
import { benefitFallout, benefitFalloutDialog } from './data/benefitFallout'
import { benefitUploadDialog } from './data/benefitUploadDialog'
import { benefit } from './data/benefits'
import { callerRelation } from './data/callerRelation'
import { classplanProduct } from './data/classplanProduct'
import { currentPatient, nextYearTripsFlagReducer } from './data/currentPatient'
import { currentUserId } from './data/currentUserId'
import { benefitDelete } from './data/deleteBenefit'
import { destinationAddress } from './data/destinationAddress'
import { destinationType } from './data/destinationType'
import { tripLockEdit } from './data/editTripLock'
import { tripsLoadStatus } from './data/fetchTripsStatus'
import { calcDistance } from './data/getDistance'
import { locationType } from './data/locationType'
import { memberBenefitData } from './data/memberBenefitData'
import { notes, transportationBenefits } from './data/memberInfo'
import { memberNoteDialog } from './data/memberNoteDialog'
import { memberTermedDetails } from './data/memberTermedDetails'
import { patientBenefitHistory } from './data/patientBenefitHistory'
import { patientInfo } from './data/patientInfo'
import {
  mapAddressToState,
  mapDobToState,
  mapFirstNameToState,
  mapLastNameToState,
  mapPatientIdToState,
  mapPhoneToState,
} from './data/patientSearchBar'
import { pickupAddress } from './data/pickupAddress'
import { providerAddress, providerList } from './data/providerAddressSelection'
import { isValidRecurTrip, validateRecurTrip } from './data/recurPopup'
import { patientSearch, patientSearchStatus } from './data/search'
import { mapTripIdToState, searchTripId } from './data/searchByTripId'
import { tripAddEditStatus } from './data/tripAddEdit'
import { checkTripExists } from './data/tripExists'
import { tripFiles } from './data/tripFile'
import { tripLocks } from './data/tripLock'
import { tripNoteDetail } from './data/tripNotes'
import { tripSave } from './data/tripOperation'
import { tripReport, tripReportFields } from './data/tripReport'
import { tripState } from './data/tripState'
import { tripStatus } from './data/tripStatus'
import { tripType } from './data/tripType'
import {
  nextYearTrips,
  patientTripCountByBenefitYear,
  previousYearTrips,
  tripAction,
  trips,
} from './data/trips'
import { userActivity } from './data/userActivity'
import { vaMemberDialog } from './data/vaMemberDialog'
import { isValidTrip, validateTrip } from './data/validateTrip'
import { vendorDetail } from './data/vendorList'
import key from './key'

const reducer = combineReducers({
  [benefit.key]: benefit,
  [benefitDialog.key]: benefitDialog,
  [benefitDialogType.key]: benefitDialogType,
  [benefitDelete.key]: benefitDelete,
  [currentPatient.key]: currentPatient,
  [currentUserId.key]: currentUserId,
  [classplanProduct.key]: classplanProduct,
  [benefitClassplanProduct.key]: benefitClassplanProduct,
  [destinationType.key]: destinationType,
  [memberBenefitData.key]: memberBenefitData,
  [memberNoteDialog.key]: memberNoteDialog,
  [patientInfo.key]: patientInfo,
  [patientSearch.key]: patientSearch,
  [trips.key]: trips,
  [tripAction.key]: tripAction,
  [tripsLoadStatus.key]: tripsLoadStatus,
  [vendorDetail.key]: vendorDetail,
  [appointmentTypesList.key]: appointmentTypesList,
  [callerRelation.key]: callerRelation,
  [tripStatus.key]: tripStatus,
  [tripType.key]: tripType,
  [tripAddEditStatus.key]: tripAddEditStatus,
  [locationType.key]: locationType,
  [tripSave.key]: tripSave,
  [providerList.key]: providerList,
  [providerAddress.key]: providerAddress,
  [destinationAddress.key]: destinationAddress,
  [pickupAddress.key]: pickupAddress,
  [calcDistance.key]: calcDistance,
  [tripState.key]: tripState,
  [tripAction.key]: tripAction,
  [patientBenefitHistory.key]: patientBenefitHistory,
  [tripLocks.key]: tripLocks,
  [userActivity.key]: userActivity,
  [checkTripExists.key]: checkTripExists,
  [validateTrip.key]: validateTrip,
  [isValidTrip.key]: isValidTrip,
  [tripFiles.key]: tripFiles,
  [benefitUploadDialog.key]: benefitUploadDialog,
  [tripNoteDetail.key]: tripNoteDetail,
  [tripLockEdit.key]: tripLockEdit,
  [benefitFalloutDialog.key]: benefitFalloutDialog,
  [benefitFallout.key]: benefitFallout,
  [tripReport.key]: tripReport,
  [tripReportFields.key]: tripReportFields,
  [transportationBenefits.key]: transportationBenefits,
  [notes.key]: notes,
  [vaMemberDialog.key]: vaMemberDialog,
  [mapFirstNameToState.key]: mapFirstNameToState,
  [mapLastNameToState.key]: mapLastNameToState,
  [mapPatientIdToState.key]: mapPatientIdToState,
  [mapDobToState.key]: mapDobToState,
  [mapPhoneToState.key]: mapPhoneToState,
  [mapAddressToState.key]: mapAddressToState,
  [validateRecurTrip.key]: validateRecurTrip,
  [isValidRecurTrip.key]: isValidRecurTrip,
  [memberTermedDetails.key]: memberTermedDetails,
  [patientSearchStatus.key]: patientSearchStatus,
  [previousYearTrips.key]: previousYearTrips,
  [searchTripId.key]: searchTripId,
  [mapTripIdToState.key]: mapTripIdToState,
  [nextYearTrips.key]: nextYearTrips,
  [patientTripCountByBenefitYear.key]: patientTripCountByBenefitYear,
  [nextYearTripsFlagReducer.key]: nextYearTripsFlagReducer,
})

reducer.key = key

export default reducer
