import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import CopyPhoneNumber from '~/components/CopyPhoneNumber'
import { Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCallLog } from '../../../data/callLog'
import { getContacts } from '../../../data/contacts'

const useStyles = makeStyles(({ spacing }) => ({
  attempts: {
    gridColumnStart: 'span 2',
  },
  divider: {
    margin: spacing(1, 0),
    gridColumnStart: 'span 3',
  },
}))

const Contacts = () => {
  const classes = useStyles()

  const callLog = useSelector(getCallLog)
  const contacts = useSelector(getContacts)

  const manualAttempts = useMemo(
    () =>
      callLog.dispositions
        .filterNot(({ phoneNumber }) => contacts.has(phoneNumber))
        .groupBy(({ phoneNumber }) => phoneNumber),
    [contacts, callLog.dispositions]
  )

  return !manualAttempts.isEmpty() ? (
    <React.Fragment>
      {manualAttempts.toIndexedSeq().map(attempts => (
        <React.Fragment key={attempts.first().phoneNumber}>
          <div>
            <CopyPhoneNumber
              color="primary"
              phoneNumber={attempts.first().phoneNumber}
            />
          </div>

          <div className={classes.attempts}>
            {attempts.map((attempt, i) => (
              <Typography
                key={`${attempt.dispositionId}-${attempt.subDispositionId}-${i}`}
                variant="body2"
              >
                {attempt.subdisposition
                  ? `${attempt.disposition} - ${attempt.subdisposition}`
                  : attempt.disposition}
              </Typography>
            ))}
          </div>

          <Divider className={classes.divider} />
        </React.Fragment>
      ))}
    </React.Fragment>
  ) : null
}

export default Contacts
