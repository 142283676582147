import { ofType } from 'redux-observable'
import { map, pluck } from 'rxjs/operators'
import {
  cancelTask,
  completeTask,
  fetchTask,
  reassignTask,
  snoozeTask,
} from '../data/tasks'

export default action$ =>
  action$.pipe(
    ofType(
      cancelTask.SUCCEEDED,
      completeTask.SUCCEEDED,
      reassignTask.SUCCEEDED,
      snoozeTask.SUCCEEDED
    ),
    pluck('payload', 'id'),
    map(fetchTask.requested)
  )
