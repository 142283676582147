import { Map } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getReferralManagement } from './root'

// CONSTANTS
export const PROMPT_SET = 'prompt/PROMPT_SET'
export const PROMPT_INPUT_SET = 'prompt/PROMPT_INPUT_SET'
export const PROMPT_CLEARED = 'prompt/PROMPT_CLEARED'
export const PROMPT_CONFIRMED = 'prompt/PROMPT_CONFIRMED'

// ACTION CREATORS
export const promptSet = (
  action: any,
  title: any,
  message: any,
  value?: any
) => ({
  type: PROMPT_SET,
  action,
  title,
  message,
  value,
})
export const promptInputSet = (action: any, title: any, message: any) => ({
  type: PROMPT_INPUT_SET,
  action,
  title,
  message,
})
export const promptCleared = () => ({ type: PROMPT_CLEARED })
export const promptConfirmed = (action: any, value: any) => ({
  type: PROMPT_CONFIRMED,
  action,
  value,
})

// KEY
export const key = 'prompt'

// REDUCER
export default createReducer(key, null, {
  [PROMPT_SET]: (_state: any, { action, title, message, value }: any) =>
    Map({ action, title, message, value }),
  [PROMPT_INPUT_SET]: (_state: any, { action, title, message }: any) =>
    Map({ action, title, message, input: true }),
  [PROMPT_CLEARED]: () => null,
})

// SELECTORS
export const getPrompt = (state: any) => getReferralManagement(state).get(key)
export const getPromptAction = (state: any) =>
  getPrompt(state) ? getPrompt(state).get('action') : undefined
export const getPromptTitle = (state: any) =>
  getPrompt(state) ? getPrompt(state).get('title') : undefined
export const getPromptMessage = (state: any) =>
  getPrompt(state) ? getPrompt(state).get('message') : undefined
export const getPromptValue = (state: any) =>
  getPrompt(state) ? getPrompt(state).get('value') : undefined
export const isPromptInput = (state: any) =>
  getPrompt(state) && getPrompt(state).get('input')
