import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette }) => ({
  selected: {
    backgroundColor: palette.action.hover,
  },
})

export class CommandItem extends React.PureComponent {
  onClick = () => {
    this.props.onClick(this.props.command)
  }

  render() {
    const { isSelected = false, classes, command } = this.props
    return (
      <ListItem
        button
        className={cx({
          [classes.selected]: isSelected,
        })}
        onClick={this.onClick}
      >
        <ListItemText primary={command.label} />
      </ListItem>
    )
  }
}

CommandItem.propTypes = {
  command: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object,
  isSelected: PropTypes.bool,
}

export default withStyles(styles)(CommandItem)
