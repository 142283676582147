import classnames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import PropTypes from '~/utils/propTypes'
import { Avatar, Button, Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  DEFERRED,
  PENDING,
  importStatusMap,
} from '../../utils/medListConstants'

const FilterChipsCM = ({ medList, setFilters, filters }) => {
  const statusCount = useMemo(
    () =>
      medList.reduce((acc, med) => {
        const status = importStatusMap[med.importStatus]

        return {
          ...acc,
          [status]: Object.prototype.hasOwnProperty.call(acc, status)
            ? acc[status] + 1
            : 1,
        }
      }, {}),
    [medList]
  )

  const toggleFilter = useCallback(
    value => () =>
      setFilters(prevFilters => ({
        ...prevFilters,
        [value]: !prevFilters[value],
      })),
    [setFilters]
  )

  const StatusFilter = useCallback(
    ({ status, hideWhen }) => (
      <FilterChip
        hideWhen={hideWhen}
        onClick={toggleFilter(status)}
        selected={filters[status]}
        status={status}
        statusCount={statusCount[status]}
      />
    ),
    [statusCount, toggleFilter, filters]
  )

  return (
    <span>
      <StatusFilter status={importStatusMap[PENDING]} />
      <StatusFilter status={importStatusMap[DEFERRED]} />
    </span>
  )
}

FilterChipsCM.propTypes = {
  medList: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
}

export default FilterChipsCM

const styles = ({ spacing }) => ({
  chip: {
    textTransform: 'none',
    marginRight: spacing(1),
    opacity: 0.5,
  },
  selected: {
    opacity: 1,
  },
})

const FilterChipBase = ({
  classes,
  hideWhen = false,
  onClick,
  selected,
  status,
  statusCount = 0,
}) => {
  return (
    !hideWhen && (
      <Chip
        avatar={<Avatar>{statusCount}</Avatar>}
        component={Button}
        key={status}
        label={status}
        className={classnames(classes.chip, {
          [classes.selected]: selected,
        })}
        onClick={onClick}
        size="small"
      />
    )
  )
}

FilterChipBase.propTypes = {
  classes: PropTypes.object.isRequired,
  hideWhen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  statusCount: PropTypes.number,
}

const FilterChip = withStyles(styles)(FilterChipBase)
