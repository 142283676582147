import { ofType } from 'redux-observable'
import { filter, first, mapTo, pluck, switchMap } from 'rxjs/operators'
import { patientIdSelected } from '~/features/careTeamManagement/data/currentPatientId'
import { patientInfoRequested } from '~/features/panelContent/epics/loadPatientInfoForPanel'
import { TOGGLE_PANEL_AVAILABLE } from '../data/panelAvailable'

export default action$ =>
  action$.pipe(
    ofType(patientIdSelected),
    pluck('payload', 'patientId'),
    switchMap(patientId =>
      action$.pipe(
        ofType(TOGGLE_PANEL_AVAILABLE),
        pluck('payload', 'available'),
        filter(Boolean),
        first(),
        mapTo(patientInfoRequested(patientId))
      )
    )
  )
