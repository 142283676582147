// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  closePendingStakeholderEncounterReview,
  getRoot,
} from './common/shared'

const CURRENT_ENCOUNTER_REVIEW_ID = 'currentEncounterReviewId'

const creator = scopedCreator(rootKey)
export const encounterReviewSelected = creator('ENCOUNTER_REVIEW_SELECTED', [
  'encounterReviewId',
])

const initState = null

export default createReducer(CURRENT_ENCOUNTER_REVIEW_ID, initState, {
  // @ts-expect-error can we deal with the toString being automatically called?
  [encounterReviewSelected]: (
    _state: any,
    { payload: { encounterReviewId } }: any
  ) => encounterReviewId,
  [closePendingStakeholderEncounterReview.SUCCEEDED]: () => null,
})

export const getCurrentEncounterReviewId = pipe(
  getRoot,
  get(CURRENT_ENCOUNTER_REVIEW_ID)
)
