// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'

export interface TransportationNote {
  createdAt: string
  createdBy: string
  note: string
}

export interface TransportationHospice {
  status: string
  alertEffectiveDate: string
  alertTermDate: string
  alerts: string
}

export interface MemberAddress {
  memberAddress1: string
  memberAddress2: string
  memberCity: string
  memberState: string
  memberZipCode: string
  memberCounty: string
  memberAddressType: string
  memberContactNumber: string
  memberEmail: string
}

export interface PcpDetails {
  name: string
  address1: string
  address2: string
  city: string
  ctate: string
  zipcode: string
  county: string
  phone: string
  phoneExtension: string
}

export interface LimitedBenefit {
  benefitName: string
  benefitLimit: number
  hasHitlimit: string
  hasHitHalfway: string
  remaining: number
  taken: number
  isActiveEEBenefit?: boolean
}

export interface PatientSearchResults {
  subscriberId: string
  patientId: string
  dateOfBirth: string
  firstName: string
  lastName: string
  phone: string
  address: string
  countyName: string
  eligibilityEffectiveDate: string
  eligibilityTermDate: string
  groupId: string
  productDescription: string
  classplanProductId: string
  currentPlanIsEligible: boolean
}

export interface VendorDetail {
  id: string
  name: string
  phone: string
  activeInd: boolean
}
export interface AppointmentType {
  activeInd: boolean
  id: string
  timeInterval: number
  type: string
}

export interface PatientSearchInput {
  patientId: string
  memberDateOfBirth: string
  lastName: string
  firstName: string
  phone: string
  address: string
}

export interface TripAddress {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  phone: string
  ext: string
}

export interface PatientTrip {
  tripId: string
  patientId: string
  subscriberId: string
  eligibilityEffectiveDate: string
  tripType: string
  appointmentType: string
  destinationType: string
  status: string
  caller: string
  callerRelation: string
  appointmentDate: string
  numberOfEscorts: number
  vendorName: string
  vendorStatus: string
  walkerCane: boolean
  stairs: string
  pickUpTime: string
  pickUpType: string
  distance: string
  countable: boolean
  ccc: boolean
  dys: boolean
  lcb: boolean
  flu: boolean
  oneway: boolean
  phone: string
  spokeTo: string
  createdDateTime: string
  lastUpdatedDateTime: string
  pickUpAddress: TripAddress
  destAddress: TripAddress
}

export interface PatientTripInfo {
  tripInfo: PatientTrip
  setSelectedTrip(arg: PatientTrip): void
}

export interface PatientTripRecur {
  tripInfo: PatientTrip
  onClose(): void
}

export interface PatientNote {
  subscriberId: string
  eligibilityEffectiveDate: string
  note: string
  benefitYear: number
  noteType: string
  tripId: string
  userId: string
}

export interface CallerRelation {
  activeInd: boolean
  id: string
  relation: string
  relationCode: string
}

export interface TripStatus {
  activeInd: boolean
  id: string
  isCountable: boolean
  status: string
  statusCode: string
}

export interface TripType {
  activeInd: boolean
  code: string
  id: string
  passengerType: string
  type: string
}

export interface LocationType {
  type: string
  id: string
  code: string
  activeInd: string
}
export interface MemberBenefitHistory {
  benefitYear: string
  note: [TransportationNote]
  limited: [LimitedBenefit]
  unlimited: [string]
}

export interface RecordLinkProps {
  label: string
}
export interface ClassplanProducts {
  classplanProductName: string
  classplanProductId: string
  countyName: string
}
export interface ClassplanProductData {
  classplanProducts: [any]
  counties: [string]
}

export interface TransportationBenefits {
  benefitId: string
  benefitYear: string
  classplanProductId: string
  pbp: string
  countyName: string
  classplanProductName: string
  destinationType: string
  benefitLimit: string
}

export interface BenefitGridPropTypes {
  benefitsData: PropTypes.object
}

export interface BenefitDialogPropsTypes {
  actionType: PropTypes.object
}

export interface TripActionDataProps {
  dialogState: string | null
  tripInfo: PropTypes.object | null
}

export interface BenefitsResults {
  benefitId: string
  benefitYear: string
  classplanProductId: string
  pbp: string
  countyName: string
  classplanProductName: string
  destinationType: string
  benefitLimit: string
}

export interface AddBenefitInputs {
  contractYear: string
  destnTypeId: string
  classplanProductId: string
  classplanProductName: string
  countyName: string
  benefitLimit: string
  createdBy: string
  modifiedBy: string
}

export interface UpdateBenefitInputs {
  benefitId: string
  contractYear: string
  destnTypeId: string
  classplanProductId: string
  classplanProductName: string
  countyName: string
  benefitLimit: string
  userId: string
}

export interface DestinationTypeResults {
  type: string
  id: string
  activeInd: string
}

export interface BenefitSearchProps {
  classplanProducts: PropTypes.list
}

export interface UnlimitedProps {
  unlimited: PropTypes.list
}

export interface LimitedProps {
  limited: PropTypes.list
}

export interface PatientNotesProps {
  notes: PropTypes.list
  benefitYear: string
}

export interface HospiceStatusProps {
  hospiceList: PropTypes.list
}

export interface PatientDetails {
  id: string
  subscriberId: string
  status: string
  firstName: string
  lastName: string
  middleName: string
  title: string
  dateOfBirth: string
  gender: string
  language: string
  hospice: string
  dod: string
  classPlanId: string
  productDesc: string
  effDt: string
  poaLastName: string
  poaFirstName: string
  poaPhone: string
  poaFax: string
  pcpName: string
  pcpPhone: string
  pcpFax: string
  mailAddress: MemberAddress
  homeAddress: MemberAddress
  notes: [TransportationNote]
  hospiceList: [TransportationHospice]
  hospiceIndicator: string
  limited: [LimitedBenefit]
  oneway: LimitedBenefit
  unlimited: [string]
  timezone: string
  authorizationNumber: string
}

export interface PatientHistoryDetails {
  benefitYear: string
  notes: [TransportationNote]
  limited: [LimitedBenefit]
  unlimited: [string]
}
export interface ProviderAddressSearchInput {
  name: string
  address: string
  city: string
  state: string
  zipCode: string
  phone: string
}

export interface ProviderAddressSeachResults {
  providerName: string
  providerAddress1: string
  providerAddress2: string
  providerAddress3: string
  providerCity: string
  providerState: string
  providerZip: string
  providerContactNumber: string
  distanceOfSourceZip: number
  status: string
  active: string
  providerAddressTermDate: string
  facilityTypeDesc: string
}

export interface DestinationAddress {
  name: string
  address: string
  city: string
  state: string
  zipcode: string
  cellPhone: string
  phone: string
  phoneExtension: string
}

export interface TripLock {
  tripId: number
  timeout: string
  createdBy: string
  createdAt: string
  modifiedBy: string
  modifiedAt: string
}
export interface TripPropTypes {
  tripInfo: PatientTripInfo
}

export interface MemberSearchPropTypes {
  onSubmit: PropTypes.func
  onClear: PropTypes.func
  firstName: string
  patientId: string
  dob: string
  lastName: string
  phone: string
  address: string
  setFirstName: PropTypes.func
  setPatientId: PropTypes.func
  setDob: PropTypes.func
  setLastName: PropTypes.func
  setPhone: PropTypes.func
  setAddress: PropTypes.func
}

export interface MemberSearchResultsPropTypes {
  hasSubmitted: boolean
}

export interface TripDistance {
  distance: number
  distanceText: string
  duration: number
  durationText: string
  origin: LatLong
  destination: LatLong
}

export interface TripNoteSave {
  note: [AddTripNote]
  noteType: string
}
export interface TripSave {
  stairs: boolean
  apptDate: string
  cancelInd: boolean
  pickupTypeId: string
  destnTypeId: string
  pickupPhone: string
  destnZipCode: string
  destnState: string
  userId: string

  eligibilityEffectiveDate: string
  destnCity: string
  pickupAddress1: string
  destnAddress1: string
  pickupName: string
  pickupState: string
  tripStatusId: string
  walkerOrCane: boolean
  emptyApptTime: boolean
  benefitYear: number
  mobileNumber: string

  subscriberId: string
  destnName: string
  contactRelationId: string
  pickupCity: string
  vendorId: string
  contact: string
  memberHomeCounty: string
  pickupZipCode: string
  pickupTime: string
  tripTypeId: string
  numberOfEscorts: number
  apptTypeId: string
  classplanProductId: string
  spokeTo: string
  pickupPhoneExt: string
  destnPhone: string
  destnPhoneExt: string
  distance: any
  notes: any
  emergencyTrip: boolean
  csrAuthorizesEmergencyTrip: boolean
}
export interface UserActivityData {
  tripId: number
  subscriberId: string
  pickupType: string
  pickupAddress: Address
  middleName: string
  lastName: string
  modifiedBy: string
  firstName: string
  effectiveDate: string
  createdBy: string
  appointmentDate: string
}

export interface Address {
  address1: string
  address2: string
  city: string
  state: string
  zipcode: string
}

export interface LatLong {
  lat: number
  lng: number
}

export interface TripMapDetails {
  origin: LatLong
  destination: LatLong
  pickupType: string
  destinationType: string
  distanceText: string
  durationText: string
}

export interface LatLong {
  lng: number
  lat: number
}

export interface TripDistanceDetails {
  origin: LatLong
  destination: LatLong
  distance: number
  distanceText: string
  duration: number
  durationText: string
}

export interface CheckAppointmentDate {
  tripExist: string
}

export interface TripValidate {
  createTripOverrideAuthorized: boolean
  distanceInRange: string
  editTripOverrideAuthorized: boolean
  patientCovered: boolean
  benefitConfigured: boolean
  tripMaxDistance: string
  activeTrip: boolean
}
export interface TripStatus {
  activeInd: boolean
  id: string
  isCountable: boolean
  status: string
  statusCode: string
}

export interface TripNoteDetail {
  createdAt: string
  createdBy: string
  note: string
  noteType: string
}
export interface AddTripNote {
  createdAt: string
  createdBy: string
  note: string
}
export interface TripLockInfo {
  tripId: string
  modifiedBy: string
  modifiedAt: string
  createdBy: string
  createdAt: string
  tripLockedByUserName: string
  tripLockedByUserId: string
}

export interface MemberTermDetails {
  memberPlanEffectiveDate: string
  productDesc: string
  productId: string
  memberPlanTerminationDate: string
}
