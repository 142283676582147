import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { titleCase } from '~/utils/stringManipulation'
import key from '../key'
import { getRoot } from './common/shared'

const NPI_PHYSICIANS = 'npiPhysicians'

export const NPIPhysician = Record({
  number: null,
  firstName: null,
  middleName: null,
  lastName: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  specialty: null,
})

const npiPhysicianMapper = data =>
  data.results.map(physician => {
    const address = physician.addresses.find(
      a => a.address_purpose === 'LOCATION'
    )
    const primaryTaxonomy = physician.taxonomies.find(t => t.primary)
    const specialty = primaryTaxonomy ? primaryTaxonomy.desc : 'Unknown'

    return NPIPhysician({
      number: physician.number,
      firstName: titleCase(physician.basic.first_name),
      middleName: titleCase(physician.basic.middle_name),
      lastName: titleCase(physician.basic.last_name),
      street: titleCase(address.address_1),
      city: titleCase(address.city),
      state: address.state,
      postalCode: address.postal_code.slice(0, 5),
      specialty,
    })
  })

export const fetchNPIPhysicians = Request({
  typePrefix: key,
  typeBase: 'FETCH_NPI_PHYSICIANS',
  requestParams: ['params'],
  operation: params => AspireAPI.get('npi/search', { params }),
  transform: npiPhysicianMapper,
  messages: {
    failed:
      'Lookup failure. The NPI registry site may not be responding. Please try again in a moment.',
  },
})

const creator = scopedCreator(key)
export const npiPhysiciansCleared = creator('NPI_PHYSICIANS_CLEARED')

export default createReducer(NPI_PHYSICIANS, [], {
  [fetchNPIPhysicians.SUCCEEDED]: (_state, { payload }) => payload,
  [npiPhysiciansCleared]: () => [],
})

export const getNPIPhysicians = pipe(getRoot, get(NPI_PHYSICIANS))
