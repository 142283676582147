import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'

export const key = 'epicError'

export const EPIC_CRASHED = type(key, 'EPIC_CRASHED')
export const epicCrashed = creator(EPIC_CRASHED, 'error')

export default createReducer(key, null, {
  [EPIC_CRASHED]: (state, { payload: { error } }) => error,
})

export const getEpicError = get(key)
