import axios from 'axios'
import { get, has } from 'lodash'
import { ofType } from 'redux-observable'
import { empty, from, of } from 'rxjs'
import { catchError, ignoreElements, map, mergeMap, tap } from 'rxjs/operators'
import telemetry from '~/utils/telemetry'

export const REQUESTED = 'requested'
export const SUCCEEDED = 'succeeded'
export const FAILED = 'failed'
export const CANCELLED = 'cancelled'

export const flattenErrors = (msg, parentKey = '') => {
  if (!msg) return

  const label = `${parentKey}${parentKey ? ': ' : ''}`

  switch (typeof msg) {
    case 'string':
      return `${label}"${msg}"`
    case 'number':
      return `${label}${msg}`
    case 'object':
      return Array.isArray(msg)
        ? `${label}[${msg.map(e => flattenErrors(e)).join(', ')}]`
        : Object.entries(msg)
            .map(([k, v]) =>
              flattenErrors(v, `${parentKey}${parentKey ? '.' : ''}${k}`)
            )
            .join(', ')
  }
}

const defaults = {
  transform: data => data,
  requestParams: [],
  messages: { failed: error => error.message },
}

const argsToPayload = (args, params) =>
  params
    .map((param, index) => ({ [param]: args[index] }))
    .reduce((payload, arg) => Object.assign(payload, arg), {})

const payloadToArgs = (payload, params) => params.map(param => payload[param])

const maybeCall = (maybeFunction, ...args) =>
  typeof maybeFunction === 'function' ? maybeFunction(...args) : maybeFunction

const createMeta = (key, messages) => ({
  step,
  payload,
  requestedPayload,
  options,
}) => ({
  request: { key, step, payload: requestedPayload, options },
  message: step in messages ? maybeCall(messages[step], payload) : undefined,
})

/**
 * A message or a function that creates a message.
 * @typedef {string|function(*):string} Message
 */

/**
 * @typedef {Object} Messages
 * @property {Message} [requested] - A message for when a request is initiated.
 * @property {Message} [succeeded] - A message for when a request completes
 * successfully.
 * @property {Message} [failed] - A message for when a request fails.
 */

/**
 * @typedef {Object} Action
 * @property {string} type - The type of action.
 * @property {Object} meta - Meta information about an action.
 * @property {boolean} [error] - Whether an action is an error.
 * @property {} payload - The payload of the action.
 */

/**
 * @typedef {Object} Request
 * @property {string} REQUESTED - The action type of the "requested" action.
 * @property {string} SUCCEEDED - The action type of the "succeeded" action.
 * @property {string} FAILED - The action type of the "failed" action.
 * @property {string} CANCELLED - The action type of the "cancelled" action.
 * @property {function(...*): Action} requested - The action creator for the
 * "requested" action. Dispatching this action will trigger the request. The
 * parameters supplied to this creator will be stored in an object as the
 * payload of the returned action with keys specified by `requestParams`.
 * @property {function(result: *): Action} succeeded - The action creator for
 * the "succeeded" action. The payload of the returned action will be the data
 * retrieved from the request.
 * @property {function(data: *): Action} failed - The action creator for the
 * "failed" action. The payload of the returned action will be the error that
 * was thrown.
 * @property {function(data: *): Action} cancelled - The action creator for the
 * "cancelled" action.
 * @property {function(Observable<Action>): Observable<Action>} epic - The epic
 * that listens for the "requested" action and kicks off the request.
 */

/**
 * Create an object that provides a key, action types, action creators, and an
 * epic to enable a straightforward and idiomatic way to handle asynchronous
 * requests within a `redux`/`redux-observable` architecture.
 * @param {Object} options
 * @param {string} options.typePrefix - A string that will be used as the action
 * type prefix.
 * @param {string} options.typeBase - A string that will be used as the action
 * type base.
 * @param {function(...*): (Observable|Promise)} options.operation - A function
 * that will perform an asynchronous request. A cancel token will be passed in
 * as the last argument.
 * @param {function(*): *} [options.transform] - A function to transform the
 * data returned from the `operation` function.
 * @param {string[]} [options.requestParams] - An array of the parameter names
 * for the `request` creator.
 * @param {Messages} [options.messages] - An object of messages for each step of
 * the request.
 * @returns {Request}
 */
const Request = ({
  typePrefix,
  typeBase,
  operation,
  transform = defaults.transform,
  requestParams = defaults.requestParams,
  ...options
}) => {
  const CancelToken = axios.CancelToken
  let source

  const messages = { ...defaults.messages, ...options.messages }
  const request = Object.create(Request.prototype)

  request.key = `${typePrefix}/${typeBase}`

  request.REQUESTED = `${request.key}_REQUESTED`
  request.SUCCEEDED = `${request.key}_SUCCEEDED`
  request.FAILED = `${request.key}_FAILED`
  request.CANCELLED = `${request.key}_CANCELLED`

  const meta = createMeta(request.key, messages)

  request.requested = (...args) => {
    source = CancelToken.source()

    const newOperation = (...operationArgs) =>
      operation(...operationArgs, source.token)

    const payload = argsToPayload(args, requestParams)

    return {
      type: request.REQUESTED,
      meta: meta({
        step: REQUESTED,
        payload,
        options: {
          requestParams,
          operation: newOperation,
          transform,
          succeeded: request.succeeded,
          failed: request.failed,
          cancelled: request.cancelled,
        },
      }),
      payload,
    }
  }

  request.succeeded = (result, requestedPayload) => ({
    type: request.SUCCEEDED,
    meta: meta({ step: SUCCEEDED, payload: result, requestedPayload }),
    payload: result,
  })

  request.failed = (error, requestedPayload) => ({
    type: request.FAILED,
    meta: meta({ step: FAILED, payload: error, requestedPayload }),
    error: true,
    payload: error,
  })

  request.cancelled = () => ({
    type: request.CANCELLED,
    meta: meta({ step: CANCELLED }),
  })

  // DEPRECATED
  request.epic = action$ =>
    action$.pipe(
      ofType(request.REQUESTED),
      tap(() => {
        telemetry.warn(
          'This functionality is deprecated. Request no longer uses epics. Remove this Request from `redux-observable`.',
          request.key
        )
      }),
      ignoreElements()
    )

  request.operation = operation

  request.cancelLatest = () => {
    if (source) source.cancel(request.key)
  }

  return request
}

const createFailedMessage = defaultMessage => ({ response }) => {
  const serverResponse = get(response, 'data.message')

  return serverResponse
    ? typeof serverResponse === 'object'
      ? Object.values(serverResponse).join(', ')
      : serverResponse
    : defaultMessage
}

const requestMiddleware = store => next => action => {
  if (
    has(action, 'meta.request.options') &&
    get(action, 'meta.request.step') === REQUESTED
  ) {
    const { payload, meta } = action
    const {
      requestParams,
      operation,
      transform,
      succeeded,
      failed,
      cancelled,
    } = meta.request.options

    // Dispatch succeeded/failed actions from operation
    of(payloadToArgs(payload, requestParams))
      .pipe(
        mergeMap(args => from(operation(...args))),
        map(transform),
        tap(result => {
          store.dispatch(succeeded(result, payload))
        }),
        catchError(error => {
          if (axios.isCancel(error)) {
            telemetry.warn('Request cancelled!', error)
            store.dispatch(cancelled())
          } else {
            store.dispatch(failed(error, payload))
          }

          return empty()
        })
      )
      .subscribe()

    // Strip out request.options meta information
    const newAction = {
      ...action,
      meta: { ...meta, request: { ...meta.request, options: undefined } },
    }

    return next(newAction)
  } else {
    return next(action)
  }
}

export { Request as default, createFailedMessage, requestMiddleware }
