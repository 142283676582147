import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, first, mergeMapTo } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { stakeholdersInitialized } from '~/features/stakeholders'
import { fetchPendingStakeholderEncounterReviews } from '../data/encounterReviews'
import router from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(entered(router)),
    first(),
    mergeMapTo(
      of(
        fetchPendingStakeholderEncounterReviews.requested(),
        stakeholdersInitialized()
      )
    )
  )
