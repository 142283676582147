import classnames from 'classnames'
import React from 'react'
import DetailItem from '~/components/DetailItem'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  grid: {
    display: 'grid',
  },
  mainColumns: {
    gridTemplateColumns: 'auto auto',
    gridRowGap: `${spacing(4)}px`,
  },
  itemColumns: {
    gridTemplateColumns: 'auto 1fr',
    columnGap: spacing(2),
  },
})

const HospitalizationDetails = ({ classes, row }) => (
  <Card>
    <CardContent>
      <div className={classnames(classes.grid, classes.mainColumns)}>
        {/* Hospitalization Information */}
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            Hospitalization Information
          </Typography>

          <div className={classnames(classes.grid, classes.itemColumns)}>
            {/* Case Number */}
            <DetailItem
              label="Case Number:"
              value={row.caseNumber ? row.caseNumber : 'Pending'}
            />
            {/* Status */}
            <DetailItem label="Status:" value={row.status} />
            {/* Bed Type */}
            <DetailItem label="Bed Type:" value={row.bedType} />
            {/* Hospital Name */}
            <DetailItem label="Hospital Name:" value={row.hospitalName} />
            {/* Reason */}
            <DetailItem label="Reason:" value={row.reason} />
            {/* Reason - Other */}
            {row.reason === 'Other' && (
              <DetailItem
                label="Other Reason:"
                value={row.otherHospitalizationReason}
              />
            )}
            {/* Admission Date */}
            <DetailItem
              label="Admission Date:"
              value={formatDate(row.admissionDate)}
            />
            {/* Discharge Date */}
            <DetailItem
              label="Discharge Date:"
              value={row.dischargeDate ? formatDate(row.dischargeDate) : ''}
            />
          </div>
        </div>

        {/* Hospitalization Information Continued */}
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            Hospitalization Information Continued
          </Typography>

          <div className={classnames(classes.grid, classes.itemColumns)}>
            {/* Notified By */}
            <DetailItem label="Notified By:" value={row.notifiedBy} />
            {/* Notified By - Other */}
            {row.notifiedBy === 'Other' && (
              <DetailItem
                label="Other Notified By:"
                value={row.notifiedByOther}
              />
            )}
            {/* Discharged From Hospital To */}
            <DetailItem
              label="Discharged From Hospital To:"
              value={row.dischargedFromHospitalTo}
            />
            {/* Discharged From Hospital To - Other */}
            {row.dischargedFromHospitalTo === 'Other' && (
              <DetailItem
                label="Discharged To Other:"
                value={row.dischargedFromHospitalToOther}
              />
            )}
            {/* Was Hospitalization Avoidable */}
            <DetailItem
              label="Was Hospitalization Avoidable:"
              value={row.wasHospitalizationAvoidable}
            />
            {/* Carelon Contacted Prior */}
            <DetailItem
              label="Carelon Contacted Prior:"
              value={row.aspireContactedPrior}
            />
            {/* Carelon Contacted Prior - No */}
            {row.aspireContactedPrior === 'No' && (
              <DetailItem
                label="Carelon Contacted Prior - No:"
                value={row.aspireContactedPriorNo}
              />
            )}
            {/* Hospitalization Notes */}
            <DetailItem
              label="Hospitalization Notes:"
              value={row.otherHospitalizationInfo}
            />
          </div>
        </div>

        {/* Other Hospitalization Information */}
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            Other Hospitalization Information
          </Typography>

          <div className={classnames(classes.grid, classes.itemColumns)}>
            {/* Included Observation Stay */}
            <DetailItem
              label="Included Observation Stay:"
              value={row.includedObservationStay}
            />
            {/* Admission Diagnoses */}
            <DetailItem
              label="Admission Diagnoses:"
              value={row.admissionDiagnoses}
            />
            {/* Discharge Diagnoses */}
            <DetailItem
              label="Discharge Diagnoses:"
              value={row.dischargeDiagnoses}
            />
            {/* Authorization Number */}
            <DetailItem
              label="Authorization Number:"
              value={row.authorizationNumber}
            />
          </div>
        </div>

        {/* Avoidability Information */}
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            Avoidability Information
          </Typography>

          <div className={classnames(classes.grid, classes.itemColumns)}>
            {/* MD Review Status */}
            <DetailItem label="MD Review Status:" value={row.reviewStatus} />
            {/* Was Hospitalization Avoidable */}
            <DetailItem
              label="Was Hospitalization Avoidable:"
              value={row.wasHospitalizationAvoidable}
            />
            {/* Avoidability Reasons/Comments */}
            {row.wasHospitalizationAvoidable === 'Yes' && (
              <React.Fragment>
                <DetailItem
                  label="Avoidability Reasons:"
                  value={row.avoidabilityReasons}
                />
                <DetailItem
                  label="Avoidability Comments:"
                  value={row.avoidabilityComments}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
)

HospitalizationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.record.isRequired,
}

export default withStyles(styles)(HospitalizationDetails)
