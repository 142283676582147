import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Box, Icon, List, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  boxRightMargin: { marginRight: theme.spacing(1) },
  boxLeftMargin: { marginBottom: theme.spacing(1) },
  strikeThrough: { textDecoration: 'line-through' },
}))

interface ContactMethodListPropTypes {
  icon: PropTypes.string.isRequired
  label: PropTypes.string.isRequired
  children: PropTypes.node.isRequired
}

export const ContactMethodList = ({
  icon,
  label,
  children,
}: ContactMethodListPropTypes) => {
  const classes = useStyles()
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box clone={true} className={classes.boxRightMargin}>
          <Icon>{icon}</Icon>
        </Box>
        <Typography variant="h6">{label}</Typography>
      </Box>
      <List>{children}</List>
    </>
  )
}
