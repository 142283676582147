import React from 'react'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  root: {
    padding: spacing(2),
    '& > *': {
      width: spacing(20),
    },
  },
  formControl: {
    margin: spacing(3),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: '0',
    height: spacing(8),
    padding: spacing(2),
    zIndex: 1,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  title: {
    flex: '1',
    padding: {
      left: spacing(2),
      right: spacing(2),
    },
  },
})

const SearchOptions = ({
  anchorEl,
  classes,
  onChange,
  onClose,
  open,
  resetDefaults,
  searchOptionsValue,
}) => (
  <Popover anchorEl={anchorEl} open={open} onClose={onClose}>
    <Paper square className={classes.header}>
      <Typography className={classes.title} color="inherit" variant="h6">
        Search Options
      </Typography>
      <Button onClick={resetDefaults} color="inherit">
        Reset Defaults
      </Button>
    </Paper>
    <div>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Search Params</FormLabel>
          <RadioGroup
            aria-label="beginsContains"
            name="beginsContains"
            className={classes.group}
            value={searchOptionsValue.beginsContains}
            onChange={onChange}
          >
            <FormControlLabel
              value="BeginsWith"
              control={<Radio />}
              label="Begins With"
            />
            <FormControlLabel
              value="Contains"
              control={<Radio />}
              label="Contains"
            />
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Search By</FormLabel>
          <RadioGroup
            aria-label="searchFlag"
            name="searchFlag"
            className={classes.group}
            value={searchOptionsValue.searchFlag}
            onChange={onChange}
          >
            <FormControlLabel
              value="Name"
              control={<Radio />}
              label="Drug Name"
            />
            <FormControlLabel
              value="Class"
              control={<Radio />}
              label="Drug Class"
            />
            <FormControlLabel
              value="Diagnosis"
              control={<Radio />}
              label="Patient Condition"
            />
            <FormControlLabel
              value="Supplies"
              control={<Radio />}
              label="Supplies"
            />
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Brand / Generic</FormLabel>
          <RadioGroup
            aria-label="nameFlag"
            name="nameFlag"
            className={classes.group}
            value={searchOptionsValue.nameFlag}
            onChange={onChange}
          >
            <FormControlLabel value="Brand" control={<Radio />} label="Brand" />
            <FormControlLabel
              value="Generic"
              control={<Radio />}
              label="Generic"
            />
            <FormControlLabel value="Both" control={<Radio />} label="Both" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Active Only</FormLabel>
          <RadioGroup
            aria-label="activeOnly"
            name="activeOnly"
            className={classes.group}
            value={searchOptionsValue.activeOnly}
            onChange={onChange}
          >
            <FormControlLabel value="true" control={<Radio />} label="True" />
            <FormControlLabel value="false" control={<Radio />} label="False" />
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">RX / OTC</FormLabel>
          <RadioGroup
            aria-label="rxFlag"
            name="rxFlag"
            className={classes.group}
            value={searchOptionsValue.rxFlag}
            onChange={onChange}
          >
            <FormControlLabel value="RX" control={<Radio />} label="RX" />
            <FormControlLabel value="OTC" control={<Radio />} label="OTC" />
            <FormControlLabel value="Both" control={<Radio />} label="Both" />
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Search Name</FormLabel>
          <RadioGroup
            aria-label="searchNameFlag"
            name="searchNameFlag"
            className={classes.group}
            value={searchOptionsValue.searchNameFlag}
            onChange={onChange}
          >
            <FormControlLabel
              value="DrugDisplayName"
              control={<Radio />}
              label="Drug Disply Name"
            />
            <FormControlLabel
              value="GenericName"
              control={<Radio />}
              label="Generic Name"
            />
            <FormControlLabel value="Both" control={<Radio />} label="Both" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  </Popover>
)

SearchOptions.propTypes = {
  anchorEl: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetDefaults: PropTypes.func.isRequired,
  searchOptionsValue: PropTypes.object.isRequired,
}

export default withStyles(styles)(SearchOptions)
