import { Map } from 'immutable'
import React, { useCallback } from 'react'
import { PhysicianSearch } from '~/features/physicianSearch'
import PropTypes from '~/utils/propTypes'
import memoizedWrapper from './memoizedWrapper'

const PhysicianSearchWidget = ({ onChange }) => {
  const onSelect = useCallback(
    (id, physician) => id && onChange(Map(physician).filter(Boolean).toJS()),
    [onChange]
  )

  return <PhysicianSearch onSelect={onSelect} />
}

PhysicianSearchWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default memoizedWrapper(PhysicianSearchWidget)
