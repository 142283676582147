import React, { useEffect } from 'react'
import useToggle from '~/hooks/useToggle'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  dialogActions: {
    margin: 0,
    padding: spacing(1),
  },
  dialogTitle: {
    paddingTop: spacing(2),
  },
}))

type InfoDialogProps = {
  title: string
  open: boolean
  children: React.ReactNode
  onClose: () => void
}

type Props = Omit<DialogProps, 'onClose'> & InfoDialogProps

const InfoDialog = ({ title, onClose, children, open, ...props }: Props) => {
  const classes = useStyles()

  const [dialogOpen, toggleOpen, toggleClosed] = useToggle()

  // Prevents content in children from poofing as dialog closes
  useEffect(() => {
    open ? toggleOpen() : toggleClosed()
  }, [open])

  return (
    <Dialog
      {...props}
      open={dialogOpen}
      onClose={toggleClosed}
      onExited={onClose}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button color="secondary" onClick={toggleClosed}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoDialog
