import React from 'react'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import mapPropTypes from './mapPropTypes'
import { createPropsToOptions } from './objectHelpers'
import withGoogle from './withGoogle'
import withMap from './withMap'

const propsToOptions = createPropsToOptions(props => ({
  data: props.data.map(latLng => new props.google.maps.LatLng(latLng)),
  dissipating: props.dissipating,
  gradient: props.gradient,
  maxIntensity: props.maxIntensity,
  opacity: props.opacity,
  radius: props.radius,
}))

class Heatmap extends React.PureComponent {
  constructor(props) {
    super(props)

    const options = propsToOptions(props)
    const heatmap = new props.google.maps.visualization.HeatmapLayer(options)

    heatmap.setMap(props.map)

    this.state = { heatmap }
  }

  componentDidMount() {
    this.updateBounds()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.dissipating !== this.props.dissipating ||
      prevProps.gradient !== this.props.gradient ||
      prevProps.maxIntensity !== this.props.maxIntensity ||
      prevProps.opacity !== this.props.opacity ||
      prevProps.radius !== this.props.radius
    ) {
      this.state.heatmap.setOptions(propsToOptions(this.props, prevProps))
    }

    if (prevProps.data !== this.props.data) {
      this.updateBounds(this.props)
    }
  }

  componentWillUnmount() {
    this.state.heatmap.setMap(null)
    this.updateBounds({ ...this.props, data: null })
  }

  updateBounds = ({ google, data, setBounds, deleteBounds } = this.props) => {
    if (data) {
      const heatmapBounds = data.reduce(
        (heatmapBounds, latLng) => heatmapBounds.extend(latLng),
        new google.maps.LatLngBounds()
      )

      setBounds(this.state.heatmap, heatmapBounds)
    } else {
      deleteBounds(this.state.heatmap)
    }
  }

  render() {
    return null
  }
}

Heatmap.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object,
  setBounds: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  deleteBounds: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  data: PropTypes.arrayOf(mapPropTypes.latLng),
  dissipating: PropTypes.bool,
  gradient: PropTypes.array,
  maxIntensity: PropTypes.number,
  opacity: PropTypes.number,
  radius: PropTypes.number,
}

export default compose(withMap, withGoogle)(Heatmap)
