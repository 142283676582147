import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { change, reduxForm } from 'redux-form'
import { withGoogle } from '~/components/GeoMap'
import { ActionDialog } from '~/components/dialogs'
import { Location, geocode } from '~/features/locationSearch'
import useToggle from '~/hooks/useToggle'
import formValuesTransform from '~/utils/formValuesTransform'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import {
  getPhysicianFromValues,
  getValuesFromPhysician,
} from '../../../utils/selectors'
import validate from '../../../utils/validator'
import NPILookup from '../../NPILookup'
import ContactInfo from './ContactInfo'
import MailingInfo from './MailingInfo'
import OtherInfo from './OtherInfo'
import PhysicianInfo from './PhysicianInfo'

const styles = {
  flexContainer: {
    display: 'flex',
  },
  flex: {
    flex: 1,
  },
}

const PhysicianForm = ({
  classes,
  form,
  mode,
  google,
  open,
  pristine,
  reset,
  changeValue,
  ...props
}) => {
  const [lookupOpen, toggleLookupOpen, toggleLookupClosed] = useToggle()

  const onClose = useCallback(() => {
    props.onClose()
    reset()
  }, [reset, props.onClose])

  const onSelect = useCallback(
    row => {
      const address = `${row.street}, ${row.city}, ${row.state} ${row.postalCode}`

      geocode(google, address)
        .then(result =>
          changeValue(
            form,
            'address',
            Location({
              street: result.formatted_street,
              city: result.city,
              state: result.state,
              stateCode: result.state_code,
              postalCode: result.postal_code,
              latitude: result.latitude,
              longitude: result.longitude,
            })
          )
        )
        .catch(() => {})

      changeValue(form, 'npiNumber', String(row.number))
      changeValue(form, 'firstName', row.firstName)
      changeValue(form, 'middleName', row.middleName)
      changeValue(form, 'lastName', row.lastName)

      toggleLookupClosed()
    },
    [google, changeValue, toggleLookupClosed]
  )

  const handleSubmit = useCallback(
    contact => {
      const failed = props.handleSubmit(contact)
      if (!failed) onClose()
    },
    [props.handleSubmit, onClose]
  )

  return (
    <ActionDialog
      open={open}
      title={
        <div className={classes.flexContainer}>
          <Typography variant="h6" className={classes.flex}>
            {`${mode === 'add' ? 'Add' : 'Edit'} Physician`}
          </Typography>

          <Button variant="outlined" onClick={toggleLookupOpen}>
            NPI Lookup
          </Button>
        </div>
      }
      mode={mode}
      onAdd={handleSubmit}
      onSave={handleSubmit}
      onClose={onClose}
      disableAction={pristine}
      maxWidth="lg"
      fullWidth
    >
      <form>
        <PhysicianInfo />
        <ContactInfo />
        <MailingInfo form={form} />
        <OtherInfo form={form} onSearchNPI={toggleLookupOpen} />
      </form>

      <NPILookup
        open={lookupOpen}
        onSelect={onSelect}
        onClose={toggleLookupClosed}
      />
    </ActionDialog>
  )
}

PhysicianForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  google: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default compose(
  connect(null, { changeValue: change }),
  formValuesTransform(
    'physician',
    getValuesFromPhysician,
    getPhysicianFromValues
  ),
  reduxForm({ enableReinitialize: true, validate }),
  withStyles(styles),
  withGoogle
)(PhysicianForm)
