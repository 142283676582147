import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DevXGrid from '~/components/DevXGrid'
import WithTooltip from '~/components/WithTooltip'
import { useMountEffect, useUpdateEffect } from '~/hooks'
import generateId from '~/utils/generateId'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    marginTop: spacing(1),
    overflowX: 'auto',
  },
}))

const EditableTableField = props => {
  const classes = useStyles()
  const { schema, formData } = props
  const [localFormData, setLocalFormData] = useState([])

  useMountEffect(() => {
    const startingData = formData.map(data => {
      data['id'] = generateId()
      return pruneEmpty(data)
    })
    setLocalFormData(startingData)
  })

  useUpdateEffect(() => {
    formData.splice(0, formData.length)
    localFormData.forEach(row => {
      formData.push(row)
    })
  }, [formData, localFormData])

  const pruneEmpty = obj => {
    for (const key in obj) {
      if (obj[key] === '' || obj[key] === undefined || obj[key] === null)
        delete obj[key]
    }
    return obj
  }

  const updateFormData = row => {
    const prunedRow = pruneEmpty(row)
    localFormData.find(r => r.id === prunedRow.id)
      ? setLocalFormData(
          localFormData.map(data => {
            if (data.id === prunedRow.id) {
              return prunedRow
            }
            return data
          })
        )
      : setLocalFormData([...localFormData, prunedRow])
  }

  const deleteRow = row => {
    const localFormDataCopy = [...localFormData]
    const index = localFormDataCopy.indexOf(row)
    if (index !== -1) {
      localFormDataCopy.splice(index, 1)
      setLocalFormData(localFormDataCopy)
    }
  }

  const onCommitChanges = ({ added, changed, deleted }) => {
    if (added) {
      const newRow = Object.values(added)[0]
      newRow['id'] = generateId()
      updateFormData(newRow)
    }
    if (changed) {
      const [id, changes] = Object.entries(changed)[0]
      const rowToBeChanged = formData.find(d => d['id'] == id)
      const mergedRow = {
        ...rowToBeChanged,
        ...changes,
      }
      updateFormData(mergedRow)
    }
    if (deleted) {
      const rowToBeDeleted = localFormData.find(d => d.id === deleted[0])
      deleteRow(rowToBeDeleted)
    }
  }

  const columns = Object.values(schema['items']['properties']).map(property => {
    const isRequired = Object.values(schema['items']['required']).find(
      requiredProperty => requiredProperty === property['tag']
    )
    const requiredUITag = isRequired ? '*' : ''
    return {
      name: property['tag'],
      title: `${property['title']} ${requiredUITag}`,
      displayComponent: WithTooltip,
    }
  })
  const rows = localFormData
  const getRowId = row => row.id

  return (
    <Paper className={classes.paper}>
      <DevXGrid
        {...props}
        editable={true}
        rows={rows}
        columns={columns}
        selectSingleRow={true}
        selectable={true}
        onCommitChanges={onCommitChanges}
        getRowId={getRowId}
      />
    </Paper>
  )
}

EditableTableField.propTypes = {
  schema: PropTypes.object,
  formData: PropTypes.array,
}

EditableTableField.defaultProps = {
  schema: {},
  formData: {},
}

export default EditableTableField
