import { get, has, isEmpty, isEqual, isObject, mapValues, negate } from 'lodash'
import {
  getDefaultFormState,
  retrieveSchema,
} from 'react-jsonschema-form/lib/utils'
import deepEntries from './deepEntries'
import setIn from './setIn'

const RESOLVED = '__resolved'

// Return whether a schema defines a primitive value
const isPrimitive = ({ type }) => type !== 'array' && type !== 'object'

// Return whether a schema defines a primitive array of unique values
const isPrimitiveUniqueArray = schema => {
  const array = schema.type === 'array'
  const unique = schema.uniqueItems
  const primitiveItems = schema.items && isPrimitive(schema.items)

  return array && unique && primitiveItems
}

// Return a predicate that tests whether a parent path contains a given child
const childPath = child => parent => {
  const parentShorter = parent.length < child.length
  const parentMatch = child.slice(0, parent.length)

  return parentShorter && isEqual(parent, parentMatch)
}

// Return a predicate that tests whether formData should be transferred over to
// defaults when resolving
const shouldTransferFormData = entries => {
  const primUniqArrPaths = entries
    .filter(({ schema }) => schema && isPrimitiveUniqueArray(schema))
    .map(({ schemaPath }) => schemaPath)

  return ({ value, schema, schemaPath }) => {
    if (schema === undefined) return !isObject(value)

    const empty = isEmpty(value)
    const primitive = isPrimitive(schema)
    const primUniqArr = isPrimitiveUniqueArray(schema)
    const primUniqArrChild = primUniqArrPaths.some(childPath(schemaPath))

    return primitive ? !primUniqArrChild : empty || primUniqArr
  }
}

// Return whether a value is valid given enums
const validEnum = enumValues => value => enumValues.includes(value)

// Return whether a value is invalid given enums
const invalidEnum = enumValues => negate(validEnum(enumValues))

// Return whether formData is resolved
export const isResolved = (formData = {}) => RESOLVED in formData

// Deeply resolve schema
const resolveSchema = (schema, formData, definitions) => {
  const retrieved = retrieveSchema(schema, definitions, formData)

  if (retrieved.properties) {
    const properties = mapValues(retrieved.properties, (schema, key) => {
      const value = get(formData, key)
      return resolveSchema(schema, value, definitions)
    })

    return { ...retrieved, properties }
  } else {
    return retrieved
  }
}

// Deeply get default formData
const defaultFormData = (schema, formData, definitions) => {
  const resolvedSchema = resolveSchema(schema, formData, definitions)
  const defaults = getDefaultFormState(
    resolvedSchema,
    undefined,
    definitions,
    true
  )

  return deepEntries(resolvedSchema, defaults, definitions).reduce(
    (newDefaults, { schema = {}, valuePath }) => {
      const value = get(formData, valuePath)

      if (schema.type === 'array' && schema.items && value !== undefined) {
        const newValue = value.map(item =>
          defaultFormData(schema.items, item, definitions)
        )

        return setIn(newDefaults, valuePath, newValue)
      } else {
        return newDefaults
      }
    },
    defaults
  )
}

// Resolve formData by populating default values and clearing irrelevant values
const resolveFormData = (schema, formData) => {
  const definitions = schema.definitions
  const resolvedSchema = resolveSchema(schema, formData, definitions)
  const defaults = defaultFormData(schema, formData, definitions)
  const defaultEntries = deepEntries(schema, defaults, definitions)

  // Use defaults as a "mask" to clear out irrelevant formData values by
  // transferring formData values over to defaults
  const newFormData = defaultEntries
    .filter(shouldTransferFormData(defaultEntries))
    .reduce((newFormData, { value: defaultValue, schemaPath, valuePath }) => {
      const subSchema = get(resolvedSchema, schemaPath)
      let value = defaultValue

      // Transfer value from formData if present
      if (has(formData, valuePath)) {
        value = get(formData, valuePath)
      }

      if (value !== undefined) {
        const enumValues = get(subSchema, ['enum'])
        const itemsEnumValues = get(subSchema, ['items', 'enum'])

        // Clear value if it is not a valid enum
        if (enumValues && invalidEnum(enumValues)(value)) {
          value = undefined
        }

        // Filter values that are not valid enums
        if (itemsEnumValues && value.some(invalidEnum(itemsEnumValues))) {
          value = value.filter(validEnum(itemsEnumValues))
        }
      }

      // Set value in formData only if it differs from the default value
      return value !== defaultValue
        ? setIn(newFormData, valuePath, value)
        : newFormData
    }, defaults)

  // Add an indicator that will not be serialized and is used to determine
  // whether formData has been resolved
  newFormData[RESOLVED] = undefined

  // Only return new formData if it is deeply different from previous formData
  return isEqual(formData, newFormData) ? formData : newFormData
}

// "Resolve" formData by populating defaults and clearing irrelevant values.
const resolve = (schema, formData) => {
  const resolvedFormData = resolveFormData(schema, formData)

  // Re-resolve formData only if changed
  return formData !== resolvedFormData
    ? resolve(schema, resolvedFormData)
    : resolvedFormData
}

export default resolve
