import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import {
  catchError,
  debounceTime,
  mapTo,
  mergeMap,
  pluck,
} from 'rxjs/operators'
import { getUserId } from '~/data/session'
import { showError } from '~/utils/alertOnError'
import { pushToChannel } from '~/utils/channels'
import {
  CLEAR_NOTIFICATIONS_REQUESTED,
  clearNotificationsSucceeded,
} from '../data/common/shared'
import getChannelName from '../utils/getChannelName'

export default (action$, state$) =>
  action$.pipe(
    ofType(CLEAR_NOTIFICATIONS_REQUESTED),
    debounceTime(250),
    pluck('payload', 'notificationIds'),
    mergeMap(notificationIds => {
      const state = state$.value
      const userId = getUserId(state)

      return from(
        pushToChannel(getChannelName(userId), 'clear_notifications', {
          notificationIds,
        })
      ).pipe(
        mapTo(clearNotificationsSucceeded()),
        catchError(showError('Unable to clear notifications'))
      )
    })
  )
