import { List } from 'immutable'
import React from 'react'
import { FieldValue } from '~/data/fieldValues'
import PropTypes from '~/utils/propTypes'
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core'
import MultiSelectField from './MultiSelectField'
import TextField from './TextField'

const MenuProps = { MenuListProps: { dense: true }, getContentAnchorEl: null }

const SelectField = ({
  native,
  multiple,
  SelectProps,
  options,
  value: inputValue,
  ...props
}) => {
  if (multiple) {
    // When changing values that are arrays in a form. Immutable js will make them
    // lists but the SelectField component expects arrays
    const value = List.isList(inputValue) ? inputValue.toJS() : inputValue || []

    const none = (
      <MenuItem key="none" value={null}>
        <Checkbox checked={value.length === 0} />
        <ListItemText primary="None" />
      </MenuItem>
    )

    const optionsChildren = options
      .toIndexedSeq()
      .map(({ label, value: option }) => (
        <MenuItem key={option} value={option}>
          <Checkbox size="small" checked={value.includes(option)} />
          <ListItemText primary={label} />
        </MenuItem>
      ))

    const children = [none, ...optionsChildren]
    return (
      <MultiSelectField
        MenuProps={{ ...MenuProps }}
        options={options}
        value={value}
        {...props}
      >
        {children}
      </MultiSelectField>
    )
  } else {
    const value = inputValue || ''
    return (
      <TextField
        {...props}
        select
        inputProps={{
          role: 'listbox',
        }}
        SelectProps={{
          ...SelectProps,
          value,
          native,
          multiple,
          MenuProps,
        }}
      />
    )
  }
}

SelectField.propTypes = {
  SelectProps: PropTypes.object,
  native: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.mapOf(PropTypes.instanceOf(FieldValue)),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
}

export default SelectField
