import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import { fetchEncounterCodes, deleteCode } from '../data/encounterCodes'
import { getCodingEncounterInfo } from '../data/encounterInfo'

const codeDeleted = (action$: any, state$: any) =>
  action$.pipe(
    ofType(deleteCode.SUCCEEDED),
    switchTo(state$),
    map(getCodingEncounterInfo),
    map((encounterInfo: any) => fetchEncounterCodes.requested(encounterInfo.id))
  )

export default codeDeleted
