import { ROUTE_CHANGED, paramsReducer } from 'redux-routable'
import { calcFormData, changeFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS } from '../router'
import {
  PatientOutlierReview,
  getRoot,
  transformPatientOutlierReview,
} from './common/shared'

const CURRENT_PATIENT_OUTLIER_REVIEW = 'currentPatientOutlierReview'

const creator = scopedCreator(rootKey)
export const patientOutlierReviewFormDataChanged = creator(
  'PATIENT_OUTLIER_REVIEW_FORM_DATA_CHANGED',
  ['formData']
)

const initializePatientOutlierReview = (patientOutlierReview: any) =>
  patientOutlierReview.set(
    'formData',
    calcFormData({
      formData: patientOutlierReview.formData,
      schema: patientOutlierReview.schema,
      context: patientOutlierReview.context,
      tags: patientOutlierReview.tags,
    })
  )

export const fetchPatientOutlierReview = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_OUTLIER_REVIEW',
  requestParams: ['id'],
  operation: (id: any) => AspireAPI.get(`patient_outliers/reviews/${id}`),
  transform: pipe(
    transformPatientOutlierReview,
    initializePatientOutlierReview
  ),
  messages: {
    failed: 'There was an issue getting the patient outlier review',
  },
})

export const savePatientOutlierReview = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PATIENT_OUTLIER_REVIEW',
  requestParams: ['id', 'formData'],
  operation: ({ id, formData }: any) =>
    AspireAPI.put(`patient_outliers/reviews/${id}`, {
      data: formData,
    }),
  messages: {
    failed: 'There was an issue saving the patient outlier review',
  },
})

export const completePatientOutlierReview = Request({
  typePrefix: rootKey,
  typeBase: 'COMPLETE_PATIENT_OUTLIER_REVIEW',
  requestParams: ['id'],
  operation: (id: any) =>
    AspireAPI.put(`patient_outliers/reviews/${id}/complete`),
  messages: {
    succeeded: 'Successfully completed patient outlier review',
    failed: 'There was an issue completing the patient outlier review',
  },
})

const initState = PatientOutlierReview()

export default createReducer(CURRENT_PATIENT_OUTLIER_REVIEW, initState, {
  [ROUTE_CHANGED]: paramsReducer(
    PHYSICIAN_DASHBOARD_PATIENT_OUTLIER_REVIEWS,
    initState,
    ({ patientOutlierReviewId }: any) =>
      patientOutlierReviewId
        ? PatientOutlierReview({ id: Number(patientOutlierReviewId) })
        : initState
  ),
  [fetchPatientOutlierReview.SUCCEEDED]: (_state: any, { payload }: any) =>
    payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [patientOutlierReviewFormDataChanged]: (
    state: any,
    { payload: { formData } }: any
  ) => changeFormData(state, null, formData),
  [completePatientOutlierReview.SUCCEEDED]: () => initState,
})

export const getCurrentPatientOutlierReview = pipe(
  getRoot,
  get(CURRENT_PATIENT_OUTLIER_REVIEW)
)

export const getCurrentPatientOutlierReviewId = (state: any) =>
  getCurrentPatientOutlierReview(state).id
