export const andConditions = (conditions, defaultValue = true) =>
  conditions.length > 0
    ? (...args) =>
        conditions.reduce((pass, condition) => pass && condition(...args), true)
    : () => defaultValue

export const orConditions = (conditions, defaultValue = true) =>
  conditions.length > 0
    ? (...args) =>
        conditions.reduce(
          (pass, condition) => pass || condition(...args),
          false
        )
    : () => defaultValue

export const notCondition = condition => (...args) => !condition(...args)
