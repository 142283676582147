import { Map } from 'immutable'

const filteredFields = [
  'assignedMarketName',
  'assignedAppName',
  'primaryCarePhysicianName',
  'primaryCarePhysicianPhone',
  'referringEntityName',
  'referringPhysicianName',
]

export default (src, dest) =>
  Map(dest).filter(
    (value, key) => !filteredFields.includes(key) && value !== src.get(key)
  )
