import { combineReducers } from 'redux-immutable'
import patientSearchResults from './patientSearchResults'
import requiredRescheduleReason from './requiredRescheduleReason'
import { EVENT_TYPES } from './root'

const reducer = combineReducers({
  [patientSearchResults.key]: patientSearchResults,
  [requiredRescheduleReason.key]: requiredRescheduleReason,
})

reducer.key = EVENT_TYPES

export default reducer

export * from './patientSearchResults'
export * from './requiredRescheduleReason'
