// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import notificationList from './data/notificationList'
import userListSettings from './data/userListSettings'
import userSignature from './data/userSignature'
import key from './key'

const reducer = combineReducers({
  [notificationList.key]: notificationList,
  [userListSettings.key]: userListSettings,
  [userSignature.key]: userSignature,
})

reducer.key = key

export default reducer
