import cx from 'classnames'
import * as React from 'react'
import PropTypes from '~/utils/propTypes'
import { getItem, setPermanentItem } from '~/utils/storage'
import {
  Getter,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import {
  CustomPaging,
  EditingState,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  ColumnChooser,
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CollapsedComponent from './CollapsedComponent'
import Command from './Command'
import DisplayCell from './DisplayCell'
import EditCell from './EditCell'
import FilterCell from './FilterCell'
import GroupDisplayCell from './GroupDisplayCell'
import RefreshButton from './RefreshButton'
import ResetButton from './ResetButton'

const styles = ({ spacing }) => ({
  alignItems: {
    verticalAlign: 'bottom',
    '& > td': {
      padding: spacing(1),
    },
  },
  alignMiddle: {
    verticalAlign: 'middle',
  },
  table: {
    borderCollapse: 'collapse',
    tableLayout: ({ autoLayout }) => autoLayout && 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedRowsCount: {
    padding: spacing(2.5),
    position: 'absolute',
  },
  clickable: {
    cursor: 'pointer',
  },
  rowActions: {
    padding: 0,

    '& * button': {
      padding: spacing(1),
    },
  },
  noPadding: {
    padding: 0,
  },
  // This is a very nasty hack disabling transforms to prevent
  // headers and cells from dancing around and crushing the page
  // This started happening running React >=16.8.0 locally 🤔
  // https://github.com/DevExpress/devextreme-reactive/issues/1529
  gridHACK: {
    '& > thead > tr > th': {
      transform: 'none !important',
    },
    '& > tbody > tr > td': {
      transform: 'none !important',
    },
  },
})

const groupRowStyles = ({ palette }) => ({
  root: {
    background: palette.divider,
    '& > div': {
      width: '100%',
      backgroundColor: 'transparent',
    },
  },
})

const booleanGroupingCriteria = v => (v ? { key: 'True' } : { key: 'False' })

/* eslint-disable react/prop-types */
const EmptyGroupMessage = ({ getMessage }) => (
  <Typography>{getMessage('groupByColumn')}</Typography>
)

const NoColumnsMessage = ({ getMessage }) => (
  <Typography
    data-track-content
    data-content-name="Columns Message"
    data-content-piece="no columns"
    align="center"
    color="textSecondary"
    gutterBottom
  >
    {getMessage('noColumns')}
  </Typography>
)

const GroupCell = withStyles(groupRowStyles)(({ classes, ...props }) => {
  if (props.column.displayComponent || props.column.groupByComponent)
    return <GroupDisplayCell className={classes.root} {...props} />

  return (
    <TableGroupRow.Cell className={classes.root} {...props}>
      {props.row.value}
    </TableGroupRow.Cell>
  )
})

const TableComponentBase = gridThis => {
  const TableComponentWithGridProps = props => {
    const gridHACK = gridThis.props.classes.gridHACK
    const table = gridThis.props.classes.table

    return <Table.Table {...props} className={cx(gridHACK, table)} />
  }

  return TableComponentWithGridProps
}

const RowComponentBase = gridThis => {
  const RowComponentWithGridProps = props => {
    const className = gridThis.props.rowStyles(props.row)

    return <Table.Row {...props} className={className} style={{ height: 68 }} />
  }

  return RowComponentWithGridProps
}

const CellComponentBase = gridThis => {
  const CellComponentWithGridProps = props => {
    if (props.column.name === 'actions') {
      return (
        <Table.Cell className={gridThis.props.classes.rowActions} {...props}>
          {<gridThis.props.rowActions row={props.row} />}
        </Table.Cell>
      )
    } else {
      const classNames = []
      if (gridThis.props.selectSingleRow)
        classNames.push(gridThis.props.classes.clickable)
      if (props.column.disablePadding)
        classNames.push(gridThis.props.classes.noPadding)

      return <DisplayCell className={cx(...classNames)} {...props} />
    }
  }

  return CellComponentWithGridProps
}
/* eslint-enable react/prop-types */

const editToRight = ({ tableColumns }) => [
  ...tableColumns.filter(c => c.type !== 'editCommand'),
  { key: 'editCommand', type: 'editCommand', width: 140 },
]

export class DevXGrid extends React.PureComponent {
  state = {
    grouping: this.props.defaultGrouping,
    hiddenColumns: this.props.defaultHiddenColumnNames,
    columnOrder: this.props.defaultOrder,
    searchValue: '',
    selection: [],
    stored: true,
    collapsed: false,
  }

  componentDidMount() {
    this.loadSettings()
    if (this.props.collapsible) {
      window.addEventListener('resize', this.updateCollapsed)
      this.updateCollapsed()
    }
  }

  componentDidUpdate(prevProps) {
    this.storeSettings()

    // Swap out RowComponent instance to have updated rowStyles object
    if (prevProps.rowStyles && prevProps.rowStyles !== this.props.rowStyles) {
      this.RowComponent = RowComponentBase(this)
    }
  }

  componentWillUnmount() {
    this.storeSettings()
    this.props.collapsible &&
      window.removeEventListener('resize', this.updateCollapsed)
  }

  setGridRef = ref => (this.gridRef = ref)

  updateCollapsed = () => {
    const width = this.gridRef.clientWidth
    const collapsed = width < this.props.collapseAt
    if (collapsed !== this.state.collapsed) {
      const hiddenColumns = collapsed
        ? [
            ...this.props.columns
              .filter(({ displayWhenCollapsed }) => !displayWhenCollapsed)
              .map(({ name }) => name),
            ...(this.props.rowActions ? ['actions'] : []),
          ]
        : this.props.defaultHiddenColumnNames
      this.setState({ collapsed, hiddenColumns }, this.forceUpdate)
    }
  }

  storeSettings() {
    const { localSettingsKey } = this.props
    const { stored } = this.state

    if (localSettingsKey && !stored) {
      setPermanentItem(localSettingsKey, this.state).then(() =>
        this.setState({ stored: true })
      )
    }
  }

  validateColumnSettings(settings, defaultSettings) {
    if (!settings) {
      return defaultSettings
    }

    const names = ['actions'].concat(
      this.props.columns.map(({ name }) => name) || []
    )
    const valid = settings.every(column => {
      return names.includes(column.columnName || column)
    })

    return valid ? settings : defaultSettings
  }

  loadSettings() {
    const {
      localSettingsKey,
      defaultGrouping,
      defaultHiddenColumnNames,
      defaultOrder,
    } = this.props

    if (localSettingsKey) {
      getItem(localSettingsKey).then(settings => {
        if (settings) {
          this.setState({
            grouping: this.validateColumnSettings(
              settings.grouping,
              defaultGrouping
            ),
            hiddenColumns: this.validateColumnSettings(
              settings.hiddenColumns,
              defaultHiddenColumnNames
            ),
            columnOrder: this.validateColumnSettings(
              settings.columnOrder,
              defaultOrder
            ),
          })
        }
      })
    }
  }

  restoreDefaultSettings = () => {
    this.setState({
      grouping: this.props.defaultGrouping,
      columnOrder: this.props.defaultOrder,
      hiddenColumns: this.props.defaultHiddenColumnNames,
      stored: false,
    })
    this.storeSettings()
  }

  changeGrouping = grouping => {
    this.setState({ grouping, stored: false })
  }

  changeHiddenColumns = hiddenColumns => {
    this.setState({ hiddenColumns, stored: false })
  }

  changeColumnOrder = columnOrder => {
    this.setState({ columnOrder, stored: false })
  }

  changeSingleRowSelection = selectedRowIds => {
    const { getRowId, onSelect, rows } = this.props
    const newSelectedId = selectedRowIds.find(
      id => !this.state.selection.includes(id)
    )

    if (newSelectedId === undefined) {
      this.setState({ selection: [] })
      return
    }

    const selectedRow = rows.find((row, index) =>
      getRowId ? getRowId(row) === newSelectedId : index === newSelectedId
    )

    this.setState({ selection: [newSelectedId] })
    onSelect(selectedRow)
  }
  changeNormalSelection = selection => this.setState({ selection })
  changeSelection = this.props.selectSingleRow
    ? this.changeSingleRowSelection
    : this.changeNormalSelection

  renderCollapsedComponent = props => (
    <CollapsedComponent
      columns={this.props.columns}
      RowActions={this.props.rowActions}
      DetailComponent={this.props.detailComponent}
      {...props}
    />
  )

  TableComponent = TableComponentBase(this)
  RowComponent = RowComponentBase(this)
  CellComponent = CellComponentBase(this)

  EditRowComponent = props => (
    <Table.Row {...props} className={this.props.classes.alignItems} />
  )

  TableEditCellComponent = props => {
    const elements = React.Children.toArray(props.children)

    const children = elements.map(child => {
      return React.cloneElement(child, {
        disableEditButton: this.props.shouldDisableEditButton(props.row),
        disableDeleteButton: this.props.shouldDisableDeleteButton(props.row),
        disableAddButton: this.props.shouldDisableAddButton(props.row),
      })
    })

    return (
      <Table.Cell {...props} className={this.props.classes.alignMiddle}>
        {children}
      </Table.Cell>
    )
  }

  render() {
    const {
      batchActions,
      className,
      classes,
      columnExtensions,
      columnHideable,
      columnReorderable,
      columns,
      defaultCurrentPage,
      defaultExpandedGroups,
      defaultExpandedRowIds,
      defaultFilters,
      defaultGrouping,
      defaultHiddenColumnNames,
      defaultOrder = columns.map(c => c.name),
      defaultPageSize,
      defaultSorting,
      detailComponent,
      editable,
      expandedRowIds,
      filterable,
      getRowId,
      groupable,
      hideAdd,
      hideColumnsWhenGrouped,
      hideDelete,
      hideEdit,
      hideExpansionArrow,
      hideGroupingPanel,
      highlightSelectedRows,
      integratedGroupingColumnExtensions,
      isVirtual,
      leftColumns,
      onCommitChanges,
      onExpandedRowIdsChange,
      onRefresh,
      onToggle,
      pageSizes,
      pageable,
      refreshable,
      rowActions,
      rowActionsCount,
      rows,
      searchPlaceholder,
      searchValue,
      searchable,
      selectSingleRow,
      selectable,
      sortable,
      style,
      shouldSelectAll,
    } = this.props
    const { selection } = this.state

    let groupingExtensions
    let disabledGrouping = []

    if (groupable) {
      groupingExtensions = columns
        .filter(col => col.type === 'boolean')
        .map(col => ({
          columnName: col.name,
          criteria: col.criteria || booleanGroupingCriteria,
        }))
        .concat(integratedGroupingColumnExtensions || [])
      disabledGrouping = columns
        .filter(col => col.groupingEnabled === false)
        .map(col => ({
          columnName: col.name,
          groupingEnabled: false,
        }))
    }

    const tableColumnVisibilityColumnExtensions = columns
      .filter(col => col.togglingEnabled === false)
      .map(col => ({ columnName: col.name, togglingEnabled: false }))

    const filteringStateColumnExtensions = columns
      .filter(col => col.filteringEnabled === false)
      .map(col => ({ columnName: col.name, filteringEnabled: false }))

    const columnExtensionsWithActions = rowActions
      ? [
          ...columnExtensions,
          {
            columnName: 'actions',
            width: 48 * (rowActionsCount + 1),
            sortingEnabled: false,
            groupingEnabled: false,
          },
        ]
      : columnExtensions

    const groupComponents = groupable
      ? {
          state: (
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.changeGrouping}
              defaultGrouping={defaultGrouping}
              defaultExpandedGroups={defaultExpandedGroups}
              columnExtensions={disabledGrouping.concat(
                columnExtensionsWithActions
              )}
            />
          ),
          integration: (
            <IntegratedGrouping columnExtensions={groupingExtensions} />
          ),
          ui: (
            <TableGroupRow
              cellComponent={GroupCell}
              showColumnsWhenGrouped={!hideColumnsWhenGrouped}
              indentColumnWidth={
                this.props.collapsible && this.state.collapsed
                  ? 0
                  : this.props.indentColumnWidth
              }
            />
          ),
          panel: !hideGroupingPanel ? (
            <GroupingPanel
              showGroupingControls
              showSortingControls={sortable}
              emptyMessageComponent={EmptyGroupMessage}
              columnExtensions={columnExtensions}
            />
          ) : null,
        }
      : {}

    const sortComponents = sortable
      ? {
          state: (
            <SortingState
              {...(this.props.onSortingChange
                ? {
                    onSortingChange: this.props.onSortingChange,
                    sorting: this.props.sorting,
                  }
                : {
                    columnExtensions: columnExtensionsWithActions,
                    defaultSorting: defaultSorting,
                  })}
            />
          ),
          integration: !this.props.onSortingChange && (
            <IntegratedSorting columnExtensions={columnExtensions} />
          ),
        }
      : {}

    const filterComponents = filterable
      ? {
          state: (
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
              {...(this.props.onFilterChange && {
                onFiltersChange: this.props.onFilterChange,
                filters: this.props.filters,
              })}
            />
          ),
          ui: <TableFilterRow cellComponent={FilterCell} />,
        }
      : {}

    let searchComponents = searchable
      ? {
          state: <SearchState />,
          panel: <SearchPanel messages={{ searchPlaceholder }} />,
        }
      : {}

    searchComponents =
      typeof searchValue == 'string'
        ? {
            state: <SearchState value={searchValue} />,
            panel: null,
          }
        : searchComponents

    const detailComponents =
      detailComponent || this.state.collapsed
        ? {
            state: (
              <RowDetailState
                defaultExpandedRowIds={defaultExpandedRowIds}
                expandedRowIds={expandedRowIds}
                onExpandedRowIdsChange={onExpandedRowIdsChange}
              />
            ),
            ui: (
              <TableRowDetail
                contentComponent={
                  this.state.collapsed
                    ? this.renderCollapsedComponent
                    : detailComponent
                }
                toggleCellComponent={props => {
                  const hideArrow = hideExpansionArrow?.(props.row)

                  if (hideArrow) {
                    return (
                      <TableRowDetail.ToggleCell
                        style={{ visibility: 'hidden' }}
                      />
                    )
                  } else {
                    return (
                      <TableRowDetail.ToggleCell
                        {...props}
                        onToggle={() => {
                          props.onToggle()
                          const rowId =
                            props && props.tableRow
                              ? props.tableRow.rowId
                              : null
                          return onToggle(props.row, props.expanded, rowId)
                        }}
                      />
                    )
                  }
                }}
              />
            ),
          }
        : {}

    const pagingComponents = pageable
      ? {
          state: (
            <PagingState
              {...(this.props.onCurrentPageChange
                ? {
                    currentPage: this.props.currentPage,
                    onCurrentPageChange: this.props.onCurrentPageChange,
                    pageSize: this.props.pageSize,
                    onPageSizeChange: this.props.onPageSizeChange,
                  }
                : {
                    defaultCurrentPage: defaultCurrentPage,
                    defaultPageSize: defaultPageSize,
                  })}
            />
          ),
          integration: this.props.onCurrentPageChange ? (
            <CustomPaging totalCount={this.props.totalCount} />
          ) : (
            <IntegratedPaging />
          ),
          panel: <PagingPanel pageSizes={pageSizes} />,
        }
      : {}

    const selectionComponents = selectable
      ? {
          count: !selectSingleRow && (
            <Typography className={classes.selectedRowsCount} component="span">
              Total rows selected: {selection.length}
            </Typography>
          ),
          state: (
            <SelectionState
              selection={selection}
              onSelectionChange={this.changeSelection}
            />
          ),
          integration: <IntegratedSelection />,
          ui: (
            <TableSelection
              selectByRowClick={selectSingleRow}
              showSelectAll={!selectSingleRow}
              showSelectionColumn={!selectSingleRow}
              highlightRow={highlightSelectedRows}
            />
          ),
        }
      : {}

    const editComponents = editable
      ? {
          state: (
            <EditingState
              onCommitChanges={onCommitChanges}
              columnExtensions={columnExtensions}
            />
          ),
          integration: (
            <TableEditColumn
              data-track-content
              data-content-name="Edit Column"
              showAddCommand={!hideAdd}
              showDeleteCommand={!hideDelete}
              showEditCommand={!hideEdit}
              cellComponent={this.TableEditCellComponent}
              commandComponent={Command}
            />
          ),
          ui: (
            <TableEditRow
              rowComponent={this.EditRowComponent}
              cellComponent={EditCell}
            />
          ),
        }
      : {}

    const columnReorder = columnReorderable ? (
      <TableColumnReordering
        order={
          this.state.columnOrder || [
            ...(rowActions ? ['actions'] : []),
            ...defaultOrder,
          ]
        }
        onOrderChange={this.changeColumnOrder}
      />
    ) : null
    const columnHiding =
      columnHideable || this.state.collapsed
        ? {
            ui: (
              <TableColumnVisibility
                emptyMessageComponent={NoColumnsMessage}
                columnExtensions={tableColumnVisibilityColumnExtensions || []}
                defaultHiddenColumnNames={defaultHiddenColumnNames}
                onHiddenColumnNamesChange={this.changeHiddenColumns}
                hiddenColumnNames={this.state.hiddenColumns}
              />
            ),
            panel: <ColumnChooser />,
          }
        : {}

    const toolbar =
      columnHideable ||
      (groupable && !hideGroupingPanel) ||
      searchable ||
      refreshable ||
      this.state.collapsed ? (
        <Toolbar />
      ) : null

    const extendedColumns = rowActions
      ? [{ name: 'actions', title: ' ' }, ...columns]
      : columns

    return (
      <div style={style} className={className} ref={this.setGridRef}>
        {selectionComponents.count}

        <Grid rows={rows} columns={extendedColumns} getRowId={getRowId}>
          {groupable || columnReorderable ? <DragDropProvider /> : null}
          {filterComponents.state}
          {sortComponents.state}
          {selectionComponents.state}
          {groupComponents.state}
          {pagingComponents.state}
          {editComponents.state}
          {searchComponents.state}
          {detailComponents.state}
          {groupComponents.integration}
          {searchable || filterable ? <IntegratedFiltering /> : null}
          {sortComponents.integration}
          {shouldSelectAll
            ? selectionComponents.integration
            : pagingComponents.integration}
          {shouldSelectAll
            ? pagingComponents.integration
            : selectionComponents.integration}
          {isVirtual ? (
            <VirtualTable
              forceUpdate={this.props.forceUpdate}
              tableComponent={this.TableComponent}
              rowComponent={this.RowComponent}
              cellComponent={this.CellComponent}
              columnExtensions={columnExtensionsWithActions}
              estimatedRowHeight={68}
            />
          ) : (
            <Table
              forceUpdate={this.props.forceUpdate}
              tableComponent={this.TableComponent}
              rowComponent={this.RowComponent}
              cellComponent={this.CellComponent}
              columnExtensions={columnExtensionsWithActions}
            />
          )}
          {!(this.props.collapsible && this.state.collapsed) &&
            editComponents.integration}
          {editable ? (
            <Getter name="tableColumns" computed={editToRight} />
          ) : null}
          {sortComponents.ui}
          {detailComponents.ui}
          {editComponents.ui}
          {selectionComponents.ui}
          {columnReorder}
          <TableHeaderRow showSortingControls={sortable} />
          {pagingComponents.panel}
          {groupComponents.ui}
          {filterComponents.ui}
          {columnHiding.ui}
          {toolbar}
          {!!this.props.localSettingsKey && (
            <ResetButton restoreDefaultSettings={this.restoreDefaultSettings} />
          )}
          {refreshable && <RefreshButton onRefresh={onRefresh} />}
          {groupComponents.panel}
          <TableFixedColumns leftColumns={leftColumns} />
          {searchComponents.panel}
          {columnHiding.panel}
          <Template name="footer">
            <div className={classes.footer}>
              <div>
                {typeof batchActions === 'function' &&
                  batchActions({ selection })}
              </div>
              <TemplatePlaceholder />
            </div>
          </Template>
        </Grid>
      </div>
    )
  }
}

DevXGrid.propTypes = {
  autoLayout: PropTypes.bool,
  batchActions: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  collapseAt: PropTypes.number,
  collapsible: PropTypes.bool,
  columnExtensions: PropTypes.array,
  columnHideable: PropTypes.bool,
  columnReorderable: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  defaultColumnWidths: PropTypes.array,
  defaultCurrentPage: PropTypes.number,
  defaultExpandedGroups: PropTypes.array,
  defaultExpandedRowIds: PropTypes.array,
  defaultFilters: PropTypes.array,
  defaultGrouping: PropTypes.array,
  defaultHiddenColumnNames: PropTypes.array,
  defaultOrder: PropTypes.array,
  defaultPageSize: PropTypes.number,
  defaultSorting: PropTypes.array,
  detailComponent: PropTypes.elementType,
  editable: PropTypes.bool,
  expandedRowIds: PropTypes.array,
  filterable: PropTypes.bool,
  filters: PropTypes.array,
  forceUpdate: PropTypes.any,
  getRowId: PropTypes.func,
  groupable: PropTypes.bool,
  hideAdd: PropTypes.bool,
  hideColumnsWhenGrouped: PropTypes.bool,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideExpansionArrow: PropTypes.func,
  hideGroupingPanel: PropTypes.bool,
  highlightSelectedRows: PropTypes.bool,
  indentColumnWidth: PropTypes.number,
  integratedGroupingColumnExtensions: PropTypes.array,
  isVirtual: PropTypes.bool,
  leftColumns: PropTypes.array,
  localSettingsKey: PropTypes.string,
  onCommitChanges: PropTypes.func,
  onCurrentPageChange: PropTypes.func,
  onExpandedRowIdsChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onRefresh: PropTypes.func,
  onSelect: PropTypes.func,
  onSortingChange: PropTypes.func,
  onToggle: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.array,
  pageable: PropTypes.bool,
  refreshable: PropTypes.bool,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
  rowStyles: PropTypes.func,
  rows: PropTypes.array.isRequired,
  searchPlaceholder: PropTypes.string,
  searchValue: PropTypes.string,
  searchable: PropTypes.bool,
  selectSingleRow: PropTypes.bool,
  selectable: PropTypes.bool,
  shouldDisableAddButton: PropTypes.func,
  shouldDisableDeleteButton: PropTypes.func,
  shouldDisableEditButton: PropTypes.func,
  sortable: PropTypes.bool,
  sorting: PropTypes.array,
  style: PropTypes.object,
  totalCount: PropTypes.number,
  shouldSelectAll: PropTypes.bool,
}

DevXGrid.defaultProps = {
  collapseAt: 480,
  collapsible: false,
  columnExtensions: [],
  columnReorderable: false,
  defaultCurrentPage: 0,
  defaultFilters: [],
  defaultGrouping: [],
  defaultHiddenColumnNames: [],
  defaultPageSize: 10,
  editable: false,
  filterable: false,
  filters: [],
  groupable: false,
  hideAdd: false,
  hideColumnsWhenGrouped: false,
  hideDelete: false,
  hideEdit: false,
  hideGroupingPanel: false,
  highlightSelectedRows: false,
  indentColumnWidth: 48,
  integratedGroupingColumnExtensions: [],
  isVirtual: false,
  leftColumns: [],
  onRefresh: () => {},
  onSelect: () => {},
  onToggle: () => {},
  pageable: false,
  refreshable: false,
  rowStyles: () => {},
  searchPlaceholder: 'Search...',
  searchable: false,
  selectSingleRow: false,
  selectable: false,
  shouldDisableAddButton: () => false,
  shouldDisableDeleteButton: () => false,
  shouldDisableEditButton: () => false,
  sortable: false,
  sorting: [],
  shouldSelectAll: false,
}

export default withStyles(styles)(DevXGrid)
