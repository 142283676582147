import { get, hasIn } from 'lodash'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import {
  carePlanSet,
  mapAssessmentCarePlan,
  mapProblems,
  problemsInitialized,
  problemsSet,
} from '~/features/problems/data'
import { assessmentHasCarePlan } from '~/features/problems/utils'
// @ts-expect-error no export
import { currentAssessmentFetched } from '../data/assessments'

const carePlanEpic = (action$: any) =>
  action$.pipe(
    ofType(currentAssessmentFetched),
    pluck('payload', 'assessment'),
    filter(assessmentHasCarePlan),
    mergeMap((assessment: any) => {
      const { carePlan, legacy } = mapAssessmentCarePlan(assessment)

      return of(problemsInitialized(), carePlanSet(carePlan, legacy))
    })
  )

const problemsEpic = (action$: any) =>
  action$.pipe(
    ofType(currentAssessmentFetched),
    pluck('payload', 'assessment'),
    filter(
      assessment =>
        hasIn(assessment, 'tags.problemsList.data') &&
        !assessmentHasCarePlan(assessment)
    ),
    mergeMap(({ formData, tags }) => {
      const problemsData = get(formData, tags.problemsList.data, [])
      const problems = mapProblems(problemsData)

      return of(problemsInitialized(), problemsSet(problems))
    })
  )

export default combineEpics(carePlanEpic, problemsEpic)
