import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getEventsRoot } from './root'

const FILTERS = 'filters'

export const Filters = Record({
  userId: null,
  startDate: null,
  endDate: null,
})

const creator = scopedCreator(rootKey)
export const filtersSet = creator('FILTERS_SET', [
  'userId',
  'startDate',
  'endDate',
])

export default createReducer(FILTERS, Filters(), {
  [filtersSet]: (_state, { payload: { userId, startDate, endDate } }) =>
    Filters({ userId, startDate, endDate }),
})

export const getFilters = pipe(getEventsRoot, get(FILTERS))
