import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { distinctUntilChanged, mergeMap, pluck } from 'rxjs/operators'
import {
  REQUEST_ORDERS_FOR_CARE_TEAM_MEMBER_ID,
  REQUEST_ORDERS_FOR_USER_ID,
} from '../data/loaders'
import { ordersStateCleared } from '../data/orders'
import { userIdChanged } from '../data/userId'

export default action$ =>
  action$.pipe(
    ofType(REQUEST_ORDERS_FOR_CARE_TEAM_MEMBER_ID, REQUEST_ORDERS_FOR_USER_ID),
    pluck('payload', 'userId'),
    distinctUntilChanged(),
    mergeMap(userId => of(ordersStateCleared(), userIdChanged(userId)))
  )
