import * as React from 'react'
import PropTypes from '~/utils/propTypes'
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

const ResetButton = props => {
  const { restoreDefaultSettings } = props

  return (
    <Plugin name="SettingReset">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {() => (
            <React.Fragment>
              <Tooltip placement="bottom" title="Restore Defaults">
                <IconButton onClick={restoreDefaultSettings} size="small">
                  <Icon>settings_backup_restore</Icon>
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  )
}

ResetButton.propTypes = {
  restoreDefaultSettings: PropTypes.func,
}

export default ResetButton
