import { List } from 'immutable'
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { getRoot, typePrefixCreator } from './common/shared'
import { fetchConfig } from './config'

const PREFERRED_PHARMACY = 'PREFERRED_PHARMACY'

const typePrefix = typePrefixCreator(PREFERRED_PHARMACY)
export const PREFERRED_PHARMACY_CLEARED = type(
  typePrefix,
  'PREFERRED_PHARMACY_CLEARED'
)
export const preferredPharmacyCleared = creator(PREFERRED_PHARMACY_CLEARED)

export default createReducer(PREFERRED_PHARMACY, List(), {
  [fetchConfig.SUCCEEDED]: (_state, { payload: { preferredPharmacy } }) =>
    preferredPharmacy,
  [PREFERRED_PHARMACY_CLEARED]: () => List(),
})

export const getPreferredPharmacy = compose(get(PREFERRED_PHARMACY), getRoot)
