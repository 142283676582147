// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-immutable` if it exi... Remove this comment to see the full error message
import { combineReducers } from 'redux-immutable'
import carePlanTypes from './carePlanTypes'
import problemTypesReducer from './problemTypes'
import { TYPES } from './root'

const typesReducer = combineReducers({
  [carePlanTypes.key]: carePlanTypes,
  [problemTypesReducer.key]: problemTypesReducer,
})

typesReducer.key = TYPES

export default typesReducer

export * from './root'
export * from './carePlanTypes'
export * from './problemTypes'
