import { Set } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'
import { fetchSelectedAPPs } from './selectedAPPs'

const PENDING_SELECTED_APPS = 'pendingSelectedAPPs'

const creator = scopedCreator(rootKey)
export const selectedAPPToggled = creator('SELECTED_APP_TOGGLED', ['id'])
export const selectedAPPsCleared = creator('SELECTED_APPS_CLEARED', false)

const initState = Set()
export default createReducer(PENDING_SELECTED_APPS, initState, {
  [fetchSelectedAPPs.SUCCEEDED]: (_state, { payload }) => payload,
  [selectedAPPToggled]: (state, { payload: { id } }) =>
    state.includes(id) ? state.delete(id) : state.add(id),
  [selectedAPPsCleared]: () => initState,
})

export const getPendingSelectedAPPs = pipe(getRoot, get(PENDING_SELECTED_APPS))
