import { drugForms, options, routes } from './directionsConstants'

const hasDose = new RegExp(/(^\d+\s)/)
const hasForm = new RegExp(/(^\d+\s\w+\(s\)\s)/)
const hasRoute = new RegExp(/(^\d+\s\w+\(s\)\s[a-zA-Z\s]+\s\d*)/)
const hasFrequency = new RegExp(/(^\d+\s\w+\(s\)\s[a-zA-Z\s]+\d+\s)/)

export default val =>
  hasFrequency.test(val)
    ? suggestOptions(val)
    : hasRoute.test(val)
    ? suggestFrequency(val)
    : hasForm.test(val)
    ? suggestRoutes(val)
    : hasDose.test(val)
    ? suggestForms(val)
    : noSuggestions(val)

const noSuggestions = val => [val || '']

const suggestFrequency = val => [`${val.split(hasRoute)[1]} #`]

const suggestOptions = val =>
  options.map(
    option => `${val.split(hasFrequency)[1]}times a day ${option.name}`
  )

const suggestRoutes = val =>
  routes.map(route => `${val.split(hasForm)[1]}${route.name}`)

const suggestForms = val =>
  drugForms.map(form => `${val.split(hasDose)[1]}${form.name}(s)`)
