import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Button, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FieldWrapper from '../FieldWrapper'
import memoizedWrapper from '../widgets/memoizedWrapper'

const RATIO = 16 / 5

const getWidth = () => Math.round(window.innerWidth * 0.85)

const styles = ({ palette, spacing }) => ({
  canvas: {
    border: ({ errorSchema }) =>
      // Using string since array was not working
      `2px solid ${
        errorSchema.signature ? palette.error.main : palette.divider
      }`,
    margin: [spacing(2), spacing(1)],
  },
  icon: {
    marginRight: spacing(1),
  },
  info: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
})

class SignatureField extends React.Component {
  state = {
    data: [],
    previousWidth: getWidth(),
    width: getWidth(),
  }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
    this.props.onChange({
      signature: '',
      signatureData: [],
    })
  }

  onEnd = () => {
    this.props.onChange({
      signature: this.sigPad.toDataURL('image/png'),
      signatureData: this.sigPad.toData(),
    })
  }

  updateWidth = () =>
    this.setState(
      ({ width }) => ({
        data: this.sigPad.toData(),
        previousWidth: width,
        width: getWidth(),
      }),
      () => {
        const { previousWidth, width } = this.state
        const ratio = width / previousWidth

        this.sigPad.fromData(
          this.state.data.map(line =>
            line.map(point => ({
              ...point,
              x: Math.round(point.x * ratio),
              y: Math.round(point.y * ratio),
            }))
          )
        )
      }
    )

  componentDidMount() {
    this.props.formData?.signatureData &&
      this.sigPad.fromData(this.props.formData.signatureData)
    window.addEventListener('resize', this.updateWidth)
    this.updateWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth)
  }

  render() {
    const { classes, schema, uiSchema } = this.props
    const { width } = this.state

    return (
      <React.Fragment>
        <FieldWrapper schema={schema} uiSchema={uiSchema}>
          <SignatureCanvas
            canvasProps={{
              className: classes.canvas,
              height: Math.round(width / RATIO),
              width,
            }}
            clearOnResize={false}
            maxWidth={2}
            minWidth={2}
            onEnd={this.onEnd}
            ref={ref => {
              this.sigPad = ref
            }}
          />
          <div className={classes.info}>
            <Button onClick={this.clear} size="small" variant="outlined">
              <Icon className={classes.icon}>clear</Icon>
              Clear
            </Button>
          </div>
        </FieldWrapper>
      </React.Fragment>
    )
  }
}

SignatureField.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.shape({
    signature: PropTypes.string,
    signatureData: PropTypes.array,
  }),
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
}

export default compose(memoizedWrapper, withStyles(styles))(SignatureField)
