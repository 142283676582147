import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const QUICK_LOCATIONS = 'quickLocations'

const QuickLocation = Record({
  id: null,
  label: null,
  lat: null,
  lng: null,
})

const QuickLocations = Record({
  locationA: QuickLocation(),
  locationB: QuickLocation(),
  directions: null,
})

const creator = scopedCreator(rootKey)
export const locationASelected = creator('LOCATION_A_SELECTED', [
  'id',
  'label',
  'lat',
  'lng',
])
export const locationBSelected = creator('LOCATION_B_SELECTED', [
  'id',
  'label',
  'lat',
  'lng',
])
export const directionsSet = creator('DIRECTIONS_SET', ['directions'])
export const directionsCleared = creator('DIRECTIONS_CLEARED', false)

const initState = QuickLocations()
export default createReducer(QUICK_LOCATIONS, initState, {
  [locationASelected]: (state, { payload }) => {
    const newState = state.set('locationA', QuickLocation(payload))

    return newState.locationB.id === payload.id
      ? newState.set('locationB', QuickLocation())
      : newState
  },
  [locationBSelected]: (state, { payload }) => {
    const newState = state.set('locationB', QuickLocation(payload))

    return newState.locationA.id === payload.id
      ? newState.set('locationA', QuickLocation())
      : newState
  },
  [directionsSet]: (state, { payload: { directions } }) =>
    state.set('directions', directions),
  [directionsCleared]: () => initState,
})

const getQuickLocations = pipe(getRoot, get(QUICK_LOCATIONS))

export const getLocationA = state => getQuickLocations(state).get('locationA')
export const getLocationB = state => getQuickLocations(state).get('locationB')
export const getDirections = state => getQuickLocations(state).get('directions')
