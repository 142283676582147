import { combineEpics } from 'redux-observable'
import { entered } from 'redux-routable'
import { filter, map, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { orderSelected } from '~/features/orders'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { taskSelected } from '~/features/tasks'
import {
  APP_DASHBOARD_ORDER_DETAIL,
  APP_DASHBOARD_TASK_DETAIL,
} from '../router'

const orders = (action$: any) =>
  action$.pipe(
    filter(entered(APP_DASHBOARD_ORDER_DETAIL)),
    pluck('payload', 'params', 'orderId'),
    map(orderSelected)
  )

const tasks = (action$: any) =>
  action$.pipe(
    filter(entered(APP_DASHBOARD_TASK_DETAIL)),
    pluck('payload', 'params', 'taskId'),
    map(taskSelected)
  )

export default combineEpics(orders, tasks)
