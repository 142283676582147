import React, { useEffect, useMemo } from 'react'
import useToggle from '~/hooks/useToggle'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  dialogActions: {
    margin: 0,
    padding: spacing(1),
  },
  dialogTitle: {
    padding: spacing(2, 3),
  },
}))

// Note (Nelson): Fix the any types on the event
type ActionDialogProps = {
  mode: 'confirm' | 'cancel' | 'create' | 'add' | 'save' | 'delete'
  open: boolean
  title: string
  disableAction?: boolean
  hideAction?: boolean
  children: React.ReactNode
  onConfirm?: (event: any) => void
  onCancel?: (event: any) => void
  onCreate?: (event: any) => void
  onAdd?: (event: any) => void
  onSave?: (event: any) => void
  onDelete?: (event: any) => void
  onClose: () => void
}

type Props = Omit<DialogProps, 'onClose'> & ActionDialogProps

export default function ActionDialog({
  title,
  children,
  onClose,
  mode,
  disableAction,
  hideAction,
  onConfirm,
  onCancel,
  onCreate,
  onAdd,
  onSave,
  onDelete,
  ...props
}: Props) {
  const classes = useStyles()

  const [open, toggleOpen, toggleClosed] = useToggle()

  // Prevents content in children from poofing as dialog closes
  useEffect(() => {
    if (props.open !== open) props.open ? toggleOpen() : toggleClosed()
  }, [props.open])

  const modeData = useMemo(
    () => ({
      confirm: { label: 'Confirm', callback: onConfirm },
      cancel: { label: 'Cancel', callback: onCancel },
      create: { label: 'Create', callback: onCreate },
      add: { label: 'Add', callback: onAdd },
      save: { label: 'Save', callback: onSave },
      delete: { label: 'Delete', callback: onDelete },
    }),
    [onConfirm, onCancel, onCreate, onAdd, onSave, onDelete]
  )

  return (
    <Dialog
      {...props}
      open={open}
      onClose={toggleClosed}
      onExited={onClose}
      disableBackdropClick
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button color="secondary" onClick={toggleClosed}>
          Close
        </Button>
        {!hideAction && (
          <Button
            color="primary"
            variant="contained"
            disabled={disableAction}
            onClick={modeData[mode].callback}
          >
            {modeData[mode].label}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
