import React from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  getPatientContactFormPhones,
  patientContactFormPhonesCreator,
} from '~/apps/patientRecord/data/patientContactForm'
import { getFieldValues } from '~/data/fieldValues'
import { Authorized, actions, objects } from '~/features/authorization'
import { useAction } from '~/hooks'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import { formatPhoneNumberToUS } from '~/utils/format'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { patientContactFormUseStyles } from '../utils/utils'

interface PatientPhoneFormPropTypes {
  phone: PropTypes.object.isRequired
  control: PropTypes.object.isRequired
  isCaremoreBrand: PropTypes.Boolean
}

export const PatientPhoneForm = ({
  phone,
  control,
  isCaremoreBrand,
}: PatientPhoneFormPropTypes) => {
  const classes = patientContactFormUseStyles()

  const phones = useSelector(getPatientContactFormPhones)
  const setPhones = useAction(patientContactFormPhonesCreator)
  const phoneTypeFields = useSelector(getFieldValues(['contact_phone_type']))
  const contactPhoneInactiveReason = useSelector(
    getFieldValues(['contact_phone_inactive_reason'])
  )

  const removePhone = (currentIndex: any, isPrimary: any) => {
    const item = phones.find((p: any) => p.index !== currentIndex && !p.delete)

    const updatedPhones: any[] = phones.map((el: any) => {
      const temp = {
        id: el.id,
        phone: el.phone,
        type: el.type,
        textingApproved: el.textingApproved,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        index: el.index,
      }
      if (temp.index === currentIndex) {
        temp.primary = false
        temp.delete = true
      }

      if (isPrimary && item && temp.index === item.index) {
        temp.primary = true
      }
      return temp
    })
    setPhones(updatedPhones)
  }

  const markPrimaryPhone = (currentIndex: any) => {
    const updatedPhones: any[] = phones.map((el: any) => {
      const temp = {
        id: el.id,
        phone: el.phone,
        type: el.type,
        textingApproved: el.textingApproved,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        index: el.index,
        source: el.source,
        sourceLabel: el.sourceLabel,
        inactiveReason: phone?.inactiveReason,
      }

      temp.index === currentIndex
        ? (temp.primary = true)
        : (temp.primary = false)
      return temp
    })

    setPhones(updatedPhones)
  }

  function handlePhoneChange(event: any, currentIndex: any) {
    const updatedPhones = phones.map((el: any) => {
      const temp = {
        id: el.id,
        phone: el.phone,
        type: el.type,
        textingApproved: el.textingApproved,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        index: el.index,
        source: el.source,
        sourceLabel: el.sourceLabel,
        inactiveReason: el.inactiveReason,
      }

      if (el.index === currentIndex) {
        switch (event.target.name) {
          case 'phone':
            temp.phone = event.target.value
            break
          case '':
            temp.type = event.target.value.toLowerCase()
            break
          case 'textingApproved':
            temp.textingApproved = event.target.checked
            break
          case 'active':
            temp.active = event.target.checked
            break
        }
      }
      return temp
    })
    setPhones(updatedPhones)
  }

  function handleInactiveReasonChange(event: any, currentIndex: any) {
    const updatedPhones = phones.map((el: any) => {
      const temp = {
        id: el.id,
        phone: el.phone,
        type: el.type,
        textingApproved: el.textingApproved,
        primary: el.primary,
        active: el.active,
        delete: el.delete,
        index: el.index,
        source: el.source,
        sourceLabel: el.sourceLabel,
        inactiveReason: el.inactiveReason,
      }

      if (el.index === currentIndex) {
        switch (event.target.name) {
          case 'inactiveReason':
            temp.inactiveReason = event.target.value.toLowerCase()
            break
          case '':
            temp.inactiveReason = event.target.value.toLowerCase()
            break
        }
      }

      return temp
    })

    setPhones(updatedPhones)
  }

  return (
    <React.Fragment key={phone?.index || phone?.id}>
      <Box display="flex" className={classes.marginBottomCls}>
        <IconButton onClick={() => markPrimaryPhone(phone?.index)}>
          <Icon color={phone.primary ? 'primary' : 'action'}> star </Icon>
        </IconButton>
        <Box className={classes.textFieldBox}>
          <TextField
            type="tel"
            required={true}
            variant="filled"
            fullWidth={true}
            label="Phone"
            value={phone.phoneFormatted || formatPhoneNumberToUS(phone.phone)}
            name="phone"
            inputProps={{
              pattern: '1?-?\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}',
            }}
            onChange={e => handlePhoneChange(e, phone?.index)}
          />
        </Box>
        <Box className={classes.textFieldBoxNew}>
          <Controller
            render={({ onChange }) => (
              <TextField
                label="Type"
                onChange={value => {
                  onChange(value)
                  handlePhoneChange(value, phone?.index)
                }}
                value={phone.type}
                variant="filled"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                select
                SelectProps={{ native: true }}
                required={true}
                fullWidth
              >
                <option value="" key="" />
                {phoneTypeFields.entrySeq().map(([k, v]: any) => (
                  <option value={v.value} key={k}>
                    {v.label}
                  </option>
                ))}
              </TextField>
            )}
            control={control}
            name="phoneType"
            rules={{ required: 'Please fill out this field.' }}
            variant="filled"
            defaultValue={phone?.type || ''}
          ></Controller>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={phone.textingApproved}
              name="textingApproved"
              onChange={e => handlePhoneChange(e, phone?.index)}
            />
          }
          label="Texting Approved"
        />
        <Tooltip
          title={phone.id ? 'Active Contact Method' : 'New Contact Method'}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={phone.active}
                disabled={phone.id ? false : true}
                name="active"
                onChange={e => handlePhoneChange(e, phone?.index)}
              />
            }
            label="Active"
          />
        </Tooltip>
        <Authorized object={objects.PATIENT_CONTACTS} action={actions.DELETE}>
          <IconButton onClick={() => removePhone(phone?.index, phone.primary)}>
            <Icon> close </Icon>
          </IconButton>
        </Authorized>
      </Box>
      <Box display="flex" className={classes.marginBottomCls}>
        {!phone.active && isCaremoreBrand && (
          <Box className={classes.textFieldBox}>
            <Controller
              render={({ onChange }) => (
                <TextField
                  label="Select the Reason for Phone# deactivation"
                  onChange={value => {
                    onChange(value)
                    handleInactiveReasonChange(value, phone?.index)
                  }}
                  value={phone.inactiveReason}
                  variant="filled"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  select
                  SelectProps={{ native: true }}
                  required={true}
                  fullWidth
                >
                  <option value="" key="" />
                  {contactPhoneInactiveReason.entrySeq().map(([k, v]: any) => (
                    <option value={v.value} key={k}>
                      {v.label}
                    </option>
                  ))}
                </TextField>
              )}
              control={control}
              name="inactiveReason"
              rules={{ required: 'Please fill out this field.' }}
              variant="filled"
              defaultValue={phone?.inactiveReason || ''}
            ></Controller>
          </Box>
        )}
      </Box>
    </React.Fragment>
  )
}
