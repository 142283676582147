import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'

const key = 'mdtRefillRequests'
const creator = scopedCreator(key)

export const mdtRefillRequestsCleared = creator('MDT_REFILL_REQUESTS_CLEARED', [
  key,
])

const mdtRefillRequestsApi = prescriber_id =>
  AspireAPI.get('medications/mdt_refills_count/', {
    params: { prescriber_id },
  })

export const fetchMDTRefillCount = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['prescriberId'],
  operation: mdtRefillRequestsApi,
  messages: {
    // NOTE(adam): preventing a message to not nag non-prescribers using APP dashboard
    // doing a role check to prevent the call itself would require another api call
    failed: null,
  },
})

export default createReducer(key, null, {
  [fetchMDTRefillCount.SUCCEEDED]: (_state, { payload }) => payload,
  [mdtRefillRequestsCleared]: () => null,
})

export const getRefillRequestsCount = getIn(['medications', key])
