import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const PATIENT_DETAILS = 'patientDetails'

const PatientDetail = Record({
  patientId: null,
  nextTargetedVisitDate: null,
  daysOverdue: null,
  lastVisitDate: null,
  dischargeReason: null,
  dischargeDate: null,
  dischargeNotes: null,
  dischargedBy: null,
  dischargeRecommendationCreatedDate: null,
  dischargeRecommendationReason: null,
  dischargeRecommendationNotes: null,
  dischargeRecommendationCreatedBy: null,
  providerPrognosis: null,
  hospiceEligible: null,
  currentHospitalizationAdmissionDate: null,
  currentHospitalizationInfo: null,
  currentHospitalizationReason: null,
  hospitalName: null,
  recentHospitalizationAdmissionDate: null,
  recentHospitalizationDischargeDate: null,
  recentHospitalizationDischargedTo: null,
  recentHospitalizationInfo: null,
  recentHospitalizationReason: null,
  currentSnfAdmissionDate: null,
  currentSnfNotes: null,
  recentSnfAdmissionDate: null,
  recentSnfDischargeDate: null,
  recentSnfNotes: null,
  lastCallDate: null,
})

export const fetchPatientDetails = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_DETAIL',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`dashboard/${patientId}/census_details`),
  transform: PatientDetail,
  messages: {
    failed: "There was an issue fetching this patient's details",
  },
})

export default createReducer(PATIENT_DETAILS, Map(), {
  [fetchPatientDetails.SUCCEEDED]: (state, { payload }) =>
    state.has(payload.patientId)
      ? state
      : state.set(payload.patientId, payload),
})

const getPatientDetails = pipe(getRoot, get(PATIENT_DETAILS))
export const getPatientDetailsByPatientId = (state, patientId) =>
  getPatientDetails(state).get(patientId)
