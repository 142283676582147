import moment from 'moment'
import momentTZ from 'moment-timezone'
import { Name } from '~/apps/patientRecord/data/patientSummaryRecords'

export const DATE_FORMAT = 'M/D/YYYY'
export const DATE_TIME_FORMAT = 'M/D/YYYY, h:mm a'
export const MONTH_FORMAT = 'M/YYYY'
export const SHORT_DATE_FORMAT = 'M/D/YY'
export const FULL_DATE_FORMAT = 'MM/DD/YYYY'
export const TIME_FORMAT = 'h:mm a'

export const DATE_PICKER_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_PICKER_TIME_FORMAT = 'HH:mm:ss'
export const DATE_PICKER_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm'

const timeZone = momentTZ.tz(momentTZ.tz.guess()).format('z')
const timeZoneLabels = {
  'America/Los_Angeles': 'Pacific Time',
  'America/Boise': 'Moutain Time',
  'America/Denver': 'Mountain Time',
  'America/Edmonton': 'Moutain Time',
  'America/Phoenix': 'Mountain Time (AZ)',
  'America/Chicago': 'Central Time',
  'America/El_Salvador': 'Central Time',
  'America/Matamoros': 'Central Time',
  'America/Detroit': 'Eastern Time',
  'America/New_York': 'Eastern Time',
  'America/Indiana/Indianapolis': 'Eastern Time',
  'America/Indiana/Winamac': 'Eastern Time',
  'America/Indiana/Knox': 'Eastern Time',
  'America/Indiana/Tell_City': 'Eastern Time',
  'America/Indiana/Marengo': 'Eastern Time',
  'America/Indiana/Vevay': 'Eastern Time',
  'America/Indiana/Vincennes': 'Eastern Time',
  'America/Indiana/Petersburg': 'Eastern Time',
  'America/Toronto': 'Eastern Time',
  'America/St_Thomas': 'Atlantic Time',
  'America/Puerto_Rico': 'Atlantic Time',
  'America/St_Barthelemy': 'Atlantic Time',
  'Pacific/Guam': 'Chamorro Time',
  'Pacific/Honolulu': 'Hawaii Time',
  'America/Anchorage': 'Alaska Time',
  'Europe/Berlin': 'Central European Time',
  'Europe/London': 'Greenwich Mean Time',
}
export const timeZoneAbbreviations = {
  'America/Los_Angeles': 'PT',
  'America/Boise': 'MT',
  'America/Denver': 'MT',
  'America/Edmonton': 'MT',
  'America/Phoenix': 'MT (AZ)',
  'America/Chicago': 'CT',
  'America/El_Salvador': 'CT',
  'America/Matamoros': 'CT',
  'America/Detroit': 'ET',
  'America/New_York': 'ET',
  'America/Indiana/Indianapolis': 'ET',
  'America/Indiana/Winamac': 'ET',
  'America/Indiana/Knox': 'ET',
  'America/Indiana/Tell_City': 'ET',
  'America/Indiana/Marengo': 'ET',
  'America/Indiana/Vevay': 'ET',
  'America/Indiana/Vincennes': 'ET',
  'America/Indiana/Petersburg': 'ET',
  'America/Toronto': 'ET',
  'America/St_Thomas': 'AT',
  'America/Puerto_Rico': 'AT',
  'America/St_Barthelemy': 'AT',
  'Pacific/Guam': 'ChT',
  'Pacific/Honolulu': 'HT',
  'America/Anchorage': 'AKT',
  'Europe/Berlin': 'CET',
  'Europe/London': 'GMT',
}

export const displayDate = value => formatDate(value.get('date'))

export const displayName = value => value.get('name')

export const displayPhone = value => value.get('phone')

export const displayFax = value => value.get('fax')

// Should be using the Location Record from ~/features/locationSearch
export const formatAddress = address => {
  const street = address.street || ''
  const city = address.city || ''
  const state = address.stateCode || address.state || ''
  const postalCode = address.postalCode || ''
  const apartment = address.apartment ? `#${address.apartment}` : ''

  return street
    ? `${street}, ${city}, ${state} ${postalCode} ${apartment}`
    : null
}

export const formatCheckbox = value => (value ? '✓' : '')

export const formatDate = date => {
  const formattedDate = moment(date).format(DATE_FORMAT)

  return formattedDate === 'Invalid date' || date === undefined
    ? ''
    : formattedDate
}

export const formatMonth = date => {
  const formattedDate = moment(date).format(MONTH_FORMAT)

  return formattedDate === 'Invalid date' ? '' : formattedDate
}

export const formatDateFromTimestamp = timestamp =>
  moment.utc(timestamp).local().format(DATE_FORMAT)

export const formatDatepickerDate = date => {
  const formattedDate = moment(date).format(DATE_PICKER_DATE_FORMAT)

  return formattedDate === 'Invalid date' ? '' : formattedDate
}

export const formatDateTime = (timestamp, timeZone) => {
  const shifted = timeZone
    ? momentTZ.utc(timestamp).tz(timeZone)
    : moment.utc(timestamp).local()

  const formattedDateTime = shifted.format(DATE_TIME_FORMAT)

  return formattedDateTime === 'Invalid date' ? '' : formattedDateTime
}

export const formatDateTimeWithTimeZone = date =>
  date ? formatDateTime(date) + ' ' + timeZone : null

export const formatName = (name = Name()) =>
  [name.salutation, name.firstName, name.middleName, name.lastName, name.suffix]
    .filter(Boolean)
    .join(' ')

export const formatPhoneNumber = (phone, isMasked = false) =>
  isMasked
    ? phone
        ?.replace(/[()\s\-']+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-****')
    : phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

export const formatPhoneNumberToUS = (phone, isMasked = false) =>
  isMasked
    ? phone
        ?.replace(/[()\s\-']+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) ***-****')
    : phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

export const formatShortDate = date => moment(date).format(SHORT_DATE_FORMAT)

export const formatShortUTCDate = date =>
  moment.utc(date).local().format(SHORT_DATE_FORMAT)

export const formatFullDate = date => moment(date).format(FULL_DATE_FORMAT)

export const formatTime = timestamp =>
  moment.utc(timestamp).local().format(TIME_FORMAT)

export const formatTimeZone = timeZone => timeZoneLabels[timeZone]

export const formatZipcode = zipcode =>
  zipcode && zipcode.length > 5 ? zipcode.substring(0, 5) : zipcode

export const todayUtcFormatted = () => moment.utc().format()

export const formatICD10Code = icd10Code =>
  icd10Code && icd10Code.length > 3
    ? icd10Code.substring(0, 3) + '.' + icd10Code.substring(3)
    : icd10Code
