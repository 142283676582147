import { combineReducers } from 'redux-immutable'
import caseManagers from './data/caseManagers'
import groups from './data/groups'
import healthPlans from './data/healthPlans'
import hospices from './data/hospices'
import hospitals from './data/hospitals'
import ipas from './data/ipas'
import markets from './data/markets'
import npiPhysicians from './data/npiPhysicians'
import physicians from './data/physicians'
import practices from './data/practices'
import vendors from './data/vendors'
import key from './key'

const reducer = combineReducers({
  [caseManagers.key]: caseManagers,
  [groups.key]: groups,
  [healthPlans.key]: healthPlans,
  [hospices.key]: hospices,
  [hospitals.key]: hospitals,
  [ipas.key]: ipas,
  [markets.key]: markets,
  [npiPhysicians.key]: npiPhysicians,
  [physicians.key]: physicians,
  [practices.key]: practices,
  [practices.key]: practices,
  [vendors.key]: vendors,
})

reducer.key = key

export default reducer
