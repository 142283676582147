import { colors } from '@material-ui/core'
import darkTheme from '../../dark'
import ornament from './ornament.png'
import snow1 from './snowflakes1.png'
import snow2 from './snowflakes2.png'

export const christmasStyles = () => ({
  '@global': {
    body: {
      '&:before': {
        animation: 'snowFall 16s linear infinite',
        background: `url(${snow1})`,
        bottom: 0,
        content: '""',
        left: '-500px',
        right: 0,
        position: 'fixed',
        top: '-500px',
        zIndex: '-10',
      },
      '&:after': {
        animation: 'snowFall2 12s linear infinite',
        background: `url(${snow2})`,
        bottom: 0,
        content: '""',
        filter: 'blur(2px)',
        left: 0,
        right: 0,
        position: 'fixed',
        top: '-300px',
        zIndex: '-20',
      },
    },
    '@keyframes snowFall': {
      '0%': { transform: 'translate3d(0, 0, 0)' },
      '50%': { transform: 'translate3d(250px, 250px, 0)' },
      '100%': { transform: 'translate3d(500px, 500px, 0)' },
    },
    '@keyframes snowFall2': {
      '0%': { transform: 'translate3d(0, 0, 0)' },
      '50%': { transform: 'translate3d(0, 150px, 0)' },
      '100%': { transform: 'translate3d(0, 300px, 0)' },
    },
  },
})

export const christmasTheme = () => ({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    holiday: 'christmas',
    primary: {
      light: colors.green[300],
      main: colors.green[500],
      dark: colors.green[700],
    },
    secondary: {
      main: '#D1964E',
    },
    drawer: {
      header: colors.green[800],
      backgroundClosed: {
        backgroundColor: colors.green[700],
      },
      backgroundOpen: {
        background: {
          color: colors.green[700],
        },
      },
    },
    contentText: {
      primaryLabel: colors.green[300],
      secondaryLabel: '#daab71',
    },
    calendarHead: {
      backgroundColor: colors.green[500],
    },
    calendarTitleText: {
      color: colors.green[500],
    },
    enrolledStatusBadge: {
      backgroundColor: colors.green[500],
      color: '#fff',
    },
    formPageTitle: {
      color: colors.green[500],
    },
    patientSearchProgramBadge: {
      backgroundColor: colors.red[700],
      color: colors.common.white,
    },
    vitalsToolbarHeader: {
      backgroundColor: colors.green[500],
      color: colors.common.white,
    },
    vitalsChipChosen: {
      backgroundColor: colors.green[500],
    },
    tabSelected: {
      backgroundColor: darkTheme.palette.shades.dark,
    },
    toolbarHeaderText: {
      color: colors.common.white,
    },
  },
  overrides: {
    ...darkTheme.overrides,
    MuiAppBar: {
      colorPrimary: {
        color: colors.common.white,
      },
    },
    MuiFab: {
      root: {
        '&::after': {
          content: '""',
          backgroundImage: `url(${ornament})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          bottom: -12,
          height: 100,
          position: 'absolute',
          right: -22,
          width: 100,
          zIndex: -10,
        },
      },
      label: {
        color: colors.common.white,
      },
      primary: {
        backgroundColor: 'transparent',
      },
    },
  },
})
