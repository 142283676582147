import React from 'react'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

/* eslint-disable react/prop-types */
const AddButton = ({ onExecute, disableAddButton }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      disabled={disableAddButton}
      color="primary"
      onClick={onExecute}
      title="Create new row"
    >
      New
    </Button>
  </div>
)

const EditButton = props => {
  return (
    <IconButton
      disabled={props.disableEditButton}
      onClick={props.onExecute}
      title="Edit row"
    >
      <Icon>edit</Icon>
    </IconButton>
  )
}

const DeleteButton = ({ onExecute, disableDeleteButton }) => (
  <IconButton
    disabled={disableDeleteButton}
    onClick={onExecute}
    title="Delete row"
  >
    <Icon>delete</Icon>
  </IconButton>
)

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <Icon>save</Icon>
  </IconButton>
)

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <Icon>cancel</Icon>
  </IconButton>
)

const commandComponents = {
  add: AddButton,
  cancel: CancelButton,
  commit: CommitButton,
  delete: DeleteButton,
  edit: EditButton,
}

/* eslint-enable react/prop-types */

const Command = props => {
  const {
    id,
    onExecute,
    disableEditButton,
    disableDeleteButton,
    disableAddButton,
  } = props
  const CommandButton = commandComponents[id]
  return (
    <CommandButton
      disableEditButton={disableEditButton}
      disableDeleteButton={disableDeleteButton}
      disableAddButton={disableAddButton}
      onExecute={onExecute}
    />
  )
}

Command.propTypes = {
  id: PropTypes.string.isRequired,
  onExecute: PropTypes.func.isRequired,
  disableEditButton: PropTypes.bool,
  disableDeleteButton: PropTypes.bool,
  disableAddButton: PropTypes.bool,
}

export default Command
