import { ofType } from 'redux-observable'
import { match } from 'redux-routable'
import { merge } from 'rxjs'
import { filter, map, mergeMap, pluck } from 'rxjs/operators'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/route' or its correspon... Remove this comment to see the full error message
import { getRoute } from '~/data/route'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/data/session' or its corresp... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
import {
  CHANNEL_JOINED,
  mapNotificationToRecord,
  // @ts-expect-error no export
} from '~/features/notifications'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/features/notifications/utils... Remove this comment to see the full error message
import getChannelName from '~/features/notifications/utils/getChannelName'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/channels' or its corre... Remove this comment to see the full error message
import { getChannelStream } from '~/utils/channels'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/operators' or its corr... Remove this comment to see the full error message
import { switchTo } from '~/utils/operators'
import {
  notificationAdded,
  notificationMarkedUnread,
  notificationsCleared,
} from '../data/notificationList'
import router from '../router'

export default (action$: any, state$: any) =>
  action$.pipe(
    ofType(CHANNEL_JOINED),
    switchTo(state$),
    filter(state => match(getRoute(state), router)),
    mergeMap(state => {
      const userId = getUserId(state)
      const channelName = getChannelName(userId)

      return merge(
        getChannelStream(channelName, 'new_notification').pipe(
          map(mapNotificationToRecord),
          map(notificationAdded)
        ),
        getChannelStream(channelName, 'clear_notifications').pipe(
          pluck('notificationIds'),
          map(notificationsCleared)
        ),
        getChannelStream(channelName, 'mark_notification_unread').pipe(
          // @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type '{ notifi... Remove this comment to see the full error message
          map(({ notification: [data] }) => data),
          map(mapNotificationToRecord),
          map(notificationMarkedUnread)
        )
      )
    })
  )
