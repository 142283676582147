import { OrderedMap, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'

export const ResearchCase = Record({
  caseNumber: null,
  closedDate: null,
  createdBy: null,
  createdById: null,
  createdDate: null,
  id: null,
  lastModifiedBy: null,
  lastModifiedById: null,
  lastModifiedDate: null,
  notes: null,
  resetCallAttempts: null,
  status: null,
})

// KEY
const key = 'researchCases'

// REQUEST
export const fetchResearchCases = Request({
  typePrefix: key,
  typeBase: 'FETCH_RESEARCH_CASES',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/patient/${patientId}/research`),
  transform: into(ResearchCase, 'id', OrderedMap),
  messages: {
    failed: 'Could not get patient research cases',
  },
})

// REDUCER
export default createReducer(key, OrderedMap(), {
  [fetchResearchCases.SUCCEEDED]: (_state, { payload }) => payload,
})

// SELECTORS
export const getResearchCases = pipe(getRoot, get(key))
