import { Record } from 'immutable'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'

const key = 'erVisitDialog'

const creator = scopedCreator(key)

export const Dialog = Record({
  mode: null,
  patientId: null,
  selectedERVisit: null,
})

export const modeSet = creator('MODE_SET', [
  'mode',
  'patientId',
  'selectedERVisit',
])

export default createReducer(key, Dialog(), {
  [modeSet]: (state, { payload }) => Dialog(payload),
})

export const getERVisitDialog = pipe(get(rootKey), get(key))
