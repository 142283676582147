import momentTZ from 'moment-timezone'
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getCalendar } from './root'

const TIME_ZONE = 'timeZone'

const creator = scopedCreator(rootKey)
export const timeZoneSet = creator('TIME_ZONE_SET', ['timeZone'])

export default createReducer(TIME_ZONE, momentTZ.tz.guess(), {
  [timeZoneSet]: (_state, { payload: { timeZone } }) => timeZone,
})

export const getTimeZone = pipe(getCalendar, get(TIME_ZONE))
