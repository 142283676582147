import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const key = 'focusedMarketPolygon'

export const FOCUSED_MARKET_POLYGON_SET = type(
  rootKey,
  'FOCUSED_MARKET_POLYGON_SET'
)

export const setFocusedMarketPolygon = creator(FOCUSED_MARKET_POLYGON_SET, 'id')

export default createReducer(key, '', {
  [FOCUSED_MARKET_POLYGON_SET]: (state, { payload: { id } }) => id,
})

export const getFocusedMarketPolygon = pipe(getRoot, get(key))
