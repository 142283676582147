import React from 'react'
import { Field } from 'redux-form/immutable'
import { NumberField, TextField } from '~/components/formFields'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import FormFieldWrapper from './FormFieldWrapper'

const styles = {
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '48%',
  },
  quarterWidth: {
    width: '22.5%',
  },
  formSpan: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const ExistingMedInfo = ({ classes }) => (
  <FormFieldWrapper title="Medication Information" nonCollapsible>
    <Field
      component={TextField}
      className={classes.fullWidth}
      label="Drug"
      name="drug"
      disabled
    />
    <Field
      component={NumberField}
      className={classes.fullWidth}
      label="DEA Schedule Code"
      name="schedule"
      disabled
    />
  </FormFieldWrapper>
)

ExistingMedInfo.propTypes = {
  classes: PropTypes.object,
}

const NewMedInfo = ({ classes }) => (
  <FormFieldWrapper title="Medication Information" nonCollapsible>
    <span className={classes.formSpan}>
      <Field
        component={TextField}
        className={classes.halfWidth}
        label="Drug"
        name="drug"
        disabled
      />
      <Field
        component={TextField}
        className={classes.halfWidth}
        label="Generic Name"
        name="genericname"
        disabled
      />
    </span>
    <span className={classes.formSpan}>
      <Field
        component={NumberField}
        className={classes.quarterWidth}
        label="DEA Schedule Code"
        name="schedule"
        disabled
      />
      <Field
        component={TextField}
        className={classes.quarterWidth}
        label="Route"
        name="route"
        disabled
      />
      <Field
        component={TextField}
        className={classes.quarterWidth}
        label="Dosage Form"
        name="dosageform"
        disabled
      />
      <Field
        component={TextField}
        className={classes.quarterWidth}
        label="Brand / Generic"
        name="brandGenericCode"
        disabled
      />
    </span>
  </FormFieldWrapper>
)

NewMedInfo.propTypes = {
  classes: PropTypes.object,
}

class MedInfoFields extends React.PureComponent {
  render() {
    const { classes, isExistingMed } = this.props

    return isExistingMed ? (
      <ExistingMedInfo classes={classes} />
    ) : (
      <NewMedInfo classes={classes} />
    )
  }
}

MedInfoFields.propTypes = {
  classes: PropTypes.object.isRequired,
  isExistingMed: PropTypes.bool,
}
export default withStyles(styles)(MedInfoFields)
