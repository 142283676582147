import { OrderedMap, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

const APP_LIST = 'appList'

const APPListItem = Record({
  id: null,
  isActive: null,
  market: null,
  name: null,
  numActiveScheduled: null,
  numCensusRequired: null,
  numDirectReferrals: null,
  numNewDueDirectReferrals: null,
  numNewDueReferrals: null,
  numTotalReferrals: null,
  primaryUserName: null,
  type: null,
})

const transformAPPList = (data: any) => {
  const filteredList = data.filter((app: any) => app.isActive)
  return into(APPListItem, 'id', OrderedMap)(filteredList)
}

export const fetchAPPList = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_APP_LIST',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('referral_scheduling/np_info', {
      params: {
        user_id: userId,
      },
    }),
  transform: transformAPPList,
  messages: {
    failed: 'There was an issue fetching the APP list',
  },
})

export const fetchSecondaryAPPs = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_SECONDARY_APPS',
  requestParams: ['userId'],
  operation: (userId: any) =>
    AspireAPI.get('referral_scheduling/secondary_apps', {
      params: {
        user_id: userId,
      },
    }),
  transform: transformAPPList,
  messages: {
    failed: 'There was an issue fetching the secondary APPs',
  },
})

export default createReducer(APP_LIST, OrderedMap(), {
  [fetchAPPList.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [fetchSecondaryAPPs.SUCCEEDED]: (state: any, { payload }: any) =>
    state.merge(payload),
})

export const getAPPList = pipe(getRoot, get(APP_LIST))
