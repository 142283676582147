import { useCallback, useState } from 'react'

type Toggle = [value: boolean, toggleTrue: () => void, toggleFalse: () => void]

export default (initValue = false): Toggle => {
  const [value, setValue] = useState(initValue)

  const toggleTrue = useCallback(() => setValue(true), [setValue])
  const toggleFalse = useCallback(() => setValue(false), [setValue])

  return [value, toggleTrue, toggleFalse]
}
