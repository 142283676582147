import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import {
  changePatientProgramAndCareTeam,
  fetchPatientCareTeam,
} from '../data/careTeams'

const loadPatientCareTeam = action$ =>
  action$.pipe(
    ofType(changePatientProgramAndCareTeam.SUCCEEDED),
    pluck('meta', 'request', 'payload', 'patientId'),
    mergeMap(patientId => of(fetchPatientCareTeam.requested(patientId)))
  )

export default loadPatientCareTeam
