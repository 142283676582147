import React from 'react'
import PropTypes from '~/utils/propTypes'
import { FormControl, InputLabel, Select } from '@material-ui/core'
import { TaskDefinition } from '../data/taskDefinitions'

const TaskDefinitionOption = ltd => (
  <option key={ltd.key} value={ltd.key}>
    {ltd.title}
  </option>
)

const TaskSelect = ({
  className,
  taskDefinitionsList,
  onChange,
  taskDefinitionKey,
}) => (
  <FormControl className={className} required>
    <InputLabel htmlFor="new-task-type">Task</InputLabel>
    <Select
      inputProps={{ id: 'new-task-type', name: 'type' }}
      native
      onChange={onChange}
      value={taskDefinitionKey || ''}
    >
      <option disabled value={''} />
      {taskDefinitionsList.map(TaskDefinitionOption)}
    </Select>
  </FormControl>
)

TaskSelect.propTypes = {
  className: PropTypes.string.isRequired,
  taskDefinitionsList: PropTypes.listOf(PropTypes.instanceOf(TaskDefinition))
    .isRequired,
  onChange: PropTypes.func.isRequired,
  taskDefinitionKey: PropTypes.string,
}

export default TaskSelect
