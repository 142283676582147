import React from 'react'
import { useSelector } from 'react-redux'
import { getUserById } from '~/data/users'
import { utcToLocal } from '~/utils/dates'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getTimeZone } from '../../data/calendar'
import BaseField from './BaseField'

const DATE_FORMAT = 'YYYY-MM-DD hh:mm a'

const useStyles = makeStyles({
  section: {
    flex: '1',
  },
})

const CreatedModifiedField = ({ event }) => {
  const classes = useStyles()

  const timeZone = useSelector(getTimeZone)
  const createdBy = useSelector(state => getUserById(state, event.createdBy))
  const modifiedBy = useSelector(state => getUserById(state, event.modifiedBy))

  const createdAt = event.createdAt
    ? utcToLocal(event.createdAt, timeZone)
    : null
  const modifiedAt = event.modifiedAt
    ? utcToLocal(event.modifiedAt, timeZone)
    : null
  const createdByName = createdBy.name || 'Unknown User'
  const modifiedByName = modifiedBy.name || 'Unknown User'

  return (
    <React.Fragment>
      {createdAt && (
        <BaseField label="Created">
          <Typography className={classes.section}>{createdByName}</Typography>
          <Typography className={classes.section}>
            {createdAt.format(DATE_FORMAT)}
          </Typography>
        </BaseField>
      )}

      {modifiedAt && (
        <BaseField label="Modified">
          <Typography className={classes.section}>{modifiedByName}</Typography>
          <Typography className={classes.section}>
            {modifiedAt.format(DATE_FORMAT)}
          </Typography>
        </BaseField>
      )}
    </React.Fragment>
  )
}

CreatedModifiedField.propTypes = {
  event: PropTypes.record,
}

export default CreatedModifiedField
