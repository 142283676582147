import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import key from '../key'
import { getRoot } from './common/shared'

const HOSPICES = 'hospices'

export const Hospice = Record({
  id: null,
  name: null,
  marketId: null,
  market: null,
  phone: null,
  fax: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  notes: null,
})

const transformHospice = ({ market, ...hospice }) =>
  Hospice({
    ...hospice,
    marketId: getIn(market, 'id'),
    market: getIn(market, 'name'),
  })

export const fetchHospices = Request({
  typePrefix: key,
  typeBase: 'FETCH_HOSPICES',
  requestParams: ['searchWord'],
  operation: searchWord =>
    AspireAPI.get(`contacts/hospices`, {
      params: { searchWord },
    }),
  transform: into(Hospice, 'id'),
  messages: { failed: 'There was a problem fetching hospices' },
})

export const fetchHospice = Request({
  typePrefix: key,
  typeBase: 'FETCH_HOSPICE',
  requestParams: ['hospiceId'],
  operation: hospiceId => AspireAPI.get(`contacts/hospices/${hospiceId}`),
  transform: transformHospice,
  messages: { failed: 'There was a problem fetching this hospice' },
})

export const addHospice = Request({
  typePrefix: key,
  typeBase: 'ADD_HOSPICE',
  requestParams: ['hospice'],
  operation: hospice => AspireAPI.post('contacts/hospices', { hospice }),
  messages: {
    succeeded: 'Successfully added new hospice',
    failed: 'There was a problem adding this hospice',
  },
})

export const saveHospice = Request({
  typePrefix: key,
  typeBase: 'SAVE_HOSPICE',
  requestParams: ['hospice'],
  operation: hospice =>
    AspireAPI.put(`contacts/hospices/${hospice.id}`, { hospice }),
  messages: {
    succeeded: 'Successfully saved hospice',
    failed: 'There was a problem saving this hospice',
  },
})

export const deleteHospice = Request({
  typePrefix: key,
  typeBase: 'DELETE_HOSPICE',
  requestParams: ['hospiceId'],
  operation: hospiceId => AspireAPI.delete(`contacts/hospices/${hospiceId}`),
  messages: {
    succeeded: 'Successfully deleted hospice',
    failed: 'There was a problem deleting this hospice',
  },
})

const creator = scopedCreator(key)
export const hospicesCleared = creator('HOSPICES_CLEARED', false)

const initState = Map()
export default createReducer(HOSPICES, initState, {
  [fetchHospices.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchHospice.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [saveHospice.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { hospice },
        },
      },
    }
  ) => state.set(hospice.id, hospice),
  [deleteHospice.SUCCEEDED]: (
    state,
    {
      meta: {
        request: {
          payload: { hospiceId },
        },
      },
    }
  ) => state.delete(hospiceId),
  [hospicesCleared]: () => initState,
})

export const getHospices = pipe(getRoot, get(HOSPICES))

export const getHospicesArr = createSelector([getHospices], hospices =>
  hospices.toIndexedSeq().toArray()
)

export const getHospiceById = (state, id) =>
  getHospices(state).get(id, Hospice())
